import React from 'react';
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import {useTranslation} from "react-i18next";
import apiAxios from "../../../../../../app/axios/apiAxios";
import Icon from "../../../../../../assets/icon/Icon";

let GET_OTM_SHIPMENT_LINK = `/api/${process.env.REACT_APP_API_VERSION}/shipment/getOtmShipmentLink`
let GET_OTM_TRANSPORT_ORDER_LINK = `/api/${process.env.REACT_APP_API_VERSION}/shipment/getOtmToLink`;

export const openOtmShipment = async (sid: string) => {
    try {
        let response = await apiAxios.get<string>(`${GET_OTM_SHIPMENT_LINK}?id=${sid}&_c=${Date.now()}`);

        if (response.status === 200 && response.data) {
            window.open(response.data, '_blank');
        }
    } catch {
        //ignore
    }
}

export const openOtmTransportOrder = async (sid: string) => {
    try {
        let response = await apiAxios.get<string>(`${GET_OTM_TRANSPORT_ORDER_LINK}?id=${sid}&_c=${Date.now()}`);

        if (response.status === 200 && response.data) {
            window.open(response.data, '_blank');
        }
    } catch {
        //ignore
    }
}

const ShipmentNumberTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const user = useAppSelector(selectCurrentUser);
    const {t} = useTranslation();

    if (!user) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
            </td>
        )
    }

    const role = getUserRole(user);

    let isUnConfirmed = props.dataItem.sId !== null && props.dataItem.sId !== undefined &&
    props.dataItem["isUnconfirm"] !== null && props.dataItem["isUnconfirm"] !== undefined
        ? props.dataItem["isUnconfirm"]
        : null;

    if (role === UserRole.ServiceProvider || role === UserRole.Client) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={'default-left-cell default-overflow-cell'}>
                    {isUnConfirmed !== null
                        ? <span title={isUnConfirmed ? t("shipment.grid.unconfirmed") : t("shipment.grid.confirmed")}
                                style={isUnConfirmed ?
                                    {

                                        fontSize: '12px',
                                        marginRight: '5px',
                                        verticalAlign: "middle"
                                    } : {

                                        fontSize: '12px',
                                        marginRight: '5px',
                                        verticalAlign: "middle"
                                    }}>
                        {props.children}
                    </span>
                        : <span style={{
                            fontSize: '12px',
                            marginRight: '5px',
                            verticalAlign: "middle"
                        }}>
                        {props.children}
                    </span>
                    }
                </span>
            </td>
        );
    }

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
             <span className={'default-left-cell default-overflow-cell grid-prevent-selection'}>
                 <Icon icon={"faCheck"} title={isUnConfirmed ? t("shipment.grid.unconfirmed") : t("shipment.grid.confirmed")}  style={{
                     fontSize: '16px',
                     marginRight: '5px',
                     verticalAlign: 'middle',
                     color: isUnConfirmed ? '#E96024' : '#7FB98B'
                 }}/>
                  {isUnConfirmed !== null && isUnConfirmed === false &&
                      <Icon icon={"faPrint"}
                            style={{
                                fontSize: '16px',
                                marginRight: '5px',
                                verticalAlign: 'middle',
                                cursor: 'pointer'
                            }}
                            className={'grid-prevent-selection'}
                            title={t("shipment.grid.open-otm-transport-order-shipment")}
                            onClick={async (ev) => {
                                if (props.dataItem.sId === null || props.dataItem.sId === undefined) {
                                    return;
                                }

                                ev.stopPropagation();

                                await openOtmTransportOrder(props.children as string)
                            }}
                      />
                  }

                 {props.dataItem.isFirstOrder !== undefined && props.dataItem.isFirstOrder &&
                     <Icon icon={"faTriangleExclamation"}
                           title={t("shared.labels.first-order-label")}
                           style={{
                               color: '#3F8CE5',
                               fontSize: '17px',
                               marginRight: '5px',
                               verticalAlign: 'middle'
                           }}/>
                 }

                 {isUnConfirmed !== null
                     ? <button className={'grid-prevent-selection shipment-grid-open-otm-shipment-btn cell-button'}
                               onClick={async (ev) => {
                                   if (props.dataItem.sId === null || props.dataItem.sId === undefined) {
                                       return;
                                   }

                                   ev.stopPropagation();

                                   await openOtmShipment(props.children as string)
                               }}
                               title={isUnConfirmed ? t("shipment.grid.unconfirmed") : t("shipment.grid.confirmed")}
                               style={isUnConfirmed ?
                                   {
                                      // color: '#E96024',
                                       fontSize: '12px',
                                       marginRight: '5px',
                                       verticalAlign: "middle"
                                   } : {
                                     //  color: '#6D9E77',
                                       fontSize: '12px',
                                       marginRight: '5px',
                                       verticalAlign: "middle"
                                   }}>
                         {props.children}
                     </button>
                     : <button className={'grid-prevent-selection shipment-grid-open-otm-shipment-btn cell-button'}
                               style={{
                                   fontSize: '14px'
                               }}
                               onClick={async (ev) => {
                                   if (props.dataItem.sId === null || props.dataItem.sId === undefined) {
                                       return;
                                   }

                                   ev.stopPropagation();

                                   await openOtmShipment(props.children as string)
                               }}>
                         {props.children}
                     </button>
                 }
             </span>
        </td>
    );
};

export default ShipmentNumberTemplate;
