import React from 'react';
import {useTranslation} from "react-i18next";
import SidebarButton from "../../../components/SidebarButton/SidebarButton";
import {useAppDispatch, useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {checkUserIsAdmin, getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import {selectIsAccessSettingsPanelOpened} from "../../../../../../features/application/applicationSliceSelectors";
import {setIsAccessSettingsPanelOpened} from "../../../../../../features/application/applicationSlice";

const SidebarSettingsSectionAccessSettings: React.FC = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const user = useAppSelector(selectCurrentUser);
    const isAccessSettingsPanelOpened = useAppSelector(selectIsAccessSettingsPanelOpened);

    if (!user) {
        return null;
    }

    let role = getUserRole(user);

    if (!checkUserIsAdmin(user) && role !== UserRole.Forwarder) {
        return null;
    }

    const setIsOpened = (val: boolean) => {
        dispatch(setIsAccessSettingsPanelOpened(val));
    };

    return (
        <SidebarButton id={'sidebar-access-settings-item'}
                       type={'secondary'}
                       title={t("shared.labels.access-settings")}
                       text={t("shared.labels.access-settings")}

                       active={isAccessSettingsPanelOpened}
                       onClick={() => setIsOpened(!isAccessSettingsPanelOpened)}/>
    );
};

export default SidebarSettingsSectionAccessSettings;
