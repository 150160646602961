import React from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import Alert from "../../../../components/ui/Alert/Alert";
import QuoteForm from "../../Quote/form/QuoteForm";
import OfferForm from "./OfferForm";

const OfferFormPage: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {id} = useParams();

    if (!id) {
        return (
            <div className={'w100 h100'}>
                <Alert type={'Error'}>
                    {t("shared.errors.server-error")}
                </Alert>
            </div>
        );
    }

    return <OfferForm id={id}
                      close={() => navigate('/offers/all')}
                      isFullPageDetails={true}/>;
};

export default OfferFormPage;