import React, {useState} from 'react';
import {AnimatePresence, motion} from "framer-motion";
import {useChatHelperContext} from "../../../../hooks/useChatHelperContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from '@fortawesome/free-regular-svg-icons';
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import ChatSearchInput from "../../../ChatSearchInput/ChatSearchInput";
import {useTranslation} from "react-i18next";
import moment from "moment";
import ChatAvatar from "../../../ChatAvatar/ChatAvatar";

const ChatDialogueSearch: React.FC = () => {
    const {t} = useTranslation();

    const [showSearchedMessages, setShowSearchedMessages] = useState<boolean>(false);

    const {
        isMessagesSearchSectionShown,
        disabled,
        isMobileView,
        searchMessages,
        activeSearchMessage,
        setIsMessagesSearchSectionShown,
        searchChatMessages,
        setActiveChatSearchString,
        setActiveChatSearchMessage,
    } = useChatHelperContext();

    let chevronUpDisabled: boolean = true;
    let chevronDownDisabled: boolean = true;

    if (activeSearchMessage === null) {
        chevronUpDisabled = true;
        chevronDownDisabled = true;
    } else {
        let index = searchMessages.findIndex(e => e.id === activeSearchMessage.id);

        if (index > -1) {
            chevronDownDisabled = index >= (searchMessages.length - 1);
            chevronUpDisabled = (index > (searchMessages.length - 1)) ||
                ((index + (searchMessages.length - 1)) === (searchMessages.length - 1));
        }
    }

    return (
        <>
            <AnimatePresence>
                {isMessagesSearchSectionShown &&
                    <motion.div key={'chat-dialogue-search-section'}
                                className={'chat-dialogue-search-section'}
                                data-type={isMobileView ? 'mobile' : ''}
                                initial={{y: '-100%', opacity: 0}}
                                exit={{y: '-100%', opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                transition={{duration: 0.2, ease: 'easeOut'}}>
                        <div>
                            <div className={'chat-dialogue-search-section-icon flex-gap-5'}>
                                <FontAwesomeIcon icon={faChevronUp}
                                                 style={chevronUpDisabled ? {color: 'darkgray'} : undefined}
                                                 onClick={() => {
                                                     if (chevronUpDisabled) {
                                                         return true;
                                                     }

                                                     let index = searchMessages.findIndex(e => e.id === activeSearchMessage?.id);

                                                     if (index > -1) {
                                                         let item = searchMessages[(index - 1)];
                                                         if (item) {
                                                             setActiveChatSearchMessage(item);
                                                         }
                                                     }
                                                 }}/>
                                <FontAwesomeIcon icon={faChevronDown}
                                                 style={chevronDownDisabled ? {color: 'darkgray'} : undefined}
                                                 onClick={() => {
                                                     if (chevronDownDisabled) {
                                                         return true;
                                                     }

                                                     let index = searchMessages.findIndex(e => e.id === activeSearchMessage?.id);

                                                     if (index > -1) {
                                                         let item = searchMessages[(index + 1)];
                                                         if (item) {
                                                             setActiveChatSearchMessage(item);
                                                         }
                                                     }
                                                 }}/>
                            </div>
                            <div className={'chat-dialogue-search-messages-input'}>
                                <ChatSearchInput placeholder={t("shared.btn.search")}
                                                 disabled={disabled}
                                                 autoFocus={true}
                                                 onFocus={() => setShowSearchedMessages(true)}
                                                 onBlur={() => {
                                                     setTimeout(() => {
                                                         setShowSearchedMessages(false)
                                                     }, 300);
                                                 }}
                                                 onInputChange={(val) => {
                                                     setActiveChatSearchString(val);

                                                     searchChatMessages(val)
                                                 }}
                                                 style={isMobileView ? {
                                                     backgroundColor: 'white'
                                                 } : undefined}/>
                            </div>
                            <div className={'chat-dialogue-search-section-icon'}>
                                <FontAwesomeIcon icon={faCircleXmark}
                                                 onClick={() => {
                                                     if (disabled) {
                                                         return;
                                                     }

                                                     setIsMessagesSearchSectionShown(!isMessagesSearchSectionShown);
                                                 }}/>
                            </div>
                        </div>
                    </motion.div>
                }
            </AnimatePresence>

            <AnimatePresence>
                {searchMessages.length > 0 && showSearchedMessages &&
                    <motion.div key={'chat-dialogue-search-messages-section'}
                                className={'chat-dialogue-search-messages-section'}
                                data-type={isMobileView ? 'mobile' : ''}
                                initial={{y: '50%', opacity: 0}}
                                exit={{y: '50%', opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                transition={{duration: 0.2, ease: 'easeOut'}}>
                        {[...searchMessages]
                            .sort((a, b) => {
                                return ((new Date(b.date).getTime()) - (new Date(a.date).getTime()));
                            })
                            .map(msg => {
                                let isActive = activeSearchMessage !== null && activeSearchMessage.id === msg.id;

                                return (
                                    <div key={msg.id}
                                         onClick={() => {
                                             if (disabled) {
                                                 return;
                                             }

                                             setActiveChatSearchMessage(msg);
                                         }}
                                         className={`chat-dialogue-search-messages-message ${isActive ? 'chat-dialogue-search-messages-message-active' : ''}`}>
                                        <div className={'chat-dialogue-search-messages-message-center'}>
                                            <ChatAvatar isOnline={false}
                                                        onVacation={false}
                                                        size={'Small'}/>
                                        </div>
                                        <div>
                                            <div>
                                                {msg.name}
                                            </div>
                                            <div>
                                                {msg.text}
                                            </div>
                                        </div>
                                        <div className={'chat-dialogue-search-messages-message-center'}>
                                            {moment(msg.date).format('DD.MM.YYYY')}
                                        </div>
                                    </div>
                                );
                            })}
                    </motion.div>
                }
            </AnimatePresence>
        </>
    );
};

export default ChatDialogueSearch;