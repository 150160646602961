import React, {ReactNode} from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {useAppSelector} from "../../store";
import {
    selectDeviceAuthenticationStatus,
} from "../../../features/authentication/authenticationSliceSelectors";
import {AuthenticationStatus} from "../../../features/authentication/IAuthenticationState";

type DeviceAuthenticatedProtectedRouteProps = {
    redirect?: string;
    children: ReactNode
}

const DeviceAuthenticatedProtectedRoute = ({
                                               redirect = '/authentication/device',
                                               children
                                           }: DeviceAuthenticatedProtectedRouteProps) => {
    const location = useLocation();
    const deviceAuthenticationStatus = useAppSelector(selectDeviceAuthenticationStatus);

    if (deviceAuthenticationStatus === AuthenticationStatus.NotAuthenticated ||
        deviceAuthenticationStatus === AuthenticationStatus.NoAction) {
        return <Navigate to={redirect} replace state={{from: location}}/>
    }

    return <>{children}</>;
};

export default DeviceAuthenticatedProtectedRoute;