import React, {useContext, useState} from 'react';
import {useTranslation} from "react-i18next";
import SidebarSettingsSectionAccessSettings
    from "./SidebarSettingsSectionAccessSettings/SidebarSettingsSectionAccessSettings";
import SidebarNavigationButton from "../../components/SidebarNavigationButton/SidebarNavigationButton";
import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {selectCurrentUser} from "../../../../../features/authentication/authenticationSliceSelectors";
import {checkUserInRole, checkUserIsAdmin, getUserRole} from "../../../../../helpers/user";
import {UserRole} from "../../../../../app/enums/UserRole";
import NotificationDialog from "../../../../../pages/authorized/Profile/Notification/Forwarder/NotificationDialog";
import SidebarButton from "../../components/SidebarButton/SidebarButton";
import SidebarUserSectionChat from "../SidebarUserSection/SidebarUserSectionChat/SidebarUserSectionChat";
import AccessSettingsPanel from "../../../../shared/components/AccessSettings/AccessSettingsPanel/AccessSettingsPanel";
import {selectIsAccessSettingsPanelOpened} from "../../../../../features/application/applicationSliceSelectors";
import {setIsAccessSettingsPanelOpened} from "../../../../../features/application/applicationSlice";
import {SidebarContext} from "../../Sidebar";
import {isMobile} from "react-device-detect";

const SidebarSettingsSection: React.FC = () => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);
    const dispatch = useAppDispatch();

    const isAccessSettingsPanelOpened = useAppSelector(selectIsAccessSettingsPanelOpened);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const setIsAccessSettingsOpened = (val: boolean) => {
        dispatch(setIsAccessSettingsPanelOpened(val));
    };

    const {
        expanded,
        hoverExpanded,
        setIsExpandedOnHover
    } = useContext(SidebarContext);

    return (
        <div>
            <SidebarUserSectionChat/>

            <NotificationDialog show={isOpened}
                                setShow={setIsOpened}/>

            {user && (checkUserIsAdmin(user) || getUserRole(user) === UserRole.Forwarder)
                ? <AccessSettingsPanel isOpened={isAccessSettingsPanelOpened}
                                       setIsOpened={setIsAccessSettingsOpened}/>
                : null
            }

            <SidebarNavigationButton id={'sidebar-settings'} expandedId={''}
                                     type={'primary'}
                                     text={t("shared.labels.settings")}
                                     title={t("shared.labels.settings")}
                                     path={'/settings'}
                                     icon={"faGear"}>

                <SidebarSettingsSectionAccessSettings/>

                {checkUserInRole(UserRole.Forwarder, user) &&
                    <SidebarButton id={'sidebar-notification-item'}
                                   type={'secondary'}
                                   text={t("sidebar.notification")}
                                   title={t("sidebar.notification")}
                                   onClick={() => setIsOpened(prev => !prev)}
                    />
                }

                {checkUserInRole(UserRole.ServiceProvider, user) &&
                    <SidebarNavigationButton id={'sidebar-notification-item'}
                                             type={'secondary'}
                                             text={t("sidebar.notification")}
                                             title={t("sidebar.notification")}
                                             path={'/notification'}
                                             onClick={() => {
                                                 if (isMobile && !expanded && hoverExpanded) {
                                                     setIsExpandedOnHover(false);
                                                 }
                                             }}
                    />
                }
            </SidebarNavigationButton>


        </div>
    );
};

export default SidebarSettingsSection;
