import {
    useGetDriverCheckListData,
    useSaveDriverCheckListData
} from "./requests";

const useDriverCheckListApi = () => {

    const {
        isLoading: getDriverCheckListDataIsLoading,
        cancel: cancelGetDriverCheckListData,
        getDriverCheckListData
    } = useGetDriverCheckListData();

    const {
        isLoading: saveDriverCheckListDataIsLoading,
        cancel: cancelSaveDriverCheckListData,
        saveDriverCheckListData
    } = useSaveDriverCheckListData();
    

    return {
        getDriverCheckListData: {
            query: getDriverCheckListData,
            isLoading: getDriverCheckListDataIsLoading,
            cancel: cancelGetDriverCheckListData
        },

        saveDriverCheckListData: {
            query: saveDriverCheckListData,
            isLoading: saveDriverCheckListDataIsLoading,
            cancelSaveDriverCheckListData: cancelSaveDriverCheckListData
        }
    };
};

export default useDriverCheckListApi;