import React, {useEffect, useState} from 'react';
import {IMapPositioningPostAction} from "../../../../../../../app/interfaces/shipment/IMapPositioningPostAction";
import Dialog from "../../../../../../../components/ui/Dialog/Dialog";
import {useTranslation} from "react-i18next";
import Button, {ButtonType} from "../../../../../../../components/ui/Button/Button";
import {useShipmentApi} from "../../../../../../../app/api/shipment";
import {ResponseResultCode} from "../../../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import ToastLayout from "../../../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {isAxiosError} from "axios";
import {IError} from "../../../../../../../app/interfaces/response/IResponse";
import moment from "moment/moment";
import ReactDatePicker from "../../../../../../../components/ui/ReactDatePicker/ReactDatePicker/ReactDatePicker";

type LocationMapConfirmationDialogProps = {
    model: IMapPositioningPostAction;
    onSaved: () => void;
    isDialogShown: boolean;
    setIsDialogShown: React.Dispatch<React.SetStateAction<boolean>>;
}

const LocationMapConfirmationDialog: React.FC<LocationMapConfirmationDialogProps> = ({
                                                                                         isDialogShown,
                                                                                         setIsDialogShown,
                                                                                         model,
                                                                                         onSaved
                                                                                     }) => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [, setErrors] = useState<Array<IError>>([]);
    const [modelToSave, setModelToSave] = useState<IMapPositioningPostAction>(model);

    const {
        addGpsEvent: {
            mutation: addGpsEvent,
            cancel: cancelAddGpsEvent
        }
    } = useShipmentApi();

    useEffect(() => {
        return () => {
            cancelAddGpsEvent();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const save = async (model: IMapPositioningPostAction): Promise<void> => {
        setDisabled(prev => !prev);
        setErrors([]);

        try {
            const response = await addGpsEvent(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                onSaved();

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [
                            {
                                id: model.shipmentGid,
                                isRowLoading: true
                            }
                        ]
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.shipment")} ${model.shipmentGid}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else {
                    setIsDialogShown(prev => !prev);
                    toast.error(err.message);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    return (
        <Dialog id={'confirmation-map-positioning-dialog'}
                show={isDialogShown}
                title={t("shipment.form.positioning-header")}
                width={'650px'}
                setShow={setIsDialogShown}>
            <div className={'h100 w100'}>
                <div className={'dialog-default-content-container pd-10'}>
                    <ReactDatePicker disabled={disabled}
                                     format={'dd.MM.yyyy HH:mm'}
                                     size={'big'}
                                     showClearIcon={false}
                                     value={modelToSave.date ? moment(modelToSave.date, 'DD.MM.YYYY HH:mm').toDate() : null}
                                     onChange={(val) => {
                                         if (!val) {
                                             return;
                                         }
                                         setModelToSave({
                                             ...modelToSave,
                                             date: moment(val).format('DD.MM.YYYY HH:mm')
                                         });
                                     }}
                                     placeholder={t("shared.labels.date")}
                    />

                    {/* <DatePicker format={'dd.MM.yyyy'}
                                    dateInput={DateInput}
                                    disabled={disabled}
                                    formatPlaceholder={{
                                        year: "yyyy",
                                        month: "MM",
                                        day: "dd"
                                    }}
                                    value={date ? moment(date, 'DD.MM.YYYY').toDate() : null}
                                    onChange={(ev) => {
                                        setDate(ev.target.value
                                            ? moment(ev.target.value).format('DD.MM.YYYY')
                                            : null
                                        );
                                    }}
                                    rounded={'small'}
                                    fillMode={'outline'}
                                    placeholder={t("shared.labels.date")}
                                    adaptive={true}/>*/}
                </div>
                <div className={'dialog-default-buttons-container'}>
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={() => setIsDialogShown(prev => !prev)}>
                        {t("shared.btn.cancel")}
                    </Button>
                    <Button buttonType={ButtonType.Primary}
                            disabled={disabled || !modelToSave.date || modelToSave.date === ''}
                            style={{minWidth: '150px'}}
                            className={'confirm-button'}
                            onClick={async () => {
                                if (!modelToSave.date || modelToSave.date === '') {
                                    return;
                                }

                                await save({
                                    ...modelToSave
                                });
                            }}
                            isLoading={disabled}>
                        {t("shared.btn.save")}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default LocationMapConfirmationDialog;