import React from 'react';
import {ITag} from "../../../../../../app/interfaces/tag/ITag";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import {ISaveTagPostAction} from "../../../../../../app/interfaces/tag/ISaveTagPostAction";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import cl from './TagForm.module.css';
import TagColorPicker from "../TagColorPicker/TagColorPicker";
import Input from "../../../../../ui/Input/Input";
import Checkbox from "../../../../../ui/Checkbox/Checkbox";
import Button, {ButtonType} from "../../../../../ui/Button/Button";
import {IDeleteTagAction} from "../../../../../../app/interfaces/tag/IDeleteTagAction";

type TagFormProps = {
    disabled: boolean;
    tag: ITag | null;
    onTagSave?: (tag: ISaveTagPostAction) => Promise<void>;
    onTagUpdate?: (tag: ISaveTagPostAction) => Promise<void>;
    onTagDelete?: (tag: IDeleteTagAction) => Promise<void>;
    showButtons?: boolean;
};

const TagForm: React.FC<TagFormProps> = ({
                                             tag,
                                             disabled,
                                             onTagDelete,
                                             onTagSave,
                                             onTagUpdate,
                                             showButtons = true
                                         }) => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    const form = useFormik<ISaveTagPostAction>({
        enableReinitialize: true,
        initialValues: {
            id: tag?.id ?? 0,
            name: tag?.name ?? '',
            color: tag !== null
                ? `#${tag.color}`
                : '#E37F32',
            isPublic: tag !== null
                ? tag.isPublic
                : user?.isHead ?? false
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().nullable()
                .required(t("shared.errors.required-error").replace('{0}', t("tags.labels.name"))),
            color: Yup.string().nullable()
                .required(t("shared.errors.required-error").replace('{0}', t("tags.labels.color")))
        }),
        onSubmit: async (values: ISaveTagPostAction, actions: FormikHelpers<ISaveTagPostAction>) => {
            actions.setSubmitting(true);

            if (values.id > 0 && onTagSave) {
                await onTagSave(values);
            } else if (values.id <= 0 && onTagUpdate) {
                await onTagUpdate(values);
            }

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='edit-tag-form'
              onSubmit={form.handleSubmit}
              className={`w100 h100 pd-10 ${cl.container}`}>
            <div>
                <div className={`view-row mgb-10`}>
                    <div className={cl.label}>
                        <label className={`view-label name-label required`}>
                            {t("tags.labels.name")}
                        </label>
                        {!form.values.name && form.errors.name &&
                            <div className={`error-label ${cl.error}`}>
                                {form.errors.name}
                            </div>
                        }
                    </div>
                    <Input disabled={disabled}
                           className={'tag-name-value'}
                           value={form.values.name}
                           placeholder={t("tags.labels.name")}
                           onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                               form.setFieldValue('name', ev.target.value);
                           }}/>
                </div>
                <div className={`view-row mgb-10`}>
                    <div className={cl.label}>
                        <label className={`view-label color-label required`}>
                            {t("tags.labels.color")}
                        </label>
                        {!form.values.color && form.errors.color &&
                            <div className={`error-label ${cl.error}`}>
                                {form.errors.color}
                            </div>
                        }
                    </div>

                    <TagColorPicker selectedColor={form.values.color}
                                    preview={true} text={form.values.name}
                                    onChange={(val) => {
                                        form.setFieldValue('color', val);
                                    }}/>
                </div>
                {user && user.isHead &&
                    <div className={`view-row mgb-10`}>
                        <Checkbox checked={form.values.isPublic}
                                  id={'tag-form-is-public'}
                                  title={t("tags.labels.department-tags")}
                                  label={t("tags.labels.department-tags")}
                                  change={(ev) => {
                                      form.setFieldValue('isPublic', ev);
                                  }}
                                  disabled={disabled}/>
                    </div>
                }
            </div>
            <div>
                {showButtons &&
                    <>

                        <div className={`view-row ${cl.btns}`}>
                            {tag === null
                                ? <>
                                    <Button buttonType={ButtonType.Primary}
                                            disabled={disabled}
                                            form='edit-tag-form'
                                            type='submit'
                                            style={{minWidth: '100px', padding: '7px'}}
                                            className={'confirm-button'}
                                            isLoading={disabled}>
                                        {t("shared.btn.add-tag")}
                                    </Button>
                                </>
                                : <>
                                    <Button buttonType={ButtonType.Error}
                                            disabled={disabled}
                                            type='button'
                                            style={{padding: '7px'}}
                                            onClick={async (ev) => {
                                                ev.preventDefault();

                                                if (form.values.id <= 0) {
                                                    return;
                                                }

                                                if (onTagDelete) {
                                                    await onTagDelete({
                                                        id: form.values.id
                                                    })
                                                }
                                            }}
                                            isLoading={disabled}>
                                        {t("shared.btn.delete")}
                                    </Button>
                                    <Button buttonType={ButtonType.Primary}
                                            disabled={disabled}
                                            form='edit-tag-form'
                                            type='submit'
                                            style={{minWidth: '100px', padding: '7px'}}
                                            className={'confirm-button'}
                                            isLoading={disabled}>
                                        {t("shared.btn.refresh")}
                                    </Button>
                                </>
                            }
                        </div>
                    </>
                }
            </div>
        </form>
    );
};

export default TagForm;
