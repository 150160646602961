import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'gear',
    pathData: [
        'M7.82888 16.1429L8.31591 17.2383C8.4607 17.5644 8.69698 17.8414 8.9961 18.0358C9.29522 18.2303 9.64434 18.3337 10.0011 18.3337C10.3579 18.3337 10.707 18.2303 11.0061 18.0358C11.3052 17.8414 11.5415 17.5644 11.6863 17.2383L12.1733 16.1429C12.3467 15.7542 12.6383 15.4302 13.0067 15.217C13.3773 15.0032 13.8061 14.9121 14.2317 14.9568L15.4233 15.0837C15.778 15.1212 16.136 15.055 16.4539 14.8931C16.7717 14.7312 17.0358 14.4806 17.2141 14.1716C17.3925 13.8628 17.4776 13.5089 17.4588 13.1527C17.4401 12.7966 17.3184 12.4535 17.1085 12.1651L16.403 11.1957C16.1517 10.8479 16.0175 10.4293 16.0196 10.0003C16.0195 9.57248 16.155 9.15562 16.4067 8.80959L17.1122 7.84014C17.3221 7.55179 17.4438 7.20872 17.4625 6.85255C17.4813 6.49639 17.3962 6.14244 17.2178 5.83366C17.0395 5.52469 16.7754 5.27407 16.4576 5.11218C16.1397 4.9503 15.7817 4.8841 15.427 4.92162L14.2354 5.04847C13.8098 5.09317 13.381 5.00209 13.0104 4.78829C12.6413 4.57387 12.3496 4.24812 12.177 3.85773L11.6863 2.76236C11.5415 2.4363 11.3052 2.15925 11.0061 1.96482C10.707 1.77039 10.3579 1.66693 10.0011 1.66699C9.64434 1.66693 9.29522 1.77039 8.9961 1.96482C8.69698 2.15925 8.4607 2.4363 8.31591 2.76236L7.82888 3.85773C7.65632 4.24812 7.3646 4.57387 6.99554 4.78829C6.62489 5.00209 6.1961 5.09317 5.77054 5.04847L4.57517 4.92162C4.22045 4.8841 3.86246 4.9503 3.5446 5.11218C3.22675 5.27407 2.96269 5.52469 2.78443 5.83366C2.60595 6.14244 2.52092 6.49639 2.53965 6.85255C2.55839 7.20872 2.68009 7.55179 2.88999 7.84014L3.59554 8.80959C3.84716 9.15562 3.98266 9.57248 3.98258 10.0003C3.98266 10.4282 3.84716 10.845 3.59554 11.1911L2.88999 12.1605C2.68009 12.4489 2.55839 12.7919 2.53965 13.1481C2.52092 13.5043 2.60595 13.8582 2.78443 14.167C2.96286 14.4758 3.22696 14.7263 3.54476 14.8882C3.86257 15.05 4.22047 15.1163 4.57517 15.079L5.76684 14.9522C6.1924 14.9075 6.62119 14.9986 6.99184 15.2124C7.36228 15.4262 7.65535 15.752 7.82888 16.1429Z',
        'M9.99961 12.5003C11.3803 12.5003 12.4996 11.381 12.4996 10.0003C12.4996 8.61961 11.3803 7.50033 9.99961 7.50033C8.6189 7.50033 7.49961 8.61961 7.49961 10.0003C7.49961 11.381 8.6189 12.5003 9.99961 12.5003Z'
    ],
    viewBox: '0 0 20 20',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;