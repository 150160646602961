import React from 'react';
import {SelectModel} from "../../../../../app/types/SelectModel";
import TagContainer from "../../../../ui/Tag/TagContainer/TagContainer";
import {TagItemBackgroundColor} from "../../../../ui/Tag/Tag";

type FilterPanelTagsFilterSelectedContainerProps = {
    id: string;
    value: Array<SelectModel>;
    onClear: (value?: string) => void;
};

const FilterPanelTagsFilterSelectedContainer: React.FC<FilterPanelTagsFilterSelectedContainerProps> = ({
                                                                                                           id,
                                                                                                           value,
                                                                                                           onClear
                                                                                                       }) => {
    return (
        <div style={{marginBottom: '5px'}}>
            <TagContainer id={`${id}`}
                          width={'100%'}
                          height={'100px'}
                          items={value.map(item => {
                              return {
                                  id: `item-${id}-${item.value}`,
                                  text: item.text,
                                  data: item,
                                  showClearIcon: true,
                                  backgroundColor: TagItemBackgroundColor.LightGray,
                                  rounded: true,
                                  backgroundColorStr: item.description !== undefined && item.description !== null &&
                                  item.description !== ''
                                      ? `#${item.description}`
                                      : undefined
                              };
                          })}
                          onClear={({data}) => {
                              if (data !== undefined && data !== null) {
                                  onClear(data.value as string);
                              }
                          }}/>
        </div>
    );
};

export default FilterPanelTagsFilterSelectedContainer;