import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import DeleteCustomerAttachment from './DeleteCustomerAttachment';
import { IDeleteCustomerAttachmentPostAction } from '../../../../../app/interfaces/customerAttachment/IDeleteCustomerAttachmentPostAction';

type DeleteCustomerAttachmentDialogProps = {
    docToDelete: IDeleteCustomerAttachmentPostAction | null;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const DeleteCustomerAttachmentDialog: React.FC<DeleteCustomerAttachmentDialogProps> = ({
                                                               docToDelete,
                                                               show,
                                                               setShow
                                                           }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'delete-attachment-confirmation-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("customer-attachment.labels.deleting-doc-confirmation-title")}>
            <DeleteCustomerAttachment 
                        docToDelete={docToDelete}
                        close={() => setShow(prev => !prev)}/>
        </Dialog>

    );
};

export default DeleteCustomerAttachmentDialog;