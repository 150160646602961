import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import SidebarNavigationButton from "../../../components/SidebarNavigationButton/SidebarNavigationButton";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {UserRole} from "../../../../../../app/enums/UserRole";
import {getUserRole} from "../../../../../../helpers/user";
import SidebarNavigationNestedButton
    from "../../../components/SidebarNavigationButton/SidebarNavigationNestedButton/SidebarNavigationNestedButton";
import {SidebarContext} from "../../../Sidebar";
import {isMobile} from "react-device-detect";

type SidebarMenuShipmentProps = {
    expandedId: string;
    onClick?: (id: string) => void;
};

const SidebarMenuShipment: React.FC<SidebarMenuShipmentProps> = ({
                                                                  expandedId,
                                                                  onClick: onClickProp,
                                                              }) => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    const {
        expanded,
        hoverExpanded,
        setIsExpandedOnHover
    } = useContext(SidebarContext);

    const handleNavigationClick = (id: string) => {
        if (onClickProp) {
            onClickProp(id);
        }
    };

    const handleNestedNavigationClick = () => {
        if (isMobile && !expanded && hoverExpanded) {
            setIsExpandedOnHover(false);
        }
    }

    if (!user) {
        return null;
    }

    let role = getUserRole(user);

    if (role !== UserRole.Administrator &&
        role !== UserRole.Forwarder &&
        role !== UserRole.ServiceProvider &&
        role !== UserRole.Client) {
        return null;
    }

    return (
        <SidebarNavigationButton id={'sidebar-shipment-item'} expandedId={expandedId}
                                 type={'primary'} onClick={handleNavigationClick}
                                 text={t("sidebar.shipment.title")}
                                 title={t("sidebar.shipment.title")}
                                 path={'/shipments'}
                                 icon={"faCubeApproved"}
                                 activeWhenAdditionalPath={['/disposition', '/invoices']}>
            <SidebarNavigationNestedButton id={'sidebar-shipment-item-current'}
                                           type={'primary'}
                                           path={'/shipments/current'}
                                           text={t("sidebar.shipment.current-shipment")}
                                           title={t("sidebar.shipment.current-shipment")}
                                           tooltip={t("sidebar.shipment.current-shipment-tooltip") || ''}
                                           onClick={handleNestedNavigationClick}
            />

            <SidebarNavigationNestedButton id={'sidebar-shipment-item-completed'}
                                           type={'primary'}
                                           path={'/shipments/completed'}
                                           text={t("sidebar.shipment.completed-shipment")}
                                           title={t("sidebar.shipment.completed-shipment")}
                                           tooltip={t("sidebar.shipment.completed-shipment-tooltip") || ''}
                                           onClick={handleNestedNavigationClick}
            />

            {role === UserRole.Forwarder || role === UserRole.Administrator || role === UserRole.ServiceProvider
                ? <SidebarNavigationNestedButton id={'sidebar-shipment-item-invoice'}
                                                 type={'primary'}
                                                 path={'/invoices'}
                                                 text={t("sidebar.shipment.payments-statuses")}
                                                 title={t("sidebar.shipment.payments-statuses")}
                                                 onClick={handleNestedNavigationClick}
                />
                : null
            }

            <SidebarNavigationNestedButton id={'sidebar-shipment-item-archive'}
                                           type={'primary'}
                                           path={'/shipments/archive'}
                                           text={t("sidebar.shipment.archive")}
                                           title={t("sidebar.shipment.archive")}
                                           tooltip={t("sidebar.shipment.archive-tooltip") || ''}
                                           onClick={handleNestedNavigationClick}
            />

            {role === UserRole.Forwarder || role === UserRole.Administrator
                ? <>
                    <SidebarNavigationNestedButton id={'sidebar-shipment-item-disposition'}
                                                   type={'primary'}
                                                   path={'/disposition'}
                                                   text={t("sidebar.shipment.disposition")}
                                                   title={t("sidebar.shipment.disposition")}
                                                   onClick={handleNestedNavigationClick}
                    />
                    <SidebarNavigationNestedButton id={'sidebar-shipment-item-approve'}
                                                   type={'primary'}
                                                   path={'/shipments/approve'}
                                                   text={t("sidebar.shipment.approve")}
                                                   title={t("sidebar.shipment.approve")}
                                                   tooltip={t("sidebar.shipment.approve-tooltip") || ''}
                                                   onClick={handleNestedNavigationClick}
                    />
                </>
                : null
            }
        </SidebarNavigationButton>
    );
};

export default SidebarMenuShipment;
