import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {IError} from "../../../../../../app/interfaces/response/IResponse";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import {BLErrorCode} from "../../../../../../app/enums/BLErrorCode";
import Alert from "../../../../../../components/ui/Alert/Alert";
import Button, {ButtonType} from "../../../../../../components/ui/Button/Button";
import useNotificationApi from "../../../../../../app/api/notification";
import ToastLayout from "../../../../../../components/uiLayouts/ToastLayout/ToastLayout";

type UnsubscribeNotificationsProps = {
    onUnsubscribe: () => void;
    close: () => void;
    type: 'Viber' | 'Telegram';
};

const UnsubscribeNotifications: React.FC<UnsubscribeNotificationsProps> = ({
                                                                               close,
                                                                               type,
                                                                               onUnsubscribe
                                                                           }) => {

    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError>>([]);

    const {
        unsubscribeFromTelegramNotifications: {
            mutation: unsubscribeFromTelegramNotifications,
            cancel: cancelUnsubscribeFromTelegramNotifications
        },
        unsubscribeFromViberNotifications: {
            mutation: unsubscribeFromViberNotifications,
            cancel: cancelUnsubscribeFromViberNotifications
        }
    } = useNotificationApi();

    useEffect(() => {
        return () => {
            cancelUnsubscribeFromTelegramNotifications();
            cancelUnsubscribeFromViberNotifications();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = async (): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            const response = type === 'Telegram'
                ? await unsubscribeFromTelegramNotifications()
                : await unsubscribeFromViberNotifications();

            if (response?.status === 200 && response?.data.resultCode === ResponseResultCode.Ok) {
                onUnsubscribe();
                close();

                toast.success(<ToastLayout title={type}
                                           text={t("notification.labels.unsubscribed-label")}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotFound &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();
                    toast.error(err.message);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    return (
        <div className={'h100 w100'}>
            <>
                {errors.length > 0 && errors.map((err, index) => {
                    switch (err.errorCode) {
                        case BLErrorCode.InnerError:
                            return (
                                <Alert type={'Error'}
                                       id={`delete-offer-action-error-${index}`}
                                       key={index}>
                                    {err.description}
                                </Alert>
                            )
                    }

                    return null;
                })}
            </>
            <div style={{
                margin: '30px 20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '20px'
            }}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            style={{minWidth: '150px'}}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.no")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        style={{minWidth: '150px'}}
                        onClick={submit}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.yes")}
                </Button>
            </div>
        </div>
    );
};

export default UnsubscribeNotifications;