import React, {useEffect, useRef, useState} from 'react';
import {GridActionRowContextButtonItem} from "../GridActionRowContextButton";
import useOnClickOutside from "../../../../../../../../../hooks/useOnClickOutside/useOnClickOutside";
import cl from './GridActionRowContextButtonMenu.module.css';

type GridActionRowContextButtonMenuProps = {
    isOpened: boolean;
    setIsOpened: (val: boolean) => void;
    container: React.MutableRefObject<HTMLDivElement | null>;
    actions: Array<GridActionRowContextButtonItem>;
    onClick: () => void;
}

const GridActionRowContextButtonMenu: React.FC<GridActionRowContextButtonMenuProps> = ({
                                                                                           isOpened,
                                                                                           setIsOpened,
                                                                                           container,
                                                                                           actions,
                                                                                           onClick
                                                                                       }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [point, setPoint] = useState<{
        x: number,
        y: number
    } | null>();
    useOnClickOutside(ref, (ev) => {
        if (ev.target && container.current?.contains(ev.target as Node)) {
            return;
        }

        setIsOpened(false);
    });

    useEffect(() => {
        if (!container || !container.current) {
            return;
        }

        let position = container.current?.getBoundingClientRect();
        if (position) {
            setPoint({
                x: -(250 - (container.current?.clientWidth ?? 0)),
                y: container.current?.clientHeight ?? 0
            });
        }
    }, [container]);

    if (!point || !isOpened) {
        return null;
    }

    return (
        <div style={{left: point.x, top: point.y,}}
             ref={ref}
             className={cl.menu}>
            <ul className={cl.ul}>
                {actions.map(action => {
                    return (
                        <li key={action.id}
                            id={action.id}
                            style={action.color ? {color: action.color} : undefined}
                            className={`${action.disabled ? cl.disabled : ''}${action.wraped ? cl.wraped : ''}`}
                            onClick={() => {
                                if (!action.disabled) {
                                    onClick();
                                    action.onClick();
                                }
                            }}
                        >
                            <div>
                                <div>
                                    {action.title}
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default GridActionRowContextButtonMenu;