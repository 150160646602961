import {
    useGetAccessFilterBranches,
    useGetAccessFilterDepartments,
    useGetAccessFilterForwarders,
    useGetAccessFilterServiceProviders,
    useGetAccessFilterTransportLines, useGetChatServiceProviders, useGetClientsWithGroups,
    useGetContractServiceProviderList,
    useGetEquipmentsTree,
    useGetRegionsTree,
    useGetReleaseServiceProviderList,
    useGetServiceProvidersTree,
    useGetSuppliersTree,
    useGetUserServiceProviderList, useGetUserServiceProviderListAnonymous,
    useGetUsersForAssignment,
    useGetUsersForAssignmentShort,
    useGetUsersLoginsForAssigment, useGetUsersLoginsForAssigmentAnonymous,
    useGetResponsibleVisibilityTree,
    useGetFuelTree,
    useGetAtmospherePrefilledValues,
    useGetLoadTree,
    useGetVehicleForGreenRouterTree
} from "./requests";

export const useDataHelperApi = () => {
    const {
        isLoading: getAccessFilterServiceProvidersIsLoading,
        cancel: cancelGetAccessFilterServiceProviders,
        getAccessFilterServiceProviders
    } = useGetAccessFilterServiceProviders();

    const {
        isLoading: getAccessFilterForwardersIsLoading,
        cancel: cancelGetAccessFilterForwarders,
        getAccessFilterForwarders
    } = useGetAccessFilterForwarders();

    const {
        isLoading: getAccessFilterDepartmentsIsLoading,
        cancel: cancelGetAccessFilterDepartments,
        getAccessFilterDepartments
    } = useGetAccessFilterDepartments();

    const {
        isLoading: getUsersLoginsForAssigmentIsLoading,
        cancel: cancelGetUsersLoginsForAssigment,
        getUsersLoginsForAssigment
    } = useGetUsersLoginsForAssigment();

    const {
        isLoading: getUsersForAssignmentIsLoading,
        cancel: cancelGetUsersForAssignment,
        getUsersForAssignment
    } = useGetUsersForAssignment();

    const {
        isLoading: getUsersForAssignmentShortIsLoading,
        cancel: cancelGetUsersForAssignmentShort,
        getUsersForAssignmentShort
    } = useGetUsersForAssignmentShort();

    const {
        isLoading: getAccessFilterBranchesIsLoading,
        cancel: cancelGetAccessFilterBranches,
        getAccessFilterBranches
    } = useGetAccessFilterBranches();

    const {
        isLoading: getAccessFilterTransportLinesIsLoading,
        cancel: cancelGetAccessFilterTransportLines,
        getAccessFilterTransportLines
    } = useGetAccessFilterTransportLines();

    const {
        isLoading: getRegionsTreeIsLoading,
        cancel: cancelGetRegionsTree,
        getRegionsTree
    } = useGetRegionsTree();

    const {
        isLoading: getSuppliersTreeIsLoading,
        cancel: cancelGetSuppliersTree,
        getSuppliersTree
    } = useGetSuppliersTree();

    const {
        isLoading: getEquipmentsTreeIsLoading,
        cancel: cancelGetEquipmentsTree,
        getEquipmentsTree
    } = useGetEquipmentsTree();

    const {
        isLoading: getServiceProvidersTreeIsLoading,
        cancel: cancelGetServiceProvidersTree,
        getServiceProvidersTree
    } = useGetServiceProvidersTree();

    const {
        isLoading: getUserServiceProviderListIsLoading,
        cancel: cancelGetUserServiceProviderList,
        getUserServiceProviderList
    } = useGetUserServiceProviderList();

    const {
        isLoading: getUserServiceProviderListAnonymousIsLoading,
        cancel: cancelGetUserServiceProviderListAnonymous,
        getUserServiceProviderListAnonymous
    } = useGetUserServiceProviderListAnonymous();

    const {
        isLoading: getReleaseServiceProviderListIsLoading,
        cancel: cancelGetReleaseServiceProviderList,
        getReleaseServiceProviderList
    } = useGetReleaseServiceProviderList();

    const {
        isLoading: getContractServiceProviderListIsLoading,
        cancel: cancelGetContractServiceProviderList,
        getContractServiceProviderList
    } = useGetContractServiceProviderList();

    const {
        isLoading: getClientsWithGroupsIsLoading,
        cancel: cancelGetClientsWithGroups,
        getClientsWithGroups
    } = useGetClientsWithGroups();

    const {
        isLoading: getChatServiceProvidersIsLoading,
        cancel: cancelGetChatServiceProviders,
        getChatServiceProviders
    } = useGetChatServiceProviders();

    const {
        isLoading: getUsersLoginsForAssigmentAnonymousIsLoading,
        cancel: cancelGetUsersLoginsForAssigmentAnonymous,
        getUsersLoginsForAssigmentAnonymous
    } = useGetUsersLoginsForAssigmentAnonymous();

    const {
        isLoading: getResponsibleVisibilityTreeIsLoading,
        cancel: cancelGetResponsibleVisibilityTree,
        getResponsibleVisibilityTree
    } = useGetResponsibleVisibilityTree();

    const {
        isLoading: getFuelTreeIsLoading,
        cancel: cancelGetFuelTree,
        getFuelTree
    } = useGetFuelTree();

    const {
        isLoading: getAtmospherePrefilledValuesIsLoading,
        cancel: cancelGetAtmospherePrefilledValues,
        getAtmospherePrefilledValues
    } = useGetAtmospherePrefilledValues();

    const {
        isLoading: getVehicleForGreenRouterTreeIsLoading,
        cancel: cancelGetVehicleForGreenRouterTree,
        getVehicleForGreenRouterTree
    } = useGetVehicleForGreenRouterTree();

    const {
        isLoading: getLoadTreeIsLoading,
        cancel: cancelGetLoadTree,
        getLoadTree
    } = useGetLoadTree();

    return {
        getUserServiceProviderListAnonymous: {
            query: getUserServiceProviderListAnonymous,
            isLoading: getUserServiceProviderListAnonymousIsLoading,
            cancel: cancelGetUserServiceProviderListAnonymous
        },
        getAccessFilterTransportLines: {
            query: getAccessFilterTransportLines,
            isLoading: getAccessFilterTransportLinesIsLoading,
            cancel: cancelGetAccessFilterTransportLines
        },
        getAccessFilterBranches: {
            query: getAccessFilterBranches,
            isLoading: getAccessFilterBranchesIsLoading,
            cancel: cancelGetAccessFilterBranches
        },
        getAccessFilterDepartments: {
            query: getAccessFilterDepartments,
            isLoading: getAccessFilterDepartmentsIsLoading,
            cancel: cancelGetAccessFilterDepartments
        },
        getAccessFilterForwarders: {
            query: getAccessFilterForwarders,
            isLoading: getAccessFilterForwardersIsLoading,
            cancel: cancelGetAccessFilterForwarders
        },
        getAccessFilterServiceProviders: {
            query: getAccessFilterServiceProviders,
            isLoading: getAccessFilterServiceProvidersIsLoading,
            cancel: cancelGetAccessFilterServiceProviders
        },
        getRegionsTree: {
            query: getRegionsTree,
            isLoading: getRegionsTreeIsLoading,
            cancel: cancelGetRegionsTree
        },
        getSuppliersTree: {
            query: getSuppliersTree,
            isLoading: getSuppliersTreeIsLoading,
            cancel: cancelGetSuppliersTree
        },
        getEquipmentsTree: {
            query: getEquipmentsTree,
            isLoading: getEquipmentsTreeIsLoading,
            cancel: cancelGetEquipmentsTree
        },
        getServiceProvidersTree: {
            query: getServiceProvidersTree,
            isLoading: getServiceProvidersTreeIsLoading,
            cancel: cancelGetServiceProvidersTree
        },
        getUserServiceProviderList: {
            query: getUserServiceProviderList,
            isLoading: getUserServiceProviderListIsLoading,
            cancel: cancelGetUserServiceProviderList
        },
        getReleaseServiceProviderList: {
            query: getReleaseServiceProviderList,
            isLoading: getReleaseServiceProviderListIsLoading,
            cancel: cancelGetReleaseServiceProviderList
        },
        getContractServiceProviderList: {
            query: getContractServiceProviderList,
            isLoading: getContractServiceProviderListIsLoading,
            cancel: cancelGetContractServiceProviderList
        },
        getUsersLoginsForAssigment: {
            query: getUsersLoginsForAssigment,
            isLoading: getUsersLoginsForAssigmentIsLoading,
            cancel: cancelGetUsersLoginsForAssigment
        },
        getUsersForAssignment: {
            query: getUsersForAssignment,
            isLoading: getUsersForAssignmentIsLoading,
            cancel: cancelGetUsersForAssignment
        },
        getUsersForAssignmentShort: {
            query: getUsersForAssignmentShort,
            isLoading: getUsersForAssignmentShortIsLoading,
            cancel: cancelGetUsersForAssignmentShort
        },
        getClientsWithGroups: {
            query: getClientsWithGroups,
            isLoading: getClientsWithGroupsIsLoading,
            cancel: cancelGetClientsWithGroups
        },
        getChatServiceProviders: {
            query: getChatServiceProviders,
            isLoading: getChatServiceProvidersIsLoading,
            cancel: cancelGetChatServiceProviders
        },
        getUsersLoginsForAssigmentAnonymous: {
            query: getUsersLoginsForAssigmentAnonymous,
            isLoading: getUsersLoginsForAssigmentAnonymousIsLoading,
            cancel: cancelGetUsersLoginsForAssigmentAnonymous
        },
        getResponsibleVisibilityTree: {
            query: getResponsibleVisibilityTree,
            isLoading: getResponsibleVisibilityTreeIsLoading,
            cancel: cancelGetResponsibleVisibilityTree
        },
        getFuelTree: {
            query: getFuelTree,
            isLoading: getFuelTreeIsLoading,
            cancel: cancelGetFuelTree
        },
        getAtmospherePrefilledValues: {
            query: getAtmospherePrefilledValues,
            isLoading: getAtmospherePrefilledValuesIsLoading,
            cancel: cancelGetAtmospherePrefilledValues
        },
        getLoadTree: {
            query: getLoadTree,
            isLoading: getLoadTreeIsLoading,
            cancel: cancelGetLoadTree
        },
        getVehicleForGreenRouterTree: {
            query: getVehicleForGreenRouterTree,
            isLoading: getVehicleForGreenRouterTreeIsLoading,
            cancel: cancelGetVehicleForGreenRouterTree
        },
    };
}
