import {Query} from "../../../../../app/types/Query";
import {IShipmentHeaderConfig} from "../data/interfaces/IShipmentHeaderConfig";
import {IGridFilter} from "../../../../../app/interfaces/gridFilter/IGridFilter";
import {SelectModel} from "../../../../../app/types/SelectModel";
import {GridFilter} from "../../../../../app/types/GridFilter";

export const buildShipmentQuery = (type: string, config: IShipmentHeaderConfig | null): Query => {
    const q = new Query();
    const t = getType(type);
    if (t) {
        q.addParam('t', t);
    }

    if (config) {
        let st = getSubType(config.subTab);
        if (st) {
            q.addParam('st', st);
        }

        q.addParam('onlyCurrent', config.showOnlyCurrent.toString())
            .addParam('role_cms', config.CM.toString())
            .addParam('role_tlm', config.TLM.toString())
            .addParam('role_fa', config.SMFA.toString())
            .addParam('role_exa', config.SMEXA.toString())
            .addParam('role_orm', config.OM.toString());
    }

    return q;
};

export const buildShipmentFilterQuery = (_: IGridFilter | null,
                                         predefinedFilter: SelectModel | null,
                                         filter: GridFilter | null): Query => {
    const q = new Query();

    if (predefinedFilter) {
        q.addParam('f', predefinedFilter.value);
    }

    if (filter) {
        if (filter.uid !== null && filter.uid !== '') {
            q.addParam('uid', filter.uid);
        }

        if (filter.jobNumber !== null && filter.jobNumber !== '') {
            q.addParam('jb', filter.jobNumber);
        }

        if (filter.shipmentNumber !== null && filter.shipmentNumber !== '') {
            q.addParam('sxid', filter.shipmentNumber);
        }

        if (filter.orderReleaseNumber !== null && filter.orderReleaseNumber !== '') {
            q.addParam('roxid', filter.orderReleaseNumber);
        }

        if (filter.customerRefNumber !== null && filter.customerRefNumber !== '') {
            q.addParam('crefn', filter.customerRefNumber);
        }

        if (filter.licencePlate !== null && filter.licencePlate !== '') {
            q.addParam('lp', filter.licencePlate);
        }

        if (filter.pickupDateFrom !== null && filter.pickupDateFrom !== '') {
            q.addParam('pkds', filter.pickupDateFrom);
        }

        if (filter.pickupDateTo !== null && filter.pickupDateTo !== '') {
            q.addParam('pkde', filter.pickupDateTo);
        }

        if (filter.deliveryDateFrom !== null && filter.deliveryDateFrom !== '') {
            q.addParam('dlds', filter.deliveryDateFrom);
        }

        if (filter.deliveryDateTo !== null && filter.deliveryDateTo !== '') {
            q.addParam('dlde', filter.deliveryDateTo);
        }

        if (filter.status.length > 0) {
            q.addParam('unconf', filter.status.join('+'))
        }

        if (filter.source.length > 0) {
            q.addParam('sc', filter.source.map(item => item.value).join('+'))
        }

        if (filter.destination.length > 0) {
            q.addParam('dc', filter.destination.map(item => item.value).join('+'))
        }

        if (filter.supplier.length > 0) {
            q.addParam('cls', filter.supplier.map(item => item.value).join('+'));
        }

        if (filter.serviceProvider.length > 0) {
            q.addParam('sps', filter.serviceProvider.map(item => item.value).join('+'));
        }

        if (filter.tags.length > 0) {
            q.addParam('tags', filter.tags.map(item => item.value).join('+'));
        }

        if (filter.equipment.length > 0) {
            q.addParam('eq', filter.equipment.map(item => item.value).join('+'));
        }

        if (filter.asstraIntegrationConstant === 'true') {
            q.addParam('asstraIntegrationConstant', 'true');
        }

        if (filter.asstraIntegrationTemporary === 'true') {
            q.addParam('asstraIntegrationTemporary', 'true');
        }

        if (filter.responsibleVisibility.length > 0) {
            q.addParam('responsibleVisibility', filter.responsibleVisibility.map(item => item.value).join('+'));
        }
    }


    return q;
};

const getType = (t: string): string | null => {
    switch (t) {
        case 'current':
            return '1';
        case 'completed':
            return '2';
        case 'archive':
            return '3';
        case 'approve':
            return '5';
    }

    return null;
};

const getSubType = (t: string): string | null => {
    switch (t) {
        case 'shipment-all-tab':
            return '2';
        case 'shipment-my-tab':
            return '1';
    }

    return null;
};

export const buildShipmentGridLabel = (type?: string): string => {
    if (!type) {
        return 'sidebar.shipment.title';
    }

    switch (type) {
        case 'current':
            return 'sidebar.shipment.current-shipment';
        case 'completed':
            return 'sidebar.shipment.completed-shipment';
        case 'archive':
            return 'sidebar.shipment.archive';
        case 'approve':
            return 'sidebar.shipment.approve';
    }

    return 'sidebar.shipment.title';
}
