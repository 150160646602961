import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import cl from "../AdrTemplate/AdrTemplate.module.css";
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";

const AdrTemplate = (props: any) => {
    const {t} = useTranslation();
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);
    const {handleClick} = useGridCellHandleClick(props.dataItem);



    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
                <span className={`${cl.adr} default-left-cell default-overflow-cell $\{cl.adr}`}>
                    {props.children}
                </span>
        </td>
    );

};

export default AdrTemplate;
