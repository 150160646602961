import React, {useEffect} from 'react';
import {useNavigate, useRouteError} from "react-router-dom";
import cl from './NotFound.module.css';
import logo from '../../../assets/images/logo.png';
import useDocumentTitle from "../../../hooks/useDocumentTitle/useDocumentTitle";
import {useTranslation} from "react-i18next";
import {IReactRouterError} from "../../../app/interfaces/log/IReactRouterError";
import {useLogApi} from "../../../app/api/log";

const NotFound: React.FC = () => {
    const {t} = useTranslation();
    useDocumentTitle({title: t("not-found.not-found-page-title")})
    const navigate = useNavigate();
    const routeError = useRouteError();

    const {
        logReactRouterError: {
            mutation: logReactRouterError
        }
    } = useLogApi();

    useEffect(() => {
        if (routeError && routeError instanceof Error) {
            (async () => {
                try {
                    const model: IReactRouterError = {
                        exceptionName: routeError.name,
                        exceptionMessage: routeError.message,
                        exceptionStack: routeError.stack ?? null
                    };

                    await logReactRouterError(model);
                } catch {

                }
            })();
        }
    }, []);

    return (
        <div className={cl.container}>
            <img src={logo} alt='logo' className={cl.logo}/>
            <div>
                <div className={cl.firstRowText}>
                    Error: 404 page not found
                </div>
                <div className={cl.secondRowText}>
                    Sorry, the page you're looking for cannot be accessed
                    <span onClick={() => navigate('/')}>Go to main page</span>
                </div>
            </div>
        </div>
    );
};

export default NotFound;