import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import DeleteOffer from "./DeleteOffer";

type DeleteOfferDialogProps = {
    id: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const DeleteOfferDialog: React.FC<DeleteOfferDialogProps> = ({
                                                                 id,
                                                                 show,
                                                                 setShow
                                                             }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'delete-offer-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.btn.delete")}>
            <DeleteOffer id={id}
                         close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default DeleteOfferDialog;