import {IApiErrorItem} from "../../apiError";
import {ResponseResultCode} from "../../enums/ResponseResultCode";

const loginApiErrors: Array<IApiErrorItem> = [
    {//Common server error
        response: {
            resultCode: ResponseResultCode.ServerError
        },
        resource: 'shared.errors.server-error'
    },
    {//Error when model properties isn't entered
        response: {
            resultCode: ResponseResultCode.NotValidData,
            action: 'authentication/login',
        },
        resource: 'login.errors.not-valid-argument-data'
    },
    {//Error when client generated token or user login is empty
        response: {
            resultCode: ResponseResultCode.NotValidData,
            action: 'captcha/validate',
        },
        resource: 'login.errors.captcha-token-error'
    },
    {//Http captcha validation error
        response: {
            resultCode: ResponseResultCode.ServerError,
            action: 'captcha/validate',
            field: 'recaptcha'
        },
        resource: 'shared.errors.server-error'
    },
    {//Score less than 0.5 error
        response: {
            resultCode: ResponseResultCode.Forbidden,
            action: 'captcha/validate',
            field: 'score'
        },
        resource: 'login.errors.captcha-validation-score-to-low'
    },
    {//Common captcha validation error
        response: {
            resultCode: ResponseResultCode.ServerError,
            action: 'captcha/validate'
        },
        resource: 'shared.errors.server-error'
    },
    {//Common authentication error
        response: {
            resultCode: ResponseResultCode.ServerError,
            action: 'authentication/login'
        },
        resource: 'shared.errors.server-error'
    },
    {//User not found by this credentials error
        response: {
            resultCode: ResponseResultCode.NotFound,
            action: 'authentication/login',
            field: 'user'
        },
        resource: 'login.errors.invalid-data-login-password'
    },
    {//Logged client device is saved and blocked now
        response: {
            resultCode: ResponseResultCode.Forbidden,
            action: 'authentication/login',
            field: 'device'
        },
        resource: 'login.errors.device-blocked'
    },
    {//User not found during otm/oracle authentication process
        response: {
            resultCode: ResponseResultCode.NotFound,
            action: 'authentication/loginValidate',
            field: 'user'
        },
        resource: 'login.errors.invalid-data-login-password'
    },
    {//User is blocked during otm/oracle authentication process
        response: {
            resultCode: ResponseResultCode.Forbidden,
            action: 'authentication/loginValidate',
            field: 'user'
        },
        resource: 'login.errors.blocked'
    },
    {//gl_user not found, show just user not found error
        response: {
            resultCode: ResponseResultCode.NotFound,
            action: 'authentication/loginValidate',
            field: 'gl_user'
        },
        resource: 'login.errors.invalid-data-login-password'
    },
    {//sp_admin not found, show just user not found error
        response: {
            resultCode: ResponseResultCode.NotFound,
            action: 'authentication/loginValidate',
            field: 'sp_admin'
        },
        resource: 'login.errors.invalid-data-login-password'
    },
    {//location not found, show just user not found error
        response: {
            resultCode: ResponseResultCode.NotFound,
            action: 'authentication/loginValidate',
            field: 'location'
        },
        resource: 'login.errors.invalid-data-login-password'
    },
    {//contact not found, show just user not found error
        response: {
            resultCode: ResponseResultCode.NotFound,
            action: 'authentication/loginValidate',
            field: 'contact'
        },
        resource: 'login.errors.invalid-data-login-password'
    },
    {//Common loginValidation in otm/oracle error
        response: {
            resultCode: ResponseResultCode.ServerError,
            action: 'authentication/loginValidate'
        },
        resource: 'shared.errors.server-error'
    },
];

export default loginApiErrors;