import React from 'react';
import {useNavigate} from "react-router-dom";
import cl from './NotFound.module.css';
import logo from '../../../assets/images/logo.png';
import useDocumentTitle from "../../../hooks/useDocumentTitle/useDocumentTitle";
import {useTranslation} from "react-i18next";

const NotFoundOrder: React.FC = () => {
    const {t} = useTranslation();
    useDocumentTitle({title: t("not-found.not-found-order-title") })
    const navigate = useNavigate();

    return (
        <div className={cl.container}>
            <img src={logo} alt='logo' className={cl.logo}/>
            <div>
                <div className={cl.firstRowText}>
                    {t("not-found.not-found-order-title")}
                </div>
                <div className={cl.secondRowText}>

                    <span onClick={() => navigate('/')}>Go to main page</span>
                </div>
            </div>
        </div>
    );
};

export default NotFoundOrder;
