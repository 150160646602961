import {isEdgeHTML, isTrident} from "../helpers/browser";

const getIndexedDb = (): boolean | undefined => {
    if (isTrident() || isEdgeHTML()) {
        return undefined
    }
    try {
        return !!window.indexedDB
    } catch (e) {
        return true
    }
}

export default getIndexedDb;