import React from 'react';
import {IQuote} from "../../../../../../../../app/interfaces/quote/IQuote";
import {useTranslation} from "react-i18next";
import ForwarderField from "../../../../../../../../components/shared/form/detailsFields/ForwarderField/ForwarderField";

type QuoteCuratorProps = {
    quote: IQuote;
}

const QuoteCurator: React.FC<QuoteCuratorProps> = ({
                                                       quote
                                                   }) => {
    const {t} = useTranslation();

    return (
        <div className={'details-box-item-field-value'}>
            {quote.curator !== null && quote.curator !== undefined && quote.curator.name !== null && quote.curator.name !== ''
                ? <ForwarderField user={quote.curator}
                                  chatReplyTo={{
                                      type: 'QUOTE',
                                      description: quote.chatDescription,
                                      id: quote.orderReleaseGid.replace('AAG.', ''),
                                      idExt: null
                                  }}/>
                : null
            }
        </div>
    );
};

export default QuoteCurator;
