import React, {ReactNode, useEffect, useState} from 'react';
import {createPortal} from "react-dom";

type GoogleMapCustomControlProps = {
    children: ReactNode;
    position: google.maps.ControlPosition;
    map: google.maps.Map | null;
    isMapLoaded: boolean;
    className?: string;
}

const GoogleMapCustomControl: React.FC<GoogleMapCustomControlProps> = ({
                                                                           children,
                                                                           position,
                                                                           map,
                                                                           isMapLoaded,
                                                                           className
                                                                       }) => {
    const [container] = useState<HTMLDivElement>(document.createElement('div'));

    useEffect(() => {
        if (!isMapLoaded || !map) {
            return;
        }

        if (className) {
            container.setAttribute('class', className);
        }

        const controls = map.controls[position];
        const index = controls.getLength();

        controls.push(container);

        return () => {
            controls.removeAt(index);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, isMapLoaded]);

    return createPortal(
        <>{children}</>,
        container
    );
};

export default GoogleMapCustomControl;