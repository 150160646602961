import React, {useRef, useState} from 'react';
import cl from './FormLayoutToolbar.module.css';
import {useTranslation} from "react-i18next";
import FormLayoutToolbarButton, {
    FormLayoutToolbarButtonProps
} from "../FormLayoutToolbarButton/FormLayoutToolbarButton";
import FormLayoutActionContextMenu from "../FormLayoutActionContextMenu/FormLayoutActionContextMenu";
import Button, {ButtonProps, ButtonType} from "../../../../ui/Button/Button";
import {FormConfig} from "../../../../../app/types/FormConfig";
import {isMobileOnly} from "react-device-detect";
import Icon from "../../../../../assets/icon/Icon";
import useMediaQuery from "../../../../../hooks/useMediaQuery/useMediaQuery";

export const isFormLayoutToolbarExist = (showCloseIcon?: boolean, actions?: Array<FormLayoutToolbarActionItem | 'separator'>) => {
    return (showCloseIcon !== undefined && showCloseIcon) || (actions !== undefined && actions.length > 0);
};

export type FormLayoutToolbarActionItem = {
    id: string;
    title: string,
    onClick?: () => void;
    disabled: boolean;
    color?: string;
    pinnedButtonType: ButtonType;
    type?: "button" | "submit" | "reset";
    form?: string;
    wraped?: boolean;
}

type FormLayoutToolbarProps = {
    showCloseIcon?: boolean;
    close: () => void;
    actions?: Array<FormLayoutToolbarActionItem | 'separator'>;
    pinActionEnabled: boolean;
    pinnedActions: Array<string>;
    saveConfig: (config: FormConfig) => Promise<void>;
    icons?: Array<FormLayoutToolbarButtonProps>;
    buttons?: Array<ButtonProps>;
}

const FormLayoutToolbar: React.FC<FormLayoutToolbarProps> = ({
                                                                 showCloseIcon,
                                                                 close,
                                                                 actions,
                                                                 pinActionEnabled,
                                                                 pinnedActions,
                                                                 saveConfig,
                                                                 icons,
                                                                 buttons
                                                             }) => {
    const isMaxWidth600 = useMediaQuery('(max-width:600px)');
    const actionBtnContainer = useRef<HTMLDivElement | null>(null);
    const [isActionsToggled, setIsActionsToggled] = useState<boolean>(false);
    const {t} = useTranslation();


    if (!isFormLayoutToolbarExist(showCloseIcon, actions)) {
        return null;
    }

    return (
        <div className={`${cl.toolbar} w100`}>
            <div>
                {showCloseIcon !== undefined && showCloseIcon &&
                    <button onClick={close}
                            title={t("shared.btn.close")}
                            className={'default-form-close-btn'}>
                        <Icon icon={"faXMark"}/>
                    </button>
                }
            </div>
            <div>
                {icons && icons.length > 0 && icons.map(icon => {
                    return (
                        <FormLayoutToolbarButton key={icon.id}
                                                 {...icon}/>
                    );
                })}

                {buttons && buttons.length > 0 && buttons.map(button => {
                    return (
                        <Button key={button.id} color={button.color}
                                id={button.id}
                                onClick={button.onClick}
                                title={button.title}
                                style={button.style}
                                buttonType={button.buttonType}>
                            {button.title}
                        </Button>
                    );
                })}

                {pinnedActions.length > 0 && actions && !isMobileOnly && !isMaxWidth600 &&
                    actions.map((item) => {
                        if (typeof item === 'string' || pinnedActions.find(e => e === item.id) === undefined) {
                            return null;
                        }

                        return (
                            <button className={cl.button}
                                    key={item.id} color={item.color}
                                    id={item.id}
                                    onClick={item.onClick}
                                    title={item.title}
                                    type={item.type}
                                    form={item.form}>
                                <div>
                                    <div>
                                        {item.title}
                                    </div>
                                </div>
                            </button>
                        )
                    })
                }

                {pinnedActions.length == 0 && actions && !isMobileOnly && !isMaxWidth600 &&
                    actions.slice(0, actions.length >= 3 ? 3 : actions.length).map((item) => {
                        if (typeof item === 'string') {
                            return null;
                        }

                        return (
                            <button className={cl.button}
                                    key={item.id} color={item.color}
                                    id={item.id}
                                    onClick={item.onClick}
                                    title={item.title}
                                    type={item.type}
                                    form={item.form}>
                                <div>
                                    <div>
                                        {item.title}
                                    </div>
                                </div>
                            </button>
                        )
                    })
                }

                {actions !== undefined && actions.length > 0 &&
                    <div ref={actionBtnContainer} className={'relative'}>
                        <FormLayoutToolbarButton id={'form-context-menu-btn'}
                                                 onClick={() => {/*Ignore*/
                                                 }}
                                                 title={t("shared.labels.actions")}
                                                 toggle={true}
                                                 isToggled={isActionsToggled}
                                                 setIsToggled={setIsActionsToggled}
                                                 icon={"faEllipsisHorizontal"}/>
                        <FormLayoutActionContextMenu isOpened={isActionsToggled}
                                                     pinActionEnabled={pinActionEnabled}
                                                     setIsOpened={setIsActionsToggled}
                                                     container={actionBtnContainer}
                                                     pinned={pinnedActions}
                                                     actions={actions}
                                                     onActionPin={(id: string) => {
                                                         let pinnedActionsToSave: Array<string> = [];

                                                         if (pinnedActions.find(e => e === id)) {
                                                             pinnedActionsToSave = pinnedActions.filter(e => e !== id);
                                                         } else {
                                                             pinnedActionsToSave = [...pinnedActions, id];
                                                         }

                                                         (async () => {
                                                             await saveConfig({
                                                                 pinnedActions: pinnedActionsToSave
                                                             });
                                                         })();
                                                     }}/>
                    </div>
                }
            </div>
        </div>
    );
};

export default FormLayoutToolbar;
