import {isMobile} from "react-device-detect";
import {Libraries} from "@react-google-maps/api";
import {UseLoadScriptOptions} from "@react-google-maps/api/dist/useLoadScript";

export const libraries: Libraries = ['marker', 'places'];
export const mapIds = ['DEMO_MAP_ID'];

export const defaultMapOptions: google.maps.MapOptions = {
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: false,
    streetViewControl: true,
    rotateControl: false,
    fullscreenControl: true,
    zoom: 7,
    gestureHandling: isMobile ? 'cooperative' : undefined,
    mapId: mapIds[0],
    center: {
        lat: 52.237049,
        lng: 21.017532
    }
}

export const useLoadScriptOptions: UseLoadScriptOptions = {
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY ?? '',
    libraries,
    mapIds
}
