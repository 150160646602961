import React, {useState} from 'react';
import useMediaQuery from "../../../../hooks/useMediaQuery/useMediaQuery";
import {useTranslation} from "react-i18next";
import Dialog from '../Dialog';
import cl from './ConfirmationDialog.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import Button, {ButtonType} from "../../Button/Button";

type ConfirmationDialogProps = {
    id: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    type: 'Alert' | 'Warning' | 'Information';
    title: string;
    text: string;
    primaryButtonText?: string;
    secondaryButtonText?: string;
    onCancel: () => void;
    onSubmit: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
                                                                   id,
                                                                   show,
                                                                   setShow,
                                                                   type,
                                                                   title,
                                                                   text,
                                                                   primaryButtonText,
                                                                   secondaryButtonText,
                                                                   onCancel,
                                                                   onSubmit
                                                               }) => {
    const {t} = useTranslation();
    const is650MaxWidth = useMediaQuery('(max-width: 650px)');
    const [disabled, setDisabled] = useState<boolean>(false);

    const submit = () => {
        setDisabled(prev => !prev);

        setTimeout(() => {
            onSubmit();

            setDisabled(prev => !prev);
        }, 500);
    }

    return (
        <Dialog id={id}
                show={show}
                setShow={setShow}
                width={is650MaxWidth ? '450px' : '550px'}>
            <main className={cl.container} data-type={type.toString().toLowerCase()}>
                <section className={cl.contentRow}>
                    <div>
                        {type === 'Alert'
                            ? <FontAwesomeIcon className={cl.icon} icon={faTriangleExclamation}/>
                            : <FontAwesomeIcon className={cl.icon} icon={faCircleExclamation}/>
                        }
                    </div>
                    <div>
                        <h1>
                            {title}
                        </h1>
                        <p>
                            {text}
                        </p>
                    </div>
                </section>
                <footer className={cl.buttonsRow}>
                    <Button buttonType={ButtonType.Primary}
                            onClick={onCancel}
                            disabled={disabled}
                            style={{
                                fontWeight: '500',
                                width: '30%',
                                backgroundColor: 'transparent',
                                border: '1px solid rgb(0,111,230)',
                                color: 'black',
                                padding: '7px'
                            }}>
                        {!secondaryButtonText ? t("shared.btn.cancel") : secondaryButtonText}
                    </Button>
                    <Button
                        buttonType={type === 'Information' ? ButtonType.Transparent : ButtonType.Error}
                        onClick={submit}
                        disabled={disabled}
                        style={type === 'Information' ?
                            {
                                fontWeight: '500',
                                width: '30%',
                                padding: '9px'
                            }
                            : {
                                fontWeight: '500',
                                width: '30%',
                                padding: '7px',
                                border: '1px solid red'
                            }}>
                        {!primaryButtonText ? t("shared.btn.confirm") : primaryButtonText}
                    </Button>
                </footer>
            </main>
        </Dialog>
    );
};

export default ConfirmationDialog;