import React, {useRef, useState} from 'react';
import GridActionRowButton from "../GridActionRowButton/GridActionRowButton";
import GridActionRowContextButtonMenu from "./GridActionRowContextButtonMenu/GridActionRowContextButtonMenu";
import {IconName} from "../../../../../../../../assets/icon/Icon";

export type GridActionRowContextButtonItem = {
    id: string;
    title: string,
    onClick: () => void;
    disabled: boolean;
    color?: string;
    wraped?: boolean
}

type GridActionRowContextButtonProps = {
    title: string;
    postIconText?: string;
    icon: IconName;
    actions: Array<GridActionRowContextButtonItem>;
}

const GridActionRowContextButton: React.FC<GridActionRowContextButtonProps> = ({
                                                                                   title,
                                                                                   icon,
                                                                                   actions,
                                                                                   postIconText
                                                                               }) => {
    const container = useRef<HTMLDivElement | null>(null);
    const [isActionsToggled, setIsActionsToggled] = useState<boolean>(false);

    if (actions.length <= 0) {
        return null;
    }

    return (
        <div ref={container} className={'relative'}>
            <GridActionRowButton id={'context-button'}
                                 title={title}
                                 icon={icon}
                                 toggle={true}
                                 isToggled={isActionsToggled}
                                 setIsToggled={setIsActionsToggled}
                                 postIconText={postIconText}
                                 onClick={() => {
                                     /*Ignore*/
                                 }}/>
            <GridActionRowContextButtonMenu isOpened={isActionsToggled}
                                            setIsOpened={setIsActionsToggled}
                                            container={container}
                                            actions={actions}
                                            onClick={() => setIsActionsToggled(false)}/>
        </div>
    );
};

export default GridActionRowContextButton;