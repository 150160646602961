import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../app/store";
import {
    selectFilterPanelConfiguration, selectPersonalFilters,
    selectPredefinedFilters
} from "../../../../features/filter/filterSliceSelectors";
import {FilterPanelConfig} from "../../../../app/types/FilterPanelConfig";
import {ResponseResultCode} from "../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {useLayoutConfigApi} from "../../../../app/api/layoutConfig";
import {
    FilterSliceEntity,
    setFilterPanelConfiguration,
    setPersonalFilters, setPredefinedFilters
} from "../../../../features/filter/filterSlice";
import {LayoutConfigEntity} from "../../../../app/enums/LayoutConfigEntity";
import {useGridFilterApi} from "../../../../app/api/gridFilter";
import {SelectModel} from "../../../../app/types/SelectModel";
import {IGridFilter} from "../../../../app/interfaces/gridFilter/IGridFilter";

type UseGridFilterPanelLayoutProps = {
    entity: LayoutConfigEntity;
}

type UseGridFilterPanelLayoutReturn = {
    disabled: boolean;
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>;

    clearCounter: number;
    onClearCounterChange: () => void;

    configuration: FilterPanelConfig;
    saveConfiguration: (config: FilterPanelConfig) => Promise<void>;

    predefinedFilters: Array<SelectModel> | null;
    personalFilters: Array<IGridFilter> | null;
}

export const useGridFilterPanelLayout = ({
                                             entity
                                         }: UseGridFilterPanelLayoutProps): UseGridFilterPanelLayoutReturn => {
        const dispatch = useAppDispatch();

        const [disabled, setDisabled] = useState<boolean>(false);
        const [clearCounter, setClearCounter] = useState<number>(0);

        const layoutConfig = useAppSelector(selectFilterPanelConfiguration);
        const configuration = JSON.parse(layoutConfig.value) as FilterPanelConfig;

        const predefinedFilters = useAppSelector(state =>
            selectPredefinedFilters(state, entity as FilterSliceEntity));

        const personalFilters = useAppSelector(state =>
            selectPersonalFilters(state, entity as FilterSliceEntity));

        const {
            saveLayoutConfig: {
                mutation
            }
        } = useLayoutConfigApi();

        const {
            getGridFilters: {
                query: getGridFilters
            },
            getPredefinedGridFilters: {
                query: getPredefinedGridFilters
            }
        } = useGridFilterApi();

        useEffect(() => {
            (async () => {
                try {
                    if (predefinedFilters !== null) {
                        return;
                    }

                    const response = await getPredefinedGridFilters(entity);

                    if (response?.data && response?.data.resultCode === ResponseResultCode.Ok) {
                        dispatch(setPredefinedFilters({
                            entity: entity as FilterSliceEntity,
                            data: response.data.data ?? []
                        }));
                    }
                } catch
                    (e) {
                    if (isAxiosError(e)) {
                        toast.error(`Unable to get ${LayoutConfigEntity[entity].toString()} predefined filters. Error: ${e.message}`);
                    }

                    dispatch(setPredefinedFilters({
                        entity: entity as FilterSliceEntity,
                        data: []
                    }));
                }
            })();

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dispatch, predefinedFilters]);

        useEffect(() => {
            (async () => {
                try {
                    if (personalFilters !== null) {
                        return;
                    }

                    const response = await getGridFilters(entity);

                    if (response?.data && response?.data.resultCode === ResponseResultCode.Ok) {
                        dispatch(setPersonalFilters({
                            entity: entity as FilterSliceEntity,
                            data: response.data.data ?? []
                        }));
                    }
                } catch
                    (e) {
                    if (isAxiosError(e)) {
                        toast.error(`Unable to get ${LayoutConfigEntity[entity].toString()} personal filters. Error: ${e.message}`);
                    }

                    dispatch(setPersonalFilters({
                        entity: entity as FilterSliceEntity,
                        data: []
                    }));
                }
            })();

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dispatch, personalFilters]);

        const saveConfiguration = useCallback(async (config: FilterPanelConfig) => {
            if (configuration.isFilterPanelShown === config.isFilterPanelShown) {
                return;
            }

            try {
                let item = {
                    ...layoutConfig,
                    value: JSON.stringify({
                        ...configuration,
                        isFilterPanelShown: config.isFilterPanelShown
                    } as FilterPanelConfig)
                };

                const response = await mutation(item);

                if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                    if (response.data.field) {
                        dispatch(setFilterPanelConfiguration({
                            ...item,
                            id: Number(response.data.field)
                        }));
                    }
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error('Unable to save filter panel state, ' + e.message);
                }
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [layoutConfig, configuration]);

        const onClearCounterChange = useCallback(() => {
            setClearCounter(prev => prev + 1);
        }, [clearCounter]);

        return {
            onClearCounterChange,
            clearCounter,

            setDisabled,
            disabled,

            configuration,
            saveConfiguration,

            predefinedFilters,
            personalFilters
        };
    }
;