import React from 'react';
import cl from './OfferAvailableTill.module.css';
import {IOffer} from "../../../../../../../../app/interfaces/offer/IOffer";
import {useAppSelector} from "../../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../../features/authentication/authenticationSliceSelectors";
import moment from "moment/moment";

type OfferAvailableTillProps = {
    offer: IOffer;
};

const OfferAvailableTill: React.FC<OfferAvailableTillProps> = ({
                                                                   offer
                                                               }) => {
    const user = useAppSelector(selectCurrentUser);

    if (!user) {
        return null;
    }

    if (offer.availableTill === null) {
        return (
            <div className={'details-box-item-field-value'}>

            </div>
        );
    }

    let isWarn = moment.utc(offer.availableTill).toDate() <= moment().utc().toDate();

    return (
        <div className={`details-box-item-field-value ${isWarn ? cl.warn : cl.info}`}>
            <span >
                {moment(offer.availableTill).format('DD.MM.YYYY')}
            </span>
        </div>
    );
};

export default OfferAvailableTill;
