import {
    UploadFileStatus,
    UseFileUploadProps,
    UseFileUploadReturn
} from "../types";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {IUploadFile} from "../types/IUploadFile";
import uuid from "react-uuid";
import {getFileUploadExtensions, processRestrictions, readFileChunk} from "../utils";
import {toast} from "react-toastify";

const CHUNK_SIZE = 1024 * 1024;

const useFileUpload = (props: UseFileUploadProps): UseFileUploadReturn => {
    const ref = useRef<HTMLInputElement | null>(null);

    const [isDragActive, setIsDragActive] = useState<boolean>(false);
    const [files, setFiles] = useState<Array<IUploadFile>>([]);

    useEffect(() => {
        if (files.length > 0 &&
            props.onChange &&
            files.filter(e => e.status === UploadFileStatus.Uploaded).length === files.length) {
            props.onChange(files);
        }
    }, [files]);

    const process = (filesToUpload: Array<File>) => {
        if (ref.current) {
            ref.current.value = '';
        }

        try {
            filesToUpload = filesToUpload.slice(0, 1);

            if (filesToUpload.length <= 0) {
                return;
            }

            let fileToUpload: IUploadFile = filesToUpload
                .slice(0, 1)
                .map(item => {
                    return {
                        id: `[${uuid()}]${item.name}`,
                        file: item,
                        name: item.name,
                        extension: getFileUploadExtensions(item.name),
                        size: item.size,
                        errors: [],
                        status: UploadFileStatus.Initial,
                        progress: 0
                    }
                })[0];

            const reader = new FileReader();
            let offset = 0;

            reader.onload = (_e) => {

                try {
                    if (offset < fileToUpload.size) {
                        offset = readFileChunk(fileToUpload.file, offset, CHUNK_SIZE, reader);
                    } else {
                        setFiles([
                            processRestrictions({
                                ...fileToUpload,
                                status: UploadFileStatus.Uploaded,
                                progress: 100
                            }, props.restrictions)
                        ]);
                    }
                } catch (err) {
                    if (err instanceof Error) {
                        toast.error<string>(`Error occurred while loading a file data. Message: ${err.message}`);
                    }
                }
            };

            reader.onerror = (_ev) => {

                setFiles([{
                    ...fileToUpload,
                    status: UploadFileStatus.UploadingFailed,
                    errors: ['One or more errors occurred while uploading this file'],
                    progress: 0,
                }]);
            }

            offset = readFileChunk(fileToUpload.file, offset, CHUNK_SIZE, reader);
        } catch (err) {
            if (err instanceof Error) {
                toast.error<string>(`Error occurred while reading a file. Message: ${err.message}`);
            }
        }
    };

    const onClear = useCallback((id: string) => {
        if (props.disabled) {
            return;
        }

        setFiles(prev => {
            let res = prev.filter(e => e.id !== id);

            if (props.onChange) {
                props.onChange(res);
            }

            return res;
        });
    }, [props.disabled, files, setFiles])

    const onBrowseClick = useCallback(() => {
        if (!ref.current || props.disabled) {
            return;
        }

        ref.current.click();
    }, [props.disabled]);

    const onDragOver = useCallback((ev: React.DragEvent<HTMLDivElement>) => {
        if (props.disabled) {
            return;
        }

        ev.preventDefault();

        if (!isDragActive) {
            setIsDragActive(true);
        }
    }, [isDragActive, setIsDragActive, props.disabled]);

    const onDragLeave = useCallback((ev: React.DragEvent<HTMLDivElement>) => {
        if (props.disabled) {
            return;
        }

        ev.preventDefault();

        if (isDragActive) {
            setIsDragActive(false);
        }
    }, [isDragActive, setIsDragActive, props.disabled]);

    const onDrop = useCallback((ev: React.DragEvent<HTMLDivElement>) => {
        if (props.disabled) {
            return;
        }

        ev.preventDefault();

        setIsDragActive(false);

        process(Array.from(ev.dataTransfer.files));
    }, [props.disabled]);

    const onInputChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        if (props.disabled) {
            return;
        }

        if (ev.target.files) {
            process(Array.from(ev.target.files));
        }
    }, [props.disabled]);

    return {
        ref,
        isDragActive,
        files,

        onClear,
        onInputChange,
        onDrop,
        onDragOver,
        onDragLeave,
        onBrowseClick
    };
};

export default useFileUpload;


/*
*
* useEffect(() => {
        if (files.length <= 0 || files.filter(e => e.status === UploadFileStatus.Initial).length <= 0) {
            return;
        }

        (async () => {
            let filesToUpload = files.filter(e => e.status === UploadFileStatus.Initial);

            if (filesToUpload.length <= 0) {
                return;
            }

            const newFiles = await Promise.all<IUploadFile>(
                filesToUpload.map(file => {
                    return new Promise((resolve, _reject) => {
                        const reader = new FileReader();
                        let offset = 0;

                        reader.onload = (_e) => {
                            if (offset < file.size) {
                                offset = readFileChunk(file.file, offset, CHUNK_SIZE, reader);
                            } else {
                                resolve(processRestrictions({
                                    ...file,
                                    status: UploadFileStatus.Uploaded,
                                    progress: 100
                                }, props.restrictions));
                            }
                        };

                        reader.onerror = (_ev) => {
                            resolve({
                                ...file,
                                status: UploadFileStatus.UploadingFailed,
                                errors: ['One or more errors occurred while uploading this file'],
                                progress: 0,
                            });
                        }

                        offset = readFileChunk(file.file, offset, CHUNK_SIZE, reader);
                    });
                })
            );

            setFiles(prev => {
                let res: Array<IUploadFile> = prev;

                newFiles.forEach(nf => {
                    let existItem = res.find(e => e.id === nf.id);

                    if (existItem) {
                        existItem.progress = nf.progress;
                        existItem.status = nf.status;
                        existItem.errors = nf.errors;
                    }
                });

                if (res.filter(e => e.status === UploadFileStatus.Uploaded).length === res.length && props.onChange) {
                    props.onChange(res);
                }

                return res.map(item => ({...item}));
            });
        })();
    }, [files]);

    const process = (filesToUpload: Array<File>) => {
        if (ref.current) {
            ref.current.value = '';
        }

        if (props.multiple === false) {
            setFiles([]);

            filesToUpload = filesToUpload.slice(0, 1);
        }

        let initialFilesToUpload: Array<IUploadFile> = filesToUpload.map(item => {
            return {
                id: `[${uuid()}]${item.name}`,
                file: item,
                name: item.name,
                extension: getFileUploadExtensions(item.name),
                size: item.size,
                errors: [],
                status: UploadFileStatus.Initial,
                progress: 0
            }
        });

        setFiles(prev => {
            if (props.multiple === true) {
                return [...prev, ...initialFilesToUpload];
            }

            return [...initialFilesToUpload];
        });
    };
* */
