import React, {useEffect, useState} from 'react';
import {DropDownList, DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {SelectModel} from "../../../../../../../app/types/SelectModel";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {useAppSelector} from "../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../app/enums/UserRole";
import {ResponseResultCode} from "../../../../../../../app/enums/ResponseResultCode";
import { useDataHelperApi } from '../../../../../../../app/api/dataHelper';

type EditTransportLoadSelectorProps = {
    disabled: boolean;
    isLoadingPrefilledValue: boolean;
    load: string | null;
    shipmentGid: string,
    data: Array<SelectModel>;
    onChange: (ev: DropDownListChangeEvent) => void;
}

const EditTransportLoadSelector: React.FC<EditTransportLoadSelectorProps> = ({
                                                                                           load,
                                                                                           onChange,
                                                                                           disabled,
                                                                                           shipmentGid,
                                                                                           data,
                                                                                           isLoadingPrefilledValue
                                                                                       }) => {
    //const [data, setData] = useState<Array<SelectModel>>([]);
    const user = useAppSelector(selectCurrentUser);
    const {
        getLoadTree: {
            query: getLoadTree,
            isLoading,
            cancel
        }
    } = useDataHelperApi();

    // useEffect(() => {

    //     (async () => {
    //         try {
    //             const response = await getLoadTree(shipmentGid);
    //             //const response = await getLoadTree("AAG.20240620-0007");

    //             if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok &&
    //                 response.data?.data) {
    //                 setData(response.data.data);
    //             }
    //         } catch (e) {
    //             if (isAxiosError(e)) {
    //                 toast.error(`Unable to get load tree: ${e.message}`);
    //             }
    //         }
    //     })();

    //     return () => {
    //         cancel();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <DropDownList data={data}
                      className={'edit-transport-load-value'}
                      disabled={disabled || isLoading || !user}
                      loading={isLoading || !user || isLoadingPrefilledValue}
                      textField="text"
                      dataItemKey="value"
                      value={load !== null ? data.find(e => e.value === load) : data.find(e => e.value === "")}
                      onChange={onChange}
        />
    );
};

export default EditTransportLoadSelector;