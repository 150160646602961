import React from 'react';
import {IChangeRequisitesGetAction} from "./interfaces/IChangeRequisitesGetAction";
import {IChangeRequisitesPostAction} from "./interfaces/IChangeRequisitesPostAction";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import {RadioButton} from "@progress/kendo-react-inputs";
import cl from './ChangeRequisites.module.css';
import {DropDownList} from "@progress/kendo-react-dropdowns";
import * as Yup from "yup";
import {UploadFileInfo} from "@progress/kendo-react-upload";
import FileUpload from "../../../../../components/ui/FileUpload/FileUpload";

type ChangeRequisitesProps = {
    model: IChangeRequisitesGetAction;
    onChange: (model: IChangeRequisitesPostAction) => void;
    close: () => void;
};

const isSubmitDisabled = (values: IChangeRequisitesPostAction): boolean => {
    if (values.mode === 'OwnBankAccount') {
        return values.account === undefined;
    }

    if (values.mode === 'NewRequisites') {
        return values.file === undefined;
    }

    return true;
}

const ChangeRequisites: React.FC<ChangeRequisitesProps> = ({
                                                               model,
                                                               onChange,
                                                               close
                                                           }) => {
    const {t} = useTranslation();
    //const [files, setFiles] = React.useState<Array<UploadFileInfo>>([]);

    const form = useFormik<IChangeRequisitesPostAction>({
        initialValues: {
            mode: model.isNewBankAccount ? 'OwnBankAccount' : 'NewRequisites',
            account: undefined,
            file: undefined
        },
        validationSchema: Yup.object().shape({
            account: Yup.object().nullable().when('mode', {
                is: (mode: 'OwnBankAccount' | 'NewRequisites') => mode === 'OwnBankAccount',
                then: () => Yup.object().nullable().required(t("shared.errors.required-error")
                    .replace('{0}', t("self-billing.labels.bank-account"))),
                otherwise: () => Yup.object().nullable()
            }),
            file: Yup.mixed().nullable().when('mode', {
                is: (mode: 'OwnBankAccount' | 'NewRequisites') => mode === 'NewRequisites',
                then: () => Yup.mixed().nullable().required(t("self-billing.labels.requisite-file-warning")),
                otherwise: () => Yup.mixed().nullable()
            }),
        }),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {
            if (values.mode === 'OwnBankAccount' && values.account) {
                onChange({
                    ...values,
                    account: {
                        ...values.account,
                        description: 'ADDED'
                    }
                });
            } else {
                onChange(values);
            }

            close();
        }
    });

    function onSaveRequest(
        files: UploadFileInfo[],
        _options: { formData: FormData; requestOptions: any },
        _onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
    ): Promise<{ uid: string }> {
        const currentFile = files[0] as UploadFileInfo;
        const uid = currentFile.uid;

        return new Promise<{ uid: string }>((resolve, reject) => {
            if (
                currentFile.validationErrors &&
                currentFile.validationErrors.length > 0
            ) {
                reject({uid: uid});
            } else {
                let file = currentFile.getRawFile!();

                form.setFieldValue('file', file);

                resolve({uid: uid});
            }
        });
    }

    function onRemoveRequest(files: UploadFileInfo[]): Promise<{ uid: string }> {
        const currentFile = files[0] as UploadFileInfo;
        const uid = currentFile.uid;

        return new Promise<{ uid: string }>((resolve) => {
            form.setFieldValue('file', undefined);

            resolve({uid: uid});
        });
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                <form id={'add-requisites-form'}
                      onSubmit={form.handleSubmit}>
                    <div className={'pd-10'}>
                        <div className={`mgb-10 radio-buttons-container ${cl.radio}`}>
                            <div>
                                <RadioButton name={`mode`}
                                             value={`OwnBankAccount`}
                                             size={'small'}
                                             checked={form.values.mode === 'OwnBankAccount'}
                                             label={t("self-billing.labels.bank-account")}
                                             onChange={async () => {
                                                 await form.setFieldValue('mode', 'OwnBankAccount');
                                                 await form.setFieldValue('file', undefined);
                                                 //setFiles([]);
                                             }}/>
                            </div>

                            <div>
                                <RadioButton name={`mode`}
                                             value={`NewRequisites`}
                                             size={'small'}
                                             checked={form.values.mode === 'NewRequisites'}
                                             label={t("self-billing.labels.requisites-no-options")}
                                             onChange={async () => {
                                                 await form.setFieldValue('mode', 'NewRequisites');
                                                 await form.setFieldValue('account', undefined);
                                             }}/>
                            </div>
                        </div>

                        <div className={'mgb-10'}>
                            {form.values.mode === 'OwnBankAccount' &&
                                <div className={'drop-down-picker'}>
                                    <label className={'view-label required'}>
                                        {t("self-billing.labels.bank-account")}
                                    </label>

                                    <DropDownList data={model.bankAccounts}
                                                  disabled={model.bankAccounts.length <= 0}
                                                  textField="text"
                                                  dataItemKey="value"
                                                  value={form.values.account !== undefined
                                                      ? model.bankAccounts.find(e => e.value === form.values.account?.value)
                                                      : null}
                                                  onChange={(ev) => form.setFieldValue('account', ev.value)}
                                    />

                                    {form.errors.account &&
                                        <div className={'error-label'}>
                                            {form.errors.account}
                                        </div>
                                    }
                                </div>
                            }

                            {form.values.mode === 'NewRequisites' &&
                                <div className={'file-upload'}>
                                    <label className={'view-label required'}>
                                        {t("self-billing.labels.requisite-file")}
                                    </label>

                                    <FileUpload id={'change-requisites-file-uploader'}
                                                selectButtonText={t("self-billing.labels.attach-requisite-file")}
                                                /*multiple={false}*/
                                                disabled={false}
                                                onChange={(files) => {
                                                    form.setFieldValue('file', files.length > 0
                                                        ? files[0].file
                                                        : undefined);
                                                }}
                                                restrictions={{
                                                    allowedExtensions: [".jpg", ".jpeg", ".png", ".pdf", ".docx", ".doc"]
                                                }}
                                    />

                                    {/* <Upload batch={false}
                                            selectMessageUI={() => <>{t("self-billing.labels.attach-requisite-file")}</>}
                                            multiple={false}
                                            files={files}
                                            onAdd={(ev) => setFiles(ev.newState)}
                                            onRemove={(ev) => setFiles(ev.newState)}
                                            onProgress={(ev) => setFiles(ev.newState)}
                                            onStatusChange={(ev) => setFiles(ev.newState)}
                                            saveUrl={onSaveRequest}
                                            removeUrl={onRemoveRequest}
                                            restrictions={{
                                                allowedExtensions: [".jpg", ".jpeg", ".png", ".pdf", ".docx", ".doc"],
                                            }}
                                    />*/}

                                    {form.errors.file &&
                                        <div className={'error-label'}>
                                            {form.errors.file}
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </form>
            </div>
            <div className={'dialog-default-buttons-container'}>
                <Button buttonType={ButtonType.Transparent}
                        className={'close-button'}
                        onClick={close}>
                    {t("shared.btn.cancel")}
                </Button>
                <Button buttonType={ButtonType.Primary}
                        disabled={isSubmitDisabled(form.values)}
                        form='add-requisites-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}>
                    {t("shared.btn.confirm")}
                </Button>
            </div>
        </div>
    );
};

export default ChangeRequisites;