import {x64hash128} from '../helpers/hash';
import {load, Agent, LoadOptions, GetOptions, GetResult, hashComponents, componentsToDebugString} from './agent'
import {getComponents, Component, UnknownComponents, BuiltinComponents, SourcesToComponents} from '../index'
import {isTrident, isEdgeHTML, isChromium, isWebKit, isGecko, isDesktopSafari} from '../helpers/browser'

export {
    load,
    hashComponents,
    componentsToDebugString
};

export type {
    Agent,
    LoadOptions,
    GetOptions,
    GetResult,
    Component,
    UnknownComponents,
    BuiltinComponents
};

export default {load, hashComponents, componentsToDebugString}

export const murmurX64Hash128 = x64hash128
export {getComponents, isTrident, isEdgeHTML, isChromium, isWebKit, isGecko, isDesktopSafari};
export type { SourcesToComponents };
