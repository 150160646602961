import {HubConnection} from "@microsoft/signalr";
import {useEffect} from "react";

const useSignalRHandler = (connection: HubConnection | null,
                           methodName: string,
                           method: (...args: any[]) => void) => {
    useEffect(() => {
        if (!connection) {
            return;
        }
        connection.on(methodName, method);

        return () => {
            connection.off(methodName, method);
        };
    }, [connection, method, methodName]);
};

export default useSignalRHandler;