import React, {useEffect, useState} from 'react';
import {IUser} from "../../../../../app/interfaces/user/IUser";
import {useTranslation} from "react-i18next";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import useSelfBillingApi from "../../../../../app/api/selfBilling";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {
    ISelfBillingAddExpenseGetAction
} from "../../../../../app/interfaces/selfBilling/ISelfBillingAddExpenseGetAction";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {BLErrorCode} from "../../../../../app/enums/BLErrorCode";
import Alert from "../../../../../components/ui/Alert/Alert";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import AddExpenseFormServiceProvider from "./AddExpenseForm/AddExpenseFormServiceProvider";
import {
    ISelfBillingAddExpensePostAction
} from "../../../../../app/interfaces/selfBilling/ISelfBillingAddExpensePostAction";
import {getUserRole} from "../../../../../helpers/user";
import {UserRole} from "../../../../../app/enums/UserRole";
import AddExpenseFormForwarder from "./AddExpenseForm/AddExpenseFormForwarder";

type AddExpenseProps = {
    shipmentGid: string;
    user?: IUser;
    close?: () => void;
};

const alertStyle: React.CSSProperties = {
    margin: '5px 10px',
    fontSize: '11px',
    fontWeight: 'bold',
}

const AddExpense: React.FC<AddExpenseProps> = ({
                                                   shipmentGid,
                                                   user,
                                                   close
                                               }) => {
    const {t} = useTranslation();
    const [model, setModel] = useState<ISelfBillingAddExpenseGetAction | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError>>([]);

    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

    const {
        getAddExpenseSelfBillingData: {
            isLoading: getAddExpenseSelfBillingDataIsLoading,
            cancel: cancelGetAddExpenseSelfBillingData,
            query: getAddExpenseSelfBillingData
        },
        getAddExpenseSelfBillingDataAnonymous: {
            isLoading: getAddExpenseSelfBillingDataAnonymousIsLoading,
            cancel: cancelGetAddExpenseSelfBillingDataAnonymous,
            query: getAddExpenseSelfBillingDataAnonymous
        },
        addExpense: {
            mutation: addExpense,
            cancel: cancelAddExpense
        },
        addExpenseAnonymous: {
            mutation: addExpenseAnonymous,
            cancel: cancelAddExpenseAnonymous
        }
    } = useSelfBillingApi();


    useEffect(() => {
        if (!shipmentGid || shipmentGid === '') {
            if (close) {
                close();
            }

            return;
        }

        (async () => {
            try {
                const response = user
                    ? await getAddExpenseSelfBillingData(shipmentGid)
                    : await getAddExpenseSelfBillingDataAnonymous(shipmentGid);

                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    if (user && (getUserRole(user) === UserRole.Forwarder || getUserRole(user) === UserRole.Administrator)) {
                        if (response.data.data.selectedContractId === null || response.data.data.selectedContractId === '') {
                            setIsSubmitDisabled(true);
                        } else {
                            setIsSubmitDisabled(false);
                        }
                    } else {
                        setIsSubmitDisabled(false);
                    }

                    setModel(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error<string>(`Unable to get add expense data. Error: ${e.message}`);

                    if (close) {
                        close();
                    }
                }
            }
        })();

        return () => {
            cancelGetAddExpenseSelfBillingData();
            cancelGetAddExpenseSelfBillingDataAnonymous();
            cancelAddExpense();
            cancelAddExpenseAnonymous();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipmentGid]);

    if(!user)
        return null;

    const save = async (values: ISelfBillingAddExpensePostAction): Promise<void> => {
        if (!model) {
            return;
        }

        setDisabled(prev => !prev);

        try {
            let data: FormData = new FormData();

            data.append('shipmentGid', model.shipmentGid);
            data.append('orderReleaseGid', model.orderReleaseGid);
            data.append('bet', values.bet ?? '');
            data.append('currency', values.currency ?? '');
            data.append('notes', values.notes ?? '');
            data.append('expenseTypeId', values.expenseType.value);
            data.append('revertVat', model.revertVat ? 'true' : 'false');

            if (!user || getUserRole(user) === UserRole.ServiceProvider) {
                data.append('category', values.categoryModel.value);

                if (values.cmrFile) {
                    data.append('file', values.cmrFile);
                }
            }

            if (values.isIncorrectVatNotesShown && values.incorrectVatNotes !== null && values.incorrectVatNotes !== '') {
                data.append('incorrectVatNotes', values.incorrectVatNotes);
            }

            if (user && (getUserRole(user) === UserRole.Forwarder || getUserRole(user) === UserRole.Administrator)) {
                data.append('contractId', values.contractId ?? '');
                data.append('serviceProviderId', values.serviceProviderId ?? '');
                data.append('serviceProvider', values.serviceProvider ?? '');
            } else {
                data.append('serviceProviderId', model.initialServiceProviderId ?? '');
                data.append('serviceProvider', model.initialServiceProvider ?? '');
                data.append('contractId', model.selectedContractId ?? '');
                data.append('contract', model.contract ?? '');
            }

            const response = user
                ? await addExpense(data)
                : await addExpenseAnonymous(data);

            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={getUserRole(user) === UserRole.ServiceProvider ?  `${t("self-billing.btn.add-expense")}` : `${t("self-billing.btn.add-expense-fw")}`}/>);

                if (close) {
                    close();
                }
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();

                    toast.error(`Unable to add expense. Error: ${err.message}`);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if (!shipmentGid || shipmentGid === '') {
        return null;
    }

    if (getAddExpenseSelfBillingDataAnonymousIsLoading || getAddExpenseSelfBillingDataIsLoading || model === null) {
        return (
            <FormLoadingScreen height={`350px`} style={{padding: '5px'}}/>
        );
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                {errors.map(item => {
                    switch (item.errorCode) {
                        case BLErrorCode.InnerError:
                            return (
                                <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                    {item.description}
                                </Alert>
                            );
                        case BLErrorCode.CmrNotSupportedFormat:
                            return (
                                <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                    {t("self-billing.errors.cmr-not-supported-format")}
                                </Alert>
                            );
                        case BLErrorCode.CmrFileIsBig:
                            return (
                                <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                    {t("self-billing.errors.cmr-file-is-big")}
                                </Alert>
                            );
                        case BLErrorCode.CmrFileIsEmpty:
                            return (
                                <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                    {t("self-billing.errors.cmr-file-is-empty")}
                                </Alert>
                            );
                        case BLErrorCode.FinFileIsEmpty:
                            return (
                                <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                    {t("self-billing.errors.fin-file-is-empty")}
                                </Alert>
                            );
                    }

                    return null;
                })}

                {user && (getUserRole(user) === UserRole.Forwarder || getUserRole(user) === UserRole.Administrator)
                    ? <AddExpenseFormForwarder disabled={disabled}
                                               model={model}
                                               onSubmit={save}
                                               user={user}
                                               setIsSubmitDisabled={setIsSubmitDisabled}/>
                    : <AddExpenseFormServiceProvider model={model}
                                                     user={user}
                                                     disabled={disabled}
                                                     onSubmit={save}/>
                }
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled || isSubmitDisabled}
                        form='add-expense-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.confirm")}
                </Button>
            </div>
        </div>
    );
};

export default AddExpense;
