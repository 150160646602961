import React, {useCallback} from 'react';
import ChatDialogueSendMessageButton from "./ChatDialogueSendMessageButton/ChatDialogueSendMessageButton";
import {useChatHelperContext} from "../../../../hooks/useChatHelperContext";
import {useAppSelector} from "../../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../../features/authentication/authenticationSliceSelectors";
import useChatApi from "../../../../../../../../app/api/chat";
import {IChatMessage} from "../../../../../../../../app/interfaces/chat/IChatMessage";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import ChatDialogueGroupedMessages from "./ChatDialogueGroupedMessages/ChatDialogueGroupedMessages";
import {IGridResultResponse} from "../../../../../../../../app/interfaces/response/IGridResultResponse";

const ChatDialogue: React.FC = () => {
    const user = useAppSelector(selectCurrentUser);

    const {
        chat,
        activeChatSearchString,
        activeSearchMessage,
        sendReadCommand
    } = useChatHelperContext();

    const {
        getMessages: {
            query
        }
    } = useChatApi();

    const loadMessages = useCallback(async (usr: string, search: string, skip: number, take: number, start?: string): Promise<IGridResultResponse<IChatMessage>> => {
        try {
            const response = await query(usr, search, skip, take, start);

            if (response?.status === 200 && response.data) {
                let items = [...new Map(response.data.result.map(item => [item['id'], item])).values()]
                    .sort((a: IChatMessage, b: IChatMessage) => {
                        return ((new Date(a.date)).getTime() - (new Date(b.date)).getTime());
                    });

                let currentUserId = user?.uniqueUserValue ?? user?.idStr ?? '';

                if (currentUserId !== '') {
                    let notReadMessages = items.filter(e =>
                        e.userId !== currentUserId && !e.isRead);

                    if (notReadMessages.length > 0) {
                        sendReadCommand(usr);
                    }
                }
                return {
                    count: response.data.count,
                    result: items
                };
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(`Unable to get messages. Error: ${e.message}`);
            }
        }

        return {
            count: 0,
            result: []
        };
    }, [user]);

    if (!user || !chat) {
        return null;
    }

    return (
        <>
            <ChatDialogueGroupedMessages chatId={chat.id}
                                         activeChatSearchMessage={activeSearchMessage}
                                         loadMessages={loadMessages}
                                         activeChatSearchString={activeChatSearchString}/>

            <ChatDialogueSendMessageButton/>
        </>
    );
};

export default ChatDialogue;