import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../../../../app/store";
import GridActionRowTagsContainer, {
    GridActionRowTagItem
} from "../../../../../../../components/ui/Grid/Telerik/components/GridActionRowTagsContainer/GridActionRowTagsContainer";
import {
    selectAccessSettings,
    selectFilter,
    selectIsAccessSettingsExists, selectQuery,
    selectSelectedPersonalFilter, selectSelectedPredefinedFilter
} from "../../../../../../../features/filter/filterSliceSelectors";
import {LayoutConfigEntity} from "../../../../../../../app/enums/LayoutConfigEntity";
import {useGridLayoutContext} from "../../../../../../../components/uiLayouts/GridLayout/hooks/useGridLayoutContext";
import {
    FilterSliceEntity, setAccessSettings, setFilter,
    setFilterToApply, setSelectedPersonalFilter,
    setSelectedPredefinedFilter
} from "../../../../../../../features/filter/filterSlice";
import {GridFilter} from "../../../../../../../app/types/GridFilter";
import {IOfferHeaderConfig} from "../../../data/interfaces/IOfferHeaderConfig";
import {Query} from "../../../../../../../app/types/Query";
import {useNavigate, useParams} from "react-router-dom";
import {buildOfferQuery} from "../../../utils";
import {buildAccessQuery} from "../../../../../../../components/uiLayouts/GridLayout/context";
import {
    toggleIsAccessSettingsPanelOpened
} from "../../../../../../../features/application/applicationSlice";
import {selectCurrentUser} from "../../../../../../../features/authentication/authenticationSliceSelectors";
import {useUserApi} from "../../../../../../../app/api/user";
import {ResponseResultCode} from "../../../../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";

type OfferActionRowTagsProps = {
    availableWidth: number;
};

const OfferActionRowTags: React.FC<OfferActionRowTagsProps> = ({
                                                                   availableWidth
                                                               }) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectCurrentUser);
    const navigate = useNavigate();

    const {type} = useParams();

    const [tags, setTags] = useState<Array<GridActionRowTagItem>>([]);
    const [query, setQuery] = useState<Query | null>(null);

    const settings = useAppSelector(selectAccessSettings);
    const filterQueryParams = useAppSelector(state => selectQuery(state, LayoutConfigEntity.Offer));
    const isAccessFilterApplied = useAppSelector(selectIsAccessSettingsExists);
    const personalSelectedFilter = useAppSelector(state =>
        selectSelectedPersonalFilter(state, LayoutConfigEntity.Offer));
    const filter = useAppSelector(state =>
        selectFilter(state, LayoutConfigEntity.Offer));
    const predefinedSelectedFilter = useAppSelector(state =>
        selectSelectedPredefinedFilter(state, LayoutConfigEntity.Offer));

    const {
        headerConfig,
        saveHeaderConfig
    } = useGridLayoutContext<IOfferHeaderConfig>();

    const {
        saveAccessSettings: {
            mutation: saveAccessSettings
        }
    } = useUserApi();

    useEffect(() => {
        if (!headerConfig || !filterQueryParams) {
            return;
        }

        setQuery(buildOfferQuery(type ?? 'all', headerConfig).addParamsFromQuery(Query.ToQuery(filterQueryParams)));
    }, [headerConfig, filterQueryParams]);

    useEffect(() => {
        setTags(buildTagsList());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personalSelectedFilter, filter, t, isAccessFilterApplied, headerConfig, query]);

    const onChange = (config: IOfferHeaderConfig) => {
        (async () => {
            await saveHeaderConfig(config);
        })();
    }

    const handlePredefinedFilterChange = (ppf: string) => {
        if (!headerConfig) {
            return;
        }

        let isSelected = headerConfig.pinnedPredefinedFilter === ppf;

        onChange({
            ...headerConfig,
            pinnedPredefinedFilter: isSelected ? null : ppf
        });

        dispatch(setSelectedPredefinedFilter({
            entity: LayoutConfigEntity.Offer,
            data: isSelected ? null : {text: ppf, value: ppf}
        }));
    };

    const handleFilterClear = (field?: Array<string> | string) => {
        if (field) {
            let data = {
                ...filter
            };

            if (Array.isArray(field)) {
                let fields = field as Array<keyof GridFilter>;

                for (let i of fields) {
                    if (Array.isArray(data[i])) {
                        data[i] = [] as any;
                    } else {
                        data[i] = null as any;
                    }
                }
            } else {
                if (Array.isArray(data[field as keyof GridFilter])) {
                    data[field as keyof GridFilter] = [] as any;
                } else {
                    data[field as keyof GridFilter] = null as any;
                }
            }

            let f = {
                entity: LayoutConfigEntity.Offer as FilterSliceEntity,
                data
            };

            dispatch(setFilterToApply(f));
            dispatch(setFilter(f));
        } else {
            let f = {
                entity: LayoutConfigEntity.Offer as FilterSliceEntity,
                data: null
            };

            dispatch(setFilter(f));
            dispatch(setFilterToApply(f));

            dispatch(setSelectedPersonalFilter(f));
        }
    }

    const handleRegionFilterClear = (value: string, dir: 's' | 'd') => {
        if (value === '') {
            return;
        }

        let data = {
            ...filter
        };

        if (dir === 's') {
            data.source = data.source.filter(e => e.description2 && e.description2 !== value);
        } else if (dir === 'd') {
            data.destination = data.destination.filter(e => e.description2 && e.description2 !== value);
        }

        let f = {
            entity: LayoutConfigEntity.Offer as FilterSliceEntity,
            data
        };

        dispatch(setFilterToApply(f));
        dispatch(setFilter(f));
    };

    const buildTagsList = () => {
        let lTags: Array<GridActionRowTagItem> = [];

        if (headerConfig) {

            if (headerConfig.pinnedPredefinedFilters) {
                lTags.push(...headerConfig.pinnedPredefinedFilters.map(ppf => {
                    let q = new Query().addParamsFromQuery(query);

                    q.removeParam('f');
                    q.addParam('f', ppf);

                    q = buildAccessQuery(q, settings);

                    return {
                        id: ppf,
                        text: t(`offer.filters.pf${ppf}`),
                        showClearIcon: false,
                        isTab: true,
                        selected: headerConfig.pinnedPredefinedFilter === ppf,
                        onClick: () => handlePredefinedFilterChange(ppf),
                        countUrl: `api/${process.env.REACT_APP_API_VERSION}/offer/getOffersCount`,
                        query: q
                    }
                }));
            }

            if (headerConfig.CM) {
                lTags.push({
                    id: `offer-checkbox-filter-cm`,
                    text: t("shared.grid-header.cm"),
                    showClearIcon: false
                });
            }

            if (headerConfig.TLM) {
                lTags.push({
                    id: `offer-checkbox-filter-tlm`,
                    text: t("shared.grid-header.tlm"),
                    showClearIcon: false
                });
            }

            if (headerConfig.SMFA) {
                lTags.push({
                    id: `offer-checkbox-filter-smfa`,
                    text: t("shared.grid-header.sm-fa"),
                    showClearIcon: false
                });
            }

            if (headerConfig.OM) {
                lTags.push({
                    id: `offer-checkbox-filter-om`,
                    text: t("shared.grid-header.om"),
                    showClearIcon: false
                });
            }

            if (headerConfig.SMEXA) {
                lTags.push({
                    id: `offer-checkbox-filter-sm-exa`,
                    text: t("shared.grid-header.sm-exa"),
                    showClearIcon: false
                });
            }
        }

        if (isAccessFilterApplied) {
            lTags.push({
                id: `offer-has-access-filter-applied`,
                text: '',
                icon: "faFilter",
                tooltip: t("shared.labels.access-filter-applied-label"),
                iconColor: "var(--main-orange-color)",
                showClearIcon: true,
                onClear: () => {
                    (async () => {
                        if (!settings) {
                            return;
                        }

                        if (!user) {
                            return;
                        }

                        try {
                            let lSettings = {
                                ...settings,
                                serviceProviderName: null,
                                serviceProviderId: null,
                                forwarders: [],
                                transportLinesSource: [],
                                branches: [],
                                departments: [],
                                transportLinesDestination: []
                            };

                            const response = await saveAccessSettings(lSettings);

                            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                                let needReload = user.userAccessSettings.serviceProviderId !== null &&
                                    user.userAccessSettings.serviceProviderId !== '';

                                if (needReload) {
                                    navigate(0);
                                } else {
                                    dispatch(setAccessSettings(lSettings));

                                    document.dispatchEvent(new CustomEvent('onGridMessage', {
                                        detail: {
                                            action: 'refresh'
                                        }
                                    }));

                                    document.dispatchEvent(new CustomEvent('onAccessSettingsUpdate', {
                                        detail: {
                                            data: lSettings
                                        }
                                    }));
                                }
                            }
                        } catch (e) {
                            if (isAxiosError(e)) {
                                toast.error('Unable to clear access settings');
                            }
                        }
                    })();
                },
                onClick: () => dispatch(toggleIsAccessSettingsPanelOpened())
            });
        }

        if (predefinedSelectedFilter) {
            if (!headerConfig || headerConfig.pinnedPredefinedFilters?.length == 0 ||
                !headerConfig.pinnedPredefinedFilters?.includes(predefinedSelectedFilter.value))
                lTags.push({
                    id: `offer-predefined-filter-tag`,
                    text: t(`offer.filters.pf${predefinedSelectedFilter.value}`),
                    showClearIcon: true,
                    onClear: () => {
                        dispatch(setSelectedPredefinedFilter({
                            entity: LayoutConfigEntity.Offer,
                            data: null
                        }));

                        if(headerConfig) {
                            onChange({
                                ...headerConfig,
                                pinnedPredefinedFilter: null
                            });
                        }
                    }
                });
        }

        if (personalSelectedFilter) {
            lTags.push({
                id: `offer-selected-personal-filter-tag-${personalSelectedFilter.id}`,
                text: personalSelectedFilter.name,
                showClearIcon: true,
                onClear: () => handleFilterClear()
            });
            return lTags;
        }

        if (filter.orderReleaseNumber !== null && filter.orderReleaseNumber !== '') {
            lTags.push({
                id: `offer-order-release-filter-tag`,
                text: `${t("offer.filter.number")}: ${filter.orderReleaseNumber}`,
                showClearIcon: true,
                onClear: () => handleFilterClear('orderReleaseNumber')
            });
        }

        if (filter.customerRefNumber !== null && filter.customerRefNumber !== '') {
            lTags.push({
                id: `offer-load-customer-ref-number-filter-tag`,
                text: `${t("offer.filter.load-customer-ref-num")}: ${filter.customerRefNumber}`,
                showClearIcon: true,
                onClear: () => handleFilterClear('customerRefNumber')
            });
        }

        if (filter.pickupDateFrom || filter.pickupDateTo) {
            let text = ` `;

            if (filter.pickupDateFrom && filter.pickupDateTo) {
                text += `${t("shared.labels.date-from-label")} ${filter.pickupDateFrom} ${t("shared.labels.date-to-label")} ${filter.pickupDateTo}`;
            } else if (filter.pickupDateFrom) {
                text += `${t("shared.labels.date-from-label")} ${filter.pickupDateFrom}`;
            } else if (filter.pickupDateTo) {
                text += `${t("shared.labels.date-to-label")} ${filter.pickupDateTo}`;
            }

            lTags.push({
                id: 'offer-pickup-date-filter-tag',
                text,
                icon: "faArrowRight",
                iconColor: "#e96024",
                showClearIcon: true,
                onClear: () => handleFilterClear(['pickupDateFrom', 'pickupDateTo'])
            });
        }

        if (filter.deliveryDateFrom || filter.deliveryDateTo) {
            let text = ` `;

            if (filter.deliveryDateFrom && filter.deliveryDateTo) {
                text += `${t("shared.labels.date-from-label")} ${filter.deliveryDateFrom} ${t("shared.labels.date-to-label")} ${filter.deliveryDateTo}`;
            } else if (filter.deliveryDateFrom) {
                text += `${t("shared.labels.date-from-label")} ${filter.deliveryDateFrom}`;
            } else if (filter.deliveryDateTo) {
                text += `${t("shared.labels.date-to-label")} ${filter.deliveryDateTo}`;
            }

            lTags.push({
                id: 'offer-delivery-date-filter-tag',
                text,
                showClearIcon: true,
                icon: "faArrowLeft",
                iconColor: "#7FB98B",
                onClear: () => handleFilterClear(['deliveryDateFrom', 'deliveryDateTo'])
            });
        }


        if (filter.source.length > 0) {
            let sourceCountries = [
                ...new Set(filter.source.filter(e => e.description2 && e.description2 !== '').map(s => s.description2))
            ];

            for (let sCountry of sourceCountries) {
                if (!sCountry) {
                    continue;
                }

                lTags.push({
                    id: `offer-source-tag-country-${sCountry}`,
                    text: ` ${sCountry}`,
                    showClearIcon: true,
                    icon: "faArrowRight",
                    iconColor: "#e96024",
                    onClear: () => handleRegionFilterClear(sCountry ?? '', 's')
                });
            }
        }

        if (filter.destination.length > 0) {
            let destinationCountries = [
                ...new Set(filter.destination.filter(e => e.description2 && e.description2 !== '').map(s => s.description2))
            ];

            for (let dCountry of destinationCountries) {
                if (!dCountry) {
                    continue;
                }

                lTags.push({
                    id: `offer-destination-tag-country-${dCountry}`,
                    text: ` ${dCountry}`,
                    showClearIcon: true,
                    icon: "faArrowLeft",
                    iconColor: "#e96024",
                    onClear: () => handleRegionFilterClear(dCountry ?? '', 'd')
                });
            }
        }

        if (filter.supplier.length > 0) {
            lTags.push({
                id: `offer-supplier-tag`,
                text: `${t("offer.filter.supplier")}: ${filter.supplier[0].text} ${filter.supplier.length > 1 ? `(+${filter.supplier.length - 1})` : ''}`,
                showClearIcon: true,
                onClear: () => handleFilterClear('supplier')
            });
        }

        if (filter.status.length > 0) {
            lTags.push({
                id: `offer-status-tag`,
                text: `${t("offer.filter.otm-status")}: ${filter.status[0]} ${filter.status.length > 1 ? `(+${filter.status.length - 1})` : ''}`,
                showClearIcon: true,
                onClear: () => handleFilterClear('status')
            });
        }

        if (filter.serviceProvider.length > 0) {
            lTags.push({
                id: `offer-service-provider-tag`,
                text: `${t("offer.filter.service-provider")}: ${filter.serviceProvider[0].text} ${filter.serviceProvider.length > 1 ? `(+${filter.serviceProvider.length - 1})` : ''}`,
                showClearIcon: true,
                onClear: () => handleFilterClear('serviceProvider')
            });
        }

        if (filter.equipment.length > 0) {
            lTags.push({
                id: `offer-equipment-tag`,
                text: `${filter.equipment[0].text} ${filter.equipment.length > 1 ? `(+${filter.equipment.length - 1})` : ''}`,
                showClearIcon: true,
                onClear: () => handleFilterClear('equipment')
            });
        }
        if (filter.tags.length > 0) {
            lTags.push({
                id: `shipment-tags-tag`,
                text: `${filter.tags[0].text} ${filter.tags.length > 1 ? `(+${filter.tags.length - 1})` : ''}`,
                showClearIcon: true,
                onClear: () => handleFilterClear('tags')
            });
        }

        if (filter.jobOrderRelease !== null && filter.jobOrderRelease !== '') {
            lTags.push({
                id: `offer-job-order-release-filter`,
                text: `${t("offer.filter.job-order-release")}: ${filter.jobOrderRelease}`,
                showClearIcon: true,
                onClear: () => handleFilterClear('jobOrderRelease')
            });
        }
        return lTags;
    }

    return (
        <GridActionRowTagsContainer id={'offer-grid-filter-tags-container'}
                                    tags={tags}
                                    availableWidth={availableWidth}/>
    );
};

export default OfferActionRowTags;
