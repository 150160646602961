import React, {useState} from 'react';
import cl from './ShipmentAddressTemplate.module.css';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {useTranslation} from "react-i18next";
import LocationMapDialog from "../../../actions/LocationMap/LocationMapDialog";
import Icon from "../../../../../../assets/icon/Icon";

const ShipmentAddressTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const {t} = useTranslation();
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);

    let sId = props.dataItem.sId !== undefined && props.dataItem.sId !== null
        ? props.dataItem.sId
        : '';
    let shipmentAddress = props.dataItem["shipmentAddress"] !== undefined && props.dataItem["shipmentAddress"] !== null
        ? props.dataItem["shipmentAddress"]
        : '';
    let isUnConfirmed = props.dataItem["isUnconfirm"] !== null && props.dataItem["isUnconfirm"] !== undefined
        ? Boolean(props.dataItem["isUnconfirm"])
        : false;
    let isArchive = props.dataItem.isArchive !== null && props.dataItem.isArchive !== undefined
        ? Boolean(props.dataItem.isArchive)
        : false;

    if (!sId || sId === '' || isUnConfirmed || isArchive) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={'default-left-cell default-overflow-cell'}>
                    {shipmentAddress}
                </span>
            </td>
        )
    }


    return (
        <td {...props.tdProps}
            colSpan={1}>
            <LocationMapDialog shipmentGid={sId}
                               show={isDialogShown}
                               setShow={setIsDialogShown}/>
            <span onClick={handleClick} className={'default-left-cell default-overflow-cell grid-prevent-selection'}>
                <>


                <Icon icon={"faPen"}
                      className={`${cl.icon} ${shipmentAddress && shipmentAddress !== '' ? cl.iconWithValue : ''} shipment-grid-edit-shipment-address-btn grid-prevent-selection`}
                      onClick={(ev) => {
                          ev.stopPropagation();

                          setIsDialogShown(prev => !prev);
                      }}/>
                    {shipmentAddress && shipmentAddress !== ''
                        ? <span className={`grid-prevent-selection ${cl.text}`}
                                onClick={(ev) => {
                                    ev.stopPropagation();

                                    setIsDialogShown(prev => !prev);
                                }}>
                        {shipmentAddress}
                    </span>
                        : <span className={`grid-prevent-selection ${cl.addText}`}
                                onClick={(ev) => {
                                    ev.stopPropagation();

                                    setIsDialogShown(prev => !prev);
                                }}>
                        {t("shipment.grid.edit-shipment-address-position")}
                    </span>
                    }
                </>
            </span>
        </td>
    );
};

export default ShipmentAddressTemplate;
