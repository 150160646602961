import React, {useEffect, useMemo, useRef, useState} from 'react';
import {IShipment} from "../../../../app/interfaces/shipment/IShipment";
import {Circle, CircleProps, GoogleMap, Marker, MarkerProps, useLoadScript} from "@react-google-maps/api";
import cl from './FormMap.module.css';
import FormLoadingScreen from "../../../ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {
    buildCirclesArray,
    buildCurrentPositionMarker,
    buildCurrentTrackingPositionMarker,
    buildMarkersArray, getBoundsToMarkers
} from "./utils";
import AdvancedMarker, {AdvancedMarkerProps} from "../../../ui/GoogleMap/AdvancedMarker/AdvancedMarker";
import {useTranslation} from "react-i18next";
import {defaultMapOptions, useLoadScriptOptions} from "../../../ui/GoogleMap/utils/map";

type FormMapProps = {
    model: IShipment;
}

const FormMap: React.FC<FormMapProps> = ({
                                             model
                                         }) => {
    const {i18n} = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [language, setLanguage] = useState<string>(i18n.language);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (language !== i18n.language) {
            window.google.maps = {} as any;

            setLoading(prev => !prev);

            setLanguage(i18n.language);

            timeout = setTimeout(() => {
                setLoading(prev => !prev);
            }, 1000);
        }

        return () => {
            clearTimeout(timeout);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    if (loading) {
        return (
            <div className={cl.container}>
                <FormLoadingScreen/>
            </div>
        );
    }

    return <Map model={model} language={i18n.language}/>;
};

export default FormMap;

const Map: React.FC<FormMapProps & {
    language: string
}> = ({model, language}) => {
    const map = useRef<google.maps.Map | null>(null);
    const [advancedMarkers, setAdvancedMarkers] = useState<Array<AdvancedMarkerProps>>([]);
    const [markers, setMarkers] = useState<Array<MarkerProps>>([]);
    const [circles, setCircles] = useState<Array<CircleProps>>([]);
    const {isLoaded} = useLoadScript({
        ...useLoadScriptOptions,
        language: language,
    });

    const options = useMemo((): google.maps.MapOptions =>
        (defaultMapOptions), []);

    const onMapLoad = (mapRef: google.maps.Map) => {
        map.current = mapRef;

        let markers = buildMarkersArray(model);
        let circles = buildCirclesArray(model);

        if (!model.isCompleted) {
            if (model.hasTracking) {
                if (model.isStarted) {
                    let currentTrackingPositionMarker = buildCurrentTrackingPositionMarker(model);
                    if (currentTrackingPositionMarker !== null) {
                        setAdvancedMarkers([currentTrackingPositionMarker]);
                    }
                }
            } else {
                let currentPositionMarker = buildCurrentPositionMarker(model);
                if (currentPositionMarker !== null) {
                    markers.push(currentPositionMarker);
                }
            }
        }

        setMarkers(markers);
        setCircles(circles);

        const bounds = getBoundsToMarkers(markers);

        map.current?.fitBounds(bounds);
    }

    if (!isLoaded) {
        return (
            <div className={cl.container}>
                <FormLoadingScreen/>
            </div>
        );
    }

    return (
        <div className={cl.container}>
            <GoogleMap mapContainerClassName="h100"
                       options={options}
                       onLoad={onMapLoad}>
                {markers.map((marker, index) => {
                    return (
                        <Marker key={index} {...marker} />
                    );
                })}
                {circles.map((circle, index) => {
                    return (
                        <Circle key={index} {...circle} />
                    );
                })}
                {advancedMarkers.map((marker, index) => {
                    if (map.current === null) {
                        return null;
                    }

                    return (
                        <AdvancedMarker {...marker} key={index} map={map.current}>
                            {marker.children}
                        </AdvancedMarker>
                    );
                })}
            </GoogleMap>
        </div>
    );
}