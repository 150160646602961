import React from 'react';
import {IQuote} from "../../../../../../app/interfaces/quote/IQuote";
import QuoteDetailsBox from "../../components/QuoteDetailsBox/QuoteDetailsBox";
import {useTranslation} from "react-i18next";
import RouteSection from "../../../../../../components/shared/sections/RouteSection/RouteSection";
import FormCollapse from "../../../../../../components/ui/Collapse/FormCollapse";
import QuoteRemarks from "../../components/QuoteRemarks/QuoteRemarks";
import QuoteCargo from "../../components/QuoteCargo/QuoteCargo";
import QuoteNettoBetList from "../../components/QuoteNettoBetList/QuoteNettoBetList";
import {OrderReleaseStatus} from "../../../../../../app/enums/OrderReleaseStatus";
import QuoteMap from "../../components/QuoteMap/QuoteMap";
import {RemarkName} from "../../../../../../app/enums/RemarkName";
import QuoteFullScreenDetailsBox from "../../components/QuoteDetailsBox/QuoteFullScreenDetailsBox";
import useMediaQuery from "../../../../../../hooks/useMediaQuery/useMediaQuery";
import RouteSideSection from "../../../../../../components/shared/sections/RouteSection/RouteSideSection";

type QuoteDetailsSectionProps = {
    quote: IQuote;
    isSidePanelDetails: boolean;
}

const QuoteDetailsSection: React.FC<QuoteDetailsSectionProps> = ({
                                                                     quote,
                                                                     isSidePanelDetails
                                                                 }) => {
    const {t} = useTranslation();
    const isMaxWidth960 = useMediaQuery('(max-width: 960px)');
    const isMaxWidth600 = useMediaQuery('(max-width: 600px)');
    return (
        <div id={'quote-details-section'}>

            {isSidePanelDetails || isMaxWidth600 ?
                <QuoteDetailsBox quote={quote} isSidePanelDetails={isSidePanelDetails}/>
            : null}

            <div className={'form-layout-container'}
                 data-count={'2'}
                 data-separator={'true'}>
                <div className={'form-layout-column'}>
                    {isSidePanelDetails || isMaxWidth600 ?
                        null
                        : <QuoteFullScreenDetailsBox quote={quote} isSidePanelDetails={isSidePanelDetails}/>}

                    <FormCollapse title={t("quote.form.cargo-section")}>
                        <QuoteCargo quote={quote}/>
                    </FormCollapse>

                    {(quote.remarks.length > 0 || quote.qRemarks.length > 0)
                        ? <FormCollapse title={t("quote.form.remarks-section")}>
                            <QuoteRemarks quote={quote}/>
                        </FormCollapse>
                        : null
                    }

                    <FormCollapse title={t("quote.form.route-section")}>
                        <div className={'details-box-default-container'}>
                            <RouteSideSection model={{
                                orderType: quote.orderType,
                                orderReleaseGid: quote.orderReleaseGid,
                                earlyDeliveryDate: quote.earlyDeliveryDate,
                                earlyPickupDateText: quote.earlyPickupDateText,
                                earlyPickupDate: quote.earlyPickupDate ?? null,
                                latePickupDate: quote.latePickupDate ?? null,
                                lateDeliveryDate: quote.lateDeliveryDate,
                                lateDeliveryDateText: quote.lateDeliveryDateText,
                                hasShipment: false,
                                shipmentGid: null,
                                stopPoints: quote.stopPoints ?? [],
                                sourceCountry: quote.sourceCountry,
                                sourceCity: quote.sourceCity,
                                sourceCountryCode2: quote.sourceCountryCode2,
                                sourceProvinceCode: quote.sourceProvinceCode,
                                sourceProvinceDesc: quote.sourceProvinceDesc,
                                destinationCountry: quote.destinationCountry,
                                destinationCity: quote.destinationCity,
                                destinationCountryCode2: quote.destinationCountryCode2,
                                destinationProvinceCode: quote.destinationProvinceCode,
                                destinationProvinceDesc: quote.destinationProvinceDesc,
                                transportMode: quote.transportMode,
                                product: quote.product,
                                equipment: quote.equipment,
                                equipmentGroupName: quote.equipmentGroupName,
                                incoterms: quote.incoterms,
                                volumePerYear: quote.volumePerYear,
                                quantity: quote.quantity,
                                quantityBatch: quote.quantityBatch
                            }}/>
                        </div>

                        {quote.status !== OrderReleaseStatus.Confirmed &&
                        quote.lt !== null && quote.lt !== 0 &&
                        quote.lg !== null && quote.lg !== 0
                            ? <QuoteMap quote={quote}/>
                            : null
                        }
                    </FormCollapse>


                </div>

                <div className={'form-layout-column-separator'}></div>

                <div className={'form-layout-column'}>
                    <QuoteNettoBetList gid={quote.orderReleaseGid} isSidePanelDetails={isSidePanelDetails}
                                       quote={quote}/>
                </div>
            </div>
        </div>
    );
};

export default QuoteDetailsSection;
