import React from 'react';
import {IProfileGetAction} from "../../../../../app/interfaces/profile/IProfileGetAction";
import {IProfilePostAction} from "../../../../../app/interfaces/profile/IProfilePostAction";
import {FormikHelpers, useFormik} from "formik";
import Alert from "../../../../../components/ui/Alert/Alert";
import {useTranslation} from "react-i18next";
import cl from './ProfileForm.module.css';
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {useProfileApi} from "../../../../../app/api/profile";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {useAppSelector} from "../../../../../app/store";
import {selectCurrentUser} from "../../../../../features/authentication/authenticationSliceSelectors";
import {UserRole} from "../../../../../app/enums/UserRole";
import Input from "../../../../../components/ui/Input/Input";
import {
    faBuilding,
    faEnvelope,
    faKey,
    faMobile,
    faPhone,
    faRightToBracket,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";

type ProfileFormProps = {
    disabled: boolean;
    model: IProfileGetAction;
    close?: () => void;
    save: (model: IProfilePostAction) => Promise<void>;
}

const alertStyle: React.CSSProperties = {
    fontSize: '11px',
    fontWeight: 'bold',
    padding: '10px 5px',
    margin: '10px 15px',
    width: 'fit-content'
}

const ProfileForm: React.FC<ProfileFormProps> = ({
                                                     disabled,
                                                     model,
                                                     save,
                                                     close
                                                 }) => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    const {
        resendConfirmationEmail: {
            query
        }
    } = useProfileApi();

    const form = useFormik<IProfilePostAction>({
        initialValues: {
            email: model.email,
            mobPhone: model.mobPhone,
            phone: model.phone,
            serviceProvider: model.serviceProvider,
            serviceProviderId: model.serviceProviderId,
            language: model.language
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email(t("profile.support.invalid-email"))
                .required(t("shared.errors.required-error").replace('{0}', t("profile.labels.email")))
        }),
        onSubmit: async (values: IProfilePostAction, actions: FormikHelpers<IProfilePostAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    const saveForm = async (): Promise<void> => {
        // form.actions.setSubmitting(true);

        await save(form.values);

        //actions.setSubmitting(false);
    }

    return (
        <>
            <div className={'dialog-default-content-container'}>
                <form id={'profile-form'}
                      onSubmit={form.handleSubmit}>
                    {user && user.role !== UserRole.Forwarder && model.isNewEmailNeedConfirmation &&
                        <Alert type={'YellowInfo'} style={alertStyle}>
                            <div className={cl.alert}>
                                <div>
                                    {t("profile.labels.change-email-confirm-warning")}
                                </div>
                                <div>
                                    <button className={cl.alertBtn}
                                            type={'button'}
                                            onClick={async (ev) => {
                                                ev.preventDefault();

                                                if (disabled) {
                                                    return;
                                                }

                                                try {
                                                    const response = await query();

                                                    if (response?.status === 200 && response?.data.resultCode === ResponseResultCode.Ok) {
                                                        toast.success(t("profile.labels.confirmation-email-sent-label"));
                                                    }
                                                } catch (e) {
                                                    if (isAxiosError(e)) {
                                                        toast.error(`Unable to send confirmation email. Error: ${e.message}`);
                                                    }
                                                }
                                            }}>
                                        {t("profile.labels.send-again")}
                                    </button>
                                </div>
                            </div>
                        </Alert>
                    }

                    <div className={'pd-10'}>
                        {/*   <div className={`view-row mgb-10 ${cl.btnContainer}`}>*/}
                        {/*          <LanguageSwitcher languages={languages}
                                      languageIconPosition={'right'}
                                      disabled={disabled}
                                      value={form.values.language.toUpperCase() as unknown as Language}
                                      change={(lng: Language) => {
                                          form.setFieldValue('language', Language[lng].toString().toLowerCase());
                                      }}/>*/}
                        {/*  <Button buttonType={ButtonType.Primary}
                            type={'submit'}
                            disabled={disabled}
                            style={{minWidth: '130px'}}
                            form={'profile-form'}>
                        {t("shared.btn.save")}
                    </Button>*/}
                        {/*       </div>*/}

                        <div className={`${cl.container}`}>
                            <div>
                                <div className={`view-row ${cl.row}`}>
                                    <label className={'view-label profile-login-label'}>
                                        {t("profile.labels.login")}
                                    </label>

                                    <Input placeholder={t("profile.labels.login")}
                                           icon={faRightToBracket}
                                           id={'profile-login-value'}
                                           name={'login'}
                                           disabled={true}
                                           value={model.login ?? ''}
                                           autoComplete={'off'}
                                           onChange={form.handleChange}
                                           className={'profile-login-value'}/>
                                </div>

                                <div className={`view-row ${cl.row}`}>
                                    <label className={'view-label profile-name-label'}>
                                        {t("profile.labels.name")}
                                    </label>

                                    <Input placeholder={t("profile.labels.name")}
                                           icon={faUser}
                                           id={'profile-name-value'}
                                           name={'name'}
                                           disabled={true}
                                           value={model.name ?? ''}
                                           autoComplete={'off'}
                                           onChange={form.handleChange}
                                           className={'profile-name-value'}/>
                                </div>

                                {user && (user.role === UserRole.Client || user.role === UserRole.ServiceProvider)
                                    ? <div className={`view-row ${cl.row}`}>
                                        <label className={'view-label profile-service-provider-label'}>
                                            {t("profile.labels.service-provider")}
                                        </label>

                                        <Input placeholder={t("profile.labels.service-provider")}
                                               icon={faBuilding}
                                               id={'profile-service-provider-value'}
                                               name={'serviceProvider'}
                                               disabled={true}
                                               value={form.values.serviceProvider ?? ''}
                                               autoComplete={'off'}
                                               onChange={form.handleChange}
                                               className={'profile-service-provider-value'}/>
                                    </div>
                                    : null
                                }

                                {user && user.role === UserRole.Forwarder &&
                                    <div className={`view-row ${cl.row}`}>
                                        <label className={'view-label profile-department-label'}>
                                            {t("profile.labels.department")}
                                        </label>

                                        <Input placeholder={t("profile.labels.department")}
                                               icon={faBuilding}
                                               id={'profile-department-value'}
                                               name={'department'}
                                               disabled={true}
                                               value={model.department ?? ''}
                                               autoComplete={'off'}
                                               onChange={form.handleChange}
                                               className={'profile-department-value'}/>
                                    </div>
                                }

                                {user && user.role === UserRole.Forwarder &&
                                    <div className={`view-row ${cl.row}`}>
                                        <label className={'view-label profile-departments-access-label'}>
                                            {t("profile.labels.departments-access")}
                                        </label>

                                        <Input placeholder={t("profile.labels.departments-access")}
                                               icon={faKey}
                                               id={'profile-departments-access-value'}
                                               name={'departmentsAccess'}
                                               disabled={true}
                                               value={model.departmentsAccess ?? ''}
                                               autoComplete={'off'}
                                               onChange={form.handleChange}
                                               className={'profile-departments-access-value'}/>
                                    </div>
                                }
                            </div>

                            <div>
                                <div className={`view-row ${cl.row}`}>
                                    <label className={'view-label required profile-email-label'}>
                                        {t("profile.labels.email")}
                                    </label>

                                    <Input placeholder={t("profile.labels.email")}
                                           icon={faEnvelope}
                                           id={'profile-email-value'}
                                           name={'email'}
                                           disabled={disabled}
                                           value={form.values.email ?? ''}
                                           autoComplete={'off'}
                                           onChange={form.handleChange}
                                           className={'profile-email-value'}/>

                                    {form.errors.email &&
                                        <div className={'error-label'}>
                                            {form.errors.email}
                                        </div>
                                    }
                                </div>

                                <div className={`view-row ${cl.row}`}>
                                    <label className={'view-label profile-mobile-phone-label'}>
                                        {t("profile.labels.mobile-phone")}
                                    </label>

                                    <Input placeholder={t("profile.labels.mobile-phone")}
                                           icon={faMobile}
                                           id={'profile-mobile-phone-value'}
                                           name={'mobPhone'}
                                           disabled={disabled}
                                           value={form.values.mobPhone ?? ''}
                                           autoComplete={'off'}
                                           onChange={form.handleChange}
                                           className={'profile-mobile-phone-value'}/>
                                </div>

                                <div className={`view-row ${cl.row}`}>
                                    <label className={'view-label profile-phone-label'}>
                                        {t("profile.labels.phone")}
                                    </label>

                                    <Input placeholder={t("profile.labels.phone")}
                                           icon={faPhone}
                                           id={'profile-phone-value'}
                                           name={'phone'}
                                           disabled={disabled}
                                           value={form.values.phone ?? ''}
                                           autoComplete={'off'}
                                           onChange={form.handleChange}
                                           className={'profile-phone-value'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className={'dialog-default-buttons-container'}>

                <Button buttonType={ButtonType.Transparent}
                        disabled={disabled}
                        className={'close-button'}
                        onClick={close}>
                    {t("shared.btn.cancel")}
                </Button>

                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        form='contact-support-form'
                        type='submit'
                        onClick={saveForm}
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.save")}
                </Button>
            </div>
        </>
    );
};

export default ProfileForm;
