import React, {useContext} from 'react';
import Icon, {IconName} from "../../../../../assets/icon/Icon";
import cl from './SidebarButton.module.css';
import {SidebarContext} from "../../Sidebar";

type SidebarButtonProps = {
    id: string;
    type: 'primary' | 'secondary';
    text: string;
    title: string;
    icon?: IconName;
    active?: boolean;
    onClick?: () => void;
    disabled?: boolean;
    iconColor?: string;
    chevron?: 'up' | 'down';
    countBubble?: number;
};

const SidebarButton = React.forwardRef<HTMLDivElement, SidebarButtonProps>(({
                                                                                id,
                                                                                type,
                                                                                icon,
                                                                                title,
                                                                                text,
                                                                                onClick,
                                                                                disabled,
                                                                                active,
                                                                                iconColor,
                                                                                chevron,
                                                                                countBubble
                                                                            }, ref) => {
    const {
        hoverExpanded,
        expanded
    } = useContext(SidebarContext);

    return (
        <div id={id}
             ref={ref}
             data-type={type}
             title={title}
             className={`relative ${cl.button} ${disabled ? cl.disabled : ''} ${active ? cl.active : ''}`}
             onClick={onClick}>
            <div className={`${cl.icon} ${active ? cl.active : ''}`}
                 style={iconColor ? {
                     color: iconColor
                 } : undefined}>
                {icon ? <Icon icon={icon} /> : <span style={{width:20}}></span>}
            </div>

            <div className={`prevent-selection ${cl.text} ${active ? cl.active : ''}`}
                 data-display={chevron ? 'flex' : 'default'}
                 data-expanded={hoverExpanded || expanded ? 'expanded' : 'condensed'}>
                {text}

                {chevron &&
                    <Icon icon={"faThinChevronUp"}
                          className={cl.chevron}
                          flip={chevron === 'up' ? 'horizontal' : 'vertical'}/>
                }
            </div>

            {hoverExpanded || expanded
                ? <>
                    {countBubble !== undefined && countBubble > 0 &&
                        <div className={`messages-bubble chat-sidebar-button-messages-bubble ${cl.bubble}`}>
                            {countBubble >= 100
                                ? <>99+</>
                                : <>{countBubble}</>
                            }
                        </div>
                    }
                </>
                : <>
                    {countBubble !== undefined && countBubble > 0 &&
                        <div className={`messages-bubble chat-sidebar-button-messages-bubble ${cl.shortbubble}`}>
                            {countBubble >= 100
                                ? <>99+</>
                                : <>{countBubble}</>
                            }
                        </div>
                    }
                </>
            }
        </div>
    );
});

export default SidebarButton;
