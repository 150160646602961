import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'circleArrowRight',
    pathData: [
        'M19.9999 26.6666L26.6666 20M26.6666 20L19.9999 13.3333M26.6666 20H13.3333M36.6666 20C36.6666 29.2047 29.2047 36.6666 19.9999 36.6666C10.7952 36.6666 3.33325 29.2047 3.33325 20C3.33325 10.7952 10.7952 3.33331 19.9999 3.33331C29.2047 3.33331 36.6666 10.7952 36.6666 20Z'
    ],
    viewBox: '0 0 40 40',
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
    strokeWidth: '1'
};

export default icon;