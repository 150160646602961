import React from 'react';
import cl from './ShipmentsEventListItem.module.css';
import {ExtendedEvent} from "../ShipmentsEventList";
import ShipmentsEventListItemHeader from "./ShipmentsEventListItemHeader/ShipmentsEventListItemHeader";
import ShipmentsEventListItemProgressBar from "./ShipmentsEventListItemProgressBar/ShipmentsEventListItemProgressBar";
import {IError} from "../../../../../../app/interfaces/response/IResponse";
import {getShipmentEventListItemError} from "../utils";
import {useTranslation} from "react-i18next";
import Alert from '../../../../../../components/ui/Alert/Alert';

type ShipmentsEventListItemProps = {
    event: ExtendedEvent;
    showRemoveButton: boolean;
    remove: (gid: string) => void;
    change: (event: ExtendedEvent) => void;
    disabled: boolean;
    errors: Array<IError>;
}

const ShipmentsEventListItem: React.FC<ShipmentsEventListItemProps> = ({
                                                                           event,
                                                                           showRemoveButton,
                                                                           remove,
                                                                           change,
                                                                           disabled,
                                                                           errors
                                                                       }) => {
    const t = useTranslation();

    if (!event.shipmentEvents || event.shipmentEvents.length <= 0) {
        return null;
    }

    let errorStrings = getShipmentEventListItemError(event, errors, t);

    return (
        <div className={cl.item}>
            <ShipmentsEventListItemHeader event={event}
                                          disabled={disabled}
                                          showRemoveButton={showRemoveButton}
                                          remove={remove}
                                          change={change}/>
            {errorStrings.length > 0 &&
                <Alert type={'Error'}>
                    <div>
                        {errorStrings.map((error, index) => {
                            let oneError: boolean = errorStrings.length <= 1;
                            return (
                                <div key={index}
                                     className={cl.error}>
                                    {oneError
                                        ? <>{error}</>
                                        : <>{`${index + 1}) ${error}`}</>
                                    }
                                </div>
                            );
                        })}
                    </div>
                </Alert>
            }
            <ShipmentsEventListItemProgressBar event={event}
                                               change={change}
                                               disabled={disabled}/>
        </div>
    )
};

export default ShipmentsEventListItem;