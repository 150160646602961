import React, {useEffect, useState} from 'react';
import {useShipmentApi} from "../../../../../../../app/api/shipment";
import {IShipment} from "../../../../../../../app/interfaces/shipment/IShipment";
import Loader, {LoaderType} from "../../../../../../../components/ui/loaders/Loader/Loader";
import {ResponseResultCode} from "../../../../../../../app/enums/ResponseResultCode";
import EventsList from "./components/EventList/EventsList";

type EventProps = {
    id: string;
    sId: string;
}

const Event: React.FC<EventProps> = ({id, sId}) => {
    const [shipment, setShipment] = useState<IShipment | null | undefined>();
    const {
        getShipmentWithEvents: {
            query,
            isLoading
        }
    } = useShipmentApi();

    useEffect(() => {
        if (id === '' || sId === '') {
            return;
        }

        (async () => {
            try {
                const response = await query(id, sId);
                if (response && response.status === 200 && response.data &&
                    response.data.resultCode === ResponseResultCode.Ok && response.data.data) {
                    setShipment(response.data.data);
                } else {
                    setShipment(null);
                }
            } catch {
                setShipment(null);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, sId]);

    if (isLoading || shipment === undefined) {
        return (
            <div className={'w100 relative details-box-default-container'} style={{height: '350px'}}>
                <div className={'action-loader-container'}>
                    <div>
                        <Loader type={LoaderType.Action} style={{scale: '0.4'}}/>
                    </div>
                </div>
            </div>
        )
    }

    if (shipment === null) {
        return null;
    }

    return (
        <div className={'details-box-default-container'}>
            <EventsList model={shipment}/>
        </div>
    );
};

export default Event;