import React from 'react';
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import cl from './Select.module.css';

type SelectOption = {
    value: string;
    label: string;
}

type SelectProps = {
    disabled: boolean;
    image?: any;
    icon?: IconProp;
    options: Array<SelectOption>;
} & React.HTMLProps<HTMLSelectElement>;

const Select: React.FC<SelectProps> = ({
                                           disabled,
                                           image,
                                           icon,
                                           className,
                                           options,
                                           ...rest
                                       }) => {
   /* if (image !== undefined || icon !== undefined) {
        return (
            <div className={cl.container}>
                <span className={cl.iconContainer}>
                    {icon !== undefined && image === undefined
                        ? <FontAwesomeIcon icon={icon}/>
                        : null
                    }
                    {icon === undefined && image !== undefined
                        ? <img src={image} alt={'icon'}/>
                        : null
                    }
                </span>
                <select className={`${cl.select} ${className}`} disabled={disabled} {...rest}>
                    {options.map(opt => {
                        return (
                            <option value={opt.value}
                                    key={opt.value}>
                                {opt.label}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    } else {*/
        return (
            <select className={`${cl.select} ${className}`} disabled={disabled} {...rest}>
                {options.map(opt => {
                    return (
                        <option value={opt.value}
                                key={opt.value}>
                            {opt.label}
                        </option>
                    );
                })}
            </select>
        );
    /*}*/
};

export default Select;
