import React from 'react';
import {IChangePassword} from "../../../../../app/interfaces/account/IChangePassword";
import {useTranslation} from "react-i18next";
import cl from './ChangePasswordForm.module.css';
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import LoginInput from "../../../../ui/LoginInput/LoginInput";
import {faCheck, faLock, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export type PasswordRule = {
    id: string;
    name: string;
    isSatisfy: (val: string) => boolean;
};

type ChangePasswordFormProps = {
    id: string;
    disabled: boolean;
    save: (model: IChangePassword) => Promise<void>;
    rules: Array<PasswordRule>;
};

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
                                                                   id,
                                                                   rules,
                                                                   save,
                                                                   disabled
                                                               }) => {
    const {t} = useTranslation();

    const form = useFormik<IChangePassword>({
        initialValues: {
            password: '',
            passwordConfirmation: '',
            code: ''
        },
        validationSchema: Yup.object({
            password: Yup.string().nullable().required(t("shared.errors.required-error")
                .replace('{0}', t("account.password.new-password"))),
            passwordConfirmation: Yup.string().nullable()
                .oneOf([Yup.ref('password'), null], t("account.password.password-must-match"))
                .required(t("shared.errors.required-error")
                    .replace('{0}', t("account.password.password-confirmation")))
        }),
        onSubmit: async (values: IChangePassword, actions: FormikHelpers<IChangePassword>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id={id}
              onSubmit={form.handleSubmit}>
            <div className={`pd-10 ${cl.container}`}>
                {rules.length > 0 &&
                    <>
                        <div className={cl.rulesLabel}>
                            {t("account.password.password-need-follow-rules-text")}
                        </div>

                        <div className={`${cl.rules}`}>
                            {rules.map(rule => {
                                let isSatisfy = !form.values.password
                                    ? false
                                    : rule.isSatisfy(form.values.password);

                                return (
                                    <div key={rule.id}
                                         id={`rule-${rule.id}`}
                                         className={cl.rule}>
                                        <div>
                                            <FontAwesomeIcon icon={isSatisfy ? faCheck : faXmark}
                                                             className={`${cl.icon} ${isSatisfy ? cl.green : cl.red}`}/>
                                        </div>
                                        <div>
                                            {rule.name}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                }

                <div className={`view-row ${cl.row}`}>
                    <LoginInput icon={faLock}
                                type='password'
                                placeholder={t("account.password.new-password") ?? ''}
                                id="password"
                                name="password"
                                autoComplete="off"
                                disabled={form.isSubmitting || disabled}
                                onChange={form.handleChange}
                                value={form.values.password ?? ''}
                                error={form.touched.password && form.errors.password ? form.errors.password : undefined}/>
                </div>

                <div className={`view-row ${cl.row}`}>
                    <LoginInput icon={faLock}
                                type='password'
                                placeholder={t("account.password.password-confirmation") ?? ''}
                                id="passwordConfirmation"
                                name="passwordConfirmation"
                                autoComplete="off"
                                disabled={form.isSubmitting || disabled}
                                onChange={form.handleChange}
                                value={form.values.passwordConfirmation ?? ''}
                                error={form.touched.passwordConfirmation && form.errors.passwordConfirmation ? form.errors.passwordConfirmation : undefined}/>
                </div>
            </div>
        </form>
    );
};

export default ChangePasswordForm;
