export function countTruthy(values: unknown[]): number {
    return values.reduce<number>((sum, value) => sum + (value ? 1 : 0), 0)
}

export function includes<THaystack>(haystack: ArrayLike<THaystack>, needle: unknown): needle is THaystack {
    for (let i = 0, l = haystack.length; i < l; ++i) {
        if (haystack[i] === needle) {
            return true
        }
    }
    return false
}

export function excludes<THaystack, TNeedle>(
    haystack: ArrayLike<THaystack>,
    needle: TNeedle,
): needle is Exclude<TNeedle, THaystack> {
    return !includes(haystack, needle)
}