import React from 'react';
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo, faComment} from "@fortawesome/free-solid-svg-icons";
import cl from '../../NotificationForm/NotificationForm.module.css';
import {INotificationGetAction} from "../../../../../../../app/interfaces/notification/INotificationGetAction";
import Alert from "../../../../../../../components/ui/Alert/Alert";
import Checkbox from "../../../../../../../components/ui/Checkbox/Checkbox";
import PhoneNumberInput from "../../../../../../../components/ui/PhoneNumberInput/PhoneNumberInput";
import {IPhoneFormat} from "../../../../../../../app/interfaces/shared/IPhoneFormat";
import {Tooltip} from "react-tooltip";

type MessengerNotificationSectionProps = {
    disabled: boolean;
    model: INotificationGetAction;
    acceptAgreement: boolean;
    onAcceptAgreementChange: (val: boolean) => void;
    phoneNumber: string | null;
    phoneCode: string | null;
    phoneError?: string;
    onPhoneCodeChange: (format: IPhoneFormat) => void;
    onPhoneNumberChange: (val: string | null) => void;
};

const MessengerNotificationSection: React.FC<MessengerNotificationSectionProps> = ({
                                                                                       model,
                                                                                       acceptAgreement,
                                                                                       onAcceptAgreementChange,
                                                                                       disabled,
                                                                                       phoneNumber,
                                                                                       phoneCode,
                                                                                       phoneError,
                                                                                       onPhoneCodeChange,
                                                                                       onPhoneNumberChange
                                                                                   }) => {
    const {t} = useTranslation();
    let show = false;

    return (
        <div>
            <div className={'default-form-collapse-header'}>
                <div className={cl.collapse}>
                    <div>
                       {/* <div className={cl.icon}>
                            <FontAwesomeIcon icon={faComment}/>
                        </div>*/}
                        <div>
                            {t("notification.labels.setup-messengers-notifications-label")}
                        </div>
                    </div>
                </div>
            </div>

            <div style={{padding: '5px 5px 0 5px'}}>
                {model.mobilePhone === null || model.mobilePhone === '' || model.isNewNumberForSave
                    ? <Alert type={'Error'}
                             style={{
                                 fontSize: '12px',
                                 fontWeight: 'bold',
                                 padding: '10px',
                                 margin: '15px 0'
                             }}>
                        <div>
                            <div>
                                {t("notification.labels.subscription-alert-1")}
                            </div>
                            <div>
                                {t("notification.labels.subscription-alert-2")}
                            </div>
                            <div>
                                {t("notification.labels.subscription-alert-3")}
                            </div>
                        </div>
                    </Alert>
                    : null
                }

                {show &&
                    <>
                        <div className={`mgb-10 ${cl.checkboxRow}`}>
                            <Checkbox checked={true}
                                      title={t("notification.labels.notify-new-free-offers")}
                                      change={() => {
                                          //ignore
                                      }}
                                      disabled={true}/>

                            <label className={'view-label'}>
                                {t("notification.labels.notify-new-free-offers")}
                                <span>
                            <Tooltip id={`messengers-notify-new-free-offers`}
                                     style={{maxWidth: '400px', zIndex: '1004'}}
                                     place={'right'}/>
                            <FontAwesomeIcon data-tooltip-id={`messengers-notify-new-free-offers`}
                                             data-tooltip-html={t("notification.labels.notify-new-free-offers-tooltip")}
                                             icon={faCircleInfo}
                                             className={cl.tooltip}/>
                        </span>
                            </label>
                        </div>

                        <div className={`mgb-10 ${cl.checkboxRow}`}>
                            <Checkbox checked={true}
                                      title={t("notification.labels.notify-new-fix-rate-offers")}
                                      change={() => {
                                          //ignore
                                      }}
                                      disabled={true}/>

                            <label className={'view-label'}>
                                {t("notification.labels.notify-new-fix-rate-offers")}
                                <span>
                            <Tooltip id={`messengers-notify-new-free-offers`}
                                     style={{maxWidth: '400px', zIndex: '1004'}}
                                     place={'right'}/>
                            <FontAwesomeIcon data-tooltip-id={`messengers-notify-new-free-offers`}
                                             data-tooltip-html={t("notification.labels.notify-new-fix-rate-offers-tooltip")}
                                             icon={faCircleInfo}
                                             className={cl.tooltip}/>
                        </span>
                            </label>
                        </div>
                    </>
                }

                <div className={'mgb-10'}>
                    <label className={`view-label`}>
                        {t("shipment.grid.mobile-label")}
                    </label>

                    <PhoneNumberInput disabled={disabled} showIcon={false}
                                      phoneCode={phoneCode}
                                      phoneNumber={phoneNumber}
                                      onPhoneCodeChange={(val) => {
                                          onPhoneCodeChange(val);
                                      }}
                                      onPhoneNumberChange={(val) => {
                                          onPhoneNumberChange(val);
                                      }}
                    />

                    {phoneError &&
                        <div className={'error-label'}>
                            {phoneError}
                        </div>
                    }
                </div>

                <Alert type={'BrightGrayInfo'}
                       style={{
                           fontSize: '12px',
                           fontWeight: 'bold',
                           padding: '10px',
                           margin: '15px 0'
                       }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px'
                    }}>
                        <span>
                            <Checkbox checked={acceptAgreement}
                                      change={onAcceptAgreementChange}
                                      disabled={disabled}/>
                        </span>
                        <div>
                            <span>
                                {t("notification.labels.agreement-1")}
                            </span>
                            <span>
                                <a href={t("notification.labels.agreement-2-url")}
                                   className={cl.link}
                                   target={'_blank'}
                                   style={{
                                       margin: '0 2px'
                                   }}>
                                    {t("notification.labels.agreement-2")}
                                </a>
                            </span>
                            <span>
                                {t("notification.labels.agreement-3")}
                            </span>
                        </div>
                    </div>
                </Alert>
            </div>
        </div>
    );
};

export default MessengerNotificationSection;
