import React, {useState} from 'react';
import {IUser} from "../../../../../../../app/interfaces/user/IUser";
import {ISelfBilling} from "../../../../../../../app/interfaces/selfBilling/ISelfBilling";
import {useTranslation} from "react-i18next";
import cl from './SelfBillingExpenseSection.module.css';
import SelfBillingExpenseList from "../../components/SelfBillingExpenseList/SelfBillingExpenseList";
import AddExpenseDialog from "../../../../actions/AddExpense/AddExpenseDialog";
import FormCollapse from "../../../../../../../components/ui/Collapse/FormCollapse";
import Icon from "../../../../../../../assets/icon/Icon";
import {getUserRole} from "../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../app/enums/UserRole";

type SelfBillingExpenseSectionProps = {
    isAnonymousAccess: boolean;
    isSidePanelDetails?: boolean;
    user?: IUser;
    model: ISelfBilling;
};

const SelfBillingExpenseSection: React.FC<SelfBillingExpenseSectionProps> = ({
                                                                                 model,
                                                                                 isAnonymousAccess,
                                                                                 user,
                                                                                 isSidePanelDetails = false
                                                                             }) => {
    const {t} = useTranslation();

    const [isAddExpenseDialogShown, setIsAddExpenseDialogShown] = useState<boolean>(false);

    let isCompleted = model.invoices.length > 0 ;//expenses.filter(e => e.ecmStatus !== null && e.ecmStatus === EcmStatus.Completed).length === model.expenses.length;

    let title: string | null = isCompleted
        ? t("self-billing.labels.account-registered")
        : t("self-billing.labels.account-registration");

    if(!model.isCmrAllowedForSp)
        title = null ;

    return (
        <>
            <AddExpenseDialog shipmentGid={model.shipmentGid}
                              show={isAddExpenseDialogShown}
                              setShow={setIsAddExpenseDialogShown}
                              user={user}/>

            <FormCollapse defaultExpanded={!isCompleted}
                          title={title}
                          button={
                              <button className={cl.button}
                                      onClick={(ev) => {
                                          ev.stopPropagation();

                                          setIsAddExpenseDialogShown(prev => !prev);
                                      }}>
                                  <Icon icon={"faPlus"}/>
                                  {!user || getUserRole(user) === UserRole.ServiceProvider ? t("self-billing.btn.add-expense") : t("self-billing.btn.add-expense-fw")}
                              </button>
                          }>
                <SelfBillingExpenseList model={model}
                                        isSidePanelDetails={isSidePanelDetails}
                                        isAnonymousAccess={isAnonymousAccess}
                                        user={user}/>
            </FormCollapse>
        </>
    );
};

export default SelfBillingExpenseSection;
