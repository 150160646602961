import React from 'react';
import {IOffer} from "../../../../../../../../app/interfaces/offer/IOffer";
import transEu from '../../../../../../../../assets/images/trans-eu.png';
import {ResponseResultCode} from "../../../../../../../../app/enums/ResponseResultCode";
import {useOfferApi} from "../../../../../../../../app/api/offer";
import {useTranslation} from "react-i18next";

type OfferTransEUProps = {
    offer: IOffer;
};

const OfferTransEu: React.FC<OfferTransEUProps> = ({
                                                       offer
                                                   }) => {
    const {t} = useTranslation();

    const {
        getTransEULink: {
            query: getTransEULink
        }
    } = useOfferApi();

    if (offer.freightId === null || offer.freightId === '') {
        return null;
    }

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center'
        }}>
            <img src={transEu}
                 alt={'trans-eu'}
                 title={t("shared.labels.trans-eu")}
                 style={{
                     width: '27px',
                     marginLeft: '5px',
                     cursor: 'pointer',
                     borderRadius: '2px'
                 }}
                 onClick={async (ev) => {
                     if (!offer.freightId) {
                         return;
                     }

                     ev.stopPropagation();

                     try {
                         const response = await getTransEULink(offer.freightId);

                         if (response && response.data && response.data.resultCode === ResponseResultCode.Ok &&
                             response.data.data) {
                             window.open(response.data.data, '_blank');
                         }
                     } catch {
                         //ignore
                     }
                 }}/>
        </div>
    );
};

export default OfferTransEu;