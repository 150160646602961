import React from 'react';
import {OrderType} from "../../../../../../app/enums/OrderType";
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../ui/Dialog/Dialog";
import AssociateTag from "./AssociateTag";

type AssociateTagDialogProps = {
    orderReleaseGid: string;
    orderType: OrderType;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const AssociateTagDialog: React.FC<AssociateTagDialogProps> = ({
                                                                   orderReleaseGid,
                                                                   orderType,
                                                                   show,
                                                                   setShow
                                                               }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'associate-tag-dialog'}
                show={show}
                setShow={setShow}
                width={'750px'}
                title={t("shared.btn.add-tag")}>
            <AssociateTag orderReleaseGid={orderReleaseGid}
                          orderType={orderType}
                          close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default AssociateTagDialog;