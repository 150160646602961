import React, {useState} from 'react';
import SupplierDataDialog from "./SupplierDataDialog/SupplierDataDialog";
import cl from './SupplierData.module.css';
import {IUserData} from "../../../../../app/interfaces/user/IUserData";
import {useGridCellHandleClick} from "../../../../ui/Grid/Telerik/hooks/useGridCellHandleClick";

type SupplierDataProps = {
    user: Partial<IUserData>;
    style?: React.CSSProperties;
};

const SupplierData: React.FC<SupplierDataProps> = ({

                                                       user,
                                                       style,

                                                   }) => {
    const [isDataDialogShown, setIsDataDialogShown] = useState<boolean>(false);

    return (
        <>
            {user.id &&
                <SupplierDataDialog ids={user.id}
                                    show={isDataDialogShown}
                                    setShow={setIsDataDialogShown}/>
            }

            <div className={`${cl.container} transition-all-500 user-link`}
                 style={style}
                 onClick={(ev) => {
                     ev.stopPropagation();

                     setIsDataDialogShown(prev => !prev);
                 }}>
                <span >{user.name}</span>
            </div>
        </>
    );
};

export default SupplierData;
