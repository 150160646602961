import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import DeclineTransports from "./DeclineTransports";

type DeclineTransportsDialogProps = {
    id: string | Array<string>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const DeclineTransportsDialog: React.FC<DeclineTransportsDialogProps> = ({
                                                                             id,
                                                                             show,
                                                                             setShow
                                                                         }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'decline-transports-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={Array.isArray(id) && id.length > 1
                    ? t("shared.btn.decline-transportations")
                    : t("shared.btn.decline-transportation")}>
            <DeclineTransports id={id}
                               close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default DeclineTransportsDialog;