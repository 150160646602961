import React from 'react';
import Loader, {LoaderType} from "../Loader/Loader";
import cl from './FormLoadingScreen.module.css';

type FormLoadingScreenProps = {
    height?: string;
    style?: React.CSSProperties;
    title?: string;
}

const FormLoadingScreen: React.FC<FormLoadingScreenProps> = ({
                                                                 height = '100%',
                                                                 style,
                                                                 title
                                                             }) => {
    let hasTitle = title !== null && title !== undefined && title !== '';
    return (
        <div className={`w100`} style={{
            height: height,
            ...style
        }}>
            <div className={`action-loader-container ${cl.container} ${hasTitle ? cl.hasTitle : ''}`}>
                <div>
                    <Loader type={LoaderType.Action} style={{scale: '0.4'}}/>
                </div>
                {hasTitle && <b>{title}</b>}
            </div>
        </div>
    );
};

export default FormLoadingScreen;