import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import cl from "../../AccessSettings/AccessSettings.module.css";
import GridFilterPanelLayoutCollapse
    from "../../../../uiLayouts/GridFilterPanelLayout/components/GridFilterPanelLayoutCollapse/GridFilterPanelLayoutCollapse";
import ListBox from "../../../../ui/ListBox/ListBox";
import {SelectModel} from "../../../../../app/types/SelectModel";
import Icon from "../../../../../assets/icon/Icon";
import FilterPanelQuoteStatusFilterSelectedContainer from "./FilterPanelQuoteStatusFilterSelectedContainer";

type FilterPanelQuoteStatusFilterProps = {
    id: string;
    disabled?: boolean;
    onClear: (value?: string) => void;
    onChange: (value: Array<string>) => void,
    value: Array<string>;
}

const FilterPanelQuoteStatusFilter: React.FC<FilterPanelQuoteStatusFilterProps> = ({
                                                                                       value,
                                                                                       disabled = false,
                                                                                       onClear,
                                                                                       onChange
                                                                                   }) => {
    const {t} = useTranslation();

    const [statuses] = useState<Array<SelectModel>>([
        {text: "PR_NEW", value: "PR_NEW"},
        {text: "PROCESSING", value: "PROCESSING"},
        {text: "CONSIDERING", value: "CONSIDERING"},
        {text: "PROCESSED", value: "PROCESSED"},
        {text: "ANSWER_EXPIRED", value: "ANSWER_EXPIRED"},
        {text: "PR_ISSUED", value: "PR_ISSUED"},
        {text: "TO_CLARIFY", value: "TO_CLARIFY"}
    ]);

    const [filteredStatuses, setFilteredStatuses] = useState<Array<SelectModel>>(statuses);

    const onFilter = (value: string | null) => {
        if (!value || value === '') {
            setFilteredStatuses([...statuses]);
        } else {
            setFilteredStatuses([
                ...statuses.filter(item => {
                    return item.text.toLowerCase().includes(value.toLowerCase());
                })
            ]);
        }
    }

    return (
        <GridFilterPanelLayoutCollapse titleBold={false}
                                       defaultExpanded={false}
                                       showSeparationHeaderLine={false}
                                       hideOnFilterPanelLayoutContextClear={true}
                                       style={{margin: '5px 0 10px 0'}}
                                       tagsContainer={<FilterPanelQuoteStatusFilterSelectedContainer
                                           id={'quote-status-filter-tags'}
                                           value={value}
                                           onClear={onClear}/>}
                                       title={
                                           <>
                                               <div>
                                                   {t("quote.grid.otm-status")}
                                               </div>
                                               <div>
                                                   {value.length > 0
                                                       ? <Icon icon={"faTrash"}
                                                               className={cl.trash}
                                                               onClick={(ev) => {
                                                                   ev.stopPropagation();

                                                                   if (disabled) {
                                                                       return;
                                                                   }

                                                                   onClear();
                                                               }}/>
                                                       : null
                                                   }
                                               </div>
                                           </>
                                       }>
            <div>
                <ListBox id={'quote-statuses-list-box'}
                         selected={value
                             ? value.map(item => {
                                 return {
                                     text: item,
                                     value: item
                                 }
                             })
                             : []}
                         disabled={disabled}
                         data={filteredStatuses}
                         isLoading={false}
                         onFilter={onFilter}
                         height={250}
                         selection={'Multiple'}
                         onSelectionChange={(items) => {
                             onChange(items.map(item => item.value));
                         }}/>
            </div>
        </GridFilterPanelLayoutCollapse>
    );
};

export default FilterPanelQuoteStatusFilter;