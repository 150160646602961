import React from 'react';
import {ISupportGetAction} from "../../../../../app/interfaces/profile/ISupportGetAction";
import {ISupportPostAction} from "../../../../../app/interfaces/profile/ISupportPostAction";
import {FormikHelpers, useFormik} from "formik";
import Textarea from "../../../../../components/ui/Textarea/Textarea";
import {useTranslation} from "react-i18next";
import Input from "../../../../../components/ui/Input/Input";
import * as Yup from "yup";

type ProfileSupportFormProps = {
    disabled: boolean;
    model: ISupportGetAction;
    save: (value: ISupportPostAction) => Promise<void>;
};

const ProfileSupportForm: React.FC<ProfileSupportFormProps> = ({
                                                                   disabled,
                                                                   model,
                                                                   save
                                                               }) => {
    const {t} = useTranslation();

    const form = useFormik<ISupportPostAction>({
        initialValues: {
            login: model.login,
            email: model.email,
            phone: model.phone,
            message: null
        },
        validationSchema: Yup.object().shape({
            login: Yup.string().required(t("shared.errors.required-error").replace('{0}', t("profile.support.login"))),
            email: Yup.string().email(t("profile.support.invalid-email")).required(t("shared.errors.required-error").replace('{0}', t("profile.support.email"))),
            phone: Yup.string().required(t("shared.errors.required-error").replace('{0}', t("profile.support.phone"))),
            message: Yup.string().required(t("shared.errors.required-error").replace('{0}', t("profile.support.message"))),
        }),
        onSubmit: async (values: ISupportPostAction, actions: FormikHelpers<ISupportPostAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id={'contact-support-form'}
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div className={'mgb-20'}>
                    <label className={'view-label required contact-support-login-label'}>
                        {t("profile.support.login")}
                    </label>
                    <Input placeholder={t("profile.support.login")}
                           id={'contact-support-login-value'}
                           name={'login'}
                           disabled={true}
                           value={form.values.login ?? ''}
                           autoComplete={'off'}
                           onChange={form.handleChange}
                           className={'contact-support-login-value'}/>

                    {!form.values.login && form.errors.login &&
                        <div className={'error-label'}>
                            {form.errors.login}
                        </div>
                    }
                </div>

                <div className={'mgb-20'}>
                    <label className={'view-label required contact-support-email-label'}>
                        {t("profile.support.email")}
                    </label>
                    <Input placeholder={t("profile.support.email")}
                           id={'contact-support-email-value'}
                           name={'email'}
                           disabled={disabled}
                           value={form.values.email ?? ''}
                           autoComplete={'off'}
                           onChange={form.handleChange}
                           className={'contact-support-email-value'}/>

                    {form.errors.email &&
                        <div className={'error-label'}>
                            {form.errors.email}
                        </div>
                    }
                </div>

                <div className={'mgb-20'}>
                    <label className={'view-label required contact-support-phone-label'}>
                        {t("profile.support.phone")}
                    </label>
                    <Input placeholder={t("profile.support.phone")}
                           id={'contact-support-phone-value'}
                           name={'phone'}
                           disabled={disabled}
                           value={form.values.phone ?? ''}
                           autoComplete={'off'}
                           onChange={form.handleChange}
                           className={'contact-support-phone-value'}/>

                    {!form.values.phone && form.errors.phone &&
                        <div className={'error-label'}>
                            {form.errors.phone}
                        </div>
                    }
                </div>

                <div className={'mgb-10'}>
                    <label className={'view-label required contact-support-message-label'}>
                        {t("profile.support.message")}
                    </label>
                    <Textarea disabled={disabled}
                              className={'contact-support-message-value'}
                              style={{minHeight: '100px', resize: 'vertical', maxHeight: '150px'}}
                              value={form.values.message ?? ''}
                              placeholder={t("profile.support.message")}
                              onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                  form.setFieldValue('message', ev.target.value);
                              }}/>

                    {!form.values.message && form.errors.message &&
                        <div className={'error-label'}>
                            {form.errors.message}
                        </div>
                    }
                </div>
            </div>
        </form>
    );
};

export default ProfileSupportForm;