import {ColumnModel} from "../components/ui/Grid/Telerik/types/ColumnModel";

export const compareColumnModel = (a: ColumnModel, b: ColumnModel) => {
    // @ts-ignore
    if (a.name < b.name) {
        return -1;
    } else { // @ts-ignore
        if (a.name > b.name) {
            return 1;
        }
    }
    // a must be equal to b
    return 0;
}

