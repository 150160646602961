import React, {useState} from 'react';
import {IUser} from "../../../../../../app/interfaces/user/IUser";
import {
    IAddCustomerAttachmentGetAction
} from "../../../../../../app/interfaces/customerAttachment/IAddCustomerAttachmentGetAction";
import {
    IAddCustomerAttachmentPostAction
} from "../../../../../../app/interfaces/customerAttachment/IAddCustomerAttachmentPostAction";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {SelectModel} from "../../../../../../app/types/SelectModel";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import * as Yup from "yup";
import cl from './AddCustomerAttachmentForm.module.css';
import Textarea from "../../../../../../components/ui/Textarea/Textarea";
import FileUpload from "../../../../../../components/ui/FileUpload/FileUpload";
import { CustomerAttachmentCategory } from '../../../../../../app/enums/CustomerAttachmentCategory';

type AddCustomerAttachmentFormProps = {
    disabled: boolean;
    user?: IUser;
    model: IAddCustomerAttachmentGetAction;
    onSubmit: (values: IAddCustomerAttachmentPostAction) => Promise<void>;
};

const AddCustomerAttachmentForm: React.FC<AddCustomerAttachmentFormProps> = ({
                                                                                         model,        
                                                                                         onSubmit,
                                                                                         disabled,
                                                                                         user
                                                                                     }) => {
    const {t} = useTranslation();    

    const [documentСategories] = useState<Array<SelectModel>>(() => {
        return [{
            text: t("customer-attachment.labels.customer-order-type"),
            value: CustomerAttachmentCategory.CustomerOrder.toString()
        }];
    });

    const form = useFormik<IAddCustomerAttachmentPostAction>({
        initialValues: {
            categoryModel: documentСategories[0],
            customerAttachmentFile: null,
            notes: null,
            orderReleaseGid : ''
        },
        validationSchema: Yup.object().shape({
            /*bet: Yup.string().nullable()
                .required(t("shared.errors.required-error").replace('{0}', t("self-billing.labels.your-rate"))),
                */
            customerAttachmentFile: Yup.mixed().nullable().required(t("shared.errors.required-error")
                .replace('{0}', t("customer-attachment.labels.file-name"))),
        }),

        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values, actions) => {
            actions.setSubmitting(true);

            await onSubmit(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id={'add-customer-attachment-form'}
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>

                <div className={cl.separator}></div>

                <div className={'mgb-10 drop-down-picker'}>
                    <label className={'view-label required'}>
                        {t("customer-attachment.labels.customer-order-name")}
                    </label>

                    <DropDownList data={documentСategories}
                                  disabled={disabled}
                                  textField="text"
                                  dataItemKey="value"
                                  value={documentСategories[0]}
                                  onChange={(ev) => form.setFieldValue('categoryModel', ev.value)}
    />

                    {/*<Alert type={'BrightGrayInfo'}
                           style={{
                               margin: '5px 0',
                               fontSize: '11px',
                               fontWeight: 'bold',
                               padding: '10px'
                           }}>
                        {form.values.expenseType.description}
                    </Alert>
                        */}
                     
                </div>

                <div className={'mgb-10 file-upload'}>
                    <label className={'view-label required'}>
                        {t("customer-attachment.labels.file-name")}
                    </label>

                    <FileUpload id={'add-expense-form-file-uploader'}
                                //multiple={false}
                                disabled={disabled}
                                onChange={(files) => {
                                    form.setFieldValue('customerAttachmentFile', files.length > 0
                                        ? files[0].file
                                        : null);
                                }}
                                restrictions={{
                                    allowedExtensions: [".jpg", ".jpeg", ".png", ".pdf", ".docx", ".doc", ".xls", ".xlsx", ".rtf", ".tiff"]
                                }}
                    />


                    {form.values.customerAttachmentFile === null && form.errors.customerAttachmentFile &&
                        <div className={'error-label'}>
                            {form.errors.customerAttachmentFile}
                        </div>
                    }
                </div>

                <div className={'mgb-10'}>
                    <label className={'view-label'}>
                        {t("customer-attachment.labels.notes-name")}
                    </label>

                    <Textarea id={'add-customer-attachments-notes'}
                              name={'notes'}
                              placeholder={t("customer-attachment.labels.notes-name")}
                              disabled={form.isSubmitting}
                              value={form.values.notes ?? ''}
                              autoComplete={'off'}
                              style={{minHeight: '70px', resize: 'vertical', maxHeight: '100px'}}
                              onChange={form.handleChange}/>
                </div>
            </div>
        </form>
    );
};
export default AddCustomerAttachmentForm;
