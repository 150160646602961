import React, {useCallback} from 'react';
import {debounce} from "debounce";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";

type ChatSearchInputProps = {
    disabled: boolean;
    onInputChange: (val: string) => void;
} & React.HTMLProps<HTMLInputElement>;

const ChatSearchInput: React.FC<ChatSearchInputProps> = ({
                                                             disabled,
                                                             onInputChange,
                                                             ...rest
                                                         }) => {
    const onChange = useCallback(debounce((val: string) => {
        onInputChange(val)
    }, 1000), [onInputChange]);

    return (
        <label className={`relative w100`}>
            <FontAwesomeIcon icon={faMagnifyingGlass}
                             className={'chat-search-input-icon'}/>
            <input className={`chat-search-input transition-all-500`}
                   onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onChange(ev.target.value)}
                   disabled={false}
                   {...rest}/>
        </label>
    );
};

export default ChatSearchInput;