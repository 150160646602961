import {isChromium, isChromium86OrNewer} from "../helpers/browser";

const getLanguages = (): string[][] => {
    const n = navigator;
    const result: string[][] = [];

    const language = n.language || n.userLanguage || n.browserLanguage || n.systemLanguage
    if (language !== undefined) {
        result.push([language])
    }

    if (Array.isArray(n.languages)) {
        if (!(isChromium() && isChromium86OrNewer())) {
            result.push(n.languages)
        }
    }

    return result;
}

export default getLanguages;