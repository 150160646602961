import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import SPUnblock from "./SPUnblock";

type SPUnblockDialogProps = {
    id: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const SPUnblockDialog: React.FC<SPUnblockDialogProps> = ({
                                                       id,
                                                       show,
                                                       setShow
                                                   }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'sp-unblock-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.btn.sp-unblock")}>
            <SPUnblock id={id} close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default SPUnblockDialog;