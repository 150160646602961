import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import AssignQuote from "./AssignQuote";

type AssignQuoteDialogProps = {
    id: string | Array<string>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const AssignQuoteDialog: React.FC<AssignQuoteDialogProps> = ({
                                                                 id,
                                                                 show,
                                                                 setShow
                                                             }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'assign-shipment-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.btn.assign-quote")}>
            <AssignQuote id={id}
                         close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default AssignQuoteDialog;