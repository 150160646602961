import {useChangeLanguage, useGetForwardersData, useGetServiceProvidersData, useSaveAccessSettings} from "./requests";

export const useUserApi = () => {
    const {
        isLoading: changeLanguageLoading,
        cancel: cancelChangeLanguage,
        changeLanguage
    } = useChangeLanguage();

    const {
        isLoading: saveAccessSettingsIsLoading,
        cancel: cancelSaveAccessSettings,
        saveAccessSettings
    } = useSaveAccessSettings();

    const {
        isLoading: getServiceProvidersDataIsLoading,
        cancel: cancelGetServiceProvidersData,
        getServiceProvidersData
    } = useGetServiceProvidersData();

    const {
        isLoading: getForwardersDataIsLoading,
        cancel: cancelGetForwardersData,
        getForwardersData
    } = useGetForwardersData();

    return {
        changeLanguage: {
            mutation: changeLanguage,
            isLoading: changeLanguageLoading,
            cancel: cancelChangeLanguage
        },
        saveAccessSettings: {
            mutation: saveAccessSettings,
            isLoading: saveAccessSettingsIsLoading,
            cancel: cancelSaveAccessSettings
        },
        getForwardersData: {
            query: getForwardersData,
            isLoading: getForwardersDataIsLoading,
            cancel: cancelGetForwardersData
        },
        getServiceProvidersData: {
            query: getServiceProvidersData,
            isLoading: getServiceProvidersDataIsLoading,
            cancel: cancelGetServiceProvidersData
        }
    }
};