import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import {GridContext} from "../../../../../../components/ui/Grid/Telerik/Grid";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import globeLoader from "../../../../../../assets/images/loader.gif";
import {useOfferApi} from "../../../../../../app/api/offer";
import cl from './IsNewTemplate.module.css';

const IsNewTemplate = (props: any) => {
    const {t} = useTranslation();
    let isNew = null;
    if (props.children !== undefined && props.children !== null) {
        isNew = props.children.toString().toLowerCase() === 'true';
    }

    const {
        markOffersAsViewed: {mutation: markOffersAsViewed},
        markOffersAsNotViewed: {mutation: markOffersAsNotViewed}
    } = useOfferApi();

    const {
        updateRowData
    } = useContext(GridContext);

    const markAsViewed = async () => {
        if (props.dataItem === null ||
            props.dataItem === undefined ||
            props.dataItem.id === undefined ||
            props.dataItem.id === null ||
            props.dataItem.orderReleaseId === undefined ||
            props.dataItem.orderReleaseId === null) {
            return;
        }

        try {
            updateRowData({
                ...props.dataItem,
                isRowLoading: true,
            });

            const response = await markOffersAsViewed({
                ids: [props.dataItem.orderReleaseId]
            });

            if (response && response.status === 200 &&
                response.data && response.data.resultCode === ResponseResultCode.Ok) {
                updateRowData({
                    ...props.dataItem,
                    isRowLoading: false,
                    isNew: false
                });
            }
        } catch {
            toast.error('Unable to change viewed state of offer');

            updateRowData({
                ...props.dataItem,
                isRowLoading: false,
            });
        }
    }

    const markAsUnViewed = async () => {
        if (props.dataItem === null ||
            props.dataItem === undefined ||
            props.dataItem.id === undefined ||
            props.dataItem.id === null ||
            props.dataItem.orderReleaseId === undefined ||
            props.dataItem.orderReleaseId === null) {
            return;
        }

        try {
            updateRowData({
                ...props.dataItem,
                isRowLoading: true,
            });

            const response = await markOffersAsNotViewed({
                ids: [props.dataItem.orderReleaseId]
            });

            if (response && response.status === 200 &&
                response.data && response.data.resultCode === ResponseResultCode.Ok) {
                updateRowData({
                    ...props.dataItem,
                    isRowLoading: false,
                    isNew: true
                });
            }
        } catch {
            toast.error('Unable to change viewed state of offer');

            updateRowData({
                ...props.dataItem,
                isRowLoading: false,
            });
        }
    }

    if (props.dataItem.isRowLoading !== undefined && props.dataItem.isRowLoading !== null &&
        props.dataItem.isRowLoading === true) {
        return (
            <td {...props.tdProps}
                colSpan={1}>
                <img src={globeLoader} style={{width: '10px'}} alt={'Loading...'} title={'Loading...'}/>
            </td>
        )
    }

    return (
        <td {...props.tdProps}
            colSpan={1}>
            <span className={`w100 h100 block ${cl.container} grid-prevent-selection`}>
                {isNew !== null && isNew === true &&
                    <button className={`${cl.button} grid-prevent-selection offer-grid-mark-as-viewed-btn ${cl.new}`}
                            title={t(`offer.grid.un-viewed-offer`)}
                            onClick={markAsViewed}></button>
                }
                {isNew !== null && isNew === false &&
                    <button
                        className={`${cl.button} grid-prevent-selection offer-grid-mark-as-un-viewed-btn ${cl.old}`}
                        title={t("offer.grid.viewed-offer")}
                        onClick={markAsUnViewed}></button>
                }
            </span>
        </td>
    );
};

export default IsNewTemplate;
