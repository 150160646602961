import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'hollowCircle',
    circle: [{
        cx: 6.5,
        cy: 6.5,
        r: 6
    }],
    strokeWidth: 1,
    viewBox: '0 0 13 13'
};

export default icon;