import React, {useState} from 'react';
import {IBetAction} from "../../../../../../../../app/interfaces/quote/IBetAction";
import cl from '../ForwarderOfferBets.module.css';
import {BetStatus} from "../../../../../../../../app/enums/BetStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faClockRotateLeft} from "@fortawesome/free-solid-svg-icons";
import {IOffer} from "../../../../../../../../app/interfaces/offer/IOffer";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {useAppSelector} from "../../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../../features/authentication/authenticationSliceSelectors";
import {formatCurrencyNumber} from "../../../../../../../../helpers/currency";
import Button, {ButtonType} from "../../../../../../../../components/ui/Button/Button";
import RefuseBetDialog from "../../../../../actions/RefuseBet/RefuseBetDialog";
import SelectBetDialog from "../../../../../actions/SelectBet/SelectBetDialog";
import UserChat from "../../../../../../../../components/shared/components/Chat/components/UserChat/UserChat";
import {UserRole} from "../../../../../../../../app/enums/UserRole";
import SupplierData from "../../../../../../../../components/shared/form/detailsFields/SupplierData/SupplierData";

type ForwarderOfferBetProps = {
    offer: IOffer;
    bet: IBetAction;
    minBet: IBetAction | null;
    index: number;
};

const ForwarderOfferBet: React.FC<ForwarderOfferBetProps> = ({
                                                                 offer,
                                                                 minBet,
                                                                 bet,
                                                                 index
                                                             }) => {
    const user = useAppSelector(selectCurrentUser);

    const {t} = useTranslation();
    const [isHistoryShown, setIsHistoryShown] = useState<boolean>(false);
    const [isRefuseDialogShown, setIsRefuseDialogShown] = useState<boolean>(false);
    const [isSelectBetDialogShown, setIsSelectBetDialogShown] = useState<boolean>(false);

    let containerClass: any;
    let isMinBet: boolean = false;
    let isAvailableTillToday = bet.status === BetStatus.New && bet.tillDate !== null && bet.tillDate !== ''
        ? moment(moment(), 'DD.MM.YYYY').isSame(moment(bet.tillDate, 'DD.MM.YYYY'), 'date')
        : false;

    if (minBet !== null && minBet.betId === bet.betId) {
        containerClass = cl.selectedBet;
        isMinBet = true;
    } else if (bet.status === BetStatus.New) {
        containerClass = cl.actualBet;
    } else {
        containerClass = cl.rejectedBet;
    }

    return (
        <div className={`bet-${bet.betId}-${index} mgb-5 mgt-5 ${cl.bet} ${containerClass}`}>
            {offer.bets.filter(e => e.serviceProviderId === bet.serviceProviderId && e.betId !== bet.betId).length > 0 &&
                <FontAwesomeIcon icon={faClockRotateLeft}
                                 title={t("quote.bet.show-hide-bet-history-btn")}
                                 onClick={() => {
                                     setIsHistoryShown(prev => !prev);
                                 }}
                                 className={`${cl.history}`}/>
            }

            {bet.status === BetStatus.Cancelled &&
                <>
                    <div className={`view-row ${cl.historyBetReasonRow}`}>
                        <FontAwesomeIcon icon={faCircleXmark}/>
                        {bet.rejectReason !== null && bet.rejectReason !== '' &&
                            <span>
                                {bet.rejectReason}
                            </span>
                        }
                    </div>
                    {bet.rejectNotes !== null && bet.rejectNotes !== '' &&
                        <div className={`view-row ${cl.red}`}>
                            {bet.rejectNotes}
                        </div>
                    }
                </>
            }

            {user &&
                <div className={`mgb-5 mgt-5`}>
                    {moment.utc(bet.createdOn).local().format('DD.MM.YYYY HH:mm')}
                </div>
            }

            <div className={`${isMinBet ? cl.orange : ""} mgb-5 mgt-5 ${cl.betText} ${cl.betValueRow}`}>
                {`${formatCurrencyNumber(bet.valueBase, 'EUR')} ${bet.currencyCode !== 'EUR' ? `(${bet.bet} ${bet.currencyCode})` : ''}`}
            </div>

            {bet.tillDate !== null && bet.tillDate !== '' &&
                <div className={`view-row mgb-5 mgt-5`}>
                    {`${t("quote.bet.validity")}: `}
                    <span style={isAvailableTillToday ? {
                        color: '#c9302c'
                    } : undefined}>
                        {bet.tillDate}
                    </span>
                </div>
            }

            {bet.notes !== null && bet.notes !== '' &&
                <div className={`view-row mgb-5 mgt-5`}>
                    {`${bet.notes}`}
                </div>
            }

            <div className={`view-row mgb-5 mgt-5 ${cl.orange} ${cl.serviceProvider}`}>
                <SupplierData user={{
                    id: bet.serviceProviderId ?? '',
                    name: `${bet.serviceProvider} ${bet.extServiceProviderName !== null && bet.extServiceProviderName !== '' ? `(${bet.extServiceProviderName})` : ''}`
                }}/>

                <UserChat user={{
                    role: UserRole.ServiceProvider,
                    id: bet.userLogin ?? '',
                    serviceProvider: bet.userLogin ?? undefined,
                    skipServiceProviderList: true
                }} replyTo={{
                    type: 'RELEASE',
                    id: offer.orderReleaseXid.replace('AAG.', ''),
                    description: offer.chatDescription,
                    idExt: null,
                }}/>
            </div>

            {isHistoryShown &&
                <div className={`bet-history-container-${bet.betId}-${index} mgt-10 mgb-10`}>
                    {offer.bets.filter(e => e.serviceProviderId === bet.serviceProviderId && e.betId !== bet.betId)
                        .sort((a, b) => {
                            return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
                        })
                        .map(item => {
                            return (
                                <div key={`history-bet-${item.betId}-${index}`} className={cl.historyBet}>
                                    <div className={`view-row ${cl.historyBetReasonRow}`}>
                                        <FontAwesomeIcon icon={faCircleXmark}/>
                                        {item.rejectReason !== null && item.rejectReason !== '' &&
                                            <span>
                                                                {item.rejectReason}
                                                            </span>
                                        }
                                    </div>
                                    {item.rejectNotes !== null && item.rejectNotes !== '' &&
                                        <div className={`view-row ${cl.red}`}>
                                            {item.rejectNotes}
                                        </div>
                                    }
                                    {user &&
                                        <div className={`${cl.gray} mgb-5 mgt-5`}>
                                            {moment(item.createdOn).add(user.timeShift, 'hours').format('DD.MM.YYYY HH:mm')}
                                        </div>
                                    }
                                    <div className={`${cl.gray} mgb-5 mgt-5 ${cl.betText}`}>
                                        {`${item.bet} ${item.currencyCode}`}
                                    </div>
                                    {item.tillDate !== null && item.tillDate !== '' &&
                                        <div className={`view-row ${cl.gray} mgb-5 mgt-5`}>
                                            {`${t("quote.bet.validity")}: ${item.tillDate}`}
                                        </div>
                                    }
                                    {item.notes !== null && item.notes !== '' &&
                                        <div className={`view-row ${cl.gray} mgb-5 mgt-5`}>
                                            {`${item.notes}`}
                                        </div>
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            }

            {bet.status === BetStatus.New && offer.selectedBet === null
                ? <div className={cl.btnContainer}>
                    <RefuseBetDialog betId={bet.betId}
                                     orderReleaseGid={offer.orderReleaseGid}
                                     show={isRefuseDialogShown}
                                     setShow={setIsRefuseDialogShown}/>

                    <SelectBetDialog id={bet.betId}
                                     show={isSelectBetDialogShown}
                                     setShow={setIsSelectBetDialogShown}/>

                    <Button buttonType={ButtonType.Primary}
                            onClick={() => {
                                setIsSelectBetDialogShown(prev => !prev);
                            }}
                            style={{
                                width: '45%'
                            }}>
                        {t("shared.btn.confirm")}
                    </Button>
                    <Button buttonType={ButtonType.Warning}
                            onClick={() => {
                                setIsRefuseDialogShown(prev => !prev);
                            }}
                            style={{
                                width: '45%'
                            }}>
                        {t("shared.btn.reject")}
                    </Button>
                </div>
                : null
            }
        </div>
    );
};

export default ForwarderOfferBet;
