import React, {useState} from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import Icon, {IconName} from "../../../../../../assets/icon/Icon";
import {set} from "lodash";

const IndicatorTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);

let text = '';
    let icon: IconName | null = null;
    let style: React.CSSProperties | undefined = undefined;

    if (props.children !== null && props.children !== undefined) {
        if (props.children === 'G') {
            text = 'G'
            icon = "faCircle";
            style = {
                fontSize: '16px',
                color: '#7FB98B'
            };
        } else if (props.children === 'Y') {
            text = 'Y'
            icon = "faTriangle";
            style = {
                fontSize: '16px',

                color: '#F3D11C'
            };
        }
    }

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
                <span className={'default-center-cell'}>
                    {icon !== null && <Icon icon={icon} style={style}/>}
                </span>
        </td>
    );
};

export default IndicatorTemplate;
