import React from 'react';
import {IAddBetGetAction} from "../../../../../../app/interfaces/offer/IAddBetGetAction";
import {IAddBetPostAction} from "../../../../../../app/interfaces/offer/IAddBetPostAction";
import {FormikHelpers, useFormik} from "formik";
import {NumericTextBox} from "@progress/kendo-react-inputs";
import {useTranslation} from "react-i18next";
import ProposeRateCurrencySelector from "./ProposeRateCurrencySelector/ProposeRateCurrencySelector";
import * as Yup from "yup";
import Textarea from "../../../../../../components/ui/Textarea/Textarea";
import ConfirmAuctionServiceProviderSelector
    from "../../ConfirmAuction/ServiceProvider/ConfirmAuctionFormServiceProvider/ConfirmAuctionServiceProviderSelector";
import moment from "moment";
import ReactDatePicker from "../../../../../../components/ui/ReactDatePicker/ReactDatePicker/ReactDatePicker";

type ProposeRateFormProps = {
    model: IAddBetGetAction;
    disabled: boolean;
    save: (model: IAddBetPostAction) => Promise<void>;
};

const ProposeRateForm: React.FC<ProposeRateFormProps> = ({
                                                             model,
                                                             save,
                                                             disabled
                                                         }) => {
    const {t} = useTranslation();

    const form = useFormik<IAddBetPostAction>({
        initialValues: {
            orderReleaseGid: model.orderReleaseGid,
            serviceProviderId: model.serviceProviderId,
            serviceProvider: model.serviceProvider,
            bet: null,
            forwarder: model.forwarder,
            needTransport: model.needTransport,
            currencyCode: model.currencyCode,
            tillDate: null,
            notes: null
        },
        validationSchema: Yup.object().shape({
            bet: Yup.string().nullable().required(t("shared.errors.required-error")
                .replace('{0}', t("offer.grid.add-bet-bet"))),
            serviceProviderId: Yup.string().nullable().required(t("shared.errors.required-error")
                .replace('{0}', t("offer.grid.service-provider"))),
        }),
        onSubmit: async (values: IAddBetPostAction, actions: FormikHelpers<IAddBetPostAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='propose-rate-offer-form'
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div className={`view-row mgb-10`}>
                    <label className={'view-label required'}>
                        {t("offer.grid.add-bet-bet")}
                    </label>
                    <div style={{
                        display: 'flex',
                        gap: '10px'
                    }}>
                        <div className={'col-sm-2 numeric-text-box'} style={{width: '60%'}}>
                            <NumericTextBox disabled={disabled}
                                            placeholder={t("offer.grid.add-bet-bet")}
                                            spinners={false}
                                            name={'bet'}
                                            format={'n2'}
                                            value={form.values.bet && form.values.bet !== '' ? Number(form.values.bet.replace(/,/g, '.')) : null}
                                            min={0}
                                            max={100000000}
                                            onChange={async (ev) => {
                                                if (ev.value === null || ev.value === 0) {
                                                    await form.setFieldValue('bet', null);

                                                    return;
                                                }

                                                await form.setFieldValue('bet', ev.value?.toString());
                                            }}
                            />
                        </div>
                        <div className={'col-sm-2 drop-down-picker'} style={{width: '40%'}}>
                            <ProposeRateCurrencySelector disabled={disabled}
                                                         currency={form.values.currencyCode}
                                                         onChange={(value: string | null) => {
                                                             form.setFieldValue('currencyCode', value)
                                                         }}/>
                        </div>
                    </div>
                    {form.errors.bet &&
                        <div className={'error-label'}>
                            {form.errors.bet}
                        </div>
                    }
                </div>

                <div className={'mgb-10'}>
                    <label className={'view-label required'}>
                        {t("offer.grid.service-provider")}
                    </label>

                    <ConfirmAuctionServiceProviderSelector disabled={disabled}
                                                           value={form.values.serviceProviderId}
                                                           onChange={(val) => {
                                                               form.setFieldValue('serviceProviderId', val.value);
                                                               form.setFieldValue('serviceProvider', val.text)
                                                           }}/>

                    {!form.values.serviceProviderId && form.errors.serviceProviderId &&
                        <div className={'error-label'}>
                            {form.errors.serviceProviderId}
                        </div>
                    }
                </div>

                <div className={'mgb-10 picker'}>
                    <label className={'view-label till-date-label'}>
                        {t("offer.grid.till-date")}
                    </label>

                    <ReactDatePicker disabled={disabled}
                                     size={'big'}
                                     value={form.values.tillDate !== null && form.values.tillDate !== ''
                                         ? moment(form.values.tillDate, 'DD.MM.YYYY').toDate()
                                         : null}
                                     onChange={(val) => {
                                         form.setFieldValue('tillDate', moment(val).format('DD.MM.YYYY'));
                                     }}
                                     placeholder={t("shared.labels.date")}
                    />

                    {/*<DatePicker format={'dd.MM.yyyy'}
                                dateInput={DateInput}
                                disabled={disabled}
                                formatPlaceholder={{
                                    year: "yyyy",
                                    month: "MM",
                                    day: "dd",
                                }}
                                value={form.values.tillDate !== null && form.values.tillDate !== ''
                                    ? moment(form.values.tillDate, 'DD.MM.YYYY').toDate()
                                    : null}
                                onChange={(ev) => {
                                    form.setFieldValue('tillDate', moment(ev.value).format('DD.MM.YYYY'));
                                }}
                                popupSettings={{animate: false}}
                                rounded={'small'}
                                fillMode={'outline'}
                                placeholder={t("shared.labels.date")}
                                adaptive={true}/>*/}
                </div>

                <div className={'mgb-10'}>
                    <label className={'view-label comments-label'}>
                        {t("offer.form.notes")}
                    </label>
                    <Textarea disabled={disabled}
                              className={'propose-rate-comments-value'}
                              style={{minHeight: '100px', resize: 'vertical', maxHeight: '150px'}}
                              value={form.values.notes ?? ''}
                              placeholder={t("offer.form.notes")}
                              onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                  form.setFieldValue('notes', ev.target.value);
                              }}/>
                </div>
            </div>
        </form>
    );
};

export default ProposeRateForm;
