import React from 'react';
import Loader, {LoaderType} from "../../../Loader/Loader";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import cl from './SkeletonMapLoader.module.css';

const SkeletonMapLoader = () => {
    return (
        <SkeletonTheme baseColor={'#eaeff3'} highlightColor={'#ffffff'}>
            <div className={`${cl.container} h100 w100 hidden`}>
                <div>
                    <div className={'action-loader-container'}>
                        <div>
                            <Loader type={LoaderType.Action} style={{scale: '0.4'}}/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={cl.grid}>
                        <div className={cl.content}>
                            {Array.from({length: 3}, (_, i) => i).map((item) => {
                                return (
                                    <div key={item}>
                                        <div className={cl.group}>
                                            <Skeleton height={'15px'}
                                                      width={'100%'}
                                                      containerClassName={cl.skeletonRow}/>

                                            <Skeleton height={'15px'}
                                                      width={'100%'}
                                                      containerClassName={cl.skeletonRow}/>
                                        </div>
                                        <div>
                                            <Skeleton height={'15px'}
                                                      width={'40%'}
                                                      className={cl.skeletonRow}/>
                                        </div>
                                        <div>
                                            <Skeleton height={'15px'}
                                                      width={'80%'}
                                                      className={cl.skeletonRow}/>
                                        </div>
                                        <div>
                                            <Skeleton height={'15px'}
                                                      width={'80%'}
                                                      className={cl.skeletonRow}/>
                                        </div>
                                        <div>
                                            <Skeleton height={'15px'}
                                                      width={'40%'}
                                                      className={cl.skeletonRow}/>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={cl.footer}>
                            <div className={cl.pager}>
                                <FontAwesomeIcon icon={faAngleLeft}/>
                                <div>
                                    <Skeleton height={'25px'}
                                              width={'25px'}
                                              style={{borderRadius: '35%'}}/>

                                    <Skeleton height={'25px'}
                                              width={'25px'}
                                              style={{borderRadius: '35%'}}/>

                                    <Skeleton height={'25px'}
                                              width={'25px'}
                                              style={{borderRadius: '35%'}}/>
                                </div>
                                <FontAwesomeIcon icon={faAngleRight}/>
                            </div>
                            <div>
                                <Skeleton height={'15px'}
                                          width={'150px'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    );
};

export default SkeletonMapLoader;