import React, {useEffect, useState} from 'react';
import {DropDownList, DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {SelectModel} from "../../../../../../../app/types/SelectModel";
import {useEquipmentApi} from "../../../../../../../app/api/equipment";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {useAppSelector} from "../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../app/enums/UserRole";
import {ResponseResultCode} from "../../../../../../../app/enums/ResponseResultCode";

type EditTransportEquipmentSelectorProps = {
    disabled: boolean;
    equipment: string | null;
    onChange: (ev: DropDownListChangeEvent) => void;
}

const EditTransportEquipmentSelector: React.FC<EditTransportEquipmentSelectorProps> = ({
                                                                                           equipment,
                                                                                           onChange,
                                                                                           disabled
                                                                                       }) => {
    const [data, setData] = useState<Array<SelectModel>>([]);
    const user = useAppSelector(selectCurrentUser);
    const {
        getEquipmentGroups: {
            query: getEquipmentGroups,
            isLoading,
            cancel
        }
    } = useEquipmentApi();

    useEffect(() => {

        (async () => {
            try {
                const response = await getEquipmentGroups(true);

                if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok &&
                    response.data?.data) {
                    setData(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error(`Unable to get equipment groups: ${e.message}`);
                }
            }
        })();

        return () => {
            cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DropDownList data={data}
                      className={'edit-transport-equipment-value'}
                      disabled={disabled || isLoading || !user || getUserRole(user) === UserRole.ServiceProvider}
                      loading={isLoading || !user}
                      textField="text"
                      dataItemKey="value"
                      value={equipment !== null ? data.find(e => e.value === equipment) : null}
                      onChange={onChange}
        />
    );
};

export default EditTransportEquipmentSelector;