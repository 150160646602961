import React from 'react';
import {IOffer} from "../../../../../../../../app/interfaces/offer/IOffer";
import {ResponseResultCode} from "../../../../../../../../app/enums/ResponseResultCode";
import {useQuoteApi} from "../../../../../../../../app/api/quote";

type OfferTransporeonNumberProps = {
    offer: IOffer;
};

const OfferTransporeonNumber: React.FC<OfferTransporeonNumberProps> = ({
                                                                           offer
                                                                       }) => {
    const {
        getQuoteOtmTransporeonLink: {
            query: getQuoteOtmTransporeonLink
        }
    } = useQuoteApi();

    if (offer.transporeonNumber === null || offer.transporeonNumber === '') {
        return (
            <div className={'details-box-field-value'}>

            </div>
        );
    }

    return (
        <div className={'details-box-item-field-edit-value'}
             onClick={async () => {
                 if (!offer.transporeonNumber) {
                     return;
                 }

                 try {
                     const response = await getQuoteOtmTransporeonLink(offer.transporeonNumber);

                     if (response && response.data && response.data.resultCode === ResponseResultCode.Ok &&
                         response.data.data) {
                         window.open(response.data.data, '_blank');
                     }
                 } catch {
                     //ignore
                 }
             }}>
            {offer.transporeonNumber}
        </div>
    );
};

export default OfferTransporeonNumber;
