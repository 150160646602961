import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import ConfirmAuction from "./ConfirmAuction";

type ConfirmAuctionDialogProps = {
    id: string | Array<string>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const ConfirmAuctionDialog: React.FC<ConfirmAuctionDialogProps> = ({
                                                                       id,
                                                                       show,
                                                                       setShow
                                                                   }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'confirm-auction-dialog'}
                show={show}
                setShow={setShow}
                width={'800px'}
                title={t("shared.btn.accept-transportation")}>
            <ConfirmAuction id={id} close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default ConfirmAuctionDialog;