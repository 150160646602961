import React, {useEffect, useRef, useState} from 'react';
import cl from './FormLayoutActionContextMenu.module.css';
import useOnClickOutside from "../../../../../hooks/useOnClickOutside/useOnClickOutside";
import {FormLayoutToolbarActionItem} from "../FormLayoutToolbar/FormLayoutToolbar";
import Icon from "../../../../../assets/icon/Icon";

type FormLayoutActionContextMenuProps = {
    isOpened: boolean;
    setIsOpened: (val: boolean) => void;
    container: React.MutableRefObject<HTMLDivElement | null>;
    actions: Array<FormLayoutToolbarActionItem | 'separator'>;
    pinned: Array<string>;
    onActionPin: (id: string) => void;
    pinActionEnabled: boolean;
}

const MAX_PIN_ACTIONS = 3;

const FormLayoutActionContextMenu: React.FC<FormLayoutActionContextMenuProps> = ({
                                                                                     isOpened,
                                                                                     setIsOpened,
                                                                                     container,
                                                                                     actions,
                                                                                     pinned,
                                                                                     onActionPin,
                                                                                     pinActionEnabled
                                                                                 }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [point, setPoint] = useState<{
        x: number,
        y: number
    } | null>();
    useOnClickOutside(ref, (ev) => {
        if (ev.target && container.current?.contains(ev.target as Node)) {
            return;
        }

        setIsOpened(false);
    });

    useEffect(() => {
        if (!container || !container.current) {
            return;
        }

        let position = container.current?.getBoundingClientRect();
        if (position) {
            setPoint({
                x: -(280 - (container.current?.clientWidth ?? 0)),
                y: container.current?.clientHeight ?? 0
            });
        }
    }, [container]);

    if (!point || !isOpened) {
        return null;
    }

    return (
        <div style={{left: point.x, top: point.y,}}
             ref={ref}
             className={cl.menu}>
            <ul className={cl.ul}>
                {actions.map(action => {
                    if (typeof action === 'string') {
                        return (
                            <li key={action} className={cl.separator}/>
                        );
                    } else {
                        let isPinned = pinned.find(e => e === action.id) !== undefined;
                        let isPinnedDisabled = !isPinned && pinned.length >= MAX_PIN_ACTIONS;

                        return (
                            <li key={action.id}
                                id={action.id}
                                style={action.color ? {color: action.color} : undefined}
                                className={`${action.disabled ? cl.disabled : ''}${action.wraped ? cl.wraped : ''}`}
                                onClick={() => {
                                    if (!action.disabled && action.onClick) {
                                        action.onClick();
                                    }
                                }}
                            >
                                <div>
                                    <div className={`${action.wraped ? cl.wraped : ''}`}>
                                        {action.title}
                                    </div>
                                    <div className={cl.pinContainer}
                                         onClick={(ev) => ev.stopPropagation()}>
                                        {pinActionEnabled &&
                                            <Icon icon={"faThumbTack"}
                                                  onClick={(ev) => {
                                                      ev.stopPropagation();

                                                      if (isPinnedDisabled) {
                                                          return;
                                                      }

                                                      onActionPin(action.id);
                                                  }}
                                                  className={`${isPinned ? cl.pinned : ''} ${isPinnedDisabled ? cl.disabled : ''}`}/>
                                        }
                                    </div>
                                </div>
                            </li>
                        )
                    }
                })}
            </ul>
        </div>
    );
};

export default FormLayoutActionContextMenu;
