import React from 'react';
import {IQuote} from "../../../../../../../../app/interfaces/quote/IQuote";
import {useTranslation} from "react-i18next";
import ForwarderField from "../../../../../../../../components/shared/form/detailsFields/ForwarderField/ForwarderField";

type QuoteCustomerManagerProps = {
    quote: IQuote;
}

const QuoteCustomerManager: React.FC<QuoteCustomerManagerProps> = ({
                                                                       quote
                                                                   }) => {
    const {t} = useTranslation();

    return (
        <div className={'details-box-item-field-value'}>
            {quote.customerManager !== null && quote.customerManager !== undefined && quote.customerManager.name !== null && quote.customerManager.name !== ''
                ? <ForwarderField user={quote.customerManager}
                                  chatReplyTo={{
                                      type: 'QUOTE',
                                      description: quote.chatDescription,
                                      id: quote.orderReleaseGid.replace('AAG.', ''),
                                      idExt: null
                                  }}/>
                : null
            }
        </div>
    );
};

export default QuoteCustomerManager;
