import {ColumnModel} from "../types/ColumnModel";
import i18n from "i18next";
import enLocale from '../locale/en.json';
import plLocale from '../locale/pl.json';
import ruLocale from '../locale/ru.json';
import {loadMessages} from "@progress/kendo-react-intl";
import {pick} from "../../../../../helpers/object";
import {GridColumnProps} from "@progress/kendo-react-grid";

export const gridConfigPropertiesToPersist: Array<keyof ColumnModel> = [
    'order',
    'field',
    'title',
    'width',
    'locked',
    'constantlyLocked',
    'visible',
    'allowReordering',
    'editable'
];

export const getGridConfigColumns = (columns: Array<ColumnModel>): Array<ColumnModel> => {
    const items: Array<ColumnModel> = [];

    columns.filter(item => item.field).forEach(item => {
        items.push({
            ...pick(item, ...gridConfigPropertiesToPersist)
        });
    });

    return items;
};

export const combineDefaultAndSavedColumns = (
    defaultColumns: Array<ColumnModel>,
    savedColumns: Array<ColumnModel> | null): Array<ColumnModel> => {
    let result: Array<ColumnModel> = [];

    if (savedColumns !== null && savedColumns.length > 0) {
        defaultColumns.forEach(item => {
            let savedItem = savedColumns.find(savedItem => savedItem.field === item.field);

            result.push({
                ...item,
                ...savedItem
            });
        });
    } else {
        defaultColumns.forEach(item => {
            result.push({
                ...item
            });
        });
    }

    result = result.sort((a, b) => {
        if (a.order !== undefined && b.order !== undefined) {
            return a.order - b.order;
        }

        return 0;
    });

    result = result.sort((a, b) => Number(b.locked) - Number(a.locked));

    return result;
}

export const isGroupChanged = (old: Array<string>, updated: Array<string>) => {
    if (old.length !== updated.length) {
        return true;
    }

    let changed = old.filter(item => {
        let presentedInOldGroup = updated.find(g => g === item);

        return presentedInOldGroup === undefined;
    });

    return changed.length > 0;
}

export const isColumnsChanged = (old: Array<ColumnModel>, updated: Array<ColumnModel>) => {
    if (old.length !== updated.length) {
        return true;
    }

    let changedColumns = old.filter(item => {
        let newColumnValue = updated.find(c => c.field === item.field);

        return newColumnValue === undefined ||
            newColumnValue.visible !== item.visible ||
            newColumnValue.locked !== item.locked ||
            newColumnValue.width !== item.width ||
            newColumnValue.order !== item.order;
    });

    return changedColumns.length > 0;
}

export const isColumnsLockedChanged = (old: Array<ColumnModel>, updated: Array<ColumnModel>) => {
    let changedColumns = old.filter(item => {
        let newColumnValue = updated.find(c => c.field === item.field);

        return newColumnValue === undefined ||
            newColumnValue.locked !== item.locked;
    });

    return changedColumns.length > 0;
}

export const updateColumns = (changed: {
    columns: Array<GridColumnProps>,
    reorderFieldId?: string
} | null, current: Array<ColumnModel>): Array<ColumnModel> => {
    if (changed === null) {
        return [...current.map(item => ({...item}))];
    }

    let cols: Array<ColumnModel> = [];

    let lockedColumns = current.filter(e => e.locked !== undefined && e.locked);
    let isLockedColumnsChanged = changed.columns.filter(item => {
        let ex = lockedColumns.find(e => e.field === item.field);
        if (ex) {
            return ex.order !== item.orderIndex;
        }

        return false;
    });

    if (isLockedColumnsChanged.length > 0) {
        return [...current.map(item => ({...item}))];
    } else {
        let lastColumnOrder = changed.columns.sort((a, b) => {
            if (a.orderIndex !== undefined && b.orderIndex !== undefined) {
                return (b.orderIndex - a.orderIndex);
            }

            return 0;
        })[0].orderIndex;

        current.forEach((item) => {
            let c = changed.columns.find(e => e.field === item.field);

            if (c !== undefined) {
                cols.push({
                    ...item,
                    width: c.width !== undefined ? c.width : item.width,
                    order: c.orderIndex !== undefined ? c.orderIndex : item.order
                });
            } else {
                cols.push({
                    ...item,
                    order: lastColumnOrder !== undefined ? lastColumnOrder + 1 : item.order
                });

                lastColumnOrder = (lastColumnOrder ?? 0) + 1;
            }
        });
    }

    return cols;
}

export const getGridLocaleString = (loc: typeof i18n): string => {
    return `${loc.language}-${loc.language.toUpperCase()}`;
};

export const loadGridLocalization = (loc: typeof i18n): void => {
    let locale = getGridLocaleString(loc);

    switch (locale) {
        case 'ru-RU':
            loadMessages(ruLocale, locale);
            break;
        case 'pl-PL':
            loadMessages(plLocale, locale);
            break;
        case 'en-EN':
            loadMessages(enLocale, locale);
            break;
    }
}