import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {IDataResponse, IResponse} from "../../../../../app/interfaces/response/IResponse";
import {useShipmentApi} from "../../../../../app/api/shipment";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import Alert from "../../../../../components/ui/Alert/Alert";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import cl from './UnConfirmShipment.module.css';
import apiAxios from "../../../../../app/axios/apiAxios";

type UnConfirmShipmentProps = {
    id: string | Array<string>;
    close?: () => void;
}

const alertStyle: React.CSSProperties = {
    padding: '10px 5px',
    fontSize: '12px',
    fontWeight: 'bold'
};

let GET_SHIPMENT_MANAGEMENT_LINK = `/api/${process.env.REACT_APP_API_VERSION}/shipment/getShipmentManagementLink`;

export const openShipmentManagement = async (id: string) => {
    try {
        let response = await apiAxios.get<IDataResponse<string>>(`${GET_SHIPMENT_MANAGEMENT_LINK}?id=${id}&_c=${Date.now()}`);

        if (response.status === 200 && response.data?.data) {
            window.open(response.data.data, '_blank');
        }
    } catch {
        //ignore
    }
}

const UnConfirmShipment: React.FC<UnConfirmShipmentProps> = ({
                                                                 id,
                                                                 close
                                                             }) => {
    const {t} = useTranslation();
    const [response, setResponse] = useState<IResponse | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        getUnConfirmShipmentData: {
            query: getUnConfirmShipmentData,
            isLoading: getUnConfirmShipmentDataIsLoading,
            cancel: cancelGetUnConfirmShipmentData
        },
        unConfirmShipment: {
            mutation: unConfirmShipment,
            cancel: cancelUnConfirmShipment
        }
    } = useShipmentApi();

    useEffect(() => {
        if ((Array.isArray(id) && id.length <= 0) || id === '') {
            if (close) {
                close();
            } else {
                return;
            }
        }

        (async () => {
            try {
                const response = await getUnConfirmShipmentData(id);
                if (response?.status === 200 && response.data && response.data.resultCode === ResponseResultCode.Ok) {
                    setResponse(response.data);
                }
            } catch (err) {
                if (isAxiosError(err)) {
                    if (err.response && err.response.data && err.response.status === 422 &&
                        err.response.data.resultCode === ResponseResultCode.NotValidData &&
                        err.response.data.errors && Array.isArray(err.response.data.errors)) {
                        setResponse(err.response.data);
                    } else if (close) {
                        close();
                        toast.error(err.message);
                    }
                }
            }
        })();

        return () => {
            cancelUnConfirmShipment();
            cancelGetUnConfirmShipmentData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const submit = async (): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            let ids = [...id];

            const response = await unConfirmShipment({
                orderReleaseGid: ids.join(';')
            });



            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [
                            ...ids.map(item => {
                                return {
                                    id: item,
                                    isRowLoading: true
                                };
                            })
                        ]
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.shipment")} ${ids[0]}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err) && close) {
                close();
                toast.error(err.message);
            }
        }

        setDisabled(prev => !prev);
    }

    if (getUnConfirmShipmentDataIsLoading || response === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>
            <div className={cl.dialog}>
            {response.errors && response.errors.length > 0
                ? response.errors.map((err, index) => {
                    return (
                        <Alert type={'Error'}
                               id={`un-confirm-shipment-error-${index}`}
                               key={`${err.errorCode}-${err.field}`}
                               style={alertStyle}>
                            <div>
                                 <span>
                                    <button className={`${cl.link} open-shipment-management`}
                                            onClick={async () => {
                                                await openShipmentManagement(err.field ?? '');
                                            }}>
                                    {err.field}
                                  </button>
                                </span>
                                {err.description}
                            </div>
                        </Alert>
                    );
                })
                : <div className={cl.btnContainer}>
                    {close &&
                        <Button buttonType={ButtonType.Transparent}
                                disabled={disabled}
                                style={{minWidth: '150px'}}
                                onClick={close}
                                className={'close-button'}>
                            {t("shared.btn.no")}
                        </Button>
                    }
                    <Button buttonType={ButtonType.Primary}
                            disabled={disabled}
                            style={{minWidth: '150px'}}
                            onClick={submit}
                            isLoading={disabled}
                            className={'confirm-button'}>
                        {t("shared.btn.yes")}
                    </Button>
                </div>
            }
            </div>
        </div>
    );
};

export default UnConfirmShipment;
