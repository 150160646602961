import React, {useEffect} from 'react';
import {IResponse} from "../../../app/interfaces/response/IResponse";
import useServerError from "../../../hooks/useServerError/useServerError";
import cl from './ServerErrorLabel.module.css';
import {AnimatePresence, motion} from "framer-motion";
import {Entity} from "../../../app/apiError";
import Alert from "../Alert/Alert";

type ServerErrorLabelProps = {
    entity: Entity;
    error?: string | IResponse | null;
    timeout?: number;
    onErrorCleared: () => void;
}

const ServerErrorLabel: React.FC<ServerErrorLabelProps> = ({
                                                               error,
                                                               onErrorCleared,
                                                               entity,
                                                               timeout = 7000
                                                           }) => {
    const [err, setError] = useServerError({entity, timeout, onErrorCleared});

    useEffect(() => {
        setError(error)
    }, [error, setError]);

    return (
        <AnimatePresence>
            {err &&
                <motion.div key={'error-label'}
                            id={'error-label'}
                            className={cl.container}
                            initial={{x: -30, opacity: 0.5}}
                            animate={{opacity: 1, x: 0}}
                            transition={{duration: 1, x: 0}}
                            exit={{opacity: 0.2, x: 30}}>
                    <Alert type={'Error'}>
                        {err}
                    </Alert>

                </motion.div>
            }
        </AnimatePresence>
    );
};

export default ServerErrorLabel;
