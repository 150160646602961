import {useEffect, useRef, useState} from "react";

const useSecondCountDownTimer = (period: number): [number, () => void] => {
    const [timer, setTimer] = useState<number>(period);
    const intervalId = useRef<NodeJS.Timer>();

    const start = () => {
        setTimer(period);
        clearInterval(intervalId.current);

        intervalId.current = setInterval(() => {
            setTimer(prev => {
                if (prev < 0) {
                    return prev;
                }

                return prev - 1;
            });
        }, 1000);
    }

    useEffect(() => {
        return () => {
            clearInterval(intervalId.current);
        }
    }, []);

    useEffect(() => {
        if (timer < 0) {
            clearInterval(intervalId.current);
        }
    }, [timer])

    return [timer, start];
};

export default useSecondCountDownTimer;