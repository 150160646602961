import {AxiosResponse} from "axios";
import {IResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {IChangePassword} from "../../interfaces/account/IChangePassword";
import {IRestore} from "../../interfaces/account/IRestore";
import {IRestoreLogin} from "../../interfaces/account/IRestoreLogin";
import {IRegisterNewSupplier} from "../../interfaces/account/IRegisterNewSupplier";
import {IRegisterExistingSupplier} from "../../interfaces/account/IRegisterExistingSupplier";

export const useChangePassword = (): {
    isLoading: boolean,
    cancel: () => void,
    changePassword: (model: IChangePassword) => Promise<AxiosResponse<IResponse> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IChangePassword>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/account/changePassword`
    });

    const changePassword = useCallback(async (model: IChangePassword) => await execute(model), [execute]);

    return {changePassword, isLoading, cancel};
};

export const useRestorePassword = (): {
    isLoading: boolean,
    cancel: () => void,
    restorePassword: (model: IRestore) => Promise<AxiosResponse<IResponse> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IRestore>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/account/restorePassword`
    });

    const restorePassword = useCallback(async (model: IRestore) => await execute(model), [execute]);

    return {restorePassword, isLoading, cancel};
};

export const useGetRestorePasswordData = (): {
    isLoading: boolean,
    cancel: () => void,
    getRestorePasswordData: (accessCode: string, token: string, device: string) => Promise<AxiosResponse<IResponse> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse>({
        method: 'get',
    });

    const getRestorePasswordData = useCallback(async (accessCode: string, token: string, device: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/account/getRestorePasswordData?accessCode=${accessCode}&token=${token}&device=${device}`
    }), [execute]);

    return {getRestorePasswordData, isLoading, cancel};
};

export const useAnonymousChangePassword = (): {
    isLoading: boolean,
    cancel: () => void,
    anonymousChangePassword: (model: IChangePassword) => Promise<AxiosResponse<IResponse> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IChangePassword>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/account/anonymousChangePassword`
    });

    const anonymousChangePassword = useCallback(async (model: IChangePassword) => await execute(model), [execute]);

    return {anonymousChangePassword, isLoading, cancel};
};

export const useRestoreLogin = (): {
    isLoading: boolean,
    cancel: () => void,
    restoreLogin: (model: IRestoreLogin) => Promise<AxiosResponse<IResponse> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IRestoreLogin>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/account/restoreLogin`
    });

    const restoreLogin = useCallback(async (model: IRestoreLogin) => await execute(model), [execute]);

    return {restoreLogin, isLoading, cancel};
};

export const useRegisterNewSupplier = (): {
    isLoading: boolean,
    cancel: () => void,
    registerNewSupplier: (model: IRegisterNewSupplier) => Promise<AxiosResponse<IResponse> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IRegisterNewSupplier>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/account/registerNewSupplier`
    });

    const registerNewSupplier = useCallback(async (model: IRegisterNewSupplier) => await execute(model), [execute]);

    return {registerNewSupplier, isLoading, cancel};
};

export const useRegisterExistingSupplier = (): {
    isLoading: boolean,
    cancel: () => void,
    registerExistingSupplier: (model: IRegisterExistingSupplier) => Promise<AxiosResponse<IResponse> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IRegisterExistingSupplier>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/account/registerExistingSupplier`
    });

    const registerExistingSupplier = useCallback(async (model: IRegisterExistingSupplier) => await execute(model), [execute]);

    return {registerExistingSupplier, isLoading, cancel};
};
