import React, {ReactNode} from 'react';
import cl from './BackgroundImageContainer.module.css';

type BackgroundImageContainerProps = {
    image: any;
    children: ReactNode
}

const BackgroundImageContainer: React.FC<BackgroundImageContainerProps> = ({image, children}) => {
    return (
        <div className={cl.backgroundImageContainer}
             style={{backgroundImage: `url(${image})`}}>
            {children}
        </div>
    )
};

export default BackgroundImageContainer;