import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import GridActionRowContextButton, {
    GridActionRowContextButtonItem
} from "../../../../../../../components/ui/Grid/Telerik/components/GridActionRow/components/GridActionRowContextButton/GridActionRowContextButton";
import {useAppSelector} from "../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../features/authentication/authenticationSliceSelectors";
import {useGridLayoutContext} from "../../../../../../../components/uiLayouts/GridLayout/hooks/useGridLayoutContext";
import {IOfferHeaderConfig} from "../../../data/interfaces/IOfferHeaderConfig";
import {getUserRole} from "../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../app/enums/UserRole";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {useOfferApi} from "../../../../../../../app/api/offer";
import AcceptTransportsDialog from "../../../../actions/AcceptTransports/AcceptTransportsDialog";
import DeclineTransportsDialog from "../../../../actions/DeclineTransports/DeclineTransportsDialog";

const OfferActionRowEdiActionButton = () => {
    const {t} = useTranslation();
    const [actions, setActions] = useState<Array<GridActionRowContextButtonItem>>([]);
    const user = useAppSelector(selectCurrentUser);

    const [isDeclineTransportsDialogShown, setIsDeclineTransportsDialogShown] = useState<boolean>(false);
    const [isAcceptTransportsDialogShown, setIsAcceptTransportsDialogShown] = useState<boolean>(false);

    const {
        getTransporeonBookingLink: {
            query: getTransporeonBookingLink
        }
    } = useOfferApi();

    const {
        selectedRows
    } = useGridLayoutContext<IOfferHeaderConfig>()

    useEffect(() => {
        if (selectedRows.length <= 0) {
            setActions([]);

            return;
        }

        setActions(buildActionList());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows, user]);

    const buildActionList = (): Array<GridActionRowContextButtonItem> => {
        if (!user) {
            return [];
        }

        let res: Array<GridActionRowContextButtonItem> = [];
        let role = getUserRole(user);

        if (role === UserRole.ServiceProvider) {
            return res;
        }

        let hasEdi: boolean = true;
        let hasSpotOfferType = false;

        selectedRows.forEach(data => {
            if (data["hasEdi"] === undefined || data["hasEdi"] === null || data["hasEdi"] === false) {
                hasEdi = false;
            }

            if (data["offerType"] !== null && data["offerType"] !== '' && data["offerType"] === 'SPOT') {
                hasSpotOfferType = true;
            }
        });

        if (!hasEdi) {
            return res;
        }

        res.push({
            id: 'accept-transport-btn',
            disabled: false,
            title: selectedRows.length > 1
                ? t("shared.btn.accept-transportations")
                : t("shared.btn.accept-transportation"),
            onClick: () => setIsAcceptTransportsDialogShown(prev => !prev)
        });

        if (selectedRows.length === 1 && selectedRows[0]["hasBookingData"] !== undefined &&
            selectedRows[0]["hasBookingData"] !== null && selectedRows[0]["hasBookingData"] === true) {
            res.push({
                id: 'show-in-transporeon-time-slot-btn',
                disabled: false,
                title: t("shared.btn.show-in-transporeon-time-slot"),
                onClick: async () => {
                    try {
                        const response = await getTransporeonBookingLink(selectedRows[0].id);
                        if (response?.status === 200) {
                            if (response.data.data) {
                                window.open(response.data.data, '_blank');
                            } else {
                                toast.error('Transporeon booking link not found');
                            }
                        }
                    } catch (e) {
                        if (isAxiosError(e)) {
                            toast.error(`Unable to get transporeon booking link, message: ${e.message}`);
                        }
                    }
                }
            });
        }

        if (!hasSpotOfferType) {
            res.push({
                id: 'decline-transport-btn',
                disabled: false,
                title: selectedRows.length > 1
                    ? t("shared.btn.decline-transportations")
                    : t("shared.btn.decline-transportation"),
                onClick: () => setIsDeclineTransportsDialogShown(prev => !prev)
            });
        }

        return res;
    }

    return (
        <>
            <AcceptTransportsDialog id={selectedRows !== null && selectedRows !== undefined
                ? selectedRows.map(item => item.id)
                : []}
                                    show={isAcceptTransportsDialogShown}
                                    setShow={setIsAcceptTransportsDialogShown}/>

            <DeclineTransportsDialog id={selectedRows !== null && selectedRows !== undefined
                ? selectedRows.map(item => item.id)
                : []}
                                     show={isDeclineTransportsDialogShown}
                                     setShow={setIsDeclineTransportsDialogShown}/>

            <GridActionRowContextButton title={t("shared.labels.actions")}
                                        icon={"faEllipsisHorizontal"}
                                        actions={actions}
                                        postIconText={'EDI'}/>
        </>
    );
};

export default OfferActionRowEdiActionButton;