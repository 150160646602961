import React from 'react';
import {IQuote} from "../../../../../../app/interfaces/quote/IQuote";
import {useTranslation} from "react-i18next";
import uuid from "react-uuid";
import {getRemarkNameResource} from "../../../../../../helpers/remark";
import {RemarkName} from "../../../../../../app/enums/RemarkName";
import Checkbox from "../../../../../../components/ui/Checkbox/Checkbox";

type QuoteRemarksProps = {
    quote: IQuote;
}

const QuoteRemarks: React.FC<QuoteRemarksProps> = ({quote}) => {
    const {t} = useTranslation();

    if (quote.remarks.length <= 0 && quote.qRemarks.length <= 0) {
        return null;
    }

    return (
        <div className={'details-box-default-container'}>
            {Object.keys(quote.qRemarks).map((key, index) => {
                if (key === null) {
                    return null;
                }

                let item = quote.qRemarks[key as keyof typeof quote.qRemarks] as any;

                return (
                    <div className={`view-row q-remark-${index}`} key={`q-${item.key}-${uuid()}`}
                         style={{marginBottom: '5px'}}>
                        <div className={'two-columns'}>
                            <b >
                                {t(getRemarkNameResource(item.key))}
                            </b>
                        </div>
                        <div className={'two-columns'} style={{wordBreak: 'break-word'}}>
                            {item.key === RemarkName.FROM_SITE
                                ? <Checkbox checked={true} change={() => {
                                    //ignore
                                }} disabled={true}/>
                                : <>{item.value}</>
                            }
                        </div>
                    </div>
                );
            })}

            {quote.remarks.map((item, index) => {
                if (item === null || item.code === null) {
                    return null;
                }

                return (
                    <div className={`view-row remark-${index}`} key={`${item.code}-${uuid()}`}
                         style={{marginBottom: '5px'}}>
                        <div className={'two-columns'}>
                            <b >
                                {t(getRemarkNameResource(item.code))}
                            </b>
                        </div>
                        <div className={'two-columns'} style={{wordBreak: 'break-word'}}>
                            <div dangerouslySetInnerHTML={{__html: item.text ?? ''}}/>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default QuoteRemarks;
