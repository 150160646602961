import React, {useEffect, useState} from 'react';
import bg_image from "../../../../../assets/images/login-bg.jpg";
import cl from '../RestorePassword.module.css';
import LanguageSwitcher from "../../../../../components/ui/LanguageSwitcher/LanguageSwitcher";
import BackgroundImageContainer from "../../../../../components/ui/BackgroundImageContainer/BackgroundImageContainer";
import Container from "../../../../../components/ui/Container/Container";
import useDefaultSiteLanguages from "../../../../../hooks/useDefaultSiteLanguages/useDefaultSiteLanguages";
import useMediaQuery from "../../../../../hooks/useMediaQuery/useMediaQuery";
import {Card} from "@progress/kendo-react-layout";
import {useTranslation} from "react-i18next";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {useAccountApi} from "../../../../../app/api/account";
import {BLErrorCode} from "../../../../../app/enums/BLErrorCode";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import useGoogleReCaptchaV3 from "../../../../../hooks/useGoogleReCaptchaV3/useGoogleReCaptchaV3";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import Alert from "../../../../../components/ui/Alert/Alert";
import {useNavigate} from "react-router-dom";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import ChangePasswordForm
    from "../../../../../components/shared/components/ChangePassword/ChangePasswordForm/ChangePasswordForm";
import useDefaultSitePasswordRules from "../../../../../hooks/useDefaultSitePasswordRules/useDefaultSitePasswordRules";
import {IChangePassword} from "../../../../../app/interfaces/account/IChangePassword";
import {toast} from "react-toastify";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import CopyRightFooter from "../../../../../components/ui/CopyRightFooter/CopyRightFooter";
import {useAppSelector} from "../../../../../app/store";
import {selectDeviceId} from "../../../../../features/application/applicationSliceSelectors";
import {IUserDevice} from "../../../../../app/interfaces/user/IUserDevice";
import {getUserDevice} from "../../../../../utils/device";

type RestorePasswordActionProps = {
    code: string;
};

const alertStyle: React.CSSProperties = {
    margin: '0px 0px 5px 0px',
    fontSize: '11px',
    padding: '5px 10px',
    fontWeight: 'bold',
    justifyContent: 'center',
    textAlign: 'center'
};

const RestorePasswordAction: React.FC<RestorePasswordActionProps> = ({code}) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

   /* const {loaded, executeReCaptcha} = useGoogleReCaptchaV3({
        siteKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '',
        language: i18n.language
    });*/
    const {
        getRestorePasswordData: {
            query: getRestorePasswordData,
            isLoading: getRestorePasswordDataIsLoading,
            cancel: cancelGetRestorePasswordData
        },
        anonymousChangePassword: {
            mutation: anonymousChangePassword
        }
    } = useAccountApi();

    const languages = useDefaultSiteLanguages();
    const isMaxWidth470 = useMediaQuery('(max-width:450px)');
    const isMaxHeight490 = useMediaQuery('(max-height:490px)');
    const rules = useDefaultSitePasswordRules();

    const [errors, setErrors] = useState<Array<IError>>([]);
    const [changePasswordErrors, setChangePasswordErrors] = useState<Array<IError>>([]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [codeIsChecked, setCodeIsChecked] = useState<boolean>(false);

    useEffect(() => {
       // if (!loaded) {
       //     return;
       // }

        (async () => {
            try {

               /* if (!loaded || !executeReCaptcha) {
                    setErrors([{
                        errorCode: BLErrorCode.InnerError,
                        description: t('shared.errors.google-recaptcha-loaded'),
                        field: 'summary'
                    }]);

                    return;
                }*/

               // const token: string = await executeReCaptcha('restore/submit');

               /* if (!token) {
                    setErrors([{
                        errorCode: BLErrorCode.InnerError,
                        description: t('shared.error.google-recaptcha-loaded'),
                        field: 'summary'
                    }]);
                }*/
                const userDevice: IUserDevice = await getUserDevice();

                const response = await getRestorePasswordData(code, '', userDevice.browserName + " " + userDevice.browserVersion);

                if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                    setCodeIsChecked(true);
                }
            } catch (error) {
                if (isAxiosError(error) && error.response && error.response.data) {
                    if (error.response.data.resultCode === ResponseResultCode.NotFound ||
                        error.response.data.resultCode === ResponseResultCode.NotValidData) {
                        setErrors([{
                            errorCode: BLErrorCode.InnerError,
                            description: t("login.errors.restore-link-expired"),
                            field: 'summary'
                        }]);
                    } else if (error.response.data.resultCode === ResponseResultCode.ServerError) {
                        setErrors([{
                            errorCode: BLErrorCode.InnerError,
                            description: `${t("shared.errors.server-error")} (${error.response.data.message})`,
                            field: 'summary'
                        }]);
                    }
                }
            }
        })();

        return () => {
            cancelGetRestorePasswordData();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setPassword = async (model: IChangePassword) => {
        setErrors([]);
        setChangePasswordErrors([])
        setDisabled(prev => !prev);

        try {
            model.code = code;
            const response = await anonymousChangePassword(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                toast.success(<ToastLayout text={t("account.password.password-changed-text")}
                                           title={t("account.password.change-password")}/>);

                navigate('/login');
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.data.resultCode === ResponseResultCode.ServerError) {
                    setChangePasswordErrors([
                        {
                            field: 'summary',
                            errorCode: BLErrorCode.InnerError,
                            description: `${t("login.errors.change-password-error")} (${err.message})`
                        }
                    ]);
                } else {
                    setChangePasswordErrors([
                        {
                            field: 'summary',
                            errorCode: BLErrorCode.InnerError,
                            description: `${t("login.errors.change-password-error")} (${err.message})`
                        }
                    ]);
                }
            }
        }

        setDisabled(prev => !prev);
    };

    return (
        <BackgroundImageContainer image={bg_image}>
            <Container centerContent={true} height={'calc(100% - 30px)'}>
                <Card style={isMaxWidth470 ? {width: 'calc(100% - 20px)'} : {width: '550px'}}>
                    <div className={cl.switcher}>
                        <LanguageSwitcher languages={languages} languageIconPosition={'right'}/>
                    </div>
                    <div className={cl.logo}
                         style={isMaxHeight490 ? {display: 'none'} : undefined}>
                        <img className={'prevent-selection'}
                             src={'/images/logo_main.png'}
                             alt={'logo'}/>
                    </div>
                    <div className={`${cl.label} prevent-selection`}>
                        {t("login.restore.restore-label")}
                    </div>

                    <div className={'pd-10'}>
                        {getRestorePasswordDataIsLoading
                            ? <FormLoadingScreen height={`150px`} style={{padding: '5px'}}/>
                            : null
                        }

                        {errors.map(err => {
                            return (
                                <Alert type={'Error'} style={alertStyle} key={err.description}>
                                    {err.description}
                                </Alert>
                            )
                        })}

                        {changePasswordErrors.map(err => {
                            return (
                                <Alert type={'Error'} style={alertStyle} key={err.description}>
                                    {err.description}
                                </Alert>
                            )
                        })}

                        {codeIsChecked && errors.length <= 0 &&
                            <>
                                <ChangePasswordForm id={'change-password-form'}
                                                    disabled={disabled}
                                                    save={setPassword}
                                                    rules={rules}/>

                                <div className={cl.center}>
                                    <Button buttonType={ButtonType.Primary}
                                            form='change-password-form'
                                            type='submit'
                                            style={{minWidth: '250px'}}
                                            className={'confirm-button'}
                                            disabled={disabled}
                                            isLoading={disabled}>
                                        {t("shared.btn.save")}
                                    </Button>
                                </div>
                            </>
                        }

                        <hr className={cl.separator}/>

                        <div className={cl.linkBtns}>
                            <button disabled={getRestorePasswordDataIsLoading}
                                    onClick={() => {
                                        if (getRestorePasswordDataIsLoading) {
                                            return;
                                        }

                                        navigate('/login');
                                    }}>
                                {t("login.btn.back-to-login-page")}
                            </button>
                        </div>
                    </div>
                </Card>
            </Container>
            <CopyRightFooter/>
        </BackgroundImageContainer>
    );
};

export default RestorePasswordAction;
