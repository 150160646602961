import {ReactNode} from "react";
import cl from '../components/LocationMapMapSection/LocationMapMapSection.module.css'
import {IMapPositioningGetAction} from "../../../../../../app/interfaces/shipment/IMapPositioningGetAction";

export const buildLocationMapCurrentTrackingPositionContent = (model: IMapPositioningGetAction): ReactNode => {
    return (
        <div>
            <div className={`${cl.pin} ${model.importDirection ? cl.arrowLeft : cl.arrowRight}`}
                 data-type={model.inGeofenceArea ? 'in' : 'out'}>

            </div>
            <div className={cl.pinPulsation}
                 data-type={model.inGeofenceArea ? 'in' : 'out'}>

            </div>
        </div>
    )
}
