import {
    useGetInvoice,
    useGetInvoiceAttachment, useGetShipmentInvoiceAnonymous, useGetShipmentInvoiceAuthorize,
    useRequestAnswerAction,
    useRequestBlockAction, useRequestBlockActionAnonymous,
    useRequestPaymentAction, useRequestPaymentActionAnonymous
} from "./requests";

const useInvoiceApi = () => {
    const {
        isLoading: getInvoiceIsLoading,
        cancel: cancelGetInvoice,
        getInvoice
    } = useGetInvoice();

    const {
        isLoading: getInvoiceAttachmentIsLoading,
        cancel: cancelGetInvoiceAttachment,
        getInvoiceAttachment
    } = useGetInvoiceAttachment();

    const {
        isLoading: requestAnswerActionIsLoading,
        cancel: cancelRequestAnswerAction,
        requestAnswerAction
    } = useRequestAnswerAction();

    const {
        isLoading: requestPaymentActionIsLoading,
        cancel: cancelRequestPaymentAction,
        requestPaymentAction
    } = useRequestPaymentAction();

    const {
        isLoading: requestBlockActionIsLoading,
        cancel: cancelRequestBlockAction,
        requestBlockAction
    } = useRequestBlockAction();

    const {
        isLoading: getShipmentInvoiceAuthorizeIsLoading,
        cancel: cancelGetShipmentInvoiceAuthorize,
        getShipmentInvoiceAuthorize
    } = useGetShipmentInvoiceAuthorize();

    const {
        isLoading: getShipmentInvoiceAnonymousIsLoading,
        cancel: cancelGetShipmentInvoiceAnonymous,
        getShipmentInvoiceAnonymous
    } = useGetShipmentInvoiceAnonymous();

    const {
        isLoading: requestPaymentActionAnonymousIsLoading,
        cancel: cancelRequestPaymentActionAnonymous,
        requestPaymentActionAnonymous
    } = useRequestPaymentActionAnonymous();

    const {
        isLoading: requestBlockActionAnonymousIsLoading,
        cancel: cancelRequestBlockActionAnonymous,
        requestBlockActionAnonymous
    } = useRequestBlockActionAnonymous();

    return {
        getInvoiceAttachment: {
            query: getInvoiceAttachment,
            isLoading: getInvoiceAttachmentIsLoading,
            cancel: cancelGetInvoiceAttachment
        },
        getInvoice: {
            query: getInvoice,
            isLoading: getInvoiceIsLoading,
            cancel: cancelGetInvoice
        },
        requestBlockAction: {
            mutation: requestBlockAction,
            isLoading: requestBlockActionIsLoading,
            cancel: cancelRequestBlockAction
        },
        requestPaymentAction: {
            mutation: requestPaymentAction,
            isLoading: requestPaymentActionIsLoading,
            cancel: cancelRequestPaymentAction
        },
        requestAnswerAction: {
            mutation: requestAnswerAction,
            isLoading: requestAnswerActionIsLoading,
            cancel: cancelRequestAnswerAction
        },
        getShipmentInvoiceAuthorize: {
            query: getShipmentInvoiceAuthorize,
            isLoading: getShipmentInvoiceAuthorizeIsLoading,
            cancel: cancelGetShipmentInvoiceAuthorize
        },
        getShipmentInvoiceAnonymous: {
            query: getShipmentInvoiceAnonymous,
            isLoading: getShipmentInvoiceAnonymousIsLoading,
            cancel: cancelGetShipmentInvoiceAnonymous
        },
        requestBlockActionAnonymous: {
            mutation: requestBlockActionAnonymous,
            isLoading: requestBlockActionAnonymousIsLoading,
            cancel: cancelRequestBlockActionAnonymous
        },
        requestPaymentActionAnonymous: {
            mutation: requestPaymentActionAnonymous,
            isLoading: requestPaymentActionAnonymousIsLoading,
            cancel: cancelRequestPaymentActionAnonymous
        }
    };
}

export default useInvoiceApi;