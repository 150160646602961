import React, {useEffect, useRef, useState} from 'react';
import {GridActionRowSelectOptionProps} from "../GridActionRowSelectOption/GridActionRowSelectOption";
import {createPortal} from "react-dom";
import cl from './GridActionRowSelectPopup.module.css';
import {Tooltip} from "react-tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {useClickOutside} from "../../../../../../../../../hooks/useOnClickOutside/useOnClickOutside";

type GridActionRowSelectPopupProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    options: Array<GridActionRowSelectOptionProps>;
    onOptionClick: (option: GridActionRowSelectOptionProps) => void;
    container: React.MutableRefObject<HTMLDivElement | null>;
    width: number;
}

const GridActionRowSelectPopup: React.FC<GridActionRowSelectPopupProps> = ({
                                                                               open,
                                                                               setOpen,
                                                                               container,
                                                                               width,
                                                                               options,
                                                                               onOptionClick
                                                                           }) => {
    const ref = useRef<HTMLDivElement | null>(null);

    const [point, setPoint] = useState<{
        top: number,
        left: number
    } | null>();

    useClickOutside(ref, (ev) => {
        if (ev.target && container.current && container.current?.contains(ev.target as Node)) {
            return;
        }

        setOpen(false);
    });

    useEffect(() => {
        if (!container || !container.current || !open) {
            setPoint(null);

            return;
        }

        let position = container.current?.getBoundingClientRect();

        if (position) {
            setPoint({
                top: position.top + 2 + container.current?.clientHeight ?? 0,
                left: position.left
            });
        }
    }, [container, open]);

    if (!point || !container.current) {
        return null;
    }

    return (
        <>
            {createPortal(
                <div style={{left: point.left, top: point.top, width: `${width}px`}}
                     ref={ref}
                     className={`${cl.popup} ${open ? cl.open : ''}`}>
                    {options.map(opt => {
                        return (
                            <div key={opt.id}
                                 onClick={() => {
                                     if (opt.disabled) {
                                         return;
                                     }

                                     onOptionClick(opt);
                                 }}
                                 className={`${cl.option} ${opt.disabled ? cl.disabled : ''} transition-all-500`}>
                                <div title={opt.text} className={`${cl.text} prevent-selection`}>
                                    {opt.text}
                                </div>
                                <div className={cl.tooltipContainer}>
                                    {opt.tooltip &&
                                        <>
                                            <Tooltip id={`grid-action-row-option-tooltip-${opt.id}`}
                                                     style={{
                                                         maxWidth: '250px',
                                                         zIndex: '1002',
                                                         overflowWrap: 'break-word',
                                                         whiteSpace: 'normal'
                                                     }}
                                                     place={'right'}/>
                                            <FontAwesomeIcon
                                                data-tooltip-id={`grid-action-row-option-tooltip-${opt.id}`}
                                                data-tooltip-content={opt.tooltip}
                                                icon={faCircleInfo}
                                                className={`${cl.tooltip} transition-all-500`}/>
                                        </>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>,
                container.current
            )}
        </>
    );
};

export default GridActionRowSelectPopup;