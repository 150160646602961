import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import RemoveFromFreeStock from "./RemoveFromFreeStock";

type RemoveFromFreeStockDialogProps = {
    id: string | Array<string>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const RemoveFromFreeStockDialog: React.FC<RemoveFromFreeStockDialogProps> = ({
                                                                                 id,
                                                                                 show,
                                                                                 setShow
                                                                             }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'remove-from-free-stock-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.btn.remove-from-free-stock")}>
            <RemoveFromFreeStock id={id}
                                 close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default RemoveFromFreeStockDialog;