import React, {useEffect, useState} from 'react';
import GridActionRowContextButton, {
    GridActionRowContextButtonItem
} from "../../../../../../../components/ui/Grid/Telerik/components/GridActionRow/components/GridActionRowContextButton/GridActionRowContextButton";
import {useTranslation} from "react-i18next";
import {useGridLayoutContext} from "../../../../../../../components/uiLayouts/GridLayout/hooks/useGridLayoutContext";
import {IShipmentHeaderConfig} from "../../../data/interfaces/IShipmentHeaderConfig";
import {useAppSelector} from "../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../app/enums/UserRole";
import AssignShipmentDialog from "../../../../actions/AssignShipment/AssignShipmentDialog";
import ShipmentsEventListDialog from "../../../../actions/ShipmentsEventList/ShipmentsEventListDialog";
import CopyOrDialog from "../../../../actions/CopyOr/CopyOrDialog";
import SPUnblockDialog from "../../../../actions/SPUnblock/SPUnblockDialog";
import {useShipmentApi} from "../../../../../../../app/api/shipment";
import {ResponseResultCode} from "../../../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import CancelShipmentDialog from "../../../../actions/CancelShipment/CancelShipmentDialog";
import ConfirmShipmentDialog from "../../../../actions/ConfirmShipment/ConfirmShipmentDialog";
import UnConfirmShipmentDialog from "../../../../actions/UnConfirmShipment/UnConfirmShipmentDialog";
import AssociateTagDialog
    from "../../../../../../../components/shared/components/Tag/actions/AssociateTag/AssociateTagDialog";
import {OrderType} from "../../../../../../../app/enums/OrderType";
import ShowTimeSlotDialog from "../../../../actions/ShowTimeSlot/ShowTimeSlotDialog";
import {ButtonType} from "../../../../../../../components/ui/Button/Button";
import DeleteShipmentDialog from "../../../../actions/DeleteShipment/DeleteShipmentDialog";

const ShipmentActionRowActionButton = () => {
    const {t} = useTranslation();
    const [actions, setActions] = useState<Array<GridActionRowContextButtonItem>>([]);
    const user = useAppSelector(selectCurrentUser);

    const [isAddEventDialogOpened, setIsAddEventDialogOpened] = useState<boolean>(false);
    const [isAssignOrderDialogOpened, setIsAssignOrderDialogOpened] = useState<boolean>(false);
    const [isCopyOrDialogOpened, setIsCopyOrDialogOpened] = useState<boolean>(false);
    const [isCancelDialogOpened, setIsCancelDialogOpened] = useState<boolean>(false);
    const [isConfirmDialogOpened, setIsConfirmDialogOpened] = useState<boolean>(false);
    const [isUnConfirmDialogOpened, setIsUnConfirmDialogOpened] = useState<boolean>(false);
    const [isAssociateDialogShown, setIsAssociateDialogShown] = useState<boolean>(false);
    const [isSPUnblockDialogOpened, setIsSPUnblockDialogOpened] = useState<boolean>(false);
    const [isShowTimeSlotDialogOpened, setIsShowTimeSlotDialogOpened] = useState<boolean>(false);
    const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState<boolean>(false);
    const {
        selectedRows
    } = useGridLayoutContext<IShipmentHeaderConfig>()

    const {
        markShipmentsAsViewed: {mutation: markShipmentsAsViewed},
        maskShipmentsAsUnViewed: {mutation: markShipmentsAsUnViewed}
    } = useShipmentApi();

    useEffect(() => {
        if (selectedRows.length <= 0) {
            setActions([]);

            return;
        }

        setActions(buildActionList());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows, user]);

    const buildActionList = (): Array<GridActionRowContextButtonItem> => {
        if (!user) {
            return [];
        }

        let res: Array<GridActionRowContextButtonItem> = [];
        let role = getUserRole(user);

        let isCompleted: boolean = true;
        let hasShipmentId: boolean = true;
        let hasReleaseId: boolean = true;
        let isUnConfirm: boolean = true;
        let isArchive: boolean = true;
        let hasBookingData: boolean = true;

        selectedRows.forEach(data => {
            if (data.sId === undefined || data.sId === null) {
                hasShipmentId = false;
            }

            if (data.rId === undefined || data.rId === null) {
                hasReleaseId = false;
            }

            if (data["isUnconfirm"] === null || data["isUnconfirm"] === undefined || data["isUnconfirm"] === false) {
                isUnConfirm = false;
            }

            if (data.isFinished === null || data.isFinished === undefined || data.isFinished === false) {
                isCompleted = false;
            }

            if (data.isArchive === null || data.isArchive === undefined || !data.isArchive) {
                isArchive = false;
            }
            if (data.hasBookingData === null || data.hasBookingData === undefined || !data.hasBookingData) {
                hasBookingData = false;
            }
        });

        if (role === UserRole.ServiceProvider) {
            if (hasShipmentId && !isCompleted && !isArchive) {
                res.push({
                    id: 'event-action-btn',
                    disabled: false,
                    title: t("shared.btn.add-event"),
                    onClick: () => setIsAddEventDialogOpened(prev => !prev)
                });
            }
        } else if (role === UserRole.Administrator || role === UserRole.Forwarder) {
            if (role === UserRole.Forwarder && !isArchive) {
                res.push({
                    id: 'add-tag',
                    disabled: false,
                    title: t("shared.btn.add-tag"),
                    onClick: () => setIsAssociateDialogShown(prev => !prev)
                });
            }

            if (hasShipmentId && !isArchive) {
                if (isUnConfirm) {
                    res.push({
                        id: 'confirm-shipment-btn',
                        disabled: false,
                        title: t("shared.btn.confirm-shipment"),
                        onClick: () => setIsConfirmDialogOpened(prev => !prev)
                    });
                } else {
                    res.push({
                        id: 'un-confirm-shipment-btn',
                        disabled: false,
                        title: t("shared.btn.un-confirm-shipment"),
                        onClick: () => setIsUnConfirmDialogOpened(prev => !prev),
                        color: 'red'
                    });
                }
            }

            if (selectedRows.length === 1) {
                res.push({
                    id: 'copy-or-btn',
                    disabled: false,
                    title: t("shared.btn.copy-or"),
                    onClick: () => setIsCopyOrDialogOpened(prev => !prev)
                });
            }

            if (hasShipmentId && !isArchive) {
                res.push({
                    id: 'event-action-btn',
                    disabled: false,
                    title: t("shared.btn.add-event"),
                    onClick: () => setIsAddEventDialogOpened(prev => !prev)
                });
            }

            if (hasShipmentId && !isArchive) {
                res.push({
                    id: 'assign-shipment-action-btn',
                    disabled: false,
                    title: t("shared.btn.assign-order"),
                    onClick: () => setIsAssignOrderDialogOpened(prev => !prev)
                });
            }
            if (selectedRows.length === 1 && hasShipmentId && hasBookingData)  {
                res.push({
                    id: 'time-slot-btn',
                    disabled: false,
                    title: t("shared.btn.show-time-slot"),
                    onClick: () => setIsShowTimeSlotDialogOpened(prev => !prev)
                });
            }


        }
        if (!isArchive && selectedRows.filter(data => data.isNew === true).length > 0) {
            res.push({
                id: 'mark-as-viewed',
                disabled: false,
                title: selectedRows.length === 1
                    ? t("shared.btn.mark-item-as-viewed")
                    : t("shared.btn.mark-items-as-viewed"),
                onClick: async () => await markAsViewed()
            });
        }
        if (!isArchive && selectedRows.filter(data => data.isNew !== true).length > 0) {
            res.push({
                id: 'mark-as-un-viewed',
                disabled: false,
                title: selectedRows.length === 1
                    ? t("shared.btn.mark-item-as-un-viewed")
                    : t("shared.btn.mark-items-as-un-viewed"),
                onClick: async () => await markAsUnViewed()
            });
        }


        if ((role === UserRole.Administrator || role === UserRole.Forwarder) && selectedRows.length === 1) {
            res.push({
                id: 'sp-unblock-btn',
                disabled: false,
                title: t("shared.btn.sp-unblock"),
                wraped: true,
                onClick: () => setIsSPUnblockDialogOpened(prev => !prev)
            });

            if (!isArchive && (hasReleaseId && hasShipmentId) || isUnConfirm) {
                res.push({
                    id: 'cancel-shipment',
                    disabled: false,
                    title: selectedRows.length === 1
                        ? t("shared.btn.cancel-order")
                        : t("shared.btn.cancel-orders"),
                    onClick: () => setIsCancelDialogOpened(prev => !prev),
                    color: 'red'
                });
            }

            if (!isArchive && isUnConfirm) {
                res.push({
                    disabled: false,
                    id: 'delete',
                    title: t("shared.btn.delete"),
                    color: 'red',
                    onClick: () => setIsDeleteDialogOpened(prev => !prev)
                });
            }
        }


        return res;
    }

    const markAsViewed = async () => {
        let ids: Array<{
            id: number;
            sId: string;
        }> = [];

        selectedRows.forEach(row => {
            if (row.isNew !== null && row.isNew !== undefined && row.isNew === true) {
                ids.push({
                    id: row.shipmentId,
                    sId: row.sId
                });
            }
        })

        if (ids.length <= 0) {
            return;
        }

        try {
            document.dispatchEvent(new CustomEvent('onGridMessage', {
                detail: {
                    action: 'updateRows',
                    rows: [...ids].map((item) => {
                        return {
                            id: item.sId,
                            isRowLoading: true
                        };
                    })
                }
            }));

            const response = await markShipmentsAsViewed({
                ids: [...ids].map((item) => item.id.toString())
            });

            if (response && response.status === 200 &&
                response.data && response.data.resultCode === ResponseResultCode.Ok) {
                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [...ids].map((item) => {
                            return {
                                id: item.sId,
                                isRowLoading: false,
                                isNew: false
                            };
                        }),
                        clearSelection: true
                    }
                }));
            }
        } catch {
            toast.error('Unable to change viewed state of shipments');

            document.dispatchEvent(new CustomEvent('onGridMessage', {
                detail: {
                    action: 'updateRows',
                    rows: [...ids].map((item) => {
                        return {
                            id: item.sId,
                            isRowLoading: false
                        };
                    })
                }
            }));
        }
    }

    const markAsUnViewed = async () => {
        let ids: Array<{
            id: number;
            sId: string;
        }> = [];

        selectedRows.forEach(row => {
            if (row.isNew !== null && row.isNew !== undefined && row.isNew === false) {
                ids.push({
                    id: row.shipmentId,
                    sId: row.sId
                });
            }
        })

        if (ids.length <= 0) {
            return;
        }

        try {
            document.dispatchEvent(new CustomEvent('onGridMessage', {
                detail: {
                    action: 'updateRows',
                    rows: [...ids].map((item) => {
                        return {
                            id: item.sId,
                            isRowLoading: true,
                        };
                    })
                }
            }));

            const response = await markShipmentsAsUnViewed({
                ids: [...ids].map((item) => item.id.toString())
            });

            if (response && response.status === 200 &&
                response.data && response.data.resultCode === ResponseResultCode.Ok) {
                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [...ids].map((item) => {
                            return {
                                id: item.sId,
                                isRowLoading: false,
                                isNew: true
                            };
                        }),
                        clearSelection: true
                    }
                }));
            }
        } catch {
            toast.error('Unable to change viewed state of shipments');

            document.dispatchEvent(new CustomEvent('onGridMessage', {
                detail: {
                    action: 'updateRows',
                    rows: [...ids].map((item) => {
                        return {
                            id: item.sId,
                            isRowLoading: false
                        };
                    })
                }
            }));
        }
    }


    return (
        <>
            <AssociateTagDialog orderReleaseGid={selectedRows !== null && selectedRows !== undefined
                ? selectedRows.map(item => item.id).join(';')
                : ''}
                                orderType={OrderType.Shipment}
                                show={isAssociateDialogShown}
                                setShow={setIsAssociateDialogShown}/>

            <ConfirmShipmentDialog id={selectedRows.filter(e => e.sId !== null && e.sId !== undefined).map(e => e.sId)}
                                   show={isConfirmDialogOpened}
                                   setShow={setIsConfirmDialogOpened}/>

            <UnConfirmShipmentDialog
                id={selectedRows.filter(e => e.sId !== null && e.sId !== undefined).map(e => e.sId)}
                show={isUnConfirmDialogOpened}
                setShow={setIsUnConfirmDialogOpened}/>

            <CancelShipmentDialog
                id={selectedRows.filter(e => e.sId !== null && e.sId !== undefined && e.rId !== null && e.rId !== undefined).map(e => {
                    return `${e.sId}`;
                })}
                show={isCancelDialogOpened}
                setShow={setIsCancelDialogOpened}/>

            <CopyOrDialog id={selectedRows.length > 0 ? selectedRows[0].rId : ''}
                          show={isCopyOrDialogOpened}
                          setShow={setIsCopyOrDialogOpened}/>
            <ShowTimeSlotDialog id={selectedRows.length > 0 ? selectedRows[0].sId : ''}
                          show={isShowTimeSlotDialogOpened}
                          setShow={setIsShowTimeSlotDialogOpened}/>
            <ShipmentsEventListDialog user={user}
                                      id={selectedRows.filter(e => e.sId !== null && e.sId !== undefined).map(e => {
                                          return `${e.sId}`;
                                      })}
                                      show={isAddEventDialogOpened}
                                      setShow={setIsAddEventDialogOpened}/>

            <AssignShipmentDialog id={selectedRows.filter(e => e.sId !== null && e.sId !== undefined).map(e => e.sId)}
                                  show={isAssignOrderDialogOpened}
                                  setShow={setIsAssignOrderDialogOpened}/>

            <SPUnblockDialog id={selectedRows.length > 0 ? selectedRows[0].sId : ''}
                          show={isSPUnblockDialogOpened}
                          setShow={setIsSPUnblockDialogOpened}/>

            <GridActionRowContextButton title={t("shared.labels.actions")}
                                        icon={"faEllipsisHorizontal"}
                                        actions={actions}/>

            <DeleteShipmentDialog id={selectedRows.length > 0 ?selectedRows[0].rId :""}
                                  sId={selectedRows.length > 0 ?selectedRows[0].sId : ""}
                                  show={isDeleteDialogOpened}
                                  setShow={setIsDeleteDialogOpened}/>
        </>
    );
};

export default ShipmentActionRowActionButton;
