import React from 'react';
import {SkeletonTheme} from "react-loading-skeleton";
import cl from './SkeletonLoadingScreen.module.css';
import useMediaQuery from "../../../../hooks/useMediaQuery/useMediaQuery";
import SkeletonActionRowLoader from "./components/SkeletonActionRowLoader/SkeletonActionRowLoader";
import SkeletonGridLoader from "./components/SkeletonGridLoader/SkeletonGridLoader";
import SkeletonMapLoader from "./components/SkeletonMapLoader/SkeletonMapLoader";
import SkeletonFormLoader from "./components/SkeletonFormLoader/SkeletonFormLoader";

type SkeletonLoadingScreenProps = {
    type: 'Map' | 'Grid' | 'Details';
};

const SkeletonLoadingScreen = ({type}: SkeletonLoadingScreenProps) => {
    const isMaxWidth720 = useMediaQuery('(max-width:720px)');

    switch (type) {
        case 'Map':
            return (
                <SkeletonTheme baseColor={'#eaeff3'} highlightColor={'#ffffff'}>
                    <div className={'h100 w100 hidden'}>
                        <SkeletonActionRowLoader/>

                        <div data-device={isMaxWidth720 ? 'mobile' : 'pc'}
                             className={cl.container}>
                            <SkeletonMapLoader/>
                        </div>
                    </div>
                </SkeletonTheme>
            );
        case 'Grid':
            return (
                <SkeletonTheme baseColor={'#eaeff3'} highlightColor={'#ffffff'}>
                    <div className={'h100 w100 hidden'}>
                        <SkeletonActionRowLoader/>

                        <div data-device={isMaxWidth720 ? 'mobile' : 'pc'}
                             className={cl.container}>
                            <SkeletonGridLoader/>
                        </div>
                    </div>
                </SkeletonTheme>
            );
        case 'Details':
            return (
                <SkeletonFormLoader/>
            );
    }
};

export default SkeletonLoadingScreen;