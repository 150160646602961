import React from 'react';
import {IQuote} from "../../../../../../app/interfaces/quote/IQuote";
import useMediaQuery from "../../../../../../hooks/useMediaQuery/useMediaQuery";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../helpers/user";
import DetailsBox from "../../../../../../components/ui/DetailsBox/DetailsBox";
import DetailsBoxColumn from "../../../../../../components/ui/DetailsBox/components/DetailsBoxColumn/DetailsBoxColumn";
import QuoteSource from "./fields/QuoteSource/QuoteSource";
import TagFormField from "../../../../../../components/shared/components/Tag/components/TagFormField/TagFormField";
import {OrderType} from "../../../../../../app/enums/OrderType";
import QuoteNumber from "./fields/QuoteNumber/QuoteNumber";
import QuoteForwarder from "./fields/QuoteForwarder/QuoteForwarder";
import {UserRole} from "../../../../../../app/enums/UserRole";
import QuoteCurator from "./fields/QuoteCurator/QuoteCurator";
import QuoteCustomerManager from "./fields/QuoteCustomerManager/QuoteCustomerManager";
import QuoteAvailableTill from "./fields/QuoteAvailableTill/QuoteAvailableTill";
import QuoteClient from "./fields/QuoteClient/QuoteClient";
import QuoteContactPerson from "./fields/QuoteContactPerson/QuoteContactPerson";
import QuoteTransporeon from "./fields/QuoteTransporeon/QuoteTransporeon";
import QuoteCustomerOrderRefNumber from "./fields/QuoteCustomerOrderRefNumber/QuoteCustomerOrderRefNumber";
import QuoteRecallDate from "./fields/QuoteRecallDate/QuoteRecallDate";

type QuoteDetailsBoxProps = {
    quote: IQuote;
    isSidePanelDetails: boolean;
}

const QuoteDetailsBox: React.FC<QuoteDetailsBoxProps> = ({
                                                             isSidePanelDetails,
                                                             quote
                                                         }) => {
    const user = useAppSelector(selectCurrentUser);
    const {t} = useTranslation();
    const isMaxWidth960 = useMediaQuery('(max-width: 960px)');
    const isMaxWidth600 = useMediaQuery('(max-width: 600px)');

    if (!user) {
        return null;
    }

    let role = getUserRole(user);

    return (
        <DetailsBox id={'offer-details-data-box'} isSidePanelDetails={isSidePanelDetails || isMaxWidth960}>
            <DetailsBoxColumn>
                <div className={'details-box-default-container'}>


                    {!isMaxWidth600 && !isSidePanelDetails &&
                        <div className={'details-box-default-container-columns'}
                             data-count={'2'}>
                            <div>
                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-number'}>
                                    <label title={t("quote.form.quote-number")}>
                                        {t("quote.form.quote-number")}
                                    </label>
                                    <div>
                                        <QuoteNumber quote={quote}/>
                                    </div>
                                </div>

                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-forwarder'}>
                                    <label title={t("quote.grid.forwarder")}>
                                        {t("quote.grid.forwarder")}
                                    </label>
                                    <div>
                                        <QuoteForwarder quote={quote}/>
                                    </div>
                                </div>

                                {role === UserRole.Forwarder || role === UserRole.Administrator
                                    ? <>
                                        <div className={'details-box-default-container-item'}
                                             id={'details-box-quote-curator'}>
                                            <label title={t("quote.grid.curator")}>
                                                {t("quote.grid.curator")}
                                            </label>
                                            <div>
                                                <QuoteCurator quote={quote}/>
                                            </div>
                                        </div>
                                        <div className={'details-box-default-container-item'}
                                             id={'details-box-quote-customer-manager'}>
                                            <label title={t("quote.grid.customer-manager")}>
                                                {t("quote.grid.customer-manager")}
                                            </label>
                                            <div>
                                                <QuoteCustomerManager quote={quote}/>
                                            </div>
                                        </div>
                                    </>
                                    : null
                                }


                            </div>
                            <div>
                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-available-till'}>
                                    <label title={t("quote.form.available-till")}>
                                        {t("quote.form.available-till")}
                                    </label>
                                    <div>
                                        <QuoteAvailableTill quote={quote}/>
                                    </div>
                                </div>
                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-recall-date'}>
                                    <label title={t("quote.form.recall-date")}>
                                        {t("quote.form.recall-date")}
                                    </label>
                                    <div>
                                        <QuoteRecallDate quote={quote}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {!isMaxWidth600 && isSidePanelDetails &&
                        <div className={'details-box-default-container-columns'}
                             data-count={'2'}>
                            <div>
                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-number'}>
                                    <label title={t("quote.form.quote-number")}>
                                        {t("quote.form.quote-number")}
                                    </label>
                                    <div>
                                        <QuoteNumber quote={quote}/>
                                    </div>
                                </div>

                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-forwarder'}>
                                    <label title={t("quote.grid.forwarder")}>
                                        {t("quote.grid.forwarder")}
                                    </label>
                                    <div>
                                        <QuoteForwarder quote={quote}/>
                                    </div>
                                </div>

                                {role === UserRole.Forwarder || role === UserRole.Administrator
                                    ? <>
                                        <div className={'details-box-default-container-item'}
                                             id={'details-box-quote-curator'}>
                                            <label title={t("quote.grid.curator")}>
                                                {t("quote.grid.curator")}
                                            </label>
                                            <div>
                                                <QuoteCurator quote={quote}/>
                                            </div>
                                        </div>
                                        <div className={'details-box-default-container-item'}
                                             id={'details-box-quote-customer-manager'}>
                                            <label title={t("quote.grid.customer-manager")}>
                                                {t("quote.grid.customer-manager")}
                                            </label>
                                            <div>
                                                <QuoteCustomerManager quote={quote}/>
                                            </div>
                                        </div>
                                    </>
                                    : null
                                }
                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-available-till'}>
                                    <label title={t("quote.form.available-till")}>
                                        {t("quote.form.available-till")}
                                    </label>
                                    <div>
                                        <QuoteAvailableTill quote={quote}/>
                                    </div>
                                </div>
                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-recall-date'}>
                                    <label title={t("quote.form.recall-date")}>
                                        {t("quote.form.recall-date")}
                                    </label>
                                    <div>
                                        <QuoteRecallDate quote={quote}/>
                                    </div>
                                </div>

                            </div>
                            <div>


                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-client'}>
                                    <label title={t("quote.form.client")}>
                                        {t("quote.form.client")}
                                    </label>
                                    <div>
                                        <QuoteClient quote={quote}/>
                                    </div>
                                </div>

                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-transporeon-number'}>
                                    <label title={t("quote.form.transporeon-number")}>
                                        {t("quote.form.transporeon-number")}
                                    </label>
                                    <div>
                                        <QuoteTransporeon quote={quote}/>
                                    </div>
                                </div>
                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-customer-ref-number'}>
                                    <label title={t("quote.form.customer-ref-number")}>
                                        {t("quote.form.customer-ref-number")}
                                    </label>
                                    <div>
                                        <QuoteCustomerOrderRefNumber quote={quote}/>
                                    </div>
                                </div>
                                <div><QuoteSource quote={quote}/></div>
                            </div>
                        </div>
                    }

                    {isMaxWidth600 &&
                        <div className={'details-box-default-container-columns'}
                             data-count={'1'}>
                            <div>
                                <div className={'details-box-default-container-item'}
                                     id={'details-box-shipment-gid'}>
                                    <label title={t("quote.form.quote-number")}>
                                        {t("quote.form.quote-number")}
                                    </label>
                                    <div>
                                        <QuoteNumber quote={quote}/>
                                    </div>
                                </div>
                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-forwarder'}>
                                    <label title={t("quote.grid.forwarder")}>
                                        {t("quote.grid.forwarder")}
                                    </label>
                                    <div>
                                        <QuoteForwarder quote={quote}/>
                                    </div>
                                </div>
                                {role === UserRole.Forwarder || role === UserRole.Administrator
                                    ? <>
                                        <div className={'details-box-default-container-item'}
                                             id={'details-box-quote-curator'}>
                                            <label title={t("quote.grid.curator")}>
                                                {t("quote.grid.curator")}
                                            </label>
                                            <div>
                                                <QuoteCurator quote={quote}/>
                                            </div>
                                        </div>
                                        <div className={'details-box-default-container-item'}
                                             id={'details-box-quote-customer-manager'}>
                                            <label title={t("quote.grid.customer-manager")}>
                                                {t("quote.grid.customer-manager")}
                                            </label>
                                            <div>
                                                <QuoteCustomerManager quote={quote}/>
                                            </div>
                                        </div>
                                    </>
                                    : null
                                }

                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-available-till'}>
                                    <label title={t("quote.form.available-till")}>
                                        {t("quote.form.available-till")}
                                    </label>
                                    <div>
                                        <QuoteAvailableTill quote={quote}/>
                                    </div>
                                </div>

                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-recall-date'}>
                                    <label title={t("quote.form.recall-date")}>
                                        {t("quote.form.recall-date")}
                                    </label>
                                    <div>
                                        <QuoteRecallDate quote={quote}/>
                                    </div>
                                </div>


                            </div>
                        </div>
                    }

                    <TagFormField orderReleaseGid={quote.orderReleaseGid}
                                  orderType={OrderType.Quote}
                                  tags={quote.tags}/>
                </div>
            </DetailsBoxColumn>
            {((!isMaxWidth600 && !isSidePanelDetails) || isMaxWidth600) &&
            <DetailsBoxColumn>
                <div className={'details-box-default-container'}>



                    {!isMaxWidth600 && !isSidePanelDetails &&
                        <div className={'details-box-default-container-columns'}
                             data-count={'2'}>

                            <div>

                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-client'}>
                                    <label title={t("quote.form.client")}>
                                        {t("quote.form.client")}
                                    </label>
                                    <div>
                                        <QuoteClient quote={quote}/>
                                    </div>
                                </div>

                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-transporeon-number'}>
                                    <label title={t("quote.form.transporeon-number")}>
                                        {t("quote.form.transporeon-number")}
                                    </label>
                                    <div>
                                        <QuoteTransporeon quote={quote}/>
                                    </div>
                                </div>
                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-customer-ref-number'}>
                                    <label title={t("quote.form.customer-ref-number")}>
                                        {t("quote.form.customer-ref-number")}
                                    </label>
                                    <div>
                                        <QuoteCustomerOrderRefNumber quote={quote}/>
                                    </div>
                                </div>

                            </div>
                            <div><QuoteSource quote={quote}/></div>
                        </div>
                    }



                    {isMaxWidth600 &&
                        <div className={'details-box-default-container-columns'}
                             data-count={'1'}>
                            <div>

                                <QuoteSource quote={quote}/>
                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-client'}>
                                    <label title={t("quote.form.client")}>
                                        {t("quote.form.client")}
                                    </label>
                                    <div>
                                        <QuoteClient quote={quote}/>
                                    </div>
                                </div>

                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-transporeon-number'}>
                                    <label title={t("quote.form.transporeon-number")}>
                                        {t("quote.form.transporeon-number")}
                                    </label>
                                    <div>
                                        <QuoteTransporeon quote={quote}/>
                                    </div>
                                </div>
                                <div className={'details-box-default-container-item'}
                                     id={'details-box-quote-customer-ref-number'}>
                                    <label title={t("quote.form.customer-ref-number")}>
                                        {t("quote.form.customer-ref-number")}
                                    </label>
                                    <div>
                                        <QuoteCustomerOrderRefNumber quote={quote}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }


                </div>
            </DetailsBoxColumn>
            }
        </DetailsBox>
    );
};

export default QuoteDetailsBox;
