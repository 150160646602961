import React from 'react';
import {IShipment} from "../../../../../../../../../app/interfaces/shipment/IShipment";
import cl from './EventsList.module.css';
import {getEventLabel} from "../../../../../../../../../helpers/shipment";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {ShipmentEtaStatus} from "../../../../../../../../../app/enums/ShipmentEtaStatus";
import Icon from "../../../../../../../../../assets/icon/Icon";

type EventsListProps = {
    model: IShipment;
}

const getEtaStatusColor = (status: ShipmentEtaStatus): string => {
    if (status === ShipmentEtaStatus.Late) {
        return 'red';
    } else if (status === ShipmentEtaStatus.OnTime) {
        return '#38B868';
    } else if (status === ShipmentEtaStatus.Early) {
        return '#FFCF00';
    }

    return '';
}

const EventsList: React.FC<EventsListProps> = ({
                                                   model
                                               }) => {
    const {t} = useTranslation();

    if (!model.shipmentEvents || model.shipmentEvents.length <= 0) {
        return null;
    }

    let latestEvent = model.shipmentEvents
        .filter(e => e.factDate !== null && e.factDate !== undefined)
        .sort(function (a, b) {
            return a.spPosition - b.spPosition || a.spSubPosition - b.spSubPosition;
        }).pop();

    let latestSp = model.shipmentEvents
        .sort(function (a, b) {
            return b.spPosition - a.spPosition || b.spSubPosition - a.spSubPosition;
        })[0];

    return (
        <div className={'view-row events-list'}>
            <table className={cl.table}>
                <tbody>
                {model.shipmentEvents
                    .sort((a, b) => {
                        return a.sortPosition - b.sortPosition;
                    })
                    .map(item => {
                        let passed: boolean = false;
                        let isLast: boolean = false;

                        if (item.factDate !== null && item.factDate !== undefined) {
                            passed = true;
                        }

                        if (latestSp.spPosition === item.spPosition && latestSp.spSubPosition === item.spSubPosition) {
                            isLast = true;
                        }

                        return (
                            <tr key={item.sortPosition}
                                className={`${cl.row} ${passed ? cl.passed : cl.notPassed} event-item-${item.sortPosition}`}>
                                <td className={cl.col} data-float={'left'}>
                                    <div className={`event-item-date-${item.sortPosition} ${cl.date}`}>
                                        {item.date !== null && item.date !== undefined
                                            ? moment(item.date).format('DD.MM.YY HH:mm')
                                            : null
                                        }
                                    </div>

                                    {item.etaDate !== null && item.etaDate !== undefined
                                        ? <>
                                            <div className={`${cl.eta} event-item-eta-date-${item.sortPosition}`}
                                                 style={{color: getEtaStatusColor(item.etaStatus)}}>
                                                {moment(item.etaDate).format('DD.MM.YYYY HH:mm')}
                                            </div>
                                            <div className={`${cl.shift} event-item-eta-shift-${item.sortPosition}`}
                                                 style={{backgroundColor: getEtaStatusColor(item.etaStatus)}}>
                                                {item.etaShift ?? ''}
                                            </div>
                                        </>
                                        : null
                                    }
                                </td>

                                <td className={cl.col} data-float={'center'}>
                                    <div style={{
                                        width: '100%',
                                        height: '75px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        position: 'relative'
                                    }}>
                                        <div className={cl.circle}
                                             data-passed={!model.isCompleted && latestEvent && latestEvent.spPosition === item.spPosition && latestEvent.spSubPosition === item.spSubPosition ? 'p' : (passed ? 'p' : 'np')}>
                                            {(!model.isCompleted && latestEvent !== undefined && latestEvent !== null &&
                                                latestEvent.spPosition === item.spPosition &&
                                                latestEvent.spSubPosition === item.spSubPosition) || passed
                                                ? <Icon icon={"faCheck"}/>
                                                : null
                                            }
                                        </div>

                                        {!isLast &&
                                            <div className={cl.verticalLine}
                                                 data-passed={!model.isCompleted && latestEvent && latestEvent.spPosition === item.spPosition && latestEvent.spSubPosition === item.spSubPosition ? 'p' : (passed ? 'p' : 'np')}>

                                            </div>
                                        }
                                    </div>
                                </td>

                                <td className={cl.col} data-float={'right'}>
                                    {item.factDate !== null && item.factDate !== undefined
                                        ? <>
                                            <span
                                                className={`event-item-fact-date-${item.sortPosition} ${cl.factDate}`}>
                                                {moment(item.factDate).format('DD.MM.YY HH:mm')}
                                            </span>
                                            <br/>
                                        </>
                                        : null
                                    }

                                    <span className={`event-item-event-type-${item.sortPosition} ${cl.event}`}>
                                        {`${t(getEventLabel(item.status ?? '', item.type ?? '', null))} ${item.info ?? ''}`}
                                    </span>

                                    <br/>

                                    <span className={`event-item-address-short-${item.sortPosition} ${cl.address}`}>
                                        {item.addressShort}
                                    </span>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </div>
    );
};

export default EventsList;