import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {useOfferApi} from "../../../../../app/api/offer";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {isAxiosError} from "axios";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {IChangeEquipmentGetAction} from "../../../../../app/interfaces/offer/IChangeEquipmentGetAction";
import {IChangeEquipmentPostAction} from "../../../../../app/interfaces/offer/IChangeEquipmentPostAction";
import ChangeEquipmentForm from "./ChangeEquipmentForm/ChangeEquipmentForm";

type ChangeEquipmentProps = {
    id: string;
    close?: () => void;
}

const ChangeEquipment: React.FC<ChangeEquipmentProps> = ({
                                                             id,
                                                             close
                                                         }) => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [model, setModel] = useState<IChangeEquipmentGetAction | null>(null);
    const [, setErrors] = useState<Array<IError>>([]);

    const {
        getChangeEquipmentGroupData: {
            query: getChangeEquipmentGroupData,
            isLoading,
            cancel
        },
        changeEquipmentGroup: {
            mutation: changeEquipmentGroup,
            cancel: cancelChangeEquipmentGroup
        }
    } = useOfferApi();

    useEffect(() => {
        if (id === '') {
            if (close) {
                close()
            } else {
                return;
            }
        }

        (async () => {
            try {
                const response = await getChangeEquipmentGroupData(id);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch {
                if (close) {
                    close();
                } else {
                    toast.error<string>('Unable to get change equipment data');
                }
            }
        })();

        return () => {
            cancel();
            cancelChangeEquipmentGroup();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const save = async (model: IChangeEquipmentPostAction): Promise<void> => {
        setDisabled(prev => !prev);
        setErrors([]);

        try {
            const response = await changeEquipmentGroup(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [
                            {
                                id: id,
                                isRowLoading: true
                            }
                        ]
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.offer")} ${id}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();
                    toast.error(err.message);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }


    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                <ChangeEquipmentForm model={model}
                                     disabled={disabled}
                                     save={save}/>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        form='offer-change-equipment-group-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.submit")}
                </Button>
            </div>
        </div>
    );
};

export default ChangeEquipment;