import {CircleProps, MarkerProps} from "@react-google-maps/api";
import {IShipment} from "../../../../../app/interfaces/shipment/IShipment";
import {AdvancedMarkerProps} from "../../../../ui/GoogleMap/AdvancedMarker/AdvancedMarker";
import {buildCurrentTrackingPositionContent} from "./marker";

export const buildCirclesArray = (model: IShipment): Array<CircleProps> => {
    if (!model.stopPoints || model.stopPoints.length <= 0) {
        return [];
    }

    const stopPoints = model.stopPoints
        .filter(e => e.type !== null && e.lg !== null && e.lt !== null && e.lg !== 0 && e.lt !== 0)
        .sort((a, b) => {
            return a.position - b.position;
        });

    let circles: Array<CircleProps> = [];

    for (let sp of stopPoints) {
        if (sp.type !== null) {
            let type = sp.type.replace('[', '').replace(']', '').toLowerCase();
            if (type === 'p' || type === 'd') {
                let color = sp.needCheck
                    ? '#fed9d9'
                    : '#29d64e';

                circles.push({
                    center: {
                        lat: sp.lt ?? 0,
                        lng: sp.lg ?? 0
                    },
                    radius: 1000,
                    options: {
                        strokeColor: color,
                        strokeOpacity: 1,
                        strokeWeight: 2,
                        fillColor: color,
                        fillOpacity: 0.35,
                    }
                });
            }
        }
    }

    return circles;
}

export const buildMarkersArray = (model: IShipment): Array<MarkerProps> => {
    if (!model.stopPoints || model.stopPoints.length <= 0) {
        return [];
    }

    const stopPoints = model.stopPoints
        .filter(e => e.lg !== null && e.lt !== null && e.lg !== 0 && e.lt !== 0)
        .sort((a, b) => {
            return a.position - b.position;
        });

    let markers: Array<MarkerProps> = [];
    let bounds = new google.maps.LatLngBounds();

    for (let sp of stopPoints) {
        if (sp.type !== null && ((sp.type === 'p' || sp.type === 'd') ||
            (sp.type !== 'p' && sp.type !== 'd'))) {
            let type = sp.type.replace('[', '').replace(']', '').toLowerCase();
            markers.push({
                position: {
                    lat: sp.lt ?? 0,
                    lng: sp.lg ?? 0
                },
                zIndex: 1,
                icon: `/images/marker_blue_${type}.png`,
                draggable: false,
                title: sp.address ?? ''
            });

            bounds.extend({
                lat: sp.lt ?? 0,
                lng: sp.lg ?? 0
            });
        }
    }

    return markers;
}

export const getBoundsToMarkers = (items: Array<MarkerProps>): google.maps.LatLngBounds => {
    let bounds = new google.maps.LatLngBounds();

    items.forEach(({position}) => bounds.extend(position));

    return bounds;
}

export const buildCurrentPositionMarker = (model: IShipment): MarkerProps | null => {
    if (model.currentPosition !== null && model.currentPosition.lt !== null && model.currentPosition.lt !== 0 &&
        model.currentPosition.lg !== null && model.currentPosition.lg !== 0) {
        return {
            position: {
                lat: model.currentPosition.lt ?? 0,
                lng: model.currentPosition.lg ?? 0
            },
            draggable: false,
            zIndex: 10,
            title: model.currentPosition.address ?? '',
            icon: model.importDirection
                ? '/images/marker_green_export.png'
                : '/images/marker_green.png'
        };
    }

    return null;
}

export const buildCurrentTrackingPositionMarker = (model: IShipment): AdvancedMarkerProps | null => {
    if (model.currentPosition !== null && model.currentPosition.lt !== null && model.currentPosition.lt !== 0 &&
        model.currentPosition.lg !== null && model.currentPosition.lg !== 0) {
        return {
            position: {
                lat: model.currentPosition.lt ?? 0,
                lng: model.currentPosition.lg ?? 0,
            },
            children: buildCurrentTrackingPositionContent(model),
            map: {} as any,
        };
    }

    return null;
}