import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    FormLayoutToolbarActionItem
} from "../../../../components/uiLayouts/FormLayout/components/FormLayoutToolbar/FormLayoutToolbar";
import {ResponseResultCode} from "../../../../app/enums/ResponseResultCode";
import {AxiosError} from "axios";
import {toast} from "react-toastify";
import FormLayout from "../../../../components/uiLayouts/FormLayout/FormLayout";
import {LayoutConfigEntity} from "../../../../app/enums/LayoutConfigEntity";
import {IQuote} from "../../../../app/interfaces/quote/IQuote";
import {useQuoteApi} from "../../../../app/api/quote";
import Alert from "../../../../components/ui/Alert/Alert";
import {OrderReleaseStatus} from "../../../../app/enums/OrderReleaseStatus";
import {ButtonType} from "../../../../components/ui/Button/Button";
import AssignQuoteDialog from "../actions/AssignQuote/AssignQuoteDialog";
import CancelQuoteDialog from "../actions/CancelQuote/CancelQuoteDialog";
import SendQuoteToAllDialog from "../actions/SendQuoteToAll/SendQuoteToAllDialog";
import QuoteDetailsSection from "./sections/QuoteDetailsSection/QuoteDetailsSection";
import {
    ApplicationHubMessageAction,
    IApplicationHubMessage
} from "../../../../app/signalR/applicationHub/IApplicationHubMessage";
import {CustomScroll} from "react-custom-scroll";

type QuoteFormProps = {
    id: string | null;
    close: () => void;
    isFullPageDetails: boolean;
}

const QuoteForm: React.FC<QuoteFormProps> = ({
                                                 id,
                                                 close,
                                                 isFullPageDetails
                                             }) => {
    const [isAssignDialogShown, setIsAssignDialogShown] = useState<boolean>(false);
    const [isCancelDialogShown, setIsCancelDialogShown] = useState<boolean>(false);
    const [isSendToAllDialogShown, setIsSendToAllDialogShown] = useState<boolean>(false);

    const {t} = useTranslation();
    const [data, setData] = useState<IQuote | null>(null);
    const [actions, setActions] = useState<Array<FormLayoutToolbarActionItem | 'separator'>>([]);
    const {
        getQuote: {
            query, isLoading
        }
    } = useQuoteApi();

    useEffect(() => {
        if (!data) {
            return;
        }

        setActions(buildActionsList());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, t]);

    const getOrderData = async (id: string) => {
        try {
            const data = await query(id);

            if (data !== undefined && data.status === 200 && data.data !== undefined &&
                data.data.resultCode === ResponseResultCode.Ok) {
                setData(data.data.data ?? null);
            }
        } catch (e) {
            if (e instanceof AxiosError && e.response !== undefined && e.response.data !== undefined &&
                e.response.data.resultCode !== ResponseResultCode.NotFound) {
                toast.error(e.message);
            }
        }
    };

    const onQuoteUpdated = async (ev: any) => {


        let typedEv = ev.detail as IApplicationHubMessage;

        if (typedEv.action === ApplicationHubMessageAction.Update && typedEv.payload && typedEv.payload !== ''
            && typedEv.payload == id) {
            await getOrderData(id)
        }
    }

    useEffect(() => {

        document.addEventListener('onQuoteApplicationHubMessage', onQuoteUpdated);

        return () => {
            document.removeEventListener('onQuoteApplicationHubMessage', onQuoteUpdated);
        }
    }, []);

    useEffect(() => {
        if (!id) {
            return;
        }

        (async () => {
            await getOrderData(id)
        })();

        return () => {
            setData(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const buildActionsList = (): Array<FormLayoutToolbarActionItem | 'separator'> => {
        if (!data) {
            return [];
        }

        let arr: Array<FormLayoutToolbarActionItem | 'separator'> = [];

        if (data.status !== OrderReleaseStatus.Confirmed) {
            if (data.notSent || data.isAuction) {
                arr.push({
                    disabled: false,
                    id: 'send-to-all',
                    title: t("shared.btn.send-quote-to-all"),
                    onClick: () => setIsSendToAllDialogShown(prev => !prev),
                    pinnedButtonType: ButtonType.Secondary
                });
            }

            arr.push({
                disabled: false,
                id: 'assign-quote',
                title: t("shared.btn.assign-quote"),
                onClick: () => setIsAssignDialogShown(prev => !prev),
                pinnedButtonType: ButtonType.Secondary
            });
        }

        arr.push({
            disabled: false,
            id: 'cancel-quote',
            title: t("shared.btn.cancel-quote"),
            onClick: () => setIsCancelDialogShown(prev => !prev),
            color: 'red',
            pinnedButtonType: ButtonType.Warning
        });

        return arr;
    }

    return (
        <FormLayout id={'quote-details'}
                    isLoading={!id || isLoading}
                    showCloseIcon={isFullPageDetails}
                    close={close}
                    entity={LayoutConfigEntity.Quote}
                    actions={actions}>
            {id &&
                <>
                    <SendQuoteToAllDialog id={[id]}
                                          show={isSendToAllDialogShown}
                                          setShow={setIsSendToAllDialogShown}/>

                    <AssignQuoteDialog id={[id]}
                                       show={isAssignDialogShown}
                                       setShow={setIsAssignDialogShown}/>

                    <CancelQuoteDialog id={[id]}
                                       show={isCancelDialogShown}
                                       setShow={setIsCancelDialogShown}/>
                </>
            }
            {!data && <Alert type={'Error'}>{t("quote.errors.not-found")}</Alert>}
            {data &&
                <div className={`${isFullPageDetails ? 'full' : 'side'} details h100`}>
                    <CustomScroll heightRelativeToParent={'100%'}>
                        <QuoteDetailsSection quote={data} isSidePanelDetails={!isFullPageDetails}/>
                    </CustomScroll>
                </div>
            }
        </FormLayout>
    );
};

export default QuoteForm;
