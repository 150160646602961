import React from 'react';
import {IQuote} from "../../../../../../../../app/interfaces/quote/IQuote";
import {useTranslation} from "react-i18next";
import {useQuoteApi} from "../../../../../../../../app/api/quote";
import {ResponseResultCode} from "../../../../../../../../app/enums/ResponseResultCode";

type QuoteTransporeonProps = {
    quote: IQuote;
}

const QuoteTransporeon: React.FC<QuoteTransporeonProps> = ({
                                                               quote
                                                           }) => {
    const {t} = useTranslation();
    const {
        getQuoteOtmTransporeonLink: {
            query: getQuoteOtmTransporeonLink
        }
    } = useQuoteApi();

    if (quote.transporeonNumber === null || quote.transporeonNumber === '') {
        return (
            <div className={'details-box-item-field-value'}>

            </div>
        );
    }

    return (
        <div>
            <span className={'details-box-item-field-edit-value'}
                  onClick={async (ev) => {
                      if (!quote.transporeonNumber) {
                          return;
                      }

                      ev.stopPropagation();

                      try {
                          const response = await getQuoteOtmTransporeonLink(quote.transporeonNumber);

                          if (response && response.data && response.data.resultCode === ResponseResultCode.Ok &&
                              response.data.data) {
                              window.open(response.data.data, '_blank');
                          }
                      } catch {
                          //ignore
                      }
                  }}>
              {quote.transporeonNumber}
            </span>
        </div>
    );
};

export default QuoteTransporeon;
