import React from 'react';
import {useTranslation} from "react-i18next";


type ThuVolumeProps = {
    thuVolume: string | null;
}

const ThuVolume: React.FC<ThuVolumeProps> = ({
                                                 thuVolume
                                             }) => {
    const {t} = useTranslation();

    return (
        <>
            <label className={'thu-volume-label'}>
                {t('shipment.form.thu-volume')}
            </label>

            <span className={'details-box-item-field-value thu-volume-value'}>
                {thuVolume !== null && thuVolume !== undefined && thuVolume !== ''
                    ? <>{`${thuVolume} cumtr`}</>
                    : <>{t("shared.labels.no-data")}</>
                }
            </span>
        </>
    );
};

export default ThuVolume;
