import React, {useEffect, useMemo, useRef, useState} from 'react';
import {IQuote} from "../../../../../../app/interfaces/quote/IQuote";
import cl from './QuoteMap.module.css';
import {useTranslation} from "react-i18next";
import FormLoadingScreen from "../../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {Circle, CircleProps, GoogleMap, Marker, MarkerProps, useLoadScript} from "@react-google-maps/api";
import {defaultMapOptions, useLoadScriptOptions} from "../../../../../../components/ui/GoogleMap/utils/map";

type QuoteMapProps = {
    quote: IQuote;
}

const QuoteMap: React.FC<QuoteMapProps> = ({
                                               quote
                                           }) => {
    const {i18n} = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [language, setLanguage] = useState<string>(i18n.language);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (language !== i18n.language) {
            window.google.maps = {} as any;

            setLoading(prev => !prev);

            setLanguage(i18n.language);

            timeout = setTimeout(() => {
                setLoading(prev => !prev);
            }, 1000);
        }

        return () => {
            clearTimeout(timeout);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    if (loading) {
        return (
            <div className={cl.container}>
                <FormLoadingScreen/>
            </div>
        );
    }

    if (quote.lt === null || quote.lt === 0 || quote.lg === null || quote.lg === 0) {
        return null;
    }

    return <Map quote={quote} language={i18n.language}/>;
};

export default QuoteMap;

const Map: React.FC<QuoteMapProps & {
    language: string
}> = ({language, quote}) => {
    const map = useRef<google.maps.Map | null>(null);
    const [markers, setMarkers] = useState<Array<MarkerProps>>([]);
    const [circles, setCircles] = useState<Array<CircleProps>>([]);
    const {isLoaded} = useLoadScript({
        ...useLoadScriptOptions,
        language: language,
    });

    const options = useMemo((): google.maps.MapOptions =>
        (defaultMapOptions), []);

    const onMapLoad = (mapRef: google.maps.Map) => {
        map.current = mapRef;

        const marker: MarkerProps = {
            position: {
                lat: quote.lt ?? 0,
                lng: quote.lg ?? 0
            },
            zIndex: 10,
            icon: `/images/marker_blue_o.png`,
            draggable: false
        };

        const circle: CircleProps = {
            center: {
                lat: quote.lt ?? 0,
                lng: quote.lg ?? 0
            },
            radius: 50000,
            options: {
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF0000',
                fillOpacity: 0.35,
            }
        };

        let bounds = new google.maps.LatLngBounds();

        bounds.extend({
            lat: quote.lt ?? 0,
            lng: quote.lg ?? 0
        });

        map.current?.fitBounds(bounds);
        map.current?.setZoom(5);

        setMarkers([marker]);
        setCircles([circle]);
    }

    if (!isLoaded) {
        return (
            <div className={cl.container}>
                <FormLoadingScreen/>
            </div>
        );
    }

    return (
        <div className={cl.container}>
            <GoogleMap mapContainerClassName="h100"
                       options={options}
                       onLoad={onMapLoad}>
                {markers.map((marker, index) => {
                    return (
                        <Marker key={index} {...marker} />
                    );
                })}
                {circles.map((circle, index) => {
                    return (
                        <Circle key={index} {...circle} />
                    );
                })}
            </GoogleMap>
        </div>
    );
}