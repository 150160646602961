import moment from "moment";
import {IInvoice} from "../../../../../app/interfaces/invoice/IInvoice";
import {InvoiceStatus} from "../../../../../app/enums/InvoiceStatus";

export const isPassedPaymentWeek = (week: string | null): boolean => {
    if (!week || week === '') {
        return true;
    }

    let w = parseInt(week);
    if (!isNaN(w)) {
        return w > 0 && moment().isoWeek() > w;
    }

    return false;
};

export const getInvoiceStatusColor = (status: InvoiceStatus): string => {
    switch (status) {
        case InvoiceStatus.NotPaid:
            return 'afafaf';
        case InvoiceStatus.Paid:
            return '7FB98B';
        case InvoiceStatus.PartiallyPaid:
            return '7D89BA';
    }
};

export const getInvoiceStatusResource = (status: InvoiceStatus): string => {
    switch (status) {
        case InvoiceStatus.NotPaid:
            return 'invoice.status.not-paid';
        case InvoiceStatus.Paid:
            return 'invoice.status.paid';
        case InvoiceStatus.PartiallyPaid:
            return 'invoice.status.partially-paid';
    }
};

export const getInvoiceBlockStatusTitleResource = (invoice: IInvoice): string => {
    if (!invoice.holdStatus || invoice.holdStatus === '') {
        return '';
    }

    if (invoice.holdStatus === 'HOLD_NO_INFO') {
        return 'invoice.grid.block-responsible-fw';
    }

    if (invoice.holdStatus === 'HOLD') {
        return 'invoice.grid.block-responsible-sp';
    }

    return '';
}
