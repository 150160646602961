import React, {useEffect, useState} from 'react';
import ListBox from "../../../../ui/ListBox/ListBox";
import {useDataHelperApi} from "../../../../../app/api/dataHelper";
import {SelectModel} from "../../../../../app/types/SelectModel";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {IUserAccessSettings} from "../../../../../app/interfaces/user/IUserAccessSettings";
import TagContainer from "../../../../ui/Tag/TagContainer/TagContainer";
import {TagItem, TagItemBackgroundColor} from "../../../../ui/Tag/Tag";
import {selectAccessSettingsForwarders} from "../../../../../features/filter/filterSliceSelectors";
import {setAccessSettings, setAccessSettingsData} from "../../../../../features/filter/filterSlice";

type AccessSettingsForwarderFilterProps = {
    disabled: boolean
    settings: IUserAccessSettings;
    isExpanded: boolean;
};

const AccessSettingsForwarderFilter: React.FC<AccessSettingsForwarderFilterProps> = ({
                                                                                         disabled,
                                                                                         settings,
                                                                                         isExpanded
                                                                                     }) => {
    const [data, setData] = useState<Array<SelectModel>>([]);
    const forwarders = useAppSelector(selectAccessSettingsForwarders);
    const dispatch = useAppDispatch();

    const {
        getAccessFilterForwarders: {
            query,
            isLoading
        }
    } = useDataHelperApi();

    useEffect(() => {
        if (forwarders.length > 0) {
            return;
        }

        (async () => {
            try {
                const response = await query();

                if (response?.data?.resultCode === ResponseResultCode.Ok && response?.data.data) {
                    dispatch(setAccessSettingsData({
                        entity: 'Forwarders',
                        data: {
                            forwarders: response.data.data
                        } as any
                    }));
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error('Unable to load forwarders list: ' + e.message);
                }
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (data.length > 0 || forwarders.length <= 0) {
            return;
        }

        setData([...forwarders]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forwarders]);

    const onFilter = (value: string | null) => {
        if (!value || value === '') {
            setData([...forwarders]);
        } else {
            setData([
                ...forwarders.filter(item => {
                    return item.text.toLowerCase().includes(value.toLowerCase())
                })
            ]);
        }
    }

    return (
        <div style={{margin: '10px 0'}}>
            {isExpanded &&
                <div style={{marginBottom: '5px'}}>
                    <TagContainer id={'access-settings-forwarder-filter-tags'}
                                  width={'100%'}
                                  height={'100px'}
                                  items={settings.forwarders.filter(e => {
                                      return forwarders.find(ex => ex.value === e) !== undefined;
                                  }).map((fw) => {
                                      let existItem = forwarders.find(ex => ex.value === fw) as SelectModel;

                                      return {
                                          id: `forwarder-tag-${existItem.value}`,
                                          text: existItem.text,
                                          data: existItem,
                                          showClearIcon: true,
                                          backgroundColor: TagItemBackgroundColor.LightGray,
                                          rounded: true
                                      } as TagItem;
                                  })}
                                  onClear={({data}) => {
                                      if (data !== undefined && data !== null) {
                                          let item = data as SelectModel;

                                          dispatch(setAccessSettings({
                                              ...settings,
                                              forwarders: [
                                                  ...settings.forwarders.filter(e => e !== item.value)
                                              ]
                                          }));
                                      }
                                  }}/>
                </div>
            }
            <ListBox id={'access-settings-forwarder-filter'}
                     selected={settings.forwarders.map(item => {
                         return {
                             text: item,
                             value: item
                         }
                     })}
                     disabled={disabled}
                     data={data}
                     isLoading={isLoading}
                     onFilter={onFilter}
                     height={250}
                     selection={'Multiple'}
                     onSelectionChange={(items) => {
                         dispatch(setAccessSettings({
                             ...settings,
                             forwarders: [...items.map(item => item.value)]
                         }));
                     }}/>
        </div>
    );
};

export default AccessSettingsForwarderFilter;