import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'truck',
    pathData: [
        'M11.667 5.83301H13.6147C13.8185 5.83301 13.9204 5.83301 14.0164 5.85603C14.1014 5.87645 14.1827 5.91012 14.2572 5.95581C14.3413 6.00734 14.4134 6.07941 14.5575 6.22353L17.9431 9.60915C18.0873 9.75328 18.1593 9.82534 18.2109 9.90944C18.2566 9.984 18.2902 10.0653 18.3106 10.1503C18.3337 10.2462 18.3337 10.3481 18.3337 10.552V12.9163C18.3337 13.3046 18.3337 13.4988 18.2702 13.6519C18.1856 13.8561 18.0234 14.0183 17.8192 14.1029C17.6661 14.1663 17.4719 14.1663 17.0837 14.1663M12.917 14.1663H11.667M11.667 14.1663V5.99967C11.667 5.06625 11.667 4.59954 11.4853 4.24302C11.3255 3.92942 11.0706 3.67445 10.757 3.51466C10.4005 3.33301 9.93375 3.33301 9.00033 3.33301H4.33366C3.40024 3.33301 2.93353 3.33301 2.57701 3.51466C2.2634 3.67445 2.00844 3.92942 1.84865 4.24302C1.66699 4.59954 1.66699 5.06625 1.66699 5.99967V12.4997C1.66699 13.4201 2.41318 14.1663 3.33366 14.1663M11.667 14.1663H8.33366M8.33366 14.1663C8.33366 15.5471 7.21437 16.6663 5.83366 16.6663C4.45295 16.6663 3.33366 15.5471 3.33366 14.1663M8.33366 14.1663C8.33366 12.7856 7.21437 11.6663 5.83366 11.6663C4.45295 11.6663 3.33366 12.7856 3.33366 14.1663M17.0837 14.583C17.0837 15.7336 16.1509 16.6663 15.0003 16.6663C13.8497 16.6663 12.917 15.7336 12.917 14.583C12.917 13.4324 13.8497 12.4997 15.0003 12.4997C16.1509 12.4997 17.0837 13.4324 17.0837 14.583Z'
    ],
    viewBox: '0 0 20 20',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;
