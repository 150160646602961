import useAxios from "../../../hooks/useAxios/useAxios";
import {IDataResponse, IResponse} from "../../interfaces/response/IResponse";
import {ILogin, ILoginUser} from "../../interfaces/login/ILogin";
import {useCallback} from "react";
import {AxiosResponse} from "axios";

export const useLogin = (): {
    isLoading: boolean,
    cancel: () => void,
    login: (params: ILogin) => Promise<AxiosResponse<IResponse, ILogin> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ILogin>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/authentication/login`
    });

    const login = useCallback(async (params: ILogin) => await execute(params), [execute]);

    return {login, isLoading, cancel};
};

export const useGetLoginUserList = (): {
    isLoading: boolean,
    cancel: () => void,
    getLoginUserList: (email: string) => Promise<AxiosResponse<IDataResponse<Array<ILoginUser>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<ILoginUser>>>({
        method: 'GET'
    });

    const getLoginUserList = useCallback(async (email: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/authentication/getLoginUserList?email=${email}`
        });
    }, [execute]);

    return {isLoading, cancel, getLoginUserList};
};

export const useLogout = (): {
    isLoading: boolean,
    cancel: () => void,
    logout: () => Promise<AxiosResponse<IResponse> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/authentication/logout`
    });

    const logout = useCallback(async () => await execute(), [execute]);

    return {logout, isLoading, cancel};
};