import React, {useEffect, useRef, useState} from 'react';
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {useOfferApi} from "../../../../../app/api/offer";
import {isAxiosError} from "axios";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {ISendContractGetAction} from "../../../../../app/interfaces/offer/ISendContractGetAction";
import {IOfferSelectedItem} from "../../../../../app/interfaces/offer/IOfferSelectedItem";
import {ActiveSelectedOffers} from "../../form/components/SelectedOffers/SelectedOffers";
import SendContractForm from "./SendContractForm/SendContractForm";
import SendContractFormSkeletonLoader
    from "./SendContractForm/components/SendContractFormSkeletonLoader/SendContractFormSkeletonLoader";
import {ISendContractPostAction} from "../../../../../app/interfaces/offer/ISendContractPostAction";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {useTranslation} from "react-i18next";
import {join} from "lodash";
import {IUser} from "../../../../../app/interfaces/user/IUser";
import Alert from "../../../../../components/ui/Alert/Alert";

type SendContractProps = {
    id: string | Array<string>;
    user?: IUser;
    close?: () => void;
};

const alertStyle: React.CSSProperties = {
    fontWeight: 'bold',
    padding: '10px',
    textAlign: 'center',
    justifyContent: 'center',
    marginTop: '0px',
    fontSize: '12px'
};

const SendContract: React.FC<SendContractProps> = ({
                                                       id,
                                                       close,
                                                       user
                                                   }) => {
    const {t} = useTranslation();
    const [model, setModel] = useState<ISendContractGetAction | null>(null);
    const [errors, setErrors] = useState<Array<IError>>([]);
    const [disabled, setDisabled] = useState<boolean>(false);

    const [selectedItems, setSelectedItems] = useState<Array<IOfferSelectedItem>>([])
    const [selectedOffer, setSelectedOffer] = useState<IOfferSelectedItem | null>(null);
    const isSelectOfferInitialSetup = useRef<boolean>(true);

    const {
        getSendContractData: {
            query: getSendContractData,
            isLoading,
            cancel: cancelGetSendContractData
        },
        sendContract: {
            mutation: sendContract,
            cancel: cancelSendContract
        },
        validateExecutionAgent: {
            query: validateExecutionAgentAction,
            cancel: cancelValidateExecutionAgent
        },
        validateClient: {
            query: validateClientAction,
            cancel: cancelValidateClient
        },

        getSendContractDataAnonymous: {
            query: getSendContractDataAnonymous,
            isLoading: getSendContractDataAnonymousIsLoading,
            cancel: cancelGetSendContractDataAnonymous
        },
        sendContractAnonymous: {
            mutation: sendContractAnonymous,
            cancel: cancelSendContractAnonymous
        },
        validateExecutionAgentAnonymous: {
            query: validateExecutionAgentActionAnonymous,
            cancel: cancelValidateExecutionAgentAnonymous
        },
        validateClientAnonymous: {
            query: validateClientActionAnonymous,
            cancel: cancelValidateClientAnonymous
        }
    } = useOfferApi();

    useEffect(() => {
        if ((Array.isArray(id) && id.length <= 0) || id === '') {
            if (close) {
                close()
            } else {
                return;
            }
        }

        (async () => {
            const model = await loadSendContractData(id);

            if (model && model.items.length > 0) {
                let existSelectedOffer = model.items.find(e => e.id === model.orderReleaseGid.replace('AAG.', ''));

                if (existSelectedOffer) {
                    setSelectedOffer(existSelectedOffer);
                }

                setSelectedItems(model.items);
            }
        })();

        return () => {
            cancelGetSendContractData();
            cancelValidateExecutionAgent();
            cancelValidateClient();
            cancelSendContract();

            if (!user) {
                cancelGetSendContractDataAnonymous();
                cancelValidateClientAnonymous();
                cancelValidateExecutionAgentAnonymous();
                cancelSendContractAnonymous();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedOffer === null) {
            return;
        } else if (isSelectOfferInitialSetup.current) {
            isSelectOfferInitialSetup.current = false;

            return;
        }

        (async () => {
            await loadSendContractData(`AAG.${selectedOffer.id}`);
        })();
    }, [selectedOffer]);

    const loadSendContractData = async (id: string | Array<string>): Promise<ISendContractGetAction | null> => {
        try {
            const response = user
                ? await getSendContractData(id)
                : await getSendContractDataAnonymous(id);

            if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                setModel(response.data.data);

                return response.data.data;
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setModel(err.response.data.data);
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();
                    toast.error(err.message);
                }
            }
        }

        return null;
    };

    const validateExecutionAgent = async (forwarder: string, rid: string): Promise<boolean> => {
        try {
            const response = user
                ? await validateExecutionAgentAction(forwarder, rid)
                : await validateExecutionAgentActionAnonymous(forwarder, rid);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                return true;
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();
                    toast.error(err.message);
                }
            }

            return false;
        }

        return false;
    };

    const validateClient = async (id: string): Promise<boolean> => {
        try {
            const response = user
                ? await validateClientAction(id)
                : await validateClientActionAnonymous(id);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                return true;
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();
                    toast.error(err.message);
                }
            }

            return false;
        }

        return false;
    };

    const save = async (data: ISendContractPostAction) => {
        if (!selectedOffer || !model) {
            return;
        }

        setDisabled(prev => !prev);

        try {
            if (data.forwarder && (data.isContractMode || data.isPersonalMode)) {
                let isValid = await validateExecutionAgent(data.forwarder, selectedOffer.id);

                if (!isValid) {
                    setDisabled(prev => !prev);

                    return;
                }
            }

            let isValid = await validateClient(model.orderReleaseGid);

            if (!isValid) {
                setDisabled(prev => !prev);

                return;
            }

            let postData: ISendContractPostAction | null = null;

            if (data.isPersonalMode) {
                postData = {
                    contractId: data.contractId,
                    isPersonalMode: data.isPersonalMode,
                    isContractMode: data.isContractMode,
                    isFFMode: data.isFFMode,
                    forwarder: data.forwarder,
                    forwarderName: data.forwarderName,
                    currency: data.currency,
                    autoConfirm: data.autoConfirm,
                    isDateChanged: data.isDateChanged,
                    approvedByCustomer: data.approvedByCustomer,
                    notApprovedOrTechnical: data.notApprovedOrTechnical,
                    serviceProviderId: data.serviceProviderId,
                    serviceProvider: data.serviceProvider,
                    orderReleaseGid: data.orderReleaseGid,
                    betValue: data.betValue,
                    bet: data.betValue ? data.betValue.toString() : null,
                    tillDate: data.tillDate,
                    notes: data.notes,
                    startPickUpDate: data.startPickUpDate,
                    startPickUpTime: data.startPickUpTime,
                    endPickUpDate: data.endPickUpDate,
                    endPickUpTime: data.endPickUpTime,
                    startDeliveryDate: data.startDeliveryDate,
                    startDeliveryTime: data.startDeliveryTime,
                    endDeliveryDate: data.endDeliveryDate,
                    endDeliveryTime: data.endDeliveryTime,
                    isLeg: data.isLeg,
                    mode: null,
                    allowSendToTransEU: false,
                    sendToTransEU: false,
                    showSendToTransEU: false,
                    transEuPass: null,
                    transEuLogin: null,
                    itemsSelectedInList: join(selectedItems.map(item => item.id), ';'),
                    selectedItem: selectedOffer?.id ?? ''
                };
            }

            if (data.isContractMode) {
                postData = {
                    isPersonalMode: data.isPersonalMode,
                    isContractMode: data.isContractMode,
                    isFFMode: data.isFFMode,
                    autoConfirm: data.autoConfirm,
                    serviceProviderId: data.serviceProviderId,
                    serviceProvider: data.serviceProvider,
                    approvedByCustomer: data.approvedByCustomer,
                    notApprovedOrTechnical: data.notApprovedOrTechnical,
                    isDateChanged: data.isDateChanged,
                    forwarder: data.forwarder,
                    forwarderName: data.forwarderName,
                    orderReleaseGid: data.orderReleaseGid,
                    startPickUpDate: data.startPickUpDate,
                    startPickUpTime: data.startPickUpTime,
                    endPickUpDate: data.endPickUpDate,
                    endPickUpTime: data.endPickUpTime,
                    startDeliveryDate: data.startDeliveryDate,
                    startDeliveryTime: data.startDeliveryTime,
                    endDeliveryDate: data.endDeliveryDate,
                    endDeliveryTime: data.endDeliveryTime,
                    isLeg: data.isLeg,
                    currency: null,
                    bet: null,
                    betValue: null,
                    tillDate: null,
                    notes: null,
                    mode: null,
                    allowSendToTransEU: false,
                    sendToTransEU: false,
                    showSendToTransEU: false,
                    transEuPass: null,
                    transEuLogin: null,
                    contractId: null,
                    itemsSelectedInList: join(selectedItems.map(item => item.id), ';'),
                    selectedItem: selectedOffer?.id ?? ''
                };
            }

            if (data.isFFMode) {
                postData = {
                    contractId: data.contractId,
                    isPersonalMode: data.isPersonalMode,
                    isContractMode: data.isContractMode,
                    isFFMode: data.isFFMode,
                    forwarder: data.forwarder,
                    forwarderName: data.forwarderName,
                    currency: data.currency,
                    autoConfirm: data.autoConfirm,
                    isDateChanged: data.isDateChanged,
                    approvedByCustomer: data.approvedByCustomer,
                    notApprovedOrTechnical: data.notApprovedOrTechnical,
                    serviceProviderId: data.serviceProviderId,
                    serviceProvider: data.serviceProvider,
                    orderReleaseGid: data.orderReleaseGid,
                    betValue: data.betValue,
                    bet: data.betValue ? data.betValue.toString() : null,
                    tillDate: data.tillDate,
                    notes: data.notes,
                    startPickUpDate: data.startPickUpDate,
                    startPickUpTime: data.startPickUpTime,
                    endPickUpDate: data.endPickUpDate,
                    endPickUpTime: data.endPickUpTime,
                    startDeliveryDate: data.startDeliveryDate,
                    startDeliveryTime: data.startDeliveryTime,
                    endDeliveryDate: data.endDeliveryDate,
                    endDeliveryTime: data.endDeliveryTime,
                    isLeg: data.isLeg,
                    mode: null,
                    allowSendToTransEU: data.allowSendToTransEU,
                    sendToTransEU: data.sendToTransEU,
                    showSendToTransEU: data.showSendToTransEU,
                    transEuPass: data.transEuPass,
                    transEuLogin: data.transEuLogin,
                    itemsSelectedInList: join(selectedItems.map(item => item.id), ';'),
                    selectedItem: selectedOffer?.id ?? ''
                };
            }

            if (!postData) {
                return;
            }

            const response = user
                ? await sendContract(postData)
                : await sendContractAnonymous(postData);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {


                    if (selectedItems.length > 1) {

                        let nextItem = selectedItems.filter(e => e.id !== selectedOffer.id)[0];

                        setSelectedOffer(nextItem);
                        setSelectedItems(selectedItems.filter(e => e.id !== selectedOffer.id));
                        setErrors([]);
                    } else if (close) {
                        close();
                    }


                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [
                            {
                                id: model.orderReleaseGid,
                                isRowLoading: true
                            }
                        ]
                    }
                }));

                if(response.data.message) {

                    toast.warning(<ToastLayout text={t("offer.errors.sop-warn")}
                                               title={`${t("shared.labels.offer")} ${model.orderReleaseGid.replace('AAG.', '')}`}/>, {autoClose: false});
                }
                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.offer")} ${model.orderReleaseGid.replace('AAG.', '')}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();
                    toast.error(err.message);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if (model === null) {
        return (
            <FormLoadingScreen height={user ? `400px` : '100%'} style={{padding: '5px'}}/>
        );
    }

    return (
        <div className={'w100 h100 o-auto'}>
            <div className={'dialog-default-content-container'}>
{/*{sopWarning &&*/}
{/*                    <Alert type={'BrightGrayInfo'}*/}
{/*                           style={alertStyle}>*/}
{/*                        {t("offer.errors.sop-warn")}*/}
{/*                    </Alert>*/}
{/*                }*/}

                <ActiveSelectedOffers cols={7}
                                      items={selectedItems}
                                      selectedOffer={selectedOffer}
                                      disabled={isLoading || getSendContractDataAnonymousIsLoading}
                                      onClick={(item) => {
                                          if (selectedOffer !== null && selectedOffer.id === item.id) {
                                              return;
                                          }

                                          setSelectedOffer(item);
                                          setErrors([]);
                                      }}/>

                {isLoading
                    ? <SendContractFormSkeletonLoader/>
                    : <SendContractForm model={model}
                                        user={user}
                                        save={save}
                                        disabled={disabled}
                                        errors={errors}/>
                }
            </div>
        </div>
    );
};

export default SendContract;
