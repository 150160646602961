export enum OrderReleaseStatus {
    Free = 0,
    WithBet,
    Unconfirmed,
    Confirmed,
    Rejected,
    Cancelled,
    Locked,
    Expired,
    Unknown
}