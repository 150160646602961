import React from 'react';
import {LayoutConfigEntity} from "../../../../../../app/enums/LayoutConfigEntity";
import {useTranslation} from "react-i18next";
import Dialog from "../../../../Dialog/Dialog";
import GridColumnsConfigurationSetupForm from "../GridColumnsConfigurationSetupForm/GridColumnsConfigurationSetupForm";
import {GridConfig} from "../../types/GridConfig";


type GridColumnsConfigurationSetupDialogProps = {
    show: boolean;
    setShow: (isShown: boolean) => void;
    entity: LayoutConfigEntity;
    defaultGridConfig: GridConfig;
    onSaved: () => void;
}

const GridColumnsConfigurationSetupDialog = ({
                                                 show,
                                                 setShow,
                                                 entity,
                                                 defaultGridConfig,
                                                 onSaved
                                             }: GridColumnsConfigurationSetupDialogProps) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'grid-column-setup-dialog'}
                show={show}
                width={'95%'}
                setShow={setShow}
                title={t("shared.grid-header.columns-setup-title")}>
            <GridColumnsConfigurationSetupForm entity={entity}
                                               onSaved={onSaved}
                                               defaultGridConfig={defaultGridConfig}/>
        </Dialog>
    );
};

export default GridColumnsConfigurationSetupDialog;