import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {ICancelSpotGetAction} from "../../../../../app/interfaces/offer/ICancelSpotGetAction";
import {useOfferApi} from "../../../../../app/api/offer";
import {isAxiosError} from "axios";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {ICancelSpotPostAction} from "../../../../../app/interfaces/offer/ICancelSpotPostAction";
import {BLErrorCode} from "../../../../../app/enums/BLErrorCode";
import Alert from "../../../../../components/ui/Alert/Alert";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import CancelOfferForm from "./CancelOfferForm/CancelOfferForm";

type CancelOfferProps = {
    id: string | Array<string>;
    close?: () => void;
};

const alertStyle: React.CSSProperties = {
    fontSize: '13px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '10px 5px'
}

const CancelOffer: React.FC<CancelOfferProps> = ({
                                                     id,
                                                     close
                                                 }) => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [model, setModel] = useState<ICancelSpotGetAction | null>(null);
    const [errors, setErrors] = useState<Array<IError>>([]);

    const {
        getCancelSpotData: {
            query: getCancelSpotData,
            isLoading,
            cancel: cancelGetCancelSpotData
        },
        cancelSpot: {
            mutation: cancelSpot,
            cancel: cancelCancelSpot
        }
    } = useOfferApi();

    useEffect(() => {
        if ((Array.isArray(id) && id.length <= 0) || id === '') {
            if (close) {
                close()
            } else {
                return;
            }
        }

        (async () => {
            try {
                const response = await getCancelSpotData(id);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch (err) {
                if (isAxiosError(err)) {
                    if (err.response && err.response.data && err.response.status === 422 &&
                        err.response.data.resultCode === ResponseResultCode.NotValidData &&
                        err.response.data.errors && Array.isArray(err.response.data.errors)) {
                        setModel(err.response.data.data);
                        setErrors(err.response.data.errors);
                    } else if (close) {
                        close();
                        toast.error(err.message);
                    }
                }
            }
        })();

        return () => {
            cancelGetCancelSpotData();
            cancelCancelSpot();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const save = async (data: ICancelSpotPostAction): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            const response = await cancelSpot(data);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [...id].map((item) => {
                            return {
                                id: item,
                                isRowLoading: true
                            };
                        })
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.offer")} ${data.orderReleaseGid !== '' ? data.orderReleaseGid.split(';')[0] : ''}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();
                    toast.error(err.message);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'w100'}>
            <div className={'dialog-default-content-container'}>
                {errors.length > 0
                    ? errors.map((err, index) => {
                        switch (err.errorCode) {
                            case BLErrorCode.OrderHasRelatedInvoices:
                                return (
                                    <Alert type={'Error'}
                                           id={`cancel-shipment-order-has-related-invoices-error-${index}`}
                                           style={alertStyle}
                                           key={`${err.errorCode}-${err.description}`}>
                                        {`${err.description} ${t("offer.errors.cancel-offer-order-has-related-invoices")}`}
                                    </Alert>
                                );
                            case BLErrorCode.OrderHasRelatedInsuranceOperations:
                                return (
                                    <Alert type={'Error'}
                                           id={`cancel-shipment-order-has-related-insurance-operations-error-${index}`}
                                           style={alertStyle}
                                           key={`${err.errorCode}-${err.description}`}>
                                        {`${err.description} ${t("offer.errors.cancel-offer-order-has-related-insurance-operations")}`}
                                    </Alert>
                                );
                            case BLErrorCode.ProfitTransferCreated:
                                return (
                                    <Alert type={'Error'}
                                           style={alertStyle}
                                           id={`cancel-shipment-profit-transfer-created-error-${index}`}
                                           key={`${err.errorCode}-${err.description}`}>
                                        {`${err.description} ${t("offer.errors.cancel-offer-profit-transfer-created")}`}
                                    </Alert>
                                );
                        }

                        return null;
                    })
                    : null
                }

                {!model.notValid &&
                    <CancelOfferForm model={model}
                                     disabled={disabled}
                                     save={save}/>
                }
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                {!model.notValid &&
                    <Button buttonType={ButtonType.Primary}
                            disabled={disabled}
                            form='cancel-offer-form'
                            type='submit'
                            style={{minWidth: '150px'}}
                            className={'confirm-button'}
                            isLoading={disabled}>
                        {t("shared.btn.confirm")}
                    </Button>
                }
            </div>
        </div>
    );
};

export default CancelOffer;
