import React, {useLayoutEffect, useRef, useState} from 'react';
import Tag, {TagItem} from "../Tag";
import cl from './TagContainer.module.css';

type TagContainerProps = {
    id: string;
    width: string;
    height: string;
    minHeight?: string;
    disabled?: boolean;
    onClear?: (item: TagItem) => void;
    items: Array<TagItem>;
}

const TagContainer: React.FC<TagContainerProps> = ({
                                                       id,
                                                       width,
                                                       height,
                                                       items,
                                                       disabled,
                                                       onClear,
                                                       minHeight
                                                   }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState<number>(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useLayoutEffect(() => {
        if (containerRef.current !== null) {
            let computedStyle = getComputedStyle(containerRef.current);

            let elementWidth = containerRef.current.clientWidth;

            elementWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

            if (elementWidth > 0 && containerWidth !== elementWidth) {
                setContainerWidth(elementWidth);
            }
        }
    });

    if (items.length <= 0) {
        return null;
    }

    return (
        <div ref={containerRef}
             id={id}
             className={`${cl.container} skip`}
             style={{width: width, maxHeight: height, minHeight: minHeight}}>
            {containerWidth > 0 && items.map(item => {
                return (
                    <Tag key={`tag-${item.id}`}
                         containerMaxWidth={containerWidth}
                         disabled={disabled}
                         onClear={onClear}
                         item={item}/>
                );
            })}
        </div>
    );
};

export default TagContainer;