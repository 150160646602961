import React from 'react';
import {IShipment} from "../../../../app/interfaces/shipment/IShipment";
import CommodityCode from "../detailsFields/CommodityCode/CommodityCode";
import TransportHandlingUnit from '../detailsFields/TransportHandlingUnit/TransportHandlingUnit';
import ThuCount from "../detailsFields/THUCount/THUCount";
import ThuVolume from "../detailsFields/THUVolume/THUVolume";
import TotalWeight from '../detailsFields/TotalWeight/TotalWeight';
import Cost from "../detailsFields/Cost/Cost";
import useMediaQuery from "../../../../hooks/useMediaQuery/useMediaQuery";

type CargoDetailsProps = {
    model: IShipment
}

const CargoDetails: React.FC<CargoDetailsProps> = ({
                                                       model
                                                   }) => {
    const isMaxWidth600 = useMediaQuery('(max-width: 600px)');

    return (
        <div className={'details-box-default-container'}>
            {!isMaxWidth600 &&
                <div className={'details-box-default-container-columns'}
                     data-count={'2'}>
                    <div>
                        <div className={'details-box-default-container-item'}>
                            <CommodityCode commodityCode={model.commodityCode}
                                           adr={model.adr}/>
                        </div>

                        <div className={'details-box-default-container-item'}>
                            <TransportHandlingUnit transportHandlingUnit={model.transportHandlingUnit}/>
                        </div>

                        <div className={'details-box-default-container-item'}>
                            <ThuCount thuCount={model.thuCount}/>
                        </div>
                    </div>

                    <div>
                        <div className={'details-box-default-container-item'}>
                            <TotalWeight weight={model.totalWeight}/>
                        </div>

                        <div className={'details-box-default-container-item'}>
                            <Cost cost={model.cost ?? null}/>
                        </div>

                        <div className={'details-box-default-container-item'}>
                            <ThuVolume thuVolume={model.thuVolume}/>
                        </div>
                    </div>
                </div>
            }

            {isMaxWidth600 &&
                <div className={'details-box-default-container-columns'}
                     data-count={'1'}>
                    <div>
                        <div className={'details-box-default-container-item'}>
                            <CommodityCode commodityCode={model.commodityCode}
                                           adr={model.adr}/>
                        </div>

                        <div className={'details-box-default-container-item'}>
                            <TransportHandlingUnit transportHandlingUnit={model.transportHandlingUnit}/>
                        </div>

                        <div className={'details-box-default-container-item'}>
                            <ThuCount thuCount={model.thuCount}/>
                        </div>

                        <div className={'details-box-default-container-item'}>
                            <TotalWeight weight={model.totalWeight}/>
                        </div>

                        <div className={'details-box-default-container-item'}>
                            <Cost cost={model.cost ?? null}/>
                        </div>

                        <div className={'details-box-default-container-item'}>
                            <ThuVolume thuVolume={model.thuVolume}/>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default CargoDetails;