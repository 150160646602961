import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import BaseLayout from "../../components/layouts/BaseLayout/BaseLayout";
import AnonymousLayout from "../../components/layouts/AnonymousLayout/AnonymousLayout";
import Login from "../../pages/anonymous/Login/Login";
import NotFound from "../../components/ui/NotFound/NotFound";
import AuthorizedLayout from "../../components/layouts/AuthorizedLayout/AuthorizedLayout";
import AnonymousRoute from "./AnonymousRoute/AnonymousRoute";
import UserAuthenticatedProtectedRoute from "./UserAuthenticatedProtectedRoute/UserAuthenticatedProtectedRoute";
import React from "react";
import DeviceAuthenticatedProtectedRoute from "./DeviceAuthenticatedProtectedRoute/DeviceAuthenticatedProtectedRoute";
import DeviceAuthentication from "../../pages/anonymous/DeviceAuthentication/DeviceAuthentication";
import DeviceAnonymousLayout from "../../components/layouts/DeviceAnonymousLayout/DeviceAnonymousLayout";
import AnonymousDeviceRoute from "./AnonymousDeviceRoute/AnonymousDeviceRoute";
import Profile from "../../pages/authorized/Profile/Profile/Profile";
import ProfileSupport from "../../pages/authorized/Profile/Support/ProfileSupport";
import ProfileHelp from "../../pages/authorized/Profile/Help/ProfileHelp";
import RestorePassword from "../../pages/anonymous/Restore/RestorePassword/RestorePassword";
import RestoreLogin from "../../pages/anonymous/Restore/RestoreLogin/RestoreLogin";
import Registration from "../../pages/anonymous/Registration/Registration";
import Notification from "../../pages/authorized/Profile/Notification/Notification";
import AnonymousDataAccessRoute from "./AnonymousDataAccessRoute/AnonymousDataAccessRoute";
import AnonymousDataAccessLayout from "../../components/layouts/AnonymousDataAccessLayout/AnonymousDataAccessLayout";
import SelfBillingStart from "../../pages/anonymous/SelfBilling/form/SelfBillingStart/SelfBillingStart";
import SelfBillingConfirmation
    from "../../pages/anonymous/SelfBilling/form/SelfBillingConfirmation/SelfBillingConfirmation";
import SelfBillingSupport from "../../pages/anonymous/SelfBilling/form/SelfBillingSupport/SelfBillingSupport";
import SelfBillingLogin from "../../pages/anonymous/SelfBilling/form/SelfBillingLogin/SelfBillingLogin";
import SelfBillingShipment from "../../pages/anonymous/SelfBilling/form/SelfBillingShipment/SelfBillingShipment";
import SendContract from "../../pages/anonymous/Offer/SendContract/SendContract";
import ChangePlannedDate from "../../pages/anonymous/Shipment/ChangePlannedDate/ChangePlannedDate";
import EditShipmentEvent from "../../pages/anonymous/Shipment/EditShipmentEvent/EditShipmentEvent";
import QuoteFormPage from "../../pages/authorized/Quote/form/QuoteFormPage";
import OfferFormPage from "../../pages/authorized/Offer/form/OfferFormPage";
import OfferDetailsPage from "../../pages/anonymous/Offer/OfferDetails/OfferDetailsPage";
import ShipmentGridNoValidationWrapper
    from "../../pages/authorized/Shipment/grid/utils/ShipmentGridNoValidationWrapper";
import OfferGridNoValidationWrapper from "../../pages/authorized/Offer/grid/utils/OfferGridNoValidationWrapper";
import QuoteGridNoValidationWrapper from "../../pages/authorized/Quote/grid/utils/QuoteGridNoValidationWrapper";
import ConfirmTruckStatePage from "../../pages/anonymous/DriverCheckList/ConfirmTruckStatePage";

const ShipmentFormPage = React.lazy(() => import('../../pages/authorized/Shipment/form/ShipmentFormPage'));
const InvoiceFormPage = React.lazy(() => import('../../pages/authorized/Invoice/form/InvoiceFormPage'));

const Dashboard = React.lazy(() => import('../../pages/authorized/Dashboard/Dashboard'));

const ShipmentGrid = React.lazy(() => import("../../pages/authorized/Shipment/grid/utils/ShipmentGridValidationWrapper"));
const QuoteGrid = React.lazy(() => import("../../pages/authorized/Quote/grid/utils/QuoteGridValidationWrapper"));
const OfferGrid = React.lazy(() => import("../../pages/authorized/Offer/grid/utils/OfferGridValidationWrapper"));
const DispositionGrid = React.lazy(() => import('../../pages/authorized/Disposition/grid/DispositionGrid'));
const InvoiceGrid = React.lazy(() => import('../../pages/authorized/Invoice/grid/InvoiceGrid'));
const TransportLineGrid = React.lazy(() => import('../../pages/authorized/TransportLine/grid/TransportLineGrid'));


const getRouterWithRoutes = () => {
    return createBrowserRouter(
        createRoutesFromElements(
            <Route element={<BaseLayout/>}
                   errorElement={<NotFound/>}>

                {/* This layout used for anonymous pages */}
                <Route element={
                    <AnonymousRoute>
                        <AnonymousLayout/>
                    </AnonymousRoute>
                }>
                    <Route path={'/login'} element={<Login/>}/>
                    <Route path={'/account/login'} element={<Login/>}/>
                    <Route path={'/restore/password'} element={<RestorePassword/>}/>
                    <Route path={'/restore/login'} element={<RestoreLogin/>}/>
                    <Route path={'/registration'} element={<Registration/>}/>
                </Route>

                {/* This layout used for user and device authenticated pages */}
                <Route element={
                    <UserAuthenticatedProtectedRoute>
                        <DeviceAuthenticatedProtectedRoute>
                            <AuthorizedLayout/>
                        </DeviceAuthenticatedProtectedRoute>
                    </UserAuthenticatedProtectedRoute>
                }>
                    <Route path={'/'} element={<Dashboard/>}/>
                    <Route path={'/profile'} element={<Profile/>}/>
                    <Route path={'/profile/support'} element={<ProfileSupport/>}/>
                    <Route path={'/profile/help'} element={<ProfileHelp/>}/>
                    <Route path={'/notification'} element={<Notification/>}/>
                    <Route path={'/import/:type'} element={<div>Import</div>}/>
                    <Route path={'/transport'} element={<div>Transport</div>}/>
                    <Route path={'/transport/:type'} element={<div>Transport</div>}/>
                    <Route path={'/transport-lines'} element={<TransportLineGrid/>}/>
                    <Route path={'/shipments'} element={<ShipmentGrid/>}/>
                    <Route path={'/shipments/:type/:gid?'} element={<ShipmentGrid/>}/>
                    <Route path={'/shipment/orderdetails'} element={<ShipmentGridNoValidationWrapper/>}/>
                    <Route path={'/shipment/detailsinfo'} element={<OfferGridNoValidationWrapper/>}/>
                    <Route path={'/shipment/:gid/:xid'} element={<ShipmentFormPage/>}/>
                    <Route path={'/disposition'} element={<DispositionGrid/>}/>
                    <Route path={'/invoices'} element={<InvoiceGrid/>}/>
                    <Route path={'/invoice/:id'} element={<InvoiceFormPage/>}/>
                    <Route path={'/quotes'} element={<QuoteGrid/>}/>
                    <Route path={'/quote/detailsinfo'} element={<QuoteGridNoValidationWrapper/>}/>
                    <Route path={'/quote/QuoteDetails'} element={<QuoteGridNoValidationWrapper/>}/>
                    <Route path={'/quotes/:type'} element={<QuoteGrid/>}/>
                    <Route path={'/quote/:id'} element={<QuoteFormPage/>}/>
                    <Route path={'/offers'} element={<OfferGrid/>}/>
                    <Route path={'/offer/orderdetails'} element={<OfferGridNoValidationWrapper/>}/>
                    <Route path={'/offer/detailsinfo'} element={<OfferGridNoValidationWrapper/>}/>
                    <Route path={'/offers/:type'} element={<OfferGrid/>}/>
                    <Route path={'/offer/:id'} element={<OfferFormPage/>}/>
                </Route>

                {/* This layout used for unauthenticated device pages */}
                <Route element={
                    <UserAuthenticatedProtectedRoute>
                        <AnonymousDeviceRoute>
                            <DeviceAnonymousLayout/>
                        </AnonymousDeviceRoute>
                    </UserAuthenticatedProtectedRoute>
                }>
                    <Route path={'/authentication/device'} element={<DeviceAuthentication/>}/>
                </Route>

                {/* This layout used for anonymous data access pages */}
                <Route element={
                    <AnonymousDataAccessRoute>
                        <AnonymousDataAccessLayout/>
                    </AnonymousDataAccessRoute>
                }>
                    <Route path={'/selfbilling/start'} element={<SelfBillingStart/>}/>
                    <Route path={'/selfbilling/confirmation'} element={<SelfBillingConfirmation/>}/>
                    <Route path={'/selfbilling/support'} element={<SelfBillingSupport/>}/>
                    <Route path={'/selfbilling/registration'} element={<Registration/>}/>
                    <Route path={'/selfbilling/login'} element={<SelfBillingLogin/>}/>
                    <Route path={'/selfbilling/shipment'} element={<SelfBillingShipment/>}/>

                    <Route path={'/offer/sendcontractdirect'} element={<SendContract/>}/>
                    <Route path={'/offer/offerdetailsshort'} element={<OfferDetailsPage/>}/>

                    <Route path={'/shipment/changedateviewshort'} element={<ChangePlannedDate/>}/>
                    <Route path={'/shipment/eventsviewshort'} element={<EditShipmentEvent/>}/>
                </Route>

                {/* This page used for anonymous or authenticated user*/}
                <Route path={'/shipment/driverchecklist'} element={<ConfirmTruckStatePage/>}/>

                
            </Route>
        )
    );
}

export default getRouterWithRoutes;
