import React, {useState} from 'react';
import {IRemarkGetAction} from "../../../../../../app/interfaces/offer/IRemarkGetAction";
import {IRemarkPostAction} from "../../../../../../app/interfaces/offer/IRemarkPostAction";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import SelectedOffers from "../../../form/components/SelectedOffers/SelectedOffers";
import {SelectModel} from "../../../../../../app/types/SelectModel";
import {useTranslation} from "react-i18next";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import Textarea from "../../../../../../components/ui/Textarea/Textarea";
import {RemarkName} from "../../../../../../app/enums/RemarkName";
import {getRemarkNameResource} from "../../../../../../helpers/remark";

type EditRemarkFormProps = {
    model: IRemarkGetAction;
    disabled: boolean;
    save: (item: IRemarkPostAction) => Promise<void>;
};

const EditRemarkForm: React.FC<EditRemarkFormProps> = ({
                                                           model,
                                                           save,
                                                           disabled
                                                       }) => {
    const {t} = useTranslation();
    const [reasons] = useState<Array<SelectModel>>([
        {
            text: `${t(getRemarkNameResource(RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_LOADING)).replace(':', '')}` +
                ` (${RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_LOADING})`,
            value: RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_LOADING
        },
        {
            text: `${t(getRemarkNameResource(RemarkName.LOADING_DOCUMENTS)).replace(':', '')}` +
                ` (${RemarkName.LOADING_DOCUMENTS})`,
            value: RemarkName.LOADING_DOCUMENTS
        },
        {
            text: `${t(getRemarkNameResource(RemarkName.EQUIPMENT_DETAILS)).replace(':', '')}` +
                ` (${RemarkName.EQUIPMENT_DETAILS})`,
            value: RemarkName.EQUIPMENT_DETAILS
        },
        {
            text: `${t(getRemarkNameResource(RemarkName.EBS_AAG_COMMENTS_FROM_CUSTOMER)).replace(':', '')}` +
                ` (${RemarkName.EBS_AAG_COMMENTS_FROM_CUSTOMER})`,
            value: RemarkName.EBS_AAG_COMMENTS_FROM_CUSTOMER
        },
        {
            text: `${t(getRemarkNameResource(RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_EXP_CUSTOMS)).replace(':', '')}` +
                ` (${RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_EXP_CUSTOMS})`,
            value: RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_EXP_CUSTOMS
        },
        {
            text: `${t(getRemarkNameResource(RemarkName.COMMENTS_FOR_CUSTOMER_AND_SERV_PROVIDER_TO_PRINT)).replace(':', '')}` +
                ` (${RemarkName.COMMENTS_FOR_CUSTOMER_AND_SERV_PROVIDER_TO_PRINT})`,
            value: RemarkName.COMMENTS_FOR_CUSTOMER_AND_SERV_PROVIDER_TO_PRINT
        },
        {
            text: `${t(getRemarkNameResource(RemarkName.COMMENTS_FROM_SERVICE_PROVIDER)).replace(':', '')}` +
                ` (${RemarkName.COMMENTS_FROM_SERVICE_PROVIDER})`,
            value: RemarkName.COMMENTS_FROM_SERVICE_PROVIDER
        },
        {
            text: `${t(getRemarkNameResource(RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT)).replace(':', '')}` +
                ` (${RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT})`,
            value: RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT
        },
        {
            text: `${t(getRemarkNameResource(RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_FREIGHT)).replace(':', '')}` +
                ` (${RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_FREIGHT})`,
            value: RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_FREIGHT
        },
        {
            text: `${t(getRemarkNameResource(RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_DISCHARGE)).replace(':', '')}` +
                ` (${RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_DISCHARGE})`,
            value: RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_DISCHARGE
        },
        {
            text: `${t(getRemarkNameResource(RemarkName.COMMENTS)).replace(':', '')}` +
                ` (${RemarkName.COMMENTS})`,
            value: RemarkName.COMMENTS
        },
        {
            text: `${t(getRemarkNameResource(RemarkName.COMMENTS_FOR_SUPPLIER_CABINET)).replace(':', '')}` +
                ` (${RemarkName.COMMENTS_FOR_SUPPLIER_CABINET})`,
            value: RemarkName.COMMENTS_FOR_SUPPLIER_CABINET
        },
        {
            text: `${t(getRemarkNameResource(RemarkName.COMMENTS_FROM_CUSTOMER)).replace(':', '')}` +
                ` (${RemarkName.COMMENTS_FROM_CUSTOMER})`,
            value: RemarkName.COMMENTS_FROM_CUSTOMER
        },
        {
            text: `${t(getRemarkNameResource(RemarkName.COMMENTS_FROM_FORWARDING_AGENT)).replace(':', '')}` +
                ` (${RemarkName.COMMENTS_FROM_FORWARDING_AGENT})`,
            value: RemarkName.COMMENTS_FROM_FORWARDING_AGENT
        },
        {
            text: `${t(getRemarkNameResource(RemarkName.COMMENTS_FROM_SALES_AGENT)).replace(':', '')}` +
                ` (${RemarkName.COMMENTS_FROM_SALES_AGENT})`,
            value: RemarkName.COMMENTS_FROM_SALES_AGENT
        },
        {
            text: `${t(getRemarkNameResource(RemarkName.SHIPMENT_DETAILS)).replace(':', '')}` +
                ` (${RemarkName.SHIPMENT_DETAILS})`,
            value: RemarkName.SHIPMENT_DETAILS
        }
    ]);

    const form = useFormik<IRemarkPostAction>({
        initialValues: {
            mainGid: model.mainGid,
            orderReleaseGid: model.orderReleaseGid,
            code: model.code ?? RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_LOADING,
            text: model.text,
            seq: model.seq,
            isNew: model.isNew
        },
        validationSchema: Yup.object().shape({
            code: Yup.string().required(t("shared.errors.required-error").replace('{0}', t("offer.form.remark-type"))),
            text: Yup.string().required(t("shared.errors.required-error").replace('{0}', t("offer.form.remark-text")))
        }),
        onSubmit: async (values: IRemarkPostAction, actions: FormikHelpers<IRemarkPostAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='edit-remark-form'
              onSubmit={form.handleSubmit}>
            {model.items.length > 1 && <SelectedOffers ids={model.items.map(item => item.id)}/>}
            <div className={'pd-10'}>
                <div className={`mgb-10 drop-down-picker`}>
                    <label className={'view-label required remark-type-label'}>
                        {t("offer.form.remark-type")}
                    </label>

                    <DropDownList data={reasons}
                                  textField="text"
                                  disabled={disabled || reasons.length <= 0}
                                  id={'edit-remark-type'}
                                  dataItemKey="value"
                                  value={form.values.code !== null ? reasons.find(e => e.value === form.values.code) : null}
                                  onChange={(ev) => {
                                      form.setFieldValue('code', ev.target.value.value);
                                  }}/>

                    {form.errors.code &&
                        <div className={'error-label'}>
                            {form.errors.code}
                        </div>
                    }
                </div>

                <div className={'mgb-10'}>
                    <label className={'view-label required remark-text-label'}>
                        {t("offer.form.remark-text")}
                    </label>
                    <Textarea disabled={disabled}
                              className={'edit-remark-text-value'}
                              style={{minHeight: '100px', resize: 'vertical', maxHeight: '150px'}}
                              value={form.values.text ?? ''}
                              placeholder={t("offer.form.remark-text")}
                              onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                  form.setFieldValue('text', ev.target.value);
                              }}/>

                    {form.errors.text &&
                        <div className={'error-label'}>
                            {form.errors.text}
                        </div>
                    }
                </div>
            </div>
        </form>
    );
};

export default EditRemarkForm;