import React from 'react';
import cl from './Loader.module.css';

export enum LoaderType {
    Default = 0,
    Action
}

type LoaderProps = {
    style?: React.CSSProperties,
    type?: LoaderType;
};

const Loader: React.FC<LoaderProps> = ({style, type = LoaderType.Default}) => {
    const getLoaderClassName = () => {
        switch (type) {
            case LoaderType.Default:
                return 'loader';
            case LoaderType.Action:
                return cl.loader
        }
    }

    return (
        <div className={cl.container}>
            <div className={getLoaderClassName()} style={style}>

            </div>
        </div>
    )
};

export default Loader;