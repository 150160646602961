import React from 'react';
import {OrderType} from "../../../../app/enums/OrderType";
import {useTranslation} from "react-i18next";
import cl from './RouteSection.module.css';
import moment from "moment/moment";
import YearlyPickupDate from "./fields/YearlyPickupDate/YearlyPickupDate";
import Region from "../../form/detailsFields/Region/Region";
import City from "../../form/detailsFields/City/City";
import {IStopPoint} from "../../../../app/interfaces/shared/IStopPoint";
import LateDeliveryDate from "./fields/LateDeliveryDate/LateDeliveryDate";
import RouteSectionStopPointList from "./components/RouteSectionStopPointList/RouteSectionStopPointList";
import Equipment from "./fields/Equipment/Equipment";
import TransportMode from "./fields/TransportMode/TransportMode";
import Quantity from './fields/Quantity/Quantity';
import VolumePerYear from './fields/VolumePerYear/VolumePerYear';
import IncoTerms from "./fields/IncoTerms/IncoTerms";
import Icon from "../../../../assets/icon/Icon";
import useMediaQuery from "../../../../hooks/useMediaQuery/useMediaQuery";

export type RouteSectionModel = {
    orderType: OrderType;

    earlyPickupDate: Date | null;
    earlyPickupDateText: string | null;
    earlyDeliveryDate?: Date | null;

    lateDeliveryDateText: string | null;
    lateDeliveryDate?: Date | null;
    latePickupDate: Date | null;

    isFinished?: boolean | null;
    hasShipment: boolean;
    shipmentGid: string | null;
    orderReleaseGid: string | null;
    stopPoints: Array<IStopPoint>;

    sourceCountry: string | null;
    sourceCountryCode2: string | null;
    sourceProvinceCode: string | null;
    sourceProvinceDesc: string | null;
    sourceCity: string | null;

    destinationCountry: string | null;
    destinationCountryCode2: string | null;
    destinationProvinceCode: string | null;
    destinationProvinceDesc: string | null;
    destinationCity: string | null;

    transportMode?: string | null;
    product?: string | null;
    equipment?: string | null;
    equipmentGroupName?: string | null;
    incoterms?: string | null;
    volumePerYear?: string | null;
    quantity?: string | null;
    quantityBatch?: string | null;
}

type RouteSectionProps = {
    model: RouteSectionModel;
    showEdit?: boolean
}

const isWarn = (model: RouteSectionModel): boolean => {
    if (model.shipmentGid !== null && model.shipmentGid !== '') {
        return false;
    }

    return (((model.latePickupDate == null || false) && model.earlyPickupDate !== null && model.earlyPickupDate !== undefined && model.earlyPickupDate <= moment().toDate()) || (model.latePickupDate !== null && model.latePickupDate !== undefined && model.latePickupDate <= moment().toDate()));
}

const RouteSideSection: React.FC<RouteSectionProps> = ({
                                                       showEdit,
                                                       model
                                                   }) => {
    const {t} = useTranslation();
    const isMaxWidth600 = useMediaQuery('(max-width:600px)');

    return (
        <div>
            {model.orderType === OrderType.Quote &&
                <div className={'details-box-default-container-columns'}
                     data-count={isMaxWidth600 ? '1' : '2'}
                     style={{marginBottom: '20px'}}>
                    {!isMaxWidth600 &&
                        <>
                            <div>
                                <div className={'details-box-default-container-item'}>
                                    <TransportMode model={model}/>
                                </div>

                                <div className={'details-box-default-container-item'}>
                                    <Equipment model={model}/>
                                </div>

                                <div className={'details-box-default-container-item'}>
                                    <IncoTerms model={model}/>
                                </div>
                            </div>

                            <div>
                                <div className={'details-box-default-container-item'}>
                                    <VolumePerYear model={model}/>
                                </div>

                                <div className={'details-box-default-container-item'}>
                                    <Quantity model={model}/>
                                </div>
                            </div>
                        </>
                    }

                    {isMaxWidth600 &&
                        <div>
                            <div className={'details-box-default-container-item'}>
                                <TransportMode model={model}/>
                            </div>

                            <div className={'details-box-default-container-item'}>
                                <Equipment model={model}/>
                            </div>

                            <div className={'details-box-default-container-item'}>
                                <IncoTerms model={model}/>
                            </div>

                            <div className={'details-box-default-container-item'}>
                                <VolumePerYear model={model}/>
                            </div>

                            <div className={'details-box-default-container-item'}>
                                <Quantity model={model}/>
                            </div>
                        </div>
                    }
                </div>
            }

            <div className={cl.container}>
                <div className={cl.point}>
                    <header>

                        {t("shipment.form.source-point")}
                        <Icon icon={'faArrowRight'}
                              className={cl.icon}
                              dataCustomValueAttribute={"[P]"}/>

                    </header>

                    <div>
                        <div className={`details-box-default-container-item ${cl.row}`}>
                            <label className={`source-yearly-pickup-date-label`}>
                                {t("shipment.form.early-pickup-date")}
                            </label>

                            <div
                                className={`details-box-item-field-value source-yearly-pickup-date-value ${isWarn(model) ? 'warn-red' : ''}`}>
                                <YearlyPickupDate model={model}/>
                            </div>
                        </div>

                        <div className={`details-box-default-container-item ${cl.row}`}>
                            <label className={`source-country-flag-label`}>
                                {t("shipment.form.source-country")}
                            </label>
                            <div className={`details-box-item-field-value source-country-flag-value`}>
                                {model.sourceCountry}
                            </div>
                        </div>

                        <div className={`details-box-default-container-item ${cl.row}`}>
                            <label className={`source-region-label`}>
                                {t("shipment.form.source-region")}
                            </label>
                            <div className={`details-box-item-field-value source-region-value`}>
                                <Region code={model.sourceCountryCode2}
                                        provinceCode={model.sourceProvinceCode}
                                        provinceDescription={model.sourceProvinceDesc}/>
                            </div>
                        </div>

                        <div className={`details-box-default-container-item ${cl.row}`}>
                            <label className={`source-city-label`}>
                                {t("shipment.form.source-city")}
                            </label>
                            <div className={`details-box-item-field-value source-city-value`}>
                                <City city={model.sourceCity}/>
                            </div>
                        </div>
                        {model.stopPoints !== null && model.stopPoints.filter(e => e.type?.toLowerCase() === '[p]').length > 1
                            ? <span className={cl.warn} >
                                    {`${model.stopPoints.filter(e => e.type?.toLowerCase() === '[p]').length} ${t("shipment.form.stops-for-loading")}`}
                            </span>
                            : null
                        }
                    </div>
                </div>

                <div>
                    {/*<Icon icon={"faCircleArrowRight"}*/}
                    {/*      rotate={!isMaxWidth600 ? undefined : 90}/>*/}
                </div>

                <div className={cl.point}>
                    <header>

                        {t("shipment.form.destination-point")}
                        <Icon icon={'faArrowLeft'}
                              className={cl.icon}
                              dataCustomValueAttribute={"[D]"}/>

                    </header>

                    <div>
                        <div className={`details-box-default-container-item ${cl.row}`}>
                            <label className={`destination-late-delivery-date-label`}>
                                {t("shipment.form.late-delivery-date")}
                            </label>
                            <div className={`details-box-item-field-value destination-late-delivery-date-value`}>
                                <LateDeliveryDate model={model}/>
                            </div>
                        </div>

                        <div className={`details-box-default-container-item ${cl.row}`}>
                            <label className={`destination-country-flag-label`}>
                                {t("shipment.form.destination-country")}
                            </label>
                            <div className={`details-box-item-field-value destination-country-flag-value`}>
                                {model.destinationCountry}
                            </div>
                        </div>

                        <div className={`details-box-default-container-item ${cl.row}`}>
                            <label className={`destination-region-label`}>
                                {t("shipment.form.destination-region")}
                            </label>
                            <div className={`details-box-item-field-value destination-region-value`}>
                                <Region code={model.destinationCountryCode2}
                                        provinceCode={model.destinationProvinceCode}
                                        provinceDescription={model.destinationProvinceDesc}/>
                            </div>
                        </div>

                        <div className={`details-box-default-container-item ${cl.row}`}>
                            <label className={`destination-city-label`}>
                                {t("shipment.form.destination-city")}
                            </label>
                            <div className={`details-box-item-field-value destination-city-value`}>
                                <City city={model.destinationCity}/>
                            </div>
                        </div>
                        {model.stopPoints !== null && model.stopPoints.filter(e => e.type?.toLowerCase() === '[d]').length > 1
                            ? <span className={cl.warn} >
                                    {`${model.stopPoints.filter(e => e.type?.toLowerCase() === '[d]').length} ${t("shipment.form.stops-for-un-loading")}`}
                            </span>
                            : null
                        }
                    </div>
                </div>
            </div>

            <div className={cl.route}>
                {t("shipment.form.route-details")}
            </div>

            <div className={'view-row'}>
                <RouteSectionStopPointList showEdit={showEdit} model={model}/>
            </div>
        </div>
    );
};

export default RouteSideSection;

