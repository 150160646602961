import React from 'react';
import {useTranslation} from "react-i18next";
import SidebarNavigationButton from "../../../components/SidebarNavigationButton/SidebarNavigationButton";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";

type SidebarMenuTransportProps = {
    expandedId: string;
    onClick?: (id: string) => void;
};


const SidebarMenuTransport:  React.FC<SidebarMenuTransportProps> = ({
                                                                        expandedId,
                                                                        onClick: onClickProp,
                                                                    }) => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    const handleNavigationClick = (id: string) => {
        if (onClickProp) {
            onClickProp(id);
        }
    };


    if (!user) {
        return null;
    }

    let role = getUserRole(user);

    if (role !== UserRole.ServiceProvider) {
        return null;
    }

    return (
        <SidebarNavigationButton id={'sidebar-transport-item'} expandedId={expandedId}
                                 type={'primary'} onClick={handleNavigationClick}
                                 text={t("sidebar.transport.transport-lines")}
                                 title={t("sidebar.transport.transport-lines")}
                                 path={'/transport-lines'}
                                 icon={"faTruck"}
                                 activeWhenAdditionalPath={['/transport-lines']}>

        </SidebarNavigationButton>
    );
};

export default SidebarMenuTransport;
