import React, {useEffect} from 'react';
import cl from './DeviceAnonymousLayout.module.css';
import {Outlet} from "react-router-dom";
import useIfFirstRender from "../../../hooks/useIsFirstRender/useIfFirstRender";
import {useAppDispatch, useAppSelector} from "../../../app/store";
import {
    selectDeviceAuthenticationResult,
    selectDeviceAuthenticationStatus
} from "../../../features/authentication/authenticationSliceSelectors";
import {AuthenticationStatus} from "../../../features/authentication/IAuthenticationState";
import LoadingScreen from "../../ui/loaders/LoadingScreen/LoadingScreen";
import {
    validateDeviceAuthentication,
    validateUserAuthentication
} from "../../../features/authentication/authenticationSlice";
import {ResponseResultCode} from "../../../app/enums/ResponseResultCode";
import {useLoginApi} from "../../../app/api/login";
import {useTranslation} from "react-i18next";
import {setLogoutMessage} from "../../../features/application/applicationSlice";
import useSignalRHub from "../../../hooks/useSignalRHub/useSignalRHub";

const DeviceAnonymousLayout: React.FC = () => {
    const {t} = useTranslation();
    const isFirstRender = useIfFirstRender();
    const dispatch = useAppDispatch();
    const {logout: {mutation}} = useLoginApi();
    const [, close] = useSignalRHub(`${process.env.REACT_APP_API_DOMAIN}/applicationHub`, {
        singleton: true
    });
    const deviceAuthenticationStatus = useAppSelector(selectDeviceAuthenticationStatus);
    const deviceAuthenticationResult = useAppSelector(selectDeviceAuthenticationResult);

    useEffect(() => {
        if (isFirstRender) {
            // Check if device is existing and confirmation is valid
            dispatch(validateDeviceAuthentication());
        }
    }, [isFirstRender, dispatch]);

    useEffect(() => {
        if (isFirstRender || deviceAuthenticationResult === undefined) {
            return
        }
        // Process device validation result
        // If the result Not found, the device authentication continues. Send email and confirm with code.
        (async () => {
            try {
                // Not valid arguments or server error, need to log out and back to /login page
                if (deviceAuthenticationResult === ResponseResultCode.Unauthorized ||
                    deviceAuthenticationResult === ResponseResultCode.NotValidData ||
                    deviceAuthenticationResult === ResponseResultCode.ServerError) {
                    dispatch(setLogoutMessage(t("shared.errors.server-error")));
                    close();
                    await mutation();

                    // This call sets global user authentication state
                    dispatch(validateUserAuthentication());
                }

                // In this case device is blocked, need to log out with appropriate message
                if (deviceAuthenticationResult === ResponseResultCode.Forbidden) {
                    dispatch(setLogoutMessage(t("device.errors.forbidden")));
                    close();
                    await mutation();

                    // This call sets global user authentication state
                    dispatch(validateUserAuthentication());
                }
            } catch (error) {
                close();
                await mutation();

                // This call sets global user authentication state
                dispatch(validateUserAuthentication());
            }

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFirstRender, deviceAuthenticationResult]);

    if (deviceAuthenticationStatus === AuthenticationStatus.NoAction ||
        deviceAuthenticationStatus === AuthenticationStatus.InProgress ||
        deviceAuthenticationResult === undefined ||
        deviceAuthenticationResult !== ResponseResultCode.NotFound) {
        return <LoadingScreen/>;
    }

    return (
        <div className={cl.container}>
            <Outlet/>
        </div>
    );
};

export default DeviceAnonymousLayout;