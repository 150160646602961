import React, {useEffect, useState} from 'react';
import {SelectModel} from "../../../../../../../../app/types/SelectModel";
import {useCurrencyApi} from "../../../../../../../../app/api/currency";
import {ResponseResultCode} from "../../../../../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {IUser} from "../../../../../../../../app/interfaces/user/IUser";

type SendContractCurrencySelectorProps = {
    disabled: boolean;
    currency: string | null;
    onChange: (currency: string | null) => void;
    user?: IUser;
};

const SendContractCurrencySelector: React.FC<SendContractCurrencySelectorProps> = ({
                                                                                       user,
                                                                                       disabled,
                                                                                       currency,
                                                                                       onChange
                                                                                   }) => {
    const [data, setData] = useState<Array<SelectModel>>([]);

    const {
        getCurrencies: {
            query: getCurrencies,
            isLoading: getCurrenciesIsLoading,
            cancel: cancelGetCurrencies
        },
        getCurrenciesAnonymous: {
            query: getCurrenciesAnonymous,
            isLoading: getCurrenciesAnonymousIsLoading,
            cancel: cancelGetCurrenciesAnonymous
        }
    } = useCurrencyApi();

    useEffect(() => {
        (async () => {
            try {
                const response = user
                    ? await getCurrencies('All')
                    : await getCurrenciesAnonymous('All');

                if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok &&
                    response.data?.data) {
                    setData(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error(`Unable to get currencies: ${e.message}`);
                }
            }
        })();

        return () => {
            cancelGetCurrencies();
            cancelGetCurrenciesAnonymous();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DropDownList data={data}
                      disabled={disabled || getCurrenciesIsLoading || getCurrenciesAnonymousIsLoading}
                      loading={getCurrenciesIsLoading || getCurrenciesAnonymousIsLoading}
                      textField="text"
                      dataItemKey="value"
                      value={currency !== null ? data.find(e => e.value === currency) : null}
                      onChange={(ev) => {
                          if (ev.value) {
                              onChange(ev.value.value);
                          }
                      }}
        />
    );
};

export default SendContractCurrencySelector;