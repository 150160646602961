import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'circleDollar',
    pathData: [
        'M7.66667 3.66667V7.66667L10.3333 9M14.3333 7.66667C14.3333 11.3486 11.3486 14.3333 7.66667 14.3333C3.98477 14.3333 1 11.3486 1 7.66667C1 3.98477 3.98477 1 7.66667 1C11.3486 1 14.3333 3.98477 14.3333 7.66667Z'
    ],
    viewBox: '0 0 15 15',
    strokeWidth: 1,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;