import React from 'react';
import {IShipment} from "../../../../../../../app/interfaces/shipment/IShipment";
import {useTranslation} from "react-i18next";

type TrailerProps = {
    shipment: IShipment;
}

const Trailer: React.FC<TrailerProps> = ({
                                             shipment
                                         }) => {
    const {t} = useTranslation();

    return (
        <>
            <label className={'trailer-label'}>{t('shipment.form.trailer')}</label>
            <span className={'details-box-item-field-value trailer-value'}>
                {shipment.trailer !== null && shipment.trailer !== ''
                    ? <>{shipment.trailer}</>
                    : <>{t("shared.labels.no-data")}</>
                }
            </span>
        </>
    );
};

export default Trailer;
