import React, {useState} from 'react';
import {IShipment} from "../../../../../../../app/interfaces/shipment/IShipment";
import cl from './ShowTimeSlot.module.css';
import {useTranslation} from "react-i18next";
import {ITsHistory} from "../../../../../../../app/interfaces/shared/ITsHistory";
import {useShipmentApi} from "../../../../../../../app/api/shipment";
import {ResponseResultCode} from "../../../../../../../app/enums/ResponseResultCode";
import Loader, {LoaderType} from "../../../../../../../components/ui/loaders/Loader/Loader";
import uuid from "react-uuid";
import moment from "moment";
import ShowTimeSlotDialog from "../../../../actions/ShowTimeSlot/ShowTimeSlotDialog";
import Button, {ButtonType} from "../../../../../../../components/ui/Button/Button";
import {useOfferApi} from "../../../../../../../app/api/offer";


type ShowTimeSlotProps = {
    shipment: IShipment;
}

const ShowTimeSlot: React.FC<ShowTimeSlotProps> = ({
                                                               shipment
                                                           }) => {
    const {t} = useTranslation();

    const {
        getTimeSlotData: {
            query
        }
    } = useShipmentApi();

    if (!shipment || shipment.hasBookingData === null) {
        return null;
    }



    return (
        <Button buttonType={ButtonType.Primary}
                onClick={async () => {
                    try {
                        const response = await query(shipment.shipmentGid??"");

                        if (response && response.data && response.data.resultCode === ResponseResultCode.Ok &&
                            response.data.data) {
                            window.open(response.data.data, '_blank');
                        }
                    } catch {
                        //ignore
                    }
                }}
                style={{
                    padding: '0 10px',
                    fontSize: '11px',
                    fontWeight: 'bold',
                    height: '20px'
                }}>
            {t("shared.btn.show-in-transporeon-time-slot")}
        </Button>


    );
};

export default ShowTimeSlot;
