import React, {useEffect, useRef, useState} from 'react';
import cl from "../../../ui/Tag/Tag.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faRectangleXmark} from "@fortawesome/free-solid-svg-icons";
import {createPortal} from "react-dom";
import {useTranslation} from "react-i18next";
import uuid from "react-uuid";

export type TagItem = {
    id: string | number;
    text: string;
    title: string;
    showDeleteIcon: boolean;
    showEditIcon: boolean;
    showHoverDeleteIcon: boolean;
    showHoverEditIcon: boolean;
    hoverMagnification: boolean;
    backgroundColor: string;
    color?: string;
};

export type TagProps = {
    item: TagItem;
    size: 'full' | 'rectangle';
    onClear?: (item: TagItem) => void;
    onEdit?: (item: TagItem) => void;
    containerMaxWidth: number;
};

type TagCoordinates = {
    x: number;
    y: number;
};

const getTextContentMaxWidth = (item: TagItem, containerMaxWidth: number): number => {
    let textContentMaxWidth: number;

    if (item.showEditIcon && item.showDeleteIcon) {
        textContentMaxWidth = containerMaxWidth - 49.6;
    } else if (item.showEditIcon || item.showDeleteIcon) {
        textContentMaxWidth = containerMaxWidth - 31.2;
    } else {
        textContentMaxWidth = containerMaxWidth - 12.8;
    }

    return textContentMaxWidth;
};

const Tag: React.FC<TagProps> = ({
                                     item,
                                     size,
                                     onClear,
                                     onEdit,
                                     containerMaxWidth
                                 }) => {
    const {t} = useTranslation();

    const guid = useRef<string>(uuid());
    const [point, setPoint] = useState<TagCoordinates | null>(null);

    useEffect(() => {
        if (!point || !item.hoverMagnification) {
            return;
        }

        const handleMouseMove = (event: MouseEvent) => {
            let button = document.querySelector(`.tag-${guid.current}`);
            let hoverButton = document.querySelector(`.tag-magnification-${guid.current}`);

            if (button && hoverButton) {
                let buttonRect = button.getBoundingClientRect();
                let hoverButtonRect = hoverButton.getBoundingClientRect();

                let isInteracting = false;

                if ((event.x >= buttonRect.x && event.x <= buttonRect.x + buttonRect.width &&
                        event.y >= buttonRect.y && event.y <= buttonRect.y + buttonRect.height) ||
                    (event.x >= hoverButtonRect.x && event.x <= hoverButtonRect.x + hoverButtonRect.width &&
                        event.y >= hoverButtonRect.y && event.y <= hoverButtonRect.y + hoverButtonRect.height)) {
                    isInteracting = true;
                }

                if (!isInteracting) {
                    setPoint(null);
                }
            }
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [point]);

    return (
        <>
            <button id={`tag-${item.id}`}
                    className={`${cl.tag} ${size === 'rectangle' ? cl.rectangle : ''} tag-${guid.current}`}
                    style={{
                        backgroundColor: `#${item.backgroundColor}`,
                        color: `${item.color ? '#' + item.color : 'white'}`
                    }}
                    onMouseEnter={ev => {
                        if (!item.hoverMagnification) {
                            return;
                        }

                        let rect = (ev.target as HTMLElement).getBoundingClientRect();

                        setPoint({
                            x: rect.left + rect.width / 2,
                            y: rect.top + rect.height / 2
                        });
                    }}>

                {size === 'full' && item.showEditIcon &&
                    <FontAwesomeIcon icon={faPen}
                                     title={t("shared.btn.edit")}
                                     onClick={(ev) => {
                                         ev.stopPropagation();

                                         if (onEdit) {
                                             onEdit(item);
                                         }
                                     }}
                                     className={`${cl.pen} grid-prevent-selection`}/>
                }

                {size === 'full' &&
                    <div title={item.title}
                         style={{
                             maxWidth: `${getTextContentMaxWidth(item, containerMaxWidth)}px`,
                             whiteSpace: 'nowrap',
                             overflow: 'hidden',
                             textOverflow: 'ellipsis'
                         }}>
                        {item.text}
                    </div>
                }

                {size === 'full' && item.showDeleteIcon &&
                    <FontAwesomeIcon icon={faRectangleXmark}
                                     title={t("shared.btn.delete-tag")}
                                     onClick={(ev) => {
                                         ev.stopPropagation();

                                         if (onClear) {
                                             onClear(item);
                                         }
                                     }}
                                     className={`${cl.close} grid-prevent-selection`}/>
                }
            </button>

            {item.hoverMagnification && point &&
                createPortal(
                    <div id={`tag-magnification-${item.id}`}
                         className={`${cl.tag} ${cl.tagMagnified} tag-magnification-${guid.current}`}
                         style={{
                             backgroundColor: `#${item.backgroundColor}`,
                             position: 'absolute',
                             top: `${point.y}px`,
                             left: `${point.x}px`,
                         }}>
                        <div title={item.title}
                             style={{
                                 maxWidth: `${getTextContentMaxWidth(item, containerMaxWidth)}px`,
                                 whiteSpace: 'nowrap',
                                 overflow: 'hidden',
                                 textOverflow: 'ellipsis'
                             }}>
                            {item.text}
                        </div>

                        {item.showHoverDeleteIcon &&
                            <FontAwesomeIcon icon={faRectangleXmark}
                                             title={t("shared.btn.delete-tag")}
                                             onClick={(ev) => {
                                                 ev.stopPropagation();

                                                 if (onClear) {
                                                     onClear(item);
                                                 }
                                             }}
                                             className={`${cl.close} ${cl.closeMagnified} grid-prevent-selection`}/>
                        }
                    </div>,
                    document.body)
            }
        </>
    )
        ;
};

export default Tag;