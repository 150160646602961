import React from 'react';
import {IQuote} from "../../../../../../../../app/interfaces/quote/IQuote";
import {useQuoteApi} from "../../../../../../../../app/api/quote";
import {ResponseResultCode} from "../../../../../../../../app/enums/ResponseResultCode";

type QuoteNumberProps = {
    quote: IQuote;
}

const QuoteNumber: React.FC<QuoteNumberProps> = ({
                                                     quote
                                                 }) => {
    const {
        getQuoteManagementLink: {
            query: getQuoteManagementLink
        }
    } = useQuoteApi();

    return (
        <div>
              <span className={'details-box-item-field-value'}
                    onClick={async (ev) => {
                        ev.stopPropagation();

                        try {
                            const response = await getQuoteManagementLink(quote.orderReleaseXid);

                            if (response && response.data && response.data.resultCode === ResponseResultCode.Ok &&
                                response.data.data) {
                                window.open(response.data.data, '_blank');
                            }
                        } catch {
                            //ignore
                        }
                    }}>
                  <span style={{color: 'var(--main-orange-color)', cursor: 'pointer'}}>{quote.orderReleaseXid}</span>
              </span>
        </div>
    );
};

export default QuoteNumber;
