import {configureStore} from "@reduxjs/toolkit";
import applicationReducer from '../../features/application/applicationSlice';
import authenticationReducer from '../../features/authentication/authenticationSlice';
import filterReducer from '../../features/filter/filterSlice';
import chatReducer from '../../features/chat/chatSlice';

const store = configureStore({
    reducer: {
        application: applicationReducer,
        authentication: authenticationReducer,
        filter: filterReducer,
        chat: chatReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;