import {IShipment} from "../../../../../app/interfaces/shipment/IShipment";
import {IUser} from "../../../../../app/interfaces/user/IUser";
import {getUserRole} from "../../../../../helpers/user";
import {UserRole} from "../../../../../app/enums/UserRole";

export const isShipmentFormTabsShown = (data: IShipment, usr: IUser) => data.hasShipment && getUserRole(usr) !== UserRole.Client;

export const getShipmentFormTab = (data: IShipment, paramTab: string | null, usr: IUser): string => {
    if (!isShipmentFormTabsShown(data, usr) || !paramTab) {
        return '1';
    }

    let res = '1';

    if (paramTab === '4') {
        res = data.isCmrAllowedForSp ? '2' : '4';
    }

    if (paramTab === '2') {
        res = data.isCmrAllowedForSp ? '2' : '1';
    }

    return res;
}