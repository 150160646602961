import React from 'react';
import {IUser} from "../../../../../../../app/interfaces/user/IUser";
import {IShipmentInvoice} from "../../../../../../../app/interfaces/invoice/IShipmentInvoice";
import ShipmentInvoiceFormInvoiceListCard
    from "../ShipmentInvoiceFormInvoiceListCard/ShipmentInvoiceFormInvoiceListCard";
import cl from './ShipmentInvoiceFormInvoiceList.module.css';

type ShipmentInvoiceFormInvoiceListProps = {
    shipmentInvoice: IShipmentInvoice;
    user?: IUser;
    isSidePanelDetails: boolean;
};

const ShipmentInvoiceFormInvoiceList: React.FC<ShipmentInvoiceFormInvoiceListProps> = ({
                                                                                           shipmentInvoice,
                                                                                           user,
                                                                                           isSidePanelDetails
                                                                                       }) => {

    if (shipmentInvoice.invoices.length <= 0) {
        return null;
    }


    return (
        <div className={cl.container}>
            {[...shipmentInvoice.invoices].map(invoice => {
                return <ShipmentInvoiceFormInvoiceListCard key={invoice.invoiceGid}
                                                           data={shipmentInvoice}
                                                           invoice={invoice} isSidePanelDetails={isSidePanelDetails}
                                                           user={user}/>
            })}
        </div>
    );
};

export default ShipmentInvoiceFormInvoiceList;
