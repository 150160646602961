import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {SelectModel} from "../../../../../../../../app/types/SelectModel";
import {debounce} from "debounce";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {useDataHelperApi} from "../../../../../../../../app/api/dataHelper";
import {IUser} from "../../../../../../../../app/interfaces/user/IUser";
import {useAppSelector} from "../../../../../../../../app/store";
import {selectAnonymousUserId} from "../../../../../../../../features/authentication/authenticationSliceSelectors";

type SendContractSupplierManagerSelectorProps = {
    user?: IUser;
    disabled: boolean;
    value: string | null;
    onChange: (item: SelectModel | null) => void;
};

const SendContractSupplierManagerSelector: React.FC<SendContractSupplierManagerSelectorProps> = ({
                                                                                                     user,
                                                                                                     disabled,
                                                                                                     value,
                                                                                                     onChange
                                                                                                 }) => {
    const anonymousUserId = useAppSelector(selectAnonymousUserId);
    const {t} = useTranslation();
    const [items, setItems] = useState<Array<SelectModel>>([]);
    const [filteredItems, setFilteredItems] = useState<Array<SelectModel>>([]);
    const [filter, setFilter] = useState<string | undefined>();

    const {
        getUsersLoginsForAssigment: {
            query: getUsersLoginsForAssigment,
            isLoading: getUsersLoginsForAssigmentIsLoading,
            cancel: cancelGetUsersLoginsForAssigment
        },
        getUsersLoginsForAssigmentAnonymous: {
            query: getUsersLoginsForAssigmentAnonymous,
            isLoading: getUsersLoginsForAssigmentAnonymousIsLoading,
            cancel: cancelGetUsersLoginsForAssigmentAnonymous
        }
    } = useDataHelperApi();

    useEffect(() => {

        (async () => {
            await load();
        })();

        return () => {
            cancelGetUsersLoginsForAssigment();
            cancelGetUsersLoginsForAssigmentAnonymous();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            let filtered = items.filter(e => e.text.toLowerCase().includes(filter?.toLowerCase() ?? ''));

            setFilteredItems(filtered);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);


    const load = useCallback(debounce(async () => {
        try {
            const response = user
                ? await getUsersLoginsForAssigment()
                : await getUsersLoginsForAssigmentAnonymous(anonymousUserId);

            if (response?.status === 200) {
                setItems(response.data.filter(e => e.text !== '' && e.value !== ''));
                setFilteredItems(response.data.filter(e => e.text !== '' && e.value !== ''));
            }
        } catch {
            //ignore
        }
    }, 500), [anonymousUserId]);

    return (
        <div className={`w100 combobox-picker`}>
            <ComboBox data={filteredItems}
                      id={'send-contract-supplier-manager'}
                      value={value && filteredItems.length > 0 ? filteredItems.find(e => e.value === value) : undefined}
                      onChange={ev => onChange(ev.value ?? null)}
                      disabled={disabled || getUsersLoginsForAssigmentAnonymousIsLoading || getUsersLoginsForAssigmentIsLoading}
                      placeholder={t("offer.form.current-offer-supplier-manager")}
                      textField="text"
                      dataItemKey="value"
                      clearButton={true}
                      filterable={true}
                      loading={getUsersLoginsForAssigmentAnonymousIsLoading || getUsersLoginsForAssigmentIsLoading}
                      onFilterChange={(ev) => {
                          onChange(null);

                          if (!ev.filter?.value || ev.filter.value.length < 3) {
                              return;
                          }

                          setFilter(ev.filter.value);
                      }}
            />
        </div>
    );
};

export default SendContractSupplierManagerSelector;