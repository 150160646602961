import React from 'react';
import {openJobManagement} from "./JobCellTemplate";
import cl from './JobCellTemplate.module.css';

const JobGroupHeaderTemplate = (props: any) => {
    return (
        props.tdProps && (
            <td {...props.tdProps}>
                {props.children && (
                    <p className="k-reset" style={{marginLeft: '10px'}}>
                        <>
                            {props.children.props.children[0]}
                            <button className={cl.button}
                                    onClick={async () => await openJobManagement(props.dataItem[props.field])}>
                                {props.dataItem[props.field]}
                            </button>
                        </>
                    </p>
                )}
            </td>
        )
    );
};

export default JobGroupHeaderTemplate;