import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import FormLoadingScreen from "../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import * as ChangePlannedDateForm from "../../../authorized/Shipment/actions/ChangePlannedDate/ChangePlannedDate";

const ChangePlannedDate: React.FC = () => {
    const [searchParams] = useSearchParams();

    const [id, setId] = useState<string | null>(null);

    useEffect(() => {
        setId(searchParams.get('sid'));
    }, []);

    if (!id) {
        return <FormLoadingScreen height={`100%`} style={{padding: '5px'}}/>;
    }

    return (
        <div className={'h100 w100 hidden'}>
            <ChangePlannedDateForm.default id={'empty'} sId={id}/>
        </div>
    );
};

export default ChangePlannedDate;
