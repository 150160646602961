import React, {useEffect, useState} from 'react';
import {SelectModel} from "../../../../../../../../../../app/types/SelectModel";
import {useCurrencyApi} from "../../../../../../../../../../app/api/currency";
import {ResponseResultCode} from "../../../../../../../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {DropDownList} from "@progress/kendo-react-dropdowns";

type NettoBetCurrencySelectorProps = {
    disabled: boolean;
    currency: string | null;
    onChange: (currency: string | null) => void;
}

const NettoBetCurrencySelector: React.FC<NettoBetCurrencySelectorProps> = ({
                                                                               disabled,
                                                                               currency,
                                                                               onChange
                                                                           }) => {
    const [data, setData] = useState<Array<SelectModel>>([]);

    const {
        getCurrencies: {
            query,
            isLoading,
            cancel
        }
    } = useCurrencyApi();

    useEffect(() => {
        (async () => {
            try {
                const response = await query('Quote');

                if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok &&
                    response.data?.data) {
                    setData(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error(`Unable to get currencies: ${e.message}`);
                }
            }
        })();

        return () => {
            cancel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DropDownList data={data}
                      disabled={disabled || isLoading}
                      loading={isLoading}
                      textField="text"
                      dataItemKey="value"
                      value={currency !== null ? data.find(e => e.value === currency) : null}
                      onChange={(ev) => {
                          if (ev.value) {
                              onChange(ev.value.value);
                          }
                      }}
        />
    );
};

export default NettoBetCurrencySelector;
