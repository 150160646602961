import React, { useEffect, useState } from 'react';
import JobGroupHeaderTemplate from "../JobTemplate/JobGroupHeaderTemplate";
import { useTranslation } from 'react-i18next';

const GroupBaseTemplate = (props: any) => {

    const {t} = useTranslation();

    const [uniqueItems, setUniqueItems] = useState<any[]>([]);

    useEffect(() => {
        // Функция для удаления дубликатов по ключу 'jobOrderRelease'
        const getUniqueItems = (items: any[]) => {
            const uniqueItemsMap: { [key: string]: any } = {};
            items.forEach(item => {
                uniqueItemsMap[item["jobOrderRelease"]] = item;
            });
            return Object.values(uniqueItemsMap);
        };
        //этот компонент используется и в шипментах и в релизах
        //нас интересует случай для релизов, поэтому такое условие
        if (props.dataItem.field !== 'job') {
            // Получаем уникальные элементы и обновляем состояние
            setUniqueItems(getUniqueItems(props.dataItem.items));
        }
    }, [props.dataItem.field, props.dataItem.items]);
    
    if (props.dataItem.field === 'job') {
        return <JobGroupHeaderTemplate {...props} />;
    }
    return (
        props.tdProps && (
            <td {...props.tdProps}
                style={{
                    color: 'black',
                    fontWeight: 'black'
                }}>
                {props.children && (
                    <p className="k-reset">
                        {props.children.props.children[0]}
                        {props.dataItem[props.field]}
                        {uniqueItems.map((item: any, index: number) => (
                            <span key={index} style={{ marginLeft: '10px'}}>
                                <span style={{ color: '#E96024'}}>job: </span>
                                {item["jobOrderRelease"] ? item["jobOrderRelease"] : t("shared.labels.no-data")}
                            </span>
                        ))}
                    </p>
                )}
            </td>
        )
    );
};

export default GroupBaseTemplate;