import React from 'react';
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import cl from './ProfileHelpCard.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileArrowDown} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

type ProfileHelpCardProps = {
    title: string;
    icon: IconProp;
    onDownloadButtonClick: () => void;
};

const ProfileHelpCard: React.FC<ProfileHelpCardProps> = ({
                                                             icon,
                                                             title,
                                                             onDownloadButtonClick
                                                         }) => {
    const {t} = useTranslation();

    return (
        <div className={cl.help}>
            <div className={cl.iconContainer}>
                <FontAwesomeIcon icon={icon}/>
            </div>

            <div className={cl.textContainer}>
                {title}
            </div>

            <div className={cl.downloadIconContainer}>
                <FontAwesomeIcon icon={faFileArrowDown}
                                 onClick={onDownloadButtonClick}
                                 title={t("shared.btn.download")}/>
            </div>
        </div>
    );
};

export default ProfileHelpCard;