import {IShipment} from "../../../../../../../../../app/interfaces/shipment/IShipment";
import React from "react";
import {useTranslation} from "react-i18next";

type CustomerProps = {
    shipment: IShipment
}

const Customer: React.FC<CustomerProps> = ({shipment}) => {
    const {t} = useTranslation();

    return (
        <div className={'details-box-item-field-value'}>
            {shipment.client !== null && shipment.client !== undefined ? shipment.client : t("shared.labels.no-data")}
        </div>
    );
};

export default Customer;