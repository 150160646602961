import React, {useEffect, useState} from 'react';
import {DropDownList, DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {SelectModel} from "../../../../../../../../../../app/types/SelectModel";
import {useEquipmentApi} from "../../../../../../../../../../app/api/equipment";
import {ResponseResultCode} from "../../../../../../../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";

type NettoBetEquipmentGroupSelectorProps = {
    disabled: boolean;
    equipment: string | null;
    onChange: (eqGroup: any, eqAlias: string | null) => void;
}

const NettoBetEquipmentGroupSelector: React.FC<NettoBetEquipmentGroupSelectorProps> = ({
                                                                                           disabled,
                                                                                           equipment,
                                                                                           onChange
                                                                                       }) => {
    const [data, setData] = useState<Array<SelectModel>>([]);

    const {
        getEquipmentGroups: {
            query: getEquipmentGroups,
            isLoading,
            cancel
        },
        getEquipmentGroupAlias: {
            query: getEquipmentGroupAlias,
            isLoading: getEquipmentGroupAliasIsLoading,
            cancel: cancelGetEquipmentGroupAlias
        }
    } = useEquipmentApi();

    useEffect(() => {

        (async () => {
            try {
                const response = await getEquipmentGroups(false);

                if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok &&
                    response.data?.data) {
                    setData(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error(`Unable to get equipment groups: ${e.message}`);
                }
            }
        })();

        return () => {
            cancel();
            cancelGetEquipmentGroupAlias();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onEquipmentChange = (ev: DropDownListChangeEvent) => {
        (async () => {
            try {
                let group = ev.target.value.value;

                if (group === null || group === undefined || group === '') {
                    onChange(null, null);

                    return;
                }

                const response = await getEquipmentGroupAlias(ev.target.value.value);

                if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok &&
                    response.data?.data) {
                    onChange(group, response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error(`Unable to get equipment group alias: ${e.message}`);
                }
            }
        })();
    }

    return (
        <DropDownList data={data}
                      className={'edit-transport-equipment-value'}
                      disabled={disabled || isLoading || getEquipmentGroupAliasIsLoading}
                      loading={isLoading || getEquipmentGroupAliasIsLoading}
                      textField="text"
                      dataItemKey="value"
                      value={equipment !== null ? data.find(e => e.value === equipment) : null}
                      onChange={onEquipmentChange}
        />
    );
};

export default NettoBetEquipmentGroupSelector;
