import React from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import icon from '../../../../../../assets/images/rate_geo.gif';
import {useTranslation} from "react-i18next";

const DocumentLinkTemplate = (props: any) => {
    const {t} = useTranslation();
    const {handleClick} = useGridCellHandleClick(props.dataItem);

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
            {props.dataItem.documentHref !== null &&
                props.dataItem.documentHref !== undefined &&
                props.dataItem.documentHref !== '' &&
                <span className={'default-center-cell default-overflow-cell grid-prevent-selection'}>
                    <img style={{width: '19px', cursor: 'pointer'}}
                         src={icon}
                         alt={'docs-link'}
                         title={t("shared.labels.documents-observe")}
                         onClick={(ev) => {
                             ev.stopPropagation();

                             window.open(props.dataItem.documentHref, '_blank');
                         }}
                    />
                </span>
            }
        </td>
    );
};

export default DocumentLinkTemplate;