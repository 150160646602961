import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {isAxiosError} from "axios";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {IAssignOfferGetAction} from "../../../../../app/interfaces/offer/IAssignOfferGetAction";
import {useOfferApi} from "../../../../../app/api/offer";
import {IAssignOfferPostAction} from "../../../../../app/interfaces/offer/IAssignOfferPostAction";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import AssignOfferForm from "./AssignOfferForm/AssignOfferForm";

type AssignOfferProps = {
    id: string | Array<string>;
    close?: () => void;
};

const AssignOffer: React.FC<AssignOfferProps> = ({
                                                     id,
                                                     close
                                                 }) => {
    const {t} = useTranslation();
    const [model, setModel] = useState<IAssignOfferGetAction | null>(null);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        getAssignData: {
            isLoading,
            cancel,
            query: getAssignData
        },
        assignOffer: {
            cancel: cancelAssignOffer,
            mutation: assignOffer
        }
    } = useOfferApi();

    useEffect(() => {
        if (!id || id === '' || (Array.isArray(id) && id.length <= 0)) {
            if (close) {
                close();
            }

            return;
        }

        (async () => {
            try {
                const response = await getAssignData(id);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error<string>('Unable to get assign offer data');

                    if (close) {
                        close();
                    }
                }
            }
        })();

        return () => {
            cancel();
            cancelAssignOffer();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const save = async (model: IAssignOfferPostAction): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            const response = await assignOffer(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [...id].map((item) => {
                            return {
                                id: item,
                                isRowLoading: true
                            };
                        })
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.offer")} ${model.orderReleaseGid !== '' ? model.orderReleaseGid.split(';')[0] : ''}`}/>);
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(e.message);
            }
        }

        setDisabled(prev => !prev);
    }

    if ((Array.isArray(id) && id.length <= 0) || id === '') {
        return null;
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }


    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                <AssignOfferForm model={model}
                                 save={save}
                                 disabled={disabled}
                                 setIsSubmitDisabled={setIsSubmitDisabled}/>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={isSubmitDisabled || disabled}
                        form='assign-offer-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.assign")}
                </Button>
            </div>
        </div>
    );
};

export default AssignOffer;