import React from 'react';
import {useTranslation} from "react-i18next";
import {IShipment} from "../../../../../../../app/interfaces/shipment/IShipment";
import cl from "../../../../grid/templates/TransportOrderTemplate/TransportOrderTemplate.module.css";
import downloadIconBlue from "../../../../../../../assets/images/download-blue.png";
import downloadIconOrange from "../../../../../../../assets/images/download-orange.png";

type TransportOrderProps = {
    shipment: IShipment;
}
let GET_TRANSPORT_ORDER_LINK = `${process.env.REACT_APP_API_DOMAIN}/api/${process.env.REACT_APP_API_VERSION}/document/getTransportOrderDocument`;
const TransportOrder: React.FC<TransportOrderProps> = ({
                                         shipment
                                     }) => {
    const {t} = useTranslation();

    return (
        <div>
            <span className={`default-center-cell ${cl.container} grid-prevent-selection`}>
                {shipment.isTransportOrder && shipment.isAlreadyDownloaded &&
                    <>
                        <img src={downloadIconBlue} onClick={(ev) => {

                            window.open(GET_TRANSPORT_ORDER_LINK + '?id=' + shipment.shipmentGid, '_blank');
                        }}
                             alt={t("shipment.grid.click-to-download")}
                             title={t("shipment.grid.click-to-download")}/>
                        <span onClick={(ev) => {

                            window.open(GET_TRANSPORT_ORDER_LINK + '?id=' + shipment.shipmentGid, '_blank');
                        }}>{t("shared.labels.download")}</span>
                    </>
                }
                {shipment.isTransportOrder && !shipment.isAlreadyDownloaded &&
                    <>
                        <img src={downloadIconOrange} onClick={(ev) => {

                            window.open(GET_TRANSPORT_ORDER_LINK + '?id=' + shipment.shipmentGid, '_blank');
                        }}
                             alt={t("shipment.grid.click-to-download")}
                             title={t("shipment.grid.click-to-download")}/>
                        <span onClick={(ev) => {
                            window.open(GET_TRANSPORT_ORDER_LINK + '?id=' + shipment.shipmentGid, '_blank');

                        }}>{t("shared.labels.download")} </span>
                    </>
                }
            </span>
        </div>
    );
};

export default TransportOrder;
