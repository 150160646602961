import React, {useEffect, useState} from 'react';
import {IAssociateTagGetAction} from "../../../../../../../app/interfaces/tag/IAssociateTagGetAction";
import {ITag} from "../../../../../../../app/interfaces/tag/ITag";
import TagForm from "../../../components/TagForm/TagForm";
import {ISaveTagPostAction} from "../../../../../../../app/interfaces/tag/ISaveTagPostAction";
import {ResponseResultCode} from "../../../../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import {SelectModel} from "../../../../../../../app/types/SelectModel";
import {useTagApi} from "../../../../../../../app/api/tag";
import {toast} from "react-toastify";
import {TFunction} from "i18next";
import {groupBy, GroupResult} from "@progress/kendo-data-query";
import {useTranslation} from "react-i18next";
import Tag, {TagItemBackgroundColor} from "../../../../../../ui/Tag/Tag";
import ListBox from "../../../../../../ui/ListBox/ListBox";

type AssociateTagContentProps = {
    model: IAssociateTagGetAction;
    disabled: boolean;
    onTagSelect: (items: Array<ITag>) => void;
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    selectedTags: Array<ITag>;
};

const groupData = (t: TFunction<"translation", undefined>, data: Array<ITag>): Array<SelectModel | string> => {
    let result: Array<SelectModel | string> = [];

    let groups: Array<GroupResult> = groupBy(data, [{field: 'isPublic'}]) as Array<GroupResult>;

    groups.sort((a, b) => {
        return Number(a.value) - Number(b.value);
    }).forEach(gr => {
        result.push(gr.value === true
            ? t("tags.labels.department-tags")
            : t("tags.labels.personal-tags"));

        gr.items.forEach(tag => {
            let typedTag = tag as ITag;

            result.push({
                text: typedTag.name,
                value: typedTag.id.toString()
            });
        })
    });

    return result;
};

const AssociateTagContent: React.FC<AssociateTagContentProps> = ({
                                                                     setDisabled,
                                                                     disabled,
                                                                     selectedTags,
                                                                     onTagSelect
                                                                 }) => {
    const {t} = useTranslation();

    const [data, setData] = useState<Array<ITag>>([]);
    const [filteredData, setFilteredData] = useState<Array<SelectModel | string>>([]);

    const {
        getAvailableTags: {
            query,
            isLoading,
            cancel: cancelGetAvailableTags
        },
        saveTag: {
            mutation: saveTagAction,
            cancel: cancelSaveTag
        }
    } = useTagApi();

    useEffect(() => {
        (async () => {
            await loadCurrentUserTags();
        })();

        return () => {
            cancelGetAvailableTags();
            cancelSaveTag();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFilter = (value: string | null) => {
        if (!value || value === '') {
            setFilteredData(groupData(t, data));
        } else {
            setFilteredData(groupData(t, data
                .filter(item => {
                    return item.name.toLowerCase().includes(value.toLowerCase())
                })));
        }
    }

    const loadCurrentUserTags = async () => {
        try {
            const response = await query();

            if (response?.data?.resultCode === ResponseResultCode.Ok && response?.data.data) {
                setData(response.data.data);
                setFilteredData(groupData(t, response.data.data));
                return response.data.data;
            }
            // @ts-ignore

        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(`'Unable to load tags list: ${e.message}`);
            }
        }
        return  null;
    }

    const saveTag = async (tag: ISaveTagPostAction) => {
        setDisabled(prev => !prev);

        try {
            const response = await saveTagAction({
                ...tag,
                color: tag.color.indexOf('#') > -1
                    ? tag.color.substring(1, 7)
                    : tag.color
            });

            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
              let _data =  await loadCurrentUserTags();

                if(_data) {
                    let item = _data.find(e => e.id === response.data.data?.id);
                    if (item) {

                        let props = selectedTags
                        props.push(item)
                        onTagSelect(props);
                    }
                }

            }
        } catch (err) {
            if (isAxiosError(err)) {
                toast.error(`Unable to save tag, error: ${err.message}`);
            }
        }

        setDisabled(prev => !prev);
    }

    return (
        <div className={'h100 w100 pd-10'}>
            <div className={'w100 pd-10'}>
                {selectedTags.map(item => <div style={{padding: '5px',     display: 'inline'}} ><Tag item={{
                    id: item.id,
                    text: item.name,
                    backgroundColor: TagItemBackgroundColor.Navy,
                    backgroundColorStr: `#${item.color}`
                }} containerMaxWidth={300}/></div>)}
            </div>
            <div className={'flex-two-columns flex-gap-10 pd-10'}
                 style={{}}>
                <div>
                    <ListBox id={'edit-tags-tags-list'}
                             selected={selectedTags.map(item => ({
                                 text: item.name,
                                 value: item.id.toString()
                             }))}
                             disabled={disabled}
                             data={filteredData}
                             isLoading={isLoading}
                             onFilter={onFilter}
                             height={250}
                             selection={'Multiple'}
                             template={props => {
                                 let item = data.find(tag => tag.id === Number(props.value));

                                 if (item) {
                                     return (
                                         <Tag item={{
                                             id: props.value,
                                             text: props.text,
                                             backgroundColor: TagItemBackgroundColor.Navy,
                                             backgroundColorStr: `#${item.color}`
                                         }} containerMaxWidth={300}/>
                                     );
                                 }

                                 return (
                                     <>
                                         {props.text}
                                     </>
                                 )
                             }}
                             onSelectionChange={(items) => {
                                 if (items.length > 0) {
                                     let props: Array<ITag> = [];

                                     items.forEach(t => {
                                         let item = data.find(e => e.id === Number(t.value));
                                         if (item) {
                                             props.push(item);
                                         }
                                     });

                                     onTagSelect(props);
                                 } else {
                                     onTagSelect([]);
                                 }
                             }}/>
                </div>
                <div>
                    <TagForm tag={null}
                             disabled={false}
                             onTagSave={saveTag}
                             onTagUpdate={saveTag}/>
                </div>
            </div>
        </div>
    );
};

export default AssociateTagContent;
