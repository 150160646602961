import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import useDocumentTitle from "../../../hooks/useDocumentTitle/useDocumentTitle";
import bg_image from "../../../assets/images/login-bg.jpg";
import LanguageSwitcher from "../../../components/ui/LanguageSwitcher/LanguageSwitcher";
import BackgroundImageContainer from '../../../components/ui/BackgroundImageContainer/BackgroundImageContainer';
import Container from "../../../components/ui/Container/Container";
import useDefaultSiteLanguages from "../../../hooks/useDefaultSiteLanguages/useDefaultSiteLanguages";
import useMediaQuery from "../../../hooks/useMediaQuery/useMediaQuery";
import cl from './Registration.module.css';
import {useLocation, useNavigate} from "react-router-dom";
import Card from "../../../components/ui/Card/Card";
import ExistingSupplierRegistration from "./ExistingSupplierRegistration/ExistingSupplierRegistration";
import NewSupplierRegistration from "./NewSupplierRegistration/NewSupplierRegistration";

const Registration: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useLocation();

    useDocumentTitle({title: t("login.registration.registration-label")});

    const languages = useDefaultSiteLanguages();

    const isMaxWidth470 = useMediaQuery('(max-width:450px)');
    const isMaxHeight490 = useMediaQuery('(max-height:490px)');

    const [isFromSelfBillingPage, setIsFromSelfBillingPage] = useState<boolean>(false);
    const [cooperationTerm, setCooperationTerm] = useState<string | null>(null);
    const [cardWidth, setCardWidth] = useState<string>(
        isMaxWidth470 ? 'calc(100% - 20px)' : '450px'
    );

    useEffect(() => {
        if (params && params.state && params.state.fromSelfBilling !== undefined && params.state.fromSelfBilling) {
            setIsFromSelfBillingPage(true);
        }
    }, [params]);

    useEffect(() => {
        let width: string;

        if (cooperationTerm !== null && cooperationTerm === '2') {
            width = isMaxWidth470 ? 'calc(100% - 20px)' : '1150px';

        } else {
            width = isMaxWidth470 ? 'calc(100% - 20px)' : '450px';
        }

        if (width !== cardWidth) {
            setCardWidth(width);
        }
    }, [isMaxWidth470, cooperationTerm]);

    return (
        <BackgroundImageContainer image={bg_image}>
            <Container centerContent={true} height={'calc(100% - 30px)'}>
                <Card style={{width: cardWidth}}
                      allAnimation={true}>
                    <div className={cl.switcher}>
                        <LanguageSwitcher languages={languages} languageIconPosition={'right'}/>
                    </div>
                    <div className={cl.logo}
                         style={isMaxHeight490 ? {display: 'none'} : undefined}>
                        <img className={'prevent-selection'}
                             src={'/images/logo_main.png'}
                             alt={'logo'}/>
                    </div>
                    <div className={`${cl.label} prevent-selection`}>
                        <>
                            {t("login.registration.new-user-registration-label")}

                            {cooperationTerm !== null && cooperationTerm === '1' &&
                                <div className={cl.cooperationTermLabel}>
                                    {t("login.registration.existing-supplier-cooperation-term")}
                                </div>
                            }

                            {cooperationTerm !== null && cooperationTerm === '2' &&
                                <div className={cl.cooperationTermLabel}>
                                    {t("login.registration.new-supplier-cooperation-term")}
                                </div>
                            }
                        </>
                    </div>

                    <div className={'pd-10'}>
                        {cooperationTerm === null &&
                            <>
                                <label className={'view-label required'}>
                                    {t("login.registration.cooperation-terms")}
                                </label>

                                <div className={cl.cooperationTermContainer}>
                                    <button className={'transition-all-500'}
                                            onClick={() => {
                                                setCooperationTerm('2');
                                            }}>
                                        {t("login.registration.new-supplier-cooperation-term")}
                                    </button>
                                    <button className={'transition-all-500'}
                                            onClick={() => {
                                                setCooperationTerm('1');
                                            }}>
                                        {t("login.registration.existing-supplier-cooperation-term")}
                                    </button>
                                </div>
                            </>
                        }

                        {cooperationTerm !== null && cooperationTerm === '1' &&
                            <ExistingSupplierRegistration/>
                        }

                        {cooperationTerm !== null && cooperationTerm === '2' &&
                            <NewSupplierRegistration/>
                        }

                        <hr className={cl.separator}/>

                        <div className={cl.linkBtns}>
                            {cooperationTerm !== null &&
                                <button onClick={() => {
                                    setCooperationTerm(null);
                                }}>
                                    {t("shared.btn.back")}
                                </button>
                            }

                            {isFromSelfBillingPage
                                ? <button onClick={() => {
                                    navigate(-1);
                                }}>
                                    {t("self-billing.btn.back-to-self-billing-start-page")}
                                </button>
                                : <button onClick={() => {
                                    navigate('/login');
                                }}>
                                    {t("login.btn.back-to-login-page")}
                                </button>
                            }
                        </div>
                    </div>
                </Card>
            </Container>
        </BackgroundImageContainer>
    );
};

export default Registration;
