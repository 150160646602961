import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import cl from "../../../../Shipment/grid/templates/ServiceProviderTemplate/ServiceProviderTemplate.module.css";
import viberIcon from "../../../../../../assets/images/viber.png";
import ttTrackingIcon from "../../../../../../assets/images/tt-tracking.png";
import shippeoTrackingIcon from "../../../../../../assets/images/shippeo-tracking.png";
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import UserChat from "../../../../../../components/shared/components/Chat/components/UserChat/UserChat";
import {UserRole} from "../../../../../../app/enums/UserRole";
import {getUserRole} from "../../../../../../helpers/user";
import SupplierData from "../../../../../../components/shared/form/detailsFields/SupplierData/SupplierData";
import SupplierDataDialog
    from "../../../../../../components/shared/form/detailsFields/SupplierData/SupplierDataDialog/SupplierDataDialog";

const ServiceProviderTemplate = (props: any) => {
    const user = useAppSelector(selectCurrentUser);

    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const {t} = useTranslation();
    const [isDataDialogShown, setIsDataDialogShown] = useState<boolean>(false);
    let releaseNumber = props.dataItem['orderReleaseXid'];

    if (!releaseNumber || releaseNumber === '' || !user
        || !props.dataItem['serviceProvider'] || props.dataItem['serviceProvider'] === ''
        || !props.dataItem['serviceProviderId'] || props.dataItem['serviceProviderId'] === '') {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                {props.children}
            </td>
        )
    }

    let hasMessengerDistribution = props.dataItem["HasMessengersSubscription"] !== null && props.dataItem["HasMessengersSubscription"] !== undefined
        ? Boolean(props.dataItem["HasMessengersSubscription"])
        : false;

    let hasTTTracking = props.dataItem["isTTTracking"] !== null && props.dataItem["isTTTracking"] !== undefined
        ? Boolean(props.dataItem["isTTTracking"])
        : false;

    let hasShippeoTracking = props.dataItem["isShippeoTracking"] !== null && props.dataItem["isShippeoTracking"] !== undefined
        ? Boolean(props.dataItem["isShippeoTracking"])
        : false;

    if (props.dataItem["hasRegisteredAccount"] === false) {
        return (
            <>
                <td {...props.tdProps}
                    colSpan={1}
                    className={`grid-prevent-selection`}>
                    {user.id &&
                        <SupplierDataDialog ids={props.dataItem['spId']}
                                            show={isDataDialogShown}
                                            setShow={setIsDataDialogShown}/>
                    }
                    <span onClick={handleClick} className={`default-flex-cell default-overflow-cell grid-prevent-selection`}
                          style={{cursor: "pointer"}}>
                        {hasMessengerDistribution &&
                            <img src={viberIcon}
                                 className={cl.imgIcon}
                                 alt={t('shared.labels.messengers-distribution-label')}
                                 title={t('shared.labels.messengers-distribution-label')}/>
                        }
                        {hasTTTracking &&
                            <img src={ttTrackingIcon}
                                 className={cl.imgIcon}
                                 alt={t('shipment.grid.tt-tracking')}
                                 title={t('shipment.grid.tt-tracking')}/>
                        }
                        {hasShippeoTracking &&
                            <img src={shippeoTrackingIcon}
                                 className={cl.imgIcon}
                                 alt={t('shipment.grid.shippeo-tracking')}
                                 title={t('shipment.grid.shippeo-tracking')}/>
                        }

                        <div className={`${cl.container} transition-all-500`}
                             style={{color: 'inherit', textAlign: 'left'}}
                             onClick={(ev) => {
                                 ev.stopPropagation();

                                 setIsDataDialogShown(prev => !prev);
                             }}>
                                        {props.dataItem['serviceProvider']}
                        </div>

                    </span>
                </td>
            </>
        );
    } else {
        let serviceProviderId = props.dataItem['serviceProviderId'];
        let isQuote = !props.dataItem['isQuote'] && props.dataItem['isQuote'] === true;

        let description = '';

        if (props.dataItem['sourceDistrict'] && props.dataItem['sourceCity']
            && props.dataItem['destinationDistrict'] && props.dataItem['destinationCity']) {
            if (props.dataItem['pickupDate'] && props.dataItem['pickupDate'] !== '') {
                description = `${props.dataItem['pickupDate']}, `;
            }

            description += `${props.dataItem['sourceDistrict']} ${props.dataItem['sourceCity']}`;

            description += ` - ${props.dataItem['destinationDistrict']} ${props.dataItem['destinationCity']}`
        }


        return (
            <td {...props.tdProps}
                colSpan={1}>
                {user.id &&
                    <SupplierDataDialog ids={props.dataItem['spId']}
                                        show={isDataDialogShown}
                                        setShow={setIsDataDialogShown}/>
                }
                <span className={`default-flex-cell default-overflow-cell grid-prevent-selection`}
                      style={{cursor: 'pointer'}}>
                    {getUserRole(user) !== UserRole.ServiceProvider &&
                        <div style={{marginRight: '5px', marginTop: '3px'}}>
                            <UserChat user={{
                                role: UserRole.ServiceProvider,
                                id: serviceProviderId,
                                serviceProvider: serviceProviderId
                            }} replyTo={{
                                type: isQuote ? 'QUOTE' : 'RELEASE',
                                id: releaseNumber,
                                idExt: null,
                                description: description === '' ? null : description
                            }}/>
                        </div>
                    }
                    <span className={`default-flex-cell default-overflow-cell grid-prevent-selection`} style={{paddingLeft: 0}} onClick={handleClick} >
                    {hasMessengerDistribution &&
                        <img src={viberIcon}
                             className={cl.imgIcon}
                             alt={t('shared.labels.messengers-distribution-label')}
                             title={t('shared.labels.messengers-distribution-label')}/>
                    }
                    {hasTTTracking &&
                        <img src={ttTrackingIcon}
                             className={cl.imgIcon}
                             alt={t('shipment.grid.tt-tracking')}
                             title={t('shipment.grid.tt-tracking')}/>
                    }
                    {hasShippeoTracking &&
                        <img src={shippeoTrackingIcon}
                             className={cl.imgIcon}
                             alt={t('shipment.grid.shippeo-tracking')}
                             title={t('shipment.grid.shippeo-tracking')}/>
                    }

                    <div className={`${cl.container} transition-all-500`}
                         style={{color: 'inherit', textAlign: 'left'}}
                         onClick={(ev) => {
                             ev.stopPropagation();

                             setIsDataDialogShown(prev => !prev);
                         }}>
                                        {props.dataItem['serviceProvider']}
                        </div>
                        </span>
                    </span>
            </td>
        );
    }
};

export default ServiceProviderTemplate;
