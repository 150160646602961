import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'magnifyingGlass',
    pathData: [
        'M9.23443 12.9599C5.81342 12.9599 3.04014 10.1866 3.04014 6.76557C3.04014 3.34457 5.81342 0.571289 9.23443 0.571289C12.6554 0.571289 15.4287 3.34457 15.4287 6.76557C15.4287 10.1866 12.6554 12.9599 9.23443 12.9599Z',
        'M0.571707 15.4284L4.85742 11.1427'
    ],
    viewBox: '0 0 16 16',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;