import React, {useState} from 'react';
import cl from './GridActionRowButton.module.css';
import Icon, {IconName} from "../../../../../../../../assets/icon/Icon";

interface IGridActionRowButtonBaseProps {
    id: string;
    title: string;
    disabled?: boolean;
    icon: IconName;
    postIconText?: string;
}

interface IGridActionRowDefaultButton extends IGridActionRowButtonBaseProps {
    onClick: () => void;
}

interface IGridActionRowToggleButtonProps extends IGridActionRowButtonBaseProps {
    toggle: boolean;
    defaultValue?: boolean;
    onClick: (checked: boolean) => void;

    isToggled?: boolean;
    setIsToggled?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const isGridActionRowToggleButtonProps = (item: IGridActionRowToggleButtonProps | IGridActionRowDefaultButton): item is IGridActionRowToggleButtonProps => {
    return (item as IGridActionRowToggleButtonProps).toggle !== undefined;
}

export type GridActionRowButtonProps = IGridActionRowDefaultButton | IGridActionRowToggleButtonProps;

const GridActionRowButton: React.FC<GridActionRowButtonProps> = (props) => {
    const [isToggle, setIsToggle] = useState<boolean>(
        isGridActionRowToggleButtonProps(props) && props.defaultValue !== undefined
            ? props.defaultValue
            : false
    );

    if (isGridActionRowToggleButtonProps(props) && props.toggle) {
        const {
            id,
            title,
            onClick,
            disabled,
            icon,
            isToggled,
            setIsToggled,
            postIconText
        } = props;

        return (
            <label title={title}
                   id={id}
                   className={`${cl.button} ${isToggled !== undefined ? (isToggled ? cl.toggle : '') : (isToggle ? cl.toggle : '')} ${disabled ? cl.disabled : ''} transition-all-500`}>
                <input type={'checkbox'} checked={isToggled !== undefined ? isToggled : isToggle}
                       onChange={() => {
                           if (disabled) {
                               return;
                           }

                           if (setIsToggled !== undefined) {
                               setIsToggled(prev => {
                                   onClick(!prev);

                                   return !prev;
                               });
                           } else {
                               setIsToggle(prev => {
                                   onClick(!prev);

                                   return !prev;
                               });
                           }
                       }}/>
                <span className={cl.iContainer}>
                    <Icon icon={icon} strokeWidth={1}/>
                </span>
                {postIconText !== undefined && postIconText !== '' &&
                    <span className={cl.postIconText}>{postIconText.slice(0, 3)}</span>
                }
            </label>
        );
    } else if (!isGridActionRowToggleButtonProps(props)) {
        const {id, title, onClick, disabled, icon} = props;

        return (
            <button id={id}
                    title={title}
                    onClick={onClick}
                    className={`${cl.button} transition-all-500`}
                    disabled={disabled}>
                <span className={cl.iContainer}>
                    <Icon icon={icon} strokeWidth={1}/>
                </span>
            </button>
        );
    }

    return null;
};

export default GridActionRowButton;