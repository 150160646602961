import React, {useEffect, useState} from 'react';
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {useTranslation} from "react-i18next";
import {useShipmentApi} from "../../../../../app/api/shipment";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {IAssignShipmentGetAction} from "../../../../../app/interfaces/shipment/IAssignShipmentGetAction";
import AssignShipmentForm from "./AssignShipmentForm/AssignShipmentForm";
import {IAssignShipmentPostAction} from "../../../../../app/interfaces/shipment/IAssignShipmentPostAction";
import {toast} from 'react-toastify';
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {isAxiosError} from "axios";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";

type AssignShipmentProps = {
    id: string | Array<string>;
    close?: () => void;
}

const AssignShipment: React.FC<AssignShipmentProps> = ({
                                                           id,
                                                           close
                                                       }) => {
    const {t} = useTranslation();
    const [model, setModel] = useState<IAssignShipmentGetAction | null>(null);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        getAssignShipmentData: {
            isLoading,
            cancel,
            query: getAssignShipmentData
        },
        assignShipment: {
            cancel: cancelAssignShipment,
            mutation: assignShipment
        }
    } = useShipmentApi();

    useEffect(() => {
        if (!id || id === '') {
            return;
        }

        (async () => {
            try {
                const response = await getAssignShipmentData(id);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch {
                //ignore
            }
        })();

        return () => {
            cancel();
            cancelAssignShipment();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const save = async (model: IAssignShipmentPostAction): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            const response = await assignShipment(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [...id].map((item) => {
                            return {
                                id: item,
                                isRowLoading: true
                            };
                        })
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.shipment")} ${model.orderReleaseGid !== '' ? model.orderReleaseGid.split(';')[0] : ''}`}/>);
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(e.message);
            }
        }

        setDisabled(prev => !prev);
    }

    if ((Array.isArray(id) && id.length <= 0) || id === '') {
        return null;
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                <AssignShipmentForm model={model}
                                    save={save}
                                    disabled={disabled}
                                    setIsSubmitDisabled={setIsSubmitDisabled}/>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={isSubmitDisabled || disabled}
                        form='assign-shipment-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.assign")}
                </Button>
            </div>
        </div>
    );
};

export default AssignShipment;