import React from 'react';
import {IOffer} from "../../../../../../../../app/interfaces/offer/IOffer";

type OfferTransporeonTransportNumberProps = {
    offer: IOffer;
};

const OfferTransporeonTransportNumber: React.FC<OfferTransporeonTransportNumberProps> = ({
                                                                                             offer
                                                                                         }) => {
    if (offer.transporeonTransportNumber === null || offer.transporeonTransportNumber === '') {
        return (
            <div className={'details-box-item-field-value'}>

            </div>
        );
    }


    return (
        <div className={'details-box-item-field-value'}>
            {offer.transporeonTransportNumber}
        </div>
    );
};

export default OfferTransporeonTransportNumber;
