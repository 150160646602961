import React, {useRef} from 'react';
import cl from './GridFilterPanelLayoutInput.module.css';
import {useTranslation} from "react-i18next";
import Icon from "../../../../../assets/icon/Icon";

type GridFilterPanelLayoutInputProps = {
    disabled: boolean;
    showClearIcon?: boolean;
    onClear?: () => void;
} & React.HTMLProps<HTMLInputElement>;

const GridFilterPanelLayoutInput: React.FC<GridFilterPanelLayoutInputProps> = ({
                                                                                   disabled,
                                                                                   value,
                                                                                   showClearIcon,
                                                                                   onClear,
                                                                                   placeholder,
                                                                                   ...rest
                                                                               }) => {
    const {t} = useTranslation();
    const ref = useRef<HTMLInputElement | null>(null);

    return (
        <div className={`w100 relative ${cl.container}`}>
            <input className={`${cl.input}`}
                   ref={ref}
                   disabled={disabled}
                   value={value}
                   placeholder={undefined}
                   {...rest} />

            {placeholder &&
                <label className={`${cl.label} ${value ? cl.filled : ''}`}>
                    {placeholder}
                </label>
            }

            {showClearIcon && value && <Icon icon={"faXMark"}
                                             title={t("shared.btn.reset")}
                                             onClick={() => {
                                                 if (disabled) {
                                                     return;
                                                 }

                                                 if (onClear) {
                                                     onClear();
                                                 }

                                                 ref.current?.focus();
                                             }}
                                             className={cl.clearIcon}/>}
        </div>
    );
};

export default GridFilterPanelLayoutInput;