import React, {useEffect, useState} from 'react';
import {IPosition} from "../../../../../../../app/interfaces/shared/IPosition";
import Loader, {LoaderType} from "../../../../../../../components/ui/loaders/Loader/Loader";
import cl from './LocationMapRouteMarkerInfoWindow.module.css';
import {useShipmentApi} from "../../../../../../../app/api/shipment";
import {toast} from "react-toastify";

type LocationMapRouteMarkerInfoWindowProps = {
    position?: IPosition;
}
const LocationMapRouteMarkerInfoWindow: React.FC<LocationMapRouteMarkerInfoWindowProps> = ({
                                                                                               position
                                                                                           }) => {
    const [localDate, setLocalDate] = useState<string | null>(null);

    const {
        getRoutePointLocalTime: {
            query,
            isLoading
        }
    } = useShipmentApi();

    useEffect(() => {
        (async () => {
            if (!position) {
                return;
            }

            try {
                const response = await query(position.lt?.toString() ?? '', position.lg?.toString() ?? '', position.dateStr);
                if (response && response.status === 200 && response.data && response.data.data) {
                    setLocalDate(response.data.data);
                }
            } catch {
                toast.error('Unable to get point local date string');
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position]);

    if (isLoading || !position) {
        return (
            <div className={`action-loader-container ${cl.loaderContainer}`}>
                <div>
                    <Loader type={LoaderType.Action} style={{scale: '0.4'}}/>
                </div>
            </div>
        );
    }

    return (
        <div className={cl.container}>
            <div>
                <b>
                    {`lt: ${position.lt}`}
                </b>
            </div>
            <div>
                <b>
                    {`lg: ${position.lg}`}
                </b>
            </div>
            {localDate &&
                <div>
                    <b>
                        {localDate}
                    </b>
                </div>
            }
            <div>
                <b>
                    {position.truck}
                </b>
            </div>
        </div>
    );
};

export default LocationMapRouteMarkerInfoWindow;