import React from 'react';
import Loader, {LoaderType} from "../Loader/Loader";

const ActionLoader = () => {
    return (
        <div className={'action-loader-container'}>
            <div>
                <Loader type={LoaderType.Action} style={{scale: '0.4'}}/>
            </div>
        </div>
    );
};

export default ActionLoader;