import React from 'react';
import {IShipment} from "../../../../../../app/interfaces/shipment/IShipment";
import ShipmentDetailsBox from "../../components/fields/ShipmentDetailsBox/ShipmentDetailsBox";
import OrderDetails from "../../details/OrderDetails/OrderDetails";
import CargoDetails from "../../../../../../components/shared/form/CargoDetails/CargoDetails";
import RemarksDetails from "../../../../../../components/shared/form/RemarksDetails/RemarksDetails";
import {useTranslation} from "react-i18next";
import RouteSection from "../../../../../../components/shared/sections/RouteSection/RouteSection";
import FormMap from "../../../../../../components/shared/form/FormMap/FormMap";
import Event from '../../components/fields/Event/Event';
import FormCollapse from "../../../../../../components/ui/Collapse/FormCollapse";
import DetailsBoxColumn from "../../../../../../components/ui/DetailsBox/components/DetailsBoxColumn/DetailsBoxColumn";
import LatestEvent from "../../../../../../components/shared/form/LatestEvent/LatestEvent";
import useMediaQuery from "../../../../../../hooks/useMediaQuery/useMediaQuery";
import RouteSideSection from "../../../../../../components/shared/sections/RouteSection/RouteSideSection";

type DetailsSectionProps = {
    shipment: IShipment;
    isSidePanelDetails: boolean;
    id: string;
    sId: string;
}

const DetailsSection: React.FC<DetailsSectionProps> = ({
                                                           shipment,
                                                           isSidePanelDetails,
                                                           id,
                                                           sId
                                                       }) => {
    const {t} = useTranslation();
    const isMaxWidth600 = useMediaQuery('(max-width: 600px)');
    return (
        <div id={'shipment-details-section'}>
            {/*<ShipmentDetailsBox shipment={shipment} isSidePanelDetails={isSidePanelDetails}/>*/}

            <div className={'form-layout-container'}
                 data-count={'3'}
                 data-separator={'true'}>
                <div className={'form-layout-column'}>

                        <ShipmentDetailsBox shipment={shipment} isSidePanelDetails={isSidePanelDetails}/>

                    <FormCollapse title={t("shipment.form.transport-section")}>
                        <OrderDetails shipment={shipment}/>
                    </FormCollapse>

                    <FormCollapse title={t("shipment.form.cargo-section")}>
                        <CargoDetails model={shipment}/>
                    </FormCollapse>

                    {shipment.remarks !== null && shipment.remarks.length > 0 &&
                        <FormCollapse title={t("shipment.form.remarks-section")}>
                            <RemarksDetails isEditingAllowed={false}
                                            remarks={shipment.remarks}/>
                        </FormCollapse>
                    }
                </div>

                <div className={'form-layout-column-separator'}></div>

                <div className={'form-layout-column'}>
                    {isSidePanelDetails ?
                    <FormCollapse
                        title={shipment.legGid !== null && shipment.legGid !== '' && shipment.tl !== null && shipment.tl !== ''
                            ? `${t("shipment.form.customer-full-route")} ${shipment.tl}`
                            : `${t("shipment.form.route-section")}`
                        }>
                        <div className={'details-box-default-container'}>
                            <RouteSideSection showEdit={true}
                                          model={{
                                              orderType: shipment.orderType,
                                              isFinished: shipment.isCompleted,
                                              hasShipment: shipment.hasShipment,
                                              earlyPickupDateText: shipment.earlyPickupDateText,
                                              earlyPickupDate: shipment.earlyPickupDate ?? null,
                                              earlyDeliveryDate: shipment.earlyDeliveryDate ?? null,
                                              lateDeliveryDateText: shipment.lateDeliveryDateText,
                                              lateDeliveryDate: shipment.lateDeliveryDate,
                                              latePickupDate: shipment.latePickupDate ?? null,
                                              shipmentGid: shipment.shipmentGid,
                                              orderReleaseGid: shipment.orderReleaseGid,
                                              stopPoints: shipment.stopPoints ?? [],
                                              sourceCountry: shipment.sourceCountry,
                                              sourceCity: shipment.sourceCity,
                                              sourceCountryCode2: shipment.sourceCountryCode2,
                                              sourceProvinceCode: shipment.sourceProvinceCode,
                                              sourceProvinceDesc: shipment.sourceProvinceDesc,
                                              destinationCountry: shipment.destinationCountry,
                                              destinationCity: shipment.destinationCity,
                                              destinationCountryCode2: shipment.destinationCountryCode2,
                                              destinationProvinceCode: shipment.destinationProvinceCode,
                                              destinationProvinceDesc: shipment.destinationProvinceDesc
                                          }}/>
                        </div>
                    </FormCollapse> : <div style={{paddingTop:'10px'}} className={'details-box-default-container'}>
                            <RouteSection showEdit={true}
                                          model={{
                                              orderType: shipment.orderType,
                                              isFinished: shipment.isCompleted,
                                              hasShipment: shipment.hasShipment,
                                              earlyPickupDateText: shipment.earlyPickupDateText,
                                              earlyPickupDate: shipment.earlyPickupDate ?? null,
                                              earlyDeliveryDate: shipment.earlyDeliveryDate ?? null,
                                              lateDeliveryDateText: shipment.lateDeliveryDateText,
                                              lateDeliveryDate: shipment.lateDeliveryDate,
                                              latePickupDate: shipment.latePickupDate ?? null,
                                              shipmentGid: shipment.shipmentGid,
                                              orderReleaseGid: shipment.orderReleaseGid,
                                              stopPoints: shipment.stopPoints ?? [],
                                              sourceCountry: shipment.sourceCountry,
                                              sourceCity: shipment.sourceCity,
                                              sourceCountryCode2: shipment.sourceCountryCode2,
                                              sourceProvinceCode: shipment.sourceProvinceCode,
                                              sourceProvinceDesc: shipment.sourceProvinceDesc,
                                              destinationCountry: shipment.destinationCountry,
                                              destinationCity: shipment.destinationCity,
                                              destinationCountryCode2: shipment.destinationCountryCode2,
                                              destinationProvinceCode: shipment.destinationProvinceCode,
                                              destinationProvinceDesc: shipment.destinationProvinceDesc
                                          }}/>
                        </div>}

                    {shipment.hasShipment &&

                            <FormMap model={shipment}/>


                    }
                </div>
                <div className={'form-layout-column-separator'}></div>
                <div className={'form-layout-column'}>


                        <DetailsBoxColumn center >
                        <div className={'details-box-default-container'}>
                            {!shipment.latestEvent && (!shipment.currentPosition || !shipment.currentPosition.addressChangedOnLocal) ? <></> :
                        <LatestEvent currentPosition={shipment.currentPosition}
                                     latestEvent={shipment.latestEvent}
                                     stopPoints={shipment.stopPoints ?? []}/>}
                            {shipment.hasShipment &&
                                <Event id={id} sId={sId}/>

                            }
                        </div>

                    </DetailsBoxColumn>

                </div>
            </div>
        </div>
    );
};

export default DetailsSection;
