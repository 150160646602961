import {RefObject, useEffect, useRef} from 'react';

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    handler: (ev: MouseEvent) => void,
    mouseEvent: 'mousedown' | 'mouseup' = 'mousedown'
): void => {
    const savedHandler = useRef(handler);

    useEffect(() => {
        const targetElement: T | Window = ref?.current ?? window

        if (!(targetElement && targetElement.addEventListener)) {
            return;
        }

        const onEvent = (event: any) => {
            const el = ref?.current

            // Do nothing if clicking ref's element or descendent elements
            if (!el || el.contains(event.target as Node)) {
                return
            }

            savedHandler.current(event);
        }

        const listener: typeof handler = event => onEvent(event);

        targetElement.addEventListener(mouseEvent, listener as (e: Event) => void);

        return () => {
            targetElement.removeEventListener(mouseEvent, listener as (e: Event) => void);
        }
    }, [mouseEvent, ref])
};

export default useOnClickOutside;

export const useClickOutside = <T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    handler: (ev: MouseEvent) => void
): void => {
    const savedHandler = useRef(handler);

    useEffect(() => {
        const onEvent = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                savedHandler.current(event);
            }
        }

        const listener: typeof handler = event => onEvent(event);

        document.addEventListener('mousedown', listener as (e: Event) => void);

        return () => {
            document.removeEventListener('mousedown', listener as (e: Event) => void);
        }
    }, [ref])
};
