import {AxiosResponse} from "axios";
import {IResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {IReactRouterError} from "../../interfaces/log/IReactRouterError";

export const useLogReactRouterError = (): {
    isLoading: boolean,
    cancel: () => void,
    logReactRouterError: (params: IReactRouterError) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IReactRouterError>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/log/logReactRouterError`
    });

    const logReactRouterError = useCallback(async (params: IReactRouterError) => await execute(params), [execute]);

    return {logReactRouterError, isLoading, cancel};
};