import React from 'react';
import {IQuote} from "../../../../../../../../app/interfaces/quote/IQuote";
import ForwarderField from "../../../../../../../../components/shared/form/detailsFields/ForwarderField/ForwarderField";

type QuoteForwarderProps = {
    quote: IQuote;
}

const QuoteForwarder: React.FC<QuoteForwarderProps> = ({
                                                           quote
                                                       }) => {
    return (
        <div className={'details-box-item-field-value'}>
            {quote.forwarder !== null && quote.forwarder !== undefined && quote.forwarder.name !== null && quote.forwarder.name !== ''
                ? <ForwarderField user={quote.forwarder}
                                  chatReplyTo={{
                                      type: 'QUOTE',
                                      description: quote.chatDescription,
                                      id: quote.orderReleaseGid.replace('AAG.', ''),
                                      idExt: null
                                  }}/>
                : null
            }
        </div>
    );
};

export default QuoteForwarder;
