import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {matchPath, NavLink, useLocation} from 'react-router-dom';
import cl from './SidebarNavigationButton.module.css';
import {SidebarContext} from "../../Sidebar";
import {isCurrentNestedLinkActive} from "../../utils";
import Icon, {IconName} from "../../../../../assets/icon/Icon";

type SidebarNavigationButtonProps = {
    id: string;
    expandedId?: string;
    type: 'primary' | 'secondary';
    text: string;
    title: string;
    path: string;
    icon?: IconName;
    onClick?: (id: string) => void;
    disabled?: boolean;
    children?: ReactNode;
    activeWhenAdditionalPath?: Array<string>;
};

const SidebarNavigationButton =
    React.forwardRef<HTMLAnchorElement, SidebarNavigationButtonProps>(({
                                                                           id,
                                                                           expandedId,
                                                                           type,
                                                                           text,
                                                                           title,
                                                                           path,
                                                                           icon,
                                                                           onClick: onClickProp,
                                                                           disabled,
                                                                           children,
                                                                           activeWhenAdditionalPath
                                                                       }, ref) => {
            const [isOpened, setIsOpened] = useState<boolean>(false);
            const location = useLocation();

            const {
                hoverExpanded,
                expanded
            } = useContext(SidebarContext);

            const handleNavigationClick = (ev: React.MouseEvent<HTMLAnchorElement>) => {
                if (children) {
                    ev.preventDefault();

                    setIsOpened(prev => !prev);
                }

                if (onClickProp) {
                    onClickProp(id);
                }
            };

            useEffect(() => {
                if (expandedId != id)
                    setIsOpened(false);

            }, [expandedId]);


            if (!children) {
                let isNestedItemActive = isCurrentNestedLinkActive(location.pathname, activeWhenAdditionalPath);

                return (
                    <NavLink id={id}
                             to={path}
                             ref={ref}
                             data-type={type}
                             title={title}
                             className={({isActive}) => `relative ${cl.button} ${disabled ? cl.disabled : ''} ${isActive || isNestedItemActive ? cl.active : ''}`}
                             onClick={handleNavigationClick}>
                        <div className={`${cl.icon}`}>
                            {icon ? <Icon icon={icon}/> : <span style={{width: 20}}></span>}
                        </div>

                        <div className={`prevent-selection ${cl.text}`}
                             data-expanded={hoverExpanded || expanded ? 'expanded' : 'condensed'}>
                            {text}
                        </div>

                        {(expanded || hoverExpanded) && children
                            ? <Icon icon={"faThinChevronDown"}
                                    className={cl.chevron}
                                    flip={isOpened ? 'vertical' : 'horizontal'}/>
                            : null
                        }
                    </NavLink>
                );
            } else {
                let isNestedItemActive = isCurrentNestedLinkActive(location.pathname, activeWhenAdditionalPath);
                let isMatch = matchPath(`${path}/*`, location.pathname);

                return (
                    <div id={`navigation-nested-container-${id}`}>
                        <NavLink id={id}
                                 to={path}
                                 ref={ref}
                                 data-type={type}
                                 title={title}
                                 className={({isActive}) => `relative ${cl.button} ${disabled ? cl.disabled : ''} ${isActive || isNestedItemActive ? cl.active : ''}`}
                                 onClick={handleNavigationClick}>
                            <div className={`${cl.icon}`}>
                                {icon && <Icon icon={icon}/>}
                            </div>

                            <div className={`prevent-selection ${cl.text}`}
                                 data-expanded={hoverExpanded || expanded ? 'expanded' : 'condensed'}>
                                {text}
                            </div>

                            {(expanded || hoverExpanded) && children
                                ? <Icon icon={"faThinChevronDown"}
                                        className={cl.chevron}
                                        flip={isOpened ? 'vertical' : 'horizontal'}/>
                                : null
                            }
                        </NavLink>

                        {isOpened && (hoverExpanded || expanded)
                            ? <div
                                className={`${cl.nested} ${(isMatch && isMatch.params || isNestedItemActive) ? cl.active : ''}`}>
                                {children}
                            </div>
                            : null
                        }
                    </div>
                );
            }

        }
    );

export default SidebarNavigationButton;
