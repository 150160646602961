import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'xMark',
    rect: [
        {
            x: "10",
            y: "8.88867",
            width: "1.57133",
            height: "12.5707",
            rx: "0.785667",
            transform: "rotate(135 10 8.88867)"
        },
        {
            x: "8.88867",
            y: "-0.00012207",
            width: "1.57133",
            height: "12.5707",
            rx: "0.785667",
            transform: "rotate(45 8.88867 -0.00012207)"
        },
    ],
    viewBox: '0 0 10 10'
};

export default icon;