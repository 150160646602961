import React, {useState} from 'react';
import cl from './CustomerAttachment.module.css';
import {useTranslation} from "react-i18next";
import Icon from "../../../../../../../../assets/icon/Icon";
import AddCustomerAttachmentDialog from "../../../../../actions/AddCustomerAttachment/AddCustomerAttachmentDialog"
import { ICustomerAttachmentDoc } from '../../../../../../../../app/interfaces/customerAttachment/ICustomerAttachmentDoc';
import useCustomerAttachmentApi from '../../../../../../../../app/api/customerAttachment';
import { useDownloadFile } from '../../../../../../../../hooks/useDownloadFile/useDownloadFile';
import { IResponse, isResponse } from '../../../../../../../../app/interfaces/response/IResponse';
import { isAxiosError } from 'axios';
import { ResponseResultCode } from '../../../../../../../../app/enums/ResponseResultCode';
import { toast } from 'react-toastify';
import { IDeleteCustomerAttachmentPostAction } from '../../../../../../../../app/interfaces/customerAttachment/IDeleteCustomerAttachmentPostAction';
import DeleteCustomerAttachmentDialog from '../../../../../actions/DeleteCustomerAttachment/DeleteCustomerAttachmentDialog';

type CustomerAttachmentProps = {
    orderReleaseGid: string;
    customerAttachmentDocs: Array<ICustomerAttachmentDoc> | null;
};


const CustomerAttachment: React.FC<CustomerAttachmentProps> = ({
                                                   orderReleaseGid,
                                                   customerAttachmentDocs
                                               }) => {
    const {t} = useTranslation();
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);
    const [isDeleteConfirmationDialogShown, setIsDeleteConfirmationDialogShown] = useState<boolean>(false);
    const [customerAttachmentDocToDelete, setCustomerAttachmentDocToDelete] = useState<IDeleteCustomerAttachmentPostAction | null>(null);
    const [attachmentDocs, setAttachmentDocs] = useState<Array<ICustomerAttachmentDoc> | null>(customerAttachmentDocs);

    const {
        downloadCustomerAttachment: {
            query: downloadCustomerAttachment
        }
    } = useCustomerAttachmentApi();

    
    const {
        download
    } = useDownloadFile({
        target: '_blank',
        getBlob: async (param?: string, param2?: string) => {
            if (!param || !param2) {
                return;
            }
    
            try {
                const response = await downloadCustomerAttachment(param2);
    
                if (response?.status === 200 && !isResponse(response.data)) {
                    return response.data;
                }
            } catch (err) {
                if (isAxiosError(err) && err.response && err.response.data && err.response.data.type === "application/json") {
                    const textResponse = await err.response?.data?.text();
    
                    if (textResponse && typeof textResponse === 'string') {
                        let response = JSON.parse(textResponse) as IResponse;
    
                        if (response.resultCode === ResponseResultCode.NotValidData) {
                            toast.error(`Unable to download file. Error: Input parameter is invalid.`)
                        }
    
                        if (response.resultCode === ResponseResultCode.NotFound) {
                            toast.error(`Unable to download file. Error: File with this name is not found.`)
                        }
                    }
                }
            }
        }
    });

    return (
        <>
            <AddCustomerAttachmentDialog orderReleaseGid={orderReleaseGid}
                              show={isDialogShown}
                              setShow={setIsDialogShown}
                              />

            {/* <ConfirmationDialog id={'delete-attachment-confirmation-dialog'}
                                show={isDeleteConfirmationDialogShown}
                                setShow={setIsDeleteConfirmationDialogShown}
                                type={'Alert'}
                                title={t("customer-attachment.labels.deleting-doc-confirmation-title")}
                                text={t("customer-attachment.labels.deleting-doc-confirmation-text")}
                                onCancel={() => setIsDeleteConfirmationDialogShown(prev => !prev)}
                                onSubmit={async () => {
                                    if (!customerAttachmentDocToDelete) {
                                        return;
                                    }

                                    try {
                                        const response = await deleteCustomerAttachmentDoc(customerAttachmentDocToDelete);

                                        if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                                            if (attachmentDocs && attachmentDocs.length > 0) 
                                                {
                                                    // Успешно удалено, обновляем список вложений
                                                    // const updatedDocs = attachmentDocs
                                                    //     .filter(doc => doc.attachmentGid !== customerAttachmentDocToDelete.attachmentGid);
                                                    // setAttachmentDocs(updatedDocs);

                                                    let rows = customerAttachmentDocToDelete.orderReleaseGid.split(';').map(item => {
                                                        return {
                                                            id: item,
                                                            isRowLoading: true
                                                        };
                                                    });
                                    
                                                    document.dispatchEvent(new CustomEvent('onGridMessage', {
                                                        detail: {
                                                            action: 'updateRows',
                                                            rows: rows
                                                        }
                                                    }));
                                                }           
                                        }
                                    } catch (e) {
                                        if (isAxiosError(e)) {
                                            toast.error(`Unable to delete ${customerAttachmentDocToDelete} customerAttachmentDoc. Error: ${e.message}`);
                                        }
                                    }

                                    setIsDeleteConfirmationDialogShown(prev => !prev);

                                }}/> */}

            <DeleteCustomerAttachmentDialog docToDelete={customerAttachmentDocToDelete}
                                  show={isDeleteConfirmationDialogShown}
                                  setShow={setIsDeleteConfirmationDialogShown}/>

            
             <div>
                <div className={`${cl.attachmentDocs}`}>

                        <Icon icon={"faPen"}
                              className={'details-box-item-field-icon'}
                              onClick={() => setIsDialogShown(prev => !prev)}/>

                        <div className={'details-box-item-field-edit-value'}
                         onClick={() => setIsDialogShown(prev => !prev)}>
                            <>{t("customer-attachment.btn.add-customer-attachment")}</>
                    </div> 
                </div>

                {attachmentDocs && attachmentDocs.length > 0 && (
        <div className="customer-attachment-list">
            {attachmentDocs.map((doc: ICustomerAttachmentDoc) => (
                <div key={doc.id} className={`${cl.attachmentDocsEl}`}>
                    
                    <div title={`${doc.createdBy ?? ''}${doc.createdOn ? ', ' + doc.createdOn : ''}${doc.notes ? ', ' + doc.notes : ''}`} 
                        className={`${cl.attachmentDocsName}`}
                        onClick={async () => await download(doc?.fileName ?? '', doc?.attachmentGid ?? '')}>
                            {doc.fileName}
                    </div>
                    <div className={`${cl.attachmentDocsTrash}`}>
                                    <Icon icon={"faTrash"}
                                          className={cl.trashIcon}
                                          title={t("customer-attachment.labels.delete-doc-label")}
                                          onClick={(ev) => {
                                              ev.stopPropagation();

                                              setCustomerAttachmentDocToDelete({attachmentGid: doc?.attachmentGid ?? '', orderReleaseGid: doc?.orderReleaseGid ?? ''});
                                              setIsDeleteConfirmationDialogShown(prev => !prev);
                                              
                                          }}/>
                    </div>
                </div>

                

            ))}
        </div>
    )}
            </div> 
        </>
    );
};

export default CustomerAttachment;
