import React, {SetStateAction} from 'react';
import {IConfirmAuctionGetAction} from "../../../../../../../app/interfaces/offer/IConfirmAuctionGetAction";
import {IConfirmAuctionPostAction} from "../../../../../../../app/interfaces/offer/IConfirmAuctionPostAction";
import {useTranslation} from "react-i18next";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import SelectedOffers from "../../../../form/components/SelectedOffers/SelectedOffers";
import ConfirmAuctionServiceProviderSelector from "./ConfirmAuctionServiceProviderSelector";

type ConfirmAuctionFormServiceProviderProps = {
    model: IConfirmAuctionGetAction;
    disabled: boolean;
    setIsSubmitDisabled: React.Dispatch<SetStateAction<boolean>>;
    save: (model: IConfirmAuctionPostAction) => Promise<void>;
};

const ConfirmAuctionFormServiceProvider: React.FC<ConfirmAuctionFormServiceProviderProps> = ({
                                                                                                 model,
                                                                                                 disabled,
                                                                                                 setIsSubmitDisabled,
                                                                                                 save
                                                                                             }) => {
    const {t} = useTranslation();

    const form = useFormik<IConfirmAuctionPostAction>({
        initialValues: {
            orderReleaseGid: model.orderReleaseGid,
            serviceProviderId: model.serviceProviderId,
            serviceProvider: null
        },
        validationSchema: Yup.object().shape({
            serviceProviderId: Yup.string().nullable().required(t("shared.errors.required-error")
                .replace('{0}', t("offer.grid.service-provider")))
        }),
        onSubmit: async (values: IConfirmAuctionPostAction, actions: FormikHelpers<IConfirmAuctionPostAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='confirm-auction-form'
              onSubmit={form.handleSubmit}>
            {model.items.length > 1 && <SelectedOffers ids={model.items.map(item => item.id)}/>}
            <div className={'pd-10'}>
                <div style={{
                    fontSize: '17px',
                    color: '#4a4a4a',
                    textAlign: 'center'
                }} className={'mgb-10'}>
                    {t("offer.form.confirm-selected-transportation")}
                </div>

                {model.hasCustomerFromFilter &&
                    <div style={{
                        fontSize: '12px',
                        color: '#4a4a4a',
                        textAlign: 'center',
                        fontWeight: 'bold'
                    }} className={'mgb-10'}>
                        {t("offer.form.quick-to")}
                    </div>
                }
                <div className={'mgb-10'}>
                    <label className={'view-label required'}>
                        {t("offer.grid.service-provider")}
                    </label>

                    <ConfirmAuctionServiceProviderSelector disabled={disabled}
                                                           value={form.values.serviceProviderId}
                                                           onChange={(val) => {
                                                               setIsSubmitDisabled(!(val.value !== null && val.value !== undefined));

                                                               form.setFieldValue('serviceProviderId', val.value);
                                                               form.setFieldValue('serviceProvider', val.text)
                                                           }}/>

                    {!form.values.serviceProviderId && form.errors.serviceProviderId &&
                        <div className={'error-label'}>
                            {form.errors.serviceProviderId}
                        </div>
                    }
                </div>
            </div>
        </form>
    );
};

export default ConfirmAuctionFormServiceProvider;