import React, {useEffect, useState} from 'react';
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import FormLoadingScreen from "../../../../../ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {useUserApi} from "../../../../../../app/api/user";
import {IUserData} from "../../../../../../app/interfaces/user/IUserData";
import cl from './ForwarderDataForm.module.css';
import ChatAvatar from "../../../../components/Chat/components/ChatAvatar/ChatAvatar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faMobile, faPhone} from "@fortawesome/free-solid-svg-icons";

type ForwarderDataFormProps = {
    ids: string;
    close: () => void;
}

const ForwarderDataForm: React.FC<ForwarderDataFormProps> = ({
                                                                 ids,
                                                                 close
                                                             }) => {
    const [users, setUsers] = useState<Array<IUserData> | null>(null);

    const {
        getForwardersData: {
            isLoading,
            cancel,
            query
        }
    } = useUserApi();

    useEffect(() => {
        if (!ids || ids === '') {
            if (close) {
                close();
            }

            return;
        }

        (async () => {
            try {
                const response = await query(ids);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setUsers(response.data.data);

                    if (response.data.data.length <= 0 && close) {
                        close();
                    }
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error<string>('Unable to get forwarders data');

                    if (close) {
                        close();
                    }
                }
            }
        })();

        return () => {
            cancel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ids]);

    if (!ids || ids === '') {
        return null;
    }

    if (isLoading || users === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100 pd-10'}>
            {users.map(user => {
                return (
                    <div key={user.name}
                         className={cl.user}>
                        <div className={cl.header}>
                            <div>
                                <ChatAvatar isOnline={false}
                                            onVacation={false}
                                            size={'Big'}/>
                            </div>
                            <div>
                                {user.name}
                            </div>
                        </div>

                        {user.email &&
                            <div className={cl.row}>
                                <FontAwesomeIcon icon={faEnvelope}/>

                                <span>
                                    {user.email}
                                </span>
                            </div>
                        }

                        {user.phone &&
                            <div className={cl.row}>
                                <FontAwesomeIcon icon={faPhone}/>

                                <span>
                                    {user.phone}
                                </span>
                            </div>
                        }

                        {user.phone1 &&
                            <div className={cl.row}>
                                <FontAwesomeIcon icon={faMobile}/>

                                <span>
                                    {user.phone1}
                                </span>
                            </div>
                        }
                    </div>
                );
            })}
        </div>
    );
};

export default ForwarderDataForm;