import React, {ReactNode} from 'react';
import cl from './GridLayoutFilterPanel.module.css';
import {useGridLayoutContext} from "../../hooks/useGridLayoutContext";
import {createPortal} from "react-dom";
import Icon from "../../../../../assets/icon/Icon";
import {useTranslation} from "react-i18next";

type GridLayoutFilterPanelProps = {
    filterPanel?: ReactNode;
    isMobileView: boolean;
    isHeaderDisabled: boolean;
}

const GridLayoutFilterPanel: React.FC<GridLayoutFilterPanelProps> = ({
                                                                         filterPanel,
                                                                         isMobileView,
                                                                         isHeaderDisabled
                                                                     }) => {
    const {t} = useTranslation();

    const {
        isFilterPanelShown,
        setIsFilterPanelShown
    } = useGridLayoutContext<any>();

    if (!isFilterPanelShown) {
        return null;
    }

    if (isMobileView) {
        return (
            <>
                {
                    createPortal(
                        <div className={cl.panel}
                             data-display={'absolute'}>
                            <div className={cl.filterBtnContainer}>
                                <button className={`default-form-close-btn`}
                                        disabled={isHeaderDisabled}
                                        title={t("shared.labels.filters")}
                                        onClick={() => {
                                            if (isHeaderDisabled) {
                                                return;
                                            }

                                            let val = !isFilterPanelShown;
                                            setIsFilterPanelShown(val);
                                        }}>
                                    <Icon icon={"faXMark"}/>
                                </button>
                            </div>

                            {filterPanel}
                        </div>,
                        document.body
                    )
                }
            </>
        );
    } else {
        return (
            <div className={cl.panel}
                 data-display={'default'}>
                {filterPanel}
            </div>
        );
    }
};

export default GridLayoutFilterPanel;