import React from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import moment from "moment/moment";

const TillDateTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);

    let auctionEnd = moment.utc(props.children, 'DD.MM.YY HH:mm').toDate() <= moment().utc().add(-1, 'days').toDate();

    return (
        <td {...props.tdProps}
            onClick={handleClick}>
                <span className={`default-left-cell default-overflow-cell`}
                      style={auctionEnd ? {
                          fontWeight: 'bold',
                          color: '#c9302c'
                      } : undefined}>
                    {moment(props.children, 'DD.MM.YY HH:mm').format('DD.MM.YYYY')}
                </span>
        </td>
    );
};

export default TillDateTemplate;
