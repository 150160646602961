import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import cl from './TreeListBox.module.css';
import Loader, {LoaderType} from "../loaders/Loader/Loader";
import {useTranslation} from "react-i18next";
import {debounce} from "debounce";
import {IGridResultResponse} from "../../../app/interfaces/response/IGridResultResponse";
import {ISelectTreeModel} from "../../../app/interfaces/shared/ISelectTreeModel";
import TreeListBoxRowSkeleton from "./components/TreeListBoxRowSkeleton/TreeListBoxRowSkeleton";
import {toast} from "react-toastify";
import {isAxiosError} from "axios";
import {
    handleTreeViewCheckChange, ItemRenderProps, processTreeViewItems, TreeView,
    TreeViewCheckDescriptor,
    TreeViewExpandChangeEvent
} from "@progress/kendo-react-treeview";
import useElementSize from "../../../hooks/useElementSize/useElementSize";
import {SelectModel} from "../../../app/types/SelectModel";
import Icon from "../../../assets/icon/Icon";

type TreeListBoxProps = {
    id: string;
    take: number;
    height: number;
    selected: Array<SelectModel>;
    onChange: (ev: {
        selected: Array<ISelectTreeModel>;
        shaken: Array<ISelectTreeModel>;
    }) => void;
    onDataLoad: (skip: number, take: number, filter: string | null) => Promise<IGridResultResponse<ISelectTreeModel>>;
    disabled?: boolean;
    showSearchInput?: boolean;
    noDataLabel?: string;
    defaultExpandFirstLevelItems?: boolean;
    template?: FunctionComponent<ItemRenderProps>;
    refresh?: number;
};

const find = (tree: ISelectTreeModel, value: string): ISelectTreeModel | undefined =>
    tree.value === value ?
        tree :
        tree.items?.reduce((result: ISelectTreeModel | undefined, n) => result || find(n, value), undefined);

function markSelected(array: Array<ISelectTreeModel> = [], ids: Array<string>) {
    if (!array.length)
        return false;

    let selected = true;

    array.forEach(node => {
        node.selected = ids.includes(node.value) || markSelected(node.items, ids);
        selected = selected && node.selected;
    });

    return selected;
}

const getSelectedItems = (tree: Array<ISelectTreeModel> = []): Array<ISelectTreeModel> => {
    return tree.reduce((acc: Array<ISelectTreeModel>, current: ISelectTreeModel) => {
        if (current.selected === true) {
            acc.push(current);
        } else if (current.items.length > 0) {
            acc = [
                ...acc,
                ...getSelectedItems(current.items)
            ];
        }

        return acc;
    }, []);
}

const getSelectedRestoreItems = (tree: Array<ISelectTreeModel> = []): Array<ISelectTreeModel> => {
    let items = getSelectedItems(tree);

    const get = (l: Array<ISelectTreeModel>): Array<ISelectTreeModel> => {
        return l.reduce((acc: Array<ISelectTreeModel>, current: ISelectTreeModel) => {
            acc.push({
                ...current
            });

            if (current.items.length > 0) {
                let nested = get(current.items);

                acc = [
                    ...acc,
                    ...nested
                ];
            }

            return acc;
        }, []);
    };

    return get(items);
}

const shake = (tree: Array<ISelectTreeModel>, selected: Array<ISelectTreeModel>): Array<ISelectTreeModel> => {
    let copy = JSON.parse(JSON.stringify(tree));

    markSelected(copy, selected.map(val => val.value));

    return getSelectedItems(copy);
}

const restore = (tree: Array<ISelectTreeModel>, selected: Array<SelectModel>): Array<ISelectTreeModel> => {
    let copy = JSON.parse(JSON.stringify(tree));

    markSelected(copy, selected.map(val => val.value));

    let selectedRestored = getSelectedRestoreItems(copy);
    let unloaded: Array<ISelectTreeModel> = [];

    for (let sel of selected) {
        let existItem = selectedRestored.find(e => e.value === sel.value);

        if (!existItem) {
            unloaded.push({
                text: sel.text,
                alias: sel.text,
                value: sel.value,
                items: [],
                description: null,
                description2: sel.description2 ?? null
            });
        }
    }

    return [
        ...selectedRestored,
        ...unloaded
    ];
}

const TreeListBox: React.FC<TreeListBoxProps> = ({
                                                     onDataLoad,
                                                     take,
                                                     height,
                                                     id,
                                                     noDataLabel,
                                                     onChange,
                                                     showSearchInput = true,
                                                     disabled = false,
                                                     selected,
                                                     template,
                                                     refresh
                                                 }) => {
    const [widthL] = useElementSize(id);
    const {t} = useTranslation();

    const loaderRef = useRef<HTMLDivElement | null>(null);

    const [data, setData] = useState<Array<ISelectTreeModel>>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [filter, setFilter] = useState<string>('');
    const [isInitialLoading, setIsInitialLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);

    const [check, setCheck] = React.useState<any[] | (TreeViewCheckDescriptor & { ids: any[]; })>({
        ids: [],
        applyCheckIndeterminate: true,
        idField: 'value'
    });
    const [expand, setExpand] = React.useState<Array<any>>([]);

    useEffect(() => {
        (async () => {
            setIsInitialLoading(prev => !prev);

            let response = await onDataLoad(0, take, null);
            setTotalRows(response.count);

            setData(response.result);

            setPage(prev => prev + 1);

            setIsInitialLoading(prev => !prev);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!refresh) {
            return;
        }

        (async () => {
            const result = await onDataLoad(page * take, take, null);

            setTotalRows(result.count);
            setData(result.result);
        })();
    }, [refresh]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onFilterChange = useCallback(debounce(async (value: string) => {
        let response = await onDataLoad(0, value === '' ? take : 250, value);

        setTotalRows(response.count);
        setData(response.result);
        setPage(1);
    }, 500), [onDataLoad]);

    const loadData = useCallback(async () => {
        if (isLoading) {
            return;
        }

        setIsLoading(prev => !prev);

        try {
            const result = await onDataLoad(page * take, take, null);

            setTotalRows(result.count);
            setData(prev => {
                return [
                    ...prev,
                    ...result.result
                ]
            });

            setPage(prev => prev + 1);
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(`Unable to get tree list box data. Error: ${e.message}`);
            }
        }

        setIsLoading(prev => !prev);
    }, [isLoading, page]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0];
            if (target.isIntersecting) {
                loadData().then(() => {
                    //ignore
                });
            }
        });

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [loadData, totalRows]);

    useEffect(() => {
        if (data.length <= 0) {
            return;
        }

        let restored = restore(data, selected);

        setCheck({
            ids: restored.map(item => item.value),
            applyCheckIndeterminate: true,
            idField: 'value'
        });
    }, [selected, data]);

    const onCheckChange = (event: TreeViewExpandChangeEvent) => {
        const settings = {singleMode: false, checkChildren: true, checkParents: true};

        let ch = handleTreeViewCheckChange(event, check, data, settings) as any;

        if (ch.ids !== undefined && ch.ids !== null && Array.isArray(ch.ids)) {
            let items: Array<ISelectTreeModel> = [];
            let unloaded: Array<ISelectTreeModel> = [];

            for (let id of ch.ids) {
                for (let item of data) {
                    let existItem = find(item, id);
                    if (existItem) {
                        items.push({
                            ...existItem
                        });

                        break;
                    }
                }
            }

            for (let id of ch.ids) {
                let existItem = items.find(e => e.value === id);
                let existItemSelected = selected.find(e => e.value === id);

                if (!existItem && existItemSelected) {
                    unloaded.push({
                        text: existItemSelected.text,
                        alias: existItemSelected.text,
                        value: existItemSelected.value,
                        items: [],
                        description: null,
                        description2: existItemSelected.description2 ?? null
                    });
                }
            }

            onChange({
                selected: items,
                shaken: [
                    ...shake(data, items),
                    ...unloaded
                ]
            });
        }
    };

    const onExpandChange = (event: TreeViewExpandChangeEvent) => {
        let exp = expand.slice();
        const index = exp.indexOf(event.itemHierarchicalIndex);

        index === -1
            ? exp.push(event.itemHierarchicalIndex)
            : exp.splice(index, 1);

        setExpand(exp);
    }

    return (
        <div style={{height: `${height}px`}}
             id={id}
             data-type={showSearchInput ? 'si' : 'default'}
             className={`w100 h100 relative ${cl.treeListBox}`}>
            {showSearchInput &&
                <div className={cl.searchContainer}>
                    <input type={'text'}
                           className={cl.search}
                           disabled={disabled || isInitialLoading}
                           value={filter}
                           onChange={async (ev: React.ChangeEvent<HTMLInputElement>) => {
                               setFilter(ev.target.value);

                               await onFilterChange(ev.target.value);
                           }}
                           placeholder={t("shared.labels.search")}/>

                    <Icon icon={"faMagnifyingGlass"}
                          className={cl.glass}/>
                </div>
            }
            <div style={{
                width: `${widthL}px`,
                overflowX: "hidden",
                overflowY: "auto",
                height: `${showSearchInput ? `${height - 35}px` : `${height}px`}`,
                backgroundColor: "white"
            }}>
                {isInitialLoading &&
                    <div className={`${cl.loaderContainer}`}>
                        <div>
                            <Loader type={LoaderType.Action} style={{
                                scale: '0.4',
                                width: '30px',
                                height: '30px'
                            }}/>
                        </div>
                    </div>
                }

                {(!data || data.length <= 0) && !isInitialLoading
                    ? <div className={cl.noData}>
                        {noDataLabel !== undefined && noDataLabel !== ''
                            ? <>{noDataLabel}</>
                            : <>{t("shared.labels.no-data-available")}</>
                        }
                    </div>
                    : null
                }

                {data && data.length > 0 && !isInitialLoading &&
                    <div className={'tree-list-box'}
                         style={{
                             backgroundColor: 'white'
                         }}>
                        <TreeView data={processTreeViewItems(data, {check: check, expand: expand})}
                                  size={'medium'}
                                  onCheckChange={onCheckChange}
                                  checkboxes={true}

                                  expandIcons={true}
                                  onExpandChange={onExpandChange}
                                  item={template}/>
                        {data.length < totalRows &&
                            <div ref={loaderRef}
                                 style={{height: '25px'}}>
                                {isLoading && <TreeListBoxRowSkeleton showExpandIcon={true}/>}
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
};


export default TreeListBox;
