import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'bell',
    pathData: [
        'M7.79545 17.5003C8.38306 18.019 9.15493 18.3337 10.0003 18.3337C10.8457 18.3337 11.6175 18.019 12.2052 17.5003M15.0003 6.66699C15.0003 5.34091 14.4735 4.06914 13.5358 3.13146C12.5982 2.19378 11.3264 1.66699 10.0003 1.66699C8.67422 1.66699 7.40245 2.19378 6.46477 3.13146C5.52708 4.06914 5.0003 5.34091 5.0003 6.66699C5.0003 9.24215 4.35069 11.0053 3.62502 12.1715C3.01291 13.1552 2.70685 13.6471 2.71807 13.7843C2.7305 13.9362 2.76268 13.9942 2.88511 14.085C2.99568 14.167 3.49413 14.167 4.49101 14.167H15.5096C16.5065 14.167 17.0049 14.167 17.1155 14.085C17.2379 13.9942 17.2701 13.9362 17.2825 13.7843C17.2938 13.6471 16.9877 13.1552 16.3756 12.1715C15.6499 11.0053 15.0003 9.24215 15.0003 6.66699Z'
    ],
    viewBox: '0 0 20 20',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;