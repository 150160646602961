import React from 'react';
import {IChatReplyTo} from "../../../../../app/interfaces/chat/IChat";
import {IUser} from "../../../../../app/interfaces/user/IUser";
import UserChat from "../../../components/Chat/components/UserChat/UserChat";
import {UserRole} from "../../../../../app/enums/UserRole";
import ForwarderData from '../ForwarderData/ForwarderData';

type ForwarderFieldProps = {
    user: IUser;
    chatReplyTo?: IChatReplyTo;
};

const ForwarderField: React.FC<ForwarderFieldProps> = ({
                                                           user,
                                                           chatReplyTo
                                                       }) => {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '3px'
        }}>
            {user.uniqueUserValue && user.hasKpAccount &&
                <UserChat user={{
                    role: UserRole.Forwarder,
                    id: user.uniqueUserValue ?? ''
                }} replyTo={chatReplyTo}/>
            }

            <ForwarderData user={{
                ...user,
                idStr: user.idStr ?? user.uniqueUserValue
            }}/>
        </div>
    );
};

export default ForwarderField;
