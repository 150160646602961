import React, {ReactNode} from 'react';
import cl from './Container.module.css';

type ContainerProps = {
    height?: string;
    width?: string;
    centerContent: boolean;
    children: ReactNode
}

const Container = ({
                       height,
                       centerContent = false,
                       width,
                       children
                   }: ContainerProps) => {
    const style = {
        width: `${width ?? '100%'}`,
        height: `${height ?? '100%'}`
    };

    return (
        <div style={style}
             className={centerContent ? cl.center : ''}>
            {children}
        </div>
    );
};

export default Container;