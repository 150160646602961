import React from 'react';
import TreeListBox from "../../../../ui/TreeListBox/TreeListBox";
import {useDataHelperApi} from "../../../../../app/api/dataHelper";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {IGridResultResponse} from "../../../../../app/interfaces/response/IGridResultResponse";
import {ISelectTreeModel} from "../../../../../app/interfaces/shared/ISelectTreeModel";
import {SelectModel} from "../../../../../app/types/SelectModel";

type FilterPanelRegionFilterContentProps = {
    id: string;
    disabled?: boolean;
    onChange: (value: Array<SelectModel>) => void,
    value: Array<SelectModel>;
}

const FilterPanelRegionFilterContent: React.FC<FilterPanelRegionFilterContentProps> = ({
                                                                                           id,
                                                                                           value,
                                                                                           onChange
                                                                                       }) => {
    const {
        getRegionsTree: {
            query: getRegionsTree
        }
    } = useDataHelperApi();

    const load = async (skip: number, take: number, filter: string | null) => {
        try {
            const response = await getRegionsTree(filter, skip, take);

            if (response?.status === 200 && response.data) {
                return response.data;
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(`Unable to get regions. Error: ${e.message}`);
            }
        }

        return {
            count: 0,
            result: []
        } as IGridResultResponse<ISelectTreeModel>;
    }

    return (
        <TreeListBox id={`${id}-tree-list-box`}
                     selected={value}
                     take={20}
                     height={250}
                     onDataLoad={load}
                     onChange={(ev) => {
                         onChange(ev.shaken.map(item => {
                             return {
                                 text: item.alias,
                                 value: item.value,
                                 description2: item.description2
                             } as SelectModel
                         }));
                     }}/>
    );
};

export default FilterPanelRegionFilterContent;
