import React, {useEffect, useState} from 'react';
import ListBox from "../../../../ui/ListBox/ListBox";
import {SelectModel} from "../../../../../app/types/SelectModel";
import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {useDataHelperApi} from "../../../../../app/api/dataHelper";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {IUserAccessSettings} from "../../../../../app/interfaces/user/IUserAccessSettings";
import TagContainer from "../../../../ui/Tag/TagContainer/TagContainer";
import {TagItem, TagItemBackgroundColor} from "../../../../ui/Tag/Tag";
import {setAccessSettings, setAccessSettingsData} from "../../../../../features/filter/filterSlice";
import {selectAccessSettingsBranches} from "../../../../../features/filter/filterSliceSelectors";

type AccessSettingsBranchFilterProps = {
    disabled: boolean;
    settings: IUserAccessSettings;
    isExpanded: boolean;
}

const AccessSettingsBranchFilter: React.FC<AccessSettingsBranchFilterProps> = ({
                                                                                   disabled,
                                                                                   settings,
                                                                                   isExpanded
                                                                               }) => {
    const [data, setData] = useState<Array<SelectModel>>([]);
    const branches = useAppSelector(selectAccessSettingsBranches);
    const dispatch = useAppDispatch();

    const {
        getAccessFilterBranches: {
            query,
            isLoading
        }
    } = useDataHelperApi();

    useEffect(() => {
        if (branches.length > 0) {
            return;
        }

        (async () => {
            try {
                const response = await query();

                if (response?.data?.resultCode === ResponseResultCode.Ok && response?.data.data) {
                    dispatch(setAccessSettingsData({
                        entity: 'Branches',
                        data: {
                            branches: response.data.data
                        } as any
                    }));
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error('Unable to load branches list: ' + e.message);
                }
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (data.length > 0 || branches.length <= 0) {
            return;
        }

        setData([...branches]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [branches]);

    const onFilter = (value: string | null) => {
        if (!value || value === '') {
            setData([...branches]);
        } else {
            setData([
                ...branches.filter(item => {
                    return item.text.toLowerCase().includes(value.toLowerCase())
                })
            ]);
        }
    }

    return (
        <div style={{margin: '10px 0'}}>
            {isExpanded &&
                <div style={{marginBottom: '5px'}}>
                    <TagContainer id={'access-settings-branch-filter-tags'}
                                  width={'100%'}
                                  height={'100px'}
                                  items={settings.branches.filter(e => {
                                      return branches.find(ex => ex.value === e) !== undefined;
                                  }).map((item) => {
                                      let existItem = branches.find(ex => ex.value === item) as SelectModel;

                                      return {
                                          id: `branch-tag-${existItem.value}`,
                                          text: existItem.text,
                                          data: existItem,
                                          showClearIcon: true,
                                          backgroundColor: TagItemBackgroundColor.LightGray,
                                          rounded: true
                                      } as TagItem;
                                  })}
                                  onClear={({data}) => {
                                      if (data !== undefined && data !== null) {
                                          let item = data as SelectModel;

                                          dispatch(setAccessSettings({
                                              ...settings,
                                              branches: [
                                                  ...settings.branches.filter(e => e !== item.value)
                                              ]
                                          }));
                                      }
                                  }}/>
                </div>
            }
            <ListBox id={'access-settings-branch-filter'}
                     selected={settings.branches.map(item => {
                         return {
                             text: item,
                             value: item
                         }
                     })}
                     disabled={disabled}
                     data={data}
                     isLoading={isLoading}
                     onFilter={onFilter}
                     height={250}
                     selection={'Multiple'}
                     onSelectionChange={(items) => {
                         dispatch(setAccessSettings({
                             ...settings,
                             branches: [...items.map(item => item.value)]
                         }));
                     }}/>
        </div>
    );
};

export default AccessSettingsBranchFilter;