import React, {useEffect, useState} from 'react';
import {GridRowClickEvent, GridRowDoubleClickEvent} from "@progress/kendo-react-grid";
import {useGridLayoutContext} from "../../../uiLayouts/GridLayout/hooks/useGridLayoutContext";
import {GridConfig} from "./types/GridConfig";
import SkeletonLoadingScreen from "../../loaders/SkeletonLoadingScreen/SkeletonLoadingScreen";
import {combineDefaultAndSavedColumns, isColumnsLockedChanged} from "./utils";
import Grid from "./Grid";
import SkeletonGridLoader from "../../loaders/SkeletonLoadingScreen/components/SkeletonGridLoader/SkeletonGridLoader";
import {Query} from "../../../../app/types/Query";
import {ContextMenuItem} from "./types/ContextMenuItem";

type GridLayoutGridProps = {
    query?: Query;
    id: string;
    url: string;
    width?: string;
    height?: string;

    allowPaging?: boolean;
    allowSorting?: boolean;
    allowSelection?: boolean;
    allowResizing?: boolean;
    allowReordering?: boolean;
    allowGrouping?: boolean;
    skipLoading?: boolean;

    showCellContextMenu?: boolean;
    contextMenuItems?: Array<ContextMenuItem>;

    defaultPageSizes?: Array<number>;
    selectionMode?: "single" | "multiple";

    onSelect?: (rows: Array<any>) => void;
    onRowClick?: (ev: GridRowClickEvent) => void;
    onRowDoubleClick?: (ev: GridRowDoubleClickEvent) => void;
}

const GridLayoutGrid = (props: GridLayoutGridProps) => {
    const [config, setConfig] = useState<GridConfig | null>();
    const [isLockingColumnsUpdate, setIsLockingColumnsUpdate] = useState<boolean>();
    const [q, setQ] = useState<Query | undefined>();

    const {
        gridConfig,
        defaultGridConfig,
        saveGridConfig,
        refreshVal
    } = useGridLayoutContext<any>();

    useEffect(() => {
        if (props.query) {
            setQ(props.query);
        }
    }, [props.query]);

    useEffect(() => {
        if (!defaultGridConfig) {
            return;
        }

        let lConfig = gridConfig ?? defaultGridConfig;
        if (lConfig) {
            let cols = combineDefaultAndSavedColumns(
                defaultGridConfig?.columns ?? [],
                gridConfig?.columns ?? null
            );

            if (isColumnsLockedChanged(config?.columns ?? [], cols)) {
                setIsLockingColumnsUpdate(true);

                cols = [];
            }

            setConfig({
                columns: cols,
                sortBy: lConfig.sortBy,
                groupBy: lConfig.groupBy,
                pageSize: lConfig.pageSize,
                headerTemplate: defaultGridConfig?.headerTemplate,
                cellTemplate: defaultGridConfig?.cellTemplate,
                groupTemplate: defaultGridConfig?.groupTemplate
            });
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultGridConfig, gridConfig]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (config && config.columns.length <= 0 && isLockingColumnsUpdate) {
                setConfig({
                    ...config,
                    columns: combineDefaultAndSavedColumns(
                        defaultGridConfig?.columns ?? [],
                        gridConfig?.columns ?? null
                    )
                });

                setIsLockingColumnsUpdate(false);
            }
        }, 200);

        return () => {
            clearTimeout(timeout);
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLockingColumnsUpdate]);

    if (!defaultGridConfig || !config || config.columns.length <= 0 || !q) {
        if (isLockingColumnsUpdate || !q) {
            return <SkeletonGridLoader/>
        } else {
            return <SkeletonLoadingScreen type={'Grid'}/>
        }
    }

    return (
        <Grid config={config}
              refresh={refreshVal}
              query={q}
              id={props.id}
              url={props.url}
              width={props.width}
              height={props.height}
              allowPaging={props.allowPaging}
              allowSorting={props.allowSorting}
              allowSelection={props.allowSelection}
              allowResizing={props.allowResizing}
              allowReordering={props.allowReordering}
              allowGrouping={props.allowGrouping}
              showCellContextMenu={props.showCellContextMenu}
              contextMenuItems={props.contextMenuItems}
              defaultPageSizes={props.defaultPageSizes}
              selectionMode={props.selectionMode}
              onRowClick={props.onRowClick}
              onRowDoubleClick={props.onRowDoubleClick}
              onSelect={props.onSelect}
              skipLoading={props.skipLoading}

              handleConfigSave={async (config) => await saveGridConfig(config)}
        />
    );
};

export default GridLayoutGrid;