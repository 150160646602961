import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'noUser',
    pathData: [
        'M12.6758 1.34106L17.0006 5.66586M17.0006 1.34106L12.6758 5.66586'
    ],
    pathDataExtended: [
        {
            data: 'M7.15544 7.37339C8.91541 7.37339 10.3421 5.94666 10.3421 4.18669C10.3421 2.42673 8.91541 1 7.15544 1C5.39548 1 3.96875 2.42673 3.96875 4.18669C3.96875 5.94666 5.39548 7.37339 7.15544 7.37339Z',
            fill: 'currentColor'
        },
        {
            data: 'M0.782227 11.8524C0.782227 10.1956 2.12537 8.85242 3.78223 8.85242H10.529C12.1859 8.85242 13.529 10.1956 13.529 11.8524V14.3153H0.782227V11.8524Z',
            fill: 'currentColor'
        }
    ],
    viewBox: '0 0 18 15',
    strokeWidth: 1,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;