import {AxiosResponse} from "axios";
import {IDataResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {SelectModel} from "../../types/SelectModel";

export const useGetCurrencies = (): {
    isLoading: boolean,
    cancel: () => void,
    getCurrencies: (type: 'All' | 'Quote' | 'Shipment') => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
    });

    const getCurrencies = useCallback(async (type: 'All' | 'Quote' | 'Shipment') => {
        let q = '';

        if (type === 'Quote') {
            q += '?forQuote=true';
        }

        if (type === 'Shipment') {
            q += '?forShipment=true';
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/currency/getCurrencies${q}`
        });
    }, [execute]);

    return {getCurrencies, isLoading, cancel};
};

export const useGetCurrenciesAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    getCurrenciesAnonymous: (type: 'All' | 'Quote' | 'Shipment') => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
    });

    const getCurrenciesAnonymous = useCallback(async (type: 'All' | 'Quote' | 'Shipment') => {
        let q = '';

        if (type === 'Quote') {
            q += '?forQuote=true';
        }

        if (type === 'Shipment') {
            q += '?forShipment=true';
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/currency/getCurrenciesAnonymous${q}`
        });
    }, [execute]);

    return {getCurrenciesAnonymous, isLoading, cancel};
};
