import React, {useEffect, useState} from 'react';
import {SelectModel} from "../../../../../../../app/types/SelectModel";
import {useDataHelperApi} from "../../../../../../../app/api/dataHelper";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {
    IConfirmAuctionContractGetAction
} from "../../../../../../../app/interfaces/offer/IConfirmAuctionContractGetAction";

type ConfirmAuctionServiceProviderSelectorProps = {
    model: IConfirmAuctionContractGetAction;
    disabled: boolean;
    value: string | null;
    onChange: (item: SelectModel) => void;
};

const ConfirmAuctionServiceProviderSelector: React.FC<ConfirmAuctionServiceProviderSelectorProps> = ({
                                                                                                         model,
                                                                                                         disabled,
                                                                                                         value,
                                                                                                         onChange
                                                                                                     }) => {
    const [data, setData] = useState<Array<SelectModel>>([]);
    const {
        getReleaseServiceProviderList: {
            query: getReleaseServiceProviderList,
            isLoading: getReleaseServiceProviderListIsLoading,
            cancel: cancelGetReleaseServiceProviderList
        },
        getContractServiceProviderList: {
            query: getContractServiceProviderList,
            isLoading: getContractServiceProviderListIsLoading,
            cancel: cancelGetContractServiceProviderList
        }
    } = useDataHelperApi();

    useEffect(() => {

        (async () => {
            try {
                const response = model.isContract
                    ? await getContractServiceProviderList(model.orderReleaseGid)
                    : await getReleaseServiceProviderList(model.initialServiceProviderId);

                if (response?.status === 200 && response.data) {
                    setData(response.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error(`Unable to get service providers list: ${e.message}`);
                }
            }
        })();

        return () => {
            cancelGetContractServiceProviderList();
            cancelGetReleaseServiceProviderList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={'drop-down-picker'}>
            <DropDownList data={data}
                          className={'confirm-auction-service-provider'}
                          disabled={disabled || getReleaseServiceProviderListIsLoading || getContractServiceProviderListIsLoading}
                          loading={getReleaseServiceProviderListIsLoading || getContractServiceProviderListIsLoading}
                          textField="text"
                          dataItemKey="value"
                          value={value !== null ? data.find(e => e.value === value) : null}
                          onChange={(ev) => {
                              onChange(ev.value);
                          }}
            />
        </div>
    );
};

export default ConfirmAuctionServiceProviderSelector;