import React, {useState} from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import cl from "../ShipmentAddressTemplate/ShipmentAddressTemplate.module.css";
import {getEventLabel} from "../../../../../../helpers/shipment";
import ShipmentsEventListDialog from "../../../actions/ShipmentsEventList/ShipmentsEventListDialog";
import Icon from "../../../../../../assets/icon/Icon";

const ShipmentEventTemplate = (props: any) => {
    const user = useAppSelector(selectCurrentUser);
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const {t} = useTranslation();
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);

    let sId = props.dataItem.sId !== undefined && props.dataItem.sId !== null
        ? props.dataItem.sId
        : '';
    let shipmentEvent = props.dataItem.shipmentEvent !== undefined && props.dataItem.shipmentEvent !== null
        ? props.dataItem.shipmentEvent
        : '';
    let isUnConfirmed = props.dataItem["isUnconfirm"] !== null && props.dataItem["isUnconfirm"] !== undefined
        ? Boolean(props.dataItem["isUnconfirm"])
        : false;
    let isArchive = props.dataItem.isArchive !== null && props.dataItem.isArchive !== undefined
        ? Boolean(props.dataItem.isArchive)
        : false;
    let isFinished = props.dataItem.isFinished !== null && props.dataItem.isFinished !== undefined
        ? Boolean(props.dataItem.isFinished)
        : false;

    let label = `${t(getEventLabel(shipmentEvent, props.dataItem["shipmentLatestEventType"] ?? '', props.dataItem["shipmentEventStopPoint"] ?? null))}`
    if(props.dataItem["shipmentLatestEventAddress"])
        label += ', ' + props.dataItem["shipmentLatestEventAddress"]

    if (!sId || sId === '' || isUnConfirmed || isArchive || !user || (getUserRole(user) === UserRole.Client) ||
        (getUserRole(user) === UserRole.ServiceProvider && isFinished)) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={'default-left-cell default-overflow-cell'}>
                    {label}
                </span>
            </td>
        )
    }

    return (
        <td {...props.tdProps}
            colSpan={1}>
            <ShipmentsEventListDialog id={sId}
                                      show={isDialogShown}
                                      user={user}
                                      setShow={setIsDialogShown}/>
            <span onClick={handleClick} className={'default-left-cell default-overflow-cell grid-prevent-selection'}>
                <>

                <Icon icon={"faPen"}
                      className={`${cl.icon} ${shipmentEvent && shipmentEvent !== '' ? cl.iconWithValue : ''} shipment-grid-edit-shipment-events-btn grid-prevent-selection`}
                      onClick={(ev) => {
                          ev.stopPropagation();

                          setIsDialogShown(prev => !prev);
                      }}/>
                    {shipmentEvent && shipmentEvent !== ''
                        ? <span className={`grid-prevent-selection ${cl.text}`}
                                onClick={(ev) => {
                                    ev.stopPropagation();

                                    setIsDialogShown(prev => !prev);
                                }}>
                        {label}
                    </span>
                        : <span className={`grid-prevent-selection ${cl.addText}`}
                                onClick={(ev) => {
                                    ev.stopPropagation();

                                    setIsDialogShown(prev => !prev);
                                }}>
                        {t("shipment.grid.add-event")}
                    </span>
                    }
                </>
            </span>
        </td>
    );
};

export default ShipmentEventTemplate;
