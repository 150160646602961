import React from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../app/store";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSliceSelectors";
import {UserRole} from "../../../../app/enums/UserRole";
import AccessSettingsCollapseBubble from "./AccessSettingsCollapseBubble/AccessSettingsCollapseBubble";
import cl from './AccessSettings.module.css';
import AccessSettingsForwarderFilter from "./AccessSettingsForwarderFilter/AccessSettingsForwarderFilter";
import AccessSettingsBranchFilter from "./AccessSettingsBranchFilter/AccessSettingsBranchFilter";
import AccessSettingsDepartmentFilter from "./AccessSettingsDepartmentFilter/AccessSettingsDepartmentFilter";
import AccessSettingsServiceProviderFilter
    from "./AccessSettingsServiceProviderFilter/AccessSettingsServiceProviderFilter";
import AccessSettingsTransportLineDestinationFilter
    from "./AccessSettingsTransportLineDestinationFilter/AccessSettingsTransportLineDestinationFilter";
import AccessSettingsTransportLineSourceFilter
    from "./AccessSettingsTransportLineSourceFilter/AccessSettingsTransportLineSourceFilter";
import {selectAccessSettings} from "../../../../features/filter/filterSliceSelectors";
import {setAccessSettings} from "../../../../features/filter/filterSlice";
import Icon from "../../../../assets/icon/Icon";
import AccessSettingsFilterPanelLayoutCollapse
    from "./components/AccessSettingsFilterPanelLayoutCollapse/AccessSettingsFilterPanelLayoutCollapse";

type AccessSettingsProps = {
    disabled: boolean;
}

const AccessSettings: React.FC<AccessSettingsProps> = ({
                                                           disabled
                                                       }) => {
    const {t} = useTranslation();

    const settings = useAppSelector(selectAccessSettings);

    const user = useAppSelector(selectCurrentUser);
    const dispatch = useAppDispatch();

    if (!user || !settings) {
        return null;
    }

    if (user.role !== UserRole.Administrator && user.role !== UserRole.Forwarder) {
        return null;
    }

    return (
        <div>
            <AccessSettingsFilterPanelLayoutCollapse style={{margin: '0', padding: '10px 9px 5px 9px'}}
                                                     defaultExpanded={false}
                                                     titleBold={false}
                                                     title={
                                                         <>
                                                             <div className={cl.headerContainer}>
                                                                 <AccessSettingsCollapseBubble
                                                                     isFilterSelected={settings.forwarders.length > 0}
                                                                     count={settings.forwarders.length}/>
                                                                 <div>
                                                                     {t("accessSettings.forwarder")}
                                                                 </div>
                                                             </div>
                                                             <div>
                                                                 {settings.forwarders.length > 0 &&
                                                                     <Icon icon={"faTrash"}
                                                                           className={cl.trash}
                                                                           onClick={(ev) => {
                                                                               ev.stopPropagation();

                                                                               dispatch(setAccessSettings({
                                                                                   ...settings,
                                                                                   forwarders: []
                                                                               }));
                                                                           }}/>
                                                                 }
                                                             </div>
                                                         </>
                                                     }>
                {({isExpanded}) =>
                    <AccessSettingsForwarderFilter disabled={disabled}
                                                   settings={settings}
                                                   isExpanded={isExpanded}/>
                }
            </AccessSettingsFilterPanelLayoutCollapse>

            <AccessSettingsFilterPanelLayoutCollapse style={{margin: '0', padding: '10px 9px 5px 9px'}}
                                                     defaultExpanded={false}
                                                     titleBold={false}
                                                     title={
                                                         <>
                                                             <div className={cl.headerContainer}>
                                                                 <AccessSettingsCollapseBubble
                                                                     isFilterSelected={settings.branches.length > 0}
                                                                     count={settings.branches.length}/>
                                                                 <div>
                                                                     {t("accessSettings.branch")}
                                                                 </div>
                                                             </div>
                                                             <div>
                                                                 {settings.branches.length > 0 &&
                                                                     <Icon icon={"faTrash"}
                                                                           className={cl.trash}
                                                                           onClick={(ev) => {
                                                                               ev.stopPropagation();

                                                                               dispatch(setAccessSettings({
                                                                                   ...settings,
                                                                                   branches: []
                                                                               }));
                                                                           }}/>
                                                                 }
                                                             </div>
                                                         </>
                                                     }>
                {({isExpanded}) =>
                    <AccessSettingsBranchFilter disabled={disabled}
                                                settings={settings}
                                                isExpanded={isExpanded}/>
                }
            </AccessSettingsFilterPanelLayoutCollapse>

            {(user.role === UserRole.Administrator || user.isHead) &&
                <AccessSettingsFilterPanelLayoutCollapse style={{margin: '0', padding: '10px 9px 5px 9px'}}
                                                         defaultExpanded={false}
                                                         titleBold={false}
                                                         title={
                                                             <>
                                                                 <div className={cl.headerContainer}>
                                                                     <AccessSettingsCollapseBubble
                                                                         isFilterSelected={settings.departments.length > 0}
                                                                         count={settings.departments.length}/>
                                                                     <div>
                                                                         {t("accessSettings.department")}
                                                                     </div>
                                                                 </div>
                                                                 <div>
                                                                     {settings.departments.length > 0 &&
                                                                         <Icon icon={"faTrash"}
                                                                               className={cl.trash}
                                                                               onClick={(ev) => {
                                                                                   ev.stopPropagation();

                                                                                   dispatch(setAccessSettings({
                                                                                       ...settings,
                                                                                       departments: []
                                                                                   }));
                                                                               }}/>
                                                                     }
                                                                 </div>
                                                             </>
                                                         }>
                    {({isExpanded}) =>
                        <AccessSettingsDepartmentFilter disabled={disabled}
                                                        settings={settings}
                                                        isExpanded={isExpanded}/>
                    }
                </AccessSettingsFilterPanelLayoutCollapse>
            }

            <AccessSettingsFilterPanelLayoutCollapse style={{margin: '0', padding: '10px 9px 5px 9px'}}
                                                     defaultExpanded={false}
                                                     titleBold={false}
                                                     title={
                                                         <>
                                                             <div className={cl.headerContainer}>
                                                                 <AccessSettingsCollapseBubble
                                                                     isFilterSelected={settings.transportLinesSource.length > 0}
                                                                     count={settings.transportLinesSource.length}/>
                                                                 <div>
                                                                     {t("accessSettings.tls")}
                                                                 </div>
                                                             </div>
                                                             <div>
                                                                 {settings.transportLinesSource.length > 0 &&
                                                                     <Icon icon={"faTrash"}
                                                                           className={cl.trash}
                                                                           onClick={(ev) => {
                                                                               ev.stopPropagation();

                                                                               dispatch(setAccessSettings({
                                                                                   ...settings,
                                                                                   transportLinesSource: []
                                                                               }));
                                                                           }}/>
                                                                 }
                                                             </div>
                                                         </>
                                                     }>
                {({isExpanded}) =>
                    <AccessSettingsTransportLineSourceFilter disabled={disabled}
                                                             settings={settings}
                                                             isExpanded={isExpanded}/>
                }
            </AccessSettingsFilterPanelLayoutCollapse>

            <AccessSettingsFilterPanelLayoutCollapse style={{margin: '0', padding: '10px 9px 5px 9px'}}
                                                     defaultExpanded={false}
                                                     titleBold={false}
                                                     title={
                                                         <>
                                                             <div className={cl.headerContainer}>
                                                                 <AccessSettingsCollapseBubble
                                                                     isFilterSelected={settings.transportLinesDestination.length > 0}
                                                                     count={settings.transportLinesDestination.length}/>
                                                                 <div>
                                                                     {t("accessSettings.tld")}
                                                                 </div>
                                                             </div>
                                                             <div>
                                                                 {settings.transportLinesDestination.length > 0 &&
                                                                     <Icon icon={"faTrash"}
                                                                           className={cl.trash}
                                                                           onClick={(ev) => {
                                                                               ev.stopPropagation();

                                                                               dispatch(setAccessSettings({
                                                                                   ...settings,
                                                                                   transportLinesDestination: []
                                                                               }));
                                                                           }}/>
                                                                 }
                                                             </div>
                                                         </>
                                                     }>
                {({isExpanded}) =>
                    <AccessSettingsTransportLineDestinationFilter disabled={disabled}
                                                                  settings={settings}
                                                                  isExpanded={isExpanded}/>
                }
            </AccessSettingsFilterPanelLayoutCollapse>

            {user.role === UserRole.Administrator &&
                <AccessSettingsFilterPanelLayoutCollapse style={{margin: '0', padding: '10px 9px 5px 9px'}}
                                                         defaultExpanded={false}
                                                         titleBold={false}
                                                         title={
                                                             <>
                                                                 <div className={cl.headerContainer}>
                                                                     <AccessSettingsCollapseBubble
                                                                         isFilterSelected={settings.serviceProviderId !== null && settings.serviceProviderId !== ''}/>
                                                                     <div>
                                                                         {t("accessSettings.service-provider")}
                                                                     </div>
                                                                 </div>
                                                                 <div>
                                                                     {settings.serviceProviderId !== null && settings.serviceProviderId !== '' &&
                                                                         <Icon icon={"faTrash"}
                                                                               className={cl.trash}
                                                                               onClick={(ev) => {
                                                                                   ev.stopPropagation();

                                                                                   dispatch(setAccessSettings({
                                                                                       ...settings,
                                                                                       serviceProviderId: null,
                                                                                       serviceProviderName: null
                                                                                   }));
                                                                               }}/>
                                                                     }
                                                                 </div>
                                                             </>
                                                         }>
                    {({isExpanded}) =>
                        <AccessSettingsServiceProviderFilter disabled={disabled}
                                                             settings={settings}
                                                             isExpanded={isExpanded}/>
                    }
                </AccessSettingsFilterPanelLayoutCollapse>
            }
        </div>
    );
};

export default AccessSettings;
