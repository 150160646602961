import {Query} from "../../../../../app/types/Query";
import {IQuoteHeaderConfig} from "../data/interfaces/IQuoteHeaderConfig";
import {IGridFilter} from "../../../../../app/interfaces/gridFilter/IGridFilter";
import {SelectModel} from "../../../../../app/types/SelectModel";
import {GridFilter} from "../../../../../app/types/GridFilter";
import {IUser} from "../../../../../app/interfaces/user/IUser";
import {UserRole} from "../../../../../app/enums/UserRole";

export const buildQuoteQuery = (type: string, config: IQuoteHeaderConfig | null): Query => {
    const q = new Query();
    const t = getType(type);
    if (t) {
        q.addParam('t', t);
    }

    if (config) {
        let st = getSubType(config.subTab);
        if (st) {
            q.addParam('st', st);
        }

        q.addParam('role_cms', config.CM.toString())
            .addParam('role_tlm', config.TLM.toString())
            .addParam('role_fa', config.SMFA.toString())
            .addParam('role_exa', config.SMEXA.toString())
            .addParam('role_orm', config.OM.toString());
    }

    return q;
}

export const buildQuoteFilterQuery = (_: IGridFilter | null,
                                      predefinedFilter: SelectModel | null,
                                      filter: GridFilter | null): Query => {
    const q = new Query();

    if (predefinedFilter) {
        q.addParam('f', predefinedFilter.value);
    }

    if (filter) {
        if (filter.quoteNumber !== null && filter.quoteNumber !== '') {
            q.addParam('roxid', filter.quoteNumber);
        }

        if (filter.pickupDateFrom !== null && filter.pickupDateFrom !== '') {
            q.addParam('pkds', filter.pickupDateFrom);
        }

        if (filter.pickupDateTo !== null && filter.pickupDateTo !== '') {
            q.addParam('pkde', filter.pickupDateTo);
        }

        if (filter.deliveryDateFrom !== null && filter.deliveryDateFrom !== '') {
            q.addParam('dlds', filter.deliveryDateFrom);
        }

        if (filter.deliveryDateTo !== null && filter.deliveryDateTo !== '') {
            q.addParam('dlde', filter.deliveryDateTo);
        }

        if (filter.status.length > 0) {
            q.addParam('qstatus', filter.status.join('+'))
        }

        if (filter.source.length > 0) {
            q.addParam('sc', filter.source.map(item => item.value).join('+'))
        }

        if (filter.destination.length > 0) {
            q.addParam('dc', filter.destination.map(item => item.value).join('+'))
        }

        if (filter.supplier.length > 0) {
            q.addParam('cls', filter.supplier.map(item => item.value).join('+'));
        }

        if (filter.equipment.length > 0) {
            q.addParam('eq', filter.equipment.map(item => item.value).join('+'));
        }

        if (filter.tags.length > 0) {
            q.addParam('tags', filter.tags.map(item => item.value).join('+'));
        }
    }

    return q;
}

const getType = (t: string): string | null => {
    switch (t) {
        case 'active':
            return '1';
        case 'new':
            return '5';
        case 'sent':
            return '6';
        case 'bet':
            return '2';
        case 'expired':
            return '3';
        case 'clientSent':
            return '4';
    }

    return null;
}

const getSubType = (t: string): string | null => {
    switch (t) {
        case 'quote-my-quotes-tab':
            return '1';
        case 'quote-all-tab':
            return '2';
    }

    return null;
};

export const buildQuoteGridLabel = (type?: string): string => {
    switch (type) {
        case 'active':
            return 'sidebar.quote.all';
        case 'new':
            return 'sidebar.quote.new';
        case 'sent':
            return 'sidebar.quote.sent-to-carrier';
        case 'bet':
            return 'sidebar.quote.bet';
        case 'expired':
            return 'sidebar.quote.expired';
        case 'clientSent':
            return 'sidebar.quote.bet-sent-to-customer';
    }

    return 'sidebar.quote.title';
};
