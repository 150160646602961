const testString = 'mmMwWLliI0O&1'
const testSize = '48px'
const baseFonts = ['monospace', 'sans-serif', 'serif'] as const

const fontList = [
    'sans-serif-thin',
    'ARNO PRO',
    'Agency FB',
    'Arabic Typesetting',
    'Arial Unicode MS',
    'AvantGarde Bk BT',
    'BankGothic Md BT',
    'Batang',
    'Bitstream Vera Sans Mono',
    'Calibri',
    'Century',
    'Century Gothic',
    'Clarendon',
    'EUROSTILE',
    'Franklin Gothic',
    'Futura Bk BT',
    'Futura Md BT',
    'GOTHAM',
    'Gill Sans',
    'HELV',
    'Haettenschweiler',
    'Helvetica Neue',
    'Humanst521 BT',
    'Leelawadee',
    'Letter Gothic',
    'Levenim MT',
    'Lucida Bright',
    'Lucida Sans',
    'Menlo',
    'MS Mincho',
    'MS Outlook',
    'MS Reference Specialty',
    'MS UI Gothic',
    'MT Extra',
    'MYRIAD PRO',
    'Marlett',
    'Meiryo UI',
    'Microsoft Uighur',
    'Minion Pro',
    'Monotype Corsiva',
    'PMingLiU',
    'Pristina',
    'SCRIPTINA',
    'Segoe UI Light',
    'Serifa',
    'SimHei',
    'Small Fonts',
    'Staccato222 BT',
    'TRAJAN PRO',
    'Univers CE 55 Medium',
    'Vrinda',
    'ZWAdobeF',
] as const

const fontSpanStyle = {
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineBreak: 'auto',
    lineHeight: 'normal',
    textTransform: 'none',
    textAlign: 'left',
    textDecoration: 'none',
    textShadow: 'none',
    whiteSpace: 'normal',
    wordBreak: 'normal',
    wordSpacing: 'normal',
    position: 'absolute',
    left: '-9999px',
    fontSize: testSize,
}

const getFonts = (): string[] => {
    const d = document
    const holder = d.body

    // div to load spans for the base fonts
    const baseFontsDiv = d.createElement('div')

    // div to load spans for the fonts to detect
    const fontsDiv = d.createElement('div')

    const defaultWidth: Partial<Record<string, number>> = {}
    const defaultHeight: Partial<Record<string, number>> = {}

    // creates a span where the fonts will be loaded
    const createSpan = () => {
        const span = d.createElement('span')
        span.textContent = testString

        for (const prop of Object.keys(fontSpanStyle) as Array<keyof typeof fontSpanStyle>) {
            span.style[prop] = fontSpanStyle[prop]
        }

        return span
    }

    const createSpanWithFonts = (fontToDetect: string, baseFont: string) => {
        const s = createSpan()
        s.style.fontFamily = `'${fontToDetect}',${baseFont}`
        return s
    }

    const initializeBaseFontsSpans = () => {
        return baseFonts.map((baseFont) => {
            const s = createSpan()
            s.style.fontFamily = baseFont
            baseFontsDiv.appendChild(s)
            return s
        })
    }

    const initializeFontsSpans = () => {
        const spans: Record<string, HTMLSpanElement[]> = {}

        for (const font of fontList) {
            spans[font] = baseFonts.map((baseFont) => {
                const s = createSpanWithFonts(font, baseFont)
                fontsDiv.appendChild(s)
                return s
            })
        }

        return spans
    }

    const isFontAvailable = (fontSpans: HTMLElement[]) => {
        return baseFonts.some(
            (baseFont, baseFontIndex) =>
                fontSpans[baseFontIndex].offsetWidth !== defaultWidth[baseFont] ||
                fontSpans[baseFontIndex].offsetHeight !== defaultHeight[baseFont],
        )
    }

    const baseFontsSpans = initializeBaseFontsSpans()

    holder.appendChild(baseFontsDiv)

    for (let index = 0, length = baseFonts.length; index < length; index++) {
        defaultWidth[baseFonts[index]] = baseFontsSpans[index].offsetWidth // width for the default font
        defaultHeight[baseFonts[index]] = baseFontsSpans[index].offsetHeight // height for the default font
    }

    const fontsSpans = initializeFontsSpans()

    // add all the spans to the DOM
    holder.appendChild(fontsDiv)

    const available: string[] = []
    for (let i = 0, l = fontList.length; i < l; i++) {
        if (isFontAvailable(fontsSpans[fontList[i]])) {
            available.push(fontList[i])
        }
    }

    holder.removeChild(fontsDiv)
    holder.removeChild(baseFontsDiv)
    return available
}

export default getFonts;