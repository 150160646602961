import React, {useMemo} from 'react';
import {icons} from "./icons";
import uuid from "react-uuid";

export type IconName = keyof typeof icons;

type RotateProp = 90 | 180 | 270;
type FlipProp = 'horizontal' | 'vertical';

type IconProps = {
    icon: IconName;
    className?: string;
    title?: string;
    titleId?: string;
    rotate?: RotateProp;
    flip?: FlipProp;
    dataCustomValueAttribute?: string;
    onClick?: (ev: React.MouseEvent<SVGSVGElement>) => void;
    fill?: string;
    strokeWidth?: string | number;
    style?: React.CSSProperties;
};

export const getRotateClass = (rotate?: RotateProp): string => {
    if (!rotate) {
        return '';
    }

    switch (rotate) {
        case 90:
            return 'svg-a-rotate-90';
        case 180:
            return 'svg-a-rotate-180';
        case 270:
            return 'svg-a-rotate-270';
    }
};

export const getFlipClass = (flip?: FlipProp): string => {
    if (!flip) {
        return '';
    }

    switch (flip) {
        case 'vertical':
            return 'svg-a-flip-vertical';
        case 'horizontal':
            return 'svg-a-flip-horizontal';
    }
};

const Icon: React.FC<IconProps> = ({
                                       icon,
                                       rotate,
                                       flip,
                                       title,
                                       titleId,
                                       className,
                                       dataCustomValueAttribute,
                                       onClick,
                                       fill,
                                       strokeWidth,
                                       style
                                   }) => {
    const svg = useMemo(() => icons[icon], [icon]);

    let uniqueId = uuid();

    return (
        <svg viewBox={svg.viewBox}
             width={svg.width}
             height={svg.height}
             data-icon={svg.name}
             className={`svg-a-icon svg-a-icon-inline ${getRotateClass(rotate)} ${getFlipClass(flip)} ${className ? className : ''}`}
             xmlns="http://www.w3.org/2000/svg"
             role="img"
             aria-hidden={true}
             fill={'none'}
             focusable={false}
             data-custom-value-attr={dataCustomValueAttribute}
             onClick={onClick}
             style={style}>
            {title &&
                <title id={`svg-a-icon-title-${titleId ? titleId : uniqueId}`}>
                    {title}
                </title>
            }

            {svg.pathData?.map(path => {
                return <path key={path}
                             d={path}
                             fill={fill ? fill : !svg.strokeWidth ? 'currentColor' : undefined}
                             stroke={svg.strokeWidth ? 'currentColor' : undefined}
                             strokeLinejoin={svg.strokeLinejoin}
                             strokeLinecap={svg.strokeLinecap}
                             strokeWidth={strokeWidth ? strokeWidth : svg.strokeWidth}/>;
            })}

            {svg.circle?.map(c => {
                return <circle key={`${c.cx}-${c.cy}-${c.r}`}
                               fill={fill ? fill : (c.fill ? c.fill : 'currentColor')}
                               cx={c.cx}
                               cy={c.cy}
                               transform={c.transform}
                               stroke={svg.strokeWidth ? 'currentColor' : undefined}
                               r={c.r}/>
            })}

            {svg.pathDataExtended?.map(path => {
                return <path key={path.data}
                             d={path.data}
                             fill={path.fill}
                             stroke={path.strokeWidth ? 'currentColor' : undefined}
                             strokeLinejoin={path.strokeLinejoin}
                             strokeLinecap={path.strokeLinecap}
                             strokeWidth={strokeWidth ? strokeWidth : path.strokeWidth}/>;
            })}

            {svg.lines?.map(line => {
                return <line key={`${line.x1}-${line.x2}-${line.y1}-${line.y2}`}
                             stroke={'currentColor'}
                             x1={line.x1}
                             x2={line.x2}
                             y1={line.y1}
                             y2={line.y2}/>
            })}

            {svg.rect?.map(c => {
                return <rect key={`${c.width}-${c.height}-${c.rx}-${c.x}-${c.y}`}
                             fill={'currentColor'}
                             x={c.x}
                             y={c.y}
                             width={c.width}
                             height={c.height}
                             rx={c.rx}
                             transform={c.transform}/>
            })}
        </svg>
    );
};

export default Icon;