import React from 'react';
import {ICancelQuoteGetAction} from "../../../../../../app/interfaces/quote/ICancelQuoteGetAction";
import {ICancelQuotePostAction} from "../../../../../../app/interfaces/quote/ICancelQuotePostAction";
import {useTranslation} from "react-i18next";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import {quoteCancelReasons} from "../../../data/cancelReasons";
import {RadioButton} from "@progress/kendo-react-inputs";

type CancelQuoteFormProps = {
    model: ICancelQuoteGetAction;
    disabled: boolean;
    save: (model: ICancelQuotePostAction) => Promise<void>;
}

const CancelQuoteForm: React.FC<CancelQuoteFormProps> = ({
                                                             save,
                                                             model
                                                         }) => {
    const {t} = useTranslation();

    const form = useFormik<ICancelQuotePostAction>({
        initialValues: {
            orderReleaseGid: model.orderReleaseGid,
            notes: null,
            reason: null
        },
        validationSchema: Yup.object().shape({
            reason: Yup.string().required(t("shared.errors.required-error").replace('{0}', t("shared.labels.reason")))
        }),
        onSubmit: async (values: ICancelQuotePostAction, actions: FormikHelpers<ICancelQuotePostAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='cancel-quote-form'
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div className={'mgb-10'}>
                    <label className={'view-label required'}>
                        {t("shared.labels.reason")}
                    </label>
                    <div className={'radio-buttons-container'}>
                        {quoteCancelReasons.map(reason => {
                            return (
                                <div key={reason.value}
                                     style={{margin: '2px'}}>
                                    <RadioButton name="reason"
                                                 value={reason.value}
                                                 size={'medium'}
                                                 checked={form.values.reason === reason.value}
                                                 label={t(reason.labelResource)}
                                                 onChange={() => {
                                                     form.setFieldValue('reason', reason.value);
                                                 }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    {form.errors.reason &&
                        <div className={'error-label'}>
                            {form.errors.reason}
                        </div>
                    }
                </div>
            </div>
        </form>
    );
};

export default CancelQuoteForm;