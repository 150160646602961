import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import DeleteShipment from "./DeleteShipment";

type DeleteShipmentDialogProps = {
    id: string;
    sId: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteShipmentDialog: React.FC<DeleteShipmentDialogProps> = ({
                                                                       id,
                                                                       sId,
                                                                       show,
                                                                       setShow
                                                                   }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'delete-shipment-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.btn.delete")}>
            <DeleteShipment id={id}
                            sId={sId}
                            close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default DeleteShipmentDialog;