import React from 'react';
import {GridActionRowSelectorProps} from "../../HardGridActionRow";
import GridActionRowSelect from "../../../GridActionRow/components/GridActionRowSelect/GridActionRowSelect";
import GridActionRowSelectOption
    from "../../../GridActionRow/components/GridActionRowSelect/GridActionRowSelectOption/GridActionRowSelectOption";

type HardGridActionRowSelectorsSectionProps = {
    id: string;
    selectors: Array<GridActionRowSelectorProps>
};

const HardGridActionRowSelectorsSection: React.FC<HardGridActionRowSelectorsSectionProps> = ({
                                                                                                 id,
                                                                                                 selectors
                                                                                             }) => {
    return (
        <>
            {selectors.map((selector, index) => {
                if (selector.options.length <= 0) {
                    return null;
                }

                return (
                    <GridActionRowSelect key={`${id}-selector-${index}`}
                                         disabled={selector.selector.disabled}
                                         width={selector.selector.width}
                                         onChange={selector.selector.onChange}>
                        {selector.options.map(opt => {
                            return (
                                <GridActionRowSelectOption key={opt.id}
                                                           id={opt.id}
                                                           selected={opt.selected}
                                                           text={opt.text}
                                                           tooltip={opt.tooltip}/>
                            );
                        })}
                    </GridActionRowSelect>
                );
            })}
        </>
    );
};

export default HardGridActionRowSelectorsSection;