export const maskString = (str: string, pattern: string): string => {
    if (str === '' || pattern === '') {
        return '';
    }

    let i = 0;
    return pattern.replace(/0/g, function (val) {
        let rv = val === '0' ? '#' : val;
        return str[i++] ?? rv;
    });
}

export const isMaskValueValid = (val: string | null, pattern: string): boolean => {
    if (val === null || val.length <= 0) {
        return false;
    }

    return val.length === pattern.replace(/\D/g, '').length;
}