import {useContext} from "react";
import {GridPanelLayoutContext} from "../utils";
import {IGridFilterPanelLayoutContext} from "../interfaces/IGridFilterPanelLayoutContext";

export const useGridFilterPanelLayoutContext = () => {
    const context = useContext<IGridFilterPanelLayoutContext>(GridPanelLayoutContext);

    if (context === undefined) {
        throw new Error('Grid filter panel component not defined as a parent');
    }

    return context;
}