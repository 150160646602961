import React from 'react';
import {GridCellProps} from "@progress/kendo-react-grid";
import cl from './EditCellRender.module.css';
import {ColumnModel} from "../../types/ColumnModel";
import {GRID_SELECTED_FIELD} from "../../utils/data";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";

type EditCellRenderProps = {
    originalProps: GridCellProps;
    td: React.ReactElement<HTMLTableCellElement>;
    enterEdit: (dataItem: any, field: string | undefined) => void;
    editField: string | undefined;
    allowEditingColumns: Array<ColumnModel>;
};

const EditCellRender: React.FC<EditCellRenderProps> = (props) => {
    if (props.originalProps.field === GRID_SELECTED_FIELD || props.originalProps.field === 'expanded') {
        return React.cloneElement(props.td, {...props.td.props}, props.td.props.children as any);
    }

    const dataItem = props.originalProps.dataItem;
    const cellField = props.originalProps.field;
    const inEditField = dataItem[props.editField || ""];
    const isEditable = props.allowEditingColumns.find(e => e.field === cellField) !== undefined;

    const additionalProps =
        cellField && cellField === inEditField
            ? {
                ref: (td: any) => {
                    const input = td && td.querySelector("input");
                    const activeElement = document.activeElement;

                    if (
                        !input ||
                        !activeElement ||
                        input === activeElement ||
                        !activeElement.contains(input)
                    ) {
                        return;
                    }

                    if (input.type === "checkbox") {
                        input.focus();
                    } else {
                        input.select();
                    }
                },
            }
            : {
                onDoubleClick: () => {
                    props.enterEdit(dataItem, cellField);
                },
            };

    const clonedProps: any = {...props.td.props, ...additionalProps};

    return (
        <td {...clonedProps}
            className={isEditable && cellField && cellField !== inEditField ? 'custom-field-edit' : ''}
            colSpan={1}>
            {isEditable && cellField && cellField !== inEditField
                ? <span className={!props.td.props.children ? cl.editCell : cl.cell}>
                    {!props.td.props.children
                        ? <FontAwesomeIcon icon={faPen}/>
                        : <>{props.td.props.children}</>}
                </span>
                : <span className={cl.cell}>
                    {props.td.props.children as any}
                </span>
            }
        </td>
    );
};

export default EditCellRender;