import React, {useState} from 'react';
import {IUser} from "../../../../../../app/interfaces/user/IUser";
import {
    ISelfBillingAddExpenseGetAction
} from "../../../../../../app/interfaces/selfBilling/ISelfBillingAddExpenseGetAction";
import {
    ISelfBillingAddExpensePostAction
} from "../../../../../../app/interfaces/selfBilling/ISelfBillingAddExpensePostAction";
import {useTranslation} from "react-i18next";
import {SelectModel} from "../../../../../../app/types/SelectModel";
import {calculateRate, getExpenseTypes, getVatNote} from "../../../form/SelfBillingForm/utils";
import {useFormik} from "formik";
import * as Yup from "yup";
import cl from './AddExpenseForm.module.css';
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins, faPen} from "@fortawesome/free-solid-svg-icons";
import NumericInput from "../../../../../../components/ui/Input/NumericInput";
import AddExpenseFormCurrencySelector from "./AddExpenseFormCurrencySelector/AddExpenseFormCurrencySelector";
import Textarea from "../../../../../../components/ui/Textarea/Textarea";
import AddExpenseFormContractSelector from "./AddExpenseFormContractSelector/AddExpenseFormContractSelector";
import AddExpenseFormServiceProviderSelector
    from "./AddExpenseFormServiceProviderSelector/AddExpenseFormServiceProviderSelector";

type AddExpenseFormForwarderProps = {
    disabled: boolean;
    user?: IUser;
    model: ISelfBillingAddExpenseGetAction;
    onSubmit: (values: ISelfBillingAddExpensePostAction) => Promise<void>;
    setIsSubmitDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddExpenseFormForwarder: React.FC<AddExpenseFormForwarderProps> = ({
                                                                             model,
                                                                             onSubmit,
                                                                             disabled,
                                                                             user,
                                                                             setIsSubmitDisabled
                                                                         }) => {
    const {t} = useTranslation();

    const [expenseTypes] = useState<Array<SelectModel>>(() => {
        return getExpenseTypes().map(type => {
            return {
                ...type,
                description: t(type.description ?? ''),
                text: t(type.text)
            }
        })
    });

    const form = useFormik<ISelfBillingAddExpensePostAction>({
        initialValues: {
            expenseType: expenseTypes[0],
            bet: null,
            currency: 'EUR',
            isIncorrectVatNotesShown: false,
            rate: null,
            incorrectVatNotes: null,
            categoryModel: {} as any,
            cmrFile: null,
            notes: null,
            contractId: model.selectedContractId,
            serviceProvider: model.initialServiceProvider,
            serviceProviderId: model.initialServiceProviderId
        },
        validationSchema: Yup.object().shape({
            bet: Yup.string().nullable()
                .required(t("shared.errors.required-error").replace('{0}', t("self-billing.labels.your-rate"))),
            currency: Yup.string().nullable()
                .required(t("shared.errors.required-error").replace('{0}', 'Currency')),
            contractId: Yup.string().nullable()
                .required(t("shared.errors.required-error").replace('{0}', t("offer.grid.rate-offering"))),
            serviceProviderId: Yup.string().nullable()
                .required(t("shared.errors.required-error").replace('{0}', t("quote.bet.service-provider")))
        }),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values, actions) => {
            actions.setSubmitting(true);

            await onSubmit(values);

            actions.setSubmitting(false);
        }
    });

    let vatNote = getVatNote(model.vatNote);
    let hasVatNote = model.vat !== null && model.vatNote !== null && model.vatNote !== '';

    return (
        <form id={'add-expense-form'}
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div className={'mgb-10 drop-down-picker'}>
                    <label className={'view-label required'}>
                        {t("self-billing.labels.expense-name")}
                    </label>

                    <DropDownList data={expenseTypes}
                                  disabled={disabled}
                                  textField="text"
                                  dataItemKey="value"
                                  value={expenseTypes.find(e => e.value === form.values.expenseType.value)}
                                  onChange={(ev) => form.setFieldValue('expenseType', ev.value)}
                    />
                </div>

                {hasVatNote &&
                    <div className={`${cl.btnContainer}`}>
                        <button className={cl.button}
                                type={'button'}
                                disabled={disabled}
                                onClick={() => form.setFieldValue('isIncorrectVatNotesShown', !form.values.isIncorrectVatNotesShown)}>
                            <FontAwesomeIcon icon={faPen}/>
                            <div>
                                {t("self-billing.btn.invalid-vat")}
                            </div>
                        </button>
                    </div>
                }

                <div className={`mgb-10`}>
                    <label className={'view-label required'}>
                        {model.revertVat
                            ? t("self-billing.labels.sum-with-vat")
                            : t("self-billing.labels.rate")
                        }
                    </label>

                    <div className={`${hasVatNote ? cl.vatNoteContainer : ''}`}>
                        <div>
                            <div style={{display: 'flex', gap: '5px'}}>
                                <div className={'col-sm-2 numeric-text-box'} style={{width: '60%'}}>
                                    <NumericInput disabled={disabled}
                                                  icon={faCoins}
                                                  placeholder={t("self-billing.labels.your-rate")}
                                                  spinners={false}
                                                  name={'bet'}
                                                  format={'n2'}
                                                  value={form.values.bet && form.values.bet !== ''
                                                      ? Number(form.values.bet.replace(/,/g, '.').replace(/\s+/g, ""))
                                                      : null}
                                                  min={0}
                                                  max={100000000}
                                                  onChange={async (ev) => {
                                                      if (ev.value !== null && ev.value !== 0) {
                                                          await form.setFieldValue('bet', ev.value.toString());
                                                      } else {
                                                          await form.setFieldValue('bet', null);
                                                      }

                                                      await form.setFieldValue('rate', calculateRate(ev.value?.toString() ?? null, model.vat, model.revertVat));
                                                  }}
                                    />
                                </div>

                                <div className={'col-sm-2 drop-down-picker'} style={{width: '40%'}}>
                                    <AddExpenseFormCurrencySelector disabled={disabled}
                                                                    user={user}
                                                                    currency={form.values.currency}
                                                                    onChange={(value: string | null) => {
                                                                        form.setFieldValue('currency', value)
                                                                    }}/>
                                </div>
                            </div>
                        </div>

                        {hasVatNote && model.vat !== null &&
                            <div className={cl.vatContainer}>
                                <div>
                                    <label className={'view-label'}>
                                        {t("self-billing.labels.vat")}:
                                    </label>

                                    <div className={'view-label'}>
                                        {`${model.vat.toFixed(2).toString()}%`}
                                    </div>

                                    {vatNote !== null &&
                                        <div className={'view-label'}>
                                            {`(${t(vatNote)})`}
                                        </div>
                                    }
                                </div>

                                <div>
                                    <label className={'view-label'}>
                                        {model.revertVat
                                            ? t("self-billing.labels.rate")
                                            : t("self-billing.labels.sum-with-vat")
                                        }
                                    </label>

                                    <div className={'view-label'}>
                                        {form.values.rate
                                            ? `${form.values.rate} ${form.values.currency}`
                                            : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    {form.errors.bet &&
                        <div className={'error-label'}>
                            {form.errors.bet}
                        </div>
                    }

                    {form.errors.currency &&
                        <div className={'error-label'}>
                            {form.errors.currency}
                        </div>
                    }
                </div>

                {hasVatNote && form.values.isIncorrectVatNotesShown &&
                    <div className={'mgb-10'}>
                        <label className={'view-label'}>
                            {t("self-billing.labels.incorrect-vat-notes")}
                        </label>

                        <Textarea id={'change-cost-invalid-vat-notes'}
                                  name={'incorrectVatNotes'}
                                  placeholder={t("self-billing.labels.incorrect-vat-notes")}
                                  disabled={form.isSubmitting}
                                  value={form.values.incorrectVatNotes ?? ''}
                                  autoComplete={'off'}
                                  style={{minHeight: '70px', resize: 'vertical', maxHeight: '100px'}}
                                  onChange={form.handleChange}/>
                    </div>
                }

                <div className={cl.separator}></div>

                <div className={'mgb-10 combobox-picker'}>
                    <label className={'view-label required'}>
                        {t("quote.bet.service-provider")}
                    </label>

                    <AddExpenseFormServiceProviderSelector disabled={disabled}
                                                           value={form.values.serviceProviderId}
                                                           text={form.values.serviceProvider}
                                                           onChange={(val, text) => {
                                                               form.setFieldValue('serviceProviderId', val);
                                                               form.setFieldValue('serviceProvider', text);
                                                           }}/>

                    {form.errors.serviceProviderId &&
                        <div className={'error-label'}>
                            {form.errors.serviceProviderId}
                        </div>
                    }
                </div>

                <div className={'mgb-10'}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <label className={'view-label required'}>
                            {t("offer.grid.rate-offering")}
                        </label>

                        {form.values.contractId === null || form.values.contractId === ''
                            ? <div className={'error-label'}>
                                {t("shared.errors.required-error").replace('{0}', t("offer.grid.rate-offering"))}
                            </div>
                            : null
                        }
                    </div>


                    <AddExpenseFormContractSelector shipmentGid={model.shipmentGid}
                                                    value={form.values.contractId}
                                                    serviceProviderId={form.values.serviceProviderId}
                                                    onChange={(contractId) => {
                                                        form.setFieldValue('contractId', contractId);

                                                        setIsSubmitDisabled(contractId === null || contractId === '');
                                                    }}/>
                </div>

                <div className={'mgb-10'}>
                    <label className={'view-label'}>
                        {t("self-billing.labels.notes")}
                    </label>

                    <Textarea id={'add-cmr-notes'}
                              name={'notes'}
                              placeholder={t("self-billing.labels.notes")}
                              disabled={form.isSubmitting}
                              value={form.values.notes ?? ''}
                              autoComplete={'off'}
                              style={{minHeight: '70px', resize: 'vertical', maxHeight: '100px'}}
                              onChange={form.handleChange}/>
                </div>
            </div>
        </form>
    );
};

export default AddExpenseFormForwarder;
