import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'MarkDialog',
    lines: [
        {
            x1: "15.2725",
            y1: "0.707107",
            x2: "0.706767",
            y2: "15.2728"
        },
        {
            x1: "15.2929",
            y1: "15.2732",
            x2: "0.727201",
            y2: "0.707484"
        },
    ],
    viewBox: '0 0 16 16'
};

export default icon;
