import React, {useRef, useState} from 'react';
import {IOffer} from "../../../../../../../app/interfaces/offer/IOffer";
import {useTranslation} from "react-i18next";
import OfferDetails from "../../../components/OfferDetails/OfferDetails";
import OfferCargo from "../../../components/OfferCargo/OfferCargo";
import RemarksDetails from "../../../../../../../components/shared/form/RemarksDetails/RemarksDetails";
import {IRemark} from "../../../../../../../app/interfaces/shared/IRemark";
import DeleteRemarkDialog from "../../../../actions/DeleteRemark/DeleteRemarkDialog";
import EditRemarkDialog from "../../../../actions/EditRemark/EditRemarkDialog";
import ForwarderOfferBets from "../../../components/OffersBets/ForwarderOfferBets/ForwarderOfferBets";
import OfferMap from "../../../components/OfferMap/OfferMap";
import FormCollapse from "../../../../../../../components/ui/Collapse/FormCollapse";
import Icon from "../../../../../../../assets/icon/Icon";
import cl
    from "../../../../../../authorized/Offer/form/sections/OfferDetailsSection/Forwarder/ForwarderOfferDetailsSection.module.css";
import RouteSideSection from "../../../../../../../components/shared/sections/RouteSection/RouteSideSection";

type ForwarderOfferDetailsSectionProps = {
    offer: IOffer;
};

const ForwarderOfferDetailsSection: React.FC<ForwarderOfferDetailsSectionProps> = ({
                                                                                       offer
                                                                                   }) => {
    const {t} = useTranslation();
    const selectedRemark = useRef<IRemark | null>(null);
    const [isDeleteRemarkDialogShown, setIsDeleteRemarkDialogShown] = useState<boolean>(false);
    const [isEditRemarkDialogShown, setIsEditRemarkDialogShown] = useState<boolean>(false);

    return (
        <>
            <div className={'form-layout-container'}
                 data-count={'2'}
                 data-separator={'true'}>
                <div className={'form-layout-column'}>
                    <FormCollapse title={t("offer.form.info-section")}>
                        <OfferDetails offer={offer}/>
                    </FormCollapse>

                   {/* <FormCollapse title={t("offer.form.transport-section")}>
                        <OfferTransport offer={offer}/>
                    </FormCollapse>*/}

                    <FormCollapse title={t("offer.form.cargo-section")}>
                        <OfferCargo offer={offer}/>
                    </FormCollapse>

                    <FormCollapse title={t("offer.form.remarks-section")}
                                  button={
                                      <button className={cl.button}
                                              onClick={(ev) => {
                                                  ev.stopPropagation();
                                                  ev.preventDefault();

                                                  selectedRemark.current = null;

                                                  setIsEditRemarkDialogShown(prev => !prev);
                                              }}>
                                          <Icon icon={"faPlus"}/>
                                          {t("shared.btn.add-remark")}
                                      </button>

                                  }>
                        {selectedRemark.current !== null &&
                            <DeleteRemarkDialog id={offer.orderReleaseGid}
                                                code={selectedRemark.current?.code ?? ''}
                                                seq={selectedRemark.current?.seq ?? ''}
                                                show={isDeleteRemarkDialogShown}
                                                setShow={setIsDeleteRemarkDialogShown}/>
                        }

                        <EditRemarkDialog id={offer.orderReleaseGid}
                                          code={selectedRemark.current?.code ?? null}
                                          seq={selectedRemark.current?.seq ?? null}
                                          show={isEditRemarkDialogShown}
                                          setShow={setIsEditRemarkDialogShown}/>

                        <RemarksDetails isEditingAllowed={true}
                                        remarks={offer.newRemarks}
                                        onEditButtonClick={(item) => {
                                            selectedRemark.current = item;

                                            setIsEditRemarkDialogShown(prev => !prev);
                                        }}
                                        onRemoveButtonClick={(item) => {
                                            selectedRemark.current = item;

                                            setIsDeleteRemarkDialogShown(prev => !prev);
                                        }}/>
                    </FormCollapse>
                </div>

                <div className={'form-layout-column-separator'}></div>

                <div className={'form-layout-column'}>
                    <FormCollapse
                        title={offer.legGid !== null && offer.legGid !== '' && offer.tl !== null && offer.tl !== ''
                            ? `${t("offer.form.customer-full-route")} ${offer.tl}`
                            : `${t("offer.form.route-section")}`
                        }>
                        <div className={'details-box-default-container'}>
                            <RouteSideSection model={{
                                orderType: offer.orderType,
                                orderReleaseGid: offer.orderReleaseGid,
                                hasShipment: false,
                                earlyPickupDateText: offer.earlyPickupDateText,
                                earlyPickupDate: offer.earlyPickupDate ?? null,
                                earlyDeliveryDate: offer.earlyDeliveryDate ?? null,
                                lateDeliveryDateText: offer.lateDeliveryDateText,
                                lateDeliveryDate: offer.lateDeliveryDate,
                                latePickupDate: offer.latePickupDate ?? null,
                                shipmentGid: null,
                                stopPoints: offer.stopPoints ?? [],
                                sourceCountry: offer.sourceCountry,
                                sourceCity: offer.sourceCity,
                                sourceCountryCode2: offer.sourceCountryCode2,
                                sourceProvinceCode: offer.sourceProvinceCode,
                                sourceProvinceDesc: offer.sourceProvinceDesc,
                                destinationCountry: offer.destinationCountry,
                                destinationCity: offer.destinationCity,
                                destinationCountryCode2: offer.destinationCountryCode2,
                                destinationProvinceCode: offer.destinationProvinceCode,
                                destinationProvinceDesc: offer.destinationProvinceDesc
                            }}/>
                        </div>
                    </FormCollapse>

                    {offer.bets.length > 0 &&
                        <FormCollapse title={t("offer.form.bets-section")}>
                            <ForwarderOfferBets offer={offer}/>
                        </FormCollapse>
                    }

                    {!offer.hasLegs &&
                        <FormCollapse title={t("offer.form.available-transport-section")}>
                            <OfferMap offer={offer}/>
                        </FormCollapse>
                    }
                </div>
            </div>
        </>
    );
};

export default ForwarderOfferDetailsSection;
