import React from 'react';
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import {useTranslation} from "react-i18next";
import BorderCrossing from "./BorderCrossing";

type BorderCrossingDialogProps = {
    id: string;
    sId: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const BorderCrossingDialog: React.FC<BorderCrossingDialogProps> = ({
                                                                       id,
                                                                       sId,
                                                                       show,
                                                                       setShow
                                                                   }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'border-crossing-add-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shipment.grid.bc-notes-label")}>
            <BorderCrossing id={id} sId={sId} close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default BorderCrossingDialog;