import React from 'react';
import {useTranslation} from "react-i18next";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

const SendContractFormSkeletonLoader: React.FC = () => {
    const {t} = useTranslation();

    return (
        <SkeletonTheme baseColor={'#cad3da'} highlightColor={'#eaeff3'}>
            <div className={'pd-10'}>
                <div className={'pd-10'}
                     style={{
                         backgroundColor: 'var(--main-bright-gray-color)',
                         display: 'grid',
                         gridTemplateColumns: '450px calc(100% - 450px)',
                         gridGap: '10px',
                         height: '670px'
                     }}>
                    <div>
                        <div className={'view-row mgb-20'}
                             style={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 gap: '10px'
                             }}>
                            <label className={'view-label'}>
                                {t("offer.form.route-section")}
                            </label>

                            <div style={{display: 'flex', gap: '5px'}}>
                                <Skeleton height={'19px'}
                                          style={{
                                              borderRadius: '2px'
                                          }}
                                          width={'27px'}/>
                                &ndash;
                                <Skeleton height={'19px'}
                                          style={{
                                              borderRadius: '2px'
                                          }}
                                          width={'27px'}/>
                            </div>
                        </div>

                        <div className={'view-row mgb-20'}>
                            <label className={'view-label required'}>
                                {t("offer.form.current-offer-supplier-manager")}
                            </label>

                            <Skeleton height={'30px'}
                                      style={{
                                          borderRadius: '2px'
                                      }}
                                      width={'100%'}/>
                        </div>

                        <div className={'view-row mgb-20'}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px'
                            }}>
                                <Skeleton height={'12px'}
                                          style={{
                                              borderRadius: '50%'
                                          }}
                                          width={'12px'}/>
                                <label className={'view-label'}
                                       style={{
                                           color: '#4a4a4a'
                                       }}>
                                    {t("offer.form.send-contract-as-contract")}
                                </label>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px'
                            }}>
                                <Skeleton height={'12px'}
                                          style={{
                                              borderRadius: '50%'
                                          }}
                                          width={'12px'}/>
                                <label className={'view-label'}
                                       style={{
                                           color: '#4a4a4a'
                                       }}>
                                    {t("offer.form.send-contract-to-selected-service-provider")}
                                </label>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px'
                            }}>
                                <Skeleton height={'12px'}
                                          style={{
                                              borderRadius: '50%'
                                          }}
                                          width={'12px'}/>
                                <label className={'view-label'}
                                       style={{
                                           color: '#4a4a4a'
                                       }}>
                                    {t("offer.form.send-contract-to-all-service-providers")}
                                </label>
                            </div>
                        </div>

                        <div className={'view-row mgb-20'}>
                            <label className={'view-label required'}>
                                {t("offer.form.supplier")}
                            </label>

                            <Skeleton height={'30px'}
                                      style={{
                                          borderRadius: '2px'
                                      }}
                                      width={'100%'}/>
                        </div>

                        <div className={`view-row mgb-20`}>
                            <label className={'view-label required'}>
                                {t("offer.grid.auction-bet")}
                            </label>
                            <div style={{
                                display: 'flex',
                                gap: '10px'
                            }}>
                                <div className={'col-sm-2'} style={{width: '60%'}}>
                                    <Skeleton height={'30px'}
                                              style={{
                                                  borderRadius: '2px'
                                              }}
                                              width={'100%'}/>
                                </div>
                                <div className={'col-sm-2'} style={{width: '40%'}}>
                                    <Skeleton height={'30px'}
                                              style={{
                                                  borderRadius: '2px'
                                              }}
                                              width={'100%'}/>
                                </div>
                            </div>
                        </div>

                        <div className={'view-row mgb-20'}>
                            <label className={'view-label required'}>
                                {t("offer.grid.till-date")}
                            </label>

                            <Skeleton height={'30px'}
                                      style={{
                                          borderRadius: '2px'
                                      }}
                                      width={'100%'}/>
                        </div>

                        <div className={'view-row mgb-20'}>
                            <label className={'view-label'}>
                                {t("offer.grid.notes")}
                            </label>

                            <Skeleton height={'70px'}
                                      style={{
                                          borderRadius: '2px'
                                      }}
                                      width={'100%'}/>
                        </div>

                        <div className={'view-row mgb-5'}
                             style={{
                                 textAlign: 'center'
                             }}>
                            <Skeleton height={'31.5px'}
                                      style={{
                                          borderRadius: '5px'
                                      }}
                                      width={'70%'}/>
                        </div>

                        <div className={'view-row mgb-5'}
                             style={{
                                 textAlign: 'center'
                             }}>
                            <Skeleton height={'31.5px'}
                                      style={{
                                          borderRadius: '5px'
                                      }}
                                      width={'70%'}/>
                        </div>
                    </div>
                    <div>
                        <div className={'view-row mgb-10'}
                             style={{display: 'flex', gap: '5px'}}>
                            <div style={{
                                width: '48%'
                            }}>
                                <label className={'view-label required'}>
                                    {t("offer.form.early-pickup-date")}
                                </label>

                                <Skeleton height={'30px'}
                                          style={{
                                              borderRadius: '2px'
                                          }}
                                          width={'100%'}/>
                            </div>
                            <div style={{marginTop: '19px'}}>
                                &ndash;
                            </div>
                            <div style={{
                                width: '48%'
                            }}>
                                <label className={'view-label'}>
                                    {t("offer.form.late-pickup-date")}
                                </label>

                                <Skeleton height={'30px'}
                                          style={{
                                              borderRadius: '2px'
                                          }}
                                          width={'100%'}/>
                            </div>
                        </div>

                        <div className={'view-row mgb-20'}
                             style={{display: 'flex', gap: '5px'}}>
                            <div style={{
                                width: '48%'
                            }}>
                                <label className={'view-label'}>
                                    {t("offer.form.early-delivery-date")}
                                </label>

                                <Skeleton height={'30px'}
                                          style={{
                                              borderRadius: '2px'
                                          }}
                                          width={'100%'}/>
                            </div>
                            <div style={{marginTop: '19px'}}>
                                &ndash;
                            </div>
                            <div style={{
                                width: '48%'
                            }}>
                                <label className={'view-label'}>
                                    {t("offer.form.late-delivery-date")}
                                </label>

                                <Skeleton height={'30px'}
                                          style={{
                                              borderRadius: '2px'
                                          }}
                                          width={'100%'}/>
                            </div>
                        </div>

                        <div className={'view-row'}>
                            <label className={'view-label required'}>
                                {t("offer.grid.rate-offering")}
                            </label>

                            <Skeleton height={'420px'}
                                      style={{
                                          borderRadius: '2px'
                                      }}
                                      width={'99%'}/>
                        </div>
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    );
};

export default SendContractFormSkeletonLoader;