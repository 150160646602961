import {IOfferHeaderConfig} from "./interfaces/IOfferHeaderConfig";
import {UserRole} from "../../../../../app/enums/UserRole";

const getDefaultOfferHeaderConfig = (role: UserRole): IOfferHeaderConfig => {
    if (role === UserRole.ServiceProvider) {
        return {
            subTab: 'offer-my-offers-tab',
            isGroupByReleaseNumber: false,
            CM: false,
            TLM: false,
            SMEXA: false,
            SMFA: false,
            OM: false,
            pinnedFilter: null,
            pinnedPredefinedFilters: null,
            pinnedPredefinedFilter: null
        };
    }

    return {
        subTab: 'offer-my-offers-tab',
        isGroupByReleaseNumber: false,
        CM: false,
        TLM: false,
        SMEXA: false,
        SMFA: false,
        OM: false,
        pinnedFilter: null,
        pinnedPredefinedFilters: null,
        pinnedPredefinedFilter: null
    };
}

export default getDefaultOfferHeaderConfig;