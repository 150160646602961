import React from 'react';
import {
    IInvoiceDetailsRequestPostAction
} from "../../../../../../app/interfaces/invoice/IInvoiceDetailsRequestPostAction";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import Button, {ButtonType} from "../../../../../../components/ui/Button/Button";
import {Upload, UploadFileInfo} from "@progress/kendo-react-upload";
import FileUpload from "../../../../../../components/ui/FileUpload/FileUpload";

type InvoiceRequestDetailsFormProps = {
    invoiceGid: string;
    disabled: boolean;
    submit: (model: IInvoiceDetailsRequestPostAction) => Promise<void>;
};

const InvoiceRequestDetailsForm: React.FC<InvoiceRequestDetailsFormProps> = ({
                                                                                 submit,
                                                                                 invoiceGid,
                                                                                 disabled
                                                                             }) => {
    const [files, setFiles] = React.useState<Array<UploadFileInfo>>([]);

    const {t} = useTranslation();

    const form = useFormik<IInvoiceDetailsRequestPostAction>({
        initialValues: {
            notes: '',
            invoiceGid,
            files: null
        },
        validationSchema: Yup.object().shape({
            notes: Yup.string().required(t("shared.errors.required-error").replace('{0}', t("chat.labels.message")))
        }),
        onSubmit: async (values, actions) => {
            actions.setSubmitting(true);

            await submit(values);

            actions.setSubmitting(false);
        }
    });

    function onSaveRequest(
        files: UploadFileInfo[],
        _options: { formData: FormData; requestOptions: any },
        _onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
    ): Promise<{ uid: string }> {
        const currentFile = files[0] as UploadFileInfo;
        const uid = currentFile.uid;

        return new Promise<{ uid: string }>((resolve, reject) => {
            if (
                currentFile.validationErrors &&
                currentFile.validationErrors.length > 0
            ) {
                reject({uid: uid});
            } else {
                let file = currentFile.getRawFile!();

                form.setFieldValue('files', [file]);

                resolve({uid: uid});
            }
        });
    }

    function onRemoveRequest(files: UploadFileInfo[]): Promise<{ uid: string }> {
        const currentFile = files[0] as UploadFileInfo;
        const uid = currentFile.uid;

        return new Promise<{ uid: string }>((resolve) => {
            resolve({uid: uid});
        });
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                <form id={'invoice-request-details'}
                      onSubmit={form.handleSubmit}>
                    <div className={`pd-10`}>
                        <div className={'mgb-10'}>
                            <div className={'chat-send-message-button-container'}>
                                <div>
                               <textarea placeholder={t("chat.labels.type-message-here")}
                                         className={'chat-send-message-button-textarea'}
                                         value={form.values.notes}
                                         onChange={(ev) => form.setFieldValue('notes', ev.target.value)}
                                         disabled={disabled}
                               />
                                </div>
                            </div>
                        </div>

                        <div className={'mgb-10 file-upload'}>
                            <FileUpload id={'invoice-request-details-file-uploader'}
                                        /*multiple={false}*/
                                        disabled={disabled}
                                        onChange={(files) => {
                                            form.setFieldValue('files', files.length > 0
                                                ? [files[0].file]
                                                : null);
                                        }}
                                        restrictions={{
                                            allowedExtensions: [".jpg", ".jpeg", ".png", ".pdf", ".docx", ".doc"]
                                        }}
                            />

                            {/*<Upload batch={false}
                                    multiple={false}
                                    files={files}
                                    onAdd={(ev) => setFiles(ev.newState)}
                                    onRemove={(ev) => setFiles(ev.newState)}
                                    onProgress={(ev) => setFiles(ev.newState)}
                                    onStatusChange={(ev) => setFiles(ev.newState)}
                                    saveUrl={onSaveRequest}
                                    removeUrl={onRemoveRequest}
                                    restrictions={{
                                        allowedExtensions: [".jpg", ".jpeg", ".png", ".pdf", ".docx", ".doc"],
                                    }}
                            />*/}
                        </div>
                    </div>
                </form>
            </div>

            <div className={'dialog-default-buttons-container'}>
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled || !form.values.notes || form.values.notes === ''}
                        form='invoice-request-details'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.labels.send-message")}
                </Button>
            </div>
        </div>
    );
};

export default InvoiceRequestDetailsForm;