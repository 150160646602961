import areCookiesEnabled from "./sources/cookiesEnabled";
import getChrome from "./sources/chrome";
import getVendor from "./sources/vendor";
import getEmptyEvalLength from "./sources/emptyEvalLenght";
import getProductSub from "./sources/productSub";
import getPluginsSupport from "./sources/pluginsSupport";
import getAudioFingerprint from "./sources/audio";
import getFonts from "./sources/fonts";
import getCanvasFingerprint from "./sources/canvas";
import getPlugins from "./sources/plugins";
import getPlatform from "./sources/platform";
import getCpuClass from "./sources/cpuClass";
import getOpenDatabase from "./sources/openDatabase";
import getIndexedDb from "./sources/indexedDb";
import getLocalStorage from "./sources/localStorage";
import getSessionStorage from "./sources/sessionStorage";
import getTimezone from "./sources/timezone";
import getTimezoneOffset from "./sources/timezoneOffset";
import getHardwareConcurrency from "./sources/hardwareConcurrency";
import getScreenResolution from "./sources/screenResolution";
import getDeviceMemory from "./sources/deviceMemory";
import getColorDepth from "./sources/colorDepth";
import getLanguages from "./sources/languages";
import getOsCpu from "./sources/osCpu";
import {excludes} from "./helpers/data";
import getDevice from "./sources/device";

export const sources = {
    osCpu: getOsCpu,
    languages: getLanguages,
    colorDepth: getColorDepth,
    deviceMemory: getDeviceMemory,
    screenResolution: getScreenResolution,
    hardwareConcurrency: getHardwareConcurrency,
    timezoneOffset: getTimezoneOffset,
    timezone: getTimezone,
    sessionStorage: getSessionStorage,
    localStorage: getLocalStorage,
    indexedDB: getIndexedDb,
    openDatabase: getOpenDatabase,
    cpuClass: getCpuClass,
    platform: getPlatform,
    plugins: getPlugins,
    canvas: getCanvasFingerprint,
    fonts: getFonts,
    audio: getAudioFingerprint,
    pluginsSupport: getPluginsSupport,
    productSub: getProductSub,
    emptyEvalLength: getEmptyEvalLength,
    vendor: getVendor,
    chrome: getChrome,
    cookiesEnabled: areCookiesEnabled,
    device: getDevice
}

export type Source<TOptions, TValue> = (options: TOptions) => Promise<TValue> | TValue

export type UnknownSources<TOptions> = Record<string, Source<TOptions, unknown>>

export type SourceValue<TSource extends Source<any, any>> = TSource extends Source<any, infer T> ? T : never

export type Component<T> = (
    | {
    value: T
    error?: undefined
}
    | {
    value?: undefined
    error: Error | { message: unknown }
}
    ) & {
    duration: number
}

export type UnknownComponents = Record<string, Component<unknown>>

export type SourcesToComponents<TSources extends UnknownSources<any>> = {
    [K in keyof TSources]: Component<SourceValue<TSources[K]>>
}

export type BuiltinComponents = SourcesToComponents<typeof sources>

export async function getComponents<
    TSourceOptions,
    TSources extends UnknownSources<TSourceOptions>,
    TExclude extends string
>(
    sources: TSources,
    sourceOptions: TSourceOptions,
    excludeSources: readonly TExclude[],
): Promise<Omit<SourcesToComponents<TSources>, TExclude>> {
    let timestamp = Date.now()
    const components = {} as Omit<SourcesToComponents<TSources>, TExclude>

    for (const sourceKey of Object.keys(sources) as Array<keyof TSources>) {
        if (!excludes(excludeSources, sourceKey)) {
            continue
        }

        let result: Pick<Component<unknown>, 'value' | 'error'>

        try {
            result = {value: await sources[sourceKey](sourceOptions)}
        } catch (error) {
            result = error && typeof error === 'object' && 'message' in error ? {error} : {error: {message: error}}
        }

        const nextTimestamp = Date.now()
        components[sourceKey] = {...result, duration: nextTimestamp - timestamp} as Component<any> // TypeScript has beaten me here
        timestamp = nextTimestamp
    }

    return components
}

export default function getBuiltinComponents(): Promise<BuiltinComponents> {
    return getComponents(sources, undefined, [])
}