import React from 'react';
import {ISendContractGetAction} from "../../../../../../app/interfaces/offer/ISendContractGetAction";
import cl from './SendContractForm.module.css';
import {IError} from "../../../../../../app/interfaces/response/IResponse";
import {BLErrorCode} from "../../../../../../app/enums/BLErrorCode";
import Alert from "../../../../../../components/ui/Alert/Alert";
import {useTranslation} from "react-i18next";
import SendContractSupplierManagerSelector
    from "./components/SendContractSupplierManagerSelector/SendContractSupplierManagerSelector";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import {ISendContractPostAction} from "../../../../../../app/interfaces/offer/ISendContractPostAction";
import {RadioButton} from "@progress/kendo-react-inputs";
import Checkbox, {CheckboxSize} from "../../../../../../components/ui/Checkbox/Checkbox";
import Textarea from "../../../../../../components/ui/Textarea/Textarea";
import moment from "moment";
import SendContractCurrencySelector from "./components/SendContractCurrencySelector/SendContractCurrencySelector";
import NumericInput from "../../../../../../components/ui/Input/NumericInput";
import {faCircleInfo, faCoins} from "@fortawesome/free-solid-svg-icons";
import SendContractServiceProviderSelector
    from "./components/SendContractServiceProviderSelector/SendContractServiceProviderSelector";
import Button, {ButtonType} from "../../../../../../components/ui/Button/Button";
import {Tooltip} from "react-tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SendContractContractSelector from "./components/SendContractContractSelector/SendContractContractSelector";
import SendContractServiceProviderGridSelector
    from "./components/SendContractServiceProviderGridSelector/SendContractServiceProviderGridSelector";
import SendContractTransEuLogin from "./components/SendContractTransEuLogin/SendContractTransEuLogin";
import {toast} from "react-toastify";
import {isAxiosError} from "axios";
import {useOfferApi} from "../../../../../../app/api/offer";
import {IUser} from "../../../../../../app/interfaces/user/IUser";
import ReactDatePicker from "../../../../../../components/ui/ReactDatePicker/ReactDatePicker/ReactDatePicker";

type SendContractFormProps = {
    model: ISendContractGetAction;
    disabled: boolean;
    errors: Array<IError>;
    save: (data: ISendContractPostAction) => Promise<void>;
    user?: IUser;
};

const alertStyle: React.CSSProperties = {
    fontWeight: 'bold',
    padding: '10px',
    textAlign: 'center',
    justifyContent: 'center',
    marginTop: '0px',
    fontSize: '12px'
};

const SendContractForm: React.FC<SendContractFormProps> = ({
                                                               model,
                                                               disabled,
                                                               save,
                                                               errors,
                                                               user
                                                           }) => {
    const {t} = useTranslation();

    const {
        checkForwarderForTransEUAccess: {
            query: checkForwarderForTransEUAccess
        },
        checkForwarderForTransEUAccessAnonymous: {
            query: checkForwarderForTransEUAccessAnonymous
        }
    } = useOfferApi();

    const form = useFormik<ISendContractPostAction>({
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
            orderReleaseGid: model.orderReleaseGid,
            forwarder: model.forwarder,
            forwarderName: model.forwarderName,
            isContractMode: model.isContractMode,
            isFFMode: model.isFFMode,
            isPersonalMode: model.isPersonalMode,
            mode: model.isFFMode ? 'isFFMode' : (model.isContractMode ? 'isContractMode' ? (model.isPersonalMode ? 'isPersonalMode' : null) : null : null),
            allowSendToTransEU: model.allowSendToTransEU,
            sendToTransEU: model.sendToTransEU,
            showSendToTransEU: model.showSendToTransEU,
            notes: model.notes,
            tillDate: model.tillDate,
            bet: model.bet,
            betValue: model.betValue,
            currency: model.currency,
            serviceProvider: model.serviceProvider,
            serviceProviderId: model.serviceProviderId,
            approvedByCustomer: model.approvedByCustomer,
            notApprovedOrTechnical: model.notApprovedOrTechnical,
            isDateChanged: model.isDateChanged,
            startPickUpDate: model.startPickUpDate,
            startPickUpTime: model.startPickUpTime,
            endPickUpDate: model.endPickUpDate,
            endPickUpTime: model.endPickUpTime,
            startDeliveryDate: model.startDeliveryDate,
            startDeliveryTime: model.startDeliveryTime,
            endDeliveryDate: model.endDeliveryDate,
            endDeliveryTime: model.endDeliveryTime,
            contractId: model.selectedContractId,
            transEuLogin: model.transEuLogin,
            transEuPass: model.transEuPass,
            autoConfirm: false,
            isLeg: model.isLeg,
            itemsSelectedInList: null,
            selectedItem: null
        },
        validationSchema: Yup.object().shape({
            forwarder: Yup.string().required(t("shared.errors.required-error").replace('{0}', t("offer.form.current-offer-supplier-manager"))),
            /*mode: Yup.string().oneOf(["isContractMode", "isPersonalMode", "isFFMode"])
                .required(t("offer.errors.send-mode-is-required")),*/
            serviceProviderId: Yup.string().nullable()
                .test('required-service-provider',
                    t("shared.errors.required-error").replace('{0}', t("offer.form.supplier")),
                    function (val) {
                        const {isPersonalMode, isContractMode} = this.parent;

                        if (isContractMode) {
                            return  true;
                        }

                        if (isPersonalMode === false) {
                            return true;
                        }

                        return !(val === null || val === undefined || val === '');
                    }
                ),
            betValue: Yup.string().nullable()
                .test('required-bet',
                    t("shared.errors.required-error").replace('{0}', t("offer.grid.auction-bet")),
                    function (val) {
                        const {isContractMode, isFFMode} = this.parent;

                        if (isContractMode) {
                            return  true;
                        }

                        if (isFFMode === true) {
                            return true;
                        }

                        return !(val === null || val === undefined || val === '');
                    }
                ),
            tillDate: Yup.string().nullable()
                .test('required-till-date',
                    t("shared.errors.required-error").replace('{0}', t("offer.grid.till-date")),
                    function (val) {
                        const {isPersonalMode, isFFMode} = this.parent;

                        if (isPersonalMode === false && isFFMode === false) {
                            return true;
                        }

                        return !(val === null || val === undefined || val === '');
                    }
                ),
            startPickUpDate: Yup.string().nullable()
                .test('required-start-pickup-date',
                    t("shared.errors.required-error").replace('{0}', t("offer.form.early-pickup-date")),
                    function (val) {
                        const {isDateChanged, startPickUpTime} = this.parent;

                        if (isDateChanged === false) {
                            return true;
                        }

                        return !(!val || val === '' || !startPickUpTime || startPickUpTime === '');
                    }
                ),
            endDeliveryDate: Yup.string().nullable()
                .test('required-end-delivery-date',
                    t("shared.errors.required-error").replace('{0}', t("offer.form.late-delivery-date")),
                    function (val) {
                        const {isDateChanged, endDeliveryTime} = this.parent;

                        if (isDateChanged === false || model.isLastLeg) {
                            return true;
                        }

                        return !(val === null || val === undefined || val === '' || endDeliveryTime === null ||
                            endDeliveryTime === undefined || endDeliveryTime === '');
                    }
                ),
            contractId: Yup.string().nullable()
                .test('required-contract-id',
                    t("shared.errors.required-error").replace('{0}', t("offer.grid.rate-offering")),
                    function (val) {
                        const {isPersonalMode} = this.parent;

                        if (isPersonalMode === false) {
                            return true;
                        }

                        return !(val === null || val === undefined || val === '');
                    }
                ),
        }),
        onSubmit: async (values: ISendContractPostAction, actions: FormikHelpers<ISendContractPostAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    const checkTransEUAccess = async (userId: string) => {
        try {
            const response = user
                ? await checkForwarderForTransEUAccess(userId)
                : await checkForwarderForTransEUAccessAnonymous(userId);

            if (response?.status === 200) {
                if (response.data.data) {
                    await form.setFieldValue('showSendToTransEU', true);
                    await form.setFieldValue('sendToTransEU', true);


                } else {
                    await form.setFieldValue('showSendToTransEU', false);
                    await form.setFieldValue('sendToTransEU', false);
                }
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(`Unable to check forwarder trans EU access: ${e.message}`);
            }
        }
    };

    return (
        <form id={'send-contract-form'}
              onSubmit={form.handleSubmit}
              className={'pd-10'}>
            <div className={`pd-10 ${cl.container}`}>
                {errors.length > 0 &&
                    errors.map(item => {
                        switch (item.errorCode) {
                            case BLErrorCode.ValidateExecutionAgentError:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.validate-execution-agent-error")}
                                    </Alert>
                                );
                            case BLErrorCode.ClientHasDelayedPayments:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.client-has-delayed-payments")}
                                    </Alert>
                                );
                            case BLErrorCode.ClientNotReal:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.client-not-real")}
                                    </Alert>
                                );
                            case BLErrorCode.ClientWorksInAdvance:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.client-works-in-advance")}
                                    </Alert>
                                );
                            case BLErrorCode.ClientHasLowCredit:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.client-has-low-credit")}
                                    </Alert>
                                );
                            case BLErrorCode.NoStandartOperation:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.client-no-standart-operation")}
                                    </Alert>
                                );
                            case BLErrorCode.OrderStatusChanged:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.client-order-status-changed")}
                                    </Alert>
                                );
                            case BLErrorCode.EarlyPickupDateMustBeEarlierCurrentDate:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("shared.errors.date-must-be-later-than-current-date").replace('{0}', t("offer.form.early-pickup-date"))}
                                    </Alert>
                                );
                            case BLErrorCode.PlannedModeNotChoose:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.change-planned-date-mode-is-required")}
                                    </Alert>
                                );
                            case BLErrorCode.LatePickupDateEearlierThanEarlyPickupDate:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("shared.errors.date-field-one-cannot-be-earlier-than-date-two")
                                            .replace('{0}', t("offer.form.late-pickup-date"))
                                            .replace('{1}', t("offer.form.early-pickup-date"))}
                                    </Alert>
                                );
                            case BLErrorCode.LateDeliveryDateEearlierThanEarlyDeliveryDate:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("shared.errors.date-field-one-cannot-be-earlier-than-date-two")
                                            .replace('{0}', t("offer.form.late-delivery-date"))
                                            .replace('{1}', t("offer.form.early-delivery-date"))}
                                    </Alert>
                                );
                            case BLErrorCode.LateDeliveryDateEearlierThanEarlyPickupDate:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("shared.errors.date-field-one-cannot-be-earlier-than-date-two")
                                            .replace('{0}', t("offer.form.late-delivery-date"))
                                            .replace('{1}', t("offer.form.early-pickup-date"))}
                                    </Alert>
                                );
                            case BLErrorCode.InvalidDate:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.send-contract-invalid-date")}
                                    </Alert>
                                );
                            case BLErrorCode.ContractExpirationDateError:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.send-contract-contract-expiration-date-error")}
                                    </Alert>
                                );
                            case BLErrorCode.MustBeGreaterZero:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.send-contract-must-be-greater-zero")}
                                    </Alert>
                                );
                            case BLErrorCode.DateMustBeEarlierCurrentDate:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.send-contract-date-must-be-earlier-current-date")}
                                    </Alert>
                                );
                            case BLErrorCode.SpNotAllowed:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.send-contract-sp-not-allowed")}
                                    </Alert>
                                );
                            case BLErrorCode.OrderNotFound:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.send-contract-order-not-found")
                                            .replace('{0}', item.description ?? '')}
                                    </Alert>
                                );
                            case BLErrorCode.SupplierNotFound:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.send-contract-supplier-not-found")}
                                    </Alert>
                                );
                            case BLErrorCode.ContractNotFound:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.send-contract-contract-not-found")}
                                    </Alert>
                                );
                            case BLErrorCode.ServiceProviderNotAllowed:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.send-contract-service-provider-not-allowed")
                                            .replace('{0}', item.description ?? '')}
                                    </Alert>
                                );
                            case BLErrorCode.LateDeliveryDateIsMissing:
                                return (
                                    <Alert type={'Error'} style={alertStyle} key={item.errorCode}>
                                        {t("offer.errors.send-contract-late-delivery-date-is-missing")}
                                    </Alert>
                                );
                        }

                        return null;
                    })
                }

                {model.alert !== null && model.alert === BLErrorCode.SpecificCustomer.toString() &&
                    <Alert type={'Error'}
                           style={alertStyle}>
                        {t("offer.errors.send-contract-specific-customer")}
                    </Alert>
                }

                {model.isConfirmed && model.text !== null && model.text !== ''
                    ? <>
                        <Alert type={'Success'}
                               style={alertStyle}>
                            {model.text}
                        </Alert>


                    </>
                    : null
                }

                {!model.isConfirmed
                    ? <>
                        {model.firstOrder &&
                            <Alert type={'YellowInfo'}
                                   style={alertStyle}>
                                {t("shared.labels.first-order")}
                            </Alert>
                        }
                    </>
                    : null
                }

                <div className={cl.columns}>
                    <div>
                        {model.tl !== null && model.tl !== '' &&
                            <div className={`mgb-10 ${cl.route}`}>
                                <label className={'view-label'}>
                                    {t('offer.form.route-section')}
                                </label>

                                <div>
                                    <img
                                        src={`/images/flags/${model.tl.split('-')[0].replaceAll(/\s/g, '').toUpperCase()}.png`}
                                        alt={model.tl.split('-')[0].replaceAll(/\s/g, '').toUpperCase()}/>
                                    <div className={cl.routeDash}>
                                        &ndash;
                                    </div>
                                    <img
                                        src={`/images/flags/${model.tl.split('-')[1].replaceAll(/\s/g, '').toUpperCase()}.png`}
                                        alt={model.tl.split('-')[1].replaceAll(/\s/g, '').toUpperCase()}/>
                                </div>
                            </div>
                        }
                    </div>
                    <div>
                        {!form.values.isDateChanged &&
                            <div className={`view-row mgb-10 ${cl.changeTypeRadioContainer}`}>
                                <Button buttonType={ButtonType.Third}
                                        onClick={() => {
                                            form.setFieldValue('isDateChanged', true);
                                        }}
                                        style={{minWidth: '150px'}}>
                                    <>

                                        {t("shared.btn.change-dates")}
                                    </>
                                </Button>
                            </div>
                        }

                        {form.values.isDateChanged && !model.isMiddleLeg &&
                            <div className={'view-row mgb-10'}>
                                <div className={`radio-buttons-container ${cl.changeTypeRadioContainer}`}>
                                    <div>
                                        <RadioButton name="changeDate"
                                                     value="1"
                                                     size={'small'}
                                                     checked={form.values.approvedByCustomer}
                                                     label={t("offer.form.change-date-approved-by-customer-mode")}
                                                     onChange={() => {
                                                         form.setFieldValue('mode', "1");
                                                         if (!form.values.approvedByCustomer) {
                                                             form.setFieldValue('approvedByCustomer', true);
                                                         }
                                                         form.setFieldValue('notApprovedOrTechnical', false);
                                                     }}
                                        />
                                        <Tooltip id={'approved-by-customer-mode'}
                                                 style={{maxWidth: '250px', zIndex: '1002', fontSize: '11px'}}
                                                 place={'right'}/>
                                        <FontAwesomeIcon data-tooltip-id={'approved-by-customer-mode'}
                                                         data-tooltip-content={t("offer.form.change-date-approved-by-customer-mode-tooltip")}
                                                         icon={faCircleInfo}
                                                         className={cl.tooltip}/>
                                    </div>
                                    <div>
                                        <RadioButton name="changeDate"
                                                     value="0"
                                                     size={'small'}
                                                     checked={form.values.notApprovedOrTechnical}
                                                     label={t("offer.form.change-date-not-approved-or-technical-mode")}
                                                     onChange={() => {
                                                         form.setFieldValue('mode', "0");
                                                         if (!form.values.notApprovedOrTechnical) {
                                                             form.setFieldValue('notApprovedOrTechnical', true);
                                                         }
                                                         form.setFieldValue('approvedByCustomer', false);
                                                     }}
                                        />
                                        <Tooltip id={'technical-mode'}
                                                 style={{maxWidth: '250px', zIndex: '1002', fontSize: '11px'}}
                                                 place={'right'}/>
                                        <FontAwesomeIcon data-tooltip-id={'technical-mode'}
                                                         data-tooltip-content={t("offer.form.change-date-not-approved-or-technical-mode-tooltip")}
                                                         icon={faCircleInfo}
                                                         className={cl.tooltip}/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className={cl.columns} style={{flex: '1'}}>
                    <div>
                        <div className={'view-row mgb-20'}>
                            <label className={'view-label required'}>
                                {t("offer.form.current-offer-supplier-manager")}
                            </label>

                            <SendContractSupplierManagerSelector user={user}
                                                                 disabled={disabled}
                                                                 onChange={(val) => {
                                                                     form.setFieldValue('forwarder', val?.value ?? null);
                                                                     form.setFieldValue('forwarderName', val?.text ?? null);

                                                                     if (val === undefined || val === null || val.value === '') {
                                                                         form.setFieldValue('showSendToTransEU', false);
                                                                         form.setFieldValue('sendToTransEU', false);
                                                                     } else if (form.values.isFFMode) {
                                                                         (async () => {
                                                                             await checkTransEUAccess(val.value);
                                                                         })();
                                                                     }
                                                                 }}
                                                                 value={form.values.forwarder}/>

                            {form.errors.forwarder &&
                                <div className={'error-label'}>
                                    {form.errors.forwarder}
                                </div>
                            }
                        </div>

                        <div className={`view-row mgb-20`}>
                            <div>
                                {model.isContractMode &&
                                    <div className={cl.radioRow}>
                                        <RadioButton name={`mode`}
                                                     value={`isContractMode`}
                                                     size={'small'}
                                                     checked={form.values.isContractMode}
                                                     onChange={() => {
                                                         form.setFieldValue('mode', 'isContractMode');

                                                         form.setFieldValue('serviceProviderId', null);
                                                         form.setFieldValue('serviceProvider', null);
                                                         form.setFieldValue('contractId', null);

                                                         form.setFieldValue('isContractMode', true);
                                                         form.setFieldValue('isPersonalMode', false);
                                                         form.setFieldValue('isFFMode', false);
                                                     }}
                                        />

                                        <label className={'view-label'}>
                                            {t("offer.form.send-contract-as-contract")}
                                        </label>
                                    </div>
                                }

                                <div className={cl.radioRow}>
                                    <RadioButton name={`mode`}
                                                 value={`isPersonalMode`}
                                                 size={'small'}
                                                 checked={form.values.isPersonalMode}
                                                 onChange={() => {
                                                     form.setFieldValue('mode', 'isPersonalMode');

                                                     form.setFieldValue('serviceProviderId', null);
                                                     form.setFieldValue('serviceProvider', null);
                                                     form.setFieldValue('contractId', null);

                                                     form.setFieldValue('isPersonalMode', true);
                                                     form.setFieldValue('isFFMode', false);
                                                     form.setFieldValue('isContractMode', false);
                                                 }}
                                    />
                                    <label className={'view-label'}>
                                        {t("offer.form.send-contract-to-selected-service-provider")}
                                    </label>
                                </div>

                                <div className={cl.radioRow}>
                                    <RadioButton name={`mode`}
                                                 value={`isFFMode`}
                                                 size={'small'}
                                                 checked={form.values.isFFMode}
                                                 onChange={() => {
                                                     form.setFieldValue('mode', 'isFFMode');

                                                     form.setFieldValue('serviceProviderId', null);
                                                     form.setFieldValue('serviceProvider', null);
                                                     form.setFieldValue('contractId', null);

                                                     form.setFieldValue('isFFMode', true);
                                                     form.setFieldValue('isPersonalMode', false);
                                                     form.setFieldValue('isContractMode', false);

                                                     (async () => {
                                                         if (form.values.forwarder !== undefined && form.values.forwarder !== null) {
                                                             await checkTransEUAccess(form.values.forwarder);
                                                         }
                                                     })();
                                                 }}
                                    />

                                    <label className={'view-label'}>
                                        {t("offer.form.send-contract-to-all-service-providers")}
                                    </label>
                                </div>
                            </div>

                            {form.values.isFFMode && form.values.allowSendToTransEU && form.values.showSendToTransEU &&
                                <Checkbox checked={form.values.sendToTransEU}
                                          size={CheckboxSize.Small}
                                          label={t("offer.form.send-to-trans-eu")}
                                          change={(val) => {
                                              form.setFieldValue('sendToTransEU', val);
                                          }}
                                          disabled={disabled}/>
                            }
                        </div>

                        {form.values.isPersonalMode || form.values.isFFMode
                            ? <>
                                {form.values.isPersonalMode &&
                                    <div className={`view-row combobox-picker mgb-10`}>
                                        <label className={'view-label required'}>
                                            {t("offer.form.supplier")}
                                        </label>
                                        <SendContractServiceProviderSelector user={user}
                                                                             disabled={disabled}
                                                                             value={form.values.serviceProviderId}
                                                                             text={form.values.serviceProvider}
                                                                             onChange={(val, text) => {
                                                                                 form.setFieldValue('serviceProviderId', val);
                                                                                 form.setFieldValue('serviceProvider', text);
                                                                             }}/>

                                        {!form.values.serviceProviderId && form.errors.serviceProviderId &&
                                            <div className={'error-label'}>
                                                {form.errors.serviceProviderId}
                                            </div>
                                        }
                                    </div>
                                }

                                <div className={`view-row mgb-10`}>
                                    <label className={`view-label ${form.values.isPersonalMode ? 'required' : ''}`}>
                                        {t("offer.grid.auction-bet")}
                                    </label>

                                    <div style={{display: 'flex', gap: '5px'}}>
                                        <div className={'col-sm-2 numeric-text-box'} style={{width: '60%'}}>
                                            <NumericInput disabled={disabled}
                                                          icon={faCoins}
                                                          placeholder={t("offer.grid.auction-bet")}
                                                          spinners={false}
                                                          name={'betValue'}
                                                          format={'n2'}
                                                          value={form.values.betValue ? form.values.betValue : null}
                                                          min={0}
                                                          max={100000000}
                                                          onChange={async (ev) => {
                                                              await form.setFieldValue('betValue', ev.value);
                                                          }}
                                            />
                                        </div>
                                        <div className={'col-sm-2 drop-down-picker'} style={{width: '40%'}}>
                                            <SendContractCurrencySelector disabled={disabled}
                                                                          user={user}
                                                                          currency={form.values.currency}
                                                                          onChange={(value: string | null) => {
                                                                              form.setFieldValue('currency', value)
                                                                          }}/>
                                        </div>
                                    </div>

                                    {form.errors.betValue &&
                                        <div className={'error-label'}>
                                            {form.errors.betValue}
                                        </div>
                                    }

                                    {model.costBrutto !== null && model.costBrutto !== '' &&
                                        <div style={{marginTop: '5px'}}>
                                            <label className={'view-label'}>
                                                <>
                                                    {t("offer.grid.cost-brutto")}
                                                    <span style={{color: '#4a4a4a', marginLeft: '5px'}}>
                                                        {model.costBrutto}
                                                    </span>
                                                </>
                                            </label>
                                        </div>
                                    }
                                </div>

                                <div className={'mgb-10 picker'}>
                                    <label className={'view-label required'}>
                                        {t("offer.grid.till-date")}
                                    </label>

                                    <ReactDatePicker disabled={disabled}
                                                     size={'big'}
                                                     value={form.values.tillDate && form.values.tillDate !== ''
                                                         ? moment(form.values.tillDate, 'DD.MM.YYYY').toDate()
                                                         : null}
                                                     onChange={(val) => {
                                                         form.setFieldValue('tillDate', val ? moment(val).format('DD.MM.YYYY') : null);
                                                     }}
                                                     onOpen={() => {
                                                         if (!form.values.tillDate || form.values.tillDate === '') {
                                                             form.setFieldValue('tillDate', moment().format('DD.MM.YYYY'));
                                                         }
                                                     }}
                                                     placeholder={t("shared.labels.date")}
                                    />

                                    {/* <DatePicker format={'dd.MM.yyyy'}
                                                dateInput={DateInput}
                                                id={'send-contact-till-date'}
                                                disabled={disabled}
                                                formatPlaceholder={{
                                                    year: "yyyy",
                                                    month: "MM",
                                                    day: "dd",
                                                }}
                                                value={form.values.tillDate && form.values.tillDate !== ''
                                                    ? moment(form.values.tillDate, 'DD.MM.YYYY').toDate()
                                                    : null}
                                                onChange={(ev) => {
                                                    form.setFieldValue('tillDate', ev.target.value ? moment(ev.target.value).format('DD.MM.YYYY') : null);
                                                }}
                                                onOpen={() => {
                                                    console.log('open');
                                                    if (!form.values.tillDate || form.values.tillDate === '') {
                                                        form.setFieldValue('tillDate', moment().format('DD.MM.YYYY'));
                                                    }
                                                }}
                                                popupSettings={{animate: false}}
                                                rounded={'small'}
                                                fillMode={'outline'}
                                                placeholder={t("shared.labels.date")}
                                                adaptive={true}/>*/}

                                    {!form.values.tillDate && form.errors.tillDate &&
                                        <div className={'error-label'}>
                                            {form.errors.tillDate}
                                        </div>
                                    }
                                </div>

                                <div className={'mgb-10'}>
                                    <label className={'view-label'}>
                                        {t("offer.form.comments")}
                                    </label>
                                    <Textarea disabled={disabled}
                                              style={{minHeight: '50px', resize: 'vertical', maxHeight: '150px'}}
                                              value={form.values.notes ?? ''}
                                              placeholder={t("offer.form.comments")}
                                              onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                  form.setFieldValue('notes', ev.target.value);
                                              }}/>
                                </div>
                            </>
                            : null
                        }

                        <div className={cl.btns}>
                            {form.values.isContractMode &&
                                <Button buttonType={ButtonType.Secondary}
                                        disabled={disabled || (form.values.serviceProviderId !== null && form.values.serviceProviderId.split(';').length > 1)}
                                        style={{minWidth: '150px'}}
                                        onClick={async () => {
                                            await form.setFieldValue('autoConfirm', true);

                                            await form.submitForm();
                                        }}
                                        type={'button'}
                                        className={'confirm-button'}
                                        isLoading={disabled}>
                                    {t("shared.btn.confirm-for-carrier")}
                                </Button>
                            }

                            {form.values.isContractMode &&
                                <Button buttonType={ButtonType.Primary}
                                        disabled={disabled}
                                        style={{minWidth: '150px'}}
                                        onClick={async () => {
                                            await form.setFieldValue('autoConfirm', false);

                                            await form.submitForm();
                                        }}
                                        type={'button'}
                                        className={'confirm-button'}
                                        isLoading={disabled}>
                                    {t("shared.btn.send-to-carriers-for-confirmation")}
                                </Button>
                            }

                            {form.values.isPersonalMode &&
                                <Button buttonType={ButtonType.Secondary}
                                        disabled={disabled}
                                        style={{minWidth: '150px'}}
                                        onClick={async () => {
                                            await form.setFieldValue('autoConfirm', true);

                                            await form.submitForm();
                                        }}
                                        type={'button'}
                                        className={'confirm-button'}
                                        isLoading={disabled}>
                                    {t("shared.btn.confirm-for-carrier")}
                                </Button>
                            }

                            {form.values.isPersonalMode &&
                                <Button buttonType={ButtonType.Primary}
                                        disabled={disabled}
                                        style={{minWidth: '150px'}}
                                        onClick={async () => {
                                            await form.setFieldValue('autoConfirm', false);

                                            await form.submitForm();
                                        }}
                                        type={'button'}
                                        className={'confirm-button'}
                                        isLoading={disabled}>
                                    {t("shared.btn.send-to-carrier-for-confirmation")}
                                </Button>
                            }

                            {form.values.isFFMode &&
                                <Button buttonType={ButtonType.Primary}
                                        disabled={disabled}
                                        style={{minWidth: '150px'}}
                                        onClick={async () => {
                                            await form.setFieldValue('autoConfirm', false);

                                            await form.submitForm();
                                        }}
                                        type={'button'}
                                        className={'confirm-button'}
                                        isLoading={disabled}>
                                    {t("shared.btn.send")}
                                </Button>
                            }
                        </div>
                    </div>
                    <div>
                        <div className={'view-row mgb-10'}>
                            <div className={`${cl.datesContainer} picker`}>
                                <div>
                                    <label className={'view-label required'}>
                                        {t("offer.form.early-pickup-date")}
                                    </label>

                                    <ReactDatePicker disabled={disabled || !form.values.isDateChanged}
                                                     format={'dd.MM.yyyy HH:mm'}
                                                     size={'big'}
                                                     value={form.values.startPickUpDate !== null && form.values.startPickUpDate !== '' &&
                                                     form.values.startPickUpTime !== null && form.values.startPickUpTime !== ''
                                                         ? moment(`${form.values.startPickUpDate} ${form.values.startPickUpTime}`, 'DD.MM.YYYY HH:mm').toDate()
                                                         : null}
                                                     onChange={(val) => {
                                                         let date = val !== null
                                                             ? moment(val).format('DD.MM.YYYY')
                                                             : null;

                                                         let time = val !== null
                                                             ? moment(val).format('HH:mm')
                                                             : null;

                                                         form.setFieldValue('startPickUpDate', date);
                                                         form.setFieldValue('startPickUpTime', time);
                                                     }}
                                                     placeholder={t("shared.labels.date")}
                                    />

                                    {/* <DatePicker format={'dd.MM.yyyy'}
                                                    dateInput={DateInput}
                                                    disabled={disabled || !form.values.isDateChanged}
                                                    popup={(props) => <DatePickerPopup {...props}
                                                                                       popupAlign={{
                                                                                           vertical: 'top',
                                                                                           horizontal: 'left'
                                                                                       }}/>}
                                                    formatPlaceholder={{
                                                        year: "yyyy",
                                                        month: "MM",
                                                        day: "dd"
                                                    }}
                                                    value={form.values.startPickUpDate !== null && form.values.startPickUpDate !== ''
                                                        ? moment(form.values.startPickUpDate, 'DD.MM.YYYY').toDate()
                                                        : null}
                                                    onChange={(ev) => {
                                                        let date = ev.target.value !== null
                                                            ? moment(ev.target.value).format('DD.MM.YYYY')
                                                            : null;

                                                        form.setFieldValue('startPickUpDate', date);
                                                    }}
                                                    rounded={'small'}
                                                    fillMode={'outline'}
                                                    placeholder={t("shared.labels.date")}
                                                    adaptive={true}/>*/}
                                </div>
                                <div>
                                    <label className={'view-label'}>
                                        {t("offer.form.late-pickup-date")}
                                    </label>

                                    <ReactDatePicker disabled={disabled || !form.values.isDateChanged}
                                                     format={'dd.MM.yyyy HH:mm'}
                                                     size={'big'}
                                                     value={form.values.endPickUpDate !== null && form.values.endPickUpDate !== '' &&
                                                     form.values.endPickUpTime !== null && form.values.endPickUpTime !== ''
                                                         ? moment(`${form.values.endPickUpDate} ${form.values.endPickUpTime}`, 'DD.MM.YYYY HH:mm').toDate()
                                                         : null}
                                                     onChange={(val) => {
                                                         let date = val !== null
                                                             ? moment(val).format('DD.MM.YYYY')
                                                             : null;

                                                         let time = val !== null
                                                             ? moment(val).format('HH:mm')
                                                             : null;

                                                         form.setFieldValue('endPickUpDate', date);
                                                         form.setFieldValue('endPickUpTime', time);
                                                     }}
                                                     placeholder={t("shared.labels.date")}
                                    />

                                    {/*<DatePicker format={'dd.MM.yyyy'}
                                                    dateInput={DateInput}
                                                    disabled={disabled || !form.values.isDateChanged}
                                                    popup={(props) => <DatePickerPopup {...props}
                                                                                       popupAlign={{
                                                                                           vertical: 'top',
                                                                                           horizontal: 'left'
                                                                                       }}/>}
                                                    formatPlaceholder={{
                                                        year: "yyyy",
                                                        month: "MM",
                                                        day: "dd"
                                                    }}
                                                    value={form.values.endPickUpDate !== null && form.values.endPickUpDate !== ''
                                                        ? moment(form.values.endPickUpDate, 'DD.MM.YYYY').toDate()
                                                        : null}
                                                    onChange={(ev) => {
                                                        let date = ev.target.value !== null
                                                            ? moment(ev.target.value).format('DD.MM.YYYY')
                                                            : null;

                                                        form.setFieldValue('endPickUpDate', date);
                                                    }}
                                                    rounded={'small'}
                                                    fillMode={'outline'}
                                                    placeholder={t("shared.labels.date")}
                                                    adaptive={true}/>*/}

                                </div>
                                <div>
                                    <label className={'view-label'}>
                                        {t("offer.form.early-delivery-date")}
                                    </label>

                                    <ReactDatePicker disabled={disabled || !form.values.isDateChanged}
                                                     format={'dd.MM.yyyy HH:mm'}
                                                     size={'big'}
                                                     value={form.values.startDeliveryDate !== null && form.values.startDeliveryDate !== '' &&
                                                     form.values.startDeliveryTime !== null && form.values.startDeliveryTime !== ''
                                                         ? moment(`${form.values.startDeliveryDate} ${form.values.startDeliveryTime}`, 'DD.MM.YYYY HH:mm').toDate()
                                                         : null}
                                                     onChange={(val) => {
                                                         let date = val !== null
                                                             ? moment(val).format('DD.MM.YYYY')
                                                             : null;

                                                         let time = val !== null
                                                             ? moment(val).format('HH:mm')
                                                             : null;

                                                         form.setFieldValue('startDeliveryDate', date);
                                                         form.setFieldValue('startDeliveryTime', time);
                                                     }}
                                                     placeholder={t("shared.labels.date")}
                                    />

                                    {/*<DatePicker format={'dd.MM.yyyy'}
                                                    dateInput={DateInput}
                                                    disabled={disabled || !form.values.isDateChanged}
                                                    popup={(props) => <DatePickerPopup {...props}
                                                                                       popupAlign={{
                                                                                           vertical: 'top',
                                                                                           horizontal: 'left'
                                                                                       }}/>}
                                                    formatPlaceholder={{
                                                        year: "yyyy",
                                                        month: "MM",
                                                        day: "dd"
                                                    }}
                                                    value={form.values.startDeliveryDate !== null && form.values.startDeliveryDate !== ''
                                                        ? moment(form.values.startDeliveryDate, 'DD.MM.YYYY').toDate()
                                                        : null}
                                                    onChange={(ev) => {
                                                        let date = ev.target.value !== null
                                                            ? moment(ev.target.value).format('DD.MM.YYYY')
                                                            : null;

                                                        form.setFieldValue('startDeliveryDate', date);
                                                    }}
                                                    rounded={'small'}
                                                    fillMode={'outline'}
                                                    placeholder={t("shared.labels.date")}
                                                    adaptive={true}/>*/}
                                </div>
                                <div>
                                    <label className={`view-label ${!model.isLastLeg ? 'required' : ''}`}>
                                        {t("offer.form.late-delivery-date")}
                                    </label>

                                    <ReactDatePicker disabled={disabled || !form.values.isDateChanged}
                                                     format={'dd.MM.yyyy HH:mm'}
                                                     size={'big'}
                                                     value={form.values.endDeliveryDate !== null && form.values.endDeliveryDate !== '' &&
                                                     form.values.endDeliveryTime !== null && form.values.endDeliveryTime !== ''
                                                         ? moment(`${form.values.endDeliveryDate} ${form.values.endDeliveryTime}`, 'DD.MM.YYYY HH:mm').toDate()
                                                         : null}
                                                     onChange={(val) => {
                                                         let date = val !== null
                                                             ? moment(val).format('DD.MM.YYYY')
                                                             : null;

                                                         let time = val !== null
                                                             ? moment(val).format('HH:mm')
                                                             : null;

                                                         form.setFieldValue('endDeliveryDate', date);
                                                         form.setFieldValue('endDeliveryTime', time);
                                                     }}
                                                     placeholder={t("shared.labels.date")}
                                    />

                                    {/*<DatePicker format={'dd.MM.yyyy'}
                                                    dateInput={DateInput}
                                                    disabled={disabled || !form.values.isDateChanged}
                                                    popup={(props) => <DatePickerPopup {...props}
                                                                                       popupAlign={{
                                                                                           vertical: 'top',
                                                                                           horizontal: 'left'
                                                                                       }}/>}
                                                    formatPlaceholder={{
                                                        year: "yyyy",
                                                        month: "MM",
                                                        day: "dd"
                                                    }}
                                                    value={form.values.endDeliveryDate !== null && form.values.endDeliveryDate !== ''
                                                        ? moment(form.values.endDeliveryDate, 'DD.MM.YYYY').toDate()
                                                        : null}
                                                    onChange={(ev) => {
                                                        let date = ev.target.value !== null
                                                            ? moment(ev.target.value).format('DD.MM.YYYY')
                                                            : null;

                                                        form.setFieldValue('endDeliveryDate', date);
                                                    }}
                                                    rounded={'small'}
                                                    fillMode={'outline'}
                                                    placeholder={t("shared.labels.date")}
                                                    adaptive={true}/>*/}

                                </div>
                            </div>

                            {(!form.values.startPickUpDate || !form.values.startPickUpTime) && form.errors.startPickUpDate &&
                                <div className={'error-label'}>
                                    {form.errors.startPickUpDate}
                                </div>
                            }

                            {(!form.values.endDeliveryDate || !form.values.endDeliveryTime) && form.errors.endDeliveryDate &&
                                <div className={'error-label'}>
                                    {form.errors.endDeliveryDate}
                                </div>
                            }
                        </div>

                        {form.values.isContractMode &&
                            <div className={'view-row'} style={{flex: '1'}}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <label className={'view-label required'}>
                                        {t("offer.form.suppliers")}
                                    </label>
                                    {form.errors.serviceProviderId &&
                                        <div className={'error-label'}
                                             style={{margin: '0'}}>
                                            {t("offer.errors.suppliers-required")}
                                        </div>
                                    }
                                </div>

                                <SendContractServiceProviderGridSelector model={model}
                                                                         user={user}
                                                                         onChange={(serviceProviderId) => {
                                                                             form.setFieldValue('serviceProviderId', serviceProviderId);
                                                                         }}/>
                            </div>
                        }

                        {form.values.isPersonalMode &&
                            <div className={'view-row'} style={{height: '70%'}}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <label className={'view-label required'}>
                                        {t("offer.grid.rate-offering")}
                                    </label>
                                    {!form.values.contractId && form.errors.contractId &&
                                        <div className={'error-label'}
                                             style={{margin: '0'}}>
                                            {form.errors.contractId}
                                        </div>
                                    }
                                </div>

                                <SendContractContractSelector model={model}
                                                              user={user}
                                                              value={form.values.contractId}
                                                              serviceProviderId={form.values.serviceProviderId}
                                                              onChange={(contractId) => {
                                                                  form.setFieldValue('contractId', contractId);
                                                              }}/>
                            </div>
                        }

                        {form.values.isFFMode && form.values.allowSendToTransEU &&
                            form.values.showSendToTransEU && form.values.sendToTransEU && form.values.forwarder &&
                            <div className={'view-row'} style={{flex: '1'}}>
                                <SendContractTransEuLogin disabled={disabled}
                                                          user={user}
                                                          forwarder={form.values.forwarder}
                                                          forwarderName={form.values.forwarderName ?? ''}
                                                          login={form.values.transEuLogin}
                                                          password={form.values.transEuPass}
                                                          onLoginChange={(val) => form.setFieldValue('transEuLogin', val)}
                                                          onPasswordChange={(val) => form.setFieldValue('transEuPass', val)}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </form>
    );
};

export default SendContractForm;
