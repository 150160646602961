import {GridConfig} from "../types/GridConfig";
import {useEffect, useRef, useState} from "react";
import {useDownloadFile} from "../../../../../hooks/useDownloadFile/useDownloadFile";
import {IResponse, isResponse} from "../../../../../app/interfaces/response/IResponse";
import {isAxiosError} from "axios";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import {useDocumentApi} from "../../../../../app/api/document";
import {IExportGridField} from "../../../../../app/interfaces/shared/IExportGrid";
import {useTranslation} from "react-i18next";

type UseGridExportProps = {
    gridId: string;
    config: GridConfig | null;
    useSelectedRowToExport?: boolean;
};

type UseGridExportReturn = {
    isLoading: boolean;
    download: (fileName: string) => Promise<void>;
};

const useGridExport = (props: UseGridExportProps): UseGridExportReturn => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);

    const gridRows = useRef<Array<any> | null>(null);

    const {
        exportToDocument: {
            mutation,
            cancel,
            isLoading
        }
    } = useDocumentApi();

    useEffect(() => {
        const handleFromGridMessage = (ev: any) => {
            if (ev && ev.detail && ev.detail.action && ev.detail.action !== '') {
                switch (ev.detail.action) {
                    case 'exportDataChanged':
                        if (ev.detail.gridId !== props.gridId) {
                            return;
                        }

                        if (ev.detail.rows !== undefined && ev.detail.rows !== null && ev.detail.rows.length > 0) {
                            if (props.useSelectedRowToExport &&
                                ev.detail.selectedRows !== undefined &&
                                ev.detail.selectedRows !== null &&
                                ev.detail.selectedRows.length > 0) {
                                gridRows.current = ev.detail.selectedRows;

                                return;
                            }

                            gridRows.current = ev.detail.rows;
                        }

                        break;
                }
            }
        };

        document.addEventListener('fromGridMessage', handleFromGridMessage);

        return () => {
            cancel();

            document.removeEventListener('fromGridMessage', handleFromGridMessage);
        }
    }, []);

    const {
        download
    } = useDownloadFile({
        target: '_blank',
        getBlob: async () => {
            if (!gridRows.current || !props.config) {
                return;
            }

            setDisabled(prev => !prev);

            if (props.config.columns.length <= 0 || props.config.columns.filter(e => e.visible).length <= 0) {
                toast.error(`Cannot build the report, because 0 columns were provided.`)

                setDisabled(prev => !prev);

                return;
            }

            try {
                let d: Array<Array<IExportGridField>> = [];

                gridRows.current.forEach(row => {
                    let items: Array<IExportGridField> = [];

                    Object.keys(row).forEach(key => {
                        if (typeof row[key] !== 'object') {
                            items.push({
                                field: key,
                                value: row[key]
                            });
                        }
                    });

                    d.push(items);
                });

                if (d.length <= 0) {
                    setDisabled(prev => !prev);

                    return;
                }

                const response = await mutation({
                    columns: props.config.columns
                        .filter(e => e.visible && !e.constantlyLocked)
                        .map(item => ({
                            index: item.order ?? 0,
                            field: item.field,
                            text: t(item.title)
                        })),
                    rows: d
                });

                if (response?.status === 200 && !isResponse(response.data)) {
                    setDisabled(prev => !prev);

                    return response.data;
                }
            } catch (err) {
                if (isAxiosError(err) && err.response && err.response.data && err.response.data.type === "application/json") {
                    const textResponse = await err.response?.data?.text();

                    if (textResponse && typeof textResponse === 'string') {
                        let response = JSON.parse(textResponse) as IResponse;

                        if (response.resultCode === ResponseResultCode.NotValidData && response.field === 'params') {
                            toast.error(`The error occurred. Not valid input arguments`);

                            setDisabled(prev => !prev);

                            return;
                        }

                        if (response.resultCode === ResponseResultCode.NotFound && response.field === 'doc') {
                            toast.error(`The error occurred, while building the file`);

                            setDisabled(prev => !prev);

                            return;
                        }
                    }
                }
            }

            setDisabled(prev => !prev);
        }
    })

    return {
        isLoading: disabled || isLoading,
        download: async (fileName: string) => await download(fileName)
    };
};

export default useGridExport;