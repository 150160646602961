import React, {ReactNode, useEffect, useRef, useState} from 'react';
import useIfFirstRender from "../../../../../../../../hooks/useIsFirstRender/useIfFirstRender";
import {GridActionRowSelectOptionProps} from "./GridActionRowSelectOption/GridActionRowSelectOption";
import cl from './GridActionRowSelect.module.css';
import GridActionRowSelectPopup from "./GridActionRowSelectPopup/GridActionRowSelectPopup";
import Icon from "../../../../../../../../assets/icon/Icon";

export type GridActionRowSelectProps = {
    children: ReactNode;
    disabled: boolean;
    width: number;
    onChange?: (item: GridActionRowSelectOptionProps) => void;
}

const GridActionRowSelect: React.FC<GridActionRowSelectProps> = ({
                                                                     children,
                                                                     disabled,
                                                                     onChange,
                                                                     width
                                                                 }) => {
    const container = useRef<HTMLDivElement>(null);
    const isFirstRender = useIfFirstRender();

    const [options, setOptions] = useState<Array<GridActionRowSelectOptionProps>>([]);
    const [selectedOption, setSelectedOption] = useState<GridActionRowSelectOptionProps | null>(null);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        const opts: Array<GridActionRowSelectOptionProps> = getTabArray();

        if (opts.length > 0) {
            setOptions(opts);

            const selected = opts.find(item => item.selected);

            setSelectedOption(selected ? selected : opts[0]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isFirstRender) {
            return;
        }

        const opts: Array<GridActionRowSelectOptionProps> = getTabArray();

        if (opts.length > 0) {
            setOptions(opts);

            const selected = opts.find(item => item.selected);

            if (selected) {
                if (!selectedOption || (selectedOption.id !== selected.id) ||
                    (selectedOption.id === selected.id && selectedOption.text !== selected.text)) {
                    setSelectedOption(selected);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);

    const getTabArray = (): Array<GridActionRowSelectOptionProps> => {
        const opts: Array<GridActionRowSelectOptionProps> = [];

        React.Children.forEach(children, elem => {
            if (!React.isValidElement(elem))
                return

            opts.push({
                id: elem.props.id,
                selected: elem.props.selected,
                text: elem.props.text,
                tooltip: elem.props.tooltip,
                disabled: elem.props.disabled,
                description: elem.props.description
            });
        });

        return opts;
    }


    const onOptionClick = (data: GridActionRowSelectOptionProps) => {
        const item = options.find(item => item.id === data.id);

        if (item && selectedOption?.id !== data.id) {
            if (onChange) {
                onChange(item);
            }
        }
    }

    return (
        <div className={`${cl.select} prevent-selection transition-all-500`}
             style={{width: `${width}px`, minWidth: `${width}px`}}
             ref={container}
            /*onMouseEnter={() => {
                if (disabled) {
                    return;
                }

                if (!isOpen) {
                    setIsOpen(true);
                }
            }}
            onMouseLeave={() => {
                if (disabled) {
                    return;
                }

                if (isOpen) {
                    setIsOpen(false);
                }
            }}*/
             onClick={() => {
                 if (disabled) {
                     return;
                 }

                 setIsOpen(prev => !prev)
             }}>
            <div className={cl.input}>
                <div>
                    {selectedOption &&
                        <>
                            {selectedOption.text}
                        </>
                    }
                </div>
                <div>
                    <Icon icon={"faThinChevronDown"}
                          className={`${cl.toggle} ${isOpen ? cl.toggleOpened : ''} transition-all-500`}/>
                </div>
            </div>
            <GridActionRowSelectPopup container={container}
                                      width={width}
                                      open={isOpen}
                                      setOpen={setIsOpen}
                                      options={options}
                                      onOptionClick={onOptionClick}/>
        </div>
    );
};

export default GridActionRowSelect;
