import React, {useEffect, useState} from 'react';
import cl from './SupplierDataForm.module.css';
import {IUserData} from "../../../../../../app/interfaces/user/IUserData";
import {useUserApi} from "../../../../../../app/api/user";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import FormLoadingScreen from "../../../../../ui/loaders/FormLoadingScreen/FormLoadingScreen";
import ChatAvatar from "../../../../components/Chat/components/ChatAvatar/ChatAvatar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faMobile, faPhone} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import notRegisteredIcon from "../../../../../../assets/images/not-registed.png";
import roundTripImg from '../../../../../../assets/images/krugoreis.png';
import roundTripBlocked from '../../../../../../assets/images/krugoreis_blocked.png';

type SupplierDataFormProps = {
    ids: string;
    close: () => void;
};

const SupplierDataForm: React.FC<SupplierDataFormProps> = ({
                                                               ids,
                                                               close
                                                           }) => {
    const {t} = useTranslation();
    const [users, setUsers] = useState<Array<IUserData> | null>(null);

    const {
        getServiceProvidersData: {
            isLoading,
            cancel,
            query
        }
    } = useUserApi();

    useEffect(() => {
        if (!ids || ids === '') {
            if (close) {
                close();
            }

            return;
        }

        (async () => {
            try {
                const response = await query(ids);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setUsers(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error<string>('Unable to get forwarders data');

                    if (close) {
                        close();
                    }
                }
            }
        })();

        return () => {
            cancel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ids]);

    if (!ids || ids === '') {
        return null;
    }

    if (isLoading || users === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    const copy = async (text: string) => {
        if (navigator.clipboard != undefined) {//Chrome
            await navigator.clipboard.writeText(text);
        }
    }

    return (
        <div className={'h100 w100 pd-10'}>
            {users.map(user => {
                return (
                    <div key={user.serviceProvider}
                         className={`${cl.user} relative`}>
                        <button className={cl.copy}
                                onClick={async (ev) => {
                                    ev.stopPropagation();

                                    let text = t("shared.labels.sp-info-for-copy");

                                    text = text.replace('{0}', user.serviceProvider ?? t("shared.labels.no-data"));
                                    text = text.replace('{1}', user.address ?? t("shared.labels.no-data"));
                                    text = text.replace('{2}', user.usreouCode ?? t("shared.labels.no-data"));
                                    text = text.replace('{3}', user.phone ?? t("shared.labels.no-data"));
                                    text = text.replace('{4}', user.email ?? t("shared.labels.no-data"));

                                    await copy(text);
                                }}>
                            {t("shared.labels.copy-service-provider-data")}
                        </button>
                        <div className={cl.header}>
                            <div>
                                <ChatAvatar isOnline={false}
                                            onVacation={false}
                                            size={'Big'}/>
                            </div>
                            <div>
                                {!user.hasKpAccount &&
                                    <img src={notRegisteredIcon}
                                         className={cl.img}
                                         alt={t('shared.labels.service-provider-not-registered')}
                                         title={t('shared.labels.service-provider-not-registered')}/>
                                }

                                {user.serviceProvider}
                            </div>
                        </div>

                        <div className={cl.roundTrip}>
                            <img src={user.transportCycleMode ? roundTripImg : roundTripBlocked}
                                 title={''}
                                 alt={'round-trip'}/>

                            <span>
                                {user.transportCycleMode
                                    ? <>{t("shared.labels.service-provider-offers-round-trips")}</>
                                    : <>{t("shared.labels.service-provider-do-not-offer-round-trips")}</>}
                            </span>
                        </div>

                        {user.email &&
                            <div className={cl.row}>
                                <FontAwesomeIcon icon={faEnvelope}/>

                                <span>
                                    {user.email}
                                </span>
                            </div>
                        }

                        {user.phone &&
                            <div className={cl.row}>
                                <FontAwesomeIcon icon={faPhone}/>

                                <span>
                                    {user.phone}
                                </span>
                            </div>
                        }

                        {user.phone1 &&
                            <div className={cl.row}>
                                <FontAwesomeIcon icon={faMobile}/>

                                <span>
                                    {user.phone1}
                                </span>
                            </div>
                        }
                    </div>
                );
            })}
        </div>
    );
};

export default SupplierDataForm;