import React, {ReactNode, useState} from 'react';
import {useTranslation} from "react-i18next";
import GridFilterPanelLayoutCollapse
    from "../../../components/GridFilterPanelLayoutCollapse/GridFilterPanelLayoutCollapse";
import cl from '../../../GridFilterPanelLayout.module.css';
import GridFilterPanelLayoutSaveDialog
    from "../../../components/GridFilterPanelLayoutSaveDialog/GridFilterPanelLayoutSaveDialog";
import {isResetFilterButtonActive, isSaveFilterButtonActive} from "../../../utils";
import {LayoutConfigEntity} from "../../../../../../app/enums/LayoutConfigEntity";
import {useAppDispatch} from "../../../../../../app/store";
import {
    setFilter,
    setFilterToApply,
    setPersonalFilters, setSelectedPersonalFilter
} from "../../../../../../features/filter/filterSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClone} from "@fortawesome/free-solid-svg-icons";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {useGridFilterApi} from "../../../../../../app/api/gridFilter";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import {useGridFilterPanelLayoutContext} from "../../../hooks/useGridFilterPanelLayoutContext";
import Icon from "../../../../../../assets/icon/Icon";

type PersonalFiltersSectionProps = {
    disabled: boolean;
    children?: ReactNode;
}

const PersonalFiltersSection: React.FC<PersonalFiltersSectionProps> = ({
                                                                           children,
                                                                           disabled
                                                                       }) => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    const [isSaveDialogShown, setIsSaveDialogShown] = useState<boolean>(false);
    const [isSaveAsDialogShown, setIsSaveAsDialogShown] = useState<boolean>(false);

    const {
        filter,
        filterToApply,
        personalFilters,
        entity,
        selectedPersonalFilter,
        onClearCounterChange
    } = useGridFilterPanelLayoutContext();

    const {
        saveGridFilter: {
            mutation: saveGridFilter
        }
    } = useGridFilterApi();

    const save = async () => {
        try {
            if (selectedPersonalFilter !== null) {
                let f = {
                    ...selectedPersonalFilter,
                    value: JSON.stringify(filterToApply)
                };

                const response = await saveGridFilter(f);

                if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                    dispatch(setSelectedPersonalFilter({
                        entity: entity,
                        data: f
                    }));

                    let index = personalFilters.findIndex(e => e.id === f.id);

                    if (index !== undefined && index >= 0) {
                        let items = personalFilters.map((item) => {
                            return {...item};
                        });

                        items.splice(index, 1, f);

                        dispatch(setPersonalFilters({
                            entity: entity,
                            data: items
                        }));
                    }
                }
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(`Unable to save ${LayoutConfigEntity[entity].toString()} filter. Error: ${e.message}`);
            }
        }
    }

    const saveAs = async (name: string) => {
        try {
            let f = {
                id: 0,
                entity: entity as LayoutConfigEntity,
                name: name,
                value: JSON.stringify(filterToApply)
            };

            const response = await saveGridFilter(f);

            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                if (response.data.field) {
                    f.id = Number(response.data.field);

                    dispatch(setPersonalFilters({
                        entity: entity,
                        data: [
                            ...personalFilters,
                            f
                        ]
                    }));

                    dispatch(setSelectedPersonalFilter({
                        entity: entity,
                        data: f
                    }));
                }
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(`Unable to save new ${LayoutConfigEntity[entity].toString()} filter. Error: ${e.message}`);
            }
        }
    }

    if (!children) {
        return null;
    }

    return (
        <>
            <GridFilterPanelLayoutSaveDialog show={isSaveDialogShown}
                                             setShow={setIsSaveDialogShown}
                                             onSave={async (name: string) => {
                                                 await saveAs(name);
                                             }}/>
            <GridFilterPanelLayoutSaveDialog show={isSaveAsDialogShown}
                                             setShow={setIsSaveAsDialogShown}
                                             onSave={async (name: string) => {
                                                 await saveAs(name);
                                             }}/>
            <GridFilterPanelLayoutCollapse style={{margin: '0', padding: '15px 10px 10px 15px'}}
                                           titleUpperCase={true}
                                           title={
                                               <>
                                                   <div className={cl.flexG7}>
                                                       <div>
                                                           {t("shared.labels.filter")}
                                                       </div>
                                                       <div className={cl.flexG5}>
                                                           {selectedPersonalFilter !== null &&
                                                               <FontAwesomeIcon icon={faClone}
                                                                                title={t("shared.btn.save-as")}
                                                                                onClick={(ev) => {
                                                                                    ev.stopPropagation();

                                                                                    if (disabled) {
                                                                                        return;
                                                                                    }

                                                                                    setIsSaveAsDialogShown(prev => !prev);
                                                                                }}
                                                                                className={`${cl.collapseHeaderSaveIcon} ${disabled ? cl.disabled : ''} transition-all-500`}/>
                                                           }
                                                           {isSaveFilterButtonActive(selectedPersonalFilter, filter, filterToApply) &&
                                                               <Icon icon={"faFloppyDisk"}
                                                                     title={t("shared.btn.save")}
                                                                     onClick={async (ev) => {
                                                                         ev.stopPropagation();

                                                                         if (disabled) {
                                                                             return;
                                                                         }

                                                                         if (selectedPersonalFilter !== null) {
                                                                             await save();

                                                                             return;
                                                                         }

                                                                         setIsSaveDialogShown(prev => !prev);
                                                                     }}
                                                                     className={`${cl.collapseHeaderSaveIcon} ${disabled ? cl.disabled : ''} transition-all-500`}/>
                                                           }
                                                       </div>
                                                   </div>
                                                   <div>
                                                       <button
                                                           disabled={!isResetFilterButtonActive(filter, filterToApply)}
                                                           onClick={(ev) => {
                                                               ev.stopPropagation();

                                                               if (disabled) {
                                                                   return;
                                                               }


                                                               let val = {
                                                                   entity,
                                                                   data: null
                                                               };


                                                               dispatch(setFilter(val));
                                                               dispatch(setFilterToApply(val));

                                                               dispatch(setSelectedPersonalFilter(val));

                                                               onClearCounterChange();
                                                           }}
                                                           className={cl.resetBtn}>
                                                           {t("shared.btn.reset")}
                                                       </button>
                                                   </div>
                                               </>
                                           }>
                {children}
            </GridFilterPanelLayoutCollapse>
        </>
    );
};

export default PersonalFiltersSection;
