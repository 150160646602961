import React from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {useParams} from "react-router-dom";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import UserChat from "../../../../../../components/shared/components/Chat/components/UserChat/UserChat";
import {UserRole} from "../../../../../../app/enums/UserRole";

const ManagerTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const {type} = useParams();

    const user = useAppSelector(selectCurrentUser);

    let id = '';
    let name = '';
    let hasChat = true;
    let releaseNumber = props.dataItem['orderReleaseXid'];

    switch (props.field) {
        case 'customerManager':
            id = props.dataItem["customerManagerId"];
            name = props.dataItem.customerManager;
            hasChat = props.dataItem['customerManagerHasChat'];
            break;
        case 'curator':
            id = props.dataItem.curatorId;
            name = props.dataItem.curator;
            hasChat = props.dataItem['curatorHasChat'];
            break;
        case 'forwarder':
            id = props.dataItem["forwarderId"];
            name = props.dataItem.forwarder;
            hasChat = props.dataItem['forwarderHasChat'];
            break;
    }

    if (!name || name === '' || !id || id === '' || !type || !user || !releaseNumber || releaseNumber === '') {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={`default-left-cell default-overflow-cell`}>
                    {props.children}
                </span>
            </td>
        );
    }

    let ids = id.split(';');

    if (ids.length <= 1 && ((user.uniqueUserValue ?? user.idStr) === ids[0])) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={`default-left-cell default-overflow-cell`}>
                    {props.children}
                </span>
            </td>
        );
    }

    let description = '';

    if(props.dataItem['pickupDate'] && props.dataItem['sourceDistrict'] && props.dataItem['sourceCity']
        && props.dataItem['destinationDistrict'] && props.dataItem['destinationCity']){
        description = `${props.dataItem['pickupDate']}, ${props.dataItem['sourceDistrict']} ${props.dataItem['sourceCity']}`;

        description += ` - ${props.dataItem['destinationDistrict']} ${props.dataItem['destinationCity']}`
    }

    return (
        <td {...props.tdProps}
            colSpan={1}>
             <span className={`default-flex-cell default-overflow-cell grid-prevent-selection`}
                   style={{cursor: 'pointer'}}>
                 {hasChat &&   <UserChat user={{
                     id: id,
                     role: UserRole.Forwarder
                 }} replyTo={{
                     type: 'QUOTE',
                     id: releaseNumber,
                     idExt: null,
                     description: description === '' ? null : description
                 }}/>}

                 <span           style={{ textAlign: 'left', padding: '0 3px'}}  className={`default-flex-cell default-overflow-cell grid-prevent-selection`} onClick={handleClick}>
                     {props.children}
                 </span>
             </span>
        </td>
    );
};

export default ManagerTemplate;
