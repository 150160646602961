import React from 'react';
import cl from './CopyRightFooter.module.css';
import {useTranslation} from "react-i18next";

const CopyRightFooter: React.FC = () => {
    const {t} = useTranslation();

    return (
        <footer className={cl.footer}>
            <span>&copy; {new Date().getFullYear()}</span>
            <span>{t("asstra")}</span>
            <span>{t("shared.labels.rights")}</span>
        </footer>
    );
};

export default CopyRightFooter;