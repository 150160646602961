import React, {useEffect, useState} from 'react';
import {ITag} from "../../../../../../app/interfaces/tag/ITag";
import TagForm from "../../components/TagForm/TagForm";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {useTagApi} from "../../../../../../app/api/tag";
import ListBox from "../../../../../ui/ListBox/ListBox";
import {SelectModel} from "../../../../../../app/types/SelectModel";
import Tag, {TagItemBackgroundColor} from "../../../../../ui/Tag/Tag";
import {groupBy, GroupResult} from "@progress/kendo-data-query";
import {useTranslation} from "react-i18next";
import {TFunction} from "i18next";
import {IError} from "../../../../../../app/interfaces/response/IResponse";
import {BLErrorCode} from "../../../../../../app/enums/BLErrorCode";
import Alert from "../../../../../ui/Alert/Alert";
import {ISaveTagPostAction} from "../../../../../../app/interfaces/tag/ISaveTagPostAction";
import {IDeleteTagAction} from "../../../../../../app/interfaces/tag/IDeleteTagAction";

type EditTagsProps = {
    close: () => void;
};

const groupData = (t: TFunction<"translation", undefined>, data: Array<ITag>): Array<SelectModel | string> => {
    let result: Array<SelectModel | string> = [];

    let groups: Array<GroupResult> = groupBy(data, [{field: 'isPublic'}]) as Array<GroupResult>;

    groups.forEach(gr => {
        result.push(gr.value === true
            ? t("tags.labels.department-tags")
            : t("tags.labels.personal-tags"));

        gr.items.forEach(tag => {
            let typedTag = tag as ITag;

            result.push({
                text: typedTag.name,
                value: typedTag.id.toString()
            });
        })
    });

    return result;
};

const EditTags: React.FC<EditTagsProps> = () => {
    const {t} = useTranslation();

    const [disabled, setDisabled] = useState<boolean>(false);
    const [selectedTag, setSelectedTag] = useState<ITag | null>(null);
    const [errors, setErrors] = useState<Array<IError>>([]);

    const [data, setData] = useState<Array<ITag>>([]);
    const [filteredData, setFilteredData] = useState<Array<SelectModel | string>>([]);

    const {
        getCurrentUserTags: {
            query,
            isLoading,
            cancel: cancelGetCurrentUserTags
        },
        saveTag: {
            mutation: saveTagAction,
            cancel: cancelSaveTag
        },
        deleteTag: {
            mutation: deleteTagAction,
            cancel: cancelDeleteTag
        }
    } = useTagApi();

    useEffect(() => {
        (async () => {
            await loadCurrentUserTags();
        })();

        return () => {
            cancelGetCurrentUserTags();
            cancelSaveTag();
            cancelDeleteTag();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadCurrentUserTags = async () => {
        try {
            const response = await query();

            if (response?.data?.resultCode === ResponseResultCode.Ok && response?.data.data) {
                setData(response.data.data);
                setFilteredData(groupData(t, response.data.data));
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(`'Unable to load tags list: ${e.message}`);
            }
        }
    }

    const onFilter = (value: string | null) => {
        if (!value || value === '') {
            setFilteredData(groupData(t, data));
        } else {
            setFilteredData(groupData(t, data
                .filter(item => {
                    return item.name.toLowerCase().includes(value.toLowerCase())
                })));
        }
    }

    const saveTag = async (tag: ISaveTagPostAction) => {
        setDisabled(prev => !prev);
        setErrors([]);
        setSelectedTag(null);

        try {
            const response = await saveTagAction({
                ...tag,
                color: tag.color.indexOf('#') > -1
                    ? tag.color.substring(1, 7)
                    : tag.color
            });

            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                await loadCurrentUserTags();
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    const deleteTag = async (tag: IDeleteTagAction) => {
        setDisabled(prev => !prev);
        setErrors([]);
        setSelectedTag(null);

        try {
            const response = await deleteTagAction(tag);

            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                await loadCurrentUserTags();
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    return (
        <div className={'h100 w100 pd-10'}>
            {errors.map((err, index) => {
                switch (err.errorCode) {
                    case BLErrorCode.InnerError:
                        return (
                            <Alert type={'Error'}
                                   id={`edit-tags-error-${index}`}>
                                {err.description}
                            </Alert>
                        );
                    case BLErrorCode.AlreadyExists:
                        return (
                            <Alert type={'Error'}
                                   id={`edit-tags-error-${index}`}>
                                {t("tags.errors.tag-with-this-name-already-exists")}
                            </Alert>
                        );
                }

                return null;
            })}

            <div className={'flex-two-columns flex-gap-10 pd-10'}
                 style={{}}>
                <div>
                    <ListBox id={'edit-tags-tags-list'}
                             selected={selectedTag ? [
                                 {
                                     value: selectedTag.id.toString(),
                                     text: selectedTag.name
                                 }] : []}
                             disabled={disabled}
                             data={filteredData}
                             isLoading={isLoading}
                             onFilter={onFilter}
                             height={250}
                             selection={'Single'}
                             template={props => {
                                 let item = data.find(tag => tag.id === Number(props.value));

                                 if (item) {
                                     return (
                                         <Tag item={{
                                             id: props.value,
                                             text: props.text,
                                             backgroundColor: TagItemBackgroundColor.Navy,
                                             backgroundColorStr: `#${item.color}`
                                         }} containerMaxWidth={300}/>
                                     );
                                 }

                                 return (
                                     <>
                                         {props.text}
                                     </>
                                 )
                             }}
                             onSelectionChange={(items) => {
                                 if (items.length > 0) {
                                     let item = data.find(e => e.id === Number(items[0].value));
                                     if (item) {
                                         setSelectedTag(item);
                                     }
                                 } else {
                                     setSelectedTag(null);
                                 }
                             }}/>
                </div>
                <div>
                    <TagForm tag={selectedTag}
                             disabled={false}
                             onTagSave={saveTag}
                             onTagUpdate={saveTag}
                             onTagDelete={deleteTag}/>
                </div>
            </div>
        </div>
    );
};

export default EditTags;
