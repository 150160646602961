import React, {Children, ReactNode, useEffect, useState} from 'react';
import uuid from "react-uuid";
import DetailsBoxColumn from "./components/DetailsBoxColumn/DetailsBoxColumn";
import cl from './DetailsBox.module.css';

type DetailsBoxProps = {
    id: string;
    isSidePanelDetails: boolean;
    children: ReactNode;
}

const DetailsBox: React.FC<DetailsBoxProps> = ({
                                                   children,
                                                   isSidePanelDetails
                                               }) => {
    const [columns, setColumns] = useState<Array<{
        id: string;
        column: ReactNode
        props: any;
    }>>([]);

    useEffect(() => {
        let cols: Array<{
            id: string;
            column: ReactNode
            props: any;
        }> = [];

        Children.toArray(children).forEach(item => {
            if (React.isValidElement(item)) {
                cols.push({
                    id: uuid(),
                    column: item,
                    props: item.props
                });
            }
        });

        setColumns(cols);
    }, [children]);

    if (columns.length <= 0) {
        return null;
    }

    return (
        <div data-count={columns.length}
             data-view={isSidePanelDetails ? 'side' : 'full'}
             className={cl.container}>
            {columns.map(col => {
                return (
                    <DetailsBoxColumn key={col.id}
                                      {...col.props}
                                      width={isSidePanelDetails ? '100%' : `${100 / columns.length}%`}>
                        {col.props.children}
                    </DetailsBoxColumn>
                )
            })}
        </div>
    );
};

export default DetailsBox;