import React, {useEffect, useState} from 'react';
import {LayoutConfigEntity} from "../../../../../../app/enums/LayoutConfigEntity";
import cl from './GridColumnsConfigurationSetupForm.module.css';
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {ILayoutConfig} from "../../../../../../app/interfaces/layoutConfig/ILayoutConfig";
import {useLayoutConfigApi} from "../../../../../../app/api/layoutConfig";
import {GridConfig} from "../../types/GridConfig";
import {LayoutConfigType} from "../../../../../../app/enums/LayoutConfigType";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import {InitialColumnModel} from "../../types/ColumnModel";
import axios from "axios";
import {toast} from "react-toastify";
import Loader, {LoaderType} from "../../../../loaders/Loader/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faThumbTack} from "@fortawesome/free-solid-svg-icons";
import Button, {ButtonType} from "../../../../Button/Button";
import {reIndexLockedColumns} from "./utils";
import {compareColumnModel} from "../../../../../../helpers/columnModel";
import Checkbox, {CheckboxSize} from "../../../../../../components/ui/Checkbox/Checkbox";

type GridColumnsConfigurationSetupFormProps = {
    entity: LayoutConfigEntity;
    defaultGridConfig: GridConfig;
    onSaved: () => void;
};

const GridColumnsConfigurationSetupForm: React.FC<GridColumnsConfigurationSetupFormProps> = ({
                                                                                                 entity,
                                                                                                 defaultGridConfig,
                                                                                                 onSaved
                                                                                             }) => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);

    const [initialColumns, setInitialColumns] = useState<Array<InitialColumnModel> | null>(null);
    const [gridConfig, setGridConfig] = useState<GridConfig | null>(null);
    const [savedGridLayoutConfig, setSavedGridLayoutConfig] = useState<ILayoutConfig | null>(null);

    const {
        getLayoutConfig: {query},
        saveLayoutConfig: {mutation}
    } = useLayoutConfigApi();

    useEffect(() => {
        setInitialColumns(defaultGridConfig.columns);

        (async () => {
            setIsLoading(prev => !prev);

            if (!user) {
                return null;
            }

            try {
                const configResponse = await query(entity, LayoutConfigType.Grid, user.deviceId);
                if (configResponse?.status === 200 &&
                    configResponse.data.data !== undefined &&
                    configResponse.data.resultCode === ResponseResultCode.Ok) {
                    setSavedGridLayoutConfig(configResponse.data.data);

                    let gridConfig = JSON.parse(configResponse.data.data.value);
                    setGridConfig(gridConfig);
                } else {
                    setGridConfig(defaultGridConfig);
                }
            } catch {
                setGridConfig(defaultGridConfig);
            }

            setIsLoading(prev => !prev);
        })();

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const onChange = (column: InitialColumnModel) => {
        if (!initialColumns || gridConfig === null) {
            return
        }

        let field = gridConfig.columns.find(i => i.field === column.field);

        if (field) {
            let updatedInitialArray = initialColumns.map(item => ({...item}));
            let toUpdate = updatedInitialArray.find(item => item.field === column.field);
            if (toUpdate) {
                if (column.visible !== undefined) {
                    toUpdate.visible = column.visible;
                    field.visible = column.visible;
                }

                if (column.locked !== undefined) {
                    toUpdate.locked = column.locked;
                    field.locked = column.locked;

                    field.allowReordering = !field.locked;
                }
            }
            setInitialColumns(updatedInitialArray);
        }
    };

    const saveGridConfig = async (): Promise<void> => {
        if (!user) {
            return;
        }

        try {
            setDisabled(prev => !prev);

            if (gridConfig) {
                gridConfig.columns = reIndexLockedColumns(gridConfig?.columns ?? []);
            }

            const response = await mutation({
                id: savedGridLayoutConfig !== null && savedGridLayoutConfig !== undefined
                    ? savedGridLayoutConfig.id
                    : 0,
                type: LayoutConfigType.Grid,
                entity: entity,
                deviceId: user.deviceId,
                value: JSON.stringify(gridConfig)
            });

            if (response && response.status === 200 && response.data && response.data.field) {
                onSaved();
            }

            setDisabled(prev => !prev);
        } catch (err) {
            if (axios.isAxiosError(err)) {
                toast.error<string>(`Unable to save ${LayoutConfigEntity[entity].toString()} configuration.`);
            }
        }
    };

    if (defaultGridConfig.columns.length === 0) {
        return null;
    }

    if (isLoading || initialColumns === null) {
        return (
            <div className={`h100 w100 ${cl.pd5}`}
                 style={{height: `${(defaultGridConfig.columns.length / 4) * 40 + 50}px`}}>
                <div className={'action-loader-container'}>
                    <div>
                        <Loader type={LoaderType.Action} style={{scale: '0.4'}}/>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={`h100 w100`}>
            <div className={cl.columns}
                 style={{height: `${((Math.round(defaultGridConfig.columns.length / 4)) * 42) + 10}px`}}>
                {initialColumns.filter(e => e.field && (e.constantlyLocked === undefined || !e.constantlyLocked))
                    .map(item => ({...item, name: t(item.title)}))
                    .sort(compareColumnModel)
                    .map(item => {
                    let isLocked: boolean = item.locked !== undefined ? item.locked : false;
                    let isVisible: boolean = item.visible !== undefined ? item.visible : true;

                    if (gridConfig !== null && gridConfig.columns.length > 0) {
                        let savedItem = gridConfig.columns.find(sItem => sItem.field === item.field);
                        if (savedItem) {
                            if (savedItem.visible !== undefined) {
                                isVisible = savedItem.visible;
                            }

                            if (savedItem.locked !== undefined) {
                                isLocked = savedItem.locked;
                            }
                        }
                    }

                    return (
                        <div key={item.field}
                             className={cl.column}>

                            <div className={cl.checkboxContainer}>
                                <div onClick={() => onChange({
                                    ...item,
                                    visible: !isVisible
                                })}>
                                    <FontAwesomeIcon icon={faEye} style={{color : isVisible ? '#3F8CE5' : 'gray'}}
                                                     title={t("shared.grid-header.show-hide-column-title")}/>
                                    <div >
                                        <Checkbox checked={isVisible} size={CheckboxSize.Medium}
                                              title={t("shared.grid-header.show-hide-column-title")}
                                                  change={() => onChange({
                                                  ...item,
                                                  visible: !isVisible
                                              })} label={""}
                                              disabled={disabled}/></div>
                                </div>
                                <div onClick={() => onChange({
                                    ...item,
                                    locked: !isLocked
                                })}>
                                    <FontAwesomeIcon icon={faThumbTack} style={{color : isLocked ? '#3F8CE5' : 'gray'}}
                                                     title={t("shared.grid-header.freeze-unfreeze-column-title")}/>
                                    <div>
                                        <Checkbox checked={isLocked} size={CheckboxSize.Medium}
                                              title={t("shared.grid-header.freeze-unfreeze-column-title")}
                                                  change={() => onChange({
                                                  ...item,
                                                  locked: !isLocked
                                              })} label={""}
                                              disabled={disabled || !isVisible}/>

                                    </div>
                                </div>
                            </div>
                            <div className={`prevent-selection text-no-wrap ${cl.columnText}`}>
                                {t(item.title)}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={cl.buttons}>
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        onClick={saveGridConfig}
                        style={{width: '150px'}}>
                    {t("shared.btn.save")}
                </Button>
            </div>
        </div>
    );
};

export default GridColumnsConfigurationSetupForm;
