import React from 'react';
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import ReactDatePicker from "../../../../ui/ReactDatePicker/ReactDatePicker/ReactDatePicker";

type FilterPanelPeriodFilterProps = {
    id: string;
    disabled?: boolean;
    title: string;
    onClear: () => void;
    onChange: (type: 'from' | 'to', value: string | null) => void,
    value: {
        from: string | null,
        to: string | null
    }
};

const FilterPanelPeriodFilter: React.FC<FilterPanelPeriodFilterProps> = ({
                                                                             value,
                                                                             onChange,
                                                                             title,
                                                                             disabled = false
                                                                         }) => {
    const {t} = useTranslation();

    return (
        <div className={'flex view-row mgb-10 filter-date-picker relative'}>
            <div className={'w100'}>
                <ReactDatePicker disabled={disabled}
                                 floatingPlaceholderFontSize={'10px'}
                                 size={'medium'}
                                 maxDate={value.to !== null && value.to !== ''
                                     ? moment(value.to, 'DD.MM.YYYY').toDate()
                                     : undefined}
                                 value={value.from !== null && value.from !== ''
                                     ? moment(value.from, 'DD.MM.YYYY').toDate()
                                     : null}
                                 onChange={(val) => {
                                     onChange('from', val
                                         ? moment(val).format('DD.MM.YYYY')
                                         : null);
                                 }}
                                 placeholder={`${title} (${t("shared.labels.date-from-label").toUpperCase()})`}
                                 floatingPlaceholder
                />
                {/*<DatePicker format={'dd.MM.yyyy'}
                            dateInput={DateInput}
                            ariaLabel={'floating-placeholder'}
                            disabled={disabled}
                            formatPlaceholder={{
                                year: "yyyy",
                                month: "MM",
                                day: "dd",
                            }}
                            max={value.to !== null && value.to !== ''
                                ? moment(value.to, 'DD.MM.YYYY').toDate()
                                : undefined}
                            value={value.from !== null && value.from !== ''
                                ? moment(value.from, 'DD.MM.YYYY').toDate()
                                : null}
                            onChange={(ev) => {
                                onChange('from', ev.value ?
                                    moment(ev.value).format('DD.MM.YYYY')
                                    : null);
                            }}
                            popupSettings={{animate: false}}
                            rounded={'small'}
                            fillMode={'outline'}
                            placeholder={`${title} (${t("shared.labels.date-from-label").toUpperCase()})`}
                            adaptive={true}/>*/}
            </div>
            <div style={{
                width: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <hr style={{
                    color: '#d8d8d8',
                    backgroundColor: '#d8d8d8',
                    border: 'none',
                    height: '1.5px',
                    width: '10px'
                }}/>
            </div>
            <div className={'w100'}>
                <ReactDatePicker disabled={disabled}
                                 floatingPlaceholderFontSize={'10px'}
                                 size={'medium'}
                                 minDate={value.from !== null && value.from !== ''
                                     ? moment(value.from, 'DD.MM.YYYY').toDate()
                                     : undefined}
                                 value={value.to !== null && value.to !== ''
                                     ? moment(value.to, 'DD.MM.YYYY').toDate()
                                     : null}
                                 onChange={(val) => {
                                     onChange('to', val
                                         ? moment(val).format('DD.MM.YYYY')
                                         : null);
                                 }}
                                 placeholder={`${title} (${t("shared.labels.date-to-label").toUpperCase()})`}
                                 floatingPlaceholder
                />
                {/*<DatePicker format={'dd.MM.yyyy'}
                            ariaLabel={'floating-placeholder'}
                            dateInput={DateInput}
                            disabled={disabled}
                            formatPlaceholder={{
                                year: "yyyy",
                                month: "MM",
                                day: "dd",
                            }}
                            min={value.from !== null && value.from !== ''
                                ? moment(value.from, 'DD.MM.YYYY').toDate()
                                : undefined}
                            value={value.to !== null && value.to !== ''
                                ? moment(value.to, 'DD.MM.YYYY').toDate()
                                : null}
                            onChange={(ev) => {
                                onChange('to', ev.value ?
                                    moment(ev.value).format('DD.MM.YYYY')
                                    : null);
                            }}
                            popupSettings={{animate: false}}
                            rounded={'small'}
                            fillMode={'outline'}
                            placeholder={`${title} (${t("shared.labels.date-to-label").toUpperCase()})`}
                            adaptive={true}/>*/}
            </div>
        </div>
    );
};

export default FilterPanelPeriodFilter;