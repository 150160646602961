import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import SidebarNavigationButton from "../../../components/SidebarNavigationButton/SidebarNavigationButton";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import SidebarNavigationNestedButton
    from "../../../components/SidebarNavigationButton/SidebarNavigationNestedButton/SidebarNavigationNestedButton";
import {SidebarContext} from "../../../Sidebar";
import {isMobile} from "react-device-detect";

type SidebarMenuQuoteProps = {
    expandedId: string;
    onClick?: (id: string) => void;
};

const SidebarMenuQuote: React.FC<SidebarMenuQuoteProps> = ({
                                                               expandedId,
                                                               onClick: onClickProp,
                                                           }) => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    const {
        expanded,
        hoverExpanded,
        setIsExpandedOnHover
    } = useContext(SidebarContext);

    if (!user) {
        return null;
    }

    let role = getUserRole(user);

    if (role !== UserRole.Administrator && role !== UserRole.Forwarder) {
        return null;
    }

    const handleNavigationClick = (id: string) => {
        if (onClickProp) {
            onClickProp(id);
        }
    };

    const handleNestedNavigationClick = () => {
        if (isMobile && !expanded && hoverExpanded) {
            setIsExpandedOnHover(false);
        }
    }

    return (
        <SidebarNavigationButton id={'sidebar-quote-item'} expandedId={expandedId}
                                 type={'primary'}
                                 onClick={handleNavigationClick}
                                 text={t("sidebar.quote.title")}
                                 title={t("sidebar.quote.title")}
                                 path={'/quotes'}
                                 icon={"faBook"}>
            <SidebarNavigationNestedButton id={'sidebar-quote-item-active'}
                                           type={'primary'}
                                           path={'/quotes/active'}
                                           text={t("sidebar.quote.all")}
                                           title={t("sidebar.quote.all")}
                                           tooltip={t("sidebar.quote.all-tooltip") || ''}
                                           onClick={handleNestedNavigationClick}
            />

            <SidebarNavigationNestedButton id={'sidebar-quote-item-new'}
                                           type={'primary'}
                                           path={'/quotes/new'}
                                           text={t("sidebar.quote.new")}
                                           title={t("sidebar.quote.new")}
                                           tooltip={t("sidebar.quote.new-tooltip") || ''}
                                           onClick={handleNestedNavigationClick}
            />

            <SidebarNavigationNestedButton id={'sidebar-quote-item-sent'}
                                           type={'primary'}
                                           path={'/quotes/sent'}
                                           text={t("sidebar.quote.sent-to-carrier")}
                                           title={t("sidebar.quote.sent-to-carrier")}
                                           tooltip={t("sidebar.quote.sent-to-carrier-tooltip") || ''}
                                           onClick={handleNestedNavigationClick}
            />

            <SidebarNavigationNestedButton id={'sidebar-quote-item-bet'}
                                           type={'primary'}
                                           path={'/quotes/bet'}
                                           text={t("sidebar.quote.bet")}
                                           title={t("sidebar.quote.bet")}
                                           tooltip={t("sidebar.quote.bet-tooltip") || ''}
                                           onClick={handleNestedNavigationClick}
            />

            <SidebarNavigationNestedButton id={'sidebar-quote-item-expired'}
                                           type={'primary'}
                                           path={'/quotes/expired'}
                                           text={t("sidebar.quote.expired")}
                                           title={t("sidebar.quote.expired")}
                                           tooltip={t("sidebar.quote.expired-tooltip") || ''}
                                           onClick={handleNestedNavigationClick}
            />

            <SidebarNavigationNestedButton id={'sidebar-quote-item-customer-sent'}
                                           type={'primary'}
                                           path={'/quotes/clientSent'}
                                           text={t("sidebar.quote.bet-sent-to-customer")}
                                           title={t("sidebar.quote.bet-sent-to-customer")}
                                           tooltip={t("sidebar.quote.bet-sent-to-customer-tooltip") || ''}
                                           onClick={handleNestedNavigationClick}
            />
        </SidebarNavigationButton>
    );
};

export default SidebarMenuQuote;
