import React from 'react';
import cl from './SelectedOffers.module.css';
import {IOfferSelectedItem} from "../../../../../../app/interfaces/offer/IOfferSelectedItem";
import freeFreightImg from '../../../../../../assets/images/ff.png';
import auctionImg from '../../../../../../assets/images/auction.png';
import contractImg from '../../../../../../assets/images/contract.png';
import {AuctionType} from "../../../../../../app/enums/AuctionType";

type SelectedOffersProps = {
    ids: string | Array<string>;
}

const SelectedOffers: React.FC<SelectedOffersProps> = ({
                                                           ids
                                                       }) => {
    return (
        <div className={cl.container}>
            {[...ids].map(item => {
                return (
                    <div key={item}>
                        {item}
                    </div>
                );
            })}
        </div>
    );
};

export default SelectedOffers;

type ActiveSelectedOffersProps = {
    disabled: boolean;
    items: Array<IOfferSelectedItem>;
    selectedOffer: IOfferSelectedItem | null;
    onClick: (item: IOfferSelectedItem) => void;
    cols?: number;
};

export const ActiveSelectedOffers: React.FC<ActiveSelectedOffersProps>
    = ({
           items,
           disabled,
           onClick,
           selectedOffer,
           cols = 5
       }) => {
    return (
        <div className={cl.container}
             style={cols !== undefined && cols > 0
                 ? {
                     gridTemplateColumns: `repeat(${cols}, 1fr)`
                 } : undefined}>
            {items.map((item) => {
                let isSelected = selectedOffer !== null && selectedOffer.id === item.id;

                return (
                    <div key={item.id}
                         className={`${cl.item} ${isSelected ? cl.selected : ''} prevent-selection`}
                         onClick={() => {
                             if (disabled)
                                 return;

                             onClick(item);
                         }}>
                        <>
                            {item.id}

                            {item.isSent
                                ? <>
                                    {item.status !== null && item.status !== undefined
                                        ? <>
                                            {item.status === AuctionType.Auction &&
                                                <img className={cl.img}
                                                     src={auctionImg}
                                                     alt={'auction'}/>
                                            }

                                            {item.status === AuctionType.ContractFlex || item.status === AuctionType.ContractStrong
                                                ? <img className={cl.img}
                                                       src={contractImg}
                                                       alt={'contract'}/>
                                                : null
                                            }
                                        </>
                                        : <img className={cl.img}
                                               src={freeFreightImg}
                                               alt={'ff'}/>
                                    }
                                </>
                                : null
                            }
                        </>
                    </div>
                );
            })}
        </div>
    );
};