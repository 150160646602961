import React, {ReactNode} from 'react';
import {useCollapse} from "react-collapsed";
import cl from "./FormCollapse.module.css";
import Icon from "../../../assets/icon/Icon";

type FormCollapseProps = {
    children: ReactNode;
    defaultExpanded?: boolean;
    title: string | ReactNode | null;
    button?: ReactNode;
};

const FormCollapse: React.FC<FormCollapseProps> = ({
                                                       defaultExpanded,
                                                       title,
                                                       children,
                                                       button
                                                   }) => {
    const config = {
        defaultExpanded: defaultExpanded !== undefined ? defaultExpanded : true,
    };

    const {getCollapseProps, getToggleProps, isExpanded} = useCollapse(config);

    return (
        <div className={cl.collapse}>
            <div className={cl.header} {...getToggleProps()}>
                <div className={`${cl.title} prevent-selection`}>
                    {title ? <>{title}</> : <>&nbsp;</>}

                    {button &&
                        <div>
                            {button}
                        </div>
                    }
                </div>
                <div className={cl.icon}>
                    <Icon icon={"faThinChevronUp"}
                          flip={isExpanded ? 'horizontal' : 'vertical'}/>
                </div>
            </div>
            <div {...getCollapseProps()}>
                <div className={cl.content}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default FormCollapse;
