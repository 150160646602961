import React, {useEffect, useState} from 'react';
import {IShipment} from "../../../../../../app/interfaces/shipment/IShipment";
import FormLoadingScreen from "../../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {ISelfBilling} from "../../../../../../app/interfaces/selfBilling/ISelfBilling";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import useSelfBillingApi from "../../../../../../app/api/selfBilling";
import useInvoiceApi from "../../../../../../app/api/invoice";
import SelfBillingForm from "../../../../../anonymous/SelfBilling/form/SelfBillingForm/SelfBillingForm";

type SelfBillingSectionProps = {
    shipment: IShipment;
    isSidePanelDetails?: boolean;
};

const SelfBillingSection: React.FC<SelfBillingSectionProps> = ({
                                                                   shipment,
                                                                   isSidePanelDetails
                                                               }) => {
    const user = useAppSelector(selectCurrentUser);

    const [model, setModel] = useState<ISelfBilling | null>(null);

    const {
        getSelfBillingDataAuthorize: {
            query: getSelfBillingData,
            isLoading: getSelfBillingDataIsLoading,
            cancel: cancelGetSelfBillingData
        }
    } = useSelfBillingApi();

    const {
        getShipmentInvoiceAuthorize: {
            query: getShipmentInvoiceAuthorize,
            cancel: cancelGetShipmentInvoiceAuthorize
        }
    } = useInvoiceApi();

    useEffect(() => {

        (async () => {
            await load();
        })();

        return () => {
            cancelGetSelfBillingData();
            cancelGetShipmentInvoiceAuthorize();
        }
    }, []);

    const load = async () => {
        if (!shipment.shipmentGid || !shipment.serviceProviderId) {
            return;
        }

        try {
            const response = await getSelfBillingData(shipment.shipmentGid, shipment.serviceProviderId);

            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok && response.data.data) {
                let hasAttachedDocuments = false;

                response.data.data.expenses.forEach(expense => {
                    if (expense.cmrDocuments.length > 0) {
                        hasAttachedDocuments = true;
                    }
                });

                if (hasAttachedDocuments || response.data.data.invoices.length > 0) {
                    try {
                        const shipmentInvoiceResponse = await getShipmentInvoiceAuthorize(response.data.data.shipmentGid, response.data.data.shipmentXid);

                        if (shipmentInvoiceResponse?.status === 200 &&
                            shipmentInvoiceResponse.data?.resultCode === ResponseResultCode.Ok &&
                            shipmentInvoiceResponse.data.data) {

                            response.data.data.shipmentInvoice = shipmentInvoiceResponse.data.data;
                        }
                    } catch (err) {
                        if (isAxiosError(err)) {
                            toast.error(`Unable to get shipment invoice. Error: ${err.message}`);
                        }
                    }
                }
                response.data.data.isCmrAllowedForSp = shipment.isCmrAllowedForSp;

                setModel(response.data.data);

            }
        } catch (err) {
            if (isAxiosError(err)) {
                toast.error(`Unable to get self billing data. Error: ${err.message}`)
            }
        }
    }

    if (!shipment.shipmentGid || !shipment.serviceProviderId || !model || getSelfBillingDataIsLoading) {
        return <FormLoadingScreen height={`100%`} style={{padding: '5px'}}/>;
    }

    return <SelfBillingForm isAnonymousAccess={false}
                            isSidePanelDetails={isSidePanelDetails}
                            model={model}
                            user={user}/>
};

export default SelfBillingSection;
