import {IMapPositioningGetAction} from "../../../../../../app/interfaces/shipment/IMapPositioningGetAction";
import {CircleProps, MarkerProps} from "@react-google-maps/api";
import uuid from "react-uuid";
import {IStopPoint} from "../../../../../../app/interfaces/shared/IStopPoint";
import {AdvancedMarkerProps} from "../../../../../../components/ui/GoogleMap/AdvancedMarker/AdvancedMarker";
import {buildLocationMapCurrentTrackingPositionContent} from "./marker";
import {IPosition} from "../../../../../../app/interfaces/shared/IPosition";

export type LocationMapExtendedMarkerProps = {
    id: string;
    sp: IStopPoint;
    pos?: IPosition;
} & MarkerProps;

export const buildStopPointMarkersArray = (model: IMapPositioningGetAction, handleInfoWindow: (id: string, ev: 'close' | 'open') => void): Array<LocationMapExtendedMarkerProps> => {
    if (!model.stopPoints || model.stopPoints.length <= 0) {
        return [];
    }

    let res: Array<LocationMapExtendedMarkerProps> = [];

    let stopPoints = model.stopPoints.filter(e => e.type !== null &&
        e.lt !== null && e.lt !== 0 && e.lg !== null && e.lg !== 0 &&
        ((e.type.toLowerCase() === '[c]' || e.type.toLowerCase() === '[o]') ||
            (e.type.toLowerCase() !== '[c]' && e.type.toLowerCase() !== '[o]'))
    );

    for (let sp of stopPoints) {
        let type = sp.type?.replace('[', '').replace(']', '').toLowerCase();
        if (type) {
            let id = uuid();

            let m = {
                id: id,
                position: {
                    lat: sp.lt ?? 0,
                    lng: sp.lg ?? 0
                },
                zIndex: 1,
                icon: `/images/marker_blue_${type}.png`,
                sp: sp
            } as LocationMapExtendedMarkerProps;

            m.onMouseOver = function () {
                handleInfoWindow(id, 'open');
            }

            m.onMouseOut = function () {
                handleInfoWindow(id, 'close');
            }

            m.onClick = function () {
                handleInfoWindow(id, 'close');
            }

            res.push(m);
        }
    }


    return res;
}

export const buildStopPointCirclesArray = (model: IMapPositioningGetAction): Array<CircleProps> => {
    if (!model.stopPoints || model.stopPoints.length <= 0) {
        return [];
    }

    const stopPoints = model.stopPoints
        .filter(e => e.type !== null && e.lg !== null && e.lt !== null && e.lg !== 0 && e.lt !== 0)
        .sort((a, b) => {
            return a.position - b.position;
        });

    let circles: Array<CircleProps> = [];

    for (let sp of stopPoints) {
        if (sp.type !== null) {
            let type = sp.type.replace('[', '').replace(']', '').toLowerCase();
            if (type === 'p' || type === 'd') {
                let color = sp.needCheck
                    ? '#fed9d9'
                    : '#29d64e';

                circles.push({
                    center: {
                        lat: sp.lt ?? 0,
                        lng: sp.lg ?? 0
                    },
                    radius: 1000,
                    options: {
                        strokeColor: color,
                        strokeOpacity: 1,
                        strokeWeight: 2,
                        fillColor: color,
                        fillOpacity: 0.35,
                    }
                });
            }
        }
    }

    return circles;
}

export const buildLocationMapCurrentTrackingPositionMarker = (model: IMapPositioningGetAction): AdvancedMarkerProps | null => {
    if (model.currentPosition !== null && model.currentPosition.lt !== null && model.currentPosition.lt !== 0 &&
        model.currentPosition.lg !== null && model.currentPosition.lg !== 0) {
        return {
            position: {
                lat: model.currentPosition.lt ?? 0,
                lng: model.currentPosition.lg ?? 0,
            },
            children: buildLocationMapCurrentTrackingPositionContent(model),
            map: {} as any,
        };
    }

    return null;
}

export const buildRoutePointMarkersArray = (points: Array<IPosition>, handleInfoWindow: (id: string, ev: 'close' | 'open') => void): Array<LocationMapExtendedMarkerProps> => {
    if (points.length <= 0) {
        return [];
    }

    let res: Array<LocationMapExtendedMarkerProps> = [];

    for (let point of points) {
        if (point.lt && point.lt !== 0 && point.lg && point.lg !== 0) {
            let id = uuid();

            let m = {
                id: id,
                position: {
                    lat: point.lt ?? 0,
                    lng: point.lg ?? 0
                },
                zIndex: 1,
                icon: point.isStop
                    ? {
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 5,
                        fillColor: 'red',
                        fillOpacity: 0.8,
                        strokeWeight: 2,
                        rotation: point.angle
                    }
                    : {
                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        scale: 3,
                        fillColor: '#057cff',
                        fillOpacity: 0.8,
                        strokeWeight: 2,
                        rotation: point.angle
                    },
                sp: null as any,
                pos: point
            } as LocationMapExtendedMarkerProps;

            m.onMouseOver = function () {
                handleInfoWindow(id, 'open');
            }

            m.onMouseOut = function () {
                //handleInfoWindow(id, 'close');
            }

            m.onClick = function () {
                handleInfoWindow(id, 'close');
            }

            res.push(m);
        }
    }

    return res;
}

export const buildRoutePointCirclesArray = (points: Array<IPosition>): Array<CircleProps> => {
    if (points.length <= 0) {
        return [];
    }

    let circles: Array<CircleProps> = [];

    for (let point of points) {
        if (point.lt && point.lt !== 0 && point.lg && point.lg !== 0) {
            let center = {
                lat: point.lt ?? 0,
                lng: point.lg ?? 0
            };

            circles.push(point.isStop
                ? {
                    options: {
                        strokeColor: 'red',
                        strokeOpacity: 0.8,
                        strokeWeight: 10,
                        fillColor: 'red',
                        fillOpacity: 0.35,
                    },
                    center: center,
                    radius: 100
                }
                : {
                    options: {
                        strokeColor: '#057cff',
                        strokeOpacity: 0.8,
                        strokeWeight: 10,
                        fillColor: '#057cff',
                        fillOpacity: 0.35,
                    },
                    center: center,
                    radius: 100
                });
        }
    }
    return circles;
}