import GridLayout from "../../../../components/uiLayouts/GridLayout/GridLayout";
import {LayoutConfigEntity} from "../../../../app/enums/LayoutConfigEntity";
import defaultShipmentHeaderConfig from "./data/header";
import getShipmentInitialGridConfig from "./data/columns";
import {useAppSelector} from "../../../../app/store";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../helpers/user";
import ShipmentMapContent from "./components/ShipmentMapContent/ShipmentMapContent";
import ShipmentGridContent from "./components/ShipmentGridContent/ShipmentGridContent";
import DetailsGridLayout from "../../../../components/uiLayouts/DetailsGridLayout/DetailsGridLayout";
import React, {useEffect, useState} from "react";
import ShipmentForm from "../form/ShipmentForm";
import {useSearchParams} from "react-router-dom";
import useIfFirstRender from "../../../../hooks/useIsFirstRender/useIfFirstRender";
import ShipmentFilterPanel from "./components/ShipmentFilterPanel/ShipmentFilterPanel";
import ShipmentActionRow from "./components/ShipmentActionRow/ShipmentActionRow";
import useMediaQuery from "../../../../hooks/useMediaQuery/useMediaQuery";
import {isMobile} from "react-device-detect";

const ShipmentGrid = () => {
    const [isSideDetailsOpened, setIsSideDetailsOpened] = useState<boolean>(false);
    const [isFullDetailsOpened, setIsFullDetailsOpened] = useState<boolean>(false);
    const [isUrlChecked, setIsUrlChecked] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const [detailsSelectedItem, setDetailsSelectedItem] = useState<any | null>(null);

    const isFirstRender = useIfFirstRender();
    const user = useAppSelector(selectCurrentUser);
    const isMaxWidth600 = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has('id') || urlParams.get('sId') || urlParams.get('sid')) {
            setIsFullDetailsOpened(true);
            setDetailsSelectedItem({
                rId: urlParams.has('id') ? urlParams.get('id') : '',
                sId: urlParams.has('sId') ? urlParams.get('sId') : (urlParams.has('sid') ? urlParams.get('sid') : '')
            });
        }

        setIsUrlChecked(true);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isFirstRender || !isUrlChecked) {
            return;
        }
        const urlParams = new URLSearchParams(window.location.search);

        if (isFullDetailsOpened && !urlParams.get('id') && !urlParams.get('sid') && !urlParams.get('sId')) {
            setIsFullDetailsOpened(false);
        }

        if (!isFullDetailsOpened && (urlParams.get('id') || urlParams.get('sid') || urlParams.get('sId'))) {
            setIsFullDetailsOpened(true);
            setDetailsSelectedItem({
                rId: urlParams.has('id') ? urlParams.get('id') : '',
                sId: urlParams.has('sId') ? urlParams.get('sId') : (urlParams.has('sid') ? urlParams.get('sid') : '')
            });
        }
    }, [isFirstRender, searchParams, isUrlChecked]);

    if (!user || !isUrlChecked) {
        return null;
    }

    return (
        <DetailsGridLayout id={'shipment-details'}
                           details={<ShipmentForm id={detailsSelectedItem?.rId ?? null}
                                                  sId={detailsSelectedItem?.sId ?? null}
                                                  isFullPageDetails={isFullDetailsOpened}
                                                  close={() => {
                                                      if (searchParams.has('id') || searchParams.has('sid') || searchParams.has('sId')) {
                                                          searchParams.delete('sId');
                                                          searchParams.delete('id');
                                                          searchParams.delete('sid');
                                                          setSearchParams(searchParams);
                                                      }

                                                      if (isFullDetailsOpened) {
                                                          setIsFullDetailsOpened(false);
                                                      }

                                                      if (isSideDetailsOpened) {
                                                          setIsSideDetailsOpened(false);
                                                      }
                                                  }}/>}
                           grid={
                               <GridLayout grid={<ShipmentGridContent
                                   onRowDoubleClick={(ev) => {
                                       if (ev.dataItem) {
                                           setIsSideDetailsOpened(false);

                                           setDetailsSelectedItem(ev.dataItem);

                                           setSearchParams({
                                               'id': ev.dataItem.rId,
                                               'sId': ev.dataItem.sId
                                           });

                                           setIsFullDetailsOpened(prev => !prev)
                                       }
                                   }}
                                   onRowClick={(ev) => {
                                       if (ev.dataItem) {
                                           if (isMobile || isMaxWidth600) {
                                               setDetailsSelectedItem(ev.dataItem);

                                               setSearchParams({
                                                   'id': ev.dataItem.rId,
                                                   'sId': ev.dataItem.sId
                                               });

                                               setIsFullDetailsOpened(prev => !prev);
                                           } else {
                                               setIsSideDetailsOpened(prev => {
                                                   setDetailsSelectedItem(ev.dataItem);

                                                   if (prev) {
                                                       return !(detailsSelectedItem !== null && detailsSelectedItem.rId === ev.dataItem.rId);
                                                   }

                                                   return true;
                                               });
                                           }
                                       }
                                   }}/>}
                                           header={<ShipmentActionRow/>}
                                           map={<ShipmentMapContent/>}
                                           filterPanel={<ShipmentFilterPanel/>}

                                           defaultHeaderConfig={defaultShipmentHeaderConfig}
                                           defaultGridConfig={getShipmentInitialGridConfig(getUserRole(user))}

                                           entity={LayoutConfigEntity.Shipment}/>
                           }
                           isFullDetailsOpened={isFullDetailsOpened}
                           setIsFullDetailsOpened={setIsFullDetailsOpened}
                           isSideDetailsOpened={isSideDetailsOpened}
                           setIsSideDetailsOpened={setIsSideDetailsOpened}/>
    )
};

export default ShipmentGrid;
