import {
    useAnonymousChangePassword,
    useChangePassword,
    useGetRestorePasswordData, useRegisterExistingSupplier, useRegisterNewSupplier,
    useRestoreLogin,
    useRestorePassword
} from "./requests";

export const useAccountApi = () => {
    const {
        isLoading: changePasswordIsLoading,
        cancel: cancelChangePassword,
        changePassword
    } = useChangePassword();

    const {
        isLoading: restorePasswordIsLoading,
        cancel: cancelRestorePassword,
        restorePassword
    } = useRestorePassword();

    const {
        isLoading: getRestorePasswordDataIsLoading,
        cancel: cancelGetRestorePasswordData,
        getRestorePasswordData
    } = useGetRestorePasswordData();

    const {
        isLoading: anonymousChangePasswordIsLoading,
        cancel: cancelAnonymousChangePassword,
        anonymousChangePassword
    } = useAnonymousChangePassword();

    const {
        isLoading: restoreLoginIsLoading,
        cancel: cancelRestoreLogin,
        restoreLogin
    } = useRestoreLogin();

    const {
        isLoading: registerNewSupplierIsLoading,
        cancel: cancelRegisterNewSupplier,
        registerNewSupplier
    } = useRegisterNewSupplier();

    const {
        isLoading: registerExistingSupplierIsLoading,
        cancel: cancelRegisterExistingSupplier,
        registerExistingSupplier
    } = useRegisterExistingSupplier();

    return {
        changePassword: {
            mutation: changePassword,
            isLoading: changePasswordIsLoading,
            cancel: cancelChangePassword
        },
        restorePassword: {
            mutation: restorePassword,
            isLoading: restorePasswordIsLoading,
            cancel: cancelRestorePassword
        },
        getRestorePasswordData: {
            query: getRestorePasswordData,
            isLoading: getRestorePasswordDataIsLoading,
            cancel: cancelGetRestorePasswordData
        },
        anonymousChangePassword: {
            mutation: anonymousChangePassword,
            isLoading: anonymousChangePasswordIsLoading,
            cancel: cancelAnonymousChangePassword
        },
        restoreLogin: {
            mutation: restoreLogin,
            isLoading: restoreLoginIsLoading,
            cancel: cancelRestoreLogin
        },
        registerExistingSupplier: {
            mutation: registerExistingSupplier,
            isLoading: registerExistingSupplierIsLoading,
            cancel: cancelRegisterExistingSupplier
        },
        registerNewSupplier: {
            mutation: registerNewSupplier,
            isLoading: registerNewSupplierIsLoading,
            cancel: cancelRegisterNewSupplier
        }
    }
}