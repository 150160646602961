import React, {ReactNode} from 'react';
import SidePanel from "../../ui/SidePanel/SidePanel";
import {AnimatePresence, motion} from "framer-motion";
import cl from './DetailsGridLayout.module.css';

type DetailsGridLayoutProps = {
    id: string;
    details: ReactNode;
    grid: ReactNode;
    isFullDetailsOpened: boolean;
    setIsFullDetailsOpened: (val: boolean) => void;
    isSideDetailsOpened: boolean;
    setIsSideDetailsOpened: (val: boolean) => void;
}

const DetailsGridLayout: React.FC<DetailsGridLayoutProps> = ({
                                                                 id,
                                                                 details,
                                                                 grid,
                                                                 isSideDetailsOpened,
                                                                 setIsSideDetailsOpened,
                                                                 isFullDetailsOpened,
                                                             }) => {
    return (
        <>
            <SidePanel id={`side-panel-${id}`}
                       isShown={isSideDetailsOpened}
                       setIsShown={setIsSideDetailsOpened}>
                {details}
            </SidePanel>
            <div className={'h100 w100 hidden relative'}>
                <AnimatePresence>
                    {isFullDetailsOpened && !isSideDetailsOpened &&
                        <motion.div key={`full-details-div-${id}`}
                                    className={`h100 w100 hidden ${cl.fullDetails}`}
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    exit={{opacity: 0}}
                                    transition={{type: "spring", bounce: 0, duration: 0.4}}>
                            {details}
                        </motion.div>
                    }
                </AnimatePresence>
                <div className={`h100 w100 hidden ${isFullDetailsOpened && !isSideDetailsOpened ? cl.hidden : ''}`}>
                    {grid}
                </div>
            </div>
        </>
    );
};

export default DetailsGridLayout;