import React, {useEffect, useState} from 'react';
import {SelectModel} from "../../../../../../../app/types/SelectModel";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {useDataHelperApi} from "../../../../../../../app/api/dataHelper";
import {useAppSelector} from "../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../features/authentication/authenticationSliceSelectors";

type ConfirmAuctionServiceProviderSelectorProps = {
    disabled: boolean;
    value: string | null;
    onChange: (item: SelectModel) => void;
};

const ConfirmAuctionServiceProviderSelector: React.FC<ConfirmAuctionServiceProviderSelectorProps> = ({
                                                                                                         value,
                                                                                                         disabled,
                                                                                                         onChange
                                                                                                     }) => {
    const user = useAppSelector(selectCurrentUser);

    const [data, setData] = useState<Array<SelectModel>>([]);
    const {
        getUserServiceProviderList: {
            query: getUserServiceProviderList,
            isLoading: getUserServiceProviderListIsLoading,
            cancel: cancelGetUserServiceProviderList
        },
        getUserServiceProviderListAnonymous: {
            query: getUserServiceProviderListAnonymous,
            isLoading: getUserServiceProviderListAnonymousIsLoading,
            cancel: cancelGetUserServiceProviderListAnonymous
        }
    } = useDataHelperApi();

    useEffect(() => {

        (async () => {
            try {
                const response = user
                    ? await getUserServiceProviderList()
                    : await getUserServiceProviderListAnonymous();

                if (response?.status === 200 && response.data) {
                    setData(response.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error(`Unable to get service providers list: ${e.message}`);
                }
            }
        })();

        return () => {
            cancelGetUserServiceProviderList();
            cancelGetUserServiceProviderListAnonymous();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={'drop-down-picker'}>
            <DropDownList data={data}
                          className={'confirm-auction-service-provider'}
                          disabled={disabled || getUserServiceProviderListAnonymousIsLoading || getUserServiceProviderListIsLoading}
                          loading={getUserServiceProviderListAnonymousIsLoading || getUserServiceProviderListIsLoading}
                          textField="text"
                          dataItemKey="value"
                          value={value !== null ? data.find(e => e.value === value) : null}
                          onChange={(ev) => {
                              onChange(ev.value);
                          }}
            />
        </div>
    );
};

export default ConfirmAuctionServiceProviderSelector;