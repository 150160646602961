import React, {useEffect, useRef, useState} from 'react';
import cl from './SidebarContextMenu.module.css';
import {createPortal} from "react-dom";
import {useClickOutside} from "../../../../../hooks/useOnClickOutside/useOnClickOutside";

export type SidebarContextMenuItem = {
    id: string;
    isSelected?: boolean;
    text: string;
    title: string;
    onClick: () => void;
};

type SidebarContextMenuProps = {
    target: React.MutableRefObject<HTMLElement | null>
    id: string;
    opened: boolean;
    setOpened: React.Dispatch<React.SetStateAction<boolean>>;
    closeOnClickOutside?: boolean;
    options: Array<SidebarContextMenuItem>;
    verticalPosition: 'bottom' | 'top';
    horizontalPosition: 'left' | 'right' | 'center';
    width: number;
};

const SidebarContextMenu: React.FC<SidebarContextMenuProps> = ({
                                                                   id,
                                                                   target,
                                                                   closeOnClickOutside,
                                                                   setOpened,
                                                                   opened,
                                                                   verticalPosition,
                                                                   horizontalPosition,
                                                                   width,
                                                                   options
                                                               }) => {
    const popup = useRef<HTMLDivElement | null>(null);

    const [point, setPoint] = useState<{
        top: number,
        left: number
    } | null>();

    useClickOutside(popup, (ev) => {
        if (ev.target && target.current && target.current?.contains(ev.target as Node)) {
            return;
        }

        if (closeOnClickOutside) {
            setOpened(false);
        }
    });

    useEffect(() => {
        if (!target || !target.current || !opened) {
            return;
        }

        const resize = () => {
            setupPoint();
        };

        const setupPoint = () => {
            if (!target || !target.current || !opened) {
                return;
            }

            let position = target.current?.getBoundingClientRect();

            if (position) {
                let y: number = 0;
                let x: number = 0;

                switch (verticalPosition) {
                    case "bottom":
                        y = position.top + target.current?.clientHeight ?? 0
                        break;
                    case "top":
                        y = position.top - 2 - options.length * 40
                        break;
                }

                switch (horizontalPosition) {
                    case "center":
                        x = position.left + (position.width - width) / 2;
                        break;
                    case "left":
                        x = position.left;
                        break;
                    case "right":
                        x = position.right - width
                        break;
                }

                setPoint({
                    top: y,
                    left: x
                });
            }
        }

        setupPoint();

        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        }
    }, [target, opened, options, width]);

    return (
        <>
            {opened && point && createPortal(
                <div id={id}
                     style={{left: point.left, top: point.top, width: `${width}px`}} className={`${cl.menu}`}
                     ref={popup}>
                    {options.map(opt => {
                        return (
                            <div id={opt.id}
                                 key={opt.id}
                                 title={opt.title}
                                 className={`${cl.option} ${opt.isSelected ? cl.selected : ''}`}
                                 onClick={opt.onClick}>
                                {opt.text}
                            </div>
                        )
                    })}
                </div>,
                document.body
            )}
        </>
    );
};

export default SidebarContextMenu;