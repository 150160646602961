import React, {ReactNode} from 'react';
import cl from './Alert.module.css';

type AlertProps = {
    id?: string;
    children: ReactNode;
    type: 'Error' | 'YellowInfo' | 'BrightGrayInfo' | 'Success' | 'OrangeInfo';
    style?: React.CSSProperties;
}

const Alert: React.FC<AlertProps> = ({
                                         id,
                                         children,
                                         type,
                                         style
                                     }) => {
    return (
        <div className={cl.container}
             id={id}
             data-type={type}
             style={style}>
            {children}
        </div>
    );
};

export default Alert;