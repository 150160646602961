import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import useInvoiceApi from "../../../../../../app/api/invoice";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import FormLoadingScreen from "../../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {IShipmentInvoice} from "../../../../../../app/interfaces/invoice/IShipmentInvoice";
import {IShipment} from "../../../../../../app/interfaces/shipment/IShipment";
import ShipmentInvoiceForm from "../../../../Invoice/form/components/ShipmentInvoiceForm/ShipmentInvoiceForm";

type PaymentStatusSectionProps = {
    shipment: IShipment;
};

const PaymentStatusSection: React.FC<PaymentStatusSectionProps> = ({
                                                                       shipment
                                                                   }) => {
    const user = useAppSelector(selectCurrentUser);

    const [model, setModel] = useState<IShipmentInvoice | null>(null);

    const {
        getShipmentInvoiceAuthorize: {
            query: getShipmentInvoiceAuthorize,
            isLoading: getShipmentInvoiceAuthorizeIsLoading,
            cancel: cancelGetShipmentInvoiceAuthorize
        }
    } = useInvoiceApi();

    useEffect(() => {

        (async () => {
            await load();
        })();

        return () => {
            cancelGetShipmentInvoiceAuthorize();
        }
    }, []);

    const load = async () => {
        if (!shipment.shipmentGid || !shipment.shipmentXid) {
            return;
        }

        try {

            const shipmentInvoiceResponse =
                await getShipmentInvoiceAuthorize(shipment.shipmentGid, shipment.shipmentXid);

            if (shipmentInvoiceResponse?.status === 200 &&
                shipmentInvoiceResponse.data?.resultCode === ResponseResultCode.Ok &&
                shipmentInvoiceResponse.data.data) {
                setModel(shipmentInvoiceResponse.data.data);
            }

        } catch (err) {
            if (isAxiosError(err)) {
                toast.error(`Unable to get shipment invoice. Error: ${err.message}`);
            }
        }
    }

    if (!user || !shipment.shipmentGid || !shipment.serviceProviderId || !model || getShipmentInvoiceAuthorizeIsLoading) {
        return <FormLoadingScreen height={`100%`} style={{padding: '5px'}}/>;
    }

    return (
        <div className={`full details h100 hidden`} style={{padding: '10px'}}>
            <div className={'o-auto h100'}>
                <ShipmentInvoiceForm data={model} showTotalSum={true} isSidePanelDetails={false}
                                     showCollapse={false}
                                     user={user}/>
            </div>
        </div>
    );
};

export default PaymentStatusSection;
