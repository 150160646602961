import React, {useEffect, useRef, useState} from 'react';
import GridLayoutGrid from "../../../../../../components/ui/Grid/Telerik/GridLayoutGrid";
import {Query} from "../../../../../../app/types/Query";
import {useParams} from "react-router-dom";
import {useGridLayoutContext} from "../../../../../../components/uiLayouts/GridLayout/hooks/useGridLayoutContext";
import {IShipmentHeaderConfig} from "../../data/interfaces/IShipmentHeaderConfig";
import {
    ApplicationHubMessageAction,
    IApplicationHubMessage
} from "../../../../../../app/signalR/applicationHub/IApplicationHubMessage";
import {GridService} from "../../../../../../components/ui/Grid/Telerik/api/GridService";
import {buildShipmentFilterQuery, buildShipmentQuery} from "../../utils";
import {
    useGridFilterPanelLayoutPreload
} from "../../../../../../components/uiLayouts/GridFilterPanelLayout/hooks/useGridFilterPanelLayoutPreload";
import {LayoutConfigEntity} from "../../../../../../app/enums/LayoutConfigEntity";
import {useAppDispatch, useAppSelector} from "../../../../../../app/store";
import {selectAccessSettings, selectQuery} from "../../../../../../features/filter/filterSliceSelectors";
import {setFilterQuery} from "../../../../../../features/filter/filterSlice";
import {IUserAccessSettings} from "../../../../../../app/interfaces/user/IUserAccessSettings";
import {buildAccessQuery} from "../../../../../../components/uiLayouts/GridLayout/context";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";

const GRID_DATA_URL = `/api/${process.env.REACT_APP_API_VERSION}/shipment/getShipments`

type ShipmentGridContentProps = {
    onRowClick: (data: any) => void;
    onRowDoubleClick: (data: any) => void;
}

const ShipmentGridContent: React.FC<ShipmentGridContentProps> = ({
                                                                     onRowClick,
                                                                     onRowDoubleClick
                                                                 }) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectCurrentUser);
    const [query, setQuery] = useState<Query | undefined>();
    const [headerQuery, setHeaderQuery] = useState<Query | undefined>();
    const [accessSettingsQuery, setAccessSettingsQuery] = useState<Query | undefined>();
    const stateRef = useRef();
    const filterQuery = useAppSelector(state =>
        selectQuery(state, LayoutConfigEntity.Shipment));
    const settings = useAppSelector(selectAccessSettings);
    const {type} = useParams();

    const skipLoading = useRef<boolean>((type === 'archive' && user && (getUserRole(user) === UserRole.Administrator || getUserRole(user) === UserRole.Forwarder)) ?? false);

    const {
        headerConfig,
        setSelectedRows
    } = useGridLayoutContext<IShipmentHeaderConfig>();


    useGridFilterPanelLayoutPreload({
        entity: LayoutConfigEntity.Shipment,
        selectedPersonalFilter: headerConfig?.pinnedFilter ?? null,
        selectedPredefinedFilter: headerConfig?.pinnedPredefinedFilter ?? null,
        onComplete: (personal, predefined, filter) => {
            dispatch(setFilterQuery({
                entity: LayoutConfigEntity.Shipment,
                data: buildShipmentFilterQuery(personal, predefined, filter).getParams()
            }));
        },
        onChange: (personal, predefined, filter) => {

            dispatch(setFilterQuery({
                entity: LayoutConfigEntity.Shipment,
                data: buildShipmentFilterQuery(personal, predefined, filter).getParams()
            }))
        }
    });


    useEffect(() => {
        const onShipmentApplicationHubMessage = (ev: any) => {
            let typedEv = ev.detail as IApplicationHubMessage;
            if (typedEv.action === ApplicationHubMessageAction.Update && typedEv.payload && typedEv.payload !== '') {
                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'getItem',
                        field: 'id',
                        value: typedEv.payload,
                        callback: (item: any) => {
                            if (item !== null && item !== undefined && typedEv.payload) {
                                let service = new GridService(GRID_DATA_URL);

                                let q = new Query();
                                if (stateRef.current) {
                                    q.addParamsFromQuery(stateRef.current ?? null)
                                }


                                q.addParam('sid', typedEv.payload);

                                service.execute({
                                    skip: 0,
                                    take: 1
                                }, q).then(data => {
                                    if (data.data && data.data.length === 1) {
                                        let item = data.data[0];

                                        item.isRowLoading = false;

                                        document.dispatchEvent(new CustomEvent('onGridMessage', {
                                            detail: {
                                                action: 'updateRow',
                                                rows: [
                                                    {...item}
                                                ]
                                            }
                                        }));
                                    }
                                });
                            } else {
                                document.dispatchEvent(new CustomEvent('onGridMessage', {
                                    detail: {
                                        action: 'refresh'
                                    }
                                }));
                            }
                        }
                    }
                }));
            }
        }

        document.addEventListener('onShipmentApplicationHubMessage', onShipmentApplicationHubMessage);

        return () => {
            document.removeEventListener('onShipmentApplicationHubMessage', onShipmentApplicationHubMessage);
        }
    }, []);


    useEffect(() => {

        let onAccessSettingsUpdate = (ev: any) => {
            setAccessSettingsQuery(buildAccessQuery(new Query(), ev.detail.data as IUserAccessSettings));
        }

        document.addEventListener('onAccessSettingsUpdate', onAccessSettingsUpdate);


        setHeaderQuery(buildShipmentQuery(type ?? 'current', headerConfig));

        skipLoading.current = !!(type === 'archive' && user && (getUserRole(user) === UserRole.Administrator || getUserRole(user) === UserRole.Forwarder));

        return () => {
            document.removeEventListener('onAccessSettingsUpdate', onAccessSettingsUpdate);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, headerConfig]);

    useEffect(() => {

        // @ts-ignore
        stateRef.current = headerQuery;

        let q = new Query();

        if (filterQuery) {
            q.addParamsFromQuery(filterQuery ? Query.ToQuery(filterQuery) : null)
        }

        if (headerQuery) {
            q.addParamsFromQuery(headerQuery ?? null)
        }

        if (accessSettingsQuery && accessSettingsQuery.getParams().length > 0) {
            q.addParamsFromQuery(accessSettingsQuery);
        }

        if(headerConfig && headerConfig.isGroupByJob) {
            q.addParam('group', 'true')
        }

        q = buildAccessQuery(q, settings);

        setQuery(q);
    }, [headerQuery, filterQuery, accessSettingsQuery]);

    useEffect(() => {
        if (skipLoading.current && filterQuery && filterQuery.length > 0) {
            skipLoading.current = false;
        }
    }, [filterQuery]);

    return (
        <GridLayoutGrid id={'shipment-grid'}
                        url={GRID_DATA_URL}
                        query={query}
                        selectionMode={'multiple'}
                        onRowDoubleClick={onRowDoubleClick}
                        onRowClick={onRowClick}
                        onSelect={(rows) => setSelectedRows(rows)}
                        showCellContextMenu={true}
                        skipLoading={skipLoading.current}/>
    );
};

export default ShipmentGridContent;
