import React from 'react';
import {RouteSectionModel} from "../../RouteSection";
import {useTranslation} from "react-i18next";

type IncoTermsProps = {
    model: RouteSectionModel;
}

const IncoTerms: React.FC<IncoTermsProps> = ({model}) => {
    const {t} = useTranslation();

    return (
        <>
            <label className={'incoterms-label'}>{t('quote.form.incoterms')}</label>
            <span className={'details-box-item-field-value incoterms-value'}>
                 {model.incoterms !== undefined && model.incoterms !== null && model.incoterms !== ''
                     ? <>{model.incoterms}</>
                     : <>{t("shared.labels.no-data")}</>
                 }
            </span>
        </>
    );
};

export default IncoTerms;