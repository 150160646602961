import React from 'react';
import {useAppSelector} from "../../../../app/store";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSliceSelectors";
import {UserRole} from "../../../../app/enums/UserRole";
import * as ServiceProviderNotificationContent from "./ServiceProvider/NotificationContent";
import * as ForwarderNotificationContent from "./Forwarder/NotificationContent";
import * as AdministratorNotificationContent from "./Administrator/NotificationContent";

const Notification: React.FC = () => {
    const user = useAppSelector(selectCurrentUser);

    if (!user) {
        return null;
    }

    return (
        <>
            {user.role === UserRole.ServiceProvider &&
                <ServiceProviderNotificationContent.default/>
            }

            {user.role === UserRole.Forwarder
                ? <ForwarderNotificationContent.default/>
                : null
            }

            {user.role === UserRole.Administrator
                ? <AdministratorNotificationContent.default/>
                : null
            }
        </>
    );
};


export default Notification;