import React from 'react';
import {OrderType} from "../../../../../../app/enums/OrderType";
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../ui/Dialog/Dialog";
import DisassociateTag from "./DisassociateTag";

type DisassociateTagDialogProps = {
    tagId: number;
    orderReleaseGid: string;
    orderType: OrderType;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const DisassociateTagDialog: React.FC<DisassociateTagDialogProps> = ({
                                                                         tagId,
                                                                         orderType,
                                                                         orderReleaseGid,
                                                                         show,
                                                                         setShow
                                                                     }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'disassociate-tag-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.btn.delete-tag")}>
            <DisassociateTag tagId={tagId}
                             orderReleaseGid={orderReleaseGid}
                             orderType={orderType}
                             close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default DisassociateTagDialog;