import React from 'react';
import useGridPageParameterAndRoleValidation
    , {
    RoleAllowedParameter
} from "../../../../../hooks/useGridPageParameterAndRoleValidation/useGridPageParameterAndRoleValidation";
import {UserRole} from "../../../../../app/enums/UserRole";
import ShipmentGrid from '../ShipmentGrid';



const ShipmentGridNoValidationWrapper = () => {


    return <ShipmentGrid/>;
};

export default ShipmentGridNoValidationWrapper;
