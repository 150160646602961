import {DataResult, State} from "@progress/kendo-data-query";
import {Query} from "../../../../../app/types/Query";
import uuid from "react-uuid";
import apiAxios from "../../../../../app/axios/apiAxios";

export class GridService {
    private readonly BASE_URL: string;
    private aborts: Array<{
        abort: AbortController,
        id: string
    }> = [];

    constructor(url: string) {
        this.BASE_URL = url;
    }

    public execute(state: State, query?: Query): Promise<DataResult> {
        return this.get(state, query);
    }

    public cancelAllRequests(): void {
        if (this.aborts.length > 0) {
            this.aborts.forEach((item) => {
                item.abort.abort('r-condition')
            });

            this.aborts = [];
        }
    }

    private async get(state: State, query?: Query): Promise<DataResult> {
        try {
            if (this.aborts.length > 0) {
                this.aborts.forEach((item) => {
                    item.abort.abort('r-condition')
                });

                this.aborts = [];
            }

            const abort = new AbortController();

            this.aborts.push({
                id: uuid(),
                abort
            });

            let queryStr: string = '';
            let defaultQuery = this.BuildDefaultQuery(state);

            if (query && defaultQuery) {
                queryStr = defaultQuery.addParamsFromQuery(query).toString();
            } else if (query) {
                queryStr = query.toString();
            } else if (defaultQuery) {
                queryStr = defaultQuery.toString();
            }

            const response = await apiAxios.get(
                `${this.BASE_URL}?${queryStr}`, {
                    signal: abort.signal
                });

            if (response.status === 200) {
                return {
                    total: response.data.count,
                    data: response.data.result
                };
            }
        } catch (e) {
            throw e;
        }

        return {
            total: 0,
            data: []
        };
    }

    private BuildDefaultQuery(state: State): Query | null {
        if (state.take === undefined && state.skip === undefined) {
            return null;
        }

        let q = new Query();

        if (state.take !== undefined) {
            q.addParam('take', state.take.toString());
        }

        if (state.skip !== undefined) {
            q.addParam('skip', state.skip.toString());
        }

        if (state.sort !== undefined && state.sort.length > 0) {
            let item = state.sort[0];
            if (item) {
                q.addParam('sort', `${item.field} ${item.dir === 'asc' ? 'ascending' : 'descending'}`)
            }
        }

        return q;
    }
}