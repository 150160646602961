import React, {useEffect, useState} from 'react';
import cl from './SelfBillingLoginFormContent.module.css';
import {ILogin, ILoginUser} from "../../../../../../app/interfaces/login/ILogin";
import {useTranslation} from "react-i18next";
import useGoogleReCaptchaV3 from "../../../../../../hooks/useGoogleReCaptchaV3/useGoogleReCaptchaV3";
import {useNavigate} from "react-router-dom";
import {useLoginApi} from "../../../../../../app/api/login";
import {ServerError} from "../../../../../../hooks/useServerError/useServerError";
import {IResponse, isResponse} from "../../../../../../app/interfaces/response/IResponse";
import useIfFirstRender from "../../../../../../hooks/useIsFirstRender/useIfFirstRender";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import {IUserDevice} from "../../../../../../app/interfaces/user/IUserDevice";
import {getUserDevice} from "../../../../../../utils/device";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import {AxiosError} from "axios";
import {UserDeviceType} from "../../../../../../app/enums/UserDeviceType";
import {motion} from "framer-motion";
import ServerErrorLabel from "../../../../../../components/ui/ServerErrorLabel/ServerErrorLabel";
import LoginInput from "../../../../../../components/ui/LoginInput/LoginInput";
import {faLock, faUser} from "@fortawesome/free-solid-svg-icons";
import Button, {ButtonType} from "../../../../../../components/ui/Button/Button";
import useSelfBillingApi from "../../../../../../app/api/selfBilling";
import {useAppDispatch} from "../../../../../../app/store";
import {validateUserAuthentication} from "../../../../../../features/authentication/authenticationSlice";

const initialValues: ILogin = {
    login: '',
    password: '',
    token: '',
    userDevice: {
        deviceId: '',
        browserVersion: '',
        browserName: '',
        deviceType: UserDeviceType.PersonalComputer
    }
};

type SelfBillingLoginFormContentProps = {
    selectedUser?: ILoginUser | null;
    componentName: string;
};

const SelfBillingLoginFormContent: React.FC<SelfBillingLoginFormContentProps> = ({
                                                                                     selectedUser
                                                                                 }) => {
    const {t, i18n} = useTranslation();
    const {loaded, executeReCaptcha} = useGoogleReCaptchaV3({
        siteKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '',
        language: i18n.language
    });
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [error, setError] = useState<ServerError | IResponse>();
    const isFirstRender = useIfFirstRender();

    const {
        login: {
            mutation
        }
    } = useLoginApi();

    const {
        getSelfBillingOverviewAnonymous: {
            query
        }
    } = useSelfBillingApi();

    const form = useFormik<ILogin>({
        initialValues,
        validationSchema: Yup.object({
            login: Yup.string()
                .required(t("shared.errors.required-error").replace('{0}', t("login.labels.login")))
                .max(100),
            password: Yup.string()
                .required(t("shared.errors.required-error").replace('{0}', t("login.labels.password")))
                .max(100),
        }),
        onSubmit: async (values: ILogin, actions: FormikHelpers<ILogin>) => {
            try {
                actions.setSubmitting(true);

                if (!loaded || !executeReCaptcha) {
                    setError(t('shared.errors.google-recaptcha-loaded'));

                    return;
                }

                const userDevice: IUserDevice = await getUserDevice();
                const token: string = await executeReCaptcha('login/submit');

                if (!token) {
                    setError(t('shared.error.google-recaptcha-loaded'));
                }

                const response = await mutation({
                    userDevice,
                    token,
                    login: values.login,
                    password: values.password
                });

                if (response?.data !== undefined &&
                    response.data.resultCode === ResponseResultCode.Ok) {
                    const response = await query();

                    if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok &&
                        response.data.data) {
                        dispatch(validateUserAuthentication());
                        navigate(`/shipment/${response.data.data.shipmentGid}/${response.data.data.orderReleaseXid}?t=2`);
                    }
                }

            } catch (error) {
                if (error instanceof AxiosError) {
                    if (error.response !== undefined &&
                        error.response.data !== undefined &&
                        isResponse(error.response.data)) {
                        setError(error.response.data as IResponse);
                    } else {
                        setError({
                            resultCode: ResponseResultCode.ServerError,
                            message: error.message
                        });
                    }
                }
            } finally {
                actions.setSubmitting(false);
            }
        }
    });

    useEffect(() => {
        if (form) {
            if (!isFirstRender) {
                form.validateForm().then(() => {
                    //ignore
                });
            } else {
                if (selectedUser !== null && selectedUser !== undefined) {
                    form.setFieldValue('login', selectedUser.login);
                    form.submitForm().then(() => {
                        //ignore
                    });
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFirstRender, i18n.language]);

    return (
        <motion.div key={'login-form-content'}
                    className={'pd-10'}
                    initial={{opacity: 0.5}}
                    animate={{opacity: 1}}
                    transition={{duration: 1}}
                    exit={{opacity: 0.5}}>
            <div style={{marginBottom: '10px'}}>
                <ServerErrorLabel entity={'login'}
                                  error={error}
                                  onErrorCleared={() => setError(undefined)}/>
            </div>
            <form onSubmit={form.handleSubmit}>
                <div className={cl.relative}>
                    <LoginInput id="login"
                                icon={faUser}
                                name="login"
                                autoComplete="login"
                                type='text'
                                disabled={form.isSubmitting || selectedUser !== undefined}
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                    if (ev && ev.target && ev.target.value) {
                                        form.setFieldValue('login', ev.target.value.toUpperCase());
                                    } else {
                                        form.setFieldValue('login', '');
                                    }
                                }}
                                value={form.values.login}
                                error={form.touched.login && form.errors.login ? form.errors.login : undefined}
                                placeholder={t("login.labels.login") ?? ''}/>
                </div>
                <div>
                    <LoginInput icon={faLock}
                                type='password'
                                placeholder={t("login.labels.password") ?? ''}
                                id="password"
                                name="password"
                                autoComplete="current-password"
                                disabled={form.isSubmitting}
                                onChange={form.handleChange}
                                value={form.values.password}
                                error={form.touched.password && form.errors.password ? form.errors.password : undefined}/>
                </div>
                <div className={cl.buttonContainer}>
                    <Button buttonType={ButtonType.Primary}
                            type='submit'
                            disabled={form.isSubmitting}
                            isLoading={form.isSubmitting}
                            style={{width: '70%'}}>
                        {t("shared.btn.login")}
                    </Button>
                </div>
            </form>

            <hr className={cl.separator}/>




        </motion.div>
    );
};

export default SelfBillingLoginFormContent;
