import faAsstrALogo from '../icon/icons/asstraLogo';
import faChevronRight from '../icon/icons/chevronRight';
import faChevronLeft from '../icon/icons/chevronLeft';
import faGlobe from '../icon/icons/globe';
import faCircleQuestion from '../icon/icons/circleQuestion';
import faEnvelope from '../icon/icons/envelope';
import faGear from '../icon/icons/gear';
import faBell from '../icon/icons/bell';
import faMessages from '../icon/icons/messages';
import faThinChevronUp from '../icon/icons/thinChevronUp';
import faThinChevronDown from '../icon/icons/thinChevronDown';
import faBook from '../icon/icons/book';
import faDashboard from '../icon/icons/dashboard';
import faCubePlus from '../icon/icons/cubePlus';
import faCubeApproved from '../icon/icons/cubeApproved';
import faTruck from '../icon/icons/truck';
import faMagnifyingGlass from '../icon/icons/magnifyingGlass';
import faEllipsisVertical from '../icon/icons/ellipsisVertical';
import faFloppyDisk from '../icon/icons/floppyDisk';
import faTrash from '../icon/icons/trash';
import faBookmark from '../icon/icons/bookmark';
import faBarFilter from '../icon/icons/barFilter';
import faXMark from '../icon/icons/xMark';
import faArrowsRotate from '../icon/icons/arrowsRotate';
import faUpload from '../icon/icons/upload';
import faList from '../icon/icons/list';
import faEllipsisHorizontal from '../icon/icons/ellipsisHorizontal';
import faPlus from '../icon/icons/plus';
import faClose from '../icon/icons/xMarkDialog';
import faTriangle from '../icon/icons/triangle';
import faCircle from '../icon/icons/circle';
import faCheck from '../icon/icons/check';
import faTriangleExclamation from '../icon/icons/triangleExclamation';
import faPrint from '../icon/icons/print';
import faMessage from '../icon/icons/message';
import faCircleLocationDot from '../icon/icons/circleLocationDot';
import faNoUser from '../icon/icons/noUser';
import faPen from '../icon/icons/pen';
import faCircleDollar from '../icon/icons/circleDollar';
import faBroadcast from '../icon/icons/broadcast';
import faTruckTracking from '../icon/icons/truckTracking';
import faThumbTack from '../icon/icons/thumbTack';
import faHollowCircle from '../icon/icons/hollowCircle';
import faCircleArrowRight from '../icon/icons/circleArrowRight';
import faArrowLeft from '../icon/icons/arrowLeft';
import faArrowRight from '../icon/icons/arrowRight';
import faRecordVinyl from '../icon/icons/recordVinyl';
import faLocationDot from '../icon/icons/locationDot';
import faCopy from '../icon/icons/copy';
import faClock from '../icon/icons/clock';
import faFilter from '../icon/icons/filter';

export const icons = {
    faAsstrALogo,
    faChevronRight,
    faChevronLeft,
    faGlobe,
    faCircleQuestion,
    faEnvelope,
    faGear,
    faBell,
    faMessages,
    faThinChevronDown,
    faThinChevronUp,
    faBook,
    faDashboard,
    faCubeApproved,
    faCubePlus,
    faTruck,
    faMagnifyingGlass,
    faEllipsisVertical,
    faFloppyDisk,
    faTrash,
    faBookmark,
    faBarFilter,
    faXMark,
    faClose,
    faArrowsRotate,
    faUpload,
    faList,
    faEllipsisHorizontal,
    faPlus,
    faTriangle,
    faCircle,
    faCheck,
    faTriangleExclamation,
    faPrint,
    faMessage,
    faCircleLocationDot,
    faNoUser,
    faPen,
    faCircleDollar,
    faBroadcast,
    faTruckTracking,
    faThumbTack,
    faHollowCircle,
    faCircleArrowRight,
    faArrowLeft,
    faArrowRight,
    faRecordVinyl,
    faLocationDot,
    faCopy,
    faClock,
    faFilter,
};
