import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {useOfferApi} from "../../../../../app/api/offer";
import {isAxiosError} from "axios";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {IRecallBetGetAction} from "../../../../../app/interfaces/offer/IRecallBetGetAction";
import {IRecallBetPostAction} from "../../../../../app/interfaces/offer/IRecallBetPostAction";
import {BLErrorCode} from "../../../../../app/enums/BLErrorCode";
import Alert from "../../../../../components/ui/Alert/Alert";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import RecallBetForm from "./RecallBetForm/RecallBetForm";
import {useAppSelector} from "../../../../../app/store";
import {selectCurrentUser} from "../../../../../features/authentication/authenticationSliceSelectors";

type RecallBetProps = {
    betId: number;
    orderReleaseGid: string;
    close?: () => void;
};

const alertStyle: React.CSSProperties = {
    fontSize: '13px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '10px 5px'
}

const RecallBet: React.FC<RecallBetProps> = ({
                                                 betId,
                                                 orderReleaseGid,
                                                 close
                                             }) => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [model, setModel] = useState<IRecallBetGetAction | null>(null);
    const [errors, setErrors] = useState<Array<IError>>([]);
    const user = useAppSelector(selectCurrentUser);

    const {
        getRecallBetData: {
            query: getRecallBetData,
            isLoading: getRecallBetDataIsLoading,
            cancel: cancelGetRecallBetData
        },
        recallBet: {
            mutation: recallBet,
            cancel: cancelRecallBet
        },
        getRecallBetDataAnonymous: {
            query: getRecallBetDataAnonymous,
            isLoading: getRecallBetDataAnonymousIsLoading,
            cancel: cancelGetRecallBetDataAnonymous
        },
        recallBetAnonymous: {
            mutation: recallBetAnonymous,
            cancel: cancelRecallBetAnonymous
        }
    } = useOfferApi();

    useEffect(() => {
        if (betId <= 0) {
            if (close) {
                close()
            } else {
                return;
            }
        }

        (async () => {
            try {
                const response = user
                    ? await getRecallBetData(betId)
                    : await getRecallBetDataAnonymous(betId);

                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch (err) {
                if (isAxiosError(err)) {
                    if (err.response && err.response.data && err.response.status === 422 &&
                        err.response.data.resultCode === ResponseResultCode.NotValidData &&
                        err.response.data.errors && Array.isArray(err.response.data.errors)) {
                        setModel(err.response.data.data);
                        setErrors(err.response.data.errors);
                    } else if (close) {
                        close();
                        toast.error(err.message);
                    }
                }
            }
        })();

        return () => {
            cancelGetRecallBetData();
            cancelRecallBet();

            cancelGetRecallBetDataAnonymous();
            cancelRecallBetAnonymous();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [betId]);

    const save = async (data: IRecallBetPostAction): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            const response = user
                ? await recallBet(data)
                : await recallBetAnonymous(data);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRow',
                        rows: [
                            {
                                id: orderReleaseGid,
                                isRowLoading: true
                            }
                        ]
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.offer")} ${orderReleaseGid}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();
                    toast.error(err.message);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if (getRecallBetDataIsLoading || getRecallBetDataAnonymousIsLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'w100'}>
            <div className={'dialog-default-content-container'}>
                {errors.length > 0
                    ? errors.map((err, index) => {
                        switch (err.errorCode) {
                            case BLErrorCode.InnerError:
                                return (
                                    <Alert type={'Error'}
                                           id={`offer-recall-bet-error-${index}`}
                                           style={alertStyle}
                                           key={`${err.errorCode}-${err.description}`}>
                                        {`${err.description}`}
                                    </Alert>
                                );
                        }

                        return null;
                    })
                    : null
                }

                <RecallBetForm model={model}
                               disabled={disabled}
                               save={save}/>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        form='recall-bet-offer-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.confirm")}
                </Button>
            </div>
        </div>
    );
};

export default RecallBet;
