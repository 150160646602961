import {
    useAddCustomerAttachmentData,
    useGetAddCustomerAttachmentData,
    useGetCustomerAttachmentDocs,
    useDownloadCustomerAttachment,
    useDeleteCustomerAttachmentDoc
} from "./requests";

const useCustomerAttachmentApi = () => {
    const {
        isLoading: addCustomerAttachmentIsLoading,
        cancel: cancelAddCustomerAttachment,
        addCustomerAttachment
    } = useAddCustomerAttachmentData();

    const {
        isLoading: getAddCustomerAttachmentDataIsLoading,
        cancel: cancelGetAddCustomerAttachmentData,
        getAddCustomerAttachmentData
    } = useGetAddCustomerAttachmentData();

    const {
        isLoading: getCustomerAttachmentDocsIsLoading,
        cancel: cancelCustomerAttachmentDocs,
        getCustomerAttachmentDocs
    } = useGetCustomerAttachmentDocs();

    const {
        isLoading: downloadCustomerAttachmentIsLoading,
        cancel: cancelDownloadCustomerAttachment,
        downloadCustomerAttachment
    } = useDownloadCustomerAttachment();

    const {
        isLoading: deleteCustomerAttachmentDocIsLoading,
        cancel: cancelDeleteCustomerAttachmentDoc,
        deleteCustomerAttachmentDoc
    } = useDeleteCustomerAttachmentDoc();
    

    return {
        addCustomerAttachment: {
            mutation: addCustomerAttachment,
            isLoading: addCustomerAttachmentIsLoading,
            cancel: cancelAddCustomerAttachment
        },

        getAddCustomerAttachmentData: {
            query: getAddCustomerAttachmentData,
            isLoading: getAddCustomerAttachmentDataIsLoading,
            cancel: cancelGetAddCustomerAttachmentData
        },
        getCustomerAttachmentDocs: {
            queryCustomerAttachmentDocs: getCustomerAttachmentDocs,
            isLoadingCustomerAttachmentDocs: getCustomerAttachmentDocsIsLoading,
            cancelCustomerAttachmentDocs: cancelCustomerAttachmentDocs
        },
        downloadCustomerAttachment: {
            query: downloadCustomerAttachment,
            isLoading: downloadCustomerAttachmentIsLoading,
            cancel: cancelDownloadCustomerAttachment
        },

        deleteCustomerAttachmentDoc: {
            query: deleteCustomerAttachmentDoc,
            isLoading: deleteCustomerAttachmentDocIsLoading,
            cancel: cancelDeleteCustomerAttachmentDoc
        },
    };
};

export default useCustomerAttachmentApi;