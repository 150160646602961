import {useCallback, useState} from 'react';
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import apiAxios from "../../app/axios/apiAxios";

export type UseAxiosReturn<T, V> = {
    isLoading: boolean;
    cancel: () => void;
    execute: (data?: V, overrideParams?: AxiosRequestConfig) => Promise<AxiosResponse<T, V> | undefined>;
}

function useAxios<T extends unknown = void, V extends unknown = void>(params: AxiosRequestConfig): UseAxiosReturn<T, V> {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [abort] = useState<AbortController>(new AbortController());

    const execute = async (data?: V, overrideParams?: AxiosRequestConfig): Promise<AxiosResponse<T, V> | undefined> => {
        setIsLoading(prev => !prev);

        try {
            return await apiAxios.request<T, AxiosResponse<T, V>>({
                signal: abort.signal,
                data,
                ...overrideParams,
                ...params
            });
        } catch (e) {
            setIsLoading(false);

            if (!(e instanceof AxiosError && axios.isCancel(e))) {
                if (console && console.clear !== undefined) {
                    console.clear();
                }

                throw e;
            }
        } finally {
            setIsLoading(false)
        }
    }

    const cancel = useCallback((): void => abort.abort(), [abort]);

    return {isLoading, cancel, execute}
}

export default useAxios;
