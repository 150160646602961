import React, {useState} from 'react';
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type ChatPanelActionButtonProps = {
    title: string;
    icon: IconProp;
    color: string;
    onClick: () => void;
    defaultView?: boolean;
    size?: number;
};

const ChatPanelActionButton: React.FC<ChatPanelActionButtonProps> = ({
                                                                         title,
                                                                         icon,
                                                                         color,
                                                                         onClick,
                                                                         defaultView = false,
                                                                         size = 14
                                                                     }) => {
    const [iconVisible, setIconVisible] = useState<boolean>(false);

    if (defaultView) {
        return (
            <button className={`transition-all-500 chat-panel-action-button`}
                    onMouseEnter={() => setIconVisible(true)}
                    onMouseLeave={() => setIconVisible(false)}
                    onClick={onClick}
                    title={title}
                    style={{
                        backgroundColor: color,
                        color: iconVisible ? 'white' : color
                    }}>
                <FontAwesomeIcon icon={icon}/>
            </button>
        );
    }

    return (
        <button className={`transition-all-500 chat-panel-action-n-button`}
                onClick={onClick}
                title={title}
                style={{
                    backgroundColor: 'inherit',
                    color: '#9a9eae'
                }}>
            <FontAwesomeIcon icon={icon}
                             style={{
                                 fontSize: `${size}px`
                             }}/>
        </button>
    );
};

export default ChatPanelActionButton;