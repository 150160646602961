import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {IRemarkGetAction} from "../../../../../app/interfaces/offer/IRemarkGetAction";
import {useOfferApi} from "../../../../../app/api/offer";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {IRemarkPostAction} from "../../../../../app/interfaces/offer/IRemarkPostAction";
import EditRemarkForm from "./EditRemarkForm/EditRemarkForm";

type EditRemarkProps = {
    id: string;
    code: string | null;
    seq: string | null;
    close?: () => void;
};

const EditRemark: React.FC<EditRemarkProps> = ({
                                                   id,
                                                   code,
                                                   seq,
                                                   close
                                               }) => {
    const {t} = useTranslation();
    const [model, setModel] = useState<IRemarkGetAction | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        getEditRemarkData: {
            isLoading,
            cancel,
            query: getEditRemarkData
        },
        editRemark: {
            cancel: cancelEditRemark,
            mutation: editRemark
        }
    } = useOfferApi();

    useEffect(() => {
        if (!id) {
            if (close) {
                close();
            }

            return;
        }

        (async () => {
            try {
                const response = await getEditRemarkData(id, code, seq);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error<string>('Unable to get remark data');

                    if (close) {
                        close();
                    }
                }
            }
        })();

        return () => {
            cancel();
            cancelEditRemark();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const save = async (item: IRemarkPostAction): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            const response = await editRemark(item);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRow',
                        rows: [
                            {
                                id: id,
                                isRowLoading: true
                            }
                        ]
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.offer")} ${id}`}/>);
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(e.message);
            }
        }

        setDisabled(prev => !prev);
    }

    if (!id || id === '') {
        return null;
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                <EditRemarkForm model={model}
                                disabled={disabled}
                                save={save}/>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        style={{minWidth: '150px'}}
                        type={'submit'}
                        form={'edit-remark-form'}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.save")}
                </Button>
            </div>
        </div>
    );
};

export default EditRemark;