import React from 'react';
import {IOffer} from "../../../../../../../app/interfaces/offer/IOffer";
import {BetStatus} from "../../../../../../../app/enums/BetStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import moment from "moment";

type ServiceProviderOfferBetsProps = {
    offer: IOffer;
};

const ServiceProviderOfferBets: React.FC<ServiceProviderOfferBetsProps> = ({
                                                                               offer
                                                                           }) => {
    const {t, i18n} = useTranslation();

    return (
        <div className={'bet-list-container'}>
            {offer.bets
                .sort((a, b) => {
                    return a.status - b.status || new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
                })
                .map((item, index) => {
                    let isAvailableTillToday = item.status === BetStatus.New && item.tillDate !== null && item.tillDate !== ''
                        ? moment(moment(), 'DD.MM.YYYY').isSame(moment(item.tillDate, 'DD.MM.YYYY'), 'date')
                        : false;

                    return (
                        <div key={`bet-${index}`}
                             className={`bet-card ${item.status === BetStatus.Cancelled ? 'cancelled-bet' : ''} transition-all-500`}>
                            {item.status === BetStatus.Cancelled &&
                                <div className={'relative'}>
                                    <div className={'bet-rejected-icon'}>
                                        <FontAwesomeIcon icon={faBan}/>
                                    </div>

                                    {item.rejectReason !== null && item.rejectReason !== '' &&
                                    i18n.exists(`offer.recall.recall-${item.rejectReason}`)
                                        ? <div className={'bet-rejected-reason-container'}
                                               title={t(`offer.recall.recall-${item.rejectReason}`)}>
                                            {t(`offer.recall.recall-${item.rejectReason}`)}
                                        </div>
                                        : <div className={'bet-rejected-reason-container'} style={{borderBottom: 'none'}}>

                                        </div>
                                    }

                                    {item.rejectNotes !== null && item.rejectNotes !== '' &&
                                        <div className={'bet-rejected-notes-container'}>
                                            {item.rejectNotes}
                                        </div>
                                    }
                                </div>
                            }

                            <div className={`view-row bet-field`}>
                                {moment.utc(item.createdOn).local().format('DD.MM.YYYY HH:mm')}
                            </div>

                            <div
                                className={`view-row bet-field bet-prop-field ${item.status === BetStatus.Cancelled ? '' : 'bet-active'}`}>
                                {`${item.bet} ${item.currencyCode}`}
                            </div>

                            {item.tillDate !== null && item.tillDate !== '' &&
                                <div className={`view-row bet-field`}>
                                    {`${t("quote.bet.validity")}: `}
                                    <span style={isAvailableTillToday ? {
                                        color: '#c9302c'
                                    } : undefined}>
                                        {item.tillDate}
                                    </span>
                                </div>
                            }

                            {item.notes !== null && item.notes !== '' &&
                                <div className={`view-row bet-field`}
                                     style={{fontWeight: '500'}}>
                                    {`${item.notes}`}
                                </div>
                            }

                            <div className={`view-row bet-field`}>
                                {item.extServiceProviderName !== null && item.extServiceProviderName !== '' ? item.extServiceProviderName : item.serviceProvider}
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default ServiceProviderOfferBets;
