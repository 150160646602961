import {useCallback, useEffect, useState} from "react";
import {IResponse, isResponse} from "../../app/interfaces/response/IResponse";
import getServerErrorMessage, {Entity} from "../../app/apiError";
import {useTranslation} from "react-i18next";

export type ServerError = string | undefined | null;

type UseServerErrorProps = {
    entity: Entity;
    timeout?: number;
    onErrorCleared: () => void;
}

const useServerError = ({
                            entity,
                            timeout,
                            onErrorCleared
                        }: UseServerErrorProps): [ServerError, (err: ServerError | IResponse) => void] => {
    const {t} = useTranslation();
    const [value, setValue] = useState<ServerError>();

    const setError = useCallback((err: ServerError | IResponse) => {
        if (err !== undefined && isResponse(err)) {
            setValue(t(getServerErrorMessage(entity, err) ?? ''));
        } else {
            setValue(err);
        }
    }, [t, entity]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (value) {
            timeoutId = setTimeout(() => {
                setValue(null);
                onErrorCleared();
            }, timeout);
        }

        return () => {
            clearTimeout(timeoutId);
        }
    }, [onErrorCleared, value, timeout]);

    return [value, setError];
};

export default useServerError;