import {IChatSocketSession} from "../../../../../app/types/ChatSocketSession";
import {IChatReplyTo} from "../../../../../app/interfaces/chat/IChat";
import {faBook, faCubes, faRoad, IconDefinition} from "@fortawesome/free-solid-svg-icons";

export const getUserOnlineStatus = (user: {
    id: string;
    serviceProviderId?: string;
    groupId?: string;
}, sessions: Array<IChatSocketSession>): boolean => {
    if (sessions.length <= 0) {
        return false;
    }

    if (user.groupId !== undefined && user.groupId !== null && user.groupId !== '' &&
        user.serviceProviderId !== undefined && user.serviceProviderId !== null && user.groupId !== '') {
        return sessions.find(e => e.Gr === user.groupId && e.spId === user.serviceProviderId) !== undefined;
    } else {
        if (user.groupId !== undefined && user.groupId !== null && user.groupId !== '') {
            return sessions.find(e => e.Gr === user.groupId) !== undefined;
        }

        if (user.serviceProviderId !== undefined && user.serviceProviderId !== null && user.serviceProviderId !== '') {
            return sessions.find(e => e.spId === user.serviceProviderId) !== undefined;
        }
    }

    return sessions.find(e => e.userId === user.id) !== undefined;
};


export const getLinkedEntityIcon = (message: IChatReplyTo): IconDefinition | null => {
    let icon: IconDefinition | null = null;

    if (message.type === 'RELEASE') {
        icon = faCubes;
    } else if (message.type === 'QUOTE') {
        icon = faBook;
    } else if (message.type === 'SHIPMENT') {
        icon = faRoad;
    }

    return icon;
};

export const getLinkedEntityLink = (message: IChatReplyTo): string | null => {
    let link: string | null = null;

    if (message.id === null || message.id === '') {
        return link;
    }

    if (message.type === 'RELEASE') {
        link = `/offer/AAG.${message.id}`;
    } else if (message.type === 'QUOTE') {
        link = `/quote/AAG.${message.id}`;
    } else if (message.type === 'SHIPMENT' && message.idExt !== null && message.idExt !== '') {
        link = `/shipment/${message.idExt}/AAG.${message.id}`;
        //link = `/shipments/current?id=AAG.${message.id}&sId=${message.idExt}`;
    }

    return link;
};