import React from 'react';
import {useTranslation} from "react-i18next";
import {IShipment} from "../../../../../../../../../app/interfaces/shipment/IShipment";

type RateProps = {
    shipment: IShipment
}

const Rate: React.FC<RateProps> = ({shipment}) => {
    const {t} = useTranslation();

    return (
        <div className={'details-box-item-field-value'}>
            {shipment.costBrutto !== null ? shipment.costBrutto : ""}
        </div>
    );
};

export default Rate;
