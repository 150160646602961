import {GridLayoutView} from "../models/enums/GridLayoutView";
import {GridLayoutLoadingType} from "../models/enums/GridLayoutLoadingType";
import {useAppSelector} from "../../../../app/store";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSliceSelectors";
import {useCallback, useEffect, useState} from "react";
import {GridLayoutProps} from "../GridLayout";
import {GridConfig} from "../../../ui/Grid/Telerik/types/GridConfig";
import {ILayoutConfig} from "../../../../app/interfaces/layoutConfig/ILayoutConfig";
import {LayoutConfigType} from "../../../../app/enums/LayoutConfigType";
import {ResponseResultCode} from "../../../../app/enums/ResponseResultCode";
import {useLayoutConfigApi} from "../../../../app/api/layoutConfig";
import {getGridLayoutLoadingType} from "../utils";
import axios from "axios";
import {toast} from "react-toastify";
import {LayoutConfigEntity} from "../../../../app/enums/LayoutConfigEntity";
import {combineDefaultAndSavedColumns, getGridConfigColumns} from "../../../ui/Grid/Telerik/utils";

type UseGridLayoutReturn<T> = {
    view: GridLayoutView | null;
    loading: GridLayoutLoadingType;

    saveHeaderConfig: (config: T) => Promise<void>;
    headerConfig: T | null;
    defaultHeaderConfig: T | null;
    isHeaderDisabled: boolean;

    isFilterPanelShown: boolean;
    setIsFilterPanelShown: (show: boolean) => void;

    saveGridConfig: (config: GridConfig) => Promise<void>;
    reloadGridConfig: () => Promise<void>;
    gridConfig: GridConfig | null;
    defaultGridConfig: GridConfig | null;
    isGridDisabled: boolean;

    changeView: (updateView: GridLayoutView | null) => Promise<void>;
    groupBy: (group?: string) => void;
    refreshVal: number;
    refresh: () => void;

    selectedRows: Array<any>;
    setSelectedRows: (rows: Array<any>) => void;
}

const DELAY: number = 200;

const useGridLayout = <T, >(props: GridLayoutProps<T>): UseGridLayoutReturn<T> => {
    const user = useAppSelector(selectCurrentUser);

    const [loading, setLoading] = useState<GridLayoutLoadingType>(GridLayoutLoadingType.Unset);
    const [view, setView] = useState<GridLayoutView | null>(null);

    const [headerConfigId, setHeaderConfigId] = useState<number | null>(null);
    const [headerConfig, setHeaderConfig] = useState<T | null>(null);
    const [defaultHeaderConfig, setDefaultHeaderConfig] = useState<T | null>(null);
    const [isHeaderDisabled, setIsHeaderDisabled] = useState<boolean>(false);

    const [gridConfigId, setGridConfigId] = useState<number | null>(null);
    const [gridConfig, setGridConfig] = useState<GridConfig | null>(null);
    const [defaultGridConfig, setDefaultGridConfig] = useState<GridConfig | null>(null);
    const [isGridDisabled, setIsGridDisabled] = useState<boolean>(false);
    const [refreshVal, setRefreshVal] = useState<number>(0);

    const [selectedRows, setSelectedRows] = useState<Array<any>>([]);

    const [isFilterPanelShown, setIsFilterPanelShown] = useState<boolean>(false);

    const {
        getLayoutConfig: {query},
        saveLayoutConfig: {mutation}
    } = useLayoutConfigApi();

    useEffect(() => {
        if (!user) {
            return;
        }

        setDefaultGridConfig(props.defaultGridConfig);
        setDefaultHeaderConfig(props.defaultHeaderConfig);
        setHeaderConfig(props.defaultHeaderConfig);

        (async () => {
            await changeView(null);
        })();

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const changeView = async (updateView: GridLayoutView | null): Promise<void> => {
        if ((view !== null && updateView !== null && view === updateView) ||
            (updateView !== null && updateView === GridLayoutView.Map && !props.map)) {
            return;
        }

        setIsHeaderDisabled(prev => !prev);
        setLoading(getGridLayoutLoadingType(updateView, headerConfig ?? defaultHeaderConfig));

        let viewToUpdateLocal: GridLayoutView = GridLayoutView.Grid;
        if (headerConfig === null) {
            const header = await loadLayoutConfig(LayoutConfigType.GridActionRow);

            if (header !== null) {
                let val = JSON.parse(header.value);
                if (val.isMapView !== undefined && val.isMapView) {
                    viewToUpdateLocal = GridLayoutView.Map;
                }

                setHeaderConfig(val);
                setHeaderConfigId(header.id);
            }
        } else if (updateView === null) {
            let isMapView = (headerConfig as any).isMapView;
            if (isMapView !== undefined && isMapView === true) {
                viewToUpdateLocal = GridLayoutView.Map;
            }
        }

        if (((updateView !== null && updateView === GridLayoutView.Grid)
                || (viewToUpdateLocal !== GridLayoutView.Map && updateView === null)) &&
            gridConfig === null) {
            const grid = await loadLayoutConfig(LayoutConfigType.Grid);

            if (grid != null) {
                setGridConfig(JSON.parse(grid.value));
                setGridConfigId(grid.id);
            }
        }

        setView(() => {
            return updateView === null ? viewToUpdateLocal : updateView;
        });

        await new Promise(r => setTimeout(r, DELAY));

        setLoading(GridLayoutLoadingType.None);
        setIsHeaderDisabled(prev => !prev);
    }

    const loadLayoutConfig = async (type: LayoutConfigType): Promise<ILayoutConfig | null> => {
        if (!user) {
            return null;
        }

        try {
            const configResponse = await query(props.entity, type, user.deviceId);
            if (configResponse?.status === 200 &&
                configResponse.data.data !== undefined &&
                configResponse.data.resultCode === ResponseResultCode.Ok) {
                return configResponse.data.data;
            }
        } catch {
            //ignore
        }

        return null;
    }

    const saveHeaderConfig = useCallback(async (config: T): Promise<void> => {
        if (!user) {
            return;
        }

        try {
            setIsHeaderDisabled(prev => !prev);

            setHeaderConfig(config);

            const response = await mutation({
                id: headerConfigId !== null && headerConfigId !== undefined ? headerConfigId : 0,
                type: LayoutConfigType.GridActionRow,
                entity: props.entity,
                deviceId: user.deviceId,
                value: JSON.stringify(config)
            });

            if (response && response.status === 200 && response.data && response.data.field) {
                setHeaderConfigId(Number(response.data.field));
            }

            setIsHeaderDisabled(prev => !prev);
        } catch (err) {
            if (axios.isAxiosError(err)) {
                toast.error<string>(`Unable to save ${LayoutConfigEntity[props.entity].toString()} configuration.`);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headerConfigId]);

    const saveGridConfig = useCallback(async (config: GridConfig): Promise<void> => {
        if (!user) {
            return;
        }

        try {
            setIsGridDisabled(prev => !prev);

            setGridConfig(config);

            const response = await mutation({
                id: gridConfigId !== null && gridConfigId !== undefined ? gridConfigId : 0,
                type: LayoutConfigType.Grid,
                entity: props.entity,
                deviceId: user.deviceId,
                value: JSON.stringify(config)
            });

            if (response && response.status === 200 && response.data && response.data.field) {
                setGridConfigId(Number(response.data.field));
            }

            setIsGridDisabled(prev => !prev);
        } catch (err) {
            if (axios.isAxiosError(err)) {
                toast.error<string>(`Unable to save ${LayoutConfigEntity[props.entity].toString()} configuration.`);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridConfigId]);

    const reloadGridConfig = useCallback(async () => {
        setIsGridDisabled(prev => !prev);

        const grid = await loadLayoutConfig(LayoutConfigType.Grid);

        if (grid != null) {
            setGridConfig(JSON.parse(grid.value));
        }

        setIsGridDisabled(prev => !prev);
    }, []);

    const groupBy = useCallback((group?: string) => {
        if (gridConfig) {
            setGridConfig({
                ...gridConfig,
                groupBy: group ? [group] : []
            });
        } else if (defaultGridConfig) {
            let cols = combineDefaultAndSavedColumns(
                defaultGridConfig?.columns ?? [],
                null
            );

            setGridConfig({
                columns: getGridConfigColumns(cols),
                sortBy: defaultGridConfig.sortBy,
                groupBy: group ? [group] : defaultGridConfig.groupBy,
                pageSize: defaultGridConfig.pageSize,
            });
        }
    }, [gridConfig, defaultGridConfig]);

    const setFilterPanelShown = useCallback((show: boolean) => {
        setIsFilterPanelShown(show);
    }, [setIsFilterPanelShown]);

    const refresh = useCallback(() => {
        setRefreshVal(prev => prev + 1);
    }, []);

    return {
        view,
        loading,

        saveHeaderConfig,
        headerConfig,
        defaultHeaderConfig,
        isHeaderDisabled,

        saveGridConfig,
        reloadGridConfig,
        gridConfig,
        defaultGridConfig,
        isGridDisabled,

        changeView,
        groupBy,
        refresh,
        refreshVal,

        selectedRows,
        setSelectedRows,

        isFilterPanelShown,
        setIsFilterPanelShown: setFilterPanelShown
    };
}

export default useGridLayout;