import {
    useGetDocument, useGridExportToDocument
} from "./requests";

export const useDocumentApi = () => {
    const {
        isLoading: getDocumentIsLoading,
        cancel: cancelGetDocument,
        getDocument
    } = useGetDocument();

    const {
        isLoading: exportToDocumentIsLoading,
        cancel: cancelExportToDocument,
        exportToDocument
    } = useGridExportToDocument();

    return {
        getDocument: {
            query: getDocument,
            isLoading: getDocumentIsLoading,
            cancel: cancelGetDocument
        },
        exportToDocument: {
            mutation: exportToDocument,
            isLoading: exportToDocumentIsLoading,
            cancel: cancelExportToDocument
        }
    };
}