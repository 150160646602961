import React from 'react';
import cl from './Tag.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRectangleXmark} from "@fortawesome/free-solid-svg-icons";
import Icon from "../../../assets/icon/Icon";

export enum TagItemBackgroundColor {
    Blue = 'var(--main-blue-color)',
    Orange = 'var(--main-orange-color)',
    Navy = 'rgb(24 49 83)',
    DarkRed = '#EF4444',
    LightBlue = '#0ea5e9',
    Gray = '#878787',
    LightGray = '#EFEFEF'
}

export type TagItem = {
    id: string | number;
    text: string;
    data?: any;
    showClearIcon?: boolean;
    backgroundColor: TagItemBackgroundColor;
    backgroundColorStr?: string;
    rounded?: boolean;
}

export type TagProps = {
    item: TagItem;
    disabled?: boolean;
    onClear?: (item: TagItem) => void;
    containerMaxWidth: number;
    style?: React.CSSProperties;
}

const Tag: React.FC<TagProps> = ({
                                     item,
                                     onClear,
                                     containerMaxWidth,
                                     disabled = false,
                                     style
                                 }) => {
    let textContentMaxWidth = item.showClearIcon
        ? containerMaxWidth - 31.2
        : containerMaxWidth - 12.8;

    return (
        <button disabled={disabled}
                className={cl.tag}
                data-type={item.rounded ? 'rounded' : 'default'}
                style={{
                    ...style,
                    backgroundColor: item.backgroundColorStr !== undefined && item.backgroundColorStr !== null && item.backgroundColorStr !== ''
                        ? item.backgroundColorStr
                        : item.backgroundColor.toString()
                }}
                id={`tag-${item.id}`}>
            <div title={item.text}
                 style={{
                     maxWidth: `${textContentMaxWidth}px`,
                     whiteSpace: 'nowrap',
                     overflow: 'hidden',
                     textOverflow: 'ellipsis'
                 }}>
                {item.text}
            </div>
            {!item.rounded && item.showClearIcon &&
                <FontAwesomeIcon icon={faRectangleXmark}
                                 onClick={() => {
                                     if (disabled) {
                                         return;
                                     }

                                     if (onClear) {
                                         onClear(item);
                                     }
                                 }}
                                 className={`${cl.close} ${disabled ? cl.disabled : ''}`}/>
            }

            {item.rounded && item.showClearIcon &&
                <Icon icon={"faXMark"}
                      onClick={() => {
                          if (disabled) {
                              return;
                          }

                          if (onClear) {
                              onClear(item);
                          }
                      }}
                      className={`${cl.closeRounded} ${disabled ? cl.disabled : ''}`}/>
            }
        </button>
    );
};

export default Tag;