import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {SelectModel} from "../../../../../../../../app/types/SelectModel";
import {debounce} from "debounce";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {useOfferApi} from "../../../../../../../../app/api/offer";
import {IUser} from "../../../../../../../../app/interfaces/user/IUser";

type SendContractServiceProviderSelectorProps = {
    disabled: boolean;
    value: string | null;
    text: string | null;
    onChange: (value: string | null, text: string | null) => void;
    user?: IUser;
};

const SendContractServiceProviderSelector: React.FC<SendContractServiceProviderSelectorProps> = ({
                                                                                                     user,
                                                                                                     disabled,
                                                                                                     value,
                                                                                                     text,
                                                                                                     onChange
                                                                                                 }) => {
    const {t} = useTranslation();
    const [items, setItems] = useState<Array<SelectModel>>([]);
    const [filter, setFilter] = useState<string | undefined>();
    const [selectedItem, setSelectedItem] = useState<SelectModel | null>();

    const {
        getServiceProviders: {
            query: getServiceProviders,
            isLoading: getServiceProvidersIsLoading,
            cancel: cancelGetServiceProviders
        },
        getServiceProvidersAnonymous: {
            query: getServiceProvidersAnonymous,
            isLoading: getServiceProvidersAnonymousIsLoading,
            cancel: cancelGetServiceProvidersAnonymous
        }
    } = useOfferApi();

    useEffect(() => {
        return () => {
            cancelGetServiceProviders();
            cancelGetServiceProvidersAnonymous();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (value !== undefined && value !== null && value !== '') {
            let item = items.find(e => e.value === value);

            if (item) {
                setSelectedItem({
                    ...item
                });
            } else if (text !== undefined && text !== null && text !== '') {
                setSelectedItem({
                    text: text,
                    value: value
                });
            }
        } else {
            setSelectedItem(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        (async () => {
            await load(filter);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const load = useCallback(debounce(async (lFilter?: string) => {
        try {
            const response = user
                ? await getServiceProviders(lFilter ?? '', true, true)
                : await getServiceProvidersAnonymous(lFilter ?? '', true, true);

            if (response?.status === 200 && response.data?.data) {
                setItems(response.data.data.filter(e => e.text !== '' && e.value !== ''));
            }
        } catch {
            //ignore
        }
    }, 500), []);


    return (
        <ComboBox data={items}
                  value={selectedItem}
                  defaultValue={value !== undefined && value !== '' && text !== undefined && text !== ''
                      ? {text: text, value: value}
                      : undefined}
                  onChange={ev => {
                      onChange(ev.value?.value ?? null, ev.value?.text ?? null);
                  }}
                  disabled={disabled}
                  placeholder={t("quote.bet.service-provider")}
                  textField="text"
                  dataItemKey="value"
                  clearButton={true}
                  filterable={true}
                  loading={getServiceProvidersAnonymousIsLoading || getServiceProvidersIsLoading}
                  onFilterChange={(ev) => {
                      if ((ev.nativeEvent === undefined || ev.nativeEvent === null) && (
                          ev.syntheticEvent === undefined || ev.syntheticEvent === null
                      )) {
                          setFilter(undefined);

                          return;
                      }

                      if (value !== null && value !== undefined && value !== '') {
                          onChange(null, null);
                      }

                      if (!ev.filter?.value || ev.filter.value.length < 3) {
                          return;
                      }

                      setFilter(ev.filter.value);
                  }}
        />
    );
};

export default SendContractServiceProviderSelector;