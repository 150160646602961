import React from 'react';
import {ICancelShipmentGetAction} from "../../../../../../app/interfaces/shipment/ICancelShipmentGetAction";
import {ICancelShipmentPostAction} from "../../../../../../app/interfaces/shipment/ICancelShipmentPostAction";
import {FormikHelpers, useFormik} from "formik";
import Textarea from "../../../../../../components/ui/Textarea/Textarea";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import cl from './CancelShipmentForm.module.css';
import {DropDownList} from "@progress/kendo-react-dropdowns";

type CancelShipmentFormProps = {
    model: ICancelShipmentGetAction;
    disabled: boolean;
    save: (model: ICancelShipmentPostAction) => Promise<void>;
}

const CancelShipmentForm: React.FC<CancelShipmentFormProps> = ({
                                                                   save,
                                                                   model,
                                                                   disabled
                                                               }) => {
    const {t} = useTranslation();
    const [reasons] = React.useState<Array<any>>([
        {id: 'CANCELED_BY_CUSTOMER', text: t("shipment.cancel-shipment-reason.cancelled-by-customer")},
        {id: 'CANCELED_BY_CARRIER', text: t("shipment.cancel-shipment-reason.cancelled-by-carrier")}
    ]);

    const form = useFormik<ICancelShipmentPostAction>({
        initialValues: {
            orderReleaseGid: model.orderReleaseGid,
            notes: '',
            reason: null
        },
        validationSchema: Yup.object().shape({
            reason: Yup.string().required(t("shared.errors.required-error").replace('{0}', t("shipment.form.cancel-shipment-reason")))
        }),
        onSubmit: async (values: ICancelShipmentPostAction, actions: FormikHelpers<ICancelShipmentPostAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='cancel-shipment-form'
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div className={`mgb-20 ${cl.warning}`}>
                    {t("shipment.warnings.cancel-shipment-confirmation")}
                </div>
                <div className={'mgb-20 drop-down-picker'}>
                    <label
                        className={'view-label cancel-shipment-reason-label required'}>{t("shipment.form.cancel-shipment-reason")}</label>
                    <DropDownList data={reasons}
                                  textField="text"
                                  id={'cancel-shipment-reason-value'}
                                  dataItemKey="id"
                                  value={form.values.reason !== null ? reasons.find(e => e.id === form.values.reason) : null}
                                  onChange={(ev) => {
                                      form.setFieldValue('reason', ev.target.value.id);
                                  }}/>
                    {form.errors.reason &&
                        <div className={'error-label'}>
                            {form.errors.reason}
                        </div>
                    }
                </div>
                <div className={'mgb-10'}>
                    <label
                        className={'view-label cancel-shipment-reason-notes-label'}>{t("shipment.form.cancel-shipment-reason-notes")}</label>
                    <Textarea disabled={disabled}
                              style={{minHeight: '70px', resize: 'vertical', maxHeight: '150px'}}
                              value={form.values.notes ?? ''}
                              placeholder={t("shipment.form.cancel-shipment-reason-notes")}
                              id={'notes'}
                              name={'notes'}
                              className={'cancel-shipment-reason-notes-value'}
                              onChange={form.handleChange}/>
                </div>
            </div>
        </form>
    );
};

export default CancelShipmentForm;