import {useLayoutEffect, useState} from "react";
import {debounce} from 'debounce';

const useElementSize = (elementId: string, interval: number | undefined = 300): [number, number] => {
    const [size, setSize] = useState<[number, number]>([0, 0]);

    useLayoutEffect(() => {
        const element = document.getElementById(elementId);
        if (!element) {
            return;
        }

        const onResize = debounce(() => {
            setSize([element.clientWidth, element.clientHeight]);
        }, interval);

        window.addEventListener('resize', onResize);

        onResize();

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [elementId]);

    return size;
};

export default useElementSize;