import React, {SetStateAction, useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {SelectModel} from "../../../../../../../app/types/SelectModel";
import {debounce} from "debounce";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {useDataHelperApi} from "../../../../../../../app/api/dataHelper";

type AssignQuoteExecutionAgentSelectorProps = {
    disabled: boolean;
    value?: string;
    onChange: (val?: string) => void;
    setIsSubmitDisabled: React.Dispatch<SetStateAction<boolean>>;
}

const AssignQuoteExecutionAgentSelector: React.FC<AssignQuoteExecutionAgentSelectorProps> = ({
                                                                                                 disabled,
                                                                                                 value,
                                                                                                 onChange,
                                                                                                 setIsSubmitDisabled
                                                                                             }) => {
    const {t} = useTranslation();
    const [items, setItems] = useState<Array<SelectModel>>([]);
    const [filter, setFilter] = useState<string | undefined>();

    const {
        getUsersForAssignment: {
            query,
            isLoading,
            cancel
        }
    } = useDataHelperApi();

    useEffect(() => {
        return () => {
            cancel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            await load(value, filter);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const load = useCallback(debounce(async (lValue?: string, lFilter?: string) => {
        try {
            const response = await query(false, lValue, lFilter);
            if (response?.status === 200 && response.data?.data) {
                setItems(response.data.data.filter(e => e.text !== '' && e.value !== ''));
                setIsSubmitDisabled(false);
            }
        } catch {
            //ignore
        }
    }, 500), []);

    return (
        <div className={`w100 combobox-picker`} style={{paddingTop: '5px'}}>
            <ComboBox data={items}
                      id={'assign-quote-execution-agent'}
                      value={value && items.length > 0 ? items.find(e => e.value === value) : undefined}
                      onChange={ev => onChange(ev.value?.value)}
                      disabled={disabled}
                      placeholder={t("quote.grid.forwarder")}
                      textField="text"
                      dataItemKey="value"
                      clearButton={true}
                      filterable={true}
                      loading={isLoading}
                      onFilterChange={(ev) => {
                          onChange(undefined);

                          if (!ev.filter?.value || ev.filter.value.length < 3) {
                              return;
                          }

                          setFilter(ev.filter.value);
                      }}
            />
        </div>
    );
};

export default AssignQuoteExecutionAgentSelector;