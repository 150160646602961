import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import ChangeNoteDialog from "../../../../../../actions/ChangeNote/ChangeNoteDialog";
import cl from './Notes.module.css';
import Icon from "../../../../../../../../../assets/icon/Icon";

type NotesProps = {
    notes?: string;
    notesInfo?: string;
    orderReleaseGid: string;
    shipmentGid: string;
}

const Notes: React.FC<NotesProps> = ({
                                         notes,
                                         notesInfo,
                                         orderReleaseGid,
                                         shipmentGid
                                     }) => {
    const {t} = useTranslation();
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);

    return (
        <>
            <ChangeNoteDialog id={orderReleaseGid}
                              sId={shipmentGid}
                              show={isDialogShown}
                              setShow={setIsDialogShown}/>

            <div className={`${cl.content} details-box-notes`}>
                <div>
                    {notes !== undefined && notes !== null && notes !== ''
                        ? <Icon icon={"faPen"}
                                className={'details-box-item-field-gray-icon'}
                                onClick={() => setIsDialogShown(prev => !prev)}/> :
                        <Icon icon={"faPen"}
                              className={'details-box-item-field-icon'}
                              onClick={() => setIsDialogShown(prev => !prev)}/>
                    }

                    <div className={'details-box-item-field-edit-value details-box-notes-edit-btn'}
                         onClick={() => setIsDialogShown(prev => !prev)}>
                        {notes !== undefined && notes !== null && notes !== ''
                            ? <></>
                            : <>{t("shipment.grid.add-note")}</>
                        }
                    </div>
                </div>

                <div title={notesInfo} className={cl.note}>
                    {notes !== undefined && notes !== null && notes !== '' &&
                        <>{notes}</>
                    }
                </div>
            </div>
        </>
    );
};

export default Notes;
