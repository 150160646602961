import React from 'react';
import {IOffer} from "../../../../../../app/interfaces/offer/IOffer";
import CommodityCode from "../../../../../../components/shared/form/detailsFields/CommodityCode/CommodityCode";
import TransportHandlingUnit
    from "../../../../../../components/shared/form/detailsFields/TransportHandlingUnit/TransportHandlingUnit";
import ThuCount from "../../../../../../components/shared/form/detailsFields/THUCount/THUCount";
import TotalWeight from "../../../../../../components/shared/form/detailsFields/TotalWeight/TotalWeight";
import Cost from "../../../../../../components/shared/form/detailsFields/Cost/Cost";
import ThuVolume from "../../../../../../components/shared/form/detailsFields/THUVolume/THUVolume";
import useMediaQuery from "../../../../../../hooks/useMediaQuery/useMediaQuery";

type OfferCargoProps = {
    offer: IOffer;
};

const OfferCargo: React.FC<OfferCargoProps> = ({
                                                   offer
                                               }) => {
    const isMaxWidth600 = useMediaQuery('(max-width:600px)');

    return (
        <div className={'details-box-default-container'}>
            <div className={'details-box-default-container-columns'}
                 data-count={isMaxWidth600 ? '1' : '2'}>
                {!isMaxWidth600 &&
                    <>
                        <div>
                            <div className={'details-box-default-container-item'}>
                                <CommodityCode commodityCode={offer.commodityCode}
                                               adr={offer.adr}/>
                            </div>

                            <div className={'details-box-default-container-item'}>
                                <TransportHandlingUnit transportHandlingUnit={offer.transportHandlingUnit}/>
                            </div>

                            <div className={'details-box-default-container-item'}>
                                <ThuCount thuCount={offer.thuCount}/>
                            </div>
                        </div>

                        <div>
                            <div className={'details-box-default-container-item'}>
                                <TotalWeight weight={offer.totalWeight}/>
                            </div>

                            <div className={'details-box-default-container-item'}>
                                <Cost cost={offer.cost}/>
                            </div>

                            <div className={'details-box-default-container-item'}>
                                <ThuVolume thuVolume={offer.thuVolume}/>
                            </div>
                        </div>
                    </>
                }

                {isMaxWidth600 &&
                    <div>
                        <div className={'details-box-default-container-item'}>
                            <CommodityCode commodityCode={offer.commodityCode}
                                           adr={offer.adr}/>
                        </div>

                        <div className={'details-box-default-container-item'}>
                            <TransportHandlingUnit transportHandlingUnit={offer.transportHandlingUnit}/>
                        </div>

                        <div className={'details-box-default-container-item'}>
                            <ThuCount thuCount={offer.thuCount}/>
                        </div>

                        <div className={'details-box-default-container-item'}>
                            <TotalWeight weight={offer.totalWeight}/>
                        </div>

                        <div className={'details-box-default-container-item'}>
                            <Cost cost={offer.cost}/>
                        </div>

                        <div className={'details-box-default-container-item'}>
                            <ThuVolume thuVolume={offer.thuVolume}/>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
};

export default OfferCargo;