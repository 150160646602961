import React from 'react';
import {RouteSectionModel} from "../../RouteSection";
import {useTranslation} from "react-i18next";

type VolumePerYearProps = {
    model: RouteSectionModel;
}

const VolumePerYear: React.FC<VolumePerYearProps> = ({model}) => {
    const {t} = useTranslation();

    return (
        <>
            <label className={'volume-per-year-label'}>
                {t('quote.form.volume-per-year')}
            </label>
            <span className={'details-box-item-field-value volume-per-year-value'}>
                 {model.volumePerYear !== undefined && model.volumePerYear !== null && model.volumePerYear !== ''
                     ? <>{model.volumePerYear}</>
                     : <>{t("shared.labels.no-data")}</>
                 }
            </span>
        </>
    );
};

export default VolumePerYear;