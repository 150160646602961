import React from 'react';
import cl from './AccessSettingsCollapseBubble.module.css';
import Icon from "../../../../../assets/icon/Icon";

type AccessSettingsCollapseBubbleProps = {
    isFilterSelected: boolean;
    count?: number;
}

const AccessSettingsCollapseBubble: React.FC<AccessSettingsCollapseBubbleProps> = ({
                                                                                       isFilterSelected,
                                                                                       count
                                                                                   }) => {
    return (
        <div>
            <Icon icon={"faBarFilter"}
                  className={`${cl.filter} ${isFilterSelected ? cl.active : ''}`}/>
            {count !== undefined && count > 0 &&
                <span className={cl.count}>
                    {count}
                </span>
            }
        </div>
    );
};

export default AccessSettingsCollapseBubble;