import React, {useMemo} from 'react';
import useChat from "./hooks/useChat";
import {IChatHelperContext} from "./interfaces/IChatHelperContext";
import {ChatHelperContext} from "./contexts";
import ChatPanel from "./components/ChatPanel/ChatPanel";
import ChatPanelLayout from "./components/ChatPanel/ChatPanelLayout/ChatPanelLayout";
import ChatContent from "./components/ChatContent/ChatContent";

const Chat: React.FC = () => {
    const {
        isMobileView,
        isChatOpened,
        isFullScreenMode,
        isChatsLoading,
        isMessagesLoading,
        isUserInformationSectionShown,
        isMessagesSearchSectionShown,
        disabled,

        chats,
        chat,
        message,
        messages,
        searchMessages,
        messagesCount,
        activeSearchMessage,
        activeChatSearchString,

        setIsChatDialogOpen,
        setIsFullScreenMode,
        setIsUserInformationSectionShown,
        setIsMessagesSearchSectionShown,
        setActiveChatSearchMessage,
        setActiveChatSearchString,
        getChats,
        updateChats,
        getChatMessages,
        searchChatMessages,
        setChat,
        hideChat,
        onMessageChange,
        sendMessage,
        sendReadCommand
    } = useChat();

    const value = useMemo(() => ({
        isMobileView,
        isChatOpened,
        isFullScreenMode,
        isChatsLoading,
        isMessagesLoading,
        isUserInformationSectionShown,
        isMessagesSearchSectionShown,
        disabled,

        chats,
        chat,
        message,
        messages,
        searchMessages,
        messagesCount,
        activeSearchMessage,
        activeChatSearchString,

        setIsChatDialogOpen,
        setIsFullScreenMode,
        setIsUserInformationSectionShown,
        setIsMessagesSearchSectionShown,
        setActiveChatSearchMessage,
        setActiveChatSearchString,
        getChats,
        updateChats,
        getChatMessages,
        searchChatMessages,
        setChat,
        hideChat,
        onMessageChange,
        sendMessage,
        sendReadCommand
    } as IChatHelperContext), [
        isMobileView,
        isChatOpened,
        isFullScreenMode,
        isChatsLoading,
        isMessagesLoading,
        isUserInformationSectionShown,
        isMessagesSearchSectionShown,
        disabled,

        chats,
        chat,
        message,
        messages,
        searchMessages,
        messagesCount,
        activeSearchMessage,
        activeChatSearchString,

        setIsChatDialogOpen,
        setIsFullScreenMode,
        setIsUserInformationSectionShown,
        setIsMessagesSearchSectionShown,
        setActiveChatSearchMessage,
        setActiveChatSearchString,
        getChats,
        updateChats,
        getChatMessages,
        searchChatMessages,
        setChat,
        hideChat,
        onMessageChange,
        sendMessage,
        sendReadCommand
    ]);

    return (
        <ChatHelperContext.Provider value={value}>
            <ChatPanel>
                <ChatPanelLayout>
                    <ChatContent/>
                </ChatPanelLayout>
            </ChatPanel>
        </ChatHelperContext.Provider>
    );
};

export default Chat;