import React from 'react';
import {useTranslation} from "react-i18next";

const ChatDialogueMessagesNotFound: React.FC = () => {
    const {t} = useTranslation();

    return (
        <div className={'w100 h100 hidden'}
             style={{
                 display: 'flex',
                 alignItems: 'center',
                 justifyContent: 'center'
             }}>
            <div style={{
                color: '#a2a6b6',
                fontWeight: 'bold',
                fontSize: '11px'
            }}>
                {t("chat.labels.messages-history-empty")}
            </div>
        </div>
    );
};

export default ChatDialogueMessagesNotFound;