import React from 'react';
import {ShipmentEventsLineExtendedShipment, ShipmentEventsLineExtendedShipmentEvent} from "../../utils";
import circularBlack from "../../../../../../../../../assets/images/circular-black.png";
import circularGray from "../../../../../../../../../assets/images/circular-gray.png";
import cl from './ShipmentEventsLineProgressBarItem.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getEventLabel, getStopPointIcon} from "../../../../../../../../../helpers/shipment";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {ShipmentEtaStatus} from "../../../../../../../../../app/enums/ShipmentEtaStatus";
import Icon from "../../../../../../../../../assets/icon/Icon";

type ShipmentEventsLineProgressBarItemProps = {
    shipment: ShipmentEventsLineExtendedShipment;
    event: ShipmentEventsLineExtendedShipmentEvent;
}

const getEtaStatusColor = (status: ShipmentEtaStatus): string => {
    if (status === ShipmentEtaStatus.Late) {
        return 'red';
    } else if (status === ShipmentEtaStatus.OnTime) {
        return '#38B868';
    } else if (status === ShipmentEtaStatus.Early) {
        return '#FFCF00';
    }

    return '';
}

const ShipmentEventsLineProgressBarItem: React.FC<ShipmentEventsLineProgressBarItemProps> = ({
                                                                                                 shipment,
                                                                                                 event
                                                                                             }) => {
    const {t} = useTranslation();

    let circle = !shipment.isCompleted && event.isLatest
        ? circularBlack
        : (event.passed ? circularBlack : circularGray);

    let icon = getStopPointIcon(event.type ?? '');

    return (
        <div className={`${cl.item} ${event.passed ? cl.passed : ''}`}>
            <div className={cl.data} style={{flex: '1'}}>
                {event.factDate !== null && event.factDate !== undefined
                    ? <>
                      <span className={cl.factDate}>
                          {moment(event.factDate).format('DD.MM.YY HH:mm')}
                      </span>
                        {event.date !== null && event.date !== undefined
                            ? <span className={cl.date}>
                                {` (${moment(event.date).format('DD.MM.YY HH:mm')})`}
                            </span>
                            : null
                        }
                    </>
                    : <>
                        {event.date !== null && event.date !== undefined
                            ? <span className={cl.date}>
                                {`${moment(event.date).format('DD.MM.YY HH:mm')}`}
                            </span>
                            : <span>&nbsp;</span>
                        }
                    </>
                }
                {event.etaDate !== null && event.etaDate !== undefined
                    ? <>
                        <span className={`${cl.etaDate} event-item-eta-date-${event.sortPosition}`}
                              style={{color: getEtaStatusColor(event.etaStatus)}}>
                            {moment(event.etaDate).format('DD.MM.YYYY HH:mm')}
                        </span>
                        <span className={`${cl.etaShift} event-item-eta-shift-${event.sortPosition}`}
                              style={{backgroundColor: getEtaStatusColor(event.etaStatus)}}>
                                                    {event.etaShift ?? ''}
                        </span>
                    </>
                    : null
                }
            </div>

            <div className={'relative'} style={{
                display: 'flex',
                alignItems: 'center',
                height: '30px'
            }}>
                <div className={cl.circle}
                     data-passed={event.passed ? 'p' : 'np'}>
                    {event.passed &&
                        <Icon icon={"faCheck"}/>
                    }
                </div>

                <div className={cl.line} data-type={event.line}
                     data-passed={event.passed ? 'p' : 'np'}>

                </div>
            </div>

            <div className={cl.data}>
                <div className={cl.label}>
                    {icon &&
                        <FontAwesomeIcon icon={icon}
                                         className={cl.typeIcon}
                                         data-sp-type={event.type}/>
                    }
                    <label className={`view-label ${event.passed ? cl.passed : ''}`}>
                        {`${t(getEventLabel(event.status ?? '', event.type ?? '', null))} ${event.info ?? ''}`}
                    </label>
                </div>
                <div className={cl.address}>
                    {event.addressShort ? event.addressShort : <>&nbsp;</>}
                </div>
            </div>
        </div>
    );
};

export default ShipmentEventsLineProgressBarItem;
