import React, {SetStateAction, useEffect} from 'react';
import {ISelectBetPostAction} from "../../../../../../app/interfaces/offer/ISelectBetPostAction";
import {ISelectBetGetAction} from "../../../../../../app/interfaces/offer/ISelectBetGetAction";
import {useTranslation} from "react-i18next";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import SelectBetServiceProviderSelector from "./SelectBetServiceProviderSelector/SelectBetServiceProviderSelector";
import SelectBetContractSelector from "./SelectBetContractSelector/SelectBetContractSelector";

type SelectBetFormProps = {
    model: ISelectBetGetAction;
    disabled: boolean;
    setIsSubmitDisabled: React.Dispatch<SetStateAction<boolean>>;
    save: (model: ISelectBetPostAction) => Promise<void>;
};

const SelectBetForm: React.FC<SelectBetFormProps> = ({
                                                         model,
                                                         disabled,
                                                         setIsSubmitDisabled,
                                                         save
                                                     }) => {
    const {t} = useTranslation();

    useEffect(() => {
        if (model.serviceProviderId !== null && model.selectedContractId !== null) {
            setIsSubmitDisabled(false);
        }
    }, []);

    const form = useFormik<ISelectBetPostAction>({
        initialValues: {
            betId: model.betId,
            orderReleaseGid: model.orderReleaseGid,
            serviceProviderId: model.serviceProviderId,
            serviceProvider: null,
            contractId: model.selectedContractId
        },
        validationSchema: Yup.object().shape({
            serviceProviderId: Yup.string().nullable().required(t("shared.errors.required-error")
                .replace('{0}', t("offer.grid.service-provider"))),
            contractId: Yup.string().nullable().required(t("shared.errors.required-error").replace('{0}', t("offer.grid.rate-offering")))
        }),
        onSubmit: async (values: ISelectBetPostAction, actions: FormikHelpers<ISelectBetPostAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='select-bet-form'
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div style={{
                    fontSize: '17px',
                    color: '#4a4a4a',
                    textAlign: 'center'
                }} className={'mgb-10'}>
                    {t("offer.form.confirm-selected-transportation")}
                </div>

                <div className={'mgb-10'}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <label className={'view-label required'}>
                            {t("offer.grid.service-provider")}
                        </label>
                        {!form.values.serviceProviderId && form.errors.serviceProviderId &&
                            <div className={'error-label'}
                                 style={{margin: '0'}}>
                                {form.errors.serviceProviderId}
                            </div>
                        }
                    </div>

                    <SelectBetServiceProviderSelector model={model}
                                                      disabled={disabled}
                                                      value={form.values.serviceProviderId}
                                                      onChange={(val) => {
                                                          setIsSubmitDisabled(!(val.value !== null && val.value !== undefined && form.values.contractId !== null && form.values.contractId !== ''));

                                                          form.setFieldValue('serviceProviderId', val.value);
                                                          form.setFieldValue('serviceProvider', val.text);
                                                          form.setFieldValue('contractId', null);
                                                      }}/>
                </div>

                <div className={'mgb-10'}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <label className={'view-label required'}>
                            {t("offer.grid.rate-offering")}
                        </label>
                        {!form.values.contractId && form.errors.contractId &&
                            <div className={'error-label'}
                                 style={{margin: '0'}}>
                                {form.errors.contractId}
                            </div>
                        }
                    </div>

                    <SelectBetContractSelector model={model}
                                               value={form.values.contractId}
                                               serviceProviderId={form.values.serviceProviderId}
                                               onChange={(contractId) => {
                                                   setIsSubmitDisabled(!(form.values.serviceProviderId !== null && form.values.serviceProviderId !== '' &&
                                                       contractId !== null && contractId !== ''));
                                                   form.setFieldValue('contractId', contractId);
                                               }}/>
                </div>
            </div>
        </form>
    );
};

export default SelectBetForm;