import React, {useEffect, useRef, useState} from 'react';
import DatePicker, {ReactDatePickerProps} from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {
    getReactDatePickerCalendarSvgClass,
    getReactDatePickerClass,
    getReactDatePickerCloseSvgClass,
    getReactDatePickerFloatingPlaceholderSvgClass,
    getReactDatePickerLocale
} from "./utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-regular-svg-icons";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {createPortal} from "react-dom";
import uuid from "react-uuid";
import moment from "moment";

export type ReactDatePickerSize = 'big' | 'medium' | 'small';
export type ReactDatePickerFormat = 'dd.MM.yyyy' | 'dd.MM.yyyy HH:mm';

export type ReactDatePickerInputProps = {
    format?: ReactDatePickerFormat;
    value?: Date | null;
    size?: ReactDatePickerSize;
    placeholder?: string;
    floatingPlaceholder?: boolean;
    floatingPlaceholderFontSize?: string;
    showClearIcon?: boolean;
    hasError?: boolean;
    onOpen?: () => void;
    setNowDateOnOpen?: boolean;
} & Pick<ReactDatePickerProps, 'onChange' | 'disabled' | 'readOnly' | 'maxDate' | 'minDate'>;

const ReactDatePicker: React.FC<ReactDatePickerInputProps> = ({
                                                                  value,
                                                                  placeholder,
                                                                  floatingPlaceholder = false,
                                                                  readOnly = false,
                                                                  disabled = false,
                                                                  showClearIcon = true,
                                                                  size = 'big',
                                                                  floatingPlaceholderFontSize,
                                                                  onChange,
                                                                  maxDate,
                                                                  minDate,
                                                                  hasError = false,
                                                                  onOpen,
                                                                  format = 'dd.MM.yyyy',
                                                                  setNowDateOnOpen = true
                                                              }) => {
    const id = useRef<string>(uuid());

    const input = useRef<HTMLInputElement | null>(null);

    const {i18n} = useTranslation();
    const [isCalendarOpened, setIsCalendarOpened] = useState<boolean>(false);

    useEffect(() => {
        const container = document.getElementById(`date-picker-${id.current}`);

        if (container) {
            input.current = container.querySelector('input');
        }
    }, []);

    useEffect(() => {
        if (isCalendarOpened && onOpen) {
            onOpen();
        }
    }, [isCalendarOpened]);

    useEffect(() => {
        if (isCalendarOpened && !value && setNowDateOnOpen) {
            onChange(moment().toDate(), undefined as any);
        }
    }, [value, isCalendarOpened]);

    return (
        <>
            <div className={'relative w100'} id={`date-picker-${id.current}`}>
                <FontAwesomeIcon icon={faCalendar}
                                 className={getReactDatePickerCalendarSvgClass(size)}
                                 onClick={() => {
                                     if (readOnly || disabled) {
                                         return;
                                     }

                                     setIsCalendarOpened(prev => !prev);

                                     if (input.current) {
                                         input.current?.focus();
                                     }
                                 }}/>

                {showClearIcon && value && !readOnly && !disabled &&
                    <FontAwesomeIcon icon={faXmark}
                                     className={getReactDatePickerCloseSvgClass()}
                                     onClick={(ev) => onChange(null, ev)}/>}

                {placeholder && floatingPlaceholder
                    ? <label data-size={size}
                             style={floatingPlaceholderFontSize ? {fontSize: floatingPlaceholderFontSize} : undefined}
                             className={getReactDatePickerFloatingPlaceholderSvgClass((value !== undefined && value !== null) || isCalendarOpened)}>
                        {placeholder}
                    </label>
                    : null
                }

                <DatePicker className={getReactDatePickerClass(size, showClearIcon, disabled || readOnly, hasError)}

                            dateFormat={format}
                            portalId={`date-picker-calendar-portal-${id.current}`}

                            timeFormat={format === 'dd.MM.yyyy HH:mm' ? 'HH:mm' : undefined}
                            showTimeSelect={format === 'dd.MM.yyyy HH:mm'}
                            locale={getReactDatePickerLocale(i18n.language)}
                            selected={value}
                            data-size={size}
                            open={isCalendarOpened}
                            readOnly={readOnly}
                            disabled={disabled}
                            maxDate={maxDate}
                            minDate={minDate}
                            placeholderText={floatingPlaceholder ? undefined : placeholder}

                            onCalendarOpen={() => setIsCalendarOpened(true)}
                            onCalendarClose={() => setIsCalendarOpened(false)}
                            onClickOutside={() => setIsCalendarOpened(false)}
                            onChange={onChange}
                />
            </div>
            {createPortal(
                <div id={`date-picker-calendar-portal-${id.current}`}></div>,
                document.body
            )}
        </>
    );
};

export default ReactDatePicker;