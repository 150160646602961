import React, { useCallback, useEffect, useState } from 'react';
import { FormikHelpers, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { AxiosError, isAxiosError } from 'axios';
import { toast } from "react-toastify";
import { IConfirmTruckStateGetAction } from '../../../app/interfaces/driverCheckList/IConfirmTruckStateGetAction';
import { IConfirmTruckStatePostAction } from '../../../app/interfaces/driverCheckList/IConfirmTruckStatePostAction';
import { Input, RadioButton } from '@progress/kendo-react-inputs';
import { ResponseResultCode } from '../../../app/enums/ResponseResultCode';
import useDriverCheckListApi from '../../../app/api/driverCheckList';
import cl from './ConfirmTruckStateForm.module.css';
import Button, { ButtonType } from '../../../components/ui/Button/Button';
import LanguageSwitcher from '../../../components/ui/LanguageSwitcher/LanguageSwitcher';
import useDefaultSiteLanguages from '../../../hooks/useDefaultSiteLanguages/useDefaultSiteLanguages';
import { IError } from '../../../app/interfaces/response/IResponse';
import ToastLayout from '../../../components/uiLayouts/ToastLayout/ToastLayout';
import { BLErrorCode } from '../../../app/enums/BLErrorCode';
import Alert from '../../../components/ui/Alert/Alert';

//используй как образец EditTransportForm

type ConfirmTruckStateFormProps = {
    model: IConfirmTruckStateGetAction;
    //disabled: boolean;
    //save: (model: IConfirmTruckStatePostAction) => Promise<void>;
}

const ConfirmTruckStateForm: React.FC<ConfirmTruckStateFormProps> = ({
    model,
    //save,
    //disabled
}) => {
    const { t } = useTranslation();

    const languages = useDefaultSiteLanguages();

    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError>>([]);
    const [submitted, setSubmitted] = useState<boolean>(false);

    const {
        saveDriverCheckListData: {
            query: saveDriverCheckListData,
            isLoading: isLoadingSaveDriverCheckListData,
            cancelSaveDriverCheckListData
        }
    } = useDriverCheckListApi();


    const save = async (model: IConfirmTruckStatePostAction): Promise<void> => {
        if (!model) {
            return;
        }
        
        setDisabled(prev => !prev);
        setErrors([]);

        try {
            const response = await saveDriverCheckListData(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                // if (close) {
                //     close();
                // }
                form.setFieldValue('truckInProperCondition', null);
                form.setFieldValue('awningWithoutDamage', null);
                form.setFieldValue('trailerFloorWithoutDamage', null);
                form.setFieldValue('driverName', null);
                form.setFieldValue('driverPhone', null);

                setSubmitted(true);

                //toast.success(<ToastLayout text="" title={t("driver-check-list.form.success-sent")}/>);
                }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                        setErrors(err.response.data.errors);
                    }
                // } else if (close) {
                //     close();
                //     toast.error(err.message);
                // }
            }
        }

        setDisabled(prev => !prev);
    }


    const form = useFormik<IConfirmTruckStatePostAction>({
        initialValues: {
            shipmentGid: model?.shipmentGid == null ? "" : model.shipmentGid,
            shipmentId: model.shipmentId == null ? "" : model.shipmentId,
            vehicleRegNumber: model.vehicleRegNumber == null ? "" : model.vehicleRegNumber,
            truckInProperCondition: null,
            awningWithoutDamage: null,
            trailerFloorWithoutDamage: null,
            driverName: model?.driverName == null ? "" : model.driverName,
            driverPhone: model?.driverPhone == null ? "" : model.driverPhone
        },

        validationSchema: Yup.object().shape({
            truckInProperCondition: Yup.string().nullable()
                .test('required-truck-in-proper-condition',
                    t("driver-check-list.errors.required-field"),
                    function (val) {
                        return !(val === null || val === undefined || val === '');
                    }
                ),
            awningWithoutDamage: Yup.string().nullable()
                .test('required-awning-without-damage',
                    t("driver-check-list.errors.required-field"),
                    function (val) {
                        return !(val === null || val === undefined || val === '');
                    }
                ),
            trailerFloorWithoutDamage: Yup.string().nullable()
                .test('required-floor-without-damage',
                    t("driver-check-list.errors.required-field"),
                    function (val) {
                        return !(val === null || val === undefined || val === '');
                    }
                ), 
            driverPhone: Yup.string().nullable()
                .test('required-driver-phone',
                    t("driver-check-list.errors.required-field"),
                    function (val) {
                        return !(val === null || val === undefined || val === '');
                    }
                ),  
                
            driverName: Yup.string().nullable()
                .test('required-driver-name',
                    t("driver-check-list.errors.required-field"),
                    function (val) {
                        return !(val === null || val === undefined || val === '');
                    }
                ),   
                
        }),
        validateOnBlur: false,
        validateOnChange: false,

        onSubmit: async (values: IConfirmTruckStatePostAction, actions: FormikHelpers<IConfirmTruckStatePostAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        
        <form id={'confirm-truck-state-form'} className={cl.wrap}
            onSubmit={form.handleSubmit}>

            {submitted ? (
                <div className={`${submitted ? cl.successSubmitted : ''}`}>
                    <span>{t("driver-check-list.form.success-sent")}</span>
                </div> 
            ) : null}

              
            <div className={`${cl.inner} ${submitted ? cl.innerHidden : ''}`}>
                <div className={cl.languageSwitcherContainer}>
                    <LanguageSwitcher languages={languages} languageIconPosition={'right'}/>
                </div>
                <h2 className={cl.title}>{t("driver-check-list.form.form-name")}</h2>

                <>
                {errors.length > 0 && errors.map((err, index) => {
                    switch (err.errorCode) {
                        case BLErrorCode.InnerError:
                            return (
                                <Alert type={'Error'}>
                                {t("shared.errors.server-error")}
                            </Alert>
                            );
                    }

                    return null;
                })}
                </>
                
                <div className={cl.item}>
                    <span className={`${cl.subtitle} ${cl.bold}`}>{t("driver-check-list.form.shipment-id")}</span>
                    <span>{ model.shipmentId }</span>
                </div>
                <div className={cl.item}>
                    <span className={`${cl.subtitle} ${cl.bold}`}>{t("driver-check-list.form.vehicle-reg-number")}</span>
                    <span>{ model.vehicleRegNumber }</span>
                </div>
                <div className={`${cl.item} ${cl.tooltip}`}>
                    <span>{t("driver-check-list.form.tooltip")}</span>
                </div>
                <div className={`${cl.choose}`}>
                    <span className={`${cl.subtitle} ${cl.bold}`}>{t("driver-check-list.form.truck-in-proper-condition")}</span>
                    <div className={cl.radiobuttons}>
                        <div className={cl.radio}>
                            <RadioButton name={`proper-condition`}
                                //value={`OwnBankAccount`}
                                size={'small'}
                                disabled={disabled}
                                checked={form.values.truckInProperCondition === true}
                                label={t("shared.btn.yes")}
                                onChange={() => {
                                    form.setFieldValue('truckInProperCondition', true);
                                }}
                                />
                        </div> 
                        <RadioButton name={`proper-condition`}
                            //value={`NewRequisites`}
                            size={'small'}
                            disabled={disabled}
                            checked={form.values.truckInProperCondition === false}
                            label={t("shared.btn.no")}
                            onChange={() => {
                                form.setFieldValue('truckInProperCondition', false);
                            }}
                            />   

                        {form.values.truckInProperCondition === null && form.errors.truckInProperCondition &&
                            <div className={`error-label ${cl.error}`}>
                                {form.errors.truckInProperCondition}
                            </div>
                        } 
                    </div>
                </div>
                <div className={`${cl.choose}`}>
                    <span className={`${cl.subtitle} ${cl.bold}`}>{t("driver-check-list.form.awning-without-damage")}</span>
                    <div className={cl.radiobuttons}>
                        <div className={cl.radio}>
                            <RadioButton name={`awning`}
                                //value={`OwnBankAccount`}
                                size={'small'}
                                disabled={disabled}
                                checked={form.values.awningWithoutDamage === true}
                                label={t("shared.btn.yes")}
                                onChange={() => {
                                    form.setFieldValue('awningWithoutDamage', true);
                                }}
                                />
                        </div> 
                        <RadioButton name={`awning`}
                            //value={`NewRequisites`}
                            size={'small'}
                            disabled={disabled}
                            checked={form.values.awningWithoutDamage === false}
                            label={t("shared.btn.no")}
                            onChange={() => {
                                form.setFieldValue('awningWithoutDamage', false);
                            }}
                            /> 

                        {form.values.awningWithoutDamage === null && form.errors.awningWithoutDamage &&
                            <div className={`error-label ${cl.error}`}>
                                {form.errors.awningWithoutDamage}
                            </div>
                        }
                    </div>     
                </div>
                <div className={`${cl.choose}`}>
                    <span className={`${cl.subtitle} ${cl.bold}`}>{t("driver-check-list.form.trailer-floor-without-damage")}</span>
                    <div className={cl.radiobuttons}>
                        <div className={cl.radio}>
                            <RadioButton name={`trailer-floor`}
                                //value={`OwnBankAccount`}
                                size={'small'}
                                disabled={disabled}
                                checked={form.values.trailerFloorWithoutDamage === true}
                                label={t("shared.btn.yes")}
                                onChange={() => {
                                    form.setFieldValue('trailerFloorWithoutDamage', true);
                                }}
                                />
                        </div> 
                        <RadioButton name={`trailer-floor`}
                            //value={`NewRequisites`}
                            size={'small'}
                            disabled={disabled}
                            checked={form.values.trailerFloorWithoutDamage === false}
                            label={t("shared.btn.no")}
                            onChange={() => {
                                form.setFieldValue('trailerFloorWithoutDamage', false);
                            }}
                            />
                        {form.values.trailerFloorWithoutDamage === null && form.errors.trailerFloorWithoutDamage &&
                            <div className={`error-label ${cl.error}`}>
                                {form.errors.trailerFloorWithoutDamage}
                            </div>
                        }
                    </div>    
                </div>
                <div className={`${cl.choose}`}>
                    <span className={cl.subtitle}>{t("driver-check-list.form.driver-name")}</span>
                    <Input className={cl.field}
                        disabled={disabled}
                        autoComplete={'off'}
                        name={'driverName'}
                        id={'driverName'}
                        //placeholder={t("shipment.form.truck-model")}
                        value={form.values.driverName ?? ''}
                        onChange={form.handleChange}
                    />

                    {!form.values.driverName && form.errors.driverName &&
                            <div className={`error-label ${cl.error}`}>
                                {form.errors.driverName}
                            </div>
                    } 
                </div>
                <div className={`${cl.choose}`}>
                    <span className={cl.subtitle}>{t("driver-check-list.form.driver-phone")}</span>
                    <Input className={cl.field}
                        disabled={disabled}
                        autoComplete={'off'}
                        name={'driverPhone'}
                        id={'driverPhone'}
                        //placeholder={t("shipment.form.truck-model")}
                        value={form.values.driverPhone ?? ''}
                        onChange={form.handleChange}
                    />

                    {!form.values.driverPhone && form.errors.driverPhone &&
                            <div className={`error-label ${cl.error}`}>
                                {form.errors.driverPhone}
                            </div>
                    } 
                </div>
                <div className={cl.btn}>
                    <Button buttonType={ButtonType.Primary}
                    type='submit'
                    disabled={form.isSubmitting}
                    isLoading={form.isSubmitting}
                    >
                    {t("shared.btn.confirm")}
                    </Button>
                </div>

                </div>

        </form>
    );
};

export default ConfirmTruckStateForm;
