import React, {useState} from 'react';
import {IRecallBetGetAction} from "../../../../../../app/interfaces/offer/IRecallBetGetAction";
import {IRecallBetPostAction} from "../../../../../../app/interfaces/offer/IRecallBetPostAction";
import {useTranslation} from "react-i18next";
import {SelectModel} from "../../../../../../app/types/SelectModel";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import Textarea from "../../../../../../components/ui/Textarea/Textarea";

type RecallBetFormProps = {
    model: IRecallBetGetAction;
    disabled: boolean;
    save: (model: IRecallBetPostAction) => Promise<void>;
};

const RecallBetForm: React.FC<RecallBetFormProps> = ({
                                                         model,
                                                         disabled,
                                                         save
                                                     }) => {
    const {t} = useTranslation();
    const [reasons] = useState<Array<SelectModel>>([
        {value: '1', text: t('offer.recall.recall-1')},
        {value: '2', text: t('offer.recall.recall-2')},
        {value: '3', text: t('offer.recall.recall-3')},
        {value: '4', text: t('offer.recall.recall-4')},
    ]);

    const form = useFormik<IRecallBetPostAction>({
        initialValues: {
            betIds: model.betIds,
            notes: null,
            reason: null,
        },
        validationSchema: Yup.object().shape({
            reason: Yup.string().required(t("shared.errors.required-error").replace('{0}', t("offer.form.cancel-offer-reason")))
        }),
        onSubmit: async (values: IRecallBetPostAction, actions: FormikHelpers<IRecallBetPostAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='recall-bet-offer-form'
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div className={'mgb-20'}
                     style={{
                         fontSize: '18px',
                         textAlign: 'center',
                         color: '#4a4a4a'
                     }}>
                    {t("offer.warnings.recall-bet-offer-confirmation")}
                </div>

                <div className={`mgb-10 drop-down-picker`}>
                    <label className={'view-label required reason-label'}>
                        {t("offer.form.cancel-offer-reason")}
                    </label>

                    <DropDownList data={reasons}
                                  textField="text"
                                  disabled={disabled || reasons.length <= 0}
                                  id={'recall-bet-offer-reason-value'}
                                  dataItemKey="value"
                                  value={form.values.reason !== null ? reasons.find(e => e.value === form.values.reason) : null}
                                  onChange={(ev) => {
                                      form.setFieldValue('reason', ev.target.value.value);
                                  }}/>

                    {form.errors.reason &&
                        <div className={'error-label'}>
                            {form.errors.reason}
                        </div>
                    }
                </div>

                <div className={'mgb-10'}>
                    <label className={'view-label comments-label'}>
                        {t("offer.form.comments")}
                    </label>
                    <Textarea disabled={disabled}
                              className={'cancel-offer-comments-value'}
                              style={{minHeight: '100px', resize: 'vertical', maxHeight: '150px'}}
                              value={form.values.notes ?? ''}
                              placeholder={t("offer.form.comments")}
                              onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                  form.setFieldValue('notes', ev.target.value);
                              }}/>
                </div>
            </div>
        </form>
    );
};

export default RecallBetForm;