import React from 'react';
import {useTranslation} from "react-i18next";
import {IShipment} from "../../../../../../../app/interfaces/shipment/IShipment";

type TruckProps = {
    shipment: IShipment;
}

const Truck: React.FC<TruckProps> = ({
                                         shipment
                                     }) => {
    const {t} = useTranslation();

    return (
        <>
            <label className={'truck-label'}>{t('shipment.form.truck')}</label>
            <span className={'details-box-item-field-value truck-value'}>
                {shipment.truck !== null && shipment.truck !== ''
                    ? <>{shipment.truck}</>
                    : <>{t("shared.labels.no-data")}</>
                }
            </span>
        </>
    );
};

export default Truck;