import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'barFilter',
    pathData: [
        'M4.5 9H13.5M2.25 4.5H15.75M6.75 13.5H11.25'
    ],
    viewBox: '0 0 18 18',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;