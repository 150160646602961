import React, {ReactNode, useCallback, useMemo} from 'react';
import {LayoutConfigEntity} from "../../../app/enums/LayoutConfigEntity";
import {useAppDispatch, useAppSelector} from "../../../app/store";
import {selectCurrentUser} from "../../../features/authentication/authenticationSliceSelectors";
import FormLoadingScreen from "../../ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {GridPanelLayoutContext} from "./utils";
import cl from './GridFilterPanelLayout.module.css';
import {useGridFilterPanelLayout} from "./hooks/useGridFilterPanelLayout";
import GridFilterPanelLayoutFiltersTab from "./tabs/GridFilterPanelLayoutFiltersTab/GridFilterPanelLayoutFiltersTab";
import {IGridFilterPanelLayoutContext} from "./interfaces/IGridFilterPanelLayoutContext";
import {
    selectFilter,
    selectFilterToApply, selectSelectedPersonalFilter,
    selectSelectedPredefinedFilter
} from "../../../features/filter/filterSliceSelectors";
import {FilterSliceEntity, setFilter, setFilterToApply} from "../../../features/filter/filterSlice";
import {GridFilter} from "../../../app/types/GridFilter";
import {IGridFilter} from "../../../app/interfaces/gridFilter/IGridFilter";
import {SelectModel} from "../../../app/types/SelectModel";

type GridFilterPanelLayoutProps = {
    id: string;
    entity: LayoutConfigEntity;
    header?: ReactNode;
    children?: ReactNode;

    onPersonalFilterRemove?: (filter: IGridFilter) => void;

    pinnedPersonalFilter?: number | null;
    onPersonalFilterPin?: (filter: IGridFilter | null) => void;

    pinnedPredefinedFilter?: string | null;
    pinnedPredefinedFilters?: Array<string> | null;
    onPredefinedFilterPin?: (filter: SelectModel, op: 'Add' | 'Remove') => void;
    onPredefinedFilterSelect?: (filter: SelectModel | null) => void;
}

const GridFilterPanelLayout: React.FC<GridFilterPanelLayoutProps> = ({
                                                                         id,
                                                                         header,
                                                                         entity,
                                                                         children,
                                                                         onPersonalFilterRemove,
                                                                         pinnedPersonalFilter,
                                                                         onPersonalFilterPin,
                                                                         onPredefinedFilterPin,
                                                                         pinnedPredefinedFilters,
                                                                         pinnedPredefinedFilter,
                                                                         onPredefinedFilterSelect
                                                                     }) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectCurrentUser);

    const filter = useAppSelector(state =>
        selectFilter(state, entity as FilterSliceEntity));
    const filterToApply = useAppSelector(state =>
        selectFilterToApply(state, entity as FilterSliceEntity));
    const selectedPersonalFilter = useAppSelector(state =>
        selectSelectedPersonalFilter(state, entity as FilterSliceEntity));
    const selectedPredefinedFilter = useAppSelector(state =>
        selectSelectedPredefinedFilter(state, entity as FilterSliceEntity));

    const {
        clearCounter,
        onClearCounterChange,

        disabled,
        setDisabled,

        predefinedFilters,
        personalFilters
    } = useGridFilterPanelLayout({
        entity: entity
    });

    const applyFilterToApply = useCallback((field: keyof GridFilter | Array<keyof GridFilter>, value: Array<string> | string | null) => {

        let obj = {
            ...filterToApply
        };

        if (value === null) {
            if (Array.isArray(field)) {
                for (let item of field) {
                    obj[item] = Array.isArray(obj[item])
                        ? [] as any
                        : value as any;
                }
            } else {
                obj[field] = Array.isArray(obj[field])
                    ? [] as any
                    : value as any;
            }
        } else {
            if (Array.isArray(field)) {
                for (let item of field) {
                    obj[item] = Array.isArray(obj[item]) && Array.isArray(value)
                        ? value as any
                        : value as any;
                }
            } else {
                obj[field] = Array.isArray(obj[field]) && Array.isArray(value)
                    ? value as any
                    : value as any;
            }
        }

        dispatch(setFilterToApply({
            entity: entity as FilterSliceEntity,
            data: obj
        }));
    }, [dispatch, filterToApply]);

    const onKeyDown = useCallback((ev: React.KeyboardEvent, _field: keyof GridFilter) => {

        if (ev.key === 'Enter') {

            dispatch(setFilter({
                entity: entity as FilterSliceEntity,

                data: {...filterToApply, uid: new Date().getTime() + ""}
            }));
        }
    }, [dispatch, filterToApply]);

    const value = useMemo(() => ({
        clearCounter,
        onClearCounterChange,
        disabled,
        setDisabled,
        filter,
        filterToApply,
        applyFilterToApply,
        personalFilters,
        predefinedFilters,
        selectedPersonalFilter,
        selectedPredefinedFilter,
        onPersonalFilterRemove,
        pinnedPersonalFilter,
        onPersonalFilterPin,
        pinnedPredefinedFilters,
        onPredefinedFilterPin,
        pinnedPredefinedFilter,
        onPredefinedFilterSelect,
        onKeyDown,
        entity: entity as FilterSliceEntity
    } as IGridFilterPanelLayoutContext), [
        clearCounter,
        onClearCounterChange,
        disabled,
        setDisabled,
        filter,
        filterToApply,
        applyFilterToApply,
        personalFilters,
        predefinedFilters,
        entity,
        selectedPersonalFilter,
        selectedPredefinedFilter,
        onPersonalFilterRemove,
        pinnedPersonalFilter,
        onPersonalFilterPin,
        pinnedPredefinedFilters,
        onPredefinedFilterPin,
        pinnedPredefinedFilter,
        onPredefinedFilterSelect,
        onKeyDown
    ]);

    if (!user || predefinedFilters === null || personalFilters === null) {
        return <FormLoadingScreen style={{padding: '5px'}}/>;
    }


    let isHeaderExists = header !== null && header !== undefined;

    return (
        <GridPanelLayoutContext.Provider value={value}>
            <div id={id}
                 className={`grid-filter hidden ${cl.container}`}
                 data-type={isHeaderExists ? 'h' : 'default'}>
                {isHeaderExists &&
                    <div className={cl.header}>
                        {header}
                    </div>
                }
                <GridFilterPanelLayoutFiltersTab disabled={disabled}
                                                 children={children}/>
            </div>
        </GridPanelLayoutContext.Provider>
    );
};

export default GridFilterPanelLayout;
