import React, {useEffect, useState} from 'react';
import Card, {CardAppearAnimationType} from "../../../../components/ui/Card/Card";
import Container from "../../../../components/ui/Container/Container";
import {useTranslation} from "react-i18next";
import cl from './ProfileSupport.module.css';
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../app/enums/ResponseResultCode";
import FormLoadingScreen from "../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import Button, {ButtonType} from "../../../../components/ui/Button/Button";
import {ISupportGetAction} from "../../../../app/interfaces/profile/ISupportGetAction";
import {useProfileApi} from "../../../../app/api/profile";
import {ISupportPostAction} from "../../../../app/interfaces/profile/ISupportPostAction";
import ProfileSupportForm from "./ProfileSupportForm/ProfileSupportForm";
import useDocumentTitle from "../../../../hooks/useDocumentTitle/useDocumentTitle";

const ProfileSupport = () => {
    const {t} = useTranslation();

    const [model, setModel] = useState<ISupportGetAction | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);

    useDocumentTitle({
        title: t("profile.support.support-title")
    });

    const {
        getContactSupportData: {
            cancel: cancelGetContactSupportData,
            query: getContactSupportData,
            isLoading
        },
        contactSupport: {
            cancel: cancelContactSupport,
            mutation: contactSupport
        }
    } = useProfileApi();

    useEffect(() => {
        (async () => {
            await load();
        })();

        return () => {
            cancelGetContactSupportData();
            cancelContactSupport();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const load = async () => {
        try {
            const response = await getContactSupportData();
            if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                setModel(response.data.data);
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error<string>('Unable to get contact support data');
            }
        }
    }

    const save = async (model: ISupportPostAction): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            const response = await contactSupport(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                toast.success(t("profile.support.sent-label"));

                setModel(null);

                await load();
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(e.message);
            }
        }

        setDisabled(prev => !prev);
    }

    return (
        <div className={'h100 w100 hidden'}>
            <Container centerContent={true}>
                <Card cardAppearAnimationType={CardAppearAnimationType.FadeIn}
                      style={{
                          width: '500px'
                      }}>
                    <div className={`default-form-collapse-header prevent-selection ${cl.header}`}>
                        {t("profile.support.support-label")}
                    </div>
                    <div>
                        <div className={'dialog-default-content-container'}>
                            {isLoading || model === null
                                ? <FormLoadingScreen height={`340px`} style={{padding: '5px'}}/>
                                : null
                            }

                            {!isLoading && model &&
                                <ProfileSupportForm disabled={disabled}
                                                    model={model}
                                                    save={save}/>
                            }
                        </div>
                        <div className={'dialog-default-buttons-container'}>
                            <Button buttonType={ButtonType.Secondary}
                                    disabled={disabled}
                                    form='contact-support-form'
                                    type='submit'
                                    style={{minWidth: '150px'}}
                                    className={'confirm-button'}
                                    isLoading={disabled}>
                                {t("shared.btn.confirm")}
                            </Button>
                        </div>
                    </div>
                </Card>
            </Container>
        </div>
    );
};

export default ProfileSupport;