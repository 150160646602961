import React from 'react';
import {useTranslation} from "react-i18next";
import cl from './CommodityCode.module.css';

type CommodityCodeProps = {
    commodityCode: Array<Record<string, string>> | null;
    adr: Array<Record<string, string>> | null;
}

const CommodityCode: React.FC<CommodityCodeProps> = ({
                                                         commodityCode,
                                                         adr
                                                     }) => {
    const {t} = useTranslation();

    return (
        <>
            <label className={'commodity-code-label'}>
                {t('shipment.form.commodity-code')}
            </label>
            <span className={'details-box-item-field-value commodity-code-value'}>
                {commodityCode !== null && commodityCode.length > 0
                    ? commodityCode.map(code => {
                        let ad = '';

                        if (adr !== null && adr.length > 0) {
                            let item = adr.find(e => e.key === code.key);
                            if (item !== undefined && item !== null &&
                                item.value !== null &&
                                item.value !== undefined &&
                                item.value !== '') {
                                ad = item.value;
                            }
                        }

                        return (
                            <React.Fragment key={code.value}>
                                <span className={'details-box-item-field-value'}>
                                    {code.value}
                                </span>
                                {ad !== '' && ad.toLowerCase() === 'yes'
                                    && <span className={`${cl.hazardous}`}>
                                        hazardous
                                    </span>
                                }
                                {ad !== '' && ad.toLowerCase() !== 'yes'
                                    && <span className={`${cl.hazardous}`}>
                                        {ad}
                                    </span>
                                }
                            </React.Fragment>
                        )
                    })
                    : <span className={'details-box-item-field-value'}>
                        {t("shared.labels.no-data")}
                        {adr !== null && adr.length > 0 &&
                            <span className={cl.hazardous}>hazardous</span>
                        }
                    </span>
                }

            </span>
        </>
    );
};

export default CommodityCode;
