import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";

type ChatAvatarProps = {
    isOnline: boolean;
    onVacation: boolean;
    size: 'Small' | 'Medium' | 'Big';
};

const ChatAvatar: React.FC<ChatAvatarProps> = ({
                                                   isOnline,
                                                   onVacation,
                                                   size
                                               }) => {
    return (
        <div className={`chat-avatar relative`}
             data-size={size}>
            {isOnline && <div data-size={size} className={`chat-avatar-marker chat-avatar-online`}></div>}
            {onVacation && <div data-size={size} className={`chat-avatar-marker chat-avatar-online`}></div>}

            <FontAwesomeIcon icon={faUser}/>
        </div>
    );
};

export default ChatAvatar;