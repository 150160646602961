import {useContext} from "react";
import {IChatHelperContext} from "../interfaces/IChatHelperContext";
import {ChatHelperContext} from "../contexts";

export const useChatHelperContext = () => {
    const context = useContext<IChatHelperContext>(ChatHelperContext);

    if (context === undefined) {
        throw new Error('Chat component not defined as a parent');
    }

    return context;
};