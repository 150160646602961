import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import useIfFirstRender from "../../../../hooks/useIsFirstRender/useIfFirstRender";
import {useAppSelector} from "../../../../app/store";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSliceSelectors";
import GridLayout from "../../../../components/uiLayouts/GridLayout/GridLayout";
import {getUserRole} from "../../../../helpers/user";
import {LayoutConfigEntity} from "../../../../app/enums/LayoutConfigEntity";
import DetailsGridLayout from "../../../../components/uiLayouts/DetailsGridLayout/DetailsGridLayout";
import OfferForm from "../form/OfferForm";
import OfferGridContent from "./components/OfferGridContent/OfferGridContent";
import OfferActionRow from "./components/OfferActionRow/OfferActionRow";
import OfferFilterPanel from "./components/OfferFilterPanel/OfferFilterPanel";
import getDefaultOfferHeaderConfig from "./data/header";
import getOfferInitialGridConfig from "./data/columns";
import useMediaQuery from "../../../../hooks/useMediaQuery/useMediaQuery";
import {isMobile} from "react-device-detect";

const OfferGrid = () => {
    const [isSideDetailsOpened, setIsSideDetailsOpened] = useState<boolean>(false);
    const [isFullDetailsOpened, setIsFullDetailsOpened] = useState<boolean>(false);
    const [isUrlChecked, setIsUrlChecked] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const [detailsSelectedItem, setDetailsSelectedItem] = useState<string | null>(null);

    const isFirstRender = useIfFirstRender();
    const user = useAppSelector(selectCurrentUser);
    const isMaxWidth600 = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get('id')) {
            setIsFullDetailsOpened(true);
            setDetailsSelectedItem(urlParams.get('id'));
        }

        setIsUrlChecked(true);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isFirstRender || !isUrlChecked) {
            return;
        }
        const urlParams = new URLSearchParams(window.location.search);

        if (isFullDetailsOpened && !urlParams.get('id')) {
            setIsFullDetailsOpened(false);
        }

        if (!isFullDetailsOpened && urlParams.get('id')) {
            setIsFullDetailsOpened(true);
            setDetailsSelectedItem(urlParams.get('id'));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFirstRender, searchParams, isUrlChecked]);

    if (!user || !isUrlChecked) {
        return null;
    }

    return (
        <DetailsGridLayout id={'offer-details'}
                           details={<OfferForm id={detailsSelectedItem}
                                               isFullPageDetails={isFullDetailsOpened}
                                               close={() => {
                                                   if (searchParams.has('id')) {
                                                       searchParams.delete('id');

                                                       setSearchParams(searchParams);
                                                   }

                                                   if (isFullDetailsOpened) {
                                                       setIsFullDetailsOpened(false);
                                                   }

                                                   if (isSideDetailsOpened) {
                                                       setIsSideDetailsOpened(false);
                                                   }
                                               }}/>}
                           grid={
                               <GridLayout grid={<OfferGridContent
                                   onRowDoubleClick={(ev) => {
                                       if (ev.dataItem) {
                                           setIsSideDetailsOpened(false);

                                           setDetailsSelectedItem(ev.dataItem.id);

                                           setSearchParams({
                                               'id': ev.dataItem.id,
                                           });

                                           setIsFullDetailsOpened(prev => !prev)
                                       }
                                   }}
                                   onRowClick={(ev) => {
                                       if (ev.dataItem) {
                                           if (isMobile || isMaxWidth600) {
                                               setDetailsSelectedItem(ev.dataItem.id);

                                               setSearchParams({
                                                   'id': ev.dataItem.id,
                                               });

                                               setIsFullDetailsOpened(prev => !prev);
                                           } else {
                                               setIsSideDetailsOpened(prev => {
                                                   setDetailsSelectedItem(ev.dataItem.id);

                                                   if (prev) {
                                                       return !(detailsSelectedItem !== null && detailsSelectedItem === ev.dataItem.id);
                                                   }

                                                   return true;
                                               });
                                           }
                                       }
                                   }}/>}
                                           header={<OfferActionRow/>}
                                           filterPanel={<OfferFilterPanel/>}

                                           defaultHeaderConfig={getDefaultOfferHeaderConfig(getUserRole(user))}
                                           defaultGridConfig={getOfferInitialGridConfig(getUserRole(user))}

                                           entity={LayoutConfigEntity.Offer}/>
                           }
                           isFullDetailsOpened={isFullDetailsOpened}
                           setIsFullDetailsOpened={setIsFullDetailsOpened}
                           isSideDetailsOpened={isSideDetailsOpened}
                           setIsSideDetailsOpened={setIsSideDetailsOpened}/>
    );
};

export default OfferGrid;