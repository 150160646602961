import React from 'react';
import {RouteSectionModel} from "../../RouteSection";
import {useTranslation} from "react-i18next";

type EquipmentProps = {
    model: RouteSectionModel;
}

const Equipment: React.FC<EquipmentProps> = ({model}) => {
    const {t} = useTranslation();

    return (
        <>
            <label className={'equipment-label'}>
                {t('quote.form.equipment-group')}
            </label>
            <span className={'details-box-item-field-value equipment-value'}>
                 {model.equipment !== undefined && model.equipment !== null && model.equipment !== ''
                     ? <>{model.equipment}</>
                     : <>{t("shared.labels.no-data")}</>
                 }
                {model.equipmentGroupName !== undefined && model.equipmentGroupName !== null && model.equipmentGroupName !== ''
                    ? <>{` / ${model.equipmentGroupName}`}</>
                    : <>{`${model.equipment !== undefined && model.equipment !== null && model.equipment !== '' ? ` / ${t("shared.labels.no-data")}` : ''}`}</>
                }
            </span>
        </>
    );
};

export default Equipment;