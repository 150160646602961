import React, {useContext, useState} from 'react';
import {ExtendedNettoBet, QuoteNettoBetListContext} from "../../QuoteNettoBetList";
import Button, {ButtonType} from "../../../../../../../../components/ui/Button/Button";
import {useTranslation} from "react-i18next";
import cl from './QuoteNettoBetListItemContent.module.css';
import QuoteNettoBetListItemForm from "./QuoteNettoBetListItemForm/QuoteNettoBetListItemForm";
import Alert from "../../../../../../../../components/ui/Alert/Alert";
import SendTranspareonAccountRequestDialog
    from '../../../../../actions/SendTranspareonAccountRequest/SendTranspareonAccountRequestDialog';
import Loader, {LoaderType} from "../../../../../../../../components/ui/loaders/Loader/Loader";
import {BLErrorCode} from "../../../../../../../../app/enums/BLErrorCode";
import {IQuote} from "../../../../../../../../app/interfaces/quote/IQuote";

type QuoteNettoBetListItemContentProps = {
    nettoBet: ExtendedNettoBet;
    disabled: boolean;
    resetNumber: number;
    setResetNumber: React.Dispatch<React.SetStateAction<number>>;
    quote: IQuote;
}

const alertStyle: React.CSSProperties = {
    margin: '0px 0px 5px 0px',
    fontSize: '14px',
    padding: '10px',
}

const QuoteNettoBetListItemContent: React.FC<QuoteNettoBetListItemContentProps> = ({
                                                                                       nettoBet,
                                                                                       disabled,
                                                                                       resetNumber,
                                                                                       setResetNumber,
                                                                                       quote
                                                                                   }) => {
    const {t} = useTranslation();
    const {resetNettoBet} = useContext(QuoteNettoBetListContext);
    const [isSendRequestDialogShown, setIsSendRequestDialogShown] = useState<boolean>(false);

    return (
        <div className={`netto-bet-form-${nettoBet.id}`}>
            <div>
                {nettoBet.errors.length > 0 &&
                    nettoBet.errors.map(err => {
                        let error: string;

                        switch (err.errorCode) {
                            case BLErrorCode.SupplierNotFound:
                                error = `${t("quote.errors.bet-supplier-not-found")} ${err.description ? `(${err.description})` : ''}`;
                                break;
                            case BLErrorCode.EquipmentGroupRequired:
                                error = t("shared.errors.required-error")
                                    .replace('{0}', t("quote.bet.equipment-group"));
                                break;
                            case BLErrorCode.BetRequired:
                                error = t("shared.errors.required-error")
                                    .replace('{0}', t("quote.bet.netto-bet"));
                                break;
                            case BLErrorCode.BetNotValid:
                                error = t("quote.errors.bet-netto-bet-is-not-valid");
                                break;
                            case BLErrorCode.SellRateNotValid:
                                error = t("quote.errors.bet-sell-rate-is-not-valid");
                                break;
                            case BLErrorCode.SellRateRequired:
                                error = t("shared.errors.required-error")
                                    .replace('{0}', t("quote.bet.sell-rate"));
                                break;
                            case BLErrorCode.AsstraCompanyRequired:
                                error = t("shared.errors.required-error")
                                    .replace('{0}', t("quote.bet.asstra-company"));
                                break;
                            case BLErrorCode.TillDateNotValid:
                                error = t("quote.errors.bet-till-date-is-not-valid");
                                break;
                            case BLErrorCode.MaxLimitExceeded:
                                switch (err.field) {
                                    case "DemurrageNotes":
                                        error = t("quote.errors.bet-comments-limit-exceeded")
                                            .replace('{0}', t("quote.bet.has-demurrage-notes"));
                                        break;
                                    case "TransitCountries":
                                        error = t("quote.errors.bet-comments-limit-exceeded")
                                            .replace('{0}', t("quote.bet.has-transit-countries"));
                                        break;
                                    case "BorderCrossingPoints":
                                        error = t("quote.errors.bet-comments-limit-exceeded")
                                            .replace('{0}', t("quote.bet.has-border-crossing-points"));
                                        break;  
                                    case "Notes":
                                        error = t("quote.errors.bet-comments-limit-exceeded")
                                            .replace('{0}', t("quote.bet.comments"));
                                        break;   
                                    case "AdditionalComments":
                                        error = t("quote.errors.bet-comments-limit-exceeded")
                                            .replace('{0}', t("quote.bet.additional-comments"));
                                        break;  
                                    case "PayableWeight":
                                        error = t("quote.errors.bet-comments-limit-exceeded")
                                            .replace('{0}', t("quote.bet.has-payable-weight"));
                                        break;
                                    case "RateIncludes":
                                        error = t("quote.errors.bet-comments-limit-exceeded")
                                            .replace('{0}', t("quote.bet.has-rate-includes"));
                                        break; 
                                    case "RateExcludes":
                                        error = t("quote.errors.bet-comments-limit-exceeded")
                                            .replace('{0}', t("quote.bet.has-rate-excludes"));
                                        break;
                                    default:
                                        error = t("quote.errors.bet-comments-limit-exceeded")
                                            .replace('{0}', '');
                                        break;
                                }
                                break;
                            default:
                                error = "code: " + err.errorCode;
                                break;
                        }

                        return (
                            <Alert type={'Error'} key={`netto-bet-error-${nettoBet.uuid}-${err.errorCode}`}>
                                {error}
                            </Alert>
                        )
                    })
                }

                {nettoBet.showAlert && nettoBet.alertText !== null && nettoBet.alertText !== '' &&
                    <Alert type={'BrightGrayInfo'} style={alertStyle}>
                        <div style={{lineHeight: '17px'}}
                             dangerouslySetInnerHTML={{__html: nettoBet.alertText}}>
                        </div>
                    </Alert>
                }

                {nettoBet.isNotRegisteredInTranspareonAccount &&
                    <>
                        <Alert type={'BrightGrayInfo'} style={alertStyle}>
                            <div>
                                <div style={{lineHeight: '17px'}}>
                                    {t("quote.bet.not-registered-in-transporeon-account")}
                                    <span className={cl.sendRequest}
                                          onClick={() => setIsSendRequestDialogShown(prev => !prev)}>
                                    {t("shared.btn.send-request")}
                                </span>
                                </div>
                            </div>
                        </Alert>
                        <SendTranspareonAccountRequestDialog gid={`AAG.${nettoBet.orderReleaseXid}`}
                                                             asstraCompany={nettoBet.asstraCompany ?? ''}
                                                             show={isSendRequestDialogShown}
                                                             setShow={setIsSendRequestDialogShown}/>
                    </>
                }

                {nettoBet.hasNoAccessToQuoteStructure &&
                    <Alert type={'BrightGrayInfo'} style={alertStyle}>
                        {t("quote.bet.has-no-access-to-quote-structure-warning")}
                    </Alert>
                }

                {nettoBet.isQuoteInTransporeon &&
                    <Alert type={'BrightGrayInfo'} style={alertStyle}>
                        {t("quote.bet.quote-in-transporeon-warning")}
                    </Alert>
                }
            </div>

            <div className={`content-section relative`}>
                {nettoBet.isSaveLoading &&
                    <div className={`action-loader-container ${cl.loaderContainer}`}>
                        <div>
                            <Loader type={LoaderType.Action} style={{scale: '0.4'}}/>
                        </div>
                    </div>
                }

                <QuoteNettoBetListItemForm nettoBet={nettoBet}
                                           quote={quote}
                                           disabled={disabled}
                                           resetNumber={resetNumber}/>
            </div>

            {!nettoBet.isNotRegisteredInTranspareonAccount && nettoBet.isEditMode &&
                <div className={`btn-section ${cl.buttons}`}>
                    <Button buttonType={ButtonType.Transparent}
                            onClick={() => {
                                resetNettoBet(nettoBet);
                                setResetNumber(prev => prev + 1);
                            }}>
                        {t("shared.btn.cancel")}
                    </Button>
                    <Button buttonType={ButtonType.Primary}
                            type={'submit'}
                            form={`netto-bet-form-${nettoBet.uuid}`}>
                        {t("shared.btn.save")}
                    </Button>
                </div>
            }
        </div>
    );
};

export default QuoteNettoBetListItemContent;
