import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AuthenticationStatus, IAuthenticationState} from "./IAuthenticationState";
import apiAxios from "../../app/axios/apiAxios";
import {IDataResponse, IResponse, isResponse} from "../../app/interfaces/response/IResponse";
import {ResponseResultCode} from "../../app/enums/ResponseResultCode";
import {IUserDevice} from "../../app/interfaces/user/IUserDevice";
import {AxiosError, AxiosResponse} from "axios";
import {load} from "../../utils/fingerprint/agent";
import {IUser} from "../../app/interfaces/user/IUser";
import {setLanguage} from "../application/applicationSlice";
import {setAccessSettings, setFilterPanelConfiguration} from "../filter/filterSlice";
import {Language} from "../../app/enums/Language";
import {LANGUAGE_LOCAL_STORAGE_KEY} from "../../components/ui/LanguageSwitcher/LanguageSwitcher";

const initialState: IAuthenticationState = {
    userAuthenticationStatus: AuthenticationStatus.NoAction,
    deviceAuthenticationStatus: AuthenticationStatus.NoAction,
    anonymousUserAuthenticationStatus: AuthenticationStatus.NoAction
};

export const getCurrentUser = createAsyncThunk<IDataResponse<IUser | undefined>, undefined>(
    "authentication/getCurrentUser",
    async (_, thunkAPI) => {
        try {
            const response = await apiAxios.get<IDataResponse<IUser | undefined>>(
                `api/${process.env.REACT_APP_API_VERSION}/user/getCurrentUser`);

            if (response.data.data?.filterConfiguration) {
                thunkAPI.dispatch(setFilterPanelConfiguration(response.data.data.filterConfiguration));
            }

            if (response.data.data?.userAccessSettings) {
                thunkAPI.dispatch(setAccessSettings(response.data.data.userAccessSettings));
            }

            return {
                resultCode: response.data.resultCode,
                data: response.data.data
            };
        } catch (err) {
            if (err instanceof AxiosError && err.response && err.response.data) {
                if (isResponse(err.response.data)) {
                    return {
                        resultCode: err.response.data.resultCode,
                        data: undefined
                    };
                }
            }
        }

        return {
            resultCode: ResponseResultCode.NotFound,
            data: undefined
        };
    }
);

export const validateUserAuthentication = createAsyncThunk<boolean, undefined, { rejectValue: boolean }>(
    "authentication/validateUserAuthentication",
    async (_, thunkAPI) => {
        // Clear authentication slice, if not problems with re-login begin
        thunkAPI.dispatch(clear());

        try {
            const response = await apiAxios
                .get<IDataResponse<IUser>>(`api/${process.env.REACT_APP_API_VERSION}/authentication/isUserAuthenticated`);

            if ((response.status === 200 || response.status === 204) &&
                response.data && response.data.data) {
                thunkAPI.dispatch(setLanguage(response.data.data.language));
                localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, Language[response.data.data.language.toString().toLowerCase() as keyof typeof Language].toString().toLowerCase())
            }

            return response.status === 200 || response.status === 204;
        } catch (err) {
            thunkAPI.rejectWithValue(false);

            return false;
        }
    }
);

export const validateAnonymousUserAuthentication = createAsyncThunk<boolean, {
    type: string;
    code: string;
    device: string;
    user?: string;
    id?: string;
    sid?: string;
}, { rejectValue: boolean }>(
    "authentication/validateAnonymousUserAuthentication",
    async (params, thunkAPI) => {
        try {
            const headers: any = {
                "x-a-asstra-code": params.code,
                "x-a-asstra-type": params.type,
                "x-a-asstra-device": params.device,
            };

            if (params.sid) {
                headers['x-a-asstra-sid'] = params.sid;
            }
            if (params.id) {
                headers['x-a-asstra-id'] = params.id;
            }
            if (params.user) {
                headers['x-a-asstra-user'] = params.user;
            }

            const response = await apiAxios.get<IDataResponse<IUser>>(
                `api/${process.env.REACT_APP_API_VERSION}/authentication/isAnonymousUserAuthenticated`, {
                    headers: headers
                }
            );

            if ((response.status === 200 || response.status === 204) &&
                response.data && response.data.data) {
                thunkAPI.dispatch(setAnonymousUserId(response.data.data.uniqueUserValue ?? undefined));

                return true;
            }

            thunkAPI.rejectWithValue(false);

            return false;
        } catch (err) {
            thunkAPI.rejectWithValue(false);

            return false;
        }
    }
);

export const validateDeviceAuthentication = createAsyncThunk<IResponse, void>(
    "authentication/validateDeviceAuthentication",
    async (_) => {
        try {
            const fp = await load();

            const {visitorId} = await fp.get();

            const response: AxiosResponse<IResponse, IUserDevice> = await apiAxios.post<IResponse>(
                `api/${process.env.REACT_APP_API_VERSION}/device/validateDeviceAuthentication`,
                {
                    deviceId: visitorId
                } as IUserDevice);

            if (response && (response.status === 200 || response.status === 204)
                && response.data && response.data.resultCode === ResponseResultCode.Ok) {
                return {
                    resultCode: response.data.resultCode
                }
            }

        } catch (err) {
            if (err instanceof AxiosError && err.response && err.response.data) {
                if (isResponse(err.response.data)) {
                    return {
                        resultCode: err.response.data.resultCode
                    }
                }
            }
        }

        return {
            resultCode: ResponseResultCode.Unauthorized
        }
    }
);

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        clear: (state) => {
            state.userAuthenticationStatus = initialState.userAuthenticationStatus;
            state.deviceAuthenticationResult = initialState.deviceAuthenticationResult;
            state.deviceAuthenticationStatus = initialState.deviceAuthenticationStatus;
            state.user = initialState.user;
        },
        setAuthenticationState: (state, action: PayloadAction<IAuthenticationState>) => {
            state.userAuthenticationStatus = action.payload.userAuthenticationStatus;
            state.deviceAuthenticationStatus = action.payload.deviceAuthenticationStatus;
            state.deviceAuthenticationResult = action.payload.deviceAuthenticationResult;
        },
        setAnonymousUserCode: (state, action: PayloadAction<string | undefined>) => {
            state.anonymousUserCode = action.payload;
        },
        setAnonymousUserAuthenticationStatus: (state, action: PayloadAction<AuthenticationStatus | undefined>) => {
            state.anonymousUserAuthenticationStatus = action.payload;
        },
        setAnonymousUserId: (state, action: PayloadAction<string | undefined>) => {
            state.anonymousUserId = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(validateUserAuthentication.pending, (state) => {
            state.userAuthenticationStatus = AuthenticationStatus.InProgress;
        });
        builder.addCase(validateUserAuthentication.fulfilled, (state, {payload}) => {
            if (payload) {
                state.userAuthenticationStatus = AuthenticationStatus.Authenticated;
            } else {
                state.userAuthenticationStatus = AuthenticationStatus.NotAuthenticated;
            }
        });
        builder.addCase(validateUserAuthentication.rejected, (state, {payload}) => {
            if (payload) {
                state.userAuthenticationStatus = AuthenticationStatus.Authenticated;
            } else {
                state.userAuthenticationStatus = AuthenticationStatus.NotAuthenticated;
            }
        });
        builder.addCase(validateDeviceAuthentication.pending, (state) => {
            state.deviceAuthenticationStatus = AuthenticationStatus.InProgress;
        });
        builder.addCase(validateDeviceAuthentication.fulfilled, (state, {payload}) => {
            if (payload.resultCode === ResponseResultCode.Ok) {
                state.deviceAuthenticationStatus = AuthenticationStatus.Authenticated;
            } else {
                state.deviceAuthenticationStatus = AuthenticationStatus.NotAuthenticated;
            }

            state.deviceAuthenticationResult = payload.resultCode;
        });

        builder.addCase(getCurrentUser.fulfilled, (state, {payload}) => {
            if (payload.data) {
                state.user = payload.data;
            }
        });

        builder.addCase(validateAnonymousUserAuthentication.pending, (state) => {
            state.anonymousUserAuthenticationStatus = AuthenticationStatus.InProgress;
        });
        builder.addCase(validateAnonymousUserAuthentication.fulfilled, (state, {payload}) => {
            if (payload) {
                state.anonymousUserAuthenticationStatus = AuthenticationStatus.Authenticated;
            } else {
                state.anonymousUserAuthenticationStatus = AuthenticationStatus.NotAuthenticated;
            }
        });
        builder.addCase(validateAnonymousUserAuthentication.rejected, (state, {payload}) => {
            if (payload) {
                state.anonymousUserAuthenticationStatus = AuthenticationStatus.Authenticated;
            } else {
                state.anonymousUserAuthenticationStatus = AuthenticationStatus.NotAuthenticated;
            }
        });
    }
});

export const {
    clear,
    setAuthenticationState,
    setAnonymousUserCode,
    setAnonymousUserAuthenticationStatus,
    setAnonymousUserId
} = authenticationSlice.actions;
export default authenticationSlice.reducer;
