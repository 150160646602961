import {AxiosResponse} from "axios";
import {IDataResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {ISearchGlobalItem} from "../../interfaces/shared/ISearchGlobalItem";

export const useSearchGlobal = (): {
    isLoading: boolean,
    cancel: () => void,
    searchGlobal: (filter: string | null) => Promise<AxiosResponse<IDataResponse<Array<ISearchGlobalItem>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<ISearchGlobalItem>>>({
        method: 'get'
    });

    const searchGlobal = useCallback(async (filter: string | null) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/search/searchGlobal?filter=${filter}`
    }), [execute]);

    return {searchGlobal, isLoading, cancel};
};