import React from 'react';
import {IUser} from "../../../../../app/interfaces/user/IUser";
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import EditBillingData from "./EditBillingData";

type EditBillingDataDialogProps = {
    user?: IUser;
    shipmentGid: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditBillingDataDialog: React.FC<EditBillingDataDialogProps> = ({
                                                                         user,
                                                                         show,
                                                                         setShow,
                                                                         shipmentGid
                                                                     }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'edit-billing-data-dialog'}
                show={show}
                setShow={setShow}
                width={'850px'}
                title={t("self-billing.labels.billing-data-label")}>
            <EditBillingData shipmentGid={shipmentGid}
                             user={user}
                             close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default EditBillingDataDialog;