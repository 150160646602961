import React, {useEffect, useState} from 'react';
import ListBox from "../../../../ui/ListBox/ListBox";
import {IUserAccessSettings} from "../../../../../app/interfaces/user/IUserAccessSettings";
import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {useDataHelperApi} from "../../../../../app/api/dataHelper";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {IListTransportLineItem} from "../../../../../app/interfaces/shared/IListTransportLineItem";
import TagContainer from "../../../../ui/Tag/TagContainer/TagContainer";
import {TagItem, TagItemBackgroundColor} from "../../../../ui/Tag/Tag";
import {selectAccessSettingsTransportLinesSource} from "../../../../../features/filter/filterSliceSelectors";
import {setAccessSettings, setAccessSettingsData} from "../../../../../features/filter/filterSlice";

type AccessSettingsTransportLineSourceFilterProps = {
    disabled: boolean;
    settings: IUserAccessSettings;
    isExpanded: boolean;
}

const AccessSettingsTransportLineSourceFilter: React.FC<AccessSettingsTransportLineSourceFilterProps> = ({
                                                                                                             disabled,
                                                                                                             settings,
                                                                                                             isExpanded
                                                                                                         }) => {
    const [data, setData] = useState<Array<IListTransportLineItem>>([]);
    const tls = useAppSelector(selectAccessSettingsTransportLinesSource);
    const dispatch = useAppDispatch();

    const {
        getAccessFilterTransportLines: {
            query,
            isLoading
        }
    } = useDataHelperApi();

    useEffect(() => {
        if (tls.length > 0) {
            return;
        }

        (async () => {
            try {
                const response = await query('source');

                if (response?.data?.resultCode === ResponseResultCode.Ok && response?.data.data) {
                    dispatch(setAccessSettingsData({
                        entity: 'TransportLinesSource',
                        data: {
                            transportLinesSource: response.data.data
                        } as any
                    }));
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error('Unable to load transport lines list: ' + e.message);
                }
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (data.length > 0 || tls.length <= 0) {
            return;
        }

        setData([...tls]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tls]);

    const onFilter = (value: string | null) => {
        if (!value || value === '') {
            setData([...tls]);
        } else {
            setData([
                ...tls.filter(item => {
                    return item.source.toLowerCase().includes(value.toLowerCase())
                })
            ]);
        }
    }

    return (
        <div style={{margin: '10px 0'}}>
            {isExpanded &&
                <div style={{marginBottom: '5px'}}>
                    <TagContainer id={'access-settings-tls-filter-tags'}
                                  width={'100%'}
                                  height={'100px'}
                                  items={settings.transportLinesSource.filter(e => {
                                      return tls.find(ex => ex.source === e) !== undefined;
                                  }).map((item) => {
                                      let existItem = tls.find(ex => ex.source === item) as IListTransportLineItem;

                                      return {
                                          id: `tld-tag-${existItem.source}`,
                                          text: existItem.source,
                                          data: existItem,
                                          showClearIcon: true,
                                          backgroundColor: TagItemBackgroundColor.LightGray,
                                          rounded: true
                                      } as TagItem;
                                  })}
                                  onClear={({data}) => {
                                      if (data !== undefined && data !== null) {
                                          let item = data as IListTransportLineItem;

                                          dispatch(setAccessSettings({
                                              ...settings,
                                              transportLinesSource: [
                                                  ...settings.transportLinesSource.filter(e => e !== item.source)
                                              ]
                                          }));
                                      }
                                  }}/>
                </div>
            }
            <ListBox id={'access-settings-tls-filter'}
                     selected={settings.transportLinesSource.map(item => {
                         return {
                             text: item,
                             value: item
                         }
                     })}
                     disabled={disabled}
                     data={data.map(item => {
                         return {
                             text: item.source,
                             value: item.source
                         }
                     })}
                     isLoading={isLoading}
                     onFilter={onFilter}
                     height={250}
                     selection={'Multiple'}
                     onSelectionChange={(items) => {
                         dispatch(setAccessSettings({
                             ...settings,
                             transportLinesSource: [...items.map(item => item.value)]
                         }));
                     }}/>
        </div>
    );
};

export default AccessSettingsTransportLineSourceFilter;