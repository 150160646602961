import React from 'react';
import {useTranslation} from "react-i18next";
import ChatSearchInput from "../../ChatSearchInput/ChatSearchInput";
import {useChatHelperContext} from "../../../hooks/useChatHelperContext";
import ChatContentChatList from "./ChatContentChatList";

const ChatContentChatListSection: React.FC = () => {
    const {t} = useTranslation();

    const {
        isChatsLoading,
        getChats
    } = useChatHelperContext();

    return (
        <div className={`chat-content-chat-list`}>
            <header>
                <div className={'chat-content-chat-list-title'}>
                    {t("chat.labels.chats")}
                </div>

                <ChatSearchInput placeholder={t("chat.labels.search-chats")}
                                 disabled={isChatsLoading}
                                 onInputChange={(val) => getChats(val)}/>
            </header>

            <div>
                <ChatContentChatList/>
            </div>
        </div>
    );
};

export default ChatContentChatListSection;