import {useGetChat, useGetChats, useGetMessages, useGetUserChatData, useGetUsersForChatSelection} from "./requests";

const useChatApi = () => {
    const {
        isLoading: getChatsIsLoading,
        cancel: cancelGetChats,
        getChats
    } = useGetChats();

    const {
        isLoading: getChatIsLoading,
        cancel: cancelGetChat,
        getChat
    } = useGetChat();

    const {
        isLoading: getMessagesIsLoading,
        cancel: cancelGetMessages,
        getMessages
    } = useGetMessages();

    const {
        isLoading: getUsersForChatSelectionIsLoading,
        cancel: cancelGetUsersForChatSelection,
        getUsersForChatSelection
    } = useGetUsersForChatSelection();

    const {
        isLoading: getUserChatDataIsLoading,
        cancel: cancelGetUserChatData,
        getUserChatData
    } = useGetUserChatData();

    return {
        getChats: {
            query: getChats,
            isLoading: getChatsIsLoading,
            cancel: cancelGetChats
        },
        getChat: {
            query: getChat,
            isLoading: getChatIsLoading,
            cancel: cancelGetChat
        },
        getMessages: {
            query: getMessages,
            isLoading: getMessagesIsLoading,
            cancel: cancelGetMessages
        },
        getUsersForChatSelection: {
            query: getUsersForChatSelection,
            isLoading: getUsersForChatSelectionIsLoading,
            cancel: cancelGetUsersForChatSelection
        },
        getUserChatData: {
            query: getUserChatData,
            isLoading: getUserChatDataIsLoading,
            cancel: cancelGetUserChatData
        }
    }
};

export default useChatApi;