import GridFilterPanelLayout from "../../../../../../components/uiLayouts/GridFilterPanelLayout/GridFilterPanelLayout";
import {LayoutConfigEntity} from "../../../../../../app/enums/LayoutConfigEntity";
import QuoteFilterPanelCheckboxSection from "./QuoteFilterPanelCheckboxSection/QuoteFilterPanelCheckboxSection";
import React from "react";
import QuoteFilterPanelFiltersSection from "./QuoteFilterPanelFiltersSection/QuoteFilterPanelFiltersSection";
import {IQuoteHeaderConfig} from "../../data/interfaces/IQuoteHeaderConfig";
import {useGridLayoutContext} from "../../../../../../components/uiLayouts/GridLayout/hooks/useGridLayoutContext";

const QuoteFilterPanel = () => {
    const {
        headerConfig,
        saveHeaderConfig
    } = useGridLayoutContext<IQuoteHeaderConfig>();

    return (
        <GridFilterPanelLayout id={'quote-grid-filter-panel'}
                               entity={LayoutConfigEntity.Quote}
                               header={<QuoteFilterPanelCheckboxSection/>}

                               pinnedPersonalFilter={headerConfig?.pinnedFilter ?? null}
                               pinnedPredefinedFilters={headerConfig?.pinnedPredefinedFilters ?? null}

                               onPredefinedFilterSelect={(filter) => {
                                   (async () => {
                                       if (!headerConfig) {
                                           return;
                                       }

                                       await saveHeaderConfig({
                                           ...headerConfig,
                                           pinnedPredefinedFilter: filter?.value ?? null
                                       });
                                   })();
                               }}
                               onPredefinedFilterPin={(filter, op) => {
                                   window.dispatchEvent(new Event('resize'));

                                   (async () => {
                                       if (!headerConfig) {
                                           return;
                                       }

                                       let items = headerConfig.pinnedPredefinedFilters === null || headerConfig.pinnedPredefinedFilters === undefined
                                           ? [
                                               filter.value
                                           ]
                                           : op === 'Add' ? [
                                                   ...headerConfig.pinnedPredefinedFilters,
                                                   filter.value
                                               ]
                                               : [
                                                   ...headerConfig.pinnedPredefinedFilters.filter(e => e !== filter.value)
                                               ]

                                       await saveHeaderConfig({
                                           ...headerConfig,
                                           pinnedPredefinedFilters: items
                                       });
                                   })();
                               }}
                               onPersonalFilterPin={(filter) => {
                                   (async () => {
                                       if (!headerConfig) {
                                           return;
                                       }

                                       await saveHeaderConfig({
                                           ...headerConfig,
                                           pinnedFilter: filter === null
                                               ? null
                                               : filter.id
                                       });
                                   })();
                               }}
                               onPersonalFilterRemove={async (filter) => {
                                   if (headerConfig && headerConfig.pinnedFilter && headerConfig.pinnedFilter === filter.id) {
                                       await saveHeaderConfig({
                                           ...headerConfig,
                                           pinnedFilter: null
                                       });
                                   }
                               }}>
            <QuoteFilterPanelFiltersSection/>
        </GridFilterPanelLayout>
    );
}

export default QuoteFilterPanel;