import {motion} from 'framer-motion';
import React, {ReactNode} from 'react';
import cl from './Card.module.css';

export enum CardAppearAnimationType {
    FadeIn = 0
}

export type CardProps = {
    cardAppearAnimationType?: CardAppearAnimationType;
    allAnimation?: boolean;
    children: ReactNode;
    style?: React.CSSProperties
}

const Card = ({
                  cardAppearAnimationType,
                  children,
                  style,
                  allAnimation = false
              }: CardProps) => {
    const cardStyle: React.CSSProperties = {
        ...style
    };

    if (cardAppearAnimationType !== undefined && cardAppearAnimationType === CardAppearAnimationType.FadeIn) {
        return (
            <motion.div key="card-anim"
                        style={{width: style?.width}}
                        initial={{opacity: 0.5, y: -50}}
                        animate={{opacity: 1, y: 0}}
                        transition={{
                            ease: "linear",
                            duration: 1,
                            y: {duration: 1}
                        }}>
                <div className={`${cl.card} ${allAnimation ? 'transition-all-500' : ''}`}>
                    {children}
                </div>
            </motion.div>
        );
    }

    return (
        <div style={cardStyle}
             className={`${cl.card} ${allAnimation ? 'transition-all-500' : ''}`}>
            {children}
        </div>
    );
};

export default Card;