import React, {useState} from 'react';
import {ISelfBilling} from "../../../../../../../app/interfaces/selfBilling/ISelfBilling";
import {ISelfBillingExpense} from "../../../../../../../app/interfaces/selfBilling/ISelfBillingExpense";
import {IUser} from "../../../../../../../app/interfaces/user/IUser";
import {ICmrDocument} from "../../../../../../../app/interfaces/cmr/ICmrDocument";
import cl from './SelfBillingDocumentListItem.module.css';
import {getCmrStatus} from "../../utils";
import Tag from "../../../../../../../components/shared/components/Tag/Tag";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {CmrDocumentType} from "../../../../../../../app/enums/CmrDocumentType";
import {EcmStatus} from "../../../../../../../app/enums/EcmStatus";
import SelfBillingDocumentListItemBillingSection
    from "./SelfBillingDocumentListItemBillingSection/SelfBillingDocumentListItemBillingSection";
import {useDownloadFile} from "../../../../../../../hooks/useDownloadFile/useDownloadFile";
import {IResponse, isResponse} from "../../../../../../../app/interfaces/response/IResponse";
import {isAxiosError} from "axios";
import {ResponseResultCode} from "../../../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import useSelfBillingApi from "../../../../../../../app/api/selfBilling";
import {getECMErrorByCode, hasECMErrorByCode} from "../../../../../../../helpers/shipment";
import Icon from "../../../../../../../assets/icon/Icon";

type SelfBillingDocumentListItemProps = {
    document: ICmrDocument;
    model: ISelfBilling;
    expense: ISelfBillingExpense;
    isAnonymousAccess: boolean;
    user?: IUser;
};

const SelfBillingDocumentListItem: React.FC<SelfBillingDocumentListItemProps> = ({
                                                                                     document,
                                                                                     model,
                                                                                     isAnonymousAccess,
                                                                                     user
                                                                                 }) => {
    const {t} = useTranslation();

    const [isHistoryShown, setIsHistoryShown] = useState<boolean>(false);

    const {
        getAttachmentAnonymous: {
            query: getAttachmentAnonymous
        },
        downloadCmrDocumentAnonymous: {
            query: downloadCmrDocumentAnonymous
        },
        getAttachmentAuthorize: {
            query: getAttachmentAuthorize
        },
        downloadCmrDocumentAuthorize: {
            query: downloadCmrDocumentAuthorize
        }
    } = useSelfBillingApi();

    const {
        download
    } = useDownloadFile({
        target: '_blank',
        getBlob: async (param?: string, param2?: string) => {
            if (!param || !param2) {
                return;
            }
            let isAuthorize = user !== undefined && !isAnonymousAccess;

            try {
                if (document.status === EcmStatus.Draft || document.status === EcmStatus.New ||
                    document.status === EcmStatus.Sent || document.status === EcmStatus.Incorrect ||
                    document.status === EcmStatus.NotFound || document.status === EcmStatus.Handled) {
                    const response = isAuthorize
                        ? await getAttachmentAuthorize(Number(param2))
                        : await getAttachmentAnonymous(Number(param2));

                    if (response?.status === 200 && !isResponse(response.data)) {
                        return response.data;
                    }
                } else {
                    const response = isAuthorize
                        ? await downloadCmrDocumentAuthorize(model.shipmentGid, document.cmrGuid)
                        : await downloadCmrDocumentAnonymous(model.shipmentGid, document.cmrGuid);

                    if (response?.status === 200 && !isResponse(response.data)) {
                        return response.data;
                    }
                }
            } catch (err) {
                if (isAxiosError(err) && err.response && err.response.data && err.response.data.type === "application/json") {
                    const textResponse = await err.response?.data?.text();

                    if (textResponse && typeof textResponse === 'string') {
                        let response = JSON.parse(textResponse) as IResponse;

                        if (response.resultCode === ResponseResultCode.NotValidData) {
                            toast.error(`Unable to download file. Error: Input parameter is invalid.`)
                        }

                        if (response.resultCode === ResponseResultCode.NotFound) {
                            toast.error(`Unable to download file. Error: File is not found.`)
                        }
                    }
                }
            }
        }
    });

    let ecmStatus = getCmrStatus(document.status);
    if(document.status == EcmStatus.Income) {
        ecmStatus = ["self-billing.ecm-status.income", "3F8CE5"];
    }


    let history = [...document.statuses]
        .sort((a, b) => {
            return (new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());
        });

    return (
        <div className={cl.container}>
            <div className={cl.header}>
                <div>
                    <span>
                        {moment.utc(document.createdOn).local().format('DD.MM.YYYY HH:mm')}
                    </span>

                    <span>
                        {document.createdBy}
                    </span>
                </div>
                <div>
                    {document.statuses.length > 1 && !isHistoryShown &&
                        <button className={cl.history}
                                onClick={() => setIsHistoryShown(prev => !prev)}>
                            <Icon icon={"faClock"}
                                  title={t("disposition.form.changes-history")}/>

                            {t("disposition.form.changes-history")}
                        </button>
                    }

                    {ecmStatus !== null &&
                        <div className={cl.tag}>
                            <Tag item={{
                                id: `document-status-${document.id}`,
                                text: t(ecmStatus[0]),
                                title: t(ecmStatus[0]),
                                showDeleteIcon: false,
                                showEditIcon: false,
                                showHoverDeleteIcon: false,
                                showHoverEditIcon: false,
                                hoverMagnification: false,
                                backgroundColor: ecmStatus[1]
                            }}
                                 size={'full'}
                                 containerMaxWidth={200}/>
                        </div>
                    }
                </div>
            </div>

            {document.downloadLink === null || document.downloadLink === ''
                ? <div className={`${cl.link}`}>
                    {document.fileName}
                </div>
                : <div className={`${cl.link} ${cl.linkActive}`}
                       onClick={async () => await download(document.fileName ?? '', document.id.toString())}>
                    {document.fileName}
                </div>
            }

            {document.status != EcmStatus.Completed && document.status != EcmStatus.Incorrect && document.status != EcmStatus.NotFound && document.status != EcmStatus.Handled && document.invoiceNumber !== null && document.invoiceNumber !== '' &&
                <div className={cl.text}>
                    {`${t("self-billing.labels.supplier-invoice-number")}: ${document.invoiceNumber}`}
                </div>
            }

            {document.status != EcmStatus.Completed && document.status != EcmStatus.Incorrect && document.status != EcmStatus.NotFound && document.status != EcmStatus.Handled && document.bankAccount !== null && document.bankAccount !== '' &&
                <div className={cl.text}>
                    {`${t("self-billing.labels.bank-account")}: ${document.bankAccount}`}
                </div>
            }

            {document.status != EcmStatus.Completed && document.status != EcmStatus.Incorrect && document.status != EcmStatus.NotFound && document.status != EcmStatus.Handled && document.anotherPaymentRecipient !== null && document.anotherPaymentRecipient !== '' &&
                <div className={cl.text}>
                    {`${t("self-billing.labels.payment-receiver")}: ${document.anotherPaymentRecipient}`}
                </div>
            }

            {document.comments !== null && document.comments !== '' && document.status !== EcmStatus.Draft &&
                <div className={cl.text}>
                    {`${hasECMErrorByCode(document.comments) ? t(getECMErrorByCode(document.comments)) : document.comments}`}
                </div>
            }

            {document.billing !== null &&
                <SelfBillingDocumentListItemBillingSection billing={document.billing}/>
            }

            {isHistoryShown && document.statuses.length > 1 &&
                <div style={{marginTop: '10px'}}>

                    {history.slice(1).map(status => {
                        let st = getCmrStatus(status.status);

                        return (
                            <div key={status.id}
                                 className={cl.container}
                                 style={{
                                     fontSize: '11px',
                                     padding: '5px',
                                     backgroundColor: 'white',
                                     borderRadius: '5px',
                                     margin: '10px 0'
                                 }}>
                                <div className={cl.header}>
                                    <div>
                                        <span>
                                            {moment.utc(status.createdOn).local().format('DD.MM.YYYY HH:mm')}
                                        </span>

                                        <span>
                                                {status.createdBy}
                                        </span>
                                    </div>

                                    <div className={cl.tag}>
                                        {document.documentType !== CmrDocumentType.Cost && st !== null &&
                                            <Tag item={{
                                                id: `document-status-${document.id}`,
                                                text: t(st[0]),
                                                title: t(st[0]),
                                                showDeleteIcon: false,
                                                showEditIcon: false,
                                                showHoverDeleteIcon: false,
                                                showHoverEditIcon: false,
                                                hoverMagnification: false,
                                                backgroundColor: st[1]
                                            }}
                                                 size={'full'}
                                                 containerMaxWidth={200}/>
                                        }
                                    </div>
                                </div>

                                <div style={{fontSize: '12px'}}>
                                    {status.fileName}
                                </div>

                                <div className={cl.text}>
                                    {`${hasECMErrorByCode(status.comments) ? t(getECMErrorByCode(status.comments)) : status.comments}`}
                                </div>

                                {status.billing !== null &&
                                    <SelfBillingDocumentListItemBillingSection billing={status.billing}/>
                                }
                            </div>
                        );
                    })}
                </div>
            }
        </div>
    );
};

export default SelfBillingDocumentListItem;
