import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import cl from './CustomerTemplate.module.css';
import ClientStatusDialog from "../../../actions/ClientStatus/ClientStatusDialog";
import attachCustomerOrderIcon from "../../../../../../assets/images/attach_customer_order.png";
import customerOrderAttachedIcon from "../../../../../../assets/images/customer_order_attached.png";
import AddCustomerAttachmentDialog from '../../../actions/AddCustomerAttachment/AddCustomerAttachmentDialog';

const CustomerTemplate = (props: any) => {
    const {t} = useTranslation();
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);
    const [isAddCustomerAttachmentDialogShown, setIsAddCustomerAttachmentDialogShown] = useState<boolean>(false);

    let id = props.dataItem.id !== null &&
    props.dataItem.id !== undefined && props.dataItem.id !== ''
        ? props.dataItem.id
        : null;

    if (!id || id === '') {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={'default-flex-cell default-overflow-cell'}>
                    {props.children}
                </span>
            </td>
        )
    }

    const iconPath = props.dataItem.hasCustomerAttachmentDocs ? customerOrderAttachedIcon : attachCustomerOrderIcon;
    const iconTooltip = props.dataItem.hasCustomerAttachmentDocs
        ? t('customer-attachment.labels.customer-order-attached-tooltip') 
        : t('customer-attachment.labels.attach-customer-order-tooltip');

    return (
        <td {...props.tdProps}
            colSpan={1} style={{ display: 'flex', alignItems: 'center' }}>
            <img src={iconPath} title={iconTooltip} alt={''} width='20' height='auto' style={{ cursor: 'pointer' }}
                                             onClick={(ev) => {
                                                if (!props.dataItem.hasCustomerAttachmentDocs)
                                                {
                                                    ev.stopPropagation();

                                                    setIsAddCustomerAttachmentDialogShown(prev => !prev);
                                                }  
                                            }}/>
            <span onClick={handleClick} className={'default-flex-cell default-overflow-cell grid-prevent-selection'}
                  style={{
                      gap: '5px'
                  }}>

                <FontAwesomeIcon icon={faCircleExclamation}
                                 onClick={(ev) => {
                                     ev.stopPropagation();

                                     setIsDialogShown(prev => !prev);

                                 }}
                                 className={`${cl.icon} transition-all-500 grid-prevent-selection`}
                                 title={t("shared.btn.show-status")}/>
               <span style={{textAlign: 'left'}}> {props.children}</span>
            </span>
            <ClientStatusDialog id={props.dataItem.id}
                                show={isDialogShown}
                                setShow={setIsDialogShown}/>

            <AddCustomerAttachmentDialog orderReleaseGid={props.dataItem.id}
                              show={isAddCustomerAttachmentDialogShown}
                              setShow={setIsAddCustomerAttachmentDialogShown}
                              />
        </td>
    );
};

export default CustomerTemplate;
