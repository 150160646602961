import {AxiosResponse} from "axios";
import {IDataResponse, IResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {IUserDevice} from "../../interfaces/user/IUserDevice";
import {IUserDeviceConfirmation} from "../../interfaces/user/IUserDeviceConfirmation";

export const useSendConfirmationEmail = (): {
    isLoading: boolean,
    cancel: () => void,
    sendConfirmationEmail: (deviceId: string) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, Partial<IUserDevice>>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/device/sendConfirmationEmail`
    });

    const sendConfirmationEmail = useCallback(async (deviceId: string) => await execute({deviceId} as Partial<IUserDevice>), [execute]);

    return {sendConfirmationEmail, isLoading, cancel};
};

export const useConfirmDevice = (): {
    isLoading:boolean,
    cancel: () => void,
    confirmDevice: (confirmation: IUserDeviceConfirmation) => Promise<AxiosResponse<IResponse> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IUserDeviceConfirmation>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/device/confirmDevice`
    });

    const confirmDevice = useCallback(async (confirmation: IUserDeviceConfirmation) => await execute(confirmation), [execute]);

    return {confirmDevice, isLoading, cancel};
};

export const useGetDevices = (): {
    isLoading:boolean,
    cancel: () => void,
    getDevices: () => Promise<AxiosResponse<IDataResponse<Array<IUserDevice>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<IUserDevice>>>({
        method: 'get',
        url: `api/${process.env.REACT_APP_API_VERSION}/device/getDevices`
    });

    const getDevices = useCallback(async () => await execute(), [execute]);

    return {getDevices, isLoading, cancel};
};