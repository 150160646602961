import React from 'react';
import {IChangeDateGetAction} from "../../../../../../app/interfaces/shipment/IChangeDateGetAction";
import {FormikHelpers, useFormik} from "formik";
import {
    IChangeDatePostAction
} from "../../../../../../app/interfaces/shipment/IChangeDatePostAction";
import {useTranslation} from 'react-i18next';
import {RadioButton} from '@progress/kendo-react-inputs';
import * as Yup from "yup";
import Alert from "../../../../../../components/ui/Alert/Alert";
import {Tooltip} from "react-tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import cl from './ChangePlannedDateForm.module.css';
import {getStopPointIcon, getStopPointTypeResource} from "../../../../../../helpers/shipment";
import Textarea from "../../../../../../components/ui/Textarea/Textarea";
import moment from "moment";
import {IError} from "../../../../../../app/interfaces/response/IResponse";
import {BLErrorCode} from "../../../../../../app/enums/BLErrorCode";
import ReactDatePicker from "../../../../../../components/ui/ReactDatePicker/ReactDatePicker/ReactDatePicker";

type ChangePlannedDateFormProps = {
    model: IChangeDateGetAction;
    disabled: boolean;
    save: (model: IChangeDatePostAction) => Promise<void>;
    errors: Array<IError>;
}
const ChangePlannedDateForm: React.FC<ChangePlannedDateFormProps> = ({
                                                                         model,
                                                                         save,
                                                                         disabled,
                                                                         errors
                                                                     }) => {
    const {t} = useTranslation();

    const form = useFormik<IChangeDatePostAction>({
        initialValues: {
            orderReleaseGid: model.orderReleaseGid,
            approvedByCustomer: false,
            notApprovedOrTechnical: false,
            mode: null,
            dtItems: [],
            items: model.dtItems.map(item => {
                return {
                    ...item
                };
            })
        },
        validationSchema: Yup.object().shape({
            mode: Yup.string().oneOf(["0", "1"]).required(t("shipment.errors.change-planned-date-mode-is-required"))
        }),
        onSubmit: async (values: IChangeDatePostAction, actions: FormikHelpers<IChangeDatePostAction>) => {
            actions.setSubmitting(true);

            values.dtItems = [];
            values.items.forEach(item => {
                values.dtItems.push({
                    position: item.position,
                    changeDateStart: item.changeDateStart,
                    reason: item.reason
                });
            });

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='change-date-form'
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div className={'mgb-10'}>
                    {form.errors.mode && !form.values.mode &&
                        <Alert type={'Error'} style={{fontSize: '12px', fontWeight: 'bold', margin: '0 0 10px 0'}}>
                            {form.errors.mode}
                        </Alert>
                    }
                    <div className={'radio-buttons-container'}>
                        <div style={{display: 'inline'}}>
                            <RadioButton name="mode"
                                         value="1"
                                         size={'small'}
                                         checked={form.values.approvedByCustomer}
                                         label={t("shipment.form.change-date-approved-by-customer-mode")}
                                         onChange={() => {
                                             form.setFieldValue('mode', "1");
                                             if (!form.values.approvedByCustomer) {
                                                 form.setFieldValue('approvedByCustomer', true);
                                             }
                                             form.setFieldValue('notApprovedOrTechnical', false);
                                         }}
                            />
                            <Tooltip id={'approved-by-customer-mode'}
                                     style={{maxWidth: '250px', zIndex: '1002', fontSize: '11px'}}
                                     place={'right'}/>
                            <FontAwesomeIcon data-tooltip-id={'approved-by-customer-mode'}
                                             data-tooltip-content={t("shipment.form.change-date-approved-by-customer-mode-tooltip")}
                                             icon={faCircleInfo}
                                             className={cl.tooltip}/>
                        </div>
                        <div style={{display: 'inline', marginLeft: '25px'}}>
                            <RadioButton name="mode"
                                         value="0"
                                         size={'small'}
                                         checked={form.values.notApprovedOrTechnical}
                                         label={t("shipment.form.change-date-not-approved-or-technical-mode")}
                                         onChange={() => {
                                             form.setFieldValue('mode', "0");
                                             if (!form.values.notApprovedOrTechnical) {
                                                 form.setFieldValue('notApprovedOrTechnical', true);
                                             }
                                             form.setFieldValue('approvedByCustomer', false);
                                         }}
                            />
                            <Tooltip id={'technical-mode'}
                                     style={{maxWidth: '250px', zIndex: '1002', fontSize: '11px'}}
                                     place={'right'}/>
                            <FontAwesomeIcon data-tooltip-id={'technical-mode'}
                                             data-tooltip-content={t("shipment.form.change-date-not-approved-or-technical-mode-tooltip")}
                                             icon={faCircleInfo}
                                             className={cl.tooltip}/>
                        </div>
                    </div>
                </div>
                <div className={cl.spList}>
                    {form.values.items.map((sp, index) => {
                        let icon = getStopPointIcon(sp.spType ?? '');

                        if (icon === null) {
                            return null;
                        }

                        let errs: Array<string> = [];

                        errors.filter(e => e.description !== null && Number(e.description) === sp.position)
                            .forEach(err => {
                                switch (err.errorCode) {
                                    case BLErrorCode.InvalidDate:
                                        errs.push(t("shipment.errors.change-date-incorrect-date"));
                                        break;
                                }
                            });

                        return (
                            <div key={sp.position} className={'mg-10'}>
                                <div className={cl.spLabel}>
                                    <FontAwesomeIcon icon={icon}
                                                     className={cl.icon}
                                                     data-sp-type={sp.spType}/>
                                    <label className={'view-label'}>
                                        {`${t(getStopPointTypeResource(sp.spType ?? ''))} ${sp.spInfo}`}
                                    </label>
                                </div>
                                <div className={`${cl.address} mgb-10`}>
                                    {sp.address}
                                </div>

                                <div className={`mgb-10`} style={{width: '50%'}}>
                                    <div className={cl.datePickerContainer}>
                                        <div style={{width: 'calc(100% - 16px)'}}>
                                            <ReactDatePicker disabled={disabled || sp.isLocked}
                                                             format={'dd.MM.yyyy HH:mm'}
                                                             size={'big'}
                                                             placeholder={t("shared.labels.date")}
                                                             value={sp.changeDateStart ? moment(sp.changeDateStart, 'DD.MM.YYYY HH:mm').toDate() : null}
                                                             onChange={(val) => {
                                                                 form.setFieldValue(`items[${index}].changeDateStart`, val ? moment(val).format('DD.MM.YYYY HH:mm') : null);
                                                             }}
                                            />
                                        </div>

                                        {sp.isLocked && sp.lockNote && sp.lockNote !== ''
                                            ? <div>
                                                <Tooltip id={`date-start-${sp.position}-${index}`}
                                                         style={{
                                                             maxWidth: '250px',
                                                             zIndex: '1002',
                                                             fontSize: '11px'
                                                         }}
                                                         place={'right'}/>
                                                <FontAwesomeIcon
                                                    data-tooltip-id={`date-start-${sp.position}-${index}`}
                                                    data-tooltip-content={t(`shipment.warnings.change-date-${sp.lockNote.toLowerCase()}`)}
                                                    icon={faCircleInfo}
                                                    className={cl.tooltipwarn}/>
                                            </div>
                                            : null
                                        }
                                    </div>

                                    {errs.length > 0 && errs.map(e => {
                                        return (
                                            <div className={'error-label'} key={e}>
                                                {e}
                                            </div>
                                        );
                                    })}
                                </div>

                                {model.showReasonFields &&
                                    <div>
                                        <Textarea disabled={disabled}
                                                  style={{
                                                      minHeight: '40px',
                                                      resize: 'vertical',
                                                      maxHeight: '150px'
                                                  }}
                                                  value={sp.reason ?? ''}
                                                  placeholder={t("shipment.form.change-date-reason-code")}
                                                  onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                      form.setFieldValue(`items[${index}].reason`, ev.target.value);
                                                  }}/>
                                    </div>
                                }
                            </div>
                        );
                    })}
                </div>
            </div>
        </form>
    )
        ;
};

export default ChangePlannedDateForm;
