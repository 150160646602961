import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import ChangeNote from "./ChangeNote";

type ChangeNoteDialogProps = {
    id: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangeNoteDialog: React.FC<ChangeNoteDialogProps> = ({
    id,
    show,
    setShow
                                                           }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'change-note-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("offer.grid.notes")}>
            <ChangeNote id={id}
                        close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default ChangeNoteDialog;