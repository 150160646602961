import React, {useState} from 'react';
import cl from './TagFormField.module.css';
import {useTranslation} from "react-i18next";
import AssociateTagDialog from "../../actions/AssociateTag/AssociateTagDialog";
import {OrderType} from "../../../../../../app/enums/OrderType";
import {ITag} from "../../../../../../app/interfaces/tag/ITag";
import Tag from "../../Tag";
import DisassociateTagDialog from "../../actions/DisassociateTag/DisassociateTagDialog";
import EditTagDialog from "../../actions/EditTag/EditTagDialog";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import Icon from "../../../../../../assets/icon/Icon";

type TagFormFieldProps = {
    orderReleaseGid: string;
    orderType: OrderType;
    tags: Array<ITag> | null;
};

const TagFormField: React.FC<TagFormFieldProps> = ({
                                                       orderReleaseGid,
                                                       orderType,
                                                       tags
                                                   }) => {
    const user = useAppSelector(selectCurrentUser);
    const {t} = useTranslation();

    const [isEditDialogShown, setIsEditDialogShown] = useState<boolean>(false);
    const [isDisassociateDialogShown, setIsDisassociateDialogShown] = useState<boolean>(false);
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);
    const [activeTag, setActiveTag] = useState<ITag | null>(null);

    if ((!user || getUserRole(user) !== UserRole.Forwarder) && (!tags || tags.length <= 0)) {
        return null;
    }

    return (
        <>
            <AssociateTagDialog orderReleaseGid={orderReleaseGid}
                                orderType={orderType}
                                show={isDialogShown}
                                setShow={setIsDialogShown}/>

            {activeTag &&
                <>
                    <DisassociateTagDialog tagId={activeTag.id}
                                           orderReleaseGid={orderReleaseGid}
                                           orderType={orderType}
                                           show={isDisassociateDialogShown}
                                           setShow={setIsDisassociateDialogShown}/>

                    <EditTagDialog id={activeTag.id}
                                   orderReleaseGid={orderReleaseGid}
                                   show={isEditDialogShown}
                                   setShow={setIsEditDialogShown}/>
                </>
            }

            <div className={`tag-form-field ${cl.container}`}>
                {user && getUserRole(user) === UserRole.Forwarder &&
                    <div>
                        <Icon icon={"faPen"}
                              className={'details-box-item-field-icon'}
                              onClick={() => setIsDialogShown(prev => !prev)}/>

                        <div className={'details-box-item-field-edit-value details-box-tag-edit-btn'}
                             onClick={() => setIsDialogShown(prev => !prev)}>
                            {t("shared.btn.add-tag")}
                        </div>
                    </div>
                }

                <div className={cl.tags}>
                    {tags && tags.map(tag => {
                        return (
                            <Tag key={tag.id}
                                 size={'full'}
                                 item={{
                                     id: `item-${tag.id}`,
                                     text: tag.name,
                                     title: `${tag.name} / ${tag.userName !== '' ? tag.userName : ''}`,
                                     showDeleteIcon: tag.canDelete,
                                     showEditIcon: (user && getUserRole(user) === UserRole.Forwarder) ?? false,
                                     showHoverDeleteIcon: false,
                                     showHoverEditIcon: false,
                                     hoverMagnification: false,
                                     backgroundColor: tag.color
                                 }}
                                 containerMaxWidth={300}
                                 onEdit={() => {
                                     setActiveTag(tag);

                                     setIsEditDialogShown(prev => !prev);
                                 }}
                                 onClear={() => {
                                     setActiveTag(tag);

                                     setIsDisassociateDialogShown(prev => !prev);
                                 }}/>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default TagFormField;
