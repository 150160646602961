import React from 'react';
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import LocationMap from "./LocationMap";
import {useTranslation} from "react-i18next";

type LocationMapDialogProps = {
    shipmentGid: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const LocationMapDialogHeader = () => {
    const {t} = useTranslation();

    return (
        <span>
            {t("shared.btn.position")}
            <span style={{fontSize: '12px', color: 'red', textTransform: 'none', marginLeft: '5px'}}>
                {`*(${t("shipment.form.location-header-notes")})`}
            </span>
        </span>
    )
}

const LocationMapDialog: React.FC<LocationMapDialogProps> = ({
                                                                 shipmentGid,
                                                                 show,
                                                                 setShow
                                                             }) => {
    return (
        <Dialog id={'location-map-dialog'}
                show={show}
                width={'98vw'}
                setShow={setShow}
                title={<LocationMapDialogHeader/>}>
            <LocationMap shipmentGid={shipmentGid}
                         close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default LocationMapDialog;