import React, {ReactNode} from 'react';
import cl from './DialogLayout.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

type DialogLayoutProps = {
    onCloseButtonClick: () => void;
    title: string | ReactNode;
    children: ReactNode;
};

const DialogLayout: React.FC<DialogLayoutProps> = ({
                                                       onCloseButtonClick,
                                                       title,
                                                       children
                                                   }) => {
    return (
        <div className={`${cl.container} dialog`}>
            <div className={cl.header}>
                <span className={`${cl.title} prevent-selection`}>
                    {title}
                </span>
                <FontAwesomeIcon className={`${cl.close} transition-all-500`}
                                 onClick={onCloseButtonClick}
                                 icon={faClose}/>
            </div>
            <div className={cl.content}>
                {children}
            </div>
        </div>
    );
};

export default DialogLayout;
