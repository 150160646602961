import React from 'react';
import {IChatReplyTo} from "../../../../../../app/interfaces/chat/IChat";
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../ui/Dialog/Dialog";
import SendMessageToMultipleServiceProviders from "./SendMessageToMultipleServiceProviders";

type SendMessageToMultipleServiceProvidersDialogProps = {
    replyTo: IChatReplyTo;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const SendMessageToMultipleServiceProvidersDialog: React.FC<SendMessageToMultipleServiceProvidersDialogProps> = ({
                                                                                                                     show,
                                                                                                                     setShow,
                                                                                                                     replyTo
                                                                                                                 }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'send-message-to-multiple-service-provider'}
                show={show}
                setShow={setShow}
                width={'850px'}
                title={t("chat.labels.send-message-to-multiple-service-providers-dialog-title")}>
            <SendMessageToMultipleServiceProviders replyTo={replyTo}
                                                   close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default SendMessageToMultipleServiceProvidersDialog;