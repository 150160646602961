import React from 'react';
import {IUser} from "../../../../../app/interfaces/user/IUser";
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import AddCmrDocument from "./AddCmrDocument";

type AddCmrDocumentDialogProps = {
    shipmentGid: string;
    user?: IUser;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddCmrDocumentDialog: React.FC<AddCmrDocumentDialogProps> = ({
                                                                       shipmentGid,
                                                                       show,
                                                                       setShow,
                                                                       user
                                                                   }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'add-cmr-document-dialog'}
                show={show}
                setShow={setShow}
                width={'850px'}
                title={t("self-billing.btn.add-cmr")}>
            <AddCmrDocument shipmentGid={shipmentGid}
                            user={user}
                            close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default AddCmrDocumentDialog;