import React from 'react';
import downloadIconBlue from '../../../../../../assets/images/download-blue.png';
import downloadIconOrange from '../../../../../../assets/images/download-orange.png';
import {useTranslation} from "react-i18next";
import cl from './TransportOrderTemplate.module.css';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";

let GET_TRANSPORT_ORDER_LINK = `${process.env.REACT_APP_API_DOMAIN}/api/${process.env.REACT_APP_API_VERSION}/document/getTransportOrderDocument`;
const TransportOrderTemplate = (props: any) => {
    const {t} = useTranslation();
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    let isTransportOrder = props.dataItem.tOrder !== null && props.dataItem.tOrder !== undefined
        ? Boolean(props.dataItem.tOrder)
        : false;

    let isAlreadyDownloaded = props.dataItem.transportOrderDownloaded !== null && props.dataItem.transportOrderDownloaded !== undefined
        ? Boolean(props.dataItem.transportOrderDownloaded)
        : false;
    return (
        <td {...props.tdProps} onClick={handleClick}
            colSpan={1}>
            <span className={`default-center-cell ${cl.container} grid-prevent-selection`}>
                {isTransportOrder && isAlreadyDownloaded &&
                    <>
                        <img src={downloadIconBlue} onClick={(ev) => {
                            ev.stopPropagation();
                            window.open(GET_TRANSPORT_ORDER_LINK + '?id=' + props.dataItem.sId, '_blank');
                        }}
                             alt={t("shipment.grid.click-to-download")}
                             title={t("shipment.grid.click-to-download")}/>
                        <span onClick={(ev) => {
                            ev.stopPropagation();
                            window.open(GET_TRANSPORT_ORDER_LINK + '?id=' + props.dataItem.sId, '_blank');
                        }}>{t("shared.labels.download")}</span>
                    </>
                }
                {isTransportOrder && !isAlreadyDownloaded &&
                    <>
                        <img src={downloadIconOrange} onClick={(ev) => {
                            ev.stopPropagation();
                            window.open(GET_TRANSPORT_ORDER_LINK + '?id=' + props.dataItem.sId, '_blank');
                        }}
                             alt={t("shipment.grid.click-to-download")}
                             title={t("shipment.grid.click-to-download")}/>
                        <span onClick={(ev) => {
                            ev.stopPropagation();
                            window.open(GET_TRANSPORT_ORDER_LINK + '?id=' + props.dataItem.sId, '_blank');
                        }}>{t("shared.labels.download")} </span>
                    </>
                }
            </span>
        </td>
    );
};

export default TransportOrderTemplate;
