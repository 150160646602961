import React from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {useParams} from "react-router-dom";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import UserChat from "../../../../../../components/shared/components/Chat/components/UserChat/UserChat";
import {UserRole} from "../../../../../../app/enums/UserRole";

const ManagerTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const {type} = useParams();

    const user = useAppSelector(selectCurrentUser);

    let id = '';
    let name = '';
    let hasChat = true;
    let releaseNumber = props.dataItem['releaseNumber'];
    let shipmentId = props.dataItem['sId'];

    switch (props.field) {
        case 'customerManager':
            id = props.dataItem["customerManagerId"];
            name = props.dataItem["customerManager"];
            hasChat = props.dataItem['customerManagerHasChat'];
            break;
        case 'orderManager':
            id = props.dataItem["orderManagerId"];
            name = props.dataItem["orderManager"];
            hasChat = props.dataItem['orderManagerHasChat'];
            break;
        case 'forwarder':
            id = props.dataItem["forwarderId"];
            name = props.dataItem["forwarder"];
            hasChat = props.dataItem['forwarderHasChat'];
            break;
        case 'executer':
            id = props.dataItem["executerId"];
            name = props.dataItem["executer"];
            hasChat = props.dataItem['executerHasChat'];
            break;
        case 'forwarderAgent':
            id = props.dataItem["forwarderAgentId"];
            name = props.dataItem["forwarderAgent"];
            hasChat = props.dataItem['forwarderAgentHasChat'];
            break;
    }

    if (!name || name === '' || !id || id === '' || !type || (type === 'archive' && props.field === 'forwarder') || !user ||
        !releaseNumber || releaseNumber === '' || !shipmentId || shipmentId === '') {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={`default-left-cell default-overflow-cell`}>
                    {props.children}
                </span>
            </td>
        );
    }

    let ids = id.split(';');

    if (ids.length <= 1 && ((user.uniqueUserValue ?? user.idStr) === ids[0])) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={`default-left-cell default-overflow-cell`}>
                    {props.children}
                </span>
            </td>
        );
    }

    let description = '';

    if(props.dataItem['pickupDate'] && props.dataItem['sourceDistrict'] && props.dataItem['sourceCity']
        && props.dataItem['destDistrict'] && props.dataItem['destCity']){
        description = `${props.dataItem['pickupDate']}, ${props.dataItem['sourceDistrict']} ${props.dataItem['sourceCity']}`;

        description += ` - ${props.dataItem['destDistrict']} ${props.dataItem['destCity']}`
    }

    return (
        <td {...props.tdProps}
            colSpan={1}>
             <span className={`default-flex-cell default-overflow-cell grid-prevent-selection`}
                   style={{cursor: 'pointer'}}>
                {hasChat &&   <UserChat user={{
                     id: id,
                     role: UserRole.Forwarder
                 }} replyTo={{
                     type: 'SHIPMENT',
                     id: releaseNumber,
                     idExt: shipmentId,
                     description: description === '' ? null : description
                 }}/>}

                 <span           style={{ textAlign: 'left', padding: '0 3px'}}  className={`default-flex-cell default-overflow-cell grid-prevent-selection`} onClick={handleClick}>
                     {props.children}
                 </span>
             </span>
        </td>
    );
};

export default ManagerTemplate;
