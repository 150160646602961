import React, {useState} from 'react';
import {ISelfBillingExpense} from "../../../../../../../app/interfaces/selfBilling/ISelfBillingExpense";
import {useCollapse} from "react-collapsed";
import {ExpenseStatus} from "../../../../../../../app/enums/ExpenseStatus";
import cl from './SelfBillingExpenseListItem.module.css';
import {useTranslation} from "react-i18next";
import {ISelfBilling} from "../../../../../../../app/interfaces/selfBilling/ISelfBilling";
import {IUser} from "../../../../../../../app/interfaces/user/IUser";
import {getUserRole} from "../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../app/enums/UserRole";
import Alert from "../../../../../../../components/ui/Alert/Alert";
import SelfBillingExpenseListItemOverview
    from "../SelfBillingExpenseListItemOverview/SelfBillingExpenseListItemOverview";
import Tag from "../../../../../../../components/shared/components/Tag/Tag";
import uuid from "react-uuid";
import {getInvoiceStatusResource} from "../../../../../../authorized/Invoice/form/utils";
import {getExpensePaymentStatusColor} from "../../utils";
import apiAxios from "../../../../../../../app/axios/apiAxios";
import {EcmStatus} from "../../../../../../../app/enums/EcmStatus";
import RequestInvalidVatDialog from "../../../../actions/RequestInvalidVat/RequestInvalidVatDialog";
import AddCmrDocumentDialog from "../../../../actions/AddCmrDocument/AddCmrDocumentDialog";
import ChangeCostDialog from "../../../../actions/ChangeCost/ChangeCostDialog";
import Icon from "../../../../../../../assets/icon/Icon";
import SelfBillingDocumentList from "../SelfBillingDocumentList/SelfBillingDocumentList";
import ApproveShipmentDialog from "../../../../../../authorized/Shipment/actions/Approve/ApproveShipmentDialog";

type SelfBillingExpenseListItemProps = {
    model: ISelfBilling;
    expense: ISelfBillingExpense;
    isAnonymousAccess: boolean;
    isSidePanelDetails: boolean;
    user?: IUser;
};

let GET_OTM_SHIPMENT_LINK = `/api/${process.env.REACT_APP_API_VERSION}/shipment/getOtmShipmentLink`

export const openOtmShipment = async (sid: string) => {
    try {
        let response = await apiAxios.get<string>(`${GET_OTM_SHIPMENT_LINK}?id=${sid}&_c=${Date.now()}`);

        if (response.status === 200 && response.data) {
            window.open(response.data, '_blank');
        }
    } catch {
        //ignore
    }
}

const isCollapsed = (expense: ISelfBillingExpense): boolean => {
    return expense.expenseStatus !== null && (expense.expenseStatus === ExpenseStatus.New || expense.expenseStatus === ExpenseStatus.Approval);
}

const SelfBillingExpenseListItem: React.FC<SelfBillingExpenseListItemProps> = ({
                                                                                   expense,
                                                                                   model,
                                                                                   isAnonymousAccess,
                                                                                   user,
                                                                                   isSidePanelDetails
                                                                               }) => {
    const {t} = useTranslation();

    const [isInvalidVatDialogShown, setIsInvalidVatDialogShown] = useState<boolean>(false);
    const [isAddCmrDocumentDialogShown, setIsAddCmrDocumentDialogShown] = useState<boolean>(false);
    const [isChangeCostDialogShown, setIsChangeCostDialogShown] = useState<boolean>(false);
    const [isApproveDialogShown, setIsApproveDialogShown] = useState<boolean>(false);

    const {getCollapseProps, getToggleProps, isExpanded} = useCollapse({
        defaultExpanded: isCollapsed(expense)
    });

    let paymentStatusColor = getExpensePaymentStatusColor(expense);

    let expenseName: string;

    if (expense.isFreight) {
        expenseName = t("self-billing.labels.freight").toUpperCase();
    } else if (expense.expenseGid !== null && expense.expenseGid !== '') {
        expenseName = expense.expenseGid.replace('AAG.', '').replace('_', ' ');
    } else {
        expenseName = expense.expenseName ?? '';
    }

    return (
        <>
            <RequestInvalidVatDialog orderReleaseGid={expense.orderReleaseGid}
                                     shipmentGid={expense.shipmentGid}
                                     user={user}
                                     show={isInvalidVatDialogShown}
                                     setShow={setIsInvalidVatDialogShown}/>

            <AddCmrDocumentDialog shipmentGid={expense.shipmentGid}
                                  show={isAddCmrDocumentDialogShown}
                                  setShow={setIsAddCmrDocumentDialogShown}
                                  user={user}/>

            <ChangeCostDialog shipmentGid={expense.shipmentGid}
                              show={isChangeCostDialogShown}
                              setShow={setIsChangeCostDialogShown}
                              user={user}/>
            <ApproveShipmentDialog id={expense.shipmentGid}
                              show={isApproveDialogShown}
                              setShow={setIsApproveDialogShown}/>
            <div>
                <header className={cl.header} {...getToggleProps()}>
                    <div>
                        <div>
                            <div >
                                {`${t("shared.labels.shipment")}`}

                                {isAnonymousAccess
                                    ? <span className={cl.gid}>{expense.shipmentGid.replace("AAG.","")}</span>
                                    : <>
                                        {user && (getUserRole(user) === UserRole.Administrator || getUserRole(user) === UserRole.Forwarder)
                                            ? <span className={`${cl.gid} ${cl.gidActive}`}
                                                    onClick={async (ev) => {
                                                        ev.stopPropagation();

                                                        await openOtmShipment(expense.shipmentGid);
                                                    }}>
                                                    {expense.shipmentGid.replace("AAG.","")}
                                                </span>
                                            : <span className={cl.gid}>{expense.shipmentGid.replace("AAG.","")}</span>
                                        }
                                    </>
                                }
                            </div>

                            {expense.invoices.length > 0 && paymentStatusColor !== null && paymentStatusColor !== '' &&
                                <div className={cl.tag}>
                                    <Tag size={'full'}
                                         containerMaxWidth={200}
                                         item={{
                                             id: `expense-payment-status-${uuid()}`,
                                             text: t(getInvoiceStatusResource(expense.paymentStatus)),
                                             title: t(getInvoiceStatusResource(expense.paymentStatus)),
                                             showDeleteIcon: false,
                                             showEditIcon: false,
                                             showHoverDeleteIcon: false,
                                             showHoverEditIcon: false,
                                             hoverMagnification: false,
                                             backgroundColor: paymentStatusColor,
                                         }}
                                    />
                                </div>
                            }

                            <div className={'prevent-selection'}>
                                <label style={{marginRight: '10px'}}>
                                    {t("self-billing.labels.expense-name") + ":"}
                                </label>
<span style={{fontWeight:"normal"}}>
                                    {expenseName}
</span>
                            </div>
                            {(user && (getUserRole(user) === UserRole.Administrator || getUserRole(user) === UserRole.Forwarder)) && <div className={'prevent-selection'}>
                                <label style={{marginRight: '10px'}}>
                                    {t("shipment.form.supplier") + ":"}
                                </label>
                                <span style={{fontWeight:"normal"}}>
                                    {expense.serviceProvider}
</span>
                            </div>}
                            {!model.isCmrAllowedForSp && <div>
                                <label style={{marginRight: '10px'}}>
                                    {t("shipment.form.rate")}
                                </label>

                                <span style={{fontWeight:"normal"}}>
                                    {expense.rate}
                                </span>
                            </div>}
                            {!((isAnonymousAccess || (user && getUserRole(user) === UserRole.ServiceProvider)) &&
                                expense.hasShipment && (expense.nonAdvancedInvoice === null || expense.nonAdvancedInvoice === '')) &&
                            expense.nonAdvancedInvoice !== null && expense.nonAdvancedInvoice !== '' && !isSidePanelDetails
                                && model.isCmrAllowedForSp
                                ? <div className={cl.alert}>
                                    <Alert type={'OrangeInfo'}>
                                        {`${t("self-billing.labels.attach-forbidden-due-invoice").replace('{0}', expense.nonAdvancedInvoice ?? '')}`}
                                    </Alert>
                                </div>
                                : null
                            }
                            {!isSidePanelDetails && model.isCmrAllowedForSp &&  (expense.ecmStatus === null || expense.ecmStatus !== EcmStatus.Completed) && (isAnonymousAccess || (user && getUserRole(user) === UserRole.ServiceProvider)) &&
                            expense.hasShipment && (expense.nonAdvancedInvoice === null || expense.nonAdvancedInvoice === '')
                                ? <div className={cl.btnslong}>
                                    <button className={`${cl.button} prevent-selection`}
                                            title={t("self-billing.btn.add-cmr")}
                                            onClick={(ev) => {
                                                ev.stopPropagation();

                                                setIsAddCmrDocumentDialogShown(prev => !prev);
                                            }}>
                                        <Icon icon={"faPen"}/>

                                        <div>
                                            {t("self-billing.btn.add-cmr")}
                                        </div>
                                    </button>

                                    <button className={`${cl.button} prevent-selection`}
                                            title={t("self-billing.btn.change-cost")}
                                            onClick={(ev) => {
                                                ev.stopPropagation();

                                                setIsChangeCostDialogShown(prev => !prev);
                                            }}>
                                        <Icon icon={"faPen"}/>

                                        <div>
                                            {t("self-billing.btn.change-cost")}
                                        </div>
                                    </button>

                                    {model.vat !== null &&
                                        <button className={`${cl.button} prevent-selection`}
                                                title={t("self-billing.btn.invalid-vat")}
                                                onClick={(ev) => {
                                                    ev.stopPropagation();

                                                    setIsInvalidVatDialogShown(prev => !prev);
                                                }}>
                                            <Icon icon={"faPen"}/>

                                            <div>
                                                {t("self-billing.btn.invalid-vat")}
                                            </div>
                                        </button>
                                    }
                                </div>
                                : null
                            }

                            { model.isCmrAllowedForSp &&  (expense.ecmStatus === null || expense.ecmStatus !== EcmStatus.Completed) && ((user && (getUserRole(user) === UserRole.Administrator || getUserRole(user) === UserRole.Forwarder))) &&
                            expense.hasShipment && (expense.expenseStatus == ExpenseStatus.Approval || expense.expenseStatus == ExpenseStatus.New)
                                ? <div className={cl.btnslong}>


                                    <button className={`${cl.button} prevent-selection`}
                                            title={t("self-billing.btn.approve")}
                                            onClick={(ev) => {
                                                ev.stopPropagation();

                                                setIsApproveDialogShown(prev => !prev);
                                            }}>
                                        <Icon icon={"faCheck"}/>

                                        <div>
                                            {t("self-billing.btn.approve")}
                                        </div>
                                    </button>

                                </div>
                                : null
                            }
                        </div>

                        <div>
                            {expense.cmrDocuments.length > 0 &&
                                <Icon icon={"faThinChevronUp"}
                                      className={cl.chevron}
                                      flip={isExpanded ? 'horizontal' : 'vertical'}/>
                            }
                        </div>
                    </div>

                    {isSidePanelDetails && model.isCmrAllowedForSp &&  (expense.ecmStatus === null || expense.ecmStatus !== EcmStatus.Completed) && (isAnonymousAccess || (user && getUserRole(user) === UserRole.ServiceProvider)) &&
                    expense.hasShipment && (expense.nonAdvancedInvoice === null || expense.nonAdvancedInvoice === '')
                        ? <div className={cl.btns}>
                            <button className={`${cl.button} prevent-selection`}
                                    title={t("self-billing.btn.add-cmr")}
                                    onClick={(ev) => {
                                        ev.stopPropagation();

                                        setIsAddCmrDocumentDialogShown(prev => !prev);
                                    }}>
                                <Icon icon={"faPen"}/>

                                <div>
                                    {t("self-billing.btn.add-cmr")}
                                </div>
                            </button>

                            <button className={`${cl.button} prevent-selection`}
                                    title={t("self-billing.btn.change-cost")}
                                    onClick={(ev) => {
                                        ev.stopPropagation();

                                        setIsChangeCostDialogShown(prev => !prev);
                                    }}>
                                <Icon icon={"faPen"}/>

                                <div>
                                    {t("self-billing.btn.change-cost")}
                                </div>
                            </button>

                            {model.vat !== null &&
                                <button className={`${cl.button} prevent-selection`}
                                        title={t("self-billing.btn.invalid-vat")}
                                        onClick={(ev) => {
                                            ev.stopPropagation();

                                            setIsInvalidVatDialogShown(prev => !prev);
                                        }}>
                                    <Icon icon={"faPen"}/>

                                    <div>
                                        {t("self-billing.btn.invalid-vat")}
                                    </div>
                                </button>
                            }
                        </div>
                        : null
                    }

                    {!((isAnonymousAccess || (user && getUserRole(user) === UserRole.ServiceProvider)) &&
                        expense.hasShipment && (expense.nonAdvancedInvoice === null || expense.nonAdvancedInvoice === '')) &&
                    expense.nonAdvancedInvoice !== null && expense.nonAdvancedInvoice !== '' && isSidePanelDetails
                        && model.isCmrAllowedForSp
                        ? <div className={cl.alert}>
                            <Alert type={'OrangeInfo'}>
                                {`${t("self-billing.labels.attach-forbidden-due-invoice").replace('{0}', expense.nonAdvancedInvoice ?? '')}`}
                            </Alert>
                        </div>
                        : null
                    }

                    { model.isCmrAllowedForSp && <SelfBillingExpenseListItemOverview expense={expense} isAnonymousAccess={isAnonymousAccess} user={user}
                                                        isSidePanelDetails={isSidePanelDetails}/>}
                </header>

                <div {...getCollapseProps()}>
                    <SelfBillingDocumentList model={model}
                                             expense={expense}
                                             isAnonymousAccess={isAnonymousAccess}
                                             user={user}/>
                </div>
            </div>
        </>
    );
};

export default SelfBillingExpenseListItem;
