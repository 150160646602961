import {AxiosResponse} from "axios";
import {IDataResponse, IResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {IGridFilter} from "../../interfaces/gridFilter/IGridFilter";
import {LayoutConfigEntity} from "../../enums/LayoutConfigEntity";
import {SelectModel} from "../../types/SelectModel";

export const useGetGridFilter = (): {
    isLoading: boolean,
    cancel: () => void,
    getGridFilter: (id: number) => Promise<AxiosResponse<IDataResponse<IGridFilter>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IGridFilter>>({
        method: 'get',
    });

    const getGridFilter = useCallback(async (id: number) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/gridFilter/getGridFilter?id=${id}`
    }), [execute]);

    return {getGridFilter, isLoading, cancel};
};

export const useSaveGridFilter = (): {
    isLoading: boolean,
    cancel: () => void,
    saveGridFilter: (params: IGridFilter) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IGridFilter>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/gridFilter/saveGridFilter`
    });

    const saveGridFilter = useCallback(async (params: IGridFilter) => await execute(params), [execute]);

    return {saveGridFilter, isLoading, cancel};
};

export const useGetGridFilters = (): {
    isLoading: boolean,
    cancel: () => void,
    getGridFilters: (entity: LayoutConfigEntity) => Promise<AxiosResponse<IDataResponse<Array<IGridFilter>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<IGridFilter>>>({
        method: 'get',
    });

    const getGridFilters = useCallback(async (entity: LayoutConfigEntity) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/gridFilter/getGridFilters?entity=${entity}`
    }), [execute]);

    return {getGridFilters, isLoading, cancel};
};

export const useDeleteGridFilter = (): {
    isLoading: boolean,
    cancel: () => void,
    deleteGridFilter: (params: IGridFilter) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IGridFilter>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/gridFilter/deleteGridFilter`
    });

    const deleteGridFilter = useCallback(async (params: IGridFilter) => await execute(params), [execute]);

    return {deleteGridFilter, isLoading, cancel};
};

export const useGetPredefinedGridFilters = (): {
    isLoading: boolean,
    cancel: () => void,
    getPredefinedGridFilters: (entity: LayoutConfigEntity) => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
    });

    const getPredefinedGridFilters = useCallback(async (entity: LayoutConfigEntity) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/gridFilter/getPredefinedGridFilters?entity=${entity}`
    }), [execute]);

    return {getPredefinedGridFilters, isLoading, cancel};
};