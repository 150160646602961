import React, {useCallback, useEffect, useState} from 'react';
import {SelectModel} from "../../../../../../../../app/types/SelectModel";
import {useQuoteApi} from "../../../../../../../../app/api/quote";
import {debounce} from "debounce";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {useTranslation} from "react-i18next";
import Button, {ButtonType} from "../../../../../../../ui/Button/Button";
import TreeListBox from "../../../../../../../ui/TreeListBox/TreeListBox";
import {useDataHelperApi} from "../../../../../../../../app/api/dataHelper";
import {IChatReplyTo} from "../../../../../../../../app/interfaces/chat/IChat";
import {IChatServiceProvider} from "../../../../../../../../app/interfaces/chat/IChatServiceProvider";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {IGridResultResponse} from "../../../../../../../../app/interfaces/response/IGridResultResponse";
import {ISelectTreeModel} from "../../../../../../../../app/interfaces/shared/ISelectTreeModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faTruck} from "@fortawesome/free-solid-svg-icons";

type SendMessageToMultipleServiceProvidersSelectorProps = {
    disabled: boolean;
    replyTo: IChatReplyTo;
    refresh: number;

    onServiceProviderChange: (sp: SelectModel | null) => void;
    serviceProvider: SelectModel | null;

    addedServiceProviders: Array<IChatServiceProvider>;
    onAddedServiceProvidersChange: (items: Array<IChatServiceProvider>) => void;

    serviceProviders: Array<IChatServiceProvider>;
    onServiceProvidersChange: (items: Array<IChatServiceProvider>) => void;

    selectedServiceProviders: Array<IChatServiceProvider>;
    onSelectedServiceProvidersChange: (items: Array<IChatServiceProvider>) => void;
};

const SendMessageToMultipleServiceProvidersSelector: React.FC<SendMessageToMultipleServiceProvidersSelectorProps> = ({
                                                                                                                         disabled,
                                                                                                                         replyTo,
                                                                                                                         refresh,

                                                                                                                         serviceProvider,
                                                                                                                         onServiceProviderChange,
                                                                                                                         addedServiceProviders,
                                                                                                                         onAddedServiceProvidersChange,

                                                                                                                         onServiceProvidersChange,
                                                                                                                         serviceProviders,

                                                                                                                         selectedServiceProviders,
                                                                                                                         onSelectedServiceProvidersChange
                                                                                                                     }) => {
    const [isServiceProvidersLoading, setIsServiceProvidersLoading] = useState<boolean>(false);

    return (
        <>
            <div className={`mgb-5`}>
                <ServiceProviderSelector disabled={disabled || isServiceProvidersLoading}
                                         serviceProvider={serviceProvider}
                                         onChange={(sp) => onServiceProviderChange(sp)}
                                         addServiceProvider={(items) => {
                                             onServiceProviderChange(null);

                                             onAddedServiceProvidersChange(items);
                                         }}/>
            </div>
            <div className={`mgb-5`}>
                <ServiceProviderTreeSelector replyTo={replyTo}
                                             selectedServiceProviders={selectedServiceProviders}
                                             refresh={refresh}
                                             addedServiceProviders={addedServiceProviders}
                                             setIsServiceProvidersLoading={setIsServiceProvidersLoading}
                                             disabled={disabled}
                                             onServiceProvidersChange={onServiceProvidersChange}
                                             onSelectedServiceProvidersChange={onSelectedServiceProvidersChange}
                                             serviceProviders={serviceProviders}/>
            </div>
        </>
    );
};

export default SendMessageToMultipleServiceProvidersSelector;

const ServiceProviderTreeSelector: React.FC<{
    replyTo: IChatReplyTo;
    disabled: boolean;
    setIsServiceProvidersLoading: React.Dispatch<React.SetStateAction<boolean>>;
    refresh: number;

    addedServiceProviders: Array<IChatServiceProvider>;

    onServiceProvidersChange: (items: Array<IChatServiceProvider>) => void;
    serviceProviders: Array<IChatServiceProvider>;

    selectedServiceProviders: Array<IChatServiceProvider>;
    onSelectedServiceProvidersChange: (items: Array<IChatServiceProvider>) => void;
}> = ({
          disabled,
          replyTo,
          onServiceProvidersChange,
          serviceProviders,
          setIsServiceProvidersLoading,
          addedServiceProviders,
          refresh,
          onSelectedServiceProvidersChange,
          selectedServiceProviders
      }) => {
    const {t} = useTranslation();

    const {
        getChatServiceProviders: {
            query: getChatServiceProviders
        }
    } = useDataHelperApi();

    const convertToSelectTreeArray = (items: Array<IChatServiceProvider>): Array<ISelectTreeModel> => {
        let res: Array<ISelectTreeModel> = [];

        const convertToSelectTreeItem = (item: IChatServiceProvider): ISelectTreeModel => {
            let res = {
                value: item.id,
                text: item.name,
            } as ISelectTreeModel;

            if (item.items.length > 0) {
                res.items = convertToSelectTreeArray(item.items);
            } else {
                res.items = [];
            }

            return res;
        };

        items.forEach(item => {
            res.push(convertToSelectTreeItem(item));
        });

        return res;
    };

    const load = async () => {
        if (serviceProviders.length > 0) {
            return {
                count: serviceProviders.length + addedServiceProviders.length,
                result: convertToSelectTreeArray([...serviceProviders, ...addedServiceProviders])
            } as IGridResultResponse<ISelectTreeModel>;
        }

        setIsServiceProvidersLoading(prev => !prev);

        try {
            const response = await getChatServiceProviders(
                undefined,
                replyTo.id,
                replyTo.type);

            if (response?.status === 200 && response.data) {
                onServiceProvidersChange(response.data.result);

                setIsServiceProvidersLoading(prev => !prev);
                return {
                    count: response.data.count + addedServiceProviders.length,
                    result: convertToSelectTreeArray([...response.data.result, ...addedServiceProviders])
                } as IGridResultResponse<ISelectTreeModel>;
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(`Unable to get service providers. Error: ${e.message}`);
            }
        }

        setIsServiceProvidersLoading(prev => !prev);

        return {
            count: addedServiceProviders.length,
            result: convertToSelectTreeArray([...addedServiceProviders])
        } as IGridResultResponse<ISelectTreeModel>;
    }

    const find = (tree: IChatServiceProvider, value: string): IChatServiceProvider | undefined =>
        tree.id === value ?
            tree :
            tree.items?.reduce((result: IChatServiceProvider | undefined, n) => result || find(n, value), undefined);

    return (
        <TreeListBox id={'send-message-to-multiple-service-providers-selector'}
                     take={500}
                     refresh={refresh}
                     height={300}
                     disabled={disabled}
                     showSearchInput={false}
                     defaultExpandFirstLevelItems={false}
                     onDataLoad={load}
                     template={(ev) => {
                         let sp = ev.item as ISelectTreeModel;

                         let exist: IChatServiceProvider | undefined = undefined;

                         for (const [_, item] of [...serviceProviders.entries(), ...addedServiceProviders.entries()]) {
                             let i = find(item, sp.value);

                             if (i) {
                                 exist = i;
                                 break;
                             }
                         }

                         return (
                             <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                                 <div>
                                     {sp.text}
                                 </div>

                                 {exist && exist.hasAlert &&
                                     <FontAwesomeIcon icon={faEye}
                                                      style={{color: 'var(--main-orange-color)'}}
                                                      title={t("shared.labels.service-provider-saw-the-offer")}/>
                                 }

                                 {exist && exist.hasTransport &&
                                     <FontAwesomeIcon icon={faTruck}
                                                      style={{color: 'var(--main-orange-color)'}}
                                                      title={t("shared.labels.there-are-available-transport-for-this-offer")}/>
                                 }
                             </div>
                         );
                     }}
                     selected={selectedServiceProviders.map(sp => {
                         return {
                             text: sp.name,
                             value: sp.id
                         };
                     })}
                     onChange={(ev) => {
                         let res: Array<IChatServiceProvider> = [];
                         ev.selected.forEach(sp => {
                             let exist: IChatServiceProvider | undefined = undefined;

                             for (const [_, item] of [...serviceProviders.entries(), ...addedServiceProviders.entries()]) {
                                 let i = find(item, sp.value);

                                 if (i) {
                                     exist = i;
                                     break;
                                 }
                             }

                             if (exist) {
                                 res.push(exist);
                             }
                         });

                         onSelectedServiceProvidersChange(res.filter(e => e.isUser));
                     }}/>
    )
};


const ServiceProviderSelector: React.FC<{
    disabled: boolean;
    serviceProvider: SelectModel | null;
    onChange: (sp: SelectModel | null) => void;
    addServiceProvider: (items: Array<IChatServiceProvider>) => void;
}> = ({
          disabled,
          onChange,
          serviceProvider,
          addServiceProvider
      }) => {
    const {t} = useTranslation();

    const [items, setItems] = useState<Array<SelectModel>>([]);
    const [filter, setFilter] = useState<string | undefined>();

    const {
        getServiceProviders: {
            query,
            isLoading,
            cancel
        }
    } = useQuoteApi();

    const {
        getChatServiceProviders: {
            query: getChatServiceProviders
        }
    } = useDataHelperApi();

    useEffect(() => {
        return () => {
            cancel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            await load(filter);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const load = useCallback(debounce(async (lFilter?: string) => {
        try {
            const response = await query(lFilter ?? '');
            if (response?.status === 200 && response.data?.data) {
                setItems(response.data.data.filter(e => e.text !== '' && e.value !== ''));
            }
        } catch {
            //ignore
        }
    }, 500), []);

    return (
        <div className={'combobox-picker'}
             style={{display: 'flex', gap: '5px'}}>
            <ComboBox data={items}
                      value={serviceProvider}
                      onChange={ev => {
                          onChange(ev.value);
                      }}
                      disabled={disabled || isLoading}
                      placeholder={t("quote.bet.service-provider")}
                      textField="text"
                      dataItemKey="value"
                      clearButton={true}
                      filterable={true}
                      loading={isLoading}
                      onFilterChange={(ev) => {
                          if ((ev.nativeEvent === undefined || ev.nativeEvent === null) && (
                              ev.syntheticEvent === undefined || ev.syntheticEvent === null
                          )) {
                              setFilter(undefined);

                              return;
                          }

                          if (!ev.filter?.value || ev.filter.value.length < 3) {
                              return;
                          }

                          setFilter(ev.filter.value);
                      }}
            />
            <div>
                <Button buttonType={ButtonType.Secondary}
                        disabled={serviceProvider === null}
                        type={'button'}
                        style={{
                            borderRadius: '2px',
                            fontSize: '12px'
                        }}
                        onClick={async () => {
                            if (!serviceProvider) {
                                return;
                            }

                            try {
                                const response = await getChatServiceProviders(
                                    serviceProvider.value);

                                if (response?.status === 200 && response.data) {
                                    addServiceProvider(response.data.result);
                                }
                            } catch {
                                // Ignore
                            }
                        }}>
                    {t("shared.btn.add-service-provider")}
                </Button>
            </div>
        </div>
    );
}