import React, {useCallback, useEffect, useState} from 'react';
import cl from './QuoteNettoBetList.module.css';
import FormLoadingScreen from "../../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {useTranslation} from "react-i18next";
import {INettoBetGetAction} from "../../../../../../app/interfaces/quote/INettoBetGetAction";
import {useQuoteApi} from "../../../../../../app/api/quote";
import uuid from "react-uuid";
import {sleep} from "../../../../../../helpers/async";
import QuoteNettoBetListItem from "./QuoteNettoBetListItem/QuoteNettoBetListItem";
import {INettoBetPostAction} from "../../../../../../app/interfaces/quote/INettoBetPostAction";
import {toast} from "react-toastify";
import {isAxiosError} from "axios";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import {IError, IResponse} from "../../../../../../app/interfaces/response/IResponse";
import {IQuote} from "../../../../../../app/interfaces/quote/IQuote";

type QuoteNettoBetListProps = {
    gid: string;
    quote: IQuote;
    isSidePanelDetails?: boolean;
}

export type ExtendedNettoBet = {

    uuid: string;
    isCollapseDefaultExpanded: boolean;
    isSaveLoading: boolean;

    errors: Array<IError>;
} & INettoBetGetAction;

export const QuoteNettoBetListContext = React.createContext<{
    resetNettoBet: (nettoBet: ExtendedNettoBet) => void;
    onChange: (nettoBet: ExtendedNettoBet) => void;
    saveNettoBet: (nettoBet: INettoBetPostAction, initialNettoBet: ExtendedNettoBet) => Promise<void>;
}>(undefined as any);

const QuoteNettoBetList: React.FC<QuoteNettoBetListProps> = ({gid, quote, isSidePanelDetails}) => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [items, setItems] = useState<Array<ExtendedNettoBet> | null>([]);
    const [initialItems, setInitialItems] = useState<Array<ExtendedNettoBet>>([]);
    const {t} = useTranslation();

    const {
        getNettoBets: {
            cancel,
            query: getNettoBets
        },
        savePriceInfo: {
            cancel: cancelSavePriceInfo,
            mutation: savePriceInfo
        }
    } = useQuoteApi();

    useEffect(() => {
        if (!gid || gid === '') {
            return;
        }

        (async () => {
            try {
                const response = await getNettoBets(gid);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    let isCollapseDefaultExpanded = true;

                    let data = response.data.data.map(item => {
                        let i = {
                            ...item,
                            uuid: uuid(),
                            isCollapseDefaultExpanded: isCollapseDefaultExpanded,
                            isSaveLoading: false,
                            errors: []
                        };

                        isCollapseDefaultExpanded = false;

                        return i;
                    });

                    setItems(data);
                    setInitialItems(data);
                }
            } catch {
                await sleep(10000);

                setItems(null);
            }

            await sleep(1000);

            setIsLoading(false);
        })();

        return () => {
            cancel();
            cancelSavePriceInfo();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gid]);

    const change = useCallback((nettoBet: ExtendedNettoBet) => {
        if (!items || items.length <= 0) {
            return;
        }

        let index = items.findIndex(e => e.uuid === nettoBet.uuid);

        if (index !== undefined && index >= 0) {
            let bets = items.map((item) => {
                return {...item};
            });

            bets.splice(index, 1, nettoBet);

            setItems(bets);
        }

    }, [items]);

    const resetNettoBet = useCallback((nettoBet: ExtendedNettoBet) => {
        if (!items || items.length <= 0) {
            return;
        }

        let index = items.findIndex(e => e.uuid === nettoBet.uuid);
        let initialNettoBet = initialItems.find(e => e.uuid === nettoBet.uuid);

        if (index !== undefined && index >= 0 && initialNettoBet !== undefined) {
            let bets = items.map((item) => {
                return {...item};
            });
            initialNettoBet.isEditMode = false;
            bets.splice(index, 1, initialNettoBet);

            setItems(bets);
        }

    }, [items, initialItems]);

    const saveNettoBet = useCallback(async (nettoBet: INettoBetPostAction, initialNettoBet: ExtendedNettoBet) => {
        setDisabled(prev => !prev);

        change({
            ...initialNettoBet,
            isSaveLoading: true,
            errors: []
        });

        try {
            const response = await savePriceInfo(nettoBet);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                change({
                    ...initialNettoBet,
                    isEditMode: false,
                    isSaveLoading: false
                });
            }
        } catch (e) {
            if (isAxiosError(e)) {
                let errs: Array<IError> = [];
                if (e.response?.status === 422 && e.response.data) {
                    let resp = e.response.data as IResponse;

                    if (resp && resp.errors && resp.errors?.length > 0) {
                        errs = resp.errors;
                    }
                } else {
                    toast.error('Unable to save price details, ' + e.message);
                }

                change({
                    ...initialNettoBet,
                    isSaveLoading: false,
                    errors: errs
                });
            }
        }

        setDisabled(prev => !prev);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change]);

    if (isLoading) {
        return (
            <div className={cl.container}>
                <FormLoadingScreen title={t("quote.form.bets-loading-text")}/>
            </div>
        );
    }

    if (items === null || items.length <= 0) {
        return null;
    }

    return (
        <>
            <QuoteNettoBetListContext.Provider value={{
                onChange: change,
                resetNettoBet,
                saveNettoBet
            }}>
                {items.map(item => {
                    return (
                        <QuoteNettoBetListItem key={item.uuid}
                                               quote={quote}
                                               isSidePanelDetails={isSidePanelDetails}
                                               nettoBet={item}
                                               disabled={disabled}/>
                    );
                })}
            </QuoteNettoBetListContext.Provider>
        </>
    );
};

export default QuoteNettoBetList;
