import {SelectModel} from "./SelectModel";

export type GridFilter = {
    invoiceNumber: string | null;
    quoteNumber: string | null;
    shipmentNumber: string | null;
    orderReleaseNumber: string | null;
    jobNumber: string | null;
    customerRefNumber: string | null;
    licencePlate: string | null;
    pickupDateFrom: string | null;
    pickupDateTo: string | null;
    deliveryDateFrom: string | null;
    deliveryDateTo: string | null;
    status: Array<string>;
    blockStatus: Array<string>;
    destination: Array<SelectModel>;
    source: Array<SelectModel>;
    supplier: Array<SelectModel>;
    equipment: Array<SelectModel>;
    serviceProvider: Array<SelectModel>;
    tags: Array<SelectModel>;
    uid: string | null;
    asstraIntegrationConstant: string | null;
    asstraIntegrationTemporary: string | null;
    responsibleVisibility: Array<SelectModel>;
    jobOrderRelease: string | null;
}

export const getDefaultGridFilter = (): GridFilter => {
    return {
        invoiceNumber: null,
        quoteNumber: null,
        shipmentNumber: null,
        pickupDateFrom: null,
        pickupDateTo: null,
        orderReleaseNumber: null,
        jobNumber: null,
        customerRefNumber: null,
        licencePlate: null,
        deliveryDateFrom: null,
        deliveryDateTo: null,
        status: [],
        blockStatus: [],
        destination: [],
        source: [],
        supplier: [],
        equipment: [],
        serviceProvider: [],
        tags: [],
        uid: null,
        asstraIntegrationConstant : null,
        asstraIntegrationTemporary : null,
        responsibleVisibility: [],
        jobOrderRelease: null
    };
}

export const isGridFilterHasAtLeastOneNotNullValue = (filter: GridFilter): boolean =>
    Object.values(filter).reduce((hasNotNullValue, value) => {
        return hasNotNullValue || (value !== null && value !== '' && value.length > 0);
    }, false);

export const isGridFilterHasNoValues = (filter: GridFilter) =>
    Object.values(filter).every((val) => {
        return val === null || val === '' || val.length <= 0;
    });
