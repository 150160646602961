import React from 'react';
import {IShipment} from "../../../../../../app/interfaces/shipment/IShipment";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import TransportHistory from "../../components/fields/TransportHistory/TransportHistory";
import EquipmentGroupName from '../../components/fields/EquipmentGroupName/EquipmentGroupName';
import Truck from '../../components/fields/Truck/Truck';
import AliasNumber from "../../components/fields/AliasNumber/AliasNumber";
import Trailer from "../../components/fields/Trailer/Trailer";
import Driver from "../../components/fields/Driver/Driver";
import useMediaQuery from "../../../../../../hooks/useMediaQuery/useMediaQuery";

type OrderDetailsProps = {
    shipment: IShipment
}

const OrderDetails: React.FC<OrderDetailsProps> = ({
                                                       shipment
                                                   }) => {
    const user = useAppSelector(selectCurrentUser);
    const isMaxWidth600 = useMediaQuery('(max-width: 600px)');

    if (!user) {
        return null;
    }

    let role = getUserRole(user);

    return (
        <div>


            <div className={'details-box-default-container'}>
                {!isMaxWidth600 &&
                    <div className={'details-box-default-container-columns'}
                         data-count={'2'}>
                        <div>
                            <div className={'details-box-default-container-item'}>
                                <EquipmentGroupName shipment={shipment}/>
                            </div>

                            <div className={'details-box-default-container-item'}>
                                <Truck shipment={shipment}/>
                            </div>

                            {role !== UserRole.Client &&
                                <div className={'details-box-default-container-item'}>
                                    <AliasNumber shipment={shipment}/>
                                </div>
                            }
                        </div>

                        <div>
                            <div className={'details-box-default-container-item'}>
                                {role !== UserRole.Client &&
                                    <Driver shipment={shipment}/>
                                }
                            </div>

                            <div className={'details-box-default-container-item'}>
                                <Trailer shipment={shipment}/>
                            </div>
                            <div>
                                {role === UserRole.Forwarder || role === UserRole.Administrator
                                    ? <TransportHistory shipment={shipment}/>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                }

                {isMaxWidth600 &&
                    <div className={'details-box-default-container-columns'}
                         data-count={'1'}>
                        <div>
                            <div className={'details-box-default-container-item'}>
                                <EquipmentGroupName shipment={shipment}/>
                            </div>

                            <div className={'details-box-default-container-item'}>
                                <Truck shipment={shipment}/>
                            </div>

                            {role !== UserRole.Client &&
                                <div className={'details-box-default-container-item'}>
                                    <AliasNumber shipment={shipment}/>
                                </div>
                            }

                            <div className={'details-box-default-container-item'}>
                                {role !== UserRole.Client &&
                                    <Driver shipment={shipment}/>
                                }
                            </div>

                            <div className={'details-box-default-container-item'}>
                                <Trailer shipment={shipment}/>
                            </div>
                            <div>
                                {role === UserRole.Forwarder || role === UserRole.Administrator
                                    ? <TransportHistory shipment={shipment}/>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default OrderDetails;
