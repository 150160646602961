import React, {useState} from 'react';
import cl from './LoadingNumberTemplate.module.css';
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {useTranslation} from "react-i18next";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import ChangeRefNumDialog from "../../../actions/ChangeRefNum/ChangeRefNumDialog";
import Icon from "../../../../../../assets/icon/Icon";

const LoadingNumberTemplate = (props: any) => {
    const user = useAppSelector(selectCurrentUser);

    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const {t} = useTranslation();
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);

    let loadingNum = props.dataItem.loadingNumber !== undefined && props.dataItem.loadingNumber !== null
        ? props.dataItem.loadingNumber
        : '';

    if (!user || (getUserRole(user) === UserRole.ServiceProvider)) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={'default-left-cell default-overflow-cell'}>
                </span>
            </td>
        )
    }

    return (
        <td {...props.tdProps}
            colSpan={1}>
            <ChangeRefNumDialog id={props.dataItem.id}
                                show={isDialogShown}
                                setShow={setIsDialogShown} />
            <span onClick={handleClick} className={'default-left-cell default-overflow-cell grid-prevent-selection'}>
                <>

                    <Icon icon={"faPen"} className={`${cl.icon} ${loadingNum && loadingNum !== '' ? cl.iconWithValue : ''} offer-grid-edit-${props.field}-btn grid-prevent-selection`}
                          onClick={(ev) => {
                              ev.stopPropagation();

                              setIsDialogShown(prev => !prev);
                          }}/>
                    {loadingNum && loadingNum !== ''
                        ? <span className={`grid-prevent-selection ${cl.text}`}
                                onClick={(ev) => {
                                    ev.stopPropagation();

                                    setIsDialogShown(prev => !prev);
                                }}>
                        {loadingNum}
                    </span>
                        : <span className={`grid-prevent-selection ${cl.addText}`}
                                onClick={(ev) => {
                                    ev.stopPropagation();

                                    setIsDialogShown(prev => !prev);
                                }}>
                        {t("offer.grid.edit-ref-number")}
                    </span>
                    }
                </>
            </span>
        </td>
    );
};

export default LoadingNumberTemplate;
