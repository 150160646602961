import {Children, isValidElement, ReactElement, ReactNode} from 'react';

type ComponentsSwitchProps = {
    activeComponent: string;
    children: ReactNode;
}
const ComponentsSwitch = ({children, activeComponent}: ComponentsSwitchProps) => {
    return (
        <>{Children
            .toArray(children)
            .filter((item) => {
                if (isValidElement(item)) {
                    let component = item as ReactElement;
                    return component.props['componentName'] === activeComponent;
                }

                return false;
            })}
        </>
    );
};

export default ComponentsSwitch;