import React from 'react';
import Dialog from "../../../../../ui/Dialog/Dialog";
import ForwarderDataForm from "../ForwarderDataForm/ForwarderDataForm";

type ForwarderDataDialogProps = {
    ids: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const ForwarderDataDialog: React.FC<ForwarderDataDialogProps> = ({
                                                                     ids,
                                                                     show,
                                                                     setShow
                                                                 }) => {
    return (
        <Dialog id={'forwarders-data-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={' '}>
            <ForwarderDataForm ids={ids} close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default ForwarderDataDialog;