import {AxiosResponse} from "axios";
import {IDataResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {IPhoneFormat} from "../../interfaces/shared/IPhoneFormat";
import {SelectModel} from "../../types/SelectModel";

export const useGetPhoneFormats = (): {
    isLoading: boolean,
    cancel: () => void,
    getPhoneFormats: () => Promise<AxiosResponse<IDataResponse<Array<IPhoneFormat>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<IPhoneFormat>>>({
        method: 'get',
    });

    const getPhoneFormats = useCallback(async () => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/country/getPhoneFormats`
    }), [execute]);

    return {getPhoneFormats, isLoading, cancel};
};

export const useGetCountries = (): {
    isLoading: boolean,
    cancel: () => void,
    getCountries: () => Promise<AxiosResponse<Array<SelectModel>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<Array<SelectModel>>({
        method: 'get',
    });

    const getCountries = useCallback(async () => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/country/getCountries`
    }), [execute]);

    return {getCountries, isLoading, cancel};
};
