import React, { useEffect, useState } from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Alert from '../../../components/ui/Alert/Alert';
import ConfirmTruckStateForm from './ConfirmTruckStateForm';
import useDriverCheckListApi from '../../../app/api/driverCheckList';
import { ResponseResultCode } from '../../../app/enums/ResponseResultCode';
import { AxiosError, isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import { IConfirmTruckStateGetAction } from '../../../app/interfaces/driverCheckList/IConfirmTruckStateGetAction';
import FormLoadingScreen from '../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen';

const ConfirmTruckStatePage: React.FC = () => {
    const {t} = useTranslation();
    const [model, setModel] = useState<IConfirmTruckStateGetAction | null>(null);
    const [searchParams] = useSearchParams();
    //const [disabled, setDisabled] = useState<boolean>(false);
    //const [, setErrors] = useState<Array<IError>>([]);

    const [id, setId] = useState<string | null>(null);

        const {
        getDriverCheckListData: {
            query: getDriverCheckListData,
            isLoading: isLoadingGetDriverCheckListData,
            cancel
        }
    } = useDriverCheckListApi();


    // const {
    //     saveDriverCheckListData: {
    //         query: saveDriverCheckListData,
    //         isLoading: isLoadingSaveDriverCheckListData,
    //         cancelSaveDriverCheckListData
    //     }
    // } = useDriverCheckListApi();

    useEffect(() => {
        setId(searchParams.get('id'));
    }, []);


    useEffect(() => {
        if (!id) {
            return;
        }

        (async () => {
            try {
                const data = await getDriverCheckListData(id);

                if (data !== undefined && data.status === 200 && data.data !== undefined &&
                    data.data.resultCode === ResponseResultCode.Ok) {

                       setModel(data.data.data ?? null);
                }
                else
                {
                    toast.error(`'Unable to load tags list`);
                }
            } catch (e) {
                if (e instanceof AxiosError && e.response !== undefined && e.response.data !== undefined &&
                    e.response.data.resultCode !== ResponseResultCode.NotFound) {
                    toast.error(e.message);
                }
            }
        })();

        return () => {
            setModel(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

        if (!id) {
        return (
            <div className={'w100 h100'}>
                <Alert type={'Error'}>
                    {t("shared.errors.server-error")}
                </Alert>
            </div>
        );
    }


    // const save = async (model: IConfirmTruckStatePostAction): Promise<void> => {
    //     setDisabled(prev => !prev);
    //     setErrors([]);

    //     try {
    //         const response = await saveDriverCheckListData(model);

    //         if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
    //             // if (close) {
    //             //     close();
    //             // }

    //             toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
    //                                        title={`${t("shared.labels.shipment")}}`}/>);}
    //     } catch (err) {
    //         if (isAxiosError(err)) {
    //             if (err.response && err.response.data && err.response.status === 422 &&
    //                 err.response.data.resultCode === ResponseResultCode.NotValidData &&
    //                 err.response.data.errors && Array.isArray(err.response.data.errors)) {
    //                 setErrors(err.response.data.errors);
    //                 }
    //             // } else if (close) {
    //             //     close();
    //             //     toast.error(err.message);
    //             // }
    //         }
    //     }

    //     setDisabled(prev => !prev);
    // }

    if (isLoadingGetDriverCheckListData) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }


    if (model === null) {
        return (
            <div className={'w100 h100'}>
                <Alert type={'Error'}>
                    {t("shared.errors.server-error")}
                </Alert>
            </div>
        );
    }

    return <ConfirmTruckStateForm model={model}
    //save={save}
    //disabled={disabled}
    />
};

export default ConfirmTruckStatePage;
