import React from 'react';
import {ColorPalette} from "@progress/kendo-react-inputs";

type TagColorPickerProps = {
    selectedColor: string;
    onChange: (color: string) => void;
    preview: boolean;
    text: string
};

const TagColorPicker: React.FC<TagColorPickerProps> = ({
                                                           selectedColor,
                                                           onChange,
                                                            text,
                                                           preview
                                                       }) => {
    const paletteSettings = {
        palette: [
            "#7D89BA",
            "#E37F32",
            "#7FB98B",
            "#3F3F3F",
            "#F3D11C",


            "#7DBFD5",
            "#E96024",
            "#3F8CE5",
            "#C7C7C7",
            "#b8860b",
        ],
        columns: 10
    };

    return (
        <div className={'w100 h100'}
             style={preview ? {
                 display: 'flex',
                 gap: '10px'
             } : undefined}>
            {preview &&
                <div className={'transition-all-500 w100'}
                     style={{
                         backgroundColor: selectedColor,
                         flex: '1',
                         height: '46px',
                         borderRadius: '2px',
                         textAlign: 'center',
                         alignContent: 'center',
                         color: "white",
                         fontWeight: 'bold',
                         maxWidth: '100px',
                         textOverflow: 'ellipsis',
                         overflow: 'hidden',
                         whiteSpace: 'nowrap',
                         padding: '10px'
                     }}>
                    {text}
                </div>
            }
            <ColorPalette palette={paletteSettings.palette}
                          onChange={(ev) => {
                              onChange(ev.value);
                          }}
                          columns={paletteSettings.columns}
                          tileSize={20}
                          defaultValue={selectedColor}/>
        </div>
    );
};

export default TagColorPicker;
