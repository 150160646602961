import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'arrowsRotate',
    pathData: [
        'M17.0443 10.7437C16.8128 12.9186 15.5805 14.9567 13.5413 16.134C10.1534 18.09 5.82132 16.9292 3.86531 13.5413L3.65698 13.1805M2.95527 9.25529C3.18677 7.08038 4.4191 5.04227 6.45825 3.86496C9.84616 1.90896 14.1783 3.06974 16.1343 6.45764L16.3426 6.81849M2.91113 15.0545L3.52118 12.7778L5.79788 13.3878M14.2022 6.61112L16.4789 7.22117L17.0889 4.94446'
    ],
    viewBox: '0 0 20 20',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;