import {
    useDeleteGridFilter,
    useGetGridFilter,
    useGetGridFilters,
    useGetPredefinedGridFilters,
    useSaveGridFilter
} from "./requests";

export const useGridFilterApi = () => {
    const {
        isLoading: getGridFilterIsLoading,
        cancel: cancelGetGridFilter,
        getGridFilter
    } = useGetGridFilter();

    const {
        isLoading: getGridFiltersIsLoading,
        cancel: cancelGetGridFilters,
        getGridFilters
    } = useGetGridFilters();

    const {
        isLoading: saveGridFilterIsLoading,
        cancel: cancelSaveGridFilter,
        saveGridFilter
    } = useSaveGridFilter();

    const {
        isLoading: deleteGridFilterIsLoading,
        cancel: cancelDeleteGridFilter,
        deleteGridFilter
    } = useDeleteGridFilter();

    const {
        isLoading: getPredefinedGridFiltersIsLoading,
        cancel: cancelGetPredefinedGridFilters,
        getPredefinedGridFilters
    } = useGetPredefinedGridFilters();

    return {
        saveGridFilter: {
            mutation: saveGridFilter,
            isLoading: saveGridFilterIsLoading,
            cancel: cancelSaveGridFilter
        },
        getGridFilter: {
            query: getGridFilter,
            isLoading: getGridFilterIsLoading,
            cancel: cancelGetGridFilter
        },
        getGridFilters: {
            query: getGridFilters,
            isLoading: getGridFiltersIsLoading,
            cancel: cancelGetGridFilters
        },
        deleteGridFilter: {
            mutation: deleteGridFilter,
            isLoading: deleteGridFilterIsLoading,
            cancel: cancelDeleteGridFilter
        },
        getPredefinedGridFilters: {
            query: getPredefinedGridFilters,
            isLoading: getPredefinedGridFiltersIsLoading,
            cancel: cancelGetPredefinedGridFilters
        }
    };
}