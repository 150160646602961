import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import GridActionRowContextButton, {
    GridActionRowContextButtonItem
} from "../../../../../../../components/ui/Grid/Telerik/components/GridActionRow/components/GridActionRowContextButton/GridActionRowContextButton";
import {useAppSelector} from "../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../features/authentication/authenticationSliceSelectors";
import {useGridLayoutContext} from "../../../../../../../components/uiLayouts/GridLayout/hooks/useGridLayoutContext";
import {getUserRole} from "../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../app/enums/UserRole";
import {IOfferHeaderConfig} from "../../../data/interfaces/IOfferHeaderConfig";
import {ResponseResultCode} from "../../../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import {useOfferApi} from "../../../../../../../app/api/offer";
import {useParams} from "react-router-dom";
import RemoveFromFreeStockDialog from "../../../../actions/RemoveFromFreeStock/RemoveFromFreeStockDialog";
import SendToFreeStockDialog from "../../../../actions/SendToFreeStock/SendToFreeStockDialog";
import ResetAssigmentDialog from "../../../../actions/ResetAssigment/ResetAssigmentDialog";
import CancelOfferDialog from "../../../../actions/CancelOffer/CancelOfferDialog";
import AssignOfferDialog from "../../../../actions/AssignOffer/AssignOfferDialog";
import SendContractDialog from "../../../../actions/SendContract/SendContractDialog";
import ConfirmAuctionDialog from "../../../../actions/ConfirmAuction/ConfirmAuctionDialog";
import AssociateTagDialog
    from "../../../../../../../components/shared/components/Tag/actions/AssociateTag/AssociateTagDialog";
import {OrderType} from "../../../../../../../app/enums/OrderType";
import ProposeRateDialog from "../../../../actions/ProposeRate/ProposeRateDialog";

const OfferActionRowActionButton = () => {
    const {type} = useParams();
    const {t} = useTranslation();
    const [actions, setActions] = useState<Array<GridActionRowContextButtonItem>>([]);
    const user = useAppSelector(selectCurrentUser);

    const [isRemoveFromFreeStockDialogShown, setIsRemoveFromFreeStockDialogShown] = useState<boolean>(false);
    const [isSendToFreeStockDialogShown, setIsSendToFreeStockDialogShown] = useState<boolean>(false);
    const [isResetAssigmentDialogShown, setIsResetAssigmentDialogShown] = useState<boolean>(false);
    const [isCancelDialogShown, setIsCancelDialogShown] = useState<boolean>(false);
    const [isAssignOfferDialogShown, setIsAssignOfferDialogShown] = useState<boolean>(false);
    const [isSendContractDialogShown, setIsSendContractDialogShown] = useState<boolean>(false);
    const [isConfirmAuctionDialogShown, setIsConfirmAuctionDialogShown] = useState<boolean>(false);
    const [isAssociateDialogShown, setIsAssociateDialogShown] = useState<boolean>(false);
    const [isProposeRateShown, setIsProposeRateShown] = useState<boolean>(false);
    const {
        selectedRows
    } = useGridLayoutContext<IOfferHeaderConfig>()

    const {
        markOffersAsNotViewed: {
            mutation: markOffersAsNotViewed
        },
        markOffersAsViewed: {
            mutation: markOffersAsViewed
        }
    } = useOfferApi();

    useEffect(() => {
        if (selectedRows.length <= 0) {
            setActions([]);

            return;
        }

        setActions(buildActionList());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows, user]);

    const buildActionList = (): Array<GridActionRowContextButtonItem> => {
        if (!user) {
            return [];
        }

        let res: Array<GridActionRowContextButtonItem> = [];
        let role = getUserRole(user);

        let hasStatusThree = false;
        let isFreeStock = true;
        let hasAuction = true;

        selectedRows.forEach(data => {
            if (data["freeStockOwner"] === undefined || data["freeStockOwner"] === null || data["freeStockOwner"] === false) {
                isFreeStock = false;
            }

            if (data.status !== undefined && data.status !== null && data.status === '3') {
                hasStatusThree = true;
            }

            if ((data.auctionType === undefined || data.auctionType === null || data.auctionType === 0) ||
                (data.auctionType !== undefined && data.auctionType !== null && data.auctionType > 0 &&
                    (data["auctionBet"] === undefined || data["auctionBet"] === null || data["auctionBet"] === ''))) {
                hasAuction = false;
            }
        });

        if (role === UserRole.ServiceProvider
            && selectedRows.length === 1
            && !selectedRows[0].bet && !hasAuction) {
            res.push({
                id: 'add-rate',
                disabled: false,
                title: t("shared.btn.propose-rate"),
                onClick: () => setIsProposeRateShown(prev => !prev)
            });
        }


        if (role === UserRole.ServiceProvider) {
            if (hasAuction && type !== undefined && type === 'offer') {
                res.push({
                    id: 'confirm-offer-btn',
                    disabled: false,
                    title: t("shared.btn.confirm-offer"),
                    onClick: () => setIsConfirmAuctionDialogShown(prev => !prev)
                });
            }
        } else if (role === UserRole.Administrator || role === UserRole.Forwarder) {
            if (role === UserRole.Forwarder) {
                res.push({
                    id: 'add-tag',
                    disabled: false,
                    title: t("shared.btn.add-tag"),
                    onClick: () => setIsAssociateDialogShown(prev => !prev)
                });
            }

            if (type !== undefined && type !== 'bet' && !hasStatusThree) {
                res.push({
                    id: 'send-offer-contract-btn',
                    disabled: false,
                    title: t("shared.btn.send-offer-contract"),
                    onClick: () => setIsSendContractDialogShown(prev => !prev)
                });
            }

            res.push({
                id: 'assign-offer-btn',
                disabled: false,
                title: selectedRows.length > 1
                    ? t("shared.btn.assign-offers")
                    : t("shared.btn.assign-offer"),
                onClick: () => setIsAssignOfferDialogShown(prev => !prev)
            });

            if (!isFreeStock || (type !== undefined && type !== 'exchange')) {
                res.push({
                    id: 'send-to-free-stock-btn',
                    disabled: false,
                    title: t("shared.btn.send-to-free-stock"),
                    onClick: () => setIsSendToFreeStockDialogShown(prev => !prev)
                });
            }

            if (!isFreeStock || (type !== undefined && type === 'exchange')) {
                res.push({
                    id: 'remove-from-free-stock-btn',
                    disabled: false,
                    title: t("shared.btn.remove-from-free-stock"),
                    onClick: () => setIsRemoveFromFreeStockDialogShown(prev => !prev),
                    color: 'red'
                });
            }

            res.push({
                id: 'reset-assign-offer-btn',
                disabled: false,
                title: selectedRows.length > 1
                    ? t("shared.btn.cancel-assign-offers")
                    : t("shared.btn.cancel-assign-offer"),
                onClick: () => setIsResetAssigmentDialogShown(prev => !prev),
                color: 'red'
            });

            if (type !== undefined && type !== 'new') {
                res.push({
                    id: 'cancel-offer-btn',
                    disabled: false,
                    title: selectedRows.length > 1
                        ? t("shared.btn.cancel-offers")
                        : t("shared.btn.cancel-offer"),
                    onClick: () => setIsCancelDialogShown(prev => !prev),
                    color: 'red'
                });
            }


        }
        if (selectedRows.filter(data => data.isNew === true).length > 0) {
            res.push({
                id: 'mark-as-viewed',
                disabled: false,
                title: selectedRows.length === 1
                    ? t("shared.btn.mark-item-as-viewed")
                    : t("shared.btn.mark-items-as-viewed"),
                onClick: async () => await markAsViewed()
            });
        }
        if (selectedRows.filter(data => data.isNew !== true).length > 0) {
            res.push({
                id: 'mark-as-un-viewed',
                disabled: false,
                title: selectedRows.length === 1
                    ? t("shared.btn.mark-item-as-un-viewed")
                    : t("shared.btn.mark-items-as-un-viewed"),
                onClick: async () => await markAsUnViewed()
            });
        }
        return res;
    }

    const markAsViewed = async () => {
        let ids: Array<{
            id: string;
            orderReleaseId: number;
        }> = [];

        selectedRows.forEach(row => {
            if (row.isNew !== null && row.isNew !== undefined && row.isNew === true) {
                ids.push({
                    id: row.id,
                    orderReleaseId: row.orderReleaseId,
                });
            }
        })

        if (ids.length <= 0) {
            return;
        }

        try {
            document.dispatchEvent(new CustomEvent('onGridMessage', {
                detail: {
                    action: 'updateRows',
                    rows: [...ids].map((item) => {
                        return {
                            id: item.id,
                            isRowLoading: true
                        };
                    })
                }
            }));

            const response = await markOffersAsViewed({
                ids: [...ids].map((item) => item.orderReleaseId)
            });

            if (response && response.status === 200 &&
                response.data && response.data.resultCode === ResponseResultCode.Ok) {
                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [...ids].map((item) => {
                            return {
                                id: item.id,
                                isRowLoading: false,
                                isNew: false
                            };
                        }),
                        clearSelection: true
                    }
                }));
            }
        } catch {
            toast.error('Unable to change viewed state of offers');

            document.dispatchEvent(new CustomEvent('onGridMessage', {
                detail: {
                    action: 'updateRows',
                    rows: [...ids].map((item) => {
                        return {
                            id: item.id,
                            isRowLoading: false
                        };
                    })
                }
            }));
        }
    }

    const markAsUnViewed = async () => {
        let ids: Array<{
            orderReleaseId: number;
            id: string;
        }> = [];

        selectedRows.forEach(row => {
            if (row.isNew !== null && row.isNew !== undefined && row.isNew === false) {
                ids.push({
                    id: row.id,
                    orderReleaseId: row.orderReleaseId
                });
            }
        })

        if (ids.length <= 0) {
            return;
        }

        try {
            document.dispatchEvent(new CustomEvent('onGridMessage', {
                detail: {
                    action: 'updateRows',
                    rows: [...ids].map((item) => {
                        return {
                            id: item.id,
                            isRowLoading: true,
                        };
                    })
                }
            }));

            const response = await markOffersAsNotViewed({
                ids: [...ids].map((item) => item.orderReleaseId)
            });

            if (response && response.status === 200 &&
                response.data && response.data.resultCode === ResponseResultCode.Ok) {
                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [...ids].map((item) => {
                            return {
                                id: item.id,
                                isRowLoading: false,
                                isNew: true
                            };
                        }),
                        clearSelection: true
                    }
                }));
            }
        } catch {
            toast.error('Unable to change viewed state of offer');

            document.dispatchEvent(new CustomEvent('onGridMessage', {
                detail: {
                    action: 'updateRows',
                    rows: [...ids].map((item) => {
                        return {
                            id: item.id,
                            isRowLoading: false
                        };
                    })
                }
            }));
        }
    }

    return (
        <>
            <AssociateTagDialog orderReleaseGid={selectedRows !== null && selectedRows !== undefined
                ? selectedRows.map(item => item.id).join(';')
                : ''}
                                orderType={OrderType.OrderRelease}
                                show={isAssociateDialogShown}
                                setShow={setIsAssociateDialogShown}/>

            <ConfirmAuctionDialog id={selectedRows !== null && selectedRows !== undefined
                ? selectedRows.map(item => item.id)
                : []}
                                  show={isConfirmAuctionDialogShown}
                                  setShow={setIsConfirmAuctionDialogShown}/>

            <RemoveFromFreeStockDialog id={selectedRows !== null && selectedRows !== undefined
                ? selectedRows.map(item => item.id)
                : []}
                                       show={isRemoveFromFreeStockDialogShown}
                                       setShow={setIsRemoveFromFreeStockDialogShown}/>

            <SendToFreeStockDialog id={selectedRows !== null && selectedRows !== undefined
                ? selectedRows.map(item => item.id)
                : []}
                                   show={isSendToFreeStockDialogShown}
                                   setShow={setIsSendToFreeStockDialogShown}/>

            <ResetAssigmentDialog id={selectedRows !== null && selectedRows !== undefined
                ? selectedRows.map(item => item.id)
                : []}
                                  show={isResetAssigmentDialogShown}
                                  setShow={setIsResetAssigmentDialogShown}/>

            <CancelOfferDialog id={selectedRows !== null && selectedRows !== undefined
                ? selectedRows.map(item => item.id)
                : []}
                               show={isCancelDialogShown}
                               setShow={setIsCancelDialogShown}/>

            <AssignOfferDialog id={selectedRows !== null && selectedRows !== undefined
                ? selectedRows.map(item => item.id)
                : []}
                               show={isAssignOfferDialogShown}
                               setShow={setIsAssignOfferDialogShown}/>

            <SendContractDialog user={user}
                                id={selectedRows !== null && selectedRows !== undefined
                                    ? selectedRows.map(item => item.id)
                                    : []}
                                show={isSendContractDialogShown}
                                setShow={setIsSendContractDialogShown}/>

            <GridActionRowContextButton title={t("shared.labels.actions")}
                                        icon={"faEllipsisHorizontal"}
                                        actions={actions}/>

            <ProposeRateDialog id={selectedRows !== null && selectedRows !== undefined && selectedRows.length > 0
                ? selectedRows[0].id
                : ""}
                               show={isProposeRateShown}
                               setShow={setIsProposeRateShown}/>
        </>
    );
};

export default OfferActionRowActionButton;
