import React, {useRef} from 'react';
import {useChatHelperContext} from "../../../../../hooks/useChatHelperContext";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-regular-svg-icons";
import {faReply, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {getLinkedEntityIcon, getLinkedEntityLink} from "../../../../../utils";

const ChatDialogueSendMessageButton: React.FC = React.memo(() => {
    const {t} = useTranslation();
    const ref = useRef<HTMLTextAreaElement | null>(null);

    const {
        message,
        disabled,
        onMessageChange,
        chat,
        sendMessage
    } = useChatHelperContext();

    let icon: IconDefinition | null = null;
    let link: string | null = null;
    if (chat && chat.replyTo) {
        icon = getLinkedEntityIcon(chat.replyTo);
        link = getLinkedEntityLink(chat.replyTo);
    }

    return (
        <div className={'chat-dialogue-send-message-container'}
             data-type={chat && chat.replyTo ? 'reply' : ''}>
            {chat && chat.replyTo && icon && link &&
                <div className={'relative'}>
                    <FontAwesomeIcon icon={faReply}
                                     style={{
                                         position: 'absolute',
                                         color: '#9a9eae',
                                         left: '10px',
                                         fontSize: '13px'
                                     }}/>
                    <div className={'chat-dialogue-message-reply-to-container'}
                         style={{flexFlow: 'row-reverse'}}>
                        <div className={'chat-dialogue-reply-to-entity'}
                             onClick={() => {
                                 if (link === null) {
                                     return;
                                 }

                                 window.open(link,'_blank', 'rel=noopener noreferrer');
                             }}>
                            <FontAwesomeIcon icon={icon}/>
                        </div>
                        <div onClick={() => {
                            if (link === null) {
                                return;
                            }

                            window.open(link,'_blank', 'rel=noopener noreferrer');
                        }}>
                            {chat.replyTo.id}
                        </div>
                    </div>
                    {chat.replyTo.description !== null && chat.replyTo.description !== '' &&
                        <div className={'chat-dialogue-message-description'}>
                            {chat.replyTo.description}
                        </div>
                    }
                </div>
            }
            <div className={'chat-send-message-button-container'}>
                <div>
                   <textarea ref={ref}
                             placeholder={t("chat.labels.type-message-here")}
                             className={'chat-send-message-button-textarea'}
                             value={message}
                             onChange={(ev) => onMessageChange(ev.target.value)}
                             disabled={disabled}
                             onKeyDown={(ev) => {
                                 if (!chat) {
                                     return;
                                 }
                                 if (ev.key === 'Enter' && !ev.ctrlKey) {
                                     ev.preventDefault();

                                     sendMessage(message, chat);
                                 }
                             }}
                             onKeyUp={(ev) => {
                                 if (ref.current && ev.key === 'Enter' && ev.ctrlKey) {
                                     let caretPosition = ref.current?.selectionEnd ?? 0;

                                     if (!message || message === '') {
                                         ev.preventDefault();

                                         return;
                                     }

                                     if (caretPosition === message.length) {
                                         onMessageChange(message + String.fromCharCode(13, 10));

                                         return;
                                     }

                                     onMessageChange(`${message.substring(0, caretPosition)}\n${message.substring(caretPosition, message.length)}`);
                                 }
                             }}
                   />
                </div>
                <div>
                    <button className={'chat-send-message-button'}
                            disabled={disabled}
                            onClick={() => {
                                if (!chat) {
                                    return;
                                }

                                sendMessage(message, chat);
                            }}>
                        <FontAwesomeIcon icon={faPaperPlane}/>
                    </button>
                </div>
            </div>
        </div>
    );
});

export default ChatDialogueSendMessageButton;