import {useContext} from "react";
import {IGridLayoutContext} from "../models/interfaces/IGridLayoutContext";
import {GridLayoutContext} from "../context";

export const useGridLayoutContext = <T = {}>() => {
    const context = useContext<IGridLayoutContext<T>>(GridLayoutContext);

    if (context === undefined) {
        throw new Error('Grid layout component not defined as a parent');
    }

    return context;
};