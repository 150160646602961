import { AxiosResponse } from "axios";
import { IDataResponse, IResponse } from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import { useCallback } from "react";
import { IAddCustomerAttachmentGetAction } from "../../interfaces/customerAttachment/IAddCustomerAttachmentGetAction";
import { ICustomerAttachmentDoc } from "../../interfaces/customerAttachment/ICustomerAttachmentDoc";
import { IDeleteCustomerAttachmentPostAction } from "../../interfaces/customerAttachment/IDeleteCustomerAttachmentPostAction";

export const useAddCustomerAttachmentData = (): {
    isLoading: boolean,
    cancel: () => void,
    addCustomerAttachment: (params: FormData) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, FormData>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/customerAttachment/addCustomerAttachmentAction`,
        headers: { "Content-Type": "multipart/form-data" },
    });

    const addCustomerAttachment = useCallback(async (params: FormData) => await execute(params), [execute]);

    return {addCustomerAttachment, isLoading, cancel};
};

export const useGetAddCustomerAttachmentData = (): {
    isLoading: boolean,
    cancel: () => void,
    getAddCustomerAttachmentData: (shipmentGid: string) => Promise<AxiosResponse<IDataResponse<IAddCustomerAttachmentGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IAddCustomerAttachmentGetAction>>({
        method: 'GET'
    });

    const getAddCustomerAttachmentData = useCallback(async (orderReleaseGid: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/customerAttachment/getAddCustomerAttachmentData?orderReleaseGid=${orderReleaseGid}`
        });
    }, [execute]);

    return {isLoading, cancel, getAddCustomerAttachmentData};
};


export const useGetCustomerAttachmentDocs = (): {
    isLoading: boolean,
    cancel: () => void,
    getCustomerAttachmentDocs: (id: string) => Promise<AxiosResponse<IDataResponse<Array<ICustomerAttachmentDoc>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<ICustomerAttachmentDoc>>>({
        method: 'get',
    });

    const getCustomerAttachmentDocs = useCallback(async (orderReleaseGid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/customerAttachment/getCustomerAttachmentDocs?orderReleaseGid=${orderReleaseGid}`
    }), [execute]);

    return {getCustomerAttachmentDocs, isLoading, cancel};
};


export const useDownloadCustomerAttachment = (): {
    isLoading: boolean,
    cancel: () => void,
    downloadCustomerAttachment: (id: string) => Promise<AxiosResponse<IResponse | Blob> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse | Blob>({
        method: 'get',
        responseType: 'blob'
    });

    const downloadCustomerAttachment = useCallback(async (attachmentGid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/customerAttachment/downloadCustomerAttachment?attachmentGid=${attachmentGid}`
    }), [execute]);

    return {downloadCustomerAttachment, isLoading, cancel};
};

export const useDeleteCustomerAttachmentDoc = (): {
    isLoading: boolean,
    cancel: () => void,
    deleteCustomerAttachmentDoc: (params: IDeleteCustomerAttachmentPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IDeleteCustomerAttachmentPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/customerAttachment/deleteCustomerAttachment`
    });

    const deleteCustomerAttachmentDoc = useCallback(async (params: IDeleteCustomerAttachmentPostAction) => await execute(params), [execute]);

    return {deleteCustomerAttachmentDoc, isLoading, cancel};
};