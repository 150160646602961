import {IUserDevice} from "../../app/interfaces/user/IUserDevice";
import {load} from "../fingerprint/agent";
import {browserName, fullBrowserVersion, isMobile} from "react-device-detect";
import {UserDeviceType} from "../../app/enums/UserDeviceType";

export const getUserDevice = async (): Promise<IUserDevice> => {
    const fp = await load();

    const {visitorId} = await fp.get();

    return {
        deviceId: visitorId,
        browserName,
        browserVersion: fullBrowserVersion,
        deviceType: isMobile ? UserDeviceType.Mobile : UserDeviceType.PersonalComputer
    };
}