import {AxiosResponse} from "axios";
import {IGridResultResponse} from "../../interfaces/response/IGridResultResponse";
import {ISelectTreeModel} from "../../interfaces/shared/ISelectTreeModel";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {IDataResponse, IResponse} from "../../interfaces/response/IResponse";
import {ITag} from "../../interfaces/tag/ITag";
import {ISaveTagPostAction} from "../../interfaces/tag/ISaveTagPostAction";
import {IDeleteTagAction} from "../../interfaces/tag/IDeleteTagAction";
import {IDisassociateTagGetAction} from "../../interfaces/tag/IDisassociateTagGetAction";
import {OrderType} from "../../enums/OrderType";
import {IDisassociateTagPostAction} from "../../interfaces/tag/IDisassociateTagPostAction";
import {IAssociateTagGetAction} from "../../interfaces/tag/IAssociateTagGetAction";
import {IAssociateTagPostAction} from "../../interfaces/tag/IAssociateTagPostAction";

export const useGetTag = (): {
    isLoading: boolean,
    cancel: () => void,
    getTag: (id: number) => Promise<AxiosResponse<IDataResponse<ITag>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ITag>>({
        method: 'get',
    });

    const getTag = useCallback(async (id: number) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/tag/getTag?id=${id}`
        });
    }, [execute]);

    return {getTag, isLoading, cancel};
}

export const useGetFilterTags = (): {
    isLoading: boolean,
    cancel: () => void,
    getFilterTags: (filter: string | null, type: string | null) => Promise<AxiosResponse<IGridResultResponse<ISelectTreeModel>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IGridResultResponse<ISelectTreeModel>>({
        method: 'get',
    });

    const getFilterTags = useCallback(async (filter: string | null, type: string | null) => {
        let q = `api/${process.env.REACT_APP_API_VERSION}/tag/getFilterTags`;

        if (filter && filter !== '') {
            q += `?filter=${filter}`;
            if (type && type !== '') {
                q += `&type=${type}`;
            }
        } else if (type && type !== '') {
            q += `?type=${type}`;
        }

        return await execute(undefined, {
            url: q
        });
    }, [execute]);

    return {getFilterTags, isLoading, cancel};
};

export const useGetCurrentUserTags = (): {
    isLoading: boolean,
    cancel: () => void,
    getCurrentUsersTags: () => Promise<AxiosResponse<IDataResponse<Array<ITag>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<ITag>>>({
        method: 'get',
    });

    const getCurrentUsersTags = useCallback(async () => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/tag/getMyTags`
        });
    }, [execute]);

    return {getCurrentUsersTags, isLoading, cancel};
}

export const useSaveTag = (): {
    isLoading: boolean,
    cancel: () => void,
    saveTag: (params: ISaveTagPostAction) => Promise<AxiosResponse<IDataResponse<ITag>> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ISaveTagPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/tag/saveTag`
    });

    const saveTag = useCallback(async (params: ISaveTagPostAction) => await execute(params), [execute]);

    return {saveTag, isLoading, cancel};
};

export const useDeleteTag = (): {
    isLoading: boolean,
    cancel: () => void,
    deleteTag: (params: IDeleteTagAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IDeleteTagAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/tag/deleteTag`
    });

    const deleteTag = useCallback(async (params: IDeleteTagAction) => await execute(params), [execute]);

    return {deleteTag, isLoading, cancel};
};

export const useGetRemoveTagFromEntityData = (): {
    isLoading: boolean,
    cancel: () => void,
    getRemoveTagFromEntityData: (tagId: number, orderReleaseGid: string, orderType: OrderType) => Promise<AxiosResponse<IDataResponse<IDisassociateTagGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IDisassociateTagGetAction>>({
        method: 'get',
    });

    const getRemoveTagFromEntityData = useCallback(async (tagId: number, orderReleaseGid: string, orderType: OrderType) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/tag/getRemoveTagFromEntityData?tagId=${tagId}&orderReleaseGid=${orderReleaseGid}&orderType=${orderType}`
        });
    }, [execute]);

    return {getRemoveTagFromEntityData, isLoading, cancel};
}

export const useRemoveTagFromEntity = (): {
    isLoading: boolean,
    cancel: () => void,
    removeTagFromEntity: (params: IDisassociateTagPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IDisassociateTagPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/tag/removeTagFromEntity`
    });

    const removeTagFromEntity = useCallback(async (params: IDisassociateTagPostAction) => await execute(params), [execute]);

    return {removeTagFromEntity, isLoading, cancel};
};

export const useGetAssociateTagData = (): {
    isLoading: boolean,
    cancel: () => void,
    getAssociateTagData: (id: string, orderType: OrderType) => Promise<AxiosResponse<IDataResponse<IAssociateTagGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IAssociateTagGetAction>>({
        method: 'get',
    });

    const getAssociateTagData = useCallback(async (id: string, orderType: OrderType) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/tag/getAssociateTagData?id=${id}&orderType=${orderType}`
        });
    }, [execute]);

    return {getAssociateTagData, isLoading, cancel};
}

export const useAssociateTag = (): {
    isLoading: boolean,
    cancel: () => void,
    associateTag: (params: IAssociateTagPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IAssociateTagPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/tag/associateTag`
    });

    const associateTag = useCallback(async (params: IAssociateTagPostAction) => await execute(params), [execute]);

    return {associateTag, isLoading, cancel};
};

export const useGetAvailableTags = (): {
    isLoading: boolean,
    cancel: () => void,
    getAvailableTags: () => Promise<AxiosResponse<IDataResponse<Array<ITag>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<ITag>>>({
        method: 'get',
    });

    const getAvailableTags = useCallback(async () => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/tag/getAvailableTags`
        });
    }, [execute]);

    return {getAvailableTags, isLoading, cancel};
};
