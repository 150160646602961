import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {SidebarContext} from "../../../Sidebar";
import SidebarButton from "../../../components/SidebarButton/SidebarButton";
import SidebarContextMenu from "../../../components/SidebarContextMenu/SidebarContextMenu";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {useAppDispatch, useAppSelector} from "../../../../../../app/store";
import {useUserApi} from "../../../../../../app/api/user";
import useDefaultSiteLanguages from "../../../../../../hooks/useDefaultSiteLanguages/useDefaultSiteLanguages";
import {Language} from "../../../../../../app/enums/Language";
import {setLanguage, setLogoutMessage} from "../../../../../../features/application/applicationSlice";
import {IEditTransportGetAction} from "../../../../../../app/interfaces/shipment/IEditTransportGetAction";
import {IEditTransportPostAction} from "../../../../../../app/interfaces/shipment/IEditTransportPostAction";
import {selectIsSidebarHoverExpanded, selectIsSidebarExpanded} from "../../../../../../features/application/applicationSliceSelectors";

export const LANGUAGE_LOCAL_STORAGE_KEY = 'lng';


const SidebarApplicationSectionLanguageSelector: React.FC = () => {
    const dispatch = useAppDispatch();
    const {t, i18n} = useTranslation();
    const ref = useRef<HTMLDivElement | null>(null);
    const isSidebarHoverExpanded = useAppSelector(selectIsSidebarHoverExpanded);
    const isSidebarExpanded = useAppSelector(selectIsSidebarExpanded);
    const {
        changeLanguage: {
            mutation: changeLanguage
        }
    } = useUserApi();

    const [isLanguageContextMenuOpen, setIsLanguageContextMenuOpen] = useState<boolean>(false);

    const languages = useDefaultSiteLanguages();
    const selectedLanguage = languages.find(e => Language[e.language].toString().toLowerCase() === i18n.language.toLowerCase());

    const {
        hoverExpanded
    } = useContext(SidebarContext);

    useEffect(() => {
        if (!hoverExpanded) {
            setIsLanguageContextMenuOpen(false);
        }
    }, [hoverExpanded]);

    return (
        <>
            <SidebarButton ref={ref}
                           id={'sidebar-language-item'}
                           type={'secondary'}
                           text={selectedLanguage?.label.toUpperCase() ?? ''}
                           title={t("login.registration.languages")}
                           icon={"faGlobe"}
                           active={isLanguageContextMenuOpen}
                           chevron={isLanguageContextMenuOpen ? 'down' : 'up'}
                           onClick={() => { if(isSidebarExpanded || isSidebarHoverExpanded) setIsLanguageContextMenuOpen(prev => !prev)}}/>

            <SidebarContextMenu target={ref}
                                id={'sidebar-language-context-menu'}
                                verticalPosition={'top'}
                                horizontalPosition={'center'}
                                width={170}
                                opened={isLanguageContextMenuOpen}
                                setOpened={setIsLanguageContextMenuOpen}
                                options={languages.map(lng => {
                                    return {
                                        id: `sidebar-language-${Language[lng.language].toLowerCase()}`,
                                        text: lng.label,
                                        title: lng.label,
                                        isSelected: i18n.language.toLowerCase() === Language[lng.language].toString().toLowerCase(),
                                        onClick: async () => {
                                            if (i18n.language.toLowerCase() !== Language[lng.language].toString().toLowerCase()) {
                                                try {
                                                    await i18n.changeLanguage(Language[lng.language].toString().toLowerCase())

                                                    dispatch(setLanguage(lng.language));
                                                    localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, Language[lng.language].toString().toLowerCase());

                                                    setIsLanguageContextMenuOpen(false);

                                                    await changeLanguage({
                                                        language: lng.language
                                                    });
                                                } catch (err) {
                                                    if (isAxiosError(err)) {
                                                        toast.error(`Unable to change language. Error: ${err.message}`);
                                                    }
                                                }
                                            }
                                        }
                                    };
                                })}
                                closeOnClickOutside={true}/>
        </>
    );
};

export default SidebarApplicationSectionLanguageSelector;
