import React, {ReactNode, useEffect} from 'react';
import cl from './GridFilterPanelLayoutCollapse.module.css';
import {useCollapse} from "react-collapsed";
import Icon from "../../../../../assets/icon/Icon";
import {useGridFilterPanelLayoutContext} from "../../hooks/useGridFilterPanelLayoutContext";

type GridFilterPanelLayoutCollapseProps = {
    children: ReactNode | ((props: GridFilterPanelLayoutCollapseRenderProps) => JSX.Element);
    title: ReactNode;
    showSeparationHeaderLine?: boolean;
    style?: React.CSSProperties;
    defaultExpanded?: boolean;
    titleUpperCase?: boolean;
    titleBold?: boolean;
    hideOnFilterPanelLayoutContextClear?: boolean;
    tagsContainer?: ReactNode;
}

type GridFilterPanelLayoutCollapseRenderProps = {
    isExpanded: boolean;
}

const GridFilterPanelLayoutCollapse: React.FC<GridFilterPanelLayoutCollapseProps> = ({
                                                                                         children,
                                                                                         title,
                                                                                         showSeparationHeaderLine = false,
                                                                                         style,
                                                                                         defaultExpanded = true,
                                                                                         titleUpperCase = false,
                                                                                         titleBold = true,
                                                                                         hideOnFilterPanelLayoutContextClear = false,
                                                                                         tagsContainer
                                                                                     }) => {
    const {getCollapseProps, getToggleProps, isExpanded, setExpanded} = useCollapse({
        defaultExpanded
    });

    let renderProps: GridFilterPanelLayoutCollapseRenderProps = {
        isExpanded
    };

    let headerClassName = `${cl.collapseHeader} ${showSeparationHeaderLine ? cl.collapseHeaderLine : ''}`;
    let titleHeaderClassName = `${cl.collapseTitle} ${!showSeparationHeaderLine ? cl.collapseTitleMgb : ''} prevent-selection`;

    if (titleUpperCase) {
        titleHeaderClassName += ` ${cl.uppercase}`;
    }

    if (titleBold) {
        titleHeaderClassName += ` ${cl.bold}`;
    }

    const {
        clearCounter
    } = useGridFilterPanelLayoutContext();

    useEffect(() => {
        if (!clearCounter || !hideOnFilterPanelLayoutContextClear) {
            return;
        }

        setExpanded(false);
    }, [clearCounter]);

    return (
        <div className={cl.collapse}
             style={style}>
            <div className={headerClassName} {...getToggleProps()}>
                <div className={titleHeaderClassName} style={{marginRight: '10px'}}>
                    {title}
                </div>
                <div className={cl.collapseIcon}>
                    <Icon icon={"faThinChevronUp"}
                          flip={isExpanded ? 'horizontal' : 'vertical'}/>
                </div>
            </div>

            {tagsContainer
                ? <>{tagsContainer}</>
                : null
            }

            <div {...getCollapseProps()}>
                <div className={cl.collapseContent}>
                    {typeof children === 'function'
                        ? children(renderProps)
                        : children
                    }
                </div>
            </div>
        </div>
    );
};

export default GridFilterPanelLayoutCollapse;
