import React, {useCallback, useState} from 'react';
import {useTranslation} from "react-i18next";
import {matchPath, useLocation, useNavigate, useParams} from "react-router-dom";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {useGridLayoutContext} from "../../../../../../components/uiLayouts/GridLayout/hooks/useGridLayoutContext";
import {IShipmentHeaderConfig} from "../../data/interfaces/IShipmentHeaderConfig";
import useGridExport from "../../../../../../components/ui/Grid/Telerik/hooks/useGridExport";
import {
    GridActionRowButtonProps
} from "../../../../../../components/ui/Grid/Telerik/components/GridActionRow/components/GridActionRowButton/GridActionRowButton";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import moment from "moment";
import HardGridActionRow, {
    GridActionRowSelectorProps
} from "../../../../../../components/ui/Grid/Telerik/components/HardGridActionRow/HardGridActionRow";
import {buildShipmentGridLabel} from "../../utils";
import ShipmentActionRowActionButton from "./ShipmentActionRowActionButton/ShipmentActionRowActionButton";
import ShipmentActionRowTags from "./ShipmentActionRowTags/ShipmentActionRowTags";
import GridColumnsConfigurationSetupDialog
    from "../../../../../../components/ui/Grid/Telerik/components/GridColumnsConfigurationSetupDialog/GridColumnsConfigurationSetupDialog";
import {LayoutConfigEntity} from "../../../../../../app/enums/LayoutConfigEntity";
import getShipmentInitialGridConfig from "../../data/columns";

const ShipmentActionRow: React.FC = () => {
    const {t, i18n} = useTranslation();
    const {type} = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const user = useAppSelector(selectCurrentUser);
    const [isSetupDialogVisible, setIsSetupDialogVisible] = useState<boolean>(false);

    const {
        groupBy,
        refresh,
        defaultGridConfig,
        gridConfig,
        headerConfig,
        isHeaderDisabled,
        saveHeaderConfig,
        reloadGridConfig,
    } = useGridLayoutContext<IShipmentHeaderConfig>();

    const onChange = (config: IShipmentHeaderConfig) => {
        (async () => {
            await saveHeaderConfig(config);
        })();
    }

    const {
        download
    } = useGridExport({
        gridId: 'shipment-grid',
        config: gridConfig ?? defaultGridConfig,
        useSelectedRowToExport: true
    });

    const buildButtonList = useCallback((): Array<GridActionRowButtonProps> => {
        if (!headerConfig || !user) {
            return [];
        }

        let userRole = getUserRole(user);
        let res: Array<GridActionRowButtonProps> = [];

        if (userRole !== UserRole.ServiceProvider && userRole !== UserRole.Client) {
            if (!headerConfig.isMapView) {
                res.push({
                    id: 'shipment-group-by-job',
                    title: headerConfig.isGroupByJob
                        ? t("shared.grid-header.btn-disable-group-by-job")
                        : t("shared.grid-header.btn-enable-group-by-job"),
                    toggle: true,
                    defaultValue: headerConfig.isGroupByJob,
                    disabled: isHeaderDisabled,
                    icon: "faList",
                    onClick: (val) => {
                        onChange({
                            ...headerConfig,
                            isGroupByJob: val
                        });

                        groupBy(val ? 'job' : undefined);
                    }
                });
            }
        }

            res.push({
                id: 'shipment-export',
                title: t("shared.grid-header.btn-export"),
                disabled: isHeaderDisabled,
                onClick: async () => {
                    await download(`Shipment_${moment().format('DD_MM_YYYY_HH_mm')}.xlsx`);
                },
                icon: "faUpload"
            });

        if (!headerConfig.isMapView) {
            res.push({
                id: 'shipment-table-setup',
                title: t("shared.grid-header.btn-table-setup"),
                disabled: isHeaderDisabled,
                onClick: () => setIsSetupDialogVisible(prev => !prev),
                icon: "faGear"
            });
        }

        res.push({
            id: 'shipment-refresh',
            title: t("shared.grid-header.btn-refresh"),
            disabled: isHeaderDisabled,
            onClick: () => refresh(),
            icon: "faArrowsRotate"
        });

        return res;
    }, [i18n.language, isHeaderDisabled, headerConfig, user]);

    const buildSelectorsList = useCallback((): Array<GridActionRowSelectorProps> => {
        if (!headerConfig || !user) {
            return [];
        }

        let userRole = getUserRole(user);
        let res: Array<GridActionRowSelectorProps> = [];

        if (userRole !== UserRole.Client && userRole !== UserRole.ServiceProvider && type !== 'archive' && type !== 'approve') {
            res.push({
                selector: {
                    disabled: isHeaderDisabled,
                    width: 150,
                    onChange: (item) => {
                        onChange({
                            ...headerConfig,
                            subTab: item.id
                        });
                    }
                },
                options: [
                    {
                        id: 'shipment-all-tab',
                        text: t("shipment.header.all-orders-tab"),
                        selected: headerConfig.subTab === 'shipment-all-tab',
                        tooltip: t("shipment.header.all-orders-tab-tooltip")
                    },
                    {
                        id: 'shipment-my-tab',
                        text: t("shipment.header.my-orders-tab"),
                        tooltip: t("shipment.header.my-orders-tab-tooltip"),
                        selected: headerConfig.subTab === 'shipment-my-tab'
                    }
                ]
            });
        }

        return res;
    }, [i18n.language, isHeaderDisabled, user, headerConfig, type]);

    const buildMenuSelectorsList = useCallback((): GridActionRowSelectorProps | string => {
        if (!headerConfig || !user) {
            return t(buildShipmentGridLabel(type));
        }

        let userRole = getUserRole(user);
        let menu: GridActionRowSelectorProps = {
            selector: {
                disabled: isHeaderDisabled,
                width: 200,
                onChange: (item) => {
                    if (!item.description) {
                        return;
                    }

                    navigate(item.description);
                }
            },
            options: [
                {
                    id: 'shipment-category-menu-current',
                    text: t("sidebar.shipment.current-shipment"),
                    selected: matchPath('/shipments/current', location.pathname) !== null,
                    tooltip: t("sidebar.shipment.current-shipment-tooltip"),
                    description: '/shipments/current'
                },
                {
                    id: 'shipment-category-menu-completed',
                    text: t("sidebar.shipment.completed-shipment"),
                    selected: matchPath('/shipments/completed', location.pathname) !== null,
                    tooltip: t("sidebar.shipment.completed-shipment-tooltip"),
                    description: '/shipments/completed'
                },
            ]
        };

        if (userRole === UserRole.Forwarder || userRole === UserRole.Administrator || userRole === UserRole.ServiceProvider) {
            menu.options.push({
                id: 'shipment-category-menu-invoices',
                text: t("sidebar.shipment.payments-statuses"),
                selected: matchPath('/invoices', location.pathname) !== null,
                description: '/invoices'
            });
        }

        menu.options.push({
            id: 'shipment-category-menu-archive',
            text: t("sidebar.shipment.archive"),
            selected: matchPath('/shipments/archive', location.pathname) !== null,
            tooltip: t("sidebar.shipment.archive-tooltip"),
            description: '/shipments/archive'
        });

        if (userRole === UserRole.Forwarder || userRole === UserRole.Administrator) {
            menu.options.push(...[
                {
                    id: 'shipment-category-menu-disposition',
                    text: t("sidebar.shipment.disposition"),
                    selected: matchPath('/disposition', location.pathname) !== null,
                    description: '/disposition'
                },
                {
                    id: 'shipment-category-menu-approve',
                    text: t("sidebar.shipment.approve"),
                    selected: matchPath('/shipments/approve', location.pathname) !== null,
                    tooltip: t("sidebar.shipment.approve-tooltip"),
                    description: '/shipments/approve'
                }
            ]);
        }

        return menu;
    }, [i18n.language, isHeaderDisabled, user, location.pathname]);

    if (!headerConfig || !user || !type) {
        return null;
    }

    return (
        <>
            <HardGridActionRow id={'shipment-action-row'}
                               title={buildMenuSelectorsList()}
                               buttons={buildButtonList()}
                               actionButton={<ShipmentActionRowActionButton/>}
                               selectors={buildSelectorsList()}>
                {(props) =>
                    <ShipmentActionRowTags availableWidth={props.availableWidth}/>
                }
            </HardGridActionRow>

            <GridColumnsConfigurationSetupDialog show={isSetupDialogVisible}
                                                 onSaved={async () => {
                                                     setIsSetupDialogVisible(prev => !prev);

                                                     await reloadGridConfig();
                                                 }}
                                                 setShow={setIsSetupDialogVisible}
                                                 entity={LayoutConfigEntity.Shipment}
                                                 defaultGridConfig={getShipmentInitialGridConfig(getUserRole(user))}/>
        </>
    );
};

export default ShipmentActionRow;
