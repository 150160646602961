import {useSearchGlobal} from "./requests";

const useSearchApi = () => {
    const {
        isLoading: searchGlobalIsLoading,
        cancel: cancelSearchGlobal,
        searchGlobal
    } = useSearchGlobal();

    return {
        searchGlobal: {
            query: searchGlobal,
            isLoading: searchGlobalIsLoading,
            cancel: cancelSearchGlobal
        }
    };
};

export default useSearchApi;