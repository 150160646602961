import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import GridActionRowContextButton, {
    GridActionRowContextButtonItem
} from "../../../../../../../components/ui/Grid/Telerik/components/GridActionRow/components/GridActionRowContextButton/GridActionRowContextButton";
import {useAppSelector} from "../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../features/authentication/authenticationSliceSelectors";
import {useGridLayoutContext} from "../../../../../../../components/uiLayouts/GridLayout/hooks/useGridLayoutContext";
import {IShipmentHeaderConfig} from "../../../../../Shipment/grid/data/interfaces/IShipmentHeaderConfig";
import {getUserRole} from "../../../../../../../helpers/user";
import {useParams} from "react-router-dom";
import {UserRole} from "../../../../../../../app/enums/UserRole";
import {ResponseResultCode} from "../../../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import {useQuoteApi} from '../../../../../../../app/api/quote';
import AssignQuoteDialog from "../../../../actions/AssignQuote/AssignQuoteDialog";
import CancelQuoteDialog from "../../../../actions/CancelQuote/CancelQuoteDialog";
import SendQuoteToAllDialog from "../../../../actions/SendQuoteToAll/SendQuoteToAllDialog";
import {OrderType} from "../../../../../../../app/enums/OrderType";
import AssociateTagDialog
    from "../../../../../../../components/shared/components/Tag/actions/AssociateTag/AssociateTagDialog";

const QuoteActionRowActionButton = () => {
    const {type} = useParams();
    const {t} = useTranslation();
    const [actions, setActions] = useState<Array<GridActionRowContextButtonItem>>([]);
    const user = useAppSelector(selectCurrentUser);

    const [isAssignDialogShown, setIsAssignDialogShown] = useState<boolean>(false);
    const [isCancelDialogShown, setIsCancelDialogShown] = useState<boolean>(false);
    const [isSendToAllDialogShown, setIsSendToAllDialogShown] = useState<boolean>(false);
    const [isAssociateDialogShown, setIsAssociateDialogShown] = useState<boolean>(false);


    const {
        markQuotesAsViewed: {mutation: markQuotesAsViewed},
        markQuotesAsUnViewed: {mutation: markQuotesAsUnViewed}
    } = useQuoteApi();

    const {
        selectedRows
    } = useGridLayoutContext<IShipmentHeaderConfig>()

    useEffect(() => {
        if (!type) {
            return;
        }

        if (selectedRows.length <= 0) {
            setActions([]);

            return;
        }

        setActions(buildActionList());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows, user, type]);

    const buildActionList = (): Array<GridActionRowContextButtonItem> => {
        if (!user || !type) {
            return [];
        }

        let role = getUserRole(user);
        let res: Array<GridActionRowContextButtonItem> = [];

        if (selectedRows.length > 0 && type !== 'clientSent') {
            if (role === UserRole.Forwarder) {
                res.push({
                    id: 'add-tag',
                    disabled: false,
                    title: t("shared.btn.add-tag"),
                    onClick: () => setIsAssociateDialogShown(prev => !prev)
                });
            }

            res.push({
                id: 'assign-quote',
                disabled: false,
                title: selectedRows.length === 1
                    ? t("shared.btn.assign-quote")
                    : t("shared.btn.assign-quotes"),
                onClick: () => setIsAssignDialogShown(prev => !prev)
            })

            if (type === 'new') {
                res.push({
                    id: 'send-quote-to-all',
                    disabled: false,
                    title: t("shared.btn.send-quote-to-all"),
                    onClick: () => setIsSendToAllDialogShown(prev => !prev)
                });
            }
            if(selectedRows.filter(data => data.isNew === true).length > 0) {
                res.push({
                    id: 'mark-as-viewed',
                    disabled: false,
                    title: selectedRows.length === 1
                        ? t("shared.btn.mark-item-as-viewed")
                        : t("shared.btn.mark-items-as-viewed"),
                    onClick: async () => await markAsViewed()
                });
            }
            if(selectedRows.filter(data => data.isNew !== true).length > 0) {
                res.push({
                    id: 'mark-as-un-viewed',
                    disabled: false,
                    title: selectedRows.length === 1
                        ? t("shared.btn.mark-item-as-un-viewed")
                        : t("shared.btn.mark-items-as-un-viewed"),
                    onClick: async () => await markAsUnViewed()
                });
            }
            res.push({
                id: 'cancel-quote',
                disabled: false,
                title: selectedRows.length === 1
                    ? t("shared.btn.cancel-quote")
                    : t("shared.btn.cancel-quotes"),
                color: 'red',
                onClick: () => setIsCancelDialogShown(prev => !prev)
            });
        }

        return res;
    }

    const markAsViewed = async () => {
        let ids: Array<{
            orderReleaseId: number;
            id: string;
        }> = [];

        selectedRows.forEach(row => {
            if (row.isNew !== null && row.isNew !== undefined && row.isNew === true) {
                ids.push({
                    id: row.id,
                    orderReleaseId: row.orderReleaseId
                });
            }
        })

        if (ids.length <= 0) {
            return;
        }

        try {
            document.dispatchEvent(new CustomEvent('onGridMessage', {
                detail: {
                    action: 'updateRows',
                    rows: [...ids].map((item) => {
                        return {
                            id: item.id,
                            isRowLoading: true
                        };
                    })
                }
            }));

            const response = await markQuotesAsViewed({
                ids: [...ids].map((item) => item.orderReleaseId)
            });

            if (response && response.status === 200 &&
                response.data && response.data.resultCode === ResponseResultCode.Ok) {
                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [...ids].map((item) => {
                            return {
                                id: item.id,
                                isRowLoading: false,
                                isNew: false
                            };
                        }),
                        clearSelection: true
                    }
                }));
            }
        } catch {
            toast.error('Unable to change viewed state of quotes');

            document.dispatchEvent(new CustomEvent('onGridMessage', {
                detail: {
                    action: 'updateRows',
                    rows: [...ids].map((item) => {
                        return {
                            id: item.id,
                            isRowLoading: false
                        };
                    })
                }
            }));
        }
    }

    const markAsUnViewed = async () => {
        let ids: Array<{
            orderReleaseId: number;
            id: string;
        }> = [];

        selectedRows.forEach(row => {
            if (row.isNew !== null && row.isNew !== undefined && row.isNew === false) {
                ids.push({
                    id: row.id,
                    orderReleaseId: row.orderReleaseId
                });
            }
        })

        if (ids.length <= 0) {
            return;
        }

        try {
            document.dispatchEvent(new CustomEvent('onGridMessage', {
                detail: {
                    action: 'updateRows',
                    rows: [...ids].map((item) => {
                        return {
                            id: item.id,
                            isRowLoading: true,
                        };
                    })
                }
            }));

            const response = await markQuotesAsUnViewed({
                ids: [...ids].map((item) => item.orderReleaseId)
            });

            if (response && response.status === 200 &&
                response.data && response.data.resultCode === ResponseResultCode.Ok) {
                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [...ids].map((item) => {
                            return {
                                id: item.id,
                                isRowLoading: false,
                                isNew: true
                            };
                        }),
                        clearSelection: true
                    }
                }));
            }
        } catch {
            toast.error('Unable to change viewed state of quotes');

            document.dispatchEvent(new CustomEvent('onGridMessage', {
                detail: {
                    action: 'updateRows',
                    rows: [...ids].map((item) => {
                        return {
                            id: item.id,
                            isRowLoading: false
                        };
                    })
                }
            }));
        }
    }

    return (
        <>
            <AssociateTagDialog orderReleaseGid={selectedRows !== null && selectedRows !== undefined
                ? selectedRows.map(item => item.id).join(';')
                : ''}
                                orderType={OrderType.Quote}
                                show={isAssociateDialogShown}
                                setShow={setIsAssociateDialogShown}/>

            <SendQuoteToAllDialog id={selectedRows.filter(e => e.id !== null && e.id !== undefined).map(e => e.id)}
                                  show={isSendToAllDialogShown}
                                  setShow={setIsSendToAllDialogShown}/>

            <CancelQuoteDialog id={selectedRows.filter(e => e.id !== null && e.id !== undefined).map(e => e.id)}
                               show={isCancelDialogShown}
                               setShow={setIsCancelDialogShown}/>

            <AssignQuoteDialog id={selectedRows.filter(e => e.id !== null && e.id !== undefined).map(e => e.id)}
                               show={isAssignDialogShown}
                               setShow={setIsAssignDialogShown}/>

            <GridActionRowContextButton title={t("shared.labels.actions")}
                                        icon={"faEllipsisHorizontal"}
                                        actions={actions}/>


        </>
    );
};

export default QuoteActionRowActionButton;
