import React, {useEffect, useState} from 'react';
import useDocumentTitle from "../../../hooks/useDocumentTitle/useDocumentTitle";
import {useTranslation} from "react-i18next";
import useMediaQuery from "../../../hooks/useMediaQuery/useMediaQuery";
import Card, {CardAppearAnimationType} from "../../../components/ui/Card/Card";
import Container from "../../../components/ui/Container/Container";
import cl from './DeviceAuthentication.module.css';
import {useLoginApi} from "../../../app/api/login";
import {toast} from "react-toastify";
import {useAppDispatch, useAppSelector} from "../../../app/store";
import {
    validateDeviceAuthentication,
    validateUserAuthentication
} from "../../../features/authentication/authenticationSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-regular-svg-icons";
import OtpInput from "../../../components/ui/OtpInput/OtpInput";
import useSecondCountDownTimer from "../../../hooks/useSecondCountDownTimer/useSecondCountDownTimer";
import {AxiosError} from "axios";
import {useDeviceApi} from "../../../app/api/device";
import {selectDeviceId} from "../../../features/application/applicationSliceSelectors";
import {ResponseResultCode} from "../../../app/enums/ResponseResultCode";
import {isResponse} from "../../../app/interfaces/response/IResponse";
import getServerErrorMessage from "../../../app/apiError";
import useSignalRHub from "../../../hooks/useSignalRHub/useSignalRHub";

const DeviceAuthentication: React.FC = () => {
    const deviceId = useAppSelector(selectDeviceId);
    const [timer, start] = useSecondCountDownTimer(60);
    const [otp, setOtp] = useState<string>('');
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    useDocumentTitle({title: t("device.labels.device-confirmation-page-title")});
    const [, close] = useSignalRHub(`${process.env.REACT_APP_API_DOMAIN}/applicationHub`, {
        singleton: true
    });
    const {logout: {mutation}} = useLoginApi();
    const {sendConfirmationEmail: {mutation: sendConfirmationEmail, isLoading}} = useDeviceApi();
    const {confirmDevice: {mutation: confirmDevice, isLoading: isConfirmDeviceLoading}} = useDeviceApi();

    const isMaxWidth470 = useMediaQuery('(max-width:450px)');
    const isMaxHeight490 = useMediaQuery('(max-height:490px)');

    const send = async () => {
        if (!deviceId) {
            return;
        }

        try {
            const response = await sendConfirmationEmail(deviceId);

            if (response?.data && response.status === 200 && response.data.message) {
                let elem = document.getElementById('confirmation-code-tmp');
                if (elem) {
                    elem.setAttribute('value', response.data.message);
                }
            }

            start();
        } catch (err) {
            if (err instanceof AxiosError) {
                if (err.response !== undefined &&
                    err.response.data !== undefined &&
                    isResponse(err.response.data)) {
                    toast.error<string>(t(getServerErrorMessage('device', err.response.data)));
                } else {
                    toast.error<string>(t(getServerErrorMessage('device', {
                        resultCode: ResponseResultCode.ServerError,
                        message: err.message
                    })));
                }
            }
        }
    }

    const confirm = async (input: string) => {
        setOtp(input);
        if (input.replace(/ +/g, '').length !== 6 || !deviceId) {
            return;
        }

        try {
            const response = await confirmDevice({
                confirmationCode: input,
                deviceId
            });

            if ((response?.status === 200 || response?.status === 204) &&
                response?.data && response.data.resultCode === ResponseResultCode.Ok) {
                dispatch(validateDeviceAuthentication());
            }
        } catch (err) {
            if (err instanceof AxiosError) {
                if (err.response !== undefined &&
                    err.response.data !== undefined &&
                    isResponse(err.response.data)) {
                    toast.error<string>(t(getServerErrorMessage('device', err.response.data)));
                } else {
                    toast.error<string>(t(getServerErrorMessage('device', {
                        resultCode: ResponseResultCode.ServerError,
                        message: err.message
                    })));
                }
            }
        }
    }

    useEffect(() => {
        (async () => {
            await send();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logout = async () => {
        try {
            close();
            await mutation();

            // This call sets global user authentication state
            dispatch(validateUserAuthentication());
        } catch (e) {
            toast.error(t("shared.errors.server-error"));
        }
    };

    return (
        <Container centerContent={true} height={'calc(100% - 30px)'}>
            <Card cardAppearAnimationType={CardAppearAnimationType.FadeIn}
                  style={isMaxWidth470 ? {width: 'calc(100% - 20px)'} : {width: '450px'}}>
                <div className={cl.logoContainer}
                     style={isMaxHeight490 ? {display: 'none'} : undefined}>
                    <img className={'prevent-selection'}
                         src={'/images/logo_main.png'}
                         alt={'logo'}/>
                </div>
                <div className={'pd-10'}>
                    <div className={`${cl.paperPlane} prevent-selection`}>
                        <FontAwesomeIcon icon={faPaperPlane}/>
                    </div>
                    <div className={`${cl.headerText} prevent-selection`}>
                        {t("device.labels.device-confirmation-card-title")}
                    </div>
                    <input type={'hidden'} id={'confirmation-code-tmp'}/>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <OtpInput value={otp}
                                  valueLength={6}
                                  disabled={isConfirmDeviceLoading || isLoading}
                                  onChange={confirm}/>
                    </div>
                    <div className={cl.text}>
                        <span>
                           {`${t("device.labels.device-confirmation-card-text")} ${t("device.labels.device-confirmation-card-text-2")}`}
                        </span>
                        <button className={cl.resendBtn}
                                onClick={send}
                                disabled={timer > 0 || isLoading}>
                            {t("shared.btn.send")}
                        </button>
                        {timer > 0 &&
                            <span style={{color: 'red'}}>
                                {timer}
                            </span>
                        }
                    </div>
                    <div className={cl.separator}></div>
                    <div>
                        <button type={'button'}
                                onClick={logout}
                                className={cl.btn}>
                            {t("shared.btn.logout")}
                        </button>
                    </div>
                </div>
            </Card>
        </Container>
    );
};

export default DeviceAuthentication;
