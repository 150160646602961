import React, {useState} from 'react';
import {IUser} from "../../../../../app/interfaces/user/IUser";
import cl from './ForwarderData.module.css';
import ForwarderDataDialog from "./ForwarderDataDialog/ForwarderDataDialog";

type ForwarderDataProps = {
    user: IUser;
}

const ForwarderData: React.FC<ForwarderDataProps> = ({
                                                         user
                                                     }) => {
    const [isDataDialogShown, setIsDataDialogShown] = useState<boolean>(false);

    return (
        <>
            {user.idStr && user.hasKpAccount &&
                <ForwarderDataDialog ids={user.idStr}
                                     show={isDataDialogShown}
                                     setShow={setIsDataDialogShown}/>
            }

            {user.hasKpAccount &&
                <div className={`${cl.container} transition-all-500 user-link`}
                     onClick={(ev) => {
                         ev.preventDefault();

                         setIsDataDialogShown(prev => !prev);
                     }}>
                    {user.name}
                </div>
            }

            {!user.hasKpAccount &&
                <div className={`transition-all-500`}>
                    {user.name}
                </div>
            }
        </>
    );
};

export default ForwarderData;
