import React from 'react';
import {IUser} from "../../../../../app/interfaces/user/IUser";
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import AddCustomerAttachment from './AddCustomerAttachment';

type AddCustomerAttachmentDialogProps = {
    user?: IUser;
    orderReleaseGid: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddCustomerAttachmentDialog: React.FC<AddCustomerAttachmentDialogProps> = ({
                                                               user,
                                                               show,
                                                               orderReleaseGid,
                                                               setShow
                                                           }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'add-customer-attachment-dialog'}
                show={show}
                setShow={setShow}
                width={'850px'}
                title={t("customer-attachment.btn.add-customer-attachment")}>
            <AddCustomerAttachment orderReleaseGid={orderReleaseGid}
                        user={user}
                        close={() => setShow(prev => !prev)}/>
        </Dialog>

    );
};

export default AddCustomerAttachmentDialog;