export enum EcmStatus {
    Sent,
    Delivered,
    Incorrect,
    NotFound,
    Deleted,
    Completed,
    Check,
    Income,
    Draft,
    New,
    Local,
    Handled
}