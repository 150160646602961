import React from 'react';
import {RouteSectionModel} from "../../RouteSection";
import {useTranslation} from "react-i18next";

type TransportModeProps = {
    model: RouteSectionModel;
}

const TransportMode: React.FC<TransportModeProps> = ({model}) => {
    const {t} = useTranslation();

    return (
        <>
            <label className={'transport-mode-product-label'}>
                {t('quote.form.transport-mode-product')}
            </label>
            <span className={'details-box-item-field-value transport-mode-product-value'}>
                 {model.transportMode !== undefined && model.transportMode !== null && model.transportMode !== ''
                     ? <>{model.transportMode}</>
                     : <>{t("shared.labels.no-data")}</>
                 }
                {model.product !== undefined && model.product !== null && model.product !== ''
                    ? <>{` / ${model.product}`}</>
                    : <>{` / ${t("shared.labels.no-data")}`}</>
                }
            </span>
        </>
    );
};

export default TransportMode;