import {AxiosResponse} from "axios";
import {IDataResponse, IResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {IShipment} from "../../interfaces/shipment/IShipment";
import {ITsHistory} from "../../interfaces/shared/ITsHistory";
import {IAddEventGetAction} from "../../interfaces/shipment/IAddEventGetAction";
import {IAssignShipmentGetAction} from "../../interfaces/shipment/IAssignShipmentGetAction";
import {IAssignShipmentPostAction} from "../../interfaces/shipment/IAssignShipmentPostAction";
import {IAddEventPostAction} from "../../interfaces/shipment/IAddEventPostAction";
import {IChangeDateGetAction} from "../../interfaces/shipment/IChangeDateGetAction";
import {IChangeDatePostAction} from "../../interfaces/shipment/IChangeDatePostAction";
import {IBorderCrossingAction} from "../../interfaces/shipment/IBorderCrossingAction";
import {ICustomsEtaAction} from "../../interfaces/shipment/ICustomsEtaAction";
import {INoteAction} from "../../interfaces/shipment/INoteAction";
import {IDeletePostAction} from "../../interfaces/shipment/IDeletePostAction";
import {IChangeRefNumberPostAction} from "../../interfaces/shipment/IChangeRefNumberPostAction";
import {IChangeRefNumberGetAction} from "../../interfaces/shipment/IChangeRefNumberGetAction";
import {ICancelShipmentGetAction} from "../../interfaces/shipment/ICancelShipmentGetAction";
import {ICancelShipmentPostAction} from "../../interfaces/shipment/ICancelShipmentPostAction";
import {IConfirmationShipmentPostAction} from "../../interfaces/shipment/IConfirmationShipmentPostAction";
import {IEditTransportPostAction} from "../../interfaces/shipment/IEditTransportPostAction";
import {IEditTransportGetAction} from "../../interfaces/shipment/IEditTransportGetAction";
import {IRefinePositionGetAction} from "../../interfaces/shipment/IRefinePositionGetAction";
import {IRefinePositionPostAction} from "../../interfaces/shipment/IRefinePositionPostAction";
import {IMapPositioningGetAction} from "../../interfaces/shipment/IMapPositioningGetAction";
import {IMapPositioningPostAction} from "../../interfaces/shipment/IMapPositioningPostAction";
import {IPosition} from "../../interfaces/shared/IPosition";
import {IApproveShipmentPostAction} from "../../interfaces/shipment/IApproveShipmentPostAction";


export const useGetShipmentCostLink = (): {
    isLoading: boolean,
    cancel: () => void,
    getShipmentCostLink: (id: string) => Promise<AxiosResponse<IDataResponse<string>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<string>>({
        method: 'get',
    });

    const getShipmentCostLink = useCallback(async (id: string) => await execute(undefined, {
        url: `/api/${process.env.REACT_APP_API_VERSION}/shipment/getEditCostNettoLink?id=${id}&_c=${Date.now()}`
    }), [execute]);

    return {getShipmentCostLink, isLoading, cancel};
};

export const useMarkShipmentsAsViewed = (): {
    isLoading: boolean,
    cancel: () => void,
    markShipmentsAsViewed: (params: {
        ids: Array<string>
    }) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, {
        ids: Array<string>
    }>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/markShipmentsAsViewed`
    });

    const markShipmentsAsViewed = useCallback(async (params: {
        ids: Array<string>
    }) => await execute(params), [execute]);

    return {markShipmentsAsViewed, isLoading, cancel};
};

export const useMarkShipmentsAsUnViewed = (): {
    isLoading: boolean,
    cancel: () => void,
    markShipmentsAsUnViewed: (params: {
        ids: Array<string>
    }) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, {
        ids: Array<string>
    }>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/markShipmentsAsUnViewed`
    });

    const markShipmentsAsUnViewed = useCallback(async (params: {
        ids: Array<string>
    }) => await execute(params), [execute]);

    return {markShipmentsAsUnViewed, isLoading, cancel};
};

export const useGetShipment = (): {
    isLoading: boolean,
    cancel: () => void,
    getShipment: (rId: string, sId: string) => Promise<AxiosResponse<IDataResponse<IShipment>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IShipment>>({
        method: 'get',
    });

    const getShipment = useCallback(async (rId: string, sId: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getShipment?id=${rId}&sId=${sId}`
    }), [execute]);

    return {getShipment, isLoading, cancel};
};

export const useGetTsHistory = (): {
    isLoading: boolean,
    cancel: () => void,
    getTsHistory: (rId: string, sId: string) => Promise<AxiosResponse<IDataResponse<Array<ITsHistory>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<ITsHistory>>>({
        method: 'get',
    });

    const getTsHistory = useCallback(async (rId: string, sId: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getTsHistory?rId=${rId}&sId=${sId}`
    }), [execute]);

    return {getTsHistory, isLoading, cancel};
};

export const useGetShipmentWithEvents = (): {
    isLoading: boolean,
    cancel: () => void,
    getShipmentWithEvents: (rId: string, sId: string) => Promise<AxiosResponse<IDataResponse<IShipment>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IShipment>>({
        method: 'get',
    });

    const getShipmentWithEvents = useCallback(async (rId: string, sId: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getShipmentWithEvents?id=${rId}&sId=${sId}`
    }), [execute]);

    return {getShipmentWithEvents, isLoading, cancel};
};

export const useGetAddEventData = (): {
    isLoading: boolean;
    cancel: () => void;
    getAddEventData: (id: string | Array<string>) => Promise<AxiosResponse<IDataResponse<IAddEventGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IAddEventGetAction>>({
        method: 'get',
    });

    const getAddEventData = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getAddEventData?id=${param}`
        });
    }, [execute]);

    return {getAddEventData, isLoading, cancel};
}

export const useGetAssignShipmentData = (): {
    isLoading: boolean;
    cancel: () => void;
    getAssignShipmentData: (id: string | Array<string>) => Promise<AxiosResponse<IDataResponse<IAssignShipmentGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IAssignShipmentGetAction>>({
        method: 'get',
    });

    const getAssignShipmentData = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getAssignData?id=${param}`
        });
    }, [execute]);

    return {getAssignShipmentData, isLoading, cancel};
}

export const useAssignShipment = (): {
    isLoading: boolean,
    cancel: () => void,
    assignShipment: (params: IAssignShipmentPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IAssignShipmentPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/assign`
    });

    const assignShipment = useCallback(async (params: IAssignShipmentPostAction) => await execute(params), [execute]);

    return {assignShipment, isLoading, cancel};
};

export const useAddEvent = (): {
    isLoading: boolean,
    cancel: () => void,
    addEvent: (params: IAddEventPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IAddEventPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/addEvent`
    });

    const addEvent = useCallback(async (params: IAddEventPostAction) => await execute(params), [execute]);

    return {addEvent, isLoading, cancel};
};

export const useGetCopyOrData = (): {
    isLoading: boolean,
    cancel: () => void,
    getCopyOrData: (id: string) => Promise<AxiosResponse<IDataResponse<string>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<string>>({
        method: 'get',
    });

    const getCopyOrData = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getCopyORLink?id=${id}`
    }), [execute]);

    return {getCopyOrData, isLoading, cancel};
};

export const useGetTimeSlotData = (): {
    isLoading: boolean,
    cancel: () => void,
    getTimeSlotData: (id: string) => Promise<AxiosResponse<IDataResponse<string>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<string>>({
        method: 'get',
    });

    const getTimeSlotData = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getTimeSlotLink?id=${id}`
    }), [execute]);

    return {getTimeSlotData, isLoading, cancel};
};

export const useGetSPUnblockLink = (): {
    isLoading: boolean,
    cancel: () => void,
    getSPUnblockLink: (id: string) => Promise<AxiosResponse<IDataResponse<string>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<string>>({
        method: 'get',
    });

    const getSPUnblockLink = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getSPUnblockLink?id=${id}`
    }), [execute]);

    return {getSPUnblockLink, isLoading, cancel};
};

export const useChangeDate = (): {
    isLoading: boolean,
    cancel: () => void,
    changeDate: (params: IChangeDatePostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IChangeDatePostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/changeDateAction`
    });

    const changeDate = useCallback(async (params: IChangeDatePostAction) => await execute(params), [execute]);

    return {changeDate, isLoading, cancel};
};

export const useGetChangeDateData = (): {
    isLoading: boolean,
    cancel: () => void,
    getChangeDateData: (id: string, sid: string) => Promise<AxiosResponse<IDataResponse<IChangeDateGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IChangeDateGetAction>>({
        method: 'get',
    });

    const getChangeDateData = useCallback(async (id: string, sid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getChangeDateData?id=${id}&sid=${sid}`
    }), [execute]);

    return {getChangeDateData, isLoading, cancel};
}

export const useAddBorderCrossing = (): {
    isLoading: boolean,
    cancel: () => void,
    addBorderCrossing: (params: IBorderCrossingAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IBorderCrossingAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/borderCrossingAction`
    });

    const addBorderCrossing = useCallback(async (params: IBorderCrossingAction) => await execute(params), [execute]);

    return {addBorderCrossing, isLoading, cancel};
};

export const useGetBorderCrossingData = (): {
    isLoading: boolean,
    cancel: () => void,
    getBorderCrossingData: (id: string, sid: string) => Promise<AxiosResponse<IDataResponse<IBorderCrossingAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IBorderCrossingAction>>({
        method: 'get',
    });

    const getBorderCrossingData = useCallback(async (id: string, sid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getBorderCrossingData?id=${id}&sid=${sid}`
    }), [execute]);

    return {getBorderCrossingData, isLoading, cancel};
}

export const useChangeCustomsETA = (): {
    isLoading: boolean,
    cancel: () => void,
    changeCustomsETA: (params: ICustomsEtaAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ICustomsEtaAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/customsETAAction`
    });

    const changeCustomsETA = useCallback(async (params: ICustomsEtaAction) => await execute(params), [execute]);

    return {changeCustomsETA, isLoading, cancel};
};

export const useGetCustomsETAData = (): {
    isLoading: boolean,
    cancel: () => void,
    getCustomsETAData: (id: string, sid: string) => Promise<AxiosResponse<IDataResponse<ICustomsEtaAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ICustomsEtaAction>>({
        method: 'get',
    });

    const getCustomsETAData = useCallback(async (id: string, sid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getCustomsETAData?id=${id}&sid=${sid}`
    }), [execute]);

    return {getCustomsETAData, isLoading, cancel};
}

export const useChangeNote = (): {
    isLoading: boolean,
    cancel: () => void,
    changeNote: (params: INoteAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, INoteAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/noteAction`
    });

    const changeNote = useCallback(async (params: INoteAction) => await execute(params), [execute]);

    return {changeNote, isLoading, cancel};
};

export const useGetChangeNoteData = (): {
    isLoading: boolean,
    cancel: () => void,
    getChangeNoteData: (id: string, sid: string) => Promise<AxiosResponse<IDataResponse<INoteAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<INoteAction>>({
        method: 'get',
    });

    const getChangeNoteData = useCallback(async (id: string, sid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getNoteData?id=${id}&sid=${sid}`
    }), [execute]);

    return {getChangeNoteData, isLoading, cancel};
}

export const useDeleteShipment = (): {
    isLoading: boolean,
    cancel: () => void,
    deleteShipment: (params: IDeletePostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IDeletePostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/deleteAction`
    });

    const deleteShipment = useCallback(async (params: IDeletePostAction) => await execute(params), [execute]);

    return {deleteShipment, isLoading, cancel};
};

export const useChangeRefNumber = (): {
    isLoading: boolean,
    cancel: () => void,
    changeRefNumber: (params: IChangeRefNumberPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IChangeRefNumberPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/refNumberAction`
    });

    const changeRefNumber = useCallback(async (params: IChangeRefNumberPostAction) => await execute(params), [execute]);

    return {changeRefNumber, isLoading, cancel};
};

export const useGetChangeRefNumberData = (): {
    isLoading: boolean,
    cancel: () => void,
    getChangeRefNumberData: (id: string) => Promise<AxiosResponse<IDataResponse<IChangeRefNumberGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IChangeRefNumberGetAction>>({
        method: 'get',
    });

    const getChangeRefNumberData = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getRefNumberData?id=${id}`
    }), [execute]);

    return {getChangeRefNumberData, isLoading, cancel};
}

export const useCancelShipment = (): {
    isLoading: boolean,
    cancel: () => void,
    cancelShipment: (params: ICancelShipmentPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ICancelShipmentPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/cancelAction`
    });

    const cancelShipment = useCallback(async (params: ICancelShipmentPostAction) => await execute(params), [execute]);

    return {cancelShipment, isLoading, cancel};
};

export const useGetCancelShipmentData = (): {
    isLoading: boolean,
    cancel: () => void,
    getCancelShipmentData: (id: string | Array<string>) => Promise<AxiosResponse<IDataResponse<ICancelShipmentGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ICancelShipmentGetAction>>({
        method: 'get',
    });

    const getCancelShipmentData = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getCancelData?id=${param}`
        });
    }, [execute]);

    return {getCancelShipmentData, isLoading, cancel};
}

export const useConfirmShipment = (): {
    isLoading: boolean,
    cancel: () => void,
    confirmShipment: (params: IConfirmationShipmentPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IConfirmationShipmentPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/confirmAction`
    });

    const confirmShipment = useCallback(async (params: IConfirmationShipmentPostAction) => await execute(params), [execute]);

    return {confirmShipment, isLoading, cancel};
};

export const useGetConfirmShipmentData = (): {
    isLoading: boolean,
    cancel: () => void,
    getConfirmShipmentData: (id: string | Array<string>) => Promise<AxiosResponse<IResponse> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse>({
        method: 'get',
    });

    const getConfirmShipmentData = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getConfirmData?id=${param}`
        });
    }, [execute]);

    return {getConfirmShipmentData, isLoading, cancel};
}

export const useUnConfirmShipment = (): {
    isLoading: boolean,
    cancel: () => void,
    unConfirmShipment: (params: IConfirmationShipmentPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IConfirmationShipmentPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/unConfirmAction`
    });

    const unConfirmShipment = useCallback(async (params: IConfirmationShipmentPostAction) => await execute(params), [execute]);

    return {unConfirmShipment, isLoading, cancel};
};

export const useGetUnConfirmShipmentData = (): {
    isLoading: boolean,
    cancel: () => void,
    getUnConfirmShipmentData: (id: string | Array<string>) => Promise<AxiosResponse<IResponse> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse>({
        method: 'get',
    });

    const getUnConfirmShipmentData = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getUnConfirmData?id=${param}`
        });
    }, [execute]);

    return {getUnConfirmShipmentData, isLoading, cancel};
}

export const useEditTransportData = (): {
    isLoading: boolean,
    cancel: () => void,
    editTransportData: (params: IEditTransportPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IEditTransportPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/editTransportAction`
    });

    const editTransportData = useCallback(async (params: IEditTransportPostAction) => await execute(params), [execute]);

    return {editTransportData, isLoading, cancel};
};

export const useGetEditTransportData = (): {
    isLoading: boolean,
    cancel: () => void,
    getEditTransportData: (id: string, sId: string) => Promise<AxiosResponse<IDataResponse<IEditTransportGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IEditTransportGetAction>>({
        method: 'get',
    });

    const getEditTransportData = useCallback(async (id: string, sId: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getEditTransportData?id=${id}&sid=${sId}`
    }), [execute]);

    return {getEditTransportData, isLoading, cancel};
}

export const useRefinePosition = (): {
    isLoading: boolean,
    cancel: () => void,
    refinePosition: (params: IRefinePositionPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IRefinePositionPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/refineLocation`
    });

    const refinePosition = useCallback(async (params: IRefinePositionPostAction) => await execute(params), [execute]);

    return {refinePosition, isLoading, cancel};
};

export const useGetRefinePositionData = (): {
    isLoading: boolean,
    cancel: () => void,
    getRefinePositionData: (shipmentGid: string, locationGid: string) => Promise<AxiosResponse<IDataResponse<IRefinePositionGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IRefinePositionGetAction>>({
        method: 'get',
    });

    const getRefinePositionData = useCallback(async (shipmentGid: string, locationGid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getLocationRefineData?shipmentGid=${shipmentGid}&locationGid=${locationGid}`
    }), [execute]);

    return {getRefinePositionData, isLoading, cancel};
}

export const useGetMapPositioningData = (): {
    isLoading: boolean,
    cancel: () => void,
    getMapPositioningData: (shipmentGid: string) => Promise<AxiosResponse<IDataResponse<IMapPositioningGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IMapPositioningGetAction>>({
        method: 'get',
    });

    const getMapPositioningData = useCallback(async (shipmentGid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getMapPositioningData?id=${shipmentGid}`
    }), [execute]);

    return {getMapPositioningData, isLoading, cancel};
}

export const useAddGpsEvent = (): {
    isLoading: boolean,
    cancel: () => void,
    addGpsEvent: (params: IMapPositioningPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IMapPositioningPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/addGpsEventAction`
    });

    const addGpsEvent = useCallback(async (params: IMapPositioningPostAction) => await execute(params), [execute]);

    return {addGpsEvent, isLoading, cancel};
};

export const useGetRoute = (): {
    isLoading: boolean,
    cancel: () => void,
    getRoute: (shipmentGid: string) => Promise<AxiosResponse<IDataResponse<Array<string>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<string>>>({
        method: 'get',
    });

    const getRoute = useCallback(async (shipmentGid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getRoute?id=${shipmentGid}`
    }), [execute]);

    return {getRoute, isLoading, cancel};
}

export const useGetTrackPoints = (): {
    isLoading: boolean,
    cancel: () => void,
    getTrackPoints: (shipmentGid: string) => Promise<AxiosResponse<IDataResponse<Array<IPosition>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<IPosition>>>({
        method: 'get',
    });

    const getTrackPoints = useCallback(async (shipmentGid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getTrackPoints?id=${shipmentGid}`
    }), [execute]);

    return {getTrackPoints, isLoading, cancel};
}

export const useGetRoutePointLocalTime = (): {
    isLoading: boolean,
    cancel: () => void,
    getRoutePointLocalTime: (lt: string, lg: string, date: string) => Promise<AxiosResponse<IDataResponse<string>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<string>>({
        method: 'get',
    });

    const getRoutePointLocalTime = useCallback(async (lt: string, lg: string, date: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getRoutePointLocalTime?lt=${lt}&lg=${lg}&date=${date}`
    }), [execute]);

    return {getRoutePointLocalTime, isLoading, cancel};
}

export const useChangeDateAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    changeDateAnonymous: (params: IChangeDatePostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IChangeDatePostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/changeDateActionAnonymous`
    });

    const changeDateAnonymous = useCallback(async (params: IChangeDatePostAction) => await execute(params), [execute]);

    return {changeDateAnonymous, isLoading, cancel};
};

export const useGetChangeDateDataAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    getChangeDateDataAnonymous: (id: string, sid: string) => Promise<AxiosResponse<IDataResponse<IChangeDateGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IChangeDateGetAction>>({
        method: 'get',
    });

    const getChangeDateDataAnonymous = useCallback(async (id: string, sid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getChangeDateDataAnonymous?id=${id}&sid=${sid}`
    }), [execute]);

    return {getChangeDateDataAnonymous, isLoading, cancel};
}

export const useGetAddEventDataAnonymous = (): {
    isLoading: boolean;
    cancel: () => void;
    getAddEventDataAnonymous: (id: string | Array<string>) => Promise<AxiosResponse<IDataResponse<IAddEventGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IAddEventGetAction>>({
        method: 'get',
    });

    const getAddEventDataAnonymous = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/shipment/getAddEventDataAnonymous?id=${param}`
        });
    }, [execute]);

    return {getAddEventDataAnonymous, isLoading, cancel};
};

export const useAddEventAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    addEventAnonymous: (params: IAddEventPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IAddEventPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/shipment/addEventAnonymous`
    });

    const addEventAnonymous = useCallback(async (params: IAddEventPostAction) => await execute(params), [execute]);

    return {addEventAnonymous, isLoading, cancel};
};

export const useApproveShipment = (): {
    isLoading: boolean,
    cancel: () => void,
    approveShipment: (params: IApproveShipmentPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IApproveShipmentPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/cmr/approveExpenseAction`
    });

    const approveShipment = useCallback(async (params: IApproveShipmentPostAction) => await execute(params), [execute]);

    return {approveShipment, isLoading, cancel};
};
