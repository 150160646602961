const areCookiesEnabled = (): boolean => {
    const d = document

    try {
        // Create cookie
        d.cookie = 'testcookie=1; SameSite=Strict;'
        const result = d.cookie.indexOf('cookietest=') !== -1
        // Delete cookie
        d.cookie = 'testcookie=1; SameSite=Strict; expires=Thu, 01-Jan-1970 00:00:01 GMT'
        return result
    } catch (e) {
        return false
    }
}

export default areCookiesEnabled;