import React from 'react';
import {RouteSectionModel} from "../../RouteSection";
import moment from "moment";

type LateDeliveryDateProps = {
    model: RouteSectionModel
}

const buildDate = (model: RouteSectionModel): string => {
    if (model.hasShipment) {
        if (model.lateDeliveryDate !== null && model.lateDeliveryDate !== undefined) {
            return moment(model.lateDeliveryDate).format('DD.MM.YY HH:mm');
        } else {
            return '';
        }
    }

    let sp: string;
    let ep: string;

    if (model.earlyDeliveryDate !== null && model.earlyDeliveryDate !== undefined &&
        ((model.lateDeliveryDate === null || model.lateDeliveryDate === undefined) ||
            (model.earlyDeliveryDate !== model.lateDeliveryDate)))
        sp = moment(model.earlyDeliveryDate).format('DD.MM.YY HH:mm');
    else
        sp = "";

    if (model.lateDeliveryDate !== null && model.lateDeliveryDate !== undefined)
        ep = moment(model.lateDeliveryDate).format('DD.MM.YY HH:mm');
    else
        ep = "";


    if (sp === ep && sp === "")
        return '';

    if (sp === '')
        return ep;

    if (ep === '')
        return sp;

    return sp + " - " + ep;
}

const LateDeliveryDate: React.FC<LateDeliveryDateProps> = ({model}) => {
    return (
        <>
            {model.earlyPickupDateText !== null && model.earlyPickupDateText !== ''
                ? <div style={{height: '17px'}}>
                    {buildDate(model)}
                </div>
                : <div style={{height: '17px'}}>

                </div>
            }
        </>
    );
};

export default LateDeliveryDate;