import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'arrowLeft',
    pathData: [
        'M5.5 1L1 5.5M1 5.5L5.5 10M1 5.5L10 5.5'
    ],
    viewBox: '0 0 11 11',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;
