import React, {SetStateAction} from 'react';
import {IAssignQuoteAction} from "../../../../../../app/interfaces/quote/IAssignQuoteAction";
import {useTranslation} from "react-i18next";
import {FormikHelpers, useFormik} from "formik";
import AssignQuoteExecutionAgentSelector from "./AssignQuoteExecutionAgentSelector/AssignQuoteExecutionAgentSelector";
import * as Yup from "yup";

type AssignQuoteFormProps = {
    model: IAssignQuoteAction;
    disabled: boolean;
    setIsSubmitDisabled: React.Dispatch<SetStateAction<boolean>>;
    save: (model: IAssignQuoteAction) => Promise<void>;
}

const AssignQuoteForm: React.FC<AssignQuoteFormProps> = ({
                                                             model,
                                                             disabled,
                                                             setIsSubmitDisabled,
                                                             save
                                                         }) => {
    const {t} = useTranslation();

    const form = useFormik<IAssignQuoteAction>({
        initialValues: {
            executionAgent: model.executionAgent,
            orderReleaseGid: model.orderReleaseGid
        },
        validationSchema: Yup.object().shape({
            executionAgent: Yup.string().nullable()
                .required(t("shared.errors.required-error").replace('{0}', t("quote.grid.forwarder")))
        }),
        onSubmit: async (values: IAssignQuoteAction, actions: FormikHelpers<IAssignQuoteAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });
    return (
        <form id='assign-quote-form'
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div className={'mgb-10'}>
                    <label className={'view-label required'}>
                        {t("quote.grid.forwarder")}
                    </label>
                    <AssignQuoteExecutionAgentSelector
                        onChange={(val) => form.setFieldValue('executionAgent', val)}
                        disabled={disabled || form.isSubmitting}
                        value={form.values.executionAgent ?? undefined}
                        setIsSubmitDisabled={setIsSubmitDisabled}/>
                    {form.errors.executionAgent &&
                        <div className={'error-label'}>
                            {form.errors.executionAgent}
                        </div>
                    }
                </div>
            </div>
        </form>
    );
};

export default AssignQuoteForm;