import {IShipmentHeaderConfig} from "./interfaces/IShipmentHeaderConfig";

const defaultShipmentHeaderConfig: IShipmentHeaderConfig = {
    dispositionSubTab: 'shipment-disposition-all-tab',
    subTab: 'shipment-my-tab',
    isGroupByJob: false,
    isMapView: false,
    showOnlyCurrent: false,
    CM: false,
    TLM: false,
    SMEXA: false,
    SMFA: false,
    OM: false,
    pinnedFilter: null,
    pinnedPredefinedFilters: null,
    pinnedPredefinedFilter: null
};

export default defaultShipmentHeaderConfig;