import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import CopyOr from "./CopyOr";

type CopyOrDialogProps = {
    id: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const CopyOrDialog: React.FC<CopyOrDialogProps> = ({
                                                       id,
                                                       show,
                                                       setShow
                                                   }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'copy-or-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.btn.copy-or")}>
            <CopyOr id={id} close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default CopyOrDialog;