import React from 'react';
import {IStopPoint} from "../../../../../../../app/interfaces/shared/IStopPoint";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getStopPointIcon, getStopPointTypeResource} from "../../../../../../../helpers/shipment";
import {useTranslation} from "react-i18next";
import cl from './LocationMapMarkerInfoWindow.module.css';
import moment from "moment/moment";

type LocationMapMarkerInfoWindowProps = {
    stopPoint: IStopPoint;
}

const LocationMapMarkerInfoWindow: React.FC<LocationMapMarkerInfoWindowProps> = ({
    stopPoint
                                                                                 }) => {
    const {t} = useTranslation();
    let icon = getStopPointIcon(stopPoint.type ?? '');

    return (
        <div>
            <div className={cl.label}>
                {icon && <FontAwesomeIcon icon={icon}
                                          className={cl.typeIcon}
                                          data-sp-type={stopPoint.type}/>
                }
                <label className={`view-label ${cl.label}`}>
                    {`${t(getStopPointTypeResource(stopPoint.type ?? ''))} ${stopPoint.info ?? ''} : ${stopPoint.date !== null && stopPoint.date !== undefined ? moment(stopPoint.date).format('DD.MM.YYYY HH:mm') : ''}`}
                </label>
            </div>
            <div className={cl.address}>
                {stopPoint.address}
            </div>
        </div>
    );
};

export default LocationMapMarkerInfoWindow;