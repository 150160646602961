import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'globe',
    pathData: [
        'M1.66699 10.0003H18.3337M1.66699 10.0003C1.66699 14.6027 5.39795 18.3337 10.0003 18.3337M1.66699 10.0003C1.66699 5.39795 5.39795 1.66699 10.0003 1.66699M18.3337 10.0003C18.3337 14.6027 14.6027 18.3337 10.0003 18.3337M18.3337 10.0003C18.3337 5.39795 14.6027 1.66699 10.0003 1.66699M10.0003 1.66699C12.0847 3.94895 13.2693 6.91035 13.3337 10.0003C13.2693 13.0903 12.0847 16.0517 10.0003 18.3337M10.0003 1.66699C7.91593 3.94895 6.73136 6.91035 6.66699 10.0003C6.73136 13.0903 7.91593 16.0517 10.0003 18.3337'
    ],
    viewBox: '0 0 20 20',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;