import {SendJsonMessage} from "react-use-websocket/dist/lib/types";
import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {selectCurrentUser} from "../../../../../features/authentication/authenticationSliceSelectors";
import {
    selectActiveChat, selectChatSessions,
    selectChatsList,
    selectIsChatDialogOpened
} from "../../../../../features/chat/chatSliceSelectors";
import useWebSocket, {ReadyState} from "react-use-websocket";
import {IChatSocketMessage} from "../../../../../app/types/ChatSocketMessage";
import {
    setChats,
    setChatSessions,
    setChatTotalUnreadMessaged,
    setIsChatSocketConnected
} from "../../../../../features/chat/chatSlice";
import {requestNewChatMessagesCommand} from "../commands/requestNewChatMessagesCommand";
import {notifyChatChangeStatusCommand} from "../commands/notifyChatChangeStatusCommand";
import {loginToChatCommand} from "../commands/loginToChatCommand";
import useChatApi from "../../../../../app/api/chat";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {useEffect} from "react";
import {IChatSocketCommand} from "../../../../../app/types/ChatSocketCommand";


type UseChatConnectionReturn = {
    sendSocketJsonMessage: SendJsonMessage;
}

const useChatConnection = (): UseChatConnectionReturn => {
    const dispatch = useAppDispatch();

    const user = useAppSelector(selectCurrentUser);
    const isChatOpened = useAppSelector(selectIsChatDialogOpened);

    const chats = useAppSelector(selectChatsList);
    const chat = useAppSelector(selectActiveChat);
    const sessions = useAppSelector(selectChatSessions);

    const {
        getChat: {
            query: getChat
        }
    } = useChatApi();

    const {sendJsonMessage, readyState} = useWebSocket(process.env.REACT_APP_CHAT ?? '', {
        reconnectAttempts: 10,
        shouldReconnect: () => true,
        reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
        onOpen: () => {
            dispatch(setIsChatSocketConnected(true));

            if (user) {
                sendJsonMessage(loginToChatCommand(user));
            }
        },
        onClose: () => {
            dispatch(setIsChatSocketConnected(false));
        },
        onError: () => {
            dispatch(setIsChatSocketConnected(false));
        },
        onMessage: (ev) => {
            if (readyState !== ReadyState.OPEN || !user) {
                return;
            }

            if (ev.data) {
                let message = JSON.parse(ev.data) as IChatSocketMessage;

                switch (message.cmd) {
                    case "auth":
                        sendJsonMessage(requestNewChatMessagesCommand(user));
                        sendJsonMessage(notifyChatChangeStatusCommand());
                        break;
                    case "status_all":
                        dispatch(setChatSessions(message.sessions));
                        break;
                    case "new":
                        dispatch(setChatTotalUnreadMessaged(message.countNew !== null && message.countNew !== ''
                            ? Number(message.countNew)
                            : 0));
                        break;
                    case "msg":
                        processMessageCommand(message);
                        break;
                    case "read":
                        document.dispatchEvent(new CustomEvent('onChatMessagesUpdate', {
                            detail: {
                                cmd: 'read'
                            }
                        }));
                        break;
                    case "status":
                        if (message.sessions.length > 0) {
                            dispatch(setChatSessions([...sessions.map(session => {
                                let newSession = message.sessions
                                    .find(e => e.userId === session.userId &&
                                        e.spId === session.spId && e.Gr === session.Gr);

                                if (newSession) {
                                    return {
                                        ...session,
                                        online: newSession.online
                                    };
                                }

                                return {
                                    ...session
                                };
                            })]));
                        }
                        break;
                }
            }
        },
    });

    useEffect(() => {
        let onChatMessageSent = (ev: any) => {
            ev = ev as CustomEvent<{ cmd: string, command: IChatSocketCommand }>;

            if (ev.detail["command"]) {
                sendJsonMessage(ev.detail["command"]);
            }
        };

        document.addEventListener('onChatMessageSent', onChatMessageSent);

        return () => {
            document.removeEventListener('onChatMessageSent', onChatMessageSent);
        }
    }, []);

    const processMessageCommand = (msg: IChatSocketMessage) => {
        if (!isChatOpened || msg.userId === null || msg.userId === '' ||
            msg.toUserId === null || msg.toUserId === '' || !user) {
            return;
        }

        if ((chat && chat.id.toLowerCase() === msg.userId.toString()) ||
            (chat && chat.id.toLowerCase() === msg.toUserId.toString())) {
            document.dispatchEvent(new CustomEvent('onChatMessagesUpdate', {
                detail: {
                    cmd: 'new'
                }
            }));
        }

        (async () => {
            try {
                // Message command to current User.
                if (msg.toUserId === (user.uniqueUserValue ?? user.idStr ?? '')) {
                    const response = await getChat(msg.userId ?? '');

                    if (response?.status === 200 &&
                        response.data.resultCode === ResponseResultCode.Ok &&
                        response.data.data) {
                        let incomeChat = response.data.data;
                        let existChat = chats.find(e => e.id === incomeChat.id);

                        let newPos = chats.length > 0
                            ? Math.max(...chats.map(c => c.position)) + 1
                            : 0;

                        if (existChat) {
                            dispatch(setChats([...chats.map(c => {
                                if (existChat && existChat.id === c.id) {
                                    return {
                                        ...c,
                                        count: chat && chat.id === c.id ? 0 : incomeChat.count,
                                        latestMessage: incomeChat.latestMessage,
                                        position: newPos,
                                        date: incomeChat.date
                                    };
                                }
                                return {
                                    ...c
                                };
                            })]));
                        } else {
                            dispatch(setChats([...chats, {
                                ...incomeChat,
                                position: newPos
                            }]));
                        }
                    }
                } else {
                    // Message command from current user.
                    const response = await getChat(msg.toUserId ?? '');

                    if (response?.status === 200 &&
                        response.data.resultCode === ResponseResultCode.Ok &&
                        response.data.data) {
                        let incomeChat = response.data.data;
                        let existChat = chats.find(e => e.id === incomeChat.id);

                        let newPos = chats.length > 0
                            ? Math.max(...chats.map(c => c.position)) + 1
                            : 0;

                        if (existChat) {
                            dispatch(setChats([...chats.map(chat => {
                                if (existChat && existChat.id === chat.id) {
                                    return {
                                        ...chat,
                                        count: incomeChat.count,
                                        latestMessage: incomeChat.latestMessage,
                                        position: newPos,
                                        date: incomeChat.date
                                    };
                                }
                                return {
                                    ...chat
                                };
                            })]));
                        }
                    }
                }
            } catch {
                // Ignore;
            }
        })();
    }

    return {
        sendSocketJsonMessage: sendJsonMessage
    };
};

export default useChatConnection;