import {useGetLayoutConfig, useSaveLayoutConfig} from "./requests";

export const useLayoutConfigApi = () => {
    const {
        isLoading: getLayoutConfigIsLoading,
        cancel: cancelGetLayoutConfig,
        getLayoutConfig
    } = useGetLayoutConfig();

    const {
        isLoading: saveLayoutConfigIsLoading,
        cancel: cancelSaveLayoutLoading,
        saveLayoutConfig
    } = useSaveLayoutConfig();

    return {
        getLayoutConfig: {
            query: getLayoutConfig,
            isLoading: getLayoutConfigIsLoading,
            cancel: cancelGetLayoutConfig
        },
        saveLayoutConfig: {
            mutation: saveLayoutConfig,
            isLoading: saveLayoutConfigIsLoading,
            cancel: cancelSaveLayoutLoading
        }
    };
};
