import React from 'react';
import {IChatMessage} from "../../../../../../../../../app/interfaces/chat/IChatMessage";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCheckDouble, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import HighLighter from "../../../../../../../../ui/HighLighter/HighLighter";
import {getLinkedEntityIcon, getLinkedEntityLink} from "../../../../../utils";

type ChatDialogueMessageProps = {
    id: string;
    message: IChatMessage;
    direction: 'from' | 'to';
    offset: number;
    highlight?: string;
};

const ChatDialogueMessage: React.FC<ChatDialogueMessageProps> = ({id, highlight, direction, message}) => {
    if (message.linkedType !== null && message.linkedType !== '' && message.linkedId !== null && message.linkedId !== '') {
        let icon: IconDefinition | null = getLinkedEntityIcon({
            id: message.linkedId,
            idExt: message.linkedIdExt,
            type: message.linkedType as any,
            description: message.linkedDesc
        });
        let link: string | null = getLinkedEntityLink({
            id: message.linkedId,
            idExt: message.linkedIdExt,
            type: message.linkedType as any,
            description: message.linkedDesc
        });

        return (
            <div className={'reply-to-chat-dialogue-message'} id={`message-${id}`}>
                <div className={'chat-dialogue-message-text-container relative'} data-direction={direction}>
                    {icon &&
                        <>
                            <div className={'chat-dialogue-message-reply-to-container'}>
                                <div className={'chat-dialogue-reply-to-entity'}
                                     onClick={() => {
                                         if (link === null) {
                                             return;
                                         }
                                         debugger;
                                         window.open(link, '_blank', 'rel=noopener noreferrer');
                                     }}>
                                    <FontAwesomeIcon icon={icon}/>
                                </div>
                                <div onClick={() => {
                                    if (link === null) {
                                        return;
                                    }

                                    window.open(link, '_blank', 'rel=noopener noreferrer');
                                }}>
                                    {message.linkedId}
                                </div>
                            </div>
                            {message.linkedDesc !== null && message.linkedDesc !== '' &&
                                <div className={'chat-dialogue-message-description'}>
                                    {message.linkedDesc}
                                </div>
                            }
                        </>
                    }

                    <div className={'chat-dialogue-message-card'} data-direction={direction}>
                        <HighLighter text={message.text} highlight={highlight} color={'#E96024'}/>
                    </div>

                    {direction === 'from'
                        ? <FontAwesomeIcon
                            className={message.isRead ? 'chat-dialogue-message-read' : 'chat-dialogue-message-not-read'}
                            icon={message.isRead ? faCheckDouble : faCheck}/>
                        : null
                    }
                </div>
                <div className={'chat-dialogue-message-date-time-container'}>
                    {moment.utc(message.date).local().format('HH:mm')}
                </div>
            </div>
        );
    }

    return (
        <div className={'chat-dialogue-message'} id={`message-${id}`}>
            <div className={'chat-dialogue-message-text-container relative'} data-direction={direction}>
                <div className={'chat-dialogue-message-card'} data-direction={direction}>
                    <HighLighter text={message.text} highlight={highlight} color={'#E96024'}/>
                </div>

                {direction === 'from'
                    ? <FontAwesomeIcon
                        className={message.isRead ? 'chat-dialogue-message-read' : 'chat-dialogue-message-not-read'}
                        icon={message.isRead ? faCheckDouble : faCheck}/>
                    : null
                }
            </div>
            <div className={'chat-dialogue-message-date-time-container'}>
                {moment.utc(message.date).local().format('HH:mm')}
            </div>
        </div>
    );
};

export default ChatDialogueMessage;
