import React from 'react';
import {ISelfBillingEditGetAction} from "../../../../../../app/interfaces/selfBilling/ISelfBillingEditGetAction";
import {ISelfBillingEditPostAction} from "../../../../../../app/interfaces/selfBilling/ISelfBillingEditPostAction";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import cl from './EditBillingDataForm.module.css';
import moment from "moment";
import Input from "../../../../../../components/ui/Input/Input";
import trailerIcon from "../../../../../../assets/images/trailer.png";
import truckIcon from "../../../../../../assets/images/small-truck.png";
import {faBuilding} from "@fortawesome/free-solid-svg-icons";
import ReactDatePicker from "../../../../../../components/ui/ReactDatePicker/ReactDatePicker/ReactDatePicker";

type EditBillingDataFormProps = {
    disabled: boolean;
    model: ISelfBillingEditGetAction;
    onSubmit: (values: ISelfBillingEditPostAction) => Promise<void>;
};

const EditBillingDataForm: React.FC<EditBillingDataFormProps> = ({
                                                                     model,
                                                                     onSubmit,
                                                                     disabled
                                                                 }) => {
    const {t} = useTranslation();

    const form = useFormik<ISelfBillingEditPostAction>({
        initialValues: {
            shipmentGid: model.shipmentGid,
            truck: model.truck,
            trailer: model.trailer,
            supplier: model.supplier,
            deliveryDate: model.deliveryDate,
            pickupDate: model.pickupDate
        },
        validationSchema: Yup.object().shape({
            pickupDate: Yup.string().nullable()
                .required(t("shared.errors.required-error").replace('{0}', t("shipment.form.early-pickup-date"))),
            deliveryDate: Yup.string().nullable()
                .required(t("shared.errors.required-error").replace('{0}', t("shipment.form.late-delivery-date"))),
            truck: Yup.string().nullable()
                .required(t("shared.errors.required-error").replace('{0}', t("shipment.form.truck"))),
            trailer: Yup.string().nullable()
                .required(t("shared.errors.required-error").replace('{0}', t("shipment.form.trailer"))),
            supplier: Yup.string().nullable()
                .required(t("shared.errors.required-error").replace('{0}', t("shipment.form.supplier"))),
        }),
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values, actions) => {
            actions.setSubmitting(true);

            await onSubmit(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id={'edit-billing-data-form'}
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div className={`mgb-10 picker ${cl.row}`}>
                    <div>
                        <label className={'view-label required'}>
                            {t("shipment.form.early-pickup-date")}
                        </label>

                        <ReactDatePicker disabled={disabled}
                                         size={'big'}
                                         value={form.values.pickupDate !== null && form.values.pickupDate !== ''
                                             ? moment(form.values.pickupDate, 'DD.MM.YYYY').toDate()
                                             : null}
                                         onChange={(val) => {
                                             form.setFieldValue('pickupDate', val
                                                 ? moment(val).format('DD.MM.YYYY')
                                                 : null);
                                         }}
                                         placeholder={t("shared.labels.date")}
                        />

                        {/*<DatePicker format={'dd.MM.yyyy'}
                                    dateInput={DateInput}
                                    disabled={disabled}
                                    formatPlaceholder={{
                                        year: "yyyy",
                                        month: "MM",
                                        day: "dd",
                                    }}
                                    value={form.values.pickupDate !== null && form.values.pickupDate !== ''
                                        ? moment(form.values.pickupDate, 'DD.MM.YYYY').toDate()
                                        : null}
                                    onChange={(ev) => {
                                        form.setFieldValue('pickupDate', ev.value
                                            ? moment(ev.value).format('DD.MM.YYYY')
                                            : null);
                                    }}
                                    popupSettings={{animate: false}}
                                    rounded={'small'}
                                    fillMode={'outline'}
                                    placeholder={t("shared.labels.date")}
                                    adaptive={true}/>*/}

                        {form.errors.pickupDate &&
                            <div className={'error-label'}>
                                {form.errors.pickupDate}
                            </div>
                        }
                    </div>
                    <div>
                        <label className={'view-label required'}>
                            {t("shipment.form.late-delivery-date")}
                        </label>

                        <ReactDatePicker disabled={disabled}
                                         size={'big'}
                                         value={form.values.deliveryDate !== null && form.values.deliveryDate !== ''
                                             ? moment(form.values.deliveryDate, 'DD.MM.YYYY').toDate()
                                             : null}
                                         onChange={(val) => {
                                             form.setFieldValue('deliveryDate', val
                                                 ? moment(val).format('DD.MM.YYYY')
                                                 : null);
                                         }}
                                         placeholder={t("shared.labels.date")}
                        />

                        {/*<DatePicker format={'dd.MM.yyyy'}
                                    dateInput={DateInput}
                                    disabled={disabled}
                                    formatPlaceholder={{
                                        year: "yyyy",
                                        month: "MM",
                                        day: "dd",
                                    }}
                                    value={form.values.deliveryDate !== null && form.values.deliveryDate !== ''
                                        ? moment(form.values.deliveryDate, 'DD.MM.YYYY').toDate()
                                        : null}
                                    onChange={(ev) => {
                                        form.setFieldValue('deliveryDate', ev.value
                                            ? moment(ev.value).format('DD.MM.YYYY')
                                            : null);
                                    }}
                                    popupSettings={{animate: false}}
                                    rounded={'small'}
                                    fillMode={'outline'}
                                    placeholder={t("shared.labels.date")}
                                    adaptive={true}/>*/}

                        {form.errors.deliveryDate &&
                            <div className={'error-label'}>
                                {form.errors.deliveryDate}
                            </div>
                        }
                    </div>
                </div>

                <div className={`mgb-10 ${cl.row}`}>
                    <div>
                        <label className={'view-label required'}>
                            {t("shipment.form.truck")}
                        </label>

                        <Input placeholder={t("shipment.form.truck")}
                               id={'edit-billing-data-truck'}
                               name={'truck'}
                               disabled={form.isSubmitting}
                               value={form.values.truck ?? ''}
                               autoComplete={'off'}
                               onChange={form.handleChange}
                               image={truckIcon}/>

                        {form.errors.truck &&
                            <div className={'error-label'}>
                                {form.errors.truck}
                            </div>
                        }
                    </div>
                    <div>
                        <label className={'view-label required'}>
                            {t("shipment.form.trailer")}
                        </label>

                        <Input placeholder={t("shipment.form.trailer")}
                               id={'edit-billing-data-trailer'}
                               name={'trailer'}
                               disabled={form.isSubmitting}
                               value={form.values.trailer ?? ''}
                               autoComplete={'off'}
                               onChange={form.handleChange}
                               image={trailerIcon}/>

                        {form.errors.trailer &&
                            <div className={'error-label'}>
                                {form.errors.trailer}
                            </div>
                        }
                    </div>
                </div>

                <div className={`mgb-10 ${cl.row}`}>
                    <div>
                        <label className={'view-label required'}>
                            {t("shipment.form.supplier")}
                        </label>

                        <Input placeholder={t("shipment.form.supplier")}
                               id={'edit-billing-data-supplier'}
                               name={'supplier'}
                               disabled={form.isSubmitting}
                               value={form.values.supplier ?? ''}
                               autoComplete={'off'}
                               onChange={form.handleChange}
                               icon={faBuilding}/>

                        {form.errors.supplier &&
                            <div className={'error-label'}>
                                {form.errors.supplier}
                            </div>
                        }
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </form>
    );
};

export default EditBillingDataForm;