import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import SidebarNavigationButton from "../../../components/SidebarNavigationButton/SidebarNavigationButton";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import SidebarNavigationNestedButton
    from "../../../components/SidebarNavigationButton/SidebarNavigationNestedButton/SidebarNavigationNestedButton";
import {SidebarContext} from "../../../Sidebar";
import {isMobile} from "react-device-detect";

type SidebarMenuOfferProps = {
    expandedId: string;
    onClick?: (id: string) => void;
};

const SidebarMenuOffer: React.FC<SidebarMenuOfferProps> = ({
                                                               expandedId,
                                                               onClick: onClickProp,
                                                           }) => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    const {
        expanded,
        hoverExpanded,
        setIsExpandedOnHover
    } = useContext(SidebarContext);

    const handleNavigationClick = (id: string) => {
        if (onClickProp) {
            onClickProp(id);
        }
    };

    const handleNestedNavigationClick = () => {
        if (isMobile && !expanded && hoverExpanded) {
            setIsExpandedOnHover(false);
        }
    }

    if (!user) {
        return null;
    }

    let role = getUserRole(user);

    if (role !== UserRole.Administrator && role !== UserRole.Forwarder && role !== UserRole.ServiceProvider) {
        return null;
    }

    return (
        <SidebarNavigationButton id={'sidebar-offer-item'} expandedId={expandedId}
                                 type={'primary'} onClick={handleNavigationClick}
                                 text={t("sidebar.offer.title")}
                                 title={t("sidebar.offer.title")}
                                 path={'/offers'}
                                 icon={"faCubePlus"}>
            {role === UserRole.ServiceProvider &&
                <>
                    <SidebarNavigationNestedButton id={'sidebar-offer-item-offer'}
                                                   type={'primary'}
                                                   path={'/offers/offer'}
                                                   text={t("sidebar.offer.offer")}
                                                   title={t("sidebar.offer.offer")}
                                                   onClick={handleNestedNavigationClick}
                    />

                    {user.serviceProvider && !user.serviceProvider.hideOffers &&
                        <SidebarNavigationNestedButton id={'sidebar-offer-item-my-bet'}
                                                       type={'primary'}
                                                       path={'/offers/bet'}
                                                       text={t("sidebar.offer.my-bet")}
                                                       title={t("sidebar.offer.my-bet")}
                                                       onClick={handleNestedNavigationClick}
                        />
                    }
                </>
            }

            {role === UserRole.Forwarder || role === UserRole.Administrator
                ? <>
                    <SidebarNavigationNestedButton id={'sidebar-offer-item-all'}
                                                   type={'primary'}
                                                   path={'/offers/all'}
                                                   text={t("sidebar.offer.all")}
                                                   title={t("sidebar.offer.all")}
                                                   onClick={handleNestedNavigationClick}
                    />

                    <SidebarNavigationNestedButton id={'sidebar-offer-item-exchange'}
                                                   type={'primary'}
                                                   path={'/offers/exchange'}
                                                   text={t("sidebar.offer.exchange") || ''}
                                                   title={t("sidebar.offer.exchange") || ''}
                                                   tooltip={t("sidebar.offer.exchange-tooltip") || ''}
                                                   onClick={handleNestedNavigationClick}
                    />

                    <SidebarNavigationNestedButton id={'sidebar-offer-item-new'}
                                                   type={'primary'}
                                                   path={'/offers/new'}
                                                   text={t("sidebar.offer.new")}
                                                   title={t("sidebar.offer.new")}
                                                   tooltip={t("sidebar.offer.new-tooltip") || ''}
                                                   onClick={handleNestedNavigationClick}
                    />

                    <SidebarNavigationNestedButton id={'sidebar-offer-item-sent'}
                                                   type={'primary'}
                                                   path={'/offers/sent'}
                                                   text={t("sidebar.offer.sent")}
                                                   title={t("sidebar.offer.sent")}
                                                   tooltip={t("sidebar.offer.sent-tooltip") || ''}
                                                   onClick={handleNestedNavigationClick}
                    />

                    <SidebarNavigationNestedButton id={'sidebar-offer-item-bet'}
                                                   type={'primary'}
                                                   path={'/offers/bet'}
                                                   text={t("sidebar.offer.bet")}
                                                   title={t("sidebar.offer.bet")}
                                                   tooltip={t("sidebar.offer.bet-tooltip") || ''}
                                                   onClick={handleNestedNavigationClick}
                    />
                </>
                : null
            }
        </SidebarNavigationButton>
    );
};

export default SidebarMenuOffer;
