import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import {ISupportGetAction} from "../../../../../app/interfaces/profile/ISupportGetAction";
import {useProfileApi} from "../../../../../app/api/profile";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {ISupportPostAction} from "../../../../../app/interfaces/profile/ISupportPostAction";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import ProfileSupportForm from "../ProfileSupportForm/ProfileSupportForm";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";

type ProfileSupportDialogProps = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfileSupportDialog: React.FC<ProfileSupportDialogProps> = ({
                                                                       show,
                                                                       setShow
                                                                   }) => {
    const {t} = useTranslation();

    const [model, setModel] = useState<ISupportGetAction | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        getContactSupportData: {
            cancel: cancelGetContactSupportData,
            query: getContactSupportData,
            isLoading
        },
        contactSupport: {
            cancel: cancelContactSupport,
            mutation: contactSupport
        }
    } = useProfileApi();

    useEffect(() => {
        (async () => {
            await load();
        })();

        return () => {
            cancelGetContactSupportData();
            cancelContactSupport();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const load = async () => {
        try {
            const response = await getContactSupportData();
            if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                setModel(response.data.data);
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error<string>('Unable to get contact support data');
            }
        }
    }

    const save = async (model: ISupportPostAction): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            const response = await contactSupport(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                toast.success(t("profile.support.sent-label"));

                setModel(null);

                await load();
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(e.message);
            }
        }

        setDisabled(prev => !prev);
    }

    return (
        <Dialog id={'support-form-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("profile.support.support-label")}>
            <div>
                <div className={'dialog-default-content-container'}>
                    {isLoading || model === null
                        ? <FormLoadingScreen height={`340px`} style={{padding: '5px'}}/>
                        : null
                    }

                    {!isLoading && model &&
                        <ProfileSupportForm disabled={disabled}
                                            model={model}
                                            save={save}/>
                    }
                </div>
                <div className={'dialog-default-buttons-container'}>
                    <Button buttonType={ButtonType.Primary}
                            disabled={disabled}
                            form='contact-support-form'
                            type='submit'
                            style={{minWidth: '150px'}}
                            className={'confirm-button'}
                            isLoading={disabled}>
                        {t("shared.btn.confirm")}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default ProfileSupportDialog;
