import React from 'react';
import {useAppSelector} from "../../../../../app/store";
import {selectCurrentUser} from "../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../helpers/user";
import {UserRole} from "../../../../../app/enums/UserRole";
import ConfirmAuctionForwarder from "./Forwarder/ConfirmAuctionForwarder";
import ConfirmAuctionServiceProvider from "./ServiceProvider/ConfirmAuctionServiceProvider";

type ConfirmAuctionProps = {
    id: string | Array<string>;
    close?: () => void;
};

const ConfirmAuction: React.FC<ConfirmAuctionProps> = ({
                                                           id,
                                                           close
                                                       }) => {
    const user = useAppSelector(selectCurrentUser);

    let role = user ? getUserRole(user) : null;

    return (
        <>
            {role !== null && (role === UserRole.Administrator || role === UserRole.Forwarder)
                ? <ConfirmAuctionForwarder id={Array.isArray(id) ? id[0] : id} close={close}/>
                : null
            }

            {role === null || role === UserRole.ServiceProvider
                ? <ConfirmAuctionServiceProvider id={id} close={close}/>
                : null
            }
        </>
    );
};

export default ConfirmAuction;