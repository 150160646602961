import React from 'react';
import {IOffer} from "../../../../../../../../app/interfaces/offer/IOffer";
import {formatCurrencyNumber} from "../../../../../../../../helpers/currency";

type OfferInitialBetProps = {
    offer: IOffer;
};

const OfferInitialBet: React.FC<OfferInitialBetProps> = ({
                                                             offer
                                                         }) => {
    if (offer.initialBet === null || offer.initialBet <= 0) {
        return (
            <div className={'details-box-item-field-value'}>

            </div>
        );
    }

    return (
        <div className={'details-box-item-field-value'}>
            {`${formatCurrencyNumber(offer.initialBet, offer.initialBetCurrency ?? 'EUR')}`}
        </div>
    );
};

export default OfferInitialBet;
