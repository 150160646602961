import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useShipmentApi} from "../../../../../app/api/shipment";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {isAxiosError} from "axios";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {ICancelShipmentGetAction} from "../../../../../app/interfaces/shipment/ICancelShipmentGetAction";
import {ICancelShipmentPostAction} from "../../../../../app/interfaces/shipment/ICancelShipmentPostAction";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import CancelShipmentForm from "./CancelShipmentForm/CancelShipmentForm";
import {BLErrorCode} from '../../../../../app/enums/BLErrorCode';
import Alert from "../../../../../components/ui/Alert/Alert";

type CancelShipmentProps = {
    id: string | Array<string>;
    close?: () => void;
}

const alertStyle: React.CSSProperties = {
    fontSize: '13px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '10px 5px'
}

const CancelShipment: React.FC<CancelShipmentProps> = ({
                                                           id,
                                                           close
                                                       }) => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [model, setModel] = useState<ICancelShipmentGetAction | null>(null);
    const [errors, setErrors] = useState<Array<IError>>([]);

    const {
        getCancelShipmentData: {
            query: getCancelShipmentData,
            isLoading,
            cancel: cancelGetCancelShipmentData
        },
        cancelShipment: {
            mutation: cancelShipment,
            cancel: cancelCancelShipment
        }
    } = useShipmentApi();

    useEffect(() => {
        if ((Array.isArray(id) && id.length <= 0) || id === '') {
            if (close) {
                close()
            } else {
                return;
            }
        }

        (async () => {
            try {
                const response = await getCancelShipmentData(id);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch (err) {
                if (isAxiosError(err)) {
                    if (err.response && err.response.data && err.response.status === 422 &&
                        err.response.data.resultCode === ResponseResultCode.NotValidData &&
                        err.response.data.errors && Array.isArray(err.response.data.errors)) {
                        setModel(err.response.data.data);
                        setErrors(err.response.data.errors);
                    } else if (close) {
                        close();
                        toast.error(err.message);
                    }
                }
            }
        })();

        return () => {
            cancelGetCancelShipmentData();
            cancelCancelShipment();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const save = async (model: ICancelShipmentPostAction): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            const response = await cancelShipment(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                let shipmentIds: Array<string> = [];

                model.orderReleaseGid.split(';').forEach(item => {
                    //let val = item.split(',');

                    //if (val.length === 2) {
                        shipmentIds.push(item);
                    //}
                });

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [
                            ...shipmentIds.map(item => {
                                return {
                                    id: item,
                                    isRowLoading: true
                                };
                            })
                        ]
                    }
                }));

               // if (shipmentIds.length > 0) {
                    toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                               title={`${t("shared.labels.shipment")} ${shipmentIds[0]}`}/>);
              //  }
            }
        } catch (err) {
            if (isAxiosError(err) && close) {
                close();
                toast.error(err.message);
            }
        }

        setDisabled(prev => !prev);
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                {!model.notValid &&
                    <CancelShipmentForm model={model}
                                        disabled={disabled}
                                        save={save}/>
                }

                {model.notValid && errors.length > 0
                    ? errors.map((err, index) => {
                        switch (err.errorCode) {
                            case BLErrorCode.OrderHasRelatedInvoices:
                                return (
                                    <Alert type={'Error'}
                                           id={`cancel-shipment-order-has-related-invoices-error-${index}`}
                                           style={alertStyle}
                                           key={`${err.errorCode}-${err.description}`}>
                                        {`${err.description} ${t("shipment.errors.cancel-shipment-order-has-related-invoices")}`}
                                    </Alert>
                                );
                            case BLErrorCode.OrderHasRelatedInsuranceOperations:
                                return (
                                    <Alert type={'Error'}
                                           id={`cancel-shipment-order-has-related-insurance-operations-error-${index}`}
                                           style={alertStyle}
                                           key={`${err.errorCode}-${err.description}`}>
                                        {`${err.description} ${t("shipment.errors.cancel-shipment-order-has-related-insurance-operations")}`}
                                    </Alert>
                                );
                            case BLErrorCode.ProfitTransferCreated:
                                return (
                                    <Alert type={'Error'}
                                           style={alertStyle}
                                           id={`cancel-shipment-profit-transfer-created-error-${index}`}
                                           key={`${err.errorCode}-${err.description}`}>
                                        {`${err.description} ${t("shipment.errors.cancel-shipment-profit-transfer-created")}`}
                                    </Alert>
                                );
                        }

                        return null;
                    })
                    : null
                }
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                {!model.notValid &&
                    <Button buttonType={ButtonType.Primary}
                            disabled={disabled}
                            form='cancel-shipment-form'
                            type='submit'
                            style={{minWidth: '150px'}}
                            className={'confirm-button'}
                            isLoading={disabled}>
                        {t("shared.btn.confirm")}
                    </Button>
                }
            </div>
        </div>
    );
};

export default CancelShipment;
