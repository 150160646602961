import React from 'react';
import {useTranslation} from "react-i18next";
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";

const RefrigeratorTemplate = (props: any) => {
    const {t} = useTranslation();
    const {handleClick} = useGridCellHandleClick(props.dataItem);

    let val = props.dataItem[props.field];

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
            <span className={'default-center-cell'}>
                {val !== null && val !== undefined && val === true
                    ? <span>
                        {t("shared.btn.yes")}
                    </span>
                    : null
                }
            </span>
        </td>
    );
};

export default RefrigeratorTemplate;