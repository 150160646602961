import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import SendContract from "./SendContract";
import {IUser} from "../../../../../app/interfaces/user/IUser";

type SendContractDialogProps = {
    id: string | Array<string>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    user?: IUser;
};

const SendContractDialog: React.FC<SendContractDialogProps> = ({
                                                                   id,
                                                                   show,
                                                                   setShow,
                                                                   user
                                                               }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'send-contract-dialog'}
                show={show}
                setShow={setShow}
                width={'1400px'}
                title={t("shared.btn.send-offer-contract")}>
            <SendContract id={id}
                          user={user}
                          close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default SendContractDialog;