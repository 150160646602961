import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import SendTranspareonAccountRequest from "./SendTranspareonAccountRequest";

type SendTranspareonAccountRequestDialogProps = {
    gid: string;
    asstraCompany: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const SendTranspareonAccountRequestDialog: React.FC<SendTranspareonAccountRequestDialogProps> = ({
                                                                                                     gid,
                                                                                                     asstraCompany,
                                                                                                     show,
                                                                                                     setShow
                                                                                                 }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'send-request-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("quote.bet.send-help-desk-request")}>
            <SendTranspareonAccountRequest gid={gid}
                                           asstraCompany={asstraCompany}
                                           close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default SendTranspareonAccountRequestDialog;