import React from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import cmrIcon from "../../../../../../assets/images/cmr.png";
import invoiceIcon from "../../../../../../assets/images/invoice.png";
import cmrBlueIcon from "../../../../../../assets/images/cmr_blue.png";
import cmrGreenIcon from "../../../../../../assets/images/cmr_green.png";
import cmrOrangeIcon from "../../../../../../assets/images/cmr_orange.png";
import cmrRedIcon from "../../../../../../assets/images/cmr_red.png";
import {useTranslation} from "react-i18next";

const CmrTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const {t} = useTranslation();

    let hasInvoiceIcon: any | null;
    let icon: any | null;
    let iconIn: any | null;
    let text: string = '';
    let textIn: string = '';
    let cmr = props.dataItem.cmr !== null && props.dataItem.cmr !== undefined
        ? props.dataItem.cmr
        : '';
    let inCmr = props.dataItem.inCmr !== null && props.dataItem.inCmr !== undefined
        ? Boolean(props.dataItem.inCmr)
        : false;
    let isCmrAllowed = props.dataItem.isCmrAllowed !== null && props.dataItem.isCmrAllowed !== undefined
        ? Boolean(props.dataItem.isCmrAllowed)
        : false;
    let hasInvoice = props.dataItem.hasInvoice !== null && props.dataItem.hasInvoice !== undefined
        ? Boolean(props.dataItem.hasInvoice)
        : false;

    hasInvoiceIcon = invoiceIcon;

    if (isCmrAllowed && !cmr && !hasInvoice) {
        icon = cmrIcon
        text = t('self-billing.icon.allowed');
    } else {
        if (cmr) {
            switch (cmr){
                case "Completed":
                    icon = cmrGreenIcon;
                    text = t('self-billing.ecm-status.completed');
                    break;
                case "Sent":
                case "Delivered":
                case "Check":
                    icon = cmrOrangeIcon;
                    text = t('self-billing.ecm-status.delivered');
                    break;
                case "Incorrect":
                case "NotFound":
                case "Draft":
                    icon = cmrRedIcon;
                    text = t('self-billing.ecm-status.incorrect');
                    break;
            }

        }
    }
    if (inCmr) {
        iconIn = cmrBlueIcon
        textIn = t('self-billing.ecm-status.income');

    }

    return (
        <td {...props.tdProps} onClick={handleClick}
            colSpan={1}>
            <span  className={'default-center-cell'}>
                {hasInvoice && <img title={t('self-billing.ecm-status.hasinvoice')} src={hasInvoiceIcon} alt={t('self-billing.ecm-status.hasinvoice')}/>}
                {icon !== null && <img title={text} src={icon} alt={props.children}/>}
                {inCmr && <img title={textIn} src={iconIn} alt={props.children}/>}
            </span>
        </td>
    );
};

export default CmrTemplate;
