import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import CustomsEta from "./CustomsEta";

type CustomsEtaDialogProps = {
    id: string;
    sId: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomsEtaDialog: React.FC<CustomsEtaDialogProps> = ({
                                                               id,
                                                               show,
                                                               setShow,
                                                               sId
                                                           }) => {
    const {t} = useTranslation();
    return (
        <Dialog id={'customs-eta-add-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shipment.grid.eta-label")}>
            <CustomsEta id={id}
                        sId={sId}
                        close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default CustomsEtaDialog;