import useChatConnection from "./useChatConnection";
import useMediaQuery from "../../../../../hooks/useMediaQuery/useMediaQuery";
import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {
    selectActiveChat,
    selectActiveChatMessages,
    selectActiveChatMessagesCount,
    selectActiveChatMessagesIsLoading,
    selectChatIsChatsLoading,
    selectChatNewMessage,
    selectChatSearchActiveMessage,
    selectChatSearchMessages, selectChatSearchString,
    selectChatsList,
    selectIsChatDialogOpened,
    selectIsChatFullScreen,
    selectIsMessagesSearchSectionShown,
    selectIsUserInformationSectionShown,
} from "../../../../../features/chat/chatSliceSelectors";
import {useCallback, useEffect, useState} from "react";
import {
    setIsChatDialogOpened,
    setIsChatFullScreenMode,
    getChats as getChatsThunk,
    getChatMessages as getChatMessagesThunk,
    searchChatMessages as searchChatMessagesThunk,
    setChat as setChatAction,
    setIsUserInformationSectionShown as setIsUserInformationSectionShownAction,
    setIsMessagesSearchSectionShown as setIsMessagesSearchSectionShownAction,
    setActiveChatSearchMessage as setActiveChatSearchMessageAction,
    setActiveChatSearchString as setActiveChatSearchStringAction,
    setChats,
    setMessage,
    resetChatSlice
} from "../../../../../features/chat/chatSlice";
import {useChatSetup} from "./useChatSetup";
import {IChat} from "../../../../../app/interfaces/chat/IChat";
import {selectCurrentUser} from "../../../../../features/authentication/authenticationSliceSelectors";
import {hideChatCommand} from "../commands/hideChatCommand";
import {sendChatMessageCommand} from "../commands/sendChatMessageCommand";
import {IChatMessage} from "../../../../../app/interfaces/chat/IChatMessage";
import {readMessagesCommand} from "../commands/readMessagesCommand";
import moment from "moment";

type UseChatReturn = {
    isMobileView: boolean;
    isChatOpened: boolean;
    isFullScreenMode: boolean;
    isChatsLoading: boolean;
    isMessagesLoading: boolean;
    isUserInformationSectionShown: boolean
    isMessagesSearchSectionShown: boolean;
    disabled: boolean;

    chats: Array<IChat>;
    chat: IChat | null;
    message: string;
    messages: Array<IChatMessage>;
    searchMessages: Array<IChatMessage>;
    messagesCount: number;
    activeSearchMessage: IChatMessage | null;
    activeChatSearchString: string;

    setIsChatDialogOpen: (val: boolean) => void;
    setIsFullScreenMode: (val: boolean) => void;
    setIsUserInformationSectionShown: (val: boolean) => void;
    setIsMessagesSearchSectionShown: (val: boolean) => void;
    setActiveChatSearchMessage: (val: IChatMessage | null) => void;
    setActiveChatSearchString: (val: string) => void;
    getChats: (val: string | null) => void;
    updateChats: (chats: Array<IChat>) => void;
    getChatMessages: (search: string | null, skip: number, take: number, start?: string) => void;
    searchChatMessages: (search: string) => void;
    setChat: (chat: IChat | null) => void;
    hideChat: (chat: IChat) => void;
    onMessageChange: (msg: string) => void;
    sendMessage: (msg: string, chat: IChat) => void;
    sendReadCommand: (toUserId: string) => void;
}

const useChat = (): UseChatReturn => {
    // Call only once !
    const {sendSocketJsonMessage} = useChatConnection();
    useChatSetup();

    const dispatch = useAppDispatch();
    const [disabled] = useState<boolean>(false);

    const isMobileView = useMediaQuery('(max-width:950px)');
    const user = useAppSelector(selectCurrentUser);
    const isChatOpened = useAppSelector(selectIsChatDialogOpened);
    const isFullScreenMode = useAppSelector(selectIsChatFullScreen);
    const isChatsLoading = useAppSelector(selectChatIsChatsLoading);
    const isMessagesLoading = useAppSelector(selectActiveChatMessagesIsLoading)
    const isUserInformationSectionShown = useAppSelector(selectIsUserInformationSectionShown);
    const isMessagesSearchSectionShown = useAppSelector(selectIsMessagesSearchSectionShown);
    const chats = useAppSelector(selectChatsList);
    const chat = useAppSelector(selectActiveChat);
    const message = useAppSelector(selectChatNewMessage);
    const messages = useAppSelector(selectActiveChatMessages);
    const searchMessages = useAppSelector(selectChatSearchMessages);
    const activeSearchMessage = useAppSelector(selectChatSearchActiveMessage);
    const messagesCount = useAppSelector(selectActiveChatMessagesCount);
    const activeChatSearchString = useAppSelector(selectChatSearchString);

    useEffect(() => {
        // Clear whole chat, when component unmounted.
        return () => {
            dispatch(resetChatSlice());
        };
    }, []);

    useEffect(() => {
        if (!chat || chats.length <= 0) {
            return;
        }

        let isChatExist = chats.find(e => e.id === chat.id);

        if (!isChatExist) {
            let newPosition = Math.max(...chats.map(item => item.position)) + 1;

            dispatch(setChats([...chats.map(ch => {
                return {
                    ...ch,
                    replyTo: undefined
                }
            }), {
                ...chat,
                date: moment().utc().toDate(),
                position: newPosition
            }]));
        }
    }, [chat, chats]);

    const setIsChatDialogOpen = useCallback((val: boolean): void => {
        dispatch(setIsChatDialogOpened(val));
    }, [dispatch]);

    const setIsFullScreenMode = useCallback((val: boolean): void => {
        dispatch(setIsChatFullScreenMode(val));
    }, [dispatch]);

    const setIsUserInformationSectionShown = useCallback((val: boolean): void => {
        dispatch(setIsUserInformationSectionShownAction(val));
    }, [dispatch]);

    const setIsMessagesSearchSectionShown = useCallback((val: boolean): void => {
        dispatch(setIsMessagesSearchSectionShownAction(val));
    }, [dispatch]);

    const getChats = useCallback((val: string | null) => {
        dispatch(getChatsThunk(val));
    }, [dispatch]);

    const getChatMessages = useCallback((search: string | null, skip: number, take: number, start?: string) => {
        if (!chat) {
            return;
        }

        dispatch(getChatMessagesThunk({
            user: chat.id,
            search,
            skip,
            take,
            start
        }));
    }, [dispatch, chat]);

    const searchChatMessages = useCallback((search: string) => {
        if (!chat) {
            return;
        }

        dispatch(searchChatMessagesThunk({
            user: chat.id,
            search
        }));
    }, [dispatch, chat]);

    const setChat = useCallback((chat: IChat | null) => {
        dispatch(setChatAction(chat));

        if (chat && chat.count > 0) {
            dispatch(setChats([...chats.map(c => {
                if (c.id === chat.id) {
                    return {
                        ...c,
                        count: 0
                    };
                }
                return {
                    ...c
                };
            })]));
        }
    }, [dispatch, chats]);

    const updateChats = useCallback((chats: Array<IChat>) => {
        dispatch(setChats([...chats]));
    }, [dispatch]);

    const onMessageChange = useCallback((msg: string) => {
        dispatch(setMessage(msg));
    }, []);

    const setActiveChatSearchMessage = useCallback((val: IChatMessage | null) => {
        dispatch(setActiveChatSearchMessageAction(val));
    }, []);

    const setActiveChatSearchString = useCallback((val: string) => {
        dispatch(setActiveChatSearchStringAction(val));
    }, []);

    const sendMessage = useCallback((msg: string, chat: IChat) => {
        if (msg === undefined || msg === null || msg === '' || msg.trim().length === 0 || !user) {
            return;
        }

        onMessageChange('');

        sendSocketJsonMessage(sendChatMessageCommand(user, chat, msg));
    }, [user]);

    const sendReadCommand = useCallback((toUserId: string) => {
        if (!user) {
            return;
        }

        sendSocketJsonMessage(readMessagesCommand(user, toUserId));
    }, [user]);

    const hideChat = useCallback((chat: IChat) => {
        if (!user) {
            return;
        }

        setIsUserInformationSectionShown(false);
        setChat(null);
        dispatch(setChats([...chats.filter(e => e.id !== chat.id)]));

        sendSocketJsonMessage(hideChatCommand(user, chat.id));
    }, [dispatch, chats, user]);

    return {
        isMobileView,
        isChatOpened,
        isFullScreenMode,
        isChatsLoading,
        isMessagesLoading,
        isUserInformationSectionShown,
        isMessagesSearchSectionShown,
        disabled,

        chats,
        chat,
        message,
        messages,
        searchMessages,
        messagesCount,
        activeSearchMessage,
        activeChatSearchString,

        setIsChatDialogOpen,
        setIsFullScreenMode,
        setIsUserInformationSectionShown,
        setIsMessagesSearchSectionShown,
        setActiveChatSearchMessage,
        setActiveChatSearchString,
        getChats,
        updateChats,
        getChatMessages,
        searchChatMessages,
        setChat,
        hideChat,
        onMessageChange,
        sendMessage,
        sendReadCommand
    };
};

export default useChat;

