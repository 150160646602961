import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../ui/Dialog/Dialog";
import GridFilterPanelLayoutSaveForm from "../GridFilterPanelLayoutSaveForm/GridFilterPanelLayoutSaveForm";

type GridFilterPanelLayoutSaveDialogProps = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: (name: string) => void;
}

const GridFilterPanelLayoutSaveDialog: React.FC<GridFilterPanelLayoutSaveDialogProps> = ({
                                                                                             show,
                                                                                             setShow,
                                                                                             onSave
                                                                                         }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'grid-filter-panel-layout-save-filter-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.labels.add-filter-label")}>
            <GridFilterPanelLayoutSaveForm onSave={onSave}
                                           close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default GridFilterPanelLayoutSaveDialog;