import {SelectModel} from "../app/types/SelectModel";

export const getSchedulerHours = (): Array<SelectModel> => ([
    {text: "07:00", value: "7",},
    {text: "08:00", value: "8"},
    {text: "09:00", value: "9"},
    {text: "10:00", value: "10"},
    {text: "11:00", value: "11"},
    {text: "12:00", value: "12"},
    {text: "13:00", value: "13"},
    {text: "14:00", value: "14"},
    {text: "15:00", value: "15"},
    {text: "16:00", value: "16"},
    {text: "17:00", value: "17"},
    {text: "18:00", value: "18"},
    {text: "19:00", value: "19"}
]);