import React from 'react';
import {IOffer} from "../../../../../../../../app/interfaces/offer/IOffer";
import {getUserRole} from "../../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../../app/enums/UserRole";
import SupplierField from "../../../../../../../../components/shared/form/detailsFields/SupplierField/SupplierField";
import {useAppSelector} from "../../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../../features/authentication/authenticationSliceSelectors";

type OfferSupplierProps = {
    offer: IOffer;
};

const OfferSupplier: React.FC<OfferSupplierProps> = ({
                                                         offer
                                                     }) => {
    const user = useAppSelector(selectCurrentUser);

    if (!user || offer.spUser === null || !offer.spUser.name) {
        return null;
    }

    return (
        <div className={'details-box-item-field-value'}>
            {getUserRole(user) === UserRole.ServiceProvider
                ? <>{offer.spUser.name}</>
                : <SupplierField user={{
                    ...offer.spUser,
                    id: offer.serviceProviderId ?? '',
                    group: offer.spGroup,
                    extName: offer.serviceProviderExt
                }}
                                 chatReplyTo={{
                                     type: 'RELEASE',
                                     id: offer.orderReleaseXid,
                                     idExt: null,
                                     description: offer.chatDescription
                                 }}/>
            }
        </div>
    );
};

export default OfferSupplier;