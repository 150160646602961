import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'messages',
    pathData: [
        'M8.33366 12.5001L5.77095 15.0948C5.41348 15.4568 5.23475 15.6377 5.08112 15.6505C4.94784 15.6616 4.81734 15.608 4.73029 15.5064C4.62996 15.3894 4.62996 15.1351 4.62996 14.6264V13.3264C4.62996 12.87 4.25622 12.5398 3.80466 12.4737V12.4737C2.71178 12.3136 1.85347 11.4553 1.69339 10.3624C1.66699 10.1822 1.66699 9.96718 1.66699 9.53712V5.66675C1.66699 4.26662 1.66699 3.56655 1.93948 3.03177C2.17916 2.56137 2.56161 2.17892 3.03202 1.93923C3.5668 1.66675 4.26686 1.66675 5.66699 1.66675H11.8337C13.2338 1.66675 13.9339 1.66675 14.4686 1.93923C14.939 2.17892 15.3215 2.56137 15.5612 3.03177C15.8337 3.56655 15.8337 4.26662 15.8337 5.66675V9.16675M15.8337 18.3334L14.02 17.0725C13.765 16.8952 13.6376 16.8066 13.4988 16.7438C13.3757 16.688 13.2462 16.6474 13.1133 16.6229C12.9635 16.5953 12.8083 16.5953 12.4978 16.5953H11.0003C10.0669 16.5953 9.60019 16.5953 9.24367 16.4137C8.93007 16.2539 8.6751 15.9989 8.51531 15.6853C8.33366 15.3288 8.33366 14.8621 8.33366 13.9287V11.8334C8.33366 10.9 8.33366 10.4333 8.51531 10.0768C8.6751 9.76316 8.93007 9.50819 9.24367 9.3484C9.60019 9.16675 10.0669 9.16675 11.0003 9.16675H15.667C16.6004 9.16675 17.0671 9.16675 17.4236 9.3484C17.7372 9.50819 17.9922 9.76316 18.152 10.0768C18.3337 10.4333 18.3337 10.9 18.3337 11.8334V14.0953C18.3337 14.8719 18.3337 15.2602 18.2068 15.5665C18.0376 15.9748 17.7132 16.2993 17.3048 16.4685C16.9985 16.5953 16.6102 16.5953 15.8337 16.5953V18.3334Z'
    ],
    viewBox: '0 0 20 20',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;