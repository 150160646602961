import React, {useEffect, useRef, useState} from 'react';
import cl from './GridActionRowTagsContainer.module.css';
import GridActionRowTag from "./components/GridActionRowTag/GridActionRowTag";
import {isTagsChanged} from "./utils";
import Icon, {IconName} from "../../../../../../assets/icon/Icon";
import GridActionRowTagsContextMenu from "./components/GridActionRowTagsContextMenu/GridActionRowTagsContextMenu";
import {Query} from "../../../../../../app/types/Query";

export type GridActionRowTagItem = {
    id: string;
    text: string;
    tooltip?: string;
    showClearIcon?: boolean;
    onClear?: () => void;
    onClick?: () => void;
    isTab?: boolean;
    selected?: boolean;
    countUrl?: string;
    icon?: IconName;
    iconColor?: string;
    query?: Query;
};

type GridActionRowTagsContainerProps = {
    id: string;
    tags: Array<GridActionRowTagItem>;
    availableWidth: number;
};


const GridActionRowTagsContainer: React.FC<GridActionRowTagsContainerProps> = ({
                                                                                   id,
                                                                                   tags,
                                                                                   availableWidth
                                                                               }) => {
    const [isRecalculation, setIsRecalculation] = useState<boolean>(false);

    const previousTags = useRef<Array<GridActionRowTagItem>>(tags);
    const tagsMenuButtonRef = useRef<HTMLButtonElement | null>(null);

    const [isTagsMenuOpened, setIsTagsMenuOpened] = useState<boolean>(false);
    const [menuTags, setMenuTags] = useState<Array<GridActionRowTagItem>>([]);
    const [showTags, setShowTags] = useState<Array<GridActionRowTagItem>>([]);
    const [tagsWithWidth, setTagsWithWidth] = useState<Array<{
        width: number;
        tag: GridActionRowTagItem;
    }>>([]);

    useEffect(() => {
        if (!isRecalculation && isTagsChanged(tags, previousTags.current)) {
            previousTags.current = tags;

            setTagsWithWidth([]);

            //setShowTags([]);
            //setMenuTags([]);

            setIsRecalculation(true);
        }
    }, [tags, isRecalculation]);

    useEffect(() => {
        let allTagsWidth = tagsWithWidth
            .map(tag => tag.width)
            .reduce((sum, a) => sum + a, 0) + (tagsWithWidth.length - 1) * 5;

        if (allTagsWidth <= 0) {
            return;
        }

        setIsTagsMenuOpened(false);

        if (allTagsWidth <= availableWidth) {
            setShowTags(tagsWithWidth.map(tag => tag.tag));
            setMenuTags([]);
        } else {
            let itemsToShow: Array<{
                width: number;
                tag: GridActionRowTagItem;
            }> = [];
            let itemsToMenu: Array<{
                width: number;
                tag: GridActionRowTagItem;
            }> = [];

            for (const tag of tagsWithWidth) {
                let allTagsToShownWidth = itemsToShow.map(tag => tag.width)
                    .reduce((sum, a) => sum + a, 0);

                if (itemsToShow.length > 0) {
                    allTagsToShownWidth += (itemsToShow.length - 1) * 5;
                }

                if ((allTagsToShownWidth + (tag.width + 5)) <= (availableWidth - 37)) {
                    itemsToShow.push(tag);
                } else {
                    itemsToMenu.push(tag);
                }
            }

            setShowTags(itemsToShow.map(item => item.tag));
            setMenuTags(itemsToMenu.map(item => item.tag));
        }
    }, [tagsWithWidth, availableWidth]);

    if (availableWidth <= 40 || tags.length <= 0) {
        return null;
    }

    return (
        <div id={id}
             className={cl.container}>
            {/* {isRecalculation
                ? <GridActionRowTagsContainerSkeleton availableWidth={availableWidth}/>
                : null
            }
*/}

            {showTags.map(tag => {
                return <GridActionRowTag key={tag.id} {...tag}/>
            })}

            {menuTags.length > 0 &&
                <>
                    <GridActionRowTagsContextMenu isOpened={isTagsMenuOpened}
                                                  setIsOpened={setIsTagsMenuOpened}
                                                  container={tagsMenuButtonRef}
                                                  tags={menuTags}
                                                  width={300}
                                                  availableWidth={availableWidth}
                                                  verticalPosition={'bottom'}
                                                  horizontalPosition={'right'}/>

                    <button className={`default-form-close-btn ${cl.contextBtn}`}
                            ref={tagsMenuButtonRef}
                            onClick={() => setIsTagsMenuOpened(prev => !prev)}>
                        <Icon icon={"faEllipsisHorizontal"}/>
                    </button>
                </>
            }

            {isRecalculation
                ? <div className={cl.render}>
                    {tags.map(tag => {
                        return <GridActionRowTag key={tag.id}
                                                 render={true}
                                                 onRender={(width) => {
                                                     setTagsWithWidth(prev => {
                                                         let res = [...prev];
                                                         let existItem = res.find(e => e.tag.id === tag.id);

                                                         if (existItem) {
                                                             res = [
                                                                 ...prev.filter(e => e.tag.id !== tag.id), {
                                                                     width,
                                                                     tag: tag
                                                                 }
                                                             ];
                                                         } else {
                                                             res = [
                                                                 ...prev, {
                                                                     width,
                                                                     tag: tag
                                                                 }
                                                             ];
                                                         }

                                                         if (res.length === tags.length) {
                                                             setIsRecalculation(false);
                                                         }

                                                         return res;
                                                     });
                                                 }}
                                                 {...tag}/>;
                    })}
                </div>
                : null
            }
        </div>
    );
};

export default GridActionRowTagsContainer;
