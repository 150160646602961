import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'asstraLogo',
    pathData: [
        'M0 22.6542L3.29964 22.6367L5.67298 17.0835H16.5065L15.4264 14.5026H6.81834L11.3058 4.99576L9.68439 1.46909L0 22.6542Z',
        'M10.5591 0.143433H13.5305L23.7258 22.6436H20.3958L10.5591 0.143433Z',
        'M27.6676 13.98C29.06 13.98 30.1888 12.8507 30.1888 11.4575C30.1888 10.0644 29.06 8.935 27.6676 8.935C26.2752 8.935 25.1465 10.0644 25.1465 11.4575C25.1465 12.8507 26.2752 13.98 27.6676 13.98Z',
        'M32.4307 0.143433L50.9209 0.148742V2.97767H43.2869V22.6489H40.1852V2.98449L32.4307 2.98298V0.143433Z',
        'M54.5413 22.6474V0.143459H57.1018L66.4967 16.0628L75.8293 0.1427H78.3429V22.6512H75.3669V6.34965L67.1758 20.0582H65.7447L57.5225 6.47707V22.6512L54.5413 22.6474Z',
        'M98.303 4.47167L99.3309 1.93929C99.3309 1.93929 96.5035 0 91.9554 0C87.4073 0 83.5907 2.35491 83.5907 6.27218C83.5907 10.1894 86.5667 11.8413 90.5713 12.7613C94.5759 13.6812 97.2251 14.4829 97.2251 16.7089C97.2251 18.9348 95.1535 20.344 91.5794 20.344C87.1307 20.344 84.2267 17.7919 84.2267 17.7919L83.0222 20.2105C83.0222 20.2105 85.9292 23 91.5499 23C97.1706 23 100.28 20.4578 100.28 16.3623C100.28 13.2959 98.0256 11.456 92.5285 10.1697C90.2347 9.63882 86.6705 9.1011 86.6705 6.23274C86.6705 3.36437 90.0339 2.71061 92.3306 2.71061C95.7121 2.71061 98.303 4.47167 98.303 4.47167Z'
    ],
    viewBox: '0 0 101 23'
};

export default icon;