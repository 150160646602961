import React from 'react';
import {IShipment} from "../../../../../../../../../app/interfaces/shipment/IShipment";
import {useAppSelector} from "../../../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../../../app/enums/UserRole";
import {useTranslation} from "react-i18next";
import apiAxios from "../../../../../../../../../app/axios/apiAxios";
import {ShipmentEtaStatus} from "../../../../../../../../../app/enums/ShipmentEtaStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHourglass} from "@fortawesome/free-solid-svg-icons";
import TransportOrder from "../../../TransportOrder/TransportOrder";
import Icon, {IconName} from "../../../../../../../../../assets/icon/Icon";
import {openOtmTransportOrder} from "../../../../../../grid/templates/ShipmentNumberTemplate/ShipmentNumberTemplate";

let GET_OTM_RELEASE_LINK = `/api/${process.env.REACT_APP_API_VERSION}/offer/getOtmLink`;
let GET_OTM_SHIPMENT_LINK = `/api/${process.env.REACT_APP_API_VERSION}/shipment/getOtmShipmentLink`

export const openOtmShipment = async (sid: string) => {
    try {
        let response = await apiAxios.get<string>(`${GET_OTM_SHIPMENT_LINK}?id=${sid}&_c=${Date.now()}`);

        if (response.status === 200 && response.data) {
            window.open(response.data, '_blank');
        }
    } catch {
        //ignore
    }
}

export const openOtmRelease = async (id: string) => {
    try {
        let response = await apiAxios.get<string>(`${GET_OTM_RELEASE_LINK}?id=${id}&_c=${Date.now()}`);

        if (response.status === 200 && response.data) {
            window.open(response.data, '_blank');
        }
    } catch {
        //ignore
    }
}

type ShipmentGidProps = {
    shipment: IShipment;
}

const ShipmentGid: React.FC<ShipmentGidProps> = ({
                                                     shipment
                                                 }) => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    if (!user) {
        return null;
    }

    let role = getUserRole(user);

    if (!shipment.hasShipment) {
        if (role === UserRole.Forwarder || role === UserRole.Administrator) {
            return (
                <div className={'details-box-item-field-edit-value'}
                     onClick={async () => await openOtmRelease(shipment.orderReleaseGid ?? '')}>
                    {t("shared.labels.no-data")}
                </div>
            );
        } else {
            return (
                <div className={'details-box-item-field-value'}>
                    {t("shared.labels.no-data")}
                </div>
            );
        }
    }

    if (role === UserRole.Forwarder || role === UserRole.Administrator) {
        let etaStatus = (shipment.shipmentEtaArrivalStatus === ShipmentEtaStatus.Late || shipment.shipmentEtaDepartureStatus === ShipmentEtaStatus.Late) ? "etaStatusLate"
            : ((shipment.shipmentEtaArrivalStatus === ShipmentEtaStatus.OnTime || shipment.shipmentEtaDepartureStatus === ShipmentEtaStatus.OnTime) ? "etaStatusOnTime"
                : ((shipment.shipmentEtaArrivalStatus === ShipmentEtaStatus.Early || shipment.shipmentEtaDepartureStatus === ShipmentEtaStatus.Early) ? "etaStatusEarly" : "noEta"));

        let indicatorIcon: IconName | null = null;
        let indicatorStyle: React.CSSProperties | undefined = undefined;

        if (shipment.indicator === 'G') {
            indicatorIcon = "faCircle";
            indicatorStyle = {
                fontSize: '14px',
                marginRight: '5px',
                color: '#7FB98B'
            };
        } else if (shipment.indicator === 'Y') {
            indicatorIcon = "faTriangle";
            indicatorStyle = {
                fontSize: '14px',
                marginRight: '5px',
                color: '#F3D11C'
            };
        }

        let title: string = '';
        let style: React.CSSProperties | undefined = undefined;

        if (!shipment.isUnConfirmed && shipment.isStarted) {
            title = t("shipment.grid.confirmed");
            style = {
                color: 'var(--main-orange-color)',
            };
        }

        if (shipment.isUnConfirmed) {
            title = t("shipment.grid.unconfirmed");
            style = {
                color: 'var(--main-orange-color)'
            };
        }

        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <TransportOrder shipment={shipment}/>
                <Icon icon={"faCheck"} title={shipment.isUnConfirmed ? t("shipment.grid.unconfirmed") : t("shipment.grid.confirmed")} style={{
                    fontSize: '16px',
                    marginRight: '5px',
                    verticalAlign: 'middle',
                    color: shipment.isUnConfirmed ? '#E96024' : '#7FB98B'
                }}/>
                {shipment.isFirstOrder &&
                    <Icon icon={"faTriangleExclamation"}
                          title={t("shared.labels.first-order-label")}
                          style={{
                              color: '#3F8CE5',
                              fontSize: '17px',
                              marginRight: '5px',
                              verticalAlign: 'middle'
                          }}/>
                }

                <FontAwesomeIcon icon={faHourglass}
                                 className={etaStatus}
                                 style={{
                                     marginRight: '5px'
                                 }}/>

                {shipment.hasTracking &&
                    <Icon icon={"faBroadcast"} title={t("shipment.grid.truck-has-gps")}
                          style={{
                              color: 'var(--main-orange-color)',
                              fontSize: '17px',
                              marginRight: '5px'
                          }}/>
                }

                {indicatorIcon !== null && <Icon icon={indicatorIcon} style={indicatorStyle}/>}
                {shipment.isUnConfirmed !== null && !shipment.isUnConfirmed &&
                    <Icon icon={"faPrint"}
                          style={{
                              fontSize: '16px',
                              marginRight: '5px',
                              verticalAlign: 'middle',
                              cursor: 'pointer'
                          }}
                          className={'grid-prevent-selection'}
                          title={t("shipment.grid.open-otm-transport-order-shipment")}
                          onClick={async (ev) => {
                              if (shipment.shipmentGid === null || shipment.shipmentGid === undefined) {
                                  return;
                              }

                              ev.stopPropagation();

                              await openOtmTransportOrder(shipment.shipmentGid)
                          }}
                    />
                }
                <span className={'details-box-item-field-edit-value'}
                      onClick={async () => await openOtmShipment(shipment.shipmentGid ?? '')}
                      title={title}
                      style={style}>
                     {shipment.shipmentXid}
                 </span>
            </div>
        );
    }

    return (
        <div className={'details-box-item-field-value'}
             style={{display: 'flex', alignItems: 'center'}}>
            {shipment.hasTracking &&
                <Icon icon={"faBroadcast"} title={t("shipment.grid.truck-has-gps")}
                      style={{
                          color: 'var(--main-orange-color)',
                          fontSize: '17px',
                          marginRight: '5px'
                      }}/>
            }

            <span>
                {shipment.shipmentXid}
            </span>
        </div>
    );
};

export default ShipmentGid;
