import React from 'react';
import cl from './ToastLayout.module.css';

type ToastLayoutProps = {
    title: string;
    text: string;
}

const ToastLayout: React.FC<ToastLayoutProps> = ({
                                                     title,
                                                     text
                                                 }) => {
    return (
        <div>
            <div className={cl.title}>
                {title}
            </div>
            <div className={cl.text}>
                {text}
            </div>
        </div>
    );
};

export default ToastLayout;