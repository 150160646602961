import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {ITransportsAction} from "../../../../../app/interfaces/offer/ITransportsAction";
import {useOfferApi} from "../../../../../app/api/offer";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {isAxiosError} from "axios";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {BLErrorCode} from "../../../../../app/enums/BLErrorCode";
import Alert from "../../../../../components/ui/Alert/Alert";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import SelectedOffers from "../../form/components/SelectedOffers/SelectedOffers";

type DeclineTransportsProps = {
    id: string | Array<string>;
    close?: () => void;
};

const DeclineTransports: React.FC<DeclineTransportsProps> = ({
                                                                 id,
                                                                 close
                                                             }) => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError>>([]);
    const [model, setModel] = useState<ITransportsAction | null>(null);

    const {
        declineTransports: {
            mutation: declineTransports,
            cancel: cancelDeclineTransports
        },
        getDeclineTransportsData: {
            query: getDeclineTransportsData,
            cancel: cancelGetDeclineTransportsData,
            isLoading
        }
    } = useOfferApi();

    useEffect(() => {
        if (Array.isArray(id) && id.length <= 0 || id === '') {
            if (close) {
                close()
            } else {
                return;
            }
        }

        (async () => {
            try {
                const response = await getDeclineTransportsData(id);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch {
                if (close) {
                    close();
                } else {
                    toast.error<string>('Unable to get decline transports data');
                }
            }
        })();

        return () => {
            cancelGetDeclineTransportsData();
            cancelDeclineTransports();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = async (): Promise<void> => {
        if (!model) {
            return;
        }

        setDisabled(prev => !prev);

        try {
            const response = await declineTransports(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [...id].map((item) => {
                            return {
                                id: item,
                                isRowLoading: true
                            };
                        })
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.offer")} ${model.orderReleaseGid !== '' ? model.orderReleaseGid.split(';')[0] : ''}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotFound &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();
                    toast.error(err.message);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if ((Array.isArray(id) && id.length <= 0) || id === '') {
        return null;
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>
            <SelectedOffers ids={model.items.map(item => item.id)}/>
            <>
                {errors.length > 0 && errors.map((err, index) => {
                    switch (err.errorCode) {
                        case BLErrorCode.InnerError:
                            return (
                                <Alert type={'Error'}
                                       id={`decline-transports-error-${index}`}>
                                    {err.description}
                                </Alert>
                            )
                    }

                    return null;
                })}
            </>
            <div style={{
                margin: '30px 20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '20px'
            }}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            style={{minWidth: '150px'}}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.no")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        style={{minWidth: '150px'}}
                        onClick={submit}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.yes")}
                </Button>
            </div>
        </div>
    );
};

export default DeclineTransports;