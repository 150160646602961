import React from 'react';
import {useTranslation} from "react-i18next";

type ThuCountProps = {
    thuCount: string | null
}

const ThuCount: React.FC<ThuCountProps> = ({
                                               thuCount
                                           }) => {
    const {t} = useTranslation();

    return (
        <>
            <label className={'thu-count-label'}>
                {t('shipment.form.thu-count')}
            </label>
            <span className={'details-box-item-field-value thu-count-value'}>
                {thuCount !== null && thuCount !== ''
                    ? <>{thuCount}</>
                    : <>{t("shared.labels.no-data")}</>
                }
            </span>
        </>
    );
};

export default ThuCount;