import React from 'react';
import {RouteSectionModel} from "../../RouteSection";
import {useTranslation} from "react-i18next";

type QuantityProps = {
    model: RouteSectionModel;
}

const Quantity: React.FC<QuantityProps> = ({model}) => {
    const {t} = useTranslation();

    return (
        <>
            <label className={'quantity-label'}>{t('quote.form.quantity')}</label>
            <span className={'details-box-item-field-value quantity-value'}>
                 {model.quantity !== undefined && model.quantity !== null && model.quantity !== ''
                     ? <>{model.quantity}</>
                     : <>{t("shared.labels.no-data")}</>
                 }
                {model.quantityBatch !== undefined && model.quantityBatch !== null && model.quantityBatch !== ''
                    ? <>{` / ${model.quantityBatch}`}</>
                    : <>{`${model.quantity !== undefined && model.quantity !== null && model.quantity !== '' ? ` / ${t("shared.labels.no-data")}` : ''}`}</>
                }
            </span>
        </>
    );
};

export default Quantity;