import React, {useRef} from 'react';
import {useChatHelperContext} from "../../../hooks/useChatHelperContext";
import ChatListCardSkeleton from "./ChatListCardSkeleton/ChatListCardSkeleton";
import {useTranslation} from "react-i18next";
import ChatListCard from "./ChatListCard/ChatListCard";

const ChatContentChatList: React.FC = () => {
    const {t} = useTranslation();
    const isInitialLoadingChats = useRef<boolean>(true);

    const {
        isChatsLoading,
        chats
    } = useChatHelperContext();

    if (isChatsLoading && (isInitialLoadingChats.current || chats.length <= 0)) {
        isInitialLoadingChats.current = false;

        return (
            <>
                {Array.from(Array(5))
                    .map((_, index) => {
                        return <ChatListCardSkeleton key={index}/>;
                    })
                }
            </>
        );
    }

    if (chats.length <= 0) {
        return (
            <div className={'chat-content-chats-not-found'}>
                {t("chat.labels.active-chats-not-found")}
            </div>
        );
    }


    return (
        <>
            {Array.isArray(chats) && chats.length > 0 && [...chats]
                .sort((a, b) => {
                    return Number(b.position ?? 0) - Number(a.position ?? 0);
                })
                .map((chat) => {
                    return <ChatListCard key={`${chat.id}-${chat.position}`}
                                         chat={chat}/>;
                })
            }
        </>
    );
};

export default ChatContentChatList;