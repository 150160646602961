import React, {useCallback, useEffect, useRef, useState} from 'react';
import {IGridResultResponse} from "../../../../../../../../../app/interfaces/response/IGridResultResponse";
import {IChatMessage} from "../../../../../../../../../app/interfaces/chat/IChatMessage";
import {useAppDispatch, useAppSelector} from "../../../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../../../features/authentication/authenticationSliceSelectors";
import ChatDialogueMessagesLoadingScreen from "../ChatDialogueMessagesLoadingScreen/ChatDialogueMessagesLoadingScreen";
import ChatDialogueMessagesNotFound from "../ChatDialogueMessagesNotFound/ChatDialogueMessagesNotFound";
import moment from "moment";
import _ from "lodash";
import ChatDialogueMessage from "../ChatDialogueMessage/ChatDialogueMessage";
import {GroupedVirtuoso, GroupedVirtuosoHandle} from "react-virtuoso";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {UNSAFE_setMessages} from "../../../../../../../../../features/chat/chatSlice";

type ChatDialogueGroupedMessagesProps = {
    chatId: string;
    activeChatSearchMessage: IChatMessage | null;
    activeChatSearchString?: string;
    loadMessages: (user: string, search: string, skip: number, take: number, start?: string) => Promise<IGridResultResponse<IChatMessage>>;
};

const CHUNK_SIZE = 25;

const ChatDialogueGroupedMessages: React.FC<ChatDialogueGroupedMessagesProps> = React.memo(({
                                                                                                chatId,
                                                                                                loadMessages,
                                                                                                activeChatSearchMessage,
                                                                                                activeChatSearchString
                                                                                            }) => {
    const user = useAppSelector(selectCurrentUser);
    const dispatch = useAppDispatch();

    const ref = useRef<GroupedVirtuosoHandle | null>(null);

    const scrollToIndex = useRef<number | null>(null);
    const scrollToId = useRef<number | null>(null);

    const chunkCount = useRef<number | null>(null);

    const [isMessagesInitialLoading, setIsMessagesInitialLoading] = useState<boolean>(true);
    const [messages, setMessages] = useState<Array<IChatMessage>>([]);

    const [startIndex, setStartIndex] = useState<number | null>(null);
    const [firstItemIndex, setFirstItemIndex] = useState<number | null>(null);

    const [groupCount, setGroupCount] = useState<Array<number> | null>(null);
    const [groupTitles, setGroupTitles] = useState<Array<string> | null>(null);

    // Update messages on onChatMessagesUpdate event
    useEffect(() => {
        if (!chatId) {
            return;
        }

        let onChatMessagesUpdate = (ev: any) => {
            (async () => {
                ev = ev as CustomEvent<{ cmd: string }>

                if (ev.detail.cmd === 'new') {
                    try {
                        const response = await loadMessages(
                            chatId,
                            '',
                            0,
                            1
                        );

                        if (response.result.length > 0) {
                            let messagesToAdd: Array<IChatMessage> = [];

                            response.result.forEach((msg) => {
                                let existMsg = messages.find(e => e.id === msg.id);

                                if (!existMsg) {
                                    messagesToAdd.push(msg);
                                }
                            });

                            if (messagesToAdd.length > 0) {
                                setStartIndex(prev => {
                                    return (prev ?? 0) + messagesToAdd.length;
                                });

                                setFirstItemIndex(prev => {
                                    return (prev ?? 0) + messagesToAdd.length;
                                });

                                let arr = [...new Map([...messagesToAdd, ...messages].map(item => [item['id'], item])).values()]
                                    .sort((a: IChatMessage, b: IChatMessage) => {
                                        return ((new Date(a.date)).getTime() - (new Date(b.date)).getTime());
                                    });

                                setMessages([...arr]);

                                ref.current?.scrollToIndex(arr.length);
                            }
                        }
                    } catch {
                        // ignore
                    }
                }

                if (ev.detail.cmd === 'read') {
                    setMessages([...messages.map(msg => {
                        if (user && msg.userId === (user.uniqueUserValue ?? user.idStr ?? '')) {
                            return {
                                ...msg,
                                isRead: true
                            };
                        }

                        return {
                            ...msg
                        };
                    })]);
                }
            })();
        };

        document.addEventListener('onChatMessagesUpdate', onChatMessagesUpdate);

        return () => {
            document.removeEventListener('onChatMessagesUpdate', onChatMessagesUpdate);
        }
    }, [messages, chatId]);

    useEffect(() => {
        if (activeChatSearchMessage === null) {
            ref.current?.autoscrollToBottom();
        } else {
            if (activeChatSearchMessage.index === null || activeChatSearchMessage.index < 0) {
                return;
            }

            let index = messages.findIndex(e => e.id === activeChatSearchMessage.id);
            if (index > -1) {
                ref.current?.scrollToIndex(index);
            } else {
                scrollToIndex.current = activeChatSearchMessage.index;
                scrollToId.current = activeChatSearchMessage.id;

                ref.current?.scrollToIndex(0);
            }
        }
    }, [activeChatSearchMessage]);

    useEffect(() => {
        if (!chatId) {
            return;
        }

        setIsMessagesInitialLoading(true);

        (async () => {
            const response = await loadMessages(
                chatId,
                '',
                0,
                CHUNK_SIZE
            );

            setMessages(response.result);

            dispatch(UNSAFE_setMessages(response.result));

            chunkCount.current = response.count / CHUNK_SIZE;
            setFirstItemIndex(response.count);
            setStartIndex(response.count);

            setIsMessagesInitialLoading(false);
        })();
    }, [chatId]);

    useEffect(() => {
        if (isMessagesInitialLoading) {
            return;
        }

        let groups = _.chain(messages)
            .groupBy(e => moment(e.date).format('DD.MM.YYYY'))
            .map((value, key) => ({value, key}))
            .value()
            .sort((a, b) => {
                return ((moment(a.key).toDate().getTime()) - (moment(b.key).toDate().getTime()));
            });

        let counts: Array<number> = [];
        let titles: Array<string> = [];

        groups.forEach(group => {
            counts.push(group.value.length);
            titles.push(group.key);
        });

        setGroupCount(counts);
        setGroupTitles(titles);
    }, [messages, isMessagesInitialLoading]);


    const startReached = useCallback(async () => {
        if (firstItemIndex === null || startIndex === null) {
            return;
        }

        let scrollToIndexLocal = scrollToIndex.current;

        if (scrollToIndexLocal === null) {
            let i = firstItemIndex - CHUNK_SIZE;

            if (i <= 0) {
                return;
            }

            const msg = await loadMessages(chatId, '', (startIndex - i), CHUNK_SIZE);

            let arr = [...new Map([...msg.result, ...messages].map(item => [item['id'], item])).values()]
                .sort((a: IChatMessage, b: IChatMessage) => {
                    return ((new Date(a.date)).getTime() - (new Date(b.date)).getTime());
                });

            setMessages(() => arr);

            if (chunkCount.current && chunkCount.current < 2) {
                ref.current?.scrollToIndex(i);
            }

            setFirstItemIndex(() => i);
            dispatch(UNSAFE_setMessages(arr));
        } else {
            let chunkCount = Math.floor((startIndex - scrollToIndexLocal) / CHUNK_SIZE) + 1;

            const msg = await loadMessages(chatId, '', 0, chunkCount * CHUNK_SIZE);

            let arr = [...new Map([...msg.result, ...messages].map(item => [item['id'], item])).values()]
                .sort((a: IChatMessage, b: IChatMessage) => {
                    return ((new Date(a.date)).getTime() - (new Date(b.date)).getTime());
                });

            let index = [...arr].findIndex(e => "id" in e ? e.id === (scrollToId.current ?? 0) : false);

            setMessages(() => arr);

            setFirstItemIndex((prev) => {
                let n = (prev ?? 0) - (chunkCount * CHUNK_SIZE);

                return n < 0 ? 0 : n;
            });

            ref.current?.scrollToIndex(index > -1 ? index : scrollToIndexLocal);

            scrollToIndex.current = null;
            scrollToId.current = null;
        }
    }, [firstItemIndex, startIndex, chatId, messages, loadMessages]);

    if (isMessagesInitialLoading) {
        return <ChatDialogueMessagesLoadingScreen/>;
    }

    if (groupCount === null || groupTitles === null || startIndex === null || firstItemIndex === null || !user) {
        return <ChatDialogueMessagesLoadingScreen/>;
    }

    if (messages.length <= 0) {
        return <ChatDialogueMessagesNotFound/>;
    }

    return (
        <GroupedVirtuoso ref={ref}
                         style={{height: '100%'}}
                         firstItemIndex={firstItemIndex}
                         initialTopMostItemIndex={startIndex - 1}
                         startReached={startReached}
                         context={{groupTitles}}
                         groupCounts={groupCount}
                         increaseViewportBy={{top: 50, bottom: 0}}
                         components={{Header: () => <Header active={startIndex > messages.length}/>}}
                         groupContent={(index, data) => {
                             return (
                                 <div style={{
                                     display: 'flex',
                                     alignItems: 'center',
                                     justifyContent: 'center',
                                     paddingTop: '15px'
                                 }}>
                                     <div style={{
                                         backgroundColor: 'rgba(67, 69, 83, .8)',
                                         borderRadius: '2px',
                                         padding: '3px 5px',
                                         fontSize: '10px',
                                         fontWeight: 'bold',
                                         color: 'white'
                                     }}>
                                         {`${data.groupTitles[index]}`}
                                     </div>
                                 </div>
                             );
                         }}
                         itemContent={(_index, _groupIndex) => {
                             let message = messages[_index - (firstItemIndex ?? CHUNK_SIZE)];

                             if (message === undefined) {
                                 return (
                                     <SkeletonTheme baseColor={'#eaeff3'} highlightColor={'#ffffff'}>
                                         <div className={'chat-dialogue-messages-loading-screen-row'}>
                                             <div className={'w100'}>
                                                 <Skeleton height={'30px'}
                                                           width={'70%'}
                                                           style={{borderRadius: '2px'}}/>
                                             </div>
                                             <div>
                                                 <Skeleton height={'15px'}
                                                           width={'50px'}
                                                           style={{borderRadius: '2px'}}/>
                                             </div>
                                         </div>
                                     </SkeletonTheme>
                                 );
                             }

                             let isFromMessage = message.userId === (user.uniqueUserValue ?? user.idStr);

                             return (
                                 <ChatDialogueMessage id={message.id.toString()}
                                                      key={message.id}
                                                      offset={user.timeShift}
                                                      message={message}
                                                      direction={isFromMessage ? 'from' : 'to'}
                                                      highlight={activeChatSearchString}/>
                             );
                         }}/>
    );
});

export default ChatDialogueGroupedMessages;

const Header = ({
                    active
                }: {
    active: boolean;
}) => {
    if (!active) {
        return null;
    }

    return (
        <SkeletonTheme baseColor={'#eaeff3'} highlightColor={'#ffffff'}>
            <div className={'chat-dialogue-messages-loading-screen-row'}>
                <div className={'w100'}>
                    <Skeleton height={'30px'}
                              width={'70%'}
                              style={{borderRadius: '2px'}}/>
                </div>
                <div>
                    <Skeleton height={'15px'}
                              width={'50px'}
                              style={{borderRadius: '2px'}}/>
                </div>
            </div>
        </SkeletonTheme>
    );
}