import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'ellipsisHorizontal',
    viewBox: '0 0 36 36',
    circle: [
        {cx: '31.1', cy: '18', r: '2.9'},
        {cx: '18', cy: '18', r: '2.9'},
        {cx: '4.9', cy: '18', r: '2.9'},
    ]
};

export default icon;