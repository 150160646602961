import {useGetCurrencies, useGetCurrenciesAnonymous} from "./requests";

export const useCurrencyApi = () => {
    const {
        isLoading: getCurrenciesIsLoading,
        cancel: cancelGetCurrencies,
        getCurrencies
    } = useGetCurrencies();

    const {
        isLoading: getCurrenciesAnonymousIsLoading,
        cancel: cancelGetCurrenciesAnonymous,
        getCurrenciesAnonymous
    } = useGetCurrenciesAnonymous();

    return {
        getCurrencies: {
            query: getCurrencies,
            isLoading: getCurrenciesIsLoading,
            cancel: cancelGetCurrencies
        },
        getCurrenciesAnonymous: {
            query: getCurrenciesAnonymous,
            isLoading: getCurrenciesAnonymousIsLoading,
            cancel: cancelGetCurrenciesAnonymous
        }
    };
}