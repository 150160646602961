import React, {ReactNode} from 'react';
import cl from './AccessSettingsFilterPanelLayoutCollapse.module.css';
import {useCollapse} from "react-collapsed";
import Icon from "../../../../../../assets/icon/Icon";

type AccessSettingsFilterPanelLayoutCollapseProps = {
    children: ReactNode | ((props: AccessSettingsFilterPanelLayoutCollapseRenderProps) => JSX.Element);
    title: ReactNode;
    showSeparationHeaderLine?: boolean;
    style?: React.CSSProperties;
    defaultExpanded?: boolean;
    titleUpperCase?: boolean;
    titleBold?: boolean;
};

type AccessSettingsFilterPanelLayoutCollapseRenderProps = {
    isExpanded: boolean;
}

const AccessSettingsFilterPanelLayoutCollapse: React.FC<AccessSettingsFilterPanelLayoutCollapseProps> = ({
                                                                                                             children,
                                                                                                             title,
                                                                                                             showSeparationHeaderLine = false,
                                                                                                             style,
                                                                                                             defaultExpanded = true,
                                                                                                             titleUpperCase = false,
                                                                                                             titleBold = true,
                                                                                                         }) => {
    const {getCollapseProps, getToggleProps, isExpanded} = useCollapse({
        defaultExpanded
    });

    let renderProps: AccessSettingsFilterPanelLayoutCollapseRenderProps = {
        isExpanded
    };

    let headerClassName = `${cl.collapseHeader} ${showSeparationHeaderLine ? cl.collapseHeaderLine : ''}`;
    let titleHeaderClassName = `${cl.collapseTitle} ${!showSeparationHeaderLine ? cl.collapseTitleMgb : ''} prevent-selection`;

    if (titleUpperCase) {
        titleHeaderClassName += ` ${cl.uppercase}`;
    }

    if (titleBold) {
        titleHeaderClassName += ` ${cl.bold}`;
    }

    return (
        <div className={cl.collapse}
             style={style}>
            <div className={headerClassName} {...getToggleProps()}>
                <div className={titleHeaderClassName} style={{marginRight: '10px'}}>
                    {title}
                </div>
                <div className={cl.collapseIcon}>
                    <Icon icon={"faThinChevronUp"}
                          flip={isExpanded ? 'horizontal' : 'vertical'}/>
                </div>
            </div>
            <div {...getCollapseProps()}>
                <div className={cl.collapseContent}>
                    {typeof children === 'function'
                        ? children(renderProps)
                        : children
                    }
                </div>
            </div>
        </div>
    );
};

export default AccessSettingsFilterPanelLayoutCollapse;