import React, {useState} from 'react';
import {IShipment} from "../../../../../../../app/interfaces/shipment/IShipment";
import cl from './TransportHistory.module.css';
import {useTranslation} from "react-i18next";
import {ITsHistory} from "../../../../../../../app/interfaces/shared/ITsHistory";
import {useShipmentApi} from "../../../../../../../app/api/shipment";
import {ResponseResultCode} from "../../../../../../../app/enums/ResponseResultCode";
import Loader, {LoaderType} from "../../../../../../../components/ui/loaders/Loader/Loader";
import uuid from "react-uuid";
import moment from "moment";

type TransportHistoryProps = {
    shipment: IShipment;
}

const TransportHistory: React.FC<TransportHistoryProps> = ({
                                                               shipment
                                                           }) => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [items, setItems] = useState<Array<ITsHistory> | null>(null);
    const {
        getTsHistory: {
            query,
            isLoading
        }
    } = useShipmentApi();

    const fetch = async () => {
        if (isOpen) {
            setIsOpen(false);
            return;
        }

        try {
            setIsOpen(prev => !prev);

            const response = await query(shipment.orderReleaseGid ?? '', shipment.shipmentGid ?? '');
            if (response !== null && response !== undefined &&
                response.status === 200 &&
                response.data !== null &&
                response.data !== undefined &&
                response.data.resultCode === ResponseResultCode.Ok &&
                response.data.data !== undefined) {
                setItems(response.data.data);
            }
        } catch {
            setItems([]);
        }
    }

    return (
        <div style={{padding: '5px 15px 0 5px'}}>
            <div className={cl.btnContainer}>
                <button className={`${cl.button} show-transport-history-btn`}
                        onClick={fetch}>
                    {t("shipment.form.transport-history")}
                </button>
            </div>

            {isOpen && (isLoading || items === null)
                ? <div style={{
                    position: 'relative',
                    height: '80px'
                }}>
                    <div className={'action-loader-container'}>
                        <div>
                            <Loader type={LoaderType.Action} style={{scale: '0.4'}}/>
                        </div>
                    </div>
                </div>
                : null
            }

            {isOpen && !isLoading && items !== null && items.length > 0
                ? items.map((item, index) => {
                    return (
                        <div className={`${cl.item} transport-history-change-item-${index}`}
                             key={uuid()}>
                            <div className={cl.row}>
                                <div className={'transport-history-change-item-date'}>
                                    {moment(item.createdOn).format('DD.MM.YY HH:mm')}
                                </div>
                                <div className={'transport-history-change-item-user'}>
                                    {item.user}
                                </div>
                            </div>
                            <div className={cl.dRow}>
                                {item.equipment !== null && item.equipment !== ''
                                    ? <div className={'transport-history-change-item-equipment'}>
                                        <label>{`${t("shipment.form.equipment")} : `}</label>
                                        <div>{item.equipment}</div>
                                    </div>
                                    : null
                                }
                                {item.truck !== null && item.truck !== ''
                                    ? <div className={'transport-history-change-item-truck'}>
                                        <label>{`${t("shipment.form.truck-licence-plate")} : `}</label>
                                        <div>{item.truck}</div>
                                    </div>
                                    : null
                                }
                                {item.trailer !== null && item.trailer !== ''
                                    ? <div className={'transport-history-change-item-trailer'}>
                                        <label>{`${t("shipment.form.trailer-licence-plate")} : `}</label>
                                        <div>{item.trailer}</div>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    );
                })
                : null
            }

            {isOpen && !isLoading && items !== null && items.length <= 0
                ? <div className={cl.noHistory}>{t("shipment.form.no-transport-history")}</div>
                : null
            }
        </div>
    );
};

export default TransportHistory;
