import React from 'react';
import {useTranslation} from "react-i18next";
import cl from '../../NotificationForm/NotificationForm.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {INotificationGetAction} from "../../../../../../../app/interfaces/notification/INotificationGetAction";
import Checkbox from "../../../../../../../components/ui/Checkbox/Checkbox";
import {Tooltip} from "react-tooltip";
import {IUser} from "../../../../../../../app/interfaces/user/IUser";
import Input from "../../../../../../../components/ui/Input/Input";
import {
    INotificationSetupPostAction
} from "../../../../../../../app/interfaces/notification/INotificationSetupPostAction";
import _ from "lodash";
import {EmailCategory} from "../../../../../../../app/enums/EmailCategory";

type EmailNotificationSectionProps = {
    user: IUser;
    model: INotificationGetAction;
    disabled: boolean;
    email: string | null;
    emailError?: string | Array<string>;
    onEmailChange: (val: string | null) => void;
    ccEmail: string | null;
    ccEmailError?: string | Array<string>;
    onCCEmailChange: (val: string | null) => void;
    finEmail: string | null;
    finEmailError?: string | Array<string>;
    onFinEmailChange: (val: string | null) => void;
    setups: Array<INotificationSetupPostAction>;
    onSetupChange: (setup: INotificationSetupPostAction, val: boolean) => void;
};

const EmailNotificationSection: React.FC<EmailNotificationSectionProps> = ({
                                                                               user,
                                                                               disabled,
                                                                               emailError,
                                                                               email,
                                                                               onEmailChange,
                                                                               ccEmail,
                                                                               ccEmailError,
                                                                               onCCEmailChange,
                                                                               finEmail,
                                                                               finEmailError,
                                                                               onFinEmailChange,
                                                                               setups,
                                                                               onSetupChange
                                                                           }) => {
    const {t, i18n} = useTranslation();

    return (
        <div>
            <div className={'default-form-collapse-header'}>
                <div className={cl.collapse}>
                    <div>
              {/*          <div className={cl.icon}>
                            <FontAwesomeIcon icon={faEnvelope}/>
                        </div>*/}
                        <div>
                            {t("notification.labels.setup-email-notifications-label")}
                        </div>
                    </div>
                </div>
            </div>

            <div style={{padding: '5px 5px 0 5px'}}>
                <div className={'mgb-10'}>
                    <label className={'view-label required'}>
                        {t("profile.labels.email")}
                    </label>

                    <Input placeholder={t("profile.labels.email")}
                           id={'email'}
                           name={'email'}
                           disabled={disabled}
                           value={email ?? ''}
                           autoComplete={'off'}
                           icon={faEnvelope}
                           onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onEmailChange(ev.target.value)}/>

                    {emailError &&
                        <div className={`error-label`}>
                            {Array.isArray(emailError)
                                ? emailError.map(item => {
                                    return (
                                        <div key={item}>
                                            {item}
                                        </div>
                                    )
                                })
                                : <>{`${emailError}`}</>
                            }
                        </div>
                    }
                </div>

                <div className={'mgb-10'}>
                    <label className={'view-label'} style={{overflow:'none', whiteSpace: 'break-spaces'}}
                           title={t("notification.labels.cc-email")}>
                        {t("notification.labels.cc-email")}
                    </label>

                    <Input placeholder={t("notification.labels.cc-email")}
                           id={'ccEmail'}
                           name={'ccEmail'}
                           disabled={disabled}
                           value={ccEmail ?? ''}
                           autoComplete={'off'}
                           icon={faEnvelope}
                           onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onCCEmailChange(ev.target.value)}/>

                    {ccEmailError &&
                        <div className={`error-label`}>
                            {Array.isArray(ccEmailError)
                                ? ccEmailError.map(item => {
                                    return (
                                        <div key={item}>
                                            {item}
                                        </div>
                                    )
                                })
                                : <>{`${ccEmailError}`}</>
                            }
                        </div>
                    }
                </div>

                {user.spAdmin &&
                    <div className={'mgb-10'}>
                        <label className={'view-label'}>
                            {t("self-billing.labels.financial-email-label")}
                        </label>

                        <Input placeholder={t("self-billing.labels.financial-email-label")}
                               id={'finEmail'}
                               name={'finEmail'}
                               disabled={disabled}
                               value={finEmail ?? ''}
                               autoComplete={'off'}
                               icon={faEnvelope}
                               onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onFinEmailChange(ev.target.value)}/>

                        {finEmailError &&
                            <div className={`error-label`}>
                                {Array.isArray(finEmailError)
                                    ? finEmailError.map(item => {
                                        return (
                                            <div key={item}>
                                                {item}
                                            </div>
                                        )
                                    })
                                    : <>{`${finEmailError}`}</>
                                }
                            </div>
                        }
                    </div>
                }

                <div className={'mgb-10'}>
                    <div className={'default-form-collapse-header'}>
                        <div className={cl.collapse} style={{height: '20px'}}>
                            <div>
                                <div style={{fontSize: '12px'}}>
                                    {t("notification.labels.default-notifications-section")}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{padding: '5px 0 0 0'}}>
                        <div className={`mgb-10 ${cl.checkboxRow}`}>
                            <Checkbox checked={true}
                                      title={t("notification.labels.notify-new-free-offers")}
                                      change={() => {
                                          //ignore
                                      }}
                                      disabled={true}/>

                            <label className={'view-label'}>
                                {t("notification.labels.notify-new-free-offers")}
                                <span>
                            <Tooltip id={`messengers-notify-new-free-offers`}
                                     style={{maxWidth: '400px', zIndex: '1004'}}
                                     place={'right'}/>
                            <FontAwesomeIcon data-tooltip-id={`messengers-notify-new-free-offers`}
                                             data-tooltip-html={t("notification.labels.notify-new-free-offers-tooltip")}
                                             icon={faCircleInfo}
                                             className={cl.tooltip}/>
                        </span>
                            </label>
                        </div>

                        <div className={`mgb-10 ${cl.checkboxRow}`}>
                            <Checkbox checked={true}
                                      title={t("notification.labels.notify-new-fix-rate-offers")}
                                      change={() => {
                                          //ignore
                                      }}
                                      disabled={true}/>

                            <label className={'view-label'}>
                                {t("notification.labels.notify-new-fix-rate-offers")}
                                <span>
                            <Tooltip id={`messengers-notify-new-free-offers`}
                                     style={{maxWidth: '400px', zIndex: '1004'}}
                                     place={'right'}/>
                            <FontAwesomeIcon data-tooltip-id={`messengers-notify-new-free-offers`}
                                             data-tooltip-html={t("notification.labels.notify-new-fix-rate-offers-tooltip")}
                                             icon={faCircleInfo}
                                             className={cl.tooltip}/>
                        </span>
                            </label>
                        </div>
                    </div>
                </div>

                {
                    _.chain(setups.filter(e => e.category === EmailCategory.Quote || e.category === EmailCategory.Shipment || e.category === EmailCategory.OrderRelease))
                        .groupBy(e => e.category)
                        .map((value, key) => ({value, key}))
                        .sort((a, b) => {
                            return (Number(a.key) - Number(b.key));
                        })
                        .value()
                        .map(item => {
                            let category: string;

                            if (item.key === '0' || item.key === '1' || item.key === '2' || item.key === '7') {
                                category = t(`notification.category.${item.key}`);
                            } else {
                                category = t("notification.category.0");
                            }

                            return (
                                <div key={item.key} className={'mgb-10'}>
                                    <div className={'default-form-collapse-header'}>
                                        <div className={cl.collapse} style={{height: '20px'}}>
                                            <div>
                                                <div style={{fontSize: '12px'}}>
                                                    {category}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{padding: '5px 0 0 0'}}>
                                        {item.value.map(setup => {
                                            let name = '';
                                            let description = '';

                                            if (setup.nameLocales.length > 0) {
                                                let nameLocale = setup.nameLocales.find(e => e.value === i18n.language.toLowerCase());

                                                if (nameLocale) {
                                                    name = nameLocale.text;
                                                }
                                            }

                                            if (setup.descriptionLocales.length > 0) {
                                                let descriptionLocale = setup.descriptionLocales.find(e => e.value === i18n.language.toLowerCase());

                                                if (descriptionLocale) {
                                                    description = descriptionLocale.text;
                                                }
                                            }

                                            return (
                                                <div key={setup.id} className={`mgb-10 ${cl.checkboxRow}`}>
                                                    <Checkbox checked={setup.isEmail}
                                                              title={name}
                                                              change={(val) => onSetupChange(setup, val)}
                                                              disabled={disabled}/>

                                                    <label className={'view-label'}>
                                                        {name}
                                                        <span>
                                                            <Tooltip id={`setup-${item.key}-${setup.id}`}
                                                                     style={{maxWidth: '400px', zIndex: '1004'}}
                                                                     place={'right'}/>
                                                            <FontAwesomeIcon
                                                                data-tooltip-id={`setup-${item.key}-${setup.id}`}
                                                                data-tooltip-content={description}
                                                                icon={faCircleInfo}
                                                                className={cl.tooltip}/>
                                                        </span>
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })
                }

                {setups.filter(e => e.category !== EmailCategory.Quote && e.category !== EmailCategory.Shipment && e.category !== EmailCategory.OrderRelease).length > 0
                    ? <div className={'mgb-10'}>
                        <div className={'default-form-collapse-header'}>
                            <div className={cl.collapse} style={{height: '20px'}}>
                                <div>
                                    <div style={{fontSize: '12px'}}>
                                        {t("notification.category.0")}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{padding: '5px 0 0 0'}}>
                            {
                                _.chain(setups.filter(e => e.category !== EmailCategory.Quote && e.category !== EmailCategory.Shipment && e.category !== EmailCategory.OrderRelease))
                                    .groupBy(e => e.category)
                                    .map((value, key) => ({value, key}))
                                    .sort((a, b) => {
                                        return (Number(a.key) - Number(b.key));
                                    })
                                    .value()
                                    .map(item => {
                                        return (
                                            <React.Fragment key={item.key}>
                                                {item.value.map(setup => {
                                                    let name = '';
                                                    let description = '';

                                                    if (setup.nameLocales.length > 0) {
                                                        let nameLocale = setup.nameLocales.find(e => e.value === i18n.language.toLowerCase());

                                                        if (nameLocale) {
                                                            name = nameLocale.text;
                                                        }
                                                    }

                                                    if (setup.descriptionLocales.length > 0) {
                                                        let descriptionLocale = setup.descriptionLocales.find(e => e.value === i18n.language.toLowerCase());

                                                        if (descriptionLocale) {
                                                            description = descriptionLocale.text;
                                                        }
                                                    }

                                                    return (
                                                        <div key={setup.id} className={`mgb-10 ${cl.checkboxRow}`}>
                                                            <Checkbox checked={setup.isEmail}
                                                                      title={name}
                                                                      change={(val) => onSetupChange(setup, val)}
                                                                      disabled={disabled}/>

                                                            <label className={'view-label'}>
                                                                {name}
                                                                <span>
                                                            <Tooltip id={`setup-${item.key}-${setup.id}`}
                                                                     style={{maxWidth: '400px', zIndex: '1004'}}
                                                                     place={'right'}/>
                                                            <FontAwesomeIcon
                                                                data-tooltip-id={`setup-${item.key}-${setup.id}`}
                                                                data-tooltip-content={description}
                                                                icon={faCircleInfo}
                                                                className={cl.tooltip}/>
                                                        </span>
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </React.Fragment>
                                        );
                                    })
                            }
                        </div>
                    </div>
                    : null
                }
            </div>
        </div>
    );
};

export default EmailNotificationSection;
