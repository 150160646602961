import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import cl from '../../NotificationForm/NotificationForm.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faViber} from "@fortawesome/free-brands-svg-icons";
import QRCode from "react-qr-code";
import {INotificationGetAction} from "../../../../../../../app/interfaces/notification/INotificationGetAction";
import UnsubscribeNotificationsDialog from "../../../actions/UnsubscribeNotifications/UnsubscribeNotificationsDialog";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

type ViberSubscriptionGuideSectionProps = {
    model: INotificationGetAction;
    reloadForm: () => Promise<void>;
};

const ViberSubscriptionGuideSection: React.FC<ViberSubscriptionGuideSectionProps> = ({
                                                                                         model,
                                                                                         reloadForm
                                                                                     }) => {
    const {t} = useTranslation();

    const [isUnsubscribeDialogShown, setIsUnsubscribeDialogShown] = useState<boolean>(false);

    return (
        <>
            <UnsubscribeNotificationsDialog onUnsubscribe={() => reloadForm()}
                                            type={'Viber'}
                                            show={isUnsubscribeDialogShown}
                                            setShow={setIsUnsubscribeDialogShown}/>

            <div>
                <div className={'default-form-collapse-header'}>
                    <div className={cl.collapse}>
                        <div>
                            <div className={cl.viber}>
                                <FontAwesomeIcon icon={faViber as IconProp}/>
                            </div>
                            <div>
                                {t("notification.labels.viber")}
                            </div>
                        </div>
                        <div>
                            {model.hasViber &&
                                <button type={'button'}
                                        className={cl.button}
                                        onClick={() => setIsUnsubscribeDialogShown(prev => !prev)}>
                                    {t("notification.btn.unsubscribe")}
                                </button>
                            }
                        </div>
                    </div>
                </div>

                <div style={{padding: '5px 5px 0 5px'}}>
                    <div className={`mgb-10 mgt-10 ${cl.qr}`}>
                        <div>
                            <QRCode
                                size={256}
                                style={{height: "auto", maxWidth: "100%", width: "100%"}}
                                value={model.viberLink}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                    </div>

                    <p className={cl.paragraph}>
                        {t("notification.labels.viber-guide-main-label")}
                    </p>

                    <p className={cl.paragraph}>
                        {`1. ${t("notification.labels.viber-guide-step-1")}`}
                    </p>

                    <p className={cl.paragraph}>
                        {`${t("notification.labels.viber-guide-step-1-1")} `}
                        <a target={'_blank'}
                           className={cl.link}
                           href={model.viberLink}>
                            {model.viberBotName}
                        </a>
                        {` ${t("notification.labels.viber-guide-step-1-2")}`}
                    </p>

                    <p className={cl.paragraph}>
                        {`${t("notification.labels.viber-guide-step-1-3").replace('{0}', model.viberBotName)}`}
                    </p>

                    <p className={cl.paragraph}>
                        {`2. ${t("notification.labels.viber-guide-step-2")}`}
                    </p>

                    <p className={cl.paragraph}>
                        {`3. ${t("notification.labels.viber-guide-step-3")}`}
                    </p>
                </div>
            </div>
        </>
    );
};

export default ViberSubscriptionGuideSection;