import React, {useEffect, useState} from 'react';
import BackgroundImageContainer from "../../../../../components/ui/BackgroundImageContainer/BackgroundImageContainer";
import bg_image from "../../../../../assets/images/login-bg.jpg";
import Container from "../../../../../components/ui/Container/Container";
import Card, {CardAppearAnimationType} from "../../../../../components/ui/Card/Card";
import cl from "./SelfBillingStart.module.css";
import LanguageSwitcher from "../../../../../components/ui/LanguageSwitcher/LanguageSwitcher";
import CopyRightFooter from "../../../../../components/ui/CopyRightFooter/CopyRightFooter";
import useDefaultSiteLanguages from "../../../../../hooks/useDefaultSiteLanguages/useDefaultSiteLanguages";
import useMediaQuery from "../../../../../hooks/useMediaQuery/useMediaQuery";
import {useTranslation} from "react-i18next";
import useDocumentTitle from "../../../../../hooks/useDocumentTitle/useDocumentTitle";
import {useDocumentApi} from "../../../../../app/api/document";
import {useDownloadFile} from "../../../../../hooks/useDownloadFile/useDownloadFile";
import {IResponse, isResponse} from "../../../../../app/interfaces/response/IResponse";
import {isAxiosError} from "axios";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {Tooltip} from "react-tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import useSelfBillingApi from "../../../../../app/api/selfBilling";
import {useAppSelector} from "../../../../../app/store";
import {selectUserAuthenticationStatus} from "../../../../../features/authentication/authenticationSliceSelectors";
import {AuthenticationStatus} from "../../../../../features/authentication/IAuthenticationState";
import LoadingScreen from "../../../../../components/ui/loaders/LoadingScreen/LoadingScreen";

const SelfBillingStart: React.FC = () => {
    const {i18n, t} = useTranslation();
    const navigate = useNavigate();

    useDocumentTitle({title: t("self-billing.labels.start-page-title")});

    const languages = useDefaultSiteLanguages();

    const isMaxWidth470 = useMediaQuery('(max-width:450px)');
    const isMaxHeight490 = useMediaQuery('(max-height:490px)');

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const authenticationStatus = useAppSelector(selectUserAuthenticationStatus);

    const {
        getDocument: {
            query: getDocument
        }
    } = useDocumentApi();

    const {
        getSelfBillingOverviewAnonymous: {
            query: getSelfBillingOverview
        }
    } = useSelfBillingApi();

    useEffect(() => {
        if (authenticationStatus !== AuthenticationStatus.Authenticated) {
            setIsLoading(false);

            return;
        }

        (async () => {
            try {
                const response = await getSelfBillingOverview();

                if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok && response.data.data) {
                    navigate(`/shipment/${response.data.data.shipmentGid}/${response.data.data.orderReleaseXid}?t=2`);
                }
            } catch {
                // ignore
            }

            setIsLoading(prev => !prev);
        })();
    }, [authenticationStatus]);

    const {
        download
    } = useDownloadFile({
        target: '_blank',
        getBlob: async (param?: string) => {
            if (!param) {
                return;
            }

            try {
                const response = await getDocument(param);

                if (response?.status === 200 && !isResponse(response.data)) {
                    return response.data;
                }
            } catch (err) {
                if (isAxiosError(err) && err.response && err.response.data && err.response.data.type === "application/json") {
                    const textResponse = await err.response?.data?.text();

                    if (textResponse && typeof textResponse === 'string') {
                        let response = JSON.parse(textResponse) as IResponse;

                        if (response.resultCode === ResponseResultCode.NotValidData) {
                            toast.error(`Unable to download file. Error: Input parameter is invalid.`)
                        }

                        if (response.resultCode === ResponseResultCode.NotFound) {
                            toast.error(`Unable to download file. Error: File with this name is not found.`)
                        }
                    }
                }
            }
        }
    });

    if (isLoading) {
        return <LoadingScreen/>;
    }

    return (
        <BackgroundImageContainer image={bg_image}>
            <Container centerContent={true} height={'calc(100% - 30px)'}>
                <Card cardAppearAnimationType={CardAppearAnimationType.FadeIn}
                      style={isMaxWidth470 ? {width: 'calc(100% - 20px)'} : {width: '450px'}}>

                    <div className={cl.linkBtns}>
                        <div className={cl.languageSwitcherContainer}>
                            <LanguageSwitcher languages={languages} languageIconPosition={'right'}/>
                        </div>
                        <div className={cl.linkBtns}>
                            <button onClick={async () => {
                                let file = '';
                                switch (i18n.language.toLowerCase()) {
                                    case "ru":
                                        file = 'SB INSTR NEW RU.pdf';
                                        break;
                                    case "pl":
                                        file = 'SB INSTR NEW PL.pdf';
                                        break;
                                    case "en":
                                        file = 'SB INSTR NEW EN.pdf';
                                        break;
                                }

                                await download(file);
                            }}>
                                {t("self-billing.labels.manual")}
                            </button>
                        <button onClick={() => navigate('/selfbilling/support')}>
                            {t("header.help")}
                        </button>


                        </div>
                    </div>
                    <div className={cl.logo}
                         style={isMaxHeight490 ? {display: 'none'} : undefined}>
                        <img className={'prevent-selection'}
                             src={'/images/logo_main.png'}
                             alt={'logo'}/>
                    </div>

                    <div className={`${cl.label} prevent-selection`}>
                        {t("self-billing.labels.start-page-label-1")}
                        <div>
                            {`(${t("self-billing.labels.start-page-label-2")})`}
                        </div>
                    </div>

                    <div className={'pd-10'}>
                        <div className={cl.btns}>
                            <div>
                                <Button buttonType={ButtonType.Primary}
                                        style={{minWidth: '250px'}}
                                        onClick={() => navigate('/selfbilling/confirmation')}>
                                    {t("self-billing.btn.only-self-billing")}
                                </Button>

                                <Tooltip id={`self-billing-tooltip`}
                                         style={{maxWidth: '400px', zIndex: '1004', fontSize: '12px'}}
                                         place={'right'}/>
                                <FontAwesomeIcon data-tooltip-id={`self-billing-register-tooltip`}
                                                 data-tooltip-content={t("self-billing.labels.self-billing-tooltip")}
                                                 icon={faCircleInfo}
                                                 className={cl.tooltip}/>
                            </div>

                            <div>
                                <Button buttonType={ButtonType.Secondary}
                                        style={{minWidth: '250px'}}
                                        onClick={() => navigate('/selfbilling/login')}>
                                    {t("shared.btn.login")}
                                </Button>

                                <Tooltip id={`self-billing-login-tooltip`}
                                         style={{maxWidth: '400px', zIndex: '1004', fontSize: '12px'}}
                                         place={'right'}/>
                                <FontAwesomeIcon data-tooltip-id={`self-billing-login-tooltip`}
                                                 data-tooltip-content={t("self-billing.labels.login-tooltip")}
                                                 icon={faCircleInfo}
                                                 className={cl.tooltip}/>
                            </div>

                            <div>
                                <Button buttonType={ButtonType.Secondary}
                                        style={{minWidth: '250px'}}
                                        onClick={() => navigate('/selfbilling/registration', {
                                            state: {
                                                fromSelfBilling: true
                                            }
                                        })}>
                                    {t("login.btn.register")}
                                </Button>

                                <Tooltip id={`self-billing-register-tooltip`}
                                         style={{maxWidth: '400px', zIndex: '1004', fontSize: '12px'}}
                                         place={'right'}/>
                                <FontAwesomeIcon data-tooltip-id={`self-billing-register-tooltip`}
                                                 data-tooltip-content={t("self-billing.labels.register-tooltip")}
                                                 icon={faCircleInfo}
                                                 className={cl.tooltip}/>
                            </div>
                        </div>




                    </div>
                </Card>
            </Container>
            <CopyRightFooter/>
        </BackgroundImageContainer>
    );
};

export default SelfBillingStart;
