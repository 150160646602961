import {IApplicationHubMessage} from "./IApplicationHubMessage";

export const processApplicationHubMessage = (args: IApplicationHubMessage): void => {
    switch (args.entity) {
        case 'shipment':
            const shipmentEvent = new CustomEvent('onShipmentApplicationHubMessage', {
                detail: args
            });

            document.dispatchEvent(shipmentEvent);
            break;
        case 'quote':
            const quoteEvent = new CustomEvent('onQuoteApplicationHubMessage', {
                detail: args
            });

            document.dispatchEvent(quoteEvent);
            break;
        case 'offer':
            const offerEvent = new CustomEvent('onOfferApplicationHubMessage', {
                detail: args
            });

            document.dispatchEvent(offerEvent);
            break;
    }
}