import React, {useEffect, useState} from 'react';
import {OrderType} from "../../../../../../app/enums/OrderType";
import {useTranslation} from "react-i18next";
import {IError} from "../../../../../../app/interfaces/response/IResponse";
import {useTagApi} from "../../../../../../app/api/tag";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../uiLayouts/ToastLayout/ToastLayout";
import {isAxiosError} from "axios";
import FormLoadingScreen from "../../../../../ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {IAssociateTagGetAction} from "../../../../../../app/interfaces/tag/IAssociateTagGetAction";
import {BLErrorCode} from "../../../../../../app/enums/BLErrorCode";
import Alert from "../../../../../ui/Alert/Alert";
import Button, {ButtonType} from "../../../../../ui/Button/Button";
import {ITag} from "../../../../../../app/interfaces/tag/ITag";
import AssociateTagContent from "./AssociateTagContent/AssociateTagContent";
import Tag, {TagItemBackgroundColor} from "../../../../../ui/Tag/Tag";

type AssociateTagProps = {
    orderReleaseGid: string;
    orderType: OrderType;
    close?: () => void;
};

const AssociateTag: React.FC<AssociateTagProps> = ({
                                                       orderReleaseGid,
                                                       orderType,
                                                       close
                                                   }) => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError>>([]);
    const [model, setModel] = useState<IAssociateTagGetAction | null>(null);

    const [selectedTags, setSelectedTags] = useState<Array<ITag>>([]);

    const {
        associateTag: {
            mutation: associateTag,
            cancel: cancelAssociateTag
        },
        getAssociateTagData: {
            query: getAssociateTagData,
            cancel: cancelGetAssociateTagData,
            isLoading
        }
    } = useTagApi();

    useEffect(() => {
        if (!orderReleaseGid || orderReleaseGid === '') {
            if (close) {
                close()
            } else {
                return;
            }
        }

        (async () => {
            try {
                const response = await getAssociateTagData(orderReleaseGid, orderType);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch {
                if (close) {
                    close();
                } else {
                    toast.error<string>('Unable to get associate tag data');
                }
            }
        })();

        return () => {
            cancelGetAssociateTagData();
            cancelAssociateTag();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = async (): Promise<void> => {
        setErrors([]);

        if (!model || selectedTags.length <= 0) {
            return;
        }

        setDisabled(prev => !prev);

        try {
            const response = await associateTag({
                orderReleaseGid: model.orderReleaseGid,
                orderType: model.orderType,
                tagsIds: selectedTags.map(item => item.id)
            });

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                let rows = model.orderReleaseGid.split(';').map(item => {
                    return {
                        id: item,
                        isRowLoading: true
                    };
                });

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: rows
                    }
                }));

                let updateTitle = '';
                let gid = model.orderReleaseGid.split(';').length >= 1
                    ? model.orderReleaseGid.split(';')[0].replace('AAG.', '')
                    : '';
                if (orderType === OrderType.OrderRelease) {
                    updateTitle = `${t("shared.labels.offer")} ${gid}`;
                } else if (orderType === OrderType.Quote) {
                    updateTitle = `${t("shared.labels.quote")} ${gid}`;
                } else if (orderType === OrderType.Shipment) {
                    updateTitle = `${t("shared.labels.shipment")} ${gid}`;
                }

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${updateTitle}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotFound &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();

                    toast.error(err.message);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if (!orderReleaseGid || orderReleaseGid === '') {
        return null;
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>

            <div className={'dialog-default-content-container'}>
                {errors.map(item => {
                    switch (item.errorCode) {
                        case BLErrorCode.InnerError:
                            return (
                                <Alert type={'Error'}>
                                    {item.description}
                                </Alert>
                            );
                    }

                    return null;
                })}

                <AssociateTagContent model={model}
                                     disabled={disabled}
                                     setDisabled={setDisabled}
                                     selectedTags={selectedTags}
                                     onTagSelect={(items) => {
                                         setSelectedTags(items);
                                     }}/>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            style={{minWidth: '100px'}}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled || selectedTags.length <= 0}
                        style={{minWidth: '150px'}}
                        onClick={submit}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.save")}
                </Button>
            </div>
        </div>
    );
};

export default AssociateTag;
