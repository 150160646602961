export const quoteCancelReasons: Array<any> = [
    {
        value: 'AAG.DECLINE_BAD_TERMS',
        labelResource: 'quote.cancelReason.decline-bad-terms'
    },
    {
        value: 'AAG.DECLINE_OUT_OF_DATE',
        labelResource: 'quote.cancelReason.decline-out-of-date'
    },
    {
        value: 'AAG.DECLINE_SERVICE_OUT_OF_DATE',
        labelResource: 'quote.cancelReason.decline-service-out-of-date'
    },
    {
        value: 'AAG.DECLINE_NOT_ENOUGH_INFO',
        labelResource: 'quote.cancelReason.decline-not-enough-info'
    },
    {
        value: 'AAG.DECLINE_NOT_HAVE_EXPERIENCE',
        labelResource: 'quote.cancelReason.decline-not-have-experience'
    },
    {
        value: 'AAG.DECLINE_PROCESSED_BEFORE',
        labelResource: 'quote.cancelReason.decline-processed-before'
    },
    {
        value: 'AAG.DECLINE_RECEIVED_BEFORE',
        labelResource: 'quote.cancelReason.decline-receiver-before'
    },
    {
        value: 'AAG.DECLINE_REDIRECT_QUOTE',
        labelResource: 'quote.cancelReason.decline-redirect-quote'
    },
    {
        value: 'AAG.DECLINE_CHANGE_MARKET_PRICE',
        labelResource: 'quote.cancelReason.decline-change-market-price'
    },
];