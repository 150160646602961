import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'circleDollar',
    circle: [{
        cx: 8,
        cy: 8,
        r: 8
    }],
    pathDataExtended: [{
        data: 'M7.70501 13V3H8.41744V13H7.70501ZM7.9833 11.8085C7.38961 11.8085 6.82189 11.7234 6.28015 11.5532C5.7384 11.3759 5.31169 11.1489 5 10.8723L5.41187 10.0426C5.70872 10.2908 6.0872 10.4965 6.54731 10.6596C7.00742 10.8227 7.48609 10.9043 7.9833 10.9043C8.43599 10.9043 8.80334 10.8546 9.08534 10.7553C9.36735 10.656 9.57514 10.5213 9.70872 10.3511C9.8423 10.1738 9.90909 9.97518 9.90909 9.75532C9.90909 9.5 9.82004 9.29433 9.64193 9.1383C9.47124 8.98227 9.2449 8.85816 8.96289 8.76596C8.68831 8.66667 8.38404 8.58156 8.05009 8.51064C7.71614 8.43972 7.37848 8.35816 7.03711 8.26596C6.70315 8.16667 6.39518 8.04255 6.11317 7.89362C5.83859 7.74468 5.61596 7.5461 5.44527 7.29787C5.27458 7.04255 5.18924 6.71631 5.18924 6.31915C5.18924 5.93617 5.29314 5.58511 5.50093 5.26596C5.71614 4.93972 6.04267 4.68085 6.48052 4.48936C6.92579 4.29078 7.4898 4.19149 8.17254 4.19149C8.62523 4.19149 9.07421 4.24823 9.51948 4.3617C9.96475 4.47518 10.3506 4.6383 10.6772 4.85106L10.3098 5.70213C9.97588 5.48936 9.62338 5.33688 9.25232 5.24468C8.88126 5.14539 8.52134 5.09574 8.17254 5.09574C7.73469 5.09574 7.37477 5.14894 7.09276 5.25532C6.81076 5.3617 6.60297 5.50355 6.46939 5.68085C6.34323 5.85816 6.28015 6.05674 6.28015 6.2766C6.28015 6.53901 6.36549 6.74823 6.53618 6.90426C6.71429 7.06028 6.94063 7.1844 7.21521 7.2766C7.49722 7.36879 7.80519 7.4539 8.13915 7.53192C8.4731 7.60284 8.80705 7.6844 9.141 7.7766C9.48237 7.86879 9.79035 7.98936 10.0649 8.1383C10.3469 8.28723 10.5733 8.48582 10.744 8.73404C10.9147 8.98227 11 9.30142 11 9.69149C11 10.0674 10.8924 10.4184 10.6772 10.7447C10.462 11.0638 10.128 11.3227 9.67532 11.5213C9.23006 11.7128 8.66605 11.8085 7.9833 11.8085Z',
        fill: 'white'
    }],
    viewBox: '0 0 16 16'
};

export default icon;