import React, {useState} from 'react';
import {UserRole} from "../../../../../../app/enums/UserRole";
import {IChatReplyTo} from "../../../../../../app/interfaces/chat/IChat";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMessage} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../../app/store";
import {selectChatSessions, selectIsChatSocketConnected} from "../../../../../../features/chat/chatSliceSelectors";
import {getUserOnlineStatus} from "../../utils";
import SelectUserChatDialog from "../../actions/SelectUserChat/SelectUserChatDialog";
import useChatManagerContext from "../../hooks/useChatManagerContext";
import Icon from "../../../../../../assets/icon/Icon";

type UserChatProps = {
    user: {
        role: UserRole,
        id: string;
        serviceProvider?: string;
        groupId?: string;
        skipServiceProviderList?: boolean;
    },
    replyTo?: IChatReplyTo;
};

const UserChat: React.FC<UserChatProps> = (props) => {
    const sessions = useAppSelector(selectChatSessions);
    const isChatConnected = useAppSelector(selectIsChatSocketConnected);
    const {t} = useTranslation();
    const {sendMessage} = useChatManagerContext();
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);

    if (!props.user.id || props.user.id === '' ||
        (props.user.role !== UserRole.ServiceProvider &&
            props.user.role !== UserRole.Administrator &&
            props.user.role !== UserRole.Forwarder)) {
        return null;
    }

    const handleClick = (ev: any) => {

        ev.stopPropagation();

        if (!isChatConnected) {
            return;
        }

        if (props.user.role === UserRole.ServiceProvider) {
            if (!props.user.serviceProvider || props.user.serviceProvider === '') {
                return;
            }

            if (props.user.skipServiceProviderList) {
                sendMessage(props.user.id, props.replyTo);
            } else {
                setIsDialogShown(prev => !prev);
            }
        } else {
            if (!props.user.id || props.user.id === '') {
                return;
            }

            let ids = props.user.id.split(';');

            if (ids.length > 1) {
                setIsDialogShown(prev => !prev);
            } else {
                sendMessage(props.user.id, props.replyTo);
            }
        }
    }

    let isOnline = getUserOnlineStatus({
        ...props.user
    }, sessions);

    return (
        <>
            <SelectUserChatDialog role={props.user.role}
                                  ids={props.user.role === UserRole.ServiceProvider
                                      ? (props.user.serviceProvider ?? '')
                                      : props.user.id}
                                  show={isDialogShown}
                                  setShow={setIsDialogShown}
                                  onSelect={(data) => {

                                      setIsDialogShown(prev => !prev);

                                      sendMessage(data.login, props.replyTo);
                                  }}/>

            <Icon icon={"faMessage"}
                             className={`user-chat-icon grid-prevent-selection ${isOnline ? 'chat-online' : 'chat-offline'}`}
                             title={t("shared.labels.send-message")}
                             onClick={(ev) => handleClick(ev)}
            />
        </>
    );
};

export default UserChat;
