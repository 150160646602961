import React from 'react';
import {useTranslation} from "react-i18next";

type CityProps = {
    city: string | null;
}

const City: React.FC<CityProps> = ({city}) => {
    const {t} = useTranslation();

    return (
        <>
            {city !== null && city !== ''
                ? <>{city}</>
                : <>{t("shared.labels.no-data")}</>
            }
        </>
    );
};

export default City;