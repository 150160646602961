import React, {useEffect, useState} from 'react';
import cl from '../RestorePassword.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useGoogleReCaptchaV3 from "../../../../../hooks/useGoogleReCaptchaV3/useGoogleReCaptchaV3";
import {useAccountApi} from "../../../../../app/api/account";
import useDefaultSiteLanguages from "../../../../../hooks/useDefaultSiteLanguages/useDefaultSiteLanguages";
import useMediaQuery from "../../../../../hooks/useMediaQuery/useMediaQuery";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {FormikHelpers, useFormik} from "formik";
import {IRestore} from "../../../../../app/interfaces/account/IRestore";
import * as Yup from "yup";
import {BLErrorCode} from "../../../../../app/enums/BLErrorCode";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import bg_image from "../../../../../assets/images/login-bg.jpg";
import Container from "../../../../../components/ui/Container/Container";
import Card from "../../../../../components/ui/Card/Card";
import LanguageSwitcher from "../../../../../components/ui/LanguageSwitcher/LanguageSwitcher";
import Alert from "../../../../../components/ui/Alert/Alert";
import LoginInput from "../../../../../components/ui/LoginInput/LoginInput";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import CopyRightFooter from "../../../../../components/ui/CopyRightFooter/CopyRightFooter";
import BackgroundImageContainer from "../../../../../components/ui/BackgroundImageContainer/BackgroundImageContainer";

const alertStyle: React.CSSProperties = {
    margin: '0px 0px 5px 0px',
    fontSize: '11px',
    padding: '5px 10px',
    fontWeight: 'bold',
    justifyContent: 'center',
    textAlign: 'center'
};

const RestorePasswordApplication: React.FC = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const {loaded, executeReCaptcha} = useGoogleReCaptchaV3({
        siteKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '',
        language: i18n.language
    });
    const {
        restorePassword: {
            mutation
        }
    } = useAccountApi();

    const languages = useDefaultSiteLanguages();
    const isMaxWidth470 = useMediaQuery('(max-width:450px)');
    const isMaxHeight490 = useMediaQuery('(max-height:490px)');
    const [errors, setErrors] = useState<Array<IError>>([]);
    const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            if (Object.keys(form.errors).length > 0) {
                await form.validateForm();
            }
        })();
    }, [i18n.language]);

    const form = useFormik<IRestore>({
        initialValues: {
            login: '',
            token: ''
        },
        validationSchema: Yup.object({
            login: Yup.string()
                .required(t("shared.errors.required-error").replace('{0}', t("login.labels.login")))
                .max(100)
        }),
        onSubmit: async (values: IRestore, actions: FormikHelpers<IRestore>) => {
            try {
                setErrors([]);

                actions.setSubmitting(true);

                if (!loaded || !executeReCaptcha) {
                    setErrors([{
                        errorCode: BLErrorCode.InnerError,
                        description: t('shared.errors.google-recaptcha-loaded'),
                        field: 'summary'
                    }]);

                    return;
                }

                const token: string = await executeReCaptcha('restore/submit');

                if (!token) {
                    setErrors([{
                        errorCode: BLErrorCode.InnerError,
                        description: t('shared.error.google-recaptcha-loaded'),
                        field: 'summary'
                    }]);
                }

                const response = await mutation({
                    token,
                    login: values.login
                });

                if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                    setIsEmailSent(true);
                }
            } catch (error) {
                if (isAxiosError(error) && error.response && error.response.data) {
                    if (error.response.data.resultCode === ResponseResultCode.NotFound) {
                        setErrors([{
                            errorCode: BLErrorCode.InnerError,
                            description: t("login.errors.invalid-data-login-password"),
                            field: 'summary'
                        }]);
                    } else if (error.response.data.resultCode === ResponseResultCode.ServerError) {
                        setErrors([{
                            errorCode: BLErrorCode.InnerError,
                            description: error.response.data.message,
                            field: 'summary'
                        }]);
                    }
                }
            } finally {
                actions.setSubmitting(false);
            }
        }
    });

    return (
        <BackgroundImageContainer image={bg_image}>
            <Container centerContent={true} height={'calc(100% - 30px)'}>
                <Card style={isMaxWidth470 ? {width: 'calc(100% - 20px)'} : {width: '450px'}}>
                    <div className={cl.switcher}>
                        <LanguageSwitcher languages={languages} languageIconPosition={'right'}/>
                    </div>
                    <div className={cl.logo}
                         style={isMaxHeight490 ? {display: 'none'} : undefined}>
                        <img className={'prevent-selection'}
                             src={'/images/logo_main.png'}
                             alt={'logo'}/>
                    </div>
                    <div className={`${cl.label} prevent-selection`}>
                        {t("login.restore.restore-label")}
                    </div>

                    <div className={'pd-10'}>
                        <Alert type={'BrightGrayInfo'} style={alertStyle}>
                            {isEmailSent
                                ? <>{t("login.restore.restore-instructions-sent")}</>
                                : <>{t("login.restore.restore-instructions")}</>
                            }
                        </Alert>

                        {!isEmailSent &&
                            <>
                                {errors.map(err => {
                                    return (
                                        <Alert type={'Error'} style={alertStyle} key={err.description}>
                                            {err.description}
                                        </Alert>
                                    )
                                })}

                                <form id={'restore-password-form'}
                                      onSubmit={form.handleSubmit}>
                                    <div style={{
                                        marginTop: '20px'
                                    }}>
                                        <LoginInput id="login"
                                                    icon={faUser}
                                                    name="login"
                                                    autoComplete="login"
                                                    type='text'
                                                    disabled={form.isSubmitting}
                                                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (ev && ev.target && ev.target.value) {
                                                            form.setFieldValue('login', ev.target.value.toUpperCase());
                                                        } else {
                                                            form.setFieldValue('login', '');
                                                        }
                                                    }}
                                                    value={form.values.login}
                                                    error={form.touched.login && form.errors.login ? form.errors.login : undefined}
                                                    placeholder={t("login.labels.login") ?? ''}/>
                                    </div>
                                    <div className={cl.center}>
                                        <Button buttonType={ButtonType.Primary}
                                                type='submit'
                                                disabled={form.isSubmitting}
                                                isLoading={form.isSubmitting}
                                                style={{width: '70%'}}>
                                            {t("shared.btn.confirm")}
                                        </Button>
                                    </div>
                                </form>
                            </>
                        }

                        <hr className={cl.separator}/>

                        <div className={cl.linkBtns}>
                            <button disabled={form.isSubmitting}
                                    onClick={() => {
                                        if (form.isSubmitting) {
                                            return;
                                        }

                                        navigate('/login');
                                    }}>
                                {t("login.btn.back-to-login-page")}
                            </button>

                            {!isEmailSent &&
                                <button disabled={form.isSubmitting}
                                        onClick={() => {
                                            if (form.isSubmitting) {
                                                return;
                                            }

                                            navigate('/restore/login');
                                        }}>
                                    {t("login.btn.forgot-email-and-login")}
                                </button>
                            }
                        </div>
                    </div>
                </Card>
            </Container>
            <CopyRightFooter/>
        </BackgroundImageContainer>
    );
};

export default RestorePasswordApplication;
