import React from 'react';
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import cl from './NumericInput.module.css';
import {NumericTextBox, NumericTextBoxProps} from "@progress/kendo-react-inputs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type NumericInputProps = {
    icon?: IconProp;
} & NumericTextBoxProps;

const NumericInput: React.FC<NumericInputProps> = ({icon, ...rest}) => {
    /*if (icon !== undefined) {
        return (
            <div className={`${cl.container} numeric-text-box`}>
                <span className={cl.iconContainer}>
                    <FontAwesomeIcon icon={icon}/>
                </span>
                <NumericTextBox {...rest} />
            </div>
        );
    } else {*/
        return (
            <div className={'numeric-text-box w100'}>
                <NumericTextBox {...rest} />
            </div>
        );
   // }
};

export default NumericInput;
