import React from 'react';
import {useTranslation} from "react-i18next";

type CostProps = {
    cost: number | null;
}

const Cost: React.FC<CostProps> = ({
                                       cost
                                   }) => {
    const {t} = useTranslation();

    return (
        <>
            <label className={'cost-label'}>
                {t('shipment.form.cost')}
            </label>

            <span className={'details-box-item-field-value cost-value'}>
                {cost !== null && cost !== undefined && cost > 0
                    ? <>{(new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'EUR', currencyDisplay: 'code'}).format(cost))}</>
                    : <>{t("shared.labels.no-data")}</>
                }
            </span>
        </>
    );
};

export default Cost;