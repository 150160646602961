import React from 'react';
import {
    ISelfBillingInvalidVatPostAction
} from "../../../../../../app/interfaces/selfBilling/ISelfBillingInvalidVatPostAction";
import {useTranslation} from "react-i18next";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import Textarea from "../../../../../../components/ui/Textarea/Textarea";

type RequestInvalidVatFormProps = {
    orderReleaseGid: string;
    shipmentGid: string;
    onSubmit: (values: ISelfBillingInvalidVatPostAction) => Promise<void>;
};

const RequestInvalidVatForm: React.FC<RequestInvalidVatFormProps> = ({
                                                                         shipmentGid,
                                                                         onSubmit,
                                                                         orderReleaseGid
                                                                     }) => {
    const {t} = useTranslation();

    const form = useFormik<ISelfBillingInvalidVatPostAction>({
        initialValues: {
            orderReleaseGid: orderReleaseGid,
            shipmentGid: shipmentGid,
            note: ''
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: Yup.object().shape({
            note: Yup
                .string()
                .nullable()
                .required(t("shared.errors.required-error").replace('{0}', t("chat.labels.message")))
        }),
        onSubmit: async (values: ISelfBillingInvalidVatPostAction, actions: FormikHelpers<ISelfBillingInvalidVatPostAction>) => {
            actions.setSubmitting(true);

            await onSubmit(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='invalid-vat-form'
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div className={'view-row'}>
                    <label className={'view-label required'}>
                        {t("chat.labels.message")}
                    </label>

                    <Textarea id={'invalid-vat-note'}
                              name={'note'}
                              placeholder={t("chat.labels.message")}
                              disabled={form.isSubmitting}
                              value={form.values.note ?? ''}
                              autoComplete={'off'}
                              style={{minHeight: '150px', resize: 'vertical', maxHeight: '250px'}}
                              onChange={form.handleChange}/>

                    {form.errors.note &&
                        <div className={'error-label'}>
                            {form.errors.note}
                        </div>
                    }
                </div>
            </div>
        </form>
    );
};

export default RequestInvalidVatForm;