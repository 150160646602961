import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import SidebarApplicationSectionLanguageSelector
    from "./SidebarApplicationSectionLanguageSelector/SidebarApplicationSectionLanguageSelector";
import SidebarNavigationButton from "../../components/SidebarNavigationButton/SidebarNavigationButton";
import SidebarButton from "../../components/SidebarButton/SidebarButton";
import {selectCurrentUser} from "../../../../../features/authentication/authenticationSliceSelectors";
import {useAppSelector} from "../../../../../app/store";
import {getUserRole} from "../../../../../helpers/user";
import {UserRole} from "../../../../../app/enums/UserRole";
import SidebarApplicationSectionSupport from "./SidebarApplicationSectionSupport/SidebarApplicationSectionSupport";
import {isMobile} from "react-device-detect";
import {SidebarContext} from "../../Sidebar";


const SidebarApplicationSection: React.FC = () => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    const {
        expanded,
        hoverExpanded,
        setIsExpandedOnHover
    } = useContext(SidebarContext);

    return (
        <>
            <SidebarApplicationSectionLanguageSelector/>

            <SidebarNavigationButton id={'sidebar-help'} expandedId={''}
                                     type={'primary'}
                                     text={t("header.help")}
                                     title={t("header.help")}
                                     path={'/help'}
                                     icon={"faCircleQuestion"}>
                {user && getUserRole(user) === UserRole.Forwarder &&
                    <SidebarButton id={'sidebar-asstra-power-item'}
                                   type={'secondary'}
                                   text={t("header.asstra-power")}
                                   title={t("header.asstra-power")}
                                   onClick={() => window.open(process.env.REACT_APP_ASSTRA_POWER_LINK, '_blank', 'rel=noopener noreferrer')}/>
                }

                <SidebarNavigationButton id={'sidebar-help-item'}
                                         type={'secondary'}
                                         text={t("header.manual")}
                                         title={t("header.manual")}
                                         path={'/profile/help'}
                                         onClick={() => {
                                             if (isMobile && !expanded && hoverExpanded) {
                                                 setIsExpandedOnHover(false);
                                             }
                                         }}/>

                <SidebarApplicationSectionSupport/>
            </SidebarNavigationButton>
        </>
    );
};

export default SidebarApplicationSection;
