import React from 'react';
import {IOffer} from "../../../../../../../../app/interfaces/offer/IOffer";
import {useAppSelector} from "../../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../../app/enums/UserRole";
import ForwarderField from "../../../../../../../../components/shared/form/detailsFields/ForwarderField/ForwarderField";

type OfferForwarderProps = {
    offer: IOffer;
};

const OfferForwarder: React.FC<OfferForwarderProps> = ({offer}) => {
    const user = useAppSelector(selectCurrentUser);

    if (!user) {
        return null;
    }

    let role = getUserRole(user);

    if (role === UserRole.ServiceProvider) {
        if (offer.forwardingAgent === null) {
            return null;
        }

        return (
            <div className={'details-box-item-field-value'}>
                <ForwarderField user={offer.forwardingAgent}
                                chatReplyTo={{
                                    type: 'RELEASE',
                                    description: offer.chatDescription,
                                    id: '',
                                    idExt: null
                                }}/>
            </div>
        );
    }


    if (offer.forwarderUser === null) {
        return null;
    }

    return (
        <div className={'details-box-item-field-value'}>
            <ForwarderField user={offer.forwarderUser}
                            chatReplyTo={{
                                type: 'RELEASE',
                                description: offer.chatDescription,
                                id: offer.orderReleaseXid,
                                idExt: null
                            }}/>
        </div>
    );
};

export default OfferForwarder;
