import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import {useProfileApi} from "../../../../../app/api/profile";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import ProfileForm from "../ProfileForm/ProfileForm";
import {IProfileGetAction} from "../../../../../app/interfaces/profile/IProfileGetAction";
import {UserRole} from "../../../../../app/enums/UserRole";
import {useAppSelector} from "../../../../../app/store";
import {selectCurrentUser} from "../../../../../features/authentication/authenticationSliceSelectors";
import {IProfilePostAction} from "../../../../../app/interfaces/profile/IProfilePostAction";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import i18n from "i18next";

type ProfileDialogProps = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfileDialog: React.FC<ProfileDialogProps> = ({
                                                         show,
                                                         setShow
                                                     }) => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);
    const [model, setModel] = useState<IProfileGetAction | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        getProfileData: {
            cancel: cancelGetProfileData,
            query: getProfileData,
            isLoading
        },
        saveProfile: {
            cancel: cancelSaveProfile,
            mutation: saveProfile
        },
        getIsEmailNeedConfirmation: {
            query: getIsEmailNeedConfirmation
        }
    } = useProfileApi();

    useEffect(() => {
        (async () => {
            try {
                const response = await getProfileData();
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error<string>(`Unable to get profile data. Error: ${e.message}`);
                }
            }
        })();

        return () => {
            cancelGetProfileData();
            cancelSaveProfile();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!model || !user || user.role === UserRole.Forwarder || !show) {
            return;
        }

        let interval: NodeJS.Timer = setInterval(() => {
            (async () => {
                if (!model) {
                    return;
                }

                try {
                    const response = await getIsEmailNeedConfirmation();
                    if (response?.status === 200 && response.data && response.data.data !== undefined && response.data.data !== null) {
                        if (model.isNewEmailNeedConfirmation !== response.data.data) {
                            setModel({
                                ...model,
                                isNewEmailNeedConfirmation: response.data.data
                            });
                        }
                    }
                } catch {
                    // ignore
                }
            })();
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [model, user, show]);

    const save = async (item: IProfilePostAction): Promise<void> => {

        if (!model) {
            return;
        }

        setDisabled(prev => !prev);

        try {

            const response = await saveProfile(item);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                toast.success(<ToastLayout title={t("profile.labels.profile")}
                                           text={t("profile.labels.profile-saved-label")}/>);

                if (model.language !== item.language) {
                    await i18n.changeLanguage(item.language.toString().toLowerCase());
                }

                setModel({
                    ...model,
                    email: item.email,
                    phone: item.phone,
                    mobPhone: item.mobPhone,
                    language: item.language
                });

                setShow(false)
            }
        } catch (e) {

            if (isAxiosError(e)) {
                toast.error(e.message);
            }
        }

        setDisabled(prev => !prev);
    }

    return (
        <Dialog id={'support-form-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("profile.labels.profile")}>
            <div>
                {isLoading || model === null
                    ? <FormLoadingScreen height={`340px`} style={{padding: '5px'}}/>
                    : null
                }

                {show && !isLoading && model &&
                    <ProfileForm disabled={disabled} close={() => setShow(false)}
                                 model={model}
                                 save={save}/>
                }
            </div>
        </Dialog>
    );
};

export default ProfileDialog;
