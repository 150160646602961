import React from 'react';
import {IQuote} from "../../../../../../../../app/interfaces/quote/IQuote";
import cl from './QuoteSource.module.css';
import {QuoteType} from "../../../../../../../../app/enums/QuoteType";
import {ResponseResultCode} from "../../../../../../../../app/enums/ResponseResultCode";
import {useQuoteApi} from "../../../../../../../../app/api/quote";

type QuoteSourceProps = {
    quote: IQuote;
}

const QuoteSource: React.FC<QuoteSourceProps> = ({
                                                     quote
                                                 }) => {
    const {
        getCrmLink: {
            query: getCrmLink
        }
    } = useQuoteApi();

    return (
        <div className={cl.container}>
            <div className={`${cl.typeBadge} ${cl.badge} quote-type-sub-type-category`}>
                <span>
                    {QuoteType[quote.quoteType].toUpperCase()}
                </span>
                {quote.quoteSubType !== null && quote.quoteSubType !== '' &&
                    <span>
                        {`, ${quote.quoteSubType.toUpperCase()}`}
                    </span>
                }
                {quote.quoteCategory !== null && quote.quoteCategory !== '' &&
                    <span>
                        {`, ${quote.quoteCategory.toUpperCase()}`}
                    </span>
                }
            </div>
            {quote.quoteSource !== null && quote.quoteSource !== '' &&
                <div className={`${cl.sourceBadge} ${cl.badge} quote-source`}>
                    {quote.quoteSource === 'CRM' && quote.crmNumber !== null
                        ? <button className={cl.crmBtn}
                                  onClick={async (ev) => {
                                      if (!quote.crmNumber) {
                                          return;
                                      }

                                      ev.stopPropagation();

                                      try {
                                          const response = await getCrmLink(quote.crmNumber);

                                          if (response && response.data && response.data.resultCode === ResponseResultCode.Ok &&
                                              response.data.data) {
                                              window.open(response.data.data, '_blank');
                                          }
                                      } catch {
                                          //ignore
                                      }
                                  }}>
                            {quote.quoteSource}
                        </button>
                        : <>{quote.quoteSource}</>
                    }
                </div>
            }
        </div>
    );
};

export default QuoteSource;