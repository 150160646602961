import React, {MouseEventHandler, ReactNode, useCallback, useEffect, useRef} from 'react';
import {createPortal} from "react-dom";
import useOnClickOutside from "../../../hooks/useOnClickOutside/useOnClickOutside";
import {AnimatePresence, motion} from "framer-motion";
import cl from './Dialog.module.css';
import DialogLayout from "../../uiLayouts/DialogLayout/DialogLayout";

type DialogProps = {
    id: string;
    title?: string | ReactNode;
    show: boolean;
    width?: string;
    setShow: (isShown: boolean) => void;
    children: ReactNode;
    closeOnOverlay?: boolean;
    closeOnEscape?: boolean;
}

const Dialog: React.FC<DialogProps> = ({
                                           id,
                                           show,
                                           setShow,
                                           children,
                                           width,
                                           title,
                                           closeOnOverlay,
                                           closeOnEscape
                                       }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const refOverlay = useRef<HTMLDivElement | null>(null);
    useOnClickOutside(ref, () => {
        if (closeOnOverlay !== undefined && closeOnOverlay) {
            setShow(false)
        }
    });

    const handleKeyPress = useCallback((ev: KeyboardEvent) => {
        //if (closeOnEscape === undefined || !closeOnEscape) {
        //    return;
       // }

        if (ev.key === "Escape") {
            setShow(false)
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeOnEscape]);

    useEffect(() => {
        if (show) {
            document.addEventListener("keydown", handleKeyPress)

            return () => {
                document.removeEventListener("keydown", handleKeyPress)
            }
        }
    }, [handleKeyPress, show])


    const close = () => {
        setShow(false)
    }
    const closeOverlay = (e: React.MouseEvent<HTMLDivElement>) => {

        // @ts-ignore
        if(e.target.className == cl.overlay)  {

            setShow(false)
        }
    }
    return (
        createPortal(
            <AnimatePresence>
                {show &&
                    <motion.div className={cl.overlay} ref={refOverlay}
                                key={`overlay-${id}`}
                                onMouseDown={e => closeOverlay(e)}
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}
                                transition={{type: "spring", bounce: 0, duration: 0.7}}>
                        <motion.div ref={ref}
                                    style={width ? {width: width} : undefined}
                                    className={`${cl.body} ${id}-dialog-body`}
                                    key={`body-${id}`}
                                    initial={{y: 50, opacity: 0}}
                                    animate={{
                                        y: 0,
                                        opacity: 1
                                    }}
                                    exit={{
                                        y: -50,
                                        opacity: 0,
                                    }}
                                    transition={{type: "spring", bounce: 0, duration: 1}}>
                            {title
                                ? <DialogLayout onCloseButtonClick={close}
                                                title={title}>
                                    {children}
                                </DialogLayout>
                                : <>{children}</>
                            }
                        </motion.div>
                    </motion.div>
                }
            </AnimatePresence>,
            document.body)
    );
};

export default Dialog;
