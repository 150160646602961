import {IUser} from "../../../../../app/interfaces/user/IUser";
import {IChat} from "../../../../../app/interfaces/chat/IChat";
import {IChatSocketCommand} from "../../../../../app/types/ChatSocketCommand";
import {UserRole} from "../../../../../app/enums/UserRole";

export const sendChatMessageCommand = (user: IUser, chat: IChat, message: string): IChatSocketCommand => ({
    cmd: 'msg',
    message,
    user: user.name,
    userId: user.uniqueUserValue ?? user.idStr ?? '',
    toUser: chat.name,
    toUserId: chat.id,
    firm: user.role === UserRole.ServiceProvider ? (user.serviceProvider?.name ?? '') : (user.departmentFull ?? ''),
    toFirm: chat.firm,
    linkedType: chat.replyTo?.type ?? undefined,
    linkedId: chat.replyTo?.id ?? undefined,
    linkedIdExt: chat.replyTo?.idExt ?? undefined,
    linkedDesc: chat.replyTo?.description ?? undefined
});
