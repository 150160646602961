import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import ApproveShipment from "./ApproveShipment";

type ApproveShipmentDialogProps = {
    id: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ApproveShipmentDialog: React.FC<ApproveShipmentDialogProps> = ({
                                                                       id,
                                                                       show,
                                                                       setShow
                                                                   }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'approve-shipment-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("self-billing.btn.approve")}>
            <ApproveShipment sId={id}
                            close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default ApproveShipmentDialog;
