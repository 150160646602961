import React, {useState} from 'react';
import {ISelfBilling} from "../../../../../../../app/interfaces/selfBilling/ISelfBilling";
import cl from './SelfBillingDetailsBox.module.css';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {getUserRole} from "../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../app/enums/UserRole";
import {IUser} from "../../../../../../../app/interfaces/user/IUser";
import ForwarderField from "../../../../../../../components/shared/form/detailsFields/ForwarderField/ForwarderField";
import EditBillingDataDialog from "../../../../actions/EditBillingData/EditBillingDataDialog";
import FormCollapse from "../../../../../../../components/ui/Collapse/FormCollapse";
import Icon from "../../../../../../../assets/icon/Icon";
import useMediaQuery from "../../../../../../../hooks/useMediaQuery/useMediaQuery";

type SelfBillingDetailsBoxProps = {
    isAnonymousAccess: boolean;
    isSidePanelDetails: boolean;
    model: ISelfBilling;
    user?: IUser;
};

const buildStartDate = (model: ISelfBilling): string | null => {
    if (model.hasShipment && model.earlyPickupDate !== null) {
        return moment(model.earlyPickupDate).format('DD.MM.YYYY HH:mm');
    }

    if (model.earlyPickupDate === null) {
        return null;
    }

    let result = moment(model.earlyPickupDate).format('DD.MM.YYYY HH:mm');

    if (model.latePickupDate !== null && model.latePickupDate.getTime() !== model.earlyPickupDate.getTime()) {
        result += ` - ${moment(model.latePickupDate).format('DD.MM.YYYY HH:mm')}`;
    }

    return result;
}

const buildEndDate = (model: ISelfBilling): string | null => {
    if (model.hasShipment) {
        return model.lateDeliveryDate !== null
            ? moment(model.lateDeliveryDate).format('DD.MM.YYYY HH:mm')
            : null;
    }

    let sp: string, ep: string;

    if (model.earlyDeliveryDate !== null && (model.lateDeliveryDate === null || (model.earlyDeliveryDate.getTime() !== model.lateDeliveryDate.getTime()))) {
        sp = moment(model.earlyDeliveryDate).format('DD.MM.YYYY HH:mm');
    } else {
        sp = '';
    }

    if (model.lateDeliveryDate !== null) {
        ep = moment(model.lateDeliveryDate).format('DD.MM.YYYY HH:mm');
    } else {
        ep = '';
    }

    if (sp == ep && sp == "")
        return null;

    if (sp === '')
        return ep;

    if (ep === '')
        return sp;

    return sp + " - " + ep;
}

const copy = async (text: string) => {
    try {
        await navigator.clipboard.writeText(text);
    } catch {
    }
};

const SelfBillingDetailsBox: React.FC<SelfBillingDetailsBoxProps> = ({
                                                                         model,
                                                                         isAnonymousAccess,
                                                                         user,
                                                                         isSidePanelDetails
                                                                     }) => {
    const isMobileView = useMediaQuery('(max-width:1024px)');

    const {t} = useTranslation();

    const [isEditBillingDataDialogShown, setIsEditBillingDataDialogShown] = useState<boolean>(false);

    return (
        <>
            <EditBillingDataDialog shipmentGid={model.shipmentGid}
                                   show={isEditBillingDataDialogShown}
                                   setShow={setIsEditBillingDataDialogShown}
                                   user={user}/>

            <FormCollapse title={t("self-billing.labels.confirmation-page-title")}
                          defaultExpanded={true}
                          button={isAnonymousAccess || (user && getUserRole(user) === UserRole.ServiceProvider)
                              ? <button className={cl.button}
                                        onClick={(ev) => {
                                            ev.stopPropagation();

                                            setIsEditBillingDataDialogShown(prev => !prev)
                                        }}>
                                  <Icon icon={"faPen"}/>
                                  {t("shared.btn.edit")}
                              </button>
                              : undefined}>
                {isMobileView || isSidePanelDetails
                    ? <div className={cl.box} data-view={'mobile'}>
                        <div className={cl.col}>
                            <div>
                                <label>
                                    {t("shipment.form.source-point")}
                                </label>
                                <div>
                                    {`${model.sourceCity} ${model.sourceCountry}`}
                                </div>
                            </div>

                            <div>
                                <label>
                                    {t("shipment.form.early-pickup-date")}
                                </label>
                                <div>
                                    {buildStartDate(model) ?? ''}
                                </div>
                            </div>

                            <div>
                                <label>
                                    {t("shipment.form.truck-licence-plate")}
                                </label>
                                <div>
                                    {model.truck}
                                </div>
                            </div>

                            <div>
                                <label>
                                    {t("shipment.form.supplier")}
                                </label>
                                <div>
                                    {model.serviceProvider}
                                </div>
                            </div>

                            <div>
                                <label>
                                    {t("self-billing.labels.executor")}
                                </label>
                                <div>
                                    {isAnonymousAccess && model.executorUser !== null &&
                                        <>{model.executorUser.name}</>
                                    }

                                    {!isAnonymousAccess && user && model.executorUser !== null && model.executorUser.name !== null
                                        ? <ForwarderField user={model.executorUser}
                                                          chatReplyTo={{
                                                              type: 'SHIPMENT',
                                                              id: model.shipmentXid?.replace('AAG.', '') ?? '',
                                                              idExt: model.shipmentGid,
                                                              description: model.chatDescription
                                                          }}/>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={cl.col}>
                            <div>
                                <label>
                                    {t("shipment.form.destination-point")}
                                </label>
                                <div>
                                    {`${model.destinationCity} ${model.destinationCountry}`}
                                </div>
                            </div>

                            <div>
                                <label>
                                    {t("shipment.form.late-delivery-date")}
                                </label>
                                <div>
                                    {buildEndDate(model) ?? ''}
                                </div>
                            </div>

                            <div>
                                <label>
                                    {t("shipment.form.trailer")}
                                </label>
                                <div>
                                    {model.trailer}
                                </div>
                            </div>

                            <div>
                                <label>
                                    {t("offer.grid.contract-bill-to")}
                                </label>
                                <div>
                                    {model.billTo}
                                </div>
                            </div>

                            <div data-display={'block'}>
                                <label>
                                    {t("self-billing.labels.financial-email-label")}
                                </label>
                                <div>
                                    {model.financialEmail && model.financialEmail.split(',').map(email => {
                                        return (
                                            <div key={email}
                                                 className={cl.email}>
                                                <Icon icon={"faCopy"}
                                                      title={t("shared.labels.copy-link")}
                                                      className={cl.copy}
                                                      onClick={async () => await copy(email)}/>

                                                <a href={`mailto:${email}`}>
                                                    {email}
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className={cl.box}>
                        <div className={cl.col}>
                            <div>
                                <label>
                                    {t("shipment.form.source-point")}
                                </label>
                                <div>
                                    {`${model.sourceCity} ${model.sourceCountry}`}
                                </div>
                            </div>

                            <div>
                                <label>
                                    {t("shipment.form.early-pickup-date")}
                                </label>
                                <div>
                                    {buildStartDate(model) ?? ''}
                                </div>
                            </div>

                            <div>
                                <label>
                                    {t("shipment.form.supplier")}
                                </label>
                                <div>
                                    {model.serviceProvider}
                                </div>
                            </div>

                            <div>
                                <label>
                                    {t("self-billing.labels.executor")}
                                </label>
                                <div>
                                    {isAnonymousAccess && model.executorUser !== null &&
                                        <>{model.executorUser.name}</>
                                    }

                                    {!isAnonymousAccess && user && model.executorUser !== null && model.executorUser.name !== null
                                        ? <ForwarderField user={model.executorUser}
                                                          chatReplyTo={{
                                                              type: 'SHIPMENT',
                                                              id: model.shipmentXid?.replace('AAG.', '') ?? '',
                                                              idExt: model.shipmentGid,
                                                              description: model.chatDescription
                                                          }}/>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={cl.col}>
                            <div>
                                <label>
                                    {t("shipment.form.destination-point")}
                                </label>
                                <div>
                                    {`${model.destinationCity} ${model.destinationCountry}`}
                                </div>
                            </div>

                            <div>
                                <label>
                                    {t("shipment.form.late-delivery-date")}
                                </label>
                                <div>
                                    {buildEndDate(model) ?? ''}
                                </div>
                            </div>

                            <div>
                                <label>
                                    {t("offer.grid.contract-bill-to")}
                                </label>
                                <div>
                                    {model.billTo}
                                </div>
                            </div>
                        </div>

                        <div className={cl.col}>
                            <div>
                                <label>
                                    {t("shipment.form.truck-licence-plate")}
                                </label>
                                <div>
                                    {model.truck}
                                </div>
                            </div>

                            <div>
                                <label>
                                    {t("shipment.form.trailer")}
                                </label>
                                <div>
                                    {model.trailer}
                                </div>
                            </div>

                            <div data-display={'block'}>
                                <label>
                                    {t("self-billing.labels.financial-email-label")}
                                </label>
                                <div>
                                    {model.financialEmail && model.financialEmail.split(',').map(email => {
                                        return (
                                            <div key={email}
                                                 className={cl.email}>
                                                <Icon icon={"faCopy"}
                                                      title={t("shared.labels.copy-link")}
                                                      className={cl.copy}
                                                      onClick={async () => await copy(email)}/>

                                                <a href={`mailto:${email}`}>
                                                    {email}
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </FormCollapse>
        </>
    );
};

export default SelfBillingDetailsBox;
