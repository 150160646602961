import {useGetLoginUserList, useLogin, useLogout} from "./requests";

export const useLoginApi = () => {
    const {
        isLoading: loginLoading,
        cancel: cancelLogin,
        login
    } = useLogin();

    const {
        isLoading: getLoginUserListLoading,
        cancel: getLoginUserListCancel,
        getLoginUserList
    } = useGetLoginUserList();

    const {
        isLoading: isLogoutLoading,
        cancel: logoutCancel,
        logout
    } = useLogout();

    return {
        login: {
            mutation: login,
            isLoading: loginLoading,
            cancel: cancelLogin
        },
        getLoginUserList: {
            query: getLoginUserList,
            isLoading: getLoginUserListLoading,
            cancel: getLoginUserListCancel
        },
        logout: {
            mutation: logout,
            isLoading: isLogoutLoading,
            cancel: logoutCancel
        }
    }
}