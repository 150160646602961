import React, {useEffect, useState} from 'react';
import {UserRole} from "../../../../../../app/enums/UserRole";
import {IChatReplyTo} from "../../../../../../app/interfaces/chat/IChat";
import {IChatUser} from "../../../../../../app/interfaces/chat/IChatUser";
import Button, {ButtonType} from "../../../../../ui/Button/Button";
import {useTranslation} from "react-i18next";
import FormLoadingScreen from "../../../../../ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import useChatApi from "../../../../../../app/api/chat";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import Alert from "../../../../../ui/Alert/Alert";
import cl from './SelectUserChat.module.css';
import ChatAvatar from "../../components/ChatAvatar/ChatAvatar";
import {getUserOnlineStatus} from "../../utils";
import {useAppSelector} from "../../../../../../app/store";
import {selectChatSessions} from "../../../../../../features/chat/chatSliceSelectors";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../helpers/user";

type SelectUserChatProps = {
    role: UserRole;
    ids: string;
    onSelect: (data: IChatUser) => void;
    close: () => void;
    replyTo?: IChatReplyTo;
};

const SelectUserChat: React.FC<SelectUserChatProps> = ({
                                                           role,
                                                           ids,
                                                           onSelect,
                                                           close
                                                       }) => {
    const {t} = useTranslation();
    const currentUser = useAppSelector(selectCurrentUser);

    const sessions = useAppSelector(selectChatSessions);

    const [disabled] = useState<boolean>(false);
    const [users, setUsers] = useState<Array<IChatUser> | null>(null);

    const {
        getUsersForChatSelection: {
            isLoading,
            cancel,
            query
        }
    } = useChatApi();

    useEffect(() => {
        if (!ids || ids === '') {
            if (close) {
                close();
            }

            return;
        }

        (async () => {
            try {
                const response = await query(role, ids);
                if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                    if (!response.data.data || response.data.data?.length <= 0) {
                        setUsers([]);
                    } else {
                        setUsers(response.data.data!);
                    }
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error<string>('Unable to get assign offer data');

                    if (close) {
                        close();
                    }
                }
            }
        })();

        return () => {
            cancel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ids]);

    if (!ids || (Array.isArray(ids) && ids.length <= 0) || ids === '') {
        return null;
    }

    if (isLoading || users === null || !currentUser) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    const copy = async (text: string) => {
        if (navigator.clipboard != undefined) {//Chrome
            await navigator.clipboard.writeText(text);
        }
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container pd-10'}>
                {users.length <= 0 &&
                    <Alert type={'Error'}>
                        {t("shared.labels.service-provider-not-registered")}
                    </Alert>
                }

                {users.length > 0 &&
                    users.map(user => {
                        let isOnline = getUserOnlineStatus({
                            id: user.login,
                            serviceProviderId: user.serviceProviderId ?? undefined,
                            groupId: undefined
                        }, sessions);

                        return (
                            <div key={user.login}
                                 onClick={() => {
                                     onSelect(user);
                                 }}
                                 className={`${cl.container} transition-all-500 relative`}>
                                {user.role === UserRole.ServiceProvider && (getUserRole(currentUser) === UserRole.ServiceProvider ||
                                    getUserRole(currentUser) === UserRole.Administrator)
                                    ? <div className={cl.copy}
                                           onClick={async (ev) => {
                                               ev.stopPropagation();

                                               let text = t("shared.labels.sp-info-for-copy");

                                               text = text.replace('{0}', user.serviceProvider ?? t("shared.labels.no-data"));
                                               text = text.replace('{1}', user.address ?? t("shared.labels.no-data"));
                                               text = text.replace('{2}', user.usreouCode ?? t("shared.labels.no-data"));
                                               text = text.replace('{3}', user.phone ?? t("shared.labels.no-data"));
                                               text = text.replace('{4}', user.email ?? t("shared.labels.no-data"));

                                               await copy(text);
                                           }}>
                                        {t("shared.labels.copy-service-provider-data")}
                                    </div>
                                    : null
                                }

                                <div className={`chat-list-card ${cl.card}`}>
                                    <div>
                                        <ChatAvatar isOnline={isOnline}
                                                    onVacation={false}
                                                    size={'Medium'}/>
                                    </div>
                                    <div>
                                        <div className={'chat-list-card-name-row'}>
                                            <div>
                                                {user.name}
                                            </div>
                                        </div>
                                        <div className={'chat-list-card-message-row'}>
                                            <div>
                                                {user.serviceProvider}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <div className={'dialog-default-buttons-container'}>
                <Button buttonType={ButtonType.Transparent}
                        disabled={disabled}
                        className={'close-button'}
                        onClick={close}>
                    {t("shared.btn.cancel")}
                </Button>
            </div>
        </div>
    );
};

export default SelectUserChat;