import React from 'react';
import {useTranslation} from 'react-i18next';
import {IShipment} from "../../../../../../../app/interfaces/shipment/IShipment";

type EquipmentGroupNameProps = {
    shipment: IShipment;
}
const EquipmentGroupName: React.FC<EquipmentGroupNameProps> = ({
                                                                   shipment
                                                               }) => {
    const {t} = useTranslation();

    return (
        <>
            <label className={'equipment-label'}>{t('shipment.form.equipment-group')}</label>
            <span className={'details-box-item-field-value equipment-value'}>
                {shipment.equipment === null && shipment.equipment === '' && shipment.equipmentGroupName === null && shipment.equipmentGroupName === ''?
                    <> {t("shared.labels.no-data")}</>
                : <>
                {shipment.equipment !== null && shipment.equipment !== ''
                    ? <>{shipment.equipment} / </>
                    : <>{t("shared.labels.no-data")} / </>
                }

                {shipment.equipmentGroupName !== null && shipment.equipmentGroupName !== ''
                    ? <>{shipment.equipmentGroupName}</>
                    : <>{t("shared.labels.no-data")}</>
                }</>
            }
            </span>
        </>
    );
};

export default EquipmentGroupName;
