import React from 'react';
import cl from './OfferFilterPanelCheckboxSection.module.css';
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../features/authentication/authenticationSliceSelectors";
import {useParams} from "react-router-dom";
import {useGridLayoutContext} from "../../../../../../../components/uiLayouts/GridLayout/hooks/useGridLayoutContext";
import Checkbox, {CheckboxSize} from "../../../../../../../components/ui/Checkbox/Checkbox";
import {IOfferHeaderConfig} from "../../../data/interfaces/IOfferHeaderConfig";
import {getUserRole} from "../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../app/enums/UserRole";

const OfferFilterPanelCheckboxSection = () => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);
    const {type} = useParams();

    const {
        headerConfig,
        isHeaderDisabled,
        saveHeaderConfig,
    } = useGridLayoutContext<IOfferHeaderConfig>();

    if (!type || !headerConfig || !user || getUserRole(user) === UserRole.Client || getUserRole(user) === UserRole.ServiceProvider) {
        return null;
    }

    const onChange = (config: IOfferHeaderConfig) => {
        (async () => {
            await saveHeaderConfig(config);
        })();
    }

    return (
        <div className={cl.container}>
            <div className={cl.checkboxesContainer}>
                <Checkbox checked={headerConfig.CM}
                          size={CheckboxSize.Medium}
                          label={t("shared.grid-header.cm")}
                          change={(val) => {
                              onChange({
                                  ...headerConfig,
                                  CM: val
                              });
                          }}
                          disabled={isHeaderDisabled}/>
                <Checkbox checked={headerConfig.TLM}
                          size={CheckboxSize.Medium}
                          label={t("shared.grid-header.tlm")}
                          change={(val) => {
                              onChange({
                                  ...headerConfig,
                                  TLM: val
                              });
                          }}
                          disabled={isHeaderDisabled}/>
                <Checkbox checked={headerConfig.SMFA}
                          size={CheckboxSize.Medium}
                          label={t("shared.grid-header.sm-fa")}
                          change={(val) => {
                              onChange({
                                  ...headerConfig,
                                  SMFA: val
                              });
                          }}
                          disabled={isHeaderDisabled}/>


                <Checkbox checked={headerConfig.SMEXA}
                          size={CheckboxSize.Medium}
                          label={t("shared.grid-header.sm-exa")}
                          change={(val) => {
                              onChange({
                                  ...headerConfig,
                                  SMEXA: val
                              });
                          }}
                          disabled={isHeaderDisabled}/>
                <Checkbox checked={headerConfig.OM}
                          size={CheckboxSize.Medium}
                          label={t("shared.grid-header.om")}
                          change={(val) => {
                              onChange({
                                  ...headerConfig,
                                  OM: val
                              });
                          }}
                          disabled={isHeaderDisabled}/>

            </div>
        </div>
    );
};

export default OfferFilterPanelCheckboxSection;
