import {useLogReactRouterError} from "./requests";

export const useLogApi = () => {
    const {
        isLoading: logReactRouterErrorIsLoading,
        cancel: cancelLogReactRouterError,
        logReactRouterError
    } = useLogReactRouterError();

    return {
        logReactRouterError: {
            mutation: logReactRouterError,
            isLoading: logReactRouterErrorIsLoading,
            cancel: cancelLogReactRouterError
        }
    };
}