import React from 'react';
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import RefinePosition from "./RefinePosition";

type RefinePositionDialogProps = {
    shipmentGid: string;
    locationGid: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}
const RefinePositionDialog: React.FC<RefinePositionDialogProps> = ({
                                                                       shipmentGid,
                                                                       locationGid,
                                                                       show,
                                                                       setShow
                                                                   }) => {
    return (
        <Dialog id={'refine-position-dialog'}
                show={show}
                width={'98vw'}
                setShow={setShow}>
            <RefinePosition shipmentGid={shipmentGid}
                            locationGid={locationGid}
                            close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default RefinePositionDialog;