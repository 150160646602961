import {faTruck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";

const TransportTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);

    let transport = props.dataItem.transport !== null &&
    props.dataItem.transport !== undefined &&
    props.dataItem.transport !== ''
        ? props.dataItem.transport
        : null;

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
            {!transport
                ? <span className={'default-left-cell default-overflow-cell'}>
                    {props.children}
                </span>
                : <span className={`default-flex-cell default-overflow-cell`}
                        style={{justifyContent: 'center', gap: '5px'}}>
                    <FontAwesomeIcon icon={faTruck}
                                     style={{fontSize: '12px', color: 'var(--main-orange-color)'}}/>
                    <span className={'badge blue prevent-selection'}>
                        {transport}
                    </span>
                </span>
            }
        </td>
    );
};

export default TransportTemplate;
