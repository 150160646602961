import React, {useContext} from 'react';
import SidebarNavigationButton from "../../../components/SidebarNavigationButton/SidebarNavigationButton";
import {useTranslation} from "react-i18next";
import {isMobile} from "react-device-detect";
import {SidebarContext} from "../../../Sidebar";

type SidebarMenuDashboardProps = {
    expandedId: string;
    onClick?: (id: string) => void;
};

const SidebarMenuDashboard: React.FC<SidebarMenuDashboardProps> = ({
                                                                       expandedId,
                                                                       onClick: onClickProp,
                                                                   }) => {
    const {t} = useTranslation();

    const {
        expanded,
        hoverExpanded,
        setIsExpandedOnHover
    } = useContext(SidebarContext);

    const handleNavigationClick = (id: string) => {
        if (onClickProp) {
            onClickProp(id);
        }

        if (isMobile && !expanded && hoverExpanded) {
            setIsExpandedOnHover(false);
        }
    };

    return (
        <SidebarNavigationButton id={'sidebar-dashboard-item'} expandedId={expandedId}
                                 type={'primary'}
                                 onClick={handleNavigationClick}
                                 text={t("sidebar.dashboard")}
                                 title={t("sidebar.dashboard")}
                                 path={'/'}
                                 icon={"faDashboard"}/>
    );
};

export default SidebarMenuDashboard;
