import React from 'react';
import {IChatReplyTo} from "../../../../../../../../app/interfaces/chat/IChat";
import {faBook, faCubes, faReply, faRoad, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

type SendMessageToMultipleServiceProvidersMessageProps = {
    disabled: boolean;
    message: string;
    onChange: (val: string) => void;
    replyTo: IChatReplyTo;
    hasValidationError: boolean;
};

const getLinkedEntityIcon = (message: IChatReplyTo): IconDefinition | null => {
    let icon: IconDefinition | null = null;

    if (message.type === 'RELEASE') {
        icon = faCubes;
    } else if (message.type === 'QUOTE') {
        icon = faBook;
    } else if (message.type === 'SHIPMENT') {
        icon = faRoad;
    }

    return icon;
}

const SendMessageToMultipleServiceProvidersMessage: React.FC<SendMessageToMultipleServiceProvidersMessageProps> = ({
                                                                                                                       disabled,
                                                                                                                       message,
                                                                                                                       onChange,
                                                                                                                       replyTo,
                                                                                                                       hasValidationError
                                                                                                                   }) => {
    const {t} = useTranslation();

    let icon: IconDefinition | null = getLinkedEntityIcon(replyTo);

    return (
        <div className={'chat-dialogue-send-message-container mgb-10'}
             data-type={replyTo ? 'reply' : ''}>
            {replyTo && icon &&
                <div className={'relative'}
                     style={hasValidationError ? {
                         borderColor: 'red'
                     } : undefined}>
                    <FontAwesomeIcon icon={faReply}
                                     style={{
                                         position: 'absolute',
                                         color: '#9a9eae',
                                         left: '10px',
                                         fontSize: '13px'
                                     }}/>
                    <div className={'chat-dialogue-message-reply-to-container'}
                         style={{flexFlow: 'row-reverse'}}>
                        <div className={'chat-dialogue-reply-to-entity'}>
                            <FontAwesomeIcon icon={icon}/>
                        </div>
                        <div>
                            {replyTo.id}
                        </div>
                    </div>
                    {replyTo.description !== null && replyTo.description !== '' &&
                        <div className={'chat-dialogue-message-description'}>
                            {replyTo.description}
                        </div>
                    }
                </div>
            }
            <div style={hasValidationError ? {
                    gridTemplateColumns: '100%',
                    borderColor: 'red',
                    borderTopColor: '#ebecf0'
                }
                : {
                    gridTemplateColumns: '100%'
                }}
                 className={'chat-send-message-button-container'}>
                <div>
                           <textarea placeholder={t("chat.labels.type-message-here")}
                                     className={'chat-send-message-button-textarea'}
                                     value={message}
                                     onChange={(ev) => onChange(ev.target.value)}
                                     disabled={disabled}
                           />
                </div>
            </div>
        </div>
    );
};

export default SendMessageToMultipleServiceProvidersMessage;