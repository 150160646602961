import React from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import moment from "moment/moment";

const SimpleDateTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);

    return (
        <td {...props.tdProps}
            onClick={handleClick}>
                <span className={`default-left-cell default-overflow-cell`}>
                    {moment.utc(props.children, 'DD.MM.YY HH:mm').local().format('DD.MM.YYYY HH:mm')}
                </span>
        </td>
    );
};

export default SimpleDateTemplate;
