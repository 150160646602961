import {RootState} from "../../app/store/store";
import {createSelector} from "@reduxjs/toolkit";

export const selectIsChatDialogOpened = (state: RootState) => state.chat.isOpened;

export const selectIsChatSocketConnected = (state: RootState) => state.chat.isConnected;

export const selectIsChatFullScreen = (state: RootState) => state.chat.isFullScreen;

export const selectChatTotalUnreadMessages = (state: RootState) => state.chat.totalUnreadMessages;

export const selectChatIsChatsLoading = (state: RootState) => state.chat.chats.isLoading;

export const selectChatsList = (state: RootState) => state.chat.chats.chats;

export const selectActiveChat = (state: RootState) => state.chat.chat.chat;

export const selectIsUserInformationSectionShown = (state: RootState) => state.chat.chat.isUserInformationSectionShown;

export const selectChatNewMessage = (state: RootState) => state.chat.chat.message;

export const selectActiveChatMessages = (state: RootState) => state.chat.chat.messages.messages;

export const selectActiveChatMessagesCount = (state: RootState) => state.chat.chat.messages.count;

export const selectActiveChatMessagesIsLoading = (state: RootState) => state.chat.chat.messages.isLoading;

/* Search messages selectors */
export const selectIsMessagesSearchSectionShown = (state: RootState) => state.chat.chat.search.isMessagesSearchSectionShown;

export const selectChatSearchMessages = (state: RootState) => state.chat.chat.search.messages.messages;

export const selectChatSearchString = (state: RootState) => state.chat.chat.search.search;

export const selectChatSearchActiveMessage = (state: RootState) => state.chat.chat.search.currentActiveMessage;

export const selectChatSessions = (state: RootState) => state.chat.sessions;

export const selectChatUserOnlineStatus = createSelector([
        (state: RootState) => state.chat.sessions,
        (_: RootState, entity: {
            userId: string;
            spId?: string;
            gr?: string;
        }) => entity
    ],
    (data, entity) => {
        if (data.length <= 0) {
            return false;
        }

        if (entity.spId && entity.gr) {
            return data.find(e => e.userId === entity.userId && e.Gr === entity.gr && e.spId === entity.spId) !== undefined;
        }

        return data.find(e => e.userId === entity.userId) !== undefined;
    }
);

export const selectIsChatActive = createSelector([
        (state: RootState) => state.chat.chat,
        (_: RootState, id: string) => id
    ],
    (data, id) => {
        if (!data.chat) {
            return false;
        }

        return data.chat.id === id;
    });