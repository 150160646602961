import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'floppyDisk',
    pathData: [
        'M3.66667 1V3.26667C3.66667 3.64004 3.66667 3.82672 3.73933 3.96933C3.80324 4.09477 3.90523 4.19676 4.03067 4.26067C4.17328 4.33333 4.35997 4.33333 4.73333 4.33333H9.26667C9.64004 4.33333 9.82672 4.33333 9.96933 4.26067C10.0948 4.19676 10.1968 4.09477 10.2607 3.96933C10.3333 3.82672 10.3333 3.64004 10.3333 3.26667V1.66667M10.3333 13V8.73333C10.3333 8.35997 10.3333 8.17328 10.2607 8.03067C10.1968 7.90523 10.0948 7.80324 9.96933 7.73933C9.82672 7.66667 9.64004 7.66667 9.26667 7.66667H4.73333C4.35997 7.66667 4.17328 7.66667 4.03067 7.73933C3.90523 7.80324 3.80324 7.90523 3.73933 8.03067C3.66667 8.17328 3.66667 8.35997 3.66667 8.73333V13M13 5.21699V9.8C13 10.9201 13 11.4802 12.782 11.908C12.5903 12.2843 12.2843 12.5903 11.908 12.782C11.4802 13 10.9201 13 9.8 13H4.2C3.0799 13 2.51984 13 2.09202 12.782C1.71569 12.5903 1.40973 12.2843 1.21799 11.908C1 11.4802 1 10.9201 1 9.8V4.2C1 3.0799 1 2.51984 1.21799 2.09202C1.40973 1.71569 1.71569 1.40973 2.09202 1.21799C2.51984 1 3.0799 1 4.2 1H8.78301C9.10913 1 9.27219 1 9.42564 1.03684C9.56169 1.0695 9.69175 1.12337 9.81105 1.19648C9.9456 1.27894 10.0609 1.39424 10.2915 1.62484L12.3752 3.7085C12.6058 3.9391 12.7211 4.0544 12.8035 4.18895C12.8766 4.30825 12.9305 4.43831 12.9632 4.57436C13 4.72781 13 4.89087 13 5.21699Z'
    ],
    viewBox: '0 0 14 14',
    strokeWidth: 1,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;