export const isCurrentNestedLinkActive = (currentPath: string, items?: Array<string>): boolean => {
    if (!items || items.length <= 0) {
        return false;
    }

    let isLinkActive: boolean = false;

    items.forEach(item => {
        if (currentPath.includes(item)) {
            isLinkActive = true;
        }
    });

    return isLinkActive;
};