import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useShipmentApi} from "../../../../../app/api/shipment";
import {toast} from "react-toastify";
import {IBorderCrossingAction} from "../../../../../app/interfaces/shipment/IBorderCrossingAction";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {isAxiosError} from "axios";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import BorderCrossingForm from "./BorderCrossingForm/BorderCrossingForm";

type BorderCrossingProps = {
    id: string;
    sId: string;
    close?: () => void;
}

const BorderCrossing: React.FC<BorderCrossingProps> = ({
                                                           id,
                                                           sId,
                                                           close
                                                       }) => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [model, setModel] = useState<IBorderCrossingAction | null>(null);

    const {
        getBorderCrossingData: {
            query: getBorderCrossingData,
            isLoading,
            cancel: cancelGetBorderCrossingData
        },
        addBorderCrossing: {
            mutation: addBorderCrossing,
            cancel: cancelAddBorderCrossing
        }
    } = useShipmentApi();

    useEffect(() => {
        if (id === '' || sId === '') {
            if (close) {
                close()
            } else {
                return;
            }
        }

        (async () => {
            try {
                const response = await getBorderCrossingData(id, sId);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch {
                if (close) {
                    close();
                } else {
                    toast.error<string>('Unable to get border crossing data');
                }
            }
        })();

        return () => {
            cancelGetBorderCrossingData();
            cancelAddBorderCrossing();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sId, id]);

    const save = async (model: IBorderCrossingAction): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            const response = await addBorderCrossing(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [
                            {
                                id: sId,
                                isRowLoading: true
                            }
                        ]
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.shipment")} ${sId}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err) && close) {
                close();
                toast.error(err.message);
            }
        }

        setDisabled(prev => !prev);
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                <BorderCrossingForm model={model}
                                    disabled={disabled}
                                    save={save}/>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        form='add-border-crossing-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.submit")}
                </Button>
            </div>
        </div>
    );
};

export default BorderCrossing;