import React from 'react';
import {IQuote} from "../../../../../../../../app/interfaces/quote/IQuote";
import {useTranslation} from "react-i18next";

type QuoteCustomerOrderRefNumberProps = {
    quote: IQuote;
}

const QuoteCustomerOrderRefNumber: React.FC<QuoteCustomerOrderRefNumberProps> = ({
                                                                                     quote
                                                                                 }) => {
    const {t} = useTranslation();

    if (quote.customerOrderRefNumber === null || quote.customerOrderRefNumber === '') {
        return (
            <div className={'details-box-item-field-value'}>
                {" "}
            </div>
        );
    }

    return (
        <div className={'details-box-item-field-value'}>
            {quote.customerOrderRefNumber}
        </div>
    );
};

export default QuoteCustomerOrderRefNumber;
