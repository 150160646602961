import React from 'react';
import {ICustomsEtaAction} from "../../../../../../app/interfaces/shipment/ICustomsEtaAction";
import {useTranslation} from "react-i18next";
import {FormikHelpers, useFormik} from "formik";
import moment from "moment/moment";
import ReactDatePicker from "../../../../../../components/ui/ReactDatePicker/ReactDatePicker/ReactDatePicker";

type CustomsEtaFormProps = {
    model: ICustomsEtaAction;
    disabled: boolean;
    save: (model: ICustomsEtaAction) => Promise<void>;
}

const CustomsEtaForm: React.FC<CustomsEtaFormProps> = ({
                                                           model,
                                                           save,
                                                           disabled
                                                       }) => {
    const {t} = useTranslation();

    const form = useFormik<ICustomsEtaAction>({
        initialValues: {
            orderReleaseGid: model.orderReleaseGid,
            shipmentGid: model.shipmentGid,
            etaDate: model.etaDate
        },
        onSubmit: async (values: ICustomsEtaAction, actions: FormikHelpers<ICustomsEtaAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='change-customs-eta-form'
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div className={'picker'}>
                    <ReactDatePicker disabled={disabled}
                                     size={'big'}
                                     value={form.values.etaDate ? moment(form.values.etaDate, 'DD.MM.YYYY').toDate() : null}
                                     onChange={(val) => {
                                         form.setFieldValue('etaDate', moment(val).format('DD.MM.YYYY'));
                                     }}
                                     placeholder={t("shared.labels.date")}
                    />
                    {/*
                    <DatePicker format={'dd.MM.yyyy'}
                                dateInput={DateInput}
                                id={'customs-eta-date'}
                                disabled={disabled}
                                formatPlaceholder={{
                                    year: "yyyy",
                                    month: "MM",
                                    day: "dd",
                                }}
                                value={form.values.etaDate ? moment(form.values.etaDate, 'DD.MM.YYYY').toDate() : null}
                                onChange={(ev) => {
                                    form.setFieldValue('etaDate', moment(ev.target.value).format('DD.MM.YYYY'));
                                }}
                                popupSettings={{animate: false}}
                                rounded={'small'}
                                fillMode={'outline'}
                                placeholder={t("shared.labels.date")}
                                adaptive={true}/>*/}
                </div>
            </div>
        </form>
    );
};

export default CustomsEtaForm;