import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import FormLoadingScreen from "../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import OfferDetails from "./OfferDetails";

const OfferDetailsPage: React.FC = () => {
    const [searchParams] = useSearchParams();

    const [id, setId] = useState<string | null>(null);

    useEffect(() => {
        setId(searchParams.get('id'));
    }, []);

    if (!id) {
        return <FormLoadingScreen height={`100%`} style={{padding: '5px'}}/>;
    }

    return (
        <div className={'h100 w100 hidden'}>
            <OfferDetails id={id}/>
        </div>
    );
};

export default OfferDetailsPage;