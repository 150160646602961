import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {IEvent} from "../../../../../app/interfaces/shared/IEvent";
import {useShipmentApi} from "../../../../../app/api/shipment";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {IShipmentEvent} from "../../../../../app/interfaces/shipment/IShipmentEvent";
import ShipmentsEventListItem from "./ShipmentsEventListItem/ShipmentsEventListItem";
import uuid from "react-uuid";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {IAddEventPostAction} from "../../../../../app/interfaces/shipment/IAddEventPostAction";
import {IAddEventPostActionItem} from "../../../../../app/interfaces/shipment/IAddEventPostActionItem";
import moment from "moment";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {BLErrorCode} from "../../../../../app/enums/BLErrorCode";
import Alert from "../../../../../components/ui/Alert/Alert";
import {IUser} from "../../../../../app/interfaces/user/IUser";

type ShipmentsEventListProps = {
    id: string | Array<string>;
    close?: () => void;
    user?: IUser;
}

export type ExtendedShipmentEvent = {
    showPlus: boolean;
    isEditMode: boolean;
    showReasonCode: boolean;
    line: 'Solid' | 'Dashed' | 'None';
    passed: boolean;
    isLast: boolean;
    isLatest: boolean;
    uuid: string;
    hasDateError: boolean;
} & IShipmentEvent;

export type ExtendedEvent = {
    shipmentEvents: Array<ExtendedShipmentEvent> | null;
    showAllShipmentEvents: boolean;
} & IEvent;

const ShipmentsEventList: React.FC<ShipmentsEventListProps> = ({
                                                                   user,
                                                                   id,
                                                                   close
                                                               }) => {
    const {t} = useTranslation();
    const [shipments, setShipments] = useState<Array<ExtendedEvent> | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError>>([]);

    const {
        getAddEventData: {
            isLoading: getAddEventDataIsLoading,
            cancel: cancelGetAddEventData,
            query: getAddEventData
        },
        addEvent: {
            mutation: addEvent,
            cancel: cancelAddEvent
        },

        getAddEventDataAnonymous: {
            isLoading: getAddEventDataAnonymousIsLoading,
            cancel: cancelGetAddEventDataAnonymous,
            query: getAddEventDataAnonymous
        },
        addEventAnonymous: {
            mutation: addEventAnonymous,
            cancel: cancelAddEventAnonymous
        }
    } = useShipmentApi();

    useEffect(() => {
        if (!id || id === '') {
            return;
        }

        (async () => {
            try {
                const response = user
                    ? await getAddEventData(id)
                    : await getAddEventDataAnonymous(id);

                if (response?.status === 200 && response.data && response.data.data && response.data.data.events) {
                    if (response.data.errors !== undefined && response.data.errors.length > 0) {
                        setErrors(response.data.errors);
                    }

                    setShipments(response.data.data.events.map(ev => {
                        return {
                            ...ev,
                            showAllShipmentEvents: false,
                            shipmentEvents: ev.shipmentEvents
                                ? ev.shipmentEvents
                                    .filter(e => !e.isFake)
                                    .map(se => {
                                        return {
                                            ...se,
                                            isEditMode: false,
                                            showReasonCode: false,
                                            showPlus: false,
                                            hasDateError: false,
                                            uuid: uuid()
                                        } as ExtendedShipmentEvent
                                    })
                                : null
                        };
                    }));
                } else {
                    setShipments([]);
                }
            } catch {
                setShipments([]);
            }
        })();

        return () => {
            cancelGetAddEventData();
            cancelAddEvent();
            cancelGetAddEventDataAnonymous();
            cancelAddEventAnonymous();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const save = async (): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            let events: Array<IAddEventPostActionItem> = [];

            if (shipments !== null) {
                shipments.forEach(shipment => {
                    if (shipment.shipmentEvents) {
                        shipment.shipmentEvents
                            .filter(e => e.isEditMode)
                            .forEach(event => {
                                events.push({
                                    shipmentGid: shipment.shipmentGid ?? '',
                                    date: event.editFactDate ? moment(event.editFactDate).format('DD.MM.YYYY') : '',
                                    time: event.editFactTime ? moment(event.editFactTime).format('HH:mm') : '',
                                    reason: event.reason && event.showReasonCode ? event.reason ?? '' : '',
                                    transaction: event.transaction ?? null,
                                    event: event.otmEvent ?? null,
                                    position: event.spPosition,
                                    sortPosition: event.sortPosition,
                                    subPosition: event.spSubPosition
                                });
                            });
                    }
                });
            }

            if (events.length <= 0) {
                setDisabled(prev => !prev);

                return;
            }

            let model: IAddEventPostAction = {
                events: events
            };

            const response = user
                ? await addEvent(model)
                : await addEventAnonymous(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (!response.data.errors || response.data.errors.length <= 0) {
                    if (close) {
                        close();
                    }


                    if (shipments !== null && shipments.length > 0) {
                        document.dispatchEvent(new CustomEvent('onGridMessage', {
                            detail: {
                                action: 'updateRows',
                                rows: [
                                    ...shipments.map(item => {
                                        return {
                                            id: item.shipmentGid,
                                            isRowLoading: true
                                        };
                                    })
                                ]
                            }
                        }));
                    }

                    if (shipments !== null && shipments.length > 0) {
                        toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                                   title={`${t("shared.labels.shipment")} ${shipments[0].shipmentGid !== '' ? shipments[0].shipmentGid : ''}`}/>);
                    }
                } else {
                    setErrors(response.data.errors);

                    let errorShipments: Array<ExtendedEvent> = [];

                    response.data.errors.forEach(err => {
                        if (err.field !== null) {
                            let item = shipments?.find(e => e.shipmentGid === err.field);
                            if (item !== undefined && !errorShipments.find(e => e.shipmentGid === err.field)) {
                                errorShipments.push({...item});
                            }
                        }
                    });

                    setShipments(errorShipments);
                }
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(e.message);
            }
        }

        setDisabled(prev => !prev);
    }

    const remove = useCallback((gid: string) => {
        if (shipments?.find(e => e.shipmentGid === gid)) {
            setShipments([
                ...shipments?.filter(e => e.shipmentGid !== gid)
            ]);
        }
    }, [shipments]);

    const change = useCallback((event: ExtendedEvent) => {
        if (!shipments) {
            return;
        }

        let index = shipments.findIndex(e => e.shipmentGid === event.shipmentGid);

        if (index !== undefined && index >= 0) {
            let items = shipments.map((item) => {
                return {...item};
            });

            items.splice(index, 1, event);

            setShipments(items);
        }
    }, [shipments]);

    if ((Array.isArray(id) && id.length <= 0) || id === '') {
        return null;
    }

    if (getAddEventDataIsLoading || getAddEventDataAnonymousIsLoading || shipments === null) {
        return (
            <FormLoadingScreen height={user ? `200px` : '100%'} style={{padding: '5px'}}/>
        );
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                {errors.length > 0 && errors.find(e => e.field === "summary" && e.errorCode === BLErrorCode.MultiEventsError) &&
                    <Alert type={'Error'} style={{fontSize: '11px', fontWeight: 'bold'}}>
                        {t("shipment.errors.multiple-save-error")}
                    </Alert>
                }
                {shipments.map(item => {
                    return (
                        <ShipmentsEventListItem key={item.shipmentGid}
                                                disabled={disabled}
                                                event={item}
                                                showRemoveButton={shipments.length > 1}
                                                remove={remove}
                                                change={change}
                                                errors={errors}/>
                    );
                })}
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        onClick={save}
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.submit")}
                </Button>
            </div>
        </div>
    );
};

export default ShipmentsEventList;
