import React, {useEffect, useState} from 'react';
import {IMapPositioningGetAction} from "../../../../../../../app/interfaces/shipment/IMapPositioningGetAction";
import {IShipment} from "../../../../../../../app/interfaces/shipment/IShipment";
import FormLoadingScreen from "../../../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import ShipmentEventsLine from "../../../../form/components/fields/ShipmentEventsLine/ShipmentEventsLine";

type LocationMapEventsSectionProps = {
    model: IMapPositioningGetAction;
}

const LocationMapEventsSection: React.FC<LocationMapEventsSectionProps> = ({
                                                                               model
                                                                           }) => {
    const [shipment, setShipment] = useState<Partial<IShipment> | null>(null);

    useEffect(() => {
        setShipment({
            shipmentGid: model.shipmentGid,
            inGeofenceArea: model.inGeofenceArea,
            importDirection: model.importDirection,
            isUnConfirmed: model.isUnconfirmed,
            isCompleted: model.isCompleted,
            hasTracking: model.hasTracking,
            currentPosition: model.currentPosition,
            stopPoints: model.stopPoints,
            shipmentEvents: model.shipmentEvents,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!shipment) {
        return (
            <FormLoadingScreen height={`120px`} style={{padding: '5px'}}/>
        );
    }

    return (
        <ShipmentEventsLine model={shipment}/>
    );
};

export default LocationMapEventsSection;