import React from 'react';
import cl from './ShipmentsEventListItemHeader.module.css';
import { useTranslation } from "react-i18next";
import ttTrackingIcon from "../../../../../../../assets/images/has-tt-tracking.png";
import { ExtendedEvent } from "../../ShipmentsEventList";
import Icon, { IconName } from "../../../../../../../assets/icon/Icon";

type ShipmentsEventListItemHeaderProps = {
    event: ExtendedEvent;
    showRemoveButton: boolean;
    remove: (gid: string) => void;
    change: (event: ExtendedEvent) => void;
    disabled: boolean;
}

const ShipmentsEventListItemHeader: React.FC<ShipmentsEventListItemHeaderProps> = ({
    event,
    showRemoveButton,
    remove,
    change,
    disabled
}) => {
    const { t } = useTranslation();

    let indicatorIcon: IconName | null = null;
    let indicatorStyle: React.CSSProperties | undefined = undefined;

    if (event.indicator === 'G') {
        indicatorIcon = "faCircle";
        indicatorStyle = {
            fontSize: '14px',
            color: '#7FB98B'
        };
    } else if (event.indicator === 'Y') {
        indicatorIcon = "faTriangle";
        indicatorStyle = {
            fontSize: '14px',
            color: '#F3D11C'
        };
    }

    let title: string = '';
    let style: React.CSSProperties | undefined = undefined;

    if (!event.isUnconfirmed && event.isStarted) {
        title = t("shipment.grid.confirmed");
        style = {
            color: '#7FB98B',
        };
    }

    if (event.isUnconfirmed) {
        title = t("shipment.grid.unconfirmed");
        style = {
            color: '#E96024'
        };
    }

    return (
        <div>
            {event.hasTracking &&
                <span className={cl.hasTrackingTooltip}>
                    {t("shared.labels.has-tracking-tooltip")}
                </span>
            }
            <div className={cl.container}>
                <div className={cl.gidContainer}>
                    <span className={cl.gid}
                        title={title}
                        style={style}>
                        {event.shipmentGid?.replace('AAG.', '')}
                    </span>

                    {indicatorIcon !== null && <Icon icon={indicatorIcon} style={indicatorStyle} />}

                    {event.hasTTTracking &&
                        <img src={ttTrackingIcon} alt={'ttt'} title={t("shipment.grid.truck-has-gps")} />
                    }

                    {event.hasTracking &&
                        <Icon icon={"faBroadcast"}
                            title={t("shipment.grid.truck-has-gps")}
                            style={{
                                color: 'var(--main-orange-color)',
                                fontSize: '17px'
                            }} />
                    }

                    <span className={cl.label}>
                        {event.supplier}
                    </span>
                    <span className={cl.label}>
                        {event.transport}
                    </span>
                </div>
                <div className={cl.buttons}>
                    {!disabled && !event.showAllShipmentEvents && event.shipmentEvents !== null && event.shipmentEvents.filter(e => !e.isFake).length > 5 &&
                        <button className={cl.btn}
                            onClick={() => {
                                change({
                                    ...event,
                                    showAllShipmentEvents: !event.showAllShipmentEvents
                                });
                            }}>
                            {t("shared.btn.show-all-events")}
                        </button>
                    }
                    {!disabled && showRemoveButton &&
                        <Icon className={`${cl.close} transition-all-500`}
                            onClick={() => remove(event.shipmentGid ?? '')}
                            icon={"faXMark"} />
                    }
                </div> 
            </div>

        </div>
    );
};

export default ShipmentsEventListItemHeader;
