import {motion} from 'framer-motion';
import React, {useEffect, useState} from 'react';
import {useLoginApi} from "../../../../../app/api/login";
import {AxiosError} from "axios";
import {ILoginUser} from "../../../../../app/interfaces/login/ILogin";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import cl from './LoginUserListContent.module.css';
import Loader from "../../../../../components/ui/loaders/Loader/Loader";
import {useTranslation} from "react-i18next";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {UserRole} from "../../../../../app/enums/UserRole";

type LoginUserListContentProps = {
    email: string;
    changeComponent: (email?: string, usr?: ILoginUser | null) => void;
    componentName: string;
}
const LoginUserListContent: React.FC<LoginUserListContentProps> = ({email, changeComponent}) => {
    const {t} = useTranslation();
    const {getLoginUserList: {query, isLoading}} = useLoginApi();
    const [users, setUsers] = useState<Array<ILoginUser> | null>();
    const [selectedUser, setSelectedUser] = useState<ILoginUser | null>();

    useEffect(() => {
        (async () => {
            try {
                const response = await query(email);
                if ((response?.status === 200 || response?.status === 204) &&
                    response.data !== null &&
                    response.data !== undefined &&
                    response.data.resultCode === ResponseResultCode.Ok) {
                    setUsers(response.data.data);
                }
            } catch (error) {
                if (error instanceof AxiosError) {
                    changeComponent();
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    return (
        <motion.div key={'login-user-list-content'}
                    className={'pd-10'}
                    initial={{opacity: 0.5}}
                    animate={{opacity: 1}}
                    transition={{duration: 1}}
                    exit={{opacity: 0.5}}>
            <div className={cl.title}>{t("login.labels.login-user-list-pick-label")}</div>
            <div className={cl.container}>
                {isLoading && <Loader style={{scale: '0.5'}}/>}
                {users !== null && users !== undefined && users.map((item: ILoginUser) => {
                    return (
                        <div key={item.login}
                             onClick={() => {
                                 if ((selectedUser !== null &&
                                         selectedUser !== undefined &&
                                         selectedUser.login !== item.login) ||
                                     !selectedUser) {
                                     setSelectedUser(item);
                                 }
                             }}
                             className={`${cl.row} ${selectedUser !== null && selectedUser !== undefined && selectedUser.login === item.login ? cl.active : ''}`}>
                            <div>
                                <FontAwesomeIcon icon={faUser}/>
                            </div>
                            <div>
                                <span>
                                    {item.login}
                                </span>
                                {item.role === UserRole.ServiceProvider &&
                                    <span>{`${item.name} / ${item.serviceProviderName}`}</span>
                                }
                                {item.role === UserRole.Forwarder &&
                                    <span>{item.department}</span>
                                }
                            </div>
                        </div>
                    )
                })
                }
            </div>
            <div className={cl.separator}></div>
            <div className={cl.buttonContainer}>
                <Button buttonType={ButtonType.Secondary}
                        disabled={isLoading}
                        onClick={() => changeComponent()}
                        style={{width: '40%'}}>
                    {t("shared.btn.back")}
                </Button>

                <Button buttonType={ButtonType.Primary}
                        disabled={!selectedUser}
                        onClick={() => changeComponent(undefined, selectedUser)}
                        style={{width: '40%'}}>
                    {t("shared.btn.select")}
                </Button>
            </div>
        </motion.div>
    );
};

export default LoginUserListContent;