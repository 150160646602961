import {PasswordRule} from "../../components/shared/components/ChangePassword/ChangePasswordForm/ChangePasswordForm";
import {useTranslation} from "react-i18next";

const useDefaultSitePasswordRules = (): Array<PasswordRule> => {
    const {t} = useTranslation();

    return [
        {
            id: 'eight-or-more-symbols',
            name: t("account.password.rules.password-eight-or-more-symbols"),
            isSatisfy: (val: string) => {
                return val.length >= 8;
            }
        },
        {
            id: 'at-least-one-upper-latin-letter',
            name: t("account.password.rules.at-least-one-upper-latin-letter"),
            isSatisfy: (val: string) => {
                return new RegExp(/^(?=.*?[A-Z])/).test(val);
            }
        },
        {
            id: 'at-least-one-lower-latin-letter',
            name: t("account.password.rules.at-least-one-lower-latin-letter"),
            isSatisfy: (val: string) => {
                return new RegExp(/^(?=(.*[a-z]))/).test(val);
            }
        },
        {
            id: 'at-least-one-number',
            name: t("account.password.rules.at-least-one-number"),
            isSatisfy: (val: string) => {
                return new RegExp(/[0-9]/).test(val);
            }
        },
        {
            id: 'at-least-one-punctuation-mark',
            name: t("account.password.rules.at-least-one-punctuation-mark"),
            isSatisfy: (val: string) => {
                return new RegExp(/[!"$%&'()+,\-.\/:;<=>?\[\]^{}|~]/).test(val);
            }
        }
    ];
};

export default useDefaultSitePasswordRules;