import React from 'react';
import cl from './SelectedQuotes.module.css';

type SelectedQuotesProps = {
    ids: string | Array<string>;
}

const SelectedQuotes: React.FC<SelectedQuotesProps> = ({
                                                           ids
                                                       }) => {
    return (
        <div className={cl.container}>
            {[...ids].map(item => {
                return (
                    <div key={item}>
                        {item}
                    </div>
                );
            })}
        </div>
    );
};

export default SelectedQuotes;