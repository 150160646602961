import React from 'react';
import {SelectModel} from "../../../../../app/types/SelectModel";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {IGridResultResponse} from "../../../../../app/interfaces/response/IGridResultResponse";
import {ISelectTreeModel} from "../../../../../app/interfaces/shared/ISelectTreeModel";
import {useTagApi} from "../../../../../app/api/tag";
import Tag, {TagItemBackgroundColor} from "../../../../ui/Tag/Tag";
import TreeListBox from "../../../../ui/TreeListBox/TreeListBox";
import {useTranslation} from "react-i18next";
import {ItemRenderProps} from "@progress/kendo-react-treeview";

type FilterPanelTagsFilterContentProps = {
    id: string;
    onChange: (value: Array<SelectModel>) => void,
    value: Array<SelectModel>;
    type: 'release' | 'quote' | 'shipment';
};

const FilterPanelTagsFilterContent: React.FC<FilterPanelTagsFilterContentProps> = ({
                                                                                       id,
                                                                                       onChange,
                                                                                       value,
                                                                                       type
                                                                                   }) => {
    const {t} = useTranslation();

    const {
        getFilterTags: {
            query: getFilterTags
        }
    } = useTagApi();

    const load = async (_skip: number, _take: number, filter: string | null) => {
        try {
            const response = await getFilterTags(filter, type.toString());

            if (response?.status === 200 && response.data) {
                if (response.data.result.length > 0) {
                    response.data.result.forEach(item => {
                        if (item.text === '0' && item.value === '0') {
                            item.text = t("tags.labels.personal-tags");
                        } else if (item.text === '1' && item.value === '1') {
                            item.text = t("tags.labels.department-tags");
                        }
                    })
                }

                return response.data;
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(`Unable to get ${type.toString()} filter tags. Error: ${e.message}`);
            }
        }

        return {
            count: 0,
            result: []
        } as IGridResultResponse<ISelectTreeModel>;
    }

    return (
        <TreeListBox id={`${id}-tree-list-box`}
                     selected={value}
                     take={20}
                     height={250}
                     onDataLoad={load}
                     template={(props: ItemRenderProps) => {
                         if (props.item === undefined ||
                             props.item === null ||
                             props.item.text === undefined ||
                             props.item.text === null ||
                             props.item.description === undefined ||
                             props.item.description === null ||
                             props.item.description === '') {
                             return (
                                 <>
                                     {props.item?.text ?? ''}
                                 </>
                             );
                         }

                         return (
                             <>
                                 <Tag item={{
                                     id: props.item.value,
                                     text: props.item.text,
                                     backgroundColor: TagItemBackgroundColor.Navy,
                                     backgroundColorStr: `#${props.item.description}`
                                 }} containerMaxWidth={200}/>
                             </>
                         );
                     }}
                     onChange={(ev) => {
                         onChange(ev.shaken.map(item => {
                             return {
                                 text: item.text,
                                 value: item.value,
                                 description: item.description ?? undefined
                             } as SelectModel
                         }));
                     }}/>
    );
};

export default FilterPanelTagsFilterContent;