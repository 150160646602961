import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import EditTransport from "./EditTransport";

type EditTransportDialogProps = {
    id: string;
    sId: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditTransportDialog: React.FC<EditTransportDialogProps> = ({
                                                                     id,
                                                                     sId,
                                                                     show,
                                                                     setShow
                                                                 }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'edit-transport-dialog'}
                show={show}
                setShow={setShow}
                title={`${t("shared.btn.edit-transport")} ${sId}`}
                width={'750px'}>
            <EditTransport id={id}
                           sId={sId}
                           close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default EditTransportDialog;