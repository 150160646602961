import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'circle',
    circle: [{
        cx: 8,
        cy: 8,
        r: 8
    }],
    viewBox: '0 0 16 16'
};

export default icon;