import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import RecallBet from "./RecallBet";

type RecallBetDialogProps = {
    betId: number;
    orderReleaseGid: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const RecallBetDialog: React.FC<RecallBetDialogProps> = ({
                                                             betId,
                                                             setShow,
                                                             orderReleaseGid,
                                                             show
                                                         }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'recall-bet-offer-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.btn.recall-rate")}>
            <RecallBet betId={betId}
                       orderReleaseGid={orderReleaseGid}
                       close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default RecallBetDialog;