import React from 'react';
import cl from './SkeletonGridLoader.module.css';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import Loader, {LoaderType} from "../../../Loader/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";

const SkeletonGridLoader = () => {
    return (
        <SkeletonTheme baseColor={'#eaeff3'} highlightColor={'#ffffff'}>
            <div className={`h100 w100 ${cl.grid}`}>
                <div className={cl.header}>
                    <div>
                        <Skeleton height={'15px'}
                                  width={'60%'}
                                  className={cl.headerSkeleton}/>
                    </div>
                    <div>
                        <Skeleton height={'15px'}
                                  width={'60%'}
                                  className={cl.headerSkeleton}/>
                    </div>
                    <div>
                        <Skeleton height={'15px'}
                                  width={'60%'}
                                  className={cl.headerSkeleton}/>
                    </div>
                    <div>
                        <Skeleton height={'15px'}
                                  width={'60%'}
                                  className={cl.headerSkeleton}/>
                    </div>
                    <div>
                        <Skeleton height={'15px'}
                                  width={'60%'}
                                  className={cl.headerSkeleton}/>
                    </div>
                </div>
                <div>
                    <div className={'action-loader-container'}>
                        <div>
                            <Loader type={LoaderType.Action} style={{scale: '0.4'}}/>
                        </div>
                    </div>
                </div>
                <div className={cl.footer}>
                    <div className={cl.pager}>
                        <FontAwesomeIcon icon={faAngleLeft}/>
                        <div>
                            <Skeleton height={'25px'}
                                      width={'25px'}
                                      style={{borderRadius: '35%'}}/>

                            <Skeleton height={'25px'}
                                      width={'25px'}
                                      style={{borderRadius: '35%'}}/>

                            <Skeleton height={'25px'}
                                      width={'25px'}
                                      style={{borderRadius: '35%'}}/>
                        </div>
                        <FontAwesomeIcon icon={faAngleRight}/>
                    </div>
                    <div>
                        <Skeleton height={'15px'}
                                  width={'150px'}/>
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    );
};

export default SkeletonGridLoader;