import React, {useEffect, useState} from 'react';
import cl from './SelfBillingLoginUserListContent.module.css';
import {ILoginUser} from "../../../../../../app/interfaces/login/ILogin";
import {useTranslation} from "react-i18next";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import {AxiosError} from "axios";
import useSelfBillingApi from "../../../../../../app/api/selfBilling";
import Loader from "../../../../../../components/ui/loaders/Loader/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {UserRole} from "../../../../../../app/enums/UserRole";
import Button, {ButtonType} from "../../../../../../components/ui/Button/Button";
import {motion} from "framer-motion";
import {useNavigate} from "react-router-dom";

type SelfBillingLoginUserListContentProps = {
    changeComponent: (usr?: ILoginUser | null) => void;
    componentName: string;
};

const SelfBillingLoginUserListContent: React.FC<SelfBillingLoginUserListContentProps> = ({
                                                                                             changeComponent,

                                                                                         }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [users, setUsers] = useState<Array<ILoginUser> | null>();
    const [selectedUser, setSelectedUser] = useState<ILoginUser | null>();

    const {
        getServiceProvidersLoginsListAnonymous: {
            query,
            isLoading
        }
    } = useSelfBillingApi();

    useEffect(() => {
        (async () => {
            try {
                const response = await query();
                if ((response?.status === 200 || response?.status === 204) &&
                    response.data !== null &&
                    response.data !== undefined &&
                    response.data.resultCode === ResponseResultCode.Ok) {
                    setUsers(response.data.data);
                }
            } catch (error) {
                if (error instanceof AxiosError) {
                    changeComponent();
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <motion.div key={'login-user-list-content'}
                    className={'pd-10'}
                    initial={{opacity: 0.5}}
                    animate={{opacity: 1}}
                    transition={{duration: 1}}
                    exit={{opacity: 0.5}}>
            <div className={cl.title}>{t("login.labels.login-user-select-label")}</div>
            <div className={cl.container}>
                {isLoading && <Loader style={{scale: '0.5'}}/>}
                {users !== null && users !== undefined && users.map((item: ILoginUser) => {
                    return (
                        <div key={item.login}
                             onClick={() => {
                                 if ((selectedUser !== null &&
                                         selectedUser !== undefined &&
                                         selectedUser.login !== item.login) ||
                                     !selectedUser) {
                                     setSelectedUser(item);
                                     changeComponent(item)
                                 }
                             }}
                             className={`${cl.row} ${selectedUser !== null && selectedUser !== undefined && selectedUser.login === item.login ? cl.active : ''}`}>
                            <div>
                                <FontAwesomeIcon icon={faUser}/>
                            </div>
                            <div>
                                <span>
                                    {item.login}
                                </span>
                                {item.role === UserRole.ServiceProvider &&
                                    <span>{`${item.name} / ${item.serviceProviderName}`}</span>
                                }
                                {item.role === UserRole.Forwarder &&
                                    <span>{item.department}</span>
                                }
                            </div>
                        </div>
                    )
                })
                }
            </div>
            <div className={cl.separator}></div>
            <div className={cl.buttonContainer}>


            </div>
        </motion.div>
    );
};

export default SelfBillingLoginUserListContent;
