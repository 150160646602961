import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'chevronRight',
    pathData: [
        'M9 3.74976L14.25 8.99976L9 14.2498'
    ],
    viewBox: '0 0 18 18',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;