import React from 'react';
import cl from './LoginInput.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";

type LoginInputProps = {
    disabled: boolean;
    icon: IconProp;
    error?: string;
} & React.HTMLProps<HTMLInputElement>;

const LoginInput: React.FC<LoginInputProps> = ({
                                                   icon,
                                                   error,
                                                   ...rest
                                               }) => {
    return (
        <div className={cl.container}>
            <div className={cl.inputContainer}>
            <span>
                <FontAwesomeIcon icon={icon}/>
            </span>
                <input className={cl.input} {...rest}/>
            </div>
            {error
                ? <div className='input-error-label'>
                    <FontAwesomeIcon icon={faCircleExclamation}/>
                    <span>
                        {error}
                    </span>
                </div>
                : null
            }
        </div>
    );
};

export default LoginInput;