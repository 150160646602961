import React, {useState} from 'react';
import {IShipment} from "../../../../../../../../../app/interfaces/shipment/IShipment";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {useAppSelector} from "../../../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../../../app/enums/UserRole";
import ChangeRefNumDialog from "../../../../../../actions/ChangeRefNum/ChangeRefNumDialog";
import Icon from "../../../../../../../../../assets/icon/Icon";

type LoadingNumberProps = {
    shipment: IShipment
}

const LoadingNumber: React.FC<LoadingNumberProps> = ({shipment}) => {
    const {t} = useTranslation();
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);

    const user = useAppSelector(selectCurrentUser);

    if (!user) {
        return null;
    }

    const role = getUserRole(user);

    return (
        <div className={'details-box-item-field-value'}>
            <>
                <ChangeRefNumDialog id={shipment.shipmentGid ?? ''}
                                    show={isDialogShown}
                                    setShow={setIsDialogShown}/>

                {(role === UserRole.Forwarder || role === UserRole.Administrator) &&
                    <>
                        <ChangeRefNumDialog id={shipment.shipmentGid ?? ''}
                                            show={isDialogShown}
                                            setShow={setIsDialogShown}/>
                        {shipment.loadingNumber !== null && shipment.loadingNumber !== undefined && shipment.loadingNumber !== '' ?
                            <><Icon icon={"faPen"}
                                    className={'details-box-item-field-gray-icon'}
                                    style={{marginRight: '5px'}}
                                    onClick={() => setIsDialogShown(prev => !prev)}/>{shipment.loadingNumber}
                            </> : <Icon icon={"faPen"}
                                        className={'details-box-item-field-icon'}
                                        style={{marginRight: '5px'}}
                                        onClick={() => setIsDialogShown(prev => !prev)}/>
                        }
                    </>

                }
                {(role === UserRole.ServiceProvider || role === UserRole.Client) ?
                    (shipment.loadingNumber !== null && shipment.loadingNumber !== undefined && shipment.loadingNumber !== '' ? shipment.loadingNumber : t("shared.labels.no-data")) : <></>
                }




            </>
        </div>
    );
};

export default LoadingNumber;
