import {AxiosResponse} from "axios";
import {IDataResponse, IResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {IQuote} from "../../interfaces/quote/IQuote";
import {IAssignQuoteAction} from "../../interfaces/quote/IAssignQuoteAction";
import {ICancelQuoteGetAction} from "../../interfaces/quote/ICancelQuoteGetAction";
import {ICancelQuotePostAction} from "../../interfaces/quote/ICancelQuotePostAction";
import {ISendToAllCarrierAction} from "../../interfaces/quote/ISendToAllCarrierAction";
import {INettoBetGetAction} from "../../interfaces/quote/INettoBetGetAction";
import {ITranspareonAccountRequestPostAction} from "../../interfaces/quote/ITranspareonAccountRequestPostAction";
import {SelectModel} from "../../types/SelectModel";
import {IProfitAction} from "../../interfaces/quote/IProfitAction";
import {INettoBetPostAction} from "../../interfaces/quote/INettoBetPostAction";

export const useGetQuote = (): {
    isLoading: boolean,
    cancel: () => void,
    getQuote: (id: string) => Promise<AxiosResponse<IDataResponse<IQuote>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IQuote>>({
        method: 'get',
    });

    const getQuote = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/quote/getQuote?id=${id}`
    }), [execute]);

    return {getQuote, isLoading, cancel};
};

export const useMarkQuotesAsViewed = (): {
    isLoading: boolean,
    cancel: () => void,
    markQuotesAsViewed: (params: {
        ids: Array<number>
    }) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, {
        ids: Array<number>
    }>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/quote/markAsViewedAction`
    });

    const markQuotesAsViewed = useCallback(async (params: {
        ids: Array<number>
    }) => await execute(params), [execute]);

    return {markQuotesAsViewed, isLoading, cancel};
};

export const useMarkQuotesAsUnViewed = (): {
    isLoading: boolean,
    cancel: () => void,
    markQuotesAsUnViewed: (params: {
        ids: Array<number>
    }) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, {
        ids: Array<number>
    }>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/quote/markAsNotViewedAction`
    });

    const markQuotesAsUnViewed = useCallback(async (params: {
        ids: Array<number>
    }) => await execute(params), [execute]);

    return {markQuotesAsUnViewed, isLoading, cancel};
};

export const useGetQuoteManagementLink = (): {
    isLoading: boolean,
    cancel: () => void,
    getQuoteManagementLink: (id: string) => Promise<AxiosResponse<IDataResponse<string>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<string>>({
        method: 'get',
    });

    const getQuoteManagementLink = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/quote/getQuoteManagementLink?id=${id}`
    }), [execute]);

    return {getQuoteManagementLink, isLoading, cancel};
};

export const useGetCrmLink = (): {
    isLoading: boolean,
    cancel: () => void,
    getCrmLink: (id: string) => Promise<AxiosResponse<IDataResponse<string>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<string>>({
        method: 'get',
    });

    const getCrmLink = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/quote/getCrmLink?id=${id}`
    }), [execute]);

    return {getCrmLink, isLoading, cancel};
};

export const useGetAssignQuoteData = (): {
    isLoading: boolean;
    cancel: () => void;
    getAssignQuoteData: (id: string | Array<string>) => Promise<AxiosResponse<IDataResponse<IAssignQuoteAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IAssignQuoteAction>>({
        method: 'get',
    });

    const getAssignQuoteData = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/quote/getAssignQuoteData?id=${param}`
        });
    }, [execute]);

    return {getAssignQuoteData, isLoading, cancel};
}

export const useAssignQuote = (): {
    isLoading: boolean,
    cancel: () => void,
    assignQuote: (params: IAssignQuoteAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IAssignQuoteAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/quote/assignQuoteAction`
    });

    const assignQuote = useCallback(async (params: IAssignQuoteAction) => await execute(params), [execute]);

    return {assignQuote, isLoading, cancel};
};

export const useGetCancelQuoteData = (): {
    isLoading: boolean;
    cancel: () => void;
    getCancelQuoteData: (id: string | Array<string>) => Promise<AxiosResponse<IDataResponse<ICancelQuoteGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ICancelQuoteGetAction>>({
        method: 'get',
    });

    const getCancelQuoteData = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/quote/getCancelData?id=${param}`
        });
    }, [execute]);

    return {getCancelQuoteData, isLoading, cancel};
}

export const useCancelQuote = (): {
    isLoading: boolean,
    cancel: () => void,
    cancelQuote: (params: ICancelQuotePostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ICancelQuotePostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/quote/cancelAction`
    });

    const cancelQuote = useCallback(async (params: ICancelQuotePostAction) => await execute(params), [execute]);

    return {cancelQuote, isLoading, cancel};
};

export const useGetSendToAllCarrierData = (): {
    isLoading: boolean;
    cancel: () => void;
    getSendToAllCarrierData: (id: string | Array<string>) => Promise<AxiosResponse<IDataResponse<ISendToAllCarrierAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ISendToAllCarrierAction>>({
        method: 'get',
    });

    const getSendToAllCarrierData = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/quote/getSendToAllData?id=${param}`
        });
    }, [execute]);

    return {getSendToAllCarrierData, isLoading, cancel};
}

export const useSendToAllCarrier = (): {
    isLoading: boolean,
    cancel: () => void,
    sendToAllCarrier: (params: ISendToAllCarrierAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ISendToAllCarrierAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/quote/sendToAllAction`
    });

    const sendToAllCarrier = useCallback(async (params: ISendToAllCarrierAction) => await execute(params), [execute]);

    return {sendToAllCarrier, isLoading, cancel};
};

export const useGetQuoteOtmTransporeonLink = (): {
    isLoading: boolean,
    cancel: () => void,
    getQuoteOtmTransporeonLink: (number: string) => Promise<AxiosResponse<IDataResponse<string>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<string>>({
        method: 'get',
    });

    const getQuoteOtmTransporeonLink = useCallback(async (number: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/quote/getOtmTransporeonLink?id=${number}`
    }), [execute]);

    return {getQuoteOtmTransporeonLink, isLoading, cancel};
};

export const useGetNettoBets = (): {
    isLoading: boolean,
    cancel: () => void,
    getNettoBets: (gid: string) => Promise<AxiosResponse<IDataResponse<Array<INettoBetGetAction>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<INettoBetGetAction>>>({
        method: 'get',
    });

    const getNettoBets = useCallback(async (gid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/quote/getPriceInfoData?gid=${gid}`
    }), [execute]);

    return {getNettoBets, isLoading, cancel};
};

export const useSendTranspareonAccountRequest = (): {
    isLoading: boolean,
    cancel: () => void,
    sendTranspareonAccountRequest: (params: ITranspareonAccountRequestPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ITranspareonAccountRequestPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/quote/transpareonAccountRequestAction`
    });

    const sendTranspareonAccountRequest = useCallback(async (params: ITranspareonAccountRequestPostAction) => await execute(params), [execute]);

    return {sendTranspareonAccountRequest, isLoading, cancel};
};

export const useGetServiceProviders = (): {
    isLoading: boolean,
    cancel: () => void,
    getServiceProviders: (name: string) => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
    });

    const getServiceProviders = useCallback(async (name: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/quote/getServiceProviders?name=${name}`
    }), [execute]);

    return {getServiceProviders, isLoading, cancel};
};

export const useGetContracts = (): {
    isLoading: boolean,
    cancel: () => void,
    getContracts: (id: string, spId: string, spName: string) => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
    });

    const getContracts = useCallback(async (id: string, spId: string, spName: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/quote/getContractsList?id=${id}&spId=${spId}&spName=${spName}`
    }), [execute]);

    return {getContracts, isLoading, cancel};
};

export const usePredictBruttoRate = (): {
    isLoading: boolean,
    cancel: () => void,
    predictBruttoRate: (id: string, currency: string) => Promise<AxiosResponse<IDataResponse<string | null>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<string | null>>({
        method: 'get',
    });

    const predictBruttoRate = useCallback(async (id: string, currency: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/quote/predictBruttoRate?id=${id}&currency=${currency}`
    }), [execute]);

    return {predictBruttoRate, isLoading, cancel};
};

export const useGetQuoteBillContacts = (): {
    isLoading: boolean,
    cancel: () => void,
    getQuoteBillContacts: (id: string) => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
    });

    const getQuoteBillContacts = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/quote/getQuoteBillContacts?id=${id}`
    }), [execute]);

    return {getQuoteBillContacts, isLoading, cancel};
};

export const useGetProfit = (): {
    isLoading: boolean,
    cancel: () => void,
    getProfit: (netto: string, brutto: string, nettoCurrencyCode: string, bruttoCurrencyCode: string) => Promise<AxiosResponse<IDataResponse<IProfitAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IProfitAction>>({
        method: 'get',
    });

    const getProfit = useCallback(async (netto: string, brutto: string, nettoCurrencyCode: string, bruttoCurrencyCode: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/quote/getProfit?netto=${netto}&brutto=${brutto}&nettoCurrencyCode=${nettoCurrencyCode}&bruttoCurrencyCode=${bruttoCurrencyCode}`
    }), [execute]);

    return {getProfit, isLoading, cancel};
};

export const useSavePriceInfo = (): {
    isLoading: boolean,
    cancel: () => void,
    savePriceInfo: (params: INettoBetPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, INettoBetPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/quote/savePriceInfoAction`
    });

    const savePriceInfo = useCallback(async (params: INettoBetPostAction) => await execute(params), [execute]);

    return {savePriceInfo, isLoading, cancel};
};