import React, {useEffect, useState} from 'react';
import {IUser} from "../../../../../app/interfaces/user/IUser";
import {useTranslation} from "react-i18next";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {ISelfBillingAddCmrGetAction} from "../../../../../app/interfaces/selfBilling/ISelfBillingAddCmrGetAction";
import useSelfBillingApi from "../../../../../app/api/selfBilling";
import {ISelfBillingAddCmrPostAction} from "../../../../../app/interfaces/selfBilling/ISelfBillingAddCmrPostAction";
import AddCmrDocumentForm from "./AddCmrDocumentForm/AddCmrDocumentForm";
import {BLErrorCode} from "../../../../../app/enums/BLErrorCode";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import Alert from "../../../../../components/ui/Alert/Alert";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";

type AddCmrDocumentProps = {
    shipmentGid: string;
    user?: IUser;
    close?: () => void;
};

const alertStyle: React.CSSProperties = {
    margin: '5px 10px',
    fontSize: '11px',
    fontWeight: 'bold',
}

const AddCmrDocument: React.FC<AddCmrDocumentProps> = ({
                                                           shipmentGid,
                                                           close,
                                                           user
                                                       }) => {
    const {t} = useTranslation();
    const [model, setModel] = useState<ISelfBillingAddCmrGetAction | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError>>([]);

    const {
        getAddCmrSelfBillingDocumentData: {
            isLoading: getAddCmrSelfBillingDocumentDataIsLoading,
            cancel: cancelGetAddCmrSelfBillingDocumentData,
            query: getAddCmrSelfBillingDocumentData
        },
        getAddCmrSelfBillingDocumentDataAnonymous: {
            isLoading: getAddCmrSelfBillingDocumentDataAnonymousIsLoading,
            cancel: cancelGetAddCmrSelfBillingDocumentDataAnonymous,
            query: getAddCmrSelfBillingDocumentDataAnonymous
        },
        addCmrSelfBillingDocumentData: {
            mutation: addCmrSelfBillingDocumentData,
            cancel: cancelAddCmrSelfBillingDocumentData
        },
        addCmrSelfBillingDocumentDataAnonymous: {
            mutation: addCmrSelfBillingDocumentDataAnonymous,
            cancel: cancelAddCmrSelfBillingDocumentDataAnonymous
        }
    } = useSelfBillingApi();

    useEffect(() => {
        if (!shipmentGid || shipmentGid === '') {
            if (close) {
                close();
            }

            return;
        }

        (async () => {
            try {
                const response = user
                    ? await getAddCmrSelfBillingDocumentData(shipmentGid)
                    : await getAddCmrSelfBillingDocumentDataAnonymous(shipmentGid);

                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error<string>(`Unable to get add cmr document data. Error: ${e.message}`);

                    if (close) {
                        close();
                    }
                }
            }
        })();

        return () => {
            cancelGetAddCmrSelfBillingDocumentData();
            cancelGetAddCmrSelfBillingDocumentDataAnonymous();
            cancelAddCmrSelfBillingDocumentData();
            cancelAddCmrSelfBillingDocumentDataAnonymous();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipmentGid]);

    const save = async (values: ISelfBillingAddCmrPostAction): Promise<void> => {
        if (!model) {
            return;
        }

        setDisabled(prev => !prev);

        try {
            let data: FormData = new FormData();

            let isNewPaymentData: boolean = false;

            if (values.mode === 'OwnBankAccount' &&
                values.bankAccountModel &&
                values.bankAccountModel.description &&
                values.bankAccountModel.description === 'ADDED') {
                isNewPaymentData = true;

                data.append('newBankAccount', values.bankAccountModel.value);
                data.append('isNewBankAccount', 'true');
                data.append('isNoInList', 'false');
                data.append('isOtherPayer', 'false');
                data.append('isBankAccount', 'false');
            }

            if (values.mode === 'NewRequisites' && values.financialFile) {
                isNewPaymentData = true;

                data.append('isNoInList', 'true');
                data.append('isBankAccount', 'false');
                data.append('isOtherPayer', 'false');
                data.append('bankAccountNew', 'false');
            }


            if (!isNewPaymentData) {
                if (values.mode === 'OwnBankAccount' && values.bankAccountModel) {
                    data.append('bankAccount', values.bankAccountModel.value);
                    data.append('isBankAccount', 'true');
                    data.append('isNoInList', 'false');
                    data.append('isOtherPayer', 'false');
                    data.append('bankAccountNew', 'false');
                }

                if (values.mode === 'AnotherPaymentRecipients' && values.anotherPaymentRecipientModel) {
                    data.append('anotherPaymentRecipient', values.anotherPaymentRecipientModel.value);
                    data.append('isOtherPayer', 'true');
                    data.append('isNoInList', 'false');
                    data.append('isBankAccount', 'false');
                    data.append('bankAccountNew', 'false');
                }

                if (values.mode === 'NewRequisites' && values.financialFile) {
                    data.append('isNoInList', 'true');
                    data.append('isBankAccount', 'false');
                    data.append('isOtherPayer', 'false');
                    data.append('bankAccountNew', 'false');
                }
            }

            data.append('invoiceNumber', values.invoiceNumber ?? '');
            data.append('category', values.categoryModel.value);
            data.append('costShipmentGid', model.costShipmentGid);
            data.append('notes', values.notes ?? '');
            data.append('orderReleaseGid', values.orderReleaseGid);
            data.append('shipmentGid', values.shipmentGid);

            if (!values.cmrFile) {
                return;
            } else {
                data.append('file', values.cmrFile);
            }

            if (values.financialFile) {
                data.append('financialFile', values.financialFile);
            }

            const response = user
                ? await addCmrSelfBillingDocumentData(data)
                : await addCmrSelfBillingDocumentDataAnonymous(data);

            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("self-billing.labels.cmr-file")}`}/>);

                if (close) {
                    close();
                }
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();

                    toast.error(`Unable to add document. Error: ${err.message}`);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if (!shipmentGid || shipmentGid === '') {
        return null;
    }

    if (getAddCmrSelfBillingDocumentDataAnonymousIsLoading || getAddCmrSelfBillingDocumentDataIsLoading || model === null) {
        return (
            <FormLoadingScreen height={`350px`} style={{padding: '5px'}}/>
        );
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                {errors.map(item => {
                    switch (item.errorCode) {
                        case BLErrorCode.InnerError:
                            return (
                                <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                    {item.description}
                                </Alert>
                            );
                        case BLErrorCode.CmrNotSupportedFormat:
                            return (
                                <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                    {t("self-billing.errors.cmr-not-supported-format")}
                                </Alert>
                            );
                        case BLErrorCode.CmrFileIsBig:
                            return (
                                <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                    {t("self-billing.errors.cmr-file-is-big")}
                                </Alert>
                            );
                        case BLErrorCode.CmrFileIsEmpty:
                            return (
                                <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                    {t("self-billing.errors.cmr-file-is-empty")}
                                </Alert>
                            );
                        case BLErrorCode.FinFileIsEmpty:
                            return (
                                <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                    {t("self-billing.errors.fin-file-is-empty")}
                                </Alert>
                            );
                        case BLErrorCode.CmrHasInvoice:

                            return (
                                <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                    {t("self-billing.errors.cmr-has-invoice").replace('{0}', item.description ?? '')}
                                </Alert>
                            );
                    }

                    return null;
                })}

                <AddCmrDocumentForm model={model}
                                    disabled={disabled}
                                    onSubmit={save}/>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        form='add-cmr-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.confirm")}
                </Button>
            </div>
        </div>
    );
};

export default AddCmrDocument;