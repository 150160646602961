import React, {useEffect, useState} from 'react';
import cl from './LocationMap.module.css';
import LocationMapEventsSection from "./components/LocationMapEventsSection/LocationMapEventsSection";
import LocationMapMapSection from "./components/LocationMapMapSection/LocationMapMapSection";
import {useShipmentApi} from "../../../../../app/api/shipment";
import {toast} from "react-toastify";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {IMapPositioningGetAction} from "../../../../../app/interfaces/shipment/IMapPositioningGetAction";
import {IMapPositioningPostAction} from "../../../../../app/interfaces/shipment/IMapPositioningPostAction";
import LocationMapConfirmationDialog from "./components/LocationMapConfirmationDialog/LocationMapConfirmationDialog";
import moment from "moment";


type LocationMapProps = {
    shipmentGid: string;
    close?: () => void;
}

const LocationMap: React.FC<LocationMapProps> = ({
                                                     shipmentGid,
                                                     close
                                                 }) => {
    const [model, setModel] = useState<IMapPositioningGetAction | null>(null);
    const [saveModel, setSaveModel] = useState<IMapPositioningPostAction | null>(null);
    const [isConfirmationDialogShown, setIsConfirmationDialogShown] = useState<boolean>(false);

    const {
        getMapPositioningData: {
            query: getMapPositioningData,
            isLoading,
            cancel: cancelGetMapPositioningData
        }
    } = useShipmentApi();

    useEffect(() => {
        if (shipmentGid === '') {
            if (close) {
                close()
            } else {
                return;
            }
        }

        (async () => {
            try {
                const response = await getMapPositioningData(shipmentGid);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch {
                if (close) {
                    close();
                } else {
                    toast.error<string>('Unable to get map positioning data');
                }
            }
        })();

        return () => {
            cancelGetMapPositioningData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipmentGid]);

    const save = (item: IMapPositioningGetAction, lt: number, lg: number) => {
        setSaveModel({
            shipmentGid: item.shipmentGid,
            newLg: lg,
            newLt: lt,
            date: moment().format('DD.MM.YYYY HH:mm')
        });

        setIsConfirmationDialogShown(prev => !prev);
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`98vh`} style={{padding: '5px'}}/>
        );
    }

    return (
        <div className={cl.container}>
            {saveModel &&
                <LocationMapConfirmationDialog model={saveModel}
                                               onSaved={() => {
                                                   setIsConfirmationDialogShown(false);
                                                   if (close) {
                                                       close();
                                                   }
                                               }}
                                               isDialogShown={isConfirmationDialogShown}
                                               setIsDialogShown={setIsConfirmationDialogShown}/>
            }
            <div>
                <LocationMapEventsSection model={model}/>
            </div>
            <div>
                <LocationMapMapSection model={model}
                                       save={save}/>
            </div>
        </div>
    );
};

export default LocationMap;