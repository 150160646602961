import {
    useAcceptTransportsAction,
    useAddBet, useAddBetAnonymous,
    useAssignOffer,
    useCancelSpot,
    useChangeEquipmentGroup,
    useChangePlannedData,
    useConfirmAuction, useConfirmAuctionAnonymous,
    useConfirmAuctionContract,
    useDeclineTransportsAction,
    useDeleteAction,
    useDeleteRemark,
    useEditRemark,
    useGetAcceptTransportsData,
    useGetAddBetData, useGetAddBetDataAnonymous,
    useGetAssignData,
    useGetCancelSpotDataAction,
    useGetChangeDateData,
    useGetChangeEquipmentGroupData,
    useGetChangeNoteData,
    useGetCheckForwarderForTransEUAccess, useGetCheckForwarderForTransEUAccessAnonymous,
    useGetClientStatus,
    useGetConfirmAuctionContractData,
    useGetConfirmAuctionData, useGetConfirmAuctionDataAnonymous,
    useGetDeclineTransportsData,
    useGetDeleteActionData,
    useGetEditRemarkData,
    useGetFreeStockOffersCount,
    useGetOffer, useGetOfferAnonymous,
    useGetOfferManagementLink,
    useGetRecallBetData, useGetRecallBetDataAnonymous,
    useGetRefNumberData,
    useGetRemoveFromFreeStockActionData,
    useGetResetAssigmentActionData,
    useGetSelectBetData,
    useGetSendContractData,
    useGetSendContractDataAnonymous,
    useGetSendToFreeStockActionData,
    useGetServiceProviders, useGetServiceProvidersAnonymous,
    useGetTransEULink,
    useGetTransEULoginData, useGetTransEULoginDataAnonymous,
    useGetTransporeonBookingLink,
    useGetValidateClient, useGetValidateClientAnonymous,
    useGetValidateExecutionAgent, useGetValidateExecutionAgentAnonymous,
    useMarkOffersAsNotViewed,
    useMarkOffersAsViewed,
    useRecallBet, useRecallBetAnonymous,
    useRemoveFromFreeStock,
    useResetAssigment,
    useSaveNote,
    useSaveRefNumberData,
    useSelectBet,
    useSendContract, useSendContractAnonymous,
    useSendToFreeStock,
    useWorkPostAction
} from "./requests";

export const useOfferApi = () => {
    const {
        isLoading: getOfferIsLoading,
        cancel: cancelGetOffer,
        getOffer
    } = useGetOffer();

    const {
        isLoading: getOfferAnonymousIsLoading,
        cancel: cancelGetOfferAnonymous,
        getOfferAnonymous
    } = useGetOfferAnonymous();

    const {
        isLoading: markOffersAsViewedIsLoading,
        cancel: cancelMarkOffersAsViewed,
        markOffersAsViewed
    } = useMarkOffersAsViewed();

    const {
        isLoading: markOffersAsNotViewedIsLoading,
        cancel: cancelMarkOffersAsNotViewed,
        markOffersAsNotViewed
    } = useMarkOffersAsNotViewed();

    const {
        isLoading: getOfferManagementLinkIsLoading,
        cancel: cancelGetOfferManagementLink,
        getOfferManagementLink
    } = useGetOfferManagementLink();

    const {
        isLoading: getTransEULinkIsLoading,
        cancel: cancelGetTransEULink,
        getTransEULink
    } = useGetTransEULink();

    const {
        isLoading: workPostActionIsLoading,
        cancel: cancelWorkPostAction,
        workPostAction
    } = useWorkPostAction();

    const {
        isLoading: getClientStatusIsLoading,
        cancel: cancelGetClientStatus,
        getClientStatus
    } = useGetClientStatus();

    const {
        isLoading: saveNoteIsLoading,
        cancel: cancelSaveNote,
        saveNote
    } = useSaveNote();

    const {
        isLoading: getFreeStockOffersCountIsLoading,
        cancel: cancelGetFreeStockOffersCount,
        getFreeStockOffersCount
    } = useGetFreeStockOffersCount();

    const {
        isLoading: getChangeNoteDataIsLoading,
        cancel: cancelGetChangeNoteData,
        getChangeNoteData
    } = useGetChangeNoteData();

    const {
        isLoading: getRefNumberDataIsLoading,
        cancel: cancelGetRefNumberData,
        getRefNumberData
    } = useGetRefNumberData();

    const {
        isLoading: saveRefNumberDataIsLoading,
        cancel: cancelSaveRefNumberData,
        saveRefNumberData
    } = useSaveRefNumberData();

    const {
        isLoading: getChangeDateDataIsLoading,
        cancel: cancelGetChangeDateData,
        getChangeDateData
    } = useGetChangeDateData();

    const {
        isLoading: changePlannedDateIsLoading,
        cancel: cancelChangePlannedDate,
        changePlannedDate
    } = useChangePlannedData();

    const {
        isLoading: getTransporeonBookingLinkIsLoading,
        cancel: cancelGetTransporeonBookingLink,
        getTransporeonBookingLink
    } = useGetTransporeonBookingLink();

    const {
        isLoading: changeEquipmentGroupIsLoading,
        cancel: cancelChangeEquipmentGroup,
        changeEquipmentGroup
    } = useChangeEquipmentGroup();

    const {
        isLoading: getChangeEquipmentGroupDataIsLoading,
        cancel: cancelGetChangeEquipmentGroupData,
        getChangeEquipmentGroupData
    } = useGetChangeEquipmentGroupData();

    const {
        isLoading: getAcceptTransportsDataIsLoading,
        cancel: cancelGetAcceptTransportsData,
        getAcceptTransportsData
    } = useGetAcceptTransportsData();

    const {
        isLoading: getDeclineTransportsDataIsLoading,
        cancel: cancelGetDeclineTransportsData,
        getDeclineTransportsData
    } = useGetDeclineTransportsData();

    const {
        isLoading: acceptTransportsIsLoading,
        cancel: cancelAcceptTransports,
        acceptTransports
    } = useAcceptTransportsAction();

    const {
        isLoading: declineTransportsIsLoading,
        cancel: cancelDeclineTransports,
        declineTransports
    } = useDeclineTransportsAction();

    const {
        isLoading: getSendToFreeStockActionDataIsLoading,
        cancel: cancelGetSendToFreeStockActionData,
        getSendToFreeStockActionData
    } = useGetSendToFreeStockActionData();

    const {
        isLoading: sendToFreeStockIsLoading,
        cancel: cancelSendToFreeStock,
        sendToFreeStock
    } = useSendToFreeStock();

    const {
        isLoading: getRemoveFromFreeStockActionDataIsLoading,
        cancel: cancelGetRemoveFromFreeStockActionData,
        getRemoveFromFreeStockActionData
    } = useGetRemoveFromFreeStockActionData();

    const {
        isLoading: removeFromFreeStockIsLoading,
        cancel: cancelRemoveFromFreeStock,
        removeFromFreeStock
    } = useRemoveFromFreeStock();

    const {
        isLoading: getResetAssigmentDataIsLoading,
        cancel: cancelGetResetAssigmentData,
        getResetAssigmentData
    } = useGetResetAssigmentActionData();

    const {
        isLoading: resetAssigmentIsLoading,
        cancel: cancelResetAssigment,
        resetAssigment
    } = useResetAssigment();

    const {
        isLoading: getCancelSpotDataIsLoading,
        cancel: cancelGetCancelSpotData,
        getCancelSpotData
    } = useGetCancelSpotDataAction();

    const {
        isLoading: cancelSpotIsLoading,
        cancel: cancelCancelSpot,
        cancelSpot
    } = useCancelSpot();

    const {
        isLoading: getAssignDataIsLoading,
        cancel: cancelGetAssignData,
        getAssignData
    } = useGetAssignData();

    const {
        isLoading: assignOfferIsLoading,
        cancel: cancelAssignOffer,
        assignOffer
    } = useAssignOffer();

    const {
        isLoading: getSendContractDataIsLoading,
        cancel: cancelGetSendContractData,
        getSendContractData
    } = useGetSendContractData();

    const {
        isLoading: sendContractIsLoading,
        cancel: cancelSendContract,
        sendContract
    } = useSendContract();

    const {
        isLoading: getDeleteDataIsLoading,
        cancel: cancelGetDeleteData,
        getDeleteData
    } = useGetDeleteActionData();

    const {
        isLoading: deleteActionIsLoading,
        cancel: cancelDeleteAction,
        deleteAction
    } = useDeleteAction();

    const {
        isLoading: getConfirmAuctionDataIsLoading,
        cancel: cancelGetConfirmAuctionData,
        getConfirmAuctionData
    } = useGetConfirmAuctionData();

    const {
        isLoading: confirmAuctionIsLoading,
        cancel: cancelConfirmAuction,
        confirmAuction
    } = useConfirmAuction();

    const {
        isLoading: getConfirmAuctionDataAnonymousIsLoading,
        cancel: cancelGetConfirmAuctionDataAnonymous,
        getConfirmAuctionDataAnonymous
    } = useGetConfirmAuctionDataAnonymous();

    const {
        isLoading: confirmAuctionAnonymousIsLoading,
        cancel: cancelConfirmAuctionAnonymous,
        confirmAuctionAnonymous
    } = useConfirmAuctionAnonymous();

    const {
        isLoading: getConfirmAuctionContractDataIsLoading,
        cancel: cancelGetConfirmAuctionContractData,
        getConfirmAuctionContractData
    } = useGetConfirmAuctionContractData();

    const {
        isLoading: confirmAuctionContractIsLoading,
        cancel: cancelConfirmAuctionContract,
        confirmAuctionContract
    } = useConfirmAuctionContract();

    const {
        isLoading: getRecallBetDataIsLoading,
        cancel: cancelGetRecallBetData,
        getRecallBetData
    } = useGetRecallBetData();

    const {
        isLoading: recallBetIsLoading,
        cancel: cancelRecallBet,
        recallBet
    } = useRecallBet();

    const {
        isLoading: getRecallBetDataAnonymousIsLoading,
        cancel: cancelGetRecallBetDataAnonymous,
        getRecallBetDataAnonymous
    } = useGetRecallBetDataAnonymous();

    const {
        isLoading: recallBetAnonymousIsLoading,
        cancel: cancelRecallBetAnonymous,
        recallBetAnonymous
    } = useRecallBetAnonymous();

    const {
        isLoading: getAddBetDataIsLoading,
        cancel: cancelGetAddBetData,
        getAddBetData
    } = useGetAddBetData();

    const {
        isLoading: addBetIsLoading,
        cancel: cancelAddBet,
        addBet
    } = useAddBet();

    const {
        isLoading: getEditRemarkDataIsLoading,
        cancel: cancelGetEditRemarkData,
        getEditRemarkData
    } = useGetEditRemarkData();

    const {
        isLoading: editRemarkIsLoading,
        cancel: cancelEditRemark,
        editRemark
    } = useEditRemark();

    const {
        isLoading: deleteRemarkIsLoading,
        cancel: cancelDeleteRemark,
        deleteRemark
    } = useDeleteRemark();

    const {
        isLoading: getSelectBetDataIsLoading,
        cancel: cancelGetSelectBetData,
        getSelectBetData
    } = useGetSelectBetData();

    const {
        isLoading: selectBetIsLoading,
        cancel: cancelSelectBet,
        selectBet
    } = useSelectBet();


    const {
        isLoading: getServiceProvidersIsLoading,
        cancel: cancelGetServiceProviders,
        getServiceProviders
    } = useGetServiceProviders();

    const {
        isLoading: checkForwarderForTransEUAccessIsLoading,
        cancel: cancelCheckForwarderForTransEUAccess,
        checkForwarderForTransEUAccess
    } = useGetCheckForwarderForTransEUAccess();

    const {
        isLoading: getTransEULoginDataIsLoading,
        cancel: cancelGetTransEULoginData,
        getTransEULoginData
    } = useGetTransEULoginData();

    const {
        isLoading: validateExecutionAgentIsLoading,
        cancel: cancelValidateExecutionAgent,
        validateExecutionAgent
    } = useGetValidateExecutionAgent();

    const {
        isLoading: validateClientIsLoading,
        cancel: cancelValidateClient,
        validateClient
    } = useGetValidateClient();

    const {
        isLoading: getSendContractDataAnonymousIsLoading,
        cancel: cancelGetSendContractDataAnonymous,
        getSendContractDataAnonymous
    } = useGetSendContractDataAnonymous();

    const {
        isLoading: sendContractAnonymousIsLoading,
        cancel: cancelSendContractAnonymous,
        sendContractAnonymous
    } = useSendContractAnonymous();

    const {
        isLoading: validateExecutionAgentAnonymousIsLoading,
        cancel: cancelValidateExecutionAgentAnonymous,
        validateExecutionAgentAnonymous
    } = useGetValidateExecutionAgentAnonymous();

    const {
        isLoading: validateClientAnonymousIsLoading,
        cancel: cancelValidateClientAnonymous,
        validateClientAnonymous
    } = useGetValidateClientAnonymous();

    const {
        isLoading: checkForwarderForTransEUAccessAnonymousIsLoading,
        cancel: cancelCheckForwarderForTransEUAccessAnonymous,
        checkForwarderForTransEUAccessAnonymous
    } = useGetCheckForwarderForTransEUAccessAnonymous();

    const {
        isLoading: getTransEULoginDataAnonymousIsLoading,
        cancel: cancelGetTransEULoginDataAnonymous,
        getTransEULoginDataAnonymous
    } = useGetTransEULoginDataAnonymous();

    const {
        isLoading: getServiceProvidersAnonymousIsLoading,
        cancel: cancelGetServiceProvidersAnonymous,
        getServiceProvidersAnonymous
    } = useGetServiceProvidersAnonymous();

    const {
        isLoading: getAddBetDataAnonymousIsLoading,
        cancel: cancelGetAddBetDataAnonymous,
        getAddBetDataAnonymous
    } = useGetAddBetDataAnonymous();

    const {
        isLoading: addBetAnonymousIsLoading,
        cancel: cancelAddBetAnonymous,
        addBetAnonymous
    } = useAddBetAnonymous();

    return {
        markOffersAsViewed: {
            mutation: markOffersAsViewed,
            isLoading: markOffersAsViewedIsLoading,
            cancel: cancelMarkOffersAsViewed
        },
        markOffersAsNotViewed: {
            mutation: markOffersAsNotViewed,
            isLoading: markOffersAsNotViewedIsLoading,
            cancel: cancelMarkOffersAsNotViewed
        },
        getOfferManagementLink: {
            query: getOfferManagementLink,
            isLoading: getOfferManagementLinkIsLoading,
            cancel: cancelGetOfferManagementLink
        },
        getTransEULink: {
            query: getTransEULink,
            isLoading: getTransEULinkIsLoading,
            cancel: cancelGetTransEULink
        },
        workPostAction: {
            mutation: workPostAction,
            isLoading: workPostActionIsLoading,
            cancel: cancelWorkPostAction
        },
        getOffer: {
            query: getOffer,
            isLoading: getOfferIsLoading,
            cancel: cancelGetOffer
        },
        getClientStatus: {
            query: getClientStatus,
            isLoading: getClientStatusIsLoading,
            cancel: cancelGetClientStatus
        },
        saveNote: {
            mutation: saveNote,
            isLoading: saveNoteIsLoading,
            cancel: cancelSaveNote
        },
        getChangeNoteData: {
            query: getChangeNoteData,
            isLoading: getChangeNoteDataIsLoading,
            cancel: cancelGetChangeNoteData
        },
        getRefNumberData: {
            query: getRefNumberData,
            isLoading: getRefNumberDataIsLoading,
            cancel: cancelGetRefNumberData
        },
        saveRefNumberData: {
            mutation: saveRefNumberData,
            isLoading: saveRefNumberDataIsLoading,
            cancel: cancelSaveRefNumberData
        },
        changePlannedDate: {
            mutation: changePlannedDate,
            isLoading: changePlannedDateIsLoading,
            cancel: cancelChangePlannedDate
        },
        getChangeDateData: {
            query: getChangeDateData,
            isLoading: getChangeDateDataIsLoading,
            cancel: cancelGetChangeDateData
        },
        changeEquipmentGroup: {
            mutation: changeEquipmentGroup,
            isLoading: changeEquipmentGroupIsLoading,
            cancel: cancelChangeEquipmentGroup
        },
        getChangeEquipmentGroupData: {
            query: getChangeEquipmentGroupData,
            isLoading: getChangeEquipmentGroupDataIsLoading,
            cancel: cancelGetChangeEquipmentGroupData
        },
        getTransporeonBookingLink: {
            query: getTransporeonBookingLink,
            isLoading: getTransporeonBookingLinkIsLoading,
            cancel: cancelGetTransporeonBookingLink
        },
        getAcceptTransportsData: {
            query: getAcceptTransportsData,
            isLoading: getAcceptTransportsDataIsLoading,
            cancel: cancelGetAcceptTransportsData
        },
        getDeclineTransportsData: {
            query: getDeclineTransportsData,
            isLoading: getDeclineTransportsDataIsLoading,
            cancel: cancelGetDeclineTransportsData
        },
        acceptTransports: {
            mutation: acceptTransports,
            isLoading: acceptTransportsIsLoading,
            cancel: cancelAcceptTransports
        },
        declineTransports: {
            mutation: declineTransports,
            isLoading: declineTransportsIsLoading,
            cancel: cancelDeclineTransports
        },
        getSendToFreeStockActionData: {
            query: getSendToFreeStockActionData,
            isLoading: getSendToFreeStockActionDataIsLoading,
            cancel: cancelGetSendToFreeStockActionData
        },
        sendToFreeStock: {
            mutation: sendToFreeStock,
            isLoading: sendToFreeStockIsLoading,
            cancel: cancelSendToFreeStock
        },
        getRemoveFromFreeStockActionData: {
            query: getRemoveFromFreeStockActionData,
            isLoading: getRemoveFromFreeStockActionDataIsLoading,
            cancel: cancelGetRemoveFromFreeStockActionData,
        },
        removeFromFreeStock: {
            mutation: removeFromFreeStock,
            isLoading: removeFromFreeStockIsLoading,
            cancel: cancelRemoveFromFreeStock
        },
        getCancelSpotData: {
            query: getCancelSpotData,
            isLoading: getCancelSpotDataIsLoading,
            cancel: cancelGetCancelSpotData
        },
        cancelSpot: {
            mutation: cancelSpot,
            isLoading: cancelSpotIsLoading,
            cancel: cancelCancelSpot
        },
        getResetAssigmentData: {
            query: getResetAssigmentData,
            isLoading: getResetAssigmentDataIsLoading,
            cancel: cancelGetResetAssigmentData
        },
        resetAssigment: {
            mutation: resetAssigment,
            isLoading: resetAssigmentIsLoading,
            cancel: cancelResetAssigment
        },
        getAssignData: {
            query: getAssignData,
            isLoading: getAssignDataIsLoading,
            cancel: cancelGetAssignData
        },
        assignOffer: {
            mutation: assignOffer,
            isLoading: assignOfferIsLoading,
            cancel: cancelAssignOffer
        },
        getSendContractData: {
            query: getSendContractData,
            isLoading: getSendContractDataIsLoading,
            cancel: cancelGetSendContractData
        },
        sendContract: {
            mutation: sendContract,
            isLoading: sendContractIsLoading,
            cancel: cancelSendContract
        },
        getDeleteData: {
            query: getDeleteData,
            isLoading: getDeleteDataIsLoading,
            cancel: cancelGetDeleteData
        },
        deleteAction: {
            mutation: deleteAction,
            isLoading: deleteActionIsLoading,
            cancel: cancelDeleteAction
        },
        getConfirmAuctionData: {
            query: getConfirmAuctionData,
            isLoading: getConfirmAuctionDataIsLoading,
            cancel: cancelGetConfirmAuctionData
        },
        confirmAuction: {
            mutation: confirmAuction,
            isLoading: confirmAuctionIsLoading,
            cancel: cancelConfirmAuction
        },
        getConfirmAuctionDataAnonymous: {
            query: getConfirmAuctionDataAnonymous,
            isLoading: getConfirmAuctionDataAnonymousIsLoading,
            cancel: cancelGetConfirmAuctionDataAnonymous
        },
        confirmAuctionAnonymous: {
            mutation: confirmAuctionAnonymous,
            isLoading: confirmAuctionAnonymousIsLoading,
            cancel: cancelConfirmAuctionAnonymous
        },
        getConfirmAuctionContractData: {
            query: getConfirmAuctionContractData,
            isLoading: getConfirmAuctionContractDataIsLoading,
            cancel: cancelGetConfirmAuctionContractData
        },
        confirmAuctionContract: {
            mutation: confirmAuctionContract,
            isLoading: confirmAuctionContractIsLoading,
            cancel: cancelConfirmAuctionContract
        },
        getRecallBetData: {
            query: getRecallBetData,
            isLoading: getRecallBetDataIsLoading,
            cancel: cancelGetRecallBetData
        },
        recallBet: {
            mutation: recallBet,
            isLoading: recallBetIsLoading,
            cancel: cancelRecallBet
        },
        getRecallBetDataAnonymous: {
            query: getRecallBetDataAnonymous,
            isLoading: getRecallBetDataAnonymousIsLoading,
            cancel: cancelGetRecallBetDataAnonymous
        },
        recallBetAnonymous: {
            mutation: recallBetAnonymous,
            isLoading: recallBetAnonymousIsLoading,
            cancel: cancelRecallBetAnonymous
        },
        addBet: {
            mutation: addBet,
            isLoading: addBetIsLoading,
            cancel: cancelAddBet
        },
        getAddBetData: {
            query: getAddBetData,
            isLoading: getAddBetDataIsLoading,
            cancel: cancelGetAddBetData
        },
        getEditRemarkData: {
            query: getEditRemarkData,
            isLoading: getEditRemarkDataIsLoading,
            cancel: cancelGetEditRemarkData
        },
        editRemark: {
            mutation: editRemark,
            isLoading: editRemarkIsLoading,
            cancel: cancelEditRemark
        },
        deleteRemark: {
            mutation: deleteRemark,
            isLoading: deleteRemarkIsLoading,
            cancel: cancelDeleteRemark
        },
        getSelectBetData: {
            query: getSelectBetData,
            isLoading: getSelectBetDataIsLoading,
            cancel: cancelGetSelectBetData
        },
        selectBet: {
            mutation: selectBet,
            isLoading: selectBetIsLoading,
            cancel: cancelSelectBet
        },
        getServiceProviders: {
            query: getServiceProviders,
            isLoading: getServiceProvidersIsLoading,
            cancel: cancelGetServiceProviders
        },

        checkForwarderForTransEUAccess: {
            query: checkForwarderForTransEUAccess,
            isLoading: checkForwarderForTransEUAccessIsLoading,
            cancel: cancelCheckForwarderForTransEUAccess
        },
        getTransEULoginData: {
            query: getTransEULoginData,
            isLoading: getTransEULoginDataIsLoading,
            cancel: cancelGetTransEULoginData
        },
        validateExecutionAgent: {
            query: validateExecutionAgent,
            isLoading: validateExecutionAgentIsLoading,
            cancel: cancelValidateExecutionAgent
        },
        validateClient: {
            query: validateClient,
            isLoading: validateClientIsLoading,
            cancel: cancelValidateClient
        },
        getFreeStockOffersCount: {
            query: getFreeStockOffersCount,
            isLoading: getFreeStockOffersCountIsLoading,
            cancel: cancelGetFreeStockOffersCount
        },
        validateExecutionAgentAnonymous: {
            query: validateExecutionAgentAnonymous,
            isLoading: validateExecutionAgentAnonymousIsLoading,
            cancel: cancelValidateExecutionAgentAnonymous
        },
        validateClientAnonymous: {
            query: validateClientAnonymous,
            isLoading: validateClientAnonymousIsLoading,
            cancel: cancelValidateClientAnonymous
        },
        getSendContractDataAnonymous: {
            query: getSendContractDataAnonymous,
            isLoading: getSendContractDataAnonymousIsLoading,
            cancel: cancelGetSendContractDataAnonymous
        },
        sendContractAnonymous: {
            mutation: sendContractAnonymous,
            isLoading: sendContractAnonymousIsLoading,
            cancel: cancelSendContractAnonymous
        },
        checkForwarderForTransEUAccessAnonymous: {
            query: checkForwarderForTransEUAccessAnonymous,
            isLoading: checkForwarderForTransEUAccessAnonymousIsLoading,
            cancel: cancelCheckForwarderForTransEUAccessAnonymous
        },
        getTransEULoginDataAnonymous: {
            query: getTransEULoginDataAnonymous,
            isLoading: getTransEULoginDataAnonymousIsLoading,
            cancel: cancelGetTransEULoginDataAnonymous
        },
        getServiceProvidersAnonymous: {
            query: getServiceProvidersAnonymous,
            isLoading: getServiceProvidersAnonymousIsLoading,
            cancel: cancelGetServiceProvidersAnonymous
        },
        getOfferAnonymous: {
            query: getOfferAnonymous,
            isLoading: getOfferAnonymousIsLoading,
            cancel: cancelGetOfferAnonymous
        },
        getAddBetDataAnonymous: {
            query: getAddBetDataAnonymous,
            isLoading: getAddBetDataAnonymousIsLoading,
            cancel: cancelGetAddBetDataAnonymous
        },
        addBetAnonymous: {
            mutation: addBetAnonymous,
            isLoading: addBetAnonymousIsLoading,
            cancel: cancelAddBetAnonymous
        }
    };
}
