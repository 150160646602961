import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'circleLocationDot',
    pathData: [
        'M3.33301 9.52391C2.09877 10.0684 1.33301 10.8271 1.33301 11.6663C1.33301 13.3232 4.31778 14.6663 7.99967 14.6663C11.6816 14.6663 14.6663 13.3232 14.6663 11.6663C14.6663 10.8271 13.9006 10.0684 12.6663 9.52391M11.9997 5.33301C11.9997 8.04215 8.99967 9.33301 7.99967 11.333C6.99967 9.33301 3.99967 8.04215 3.99967 5.33301C3.99967 3.12387 5.79054 1.33301 7.99967 1.33301C10.2088 1.33301 11.9997 3.12387 11.9997 5.33301ZM8.66634 5.33301C8.66634 5.7012 8.36786 5.99967 7.99967 5.99967C7.63148 5.99967 7.33301 5.7012 7.33301 5.33301C7.33301 4.96482 7.63148 4.66634 7.99967 4.66634C8.36786 4.66634 8.66634 4.96482 8.66634 5.33301Z'
    ],
    viewBox: '0 0 16 16',
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
    strokeWidth: 1
};

export default icon;