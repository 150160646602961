import React from 'react';
import {IOffer} from "../../../../../../../../app/interfaces/offer/IOffer";
import Button, {ButtonType} from "../../../../../../../../components/ui/Button/Button";
import {useTranslation} from "react-i18next";
import {useOfferApi} from "../../../../../../../../app/api/offer";
import {ResponseResultCode} from "../../../../../../../../app/enums/ResponseResultCode";

type OfferTransporeonBookingDataProps = {
    offer: IOffer;
};

const OfferTransporeonBookingData: React.FC<OfferTransporeonBookingDataProps> = ({offer}) => {
    const {t} = useTranslation();

    const {
        getTransporeonBookingLink: {
            query
        }
    } = useOfferApi();

    if (offer.transporeonBookingData === null || offer.transporeonBookingData === '') {
        return null;
    }

    return (
        <Button buttonType={ButtonType.Primary}
                onClick={async () => {
                    try {
                        const response = await query(offer.orderReleaseGid);

                        if (response && response.data && response.data.resultCode === ResponseResultCode.Ok &&
                            response.data.data) {
                            window.open(response.data.data, '_blank');
                        }
                    } catch {
                        //ignore
                    }
                }}
                style={{
                    padding: '0 10px',
                    fontSize: '11px',
                    fontWeight: 'bold',
                    height: '20px'
                }}>
            {t("shared.btn.show-in-transporeon-time-slot")}
        </Button>
    );
};

export default OfferTransporeonBookingData;