import React, {useMemo, useRef, useState} from 'react';
import {defaultMapOptions, useLoadScriptOptions} from "../../../../../../../components/ui/GoogleMap/utils/map";
import {
    Circle,
    CircleProps,
    GoogleMap,
    InfoWindow,
    Marker,
    MarkerProps,
    Polyline,
    PolylineProps,
    useLoadScript
} from "@react-google-maps/api";
import {useTranslation} from "react-i18next";
import FormLoadingScreen from "../../../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {IMapPositioningGetAction} from "../../../../../../../app/interfaces/shipment/IMapPositioningGetAction";
import {
    buildLocationMapCurrentTrackingPositionMarker, buildRoutePointCirclesArray, buildRoutePointMarkersArray,
    buildStopPointCirclesArray,
    buildStopPointMarkersArray,
    LocationMapExtendedMarkerProps
} from "../../utils";
import {getBoundsToMarkers} from "../../../../../../../components/shared/form/FormMap/utils";
import LocationMapMarkerInfoWindow from "../LocationMapMarkerInfoWindow/LocationMapMarkerInfoWindow";
import GoogleMapCustomControl
    from "../../../../../../../components/ui/GoogleMap/GoogleMapCustomControl/GoogleMapCustomControl";
import Button, {ButtonType} from "../../../../../../../components/ui/Button/Button";
import MapSearchBox from "../../../../../../../components/ui/GoogleMap/MapSearchBox/MapSearchBox";
import {useShipmentApi} from "../../../../../../../app/api/shipment";
import {toast} from "react-toastify";
import AdvancedMarker, {
    AdvancedMarkerProps
} from "../../../../../../../components/ui/GoogleMap/AdvancedMarker/AdvancedMarker";
import {useAppSelector} from "../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../app/enums/UserRole";
import moment from "moment";
import LocationMapRouteMarkerInfoWindow from "../LocationMapRouteMarkerInfoWindow/LocationMapRouteMarkerInfoWindow";

type LocationMapMapSectionProps = {
    model: IMapPositioningGetAction;
    save: (model: IMapPositioningGetAction, lt: number, lg: number) => void;
}

const LocationMapMapSection: React.FC<LocationMapMapSectionProps> = ({model, save}) => {
    const {i18n, t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    const [routeLines, setRouteLines] = useState<Array<PolylineProps>>([]);
    const [bounds, setBounds] = useState<google.maps.LatLngBounds | null>(null);
    const [stopPointMarkers, setStopPointMarkers] = useState<Array<LocationMapExtendedMarkerProps>>([]);
    const [stopPointCircles, setStopPointCircles] = useState<Array<CircleProps>>([]);
    const [currentPositionMarker, setCurrentPositionMarker] = useState<MarkerProps | null>(null);
    const [advancedMarkers, setAdvancedMarkers] = useState<Array<AdvancedMarkerProps>>([]);
    const [routePointMarkers, setRoutePointMarkers] = useState<Array<LocationMapExtendedMarkerProps>>([]);
    const [routePointCircles, setRoutePointCircles] = useState<Array<CircleProps>>([]);

    const [isReferenceSet, setIsReferenceSet] = useState<boolean>(false);
    const [isSearchBoxShown, setIsSearchBoxShown] = useState<boolean>(false);
    const [isSaveButtonShown, setIsSaveButtonShown] = useState<boolean>(false);
    const [activeInfoWindow, setActiveInfoWindow] = useState<string | null>(null);

    const map = useRef<google.maps.Map | null>(null);
    const options = useMemo(() => (defaultMapOptions), []);
    const {isLoaded} = useLoadScript({
        ...useLoadScriptOptions,
        language: i18n.language,
    });

    const {
        getRoute: {
            query: getRoute
        },
        getTrackPoints: {
            query: getTrackPoints
        }
    } = useShipmentApi();

    if (user === null) {
        return null;
    }

    const handleInfoWindow = (id: string, ev: 'close' | 'open') => {
        if (ev === 'close') {
            setActiveInfoWindow(null);
        } else {
            setActiveInfoWindow(id);
        }
    }

    const onMapLoad = (mapRef: google.maps.Map) => {
        map.current = mapRef;

        const markers = buildStopPointMarkersArray(model, handleInfoWindow);
        const circles = buildStopPointCirclesArray(model);

        setStopPointMarkers(markers);
        setStopPointCircles(circles);

        const bounds = getBoundsToMarkers(markers);

        map.current?.fitBounds(bounds);
        setBounds(bounds);

        setIsReferenceSet(true);

        map.current?.addListener('rightclick', function (ev: google.maps.MapMouseEvent) {
            if (!ev.latLng || model.isCompleted) {
                return;
            }

            const pos = {
                lat: ev.latLng.lat(),
                lng: ev.latLng.lng()
            }

            initCurrentPositionMarker({
                lat: pos.lat,
                lng: pos.lng
            });

            if (!isSaveButtonShown) {
                setIsSaveButtonShown(true);
            }
        });

        if (!model.isCompleted) {
            setIsSearchBoxShown(true);
        }

        showRoute().then(() => {
            // ignore
        });

        let isStarted: boolean = true;
        if (user) {
            let role = getUserRole(user);
            const currentUtcDate = new Date(moment.utc().format());

            if ((role === UserRole.ServiceProvider || role === UserRole.Client) &&
                model.yearlyPickupDate >= currentUtcDate) {
                isStarted = false;
            }
        }


        if (isStarted) {
            if (model.hasTracking) {
                showRoutePoints().then(() => {
                    //ignore
                });

                let currentTrackingPositionMarker = buildLocationMapCurrentTrackingPositionMarker(model);
                if (currentTrackingPositionMarker !== null) {
                    setAdvancedMarkers([currentTrackingPositionMarker]);
                }
            } else {
                showRoutePoints().then(() => {
                    //ignore
                });

                if (model.currentPosition !== null && model.currentPosition.lt !== null && model.currentPosition.lt !== 0 &&
                    model.currentPosition.lg !== null && model.currentPosition.lg !== 0) {
                    initCurrentPositionMarker({
                        lat: model.currentPosition.lt ?? 0,
                        lng: model.currentPosition.lg ?? 0,
                    });
                }
            }
        }
    }

    const initCurrentPositionMarker = (props: {
        lat: number;
        lng: number;
    }) => {
        if (currentPositionMarker || model.isCompleted) {
            setCurrentPositionMarker(null);
        }

        let markerLocal: MarkerProps = {
            icon: model.importDirection
                ? '/images/marker_green_export.png'
                : '/images/marker_green.png',
            title: '',
            draggable: true,
            zIndex: 10,
            position: {
                lat: props.lat,
                lng: props.lng
            }
        };

        markerLocal.onDragEnd = function (ev) {
            if (!ev.latLng || model.isCompleted) {
                return;
            }

            const pos = {
                lat: ev.latLng.lat(),
                lng: ev.latLng.lng()
            }

            setCurrentPositionMarker({
                ...markerLocal,
                title: '',
                position: {
                    lat: pos.lat,
                    lng: pos.lng
                }
            });

            if (map.current) {
                map.current?.panTo({
                    lat: pos.lat,
                    lng: pos.lng
                });
            }

            if (!isSaveButtonShown) {
                setIsSaveButtonShown(true);
            }
        }

        setCurrentPositionMarker(markerLocal);

        if (map.current) {
            map.current?.panTo({
                lat: props.lat,
                lng: props.lng
            });

            map.current?.setZoom(12);

            let boundsLocal = new google.maps.LatLngBounds();

            boundsLocal.extend({
                lat: props.lat,
                lng: props.lng
            });

            setBounds(boundsLocal);
        }
    }

    const onPlaceChange = (items: Array<google.maps.places.PlaceResult>) => {
        if (items.length <= 0 || model.isCompleted) {
            return;
        }

        let item = items[0];

        if (!item.geometry || !item.geometry.location) {
            return;
        }

        initCurrentPositionMarker({
            lat: item.geometry.location.lat(),
            lng: item.geometry.location.lng()
        });

        if (!isSaveButtonShown) {
            setIsSaveButtonShown(true);
        }
    }

    const showRoute = async () => {
        try {
            const response = await getRoute(model.shipmentGid);
            if (response && response.status === 200 && response.data && response.data.data &&
                response.data.data.length > 0) {
                let lines: Array<PolylineProps> = [];

                for (let l of response.data.data) {
                    lines.push({
                        path: google.maps.geometry.encoding.decodePath(l),
                        options: {
                            strokeColor: '#00BFFF',
                            strokeOpacity: 1.0,
                            strokeWeight: 2,
                        }
                    });
                }

                setRouteLines(lines);
            }
        } catch (e) {
            toast.error('Unable to get route');
        }
    }

    const showRoutePoints = async () => {
        try {
            const response = await getTrackPoints(model.shipmentGid);
            if (response && response.status === 200 && response.data && response.data.data &&
                response.data.data.length > 0) {
                const markers = buildRoutePointMarkersArray(response.data.data, handleInfoWindow);
                const circles = buildRoutePointCirclesArray(response.data.data);

                setRoutePointMarkers(markers);
                setRoutePointCircles(circles);
            }
        } catch (e) {
            toast.error('Unable to get track points');
        }
    }

    if (!isLoaded) {
        return (
            <FormLoadingScreen height={`100%`} style={{padding: '5px'}}/>
        );
    }

    return (
        <div className={'relative h100 pd-5 location-map'}>
            <GoogleMap mapContainerClassName="h100"
                       options={options}
                       onLoad={onMapLoad}>
                {routePointMarkers.map((marker, index) => {
                    return (
                        <Marker key={index} {...marker}>
                            {activeInfoWindow !== null && activeInfoWindow === marker.id &&
                                <InfoWindow>
                                    <LocationMapRouteMarkerInfoWindow position={marker.pos}/>
                                </InfoWindow>
                            }
                        </Marker>
                    );
                })}
                {routePointCircles.map((circle, index) => {
                    return (
                        <Circle key={index} {...circle} />
                    );
                })}
                {stopPointMarkers.map((marker, index) => {
                    return (
                        <Marker key={index} {...marker}>
                            {activeInfoWindow !== null && activeInfoWindow === marker.id &&
                                <InfoWindow>
                                    <LocationMapMarkerInfoWindow stopPoint={marker.sp}/>
                                </InfoWindow>
                            }
                        </Marker>
                    );
                })}
                {stopPointCircles.map((circle, index) => {
                    return (
                        <Circle key={index} {...circle} />
                    );
                })}
                {isSaveButtonShown &&
                    <GoogleMapCustomControl position={google.maps.ControlPosition.TOP_RIGHT}
                                            map={map.current}
                                            isMapLoaded={isReferenceSet}
                                            className={'pac-button-outer-container'}>
                        <Button buttonType={ButtonType.Primary}
                                onClick={() => {
                                    if (!currentPositionMarker) {
                                        return;
                                    }

                                    const pos = {
                                        lat: typeof (currentPositionMarker.position.lat) === 'function'
                                            ? currentPositionMarker.position.lat()
                                            : currentPositionMarker.position.lat,
                                        lng: typeof (currentPositionMarker.position.lng) === 'function'
                                            ? currentPositionMarker.position.lng()
                                            : currentPositionMarker.position.lng,
                                    }

                                    save(model, pos.lat, pos.lng);
                                }}>
                            {t("shared.btn.save")}
                        </Button>
                    </GoogleMapCustomControl>
                }
                {isSearchBoxShown &&
                    <GoogleMapCustomControl position={google.maps.ControlPosition.TOP_LEFT}
                                            map={map.current}
                                            isMapLoaded={isReferenceSet}
                                            className={'pac-outer-container'}>
                        <MapSearchBox map={map.current}
                                      onPlacesChanged={onPlaceChange}
                                      isMapLoaded={isReferenceSet}
                                      bounds={bounds ?? undefined}
                                      placeholder={t("shared.labels.search-address")}/>
                    </GoogleMapCustomControl>
                }
                {currentPositionMarker && <Marker {...currentPositionMarker} />}
                {routeLines.length > 0 &&
                    routeLines.map((line, index) => {
                        return (
                            <Polyline {...line} key={index}/>
                        );
                    })
                }
                {advancedMarkers.map((marker, index) => {
                    if (map.current === null) {
                        return null;
                    }

                    return (
                        <AdvancedMarker {...marker} key={index} map={map.current}>
                            {marker.children}
                        </AdvancedMarker>
                    );
                })}
            </GoogleMap>
        </div>
    );
};

export default LocationMapMapSection;