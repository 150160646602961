import React, {useState} from 'react';
import {IShipment} from "../../../../../../../../../app/interfaces/shipment/IShipment";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {useAppSelector} from "../../../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../../../app/enums/UserRole";
import ChangeRefNumDialog from "../../../../../../actions/ChangeRefNum/ChangeRefNumDialog";
import {useShipmentApi} from "../../../../../../../../../app/api/shipment";
import Icon from "../../../../../../../../../assets/icon/Icon";

type LoadingNumberProps = {
    shipment: IShipment
}

const CostNetto: React.FC<LoadingNumberProps> = ({shipment}) => {
    const {t} = useTranslation();
    const {
        getShipmentCostLink: {
            query: getShipmentCostLink
        }
    } = useShipmentApi();
    const goToCostEdit = async (id: string | null) => {

        try {
            if (id) {
                const response = await getShipmentCostLink(id);
                if (response?.status === 200 && response.data && response.data) {

                    // @ts-ignore
                    window.open(response.data, '_blank');
                }
            }
        } catch {
            //ignore
        }
    }

    const user = useAppSelector(selectCurrentUser);

    if (!user) {
        return null;
    }

    const role = getUserRole(user);

    return (
        <div className={'details-box-item-field-value'}>
            <>

                        {shipment.costBrutto !== null && shipment.costBrutto !== undefined && shipment.costBrutto !== '' ?
                            <><Icon icon={"faPen"}
                            className={'details-box-item-field-gray-icon'}
                            style={{marginRight: '5px'}}
                            onClick={async () => {
                                await goToCostEdit(shipment.shipmentGid)
                            }}/>{shipment.costBrutto}</>
                    : <><Icon icon={"faPen"}
                              className={'details-box-item-field-icon'}
                              style={{marginRight: '5px'}}
                              onClick={async () => {
                                  await goToCostEdit(shipment.shipmentGid)
                              }}/><span className={'details-box-field-icon'}>
                        {t("shipment.grid.cost-netto-adjustment")}
                    </span></>}


            </>
        </div>
    );
};

export default CostNetto;
