import React from 'react';
import {IShipment} from "../../../../../../../app/interfaces/shipment/IShipment";
import {useTranslation} from "react-i18next";

type AliasNumberProps = {
    shipment: IShipment;
}
const AliasNumber: React.FC<AliasNumberProps> = ({
                                                     shipment
                                                 }) => {
    const {t} = useTranslation();

    return (
        <>
            <label className={'alias-number-label'}>{t('shipment.form.alias-number')}</label>
            <span className={'details-box-item-field-value alias-number-value'}>
                {shipment.aliasNumber !== null && shipment.aliasNumber !== ''
                    ? <>{shipment.aliasNumber}</>
                    : <>{t("shared.labels.no-data")}</>
                }
            </span>
        </>
    );
};

export default AliasNumber;