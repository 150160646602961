import {AxiosResponse} from "axios";
import {IResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {IExportGrid} from "../../interfaces/shared/IExportGrid";

export const useGetDocument = (): {
    isLoading:boolean,
    cancel: () => void,
    getDocument: (fileName: string) => Promise<AxiosResponse<IResponse | Blob> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse | Blob>({
        method: 'get',
        responseType: 'blob'
    });

    const getDocument = useCallback(async (fileName: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/document/getDocument?fileName=${fileName}`
    }), [execute]);

    return {getDocument, isLoading, cancel};
};

export const useGridExportToDocument = (): {
    isLoading: boolean,
    cancel: () => void,
    exportToDocument: (params: IExportGrid) => Promise<AxiosResponse<IResponse | Blob> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse | Blob, IExportGrid>({
        method: 'POST',
        responseType: 'blob'
    });

    const exportToDocument = useCallback(async (params: IExportGrid) => await execute(params, {
        url: `api/${process.env.REACT_APP_API_VERSION}/document/downloadExportDocument`
    }), [execute]);

    return {exportToDocument, isLoading, cancel};
};