import React from 'react';
import {SelectModel} from "../../../../../app/types/SelectModel";
import TagContainer from "../../../../ui/Tag/TagContainer/TagContainer";
import {TagItemBackgroundColor} from "../../../../ui/Tag/Tag";

type FilterPanelEquipmentFilterSelectedContainerProps = {
    id: string;
    onClear: (value?: string) => void;
    value: Array<SelectModel>;
};

const FilterPanelEquipmentFilterSelectedContainer: React.FC<FilterPanelEquipmentFilterSelectedContainerProps> = ({
                                                                                                                     id,
                                                                                                                     value,
                                                                                                                     onClear
                                                                                                                 }) => {
    return (
        <div style={{marginBottom: '5px'}}>
            <TagContainer id={`${id}-tag`}
                          width={'100%'}
                          height={'100px'}
                          items={value.map(item => {
                              return {
                                  id: `item-${id}-${item.value}`,
                                  text: item.text,
                                  data: item,
                                  showClearIcon: true,
                                  backgroundColor: TagItemBackgroundColor.LightGray,
                                  rounded: true
                              };
                          })}
                          onClear={({data}) => {
                              if (data !== undefined && data !== null) {
                                  onClear(data.value as string);
                              }
                          }}/>
        </div>
    );
};

export default FilterPanelEquipmentFilterSelectedContainer;