import i18n from "i18next";
import {
    loadMessages,
    load
} from "@progress/kendo-react-intl";

import ruLocale from '../app/kendo/locale/ru.json';
import enLocale from '../app/kendo/locale/en.json';
import plLocale from '../app/kendo/locale/pl.json';

import ruNumbers from 'cldr-numbers-full/main/ru/numbers.json';
import enNumbers from 'cldr-numbers-full/main/en/numbers.json';
import plNumbers from 'cldr-numbers-full/main/pl/numbers.json';

import ruCaGregorian from "cldr-dates-full/main/ru/ca-gregorian.json";
import enCaGregorian from "cldr-dates-full/main/en/ca-gregorian.json";
import plCaGregorian from "cldr-dates-full/main/pl/ca-gregorian.json";

import ruDateFields from "cldr-dates-full/main/ru/dateFields.json";
import enDateFields from "cldr-dates-full/main/en/dateFields.json";
import plDateFields from "cldr-dates-full/main/pl/dateFields.json";

import ruTimeZoneNames from "cldr-dates-full/main/ru/timeZoneNames.json";
import enTimeZoneNames from "cldr-dates-full/main/en/timeZoneNames.json";
import plTimeZoneNames from "cldr-dates-full/main/pl/timeZoneNames.json";

import weekData from "cldr-core/supplemental/weekData.json";

export const loadKendoLocalization = (loc: typeof i18n): void => {
    let locale = getKendoLocaleString(loc);

    switch (locale) {
        case 'ru-RU':
            loadMessages(ruLocale, locale);

            load(ruNumbers, ruTimeZoneNames, ruDateFields, ruCaGregorian, weekData);
            break;
        case 'pl-PL':
            loadMessages(plLocale, locale);

            load(plNumbers, plTimeZoneNames, plDateFields, plCaGregorian, weekData);
            break;
        case 'en-EN':
            loadMessages(enLocale, locale);

            load(enNumbers, enTimeZoneNames, enDateFields, enCaGregorian, weekData);
            break;
    }
}

export const getKendoLocaleString = (loc: typeof i18n): string => {
    return `${loc.language.toLowerCase()}-${loc.language.toUpperCase()}`;
}