import React from 'react';
import {INoteAction} from "../../../../../../app/interfaces/shipment/INoteAction";
import {useTranslation} from "react-i18next";
import {FormikHelpers, useFormik} from "formik";
import Textarea from "../../../../../../components/ui/Textarea/Textarea";

type ChangeNoteFormProps = {
    model: INoteAction;
    disabled: boolean;
    save: (model: INoteAction) => Promise<void>;
}

const ChangeNoteForm: React.FC<ChangeNoteFormProps> = ({
                                                           model,
                                                           save,
                                                           disabled
                                                       }) => {
    const {t} = useTranslation();

    const form = useFormik<INoteAction>({
        initialValues: {
            orderReleaseGid: model.orderReleaseGid,
            shipmentGid: model.shipmentGid,
            note: model.note
        },
        onSubmit: async (values: INoteAction, actions: FormikHelpers<INoteAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='change-note-form'
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <Textarea disabled={disabled}
                          className={'change-note-note-value'}
                          style={{minHeight: '150px', resize: 'vertical', maxHeight: '350px'}}
                          value={form.values.note ?? ''}
                          placeholder={t("shipment.grid.note-label")}
                          onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                              form.setFieldValue('note', ev.target.value);
                          }}/>
            </div>
        </form>
    );
};

export default ChangeNoteForm;