import React, {useEffect, useState} from 'react';
import {
    IForwarderNotificationGetAction
} from "../../../../../../app/interfaces/notification/IForwarderNotificationGetAction";
import {IError} from "../../../../../../app/interfaces/response/IResponse";
import useNotificationApi from "../../../../../../app/api/notification";
import {isAxiosError} from "axios";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import ToastLayout from "../../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import NotificationGridComponent from "../NotificationGridComponent/NotificationGridComponent";
import {useTranslation} from "react-i18next";
import {
    IForwarderNotificationPostAction
} from "../../../../../../app/interfaces/notification/IForwarderNotificationPostAction";
import {
    IForwarderNotificationSetupPostAction
} from "../../../../../../app/interfaces/notification/IForwarderNotificationSetupPostAction";
import {
    IForwarderNotificationSetupGetAction
} from "../../../../../../app/interfaces/notification/IForwarderNotificationSetupGetAction";
import * as Yup from "yup";
import {BLErrorCode} from "../../../../../../app/enums/BLErrorCode";
import {IUser} from "../../../../../../app/interfaces/user/IUser";
import Button, {ButtonType} from "../../../../../../components/ui/Button/Button";

type NotificationGridContentProps = {
    close: () => void;
    user?: IUser;
}

const NotificationGridContent: React.FC<NotificationGridContentProps> = ({
                                                                             close
                                                                         }) => {
    const {t} = useTranslation();

    const [disabled, setDisabled] = useState<boolean>(false);

    const [model, setModel] = useState<IForwarderNotificationGetAction | null>(null);
    const [modelToSave, setModelToSave] = useState<IForwarderNotificationPostAction | null>(null);

    const [_errors, setErrors] = useState<Array<IError>>([]);

    const {
        getForwarderNotificationDetailsData: {
            query: getForwarderNotificationDetailsData,
            isLoading: getForwarderNotificationDetailsDataIsLoading,
            cancel: cancelGetForwarderNotificationDetailsData
        },
        saveForwarderNotificationDetailsData: {
            mutation: saveForwarderNotificationDetailsData,
            isLoading: saveForwarderNotificationDetailsDataIsLoading,
            cancel: cancelSaveForwarderNotificationDetailsData
        }
    } = useNotificationApi();

    useEffect(() => {
        (async () => {
            await loadDetails();
        })();

        return () => {
            cancelGetForwarderNotificationDetailsData();
            cancelSaveForwarderNotificationDetailsData();
        }
    }, []);

    const loadDetails = async (): Promise<void> => {
        try {
            const response = await getForwarderNotificationDetailsData();
            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok && response.data.data) {
                setModel({
                    ...response.data.data,
                    setups: response.data.data.setups.map(st => ({
                        ...st,
                        errors: []
                    }))
                });

                setModelToSave({
                    setups: response.data.data.setups.map(setup => {
                        return {
                            id: setup.id,
                            templateId: setup.templateId,
                            isEmail: setup.isEmail,
                            category: setup.category,
                            fromSelf: setup.fromSelf,
                            fromSystem: setup.fromSystem,
                            fromSp: setup.fromSp,
                            fromClient: setup.fromClient,
                            fromFw: setup.fromFw,
                            toCM: setup.toCM,
                            toTL: setup.toTL,
                            toFA: setup.toFA,
                            toExA: setup.toExA,
                            toOR: setup.toOR,
                            toSA: setup.toSA,
                            toCC: setup.toCC,
                            sendPeriod: setup.sendPeriod,
                            sendSchedule1: setup.sendSchedule1,
                            sendSchedule2: setup.sendSchedule2,
                            sendSchedule3: setup.sendSchedule3,
                        } as IForwarderNotificationSetupPostAction;
                    })
                });
            }
        } catch (err) {
            if (isAxiosError(err)) {
                toast.error(`Unable to load notification data. Error: ${err.message}`);
            }
        }
    }

    const onSave = async () => {
        if (!model || !modelToSave) {
            return;
        }

        setDisabled(prev => !prev);

        try {
            let res: Array<IForwarderNotificationSetupGetAction> = [];
            let isValid = true;

            for await (const st of model.setups) {
                if (st.toCC !== null && st.toCC !== '') {
                    let isEmailValid = await Yup.array()
                        .transform(function (value, originalValue) {
                            if (this.isType(value) && value !== null) {
                                return value;
                            }

                            return originalValue ? originalValue.replaceAll(';', ',').split(/[\s,]+/) : [];
                        })
                        .of(Yup.string().email(({value}) => `${t("profile.support.invalid-email")} (${value})`))
                        .of(Yup.string().test(
                            'asstra_email_required',
                            '@asstra.com email required',
                            function (item) {
                                if (!item) {
                                    return true;
                                }

                                return item.toLowerCase().endsWith('@asstra.com');
                            }
                        ))
                        .isValid(st.toCC);

                    if (!isEmailValid) {
                        isValid = isEmailValid;
                    }

                    res.push({
                        ...st,
                        errors: isEmailValid
                            ? []
                            : [{
                                errorCode: BLErrorCode.InvalidEmailFormat,
                                field: 'email',
                                description: `${t("profile.support.invalid-email")} (${st.toCC})`
                            }]
                    });
                } else {
                    res.push({
                        ...st,
                        errors: []
                    });
                }
            }

            setModel({
                setups: res
            });

            if (!isValid) {
                setDisabled(prev => !prev);

                return;
            }

            const response = await saveForwarderNotificationDetailsData(modelToSave);

            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                toast.success(<ToastLayout title={t("sidebar.notification")}
                                           text={t("notification.labels.notification-details-saved-label")}/>);

                close();
                //await loadDetails();
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else {
                    toast.error(`Unable to save details. Error: ${err.message}`);
                }
            }
        }

        setDisabled(prev => !prev);
    };

    const onChange = (items: Array<IForwarderNotificationSetupGetAction>, field: keyof IForwarderNotificationSetupGetAction, value: string | boolean) => {
        if (!model || model.setups.length <= 0) {
            return;
        }

        let res: Array<IForwarderNotificationSetupGetAction> = [];

        model.setups.forEach(st => {
            let existItem = items.find(e => e.templateId === st.templateId);

            res.push(existItem ? {
                ...existItem,
                [field]: value
            } : {...st});
        });

        setModel({
            setups: res
        });

        setModelToSave({
            setups: res.map(setup => {
                return {
                    id: setup.id,
                    templateId: setup.templateId,
                    isEmail: setup.isEmail,
                    category: setup.category,
                    fromSelf: setup.fromSelf,
                    fromSystem: setup.fromSystem,
                    fromSp: setup.fromSp,
                    fromClient: setup.fromClient,
                    fromFw: setup.fromFw,
                    toCM: setup.toCM,
                    toTL: setup.toTL,
                    toFA: setup.toFA,
                    toExA: setup.toExA,
                    toOR: setup.toOR,
                    toSA: setup.toSA,
                    toCC: setup.toCC,
                    sendPeriod: setup.sendPeriod,
                    sendSchedule1: setup.sendSchedule1,
                    sendSchedule2: setup.sendSchedule2,
                    sendSchedule3: setup.sendSchedule3,
                } as IForwarderNotificationSetupPostAction;
            })
        });
    }

    return (
        <div className={'h100 w100'}>
            {/*<NotificationGridActionRow onSave={onSave}
                                       disabled={disabled || getForwarderNotificationDetailsDataIsLoading || saveForwarderNotificationDetailsDataIsLoading}/>*/}
            <div className={'dialog-default-content-container'}>
                <div style={{height: 'calc(100% - 40px)'}}>
                    <NotificationGridComponent setups={model?.setups ?? []}
                                               disabled={disabled || saveForwarderNotificationDetailsDataIsLoading}
                                               isLoading={getForwarderNotificationDetailsDataIsLoading}
                                               onChange={onChange}/>
                </div>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        onClick={onSave}
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.save")}
                </Button>
            </div>
        </div>
    );
};

export default NotificationGridContent;
