import React from 'react';
import {IPosition} from "../../../../app/interfaces/shared/IPosition";
import {ISpEvent} from "../../../../app/interfaces/shared/ISpEvent";
import cl from './LatestEvent.module.css';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {getEventLabel} from "../../../../helpers/shipment";
import {IStopPoint} from "../../../../app/interfaces/shared/IStopPoint";
import Icon from "../../../../assets/icon/Icon";

type LatestEventProps = {
    currentPosition: IPosition | null;
    latestEvent: ISpEvent | null;
    stopPoints: Array<IStopPoint>;
}

const LatestEvent: React.FC<LatestEventProps> = ({
                                                     latestEvent,
                                                     currentPosition,
                                                     stopPoints
                                                 }) => {
    const {t} = useTranslation();


    let stopPoint = latestEvent != null && latestEvent.spPosition !== null
        ? stopPoints.find(e => e.position === Number(latestEvent.spPosition))
        : null;

    let label =latestEvent != null ? getEventLabel(latestEvent.status ?? '', latestEvent.type ?? '', stopPoint) : '';

    return (
        <div className={cl.container}>
            <div className={`latest-event-event-section ${cl.row}`}
                 style={currentPosition && currentPosition.addressChangedOnLocal ? {
                     marginBottom: '15px'
                 } : undefined}>
                <div>
                    <Icon icon={"faHollowCircle"}
                          fill={'white'}
                          style={{color: '#B7B5B5', fontSize: '13px'}}/>
                </div>
                <div>
                    <label className={cl.label}>
                        {t("shared.labels.latest-event")}
                    </label>

                    {latestEvent != null ? <div className={cl.text}>
                        {stopPoint && stopPoint.info
                            ? `${moment(latestEvent.date).format('DD.MM.YY HH:mm')} ${label ? t(label) : ''} ${stopPoint.info}, ${latestEvent.address}`
                            : `${moment(latestEvent.date).format('DD.MM.YY HH:mm')} ${label ? t(label) : ''}, ${latestEvent.address}`
                        }
                    </div> : <div className={cl.text}>{t("shared.labels.no-data")}</div>}
                </div>
            </div>


                <div className={`latest-event-event-section ${cl.row}`}>
                    <div>
                        <Icon icon={"faHollowCircle"}
                              fill={'white'}
                              style={{color: '#E96024', fontSize: '13px'}}/>
                    </div>
                    <div>
                        <label className={cl.label}>
                            {t("shared.labels.current-position")}
                        </label>

                        {currentPosition && currentPosition.addressChangedOnLocal &&
                        moment(currentPosition.addressChangedOnLocal).format('DD.MM.YY HH:mm') != "01.01.01 00:00" ? <div className={cl.text}>
                            {`${moment(currentPosition.addressChangedOnLocal).format('DD.MM.YY HH:mm')} ${currentPosition.address}`}

                        </div>: <div className={cl.text}>{t("shared.labels.no-data")}</div>}
                    </div>
                </div>

        </div>
    );
};

export default LatestEvent;
