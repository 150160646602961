import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import SendQuoteToAll from "./SendQuoteToAll";

type SendQuoteToAllDialogProps = {
    id: string | Array<string>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const SendQuoteToAllDialog: React.FC<SendQuoteToAllDialogProps> = ({
                                                                       id,
                                                                       show,
                                                                       setShow
                                                                   }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'send-quote-to-all-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.btn.send-quote-to-all")}>
            <SendQuoteToAll id={id}
                            close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default SendQuoteToAllDialog;