import React, {useCallback, useEffect, useState} from 'react';
import {SelectModel} from "../../../../../../../../../../app/types/SelectModel";
import {useQuoteApi} from "../../../../../../../../../../app/api/quote";
import {debounce} from "debounce";
import {ComboBox} from "@progress/kendo-react-dropdowns";

type NettoBetAsstraCompanySelectorProps = {
    value?: string;
    disabled: boolean;
    orderReleaseGid: string;
    onChange: (value: string | null) => void;
}

const NettoBetAsstraCompanySelector: React.FC<NettoBetAsstraCompanySelectorProps> = ({
                                                                                         value,
                                                                                         disabled,
                                                                                         onChange,
                                                                                         orderReleaseGid
                                                                                     }) => {
    const [initialItems, setInitialItems] = useState<Array<SelectModel>>([]);
    const [items, setItems] = useState<Array<SelectModel>>([]);
    const [filter, setFilter] = useState<string | undefined>();
    const [selectedItem, setSelectedItem] = useState<SelectModel | null>(null);

    const {
        getQuoteBillContacts: {
            query,
            isLoading,
            cancel
        }
    } = useQuoteApi();

    useEffect(() => {
        (async () => {
            await load();
        })();

        return () => {
            cancel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (initialItems.length > 0 && filter) {
            if (filter.length > 3) {
                let filtered = initialItems.filter(e => {
                    return e.text.toLowerCase().includes(filter.toLowerCase());
                });

                setItems([...filtered]);
            }
        } else {
            setItems(initialItems);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);


    useEffect(() => {
        if (initialItems.length <= 0) {
            return;
        }

        if (value !== undefined && value !== null && value !== '') {
            let item = items.find(e => e.value === value);

            if (item) {
                setSelectedItem({
                    ...item
                });
            }
        } else {
            setSelectedItem(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, initialItems]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const load = useCallback(debounce(async () => {
        try {
            const response = await query(orderReleaseGid);

            if (response?.status === 200 && response.data?.data) {
                let data = response.data.data.filter(e => e.text !== '' && e.value !== '')
                setInitialItems(data);
                setItems(data);
            }
        } catch {
            //ignore
        }
    }, 500), []);

    return (
        <ComboBox data={items}
                  value={selectedItem}
                  onChange={ev => {
                      onChange(ev.value?.value ?? null);
                  }}
                  disabled={disabled}
            //  placeholder={t("quote.bet.asstra-company")}
                  textField="text"
                  dataItemKey="value"
                  clearButton={true}
                  filterable={true}
                  loading={isLoading}
                  onFilterChange={(ev) => {
                      if ((ev.nativeEvent === undefined || ev.nativeEvent === null) && (
                          ev.syntheticEvent === undefined || ev.syntheticEvent === null
                      )) {
                          setFilter(undefined);

                          return;
                      }

                      if (value !== null && value !== undefined && value !== '') {
                          onChange(null);
                      }

                      setFilter(ev.filter.value);
                  }}
        />
    );
};

export default NettoBetAsstraCompanySelector;
