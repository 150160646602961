import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import DeleteRemark from "./DeleteRemark";

type DeleteRemarkDialogProps = {
    id: string;
    code: string;
    seq: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const DeleteRemarkDialog: React.FC<DeleteRemarkDialogProps> = ({
                                                                   id,
                                                                   code,
                                                                   seq,
                                                                   show,
                                                                   setShow
                                                               }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'delete-remark-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.btn.delete-remark")}>
            <DeleteRemark id={id}
                          code={code}
                          seq={seq}
                          close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default DeleteRemarkDialog;