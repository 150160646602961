import React, {useEffect, useState} from 'react';
import FormLayout from "../../../../../components/uiLayouts/FormLayout/FormLayout";
import {
    FormLayoutToolbarActionItem
} from "../../../../../components/uiLayouts/FormLayout/components/FormLayoutToolbar/FormLayoutToolbar";
import {useTranslation} from "react-i18next";
import {ButtonType} from "../../../../../components/ui/Button/Button";
import {INotificationGetAction} from "../../../../../app/interfaces/notification/INotificationGetAction";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import useNotificationApi from "../../../../../app/api/notification";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import NotificationForm from "./NotificationForm/NotificationForm";
import {useAppSelector} from "../../../../../app/store";
import {selectCurrentUser} from "../../../../../features/authentication/authenticationSliceSelectors";
import {UserRole} from "../../../../../app/enums/UserRole";
import Alert from "../../../../../components/ui/Alert/Alert";
import cl from './NotificationContent.module.css';
import {useProfileApi} from "../../../../../app/api/profile";
import {INotificationPostAction} from "../../../../../app/interfaces/notification/INotificationPostAction";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {BLErrorCode} from "../../../../../app/enums/BLErrorCode";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";

const alertStyle: React.CSSProperties = {
    fontSize: '11px',
    fontWeight: 'bold',
    padding: '10px 5px',
    margin: '5px',
    width: 'fit-content'
};

const NotificationContent: React.FC = () => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    const [disabled] = useState<boolean>(false);
    const [actions, setActions] = useState<Array<FormLayoutToolbarActionItem | 'separator'>>([]);
    const [model, setModel] = useState<INotificationGetAction | null>(null);
    const [errors, setErrors] = useState<Array<IError>>([]);

    const {
        getNotificationDetailsData: {
            query: getNotificationDetailsData,
            isLoading: getNotificationDetailsDataIsLoading,
            cancel: cancelGetNotificationDetailsData
        },
        saveNotificationDetailsData: {
            mutation: saveNotificationDetailsData,
            cancel: cancelSaveNotificationDetailsData
        }
    } = useNotificationApi();

    const {
        resendConfirmationEmail: {
            query: resendConfirmationEmail
        },
        getIsEmailNeedConfirmation: {
            query: getIsEmailNeedConfirmation
        }
    } = useProfileApi();


    useEffect(() => {
        (async () => {
            await loadDetails();
        })();

        return () => {
            cancelGetNotificationDetailsData();
            cancelSaveNotificationDetailsData();
        }
    }, []);

    useEffect(() => {
        setActions(buildActionsList());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    useEffect(() => {
        if (!model || !user || user.role !== UserRole.ServiceProvider) {
            return;
        }

        let interval: NodeJS.Timer = setInterval(() => {
            (async () => {
                if (!model) {
                    return;
                }

                try {
                    const response = await getIsEmailNeedConfirmation();
                    if (response?.status === 200 && response.data && response.data.data !== undefined && response.data.data !== null) {
                        if (model.isNewEmailNeedConfirmation !== response.data.data) {
                            setModel({
                                ...model,
                                isNewEmailNeedConfirmation: response.data.data
                            });
                        }
                    }
                } catch {
                    // ignore
                }
            })();
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [model, user]);

    const loadDetails = async (): Promise<void> => {
        try {
            const response = await getNotificationDetailsData();
            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok && response.data.data) {
                setModel(response.data.data);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                toast.error(`Unable to load notification data. Error: ${err.message}`);
            }
        }
    }

    const onSave = async (params: INotificationPostAction) => {
        if (!user) {
            return;
        }

        try {
            const response = await saveNotificationDetailsData({
                acceptAgreement: params.acceptAgreement,
                mobilePhone: params.mobilePhone,
                phoneCode: params.phoneCode,
                countryPhoneCode: params.countryPhoneCode,
                email: params.email,
                ccEmail: params.ccEmail,
                finEmail: params.finEmail,
                setups: params.setups
            } as INotificationPostAction);

            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                toast.success(<ToastLayout title={t("sidebar.notification")}
                                           text={t("notification.labels.notification-details-saved-label")}/>);

                await loadDetails();
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else {
                    toast.error(`Unable to save details. Error: ${err.message}`);
                }
            }
        }
    }

    const buildActionsList = (): Array<FormLayoutToolbarActionItem | 'separator'> => {
        return [
            {
                disabled: false,
                id: 'cancel-notification-btn',
                title: t("shared.btn.cancel"),
                pinnedButtonType: ButtonType.Transparent,
                type: 'button',
                onClick: async () => {
                    await loadDetails();
                }
            }, {
            disabled: false,
            id: 'save-notification-btn',
            title: t("shared.btn.save"),
            pinnedButtonType: ButtonType.Primary,
            type: 'button',
            onClick: () => {
                const elem = document.getElementById('notification-inner-form');
                if (elem) {
                    let form = elem as HTMLFormElement;

                    form.requestSubmit();
                }
            }
        }];
    }

    return (
        <FormLayout id={'notification-form'}
                    isLoading={model === null || getNotificationDetailsDataIsLoading || !user}
                    actions={actions}
                    showCloseIcon={false}
                    close={() => {
                        //ignore
                    }}>
            {model && user &&
                <div className={'h100 o-auto'}>
                    {errors.map(item => {
                        switch (item.errorCode) {
                            case BLErrorCode.InnerError:
                                return (
                                    <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                        {item.description}
                                    </Alert>
                                );
                            case BLErrorCode.PhoneIsAlreadyRegistered:
                                return (
                                    <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                        {t("notification.errors.phone-already-registered")}
                                    </Alert>
                                );
                            case BLErrorCode.InvalidEmailFormat:
                                return (
                                    <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                        {t("profile.support.invalid-email")}
                                    </Alert>
                                );
                        }

                        return null;
                    })}

                    {user.role === UserRole.ServiceProvider && model.isNewEmailNeedConfirmation &&
                        <Alert type={'YellowInfo'} style={alertStyle}>
                            <div className={cl.alert}>
                                <div>
                                    {t("profile.labels.change-email-confirm-warning")}
                                </div>
                                <div>
                                    <button className={cl.alertBtn}
                                            type={'button'}
                                            onClick={async (ev) => {
                                                ev.preventDefault();

                                                if (disabled) {
                                                    return;
                                                }

                                                try {
                                                    const response = await resendConfirmationEmail();

                                                    if (response?.status === 200 && response?.data.resultCode === ResponseResultCode.Ok) {
                                                        toast.success(t("profile.labels.confirmation-email-sent-label"));
                                                    }
                                                } catch (e) {
                                                    if (isAxiosError(e)) {
                                                        toast.error(`Unable to send confirmation email. Error: ${e.message}`);
                                                    }
                                                }
                                            }}>
                                        {t("profile.labels.send-again")}
                                    </button>
                                </div>
                            </div>
                        </Alert>
                    }

                    <NotificationForm model={model}
                                      reloadForm={async () => await loadDetails()}
                                      user={user}
                                      disabled={getNotificationDetailsDataIsLoading || disabled}
                                      onSubmit={onSave}/>
                </div>
            }
        </FormLayout>
    );
};

export default NotificationContent;
