import React from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {useTranslation} from "react-i18next";
import Icon from "../../../../../../assets/icon/Icon";

const NeedTrackingTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const {t} = useTranslation();

    let needTracking = props.dataItem["needTracking"] !== null && props.dataItem["needTracking"] !== undefined
        ? props.dataItem["needTracking"]
        : null;

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
            {needTracking !== null && needTracking === true &&
                <Icon icon={"faTruckTracking"}
                      title={t("shipment.grid.need-tracking")}
                      style={{
                          color: 'rgb(78, 78, 78)',
                          fontSize: '18px'
                      }}
                />
            }
        </td>
    );
};

export default NeedTrackingTemplate;
