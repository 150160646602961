import React, {useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../../app/store";
import {Query} from "../../../../../../app/types/Query";
import {selectAccessSettings, selectQuery} from "../../../../../../features/filter/filterSliceSelectors";
import {LayoutConfigEntity} from "../../../../../../app/enums/LayoutConfigEntity";
import {useParams} from "react-router-dom";
import {useGridLayoutContext} from "../../../../../../components/uiLayouts/GridLayout/hooks/useGridLayoutContext";
import {
    useGridFilterPanelLayoutPreload
} from "../../../../../../components/uiLayouts/GridFilterPanelLayout/hooks/useGridFilterPanelLayoutPreload";
import {setFilterQuery} from "../../../../../../features/filter/filterSlice";
import {
    ApplicationHubMessageAction,
    IApplicationHubMessage
} from "../../../../../../app/signalR/applicationHub/IApplicationHubMessage";
import {GridService} from "../../../../../../components/ui/Grid/Telerik/api/GridService";
import {IOfferHeaderConfig} from "../../data/interfaces/IOfferHeaderConfig";
import {buildOfferFilterQuery, buildOfferQuery} from "../../utils";
import GridLayoutGrid from "../../../../../../components/ui/Grid/Telerik/GridLayoutGrid";
import {ContextMenuItem} from "../../../../../../components/ui/Grid/Telerik/types/ContextMenuItem";
import {buildAccessQuery} from "../../../../../../components/uiLayouts/GridLayout/context";
import {IUserAccessSettings} from "../../../../../../app/interfaces/user/IUserAccessSettings";

export const OFFER_GRID_DATA_URL = `/api/${process.env.REACT_APP_API_VERSION}/offer/getOffers`

const contextMenuItems: Array<ContextMenuItem> = [
    {
        field: 'orderReleaseXid',
        text: 'ГИПЕРССЫЛКА',
        action: 'copyHyperlinkRU',
        handler: (row: any) => {
            if (row !== null && row !== undefined && row.id !== null && row.id !== undefined &&
                row.orderReleaseXid !== null && row.orderReleaseXid !== undefined) {
                let copyText = `=ГИПЕРССЫЛКА("${process.env.REACT_APP_CLIENT_DOMAIN}/offers/bet?id=${row.id}";"${row.orderReleaseXid}")`;

                try {
                    navigator.clipboard.writeText(copyText).then(() => {
                        //ignore;
                    });
                } catch (e) {
                    console.log('Copy to clipboard error', e);
                }
            }
        }
    },
    {
        field: 'orderReleaseXid',
        text: 'HYPERLINK',
        action: 'copyHyperlinkEN',
        handler: (row: any) => {
            if (row !== null && row !== undefined && row.id !== null && row.id !== undefined &&
                row.orderReleaseXid !== null && row.orderReleaseXid !== undefined) {
                let copyText = `=HYPERLINK("${process.env.REACT_APP_CLIENT_DOMAIN}/offers/bet?id=${row.id}";"${row.orderReleaseXid}")`;

                try {
                    navigator.clipboard.writeText(copyText).then(() => {
                        //ignore;
                    });
                } catch (e) {
                    console.log('Copy to clipboard error', e);
                }
            }
        }
    },
    {
        field: 'orderReleaseXid',
        text: 'HIPERŁĄCZE',
        action: 'copyHyperlinkPL',
        handler: (row: any) => {
            if (row !== null && row !== undefined && row.id !== null && row.id !== undefined &&
                row.orderReleaseXid !== null && row.orderReleaseXid !== undefined) {
                let copyText = `=HIPERŁĄCZE("${process.env.REACT_APP_CLIENT_DOMAIN}/offers/bet?id=${row.id}";"${row.orderReleaseXid}")`;

                try {
                    navigator.clipboard.writeText(copyText).then(() => {
                        //ignore;
                    });
                } catch (e) {
                    console.log('Copy to clipboard error', e);
                }
            }
        }
    },
];

type OfferGridContentProps = {
    onRowClick: (data: any) => void;
    onRowDoubleClick: (data: any) => void;
}

const OfferGridContent: React.FC<OfferGridContentProps> = ({
                                                               onRowClick,
                                                               onRowDoubleClick
                                                           }) => {
    const dispatch = useAppDispatch();
    const [query, setQuery] = useState<Query | undefined>();
    const [headerQuery, setHeaderQuery] = useState<Query | undefined>();
    const [accessSettingsQuery, setAccessSettingsQuery] = useState<Query | undefined>();
    const stateRef = useRef();
    const filterQuery = useAppSelector(state =>
        selectQuery(state, LayoutConfigEntity.Offer));
    const settings = useAppSelector(selectAccessSettings);
    const {type} = useParams();

    const {
        headerConfig,
        setSelectedRows
    } = useGridLayoutContext<IOfferHeaderConfig>();

    useGridFilterPanelLayoutPreload({
        entity: LayoutConfigEntity.Offer,
        selectedPersonalFilter: headerConfig?.pinnedFilter ?? null,
        selectedPredefinedFilter: headerConfig?.pinnedPredefinedFilter ?? null,
        onComplete: (personal, predefined, filter) => {
            dispatch(setFilterQuery({
                entity: LayoutConfigEntity.Offer,
                data: buildOfferFilterQuery(personal, predefined, filter).getParams()
            }));
        },
        onChange: (personal, predefined, filter) => {

            dispatch(setFilterQuery({
                entity: LayoutConfigEntity.Offer,
                data: buildOfferFilterQuery(personal, predefined, filter).getParams()
            }))
        }
    });

    useEffect(() => {
        const onOfferApplicationHubMessage = (ev: any) => {
            let typedEv = ev.detail as IApplicationHubMessage;
            if (typedEv.action === ApplicationHubMessageAction.Update && typedEv.payload && typedEv.payload !== '') {
                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'getItem',
                        field: 'id',
                        value: typedEv.payload,
                        callback: (item: any) => {
                            if (item !== null && item !== undefined && typedEv.payload) {
                                let service = new GridService(OFFER_GRID_DATA_URL);

                                let q = new Query();
                                if (stateRef.current) {
                                    q.addParamsFromQuery(stateRef.current ?? null)
                                }
                                q.addParam('sid', typedEv.payload);
                                service.execute({
                                    skip: 0,
                                    take: 1
                                }, q).then(data => {
                                    if (data.data && data.data.length === 1) {
                                        let item = data.data[0];

                                        item.isRowLoading = false;

                                        document.dispatchEvent(new CustomEvent('onGridMessage', {
                                            detail: {
                                                action: 'updateRow',
                                                rows: [
                                                    {...item}
                                                ]
                                            }
                                        }));
                                    } else {
                                        document.dispatchEvent(new CustomEvent('onGridMessage', {
                                            detail: {
                                                action: 'refresh'
                                            }
                                        }));
                                    }
                                });
                            } else {
                                document.dispatchEvent(new CustomEvent('onGridMessage', {
                                    detail: {
                                        action: 'refresh'
                                    }
                                }));
                            }
                        }
                    }
                }));
            }
        }

        document.addEventListener('onOfferApplicationHubMessage', onOfferApplicationHubMessage);

        return () => {
            document.removeEventListener('onOfferApplicationHubMessage', onOfferApplicationHubMessage);
        }
    }, []);

    useEffect(() => {
        let onAccessSettingsUpdate = (ev: any) => {
            setAccessSettingsQuery(buildAccessQuery(new Query(), ev.detail.data as IUserAccessSettings));
        }

        document.addEventListener('onAccessSettingsUpdate', onAccessSettingsUpdate);

        setHeaderQuery(buildOfferQuery(type ?? '', headerConfig));

        return () => {
            document.removeEventListener('onAccessSettingsUpdate', onAccessSettingsUpdate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, headerConfig]);


    useEffect(() => {
        let q = new Query();

        // @ts-ignore
        stateRef.current = headerQuery;

        if (filterQuery) {
            q.addParamsFromQuery(filterQuery ? Query.ToQuery(filterQuery) : null)
        }

        if (headerQuery) {
            q.addParamsFromQuery(headerQuery ?? null)
        }

        if (accessSettingsQuery && accessSettingsQuery.getParams().length > 0) {
            q.addParamsFromQuery(accessSettingsQuery);
        }

        q = buildAccessQuery(q, settings);

        setQuery(q);
    }, [headerQuery, filterQuery, accessSettingsQuery]);

    return (
        <GridLayoutGrid id={'offer-grid'}
                        url={OFFER_GRID_DATA_URL}
                        query={query}
                        selectionMode={'multiple'}
                        onRowDoubleClick={onRowDoubleClick}
                        onRowClick={onRowClick}
                        onSelect={(rows) => setSelectedRows(rows)}
                        showCellContextMenu={true}
                        contextMenuItems={contextMenuItems}/>
    );
};

export default OfferGridContent;