import cl from '../ReactDatePicker.module.css';
import {ReactDatePickerSize} from "../ReactDatePicker";
import {registerLocale} from "react-datepicker";

import {pl} from 'date-fns/locale/pl';
import {enGB} from 'date-fns/locale/en-GB';
import {ru} from 'date-fns/locale/ru';

registerLocale('ru', ru);
registerLocale('pl', pl);
registerLocale('en', enGB);

export const getReactDatePickerClass = (size: ReactDatePickerSize, showClearIcon: boolean, disabled: boolean, hasError: boolean): string => {
    let classes: Array<string> = [cl.input];

    if (showClearIcon) {
        classes.push(cl.ici)
    }

    if (disabled) {
        classes.push(cl.disabled);
    }

    if (hasError) {
        classes.push(cl.error);
    }

    switch (size) {
        case "big":
            classes.push(cl.big);
            break;
        case "medium":
            classes.push(cl.medium);
            break;
        case "small":
            classes.push(cl.small);
            break;
    }

    return classes.join(' ');
};

export const getReactDatePickerCalendarSvgClass = (size: ReactDatePickerSize): string => {
    let classes: Array<string> = [cl.calendar];

    switch (size) {
        case "big":
            classes.push(cl.calendarBig);
            break;
        case "small":
            classes.push(cl.calendarSmall);
            break;
    }

    return classes.join(' ');
};

export const getReactDatePickerCloseSvgClass = (): string => {
    return cl.close;
};

export const getReactDatePickerFloatingPlaceholderSvgClass = (collapsePlaceholder: boolean): string => {
    let classes: Array<string> = [cl.floatingPlaceholder];

    if (collapsePlaceholder) {
        classes.push(cl.collapsedFloatingPlaceholder);
    }

    return classes.join(' ');
};

export const getReactDatePickerLocale = (lng: string): string => {

    switch (lng) {
        case 'ru':
            return 'ru';
        case 'pl':
            return 'pl';
        case 'en':
            return 'en';
    }

    return 'en';
}