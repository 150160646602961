import React from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {useTranslation} from "react-i18next";
import ttTrackingIcon from '../../../../../../assets/images/has-tt-tracking.png';
import Icon from "../../../../../../assets/icon/Icon";

const TrackingTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const {t} = useTranslation();

    let hasTTTracking = props.dataItem.hasTTTracking !== null && props.dataItem.hasTTTracking !== undefined
        ? props.dataItem.hasTTTracking : null;

    let hasTracking = props.dataItem.hasTracking !== null && props.dataItem.hasTracking !== undefined
        ? props.dataItem.hasTracking : null;

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
            <span className={'default-center-cell'}>
                {hasTTTracking !== null && hasTTTracking &&
                    <img src={ttTrackingIcon} alt={'ttt'} title={t("shipment.grid.truck-has-gps")}/>
                }
                {hasTracking !== null && hasTracking === true && (hasTTTracking === null || !hasTTTracking) &&
                    <>
                        <Icon icon={"faBroadcast"}
                              title={t("shipment.grid.truck-has-gps")}
                              style={{
                                  color: 'var(--main-orange-color)',
                                  fontSize: '18px'
                              }}/>
                    </>
                }
            </span>
        </td>
    );
};

export default TrackingTemplate;
