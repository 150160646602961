import {GridLayoutView} from "../models/enums/GridLayoutView";
import {GridLayoutLoadingType} from "../models/enums/GridLayoutLoadingType";

export const getGridLayoutLoadingType = (view: GridLayoutView | null, headerConfig: any | null) => {
    if (view === null) {
        if (headerConfig !== null &&
            headerConfig.isMapView !== undefined &&
            headerConfig.isMapView === true) {
            return GridLayoutLoadingType.MapAndHeader;
        }
        return GridLayoutLoadingType.GridAndHeader;
    }

    switch (view) {
        case GridLayoutView.Map:
            return GridLayoutLoadingType.Map;
        case GridLayoutView.Grid:
            return GridLayoutLoadingType.Grid;
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = view;
            break;
    }

    return GridLayoutLoadingType.GridAndHeader;
}