import React from 'react';

const OperationsSection = () => {
    return (
        <div id={'shipment-operations-section'}>
            Operations
        </div>
    );
};

export default OperationsSection;