import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    FormLayoutToolbarActionItem
} from "../../../../components/uiLayouts/FormLayout/components/FormLayoutToolbar/FormLayoutToolbar";
import {ResponseResultCode} from "../../../../app/enums/ResponseResultCode";
import {AxiosError} from "axios";
import {toast} from "react-toastify";
import {useOfferApi} from "../../../../app/api/offer";
import {IOffer} from "../../../../app/interfaces/offer/IOffer";
import {LayoutConfigEntity} from "../../../../app/enums/LayoutConfigEntity";
import Alert from "../../../../components/ui/Alert/Alert";
import FormLayout from "../../../../components/uiLayouts/FormLayout/FormLayout";
import {useAppSelector} from "../../../../app/store";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../helpers/user";
import {UserRole} from "../../../../app/enums/UserRole";
import {ButtonType} from "../../../../components/ui/Button/Button";
import {OrderReleaseStatus} from "../../../../app/enums/OrderReleaseStatus";
import ResetAssigmentDialog from "../actions/ResetAssigment/ResetAssigmentDialog";
import CancelOfferDialog from "../actions/CancelOffer/CancelOfferDialog";
import AssignOfferDialog from "../actions/AssignOffer/AssignOfferDialog";
import SendContractDialog from "../actions/SendContract/SendContractDialog";
import DeleteOfferDialog from "../actions/DeleteOffer/DeleteOfferDialog";
import ConfirmAuctionDialog from "../actions/ConfirmAuction/ConfirmAuctionDialog";
import RecallBetDialog from "../actions/RecallBet/RecallBetDialog";
import ProposeRateDialog from "../actions/ProposeRate/ProposeRateDialog";
import OfferDetailsSection from "./sections/OfferDetailsSection/OfferDetailsSection";
import {
    ApplicationHubMessageAction,
    IApplicationHubMessage
} from "../../../../app/signalR/applicationHub/IApplicationHubMessage";
import {CustomScroll} from "react-custom-scroll";
import useCustomerAttachmentApi from '../../../../app/api/customerAttachment';
import { ICustomerAttachmentDoc } from '../../../../app/interfaces/customerAttachment/ICustomerAttachmentDoc';

type OfferFormProps = {
    id: string | null;
    close: () => void;
    isFullPageDetails: boolean;
}

const OfferForm: React.FC<OfferFormProps> = ({
                                                 id,
                                                 close,
                                                 isFullPageDetails
                                             }) => {
    const user = useAppSelector(selectCurrentUser);
    const {t} = useTranslation();
    const [data, setData] = useState<IOffer | null>(null);
    const [customerAttachmentData, setCustomerAttachmentData] = useState<Array<ICustomerAttachmentDoc> | null>(null);
    const [actions, setActions] = useState<Array<FormLayoutToolbarActionItem | 'separator'>>([]);
    const {
        getOffer: {
            query, isLoading
        }
    } = useOfferApi();

    const {
        getCustomerAttachmentDocs: {
            queryCustomerAttachmentDocs,
            isLoadingCustomerAttachmentDocs
        }
    } = useCustomerAttachmentApi();

    const [isResetAssigmentDialogShown, setIsResetAssigmentDialogShown] = useState<boolean>(false);
    const [isCancelDialogShown, setIsCancelDialogShown] = useState<boolean>(false);
    const [isAssignOfferDialogShown, setIsAssignOfferDialogShown] = useState<boolean>(false);
    const [isSendContractDialogShown, setIsSendContractDialogShown] = useState<boolean>(false);
    const [isDeleteDialogShown, setIsDeleteDialogShown] = useState<boolean>(false);
    const [isConfirmAuctionDialogShown, setIsConfirmAuctionDialogShown] = useState<boolean>(false);
    const [isRecallBetDialogShown, setIsRecallBetDialogShown] = useState<boolean>(false);
    const [isProposeRateDialogShown, setIsProposeRateDialogShown] = useState<boolean>(false);

    useEffect(() => {
        if (!data || !user) {
            return;
        }

        setActions(buildActionsList());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, data, t]);

    const getCustomerAttachmentDocs = async (id: string) => {
        try {
            const customerAttachmentDocs = await queryCustomerAttachmentDocs(id);
            if (customerAttachmentDocs !== undefined &&
                customerAttachmentDocs.status === 200 &&
                customerAttachmentDocs.data !== undefined &&
                customerAttachmentDocs.data.resultCode === ResponseResultCode.Ok) {
                    setCustomerAttachmentData(customerAttachmentDocs.data.data ?? null);
            }
        } catch (e) {
            if (e instanceof AxiosError && e.response !== undefined && e.response.data !== undefined &&
                e.response.data.resultCode !== ResponseResultCode.NotFound) {
                toast.error(e.message);
            }
        }
    };

    const getOrderData = async (id: string) => {
        try {
            const data = await query(id);

            if (data !== undefined && data.status === 200 && data.data !== undefined &&
                data.data.resultCode === ResponseResultCode.Ok) {
                setData(data.data.data ?? null);
            }
        } catch (e) {
            if (e instanceof AxiosError && e.response !== undefined && e.response.data !== undefined &&
                e.response.data.resultCode !== ResponseResultCode.NotFound) {
                toast.error(e.message);
            }
        }
    };

    const onOfferUpdated = async (ev: any) => {


        let typedEv = ev.detail as IApplicationHubMessage;

        if (typedEv.action === ApplicationHubMessageAction.Update && typedEv.payload && typedEv.payload !== ''
            && typedEv.payload == id) {
            await getOrderData(id);
            await getCustomerAttachmentDocs(id);
        }
    }

    useEffect(() => {

        document.addEventListener('onOfferApplicationHubMessage', onOfferUpdated);

        return () => {
            document.removeEventListener('onOfferApplicationHubMessage', onOfferUpdated);
        }
    }, []);

    useEffect(() => {
        if (!id) {
            return;
        }

        (async () => {
            await getOrderData(id)
        })();

        (async () => {
            await getCustomerAttachmentDocs(id)
        })();

        return () => {
            setData(null);
            setCustomerAttachmentData(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const buildActionsList = (): Array<FormLayoutToolbarActionItem | 'separator'> => {
        if (!data || !user) {
            return [];
        }

        let role = getUserRole(user);

        let arr: Array<FormLayoutToolbarActionItem | 'separator'> = [];

        if (role === UserRole.Administrator || role === UserRole.Forwarder) {
            if (data.status !== OrderReleaseStatus.Confirmed) {
                if (data.isAuction && data.auctionType !== null && data.initialBet !== null) {
                    arr.push({
                        disabled: false,
                        id: 'confirm-transportation-btn',
                        title: t("shared.btn.accept-transportation"),
                        onClick: () => setIsConfirmAuctionDialogShown(prev => !prev),
                        pinnedButtonType: ButtonType.Secondary
                    });
                }

                arr.push({
                    disabled: false,
                    id: 'assign-offer-btn',
                    title: t("shared.btn.assign-offer"),
                    onClick: () => setIsAssignOfferDialogShown(prev => !prev),
                    pinnedButtonType: ButtonType.Secondary
                });
            }

            arr.push({
                disabled: false,
                id: 'send-to-carriers-btn',
                title: t("shared.btn.send-offer-contract"),
                onClick: () => setIsSendContractDialogShown(prev => !prev),
                pinnedButtonType: ButtonType.Secondary
            });

            arr.push({
                disabled: false,
                id: 'reset-assign-offer-btn',
                title: t("shared.btn.cancel-assign-offer"),
                onClick: () => setIsResetAssigmentDialogShown(prev => !prev),
                pinnedButtonType: ButtonType.Warning,
                color: 'red'
            });

            arr.push({
                disabled: false,
                id: 'cancel-offer-btn',
                title: t("shared.btn.cancel-offer"),
                onClick: () => setIsCancelDialogShown(prev => !prev),
                pinnedButtonType: ButtonType.Warning,
                color: 'red'
            });

            if (role === UserRole.Administrator) {
                arr.push({
                    disabled: false,
                    id: 'delete-offer-btn',
                    title: t("shared.btn.delete"),
                    onClick: () => setIsDeleteDialogShown(prev => !prev),
                    color: 'red',
                    pinnedButtonType: ButtonType.Warning
                });
            }
        }

        if (role === UserRole.ServiceProvider) {
            if (data.status === OrderReleaseStatus.WithBet) {
                arr.push({
                    disabled: false,
                    id: 'recall-rate-btn',
                    title: t("shared.btn.recall-rate"),
                    onClick: () => setIsRecallBetDialogShown(prev => !prev),
                    color: 'red',
                    pinnedButtonType: ButtonType.Warning
                });
            }

            if (data.status === OrderReleaseStatus.Free) {
                if (!data.isAuction || data.initialBet === null) {
                    arr.push({
                        disabled: false,
                        id: 'propose-rate-btn',
                        title: t("shared.btn.propose-rate"),
                        onClick: () => setIsProposeRateDialogShown(prev => !prev),
                        pinnedButtonType: ButtonType.Secondary
                    });
                }
            }

            if (data.isAuction && data.initialBet !== null) {
                arr.push({
                    disabled: false,
                    id: 'confirm-auction-btn',
                    title: t("shared.btn.accept-transportation"),
                    onClick: () => setIsConfirmAuctionDialogShown(prev => !prev),
                    pinnedButtonType: ButtonType.Secondary
                });
            }
        }

        return arr;
    }

    return (
        <>
            {id && id !== '' && data &&
                <>
                    {data.myBet !== null &&
                        <RecallBetDialog betId={data.myBet.betId}
                                         orderReleaseGid={id}
                                         show={isRecallBetDialogShown}
                                         setShow={setIsRecallBetDialogShown}/>
                    }

                    <ProposeRateDialog id={id}
                                       show={isProposeRateDialogShown}
                                       setShow={setIsProposeRateDialogShown}/>

                    <ConfirmAuctionDialog id={[id]}
                                          show={isConfirmAuctionDialogShown}
                                          setShow={setIsConfirmAuctionDialogShown}/>

                    <DeleteOfferDialog id={id}
                                       show={isDeleteDialogShown}
                                       setShow={setIsDeleteDialogShown}/>

                    <ResetAssigmentDialog id={[id]}
                                          show={isResetAssigmentDialogShown}
                                          setShow={setIsResetAssigmentDialogShown}/>

                    <CancelOfferDialog id={[id]}
                                       show={isCancelDialogShown}
                                       setShow={setIsCancelDialogShown}/>

                    <AssignOfferDialog id={[id]}
                                       show={isAssignOfferDialogShown}
                                       setShow={setIsAssignOfferDialogShown}/>

                    <SendContractDialog id={[id]}
                                        user={user}
                                        show={isSendContractDialogShown}
                                        setShow={setIsSendContractDialogShown}/>
                </>
            }

            <FormLayout id={'offer-details'}
                        isLoading={!id || isLoading}
                        showCloseIcon={isFullPageDetails}
                        close={close}
                        entity={LayoutConfigEntity.Offer}
                        actions={actions}>
                {!data && <Alert type={'Error'}>{t("offer.errors.not-found")}</Alert>}
                {data &&
                    <div className={`${isFullPageDetails ? 'full' : 'side'} details h100`}>
                        <CustomScroll heightRelativeToParent={'100%'}>
                            <OfferDetailsSection 
                                    offer={data} 
                                    isSidePanelDetails={!isFullPageDetails}
                                    customerAttachmentDocs={customerAttachmentData}/>
                        </CustomScroll>
                    </div>
                }
            </FormLayout>
        </>
    );
};

export default OfferForm;
