import React from 'react';
import {useTranslation} from "react-i18next";
import GridFilterPanelLayoutInput
    from "../../../../../../../components/uiLayouts/GridFilterPanelLayout/components/GridFilterPanelLayoutInput/GridFilterPanelLayoutInput";
import {
    useGridFilterPanelLayoutContext
} from "../../../../../../../components/uiLayouts/GridFilterPanelLayout/hooks/useGridFilterPanelLayoutContext";
import FilterPanelPeriodFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelPeriodFilter/FilterPanelPeriodFilter";
import FilterPanelQuoteStatusFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelQuoteStatusFilter/FilterPanelQuoteStatusFilter";
import FilterPanelRegionFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelRegionFilter/FilterPanelRegionFilter";
import FilterPanelSupplierFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelSupplierFilter/FilterPanelSupplierFilter";
import FilterPanelEquipmentFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelEquipmentFilter/FilterPanelEquipmentFilter";
import FilterPanelTagsFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelTagsFilter/FilterPanelTagsFilter";

const QuoteFilterPanelFiltersSection = () => {
    const {t} = useTranslation();

    const {
        disabled,
        filterToApply,
        applyFilterToApply,
        onKeyDown
    } = useGridFilterPanelLayoutContext();

    return (
        <div>
            <div className={'view-row mgb-10'}>
                <GridFilterPanelLayoutInput disabled={disabled}
                                            showClearIcon={true}
                                            value={filterToApply.quoteNumber ?? ''}
                                            placeholder={t("quote.grid.order-release-xid")}
                                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                applyFilterToApply(
                                                    'quoteNumber',
                                                    ev.target.value === ''
                                                        ? null
                                                        : ev.target.value
                                                );
                                            }}
                                            onClear={() => {
                                                applyFilterToApply(
                                                    'quoteNumber',
                                                    null
                                                );
                                            }}
                                            onKeyDown={(ev) => onKeyDown(ev, 'quoteNumber')}
                />
            </div>

            <div className={'view-row mgb-10'}>
                <FilterPanelPeriodFilter id={'quote-pickup-date-filter'}
                                         disabled={disabled}
                                         title={t("quote.grid.pickup-date")}
                                         value={{
                                             from: filterToApply.pickupDateFrom,
                                             to: filterToApply.pickupDateTo
                                         }}
                                         onChange={(type, value) => {
                                             applyFilterToApply(type === 'from'
                                                 ? 'pickupDateFrom'
                                                 : 'pickupDateTo', value);
                                         }}
                                         onClear={() => {
                                             applyFilterToApply(
                                                 ['pickupDateFrom', 'pickupDateTo'],
                                                 null
                                             );
                                         }}/>
            </div>

            <div className={'view-row mgb-10'}>
                <FilterPanelPeriodFilter id={'quote-delivery-date-filter'}
                                         title={t("quote.grid.delivery-date")}
                                         value={{
                                             from: filterToApply.deliveryDateFrom,
                                             to: filterToApply.deliveryDateTo
                                         }}
                                         onChange={(type, value) => {
                                             applyFilterToApply(type === 'from'
                                                 ? 'deliveryDateFrom'
                                                 : 'deliveryDateTo', value);
                                         }}
                                         onClear={() => {
                                             applyFilterToApply(
                                                 ['deliveryDateFrom', 'deliveryDateTo'],
                                                 null
                                             );
                                         }}/>
            </div>

            <div className={'view-row mgb-10'}>
                <FilterPanelRegionFilter id={'quote-source-region-filter'}
                                         title={t("shared.labels.source-region-point")}
                                         onClear={(val) => {
                                             applyFilterToApply('source', !val
                                                 ? null
                                                 : filterToApply.source.filter(e => e.value !== val));
                                         }}
                                         onChange={val => {
                                             applyFilterToApply('source', val);
                                         }}
                                         value={filterToApply.source}/>
            </div>

            <div className={'view-row mgb-10'}>
                <FilterPanelRegionFilter id={'quote-destination-region-filter'}
                                         title={t("shared.labels.destination-region-point")}
                                         onClear={(val) => {
                                             applyFilterToApply('destination', !val
                                                 ? null
                                                 : filterToApply.destination.filter(e => e.value !== val));
                                         }}
                                         onChange={val => {
                                             applyFilterToApply('destination', val);
                                         }}
                                         value={filterToApply.destination}/>
            </div>

            <div className={'view-row mgb-10'}>
                <FilterPanelEquipmentFilter id={'quote-equipment-filter'}
                                            onClear={(val) => {
                                                applyFilterToApply('equipment', !val
                                                    ? null
                                                    : filterToApply.equipment.filter(e => e.value !== val));
                                            }}
                                            onChange={val => {
                                                applyFilterToApply('equipment', val);
                                            }}
                                            value={filterToApply.equipment}/>
            </div>

            <div className={'view-row mgb-10'}>
                <FilterPanelSupplierFilter id={'quote-supplier-filter'}
                                           onClear={(val) => {
                                               applyFilterToApply('supplier', !val
                                                   ? null
                                                   : filterToApply.supplier.filter(e => e.value !== val));
                                           }}
                                           onChange={val => {
                                               applyFilterToApply('supplier', val);
                                           }}
                                           value={filterToApply.supplier}/>
            </div>

            <div className={'view-row mgb-10'}>
                <FilterPanelQuoteStatusFilter id={'quote-status-filter'}
                                              onClear={(value) => {
                                                  applyFilterToApply('status', !value
                                                      ? null
                                                      : filterToApply.status.filter(item => item !== value));
                                              }}
                                              onChange={(values) => {
                                                  applyFilterToApply('status', values);
                                              }}
                                              value={filterToApply.status}/>
            </div>

            <div className={`view-row mgb-10`}>
                <FilterPanelTagsFilter id={'quote-tags-filter'}
                                       type={'quote'}
                                       onClear={(val) => {
                                           applyFilterToApply('tags', !val
                                               ? null
                                               : filterToApply.tags.filter(e => e.value !== val));
                                       }}
                                       onChange={(val) => {
                                           applyFilterToApply('tags', val);
                                       }}
                                       value={filterToApply.tags}/>
            </div>
        </div>
    );
};

export default QuoteFilterPanelFiltersSection;
