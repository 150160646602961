import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import CancelQuote from "./CancelQuote";

type CancelQuoteDialogProps = {
    id: string | Array<string>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const CancelQuoteDialog: React.FC<CancelQuoteDialogProps> = ({
                                                                 id,
                                                                 show,
                                                                 setShow
                                                             }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'cancel-quote-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={Array.isArray(id) && id.length > 1
                    ? t("shared.btn.cancel-quotes")
                    : t("shared.btn.cancel-quote")
                }>
            <CancelQuote id={id}
                         close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default CancelQuoteDialog;