import React, {useEffect, useState} from 'react';
import {Outlet, useLocation, useSearchParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../app/store";
import {
    selectAnonymousUserAuthenticationStatus,
    selectAnonymousUserCode
} from "../../../features/authentication/authenticationSliceSelectors";
import {AuthenticationStatus} from "../../../features/authentication/IAuthenticationState";
import LoadingScreen from "../../ui/loaders/LoadingScreen/LoadingScreen";
import NotFound from "../../ui/NotFound/NotFound";
import {selectDeviceId} from "../../../features/application/applicationSliceSelectors";
import {
    setAnonymousUserAuthenticationStatus,
    setAnonymousUserCode, setAnonymousUserId,
    validateAnonymousUserAuthentication
} from "../../../features/authentication/authenticationSlice";
import {getKendoLocaleString, loadKendoLocalization} from "../../../helpers/kendo";
import {useTranslation} from "react-i18next";
import {IntlProvider, LocalizationProvider} from "@progress/kendo-react-intl";
import NotFoundOrder from "../../ui/NotFound/NotFoundOrder";

const AnonymousDataAccessLayout: React.FC = () => {
    const {i18n} = useTranslation();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useAppDispatch();

    const anonymousUserCode = useAppSelector(selectAnonymousUserCode);
    const anonymousAuthStatus = useAppSelector(selectAnonymousUserAuthenticationStatus);
    const deviceId = useAppSelector(selectDeviceId);

    const [kendoLocale, setKendoLocale] = useState<string | null>(null);

    useEffect(() => {
        return () => {
            dispatch(setAnonymousUserAuthenticationStatus(undefined));
            dispatch(setAnonymousUserCode(undefined));
            dispatch(setAnonymousUserId(undefined));
        }
    }, []);

    useEffect(() => {
       if (!deviceId) {
            return;
        }
        let code = searchParams.get('key');
        let user = searchParams.get('user') ?? undefined;
        let id = searchParams.get('id') ?? undefined;
        let sid = searchParams.get('sid') ?? undefined;
        let path = location.pathname/*.split('/').filter(e => e && e !== '')[0];*/;

        if (!code) {
            dispatch(setAnonymousUserAuthenticationStatus(AuthenticationStatus.NotAuthenticated));

            return;
        }

        searchParams.delete('key');
        searchParams.delete('user');

        setSearchParams(searchParams);

        dispatch(setAnonymousUserCode(code));

        dispatch(validateAnonymousUserAuthentication({
            type: `${path}`,
            code: code,
            device: deviceId,
            user: user,
            id: id ? id : sid,
            sid: sid
        }));
    }, [deviceId]);

    useEffect(() => {
        if (kendoLocale !== getKendoLocaleString(i18n)) {
            loadKendoLocalization(i18n);

            setKendoLocale(getKendoLocaleString(i18n));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    if (anonymousAuthStatus === AuthenticationStatus.NotAuthenticated) {
        return <NotFoundOrder/>;
    }

    if (!kendoLocale ||
        anonymousAuthStatus === undefined ||
        anonymousAuthStatus === AuthenticationStatus.NoAction ||
        anonymousAuthStatus === AuthenticationStatus.InProgress) {
        return <LoadingScreen/>;
    }

    if (!anonymousUserCode) {
        return <NotFoundOrder/>;
    }

    return (
        <LocalizationProvider language={kendoLocale}>
            <IntlProvider locale={i18n.language.toLowerCase()}>
                <div className={'w100 h100 hidden'}>
                    <Outlet/>
                </div>
            </IntlProvider>
        </LocalizationProvider>
    );
};

export default AnonymousDataAccessLayout;
