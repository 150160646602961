import React, {useEffect, useState} from 'react';
import {IShipment} from "../../../../../../../app/interfaces/shipment/IShipment";
import {buildShipmentEventsLineShipment, ShipmentEventsLineExtendedShipment} from "./utils";
import FormLoadingScreen from "../../../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import cl from './ShipmentEventsLine.module.css';
import ShipmentEventsLineProgressBarItem
    from "./components/ShipmentEventsLineProgressBarItem/ShipmentEventsLineProgressBarItem";

type ShipmentEventsLineProps = {
    model: Partial<IShipment>;
}

const ShipmentEventsLine: React.FC<ShipmentEventsLineProps> = ({
                                                                   model
                                                               }) => {
    const [shipment, setShipment] = useState<ShipmentEventsLineExtendedShipment | null>(null);

    useEffect(() => {
        setShipment(buildShipmentEventsLineShipment(model));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!shipment || !shipment.shipmentEvents) {
        return (
            <FormLoadingScreen height={`120px`} style={{padding: '5px'}}/>
        );
    }

    return (
        <div className={cl.container}>
            <div className={cl.eventsOuterContainer}>
                <div>
                    {shipment.shipmentEvents.map(item => {
                        return (
                            <ShipmentEventsLineProgressBarItem key={item.sortPosition}
                                                               shipment={shipment}
                                                               event={item}/>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ShipmentEventsLine;