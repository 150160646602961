import {useContext} from "react";
import {ChatManagerContext} from "../contexts";
import {IChatManagerContext} from "../interfaces/IChatManagerContext";

const useChatManagerContext = () => {
    const context = useContext<IChatManagerContext>(ChatManagerContext);

    if (context === undefined) {
        throw new Error('Chat component not defined as a parent');
    }

    return context;
};

export default useChatManagerContext;