import React from 'react';
import {IChangeEquipmentGetAction} from "../../../../../../app/interfaces/offer/IChangeEquipmentGetAction";
import {IChangeEquipmentPostAction} from "../../../../../../app/interfaces/offer/IChangeEquipmentPostAction";
import {useTranslation} from "react-i18next";
import {FormikHelpers, useFormik} from "formik";
import ChangeEquipmentEquipmentSelector from "./ChangeEquipmentEquipmentSelector/ChangeEquipmentEquipmentSelector";
import Textarea from "../../../../../../components/ui/Textarea/Textarea";

type ChangeEquipmentFormProps = {
    model: IChangeEquipmentGetAction;
    disabled: boolean;
    save: (model: IChangeEquipmentPostAction) => Promise<void>;
}

const ChangeEquipmentForm: React.FC<ChangeEquipmentFormProps> = ({
                                                                     model,
                                                                     disabled,
                                                                     save
                                                                 }) => {
    const {t} = useTranslation();

    const form = useFormik<IChangeEquipmentPostAction>({
        initialValues: {
            orderReleaseGid: model.orderReleaseGid,
            equipmentGroupName: model.equipmentGroupName,
            alternativeTransport: model.alternativeTransport,
            alternativeTransportSeq: model.alternativeTransportSeq,
            commentsForPrint: model.commentsForPrint,
            commentsForPrintSeq: model.commentsForPrintSeq
        },
        onSubmit: async (values: IChangeEquipmentPostAction, actions: FormikHelpers<IChangeEquipmentPostAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='offer-change-equipment-group-form'
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div className={'mgb-20 drop-down-picker'}>
                    <label className={'view-label change-equipment-label'}>
                        {t("offer.grid.equipment")}
                    </label>
                    <ChangeEquipmentEquipmentSelector disabled={disabled}
                                                      equipment={form.values.equipmentGroupName}
                                                      onChange={(ev) => {
                                                          form.setFieldValue('equipmentGroupName', ev.target.value.value);
                                                      }}/>
                </div>

                <div className={'mgb-10'}>
                    <label className={'view-label change-equipment-label'}>
                        {t("offer.grid.alternative-transport")}
                    </label>
                    <Textarea disabled={disabled}
                              className={'change-equipment-alternative-transport-value'}
                              style={{minHeight: '50px', resize: 'vertical', maxHeight: '350px'}}
                              value={form.values.alternativeTransport ?? ''}
                              placeholder={t("offer.grid.alternative-transport")}
                              onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                  form.setFieldValue('alternativeTransport', ev.target.value);
                              }}/>
                </div>

                <div className={'mgb-10'}>
                    <label className={'view-label change-equipment-label'}>
                        {t("offer.grid.comments-for-print")}
                    </label>
                    <Textarea disabled={disabled}
                              className={'change-equipment-comments-for-print-value'}
                              style={{minHeight: '50px', resize: 'vertical', maxHeight: '350px'}}
                              value={form.values.commentsForPrint ?? ''}
                              placeholder={t("offer.grid.comments-for-print")}
                              onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                  form.setFieldValue('commentsForPrint', ev.target.value);
                              }}/>
                </div>
            </div>
        </form>
    );
};

export default ChangeEquipmentForm;
