import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {useOfferApi} from "../../../../../app/api/offer";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {BLErrorCode} from "../../../../../app/enums/BLErrorCode";
import Alert from "../../../../../components/ui/Alert/Alert";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {ISelectBetGetAction} from "../../../../../app/interfaces/offer/ISelectBetGetAction";
import {ISelectBetPostAction} from "../../../../../app/interfaces/offer/ISelectBetPostAction";
import SelectBetForm from "./SelectBetForm/SelectBetForm";

type SelectBetProps = {
    id: number;
    close?: () => void;
};

const SelectBet: React.FC<SelectBetProps> = ({
                                                 id,
                                                 close
                                             }) => {
    const {t} = useTranslation();
    const [model, setModel] = useState<ISelectBetGetAction | null>(null);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError>>([]);

    const {
        getSelectBetData: {
            isLoading,
            cancel,
            query: getSelectBetData
        },
        selectBet: {
            cancel: cancelSelectBet,
            mutation: selectBet
        }
    } = useOfferApi();

    useEffect(() => {
        if (!id || id <= 0) {
            if (close) {
                close();
            }

            return;
        }

        (async () => {
            try {
                const response = await getSelectBetData(id);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error<string>('Unable to get select bet data');

                    if (close) {
                        close();
                    }
                }
            }
        })();

        return () => {
            cancel();
            cancelSelectBet();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const save = async (model: ISelectBetPostAction): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            const response = await selectBet(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [
                            {
                                id: id,
                                isRowLoading: true
                            }
                        ]
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.offer")} ${model.orderReleaseGid}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotFound &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();
                    toast.error(err.message);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if (!id || id <= 0) {
        return null;
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                {errors.length > 0 && errors.map((err, index) => {
                    switch (err.errorCode) {
                        case BLErrorCode.InnerError:
                            return (
                                <Alert type={'Error'}
                                       id={`confirm-auction-error-${index}`}>
                                    {err.description}
                                </Alert>
                            );
                        case BLErrorCode.ContractNotFound:
                            return (
                                <Alert type={'Error'}
                                       id={`confirm-auction-error-${index}`}>
                                    {t("offer.errors.contract-not-found")}
                                </Alert>
                            );
                        case BLErrorCode.ConfirmationNotAccepted:
                            return (
                                <Alert type={'Error'}
                                       id={`confirm-auction-error-${index}`}>
                                    {t("offer.errors.confirmation-not-accepted")}
                                </Alert>
                            );
                    }

                    return null;
                })}

                <SelectBetForm model={model}
                               disabled={disabled}
                               setIsSubmitDisabled={setIsSubmitDisabled}
                               save={save}/>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={isSubmitDisabled || disabled}
                        form='select-bet-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.confirm")}
                </Button>
            </div>
        </div>
    );
};

export default SelectBet;