import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import SendToFreeStock from "./SendToFreeStock";

type SendToFreeStockDialogProps = {
    id: string | Array<string>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const SendToFreeStockDialog: React.FC<SendToFreeStockDialogProps> = ({
                                                                         id,
                                                                         show,
                                                                         setShow
                                                                     }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'send-to-free-stock-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.btn.send-to-free-stock")}>
            <SendToFreeStock id={id}
                             close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default SendToFreeStockDialog;