import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'thumbTack',
    pathData: [
        'M1.77915 1.9874C1.65166 1.65661 1.58792 1.49122 1.6145 1.35845C1.63775 1.24234 1.70421 1.14045 1.79944 1.07493C1.90834 1 2.07997 1 2.42323 1H5.57677C5.92003 1 6.09166 1 6.20056 1.07493C6.29579 1.14045 6.36225 1.24234 6.3855 1.35845C6.41208 1.49122 6.34834 1.65661 6.22086 1.9874L5.78388 3.12126C5.76534 3.16936 5.75607 3.19341 5.74955 3.21821C5.74376 3.24025 5.73959 3.26271 5.73708 3.28541C5.73426 3.31095 5.73426 3.33685 5.73426 3.38866V4.34744C5.73426 4.44105 5.73426 4.48785 5.74311 4.53262C5.75097 4.57234 5.76396 4.61077 5.78173 4.64688C5.80176 4.68757 5.82993 4.72412 5.88627 4.79722L6.56687 5.68022C6.85552 6.0547 6.99984 6.24194 7 6.39951C7.00014 6.53656 6.94011 6.6662 6.83692 6.75169C6.71826 6.85 6.48723 6.85 6.02518 6.85H1.97482C1.51277 6.85 1.28174 6.85 1.16308 6.75169C1.05989 6.6662 0.999858 6.53656 1 6.39951C1.00016 6.24194 1.14448 6.0547 1.43313 5.68022L2.11373 4.79722C2.17007 4.72412 2.19824 4.68757 2.21827 4.64688C2.23604 4.61077 2.24903 4.57234 2.25689 4.53262C2.26574 4.48785 2.26574 4.44105 2.26574 4.34744V3.38866C2.26574 3.33685 2.26574 3.31095 2.26292 3.28541C2.26041 3.26271 2.25624 3.24025 2.25045 3.21821C2.24393 3.19341 2.23466 3.16936 2.21612 3.12126L1.77915 1.9874Z'
    ],
    pathDataExtended: [
        {
            data: 'M4 6.85L4 10M2.26574 3.38866V4.34744C2.26574 4.44105 2.26574 4.48785 2.25689 4.53262C2.24903 4.57234 2.23604 4.61077 2.21827 4.64688C2.19824 4.68757 2.17007 4.72412 2.11373 4.79722L1.43313 5.68022C1.14448 6.0547 1.00016 6.24194 1 6.39951C0.999858 6.53656 1.05989 6.6662 1.16308 6.75169C1.28174 6.85 1.51277 6.85 1.97482 6.85H6.02518C6.48723 6.85 6.71826 6.85 6.83692 6.75169C6.94011 6.6662 7.00014 6.53656 7 6.39951C6.99984 6.24194 6.85552 6.0547 6.56687 5.68022L5.88627 4.79722C5.82993 4.72412 5.80176 4.68757 5.78173 4.64688C5.76396 4.61077 5.75097 4.57234 5.74311 4.53262C5.73426 4.48785 5.73426 4.44105 5.73426 4.34744V3.38866C5.73426 3.33685 5.73426 3.31095 5.73708 3.28541C5.73959 3.26271 5.74376 3.24025 5.74955 3.21821C5.75607 3.19341 5.76534 3.16936 5.78388 3.12126L6.22086 1.9874C6.34834 1.65661 6.41208 1.49122 6.3855 1.35845C6.36225 1.24234 6.29579 1.14045 6.20056 1.07493C6.09166 1 5.92003 1 5.57677 1H2.42323C2.07997 1 1.90834 1 1.79944 1.07493C1.70421 1.14045 1.63775 1.24234 1.6145 1.35845C1.58792 1.49122 1.65166 1.65661 1.77915 1.9874L2.21612 3.12126C2.23466 3.16936 2.24393 3.19341 2.25045 3.21821C2.25624 3.24025 2.26041 3.26271 2.26292 3.28541C2.26574 3.31095 2.26574 3.33685 2.26574 3.38866Z',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 1
        }
    ],
    viewBox: '0 0 8 11',
};

export default icon;