import {ResponseResultCode} from "../../app/enums/ResponseResultCode";
import {IUser} from "../../app/interfaces/user/IUser";

export interface IAuthenticationState {
    utcOffset?: number;
    userAuthenticationStatus: AuthenticationStatus;
    deviceAuthenticationStatus: AuthenticationStatus;
    deviceAuthenticationResult?: ResponseResultCode;
    anonymousUserAuthenticationStatus?: AuthenticationStatus;
    anonymousUserCode?: string;
    anonymousUserId?: string;
    user?: IUser;
}

export enum AuthenticationStatus {
    NoAction = 0,
    InProgress = 1,
    Authenticated = 2,
    NotAuthenticated = 3
}