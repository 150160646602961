import React from 'react';
import TagContainer from "../../../../ui/Tag/TagContainer/TagContainer";
import {TagItemBackgroundColor} from "../../../../ui/Tag/Tag";

type FilterPanelOfferTypeFilterSelectedContainerProps = {
    id: string;
    onClear: (value?: string) => void;
    value: Array<string>;
};

const FilterPanelOfferTypeFilterSelectedContainer: React.FC<FilterPanelOfferTypeFilterSelectedContainerProps> = ({
                                                                                                                     id,
                                                                                                                     value,
                                                                                                                     onClear
                                                                                                                 }) => {
    return (
        <div style={{marginBottom: '5px'}}>
            <TagContainer id={id}
                          width={'100%'}
                          height={'100px'}
                          items={value.map(item => {
                              return {
                                  id: `offer-status-filter-${item}`,
                                  text: item,
                                  data: item,
                                  showClearIcon: true,
                                  backgroundColor: TagItemBackgroundColor.LightGray,
                                  rounded: true
                              };
                          })}
                          onClear={({data}) => {
                              if (data !== undefined && data !== null) {
                                  onClear(data as string);
                              }
                          }}/>
        </div>
    );
};

export default FilterPanelOfferTypeFilterSelectedContainer;