import React from 'react';
import cl from './JobCellTemplate.module.css';
import apiAxios from "../../../../../../app/axios/apiAxios";
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";

let GET_JOB_MANAGEMENT_LINK = `/api/${process.env.REACT_APP_API_VERSION}/shipment/getJobManagementLink`

export const openJobManagement = async (gid: string) => {
    try {
        let response = await apiAxios.get<string>(`${GET_JOB_MANAGEMENT_LINK}?id=${gid}&_c=${Date.now()}`);

        if (response.status === 200 && response.data) {
            window.open(response.data, '_blank');
        }
    } catch {
        //ignore
    }
}


const JobCellTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
            <span className={cl.cell}>
                <button className={`${cl.button} shipment-grid-open-job-management grid-prevent-selection`}
                        style={{
                            fontSize: '12px'
                        }}
                        onClick={async (ev) => {
                            ev.stopPropagation();

                            await openJobManagement(props.children as string)
                        }}>
                    {props.children}
                </button>
            </span>
        </td>
    );
};

export default JobCellTemplate;