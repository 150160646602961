import {UseDraggableProps} from "./interfaces/UseDraggableProps";
import {UseDraggableCoordinate} from "./interfaces/UseDraggableCoordinate";
import {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {throttle} from "lodash";
import {debounce} from "debounce";

const defaultOnDrag = (coordinates: UseDraggableCoordinate) => coordinates;
const useDraggable = <T extends HTMLElement = HTMLElement>({
                                                               onDrag = defaultOnDrag,
                                                               restrictDragByWindowSize = true,
                                                               center = true
                                                           }: UseDraggableProps): [ref: ((item: T) => void), dragging: boolean] => {
    const [dragging, setDragging] = useState<boolean>(false);

    const position = useRef<UseDraggableCoordinate>({x: 0, y: 0});
    const funcRef = useRef<(() => void) | null>(null);
    const elementRef = useRef<T | null>(null);
    const elementSize = useRef<UseDraggableCoordinate | null>(null);

    const elementRefInner = useCallback((elem: T | null) => {
        elementRef.current = elem;

        if (funcRef.current) {
            funcRef.current();
        }

        if (!elem) {
            return;
        }

        if (center && elementRef.current) {
            let elementPositionProperty = elementRef.current
                ? window.getComputedStyle(elementRef.current).getPropertyValue('position')
                : '';

            let centerX = (document.documentElement.clientWidth
                - elementRef.current.clientWidth) / 2;

            let centerY = (document.documentElement.clientHeight
                - elementRef.current.clientHeight) / 2;

            if (elementPositionProperty === 'absolute') {
                elem.style.top = `${centerY}px`;
                elem.style.left = `${centerX}px`;
            } else {
                elem.style.transform = `translate(${centerY}px, ${centerX}px)`;
            }

            position.current = {
                x: centerX,
                y: centerY
            };
        }

        const handleMouseDown = (e: any) => {
            e.target.style.userSelect = "none";
            setDragging(true);
        };

        const onElementResize = () => {
            elementSize.current = {
                x: elementRef.current?.offsetWidth ?? 0,
                y: elementRef.current?.offsetHeight ?? 0
            };
        }

        const observer = new ResizeObserver(onElementResize);
        observer.observe(elem);

        const activeDraggableZone = elem.querySelector('.draggable-active-zone');

        if (activeDraggableZone) {
            activeDraggableZone.addEventListener('mousedown', handleMouseDown);
        }

        funcRef.current = () => {
            position.current = {
                x: 0,
                y: 0
            };

            observer.unobserve(elem);

            if (activeDraggableZone) {
                activeDraggableZone.removeEventListener('mousedown', handleMouseDown);
            }
        };
    }, []);

    useLayoutEffect(() => {
        if (!restrictDragByWindowSize) {
            return;
        }

        let elementPositionProperty = elementRef.current
            ? window.getComputedStyle(elementRef.current).getPropertyValue('position')
            : '';

        const onResize = debounce(() => {
            if (!elementRef.current || !position.current) {
                return;
            }

            const pos = position.current;
            const elem = elementRef.current;

            position.current = {
                x: Math.max(0, pos.x + (elementSize.current?.x ?? 0) > window.innerWidth
                    ? (window.innerWidth - (elementSize.current?.x ?? 0))
                    : pos.x),
                y: Math.max(0, pos.y + (elementSize.current?.y ?? 0) > window.innerHeight
                    ? (window.innerHeight - (elementSize.current?.y ?? 0))
                    : pos.y)
            };

            if (elementPositionProperty === 'absolute') {
                elem.style.top = `${position.current.y}px`;
                elem.style.left = `${position.current.x}px`;
            } else {
                elem.style.transform = `translate(${position.current.x}px, ${position.current.y}px)`;
            }
        }, 200);

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    useEffect(() => {
        if (!dragging) {
            return;
        }

        let elementPositionProperty = elementRef.current
            ? window.getComputedStyle(elementRef.current).getPropertyValue('position')
            : '';

        const handleMouseMove = throttle((event) => {
            if (!elementRef.current || !position.current) {
                return;
            }

            const pos = position.current;
            const elem = elementRef.current;

            if (restrictDragByWindowSize) {
                position.current = {
                    x: Math.max(0, pos.x + event.movementX + (elementSize.current?.x ?? 0) >= window.innerWidth
                        ? (window.innerWidth - (elementSize.current?.x ?? 0))
                        : (pos.x + event.movementX)),
                    y: Math.max(0, pos.y + event.movementY + (elementSize.current?.y ?? 0) >= window.innerHeight
                        ? (window.innerHeight - (elementSize.current?.y ?? 0))
                        : (pos.y + event.movementY))
                };
            } else {
                position.current = onDrag({
                    x: pos.x + event.movementX,
                    y: pos.y + event.movementY
                });
            }

            if (elementPositionProperty === 'absolute') {
                elem.style.top = `${pos.y}px`;
                elem.style.left = `${pos.x}px`;
            } else {
                elem.style.transform = `translate(${pos.x}px, ${pos.y}px)`;
            }
        });

        const handleMouseUp = (e: any) => {
            e.target.style.userSelect = "auto";
            setDragging(false);
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);

        return () => {
            handleMouseMove.cancel();
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [dragging, onDrag]);

    return [elementRefInner, dragging];
};

export default useDraggable;