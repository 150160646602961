import React from 'react';
import {useTranslation} from "react-i18next";
import ChangeRefNum from "./ChangeRefNum";
import Dialog from "../../../../../components/ui/Dialog/Dialog";

type ChangeRefNumDialogProps = {
    id: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangeRefNumDialog: React.FC<ChangeRefNumDialogProps> = ({
                                                                   id,
                                                                   show,
                                                                   setShow
                                                               }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'change-ref-number-dialog'}
                show={show}
                setShow={setShow}
                title={t("shipment.grid.edit-ref-number")}
                width={'650px'}>
            <ChangeRefNum id={id}
                          close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default ChangeRefNumDialog;