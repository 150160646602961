import React, {ReactNode} from 'react';
import {useChatHelperContext} from "../../hooks/useChatHelperContext";
import {createPortal} from "react-dom";
import {AnimatePresence, motion} from "framer-motion";
import useDraggable from "../../../../../../hooks/useDraggable/useDraggable";

type ChatPanelProps = {
    children: ReactNode;
};

const ChatPanel: React.FC<ChatPanelProps> = ({children}) => {
    const [ref] = useDraggable<HTMLDivElement>({});

    const {
        isChatOpened,
        isMobileView,
        isFullScreenMode
    } = useChatHelperContext();

    return (
        <>
            {createPortal(
                <AnimatePresence>
                    {isChatOpened &&
                        <motion.div ref={ref}
                                    key={'chat-panel'}
                                    className={`chat-panel ${((!isMobileView && isFullScreenMode) || isMobileView) ? 'chat-panel-expanded' : ''}`}
                                    initial={{y: '50%', opacity: 0, scale: 0.5}}
                                    exit={{y: '50%', opacity: 0}}
                                    animate={{y: 0, opacity: 1, scale: 1}}
                                    transition={{duration: 0.2, ease: 'easeOut'}}>
                            {children}
                        </motion.div>
                    }
                </AnimatePresence>,
                document.body
            )}
        </>
    );
};

export default ChatPanel;