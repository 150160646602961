import {IApiErrorItem} from "../../apiError";
import {ResponseResultCode} from "../../enums/ResponseResultCode";

const deviceApiErrors: Array<IApiErrorItem> = [
    {//Common server error
        response: {
            resultCode: ResponseResultCode.ServerError
        },
        resource: 'shared.errors.server-error'
    },
    {//Common confirm device error
        response: {
            resultCode: ResponseResultCode.ServerError,
            action: 'device/confirmDevice'
        },
        resource: 'shared.errors.server-error'
    },
    {//Common send confirmation email error
        response: {
            resultCode: ResponseResultCode.ServerError,
            action: 'device/sendConfirmationEmail'
        },
        resource: 'shared.errors.server-error'
    },
    {//When arguments to confirm device isn't valid
        response: {
            resultCode: ResponseResultCode.NotValidData,
            field: 'device',
            action: 'device/confirmDevice'
        },
        resource: 'device.errors.confirm-device-args-not-valid'
    },
    {//When unauthorized
        response: {
            resultCode: ResponseResultCode.Unauthorized,
            field: 'user',
            action: 'device/confirmDevice'
        },
        resource: 'device.errors.user-not-found'
    },
    {//When device not found
        response: {
            resultCode: ResponseResultCode.NotFound,
            field: 'device',
            action: 'device/confirmDevice'
        },
        resource: 'device.errors.device-not-found'
    },
    {//When confirmation code is expired
        response: {
            resultCode: ResponseResultCode.Expired,
            field: 'code',
            action: 'device/confirmDevice'
        },
        resource: 'device.errors.confirmation-code-expired'
    },
    {//When entered code invalid
        response: {
            resultCode: ResponseResultCode.Forbidden,
            field: 'code',
            action: 'device/confirmDevice'
        },
        resource: 'device.errors.confirmation-code-invalid'
    },
    {//When args invalid
        response: {
            resultCode: ResponseResultCode.NotValidData,
            field: 'device',
            action: 'device/sendConfirmationEmail'
        },
        resource: 'device.errors.confirm-device-args-not-valid'
    },
    {//When user not found or has no email
        response: {
            resultCode: ResponseResultCode.Unauthorized,
            field: 'user',
            action: 'device/sendConfirmationEmail'
        },
        resource: 'device.errors.user-not-found'
    },
    {//When user not found or has no email
        response: {
            resultCode: ResponseResultCode.NotFound,
            field: 'device',
            action: 'device/sendConfirmationEmail'
        },
        resource: 'device.errors.send-confirmation-email-device-not-found'
    },
];

export default deviceApiErrors;