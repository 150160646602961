import React, {useState} from 'react';
import {IUser} from "../../../../../../app/interfaces/user/IUser";
import {
    ISelfBillingAddExpenseGetAction
} from "../../../../../../app/interfaces/selfBilling/ISelfBillingAddExpenseGetAction";
import {
    ISelfBillingAddExpensePostAction
} from "../../../../../../app/interfaces/selfBilling/ISelfBillingAddExpensePostAction";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import Input from "../../../../../../components/ui/Input/Input";
import {SelectModel} from "../../../../../../app/types/SelectModel";
import {calculateRate, getExpenseTypes, getVatNote} from "../../../form/SelfBillingForm/utils";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import Alert from "../../../../../../components/ui/Alert/Alert";
import * as Yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins, faPen} from "@fortawesome/free-solid-svg-icons";
import cl from './AddExpenseForm.module.css';
import NumericInput from "../../../../../../components/ui/Input/NumericInput";
import Textarea from "../../../../../../components/ui/Textarea/Textarea";
import AddExpenseFormCurrencySelector from "./AddExpenseFormCurrencySelector/AddExpenseFormCurrencySelector";
import {EcmDocumentCategory} from "../../../../../../app/enums/EcmDocumentCategory";
import {Upload, UploadFileInfo} from "@progress/kendo-react-upload";
import FileUpload from "../../../../../../components/ui/FileUpload/FileUpload";

type AddExpenseFormServiceProviderProps = {
    disabled: boolean;
    user?: IUser;
    model: ISelfBillingAddExpenseGetAction;
    onSubmit: (values: ISelfBillingAddExpensePostAction) => Promise<void>;
};

const AddExpenseFormServiceProvider: React.FC<AddExpenseFormServiceProviderProps> = ({
                                                                                         onSubmit,
                                                                                         model,
                                                                                         disabled,
                                                                                         user
                                                                                     }) => {
    const {t} = useTranslation();

    const [files, setFiles] = React.useState<Array<UploadFileInfo>>([]);
    const [expenseTypes] = useState<Array<SelectModel>>(() => {
        return getExpenseTypes().map(type => {
            return {
                ...type,
                description: t(type.description ?? ''),
                text: t(type.text)
            }
        })
    });

    const [documentTypes] = useState<Array<SelectModel>>(() => {
        return [{
            text: t("self-billing.labels.financial-document"),
            value: EcmDocumentCategory.Fin.toString()
        }];
    });

    const form = useFormik<ISelfBillingAddExpensePostAction>({
        initialValues: {
            expenseType: expenseTypes[0],
            bet: null,
            currency: 'EUR',
            isIncorrectVatNotesShown: false,
            rate: null,
            incorrectVatNotes: null,
            categoryModel: documentTypes[0],
            cmrFile: null,
            notes: null,
            contractId: model.selectedContractId,
            serviceProvider: model.initialServiceProvider,
            serviceProviderId: model.initialServiceProviderId
        },
        validationSchema: Yup.object().shape({
            bet: Yup.string().nullable()
                .required(t("shared.errors.required-error").replace('{0}', t("self-billing.labels.your-rate"))),
            currency: Yup.string().nullable()
                .required(t("shared.errors.required-error").replace('{0}', 'Currency')),
            cmrFile: Yup.mixed().nullable().required(t("shared.errors.required-error")
                .replace('{0}', t("self-billing.labels.cmr-file"))),
        }),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values, actions) => {
            actions.setSubmitting(true);

            await onSubmit(values);

            actions.setSubmitting(false);
        }
    });

    function onSaveRequest(
        files: UploadFileInfo[],
        _options: { formData: FormData; requestOptions: any },
        _onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
    ): Promise<{ uid: string }> {
        const currentFile = files[0] as UploadFileInfo;
        const uid = currentFile.uid;

        return new Promise<{ uid: string }>((resolve, reject) => {
            if (
                currentFile.validationErrors &&
                currentFile.validationErrors.length > 0
            ) {
                reject({uid: uid});
            } else {
                let file = currentFile.getRawFile!();

                form.setFieldValue('cmrFile', file);

                resolve({uid: uid});
            }
        });
    }

    function onRemoveRequest(files: UploadFileInfo[]): Promise<{ uid: string }> {
        const currentFile = files[0] as UploadFileInfo;
        const uid = currentFile.uid;

        return new Promise<{ uid: string }>((resolve) => {
            form.setFieldValue('cmrFile', null);

            resolve({uid: uid});
        });
    }

    let vatNote = getVatNote(model.vatNote);
    let hasVatNote = model.vat !== null && model.vatNote !== null && model.vatNote !== '';

    return (
        <form id={'add-expense-form'}
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div className={'mgb-10'}>
                    <label className={'view-label'}>
                        {t("shipment.form.supplier")}
                    </label>

                    <Input placeholder={t("shipment.form.supplier")}
                           id={'add-expense-service-provider'}
                           disabled={true}
                           value={model.initialServiceProvider ?? ''}
                           autoComplete={'off'}/>
                </div>

                <div className={'mgb-10'}>
                    <label className={'view-label'}>
                        {t("offer.grid.rate-offering")}
                    </label>

                    <Input placeholder={t("offer.grid.rate-offering")}
                           id={'add-expense-contract'}
                           disabled={true}
                           value={model.contract ?? ''}
                           autoComplete={'off'}/>
                </div>

                <div className={cl.separator}></div>

                <div className={'mgb-10 drop-down-picker'}>
                    <label className={'view-label required'}>
                        {t("self-billing.labels.expense-name")}
                    </label>

                    <DropDownList data={expenseTypes}
                                  disabled={disabled}
                                  textField="text"
                                  dataItemKey="value"
                                  value={expenseTypes.find(e => e.value === form.values.expenseType.value)}
                                  onChange={(ev) => form.setFieldValue('expenseType', ev.value)}
                    />

                    <Alert type={'BrightGrayInfo'}
                           style={{
                               margin: '5px 0',
                               fontSize: '11px',
                               fontWeight: 'bold',
                               padding: '10px'
                           }}>
                        {form.values.expenseType.description}
                    </Alert>
                </div>

                {hasVatNote &&
                    <div className={`${cl.btnContainer}`}>
                        <button className={cl.button}
                                type={'button'}
                                disabled={disabled}
                                onClick={() => form.setFieldValue('isIncorrectVatNotesShown', !form.values.isIncorrectVatNotesShown)}>
                            <FontAwesomeIcon icon={faPen}/>
                            <div>
                                {t("self-billing.btn.invalid-vat")}
                            </div>
                        </button>
                    </div>
                }

                <div className={`mgb-10`}>
                    <label className={'view-label required'}>
                        {model.revertVat
                            ? t("self-billing.labels.sum-with-vat")
                            : t("self-billing.labels.rate")
                        }
                    </label>

                    <div className={`${hasVatNote ? cl.vatNoteContainer : ''}`}>
                        <div>
                            <div style={{display: 'flex', gap: '5px'}}>
                                <div className={'col-sm-2 numeric-text-box'} style={{width: '60%'}}>
                                    <NumericInput disabled={disabled}
                                                  icon={faCoins}
                                                  placeholder={t("self-billing.labels.your-rate")}
                                                  spinners={false}
                                                  name={'bet'}
                                                  format={'n2'}
                                                  value={form.values.bet && form.values.bet !== ''
                                                      ? Number(form.values.bet.replace(/,/g, '.').replace(/\s+/g, ""))
                                                      : null}
                                                  min={0}
                                                  max={100000000}
                                                  onChange={async (ev) => {
                                                      if (ev.value !== null && ev.value !== 0) {
                                                          await form.setFieldValue('bet', ev.value.toString());
                                                      } else {
                                                          await form.setFieldValue('bet', null);
                                                      }

                                                      await form.setFieldValue('rate', calculateRate(ev.value?.toString() ?? null, model.vat, model.revertVat));
                                                  }}
                                    />
                                </div>

                                <div className={'col-sm-2 drop-down-picker'} style={{width: '40%'}}>
                                    <AddExpenseFormCurrencySelector disabled={disabled}
                                                                    user={user}
                                                                    currency={form.values.currency}
                                                                    onChange={(value: string | null) => {
                                                                        form.setFieldValue('currency', value)
                                                                    }}/>
                                </div>
                            </div>
                        </div>

                        {hasVatNote && model.vat !== null &&
                            <div className={cl.vatContainer}>
                                <div>
                                    <label className={'view-label'}>
                                        {t("self-billing.labels.vat")}:
                                    </label>

                                    <div className={'view-label'}>
                                        {`${model.vat.toFixed(2).toString()}%`}
                                    </div>

                                    {vatNote !== null &&
                                        <div className={'view-label'}>
                                            {`(${t(vatNote)})`}
                                        </div>
                                    }
                                </div>

                                <div>
                                    <label className={'view-label'}>
                                        {model.revertVat
                                            ? t("self-billing.labels.rate")
                                            : t("self-billing.labels.sum-with-vat")
                                        }
                                    </label>

                                    <div className={'view-label'}>
                                        {form.values.rate
                                            ? `${form.values.rate} ${form.values.currency}`
                                            : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    {form.errors.bet &&
                        <div className={'error-label'}>
                            {form.errors.bet}
                        </div>
                    }

                    {form.errors.currency &&
                        <div className={'error-label'}>
                            {form.errors.currency}
                        </div>
                    }
                </div>

                {hasVatNote && form.values.isIncorrectVatNotesShown &&
                    <div className={'mgb-10'}>
                        <label className={'view-label'}>
                            {t("self-billing.labels.incorrect-vat-notes")}
                        </label>

                        <Textarea id={'change-cost-invalid-vat-notes'}
                                  name={'incorrectVatNotes'}
                                  placeholder={t("self-billing.labels.incorrect-vat-notes")}
                                  disabled={form.isSubmitting}
                                  value={form.values.incorrectVatNotes ?? ''}
                                  autoComplete={'off'}
                                  style={{minHeight: '70px', resize: 'vertical', maxHeight: '100px'}}
                                  onChange={form.handleChange}/>
                    </div>
                }

                <div className={cl.separator}></div>

                {/*<div className={'mgb-10 drop-down-picker'}>
                    <label className={'view-label required'}>
                        {t("self-billing.labels.document-type")}
                    </label>

                    <DropDownList data={documentTypes}
                                  disabled={disabled}
                                  textField="text"
                                  dataItemKey="value"
                                  value={form.values.categoryModel !== undefined
                                      ? documentTypes.find(e => e.value === form.values.categoryModel.value)
                                      : null}
                                  onChange={(ev) => form.setFieldValue('categoryModel', ev.value)}/>
                </div>*/}

                <div className={'mgb-10 file-upload'}>
                    <label className={'view-label required'}>
                        {t("self-billing.labels.cmr-file")}
                    </label>

                    <FileUpload id={'add-expense-form-file-uploader'}
                                /*multiple={false}*/
                                disabled={disabled}
                                onChange={(files) => {
                                    form.setFieldValue('cmrFile', files.length > 0
                                        ? files[0].file
                                        : null);
                                }}
                                restrictions={{
                                    allowedExtensions: [".jpg", ".jpeg", ".png", ".pdf", ".docx", ".doc"]
                                }}
                    />

                    {/*       <Upload batch={false}
                            disabled={disabled}
                            multiple={false}
                            files={files}
                            onAdd={(ev) => setFiles(ev.newState)}
                            onRemove={(ev) => setFiles(ev.newState)}
                            onProgress={(ev) => setFiles(ev.newState)}
                            onStatusChange={(ev) => setFiles(ev.newState)}
                            saveUrl={onSaveRequest}
                            removeUrl={onRemoveRequest}
                            restrictions={{
                                allowedExtensions: [".jpg", ".jpeg", ".png", ".pdf", ".docx", ".doc"],
                            }}
                    />*/}

                    {form.values.cmrFile === null && form.errors.cmrFile &&
                        <div className={'error-label'}>
                            {form.errors.cmrFile}
                        </div>
                    }
                </div>

                <div className={'mgb-10'}>
                    <label className={'view-label'}>
                        {t("self-billing.labels.notes")}
                    </label>

                    <Textarea id={'add-cmr-notes'}
                              name={'notes'}
                              placeholder={t("self-billing.labels.notes")}
                              disabled={form.isSubmitting}
                              value={form.values.notes ?? ''}
                              autoComplete={'off'}
                              style={{minHeight: '70px', resize: 'vertical', maxHeight: '100px'}}
                              onChange={form.handleChange}/>
                </div>
            </div>
        </form>
    );
};

export default AddExpenseFormServiceProvider;
