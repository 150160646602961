import {IUploadFile} from "./IUploadFile";
import React, {MutableRefObject} from "react";

export type FileUploadProps = {
    id: string;
    disabled?: boolean;
    /*multiple?: boolean;*/

    onChange?: (files: Array<IUploadFile>) => void;

    restrictions?: FileUploadRestrictions;
    selectButtonText?: string;
};

export type FileUploadRestrictions = {
    maxFileSize?: number;
    minFileSize?: number;
    allowedExtensions?: Array<FileUploadExtensions>;
};

export type FileUploadExtensions = ".jpg" | ".jpeg" | ".png" | ".pdf" | ".docx" | ".doc" | ".xlsx" | ".xls" | ".rtf" | ".tiff";

export type UseFileUploadProps = {
    disabled?: boolean;
    /*multiple?: boolean;*/
    restrictions?: FileUploadRestrictions;

    onChange?: (files: Array<IUploadFile>) => void;
};

export type UseFileUploadReturn = {
    ref: MutableRefObject<HTMLInputElement | null>;

    files: Array<IUploadFile>;
    isDragActive: boolean;

    onClear: (id: string) => void;
    onInputChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
    onDrop: (ev: React.DragEvent<HTMLDivElement>) => void;
    onDragOver: (ev: React.DragEvent<HTMLDivElement>) => void;
    onDragLeave: (ev: React.DragEvent<HTMLDivElement>) => void;
    onBrowseClick: () => void;
};

export enum UploadFileStatus {
    Uploaded = 0,
    Uploading = 1,
    UploadingFailed = 2,
    RestrictionFailed = 3,
    Initial = 4
}