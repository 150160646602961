import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../uiLayouts/ToastLayout/ToastLayout";
import Button, {ButtonType} from "../../../ui/Button/Button";
import {useAccountApi} from "../../../../app/api/account";
import {IChangePassword} from "../../../../app/interfaces/account/IChangePassword";
import {IError} from "../../../../app/interfaces/response/IResponse";
import {BLErrorCode} from "../../../../app/enums/BLErrorCode";
import Alert from "../../../ui/Alert/Alert";
import ChangePasswordForm from "./ChangePasswordForm/ChangePasswordForm";
import useDefaultSitePasswordRules from "../../../../hooks/useDefaultSitePasswordRules/useDefaultSitePasswordRules";

type ChangePasswordProps = {
    close: () => void;
};

const alertStyle: React.CSSProperties = {
    fontSize: '11px',
    fontWeight: 'bold',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    margin: '10px 15px'
}

const ChangePassword: React.FC<ChangePasswordProps> = ({
                                                           close
                                                       }) => {
    const {t} = useTranslation();
    const [isSubmitDisabled] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError>>([]);
    const rules = useDefaultSitePasswordRules();

    const {
        changePassword: {
            mutation
        }
    } = useAccountApi();

    const save = async (model: IChangePassword): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            const response = await mutation(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                toast.success(<ToastLayout text={t("account.password.password-changed-text")}
                                           title={t("account.password.change-password")}/>);

                close();
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.data.resultCode === ResponseResultCode.ServerError) {
                    setErrors([
                        {
                            field: 'summary',
                            errorCode: BLErrorCode.InnerError,
                            description: `Unable to change password, error: ${err.message}`
                        }
                    ]);
                } else {
                    setErrors([
                        {
                            field: 'summary',
                            errorCode: BLErrorCode.InnerError,
                            description: `Unable to change password, error: ${err.message}`
                        }
                    ]);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                {errors.map(item => {
                    switch (item.errorCode) {
                        case BLErrorCode.InnerError:
                            return (
                                <Alert type={'Error'} style={alertStyle}>
                                    {item.description}
                                </Alert>
                            );
                    }

                    return null;
                })}

                <ChangePasswordForm id={'change-password-form'}
                                    disabled={disabled}
                                    save={save}
                                    rules={rules}
                />
            </div>
            <div className={'dialog-default-buttons-container'}>
                <Button buttonType={ButtonType.Transparent}
                        disabled={disabled}
                        className={'close-button'}
                        onClick={close}>
                    {t("shared.btn.cancel")}
                </Button>
                <Button buttonType={ButtonType.Primary}
                        disabled={isSubmitDisabled || disabled}
                        form='change-password-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.save")}
                </Button>
            </div>
        </div>
    );
};

export default ChangePassword;