import React from 'react';
import {IQuote} from "../../../../../../../app/interfaces/quote/IQuote";
import {useTranslation} from "react-i18next";

type HighValueFieldProps = {
    quote: IQuote;
}

const spanStyle: React.CSSProperties = {
    color: 'white',
    backgroundColor: '#var(--red-color)',
    padding: '2px 10px',
    borderRadius: '24px'
}

const HighValueField: React.FC<HighValueFieldProps> = ({
                                                           quote
                                                       }) => {
    const {t} = useTranslation();

    if (!quote.isHighValue) {
        return null;
    }

    return (
        <div className={'details-box-item-field-value is-high-value-value'}>
            <span style={spanStyle}>
                {t("quote.form.high-value")}
            </span>
        </div>
    );
};

export default HighValueField;
