import React, {useCallback, useEffect, useState} from 'react';
import {SelectModel} from "../../../../../../app/types/SelectModel";
import {debounce} from "debounce";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {useDataHelperApi} from "../../../../../../app/api/dataHelper";

type AssignOfferFormManagerSelectorProps = {
    id: string;
    placeholder: string;
    disabled: boolean;
    value: string | null;
    onChange: (val?: string) => void;
};

const AssignOfferFormManagerSelector: React.FC<AssignOfferFormManagerSelectorProps> = ({
                                                                                           id,
                                                                                           placeholder,
                                                                                           disabled,
                                                                                           value,
                                                                                           onChange
                                                                                       }) => {
    const [items, setItems] = useState<Array<SelectModel>>([]);
    const [filter, setFilter] = useState<string | undefined>();

    const {
        getUsersForAssignment: {
            query,
            isLoading,
            cancel
        }
    } = useDataHelperApi();

    useEffect(() => {
        return () => {
            cancel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            await load(value, filter);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const load = useCallback(debounce(async (lValue: string | null, lFilter?: string) => {
        try {
            const response = await query(false, lValue ?? undefined, lFilter);
            if (response?.status === 200 && response.data?.data) {
                setItems(response.data.data.filter(e => e.text !== '' && e.value !== ''));
            }
        } catch {
            //ignore
        }
    }, 500), []);

    return (
        <div className={`w100 combobox-picker`} style={{paddingTop: '5px'}}>
            <ComboBox data={items}
                      id={id}
                      value={value && items.length > 0 ? items.find(e => e.value === value) : undefined}
                      onChange={ev => onChange(ev.value?.value)}
                      disabled={disabled}
                      placeholder={placeholder}
                      textField="text"
                      dataItemKey="value"
                      clearButton={true}
                      filterable={true}
                      loading={isLoading}
                      onFilterChange={(ev) => {
                          onChange(undefined);

                          if (!ev.filter?.value || ev.filter.value.length < 3) {
                              return;
                          }

                          setFilter(ev.filter.value);
                      }}
            />
        </div>
    );
};

export default AssignOfferFormManagerSelector;