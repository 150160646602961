import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'chevronLeft',
    pathData: [
        'M9 14.2502L3.75 9.00024L9 3.75024'
    ],
    viewBox: '0 0 18 18',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;