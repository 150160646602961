import React from 'react';
import {INotificationGetAction} from "../../../../../../app/interfaces/notification/INotificationGetAction";
import {useFormik} from "formik";
import {INotificationPostAction} from "../../../../../../app/interfaces/notification/INotificationPostAction";
import cl from './NotificationForm.module.css';
import {IUser} from "../../../../../../app/interfaces/user/IUser";
import EmailNotificationSection from "../sections/EmailNotificationSection/EmailNotificationSection";
import MessengerNotificationSection from "../sections/MessengerNotificationSection/MessengerNotificationSection";
import TelegramSubscriptionGuideSection
    from "../sections/TelegramSubscriptionGuideSection/TelegramSubscriptionGuideSection";
import ViberSubscriptionGuideSection from "../sections/ViberSubscriptionGuideSection/ViberSubscriptionGuideSection";
import * as Yup from "yup";
import {isMaskValueValid} from "../../../../../../components/ui/PhoneNumberInput/utils";
import {useTranslation} from "react-i18next";
import {INotificationSetupPostAction} from "../../../../../../app/interfaces/notification/INotificationSetupPostAction";

type NotificationFormProps = {
    user: IUser;
    model: INotificationGetAction;
    disabled: boolean;
    onSubmit: (values: INotificationPostAction) => Promise<void>;
    reloadForm: () => Promise<void>;
};

const NotificationForm: React.FC<NotificationFormProps> = ({
                                                               onSubmit,
                                                               user,
                                                               model,
                                                               reloadForm,
                                                               disabled
                                                           }) => {
    const {t} = useTranslation();

    const form = useFormik<INotificationPostAction>({
        initialValues: {
            acceptAgreement: model.acceptAgreement,
            mobilePhone: model.mobilePhone,
            phoneCode: model.phoneCode,
            countryPhoneCode: model.countryPhoneCode,
            phoneFormat: null,
            email: model.email,
            ccEmail: model.ccEmail,
            finEmail: model.finEmail,
            setups: model.setups.map(setup => ({...setup} as INotificationSetupPostAction))
        },
        validationSchema: Yup.object().shape({
            mobilePhone: Yup.string().nullable()
                .test('is-mask-value-valid',
                    t("shipment.errors.edit-transport-mobile-phone-not-valid"),
                    function (val) {
                        const {phoneFormat} = this.parent;

                        if (val === null || val === undefined || val === '' || phoneFormat === null) {
                            return true;
                        }

                        //phoneFormat.mask === undefined это единственный случай, для Сербии
                        if (phoneFormat.mask === undefined)
                            return true;

                        return isMaskValueValid(val, phoneFormat.mask);
                    }
                ),
            ccEmail: Yup.array()
                .transform(function (value, originalValue) {
                    if (this.isType(value) && value !== null) {
                        return value;
                    }

                    return originalValue ? originalValue.replaceAll(';', ',').split(/[\s,]+/) : [];
                })
                .of(Yup.string().email(({value}) => `${t("profile.support.invalid-email")} (${value})`))
                .required(),
            finEmail: Yup.array()
                .transform(function (value, originalValue) {
                    if (this.isType(value) && value !== null) {
                        return value;
                    }

                    return originalValue ? originalValue.replaceAll(';', ',').split(/[\s,]+/) : [];
                })
                .of(Yup.string().email(({value}) => `${t("profile.support.invalid-email")} (${value})`))
                .required()
        }),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values, actions) => {
            actions.setSubmitting(true);

            await onSubmit(values);

            actions.setSubmitting(false);
        }
    });

    const onSetupChange = (setup: INotificationSetupPostAction, val: boolean) => {
        let result: Array<INotificationSetupPostAction> = [];

        form.values.setups.forEach(s => {
            result.push(s.id === setup.id
                ? {
                    ...s,
                    isEmail: val
                }
                : {
                    ...s
                }
            );
        });

        form.setFieldValue('setups', result);
    };

    return (
        <form id={'notification-inner-form'}
              onSubmit={form.handleSubmit}>
            <div className={cl.container}
                 data-type={user.serviceProvider !== null && user.serviceProvider.hideOffers ? 'two' : 'four'}>
                {user.serviceProvider !== null && !user.serviceProvider.hideOffers
                    ? <>
                        <div>
                            <EmailNotificationSection model={model}
                                                      user={user}
                                                      disabled={disabled}
                                                      email={form.values.email}
                                                      emailError={form.errors.email}
                                                      onEmailChange={(val) => form.setFieldValue('email', val)}
                                                      ccEmail={form.values.ccEmail}
                                                      ccEmailError={form.errors.ccEmail}
                                                      onCCEmailChange={(val) => form.setFieldValue('ccEmail', val)}
                                                      finEmail={form.values.finEmail}
                                                      finEmailError={form.errors.finEmail}
                                                      onFinEmailChange={(val) => form.setFieldValue('finEmail', val)}
                                                      setups={form.values.setups}
                                                      onSetupChange={onSetupChange}/>
                        </div>

                        <div>
                            <MessengerNotificationSection model={model}
                                                          disabled={disabled}
                                                          acceptAgreement={form.values.acceptAgreement}
                                                          onAcceptAgreementChange={(val: boolean) => form.setFieldValue('acceptAgreement', val)}
                                                          phoneCode={form.values.phoneCode}
                                                          phoneNumber={form.values.mobilePhone}
                                                          phoneError={form.errors.mobilePhone}
                                                          onPhoneCodeChange={(val) => {
                                                              form.setFieldValue('phoneFormat', val);
                                                              form.setFieldValue('phoneCode', val.phoneCode);
                                                              form.setFieldValue('countryPhoneCode', val.country);
                                                          }}
                                                          onPhoneNumberChange={(val) => form.setFieldValue('mobilePhone', val)}/>
                        </div>

                        <div>
                            {model.mobilePhone !== null && model.mobilePhone !== '' && model.acceptAgreement &&
                                <TelegramSubscriptionGuideSection model={model}
                                                                  reloadForm={reloadForm}/>
                            }
                        </div>

                        <div>
                            {model.mobilePhone !== null && model.mobilePhone !== '' && model.acceptAgreement &&
                                <ViberSubscriptionGuideSection model={model}
                                                               reloadForm={reloadForm}/>
                            }
                        </div>
                    </>
                    : <>
                        <div>
                            <EmailNotificationSection model={model}
                                                      user={user}
                                                      disabled={disabled}
                                                      email={form.values.email}
                                                      emailError={form.errors.email}
                                                      onEmailChange={(val) => form.setFieldValue('email', val)}
                                                      ccEmail={form.values.ccEmail}
                                                      ccEmailError={form.errors.ccEmail}
                                                      onCCEmailChange={(val) => form.setFieldValue('ccEmail', val)}
                                                      finEmail={form.values.finEmail}
                                                      finEmailError={form.errors.finEmail}
                                                      onFinEmailChange={(val) => form.setFieldValue('finEmail', val)}
                                                      setups={form.values.setups}
                                                      onSetupChange={onSetupChange}/>
                        </div>
                        <div>

                        </div>
                    </>
                }
            </div>
        </form>
    );
};

export default NotificationForm;