import {LayoutConfigEntity} from "../../../../../../app/enums/LayoutConfigEntity";
import GridFilterPanelLayout from "../../../../../../components/uiLayouts/GridFilterPanelLayout/GridFilterPanelLayout";
import ShipmentFilterPanelCheckboxSection
    from "./ShipmentFilterPanelCheckboxSection/ShipmentFilterPanelCheckboxSection";
import {useGridLayoutContext} from "../../../../../../components/uiLayouts/GridLayout/hooks/useGridLayoutContext";
import ShipmentFilterPanelFiltersSection from "./ShipmentFilterPanelFiltersSection/ShipmentFilterPanelFiltersSection";
import {IShipmentHeaderConfig} from "../../data/interfaces/IShipmentHeaderConfig";

const ShipmentFilterPanel = () => {
    const {
        headerConfig,
        saveHeaderConfig
    } = useGridLayoutContext<IShipmentHeaderConfig>();

    return (
        <GridFilterPanelLayout id={'shipment-grid-filter-panel'}
                               entity={LayoutConfigEntity.Shipment}
                               header={<ShipmentFilterPanelCheckboxSection/>}

                               pinnedPersonalFilter={headerConfig?.pinnedFilter ?? null}
                               pinnedPredefinedFilters={headerConfig?.pinnedPredefinedFilters ?? null}

                               onPredefinedFilterSelect={(filter) => {
                                   (async () => {
                                       if (!headerConfig) {
                                           return;
                                       }

                                       await saveHeaderConfig({
                                           ...headerConfig,
                                           pinnedPredefinedFilter: filter?.value ?? null
                                       });
                                   })();
                               }}
                               onPredefinedFilterPin={(filter, op) => {
                                   window.dispatchEvent(new Event('resize'));

                                   (async () => {
                                       if (!headerConfig) {
                                           return;
                                       }

                                       let items = headerConfig.pinnedPredefinedFilters === null || headerConfig.pinnedPredefinedFilters === undefined
                                           ? [
                                               filter.value
                                           ]
                                           : op === 'Add' ? [
                                                   ...headerConfig.pinnedPredefinedFilters,
                                                   filter.value
                                               ]
                                               : [
                                                   ...headerConfig.pinnedPredefinedFilters.filter(e => e !== filter.value)
                                               ]

                                       await saveHeaderConfig({
                                           ...headerConfig,
                                           pinnedPredefinedFilters: items
                                       });
                                   })();
                               }}
                               onPersonalFilterPin={(filter) => {
                                   (async () => {
                                       if (!headerConfig) {
                                           return;
                                       }

                                       await saveHeaderConfig({
                                           ...headerConfig,
                                           pinnedFilter: filter === null
                                               ? null
                                               : filter.id
                                       });
                                   })();
                               }}
                               onPersonalFilterRemove={async (filter) => {
                                   if (headerConfig && headerConfig.pinnedFilter && headerConfig.pinnedFilter === filter.id) {
                                       await saveHeaderConfig({
                                           ...headerConfig,
                                           pinnedFilter: null
                                       });
                                   }
                               }}>
            <ShipmentFilterPanelFiltersSection/>
        </GridFilterPanelLayout>
    );
};

export default ShipmentFilterPanel;