import React, {useContext} from 'react';
import {SidebarContext} from "../../Sidebar";
import cl from './SidebarLogoSection.module.css';
import {useNavigate} from "react-router-dom";
import Icon from "../../../../../assets/icon/Icon";
import {isMobile} from "react-device-detect";

const SidebarLogoSection: React.FC = () => {
    const navigate = useNavigate();

    const {
        expanded,
        hoverExpanded,
        onExpandChange,
        setIsExpandedOnHover
    } = useContext(SidebarContext);

    return (
        <div className={cl.container}>
            <Icon icon={"faAsstrALogo"}
                  dataCustomValueAttribute={expanded || hoverExpanded ? 'expanded' : 'condensed'}
                  className={cl.logo}
                  onClick={() => {
                      navigate('/');

                      if (isMobile && !expanded && hoverExpanded) {
                          setIsExpandedOnHover(false);
                      }
                  }}
            />

            <Icon icon={"faChevronRight"}
                  rotate={expanded || hoverExpanded ? 180 : undefined}
                  dataCustomValueAttribute={expanded || hoverExpanded ? 'expanded' : 'condensed'}
                  className={cl.chevron}
                  onClick={() => {
                      onExpandChange(!expanded);
                  }}
            />
        </div>
    );
};

export default SidebarLogoSection;