import React, {SetStateAction, useEffect} from 'react';
import {
    IConfirmAuctionContractGetAction
} from "../../../../../../../app/interfaces/offer/IConfirmAuctionContractGetAction";
import {
    IConfirmAuctionContractPostAction
} from "../../../../../../../app/interfaces/offer/IConfirmAuctionContractPostAction";
import {useTranslation} from "react-i18next";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import ConfirmAuctionServiceProviderSelector from "./ConfirmAuctionServiceProviderSelector";
import ConfirmAuctionContractSelector from "./ConfirmAuctionContractSelector/ConfirmAuctionContractSelector";

type ConfirmAuctionFormForwarderProps = {
    model: IConfirmAuctionContractGetAction;
    disabled: boolean;
    setIsSubmitDisabled: React.Dispatch<SetStateAction<boolean>>;
    save: (model: IConfirmAuctionContractPostAction) => Promise<void>;
};

const ConfirmAuctionFormForwarder: React.FC<ConfirmAuctionFormForwarderProps> = ({
                                                                                     model,
                                                                                     save,
                                                                                     disabled,
                                                                                     setIsSubmitDisabled
                                                                                 }) => {
    const {t} = useTranslation();

    useEffect(() => {
        if (model.serviceProviderId !== null && model.selectedContractId !== null) {
            setIsSubmitDisabled(false);
        }
    }, []);

    const form = useFormik<IConfirmAuctionContractPostAction>({
        initialValues: {
            orderReleaseGid: model.orderReleaseGid,
            serviceProviderId: model.serviceProviderId,
            contractId: model.selectedContractId
        },
        validationSchema: Yup.object().shape({
            serviceProviderId: Yup.string().nullable().required(t("shared.errors.required-error")
                .replace('{0}', t("offer.grid.service-provider"))),
            contractId: Yup.string().nullable().required(t("shared.errors.required-error").replace('{0}', t("offer.grid.rate-offering")))
        }),
        onSubmit: async (values: IConfirmAuctionContractPostAction, actions: FormikHelpers<IConfirmAuctionContractPostAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='confirm-auction-form'
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div style={{
                    fontSize: '17px',
                    color: '#4a4a4a',
                    textAlign: 'center'
                }} className={'mgb-10'}>
                    {t("offer.form.confirm-selected-transportation")}
                </div>

                <div className={'mgb-10'}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <label className={'view-label required'}>
                            {t("offer.grid.service-provider")}
                        </label>
                        {!form.values.serviceProviderId && form.errors.serviceProviderId &&
                            <div className={'error-label'}
                                 style={{margin: '0'}}>
                                {form.errors.serviceProviderId}
                            </div>
                        }
                    </div>

                    <ConfirmAuctionServiceProviderSelector model={model}
                                                           disabled={disabled}
                                                           value={form.values.serviceProviderId}
                                                           onChange={(val) => {
                                                               setIsSubmitDisabled(!(val.value !== null && val.value !== undefined && form.values.contractId !== null && form.values.contractId !== ''));

                                                               form.setFieldValue('serviceProviderId', val.value);
                                                               form.setFieldValue('serviceProvider', val.text);
                                                               form.setFieldValue('contractId', null);
                                                           }}/>
                </div>

                <div className={'mgb-10'}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <label className={'view-label required'}>
                            {t("offer.grid.rate-offering")}
                        </label>
                        {!form.values.contractId && form.errors.contractId &&
                            <div className={'error-label'}
                                 style={{margin: '0'}}>
                                {form.errors.contractId}
                            </div>
                        }
                    </div>

                    <ConfirmAuctionContractSelector model={model}
                                                    value={form.values.contractId}
                                                    serviceProviderId={form.values.serviceProviderId}
                                                    onChange={(contractId) => {
                                                        setIsSubmitDisabled(!(form.values.serviceProviderId !== null && form.values.serviceProviderId !== '' &&
                                                            contractId !== null && contractId !== ''));
                                                        form.setFieldValue('contractId', contractId);
                                                    }}/>
                </div>
            </div>
        </form>
    );
};

export default ConfirmAuctionFormForwarder;
