import {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";

const useLocalStorage = <T>(key: string, initialValue: T): [T, Dispatch<SetStateAction<T>>] => {
    const getValue = useCallback((): T => {
        if (typeof window === 'undefined') {
            return initialValue
        }

        try {
            const item = window.localStorage.getItem(key)
            return item ? (parseJSON(item) as T) : initialValue
        } catch (error) {
            console.warn(`Error reading localStorage key “${key}”:`, error)
            return initialValue
        }
    }, [initialValue, key]);

    const [value, setValue] = useState<T>(getValue);

    const set: Dispatch<SetStateAction<T>> = useCallback(val => {
        try {
            const newValue = val instanceof Function ? val(value) : val

            window.localStorage.setItem(key, JSON.stringify(newValue))

            setValue(newValue)
        } catch (error) {
            console.warn(`Error setting localStorage key “${key}”:`, error)
        }
    }, []);

    useEffect(() => {
        setValue(getValue())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return [value, set];
}

function parseJSON<T>(value: string | null): T | undefined {
    try {
        return value === 'undefined' ? undefined : JSON.parse(value ?? '')
    } catch {
        console.log('parsing error on', {value})
        return undefined
    }
}

export default useLocalStorage;