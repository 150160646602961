const getScreenResolution = (): [number, number] => {
    const s = window.screen;

    const dimensions = [
        parseInt(s.width as unknown as string),
        parseInt(s.height as unknown as string)
    ] as [number, number];

    dimensions.sort().reverse();

    return dimensions;
}

export default getScreenResolution;