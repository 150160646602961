import React from 'react';

const NotificationContent: React.FC = () => {
    return (
        <div style={{height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px'}}>
           {/* <div className="k-animation-container k-animation-container-shown"
                 style="z-index: 100; position: absolute; top: 843px; left: 383px; width: 60px; direction: ltr;">
                <div className="k-child-animation-container" style="transition-delay: 0ms;">
                    <div className="k-popup k-list-container k-dropdownlist-popup">
                        <div className="k-list k-list-md">
                            <div className="k-list-content" unselectable="on" style="max-height: 200px;">
                                <ul id="35d66bce-02e4-46c7-a448-6b6aa30380f3" role="listbox" className="k-list-ul">
                                    <li id="option-c0c168b7-8314-47f7-ba67-89d4e5bbd163-0" role="option"
                                        aria-selected="false" className="k-list-item" style="position: unset;"><span
                                        className="k-list-item-text">10</span></li>
                                    <li id="option-c0c168b7-8314-47f7-ba67-89d4e5bbd163-1" role="option"
                                        aria-selected="true" className="k-list-item k-selected k-focus"
                                        style="position: unset;"><span className="k-list-item-text">25</span></li>
                                    <li id="option-c0c168b7-8314-47f7-ba67-89d4e5bbd163-2" role="option"
                                        aria-selected="false" className="k-list-item" style="position: unset;"><span
                                        className="k-list-item-text">50</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/}
        </div>
    );
};

export default NotificationContent;