import {ReactNode} from "react";
import {IShipment} from "../../../../../app/interfaces/shipment/IShipment";
import cl from '../FormMap.module.css';

export const buildCurrentTrackingPositionContent = (model: IShipment): ReactNode => {
    return (
        <div>
            <div className={`${cl.pin} ${model.importDirection ? cl.arrowLeft : cl.arrowRight}`}
                 data-type={model.inGeofenceArea ? 'in' : 'out'}>

            </div>
            <div className={cl.pinPulsation}
                 data-type={model.inGeofenceArea ? 'in' : 'out'}>

            </div>
        </div>
    )
}
