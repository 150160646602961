import React, {useContext, useEffect, useRef} from 'react';
import {GridRowContext} from '../../Grid';
import cl from './GridDefaultCellTemplate.module.css';

const GridDefaultCellTemplate = (props: any) => {
    let timeout = useRef<NodeJS.Timeout | null>(null);

    const {
        onRowClick,
        onRowDoubleClick
    } = useContext(GridRowContext);

    useEffect(() => {
        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        }
    }, []);

    const handleClick = () => {
        if (timeout.current !== null) {
            onRowDoubleClick({dataItem: props.dataItem});
            clearTimeout(timeout.current)
            timeout.current = null
        } else {
            timeout.current = setTimeout(() => {
                onRowClick({dataItem: props.dataItem});
                if (timeout.current) {
                    clearTimeout(timeout.current)
                }

                timeout.current = null
            }, 200)
        }
    }

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
                    <span className={cl.cell}>
                        {props.children}
                    </span>
        </td>
    );
};

export default GridDefaultCellTemplate;