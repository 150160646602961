import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    ILogger,
    IRetryPolicy,
    LogLevel
} from "@microsoft/signalr";
import {useEffect, useRef, useState} from "react";
import HubConnector from "../../app/signalR/hubConnector";


export type SignalRHubOptions = {
    enabled?: boolean;
    onConnected?: (hub: HubConnection) => void;
    onDisconnected?: (error?: Error) => void;
    onReconnecting?: (error?: Error) => void;
    onReconnected?: (connectionId?: string) => void;
    onError?: (error?: Error) => void;
    automaticReconnect?: number[] | IRetryPolicy | boolean;
    logging?: LogLevel | string | ILogger;
    singleton?: boolean;
};

const defaultOptions: SignalRHubOptions = {
    enabled: true,
    singleton: false
};

const useSignalRHub = (hubUrl: string, options?: SignalRHubOptions): [HubConnection | null, () => void] => {
    const [signalRHub, setSignalRHub] = useState<HubConnection | null>(null);
    const opts = useRef<SignalRHubOptions>({...defaultOptions, ...options});

    useEffect(() => {
        opts.current = {...defaultOptions, ...options};
    }, [options]);

    useEffect(() => {

        if (signalRHub) {
            return;
        }

        let isCanceled = false;
        let hubConnection: HubConnection | null = null;
        if (opts.current.singleton !== undefined && opts.current.singleton) {
            const hubConnectorInstance = HubConnector.getInstance(hubUrl, opts.current);
            hubConnection = hubConnectorInstance.getConnection();
        } else {
            const hubConnectionBuild = new HubConnectionBuilder()
                .withUrl(hubUrl);


            if (opts.current.automaticReconnect) {
                if (opts.current.automaticReconnect === true)
                    hubConnectionBuild.withAutomaticReconnect();
                else {
                        // @ts-expect-error
                    hubConnectionBuild.withAutomaticReconnect(opts.current.automaticReconnect);
                }
            }

            if (opts.current.logging)
                hubConnectionBuild.configureLogging(opts.current.logging);

            hubConnection = hubConnectionBuild.build();
        }

        if (!hubConnection) {
            return;
        }

        if (hubConnection.state === HubConnectionState.Disconnected) {
            hubConnection.start()
                .then(() => {
                    if (!hubConnection) {
                        return;
                    }

                    if (isCanceled) {
                        return hubConnection.stop();
                    }

                    opts.current.onConnected?.(hubConnection);

                    if (opts.current.onDisconnected)
                        hubConnection.onclose(opts.current.onDisconnected);

                    if (opts.current.onReconnecting)
                        hubConnection.onreconnecting(opts.current.onReconnecting);

                    if (opts.current.onReconnected)
                        hubConnection.onreconnected(opts.current.onReconnected);

                    hubConnection.onclose(() => {
                        connectionClosed();
                    });

                    setSignalRHub(hubConnection);
                })
                .catch((error) => {
                    if (isCanceled)
                        return;

                    opts.current.onError?.(error);
                });
        } else {
            setSignalRHub(hubConnection);
        }

       /* return () => {

            isCanceled = true;

            if ((opts.current.singleton !== undefined && opts.current.singleton) ||
                !hubConnection) {

                console.log("useSignalRHub : return !hubConnection")
                return;
            }

            if (hubConnection.state === HubConnectionState.Connected) {
                console.log("useSignalRHub : return stop")
                hubConnection.stop().then(() => {
                    //ignored
                });
            }
            console.log("useSignalRHub : return")
            setSignalRHub(null);
        };*/
    }, []);

    const connect = () => {

        setSignalRHub(null);

        let hubConnection: HubConnection | null = null;

        const hubConnectorInstance = HubConnector.getInstance(hubUrl, opts.current);
        hubConnection = hubConnectorInstance.getConnection();

        if(hubConnection?.state !== HubConnectionState.Disconnected) {
            setSignalRHub(hubConnection);
            return;
        }

        hubConnection?.start().then(() => {
            if (!hubConnection) {
                return;
            }

            opts.current.onConnected?.(hubConnection);

            if (opts.current.onDisconnected)
                hubConnection.onclose(opts.current.onDisconnected);

            if (opts.current.onReconnecting)
                hubConnection.onreconnecting(opts.current.onReconnecting);

            if (opts.current.onReconnected)
                hubConnection.onreconnected(opts.current.onReconnected);

            hubConnection.onclose(() => {
                connectionClosed();
            });

            setSignalRHub(hubConnection);

        })
            .catch((error) => {
                opts.current.onError?.(error);

                const timeout = setTimeout(() => {
                    connect()
                }, 1000)
            });
    }

    const connectionClosed = () => {
        if (!signalRHub) {
            return;
        }

        const timeout = setTimeout(() => {
            connect()
        }, 1000)

    }

    const closeConnection = () => {
        if (!signalRHub) {
            return;
        }

        signalRHub.stop().then(() => {
            //stopped;
            setSignalRHub(null);
        });
    }


    return [signalRHub, closeConnection];
};

export default useSignalRHub;
