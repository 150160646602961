import React, {useState} from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {useTranslation} from "react-i18next";
import cl from './BorderCrossingTemplate.module.css';
import BorderCrossingDialog from "../../../actions/BorderCrossing/BorderCrossingDialog";
import moment from "moment";
import Icon from "../../../../../../assets/icon/Icon";

const BorderCrossingTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const {t} = useTranslation();
    const [isBorderCrossingDialogShown, setIsBorderCrossingDialogShown] = useState<boolean>(false);

    let sId = props.dataItem.sId !== undefined && props.dataItem.sId !== null
        ? props.dataItem.sId
        : '';
    let notes = props.dataItem["bcNotes"] !== undefined && props.dataItem["bcNotes"] !== null
        ? props.dataItem["bcNotes"]
        : '';
    let rId = props.dataItem.rId !== undefined && props.dataItem.rId !== null
        ? props.dataItem.rId
        : '';
    let isArchive = props.dataItem.isArchive !== null && props.dataItem.isArchive !== undefined
        ? Boolean(props.dataItem.isArchive)
        : false;
    if (isArchive || !sId || sId === '') {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={'default-left-cell default-overflow-cell'}>
                    {notes}
                </span>
            </td>
        )
    }

    return (
        <td {...props.tdProps}
            colSpan={1}>
            <BorderCrossingDialog id={rId}
                                  sId={sId}
                                  show={isBorderCrossingDialogShown}
                                  setShow={setIsBorderCrossingDialogShown}/>
            <span onClick={handleClick}  className={'default-left-cell default-overflow-cell grid-prevent-selection'}>
                <>

                <Icon icon={"faPen"} className={`${cl.icon} ${notes && notes !== '' ? cl.iconWithValue : ''} grid-prevent-selection shipment-grid-edit-bc-btn`}
                                 onClick={(ev) => {
                                     ev.stopPropagation();

                                     setIsBorderCrossingDialogShown(prev => !prev);
                                 }}/>
                    {notes && notes !== ''
                        ? <span className={`grid-prevent-selection ${cl.text}`}
                                title={`${moment.utc(props.dataItem["bcNotesDate"], 'DD.MM.YY HH:mm').local().format('DD.MM.YYYY HH:mm')} / ${props.dataItem["bcNotesUser"]}`}
                                onClick={(ev) => {
                                    ev.stopPropagation();

                                    setIsBorderCrossingDialogShown(prev => !prev);
                                }}>
                        {notes}
                    </span>
                        : <span className={`grid-prevent-selection ${cl.addText}`}
                                onClick={(ev) => {
                                    ev.stopPropagation();

                                    setIsBorderCrossingDialogShown(prev => !prev);
                                }}>
                        {t("shipment.grid.add-border-crossing")}
                    </span>
                    }
                </>
            </span>
        </td>
    );
};

export default BorderCrossingTemplate;
