import React from 'react';
import {FileUploadProps, UploadFileStatus} from "./types";
import useFileUpload from "./hooks/useFileUpload";
import cl from './FileUpload.module.css';
import {useTranslation} from "react-i18next";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faFile, faFilePdf, faFileWord, faImage, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getFileUploaderSizeLabel} from "./utils";

const FileUpload: React.FC<FileUploadProps> = (props) => {
    const {t} = useTranslation();

    const {
        ref,
        isDragActive,
        files,

        onClear,
        onInputChange,
        onBrowseClick,
        onDrop,
        onDragLeave,
        onDragOver
    } = useFileUpload({
        disabled: props.disabled,
        /*multiple: props.multiple,*/
        restrictions: props.restrictions,
        onChange: props.onChange
    });

    return (
        <div className={`${cl.uploader} ${isDragActive ? cl.drActive : ''}`}>
            <div className={cl.selector}
                 onDrop={onDrop}
                 onDragLeave={onDragLeave}
                 onDragOver={onDragOver}>
                <div>
                    <button className={cl.button}
                            disabled={props.disabled}
                            onClick={onBrowseClick}
                            type={'button'}>
                        {props.selectButtonText
                            ? <>{props.selectButtonText}</>
                            : <>{t("file-uploader.select")}</>
                        }
                    </button>

                    <input id={`input-${props.id}`}
                           disabled={props.disabled}
                           ref={ref}
                           type={'file'}
                           /*multiple={props.multiple ?? false}*/
                           className={cl.dUploader}
                           onChange={onInputChange}
                    />
                </div>

                <div className={cl.dropHint}>
                    {t("file-uploader.drop-files-label")}
                </div>
            </div>

            {files.length > 0 &&
                <div className={cl.files}>
                    {files.map(file => {
                        let fileIcon: IconProp = faFile;

                        switch (file.extension) {
                            case ".jpg":
                            case ".jpeg":
                            case ".png":
                                fileIcon = faImage;
                                break;
                            case ".doc":
                            case ".docx":
                                fileIcon = faFileWord;
                                break;
                            case ".pdf":
                                fileIcon = faFilePdf;
                                break;
                        }

                        return (
                            <div key={file.id} className={cl.file}>
                                <div>
                                    <FontAwesomeIcon icon={fileIcon}/>
                                </div>

                                <div>
                                    <div className={cl.fileName}
                                         title={file.name}>
                                        {file.name}
                                    </div>

                                    {(file.status === UploadFileStatus.Uploading || file.status === UploadFileStatus.Initial) &&
                                        <div className={cl.size}>
                                            Loading...
                                        </div>
                                    }

                                    {(file.status === UploadFileStatus.RestrictionFailed || file.status === UploadFileStatus.UploadingFailed) && file.errors.length > 0
                                        ? <div className={cl.error}
                                               title={file.errors.map(err => t(err)).join(' / ')}>
                                            {file.errors.map(err => t(err)).join(' / ')}
                                        </div>
                                        : null
                                    }

                                    {file.status === UploadFileStatus.Uploaded && file.errors.length <= 0 &&
                                        <div className={cl.size}>
                                            {getFileUploaderSizeLabel(file.size)}
                                        </div>
                                    }
                                </div>

                                <div title={t("file-uploader.delete")}>
                                    <div className={props.disabled ? cl.clearDisabled : ''}
                                         onClick={() => {
                                             if (props.disabled) {
                                                 return;
                                             }

                                             onClear(file.id);
                                         }}>
                                        <FontAwesomeIcon icon={faXmark}/>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            }
        </div>
    );
};

export default FileUpload;