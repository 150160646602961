import React, {useState} from 'react';
import {IOffer} from "../../../../../../../../app/interfaces/offer/IOffer";
import {useTranslation} from "react-i18next";
import ClientStatusDialog from "../../../../../actions/ClientStatus/ClientStatusDialog";
import cl from "../../../../../grid/templates/CustomerTemplate/CustomerTemplate.module.css";
import Icon from "../../../../../../../../assets/icon/Icon";

type OfferCustomerProps = {
    offer: IOffer;
}

const OfferCustomer: React.FC<OfferCustomerProps> = ({
                                                         offer
                                                     }) => {
    const {t} = useTranslation();
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);

    if (!offer.customer) {
        return (
            <div className={'details-box-item-field-value'}>

            </div>
        );
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
            <ClientStatusDialog id={offer.orderReleaseGid}
                                show={isDialogShown}
                                setShow={setIsDialogShown}/>

            <Icon icon={"faCircleQuestion"}
                  onClick={() => {
                      setIsDialogShown(prev => !prev);
                  }}
                  className={`${cl.icon} transition-all-500`}
                  title={t("shared.btn.show-status")}/>

            <div className={'details-box-item-field-value'}>
                {offer.customer}
            </div>
        </div>
    );
};

export default OfferCustomer;
