import React, {useEffect, useState} from 'react';
import FormLayout from "../../../../components/uiLayouts/FormLayout/FormLayout";
import {useShipmentApi} from "../../../../app/api/shipment";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../app/enums/ResponseResultCode";
import {IShipment} from "../../../../app/interfaces/shipment/IShipment";
import {AxiosError} from "axios";
import Alert from "../../../../components/ui/Alert/Alert";
import {useTranslation} from "react-i18next";
import {
    FormLayoutToolbarActionItem
} from "../../../../components/uiLayouts/FormLayout/components/FormLayoutToolbar/FormLayoutToolbar";
import {LayoutConfigEntity} from "../../../../app/enums/LayoutConfigEntity";
import {useAppSelector} from "../../../../app/store";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSliceSelectors";
import {IUser} from "../../../../app/interfaces/user/IUser";
import {checkUserInRole, getUserRole} from "../../../../helpers/user";
import {UserRole} from "../../../../app/enums/UserRole";
import {ButtonType} from "../../../../components/ui/Button/Button";
import DetailsSection from "./sections/DetailsSection/DetailsSection";
import SelfBillingSection from "./sections/SelfBillingSection/SelfBillingSection";
import PaymentStatusSection from "./sections/PaymentStatusSection/PaymentStatusSection";
import OperationsSection from "./sections/OperationsSection/OperationsSection";
import AssignShipmentDialog from "../actions/AssignShipment/AssignShipmentDialog";
import ShipmentsEventListDialog from "../actions/ShipmentsEventList/ShipmentsEventListDialog";
import CopyOrDialog from "../actions/CopyOr/CopyOrDialog";
import SPUnblockDialog from "../actions/SPUnblock/SPUnblockDialog";
import DeleteShipmentDialog from "../actions/DeleteShipment/DeleteShipmentDialog";
import CancelShipmentDialog from "../actions/CancelShipment/CancelShipmentDialog";
import ConfirmShipmentDialog from "../actions/ConfirmShipment/ConfirmShipmentDialog";
import UnConfirmShipmentDialog from "../actions/UnConfirmShipment/UnConfirmShipmentDialog";
import EditTransportDialog from "../actions/EditTransport/EditTransportDialog";
import LocationMapDialog from "../actions/LocationMap/LocationMapDialog";
import {
    ApplicationHubMessageAction,
    IApplicationHubMessage
} from "../../../../app/signalR/applicationHub/IApplicationHubMessage";
import {getShipmentFormTab, isShipmentFormTabsShown} from "./utils";
import ShipmentFormTabs from "./components/ShipmentFormTabs/ShipmentFormTabs";
import {useSearchParams} from "react-router-dom";
import {CustomScroll} from "react-custom-scroll";
import ShowTimeSlotDialog from "../actions/ShowTimeSlot/ShowTimeSlotDialog";

type ShipmentFormProps = {
    id: string | null;
    sId: string | null;
    close: () => void;
    isFullPageDetails: boolean;
}

const ShipmentForm: React.FC<ShipmentFormProps> = ({
                                                       id,
                                                       sId,
                                                       close,
                                                       isFullPageDetails
                                                   }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isAddEventDialogShown, setIsAddEventDialogShown] = useState<boolean>(false);
    const [isAssignOrderDialogOpened, setIsAssignOrderDialogOpened] = useState<boolean>(false);
    const [isCopyOrDialogOpened, setIsCopyOrDialogOpened] = useState<boolean>(false);
    const [isShowTimeSlotDialogOpened, setIsShowTimeSlotDialogOpened] = useState<boolean>(false);
    const [isSPUnblockDialogOpened, setIsSPUnblockDialogOpened] = useState<boolean>(false);
    const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState<boolean>(false);
    const [isCancelDialogOpened, setIsCancelDialogOpened] = useState<boolean>(false);
    const [isConfirmDialogOpened, setIsConfirmDialogOpened] = useState<boolean>(false);
    const [isUnConfirmDialogOpened, setIsUnConfirmDialogOpened] = useState<boolean>(false);
    const [isEditTransportDialogOpened, setIsEditTransportDialogOpened] = useState<boolean>(false);
    const [isLocationMapDialogOpened, setIsLocationMapDialogOpened] = useState<boolean>(false);

    const [disabled] = useState<boolean>(false);

    const [tab, setTab] = useState<string | null>(null);
    const user = useAppSelector(selectCurrentUser);
    const {t} = useTranslation();
    const [data, setData] = useState<IShipment | null>(null);
    const [actions, setActions] = useState<Array<FormLayoutToolbarActionItem | 'separator'>>([]);
    const {getShipment: {query, isLoading}} = useShipmentApi();

    useEffect(() => {
        if (!user || !data) {
            return;
        }

        setActions(buildActionsList(user));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, data, t]);

    useEffect(() => {
        if (!id && !sId) {
            return;
        }

        (async () => {
            await getOrderData(id ? id : '', sId ? sId : '')
        })();


        return () => {
            setData(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, sId]);


    const getOrderData = async (id: string, sid: string) => {
        if (!user) {
            return;
        }

        try {
            const data = await query(id, sid);

            if (data !== undefined &&
                data.status === 200 &&
                data.data !== undefined &&
                data.data.resultCode === ResponseResultCode.Ok) {

                if (data.data.data) {
                    if (!tab) {
                        const tab = getShipmentFormTab(data.data.data, searchParams.get('t'), user);

                        setTab(tab);

                        if (searchParams.has('t')) {
                            searchParams.delete('t');
                        }

                        searchParams.append('t', tab);

                        setSearchParams(searchParams);
                    }
                }

                setData(data.data.data ?? null);
            }
        } catch (e) {
            if (e instanceof AxiosError && e.response !== undefined && e.response.data !== undefined &&
                e.response.data.resultCode !== ResponseResultCode.NotFound) {
                toast.error(e.message);
            }
        }
    };

    const onShipmentUpdated = async (ev: any) => {
        let typedEv = ev.detail as IApplicationHubMessage;

        if (typedEv.action === ApplicationHubMessageAction.Update && typedEv.payload && typedEv.payload !== ''
            && typedEv.payload == sId) {
            await getOrderData(id ?? "", sId)
        }
    }

    useEffect(() => {
        document.addEventListener('onShipmentApplicationHubMessage', onShipmentUpdated);

        return () => {
            document.removeEventListener('onShipmentApplicationHubMessage', onShipmentUpdated);
        }
    }, []);

    const buildActionsList = (usr: IUser): Array<FormLayoutToolbarActionItem | 'separator'> => {
        if (!data) {
            return [];
        }

        const role = getUserRole(usr);
        let arr: Array<FormLayoutToolbarActionItem | 'separator'> = [];

        if (role !== UserRole.Client && data.hasShipment) {

            if (!data.isArchive) {
                arr.push({
                    disabled: false,
                    id: 'edit-transport',
                    title: t("shared.btn.edit-transport"),
                    onClick: () => {
                        setIsEditTransportDialogOpened(prev => !prev)
                    },
                    pinnedButtonType: ButtonType.Secondary
                });
            }
            if (!data.isUnConfirmed && (!checkUserInRole(UserRole.ServiceProvider, user) || (checkUserInRole(UserRole.ServiceProvider, user) && !data.isCompleted))) {
                arr.push({
                    disabled: false,
                    id: 'new-event',
                    title: t("shared.btn.new-event"),
                    onClick: () => setIsAddEventDialogShown(prev => !prev),
                    pinnedButtonType: ButtonType.Secondary
                });
            }
            if ((!checkUserInRole(UserRole.ServiceProvider, user) || (checkUserInRole(UserRole.ServiceProvider, user) && !data.isCompleted))) {
                arr.push({
                    disabled: false,
                    id: 'position',
                    title: t("shared.btn.position"),
                    onClick: () => setIsLocationMapDialogOpened(prev => !prev),
                    pinnedButtonType: ButtonType.Secondary
                });
            }
        }

        if (!data.isCompleted && role === UserRole.Client && data.hasShipment) {
            arr.push({
                disabled: false,
                id: 'new-position',
                title: t("shared.btn.new-position"),
                onClick: () => console.log('new position clicked'),
                pinnedButtonType: ButtonType.Secondary
            });
        }

        if (role === UserRole.Administrator || role === UserRole.Forwarder) {
            if (data.hasShipment) {
                if (data.isUnConfirmed) {
                    arr.push({
                        disabled: false,
                        id: 'confirm-shipment',
                        title: t("shared.btn.confirm-shipment"),
                        onClick: () => setIsConfirmDialogOpened(prev => !prev),
                        pinnedButtonType: ButtonType.Secondary
                    });
                } else {
                    arr.push({
                        disabled: false,
                        id: 'un-confirm-shipment',
                        title: t("shared.btn.un-confirm-shipment"),
                        color: 'red',
                        onClick: () => setIsUnConfirmDialogOpened(prev => !prev),
                        pinnedButtonType: ButtonType.Warning
                    });
                }
            }

            if (!data.hasShipment) {
                if (data.suppliers !== null && data.suppliers.length > 0) {
                    arr.push({
                        disabled: false,
                        id: 'cancel-confirmation',
                        title: t("shared.btn.cancel-confirmation"),
                        color: 'red',
                        onClick: () => console.log('Cancel confirmation clicked'),
                        pinnedButtonType: ButtonType.Warning
                    });
                }

                arr.push({
                    disabled: false,
                    id: 'cancel-offer',
                    title: t("shared.btn.cancel-offer"),
                    color: 'red',
                    onClick: () => console.log('Cancel offer clicked'),
                    pinnedButtonType: ButtonType.Warning
                })
            } else {


                arr.push({
                    disabled: false,
                    id: 'assign-order',
                    title: t("shared.btn.assign-order"),
                    onClick: () => setIsAssignOrderDialogOpened(prev => !prev),
                    pinnedButtonType: ButtonType.Secondary
                });
                if (!data.isCompleted) {
                    arr.push({
                        disabled: false,
                        id: 'cancel-order',
                        title: t("shared.btn.cancel-order"),
                        color: 'red',
                        onClick: () => setIsCancelDialogOpened(prev => !prev),
                        pinnedButtonType: ButtonType.Warning
                    });
                }
            }

            arr.push({
                disabled: false,
                id: 'copy-or',
                title: t("shared.btn.copy-or"),
                onClick: () => setIsCopyOrDialogOpened(prev => !prev),
                pinnedButtonType: ButtonType.Secondary
            });

            if (data.hasShipment && data.hasBookingData) {
                arr.push({
                    disabled: false,
                    id: 'show-time-slot',
                    title: t("shared.btn.show-time-slot"),
                    onClick: () => setIsShowTimeSlotDialogOpened(prev => !prev),
                    pinnedButtonType: ButtonType.Secondary
                });
            }
        }

        if (role === UserRole.Administrator || (role === UserRole.Forwarder && data.isUnConfirmed)) {
            arr.push({
                disabled: false,
                id: 'delete',
                title: t("shared.btn.delete"),
                color: 'red',
                onClick: () => setIsDeleteDialogOpened(prev => !prev),
                pinnedButtonType: ButtonType.Warning
            });
        }

        if (role === UserRole.Administrator || role === UserRole.Forwarder) {
            arr.push({
                disabled: false,
                id: 'sp-unblock',
                title: t("shared.btn.sp-unblock"),
                wraped: true,
                onClick: () => setIsSPUnblockDialogOpened(prev => !prev),
                pinnedButtonType: ButtonType.Secondary
            });
        }


        return arr;
    }

    return (
        <>
            {(sId || id) && data &&
                <>
                    <DeleteShipmentDialog id={data?.orderReleaseGid ? data?.orderReleaseGid : ''}
                                          sId={sId ? sId : ''}
                                          show={isDeleteDialogOpened}
                                          setShow={setIsDeleteDialogOpened}/>

                    <CancelShipmentDialog id={[`${sId}`]}
                                          show={isCancelDialogOpened}
                                          setShow={setIsCancelDialogOpened}/>

                    <EditTransportDialog id={data?.orderReleaseGid ? data?.orderReleaseGid : ''}
                                         sId={sId ? sId : ''}
                                         show={isEditTransportDialogOpened}
                                         setShow={setIsEditTransportDialogOpened}/>

                    <LocationMapDialog shipmentGid={sId ? sId : ""}
                                       show={isLocationMapDialogOpened}
                                       setShow={setIsLocationMapDialogOpened}/>

                    <ConfirmShipmentDialog id={[sId ? sId : ""]}
                                           show={isConfirmDialogOpened}
                                           setShow={setIsConfirmDialogOpened}/>

                    <UnConfirmShipmentDialog id={[sId ? sId : ""]}
                                             show={isUnConfirmDialogOpened}
                                             setShow={setIsUnConfirmDialogOpened}/>

                    <ShipmentsEventListDialog id={sId ? sId : ""}
                                              user={user}
                                              show={isAddEventDialogShown}
                                              setShow={setIsAddEventDialogShown}/>

                    <AssignShipmentDialog id={[sId ? sId : ""]}
                                          show={isAssignOrderDialogOpened}
                                          setShow={setIsAssignOrderDialogOpened}/>

                    <CopyOrDialog id={data?.orderReleaseGid ? data?.orderReleaseGid : ''}
                                  show={isCopyOrDialogOpened}
                                  setShow={setIsCopyOrDialogOpened}/>
                    <ShowTimeSlotDialog id={data?.shipmentGid ? data?.shipmentGid : ''}
                                        show={isShowTimeSlotDialogOpened}
                                        setShow={setIsShowTimeSlotDialogOpened}/>
                    <SPUnblockDialog id={sId ?? ""}
                                     show={isSPUnblockDialogOpened}
                                     setShow={setIsSPUnblockDialogOpened}/>


                </>
            }

            <FormLayout id={'shipment-details'}
                        isLoading={isLoading || !tab}
                        showCloseIcon={isFullPageDetails}
                        close={close}
                        entity={LayoutConfigEntity.Shipment}
                        actions={actions}>
                {!data && <Alert type={'Error'}>{t("shipment.errors.not-found")}</Alert>}
                {data && user && tab &&
                    <>
                        {isShipmentFormTabsShown(data, user) &&
                            <div className={'form-layout-default-tab-container'}>
                                <ShipmentFormTabs shipment={data}
                                                  tab={tab}
                                                  disabled={disabled}
                                                  onTabChange={(tab: string) => {
                                                      setTab(tab);

                                                      searchParams.set('t', tab);

                                                      setSearchParams(searchParams);
                                                  }}/>
                            </div>
                        }
                        <div className={`${isFullPageDetails ? 'full' : 'side'} details`}
                             style={isShipmentFormTabsShown(data, user) ? {height: 'calc(100% - 60px)'} : {height: '100%'}}>
                            <CustomScroll heightRelativeToParent={'100%'}>
                                {tab === '1' &&
                                    <DetailsSection shipment={data}
                                                    isSidePanelDetails={!isFullPageDetails}
                                                    id={id ?? ''}
                                                    sId={sId ?? ''}/>
                                }

                                {/*{data.isCmrAllowedForSp &&*/ tab === '2' &&
                                    <SelfBillingSection shipment={data}
                                                        isSidePanelDetails={!isFullPageDetails}/>
                                }

                                {tab === '3' &&
                                    <OperationsSection/>
                                }

                                {tab === '4' && data.hasInvoices &&
                                    <PaymentStatusSection shipment={data}/>
                                }
                            </CustomScroll>
                        </div>
                    </>
                }
            </FormLayout>
        </>
    );
};

export default ShipmentForm;
