import React from 'react';
import {IChangeRefNumberGetAction} from "../../../../../../app/interfaces/shipment/IChangeRefNumberGetAction";
import {IChangeRefNumberPostAction} from "../../../../../../app/interfaces/shipment/IChangeRefNumberPostAction";
import {FormikHelpers, useFormik} from "formik";
import Input from "../../../../../../components/ui/Input/Input";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";

type ChangeRefNumFormProps = {
    model: IChangeRefNumberGetAction;
    disabled: boolean;
    save: (model: IChangeRefNumberPostAction) => Promise<void>;
}

const inputStyle: React.CSSProperties = {
    textTransform: 'uppercase'
}

const ChangeRefNumForm: React.FC<ChangeRefNumFormProps> = ({
                                                               model,
                                                               save,
                                                               disabled
                                                           }) => {
    const {t} = useTranslation();

    const form = useFormik<IChangeRefNumberPostAction>({
        initialValues: {
            orderReleaseGid: model.orderReleaseGid,
            customerOrderRefNum: model.customerOrderRefNum ?? '',
            loadingNumber: model.loadingNumber ?? '',
            loadingNumberSeq: model.loadingNumberSeq,
            editLoadId: model.editLoadId ?? '',
            editNumber: model.editNumber ?? ''
        },
        validationSchema: Yup.object().shape({
            customerOrderRefNum: Yup.string().test(
                'customer-order-ref-num',
                t("shared.errors.required-error").replace('{0}', t("shipment.grid.ref-num-label")),
                function (value) {
                    return !(model.isCustomerOrderRefNumRequired && (value === undefined || value === null || value.trim() === ''));
                }
            ),
            loadingNumber: Yup.string().test(
                'loading-number',
                t("shared.errors.required-error").replace('{0}', t("shipment.grid.loading-number-label")),
                function (value) {
                    return !(model.isLoadingNumberRequired && (value === undefined || value === null || value.trim() === ''));
                }
            ),
            editNumber: Yup.string().test(
                'edit-number',
                t("shared.errors.required-error").replace('{0}', t("shipment.grid.edit-number-label")),
                function (value) {
                    return !(model.isEditNumberAllowed && model.isEditNumberRequired && (value === undefined || value === null || value.trim() === ''));
                }
            ),
            editLoadId: Yup.string().test(
                'edit-load-id-number',
                t("shared.errors.required-error").replace('{0}', t("shipment.grid.edit-load-label")),
                function (value) {
                    return !(model.isEditLoadIdAllowed && model.isEditLoadIdRequired && (value === undefined || value === null || value.trim() === ''));
                }
            )
        }),
        onSubmit: async (values: IChangeRefNumberPostAction, actions: FormikHelpers<IChangeRefNumberPostAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='change-ref-number-form'
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div className={'mgb-20'}>
                    <label className={`view-label change-ref-num-customer-order-ref-num-label ${model.isCustomerOrderRefNumRequired ? 'required' : ''}`}>
                        {t("shipment.grid.ref-num-label")}
                    </label>
                    <Input placeholder={t("shipment.grid.ref-num-label")}
                           id={'customerOrderRefNum'}
                           name={'customerOrderRefNum'}
                           disabled={disabled}
                           value={form.values.customerOrderRefNum ?? ''}
                           autoComplete={'off'}
                           onChange={form.handleChange}
                           className={'change-ref-num-customer-order-ref-num-value'}
                           style={inputStyle}/>
                    {form.errors.customerOrderRefNum &&
                        <div className={'error-label'}>
                            {form.errors.customerOrderRefNum}
                        </div>
                    }
                </div>
                <div className={'mgb-20'}>
                    <label className={`view-label change-ref-num-loading-number-label ${model.isLoadingNumberRequired ? 'required' : ''}`}>
                        {t("shipment.grid.loading-number-label")}
                    </label>
                    <Input placeholder={t("shipment.grid.loading-number-label")}
                           id={'loadingNumber'}
                           name={'loadingNumber'}
                           disabled={disabled}
                           value={form.values.loadingNumber ?? ''}
                           autoComplete={'off'}
                           onChange={form.handleChange}
                           className={'change-ref-num-loading-number-value'}
                           style={inputStyle}/>
                    {form.errors.loadingNumber &&
                        <div className={'error-label'}>
                            {form.errors.loadingNumber}
                        </div>
                    }
                </div>
                <div className={'mgb-20'}>
                    <label className={`view-label change-ref-num-edit-number-label ${model.isEditNumberRequired ? 'required' : ''}`}>
                        {t("shipment.grid.edit-number-label")}
                    </label>
                    <Input placeholder={t("shipment.grid.edit-number-label")}
                           id={'editNumber'}
                           name={'editNumber'}
                           disabled={disabled || !model.isEditNumberAllowed}
                           value={form.values.editNumber ?? ''}
                           autoComplete={'off'}
                           onChange={form.handleChange}
                           className={'change-ref-num-edit-number-value'}
                           style={inputStyle}/>
                    {form.errors.editNumber &&
                        <div className={'error-label'}>
                            {form.errors.editNumber}
                        </div>
                    }
                </div>
                <div className={'mgb-20'}>
                    <label className={`view-label change-ref-num-edit-load-label ${model.isEditLoadIdRequired ? 'required' : ''}`}>
                        {t("shipment.grid.edit-load-label")}
                    </label>
                    <Input placeholder={t("shipment.grid.edit-load-label")}
                           id={'editLoadId'}
                           name={'editLoadId'}
                           disabled={disabled || !model.isEditLoadIdAllowed}
                           value={form.values.editLoadId ?? ''}
                           autoComplete={'off'}
                           onChange={form.handleChange}
                           className={'change-ref-num-edit-load-label'}
                           style={inputStyle}/>
                    {form.errors.editLoadId &&
                        <div className={'error-label'}>
                            {form.errors.editLoadId}
                        </div>
                    }
                </div>
            </div>
        </form>
    );
};
export default ChangeRefNumForm;