import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import ChangePlannedDate from "./ChangePlannedDate";
import {IUser} from "../../../../../app/interfaces/user/IUser";

type ChangePlannedDateDialogProps = {
    id: string;
    sId: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    user?: IUser;
}

const ChangePlannedDateDialog: React.FC<ChangePlannedDateDialogProps> = ({
                                                                             id,
                                                                             sId,
                                                                             show,
                                                                             setShow,
                                                                             user
                                                                         }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'change-date-event-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shipment.grid.changing-planned-date")}>
            <ChangePlannedDate id={id}
                               user={user}
                               sId={sId}
                               close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default ChangePlannedDateDialog;