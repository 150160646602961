import React from 'react';
import {IQuote} from "../../../../../../app/interfaces/quote/IQuote";
import QCostField from "../fields/QCostField/QCostField";
import TotalWeight from "../../../../../../components/shared/form/detailsFields/TotalWeight/TotalWeight";
import THUVolume from "../../../../../../components/shared/form/detailsFields/THUVolume/THUVolume";
import HighValueField from "../fields/HighValueField/HighValueField";
import AdrField from "../fields/ADRField/ADRField";
import LiquidityField from '../fields/LiquidityField/LiquidityField';
import RefField from "../fields/RefField/RefField";
import useMediaQuery from "../../../../../../hooks/useMediaQuery/useMediaQuery";

type QuoteCargoProps = {
    quote: IQuote;
}

const QuoteCargo: React.FC<QuoteCargoProps> = ({
                                                   quote
                                               }) => {
    const isMaxWidth600 = useMediaQuery('(max-width:600px)');

    return (
        <div className={'details-box-default-container'}>
            <div className={'details-box-default-container-columns'}
                 data-count={isMaxWidth600 ? '1' : '2'}>
                {!isMaxWidth600 &&
                    <>
                        <div>
                            <div className={'details-box-default-container-item'}>
                                <QCostField quote={quote}/>
                            </div>

                            <div className={'details-box-default-container-item'}>
                                <TotalWeight weight={quote.totalWeight}/>
                            </div>

                            <div className={'details-box-default-container-item'}>
                                <THUVolume thuVolume={quote.thuVolume}/>
                            </div>
                        </div>
                        <div>
                            {quote.isHighValue &&
                                <div className={'details-box-default-container-item'}>
                                    <HighValueField quote={quote}/>
                                </div>
                            }
                            {quote.adr.length > 0 &&
                                <div className={'details-box-default-container-item'}>
                                    <AdrField quote={quote}/>
                                </div>
                            }
                            {quote.isLiquidity &&
                                <div className={'details-box-default-container-item'}>
                                    <LiquidityField quote={quote}/>
                                </div>
                            }
                            {quote.isRef &&
                                <div className={'details-box-default-container-item'}>
                                    <RefField quote={quote}/>
                                </div>
                            }
                        </div>
                    </>
                }

                {isMaxWidth600 &&
                    <div>
                        <div className={'details-box-default-container-item'}>
                            <QCostField quote={quote}/>
                        </div>

                        <div className={'details-box-default-container-item'}>
                            <TotalWeight weight={quote.totalWeight}/>
                        </div>

                        <div className={'details-box-default-container-item'}>
                            <THUVolume thuVolume={quote.thuVolume}/>
                        </div>

                        {quote.isHighValue &&
                            <div className={'details-box-default-container-item'}>
                                <HighValueField quote={quote}/>
                            </div>
                        }
                        {quote.adr.length > 0 &&
                            <div className={'details-box-default-container-item'}>
                                <AdrField quote={quote}/>
                            </div>
                        }
                        {quote.isLiquidity &&
                            <div className={'details-box-default-container-item'}>
                                <LiquidityField quote={quote}/>
                            </div>
                        }
                        {quote.isRef &&
                            <div className={'details-box-default-container-item'}>
                                <RefField quote={quote}/>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default QuoteCargo;