import React, {useState} from 'react';
import cl from './NotesTemplate.module.css';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {useTranslation} from "react-i18next";
import ChangeNoteDialog from "../../../actions/ChangeNote/ChangeNoteDialog";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment/moment";
import Icon from "../../../../../../assets/icon/Icon";

const NotesTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const {t} = useTranslation();
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);

    let id = props.dataItem.id !== undefined && props.dataItem.id !== null
        ? props.dataItem.id
        : '';
    let notes = props.dataItem.notes !== undefined && props.dataItem.notes !== null
        ? props.dataItem.notes
        : '';

    let notesUser = props.dataItem.notesUser !== undefined && props.dataItem.notesUser !== null
        ? props.dataItem.notesUser
        : '';
    let notesDate = props.dataItem.notesDate !== undefined && props.dataItem.notesDate !== null
        ? moment.utc(props.dataItem.notesDate, 'DD.MM.YY HH:mm').local().format('DD.MM.YYYY HH:mm')
        : '';

    if (!id || id === '') {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={'default-left-cell default-overflow-cell'}>
                    {notes}
                </span>
            </td>
        )
    }

    return (
        <td {...props.tdProps}
            colSpan={1}>
            <ChangeNoteDialog id={id}
                              show={isDialogShown}
                              setShow={setIsDialogShown} />
            <span onClick={handleClick} className={'default-left-cell default-overflow-cell grid-prevent-selection'}>
                <>
                 <Icon icon={"faPen"} className={`${cl.icon} ${notes && notes !== '' ? cl.iconWithValue : ''} offer-grid-edit-${props.field}-btn grid-prevent-selection`}
                       onClick={(ev) => {
                           ev.stopPropagation();

                           setIsDialogShown(prev => !prev);
                       }}/>

                    {notes && notes !== ''
                        ? <span className={`grid-prevent-selection ${cl.text}`} title={notesUser + " " + notesDate}
                                onClick={(ev) => {
                                    ev.stopPropagation();

                                    setIsDialogShown(prev => !prev);
                                }}>
                        {notes}
                    </span>
                        : <span className={`grid-prevent-selection ${cl.addText}`}
                                onClick={(ev) => {
                                    ev.stopPropagation();

                                    setIsDialogShown(prev => !prev);
                                }}>
                        {t("shipment.grid.add-note")}
                    </span>
                    }
                </>
            </span>
        </td>
    );
};

export default NotesTemplate;
