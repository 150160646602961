import React from 'react';
import {useTranslation} from "react-i18next";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import Input from "../../../../ui/Input/Input";
import Button, {ButtonType} from "../../../../ui/Button/Button";

type GridFilterPanelLayoutSaveFormProps = {
    onSave: (name: string) => void;
    close: () => void;
}

const GridFilterPanelLayoutSaveForm: React.FC<GridFilterPanelLayoutSaveFormProps> = ({
                                                                                         onSave,
                                                                                         close
                                                                                     }) => {
    const {t} = useTranslation();

    const form = useFormik<{
        name: string | null
    }>({
        initialValues: {
            name: null
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().nullable()
                .required(t("shared.errors.required-error").replace('{0}', t("shared.labels.name")))
        }),
        onSubmit: async (values: {
            name: string | null
        }, actions: FormikHelpers<{
            name: string | null
        }>) => {
            actions.setSubmitting(true);

            onSave(values.name ?? '');

            close();

            actions.setSubmitting(false);
        }
    });

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                <form id='add-shipment-filter-form'
                      onSubmit={form.handleSubmit}>
                    <div className={'pd-10'}>
                        <label className={'view-label required'}>{t("shared.labels.name")}</label>
                        <Input autoComplete={'off'}
                               disabled={false}
                               name={'name'}
                               id={'name'}
                               placeholder={t("shared.labels.name")}
                               value={form.values.name ?? ''}
                               onChange={form.handleChange}/>
                        {form.errors.name &&
                            <div className={'error-label'}>
                                {form.errors.name}
                            </div>
                        }
                    </div>
                </form>
            </div>
            <div className={'dialog-default-buttons-container'}>
                <Button buttonType={ButtonType.Transparent}
                        className={'close-button'}
                        onClick={close}>
                    {t("shared.btn.cancel")}
                </Button>
                <Button buttonType={ButtonType.Primary}
                        form='add-shipment-filter-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}>
                    {t("shared.btn.save")}
                </Button>
            </div>
        </div>
    );
};

export default GridFilterPanelLayoutSaveForm;