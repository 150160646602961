import {ToolkitStore} from "@reduxjs/toolkit/dist/configureStore";
import {AxiosError, AxiosResponse, InternalAxiosRequestConfig} from "axios";
import instance from "../apiAxios";
import {RootState} from "../../store/store";
import {setLogoutMessage, setUtcOffset} from "../../../features/application/applicationSlice";
import {getTimezoneOffsetInSeconds} from "../../../helpers/date";
import {IResponse, isResponse} from "../../interfaces/response/IResponse";
import {IUserDevice} from "../../interfaces/user/IUserDevice";
import {ResponseResultCode} from "../../enums/ResponseResultCode";
import {load} from "../../../utils/fingerprint/agent";
import {setAuthenticationState} from "../../../features/authentication/authenticationSlice";
import {AuthenticationStatus} from "../../../features/authentication/IAuthenticationState";
import HubConnector from "../../signalR/hubConnector";

const setupAxiosInterceptors = (store: ToolkitStore): void => {
    const {dispatch} = store;

    instance.interceptors.request.use(
        (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
            const state: RootState = store.getState();

            if (!config.headers.has('offset')) {
                if (state.application.utcOffset === undefined) {
                    const offset: number = getTimezoneOffsetInSeconds();

                    dispatch(setUtcOffset(offset));
                    config.headers.set('offset', offset)
                } else {
                    config.headers.set('offset', state.application.utcOffset);
                }
            }

            /* Used for anonymous user authentication confirmation */
            if (state.authentication.anonymousUserCode && state.authentication.anonymousUserCode !== '' &&
                state.authentication.anonymousUserId && state.authentication.anonymousUserId !== '' &&
                !config.headers.has('X-A-ASSTRA-CODE') && !config.headers.has('X-A-ASSTRA-ID')) {
                config.headers.set('X-A-ASSTRA-CODE', state.authentication.anonymousUserCode);
                config.headers.set('X-A-ASSTRA-ID', state.authentication.anonymousUserId);
            }

            return config;
        },
        (error: AxiosError): Promise<AxiosError> => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (res: AxiosResponse): AxiosResponse => res,
        async (error: AxiosError | Error): Promise<AxiosError> => {
            if (!(error instanceof AxiosError)) {
                return Promise.reject(error);
            }

            const {config} = error;

            if (!config || config._retry || error?.response?.status !== 401) {
                return Promise.reject(error);
            }

            config._retry = true;
            try {
                const fp = await load();

                const {visitorId} = await fp.get();

                const refreshTokenResponse: AxiosResponse<IResponse, void> = await instance.post(
                    `api/${process.env.REACT_APP_API_VERSION}/authentication/refreshToken`,
                    {
                        deviceId: visitorId
                    } as Partial<IUserDevice>);

                if (refreshTokenResponse?.data &&
                    refreshTokenResponse.data.resultCode === ResponseResultCode.Ok &&
                    (refreshTokenResponse.status === 200 || refreshTokenResponse.status === 204)) {
                    return instance(config);
                }
            } catch (err) {
                if (err instanceof AxiosError) {
                    if (err.response !== undefined &&
                        err.response.data !== undefined &&
                        isResponse(err.response.data)) {
                        //    if (err.response.data.resultCode === ResponseResultCode.NotValidData &&
                        //        err.response.data.field === 'rtk' &&
                        //        window.location.pathname !== '/login') {
                        //        dispatch(setLogoutMessage('Login validity period is expired. You need to log in again'));
                        //    }

                        if (err.response.data.resultCode === ResponseResultCode.NotFound &&
                            err.response.data.field === 'device') {
                            dispatch(setLogoutMessage('Your device isn\'t authenticated or blocked'));
                        }

                        if (err.response.data.resultCode === ResponseResultCode.Expired &&
                            err.response.data.field === 'user') {
                            dispatch(setLogoutMessage('User not found or just blocked'));
                        }

                        const hubConnectorInstance = HubConnector.getInstance(
                            `${process.env.REACT_APP_API_DOMAIN}/applicationHub`,
                            {
                                singleton: true
                            }
                        );

                        const connection = hubConnectorInstance.getConnection();

                        if (connection) {
                            await connection.stop();
                        }

                        await instance.post(`api/${process.env.REACT_APP_API_VERSION}/authentication/logout`);

                        dispatch(setAuthenticationState({
                            userAuthenticationStatus: AuthenticationStatus.NotAuthenticated,
                            deviceAuthenticationResult: undefined,
                            deviceAuthenticationStatus: AuthenticationStatus.NotAuthenticated
                        }));
                    }

                    return Promise.reject(err);
                }
            }

            return Promise.reject(error);
        }
    );
}

export default setupAxiosInterceptors;