import {useGetCountries, useGetPhoneFormats} from "./requests";

export const useCountryApi = () => {
    const {
        isLoading: getPhoneFormatsIsLoading,
        cancel: cancelGetPhoneFormats,
        getPhoneFormats
    } = useGetPhoneFormats();

    const {
        isLoading: getCountriesIsLoading,
        cancel: cancelGetCountries,
        getCountries
    } = useGetCountries();

    return {
        getPhoneFormats: {
            query: getPhoneFormats,
            isLoading: getPhoneFormatsIsLoading,
            cancel: cancelGetPhoneFormats
        },
        getCountries: {
            query: getCountries,
            isLoading: getCountriesIsLoading,
            cancel: cancelGetCountries
        }
    };
}