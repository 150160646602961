import React from 'react';
import {IShipment} from "../../../../../../../../../app/interfaces/shipment/IShipment";
import {useAppSelector} from "../../../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../../../app/enums/UserRole";
import SupplierField from "../../../../../../../../../components/shared/form/detailsFields/SupplierField/SupplierField";

type SupplierProps = {
    shipment: IShipment;
}

const Supplier: React.FC<SupplierProps> = ({
                                               shipment
                                           }) => {
    const user = useAppSelector(selectCurrentUser);

    if (!user) {
        return null;
    }

    if (shipment.serviceProviderUser === null || !shipment.serviceProviderUser.name) {
        return null
    }

    return (
        <div className={'details-box-item-field-value'}>
            {getUserRole(user) === UserRole.ServiceProvider
                ? <>{shipment.serviceProviderUser.name}</>
                : <SupplierField user={{
                    ...shipment.serviceProviderUser,
                    id: shipment.serviceProviderId ?? '',
                    group: shipment.serviceProviderGroup,
                    extName: shipment.extServiceProvider
                }}
                                 chatReplyTo={{
                                     type: 'SHIPMENT',
                                     id: shipment.orderReleaseGid?.replace('AAG.', '') ?? '',
                                     idExt: shipment.shipmentGid,
                                     description: shipment.chatDescription
                                 }}/>
            }
        </div>
    );
};

export default Supplier;