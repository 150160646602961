import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../../components/ui/Dialog/Dialog";
import UnsubscribeNotifications from "./UnsubscribeNotifications";

type UnsubscribeNotificationsProps = {
    onUnsubscribe: () => void;
    type: 'Viber' | 'Telegram';
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const UnsubscribeNotificationsDialog: React.FC<UnsubscribeNotificationsProps> = ({
                                                                                     show,
                                                                                     setShow,
                                                                                     type,
                                                                                     onUnsubscribe
                                                                                 }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={`${type}-unsubscribe-notifications-dialog`}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("notification.btn.unsubscribe")}>
            <UnsubscribeNotifications type={type}
                                      onUnsubscribe={onUnsubscribe}
                                      close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default UnsubscribeNotificationsDialog;