import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import SkeletonFormLoader
    from "../../ui/loaders/SkeletonLoadingScreen/components/SkeletonFormLoader/SkeletonFormLoader";
import FormLayoutToolbar, {
    FormLayoutToolbarActionItem,
    isFormLayoutToolbarExist
} from "./components/FormLayoutToolbar/FormLayoutToolbar";
import {LayoutConfigEntity} from "../../../app/enums/LayoutConfigEntity";
import {FormConfig} from "../../../app/types/FormConfig";
import {useAppSelector} from "../../../app/store";
import {selectCurrentUser} from "../../../features/authentication/authenticationSliceSelectors";
import {ResponseResultCode} from "../../../app/enums/ResponseResultCode";
import {useLayoutConfigApi} from "../../../app/api/layoutConfig";
import {LayoutConfigType} from "../../../app/enums/LayoutConfigType";
import axios from "axios";
import {toast} from "react-toastify";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {ButtonProps} from "../../ui/Button/Button";
import {FormLayoutToolbarButtonProps} from "./components/FormLayoutToolbarButton/FormLayoutToolbarButton";

export type FormLayoutIcon = {
    id: string;
    title: string;
    icon: IconProp;
    onClick: () => void;
    style?: React.CSSProperties;
};

type FormLayoutProps = {
    id: string;
    isLoading: boolean;
    showCloseIcon?: boolean;
    close: () => void;
    children: ReactNode;
    actions?: Array<FormLayoutToolbarActionItem | 'separator'>;
    entity?: LayoutConfigEntity;
    icons?: Array<FormLayoutToolbarButtonProps>;
    buttons?: Array<ButtonProps>;
}

const FormLayout: React.FC<FormLayoutProps> = ({
                                                   children,
                                                   id,
                                                   showCloseIcon = true,
                                                   close,
                                                   isLoading,
                                                   actions,
                                                   entity,
                                                   icons,
                                                   buttons
                                               }) => {
    const [configId, setConfigId] = useState<number | null>(null);
    const [config, setConfig] = useState<FormConfig | null>(null);
    const [isConfigLoading, setIsConfigLoading] = useState<boolean>(true);
    const user = useAppSelector(selectCurrentUser);
    const {
        getLayoutConfig: {query},
        saveLayoutConfig: {mutation}
    } = useLayoutConfigApi();

    useEffect(() => {
        document.addEventListener("keydown", handleKey, false);

        return () => {
            document.removeEventListener("keydown", handleKey, false);
        };
    }, []);

    const handleKey = (e: any) => {
        if (e.keyCode == 27) {
            close();
        }
    }


    useEffect(() => {
        if (entity === undefined) {
            setIsConfigLoading(false);
            return;
        }

        if (!user) {
            return;
        }

        (async () => {
            try {
                const configResponse = await query(entity, LayoutConfigType.Form, user.deviceId);
                if (configResponse?.status === 200 &&
                    configResponse.data.data !== undefined &&
                    configResponse.data.resultCode === ResponseResultCode.Ok &&
                    configResponse.data.data && configResponse.data.data.value) {
                    setConfigId(configResponse.data.data.id);
                    setConfig(JSON.parse(configResponse.data.data.value));
                }
            } catch {
                //ignore
            }
            setIsConfigLoading(false);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const saveConfig = useCallback(async (config: FormConfig): Promise<void> => {
        if (!user || entity === undefined) {
            return;
        }

        try {
            setConfig(config);

            const response = await mutation({
                id: configId !== null && configId !== undefined ? configId : 0,
                type: LayoutConfigType.Form,
                entity: entity,
                deviceId: user.deviceId,
                value: JSON.stringify(config)
            });

            if (response && response.status === 200 && response.data && response.data.field) {
                setConfigId(Number(response.data.field));
            }
        } catch (err) {
            if (axios.isAxiosError(err)) {
                toast.error<string>(`Unable to save ${LayoutConfigEntity[entity].toString()} configuration.`);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configId]);

    if (isLoading || isConfigLoading) {
        return <SkeletonFormLoader/>;
    }

    return (
        <div className={'h100 w100 hidden relative'} id={id}>
            <FormLayoutToolbar close={close}
                               showCloseIcon={showCloseIcon}
                               actions={actions}
                               pinActionEnabled={entity !== undefined}
                               pinnedActions={config?.pinnedActions ?? []}
                               saveConfig={saveConfig}
                               icons={icons}
                               buttons={buttons}/>
            <div style={isFormLayoutToolbarExist(showCloseIcon, actions)
                ? {height: 'calc(100% - 50px)'}
                : {height: '100%'}}>
                {children}
            </div>
        </div>
    );
};

export default FormLayout;
