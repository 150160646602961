import React, {useCallback, useEffect, useRef} from 'react';

type MapSearchBoxProps = {
    map: google.maps.Map | null;
    onPlacesChanged: (places: Array<google.maps.places.PlaceResult>) => void;
    isMapLoaded: boolean;
    placeholder: string;
    bounds?: google.maps.LatLngBounds;
}

const MapSearchBox: React.FC<MapSearchBoxProps> = ({
                                                       placeholder,
                                                       map,
                                                       onPlacesChanged,
                                                       isMapLoaded,
                                                       bounds
                                                   }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);

    const handleOnPlacesChanged = useCallback(() => {
        onPlacesChanged(searchBoxRef.current?.getPlaces() ?? []);
    }, [onPlacesChanged, searchBoxRef]);

    useEffect(() => {
        if (isMapLoaded && !searchBoxRef.current && map && inputRef.current) {
            searchBoxRef.current = new google.maps.places.SearchBox(inputRef.current);
            searchBoxRef.current.addListener('places_changed', handleOnPlacesChanged);
        }

        return () => {
            if (map) {
                searchBoxRef.current = null;
                google.maps.event.clearInstanceListeners(searchBoxRef);
            }
        };
    }, [map, handleOnPlacesChanged, isMapLoaded]);

    useEffect(() => {
        if (searchBoxRef.current) {
            searchBoxRef.current?.setBounds(bounds ?? null);
        }
    }, [bounds]);

    return (
        <input ref={inputRef} placeholder={placeholder} type="text"/>
    );
};

export default MapSearchBox;