import React from 'react';
import {IChat} from "../../../../../../../../app/interfaces/chat/IChat";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../../../../app/store";
import {selectChatUserOnlineStatus, selectIsChatActive} from "../../../../../../../../features/chat/chatSliceSelectors";
import {useChatHelperContext} from "../../../../hooks/useChatHelperContext";
import ChatAvatar from "../../../ChatAvatar/ChatAvatar";
import moment from "moment/moment";

type ChatListCardProps = {
    chat: IChat;
}

const formatDate = (date: Date | null): string => {
    let dateStr = ''

    if (date) {
        if (moment(date).isSame(new Date(), 'day')) {
            dateStr = moment(date).format('HH:mm');
        } else if (new Date(date).getFullYear() === moment().toDate().getFullYear()) {
            dateStr = moment(date).format('DD MMM');
        } else {
            dateStr = moment(date).format('DD MMM YYYY');
        }
    }

    return dateStr;
}

const ChatListCard: React.FC<ChatListCardProps> = ({
                                                       chat
                                                   }) => {
    const {t} = useTranslation();

    const {
        isChatsLoading,
        chat: activeChat,
        setChat
    } = useChatHelperContext();

    let isChatActive = useAppSelector(st => selectIsChatActive(st, chat.id));
    let isOnline = useAppSelector(st => selectChatUserOnlineStatus(st, {
        userId: chat.id
    }));

    return (
        <div
            className={`chat-list-card ${isChatsLoading ? 'c-disabled' : ''} ${isChatActive ? 'chat-list-card-selected' : ''} transition-all-500`}
            onClick={() => {
                if (isChatsLoading) {
                    return true;
                }

                setChat(activeChat && activeChat.id === chat.id ? null : chat);
            }}>
            <div>
                <ChatAvatar isOnline={isOnline}
                            onVacation={chat.isAway}
                            size={'Medium'}/>
            </div>
            <div>
                <div className={`chat-list-card-name-row`}>
                    <div title={chat.name}>
                        {chat.name}
                    </div>
                    <div>
                        {formatDate(chat.date)}
                    </div>
                </div>
                <div className={'chat-list-card-message-row'}>
                    <div title={chat.latestMessage ?? ''}>
                        {chat.latestMessage !== null && chat.latestMessage !== ''
                            ? <>{chat.latestMessage}</>
                            : <>{t("chat.labels.no-messages-history")}</>
                        }
                    </div>
                    <div className={`${chat.count > 0 ? 'messages-bubble' : ''}`}>
                        {chat.count > 0
                            ? <>{chat.count >= 100 ? `99+` : chat.count}</>
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatListCard;