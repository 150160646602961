import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'ellipsisVertical',
    viewBox: '0 0 3 17',
    circle: [
        {cx: 1.5, cy: 1.5, r: 1.5},
        {cx: 1.5, cy: 8.5, r: 1.5},
        {cx: 1.5, cy: 15.5, r: 1.5},
    ]
};

export default icon;