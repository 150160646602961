import React, {useEffect, useState} from 'react';
import {IUser} from "../../../../../app/interfaces/user/IUser";
import {useTranslation} from "react-i18next";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {
    IAddCustomerAttachmentGetAction
} from '../../../../../app/interfaces/customerAttachment/IAddCustomerAttachmentGetAction';
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {BLErrorCode} from "../../../../../app/enums/BLErrorCode";
import Alert from "../../../../../components/ui/Alert/Alert";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import AddCustomerAttachmentForm from "../AddCustomerAttachment/AddCustomerAttachmentForm/AddCustomerAttachmentForm";
import { IAddCustomerAttachmentPostAction } from '../../../../../app/interfaces/customerAttachment/IAddCustomerAttachmentPostAction';
import useCustomerAttachmentApi from '../../../../../app/api/customerAttachment';

type AddCustomerAttachmentProps = {
    orderReleaseGid: string;
    user?: IUser;
    close?: () => void;
};

const alertStyle: React.CSSProperties = {
    margin: '5px 10px',
    fontSize: '11px',
    fontWeight: 'bold',
}

const AddCustomerAttachment: React.FC<AddCustomerAttachmentProps> = ({
                                                   orderReleaseGid,
                                                   user,
                                                   close
                                               }) => {
    const {t} = useTranslation();
    const [model, setModel] = useState<IAddCustomerAttachmentGetAction | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError>>([]);

    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

    const {
        getAddCustomerAttachmentData: {
            isLoading: getAddCustomerAttachmentDataIsLoading,
            cancel: cancelGetAddCustomerAttachmentData,
            query: getAddCustomerAttachmentData
        },
        addCustomerAttachment: {
            mutation: addCustomerAttachment,
            cancel: cancelAddCustomerAttachment
        }
    } = useCustomerAttachmentApi();

    useEffect(() => {
        if (!orderReleaseGid || orderReleaseGid === '') {
            if (close) {
                close();
            }

            return;
        }

        (async () => {
            try {
                const response = await getAddCustomerAttachmentData(orderReleaseGid)

                if (response?.status === 200 && response.data && response.data.data && response.data.data) {

                    setModel(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error<string>(`Unable to get add customer attachment data. Error: ${e.message}`);

                    if (close) {
                        close();
                    }
                }
            }
        })();

        return () => {
            cancelGetAddCustomerAttachmentData();
            cancelAddCustomerAttachment();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderReleaseGid]);

    const save = async (values: IAddCustomerAttachmentPostAction): Promise<void> => {
        if (!model) {
            return;
        }
        

        setDisabled(prev => !prev);

        try {
            let data: FormData = new FormData();
            data.append('orderReleaseGid', model.orderReleaseGid);
            data.append('notes', values.notes ?? '');
            data.append('category', values.categoryModel.value);

            if (values.customerAttachmentFile) {
                data.append('file', values.customerAttachmentFile);
            }

            const response = await addCustomerAttachment(data);

            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {

                let rows = model.orderReleaseGid.split(';').map(item => {
                    return {
                        id: item,
                        isRowLoading: true
                    };
                });

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: rows
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("customer-attachment.btn.add-customer-attachment")}`}/>);

                if (close) {
                    close();
                }
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();

                    toast.error(`Unable to add customer attachment. Error: ${err.message}`);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if (!orderReleaseGid || orderReleaseGid === '') {
        return null;
    }

    if (getAddCustomerAttachmentDataIsLoading || model === null) {
        return (
            <FormLoadingScreen height={`350px`} style={{padding: '5px'}}/>
        );
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                {errors.map(item => {
                    switch (item.errorCode) {
                        case BLErrorCode.InnerError:
                            return (
                                <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                    {item.description}
                                </Alert>
                            );
                        case BLErrorCode.CustomerAttachmentNotSupportedFormat:
                            return (
                                <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                    {t("customer-attachment.errors.attachment-not-supported-format")}
                                </Alert>
                            );
                        case BLErrorCode.CustomerAttachmentFileIsBig:
                            return (
                                <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                    {t("customer-attachment.errors.attachment-file-is-big")}
                                </Alert>
                            );
                        case BLErrorCode.CustomerAttachmentFileIsEmpty:
                            return (
                                <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                    {t("customer-attachment.errors.attachment-file-is-empty")}
                                </Alert>
                            );
                    }

                    return null;
                })}

             <AddCustomerAttachmentForm model={model}
                                        disabled={disabled}
                                        onSubmit={save}
                                        user={user}/>
                   
                
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled || isSubmitDisabled}
                        form='add-customer-attachment-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.confirm")}
                </Button>
            </div>
        </div>
    );
};

export default AddCustomerAttachment;