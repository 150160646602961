import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'bookmark',
    pathData: [
        'M9 1L3.5 6.5L1 4'
    ],
    viewBox: '0 0 10 8',
    strokeWidth: 2,
    strokeLinejoin: 'round',
    strokeLinecap: 'round'
};

export default icon;