import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {SelectModel} from "../../../../../../../../../../app/types/SelectModel";
import {useQuoteApi} from "../../../../../../../../../../app/api/quote";
import {debounce} from "debounce";
import {ComboBox} from "@progress/kendo-react-dropdowns";

type NettoBetContractSelectorProps = {
    value?: string;
    disabled: boolean;
    orderReleaseGid: string;
    spId: string | null;
    spName: string | null;
    onChange: (value: string | null) => void;
}

const NettoBetContractSelector: React.FC<NettoBetContractSelectorProps> = ({
                                                                               spId,
                                                                               spName,
                                                                               orderReleaseGid,
                                                                               disabled,
                                                                               value,
                                                                               onChange
                                                                           }) => {
    const {t} = useTranslation();
    const [initialItems, setInitialItems] = useState<Array<SelectModel>>([]);
    const [items, setItems] = useState<Array<SelectModel>>([]);
    const [filter, setFilter] = useState<string | undefined>();
    const [selectedItem, setSelectedItem] = useState<SelectModel | null>();

    const {
        getContracts: {
            query,
            isLoading,
            cancel
        }
    } = useQuoteApi();

    useEffect(() => {
        return () => {
            cancel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (initialItems.length > 0 && filter) {
            if(filter.length > 3) {
                let filtered = initialItems.filter(e => {
                    return e.text.toLowerCase().includes(filter.toLowerCase());
                });

                setItems([...filtered]);
            }
        } else {
            setItems(initialItems);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
        if (spId !== null && spName !== null) {
            if (value !== null && value !== undefined) {
                onChange(null);
            }

            (async () => {
                await load(orderReleaseGid, spId, spName);
            })();
        } else {
            if (value !== null && value !== undefined) {
                onChange(null);
            }

            if (initialItems.length > 0) {
                setInitialItems([]);
                setItems([]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spId]);

    useEffect(() => {
        if (initialItems.length <= 0) {
            return;
        }

        if (value !== undefined && value !== null && value !== '') {
            let item = items.find(e => e.value === value);

            if (item) {
                setSelectedItem({
                    ...item
                });
            }
        } else {
            setSelectedItem(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, initialItems]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const load = useCallback(debounce(async (lOrderReleaseGid: string, lSpId: string, lSpName: string) => {
        try {
            const response = await query(lOrderReleaseGid, lSpId, lSpName);

            if (response?.status === 200 && response.data?.data) {
                let data = response.data.data.filter(e => e.text !== '' && e.value !== '')
                setInitialItems(data);
                setItems(data);
            }
        } catch {
            //ignore
        }
    }, 500), []);

    return (
        <ComboBox data={items}
                  value={selectedItem}
                  onChange={ev => {
                      onChange(ev.value?.value ?? null);
                  }}
                  disabled={disabled}
                  //placeholder={t("quote.bet.contract")}
                  textField="text"
                  dataItemKey="value"
                  clearButton={true}
                  filterable={true}
                  loading={isLoading}
                  onFilterChange={(ev) => {
                      if ((ev.nativeEvent === undefined || ev.nativeEvent === null) && (
                          ev.syntheticEvent === undefined || ev.syntheticEvent === null
                      )) {
                          setFilter(undefined);

                          return;
                      }

                      if (value !== null && value !== undefined && value !== '') {
                          onChange(null);
                      }

                      setFilter(ev.filter.value);
                  }}
        />
    );
};

export default NettoBetContractSelector;
