import React, {useEffect, useRef, useState} from 'react';
import DatePicker, {ReactDatePickerProps} from "react-datepicker";
import uuid from "react-uuid";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {
    getReactDatePickerCalendarSvgClass, getReactDatePickerClass,
    getReactDatePickerCloseSvgClass,
    getReactDatePickerFloatingPlaceholderSvgClass, getReactDatePickerLocale
} from "../ReactDatePicker/utils";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {createPortal} from "react-dom";

export type ReactTimePickerSize = 'big' | 'medium' | 'small';

type ReactTimePickerProps = {
    value?: Date | null;
    size?: ReactTimePickerSize;
    placeholder?: string;
    floatingPlaceholder?: boolean;
    floatingPlaceholderFontSize?: string;
    showClearIcon?: boolean;
    hasError?: boolean;
    onOpen?: () => void;
    setNowDateOnOpen?: boolean;
} & Pick<ReactDatePickerProps, 'onChange' | 'disabled' | 'readOnly'>;

const ReactTimePicker: React.FC<ReactTimePickerProps> = ({
                                                             value,
                                                             placeholder,
                                                             floatingPlaceholderFontSize,
                                                             onChange,
                                                             onOpen,
                                                             floatingPlaceholder = false,
                                                             readOnly = false,
                                                             disabled = false,
                                                             showClearIcon = true,
                                                             size = 'big',
                                                             hasError = false
                                                         }) => {
    const id = useRef<string>(uuid());

    const input = useRef<HTMLInputElement | null>(null);

    const {i18n, t} = useTranslation();
    const [isTimePickerOpened, setIsTimePickerOpened] = useState<boolean>(false);

    useEffect(() => {
        const container = document.getElementById(`time-picker-${id.current}`);

        if (container) {
            input.current = container.querySelector('input');
        }
    }, []);

    useEffect(() => {
        if (isTimePickerOpened && onOpen) {
            onOpen();
        }
    }, [isTimePickerOpened]);

    return (
        <>
            <div className={'relative w100'} id={`time-picker-${id.current}`}>
                <FontAwesomeIcon icon={faClock}
                                 className={getReactDatePickerCalendarSvgClass(size)}
                                 onClick={() => {
                                     if (readOnly || disabled) {
                                         return;
                                     }

                                     setIsTimePickerOpened(prev => !prev);

                                     if (input.current) {
                                         input.current?.focus();
                                     }
                                 }}/>

                {showClearIcon && value && !readOnly && !disabled &&
                    <FontAwesomeIcon icon={faXmark}
                                     className={getReactDatePickerCloseSvgClass()}
                                     onClick={(ev) => onChange(null, ev)}/>}

                {placeholder && floatingPlaceholder
                    ? <label data-size={size}
                             style={floatingPlaceholderFontSize ? {fontSize: floatingPlaceholderFontSize} : undefined}
                             className={getReactDatePickerFloatingPlaceholderSvgClass((value !== undefined && value !== null) || isTimePickerOpened)}>
                        {placeholder}
                    </label>
                    : null
                }

                <DatePicker className={getReactDatePickerClass(size, showClearIcon, disabled || readOnly, hasError)}

                            dateFormat={'HH:mm'}
                            portalId={`time-picker-calendar-portal-${id.current}`}

                            timeCaption={t("shared.labels.time")}
                            timeFormat={'HH:mm'}
                            showTimeSelect
                            showTimeSelectOnly
                            locale={getReactDatePickerLocale(i18n.language)}
                            selected={value}
                            data-size={size}
                            open={isTimePickerOpened}
                            readOnly={readOnly}
                            disabled={disabled}
                            placeholderText={floatingPlaceholder ? undefined : placeholder}

                            onCalendarOpen={() => setIsTimePickerOpened(true)}
                            onCalendarClose={() => setIsTimePickerOpened(false)}
                            onClickOutside={() => setIsTimePickerOpened(false)}
                            onChange={onChange}
                />
            </div>
            {createPortal(
                <div id={`time-picker-calendar-portal-${id.current}`}></div>,
                document.body
            )}
        </>
    );
};

export default ReactTimePicker;