import React, {useEffect, useState} from 'react';
import useDocumentTitle from "../../../../hooks/useDocumentTitle/useDocumentTitle";
import {useTranslation} from "react-i18next";
import cl from './Profile.module.css';
import {useProfileApi} from "../../../../app/api/profile";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../app/enums/ResponseResultCode";
import {IProfileGetAction} from "../../../../app/interfaces/profile/IProfileGetAction";
import {IProfilePostAction} from "../../../../app/interfaces/profile/IProfilePostAction";
import FormLoadingScreen from "../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import ProfileForm from "./ProfileForm/ProfileForm";
import {useAppSelector} from "../../../../app/store";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSliceSelectors";
import {UserRole} from "../../../../app/enums/UserRole";
import ToastLayout from "../../../../components/uiLayouts/ToastLayout/ToastLayout";
import DeviceList from "./DeviceList/DeviceList";

const Profile = () => {
    const {t, i18n} = useTranslation();
    useDocumentTitle({
        title: t("profile.labels.profile")
    });

    const user = useAppSelector(selectCurrentUser);
    const [model, setModel] = useState<IProfileGetAction | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        getProfileData: {
            cancel: cancelGetProfileData,
            query: getProfileData,
            isLoading
        },
        saveProfile: {
            cancel: cancelSaveProfile,
            mutation: saveProfile
        },
        getIsEmailNeedConfirmation: {
            query: getIsEmailNeedConfirmation
        }
    } = useProfileApi();

    useEffect(() => {
        (async () => {
            try {
                const response = await getProfileData();
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error<string>(`Unable to get profile data. Error: ${e.message}`);
                }
            }
        })();

        return () => {
            cancelGetProfileData();
            cancelSaveProfile();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!model || !user || user.role === UserRole.Forwarder) {
            return;
        }

        let interval: NodeJS.Timer = setInterval(() => {
            (async () => {
                if (!model) {
                    return;
                }

                try {
                    const response = await getIsEmailNeedConfirmation();
                    if (response?.status === 200 && response.data && response.data.data !== undefined && response.data.data !== null) {
                        if (model.isNewEmailNeedConfirmation !== response.data.data) {
                            setModel({
                                ...model,
                                isNewEmailNeedConfirmation: response.data.data
                            });
                        }
                    }
                } catch {
                    // ignore
                }
            })();
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [model, user]);

    const save = async (item: IProfilePostAction): Promise<void> => {
        if (!model) {
            return;
        }

        setDisabled(prev => !prev);

        try {
            const response = await saveProfile(item);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                toast.success(<ToastLayout title={t("profile.labels.profile")}
                                           text={t("profile.labels.profile-saved-label")}/>);

                if (model.language !== item.language) {
                    await i18n.changeLanguage(item.language.toString().toLowerCase());
                }

                setModel({
                    ...model,
                    email: item.email,
                    phone: item.phone,
                    mobPhone: item.mobPhone,
                    language: item.language
                });
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(e.message);
            }
        }

        setDisabled(prev => !prev);
    }

    return (
        <div className={`w100 h100 hidden pd-5 ${cl.container}`}>
            <div className={`default-form-collapse-header prevent-selection ${cl.header}`}>
                {t("profile.labels.profile")}
            </div>
            <div className={`hidden`}>
                {isLoading || model === null
                    ? <FormLoadingScreen height={`100%`} style={{padding: '5px'}}/>
                    : null
                }
                <div className={`${cl.content} o-auto`}>
                    <div>
                        {!isLoading && model &&
                            <ProfileForm disabled={disabled}
                                         model={model}
                                         save={save}/>
                        }
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Profile;
