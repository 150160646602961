import React from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import cl from './OtmStatusTemplate.module.css';
import {useTranslation} from "react-i18next";

const OtmStatusTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const {t} = useTranslation();

    let color: string = '';
    let text: string = '';
    switch (props.children) {
        case 'AAG.SH_CHANGES_NEW_SH':
            color = cl.gray;
            text = t("shipment.expense-status.new");
            break;
        case 'AAG.SH_CHANGES_FOR_APPROVE':
            color = cl.yellow;
            text = t("shipment.expense-status.approval");
            break;
        case 'AAG.SH_CHANGES_APPROVED':
            color = cl.green;
            text = t("shipment.expense-status.accepted");
            break;
    }

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
                <span className={'default-center-cell'}>
                    <span className={`${cl.plate} ${color}`}>
                        {text}
                    </span>
                </span>
        </td>
    );
};

export default OtmStatusTemplate;