import React from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {useQuoteApi} from "../../../../../../app/api/quote";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";

const QuoteSourceTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);

    const {
        getCrmLink: {
            query: getCrmLink
        }
    } = useQuoteApi();

    let quoteSource = props.dataItem.quoteSource !== null &&
    props.dataItem.quoteSource !== undefined &&
    props.dataItem.quoteSource !== ''
        ? props.dataItem.quoteSource
        : null;

    let crmNumber = props.dataItem.crmNumber !== null &&
    props.dataItem.crmNumber !== undefined &&
    props.dataItem.crmNumber !== ''
        ? props.dataItem.crmNumber
        : null;

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
            {quoteSource !== null && quoteSource === 'CRM' && crmNumber !== null
                ? <span className={`default-left-cell default-overflow-cell`}>
                    <button className={'grid-prevent-selection shipment-grid-open-otm-shipment-btn cell-button'}
                            onClick={async (ev) => {
                                if (!crmNumber) {
                                    return;
                                }

                                ev.stopPropagation();

                                try {
                                    const response = await getCrmLink(crmNumber);

                                    if (response && response.data && response.data.resultCode === ResponseResultCode.Ok &&
                                        response.data.data) {
                                        window.open(response.data.data, '_blank');
                                    }
                                } catch {
                                    //ignore
                                }
                            }}>
                        {props.children}
                    </button>
                </span>
                : <span className={`default-left-cell default-overflow-cell`}>
                    {props.children}
                </span>
            }
        </td>
    );
};

export default QuoteSourceTemplate;