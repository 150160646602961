import React from 'react';

const ShipmentMapContent = () => {
    return (
        <div>
            Map
        </div>
    );
};

export default ShipmentMapContent;