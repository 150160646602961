import {useContext, useEffect, useRef} from "react";
import {GridRowContext} from "../Grid";

type UseGridCellHandleClickReturn = {
    handleClick: () => void;
}

export const useGridCellHandleClick = (dataItem: any): UseGridCellHandleClickReturn => {
    let timeout = useRef<NodeJS.Timeout | null>(null);
    const {
        onRowClick,
        onRowDoubleClick
    } = useContext(GridRowContext);

    const handleClick = () => {

        if (timeout.current !== null) {
            onRowDoubleClick({dataItem: dataItem});
            clearTimeout(timeout.current)
            timeout.current = null
        } else {
            timeout.current = setTimeout(() => {
                onRowClick({dataItem: dataItem});
                if (timeout.current) {
                    clearTimeout(timeout.current)
                }

                timeout.current = null
            }, 200)
        }
    }

    useEffect(() => {
        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        }
    }, []);

    return {
        handleClick
    };
}
