import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import ShowTimeSlot from "./ShowTimeSlot";


type CopyOrDialogProps = {
    id: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShowTimeSlotDialog: React.FC<CopyOrDialogProps> = ({
                                                       id,
                                                       show,
                                                       setShow
                                                   }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'show-time-slot-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.btn.show-time-slot")}>
            <ShowTimeSlot id={id} close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default ShowTimeSlotDialog;
