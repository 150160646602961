import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'bell',
    pathData: [
        'M11.9997 4.66634V3.46634C11.9997 2.7196 11.9997 2.34624 11.8544 2.06102C11.7265 1.81014 11.5225 1.60616 11.2717 1.47833C10.9864 1.33301 10.6131 1.33301 9.86634 1.33301H6.13301C5.38627 1.33301 5.0129 1.33301 4.72769 1.47833C4.4768 1.60616 4.27283 1.81014 4.145 2.06102C3.99967 2.34624 3.99967 2.7196 3.99967 3.46634V4.66634M3.99967 11.9997C3.37969 11.9997 3.0697 11.9997 2.81537 11.9315C2.12519 11.7466 1.58609 11.2075 1.40116 10.5173C1.33301 10.263 1.33301 9.95299 1.33301 9.33301V7.86634C1.33301 6.74624 1.33301 6.18618 1.55099 5.75836C1.74274 5.38204 2.0487 5.07608 2.42503 4.88433C2.85285 4.66634 3.4129 4.66634 4.53301 4.66634H11.4663C12.5864 4.66634 13.1465 4.66634 13.5743 4.88433C13.9506 5.07608 14.2566 5.38204 14.4484 5.75836C14.6663 6.18618 14.6663 6.74624 14.6663 7.86634V9.33301C14.6663 9.95299 14.6663 10.263 14.5982 10.5173C14.4133 11.2075 13.8742 11.7466 13.184 11.9315C12.9296 11.9997 12.6197 11.9997 11.9997 11.9997M9.99967 6.99967H11.9997M6.13301 14.6663H9.86634C10.6131 14.6663 10.9864 14.6663 11.2717 14.521C11.5225 14.3932 11.7265 14.1892 11.8544 13.9383C11.9997 13.6531 11.9997 13.2797 11.9997 12.533V11.4663C11.9997 10.7196 11.9997 10.3462 11.8544 10.061C11.7265 9.81014 11.5225 9.60616 11.2717 9.47833C10.9864 9.33301 10.6131 9.33301 9.86634 9.33301H6.13301C5.38627 9.33301 5.0129 9.33301 4.72769 9.47833C4.4768 9.60616 4.27283 9.81014 4.145 10.061C3.99967 10.3462 3.99967 10.7196 3.99967 11.4663V12.533C3.99967 13.2797 3.99967 13.6531 4.145 13.9383C4.27283 14.1892 4.4768 14.3932 4.72769 14.521C5.0129 14.6663 5.38627 14.6663 6.13301 14.6663Z'
    ],
    viewBox: '0 0 16 16',
    strokeWidth: 1,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;