import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'arrowRight',
    pathData: [
        'M5.5 10L10 5.5M10 5.5L5.5 1M10 5.5H1'
    ],
    viewBox: '0 0 11 11',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;