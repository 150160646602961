import {IStopPoint} from "../app/interfaces/shared/IStopPoint";
import {ShipmentEventNames} from "../app/enums/ShipmentEventNames";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faArrowLeft, faArrowRight, faRecordVinyl} from "@fortawesome/free-solid-svg-icons";

export const getEventLabel = (evName: string, spType: string, sp: IStopPoint | null | undefined): string => {
    let eName = sp !== null && sp !== undefined
        ? "AAG." + evName.replace(sp.position + "_", "").replace("AAG.", "")
        : evName;

    switch (eName) {
        case ShipmentEventNames.EXPECTED_ARRIVAL_TO_CUSTOMS:
            return "shipment.events.expected-arrival-to-customs";
        case ShipmentEventNames.START_SHIPMENT:
            return "shipment.events.start-shipment";
        case ShipmentEventNames.ARRIVAL_AT_UNLOADING:
            return "shipment.events.arrival-at-unloading";
        case ShipmentEventNames.DEPARTURE_FROM_WAREHOUSE:
            if (spType.toLowerCase() === "[p]")
                return "shipment.events.departure-from-warehouse-p";
            else
                return "shipment.events.departure-from-warehouse";
        case ShipmentEventNames.START_LOADING:
            return "shipment.events.start-loading";
        case ShipmentEventNames.END_LOADING:
            return "shipment.events.end-loading";
        case ShipmentEventNames.START_UNLOADING:
            return "shipment.events.start-unloading";
        case ShipmentEventNames.COMPLETE_SHIPMENT:
            return "shipment.events.complete-shipment";
        case ShipmentEventNames.ARRIVE_TO_CUSTOMS:
            return "shipment.events.arrival-to-customs";
        case ShipmentEventNames.DEPART_FROM_CUSTOMS:
            return "shipment.events.depart-from-customs";
    }

    return '';
}

export const getStopPointIcon = (type: string): IconProp | null => {
    if (type === undefined || type === '') {
        return null;
    }

    switch (type.toLowerCase()) {
        case '[p]':
            return faArrowRight;
        case '[o]':
            return faRecordVinyl;
        case 'c':
        case '[c]':
            return faRecordVinyl;
        case '[d]':
            return faArrowLeft;
    }

    return null;
}

export const getStopPointTypeResource = (type: string): string => {
    if (type === undefined || type === '') {
        return '';
    }

    switch (type.toLowerCase()) {
        case '[p]':
            return 'stopPoint.types.p';
        case '[o]':
            return 'stopPoint.types.o';
        case '[c]':
            return 'stopPoint.types.c';
        case '[d]':
            return 'stopPoint.types.d';
    }

    return '';
}

export const hasECMErrorByCode = (type: string | null): boolean => {
    if (type === undefined || type === '' || !type) {
        return false;
    }

    return type.toUpperCase().includes("CODE E");
}
export const getECMErrorByCode = (type: string | null): string => {
    if (type === undefined || type === '' || !type) {
        return '';
    }

    var index = type.toUpperCase().indexOf("CODE E");
    if(index > 0) {
        return 'self-billing.ecm-code.e' + type.toLowerCase().substring(index+6);
    }


    return type;
}
