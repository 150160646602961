import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {SelectModel} from "../../../../../app/types/SelectModel";
import {ComboBox} from "@progress/kendo-react-dropdowns";

type FilterPanelShipmentConfirmationFilterProps = {
    disabled?: boolean;
    onClear: (value?: string) => void;
    onChange: (value: Array<string>) => void,
    value: Array<string>;
}

const FilterPanelShipmentConfirmationFilter: React.FC<FilterPanelShipmentConfirmationFilterProps> = ({
                                                                                                         disabled,
                                                                                                         onChange,
                                                                                                         value
                                                                                                     }) => {
    const {t} = useTranslation();

    const [statuses] = useState<Array<SelectModel>>([
        {text: "CONFIRMED", value: "CONFIRMED"},
        {text: "UNCONFIRMED", value: "UNCONFIRMED"},
    ]);

    return (
        <ComboBox id={'shipment-statuses-multi-select'}
                  data={statuses}
                  disabled={disabled}
                  placeholder={t("shipment.grid.status")}
                  textField='text'
                  dataItemKey='value'
                  filterable={false}
                  size={'medium'}
                  rounded={'small'}
                  fillMode={'solid'}

                  onChange={(ev) => {

                      if (ev.value && ev.value.value)
                          onChange([ev.value.value]);
                      else
                          onChange([])
                  }}
                  value={value
                      ? {
                          text: value,
                          value: value
                      }
                      : {
                          text: "",
                          value: ""
                      }}
        />
    );
};

export default FilterPanelShipmentConfirmationFilter;
