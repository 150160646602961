import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'pen',
    pathData: [
        'M1.67736 10.5673C1.70417 10.3261 1.71757 10.2055 1.75406 10.0928C1.78643 9.99277 1.83218 9.89759 1.89005 9.80983C1.95527 9.71092 2.04108 9.62512 2.21268 9.45351L9.91636 1.74985C10.5607 1.10552 11.6054 1.10552 12.2497 1.74985C12.894 2.39418 12.894 3.43885 12.2497 4.08319L4.54601 11.7868C4.37441 11.9585 4.28861 12.0443 4.18969 12.1095C4.10194 12.1673 4.00676 12.2131 3.90675 12.2455C3.79403 12.282 3.67342 12.2954 3.43222 12.3222L1.45801 12.5415L1.67736 10.5673Z'
    ],
    viewBox: '0 0 14 14',
};

export default icon;