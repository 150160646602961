import React from 'react';
import cl from './TreeListBoxRowSkeleton.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretUp} from "@fortawesome/free-solid-svg-icons";
import Checkbox from "../../../Checkbox/Checkbox";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

type TreeListBoxRowSkeletonProps = {
    showExpandIcon: boolean;
}

const TreeListBoxRowSkeleton: React.FC<TreeListBoxRowSkeletonProps> = ({
                                                                           showExpandIcon
                                                                       }) => {
    return (
        <div className={cl.row}>
            {showExpandIcon &&
                <div className={cl.icon}>
                    <FontAwesomeIcon icon={faCaretUp}/>
                </div>
            }
            <div className={cl.checkbox}>
                <Checkbox checked={false}
                          change={() => {
                              //ignore
                          }}
                          disabled={false}/>
            </div>
            <div className={cl.skeleton}>
                <div style={{width:'calc(100% - 10px)'}}>
                    <SkeletonTheme baseColor={'#eaeff3'} highlightColor={'#ffffff'}>
                        <Skeleton height={'15px'}
                                  width={'100%'}
                                  style={{borderRadius: '2px'}}/>
                    </SkeletonTheme>
                </div>
            </div>
        </div>
    );
};

export default TreeListBoxRowSkeleton;