import React, {useState} from 'react';
import SidebarMenuDashboard from "./SidebarMenuDashboard/SidebarMenuDashboard";
import SidebarMenuQuote from "./SidebarMenuQuote/SidebarMenuQuote";
import SidebarMenuOffer from "./SidebarMenuOffer/SidebarMenuOffer";
import SidebarMenuShipment from "./SidebarMenuShipment/SidebarMenuShipment";
import SidebarMenuTransport from "./SidebarMenuTransport/SidebarMenuTransport";

const SidebarMenuSection: React.FC = () => {
    const [expandedMenuId, setExpandedMenuId] = useState<string>('');
    const handleNavigationClick = (id: string) => {
        setExpandedMenuId(id)
    };

    return (
        <>
            <SidebarMenuDashboard expandedId={expandedMenuId} onClick={handleNavigationClick}/>
            <SidebarMenuQuote expandedId={expandedMenuId} onClick={handleNavigationClick}/>
            <SidebarMenuOffer expandedId={expandedMenuId} onClick={handleNavigationClick}/>
            <SidebarMenuShipment expandedId={expandedMenuId} onClick={handleNavigationClick}/>
            <SidebarMenuTransport expandedId={expandedMenuId} onClick={handleNavigationClick}/>
        </>
    );
};

export default SidebarMenuSection;
