import React, {ComponentType} from "react";
import cl from './Grid.module.css';
import Loader, {LoaderType} from "../../loaders/Loader/Loader";
import {
    getSelectedState,
    Grid as TelerikGrid, GridCellProps,
    GridColumn,
    GridColumnProps,
    GridContextMenuEvent,
    GridDataStateChangeEvent, GridDetailRowProps,
    GridExpandChangeEvent,
    GridHeaderSelectionChangeEvent, GridItemChangeEvent,
    GridPageChangeEvent,
    GridPagerSettings,
    GridRowClickEvent,
    GridRowDoubleClickEvent, GridRowProps,
    GridSelectableSettings,
    GridSelectionChangeEvent,
    GridSortChangeEvent
} from "@progress/kendo-react-grid";
import {DataResult, State} from "@progress/kendo-data-query";
import {withTranslation} from "react-i18next";
import {i18n, t} from "i18next";
import {createPortal} from "react-dom";
import {GridConfig} from "./types/GridConfig";
import {
    getGridConfigColumns,
    getGridLocaleString,
    isColumnsChanged, isGroupChanged, loadGridLocalization,
    updateColumns,
} from "./utils";
import {GridService} from "./api/GridService";
import {ContextMenu, MenuItem} from "@progress/kendo-react-layout";
import {isMobileOnly} from "react-device-detect";
import {Query} from "../../../../app/types/Query";
import {
    getSelectedRowsData,
    GRID_DATA_ITEM_KEY, GRID_EDIT_FIELD,
    GRID_SELECTED_FIELD,
    idGetter,
    processData,
    processWithGroups
} from "./utils/data";
import {ContextMenuItem} from "./types/ContextMenuItem";
import EditRowRender from "./components/EditRowRender/EditRowRender";
import EditCellRender from "./components/EditCellRender/EditCellRender";

type GridProps = {
    query?: Query;
    config: GridConfig;
    id: string;
    url: string;
    width?: string;
    height?: string;

    allowPaging?: boolean;
    allowSorting?: boolean;
    allowSelection?: boolean;
    allowResizing?: boolean;
    allowReordering?: boolean;
    allowGrouping?: boolean;
    allowEditing?: boolean;
    skipLoading?: boolean;

    detailComponent?: ComponentType<GridDetailRowProps>;

    showCellContextMenu?: boolean;
    contextMenuItems?: Array<ContextMenuItem>;

    defaultPageSizes?: Array<number>;
    selectionMode?: "single" | "multiple";

    i18n: i18n;
    t: typeof t

    onDataLoad?: () => void;
    onSelect?: (rows: Array<any>) => void;
    onRowClick?: (ev: GridRowClickEvent) => void;
    onRowDoubleClick?: (ev: GridRowDoubleClickEvent) => void;
    handleConfigSave?: (config: GridConfig) => Promise<void>;
    onCellEditEnd?: (ev: {
        dataItem: any,
        changed: {
            field: string,
            value: string
        }
    }) => void;
    refresh?: number;
}

type GridState = {
    api: GridService;
    config: GridConfig;
    id: string;
    url: string;
    width: string;
    height: string;
    data: DataResult;
    locale: string;

    allowSorting: boolean;
    allowResizing: boolean;
    allowReordering: boolean;
    allowGrouping: boolean;
    allowEditing: boolean;
    skipLoading: boolean;

    showCellContextMenu: boolean;
    isContextMenuShown: boolean;

    contextMenuItems: Array<ContextMenuItem>;
    pageSettings?: GridPagerSettings;
    selectionSettings: GridSelectableSettings;
    selectedState: {
        [id: string]: boolean | number[];
    };
    collapsedGroup: Array<any>;

    state: State;
    isMounted: boolean;
}

export const GridRowContext = React.createContext<{
    onRowClick: (ev: any) => void;
    onRowDoubleClick: (ev: any) => void;
}>(undefined as any);

export const GridContext = React.createContext<{
    refreshData: () => void;
    updateRowData: (rowData: any) => void;
}>(undefined as any);

class Grid extends React.Component<GridProps, GridState> {
    private readonly loaderReference: React.RefObject<HTMLDivElement>;
    private readonly loaderOverlayReference: React.RefObject<HTMLDivElement>;

    private contextMenuOffset: {
        left: number,
        top: number
    } = {left: 0, top: 0};
    private contextMenuValueToCopy: string | undefined;
    private contextMenuRow: any | undefined;
    private isActionsDisabled: boolean = false;
    private isFirstApiCallDone: boolean = false;
    private selectionTimeout: NodeJS.Timeout | null = null;

    private localQuery?: Query;

    constructor(props: GridProps) {
        super(props);

        this.state = {
            api: new GridService(props.url),
            id: props.id,
            url: props.url,
            locale: getGridLocaleString(props.i18n),
            width: props.width ?? '100%',
            height: props.height ?? '100%',
            config: props.config,
            data: {
                total: 0,
                data: []
            },

            allowResizing: props.allowResizing !== undefined ? props.allowResizing : true,
            allowSorting: props.allowSorting !== undefined ? props.allowSorting : true,
            allowReordering: props.allowReordering !== undefined ? props.allowReordering : true,
            allowGrouping: props.allowGrouping !== undefined ? props.allowGrouping : true,
            allowEditing: props.allowEditing !== undefined ? props.allowEditing : false,
            skipLoading: props.skipLoading !== undefined ? props.skipLoading : false,

            showCellContextMenu: props.showCellContextMenu !== undefined ? props.showCellContextMenu : false,
            isContextMenuShown: false,

            state: {
                skip: 0,
                take: props.config.pageSize,
                sort: props.config.sortBy.length > 0
                    ? props.config.sortBy.map(item => ({
                        dir: item.dir,
                        field: item.field
                    }))
                    : undefined,
                group: props.config.groupBy.length > 0
                    ? props.config.groupBy.map(item => ({
                        field: item
                    }))
                    : undefined
            },
            collapsedGroup: [],
            pageSettings: (props.allowPaging === undefined || props.allowPaging)
                ? {
                    buttonCount: 5,
                    pageSizes: props.defaultPageSizes ?? [10, 25, 50],
                    pageSizeValue: props.config.pageSize,
                    responsive: false
                } : undefined,
            selectionSettings: {
                enabled: props.allowSelection !== undefined ? props.allowSelection : true,
                cell: false,
                mode: props.selectionMode !== undefined ? props.selectionMode : 'single'
            },
            selectedState: {},
            isMounted: false,
            contextMenuItems: [],
        };

        loadGridLocalization(props.i18n);

        this.loaderReference = React.createRef<HTMLDivElement>();
        this.loaderOverlayReference = React.createRef<HTMLDivElement>();

        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.loadData = this.loadData.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.sortChange = this.sortChange.bind(this);
        this.handleContextMenu = this.handleContextMenu.bind(this);
        this.handleContextMenuOnClick = this.handleContextMenuOnClick.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.onRowDoubleClick = this.onRowDoubleClick.bind(this);
        this.handleConfigSave = this.handleConfigSave.bind(this);
        this.expandChange = this.expandChange.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.onHeaderSelectionChange = this.onHeaderSelectionChange.bind(this);
        this.updateRowData = this.updateRowData.bind(this);
        this.updateRowsData = this.updateRowsData.bind(this);
        this.enterEdit = this.enterEdit.bind(this);
        this.exitEdit = this.exitEdit.bind(this);
        this.editItemChange = this.editItemChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<GridProps>) {
        if (this.props.skipLoading !== nextProps.skipLoading) {
            this.setState({
                skipLoading: nextProps.skipLoading ?? false
            });
        }

        if (this.props.refresh !== nextProps.refresh) {
            this.loadData(this.state.state);
        }

        if (!Query.compare(this.localQuery, nextProps.query) && this.isFirstApiCallDone) {
            this.localQuery = nextProps.query;

            this.setState({
                state: {
                    ...this.state.state,
                    skip: 0,
                    take: this.state.pageSettings && this.state.pageSettings.pageSizeValue !== undefined
                        ? Number(this.state.pageSettings.pageSizeValue)
                        : this.props.config.pageSize
                }
            });

            this.loadData({
                    ...this.state.state,
                    skip: 0,
                    take: this.state.pageSettings && this.state.pageSettings.pageSizeValue !== undefined
                        ? Number(this.state.pageSettings.pageSizeValue)
                        : this.props.config.pageSize
                }
            );
        }

        if (isColumnsChanged(this.state.config.columns, nextProps.config.columns)) {
            this.setState({
                config: {
                    ...this.state.config,
                    columns: [...nextProps.config.columns]
                }
            });
        }

        if (isGroupChanged(this.state.state.group?.map(item => item.field) ?? [], nextProps.config.groupBy)) {
            let gr = nextProps.config.groupBy.length > 0
                ? nextProps.config.groupBy.map(item => ({
                    field: item
                }))
                : undefined;

            this.setState({
                state: {
                    ...this.state.state,
                    group: gr
                }
            });

            this.loadData({
                ...this.state.state,
                group: gr
            });

            this.handleConfigSave({
                ...this.state.state,
                group: gr
            }, null, null);
        }

        if (this.state.locale !== getGridLocaleString(nextProps.i18n)) {
            this.setState({
                locale: getGridLocaleString(nextProps.i18n)
            });

            loadGridLocalization(nextProps.i18n);
        }
    }

    componentDidMount() {
        document.addEventListener('onGridMessage', this.handleUpdateEvent);

        this.setState({
            isMounted: true
        });
    }

    componentDidUpdate(_: any, prevState: Readonly<GridState>) {
        if (this.state.isMounted && !prevState.isMounted) {
            if (this.props.query) {
                this.localQuery = this.props.query;
            }

            this.loadData(this.state.state);
            this.isFirstApiCallDone = true;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('onGridMessage', this.handleUpdateEvent);

        this.state.api.cancelAllRequests();
    }

    handleUpdateEvent = (ev: any) => {
        if (ev && ev.detail && ev.detail.action && ev.detail.action !== '') {
            switch (ev.detail.action) {
                case 'updateRows':
                    if (ev.detail.rows && Array.isArray(ev.detail.rows)) {
                        if (ev.detail.clearSelection !== null && ev.detail.clearSelection !== undefined && ev.detail.clearSelection === true) {
                            this.setState({
                                selectedState: {}
                            });

                            if (this.props.onSelect) {
                                this.props.onSelect([]);
                            }
                        }

                        this.updateRowsData(ev.detail.rows);
                    }
                    break;
                case 'updateRow':
                    if (ev.detail.rows && Array.isArray(ev.detail.rows) && ev.detail.rows.length > 0) {
                        this.updateRowData(ev.detail.rows[0]);
                    }
                    break;
                case 'refresh':
                    if (ev.detail.gridId !== undefined && ev.detail.gridId !== null && ev.detail.gridId === this.props.id) {
                        this.loadData(this.state.state);
                    } else {
                        this.loadData(this.state.state);
                    }
                    break;
                case 'getItems':
                    if (ev.detail.callback !== null && ev.detail.callback !== undefined &&
                        ev.detail.gridId !== undefined && ev.detail.gridId !== null && ev.detail.gridId === this.props.id) {
                        let items: Array<any> = [];

                        if (this.state.state.group !== undefined && this.state.state.group.length > 0) {
                            this.state.data.data.forEach((group: any) => {
                                if (group.items !== undefined && group.items !== null) {
                                    group.items.forEach((innerItem: any) => {
                                        items.push(innerItem);
                                    });
                                }
                            });
                        } else {
                            this.state.data.data.forEach((item: any) => items.push(item));
                        }

                        ev.detail.callback(items);
                    }
                    break;
                case 'getItem':
                    if (ev.detail.callback !== null && ev.detail.callback !== undefined &&
                        ev.detail.field !== null && ev.detail.field !== undefined && ev.detail.field !== '' &&
                        ev.detail.value !== null && ev.detail.value !== undefined && ev.detail.value !== '') {
                        let item: any = null;
                        if (this.state.state.group !== undefined && this.state.state.group.length > 0) {
                            this.state.data.data.forEach((group: any) => {
                                if (group.items !== undefined && group.items !== null) {
                                    group.items.forEach((innerItem: any) => {
                                        if (innerItem[ev.detail.field] === ev.detail.value) {
                                            item = innerItem;
                                        }
                                    });
                                }
                            });
                        } else {
                            item = this.state.data.data.find(e => e[ev.detail.field] === ev.detail.value);
                        }

                        ev.detail.callback(item);
                    }
                    break;
                case 'selectItemByKey':
                    if (ev.detail.value !== null && ev.detail.value !== undefined && ev.detail.value !== '' &&
                        ev.detail.gridId !== null && ev.detail.gridId !== undefined && ev.detail.gridId !== '') {
                        if (ev.detail.gridId !== this.props.id) {
                            return;
                        }

                        let item: any = null;
                        if (this.state.state.group !== undefined && this.state.state.group.length > 0) {
                            this.state.data.data.forEach((group: any) => {
                                if (group.items !== undefined && group.items !== null) {
                                    group.items.forEach((innerItem: any) => {
                                        if (innerItem[ev.detail.field] === ev.detail.value) {
                                            item = innerItem;
                                        }
                                    });
                                }
                            });
                        } else {
                            item = this.state.data.data.find(e => e[GRID_DATA_ITEM_KEY] === ev.detail.value);
                        }

                        if (item !== undefined && item !== null) {
                            const selectedState: any = {};

                            selectedState[idGetter(item)] = true;

                            this.setState({selectedState});
                        }
                    }
                    break;
                case 'unSelectItemByKey':
                    if (ev.detail.value !== null && ev.detail.value !== undefined && ev.detail.value !== '' &&
                        ev.detail.gridId !== null && ev.detail.gridId !== undefined && ev.detail.gridId !== '') {
                        if (ev.detail.gridId !== this.props.id) {
                            return;
                        }

                        let item: any = null;
                        if (this.state.state.group !== undefined && this.state.state.group.length > 0) {
                            this.state.data.data.forEach((group: any) => {
                                if (group.items !== undefined && group.items !== null) {
                                    group.items.forEach((innerItem: any) => {
                                        if (innerItem[ev.detail.field] === ev.detail.value) {
                                            item = innerItem;
                                        }
                                    });
                                }
                            });
                        } else {
                            item = this.state.data.data.find(e => e[GRID_DATA_ITEM_KEY] === ev.detail.value);
                        }

                        if (item !== undefined && item !== null) {
                            const selectedState: any = {};

                            selectedState[idGetter(item)] = false;

                            this.setState({selectedState});
                        }
                    }
                    break;
            }
        }
    }

    updateRowsData(rows: Array<any>) {
        if (!rows || rows.length <= 0 || !this.state.data.data) {
            return;
        }

        if (this.state.state.group !== undefined && this.state.state.group.length > 0) {
            let localData: DataResult = {
                total: this.state.data.total,
                data: [
                    ...this.state.data.data.map(i => {
                        return {
                            ...i,
                            items: i.items.map((innerItem: any) => {
                                let updateRow = rows.find(e => e[GRID_DATA_ITEM_KEY] === innerItem[GRID_DATA_ITEM_KEY]);
                                if (updateRow !== null && updateRow !== undefined) {
                                    return {
                                        ...innerItem,
                                        ...updateRow
                                    };
                                }

                                return innerItem;
                            })
                        }
                    })
                ]
            };

            this.setState({
                data: localData
            });
        } else {
            let localData: DataResult = {
                total: this.state.data.total,
                data: [...this.state.data.data.map((item) => {
                    let updateRow = rows.find(e => e[GRID_DATA_ITEM_KEY] === item[GRID_DATA_ITEM_KEY]);
                    if (updateRow !== null && updateRow !== undefined) {
                        return {
                            ...item,
                            ...updateRow
                        };
                    }

                    return item;
                })]
            };

            this.setState({
                data: localData
            });
        }
    }

    updateRowData(rowData: any) {


        if (rowData[GRID_DATA_ITEM_KEY] === null ||
            rowData[GRID_DATA_ITEM_KEY] === undefined ||
            !this.state.data.data) {

            return;
        }

        if (this.state.state.group !== undefined && this.state.state.group.length > 0) {


            let item: any = null;

            this.state.data.data.forEach((group: any) => {
                if (group.items !== undefined && group.items !== null) {
                    group.items.forEach((innerItem: any) => {
                        if (innerItem[GRID_DATA_ITEM_KEY] === rowData[GRID_DATA_ITEM_KEY]) {

                            item = innerItem;
                        }
                    });
                }
            });

            if (!item) {

                return;
            }


            this.setState({
                data: {
                    ...this.state.data,
                    data: [
                        ...this.state.data.data.map(i => {
                            return {
                                ...i,
                                items: i.items.map((innerItem: any) => {
                                    if (innerItem[GRID_DATA_ITEM_KEY] === rowData[GRID_DATA_ITEM_KEY]) {
                                        return {
                                            ...innerItem,
                                            ...rowData
                                        };
                                    }

                                    return innerItem;
                                })
                            }
                        })
                    ]
                }
            });


        } else {

            let item = this.state.data.data.find(e => e[GRID_DATA_ITEM_KEY] === rowData[GRID_DATA_ITEM_KEY]);

            if (!item) {

                return;
            }

            this.setState({
                data: {
                    ...this.state.data,
                    data: [...this.state.data.data.map((item) => {
                        if (item[GRID_DATA_ITEM_KEY] === rowData[GRID_DATA_ITEM_KEY]) {

                            return {
                                ...item,
                                ...rowData
                            };
                        }

                        return item;
                    })]
                }
            });


        }
    }

    loadData(state: State) {
        if (this.state.skipLoading) {
            this.setState({
                selectedState: {},
                data: {
                    total: 0,
                    data: []
                },
                state: {
                    ...this.state.state,
                    group: []
                }
            });

            if (this.props.onSelect) {
                this.props.onSelect([]);
            }

            return;
        }

        this.showLoader();

        this.setState({
            selectedState: {}
        });

        if (this.props.onSelect) {
            this.props.onSelect([]);
        }

        this.state.api.execute(state, this.localQuery).then(data => {
            data.data.forEach(item => {
                Object.assign({selected: false}, item);
                Object.assign({isRowLoading: false}, item);

                if (this.props.allowEditing) {
                    Object.assign({[GRID_EDIT_FIELD]: undefined}, item);
                }
            });

            if ((state.group !== undefined && state.group.length > 0)) {
                this.setState({
                    data: {
                        total: data.total ?? 0,
                        data: data.data ? processWithGroups(data.data, state.group) : []
                    },
                    state: {
                        ...this.state.state,
                        group: [...state.group]
                    }
                });
            } else {
                this.setState({
                    data: {
                        total: data.total ?? 0,
                        data: data.data ?? [],
                    },
                    state: {
                        ...this.state.state,
                        group: []
                    }
                });
            }

            document.dispatchEvent(new CustomEvent('fromGridMessage', {
                detail: {
                    action: 'exportDataChanged',
                    gridId: this.props.id,
                    rows: data.data
                }
            }));

            this.hideLoader();

            setTimeout(() => {
                if (this.props.onDataLoad) {

                    this.props.onDataLoad();
                }
            }, 200);

        }).catch((err) => {
            if (err instanceof Error && err.message !== "canceled") {
                this.hideLoader();
            }
        });
    }


    onSelectionChange(event: GridSelectionChangeEvent) {
        if (event.nativeEvent !== null && event.nativeEvent !== undefined &&
            event.nativeEvent.target !== null && event.nativeEvent.target !== undefined &&
            (Array.from(event.nativeEvent.target.classList).includes('grid-prevent-selection') ||
                (event.nativeEvent.target.parentElement !== null && event.nativeEvent.target.parentElement !== undefined &&
                    Array.from(event.nativeEvent.target.parentElement.classList).includes('grid-prevent-selection')))) {
            return;
        }

        let items: Array<any> = [];

        if (this.state.state.group !== undefined && this.state.state.group.length > 0) {
            this.state.data.data.forEach((group: any) => {
                if (group.items !== undefined && group.items !== null) {
                    group.items.forEach((innerItem: any) => {
                        items.push(innerItem);
                    });
                }
            });
        } else {
            this.state.data.data.forEach((item: any) => items.push(item));
        }

        if (event.dataItem === undefined || event.dataItem === null) {
            if (this.selectionTimeout !== null) {
                this.setState({
                    selectedState: {}
                });

                if (this.props.onSelect) {
                    this.props.onSelect([]);

                    document.dispatchEvent(new CustomEvent('fromGridMessage', {
                        detail: {
                            action: 'exportDataChanged',
                            gridId: this.props.id,
                            selectedRows: [],
                            rows: items
                        }
                    }));
                }

                clearTimeout(this.selectionTimeout);
                this.selectionTimeout = null;
            } else {
                this.selectionTimeout = setTimeout(() => {
                    const selectedState = getSelectedState({
                        event,
                        selectedState: this.state.selectedState,
                        dataItemKey: GRID_DATA_ITEM_KEY,
                    });

                    let item = Object.keys(selectedState).length === 1
                        ? {
                            id: Object.keys(selectedState)[0]
                        } : null;

                    if (item &&
                        Object.keys(this.state.selectedState).length <= 2 &&
                        this.state.selectedState[idGetter(item)]) {
                        this.setState({
                            selectedState: {}
                        });

                        if (this.props.onSelect) {
                            this.props.onSelect([]);

                            document.dispatchEvent(new CustomEvent('fromGridMessage', {
                                detail: {
                                    action: 'exportDataChanged',
                                    gridId: this.props.id,
                                    selectedRows: [],
                                    rows: items
                                }
                            }));
                        }
                    } else {
                        this.setState({selectedState});

                        if (this.props.onSelect) {
                            let selectedRows = getSelectedRowsData(
                                this.state.data.data,
                                this.state.state.group ?? [],
                                selectedState
                            );

                            this.props.onSelect(selectedRows);

                            document.dispatchEvent(new CustomEvent('fromGridMessage', {
                                detail: {
                                    action: 'exportDataChanged',
                                    gridId: this.props.id,
                                    selectedRows: selectedRows,
                                    rows: items
                                }
                            }));
                        }
                    }

                    if (this.selectionTimeout !== null) {
                        clearTimeout(this.selectionTimeout)
                    }

                    this.selectionTimeout = null;
                }, 200);
            }
        } else {
            const selectedState = getSelectedState({
                event,
                selectedState: this.state.selectedState,
                dataItemKey: GRID_DATA_ITEM_KEY,
            });

            this.setState({selectedState});

            if (this.props.onSelect) {
                let selectedRows = getSelectedRowsData(
                    this.state.data.data,
                    this.state.state.group ?? [],
                    selectedState
                );

                this.props.onSelect(selectedRows);

                document.dispatchEvent(new CustomEvent('fromGridMessage', {
                    detail: {
                        action: 'exportDataChanged',
                        gridId: this.props.id,
                        selectedRows: selectedRows,
                        rows: items
                    }
                }));
            }
        }
    };

    onHeaderSelectionChange(event: GridHeaderSelectionChangeEvent) {
        if (this.props.selectionMode === 'single') {
            return;
        }

        const checkboxElement: any = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const selectedState: any = {};

        if (this.state.state.group !== undefined && this.state.state.group.length > 0) {
            this.state.data.data.forEach((item: any) => {
                if (item.items !== undefined && item.items !== null) {
                    item.items.forEach((innerItem: any) => {
                        selectedState[idGetter(innerItem)] = checked;
                    });
                }
            });
        } else {
            this.state.data.data.forEach((item: any) => {
                selectedState[idGetter(item)] = checked;
            });
        }

        this.setState({selectedState});

        if (this.props.onSelect) {
            this.props.onSelect(getSelectedRowsData(
                this.state.data.data,
                this.state.state.group ?? [],
                selectedState
            ));
        }
    };

    pageChange(event: GridPageChangeEvent) {
        this.setState({
            state: {
                ...this.state.state,
                skip: event.page.skip,
                take: event.page.take,
            },
            pageSettings: {
                ...this.state.pageSettings,
                pageSizeValue: event.targetEvent?.value ?? this.state.pageSettings?.pageSizeValue
            }
        });

        if (this.state.state.skip !== event.page.skip ||
            this.state.state.take !== event.page.take) {
            this.loadData({
                ...this.state.state,
                skip: event.page.skip,
                take: event.page.take,
            });
        }

        if (event.targetEvent?.value) {
            this.handleConfigSave(null, event.targetEvent.value, null);
        }
    };

    sortChange(event: GridSortChangeEvent) {
        let newState = {
            ...this.state.state,
            sort: event.sort.map(item => ({
                field: item.field,
                dir: item.dir
            }))
        };

        this.setState({
            state: newState
        });

        this.loadData(newState);

        this.handleConfigSave(newState, null, null);
    }

    handleContextMenu(ev: GridContextMenuEvent) {
        if (!this.state.showCellContextMenu || (ev.nativeEvent !== null && ev.nativeEvent !== undefined &&
            ev.nativeEvent.currentTarget !== null && ev.nativeEvent.currentTarget !== undefined &&
            ev.nativeEvent.currentTarget.nodeName === 'BODY')) {
            return;
        }

        ev.syntheticEvent.preventDefault();

        this.setState({
            contextMenuItems: []
        });

        if (this.props.contextMenuItems !== undefined && this.props.contextMenuItems.length > 0) {
            let fieldItems = this.props.contextMenuItems.filter(e => e.field === ev.field);

            if (fieldItems.length > 0) {
                this.setState({
                    contextMenuItems: [
                        ...fieldItems
                    ]
                });
            }
        }
        const {dataItem, field} = ev;
        this.contextMenuOffset = {left: ev.syntheticEvent.pageX, top: ev.syntheticEvent.pageY};

        if (!field)
            return;

        this.setState({
            isContextMenuShown: true
        });


        if (field && dataItem && dataItem[field]) {


            if (field.toLowerCase() == 'orderreleasexid' || field.toLowerCase() == 'orderreleasegid'
                || field.toLowerCase() == 'releasenumber') {
                if (field.toLowerCase() == 'orderreleasexid' || field.toLowerCase() == 'orderreleasegid')
                    this.contextMenuValueToCopy = dataItem['mainOrderReleaseXid'].replace('AAG.', '');
                if (field.toLowerCase() == 'releasenumber')
                    this.contextMenuValueToCopy = dataItem['mainId'].replace('AAG.', '');
            } else {
                this.contextMenuValueToCopy = dataItem[field];
            }

            this.contextMenuRow = dataItem;
        }
    }

    handleContextMenuOnClick(e: any) {
        switch (e.item.data.action) {
            case "copyCell":
                if (this.contextMenuValueToCopy) {

                    navigator.clipboard.writeText(this.contextMenuValueToCopy).then(() => {
                        //ignore;
                    });
                }
                break;
            default:
        }

        if (this.props.contextMenuItems !== undefined && this.props.contextMenuItems.length > 0) {
            let item = this.props.contextMenuItems.find(item => item.action === e.item.data.action);

            if (item !== null && item !== undefined && item.handler !== undefined && this.contextMenuRow) {
                item.handler(this.contextMenuRow);
            }
        }

        this.setState({
            isContextMenuShown: false
        });
    }

    onRowClick(ev: GridRowClickEvent) {
        if (this.props.onRowClick) {
            this.props.onRowClick(ev);
        }
    }

    onRowDoubleClick(ev: GridRowDoubleClickEvent) {
        if (this.props.onRowDoubleClick) {
            this.props.onRowDoubleClick(ev);
        } else if (this.props.onRowClick) {
            this.props.onRowClick(ev);
        }
    }

    expandChange(event: GridExpandChangeEvent) {
        const item = event.dataItem;

        if (item.groupId) {
            const newCollapsedIds = !event.value
                ? [...this.state.collapsedGroup, item.groupId]
                : this.state.collapsedGroup.filter(
                    (groupId) => groupId !== item.groupId
                );
            this.setState({
                collapsedGroup: newCollapsedIds,
            });
        } else {
            if (this.state.state.group === undefined || this.state.state.group.length <= 0) {
                let localData: DataResult = {
                    total: this.state.data.total,
                    data: [...this.state.data.data.map((inItem) => {
                        if (inItem[GRID_DATA_ITEM_KEY] === item[GRID_DATA_ITEM_KEY]) {
                            return {
                                ...inItem,
                                expanded: !item.expanded
                            };
                        }

                        return {
                            ...inItem
                        };
                    })]
                };

                this.setState({
                    data: localData
                });
            }
        }
    };

    enterEdit(dataItem: any, field?: string) {
        if (!this.props.allowEditing || !field) {
            return;
        }

        let col = this.state.config.columns.find(e => e.field === field);
        if (col && col.editable) {
            if (this.state.state.group !== undefined && this.state.state.group.length > 0) {
                let localData: DataResult = {
                    total: this.state.data.total,
                    data: [
                        ...this.state.data.data.map(i => {
                            return {
                                ...i,
                                items: i.items.map((innerItem: any) => {
                                    return {
                                        ...innerItem,
                                        [GRID_EDIT_FIELD]: innerItem[GRID_DATA_ITEM_KEY] === dataItem[GRID_DATA_ITEM_KEY]
                                            ? field
                                            : undefined
                                    };
                                })
                            }
                        })
                    ]
                };

                this.setState({
                    data: localData
                });
            } else {
                let editItem = this.state.data.data.find(e => e[GRID_EDIT_FIELD] !== undefined);

                if (editItem && this.props.onCellEditEnd) {
                    this.props.onCellEditEnd({
                        dataItem: editItem,
                        changed: {
                            field: editItem[GRID_EDIT_FIELD],
                            value: editItem[editItem[GRID_EDIT_FIELD]]
                        }
                    });
                }

                let localData: DataResult = {
                    total: this.state.data.total,
                    data: [...this.state.data.data.map((item) => {
                        return {
                            ...item,
                            [GRID_EDIT_FIELD]: item[GRID_DATA_ITEM_KEY] === dataItem[GRID_DATA_ITEM_KEY]
                                ? field
                                : undefined
                        };
                    })]
                };

                this.setState({
                    data: localData
                });
            }
        }
    };

    exitEdit() {
        if (!this.props.allowEditing) {
            return;
        }

        if (this.state.state.group !== undefined && this.state.state.group.length > 0) {
            let localData: DataResult = {
                total: this.state.data.total,
                data: [
                    ...this.state.data.data.map(i => {
                        return {
                            ...i,
                            items: i.items.map((innerItem: any) => {
                                return {
                                    ...innerItem,
                                    [GRID_EDIT_FIELD]: undefined
                                };
                            })
                        }
                    })
                ]
            };

            this.setState({
                data: localData
            });
        } else {
            let editItem = this.state.data.data.find(e => e[GRID_EDIT_FIELD] !== undefined);

            if (editItem && this.props.onCellEditEnd) {
                this.props.onCellEditEnd({
                    dataItem: editItem,
                    changed: {
                        field: editItem[GRID_EDIT_FIELD],
                        value: editItem[editItem[GRID_EDIT_FIELD]]
                    }
                });
            }

            let localData: DataResult = {
                total: this.state.data.total,
                data: [...this.state.data.data.map((item) => {
                    return {
                        ...item,
                        [GRID_EDIT_FIELD]: undefined
                    };
                })]
            };

            this.setState({
                data: localData
            });
        }
    }

    editItemChange(ev: GridItemChangeEvent) {
        if (this.state.state.group !== undefined && this.state.state.group.length > 0) {
            let item: any = null;

            this.state.data.data.forEach((group: any) => {
                if (group.items !== undefined && group.items !== null) {
                    group.items.forEach((innerItem: any) => {
                        if (innerItem[GRID_DATA_ITEM_KEY] === ev.dataItem[GRID_DATA_ITEM_KEY]) {
                            item = innerItem;
                        }
                    });
                }
            });

            if (!item) {
                return;
            }


            this.setState({
                data: {
                    ...this.state.data,
                    data: [
                        ...this.state.data.data.map(i => {
                            return {
                                ...i,
                                items: i.items.map((innerItem: any) => {
                                    if (innerItem[GRID_DATA_ITEM_KEY] === ev.dataItem[GRID_DATA_ITEM_KEY]) {
                                        return {
                                            ...innerItem,
                                            [ev.field as any]: ev.value
                                        };
                                    }

                                    return innerItem;
                                })
                            }
                        })
                    ]
                }
            });
        } else {
            let item = this.state.data.data.find(e => e[GRID_DATA_ITEM_KEY] === ev.dataItem[GRID_DATA_ITEM_KEY]);

            if (!item) {
                return;
            }

            this.setState({
                data: {
                    ...this.state.data,
                    data: [...this.state.data.data.map((item) => {
                        if (item[GRID_DATA_ITEM_KEY] === ev.dataItem[GRID_DATA_ITEM_KEY]) {
                            return {
                                ...item,
                                [ev.field as any]: ev.value
                            };
                        }

                        return item;
                    })]
                }
            });
        }
    }

    showLoader() {
        this.loaderOverlayReference.current?.classList.remove(cl.hidden);
        this.loaderReference.current?.classList.remove(cl.hidden);
    }

    hideLoader() {
        this.loaderOverlayReference.current?.classList.add(cl.hidden);
        this.loaderReference.current?.classList.add(cl.hidden);
    }

    handleConfigSave(state: State | null,
                     pageSizeValue: number | null,
                     columns: {
                         columns: Array<GridColumnProps>,
                         reorderFieldId?: string,
                     } | null) {
        if (this.isActionsDisabled) {
            return;
        }

        (async () => {
            if (!this.props.handleConfigSave) {
                return;
            }

            this.isActionsDisabled = true;

            let cols = updateColumns(columns, this.state.config.columns);

            await this.props.handleConfigSave({
                columns: getGridConfigColumns(cols),
                groupBy: state
                    ? state.group?.map(item => item.field) ?? []
                    : this.state.state.group?.map(item => item.field) ?? [],
                sortBy: state
                    ? state.sort?.map(item => ({
                    field: item.field,
                    dir: item.dir
                })) ?? []
                    : this.state.state.sort?.map(item => ({
                    field: item.field,
                    dir: item.dir
                })) ?? [],
                pageSize: pageSizeValue !== null
                    ? pageSizeValue
                    : this.state.pageSettings?.pageSizeValue ?? 25
            } as GridConfig);

            this.isActionsDisabled = false;
        })();
    }

    render() {
        return (
            <GridContext.Provider value={{
                refreshData: () => {
                    this.loadData(this.state.state)
                },
                updateRowData: this.updateRowData
            }}>
                <GridRowContext.Provider value={{
                    onRowClick: this.onRowClick,
                    onRowDoubleClick: this.onRowDoubleClick
                }}>
                    <div
                        className={`h100 w100 hidden relative grid ${isMobileOnly ? 'mobile' : ''} ${!this.state.isMounted ? cl.hidden : ''}`}>
                        <div id={`${this.props.id}-loader-overlay`}
                             className={`${cl.loaderOverlay} ${cl.hidden}`}
                             ref={this.loaderOverlayReference}>
                        </div>
                        {document.querySelector('.k-grid-container') && createPortal(
                            <div ref={this.loaderReference}
                                 id={`${this.props.id}-loader-container`}
                                 className={`${cl.loaderContainer} ${cl.hidden}`}>
                                <div>
                                    <Loader type={LoaderType.Action} style={{scale: '0.4'}}/>
                                </div>
                            </div>,
                            document.getElementById(this.state.id)?.querySelector('.k-grid-container') ??
                            document.querySelector('.k-grid-container')!
                        )}
                        <TelerikGrid id={this.state.id}
                                     style={{width: this.state.width, height: this.state.height}}
                                     data={{
                                         total: this.state.data.total,
                                         data: processData(
                                             this.state.data.data,
                                             this.state.state.group ?? [],
                                             this.state.selectedState,
                                             this.state.collapsedGroup)
                                     }}

                                     pageable={this.state.pageSettings}
                                     sortable={this.state.allowSorting}
                                     resizable={this.state.allowResizing}
                                     reorderable={this.state.allowReordering}

                                     selectable={this.state.selectionSettings}
                                     dataItemKey={GRID_DATA_ITEM_KEY}
                                     selectedField={GRID_SELECTED_FIELD}
                                     onSelectionChange={this.onSelectionChange}
                                     onHeaderSelectionChange={this.onHeaderSelectionChange}

                                     groupable={this.state.allowGrouping}

                                     cells={{
                                         headerCell: this.state.config.headerTemplate,
                                         data: this.state.config.cellTemplate,
                                         group: {
                                             groupHeader: this.state.config.groupTemplate
                                         }
                                     }}

                                     expandField="expanded"
                                     onExpandChange={this.expandChange}
                                     onColumnResize={(ev) => {
                                         if (ev.end) {
                                             this.handleConfigSave(null, null, {
                                                 columns: ev.columns
                                             });
                                         }
                                     }}
                                     onColumnReorder={(ev) => {
                                         ev.nativeEvent.preventDefault();
                                         this.handleConfigSave(null, null, {
                                             columns: ev.columns,
                                             reorderFieldId: ev.columnId,
                                         });
                                     }}

                                     onPageChange={this.pageChange}
                                     onSortChange={this.sortChange}
                                     onContextMenu={this.handleContextMenu}
                                     onDataStateChange={(e: GridDataStateChangeEvent) => {
                                         this.loadData(e.dataState);
                                     }}

                                     editField={GRID_EDIT_FIELD}
                                     rowRender={this.props.allowEditing
                                         ? (tr: React.ReactElement<HTMLTableRowElement>,
                                            props: GridRowProps) => {
                                             return (
                                                 <EditRowRender originalProps={props}
                                                                tr={tr}
                                                                exitEdit={this.exitEdit}
                                                                editField={GRID_EDIT_FIELD}/>
                                             );
                                         } : undefined}
                                     cellRender={this.props.allowEditing
                                         ? (td: React.ReactElement<HTMLTableCellElement> | null,
                                            props: GridCellProps) => {
                                             return (
                                                 <EditCellRender originalProps={props}
                                                                 td={td as any}
                                                                 enterEdit={this.enterEdit}
                                                                 editField={GRID_EDIT_FIELD}
                                                                 allowEditingColumns={this.state.config.columns.filter(e => e.editable)}/>
                                             );
                                         } : undefined}
                                     onItemChange={this.editItemChange}

                                     detail={this.props.detailComponent ?? null}
                                     {...this.state.state}>
                            {this.state.config.columns?.map((column) => {
                                if (!column.field || column.order === undefined || !column.visible) {
                                    return null;
                                }

                                if (column.field === GRID_SELECTED_FIELD &&
                                    this.state.selectionSettings.enabled !== undefined &&
                                    this.state.selectionSettings.enabled) {
                                    return (
                                        <GridColumn key={column.field}
                                                    field={column.field}
                                                    orderIndex={column.order}
                                                    width="25px"
                                                    resizable={false}
                                                    reorderable={false}
                                                    className={'constantly-locked'}
                                                    headerClassName={'header-constantly-locked checkbox'}
                                                    sortable={false}
                                                    groupable={false}
                                                    locked={true}

                                                    headerSelectionValue={(() => {
                                                        if (this.state.state.group !== undefined &&
                                                            this.state.state.group.length > 0) {

                                                        } else {
                                                            return this.state.data.data.findIndex(
                                                                (item) => !this.state.selectedState[idGetter(item)]
                                                            ) === -1
                                                        }
                                                    })()}
                                        />
                                    );
                                }

                                return (
                                    <GridColumn key={column.field}
                                                field={column.field}
                                                width={column.width}
                                                title={column.title ? this.props.t(column.title) : ' '}
                                                orderIndex={column.order}

                                                cells={{
                                                    data: column.editable
                                                        ? column.template
                                                        : (column.template ?? this.state.config.cellTemplate)
                                                }}

                                                className={column.constantlyLocked !== undefined && column.constantlyLocked
                                                    ? 'constantly-locked'
                                                    : ''}
                                                headerClassName={column.constantlyLocked !== undefined && column.constantlyLocked
                                                    ? 'header-constantly-locked'
                                                    : ''}

                                                sortable={column.allowSorting}
                                                locked={column.locked}
                                                reorderable={column.locked !== undefined && column.locked
                                                    ? false
                                                    : column.allowReordering}
                                                resizable={column.allowResizing}
                                                groupable={column.allowGrouping}
                                                editable={column.editable}
                                    />
                                );
                            })}
                        </TelerikGrid>
                        {this.state.showCellContextMenu &&
                            <ContextMenu className={'grid-context-menu'}
                                         show={this.state.isContextMenuShown}
                                         onSelect={this.handleContextMenuOnClick}
                                         offset={this.contextMenuOffset}
                                         onClose={() => this.setState({isContextMenuShown: false})}>
                                <MenuItem text={this.props.t('shared.btn.copy-cell')}
                                          data={{action: 'copyCell'}}/>
                                {this.state.contextMenuItems.map((item) => {
                                    return (
                                        <MenuItem key={item.action}
                                                  text={item.text}
                                                  data={{action: item.action}}
                                                  icon={item.icon}/>
                                    );
                                })}
                            </ContextMenu>
                        }
                    </div>
                </GridRowContext.Provider>
            </GridContext.Provider>
        );
    }
}

export default React.memo(withTranslation()(Grid));
