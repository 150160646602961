import React, {ReactNode, useLayoutEffect, useRef, useState} from 'react';
import {
    GridActionRowButtonProps,
    isGridActionRowToggleButtonProps
} from "../GridActionRow/components/GridActionRowButton/GridActionRowButton";
import GridActionRowSelectOption, {
    GridActionRowSelectOptionProps
} from "../GridActionRow/components/GridActionRowSelect/GridActionRowSelectOption/GridActionRowSelectOption";
import GridActionRowSelect, {
    GridActionRowSelectProps
} from "../GridActionRow/components/GridActionRowSelect/GridActionRowSelect";
import cl from './HardGridActionRow.module.css';
import HardGridActionRowSelectorsSection
    from "./components/HardGridActionRowSelectorsSection/HardGridActionRowSelectorsSection";
import HardGridActionRowButtonsSection
    from "./components/HardGridActionRowButtonsSection/HardGridActionRowButtonsSection";
import {GridActionRowView} from "../GridActionRow/utils/gridActionRowContextType";
import {debounce} from "debounce";

type HardGridActionRowSectionRenderProps = {
    availableWidth: number;
    view: GridActionRowView;
}

export type GridActionRowSelectorProps = {
    selector: Omit<GridActionRowSelectProps, "children">;
    options: Array<GridActionRowSelectOptionProps>;
}

type HardGridActionRowProps = {
    id: string;
    title?: string | GridActionRowSelectorProps;
    buttons?: Array<GridActionRowButtonProps>;
    actionButton?: ReactNode;
    selectors?: Array<GridActionRowSelectorProps>
    children?: ReactNode | ((props: HardGridActionRowSectionRenderProps) => JSX.Element);
};

const getSectionsWidth = (sections: Array<ChildNode>, view: GridActionRowView): number => {
    return sections.reduce((acc, val) => {
        let element = val as HTMLDivElement;

        if (!element) {
            return acc;
        }

        let elemWidth = Array.from(element.children)
            .filter(e => !e.classList.contains('skip'))
            .reduce((innerAcc, innerVal) => {
                return innerAcc + parseFloat(getComputedStyle(innerVal, null).getPropertyValue('width'));
            }, 0);

        if (view === 'PC') {
            let skipWidth = Array.from(element.children)
                .filter(e => e.classList.contains('skip'))
                .reduce((innerAcc, innerVal) => {
                    return innerAcc + parseFloat(getComputedStyle(innerVal, null).getPropertyValue('width'));
                }, 0);

            if (elemWidth < (element.clientWidth - skipWidth)) {
                elemWidth = elemWidth + (element.clientWidth - skipWidth - elemWidth);
            }
        }

        return acc + elemWidth;
    }, 0) ?? 0;
};

const HardGridActionRow: React.FC<HardGridActionRowProps> = ({
                                                                 id,
                                                                 title,
                                                                 buttons,
                                                                 selectors,
                                                                 actionButton,
                                                                 children
                                                             }) => {
    const ref = useRef<HTMLDivElement | null>(null);

    const [view, setView] = useState<GridActionRowView>('Undefined');
    const [availableWidth, setAvailableWidth] = useState<number>(0);

    useLayoutEffect(() => {
        if (!ref.current) {
            return;
        }

        const onResize = debounce(() => {
            if (!ref.current) {
                return;
            }

            let sections = Array.from(ref.current?.childNodes);

            if (sections.length <= 0) {
                return;
            }

            let sectionsWidth = getSectionsWidth(sections, view);

            let containerWidth = document.getElementById(id)?.clientWidth ?? 0;

            if (containerWidth > 0) {
                containerWidth -= 10;
            }

            if (containerWidth >= sectionsWidth) {
                if (view === 'PC') {
                    setAvailableWidth(Number((containerWidth - sectionsWidth).toFixed(2)));
                } else if (view === 'Mobile') {
                    let title = (sections[0] as HTMLDivElement).querySelector('.grid-entity-label');

                    if (title) {
                        setAvailableWidth((sections[0] as HTMLDivElement).clientWidth - title.clientWidth);
                    } else {
                        setAvailableWidth((sections[0] as HTMLDivElement).clientWidth);
                    }
                }
            } else {
                if (view === 'PC') {
                    setAvailableWidth(0);
                } else if (view === 'Mobile') {
                    let title = (sections[0] as HTMLDivElement).querySelector('.grid-entity-label');

                    if (title) {
                        setAvailableWidth((sections[0] as HTMLDivElement).clientWidth - title.clientWidth);
                    } else {
                        setAvailableWidth((sections[0] as HTMLDivElement).clientWidth);
                    }
                }
            }

            if (containerWidth > sectionsWidth) {
                setView(prev => {
                    if (prev === 'Mobile' && buttons && buttons.length > 0) {
                        let btns = buttons.filter(btn => !isGridActionRowToggleButtonProps(btn));
                        if (btns.length > 0) {
                            let predictedWidth = btns.length * 32 + (btns.length - 1) * 10;

                            if (containerWidth <= (sectionsWidth + predictedWidth)) {
                                return 'Mobile';
                            }
                        }

                    }

                    return 'PC';
                });
            } else if (containerWidth <= sectionsWidth) {
                setView('Mobile');
            }
        }, 10);

        const observer = new ResizeObserver(onResize);

        observer.observe(ref.current);

        onResize();

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [view, title, actionButton]);

    return (
        <div id={id}
             ref={ref}
             className={cl.container}
             data-children={children ? 'true' : 'false'}
             data-view={view}>
            <div>
                {title &&
                    <>
                        {typeof title === 'string'
                            ? <div className={'grid-entity-label prevent-selection'}>
                                {title}
                            </div>
                            : <div className={'grid-entity-label prevent-selection'}>
                                <GridActionRowSelect key={`${id}-menu-selector`}
                                                     disabled={title.selector.disabled}
                                                     width={title.selector.width}
                                                     onChange={title.selector.onChange}>
                                    {title.options.map(opt => {
                                        return (
                                            <GridActionRowSelectOption key={opt.id}
                                                                       id={opt.id}
                                                                       selected={opt.selected}
                                                                       text={opt.text}
                                                                       tooltip={opt.tooltip}
                                                                       description={opt.description}/>
                                        );
                                    })}
                                </GridActionRowSelect>
                            </div>
                        }
                    </>
                }

                {availableWidth > 0 &&
                    <>
                        {children && typeof children === 'function'
                            ? <div className={'skip'}
                                   style={{
                                       overflow: 'hidden',
                                       padding: view === 'PC' ? '0 10px' : '0 0 0 10px',
                                       height: '100%',
                                       flex: '1',
                                       position: "relative"
                                   }}>
                                {children({
                                    availableWidth: view === 'PC' ? availableWidth - 20 : availableWidth - 10,
                                    view
                                })}
                            </div>
                            : <div>
                                {children}
                            </div>
                        }
                    </>
                }
            </div>

            {buttons && buttons.length > 0 && selectors && selectors.length > 0
                ? <div className={`flex flex-gap-20 flex-center-vertically`}>
                    <div className={`flex flex-gap-10 flex-center-vertically`}>
                        <HardGridActionRowSelectorsSection id={id} selectors={selectors}/>
                    </div>

                    <div className={`flex flex-gap-10 flex-center-vertically`}>
                        {actionButton && <>{actionButton}</>}

                        <HardGridActionRowButtonsSection id={id} buttons={buttons} view={view}/>
                    </div>
                </div>
                : null
            }

            {buttons && buttons.length > 0 && (!selectors || selectors.length <= 0)
                ? <div className={`flex flex-gap-10 flex-center-vertically`}>
                    {actionButton && <>{actionButton}</>}

                    <HardGridActionRowButtonsSection id={id} buttons={buttons} view={view}/>
                </div>
                : null
            }

            {selectors && selectors.length > 0 && (!buttons || buttons.length <= 0)
                ? <div className={`flex flex-gap-10 flex-center-vertically`}>
                    <HardGridActionRowSelectorsSection id={id} selectors={selectors}/>
                </div>
                : null
            }

            {actionButton && (!selectors || selectors.length <= 0) && (!buttons || buttons.length <= 0)
                ? <div>
                    {actionButton}
                </div>
                : null
            }
        </div>
    );
};

export default HardGridActionRow;