import React, {ComponentPropsWithoutRef} from 'react';
import cl from './Button.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

export enum ButtonType {
    Primary = 0,
    Secondary,
    Warning,
    Error,
    Transparent,
    Third
}

export type ButtonProps = {
    buttonType: ButtonType;
    isLoading?: boolean;
} & ComponentPropsWithoutRef<"button">;

const Button: React.FC<ButtonProps> = ({
                                           children,
                                           isLoading,
                                           buttonType,
                                           className,
                                           ...rest
                                       }) => {
    return (
        <button className={`${cl.button} ${className}`}
                data-type={ButtonType[buttonType].toString().toLowerCase()}
                {...rest}>
            <>
                {isLoading !== undefined && isLoading
                    ? <span className={cl.loadingSpan}><FontAwesomeIcon icon={faSpinner}/></span>
                    : null
                }
                {children}
            </>
        </button>
    );
};

export default Button;
