import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useOfferApi} from "../../../../../../app/api/offer";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import FormLoadingScreen from "../../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import Button, {ButtonType} from "../../../../../../components/ui/Button/Button";
import {
    IConfirmAuctionContractGetAction
} from "../../../../../../app/interfaces/offer/IConfirmAuctionContractGetAction";
import {
    IConfirmAuctionContractPostAction
} from "../../../../../../app/interfaces/offer/IConfirmAuctionContractPostAction";
import ConfirmAuctionFormForwarder from "./ConfirmAuctionFormForwarder/ConfirmAuctionFormForwarder";
import {IError} from "../../../../../../app/interfaces/response/IResponse";
import {BLErrorCode} from "../../../../../../app/enums/BLErrorCode";
import Alert from "../../../../../../components/ui/Alert/Alert";

type ConfirmAuctionForwarderProps = {
    id: string;
    close?: () => void;
};

const ConfirmAuctionForwarder: React.FC<ConfirmAuctionForwarderProps> = ({
                                                                             id,
                                                                             close
                                                                         }) => {
    const {t} = useTranslation();
    const [model, setModel] = useState<IConfirmAuctionContractGetAction | null>(null);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError>>([]);

    const {
        getConfirmAuctionContractData: {
            isLoading,
            cancel,
            query: getConfirmAuctionContractData
        },
        confirmAuctionContract: {
            cancel: cancelConfirmAuctionContract,
            mutation: confirmAuctionContract
        }
    } = useOfferApi();

    useEffect(() => {
        if (!id || id === '') {
            if (close) {
                close();
            }

            return;
        }

        (async () => {
            try {
                const response = await getConfirmAuctionContractData(id);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error<string>('Unable to get confirm auction contract data');

                    if (close) {
                        close();
                    }
                }
            }
        })();

        return () => {
            cancel();
            cancelConfirmAuctionContract();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const save = async (model: IConfirmAuctionContractPostAction): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            const response = await confirmAuctionContract(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [...id].map((item) => {
                            return {
                                id: item,
                                isRowLoading: true
                            };
                        })
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.offer")} ${model.orderReleaseGid}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotFound &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();
                    toast.error(err.message);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if (!id || id === '') {
        return null;
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                {errors.length > 0 && errors.map((err, index) => {
                    switch (err.errorCode) {
                        case BLErrorCode.InnerError:
                            return (
                                <Alert type={'Error'}
                                       id={`confirm-auction-error-${index}`}>
                                    {err.description}
                                </Alert>
                            );
                        case BLErrorCode.ContractNotFound:
                            return (
                                <Alert type={'Error'}
                                       id={`confirm-auction-error-${index}`}>
                                    {t("offer.errors.contract-not-found")}
                                </Alert>
                            );
                        case BLErrorCode.NoAccess:
                            return (
                                <Alert type={'Error'}
                                       id={`confirm-auction-error-${index}`}>
                                    {t("offer.errors.no-access")}
                                </Alert>
                            );
                    }

                    return null;
                })}

                <ConfirmAuctionFormForwarder model={model}
                                             save={save}
                                             disabled={disabled}
                                             setIsSubmitDisabled={setIsSubmitDisabled}/>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={isSubmitDisabled || disabled}
                        form='confirm-auction-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.confirm")}
                </Button>
            </div>
        </div>
    );
};

export default ConfirmAuctionForwarder;