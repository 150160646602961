import {IShipmentEvent} from "../../../../../../../../app/interfaces/shipment/IShipmentEvent";
import {IShipment} from "../../../../../../../../app/interfaces/shipment/IShipment";

export type ShipmentEventsLineExtendedShipmentEvent = {
    line: 'Solid' | 'None';
    passed: boolean;
    isLast: boolean;
    isLatest: boolean;
} & IShipmentEvent;

export type ShipmentEventsLineExtendedShipment = {
    shipmentEvents: Array<ShipmentEventsLineExtendedShipmentEvent> | null;
} & Partial<IShipment>;

export const buildShipmentEventsLineShipment = (shipment: Partial<IShipment>): ShipmentEventsLineExtendedShipment | null => {
    if (!shipment.shipmentEvents || shipment.shipmentEvents.length <= 0) {
        return null;
    }

    let events: Array<ShipmentEventsLineExtendedShipmentEvent> = [];

    let latestStopPoint = getLatestStopPoint(shipment.shipmentEvents);
    let latestEvent = getLatestEvent(shipment.shipmentEvents);

    shipment.shipmentEvents.forEach(item => {
        let isLast: boolean = latestStopPoint !== null &&
            latestStopPoint.spPosition === item.spPosition &&
            latestStopPoint.spSubPosition === item.spSubPosition;

        let isLatest: boolean = latestEvent !== null &&
            latestEvent.spPosition === item.spPosition &&
            latestEvent.spSubPosition === item.spSubPosition;

        events.push({
            ...item,
            line: isLast ? 'None' : 'Solid',
            passed: item.factDate !== null && item.factDate !== undefined,
            isLast: isLast,
            isLatest: isLatest,
            isHidden: false
        });
    });

    events = events.sort((a, b) => {
        return a.sortPosition - b.sortPosition;
    });

    return {
        ...shipment,
        shipmentEvents: events
    };
}

const getLatestEvent = (events: Array<IShipmentEvent>): IShipmentEvent | null => {
    if (events.length <= 0) {
        return null;
    }


    return events
        .filter(e => e.factDate !== null && e.factDate !== undefined)
        .sort(function (a, b) {
            return a.spPosition - b.spPosition || a.spSubPosition - b.spSubPosition;
        }).pop() ?? null;
}

const getLatestStopPoint = (events: Array<IShipmentEvent>): IShipmentEvent | null => {
    if (events.length <= 0) {
        return null;
    }

    return events
        .sort(function (a, b) {
            return b.spPosition - a.spPosition || b.spSubPosition - a.spSubPosition;
        })[0];
}