import React from 'react';
import {IUser} from "../../../../../app/interfaces/user/IUser";
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import AddExpense from "./AddExpense";
import {getUserRole} from "../../../../../helpers/user";
import {UserRole} from "../../../../../app/enums/UserRole";

type AddExpenseDialogProps = {
    user?: IUser;
    shipmentGid: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddExpenseDialog: React.FC<AddExpenseDialogProps> = ({
                                                               user,
                                                               show,
                                                               shipmentGid,
                                                               setShow
                                                           }) => {
    const {t} = useTranslation();


    return (
        <Dialog id={'add-expense-dialog'}
                show={show}
                setShow={setShow}
                width={'850px'}
                title={!user || getUserRole(user) === UserRole.ServiceProvider ?   t("self-billing.btn.add-expense") : t("self-billing.btn.add-expense-fw")}>
            <AddExpense shipmentGid={shipmentGid}
                        user={user}
                        close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default AddExpenseDialog;
