import React, {useEffect, useState} from 'react';
import {useShipmentApi} from "../../../../../app/api/shipment";
import {toast} from "react-toastify";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {IRefinePositionGetAction} from "../../../../../app/interfaces/shipment/IRefinePositionGetAction";
import cl from './RefinePosition.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import Input from "../../../../../components/ui/Input/Input";
import RefinePositionMap from "./components/RefinePositionMap";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {isAxiosError} from "axios";
import {useTranslation} from "react-i18next";

type RefinePositionProps = {
    shipmentGid: string;
    locationGid: string;
    close?: () => void;
}
const RefinePosition: React.FC<RefinePositionProps> = ({
                                                           shipmentGid,
                                                           locationGid,
                                                           close
                                                       }) => {
    const {t} = useTranslation();
    const [model, setModel] = useState<IRefinePositionGetAction | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        getRefinePositionData: {
            query: getRefinePositionData,
            isLoading,
            cancel: cancelGetRefinePositionData
        },
        refinePosition: {
            mutation: refinePosition,
            cancel: cancelRefinePosition
        }
    } = useShipmentApi();

    useEffect(() => {
        if (shipmentGid === '' || locationGid === '') {
            if (close) {
                close()
            } else {
                return;
            }
        }

        (async () => {
            try {
                const response = await getRefinePositionData(shipmentGid, locationGid);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch {
                if (close) {
                    close();
                } else {
                    toast.error<string>('Unable to get change refine position data');
                }
            }
        })();

        return () => {
            cancelGetRefinePositionData();
            cancelRefinePosition();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipmentGid, locationGid]);

    const save = async (lat: number, lng: number): Promise<void> => {
        setDisabled(prev => !prev);

        if (!model) {
            return;
        }

        try {
            const response = await refinePosition({
                address: model.address ?? '',
                shipmentGid: model.shipmentGid,
                locationGid: model.locationGid,
                lt: lat,
                lg: lng
            });

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [
                            {
                                id: shipmentGid,
                                isRowLoading: true
                            }
                        ]
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.shipment")} ${shipmentGid}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (close) {
                    close();
                }
                toast.error(err.message);
            }
        }

        setDisabled(prev => !prev);
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`98vh`} style={{padding: '5px'}}/>
        );
    }

    return (
        <div className={cl.container}>
            <div className={cl.header}>
                <div>
                    <Input disabled={disabled || model.fromTranspareon}
                           value={model.address ?? ''}
                           onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                               setModel({
                                   ...model,
                                   address: ev.target.value
                               });
                           }}/>
                </div>
                <div>
                    <FontAwesomeIcon icon={faXmark}
                                     className={cl.close}
                                     onClick={() => {
                                         if (close && !disabled) {
                                             close();
                                         }
                                     }}/>
                </div>
            </div>
            <div>
                <RefinePositionMap model={model}
                                   save={save}
                                   disabled={disabled}
                                   onChange={(item) => setModel(item)}/>
            </div>
        </div>
    );
};

export default RefinePosition;