import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../ui/Dialog/Dialog";
import EditTag from "./EditTag";

type EditTagDialogProps = {
    id: number;
    orderReleaseGid: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditTagDialog: React.FC<EditTagDialogProps> = ({
                                                         id,
                                                         show,
                                                         orderReleaseGid,
                                                         setShow
                                                     }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'edit-tag-dialog'}
                show={show}
                setShow={setShow}
                width={'500px'}
                title={t("tags.labels.tag-edit")}>
            <EditTag id={id}
                     orderReleaseGid={orderReleaseGid}
                     close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default EditTagDialog;