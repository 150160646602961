import React from 'react';
import {IQuote} from "../../../../../../../app/interfaces/quote/IQuote";
import {useTranslation} from "react-i18next";

type RefFieldProps = {
    quote: IQuote;
}

const spanStyle: React.CSSProperties = {
    color: 'white',
    backgroundColor: '#c9302c',
    padding: '2px 5px',
    borderRadius: '2px'
}

const RefField: React.FC<RefFieldProps> = ({
                                               quote
                                           }) => {
    const {t} = useTranslation();

    if (!quote.isRef) {
        return null;
    }

    return (
        <div className={'details-box-item-field-value ref-value'}>
            <span style={spanStyle}>
                {t("quote.form.ref")}
            </span>
        </div>
    );
};

export default RefField;