import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import CancelOffer from "./CancelOffer";

type CancelOfferDialogProps = {
    id: string | Array<string>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const CancelOfferDialog: React.FC<CancelOfferDialogProps> = ({
                                                                 id,
                                                                 setShow,
                                                                 show
                                                             }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'cancel-offer-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={Array.isArray(id) && id.length > 1
                    ? t("shared.btn.cancel-offers")
                    : t("shared.btn.cancel-offer")}>
            <CancelOffer id={id}
                         close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default CancelOfferDialog;