import React, {useState} from 'react';
import cl from './SelfBillingSupport.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useDocumentTitle from "../../../../../hooks/useDocumentTitle/useDocumentTitle";
import useGoogleReCaptchaV3 from "../../../../../hooks/useGoogleReCaptchaV3/useGoogleReCaptchaV3";
import {useAccountApi} from "../../../../../app/api/account";
import useDefaultSiteLanguages from "../../../../../hooks/useDefaultSiteLanguages/useDefaultSiteLanguages";
import useMediaQuery from "../../../../../hooks/useMediaQuery/useMediaQuery";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {BLErrorCode} from "../../../../../app/enums/BLErrorCode";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import bg_image from "../../../../../assets/images/login-bg.jpg";
import Container from "../../../../../components/ui/Container/Container";
import Card from "../../../../../components/ui/Card/Card";
import LanguageSwitcher from "../../../../../components/ui/LanguageSwitcher/LanguageSwitcher";
import Alert from "../../../../../components/ui/Alert/Alert";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import CopyRightFooter from "../../../../../components/ui/CopyRightFooter/CopyRightFooter";
import BackgroundImageContainer from "../../../../../components/ui/BackgroundImageContainer/BackgroundImageContainer";
import SupportForm from "../../../Support/SupportForm";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import AssignShipment from "../../../../authorized/Shipment/actions/AssignShipment/AssignShipment";

const alertStyle: React.CSSProperties = {
    margin: '0px 0px 5px 0px',
    fontSize: '11px',
    padding: '5px 10px',
    fontWeight: 'bold',
    justifyContent: 'center',
    textAlign: 'center'
};

const SelfBillingSupport: React.FC = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    useDocumentTitle({title: t("self-billing.labels.support-title")});

    const {loaded, executeReCaptcha} = useGoogleReCaptchaV3({
        siteKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '',
        language: i18n.language
    });
    const {
        restoreLogin: {
            mutation
        }
    } = useAccountApi();

    const languages = useDefaultSiteLanguages();
    const isMaxWidth470 = useMediaQuery('(max-width:450px)');
    const isMaxHeight490 = useMediaQuery('(max-height:490px)');
    const [errors, setErrors] = useState<Array<IError>>([]);
    const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);

    return (
        <BackgroundImageContainer image={bg_image}>
            <Container centerContent={true} height={'calc(100% - 30px)'}>
                <Card style={isMaxWidth470 ? {width: 'calc(100% - 20px)'} : {width: '450px'}}>
                    <Dialog id={'assign-shipment-dialog'}
                            show={true}
                            setShow={()=> { navigate(-1); }}
                            width={'650px'}
                            title={t("shared.btn.assign-order")}>
                        <div className={cl.switcher}>
                            <LanguageSwitcher languages={languages} languageIconPosition={'right'}/>
                        </div>
                        <div className={cl.logo}
                             style={isMaxHeight490 ? {display: 'none'} : undefined}>
                            <img className={'prevent-selection'}
                                 src={'/images/logo_main.png'}
                                 alt={'logo'}/>
                        </div>
                        <div className={`${cl.label} prevent-selection`}>
                            {t("self-billing.labels.support-label")}
                        </div>

                        <div className={'pd-10'}>
                            {isEmailSent &&
                                <Alert type={'BrightGrayInfo'} style={alertStyle}>
                                    {t("login.restore.restore-login-instructions-sent")}
                                </Alert>
                            }

                            {!isEmailSent &&
                                <>
                                    {errors.map(err => {
                                        return (
                                            <Alert type={'Error'} style={alertStyle} key={err.description}>
                                                {err.description}
                                            </Alert>
                                        )
                                    })}

                                    <SupportForm id={'self-billing-support-form'}
                                                 onSubmit={async (values) => {
                                                     try {
                                                         setErrors([]);

                                                         setDisabled(prev => !prev);

                                                         if (!loaded || !executeReCaptcha) {
                                                             setErrors([{
                                                                 errorCode: BLErrorCode.InnerError,
                                                                 description: t('shared.errors.google-recaptcha-loaded'),
                                                                 field: 'summary'
                                                             }]);

                                                             return;
                                                         }

                                                         const token: string = await executeReCaptcha('restore/submit');

                                                         if (!token) {
                                                             setErrors([{
                                                                 errorCode: BLErrorCode.InnerError,
                                                                 description: t('shared.error.google-recaptcha-loaded'),
                                                                 field: 'summary'
                                                             }]);
                                                         }

                                                         const response = await mutation({
                                                             ...values,
                                                             token
                                                         });

                                                         if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                                                             setIsEmailSent(true);
                                                         }
                                                     } catch (error) {
                                                         if (isAxiosError(error) && error.response && error.response.data) {
                                                             if (error.response.data.resultCode === ResponseResultCode.ServerError) {
                                                                 setErrors([{
                                                                     errorCode: BLErrorCode.InnerError,
                                                                     description: error.response.data.message,
                                                                     field: 'summary'
                                                                 }]);
                                                             }
                                                         }
                                                     } finally {
                                                         setDisabled(prev => !prev);
                                                     }
                                                 }}/>

                                    <div className={cl.center}>
                                        <Button buttonType={ButtonType.Primary}
                                                type='submit'
                                                form={'self-billing-support-form'}
                                                disabled={disabled}
                                                isLoading={disabled}
                                                style={{width: '70%'}}>
                                            {t("shared.btn.submit")}
                                        </Button>
                                    </div>
                                </>
                            }

                            <hr className={cl.separator}/>

                            <div className={cl.linkBtns}>
                                <button disabled={disabled}
                                        onClick={() => {
                                            if (disabled) {
                                                return;
                                            }

                                            navigate(-1);
                                        }}>
                                    {t("self-billing.btn.back-to-self-billing-start-page")}
                                </button>
                            </div>
                        </div>
                    </Dialog>

                </Card>
            </Container>
            <CopyRightFooter/>
        </BackgroundImageContainer>
    );
};

export default SelfBillingSupport;
