import React, {useRef, useState} from 'react';
import {IChatReplyTo} from "../../../../../../../app/interfaces/chat/IChat";
import {useFormik} from "formik";
import SendMessageToMultipleServiceProvidersMessage
    from "./SendMessageToMultipleServiceProvidersMessage/SendMessageToMultipleServiceProvidersMessage";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import SendMessageToMultipleServiceProvidersSelector
    from "./SendMessageToMultipleServiceProvidersSelector/SendMessageToMultipleServiceProvidersSelector";
import {SelectModel} from "../../../../../../../app/types/SelectModel";
import Button, {ButtonType} from "../../../../../../ui/Button/Button";
import {IChatServiceProvider} from "../../../../../../../app/interfaces/chat/IChatServiceProvider";

type SendMessageToMultipleServiceProvidersFormProps = {
    chatReplyTo: IChatReplyTo;
    submit: (values: ISendMessageToMultipleServiceProvidersForm, all: boolean) => Promise<void>;
    disabled: boolean;
};

export interface ISendMessageToMultipleServiceProvidersForm {
    message: string;
    replyTo?: IChatReplyTo
    serviceProvider: SelectModel | null;
    serviceProviders: Array<IChatServiceProvider>;
    selectedServiceProviders: Array<IChatServiceProvider>;
    addedServiceProviders: Array<IChatServiceProvider>;
}


const SendMessageToMultipleServiceProvidersForm: React.FC<SendMessageToMultipleServiceProvidersFormProps> = ({
                                                                                                                 chatReplyTo,
                                                                                                                 submit,
                                                                                                                 disabled
                                                                                                             }) => {
    const {t} = useTranslation();
    const [refresh, setRefresh] = useState<number>(0);
    const sendToEveryone = useRef<boolean>(false);

    const form = useFormik<ISendMessageToMultipleServiceProvidersForm>({
        initialValues: {
            message: '',
            replyTo: chatReplyTo,
            serviceProvider: null,
            selectedServiceProviders: [],
            serviceProviders: [],
            addedServiceProviders: []
        },
        validationSchema: Yup.object().shape({
            message: Yup.string().required(t("shared.errors.required-error").replace('{0}', t("chat.labels.message")))
        }),
        onSubmit: async (values, actions) => {
            actions.setSubmitting(true);

            await submit(values, sendToEveryone.current);

            actions.setSubmitting(false);
        }
    });

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                <form id={'send-message-to-multiple-service-providers-form'}
                      onSubmit={form.handleSubmit}>
                    <div className={`pd-10`}>
                        <SendMessageToMultipleServiceProvidersMessage disabled={disabled}
                                                                      message={form.values.message}
                                                                      onChange={(val) => form.setFieldValue('message', val)}
                                                                      replyTo={chatReplyTo}
                                                                      hasValidationError={form.errors.message !== undefined}/>

                        <SendMessageToMultipleServiceProvidersSelector disabled={disabled}
                                                                       refresh={refresh}
                                                                       replyTo={chatReplyTo}
                                                                       addedServiceProviders={form.values.addedServiceProviders}
                                                                       selectedServiceProviders={form.values.selectedServiceProviders}
                                                                       serviceProvider={form.values.serviceProvider}
                                                                       serviceProviders={form.values.serviceProviders}
                                                                       onServiceProvidersChange={(items) => form.setFieldValue('serviceProviders', items)}
                                                                       onAddedServiceProvidersChange={(items) => {
                                                                           let res: Array<IChatServiceProvider> = [...form.values.addedServiceProviders];
                                                                           let isAdded = false;

                                                                           items.forEach(sp => {
                                                                               if (res.find(e => e.id === sp.id) === undefined &&
                                                                                   form.values.serviceProviders.find(e => e.id === sp.id) === undefined) {
                                                                                   isAdded = true;

                                                                                   res.push(sp);
                                                                               }
                                                                           });

                                                                           form.setFieldValue('addedServiceProviders', res);

                                                                           if (isAdded) {
                                                                               setRefresh(prev => prev + 1);
                                                                           }
                                                                       }}
                                                                       onSelectedServiceProvidersChange={(items) => form.setFieldValue('selectedServiceProviders', items)}
                                                                       onServiceProviderChange={(sp) => form.setFieldValue('serviceProvider', sp)}/>
                    </div>
                </form>
            </div>

            <div className={'dialog-default-buttons-container'}>
                <Button buttonType={ButtonType.Secondary}
                        disabled={disabled || form.values.serviceProviders.length <= 0 || !form.values.message || form.values.message === ''}
                        form='send-message-to-multiple-service-providers-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}
                        onClick={() => {
                            sendToEveryone.current = true;
                        }}>
                    {t("shared.btn.send-to-everyone-with-available-transport")}
                </Button>

                <Button buttonType={ButtonType.Primary}
                        disabled={disabled || form.values.selectedServiceProviders.length <= 0 || !form.values.message || form.values.message === ''}
                        form='send-message-to-multiple-service-providers-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}
                        onClick={() => {
                            sendToEveryone.current = false;
                        }}>
                    {t("shared.btn.confirm")}
                </Button>
            </div>
        </div>
    );
};

export default SendMessageToMultipleServiceProvidersForm;