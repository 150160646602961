import React from 'react';
import {useTranslation} from "react-i18next";

type TotalWeightProps = {
    weight: string | null;
}

const TotalWeight: React.FC<TotalWeightProps> = ({
                                                     weight
                                                 }) => {
    const {t} = useTranslation();

    return (
        <>
            <label className={'total-weight-label'}>
                {t('shipment.form.total-weight')}
            </label>

            <span className={'details-box-item-field-value total-weight-value'}>
                {weight !== null && weight !== undefined && weight !== ''
                    ? <>{`${weight} ${t("shared.labels.kg")}`}</>
                    : <>{t("shared.labels.no-data")}</>
                }
            </span>
        </>
    );
};

export default TotalWeight;