import {LayoutConfigEntity} from "../../../../app/enums/LayoutConfigEntity";
import {useAppDispatch, useAppSelector} from "../../../../app/store";
import {
    selectFilter,
    selectSelectedPersonalFilter,
    selectSelectedPredefinedFilter
} from "../../../../features/filter/filterSliceSelectors";
import {
    FilterSliceEntity, setFilterToApply,
    setSelectedPersonalFilter,
    setSelectedPredefinedFilter
} from "../../../../features/filter/filterSlice";
import {GridFilter} from "../../../../app/types/GridFilter";
import {SelectModel} from "../../../../app/types/SelectModel";
import {IGridFilter} from "../../../../app/interfaces/gridFilter/IGridFilter";
import {useEffect, useState} from "react";
import {ResponseResultCode} from "../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {useGridFilterApi} from "../../../../app/api/gridFilter";
import useIfFirstRender from "../../../../hooks/useIsFirstRender/useIfFirstRender";

type UseGridFilterPanelLayoutPreloadProps = {
    onComplete: (personal: IGridFilter | null, predefined: SelectModel | null, filter: GridFilter | null) => void;
    onChange?: (personal: IGridFilter | null, predefined: SelectModel | null, filter: GridFilter | null) => void;
    entity: LayoutConfigEntity;
    selectedPredefinedFilter: string | null;
    selectedPersonalFilter: number | null;
}

type UseGridFilterPanelLayoutPreloadReturn = {
    filter: GridFilter;
    selectedPredefinedFilter: SelectModel | null;
    selectedPersonalFilter: IGridFilter | null;
}

export const useGridFilterPanelLayoutPreload = ({
                                                    entity,
                                                    onComplete,
                                                    onChange,
                                                    selectedPredefinedFilter: savedSelectedPredefinedFilter,
                                                    selectedPersonalFilter: savedSelectedPersonalFilter
                                                }: UseGridFilterPanelLayoutPreloadProps): UseGridFilterPanelLayoutPreloadReturn => {
    const dispatch = useAppDispatch();
    const isFirstRender = useIfFirstRender();

    const [isPredefinedFilterReady, setIsPredefinedFilterReady] = useState<boolean>(false);
    const [isPersonalFilterReady, setIsPersonalFilterReady] = useState<boolean>(false);

    const {
        getGridFilter: {
            query: getGridFilter
        }
    } = useGridFilterApi();

    const selectedPersonalFilter = useAppSelector(state =>
        selectSelectedPersonalFilter(state, entity as FilterSliceEntity));
    const selectedPredefinedFilter = useAppSelector(state =>
        selectSelectedPredefinedFilter(state, entity as FilterSliceEntity));
    const filter = useAppSelector(state =>
        selectFilter(state, entity as FilterSliceEntity));

    useEffect(() => {
        if (savedSelectedPredefinedFilter) {
            dispatch(setSelectedPredefinedFilter({
                entity: entity as FilterSliceEntity,
                data: {
                    text: savedSelectedPredefinedFilter,
                    value: savedSelectedPredefinedFilter
                }
            }));
        } else {
            setIsPredefinedFilterReady(true);
        }

        if (savedSelectedPersonalFilter !== null) {
            (async () => {
                try {
                    const response = await getGridFilter(savedSelectedPersonalFilter);

                    if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok &&
                        response.data.data) {
                        dispatch(setSelectedPersonalFilter({
                            entity: entity as FilterSliceEntity,
                            data: response.data.data
                        }));

                        dispatch(setFilterToApply({
                            entity: entity as FilterSliceEntity,
                            data: JSON.parse(response.data.data.value)
                        }));
                    }
                } catch (e) {
                    if (isAxiosError(e)) {
                        toast.error(`Unable to get personal ${LayoutConfigEntity[entity].toString()} filter. Error: ${e.message}`);
                    }
                }
            })();
        } else {
            setIsPersonalFilterReady(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (isFirstRender) {
            return;
        }

        if (selectedPersonalFilter && savedSelectedPersonalFilter) {
            setIsPersonalFilterReady(true);
        }
    }, [selectedPersonalFilter, isFirstRender]);

    useEffect(() => {
        if (isFirstRender) {
            return;
        }

        if (selectedPredefinedFilter && savedSelectedPredefinedFilter) {
            setIsPredefinedFilterReady(true);
        }
    }, [selectedPredefinedFilter, isFirstRender]);

    useEffect(() => {
        if (isPersonalFilterReady && isPredefinedFilterReady) {
            onComplete(selectedPersonalFilter, selectedPredefinedFilter,
                selectedPersonalFilter === null
                    ? null
                    : JSON.parse(selectedPersonalFilter.value));
        }
    }, [isPersonalFilterReady && isPredefinedFilterReady]);

    useEffect(() => {

        if (isPersonalFilterReady && isPredefinedFilterReady && onChange) {

            onChange(selectedPersonalFilter, selectedPredefinedFilter, filter);
        }
    }, [selectedPredefinedFilter, selectedPersonalFilter, filter])

    return {
        selectedPersonalFilter,
        selectedPredefinedFilter,
        filter
    };
}
