import React, {useContext, useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import UserAvatar from "../../../../UserAvatar/UserAvatar";
import cl from './SidebarUserSectionUser.module.css';
import {SidebarContext} from "../../../Sidebar";
import Icon from "../../../../../../assets/icon/Icon";
import SidebarContextMenu, {SidebarContextMenuItem} from "../../../components/SidebarContextMenu/SidebarContextMenu";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import {validateUserAuthentication} from "../../../../../../features/authentication/authenticationSlice";
import {useTranslation} from "react-i18next";
import {useLoginApi} from "../../../../../../app/api/login";
import ChangePasswordDialog from "../../../../../shared/components/ChangePassword/ChangePasswordDialog";
import EditTagsDialog from "../../../../../shared/components/Tag/actions/EditTags/EditTagsDialog";
import useSignalRHub from "../../../../../../hooks/useSignalRHub/useSignalRHub";
import ProfileDialog from "../../../../../../pages/authorized/Profile/Profile/ProfileDialog/ProfileDialog";

const SidebarUserSectionUser: React.FC = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const [, close] = useSignalRHub(`${process.env.REACT_APP_API_DOMAIN}/applicationHub`, {
        singleton: true
    });

    const user = useAppSelector(selectCurrentUser);
    const ref = useRef<HTMLDivElement | null>(null);

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [isEditTagsDialogShown, setIsEditTagsDialogShown] = useState<boolean>(false);
    const [isChangePasswordDialogShown, setIsChangePasswordDialogShown] = useState<boolean>(false);
    const [isProfileDialogShown, setIsProfileDialogShown] = useState<boolean>(false);
    const {
        logout: {
            mutation: logout,
            isLoading: isLogoutLoading
        }
    } = useLoginApi();

    const {
        hoverExpanded,
        expanded
    } = useContext(SidebarContext);

    useEffect(() => {
        if (!hoverExpanded) {
            setIsOpened(false);
        }
    }, [hoverExpanded]);

    if (!user) {
        return null;
    }

    const buildUserMenu = (): Array<SidebarContextMenuItem> => {
        let result: Array<SidebarContextMenuItem> = [];
        let role = getUserRole(user);

        result.push(
            {
                id: 'user-menu-profile',
                text: t("profile.labels.profile"),
                title: t("profile.labels.profile"),
                onClick: () => {
                    if (isLogoutLoading) {
                        return;
                    }

                    setIsOpened(false);

                    setIsProfileDialogShown(prev => !prev)
                }
            }
        );

        if (role !== UserRole.Administrator && role !== UserRole.Forwarder && role !== UserRole.Support) {
            result.push(
                {
                    id: 'user-menu-change-password',
                    text: t("account.password.change-password"),
                    title: t("account.password.change-password"),
                    onClick: () => {
                        if (isLogoutLoading) {
                            return;
                        }

                        setIsOpened(false);

                        setIsChangePasswordDialogShown(prev => !prev);
                    }
                }
            );
        }

        if (role === UserRole.Forwarder) {
            result.push(
                {
                    id: 'user-menu-edit-tags',
                    text: t("tags.labels.tags-edit"),
                    title: t("tags.labels.tags-edit"),
                    onClick: () => {
                        if (isLogoutLoading) {
                            return;
                        }

                        setIsOpened(false);

                        setIsEditTagsDialogShown(prev => !prev);
                    }
                },
            );
        }

        result.push({
            id: 'user-menu-logout',
            text: t("header.btn.log-out"),
            title: t("header.btn.log-out"),
            onClick: async () => {
                if (isLogoutLoading) {
                    return;
                }

                try {
                    setIsOpened(false);
                    close();
                    await logout();

                    dispatch(validateUserAuthentication());
                } catch (err) {
                    console.log(err);
                }
            }
        });

        return result;
    };

    return (
        <>
            <ProfileDialog show={isProfileDialogShown}
                           setShow={setIsProfileDialogShown}/>

            <ChangePasswordDialog show={isChangePasswordDialogShown}
                                  setShow={setIsChangePasswordDialogShown}/>

            <EditTagsDialog show={isEditTagsDialogShown}
                            setShow={setIsEditTagsDialogShown}/>

            <SidebarContextMenu target={ref}
                                id={'sidebar-user-context-menu'}
                                verticalPosition={'bottom'}
                                horizontalPosition={'right'}
                                width={210}
                                opened={isOpened}
                                setOpened={setIsOpened}
                                options={buildUserMenu()}
                                closeOnClickOutside={true}/>

            <div className={cl.container}
                 ref={ref}
                 data-expanded={hoverExpanded || expanded ? 'expanded' : 'condensed'}>
                <UserAvatar user={user}
                            size={'medium'}/>

                {expanded || hoverExpanded
                    ? <div className={cl.name}
                           onClick={() => setIsOpened(prev => !prev)}>
                        <div className={`${cl.text} prevent-selection`}>
                            {user.name}
                        </div>

                        <div>
                            <Icon icon={"faEllipsisVertical"}
                                  className={cl.menu}/>
                        </div>
                    </div>
                    : null
                }
            </div>
        </>
    );
};

export default SidebarUserSectionUser;
