import React from 'react';
import cl from './Textarea.module.css';

type TextareaProps = {
    disabled: boolean;
} & React.HTMLProps<HTMLTextAreaElement>;

const Textarea: React.FC<TextareaProps> = ({
                                               disabled,
                                               className,
                                               ...rest
                                           }) => {
    return (
        <textarea className={`${cl.input} ${className}`} disabled={disabled} {...rest} />
    );
};

export default Textarea;