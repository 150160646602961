import React from 'react';
import cl from './PickupDateTemplate.module.css';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";

const PickupDateTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);

    let isReleaseEnd = props.dataItem.isReleaseEnd !== null && props.dataItem.isReleaseEnd !== undefined &&
        props.dataItem.isReleaseEnd === true;

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
                <span className={`default-left-cell default-overflow-cell ${isReleaseEnd ? cl.warn : ''}`}>
                    {props.children}
                </span>
        </td>
    );
};

export default PickupDateTemplate;