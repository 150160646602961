import React, {useEffect, useState} from 'react';
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {useTranslation} from "react-i18next";
import Alert from "../../../../../components/ui/Alert/Alert";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {isAxiosError} from "axios";
import {useShipmentApi} from "../../../../../app/api/shipment";
import {BLErrorCode} from "../../../../../app/enums/BLErrorCode";

type DeleteShipmentProps = {
    id: string;
    sId: string;
    close?: () => void;
}

const DeleteShipment: React.FC<DeleteShipmentProps> = ({
                                                           id,
                                                           sId,
                                                           close
                                                       }) => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError>>([]);

    const {
        deleteShipment: {
            mutation: deleteShipment,
            cancel
        }
    } = useShipmentApi();

    useEffect(() => {
        return () => {
            cancel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = async (): Promise<void> => {
        setDisabled(prev => !prev);
        setErrors([]);
        try {
            const response = await deleteShipment({
                orderReleaseGid: id,
                shipmentGid: sId
            });

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [
                            {
                                id: sId,
                                isRowLoading: true
                            }
                        ]
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.shipment")} ${sId}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotFound &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();
                    toast.error(err.message);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                {errors.length > 0 && errors.map((err, index) => {
                    switch (err.errorCode) {
                        case BLErrorCode.OrderNotFound:
                            return (
                                <Alert type={'Error'}
                                       id={`delete-shipment-error-${index}`}>
                                    {t("shipment.errors.delete-shipment-order-not-found")}
                                </Alert>
                            )
                    }

                    return null;
                })}
                {errors.length <= 0 &&
                    <div style={{margin:'15px', fontSize: '14px'}}>
                        {t("shipment.warnings.delete-shipment").replace('{0}', sId.replace('AAG.', ''))}
                    </div>
                }
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            style={{minWidth: '150px'}}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.no")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        style={{minWidth: '150px'}}
                        onClick={submit}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.yes")}
                </Button>
            </div>
        </div>
    );
};

export default DeleteShipment;
