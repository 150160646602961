import React from 'react';
import SidebarButton from "../../../components/SidebarButton/SidebarButton";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {
    selectChatTotalUnreadMessages,
    selectIsChatDialogOpened,
    selectIsChatSocketConnected
} from "../../../../../../features/chat/chatSliceSelectors";
import {UserRole} from "../../../../../../app/enums/UserRole";
import {setIsChatDialogOpened} from "../../../../../../features/chat/chatSlice";

const SidebarUserSectionChat: React.FC = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectCurrentUser);


    const isConnected = useAppSelector(selectIsChatSocketConnected);
    const isChatOpened = useAppSelector(selectIsChatDialogOpened);
    const unreadMessages = useAppSelector(selectChatTotalUnreadMessages);

    if (!user || user.role === UserRole.Client || user.role === UserRole.Support) {
        return null;
    }

    return (
        <SidebarButton id={'sidebar-chat-item'}
                       disabled={!isConnected}
                       type={'secondary'}
                       title={t("chat.labels.chat")}
                       text={t("chat.labels.chat")}
                       icon={"faMessages"}
                       active={isChatOpened}
                       iconColor={isConnected ? '#7FB98B' : '#677188'}
                       countBubble={unreadMessages}
                       onClick={() => {
                           if (!isConnected) {
                               return;
                           }

                           dispatch(setIsChatDialogOpened(!isChatOpened));
                       }}/>
    );
};

export default SidebarUserSectionChat;
