interface IQuery {
    addParam(key: string, value: string): Query;

    addParamsFromQuery(query: Query | null): Query | null;

    removeParam(key: string): void;

    containsParam(key: string): boolean;

    getParams(): Array<{
        key: string;
        value: string;
    }>
}


export class Query implements IQuery {
    private params = new Map<string, string>();

    addParam(key: string, value: string): Query {
        if (!this.containsParam(key)) {
            this.params.set(key, value);
        }

        return this;
    }

    addParamsFromQuery(query: Query | null): Query {
        if (!query) {
            return this;
        }

        for (let key of query.params.keys()) {
            let val = query.params.get(key);
            if (val) {
                this.addParam(key, val);
            }
        }

        return this;
    }

    removeParam(key: string): void {
        if (this.containsParam(key)) {
            this.params.delete(key);
        }
    }

    containsParam(key: string): boolean {
        return this.params.has(key);
    }

    getParam(key: string): string | null {
        if (!this.params.has(key)) {
            return null;
        }

        return this.params.get(key) ?? null;
    }

    getParams(): Array<{ key: string; value: string }> {
        let result: Array<{ key: string; value: string }> = [];
        for (let key of this.params.keys()) {
            result.push({
                key: key,
                value: this.params.get(key) ?? ''
            })
        }

        return result;
    }

    toString(): string {
        if (this.params.size <= 0) {
            return '';
        }

        let obj: {
            [index: string]: string;
        } = {};

        let params = this.getParams();

        for (let item of params) {
            obj[item.key] = encodeURIComponent(item.value);
        }

        const res = new URLSearchParams(obj);

        return res.toString();
    }

    static compare(q1?: Query | null, q2?: Query | null) {
        if (((q1 === undefined || q1 === null) && q2) ||
            ((q2 === undefined || q2 === null) && q1)) {
            return false;
        }

        if (q1 && q2) {
            if (q1.params.size !== q2.params.size) {
                return false;
            }

            let changed = q1.getParams().filter(({key, value}) => {
                let q2ItemValue = q2.params.get(key);

                return !(q2ItemValue && q2ItemValue === value);
            });

            return changed.length === 0;
        }

        return false;
    }

    static ToQuery(params: Array<{
        key: string,
        value: string
    }>): Query {
        let q = new Query();

        for (let param of params) {
            q.addParam(param.key, param.value);
        }

        return q;
    }
}