import React from 'react';
import {useChatHelperContext} from "../../../../hooks/useChatHelperContext";
import {useAppSelector} from "../../../../../../../../app/store";
import {selectChatUserOnlineStatus} from "../../../../../../../../features/chat/chatSliceSelectors";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faInfo, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import ChatAvatar from "../../../ChatAvatar/ChatAvatar";

const ChatDialogueHeader: React.FC = () => {
    const {
        disabled,
        chat,
        isUserInformationSectionShown,
        isMessagesSearchSectionShown,
        setIsUserInformationSectionShown,
        setIsMessagesSearchSectionShown,
        setChat
    } = useChatHelperContext();

    let isOnline = useAppSelector(st => selectChatUserOnlineStatus(st, {
        userId: chat?.id ?? ''
    }));

    return (
        <SkeletonTheme baseColor={'#eaeff3'} highlightColor={'#ffffff'}>
            <header className={'chat-dialogue-header'}>
                <div>
                    <FontAwesomeIcon icon={faAngleLeft}
                                     className={'chat-dialogue-header-back'}
                                     onClick={() => {
                                         if (disabled) {
                                             return;
                                         }

                                         setChat(null)
                                     }}/>
                </div>
                <div>
                    <div>
                        <ChatAvatar isOnline={isOnline}
                                    onVacation={chat?.isAway ?? false}
                                    size={'Small'}/>
                    </div>
                    <div>
                        {disabled &&
                            <div className={'w100'}>
                                <Skeleton height={'20px'}
                                          width={'50%'}
                                          style={{borderRadius: '2px'}}/>
                            </div>
                        }

                        {!disabled && chat &&
                            <>
                                {chat.name}
                            </>
                        }
                    </div>
                </div>
                <div className={'chat-dialogue-header-glass'}>
                    <FontAwesomeIcon icon={faMagnifyingGlass}
                                     onClick={() => {
                                         if (disabled) {
                                             return;
                                         }

                                         setIsMessagesSearchSectionShown(!isMessagesSearchSectionShown);
                                     }}/>
                </div>
                <div>
                    <div className={'chat-dialogue-header-circle'}
                         onClick={() => {
                             if (disabled) {
                                 return;
                             }

                             setIsUserInformationSectionShown(!isUserInformationSectionShown);
                         }}>
                        <FontAwesomeIcon icon={faInfo}/>
                    </div>
                </div>
            </header>
        </SkeletonTheme>
    );
};

export default ChatDialogueHeader;