import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import ResetAssigment from "./ResetAssigment";

type ResetAssigmentDialogProps = {
    id: string | Array<string>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const ResetAssigmentDialog: React.FC<ResetAssigmentDialogProps> = ({
                                                                       id,
                                                                       show,
                                                                       setShow
                                                                   }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'reset-assigment-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={Array.isArray(id) && id.length > 1
                    ? t("shared.btn.cancel-assign-offers")
                    : t("shared.btn.cancel-assign-offer")}>
            <ResetAssigment id={id}
                            close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default ResetAssigmentDialog;