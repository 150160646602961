import React, {createRef, FunctionComponent, useCallback, useEffect, useState} from 'react';
import {SelectModel} from "../../../app/types/SelectModel";
import {useTranslation} from "react-i18next";
import cl from './ListBox.module.css';
import {debounce} from "debounce";
import Loader, {LoaderType} from '../loaders/Loader/Loader';
import {AutoSizer, List} from 'react-virtualized';
import Checkbox from "../Checkbox/Checkbox";
import useIfFirstRender from "../../../hooks/useIsFirstRender/useIfFirstRender";
import Icon from "../../../assets/icon/Icon";

type ListBoxProps = {
    id: string;
    data: Array<SelectModel | string> | null;
    selected: Array<SelectModel>;
    isLoading: boolean;
    height: number;
    showSearchInput?: boolean;
    disabled?: boolean;
    onFilter?: (value: string | null) => void;
    selection?: 'Single' | 'Multiple';
    onSelectionChange?: (selected: Array<SelectModel>) => void;
    noDataLabel?: string
    template?: FunctionComponent<SelectModel>;
}

const ListBox: React.FC<ListBoxProps> = ({
                                             id,
                                             data,
                                             height,
                                             isLoading,
                                             onFilter,
                                             onSelectionChange,
                                             selected,
                                             noDataLabel,
                                             template,
                                             selection = 'Multiple',
                                             showSearchInput = true,
                                             disabled = false
                                         }) => {
    const listRef = createRef<List>();
    const [filter, setFilter] = useState<string>('');
    const isFirstRender = useIfFirstRender();

    const [selectedItems, setSelectedItems] = useState<Array<SelectModel>>([]);

    const {t} = useTranslation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onFilterChange = useCallback(debounce((value: string) => {
        if (onFilter) {
            onFilter(value);
        }
    }, 500), [onFilter]);

    useEffect(() => {
        if (isFirstRender || !data || data.length <= 0) {
            return
        }

        listRef.current?.scrollToRow(0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFirstRender, data]);

    useEffect(() => {
        if (selected.length !== selectedItems.length) {
            let selectedLocal: Array<SelectModel> = [];

            selected.forEach(e => {
                let item = data?.find(a => {
                    return typeof (a) === 'string'
                        ? undefined
                        : a.value === e.value
                });

                if (item && typeof (item) !== 'string') {
                    selectedLocal.push(item);
                }
            });

            setSelectedItems(selectedLocal);
        }
    }, [selected]);

    const onSelect = (item: SelectModel, type: 'Add' | 'Remove') => {
        if (selection === 'Multiple') {
            let items = type === 'Remove'
                ? [
                    ...selectedItems.filter(e => e.value !== item.value)
                ]
                : [
                    ...selectedItems,
                    item
                ];

            setSelectedItems(items);

            if (onSelectionChange) {
                onSelectionChange(items)
            }
        } else {
            let items = type === 'Remove' ? [] : [item];

            setSelectedItems(items);

            if (onSelectionChange) {
                onSelectionChange(items)
            }
        }
    }

    return (
        <div style={{height: `${height}px`}}
             id={id}
             data-type={showSearchInput ? 'si' : 'default'}
             className={`w100 h100 relative ${cl.listBox}`}>
            {showSearchInput &&
                <div className={cl.searchContainer}>
                    <input type={'text'}
                           className={cl.search}
                           disabled={disabled}
                           value={filter}
                           onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                               setFilter(ev.target.value);

                               onFilterChange(ev.target.value);
                           }}
                           placeholder={t("shared.labels.search")}/>

                    <Icon icon={"faMagnifyingGlass"}
                          className={cl.glass}/>
                </div>
            }
            <div>
                {isLoading &&
                    <div className={`${cl.loaderContainer}`}>
                        <div>
                            <Loader type={LoaderType.Action} style={{
                                scale: '0.4',
                                width: '30px',
                                height: '30px'
                            }}/>
                        </div>
                    </div>
                }

                {(!data || data.length <= 0) && !isLoading
                    ? <div className={cl.noData}>
                        {noDataLabel !== undefined && noDataLabel !== ''
                            ? <>{noDataLabel}</>
                            : <>{t("shared.labels.no-data-available")}</>
                        }
                    </div>
                    : null
                }

                {data && data.length > 0 && !isLoading &&
                    <div className={'w100'} style={{
                        backgroundColor: 'white',
                        height: `${showSearchInput ? `${height - 35}px` : `${height}px`}`
                    }}>
                        <AutoSizer>
                            {({width, height}) => (
                                <List ref={listRef}
                                      height={height}
                                      width={width}
                                      rowCount={data?.length ?? 0}
                                      rowHeight={25}
                                      rowRenderer={(props) => {
                                          let item = data[props.index];

                                          if (typeof item !== 'string') {
                                              let isSelected = selectedItems.find(e => e.value === (item as SelectModel).value) !== undefined;

                                              return (
                                                  <div key={props.key}
                                                       style={props.style}
                                                       className={`${cl.row} ${isSelected ? cl.selected : ''}`}
                                                       onClick={() => {
                                                           if (disabled) {
                                                               return;
                                                           }

                                                           onSelect(item as SelectModel, isSelected ? 'Remove' : 'Add');
                                                       }}>
                                                      <div>
                                                          <Checkbox checked={isSelected}
                                                                    defaultChecked={isSelected}
                                                                    change={() => {
                                                                        return;
                                                                    }}
                                                                    disabled={disabled}/>
                                                      </div>
                                                      <div title={item.text}>
                                                          {template !== undefined
                                                              ? template(item)
                                                              : <>{item.text}</>
                                                          }
                                                      </div>
                                                  </div>
                                              );
                                          } else {
                                              return (
                                                  <div key={props.key}
                                                       style={props.style}
                                                       className={`${cl.groupRow}`}>
                                                      {item}
                                                  </div>
                                              );
                                          }
                                      }}
                                />
                            )}
                        </AutoSizer>
                    </div>
                }
            </div>
        </div>
    );
};

export default ListBox;