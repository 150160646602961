import React, {useState} from 'react';
import {Language} from "../../../app/enums/Language";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLanguage} from "@fortawesome/free-solid-svg-icons";
import cl from './LanguageSwitcher.module.css';
import {useAppDispatch} from "../../../app/store";
import {setLanguage} from "../../../features/application/applicationSlice";

export type LanguageSwitcherModel = {
    language: Language;
    label: string;
}

type DefaultLanguageSwitcherProps = {
    languages: Array<LanguageSwitcherModel>;
    change?: (lng: Language) => void;
    disabled?: boolean;
    languageIconPosition: 'left' | 'right'
};

type ControlledLanguageSwitcherProps = {
    value: Language;
    languages: Array<LanguageSwitcherModel>;
    change?: (lng: Language) => void;
    disabled?: boolean;
    languageIconPosition: 'left' | 'right'
};

export const isControlledLanguageSwitcherProps = (item: DefaultLanguageSwitcherProps | ControlledLanguageSwitcherProps): item is ControlledLanguageSwitcherProps => {
    return (item as ControlledLanguageSwitcherProps).value !== undefined;
}

export type LanguageSwitcherProps = DefaultLanguageSwitcherProps | ControlledLanguageSwitcherProps;

export const LANGUAGE_LOCAL_STORAGE_KEY = 'lng';

const LanguageSwitcher = React.memo((props: LanguageSwitcherProps) => {
    const {i18n} = useTranslation();
    const [disabledLocal, setDisabledLocal] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    if (isControlledLanguageSwitcherProps(props)) {
        const {
            disabled,
            change,
            languageIconPosition,
            languages,
            value
        } = props;

        const changeLanguage = (lng: Language) => {
            if (disabled || disabledLocal) {
                return;
            }
            setDisabledLocal(prev => prev);

            if (change) {
                change(lng);
            }
        };

        return (
            <ul className={cl.ul}>
                {languageIconPosition === 'left'
                    ? <li className={cl.languageLi}>
                        <div></div>
                        <div></div>
                        <FontAwesomeIcon icon={faLanguage}/>
                    </li>
                    : null
                }
                {languages.map((item: LanguageSwitcherModel) => {
                    return (
                        <li key={item.language}
                            onClick={() => changeLanguage(item.language)}>
                            <div className={`${cl.label} prevent-selection`}>
                                {item.label}
                            </div>
                            {value.toString().toLowerCase() === Language[item.language].toString().toLowerCase()
                                ? <div className={cl.selectedLine}></div>
                                : null
                            }
                        </li>
                    )
                })}
                {languageIconPosition === 'right'
                    ? <li className={cl.languageLi}>
                        <div></div>
                        <div></div>
                        <FontAwesomeIcon icon={faLanguage}/>
                    </li>
                    : null
                }
            </ul>
        );
    } else {
        const {
            disabled,
            change,
            languageIconPosition,
            languages
        } = props;

        const changeLanguage = (lng: Language) => {
            if (disabled || disabledLocal) {
                return;
            }
            setDisabledLocal(prev => prev);

            if (i18n.language.toLowerCase() !== Language[lng].toString().toLowerCase()) {
                i18n.changeLanguage(Language[lng].toString().toLowerCase())
                    .then(() => {
                        if (change) {
                            change(lng);
                        }

                        dispatch(setLanguage(lng));
                        localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, Language[lng].toString().toLowerCase());

                        setDisabledLocal(prev => prev);
                    });
            }
        };

        return (
            <ul className={cl.ul}>
                {/*{languageIconPosition === 'left'
                    ? <li className={cl.languageLi}>
                        <div></div>
                        <div></div>
                        <FontAwesomeIcon icon={faLanguage}/>
                    </li>
                    : null
                }*/}
                {languages.map((item: LanguageSwitcherModel, index) => {
                    return (
                        <li key={item.language}
                            tabIndex={++index}
                            onKeyDown={(ev) => {
                                if (ev.code === 'Enter') {
                                    changeLanguage(item.language);
                                }
                            }}
                            onClick={() => changeLanguage(item.language)}>
                            <div className={`${cl.label} prevent-selection`}>
                                {item.label}
                            </div>
                            {i18n.language.toLowerCase() === Language[item.language].toString().toLowerCase()
                                ? <div className={cl.selectedLine}></div>
                                : null
                            }
                        </li>
                    )
                })}
                {/*{languageIconPosition === 'right'
                    ? <li className={cl.languageLi}>
                        <div></div>
                        <div></div>
                        <FontAwesomeIcon icon={faLanguage}/>
                    </li>
                    : null
                }*/}
            </ul>
        );
    }
});

export default LanguageSwitcher;
