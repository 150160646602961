import React, {ReactNode} from 'react';
import {useChatHelperContext} from "../../../hooks/useChatHelperContext";
import {useTranslation} from "react-i18next";
import {faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter, faXmark} from "@fortawesome/free-solid-svg-icons";
import ChatPanelActionButton from "../ChatPanelActionButton/ChatPanelActionButton";

type ChatPanelLayoutProps = {
    children: ReactNode;
}

const ChatPanelLayout: React.FC<ChatPanelLayoutProps> = ({children}) => {
    const {t} = useTranslation();

    const {
        isMobileView,
        isFullScreenMode,
        setIsChatDialogOpen,
        setIsFullScreenMode
    } = useChatHelperContext();

    return (
        <div className={'chat-panel-layout'}>
            <header>
                <section>
                    <ChatPanelActionButton title={t("shared.btn.close")}
                                           size={19}
                                           icon={faXmark}
                                           color={'#ee3333'}
                                           onClick={() => setIsChatDialogOpen(false)}/>

                    {!isMobileView &&
                        <ChatPanelActionButton color={'green'}
                                               size={12}
                                               title={isFullScreenMode ? t("shared.btn.collapse-dialog") : t("shared.btn.expand-dialog")}
                                               icon={!isFullScreenMode ? faUpRightAndDownLeftFromCenter : faDownLeftAndUpRightToCenter}
                                               onClick={() => setIsFullScreenMode(!isFullScreenMode)}/>
                    }
                </section>

                <section
                    className={`${!isFullScreenMode && !isMobileView ? 'chat-panel-layout-draggable-zone draggable-active-zone' : ''}`}>

                </section>
            </header>
            <main>
                {children}
            </main>
        </div>
    );
};

export default ChatPanelLayout;