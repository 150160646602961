import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import {IUser} from "../../../../../app/interfaces/user/IUser";
import NotificationGridContent from "./NotificationGridContent/NotificationGridContent";

type NotificationDialogProps = {
    user?: IUser;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationDialog: React.FC<NotificationDialogProps> = ({
                                                                               show,
                                                                               setShow,
                                                                               user
                                                                           }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'add-shipment-event-dialog'}
                show={show}
                setShow={setShow}
                width={'98%'}
                title={t("sidebar.notification")}>
            <NotificationGridContent user={user}
                                close={() => setShow(false)}/>
        </Dialog>
    );
};

export default NotificationDialog;
