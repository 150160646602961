export enum ShipmentEventNames {
    ARRIVAL_AT_UNLOADING = 'AAG.ARRIVAL_AT_UNLOADING',
    START_LOADING = 'AAG.START_LOADING',
    END_LOADING = 'AAG.END_LOADING',
    DEPARTURE_FROM_WAREHOUSE = 'AAG.DEPARTURE_FROM_WAREHOUSE',
    START_UNLOADING = 'AAG.START_UNLOADING',
    ARRIVE_TO_CUSTOMS = 'AAG.ARRIVE TO CUSTOMS',
    DEPART_FROM_CUSTOMS = 'AAG.DEPART FROM CUSTOMS',
    START_SHIPMENT = 'AAG.START SHIPMENT',
    COMPLETE_SHIPMENT = 'AAG.COMPLETE SHIPMENT',
    EXPECTED_ARRIVAL_TO_CUSTOMS = 'Expected arrival at customs import/export'
}