import React, {useEffect, useRef, useState} from 'react';
import {GridActionRowTagItem} from "../../GridActionRowTagsContainer";
import cl from './GridActionRowTagsContextMenu.module.css';
import {useClickOutside} from "../../../../../../../../hooks/useOnClickOutside/useOnClickOutside";
import {createPortal} from "react-dom";
import GridActionRowTagsContextMenuTag from "./GridActionRowTagsContextMenuTag";

type GridActionRowTagsContextMenuProps = {
    isOpened: boolean;
    setIsOpened: (val: boolean) => void;
    container: React.MutableRefObject<HTMLButtonElement | null>;
    tags: Array<GridActionRowTagItem>;
    verticalPosition: 'bottom' | 'top';
    horizontalPosition: 'left' | 'right' | 'center';
    width: number;
    availableWidth: number
};

const GridActionRowTagsContextMenu: React.FC<GridActionRowTagsContextMenuProps> = ({
                                                                                       container,
                                                                                       setIsOpened,
                                                                                       isOpened,
                                                                                       verticalPosition,
                                                                                       horizontalPosition,
                                                                                       width,
                                                                                       tags,
                                                                                       availableWidth
                                                                                   }) => {
    const popup = useRef<HTMLDivElement | null>(null);

    const [point, setPoint] = useState<{
        top: number,
        left: number
    } | null>();

    useClickOutside(popup, (ev) => {
        if (ev.target && container.current && container.current?.contains(ev.target as Node)) {
            return;
        }

        setIsOpened(false);
    });

    useEffect(() => {
        if (!container || !container.current || !isOpened) {
            return;
        }

        const resize = () => {
            setupPoint();
        };

        const setupPoint = () => {
            if (!container || !container.current || !isOpened) {
                return;
            }

            let position = container.current?.getBoundingClientRect();

            if (position) {
                let y: number = 0;
                let x: number = 0;

                switch (verticalPosition) {
                    case "bottom":
                        y = position.top + 5 + container.current?.clientHeight ?? 0
                        break;
                    case "top":
                        y = position.top - 2 - tags.length * 40
                        break;
                }

                switch (horizontalPosition) {
                    case "center":
                        x = position.left + (position.width - width) / 2;
                        break;
                    case "left":
                        x = position.left;
                        break;
                    case "right":
                        x = position.right - width
                        break;
                }

                setPoint({
                    top: y,
                    left: x
                });
            }
        }

        setupPoint();

        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        }
    }, [container, isOpened, width, availableWidth]);

    return (
        <>
            {isOpened && point && createPortal(
                <div id={'grid-tags-context-menu'}
                     style={{left: point.left, top: point.top, width: `${width}px`}} className={`${cl.menu}`}
                     ref={popup}>
                    {tags.map(tag => {
                        return <GridActionRowTagsContextMenuTag key={tag.id} tag={tag}/>;
                    })}
                </div>,
                document.body
            )}
        </>
    );
};

export default GridActionRowTagsContextMenu;