import React, {useState} from 'react';
import {ISelfBillingAddCmrGetAction} from "../../../../../../app/interfaces/selfBilling/ISelfBillingAddCmrGetAction";
import {ISelfBillingAddCmrPostAction} from "../../../../../../app/interfaces/selfBilling/ISelfBillingAddCmrPostAction";
import {useFormik} from "formik";
import Textarea from "../../../../../../components/ui/Textarea/Textarea";
import {useTranslation} from "react-i18next";
import Input from "../../../../../../components/ui/Input/Input";
import * as Yup from "yup";
import cl from './AddCmrDocumentForm.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faPlus} from "@fortawesome/free-solid-svg-icons";
import ChangeRequisitesDialog from "../../ChangeRequisites/ChangeRequisitesDialog";
import {RadioButton} from "@progress/kendo-react-inputs";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {IChangeRequisitesPostAction} from "../../ChangeRequisites/interfaces/IChangeRequisitesPostAction";
import {UploadFileInfo} from "@progress/kendo-react-upload";
import {SelectModel} from "../../../../../../app/types/SelectModel";
import {EcmDocumentCategory} from "../../../../../../app/enums/EcmDocumentCategory";
import Alert from "../../../../../../components/ui/Alert/Alert";
import FileUpload from "../../../../../../components/ui/FileUpload/FileUpload";

type AddCmrDocumentFormProps = {
    disabled: boolean;
    model: ISelfBillingAddCmrGetAction;
    onSubmit: (values: ISelfBillingAddCmrPostAction) => Promise<void>;
};

const isFileUploaderDisabled = (values: ISelfBillingAddCmrPostAction, accounts: Array<SelectModel>): boolean => {
    switch (values.mode) {
        case "AnotherPaymentRecipients":
            return values.anotherPaymentRecipientModel === null;
        case "NewRequisites":
            return values.financialFile === null;
        case "OwnBankAccount":
            return values.bankAccountModel === null && accounts.length == 0;
    }
};

const getInitialBankAccountModel = (model: ISelfBillingAddCmrGetAction): SelectModel | null => {
    if (!model.isBankAccount) {
        return null;
    }

    if (model.bankAccount !== null && model.bankAccount !== '') {
        let item = model.bankAccounts.find(e => e.value === model.bankAccount);

        if (item) {
            return item;
        }
    }

    if (model.bankAccounts.length === 1) {
        return model.bankAccounts[0];
    }

    return null;
};

const getInitialAnotherPaymentRecipientModel = (model: ISelfBillingAddCmrGetAction): SelectModel | null => {
    if (model.isBankAccount) {
        return null;
    }

    if (model.anotherPaymentRecipient !== null && model.anotherPaymentRecipient !== '') {
        let item = model.anotherPaymentRecipients.find(e => e.value === model.anotherPaymentRecipient);

        if (item) {
            return item;
        }
    }

    if (model.anotherPaymentRecipients.length === 1) {
        return model.anotherPaymentRecipients[0];
    }

    return null;
};

const AddCmrDocumentForm: React.FC<AddCmrDocumentFormProps> = ({
                                                                   disabled,
                                                                   model,
                                                                   onSubmit
                                                               }) => {
    const {t} = useTranslation();

    const [isRequisitesDialogShown, setIsRequisitesDialogShown] = useState<boolean>(false);
    const [modelL, setModelL] = useState<ISelfBillingAddCmrGetAction>(model);
    const [files, setFiles] = React.useState<Array<UploadFileInfo>>([]);
    const [invoiceLettersError, setInvoiceLettersError] = useState<string>('');
    const [documentTypes] = useState<Array<SelectModel>>(() => {
        if (model.isFreight) {
            return [{
                text: t("self-billing.labels.cmr"),
                value: EcmDocumentCategory.CMR.toString()
            }];
        } else {
            return [{
                text: t("self-billing.labels.financial-document"),
                value: EcmDocumentCategory.Fin.toString()
            }];
        }
    });

    const form = useFormik<ISelfBillingAddCmrPostAction>({
        initialValues: {
            mode: modelL.isBankAccount ? 'OwnBankAccount' : 'AnotherPaymentRecipients',
            orderReleaseGid: modelL.orderReleaseGid,
            shipmentGid: modelL.shipmentGid,
            notes: '',
            invoiceNumber: modelL.invoiceNumber,
            invoiceRequired: modelL.invoiceRequired,
            financialFile: null,
            cmrFile: null,
            anotherPaymentRecipientModel: getInitialAnotherPaymentRecipientModel(modelL),
            bankAccountModel: getInitialBankAccountModel(modelL),
            categoryModel: documentTypes[0]
        },
        validationSchema: Yup.object().shape({
            invoiceNumber: Yup.string().nullable().when('invoiceRequired', {
                is: (invoiceRequired: boolean) => invoiceRequired,
                then: () => Yup.string().nullable().required(t("shared.errors.required-error")
                    .replace('{0}', t("self-billing.labels.supplier-invoice-number")))
                    .max(35, t("self-billing.labels.invoice-number-length-error")),
                otherwise: () => Yup.string().nullable()
            }),
            financialFile: Yup.mixed().nullable().when('mode', {
                is: (mode: 'OwnBankAccount' | 'NewRequisites' | 'AnotherPaymentRecipients') => mode === 'NewRequisites',
                then: () => Yup.mixed().nullable().required(t("self-billing.labels.requisite-file-warning")),
                otherwise: () => Yup.mixed().nullable()
            }),
            bankAccountModel: Yup.object().nullable().when('mode', {
                is: (mode: 'OwnBankAccount' | 'NewRequisites' | 'AnotherPaymentRecipients') => mode === 'OwnBankAccount',
                then: () => Yup.object().nullable().required(t("shared.errors.required-error")
                    .replace('{0}', t("self-billing.labels.bank-account"))),
                otherwise: () => Yup.object().nullable()
            }),
            anotherPaymentRecipientModel: Yup.object().nullable().when('mode', {
                is: (mode: 'OwnBankAccount' | 'NewRequisites' | 'AnotherPaymentRecipients') => mode === 'AnotherPaymentRecipients',
                then: () => Yup.object().nullable().required(t("shared.errors.required-error")
                    .replace('{0}', t("self-billing.labels.payment-receiver"))),
                otherwise: () => Yup.object().nullable()
            }),
            cmrFile: Yup.mixed().nullable().required(t("shared.errors.required-error")
                .replace('{0}', t("self-billing.labels.cmr-file"))),
        }),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values, actions) => {
            actions.setSubmitting(true);

            await onSubmit(values);

            actions.setSubmitting(false);
        }
    });

    const onChangeRequisites = (val: IChangeRequisitesPostAction) => {
        if (val.mode === 'OwnBankAccount' && val.account) {
            if (form.values.mode !== 'OwnBankAccount') {
                form.setFieldValue('mode', 'OwnBankAccount');
            }

            form.setFieldValue('bankAccountModel', val.account);

            if (modelL.bankAccounts.find(e => e.value === val.account?.value) === undefined) {
                setModelL({
                    ...modelL,
                    bankAccounts: [
                        ...modelL.bankAccounts,
                        val.account
                    ]
                });
            }
        }

        if (val.mode === 'NewRequisites' && val.file) {
            if (form.values.mode !== 'NewRequisites') {
                form.setFieldValue('mode', 'NewRequisites');
            }

            form.setFieldValue('financialFile', val.file);
        }
    }

    function onSaveRequest(
        files: UploadFileInfo[],
        _options: { formData: FormData; requestOptions: any },
        _onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
    ): Promise<{ uid: string }> {
        const currentFile = files[0] as UploadFileInfo;
        const uid = currentFile.uid;

        return new Promise<{ uid: string }>((resolve, reject) => {
            if (
                currentFile.validationErrors &&
                currentFile.validationErrors.length > 0
            ) {
                reject({uid: uid});
            } else {
                let file = currentFile.getRawFile!();

                form.setFieldValue('cmrFile', file);

                resolve({uid: uid});
            }
        });
    }

    const onKeyDownInvoiceData = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.keyCode === 0 || ev.keyCode === 8 || ev.ctrlKey) {
            return;
        }

        let isValid: boolean = true;

        if (ev.key && !ev.key.match(/([a-zA-Z0-9\.,;:'+-/()?*[\]{}`´~ ]|[!"#%&<>÷=@_$£]|[àáâäçèéêëìíîïñòóôöùúûüýßÀÁÂÄÇÈÉÊËÌÍÎÏÒÓÔÖÙÚÛÜÑ])/g)) {
            isValid = false;
        }

        if (!isValid) {
            setInvoiceLettersError(t("self-billing.labels.invoice-letters-error").replace('{0}', ev.key));
        } else {
            setInvoiceLettersError('');
        }

        if (!isValid) {
            ev.preventDefault();
        }
    }

    function onRemoveRequest(files: UploadFileInfo[]): Promise<{ uid: string }> {
        const currentFile = files[0] as UploadFileInfo;
        const uid = currentFile.uid;

        return new Promise<{ uid: string }>((resolve) => {
            form.setFieldValue('cmrFile', null);

            resolve({uid: uid});
        });
    }

    return (
        <>
            <ChangeRequisitesDialog onChange={onChangeRequisites}
                                    show={isRequisitesDialogShown}
                                    setShow={setIsRequisitesDialogShown}
                                    model={{
                                        isNewBankAccount: modelL.isNewBankAccount,
                                        isNoInList: modelL.isNoInList,
                                        bankAccounts: modelL.newBankAccounts
                                    }}/>

            <form id={'add-cmr-form'}
                  onSubmit={form.handleSubmit}>
                <div className={'pd-10'}>
                    <div className={cl.btnContainer}>
                        <button className={cl.button}
                                type={'button'}
                                disabled={disabled}
                                onClick={() => setIsRequisitesDialogShown(prev => !prev)}>
                            <FontAwesomeIcon icon={modelL.bankAccounts.length > 0 ? faPen : faPlus}/>
                            <div>
                                {modelL.bankAccounts.length > 0
                                    ? t("self-billing.btn.change-requisites")
                                    : t("self-billing.btn.add-requisites")
                                }
                            </div>
                        </button>
                    </div>

                    <div
                        className={`mgb-10 ${form.values.financialFile ?
                            modelL.anotherPaymentRecipients.length > 0 ? 'flex-three-columns flex-gap-5' : 'flex-two-columns flex-gap-5' :
                            modelL.anotherPaymentRecipients.length > 0 ? 'flex-two-columns flex-gap-5' : ''}`}>
                        <div className={'radio-buttons-container drop-down-picker'}>
                            <div style={{marginBottom: '5px'}}>
                                <RadioButton name={`mode`}
                                             disabled={disabled}
                                             value={`OwnBankAccount`}
                                             size={'small'}
                                             checked={form.values.mode === 'OwnBankAccount'}
                                             label={t("self-billing.labels.bank-account")}
                                             onChange={async () => {
                                                 await form.setFieldTouched('mode', false, false)
                                                 await form.setFieldValue('mode', 'OwnBankAccount');
                                             }}/>
                            </div>

                            <DropDownList data={modelL.bankAccounts}
                                          disabled={disabled || modelL.bankAccounts.length <= 0 || form.values.mode !== 'OwnBankAccount'}
                                          textField="text"
                                          dataItemKey="value"
                                          value={form.values.bankAccountModel}
                                          onChange={(ev) => form.setFieldValue('bankAccountModel', ev.value)}
                            />

                            {form.values.mode === 'OwnBankAccount' && form.errors.bankAccountModel &&
                                <div className={'error-label'}>
                                    {form.errors.bankAccountModel}
                                </div>
                            }
                        </div>

                        {modelL.anotherPaymentRecipients.length > 0 &&
                            <div className={'radio-buttons-container drop-down-picker'}>
                                <div style={{marginBottom: '5px'}}>
                                    <RadioButton name={`mode`}
                                                 disabled={disabled}
                                                 value={`AnotherPaymentRecipients`}
                                                 size={'small'}
                                                 checked={form.values.mode === 'AnotherPaymentRecipients'}
                                                 label={t("self-billing.labels.payment-receiver")}
                                                 onChange={async () => {
                                                     await form.setFieldValue('mode', 'AnotherPaymentRecipients');
                                                 }}/>
                                </div>

                                <DropDownList data={modelL.anotherPaymentRecipients.filter(e => e.text !== '')}
                                              disabled={disabled || modelL.anotherPaymentRecipients.length <= 0 || form.values.mode !== 'AnotherPaymentRecipients'}
                                              textField="text"
                                              dataItemKey="value"
                                              value={form.values.anotherPaymentRecipientModel}
                                              onChange={(ev) => form.setFieldValue('anotherPaymentRecipientModel', ev.value)}
                                />

                                {form.values.mode === 'AnotherPaymentRecipients' && form.errors.anotherPaymentRecipientModel &&
                                    <div className={'error-label'}>
                                        {form.errors.anotherPaymentRecipientModel}
                                    </div>
                                }
                            </div>
                        }

                        {form.values.financialFile &&
                            <div className={'radio-buttons-container'}>
                                <div style={{marginBottom: '5px'}}>
                                    <RadioButton name={`mode`}
                                                 disabled={disabled}
                                                 value={`NewRequisites`}
                                                 size={'small'}
                                                 checked={form.values.mode === 'NewRequisites'}
                                                 label={t("self-billing.labels.new-requisites")}
                                                 onChange={async () => {
                                                     await form.setFieldValue('mode', 'NewRequisites');
                                                 }}/>
                                </div>

                                <div className={cl.file}>
                                    {/* <div>
                                        <FontAwesomeIcon icon={faFile}/>
                                    </div>*/}
                                    <div>
                                        {form.values.financialFile.name}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className={cl.separator}></div>

                    <div className={`mgb-10 ${modelL.invoiceRequired ? 'flex-two-columns flex-gap-5' : ''}`}>
                        <div className={'drop-down-picker'}>
                            <label className={'view-label required'}>
                                {t("self-billing.labels.document-type")}
                            </label>

                            <DropDownList data={documentTypes}
                                          disabled={disabled}
                                          textField="text"
                                          dataItemKey="value"
                                          value={form.values.categoryModel !== undefined
                                              ? documentTypes.find(e => e.value === form.values.categoryModel.value)
                                              : null}
                                          onChange={(ev) => form.setFieldValue('categoryModel', ev.value)}/>
                        </div>

                        {modelL.invoiceRequired &&
                            <div>
                                <label className={'view-label required'}>
                                    {t("self-billing.labels.supplier-invoice-number")}
                                </label>

                                <Input placeholder={t("self-billing.labels.supplier-invoice-number")}
                                       id={'add-cmr-supplier-invoice-number'}
                                       name={'invoiceNumber'}
                                       disabled={form.isSubmitting}
                                       value={form.values.invoiceNumber ?? ''}
                                       autoComplete={'off'}
                                       onKeyDown={onKeyDownInvoiceData}
                                       onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                           //let val = ev.target.value.replace(/([,;:'+-/()?*[\]{}`´~ ]|[!"#%&<>÷=@_$£]|[àáâäçèéêëìíîïñòóôöùúûüýßÀÁÂÄÇÈÉÊËÌÍÎÏÒÓÔÖÙÚÛÜÑ])/g, '');

                                           form.setFieldValue('invoiceNumber', ev.target.value);
                                       }}/>

                                {form.errors.invoiceNumber &&
                                    <div className={'error-label'}>
                                        {form.errors.invoiceNumber}
                                    </div>
                                }
                                {invoiceLettersError &&
                                    <div className={'error-label'}>
                                        {invoiceLettersError}
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    <div className={cl.separator}></div>

                    {modelL.bankAccounts.length <= 0 || isFileUploaderDisabled(form.values, modelL.bankAccounts)
                        ? <Alert type={'BrightGrayInfo'}
                                 style={{
                                     margin: '15px 0',
                                     fontSize: '11px',
                                     fontWeight: 'bold'
                                 }}>
                            {t("self-billing.labels.enter-payment-details-warning")}
                        </Alert>
                        : null
                    }

                    <div className={'mgb-10 file-upload'}>
                        <label className={'view-label required'}>
                            {t("self-billing.labels.cmr-file")}
                        </label>

                        <FileUpload id={'add-cmr-document-file-uploader'}
                                    selectButtonText={t("self-billing.btn.attach-cmr")}
                                    /*multiple={false}*/
                                    disabled={disabled || isFileUploaderDisabled(form.values, modelL.bankAccounts)}
                                    onChange={(files) => {
                                        form.setFieldValue('cmrFile', files.length > 0
                                            ? files[0].file
                                            : null);
                                    }}
                                    restrictions={{
                                        allowedExtensions: [".jpg", ".jpeg", ".png", ".pdf", ".docx", ".doc"]
                                    }}
                        />

                        {/*  <Upload batch={false}
                                disabled={disabled || isFileUploaderDisabled(form.values, modelL.bankAccounts)}
                                selectMessageUI={() => <>{t("self-billing.btn.attach-cmr")}</>}
                                multiple={false}
                                files={files}
                                onAdd={(ev) => setFiles(ev.newState)}
                                onRemove={(ev) => setFiles(ev.newState)}
                                onProgress={(ev) => setFiles(ev.newState)}
                                onStatusChange={(ev) => setFiles(ev.newState)}
                                saveUrl={onSaveRequest}
                                removeUrl={onRemoveRequest}
                                restrictions={{
                                    allowedExtensions: [".jpg", ".jpeg", ".png", ".pdf", ".docx", ".doc"]
                                }}
                        />
*/}
                        {form.values.cmrFile === null && form.errors.cmrFile &&
                            <div className={'error-label'}>
                                {form.errors.cmrFile}
                            </div>
                        }
                    </div>

                    <div className={'mgb-10'}>
                        <label className={'view-label'}>
                            {t("self-billing.labels.notes")}
                        </label>

                        <Textarea id={'add-cmr-notes'}
                                  name={'notes'}
                                  placeholder={t("self-billing.labels.notes")}
                                  disabled={form.isSubmitting}
                                  value={form.values.notes ?? ''}
                                  autoComplete={'off'}
                                  style={{minHeight: '70px', resize: 'vertical', maxHeight: '100px'}}
                                  onChange={form.handleChange}/>
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddCmrDocumentForm;
