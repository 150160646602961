import React, {useEffect, useState} from 'react';
import useSelfBillingApi from "../../../../../app/api/selfBilling";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {ISelfBilling} from "../../../../../app/interfaces/selfBilling/ISelfBilling";
import SelfBillingForm from "../SelfBillingForm/SelfBillingForm";
import {
    FormLayoutToolbarActionItem
} from "../../../../../components/uiLayouts/FormLayout/components/FormLayoutToolbar/FormLayoutToolbar";
import {useTranslation} from "react-i18next";
import FormLayout from "../../../../../components/uiLayouts/FormLayout/FormLayout";
import {ButtonType} from "../../../../../components/ui/Button/Button";
import useInvoiceApi from "../../../../../app/api/invoice";
import EditBillingDataDialog from "../../actions/EditBillingData/EditBillingDataDialog";
import {useAppSelector} from "../../../../../app/store";
import {selectCurrentUser} from "../../../../../features/authentication/authenticationSliceSelectors";
import AddExpenseDialog from "../../actions/AddExpense/AddExpenseDialog";

const SelfBillingShipment: React.FC = () => {
    const {t} = useTranslation();

    const user = useAppSelector(selectCurrentUser);

    const [model, setModel] = useState<ISelfBilling | null>(null);
    const [actions, setActions] = useState<Array<FormLayoutToolbarActionItem | 'separator'>>([]);
    const [isEditBillingDataDialogShown, setIsEditBillingDataDialogShown] = useState<boolean>(false);
    const [isAddExpenseDialogShown, setIsAddExpenseDialogShown] = useState<boolean>(false);

    const {
        getSelfBillingDataAnonymous: {
            query,
            isLoading,
            cancel
        }
    } = useSelfBillingApi();

    const {
        getShipmentInvoiceAnonymous: {
            query: getShipmentInvoiceAnonymous,
            cancel: cancelGetShipmentInvoiceAnonymous
        }
    } = useInvoiceApi();

    useEffect(() => {
        (async () => {
            try {
                const response = await query();

                if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok && response.data.data) {
                    let hasAttachedDocuments = false;

                    response.data.data.expenses.forEach(expense => {
                        if (expense.cmrDocuments.length > 0) {
                            hasAttachedDocuments = true;
                        }
                    });

                    if (hasAttachedDocuments) {
                        try {
                            const shipmentInvoiceResponse = await getShipmentInvoiceAnonymous(response.data.data.shipmentGid, response.data.data.shipmentXid);

                            if (shipmentInvoiceResponse?.status === 200 &&
                                shipmentInvoiceResponse.data?.resultCode === ResponseResultCode.Ok &&
                                shipmentInvoiceResponse.data.data) {
                                response.data.data.shipmentInvoice = shipmentInvoiceResponse.data.data;
                            }
                        } catch (err) {
                            if (isAxiosError(err)) {
                                toast.error(`Unable to get shipment invoice. Error: ${err.message}`);
                            }
                        }
                    }

                    setModel(response.data.data);
                }
            } catch (err) {
                if (isAxiosError(err)) {
                    toast.error(`Unable to get self billing data. Error: ${err.message}`);
                }
            }
        })();

        return () => {
            cancel();
            cancelGetShipmentInvoiceAnonymous();
        }
    }, []);

    useEffect(() => {
        if (!model) {
            return;
        }

        setActions(buildActionsList());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model, t]);

    const buildActionsList = (): Array<FormLayoutToolbarActionItem | 'separator'> => {
        if (!model) {
            return [];
        }

        let arr: Array<FormLayoutToolbarActionItem | 'separator'> = [];

        /*arr.push({
            disabled: false,
            id: 'self-billing-edit-btn',
            title: t("shared.btn.edit"),
            onClick: () => setIsEditBillingDataDialogShown(prev => !prev),
            pinnedButtonType: ButtonType.Secondary
        });

        arr.push({
            disabled: false,
            id: 'self-billing-add-expense-btn',
            title: t("self-billing.btn.add-expense"),
            onClick: () => setIsAddExpenseDialogShown(prev => !prev),
            pinnedButtonType: ButtonType.Secondary
        });*/

        return arr;
    }

    return (
        <>
            {/*{model !== null && !isLoading &&
                <>
                    <EditBillingDataDialog shipmentGid={model.shipmentGid}
                                           show={isEditBillingDataDialogShown}
                                           setShow={setIsEditBillingDataDialogShown}
                                           user={user}/>

                    <AddExpenseDialog shipmentGid={model.shipmentGid}
                                      show={isAddExpenseDialogShown}
                                      setShow={setIsAddExpenseDialogShown}
                                      user={user}/>
                </>
            }*/}

            <FormLayout id={'self-billing-form'}
                        isLoading={model === null || isLoading}
                        showCloseIcon={false}
                        actions={actions}
                        close={() => {
                            //ignore
                        }}>
                {model !== null && !isLoading &&
                    <SelfBillingForm isAnonymousAccess={true}
                                     user={undefined}
                                     model={model}/>
                }
            </FormLayout>
        </>
    )
};

export default SelfBillingShipment;
