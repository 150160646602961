import React, {useContext} from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import Checkbox from "../../../../../../components/ui/Checkbox/Checkbox";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlag} from "@fortawesome/free-solid-svg-icons";
import cl from './InWorkTemplate.module.css';
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import {useOfferApi} from "../../../../../../app/api/offer";
import {GridContext} from "../../../../../../components/ui/Grid/Telerik/Grid";
import {GridService} from "../../../../../../components/ui/Grid/Telerik/api/GridService";
import {Query} from "../../../../../../app/types/Query";
import {OFFER_GRID_DATA_URL} from "../../components/OfferGridContent/OfferGridContent";

const InWorkTemplate = (props: any) => {
    const {t} = useTranslation();
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const user = useAppSelector(selectCurrentUser);

    const {
        workPostAction: {mutation: workPostAction}
    } = useOfferApi();

    const {
        updateRowData
    } = useContext(GridContext);

    let val = props.dataItem.inWork !== undefined && props.dataItem.inWork !== null && props.dataItem.inWork !== ''
        ? props.dataItem.inWork
        : null;

    if (!user || (props.dataItem.isAssign !== undefined && props.dataItem.isAssign !== null && props.dataItem.isAssign === true)) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                {props.children}
            </td>
        );
    }

    let inWorkByCurrentUser: boolean = val !== null
        ? val.split(',').map((item: string) => item.toLowerCase()).includes(user.name.toLowerCase())
        : false;

    const changeWorkValue = async () => {
        if (props.dataItem === null ||
            props.dataItem === undefined ||
            props.dataItem.id === undefined ||
            props.dataItem.id === null ||
            props.dataItem.orderReleaseId === undefined ||
            props.dataItem.orderReleaseId === null) {
            return;
        }

        try {
            updateRowData({
                ...props.dataItem,
                isRowLoading: true,
            });

            const response = await workPostAction({
                orderReleaseId: props.dataItem.orderReleaseId
            });

            if (response && response.status === 200 &&
                response.data && response.data.resultCode === ResponseResultCode.Ok) {

                let service = new GridService(OFFER_GRID_DATA_URL);
                service.execute({
                    skip: 0,
                    take: 1
                }, new Query().addParam('id', props.dataItem.id)).then(data => {
                    if (data.data && data.data.length === 1) {
                        let item = data.data[0];

                        updateRowData({
                            ...item,
                            isRowLoading: false
                        });
                    }
                });
            }
        } catch {
            toast.error('Unable to change work state of offer');

            updateRowData({
                ...props.dataItem,
                isRowLoading: false,
            });
        }
    }

    return (
        <td {...props.tdProps}
            colSpan={1}>
            <span className={`default-flex-cell default-overflow-cell grid-prevent-selection`}
                  style={{alignItems: 'center', gap: '5px', justifyContent: 'center'}}>
                <Checkbox checked={inWorkByCurrentUser}
                          className={'grid-prevent-selection'}
                          title={inWorkByCurrentUser
                              ? t("offer.grid.remove-offer-from-work")
                              : t("offer.grid.take-offer-to-work")}
                          change={() => {
                              (async () => {
                                  await changeWorkValue();
                              })();
                          }}
                          disabled={false}/>
                <div>
                    {val !== null &&
                        <>
                            <span title={`${t("offer.grid.in-work")}: ${val}`}
                                  className={cl.flag}>
                                <FontAwesomeIcon icon={faFlag}/>
                            </span>
                            <span style={{fontSize: '10px', marginLeft: '1px'}}>
                                {val.split(',').length}
                            </span>
                        </>
                    }
                </div>
        </span>
        </td>
    )
        ;
};

export default InWorkTemplate;
