import React from 'react';
import {useDataHelperApi} from "../../../../../app/api/dataHelper";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {IGridResultResponse} from "../../../../../app/interfaces/response/IGridResultResponse";
import {ISelectTreeModel} from "../../../../../app/interfaces/shared/ISelectTreeModel";
import TreeListBox from "../../../../ui/TreeListBox/TreeListBox";
import {SelectModel} from "../../../../../app/types/SelectModel";

type FilterPanelServiceProviderFilterContentProps = {
    id: string;
    onChange: (value: Array<SelectModel>) => void,
    value: Array<SelectModel>,
    isNotInBlackList: boolean | false;
    isNotInHideStatus: boolean | false;
}

const FilterPanelServiceProviderFilterContent: React.FC<FilterPanelServiceProviderFilterContentProps> = ({
                                                                                                             id,
                                                                                                             onChange,
                                                                                                             value,
                                                                                                             isNotInBlackList,
                                                                                                             isNotInHideStatus
                                                                                                         }) => {
    const {
        getServiceProvidersTree: {
            query: getServiceProvidersTree
        }
    } = useDataHelperApi();

    const load = async (skip: number, take: number, filter: string | null) => {
        try {
            const response = await getServiceProvidersTree(filter, isNotInBlackList, isNotInHideStatus, skip, take);

            if (response?.status === 200 && response.data) {
                return response.data;
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(`Unable to get service providers. Error: ${e.message}`);
            }
        }

        return {
            count: 0,
            result: []
        } as IGridResultResponse<ISelectTreeModel>;
    }

    return (
        <TreeListBox id={`${id}-tree-list-box`}
                     selected={value}
                     take={20}
                     height={250}
                     onDataLoad={load}
                     onChange={(ev) => {
                         onChange(ev.shaken.map(item => {
                             return {
                                 text: item.text,
                                 value: item.value
                             } as SelectModel
                         }));
                     }}/>
    );
};

export default FilterPanelServiceProviderFilterContent;