import React, {useEffect, useState} from 'react';
import useSelfBillingApi from "../../../../../app/api/selfBilling";
import {ISelfBillingOverview} from "../../../../../app/interfaces/selfBilling/ISelfBillingOverview";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import useDocumentTitle from "../../../../../hooks/useDocumentTitle/useDocumentTitle";
import useDefaultSiteLanguages from "../../../../../hooks/useDefaultSiteLanguages/useDefaultSiteLanguages";
import useMediaQuery from "../../../../../hooks/useMediaQuery/useMediaQuery";
import cl from './SelfBillingConfirmation.module.css';
import bg_image from "../../../../../assets/images/login-bg.jpg";
import Card from "../../../../../components/ui/Card/Card";
import LanguageSwitcher from "../../../../../components/ui/LanguageSwitcher/LanguageSwitcher";
import BackgroundImageContainer from '../../../../../components/ui/BackgroundImageContainer/BackgroundImageContainer';
import Container from "../../../../../components/ui/Container/Container";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import SelfBillingConfirmationForm from "./SelfBillingConfirmationForm/SelfBillingConfirmationForm";
import {
    ISelfBillingConfirmationPostAction
} from "../../../../../app/interfaces/selfBilling/ISelfBillingConfirmationPostAction";
import Alert from "../../../../../components/ui/Alert/Alert";

const SelfBillingConfirmation: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    useDocumentTitle({title: t("self-billing.labels.confirmation-page-title")});

    const languages = useDefaultSiteLanguages();

    const isMaxWidth470 = useMediaQuery('(max-width:450px)');
    const isMaxHeight490 = useMediaQuery('(max-height:490px)');

    const [model, setModel] = useState<ISelfBillingOverview | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [isSupportWillContact, setIsSupportWillContact] = useState<boolean>(false);

    const {
        getSelfBillingOverviewAnonymous: {
            query: getSelfBillingOverview,
            isLoading: getSelfBillingOverviewIsLoading
        },
        confirmSelfBillingAnonymous: {
            mutation: confirmSelfBilling
        }
    } = useSelfBillingApi();

    useEffect(() => {
        (async () => {
            try {
                const response = await getSelfBillingOverview();

                if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok && response.data.data) {
                    setModel(response.data.data);
                }
            } catch (err) {
                if (isAxiosError(err)) {
                    toast.error(`Unable to get self billing data. Error: ${err.message}`);
                }
            }
        })();
    }, []);

    const submit = async (values: ISelfBillingConfirmationPostAction) => {
        if (!model) {
            return;
        }

        setDisabled(prev => !prev);

        try {
            const response = await confirmSelfBilling(values);

            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                if (values.mode === 'n') {
                    setIsSupportWillContact(true);
                } else if (values.mode === 'y') {
                    navigate(`/selfbilling/shipment`);
                }
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(`Unable to confirm self billing. Error: ${e.message}`);
            }
        }

        setDisabled(prev => !prev);
    }

    return (
        <BackgroundImageContainer image={bg_image}>
            <Container centerContent={true} height={'calc(100% - 30px)'}>
                <Card style={isMaxWidth470 ? {width: 'calc(100% - 20px)'} : {width: '550px'}}>
                    <div className={cl.languageSwitcherContainer}>
                        <LanguageSwitcher languages={languages} languageIconPosition={'right'}/>
                    </div>

                    <div className={cl.logo}
                         style={isMaxHeight490 ? {display: 'none'} : undefined}>
                        <img className={'prevent-selection'}
                             src={'/images/logo_main.png'}
                             alt={'logo'}/>
                    </div>

                    {!isSupportWillContact &&
                        <div className={`${cl.label} prevent-selection`}>
                            {t("self-billing.labels.confirmation-page-label")}
                        </div>
                    }

                    <div className={'pd-10'}>
                        {isSupportWillContact &&
                            <Alert type={'YellowInfo'}
                                   style={{
                                       margin: '',
                                       fontSize: '11px',
                                       fontWeight: 'bold',
                                       textAlign: 'center'
                                   }}>
                                {t("login.restore.restore-login-instructions-sent")}
                            </Alert>
                        }

                        {model === null || getSelfBillingOverviewIsLoading
                            ? <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
                            : null
                        }

                        {!getSelfBillingOverviewIsLoading && !isSupportWillContact && model !== null &&
                            <SelfBillingConfirmationForm model={model}
                                                         onSubmit={submit}
                                                         disabled={disabled}/>
                        }

                        <hr className={cl.separator}/>

                        <div className={cl.linkBtns}>
                            <button onClick={() => navigate('/selfbilling/start')}>
                                {t("self-billing.btn.back-to-self-billing-start-page")}
                            </button>
                        </div>
                    </div>
                </Card>
            </Container>
        </BackgroundImageContainer>
    );
};

export default SelfBillingConfirmation;
