import React, {ReactNode} from 'react';
import cl from '../../GridFilterPanelLayout.module.css';
import PersonalFiltersSection from "./PersonalFiltersSection/PersonalFiltersSection";
import PredefinedFiltersSection from "./PredefinedFiltersSection/PredefinedFiltersSection";
import Button, {ButtonType} from "../../../../ui/Button/Button";
import {useAppDispatch} from "../../../../../app/store";
import {setFilter} from "../../../../../features/filter/filterSlice";
import {useTranslation} from "react-i18next";
import {useGridFilterPanelLayoutContext} from "../../hooks/useGridFilterPanelLayoutContext";
import {CustomScroll} from "react-custom-scroll";
import useMediaQuery from "../../../../../hooks/useMediaQuery/useMediaQuery";
import {useGridLayoutContext} from "../../../GridLayout/hooks/useGridLayoutContext";

type GridFilterPanelLayoutFiltersTabProps = {
    disabled: boolean;
    children?: ReactNode;
}

const GridFilterPanelLayoutFiltersTab: React.FC<GridFilterPanelLayoutFiltersTabProps> = ({
                                                                                             disabled,
                                                                                             children
                                                                                         }) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const isMaxWidth600px = useMediaQuery('(max-width:600px)');

    const {
        filterToApply,
        entity
    } = useGridFilterPanelLayoutContext();

    const {
        isFilterPanelShown,
        setIsFilterPanelShown
    } = useGridLayoutContext<any>();

    return (
        <CustomScroll flex={'1'}>
            <PersonalFiltersSection disabled={disabled}>
                {children}
            </PersonalFiltersSection>

            <div className={cl.separator}></div>

            <PredefinedFiltersSection disabled={disabled}/>

            <div style={isMaxWidth600px ? {height: '70px'} : {height: '50px'}}></div>
            <div className={cl.btnContainer}>
                <Button buttonType={ButtonType.Secondary}
                        onClick={() => {
                            dispatch(setFilter({
                                entity: entity,
                                data: {...filterToApply, uid: new Date().getTime() + ""}
                            }));

                            if (isMaxWidth600px) {
                                setIsFilterPanelShown(!isFilterPanelShown);
                            }
                        }}>
                    {t("shared.btn.apply")}
                </Button>
            </div>
        </CustomScroll>
    );
};

export default GridFilterPanelLayoutFiltersTab;