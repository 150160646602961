import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'plus',
    pathData: [
        'M12,20a1,1,0,0,1-1-1V13H5a1,1,0,0,1,0-2h6V5a1,1,0,0,1,2,0v6h6a1,1,0,0,1,0,2H13v6A1,1,0,0,1,12,20Z'
    ],
    viewBox: '0 0 24 24'
};

export default icon;