import React, {useEffect} from 'react';
import cl from './ProgressBarItemFormSection.module.css';
import useElementSize from "../../../../../../../../hooks/useElementSize/useElementSize";
import {useTranslation} from "react-i18next";
import Input from "../../../../../../../../components/ui/Input/Input";
import moment from "moment";
import {ExtendedEvent, ExtendedShipmentEvent} from "../../../ShipmentsEventList";
import ReactDatePicker from "../../../../../../../../components/ui/ReactDatePicker/ReactDatePicker/ReactDatePicker";
import ReactTimePicker from "../../../../../../../../components/ui/ReactDatePicker/ReactTimePicker/ReactTimePicker";

type ProgressBarItemFormSectionProps = {
    event: ExtendedEvent;
    shipmentEvent: ExtendedShipmentEvent;
    change: (event: ExtendedEvent) => void;
    disabled: boolean;
}

const ProgressBarItemFormSection: React.FC<ProgressBarItemFormSectionProps> = ({
                                                                                   event,
                                                                                   shipmentEvent,
                                                                                   change,
                                                                                   disabled
                                                                               }) => {
    const [width] = useElementSize(`date-picker-${event.shipmentGid}-${shipmentEvent.sortPosition}`);
    const {t} = useTranslation();

    useEffect(() => {
        if (shipmentEvent.factDate !== null && shipmentEvent.factDate !== undefined) {
            if (shipmentEvent.editFactDate === undefined) {
                shipmentEvent.editFactDate = moment(shipmentEvent.factDate).toDate();
            }
            if (shipmentEvent.editFactTime === undefined) {
                shipmentEvent.editFactTime = moment(shipmentEvent.factDate).toDate();
            }
        } else if (shipmentEvent.date !== null && shipmentEvent.date !== undefined) {
            if (shipmentEvent.editFactDate === undefined) {
                shipmentEvent.editFactDate = moment(shipmentEvent.date).toDate();
            }
            if (shipmentEvent.editFactTime === undefined) {
                shipmentEvent.editFactTime = moment(shipmentEvent.date).toDate();
            }
        }

        change({
            ...event,
            shipmentEvents: [
                ...event.shipmentEvents?.filter(e => e.uuid !== shipmentEvent.uuid) ?? [],
                {
                    ...shipmentEvent,
                }
            ]
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFactDateChange = (val: Date | null) => {
        if (!event.shipmentEvents || disabled) {
            return;
        }

        change({
            ...event,
            shipmentEvents: [
                ...event.shipmentEvents.filter(e => e.uuid !== shipmentEvent.uuid),
                {
                    ...shipmentEvent,
                    editFactDate: val ?? undefined
                }
            ]
        });
    }

    const onFactTimeChange = (val: Date | null) => {
        if (!event.shipmentEvents || disabled) {
            return;
        }

        change({
            ...event,
            shipmentEvents: [
                ...event.shipmentEvents.filter(e => e.uuid !== shipmentEvent.uuid),
                {
                    ...shipmentEvent,
                    editFactTime: val ?? undefined
                }
            ]
        });
    }

    const onReasonCodeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.shipmentEvents || disabled) {
            return;
        }

        change({
            ...event,
            shipmentEvents: [
                ...event.shipmentEvents.filter(e => e.uuid !== shipmentEvent.uuid),
                {
                    ...shipmentEvent,
                    reason: ev.target.value
                }
            ]
        });
    }

    return (
        <div className={cl.container}>
            <div className={`${cl.twoCols} ${width > 0 && width < 120 ? cl.dirColumn : ''}`}>
                <div id={`date-picker-${event.shipmentGid}-${shipmentEvent.sortPosition}`}>
                    <ReactDatePicker disabled={disabled}
                                     size={'big'}
                                     value={shipmentEvent.editFactDate}
                                     onChange={onFactDateChange}
                                     hasError={shipmentEvent.hasDateError}
                                     placeholder={t("shared.labels.date")}
                    />
                </div>
                <div>
                    <ReactTimePicker size={'big'}
                                     disabled={disabled}
                                     value={shipmentEvent.editFactTime}
                                     hasError={shipmentEvent.hasDateError}
                                     placeholder={t("shared.labels.time")}
                                     onChange={onFactTimeChange}/>
                </div>
            </div>
            {shipmentEvent.showReasonCode &&
                <div>
                    <Input placeholder={t("shared.btn.show-reason-code")}
                           disabled={disabled}
                           value={shipmentEvent.reason ?? ''}
                           onChange={onReasonCodeChange}/>
                </div>
            }
        </div>
    );
};

export default ProgressBarItemFormSection;
