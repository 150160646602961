import React, {useEffect, useRef, useState} from 'react';
import {GridConfig} from "../../../../../../../../components/ui/Grid/Telerik/types/GridConfig";
import GridDefaultHeaderTemplate
    from "../../../../../../../../components/ui/Grid/Telerik/components/GridDefaultHeaderTemplate/GridDefaultHeaderTemplate";
import GridDefaultCellTemplate
    from "../../../../../../../../components/ui/Grid/Telerik/components/GridDefaultCellTemplate/GridDefaultCellTemplate";
import {ISendContractGetAction} from "../../../../../../../../app/interfaces/offer/ISendContractGetAction";
import {Query} from "../../../../../../../../app/types/Query";
import Grid from "../../../../../../../../components/ui/Grid/Telerik/Grid";
import {IUser} from "../../../../../../../../app/interfaces/user/IUser";

type SendContractContractSelectorProps = {
    model: ISendContractGetAction;
    serviceProviderId: string | null;
    value: string | null;
    onChange: (contractId: string | null) => void;
    user?: IUser;
};

const config: GridConfig = {
    sortBy: [],
    groupBy: [],
    pageSize: 100,
    headerTemplate: GridDefaultHeaderTemplate,
    cellTemplate: GridDefaultCellTemplate,
    columns: [
        {
            visible: true,
            field: 'billTo',
            title: 'offer.grid.contract-bill-to',
            width: 210,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            order: 0
        },
        {
            visible: true,
            field: 'effectiveDate',
            title: 'offer.grid.contract-effective-date',
            width: 120,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            order: 1
        },
        {
            visible: true,
            field: 'expirationDate',
            title: 'offer.grid.contract-expiration-date',
            width: 120,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            order: 2
        },
        {
            visible: true,
            field: 'transportMode',
            title: 'offer.grid.contract-transport-mode',
            width: 120,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            order: 3
        },
        {
            visible: true,
            field: 'rateService',
            title: 'offer.grid.contract-rate-service',
            width: 171,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            order: 4
        },
        {
            visible: true,
            field: 'rateVersion',
            title: 'offer.grid.contract-rate-version',
            width: 150,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            order: 5
        }
    ]
};

const SendContractContractSelector: React.FC<SendContractContractSelectorProps> = ({
                                                                                       model,
                                                                                       serviceProviderId,
                                                                                       value,
                                                                                       onChange,
                                                                                       user
                                                                                   }) => {
    const [query, setQuery] = useState<Query | null>(null);
    const isFirstSelectionDone = useRef<boolean>(false);
    const isFirstDataLoadDone = useRef<boolean>(false);

    useEffect(() => {
        let q = new Query().addParam('id', model.orderReleaseGid);

        if (serviceProviderId) {
            q.addParam('spId', serviceProviderId);
        }

        setQuery(q);
    }, [serviceProviderId]);

    if (!query) {
        return null;
    }

    return (
        <Grid id={'confirm-auction-contract-grid'}
              config={config}
              url={user
                  ? `/api/${process.env.REACT_APP_API_VERSION}/offer/getContracts`
                  : `/api/${process.env.REACT_APP_API_VERSION}/offer/getContractsAnonymous`}
              query={query}

              onDataLoad={() => {
                  if (!isFirstDataLoadDone.current) {
                      if (value !== null && value !== '') {
                          document.dispatchEvent(new CustomEvent('onGridMessage', {
                              detail: {
                                  action: 'selectItemByKey',
                                  gridId: 'confirm-auction-contract-grid',
                                  value: value
                              }
                          }));
                      }

                      isFirstDataLoadDone.current = true;
                  }
              }}
              onSelect={(rows) => {
                  if (!isFirstSelectionDone.current) {
                      isFirstSelectionDone.current = true;

                      return;
                  }

                  onChange(rows !== null && rows.length > 0
                      ? rows[0].id
                      : null);
              }}

              width={'100%'}
              height={'calc(100% - 13px)'}
              allowGrouping={false}
              allowReordering={false}
              allowPaging={false}
              allowResizing={false}
              allowSelection={true}
              allowSorting={false}
              selectionMode={'single'}
        />
    );
};

export default SendContractContractSelector;