import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useQuoteApi} from "../../../../../app/api/quote";
import {ICancelQuoteGetAction} from "../../../../../app/interfaces/quote/ICancelQuoteGetAction";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {isAxiosError} from "axios";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {ICancelQuotePostAction} from "../../../../../app/interfaces/quote/ICancelQuotePostAction";
import SelectedQuotes from "../../form/components/SelectedQuotes/SelectedQuotes";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import CancelQuoteForm from "./CancelQuoteForm/CancelQuoteForm";

type CancelQuoteProps = {
    id: string | Array<string>;
    close?: () => void;
}

const CancelQuote: React.FC<CancelQuoteProps> = ({
                                                     id,
                                                     close
                                                 }) => {
    const {t} = useTranslation();
    const [model, setModel] = useState<ICancelQuoteGetAction | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        getCancelQuoteData: {
            isLoading,
            cancel,
            query: getCancelQuoteData
        },
        cancelQuote: {
            cancel: cancelCancelQuote,
            mutation: cancelQuote
        }
    } = useQuoteApi();

    useEffect(() => {
        if (!id || id === '') {
            return;
        }

        (async () => {
            try {
                const response = await getCancelQuoteData(id);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch {
                //ignore
            }
        })();

        return () => {
            cancel();
            cancelCancelQuote();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const save = async (model: ICancelQuotePostAction): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            const response = await cancelQuote(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [...id].map((item) => {
                            return {
                                id: item,
                                isRowLoading: true
                            };
                        })
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.quote")} ${model.orderReleaseGid !== '' ? model.orderReleaseGid.split(';')[0] : ''}`}/>);
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(e.message);
            }
        }

        setDisabled(prev => !prev);
    }

    if ((Array.isArray(id) && id.length <= 0) || id === '') {
        return null;
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                {Array.isArray(id) && id.length > 1 && <SelectedQuotes ids={id}/>}
                <CancelQuoteForm model={model}
                                 disabled={disabled}
                                 save={save}/>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        form='cancel-quote-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.confirm")}
                </Button>
            </div>
        </div>
    );
};

export default CancelQuote;
