import {ColumnModel} from "../../../types/ColumnModel";

export const reIndexLockedColumns = (columns: Array<ColumnModel>): Array<ColumnModel> => {
    if (!columns.find(e => e.locked !== undefined && e.locked)) {
        return columns;
    }

    let cols: Array<ColumnModel> = [];

    let constantlyLockedCount = columns.filter(e => e.constantlyLocked !== undefined && e.constantlyLocked).length;

    columns.filter(e => e.constantlyLocked !== undefined && e.constantlyLocked)
        .forEach(e => {
            if (e.constantlyLocked !== undefined && e.constantlyLocked) {
                cols.push({...e});
            }
        });

    columns.filter(e => e.locked !== undefined && e.locked && (e.constantlyLocked === undefined || !e.constantlyLocked))
        .forEach((item, index) => {
            cols.push({
                ...item,
                order: index + constantlyLockedCount
            });
        });

    let index = cols.slice(-1)[0].order;

    columns
        .sort((a, b) => {
            return (a.order ?? 0) - (b.order ?? 0);
        })
        .filter(e => !e.locked).forEach((item) => {
        if (index !== undefined) {
            index = index + 1;

            cols.push({
                ...item,
                order: index
            });
        }
    });

    return cols;
};