import React, {ReactNode} from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {useAppSelector} from "../../store";
import {
    selectUserAuthenticationStatus
} from "../../../features/authentication/authenticationSliceSelectors";
import {AuthenticationStatus} from "../../../features/authentication/IAuthenticationState";

type AnonymousRouteProps = {
    redirect?: string;
    children: ReactNode;
}

const AnonymousRoute: React.FC<AnonymousRouteProps> = ({redirect = '/', children}) => {
    const location = useLocation();
    const userAuthenticationStatus = useAppSelector(selectUserAuthenticationStatus);

    if (userAuthenticationStatus === AuthenticationStatus.Authenticated) {
        let redirectTo = redirect;
        if (location.state && location.state.from && location.state.from.pathname && location.state.from.pathname !== '/') {
            redirectTo = location.state.from.pathname;

            if (location.state.from.search && location.state.from.search !== '') {
                redirectTo += location.state.from.search;
            }
        }

        return <Navigate to={redirectTo} replace state={{from: location}}/>;
    }

    return <>{children}</>;
};

export default AnonymousRoute;