import React from 'react';
import {IUser} from "../../../../../app/interfaces/user/IUser";
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import RequestInvalidVat from "./RequestInvalidVat";

type RequestInvalidVatDialogProps = {
    orderReleaseGid: string;
    shipmentGid: string;
    user?: IUser;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const RequestInvalidVatDialog: React.FC<RequestInvalidVatDialogProps> = ({
                                                                             show,
                                                                             setShow,
                                                                             user,
                                                                             orderReleaseGid,
                                                                             shipmentGid
                                                                         }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'assign-offer-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("self-billing.btn.invalid-vat")}>
            <RequestInvalidVat orderReleaseGid={orderReleaseGid}
                               shipmentGid={shipmentGid}
                               user={user}
                               close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default RequestInvalidVatDialog;