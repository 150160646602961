function makeCanvasContext() {
    const canvas = document.createElement('canvas')
    canvas.width = 240
    canvas.height = 140
    canvas.style.display = 'inline'
    return [canvas, canvas.getContext('2d')] as const
}

function isSupported(
    canvas: HTMLCanvasElement,
    context?: CanvasRenderingContext2D | null,
): context is CanvasRenderingContext2D {
    return !!(context && canvas.toDataURL)
}

function save(canvas: HTMLCanvasElement) {
    return canvas.toDataURL()
}

export interface CanvasFingerprint {
    winding: boolean
    data: string
}

export default function getCanvasFingerprint(): CanvasFingerprint {
    const [canvas, context] = makeCanvasContext()
    if (!isSupported(canvas, context)) {
        return {winding: false, data: ''}
    }

    context.rect(0, 0, 10, 10)
    context.rect(2, 2, 6, 6)
    const winding = !context.isPointInPath(5, 5, 'evenodd')

    context.textBaseline = 'alphabetic'
    context.fillStyle = '#f60'
    context.fillRect(125, 1, 62, 20)
    context.fillStyle = '#069'

    context.font = '11pt no-real-font-123'

    const printedText = `asstra-portal-client-fingerprint-canvas-text`
    context.fillText(printedText, 2, 15)
    context.fillStyle = 'rgba(102, 204, 0, 0.2)'
    context.font = '18pt Arial'
    context.fillText(printedText, 4, 45)

    context.globalCompositeOperation = 'multiply'
    for (const [color, x, y] of [
        ['#f0f', 50, 50],
        ['#0ff', 100, 50],
        ['#ff0', 75, 100],
    ] as const) {
        context.fillStyle = color
        context.beginPath()
        context.arc(x, y, 50, 0, Math.PI * 2, true)
        context.closePath()
        context.fill()
    }

    context.fillStyle = '#f0f'
    context.arc(75, 75, 75, 0, Math.PI * 2, true)
    context.arc(75, 75, 25, 0, Math.PI * 2, true)
    context.fill('evenodd')

    return {
        winding,
        data: save(canvas),
    }
}