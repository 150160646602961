import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {IGridFilter} from "../../../../../../app/interfaces/gridFilter/IGridFilter";
import {useGridFilterPanelLayoutContext} from "../../../hooks/useGridFilterPanelLayoutContext";
import GridFilterPanelLayoutCollapse
    from "../../../components/GridFilterPanelLayoutCollapse/GridFilterPanelLayoutCollapse";
import cl from '../../../GridFilterPanelLayout.module.css';
import ConfirmationDialog from "../../../../../ui/Dialog/ConfirmationDialog/ConfirmationDialog";
import {useAppDispatch} from "../../../../../../app/store";
import {
    setFilter, setFilterToApply, setPersonalFilters,
    setSelectedPersonalFilter,
    setSelectedPredefinedFilter
} from "../../../../../../features/filter/filterSlice";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {LayoutConfigEntity} from "../../../../../../app/enums/LayoutConfigEntity";
import {useGridFilterApi} from "../../../../../../app/api/gridFilter";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faThumbTack} from "@fortawesome/free-solid-svg-icons";
import Icon from "../../../../../../assets/icon/Icon";

type PredefinedFiltersSectionProps = {
    disabled: boolean;
}

const PredefinedFiltersSection: React.FC<PredefinedFiltersSectionProps> = ({
                                                                               disabled
                                                                           }) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const [isDeleteConfirmationDialogShown, setIsDeleteConfirmationDialogShown] = useState<boolean>(false);
    const [filterToRemove, setFilterToRemove] = useState<IGridFilter | null>(null);

    const {
        setDisabled,
        personalFilters,
        predefinedFilters,
        selectedPersonalFilter,
        selectedPredefinedFilter,
        entity,
        onPersonalFilterRemove,
        pinnedPersonalFilter,
        onPersonalFilterPin,
        pinnedPredefinedFilters,
        onPredefinedFilterPin,
        onPredefinedFilterSelect
    } = useGridFilterPanelLayoutContext();

    const {
        deleteGridFilter: {
            mutation: deleteGridFilter
        }
    } = useGridFilterApi();

    if (personalFilters.length <= 0 && predefinedFilters.length <= 0) {
        return null;
    }

    return (
        <GridFilterPanelLayoutCollapse style={{margin: '0', padding: '15px 10px 10px 15px'}}
                                       titleUpperCase={true}
                                       title={
                                           <>
                                               <div>
                                                   {t("shared.labels.saved-filters")}
                                               </div>
                                               <div>
                                                   <button
                                                       disabled={selectedPersonalFilter === null && selectedPredefinedFilter === null}
                                                       onClick={(ev) => {
                                                           ev.stopPropagation();

                                                           if (disabled) {
                                                               return;
                                                           }

                                                           let val = {
                                                               entity,
                                                               data: null
                                                           };

                                                           if (selectedPersonalFilter !== null) {
                                                               dispatch(setFilter(val));
                                                               dispatch(setFilterToApply(val));

                                                               dispatch(setSelectedPersonalFilter(val));
                                                           }

                                                           if (selectedPredefinedFilter !== null) {
                                                               dispatch(setSelectedPredefinedFilter(val))
                                                           }
                                                       }}
                                                       className={cl.resetBtn}>
                                                       {t("shared.btn.reset")}
                                                   </button>
                                               </div>
                                           </>
                                       }>
            <ConfirmationDialog id={'delete-filter-confirmation-dialog'}
                                show={isDeleteConfirmationDialogShown}
                                setShow={setIsDeleteConfirmationDialogShown}
                                type={'Alert'}
                                title={t("shared.labels.deleting-filter-confirmation-title")}
                                text={t("shared.labels.deleting-filter-confirmation-text")}
                                onCancel={() => setIsDeleteConfirmationDialogShown(prev => !prev)}
                                onSubmit={async () => {
                                    if (!filterToRemove) {
                                        return;
                                    }

                                    setDisabled(prev => !prev);

                                    try {
                                        const response = await deleteGridFilter(filterToRemove);

                                        if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                                            if (selectedPersonalFilter && selectedPersonalFilter.id === filterToRemove.id) {
                                                let f = {
                                                    entity,
                                                    data: null
                                                };

                                                dispatch(setSelectedPersonalFilter(f));

                                                dispatch(setFilter(f));

                                                dispatch(setFilterToApply(f));
                                            }

                                            if (onPersonalFilterRemove) {
                                                onPersonalFilterRemove(filterToRemove);
                                            }

                                            dispatch(setPersonalFilters({
                                                entity,
                                                data: [
                                                    ...personalFilters.filter(e => e.id !== filterToRemove.id)
                                                ]
                                            }));
                                        }
                                    } catch (e) {
                                        if (isAxiosError(e)) {
                                            toast.error(`Unable to delete ${LayoutConfigEntity[entity].toString()} personal filter. Error: ${e.message}`);
                                        }
                                    }

                                    setIsDeleteConfirmationDialogShown(prev => !prev);

                                    setDisabled(prev => !prev);
                                }}/>
            {personalFilters.length > 0 &&
                <GridFilterPanelLayoutCollapse showSeparationHeaderLine={false}
                                               style={{margin: '5px 0px 10px 0px'}}
                                               title={
                                                   <div>
                                                       {t("shared.labels.personal-filter")}
                                                   </div>
                                               }>
                    {personalFilters.map(filter => {
                        let isSelected = selectedPersonalFilter !== null
                            ? filter.id === selectedPersonalFilter.id
                            : false

                        let isPinned = pinnedPersonalFilter !== null
                            ? filter.id === pinnedPersonalFilter
                            : false;

                        return (
                            <div key={filter.id}
                                 onClick={() => {
                                     if (disabled) {
                                         return;
                                     }

                                     if (isSelected) {
                                         dispatch(setFilter({
                                             entity,
                                             data: null
                                         }));
                                     }

                                     dispatch(setFilterToApply({
                                         entity,
                                         data: isSelected ? null : JSON.parse(filter.value)
                                     }));

                                     dispatch(setSelectedPersonalFilter({
                                         entity,
                                         data: isSelected ? null : filter
                                     }));
                                 }}
                                 className={`${cl.filterRow} ${!isSelected && isPinned ? `${cl.filterRowFourColumn}` : ''} ${isSelected ? `${cl.selected} ${cl.filterRowFourColumn}` : ''} ${disabled ? cl.disabled : ''} transition-all-500`}>
                                <div className={cl.center}>
                                    <Icon className={`${cl.filterIcon} ${isSelected ? cl.selectedIcon : ''}`}
                                          icon={"faBarFilter"}/>
                                </div>
                                <div className={`${cl.filterRowText} ${cl.center}`} title={filter.name}>
                                    {filter.name}
                                </div>
                                {(isSelected || isPinned && onPersonalFilterPin)
                                    ? <div className={cl.iconContainer}>
                                        <FontAwesomeIcon icon={faThumbTack} style={{marginRight: '10px'}}
                                                         title={t("shared.labels.pin-filter-label")}
                                                         onClick={(ev) => {
                                                             ev.stopPropagation();

                                                             if (disabled) {
                                                                 return;
                                                             }

                                                             if (onPersonalFilterPin) {
                                                                 onPersonalFilterPin(isPinned ? null : filter);
                                                             }
                                                         }}
                                                         className={`${cl.pinIcon} ${isPinned ? cl.pinned : ''}`}/>
                                    </div>
                                    : null
                                }
                                <div className={cl.iconContainer}>
                                    <Icon icon={"faTrash"}
                                          className={cl.trashIcon}
                                          title={t("shared.labels.delete-filter-label")}
                                          onClick={(ev) => {
                                              ev.stopPropagation();

                                              if (disabled) {
                                                  return;
                                              }

                                              if (onPersonalFilterRemove) {
                                                  setFilterToRemove(filter);
                                                  setIsDeleteConfirmationDialogShown(prev => !prev);
                                              }
                                          }}/>
                                </div>
                            </div>
                        );
                    })}
                </GridFilterPanelLayoutCollapse>
            }
            {predefinedFilters.length > 0 &&
                <GridFilterPanelLayoutCollapse showSeparationHeaderLine={false}
                                               style={{margin: '5px 0px 10px 0px'}}
                                               title={
                                                   <div>
                                                       {t("shared.labels.predefined-filter")}
                                                   </div>
                                               }>
                    {predefinedFilters.map(filter => {
                        let isSelected = selectedPredefinedFilter !== null
                            ? filter.value === selectedPredefinedFilter.value
                            : false

                        let isPinned = pinnedPredefinedFilters !== null && pinnedPredefinedFilters.length > 0
                            ? pinnedPredefinedFilters.find(e => e === filter.value) !== undefined
                            : false;

                        let text = t(`${LayoutConfigEntity[entity].toString().toLowerCase()}.filters.pf${filter.text}`);

                        return (
                            <div key={filter.value}
                                 onClick={() => {
                                     if (disabled) {
                                         return;
                                     }

                                     dispatch(setSelectedPredefinedFilter({
                                         entity,
                                         data: isSelected ? null : filter
                                     }));

                                     if (onPredefinedFilterSelect) {
                                         onPredefinedFilterSelect(isSelected ? null : filter);
                                     }
                                 }}
                                 className={`${cl.filterRow} ${isSelected ? cl.selected : ''} ${disabled ? cl.disabled : ''} transition-all-500`}>
                                <div className={cl.center}>
                                    <Icon className={`${cl.filterIcon} ${isSelected ? cl.selectedIcon : ''}`}
                                          icon={"faBarFilter"}/>
                                </div>
                                <div className={`${cl.filterRowText} ${cl.center}`} title={text}>
                                    {text}
                                </div>
                                <div className={cl.iconContainer}>
                                    {onPredefinedFilterPin &&
                                        <Icon icon={"faBookmark"}
                                              title={t("shared.labels.pin-filter-label")}
                                              onClick={(ev) => {
                                                  ev.stopPropagation();

                                                  if (disabled) {
                                                      return;
                                                  }

                                                  if (onPredefinedFilterPin) {
                                                      onPredefinedFilterPin(filter, isPinned ? 'Remove' : 'Add');
                                                  }
                                              }}
                                              fill={isPinned ? '#3F8CE5' : undefined}
                                              className={`${cl.pinIcon} ${isPinned ? cl.pinned : ''}`}/>
                                    }
                                </div>
                            </div>
                        );
                    })}
                </GridFilterPanelLayoutCollapse>
            }
        </GridFilterPanelLayoutCollapse>
    );
};

export default PredefinedFiltersSection;
