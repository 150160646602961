import React, {useCallback} from "react";
import {AxiosError, AxiosResponse, isAxiosError} from "axios";
import {IResponse, isResponse} from "../../app/interfaces/response/IResponse";
import {toast} from "react-toastify";

type UseDownloadFileProps = {
    readonly apiFunc?: ((param?: string) => Promise<AxiosResponse<Blob | IResponse>>);
    readonly getBlob?: ((param?: string, param2?: string) => Promise<Blob | undefined>);
    readonly beforeDownload?: () => void;
    readonly afterDownload?: () => void;
    readonly onError?: (ev: AxiosError<any, any>) => void;
    readonly target?: React.HTMLAttributeAnchorTarget;
}

type UseDownloadFileReturn = {
    readonly download: (param?: string, param2?: string) => Promise<void>;
}

export const useDownloadFile = ({
                                    apiFunc,
                                    getBlob,
                                    beforeDownload,
                                    afterDownload,
                                    onError,
                                    target = '_blank'
                                }: UseDownloadFileProps): UseDownloadFileReturn => {
    const download = useCallback(async (param?: string, param2?: string) => {
        if (!apiFunc && !getBlob) {
            return;
        }

        try {
            if (beforeDownload) {
                beforeDownload();
            }

            let resp: Blob | AxiosResponse<Blob | IResponse, any> | undefined = undefined;

            if (apiFunc) {
                resp = await apiFunc(param);
            } else if (getBlob) {
                resp = await getBlob(param, param2);
            }

            if (resp === undefined) {
                return;
            }

            let data = resp instanceof Blob
                ? resp
                : resp.data;

            if (!isResponse(data)) {
                const url = URL.createObjectURL(new Blob([data]));

                const aElement = document.createElement('a');
                aElement.style.display = 'none';
                aElement.href = url;

                aElement.target = target.toString();

                if (param) {
                    aElement.download = param;
                }

                document.body.appendChild(aElement);
                aElement.click();

                window.URL.revokeObjectURL(url);

                if (afterDownload) {
                    afterDownload();
                }
            }
        } catch (error) {
            if (isAxiosError(error)) {
                if (onError) {
                    onError(error);
                } else {
                    toast.error(`Unable to download ${param ?? ''} file. Error: ${error.message}`);
                }
            }
        }
    }, [apiFunc, beforeDownload, afterDownload, onError]);

    return {download};
};