import React, {useEffect, useState} from 'react';
import {ResponseResultCode} from "../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {IPhoneFormat} from "../../../app/interfaces/shared/IPhoneFormat";
import {useCountryApi} from "../../../app/api/country";
import cl from './PhoneNumberInput.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMobileScreenButton} from "@fortawesome/free-solid-svg-icons";
import {DropDownList, DropDownListFilterChangeEvent} from "@progress/kendo-react-dropdowns";
import {groupBy, GroupResult} from "@progress/kendo-data-query";
import {useTranslation} from "react-i18next";
import {MaskedTextBox} from "@progress/kendo-react-inputs";
import {maskString} from "./utils";

type PhoneNumberInputProps = {
    disabled: boolean;
    showIcon?: boolean;
    phoneCode: string | null;
    phoneNumber: string | null;
    onPhoneCodeChange: (val: IPhoneFormat) => void;
    onPhoneNumberChange: (val: string | null) => void;
}

const groupHeaderItemRender = (li: any) => {
    const itemHeader = (
        <b
            style={{
                color: "#aaa",
                fontSize: '12px',
                fontWeight: 'bold',
                fontStyle: 'none',
                outline: 'none',
                transition: 'all 0.2s',
                fontFamily: 'inherit'
            }}
        >
            {li.props.children}
        </b>
    );
    return React.cloneElement(li, li.props, itemHeader);
};

const stickyHeaderRender = (div: any) => {
    const item = (
        <span
            style={{
                color: "#aaa",
                fontSize: '12px',
                fontWeight: 'bold',
                fontStyle: 'none',
                outline: 'none',
                transition: 'all 0.2s',
                fontFamily: 'inherit'
            }}
        >
        {div.props.children}
      </span>
    );
    return React.cloneElement(div, div.props, item);
};

const itemRender = (li: any) => {
    const item = (
        <i
            style={{
                fontSize: '11px',
                fontWeight: '400',
                fontStyle: 'none',
                outline: 'none',
                transition: 'all 0.2s',
                fontFamily: 'inherit'
            }}
        >
            {li.props.children}
        </i>
    );
    return React.cloneElement(li, li.props, item);
};

const valueRender = (element: any, value: IPhoneFormat | null | undefined) => {
    if (!value) {
        return element;
    }

    const children = [
        <span
            key={1}
            style={{
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <span>
                 <img title={value.countryFullName}
                      alt={value.country}
                      src={`/images/flags/${value.country.toString().toUpperCase()}.png`}
                      style={{
                          width: '22px',
                          marginRight: '3px',
                          verticalAlign: 'middle'
                      }}/>
            </span>
            <span>
                {`${value.country} (+${value.phoneCode})`}
            </span>
      </span>
    ];
    return React.cloneElement(
        element,
        {
            ...element.props,
        },
        children
    );
};

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
                                                               disabled,
                                                               phoneCode,
                                                               phoneNumber,
                                                               onPhoneCodeChange,
                                                               onPhoneNumberChange,
                                                               showIcon = true
                                                           }) => {
    const {t} = useTranslation();
    const [data, setData] = useState<Array<IPhoneFormat & {
        group: string;
    }>>([]);
    const [filteredData, setFilteredData] = useState<Array<IPhoneFormat & {
        group: string
    }>>([]);
    const [selectedItem, setSelectedItem] = useState<IPhoneFormat & {
        group: string
    } | null>(null);

    const {
        getPhoneFormats: {
            query: getPhoneFormats,
            isLoading,
            cancel
        }
    } = useCountryApi();

    useEffect(() => {
        (async () => {
            try {
                const response = await getPhoneFormats();

                if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok &&
                    response.data?.data) {
                    let items: Array<IPhoneFormat & {
                        group: string;
                    }> = response.data.data.map(item => {
                        let group = Number(item.priority).toString() === '0'
                            ? t("shared.labels.other-countries")
                            : t("shared.labels.main-countries");

                        return {
                            ...item,
                            group: group
                        };
                    });

                    let groups: Array<GroupResult> = groupBy(items, [{field: 'group'}]) as Array<GroupResult>;

                    let res = groups.reduce((acc: Array<IPhoneFormat & {
                        group: string;
                    }>, group: GroupResult) => {
                        return [...acc, ...(group.items as Array<IPhoneFormat & {
                            group: string;
                        }>)];
                    }, []);

                    res.sort((a, b) => {
                        return Number(b.priority) - Number(a.priority);
                    });

                    setData(res);
                    setFilteredData(res);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error(`Unable to get phone formats: ${e.message}`);
                }
            }
        })();

        return () => {
            cancel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedItem) {
            return;
        }

        if (!phoneCode || phoneCode === '') {
           // if (data.length > 0) {
           //     setSelectedItem(data[0]);
           //     onPhoneCodeChange(data[0]);
          //  }
        } else {
            let exist = data.find(e => e.phoneCode === phoneCode) ?? null;
            setSelectedItem(exist);
            if (exist) {
                onPhoneCodeChange(exist);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phoneCode, data]);

    const onFilter = (ev: DropDownListFilterChangeEvent) => {
        if (ev.filter === null || ev.filter === undefined ||
            ev.filter.value === null || ev.filter.value === undefined || ev.filter.value === '') {
            setFilteredData(data);

            return;
        }

        let val = ev.filter.value.toLowerCase();

        setFilteredData(data.filter(item => {
            return item.country.toLowerCase().startsWith(val) ||
                item.code3.toLowerCase().startsWith(val) ||
                item.countryFullName.toLowerCase().startsWith(val) ||
                item.phoneCode.toLowerCase().startsWith(val) ||
                item.phoneCode.toLowerCase().startsWith(val.replace('+', ''));
        }));
    }

    return (
        <div
            className={`masked-input mobile-phone-input drop-down-picker ${cl.container} ${showIcon ? cl.wi : ''} ${showIcon ? 'mobile-picker' : ''}`}>
            {showIcon &&
                <span className={cl.iconContainer}>
                    <FontAwesomeIcon icon={faMobileScreenButton}/>
                </span>
            }
            <DropDownList data={filteredData}
                          value={selectedItem}
                          disabled={disabled || isLoading}
                          loading={isLoading}
                          textField="selectLabel"
                          dataItemKey="id"
                          groupField={'group'}
                          filterable={true}
                          valueRender={valueRender}
                          itemRender={itemRender}
                          groupHeaderItemRender={groupHeaderItemRender}
                          groupStickyHeaderItemRender={stickyHeaderRender}
                          popupSettings={{
                              width: '250px',
                              animate: false
                          }}
                          onChange={(ev) => {
                              setSelectedItem(ev.value);

                              onPhoneCodeChange(ev.value);
                              onPhoneNumberChange(null);
                          }}
                          onFilterChange={onFilter}
            />
            {/*selectedItem?.mask == '' это единственный случай, для Сербии*/}
            <MaskedTextBox value={selectedItem?.mask == '' 
                ? (phoneNumber 
                    ? phoneNumber 
                    : '')
                : (phoneNumber 
                    ? maskString(phoneNumber, selectedItem?.mask ?? '') : '')}
                           disabled={disabled || isLoading}
                           placeholder={selectedItem?.placeholder}
                           mask={selectedItem?.mask}
                           onChange={(ev) => {
                               let res = (ev.value !== null && ev.value === '') || ev.value?.replace(/\D/g, '') === ''
                                   ? null
                                   : ev.value?.replace(/\D/g, '');

                               onPhoneNumberChange(res);
                           }}/>
            
        </div>
    );
};

export default PhoneNumberInput;
