import React from 'react';
import SidebarUserSectionGlobalSearch from "./SidebarUserSectionGlobalSearch/SidebarUserSectionGlobalSearch";
import SidebarUserSectionUser from "./SidebarUserSectionUser/SidebarUserSectionUser";

const SidebarUserSection: React.FC = () => {

    return (
        <>
            <SidebarUserSectionUser/>

            <SidebarUserSectionGlobalSearch/>
        </>
    );
};

export default SidebarUserSection;
