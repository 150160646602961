import React from 'react';
import cl from './Input.module.css';
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type InputProps = {
    disabled: boolean;
    image?: any;
    icon?: IconProp;
} & React.HTMLProps<HTMLInputElement>;

const Input: React.FC<InputProps> = ({
                                         disabled,
                                         image,
                                         icon,
                                         className,
                                         ...rest
                                     }) => {
  /*  if (image !== undefined || icon !== undefined) {
        return (
            <div className={cl.container}>
                <span className={cl.iconContainer}>
                    {icon !== undefined && image === undefined
                        ? <FontAwesomeIcon icon={icon}/>
                        : null
                    }
                    {icon === undefined && image !== undefined
                        ? <img src={image} alt={'icon'}/>
                        : null
                    }
                </span>
                <input className={`${cl.input} ${className}`} disabled={disabled} {...rest} />
            </div>
        );
    } else {*/
        return (
            <input className={`${cl.input} ${className}`} disabled={disabled} {...rest} />
        );
    //}
};

export default Input;
