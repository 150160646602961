import React, {useState} from 'react';
import {toast} from "react-toastify";
import {isAxiosError} from "axios";
import {IInvoiceDetailsRequestPostAction} from "../../../../../app/interfaces/invoice/IInvoiceDetailsRequestPostAction";
import InvoiceRequestDetailsForm from "./InvoiceRequestDetailsForm/InvoiceRequestDetailsForm";
import useInvoiceApi from "../../../../../app/api/invoice";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {useTranslation} from "react-i18next";
import {IUser} from "../../../../../app/interfaces/user/IUser";

type InvoiceRequestDetailsProps = {
    user?: IUser;
    invoiceGid: string;
    mode: 'Answer' | 'Block' | 'Payment';
    close: () => void;
};

const InvoiceRequestDetails: React.FC<InvoiceRequestDetailsProps> = ({
                                                                         user,
                                                                         invoiceGid,
                                                                         mode,
                                                                         close
                                                                     }) => {
    const {t} = useTranslation();
    const [disabled] = useState<boolean>(false);

    const {
        requestAnswerAction: {
            mutation: requestAnswerAction,
            isLoading: requestAnswerActionIsLoading
        },
        requestBlockAction: {
            mutation: requestBlockAction,
            isLoading: requestBlockActionIsLoading
        },
        requestPaymentAction: {
            mutation: requestPaymentAction,
            isLoading: requestPaymentActionIsLoading
        },
        requestBlockActionAnonymous: {
            mutation: requestBlockActionAnonymous,
            isLoading: requestBlockActionAnonymousIsLoading
        },
        requestPaymentActionAnonymous: {
            mutation: requestPaymentActionAnonymous,
            isLoading: requestPaymentActionAnonymousIsLoading
        }
    } = useInvoiceApi();

    const submit = async (model: IInvoiceDetailsRequestPostAction) => {
        try {
            if (mode === 'Payment') {
                const response = user
                    ? await requestPaymentAction(model)
                    : await requestPaymentActionAnonymous(model);

                if (response?.status === 200 && response?.data?.resultCode === ResponseResultCode.Ok) {
                    toast.success(<ToastLayout
                        title={`${t("invoice.form.invoice-label")} ${invoiceGid.replace('AAG.', '')}`}
                        text={t("invoice.form.message-sent-label")}/>);

                    close();
                }

                return;
            }

            if (mode === 'Answer') {
                const response = await requestAnswerAction(model);

                if (response?.status === 200 && response?.data?.resultCode === ResponseResultCode.Ok) {
                    toast.success(<ToastLayout
                        title={`${t("invoice.form.invoice-label")} ${invoiceGid.replace('AAG.', '')}`}
                        text={t("invoice.form.message-sent-label")}/>);

                    close();
                }

                return;
            }

            if (mode === 'Block') {
                const response = user
                    ? await requestBlockAction(model)
                    : await requestBlockActionAnonymous(model);

                if (response?.status === 200 && response?.data?.resultCode === ResponseResultCode.Ok) {
                    toast.success(<ToastLayout
                        title={`${t("invoice.form.invoice-label")} ${invoiceGid.replace('AAG.', '')}`}
                        text={t("invoice.form.message-sent-label")}/>);

                    close();
                }

                return;
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(`Unable to send a request. Error: ${e.message}`);
            }
        }
    }

    return (
        <InvoiceRequestDetailsForm invoiceGid={invoiceGid}
                                   disabled={disabled ||
                                       requestPaymentActionIsLoading ||
                                       requestBlockActionIsLoading ||
                                       requestAnswerActionIsLoading ||
                                       requestPaymentActionAnonymousIsLoading ||
                                       requestBlockActionAnonymousIsLoading}
                                   submit={submit}/>
    );
};

export default InvoiceRequestDetails;