import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'bookmark',
    pathData: [
        'M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1V13.865C12 14.7128 11.0111 15.176 10.3598 14.6332L6.64018 11.5335C6.26934 11.2244 5.73066 11.2244 5.35982 11.5335L1.64018 14.6332C0.988856 15.176 0 14.7128 0 13.865V1Z'
    ],
    viewBox: '0 0 12 15',
    strokeWidth: 1
};

export default icon;