import React from 'react';
import {IChangeRequisitesGetAction} from "./interfaces/IChangeRequisitesGetAction";
import {IChangeRequisitesPostAction} from "./interfaces/IChangeRequisitesPostAction";
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import ChangeRequisites from "./ChangeRequisites";

type ChangeRequisitesDialogProps = {
    model: IChangeRequisitesGetAction;
    onChange: (model: IChangeRequisitesPostAction) => void;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChangeRequisitesDialog: React.FC<ChangeRequisitesDialogProps> = ({
                                                                           show,
                                                                           setShow,
                                                                           model,
                                                                           onChange
                                                                       }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'change-cmr-requisites-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("self-billing.labels.requisites-selection")}>
            <ChangeRequisites model={model}
                              onChange={onChange}
                              close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default ChangeRequisitesDialog;