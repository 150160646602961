import React, {useState} from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import {useOfferApi} from "../../../../../../app/api/offer";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import cl from './OrderReleaseXidTemplate.module.css';
import auctionImg from '../../../../../../assets/images/auction.png';
import contractImg from '../../../../../../assets/images/contract.png';
import freeFreightImg from '../../../../../../assets/images/ff.png';
import contractNewImg from '../../../../../../assets/images/contract_new.png';
import transEu from '../../../../../../assets/images/trans-eu.png';
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faTriangleExclamation, faUser, faUsers} from "@fortawesome/free-solid-svg-icons";
import SendMessageToMultipleServiceProvidersDialog
    from "../../../../../../components/shared/components/Chat/actions/SendMessageToMultipleServiceProviders/SendMessageToMultipleServiceProvidersDialog";

const OrderReleaseXidTemplate = (props: any) => {
    const {t} = useTranslation();
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const user = useAppSelector(selectCurrentUser);

    const [isSendMessageDialogShown, setIsSendMessageDialogShown] = useState<boolean>(false);

    const {
        getOfferManagementLink: {
            query: getOfferManagementLink
        },
        getTransEULink: {
            query: getTransEULink
        }
    } = useOfferApi();

    let id = props.dataItem.id !== null &&
    props.dataItem.id !== undefined && props.dataItem.id !== ''
        ? props.dataItem.id
        : null;

    let auctionType = props.dataItem.auctionType !== undefined && props.dataItem.auctionType !== null && props.dataItem.auctionType !== ''
        ? Number(props.dataItem.auctionType)
        : null;

    if (!user || getUserRole(user) === UserRole.ServiceProvider) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={'default-flex-cell default-overflow-cell'}>
                    <>
                        {props.children}
                        {auctionType !== null && auctionType > 1 &&
                            <img src={contractImg} alt={'contract'}
                                 title={t("offer.grid.contract")}
                                 className={cl.auctionIcon}/>

                        }
                        {auctionType !== null && auctionType === 1 &&
                            <img src={auctionImg} alt={'auction'}
                                 title={t("offer.grid.order-to-confirm")}
                                 className={cl.auctionIcon}/>
                        }
                    </>
                </span>
            </td>
        );
    }

    let description = '';

    let releaseNumber = props.dataItem['orderReleaseXid'];
    if (props.dataItem['sourceDistrict'] && props.dataItem['sourceCity']
        && props.dataItem['destinationDistrict'] && props.dataItem['destinationCity']) {
        if (props.dataItem['pickupDate']) {
            description = `${props.dataItem['pickupDate']}, `;
        }

        description += `${props.dataItem['sourceDistrict']} ${props.dataItem['sourceCity']}`;

        description += ` - ${props.dataItem['destinationDistrict']} ${props.dataItem['destinationCity']}`
    }

    return (
        <>
            <SendMessageToMultipleServiceProvidersDialog show={isSendMessageDialogShown}
                                                         setShow={setIsSendMessageDialogShown}
                                                         replyTo={{
                                                             type: 'RELEASE',
                                                             id: releaseNumber,
                                                             idExt: null,
                                                             description: description === '' ? null : description
                                                         }}/>
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
            <span className={'default-flex-cell default-overflow-cell grid-prevent-selection'}>


                {props.dataItem.rejected !== undefined && props.dataItem.rejected !== null && props.dataItem.rejected === true &&
                    <div className={cl.rejectedMark}
                         title={t("offer.grid.rejected-mark")}>

                    </div>
                }

                {props.dataItem.isFirstOrder !== undefined && props.dataItem.isFirstOrder &&
                    <FontAwesomeIcon icon={faTriangleExclamation}
                                     title={t("shared.labels.first-order-label")}
                                     className={cl.firstOrder}/>
                }

                {props.dataItem.forwarder !== undefined && props.dataItem.forwarder !== null && props.dataItem.forwarder !== '' &&
                (props.dataItem["groupAccess"] === undefined || props.dataItem["groupAccess"] === null || props.dataItem["groupAccess"] === false)
                    ? <FontAwesomeIcon icon={faUser}
                                       className={cl.user}
                                       title={t("shared.labels.executor-assigned-label")}/>
                    : <>
                        {props.dataItem["groupAccess"] !== undefined && props.dataItem["groupAccess"] !== null && props.dataItem["groupAccess"] === true &&
                            <FontAwesomeIcon icon={faUsers}
                                             className={cl.user}
                                             title={`${t("shared.labels.group-access-label")}: ${props.dataItem["forwarderFull"]}`}/>
                        }
                    </>
                }



                <button className={'grid-prevent-selection offer-grid-open-otm-release-btn cell-button'}
                        onClick={async (ev) => {
                            if (!id) {
                                return;
                            }

                            ev.stopPropagation();

                            try {
                                const response = await getOfferManagementLink(id);

                                if (response && response.data && response.data.resultCode === ResponseResultCode.Ok &&
                                    response.data.data) {
                                    window.open(response.data.data, '_blank');
                                }
                            } catch {
                                //ignore
                            }
                        }}>
                    {props.children}
                </button>

                {props.dataItem.freightId !== undefined && props.dataItem.freightId !== null && props.dataItem.freightId !== '' &&
                    <img src={transEu} alt={'trans-eu'}
                         title={t("shared.labels.trans-eu")}
                         className={`${cl.transEu} grid-prevent-selection`}
                         onClick={async (ev) => {
                             if (!props.dataItem.freightId) {
                                 return;
                             }

                             ev.stopPropagation();

                             try {
                                 const response = await getTransEULink(props.dataItem.freightId);

                                 if (response && response.data && response.data.resultCode === ResponseResultCode.Ok &&
                                     response.data.data) {
                                     window.open(response.data.data, '_blank');
                                 }
                             } catch {
                                 //ignore
                             }
                         }}/>
                }

                {props.dataItem.isSent !== undefined && props.dataItem.isSent &&
                    <>
                        {auctionType !== null && auctionType === 0 &&
                            <img src={freeFreightImg} alt={'free-freight'}
                                 title={t("offer.grid.free-freight")}
                                 className={cl.auctionIcon}/>

                        }

                        {auctionType !== null && auctionType === 1 &&
                            <img src={auctionImg} alt={'auction'}
                                 title={t("offer.grid.order-to-confirm")}
                                 className={cl.auctionIcon}/>
                        }

                        {auctionType !== null && (auctionType === 2 || auctionType === 3)
                            ? <img src={contractImg} alt={'contract'}
                                   title={t("offer.grid.contract")}
                                   className={cl.auctionIcon}/>
                            : null
                        }
                    </>
                }

                {props.dataItem.isContract !== undefined && props.dataItem.isContract && (((auctionType === null || auctionType === 0) && (props.dataItem.isSent === undefined || !props.dataItem.isSent)) || (auctionType !== null && auctionType > 3))
                    ? <img src={contractNewImg} alt={'contract'}
                           title={t("offer.grid.contract")}
                           className={cl.auctionIcon}/>
                    : null
                }

                {props.dataItem["readBy"] !== undefined && props.dataItem["readBy"] !== null && props.dataItem["readBy"] > 0 &&
                    <div className={`${cl.readByMark} grid-prevent-selection`}
                         title={t("quote.grid.readBy-mark")}>
                        <FontAwesomeIcon icon={faEye}
                                         title={t("quote.grid.readBy-mark")}
                                         className={`${cl.readByMarkIcon} grid-prevent-selection`}
                                         onClick={(ev) => {
                                             ev.stopPropagation();

                                             setIsSendMessageDialogShown(prev => !prev);
                                         }}/>
                        <span className={cl.readByMarkCount}>{props.dataItem["readBy"]}</span>
                    </div>
                }
            </span>
            </td>
        </>
    );
};

export default OrderReleaseXidTemplate;
