import React, {Suspense, useCallback, useEffect, useState} from 'react';
import {Outlet, useLocation, useSearchParams} from "react-router-dom";
import cl from './AuthorizedLayout.module.css';
import {useAppDispatch, useAppSelector} from "../../../app/store";
import {selectCurrentUser} from "../../../features/authentication/authenticationSliceSelectors";
import {getCurrentUser} from "../../../features/authentication/authenticationSlice";
import LoadingScreen from "../../ui/loaders/LoadingScreen/LoadingScreen";
import useSignalRHub from "../../../hooks/useSignalRHub/useSignalRHub";
import {LogLevel} from "@microsoft/signalr";
import {IntlProvider, LocalizationProvider} from "@progress/kendo-react-intl";
import {useTranslation} from "react-i18next";
import {getKendoLocaleString, loadKendoLocalization} from "../../../helpers/kendo";
import useSignalRHandler from "../../../hooks/useSignalRHandler/useSignalRHandler";
import {IApplicationHubMessage} from "../../../app/signalR/applicationHub/IApplicationHubMessage";
import {processApplicationHubMessage} from "../../../app/signalR/applicationHub/applicationHubHelper";
import {UserRole} from "../../../app/enums/UserRole";
import Chat from "../../shared/components/Chat/Chat";
import {ChatManagerContext} from "../../shared/components/Chat/contexts";
import useChatManager from "../../shared/components/Chat/hooks/useChatManager";
import FreeStockOffersDialogAlert
    from "../../../pages/authorized/Offer/actions/FreeStockOffersDialogAlert/FreeStockOffersDialogAlert";
import ActionLoader from "../../ui/loaders/ActionLoader/ActionLoader";
import {selectIsSidebarExpanded} from "../../../features/application/applicationSliceSelectors";
import Sidebar from "../../ui/Sidebar/Sidebar";

const AuthorizedLayout: React.FC = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const {i18n} = useTranslation();
    const [kendoLocale, setKendoLocale] = useState<string | null>(null);
    const user = useAppSelector(selectCurrentUser);
    const dispatch = useAppDispatch();
    const isSidebarExpanded = useAppSelector(selectIsSidebarExpanded);
    const [hub] = useSignalRHub(
        `${process.env.REACT_APP_API_DOMAIN}/applicationHub`,
        {
            singleton: true,
            automaticReconnect: [1000, 2000, 3000, 4000, 5000, 1000, 2000, 3000, 4000, 5000],
            logging: LogLevel.Debug
        }
    );

    const onApplicationHubMessage = useCallback((args: any) => {
        processApplicationHubMessage(JSON.parse(args) as IApplicationHubMessage);
    }, []);

    useSignalRHandler(hub, 'Message', onApplicationHubMessage);

    const chatValue = useChatManager();

    useEffect(() => {
        dispatch(getCurrentUser());
    }, [dispatch]);

    useEffect(() => {
        if (kendoLocale !== getKendoLocaleString(i18n)) {
            loadKendoLocalization(i18n);

            setKendoLocale(getKendoLocaleString(i18n));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    useEffect(() => {
        if (!user) {
            return;
        }

        if (!searchParams.has('role')) {
            searchParams.append('role', UserRole[user.role]);
            setSearchParams(searchParams);
        }
    }, [user, location, searchParams, setSearchParams]);


    if (!user || !kendoLocale) {
        return <LoadingScreen/>;
    }

    return (
        <ChatManagerContext.Provider value={chatValue}>
            <LocalizationProvider language={kendoLocale}>
                <IntlProvider locale={i18n.language.toLowerCase()}>
                    {user.role === UserRole.Forwarder || user.role === UserRole.Administrator
                        ? <FreeStockOffersDialogAlert/>
                        : null
                    }

                    {user.role !== UserRole.Support && user.role !== UserRole.Client
                        ? <Chat/>
                        : null
                    }

                    <div className={cl.container}
                         data-expanded={isSidebarExpanded ? 'expanded' : 'condensed'}>
                        <Sidebar/>

                        <main>
                            <Suspense fallback={<ActionLoader/>}>
                                <Outlet/>
                            </Suspense>
                        </main>
                    </div>
                </IntlProvider>
            </LocalizationProvider>
        </ChatManagerContext.Provider>
    );
};

export default AuthorizedLayout;