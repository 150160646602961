import React, {useEffect, useMemo, useRef, useState} from 'react';
import {IOffer} from "../../../../../../app/interfaces/offer/IOffer";
import {Circle, CircleProps, GoogleMap, Marker, MarkerProps, useLoadScript} from "@react-google-maps/api";
import {defaultMapOptions, useLoadScriptOptions} from "../../../../../../components/ui/GoogleMap/utils/map";
import cl from "../../../../Quote/form/components/QuoteMap/QuoteMap.module.css";
import FormLoadingScreen from "../../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";

type OfferMapProps = {
    offer: IOffer;
};

const OfferMap: React.FC<OfferMapProps> = ({
                                               offer
                                           }) => {
    const {i18n} = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [language, setLanguage] = useState<string>(i18n.language);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (language !== i18n.language) {
            window.google.maps = {} as any;

            setLoading(prev => !prev);

            setLanguage(i18n.language);

            timeout = setTimeout(() => {
                setLoading(prev => !prev);
            }, 1000);
        }

        return () => {
            clearTimeout(timeout);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    if (loading) {
        return (
            <div className={cl.container}>
                <FormLoadingScreen/>
            </div>
        );
    }

    if (offer.lt === null || offer.lt === 0 || offer.lg === null || offer.lg === 0) {
        return null;
    }

    return <Map offer={offer} language={i18n.language}/>;
};

export default OfferMap;

const Map: React.FC<OfferMapProps & {
    language: string
}> = ({language, offer}) => {
    const user = useAppSelector(selectCurrentUser);
    const map = useRef<google.maps.Map | null>(null);
    const [markers, setMarkers] = useState<Array<MarkerProps>>([]);
    const [circles, setCircles] = useState<Array<CircleProps>>([]);
    const {isLoaded} = useLoadScript({
        ...useLoadScriptOptions,
        language: language,
    });

    const options = useMemo((): google.maps.MapOptions =>
        (defaultMapOptions), []);

    const onMapLoad = (mapRef: google.maps.Map) => {
        map.current = mapRef;

        let role = user ? getUserRole(user) : null;

        if (role === null || role === UserRole.ServiceProvider) {
            const marker: MarkerProps = {
                position: {
                    lat: offer.lt ?? 0,
                    lng: offer.lg ?? 0
                },
                zIndex: 10,
                icon: `/images/marker_blue_o.png`,
                draggable: false
            };

            let bounds = new google.maps.LatLngBounds();

            bounds.extend({
                lat: offer.lt ?? 0,
                lng: offer.lg ?? 0
            });

            map.current?.fitBounds(bounds);
            map.current?.setZoom(5);

            setMarkers([marker]);
        } else {
            const circle: CircleProps = {
                center: {
                    lat: offer.lt ?? 0,
                    lng: offer.lg ?? 0
                },
                radius: 50000,
                options: {
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#FF0000',
                    fillOpacity: 0.35,
                }
            };

            let bounds = new google.maps.LatLngBounds();

            bounds.extend({
                lat: offer.lt ?? 0,
                lng: offer.lg ?? 0
            });

            map.current?.fitBounds(bounds);
            map.current?.setZoom(2);

            setCircles([circle]);
        }
    }

    if (!isLoaded) {
        return (
            <div className={cl.container}>
                <FormLoadingScreen/>
            </div>
        );
    }

    return (
        <div className={cl.container}>
            <GoogleMap mapContainerClassName="h100"
                       options={options}
                       onLoad={onMapLoad}>
                {markers.map((marker, index) => {
                    return (
                        <Marker key={index} {...marker} />
                    );
                })}
                {circles.map((circle, index) => {
                    return (
                        <Circle key={index} {...circle} />
                    );
                })}
            </GoogleMap>
        </div>
    );
}