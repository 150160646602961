import React, {useCallback, useEffect, useState} from 'react';
import {ExtendedNettoBet} from "../../../../QuoteNettoBetList";
import Collapse from "../../../../../../../../../../components/ui/Collapse/Collapse";
import {useTranslation} from "react-i18next";
import cl from './NettoBetBetList.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faClockRotateLeft} from "@fortawesome/free-solid-svg-icons";
import {IBetAction} from "../../../../../../../../../../app/interfaces/quote/IBetAction";
import {useAppSelector} from "../../../../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../../../../features/authentication/authenticationSliceSelectors";
import moment from "moment";
import {BetStatus} from "../../../../../../../../../../app/enums/BetStatus";
import {RadioButton} from "@progress/kendo-react-inputs";
import UserChat from "../../../../../../../../../../components/shared/components/Chat/components/UserChat/UserChat";
import {UserRole} from "../../../../../../../../../../app/enums/UserRole";
import {IQuote} from "../../../../../../../../../../app/interfaces/quote/IQuote";
import SupplierData
    from "../../../../../../../../../../components/shared/form/detailsFields/SupplierData/SupplierData";

type NettoBetBetListProps = {
    nettoBet: ExtendedNettoBet;
    onSelect: (bet: IBetAction) => void;
    quote: IQuote;
}

type ExtendedBet = {
    isHistoryShow: boolean;
} & IBetAction;

const NettoBetBetList: React.FC<NettoBetBetListProps> = ({
                                                             nettoBet,
                                                             onSelect,
                                                             quote
                                                         }) => {
    const {t} = useTranslation();
    const [bets, setBets] = useState<Array<ExtendedBet>>([]);
    const [minBet, setMinBet] = useState<ExtendedBet | null>(null);
    const user = useAppSelector(selectCurrentUser);
    const [checkedItem, setCheckedItem] = useState<string | null>(null);

    useEffect(() => {
        if (nettoBet.sortedBets.length <= 0) {
            return;
        }

        let item = nettoBet.sortedBets.filter(e => e.status === BetStatus.New)
            .sort((a, b) => {
                return a.valueBase - b.valueBase;
            })[0];

        setMinBet({
            ...item,
            isHistoryShow: false
        });

        setBets([...nettoBet.sortedBets.map(item => {
            return {
                ...item,
                isHistoryShow: false
            }
        }).sort((a, b) => {
            return a.valueBase - b.valueBase;
        })]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const change = useCallback((bet: ExtendedBet) => {
        let index = bets.findIndex(e => e.betId === bet.betId);

        if (index !== undefined && index >= 0) {
            let lBets = bets.map((item) => {
                return {...item};
            });

            lBets.splice(index, 1, bet);

            setBets(lBets);

            if (minBet !== null && bet.betId === minBet.betId) {
                setMinBet(bet);
            }
        }

    }, [bets, minBet]);

    if (nettoBet.sortedBets.length <= 0) {
        return null;
    }

    return (
        <div className={`${cl.container} radio-buttons-container`}>
            <Collapse title={`${t("quote.bet.supplier-rates")} (${nettoBet.sortedBets.length})`}>
                {bets.map((item, index) => {
                    let containerClass: any;
                    let isMinBet: boolean = false;

                    if (minBet !== null && minBet.betId === item.betId) {
                        containerClass = cl.selectedBet;
                        isMinBet = true;
                    } else if (item.status === BetStatus.New) {
                        containerClass = cl.actualBet;
                    } else {
                        containerClass = cl.rejectedBet;
                    }

                    return (
                        <div key={`bet-${item.betId}-${index}}`}
                             className={`bet-${item.betId}-${index} mgb-5 mgt-5 ${cl.bet} ${containerClass}`}>
                            {nettoBet.bets.filter(e => e.serviceProviderId === item.serviceProviderId && e.betId !== item.betId).length > 0 &&
                                <FontAwesomeIcon icon={faClockRotateLeft}
                                                 title={t("quote.bet.show-hide-bet-history-btn")}
                                                 onClick={() => {
                                                     if (!nettoBet.isEditMode) {
                                                         return;
                                                     }

                                                     change({
                                                         ...item,
                                                         isHistoryShow: !item.isHistoryShow
                                                     });
                                                 }}
                                                 className={`${cl.history} ${nettoBet.isEditMode ? '' : cl.historyDisabled}`}/>
                            }

                            {item.status === BetStatus.Cancelled &&
                                <>
                                    <div className={`view-row ${cl.historyBetReasonRow}`}>
                                        <FontAwesomeIcon icon={faCircleXmark}/>
                                        {item.rejectReason !== null && item.rejectReason !== '' &&
                                            <span>
                                                {item.rejectReason}
                                            </span>
                                        }
                                    </div>
                                    {item.rejectNotes !== null && item.rejectNotes !== '' &&
                                        <div className={`view-row ${cl.red}`}>
                                            {item.rejectNotes}
                                        </div>
                                    }
                                </>
                            }

                            {user &&
                                <div className={`mgb-5 mgt-5`}>
                                    {moment.utc(item.createdOn).local().format('DD.MM.YYYY HH:mm')}
                                </div>
                            }

                            <div className={`${isMinBet ? cl.orange : ""} mgb-5 mgt-5 ${cl.betText} ${cl.betValueRow}`}>
                                {nettoBet.isEditMode &&
                                    <RadioButton name={`netto-bet-${nettoBet.uuid}`}
                                                 key={`radio-button-bet-${item.betId}-${index}}`}
                                                 value={`bet-${item.betId}`}
                                                 size={'small'}
                                                 checked={checkedItem === `bet-${item.betId}`}
                                                 onChange={() => {
                                                     setCheckedItem(`bet-${item.betId}`);

                                                     onSelect({
                                                         ...item
                                                     });
                                                 }}
                                    />
                                }

                                {`${item.valueBase.toFixed(2)} EUR ${item.currencyCode !== 'EUR' ? `(${item.bet} ${item.currencyCode})` : ''}`}
                            </div>

                            {item.tillDate !== null && item.tillDate !== '' &&
                                <div className={`view-row mgb-5 mgt-5`}>
                                    {`${t("quote.bet.validity")}: ${item.tillDate}`}
                                </div>
                            }

                            {item.notes !== null && item.notes !== '' &&
                                <div className={`view-row mgb-5 mgt-5 ${cl.note}`}>
                                    {`${item.notes}`}
                                </div>
                            }

                            {item.isPredictedBet &&
                                <div className={`view-row mgb-5 mgt-5 ${cl.orange} ${cl.predicted}`}>
                                    {t("quote.bet.is-predicted-price")}
                                </div>
                            }

                            {!item.isPredictedBet &&
                                <div className={`view-row mgb-5 mgt-5 ${cl.orange} ${cl.serviceProvider}`}>
                                    <SupplierData user={{
                                        id: item.serviceProviderId ?? '',
                                        name: `${item.serviceProvider} ${item.extServiceProviderName !== null && item.extServiceProviderName !== '' ? `(${item.extServiceProviderName})` : ''}`
                                    }}/>

                                    <UserChat user={{
                                        role: UserRole.ServiceProvider,
                                        id: item.userLogin ?? '',
                                        serviceProvider: item.userLogin ?? undefined,
                                        skipServiceProviderList: true
                                    }} replyTo={{
                                        type: 'QUOTE',
                                        id: quote.orderReleaseXid.replace('AAG.', ''),
                                        description: quote.chatDescription,
                                        idExt: null,
                                    }}/>
                                </div>
                            }

                            {item.isHistoryShow &&
                                <div className={`bet-history-container-${item.betId}-${index} mgt-10 mgb-10`}>
                                    {nettoBet.bets.filter(e => e.serviceProviderId === item.serviceProviderId && e.betId !== item.betId)
                                        .sort((a, b) => {
                                            return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
                                        })
                                        .map(item => {
                                            return (
                                                <div key={`history-bet-${item.betId}-${index}`}
                                                     className={cl.historyBet}>
                                                    <div className={`view-row ${cl.historyBetReasonRow}`}>
                                                        <FontAwesomeIcon icon={faCircleXmark}/>
                                                        {item.rejectReason !== null && item.rejectReason !== '' &&
                                                            <span>
                                                                {item.rejectReason}
                                                            </span>
                                                        }
                                                    </div>
                                                    {item.rejectNotes !== null && item.rejectNotes !== '' &&
                                                        <div className={`view-row ${cl.red}`}>
                                                            {item.rejectNotes}
                                                        </div>
                                                    }
                                                    {user &&
                                                        <div className={`${cl.gray} mgb-5 mgt-5`}>
                                                            {moment.utc(item.createdOn).local().format('DD.MM.YYYY HH:mm')}
                                                        </div>
                                                    }
                                                    <div className={`${cl.gray} mgb-5 mgt-5 ${cl.betText}`}>
                                                        {`${item.bet} ${item.currencyCode}`}
                                                    </div>
                                                    {item.tillDate !== null && item.tillDate !== '' &&
                                                        <div className={`view-row ${cl.gray} mgb-5 mgt-5`}>
                                                            {`${t("quote.bet.validity")}: ${item.tillDate}`}
                                                        </div>
                                                    }
                                                    {item.notes !== null && item.notes !== '' &&
                                                        <div className={`view-row ${cl.gray} mgb-5 mgt-5`}>
                                                            {`${item.notes}`}
                                                        </div>
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            }
                        </div>
                    );
                })}
            </Collapse>
        </div>
    );
};

export default NettoBetBetList;
