import React from 'react';
import {IShipment} from "../../../../../../../../../app/interfaces/shipment/IShipment";
import ForwarderField
    from "../../../../../../../../../components/shared/form/detailsFields/ForwarderField/ForwarderField";

type SupplierManagerProps = {
    shipment: IShipment
}

const SupplierManager: React.FC<SupplierManagerProps> = ({
                                                             shipment
                                                         }) => {
    return (
        <div className={'details-box-field-value'}>
            {shipment.executorUser !== null && shipment.executorUser !== undefined && shipment.executorUser.name !== null && shipment.executorUser.name !== ''
                ? <ForwarderField user={shipment.executorUser}
                                  chatReplyTo={{
                                      type: 'SHIPMENT',
                                      id: shipment.orderReleaseGid?.replace('AAG.', '') ?? '',
                                      idExt: shipment.shipmentGid,
                                      description: shipment.chatDescription
                                  }}/>
                : null
            }
        </div>
    );
};

export default SupplierManager;