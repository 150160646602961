import React from 'react';
import {IUser} from "../../../../../../app/interfaces/user/IUser";
import {IShipmentInvoice} from "../../../../../../app/interfaces/invoice/IShipmentInvoice";
import {useTranslation} from "react-i18next";
import ShipmentInvoiceFormOverview from "./ShipmentInvoiceFormOverview/ShipmentInvoiceFormOverview";
import ShipmentInvoiceFormInvoiceList from "./ShipmentInvoiceFormInvoiceList/ShipmentInvoiceFormInvoiceList";
import FormCollapse from "../../../../../../components/ui/Collapse/FormCollapse";

type ShipmentInvoiceFormProps = {
    user?: IUser;
    data: IShipmentInvoice;
    showCollapse: boolean;
    showTotalSum: boolean;
    isSidePanelDetails: boolean;
}

const ShipmentInvoiceForm: React.FC<ShipmentInvoiceFormProps> = ({
                                                                     data,
                                                                     user,
                                                                     showCollapse,
                                                                     showTotalSum,
                                                                     isSidePanelDetails
                                                                 }) => {
    const {t} = useTranslation();

    if (showCollapse) {
        return (
            <FormCollapse title={t("invoice.form.payment-label")}
                          defaultExpanded={true}>
                {showTotalSum && <ShipmentInvoiceFormOverview shipmentInvoice={data} />}

                <ShipmentInvoiceFormInvoiceList shipmentInvoice={data} isSidePanelDetails={isSidePanelDetails}
                                                user={user}/>
            </FormCollapse>
        )
    }

    return (
        <div>
            {showTotalSum && <ShipmentInvoiceFormOverview shipmentInvoice={data} />}

            <ShipmentInvoiceFormInvoiceList shipmentInvoice={data} isSidePanelDetails={isSidePanelDetails}
                                            user={user}/>
        </div>
    );
};

export default ShipmentInvoiceForm;
