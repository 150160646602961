import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../ui/Dialog/Dialog";
import ChangePassword from "./ChangePassword";

type ChangePasswordDialogProps = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = ({
                                                                       show,
                                                                       setShow
                                                                   }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'change-password-dialog'}
                show={show}
                setShow={setShow}
                width={'550px'}
                title={t("account.password.change-password")}>
            <ChangePassword close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default ChangePasswordDialog;