import React from 'react';

type HighLighterProps = {
    highlight?: string;
    text: string;
    color: string;
};

const HighLighter: React.FC<HighLighterProps> = ({
                                                     highlight,
                                                     text,
                                                     color
                                                 }) => {
    if (!highlight || highlight === '') {
        return <>{text}</>;
    }

    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

    return (
        <span>
            {parts.map((part, i) => {
                return (
                    <span key={i}
                          style={part.toLowerCase() === highlight.toLowerCase()
                              ? {
                                  backgroundColor: color,
                                  borderRadius: '2px',
                                  padding: '1px'
                              } : undefined}>
                        {part}
                    </span>
                );
            })}
        </span>
    );
};

export default HighLighter;