import React, {useState} from 'react';
import {IOffer} from "../../../../../../../app/interfaces/offer/IOffer";
import {useTranslation} from "react-i18next";
import ChangeRefNumDialog from "../../../../actions/ChangeRefNum/ChangeRefNumDialog";
import Icon from "../../../../../../../assets/icon/Icon";

type LoadingNumberProps = {
    offer: IOffer;
};

const LoadingNumber: React.FC<LoadingNumberProps> = ({
                                                         offer
                                                     }) => {
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);
    const {t} = useTranslation();

    return (
        <>
            <ChangeRefNumDialog id={offer.orderReleaseGid}
                                show={isDialogShown}
                                setShow={setIsDialogShown}/>

            <label className={'loading-number-label'}>
                {t('offer.grid.loading-number-label')}
            </label>

            <span className={'details-box-item-field-edit-value loading-number-value'}>


                {offer.loadingNum !== null && offer.loadingNum !== ''
                    ? <><Icon icon={"faPen"}
                              className={'details-box-item-field-gray-icon'}
                              style={{marginRight: '5px'}}
                              onClick={() => setIsDialogShown(prev => !prev)}/><span style={{color:'#1b1b1b'}} className={'details-box-field-edit-text'}
                            onClick={() => setIsDialogShown(prev => !prev)}>{offer.loadingNum}</span></>
                    : <Icon icon={"faPen"}
                            className={'details-box-item-field-icon'}
                            style={{marginRight: '5px'}}
                            onClick={() => setIsDialogShown(prev => !prev)}/>
                }
            </span>
        </>
    );
};

export default LoadingNumber;
