import React, {ReactNode, useContext} from 'react';
import cl from './SidebarSection.module.css';
import {SidebarContext} from "../../Sidebar";
import {CustomScroll} from "react-custom-scroll";

type SidebarSectionProps = {
    children: ReactNode;
    title?: string;
    border?: 'top' | 'bottom';
    paddingAll?: '5' | '10';
    paddingBottomTop?: '5' | '10';
    overflow?: boolean;
};

const SidebarSection: React.FC<SidebarSectionProps> = ({
                                                           children,
                                                           border,
                                                           paddingBottomTop,
                                                           paddingAll,
                                                           title,
                                                           overflow
                                                       }) => {
    const {
        expanded,
        hoverExpanded
    } = useContext(SidebarContext);

    if (overflow) {
        return (
            <section className={cl.section}
                     data-line={border ? border : ''}
                     data-pd-bt={paddingBottomTop ? paddingBottomTop : ''}
                     data-pd-all={paddingAll ? paddingAll : ''}>
                <CustomScroll heightRelativeToParent={'100%'}>
                    {title
                        ? <label className={`${cl.label} ${hoverExpanded || expanded ? cl.visible : ''}`}>
                            {title.toUpperCase()}
                        </label>
                        : <span style={{width:'40px'}}></span>
                    }

                    {children}
                </CustomScroll>
            </section>
        );
    }

    return (
        <section className={cl.section}
                 data-line={border ? border : ''}
                 data-pd-bt={paddingBottomTop ? paddingBottomTop : ''}
                 data-pd-all={paddingAll ? paddingAll : ''}>
            {title
                ? <label className={`${cl.label} ${hoverExpanded || expanded ? cl.visible : ''}`}>
                    {title.toUpperCase()}
                </label>
                : <span style={{width:'40px'}}></span>
            }

            {children}
        </section>
    );
};

export default SidebarSection;
