import React from 'react';
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {useTranslation} from "react-i18next";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import {useShipmentApi} from "../../../../../../app/api/shipment";
import Icon from "../../../../../../assets/icon/Icon";
import cl from './CostNettoTemplate.module.css';

const CostNettoTemplate = (props: any) => {
    const user = useAppSelector(selectCurrentUser);

    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const {t} = useTranslation();

    const {
        getShipmentCostLink: {
            query: getShipmentCostLink
        }
    } = useShipmentApi();

    const goToCostEdit = async (id: string) => {

        try {
            const response = await getShipmentCostLink(id);
            if (response?.status === 200 && response.data && response.data) {

                // @ts-ignore
                window.open(response.data, '_blank');
            }

        } catch {
            //ignore
        }
    }

    let sId = props.dataItem.sId !== undefined && props.dataItem.sId !== null
        ? props.dataItem.sId
        : '';
    let isUnConfirmed = props.dataItem["isUnconfirm"] !== null && props.dataItem["isUnconfirm"] !== undefined
        ? Boolean(props.dataItem["isUnconfirm"])
        : false;
    let costNetto = props.dataItem["costNetto"] !== undefined && props.dataItem["costNetto"] !== null
        ? props.dataItem["costNetto"]
        : '';

    if (!sId || sId === '' || !isUnConfirmed || !user || (getUserRole(user) === UserRole.ServiceProvider)) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={'default-left-cell default-overflow-cell'}>
                    {costNetto}
                </span>
            </td>
        )
    }

    return (
        <td {...props.tdProps}
            colSpan={1}>


            <span onClick={handleClick} className={'default-left-cell default-overflow-cell grid-prevent-selection'}>
                <>

                    <Icon icon={"faPen"}
                          className={`${cl.icon} ${costNetto && costNetto !== '' ? cl.iconWithValue : ''} grid-prevent-selection shipment-grid-edit-cost-netto-btn`}
                          onClick={async (ev) => {

                              ev.stopPropagation();

                              await goToCostEdit(sId);
                          }}/>
                    {costNetto && costNetto !== ''
                        ? <span className={`grid-prevent-selection ${cl.text}`}
                                onClick={async (ev) => {
                                    ev.stopPropagation();
                                    await goToCostEdit(sId);
                                }}>
                        {costNetto}
                    </span>
                        : <span className={`grid-prevent-selection ${cl.addText}`}
                                onClick={async (ev) => {
                                    ev.stopPropagation();
                                    await goToCostEdit(sId);
                                }}>
                        {t("shipment.grid.cost-netto-adjustment")}
                    </span>
                    }
                </>
            </span>
        </td>
    );
};

export default CostNettoTemplate;
