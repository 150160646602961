import React, {useContext, useEffect, useState} from 'react';
import {ExtendedNettoBet, QuoteNettoBetListContext} from "../QuoteNettoBetList";
import {useCollapse} from "react-collapsed";
import {useTranslation} from "react-i18next";
import cl from './QuoteNettoBetListItem.module.css';
import QuoteNettoBetListItemOverview from "./QuoteNettoBetListItemOverview/QuoteNettoBetListItemOverview";
import QuoteNettoBetListItemContent from "./QuoteNettoBetListItemContent/QuoteNettoBetListItemContent";
import {IQuote} from "../../../../../../../app/interfaces/quote/IQuote";
import Icon from "../../../../../../../assets/icon/Icon";
import useMediaQuery from "../../../../../../../hooks/useMediaQuery/useMediaQuery";

type QuoteNettoBetListItemProps = {
    nettoBet: ExtendedNettoBet;
    quote: IQuote;
    disabled: boolean;
    isSidePanelDetails?: boolean;
}

const QuoteNettoBetListItem: React.FC<QuoteNettoBetListItemProps> = ({
                                                                         nettoBet,
                                                                         disabled,
                                                                         quote,
                                                                         isSidePanelDetails
                                                                     }) => {
    const {t} = useTranslation();
    const [isTransitionEnd, setIsTransitionEnd] = useState<boolean>(!nettoBet.isCollapseDefaultExpanded);
    const [resetNumber, setResetNumber] = useState<number>(0);
    const [showOverview, setShowOverview] = useState<boolean>(!nettoBet.isCollapseDefaultExpanded);
    const isMaxWidth600 = useMediaQuery('(max-width: 600px)');
    const {getCollapseProps, getToggleProps, isExpanded, setExpanded} = useCollapse({
        defaultExpanded: nettoBet.isCollapseDefaultExpanded,
        onTransitionStateChange: (ev) => {
            if (ev === 'expandEnd' || ev === 'collapseEnd') {
                setIsTransitionEnd(true);
            }

            if (ev === 'expandStart' || ev === 'collapseStart') {
                setIsTransitionEnd(false);
            }

            if (ev === 'collapsing' && isTransitionEnd) {
                setIsTransitionEnd(false);
            }
        }
    });

    useEffect(() => {
        if (isTransitionEnd && !isExpanded) {
            setShowOverview(true);
        } else {
            setShowOverview(false);
        }
    }, [isExpanded, isTransitionEnd]);

    const {
        onChange
    } = useContext(QuoteNettoBetListContext);

    return (
        <div>
            <div className={!isSidePanelDetails && !isMaxWidth600 ? cl.headerFull : cl.header} {...getToggleProps()}>
                <div className={`${cl.title} prevent-selection`}>
                    {t("quote.form.bet-details")}

                    {!nettoBet.isEditMode && !nettoBet.isNotRegisteredInTranspareonAccount &&
                        <button className={cl.button}
                                onClick={(ev) => {
                                    ev.stopPropagation();

                                    onChange({
                                        ...nettoBet,
                                        isEditMode: true
                                    });

                                    if (!isExpanded) {
                                        setExpanded(true);
                                    }
                                }}>
                            <Icon icon={"faPen"}/>
                            {t("shared.btn.edit")}
                        </button>
                    }
                </div>
                <div className={cl.icon}>
                    <Icon icon={"faThinChevronUp"}
                          flip={isExpanded ? 'horizontal' : 'vertical'}/>
                </div>
            </div>

            {showOverview &&
                <QuoteNettoBetListItemOverview nettoBet={nettoBet}/>
            }

            <div {...getCollapseProps()}>
                <div className={cl.content}>
                    <QuoteNettoBetListItemContent nettoBet={nettoBet}
                                                  quote={quote}
                                                  disabled={disabled}
                                                  resetNumber={resetNumber}
                                                  setResetNumber={setResetNumber}/>
                </div>
            </div>
        </div>
    );
};

export default QuoteNettoBetListItem;
