import React, {useState} from 'react';
import {IChatReplyTo} from "../../../../../../app/interfaces/chat/IChat";
import SendMessageToMultipleServiceProvidersForm, {
    ISendMessageToMultipleServiceProvidersForm
} from "./SendMessageToMultipleServiceProvidersForm/SendMessageToMultipleServiceProvidersForm";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import ToastLayout from "../../../../../uiLayouts/ToastLayout/ToastLayout";
import {useTranslation} from "react-i18next";
import {IChatServiceProvider} from "../../../../../../app/interfaces/chat/IChatServiceProvider";
import {IChatSocketCommand} from "../../../../../../app/types/ChatSocketCommand";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {UserRole} from "../../../../../../app/enums/UserRole";

type SendMessageToMultipleServiceProvidersProps = {
    replyTo: IChatReplyTo;
    close: () => void;
};

const SendMessageToMultipleServiceProviders: React.FC<SendMessageToMultipleServiceProvidersProps> = ({
                                                                                                         replyTo,
                                                                                                         close
                                                                                                     }) => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    const [disabled] = useState<boolean>(false);

    const toArray = (nodes: Array<IChatServiceProvider>, arr: Array<IChatServiceProvider>) => {
        if (!nodes) {
            return [];
        }

        if (!arr) {
            arr = [];
        }

        for (let i = 0; i < nodes.length; i++) {
            arr.push(nodes[i]);

            toArray(nodes[i].items, arr);
        }

        return arr;
    }
    const submit = async (values: ISendMessageToMultipleServiceProvidersForm, toAll: boolean) => {
        if (!user) {
            return;
        }

        try {
            let isSent: boolean = false;
            let items: Array<IChatServiceProvider> = toAll
                ? toArray([...values.serviceProviders, ...values.addedServiceProviders], []).filter(e => e.isUser)
                : values.selectedServiceProviders;

            items.forEach(sp => {
                const message: IChatSocketCommand = {
                    cmd: 'msg',
                    message: values.message,
                    toUserId: sp.id,
                    toUser: sp.name,
                    userId: user.uniqueUserValue ?? user.idStr ?? undefined,
                    user: user.name,
                    firm: user.role === UserRole.ServiceProvider
                        ? user.serviceProvider?.name ?? undefined
                        : user.departmentFull ?? undefined,
                    toFirm: sp.firm ?? undefined,
                    linkedType: replyTo.type,
                    linkedId: replyTo.id,
                    linkedDesc: replyTo.description ?? undefined,
                    linkedIdExt: replyTo.idExt ?? undefined
                };

                document.dispatchEvent(new CustomEvent('onChatMessageSent', {
                    detail: {
                        command: message
                    }
                }));

                isSent = true;
            });

            if (isSent) {
                close();

                toast.success(<ToastLayout title={t("chat.labels.chat")}
                                           text={t("chat.labels.sent-to-carries")}/>);
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(`Unable to send the message. Error: ${e.message}`);
            }
        }
    }

    return (
        <SendMessageToMultipleServiceProvidersForm chatReplyTo={replyTo}
                                                   disabled={disabled}
                                                   submit={submit}/>
    );
};

export default SendMessageToMultipleServiceProviders;