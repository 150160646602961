import React from 'react';
import DetailsGridLayout from "../../../../../components/uiLayouts/DetailsGridLayout/DetailsGridLayout";
import NotificationGridContent from "./NotificationGridContent/NotificationGridContent";

const NotificationContent: React.FC = () => {
    return (
        <></>
        /*<DetailsGridLayout id={'forwarder-notification-grid'}
                           details={undefined as any}
                           grid={<NotificationGridContent/>}
                           isFullDetailsOpened={false}
                           setIsFullDetailsOpened={() => false}
                           isSideDetailsOpened={false}
                           setIsSideDetailsOpened={() => false}/>*/
    );
};

export default NotificationContent;
