import React, {useEffect, useState} from 'react';
import Container from "../../../../../../../../components/ui/Container/Container";
import Card from "../../../../../../../../components/ui/Card/Card";
import cl from './SendContractTransEuLogin.module.css';
import Alert from "../../../../../../../../components/ui/Alert/Alert";
import {useTranslation} from "react-i18next";
import LoginInput from "../../../../../../../../components/ui/LoginInput/LoginInput";
import {faLock, faUser} from "@fortawesome/free-solid-svg-icons";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {useOfferApi} from "../../../../../../../../app/api/offer";
import {TransEuLoginStatus} from "../../../../../../../../app/enums/TransEuLoginStatus";
import {IUser} from "../../../../../../../../app/interfaces/user/IUser";

type SendContractTransEuLoginProps = {
    onLoginChange: (val: string | null) => void;
    onPasswordChange: (val: string | null) => void;
    login: string | null;
    password: string | null;
    disabled: boolean;
    forwarder: string;
    forwarderName: string;
    user?: IUser;
};

const alertStyle: React.CSSProperties = {
    fontWeight: 'bold',
    padding: '10px',
    textAlign: 'center',
    justifyContent: 'center',
    marginTop: '0px',
    fontSize: '12px'
};

const SendContractTransEuLogin: React.FC<SendContractTransEuLoginProps> = ({
                                                                               forwarder,
                                                                               forwarderName,
                                                                               disabled,
                                                                               onLoginChange,
                                                                               onPasswordChange,
                                                                               login,
                                                                               password,
                                                                               user
                                                                           }) => {
    const {t} = useTranslation();
    const [status, setStatus] = useState<TransEuLoginStatus | null>(null);

    const {
        getTransEULoginData: {
            query: getTransEULoginData,
            isLoading: getTransEULoginDataIsLoading
        },
        getTransEULoginDataAnonymous: {
            query: getTransEULoginDataAnonymous,
            isLoading: getTransEULoginDataAnonymousIsLoading
        }
    } = useOfferApi();

    useEffect(() => {
        setStatus(null);

        if (forwarder === '') {
            return;
        }

        (async () => {
            try {
                const response = user
                    ? await getTransEULoginData(forwarder)
                    : await getTransEULoginDataAnonymous(forwarder);

                if (response?.status === 200 && response?.data?.data) {
                    if (response.data.data.transEuLogin && response.data.data.transEuPass &&
                        response.data.data.transEuAccess === TransEuLoginStatus.Success) {
                        onPasswordChange(response.data.data.transEuPass);
                        onLoginChange(response.data.data.transEuLogin);
                    } else {
                        setStatus(response.data.data.transEuAccess);
                    }
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error(`Unable to get trans eu login data: ${e.message}`);
                }
            }
        })();
    }, [forwarder]);

    return (
        <div className={`w100 h100 hidden ${cl.container}`}>
            <div>
                {status !== null && status === TransEuLoginStatus.NoAccount &&
                    <Alert type={'Error'}
                           style={alertStyle}>
                        No account found.
                    </Alert>
                }
                <Alert type={'BrightGrayInfo'}
                       style={alertStyle}>
                    {t("offer.warnings.trans-eu-login-warning").replace('{0}', forwarderName)}
                </Alert>
            </div>
            <Container centerContent={true}>
                <Card style={{width: '450px'}}>
                    <div className={cl.logoContainer}>
                        <div>
                            <img className={'prevent-selection'}
                                 src={'/images/trans-eu-logo.svg'}
                                 alt={'logo'}/>
                        </div>
                    </div>

                    <div style={{padding: '0 20px'}}>
                        <div>
                            <LoginInput id="login"
                                        icon={faUser}
                                        name="login"
                                        autoComplete="off"
                                        type='text'
                                        disabled={disabled || getTransEULoginDataIsLoading || getTransEULoginDataAnonymousIsLoading}
                                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                            if (ev && ev.target && ev.target.value) {
                                                onLoginChange(ev.target.value.toUpperCase());
                                            } else {
                                                onLoginChange('');
                                            }
                                        }}
                                        value={login ?? ''}
                                        placeholder={t("login.labels.login") ?? ''}/>
                        </div>
                        <div>
                            <LoginInput icon={faLock}
                                        type='password'
                                        placeholder={t("login.labels.password") ?? ''}
                                        id="password"
                                        name="password"
                                        autoComplete="off"
                                        disabled={disabled || getTransEULoginDataIsLoading || getTransEULoginDataAnonymousIsLoading}
                                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onPasswordChange(ev.target.value)}
                                        value={password ?? ''}/>
                        </div>
                    </div>
                </Card>
            </Container>
        </div>
    );
};

export default SendContractTransEuLogin;