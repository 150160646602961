import convertToGridConfig, {InitialGridConfig} from "../../../../../components/ui/Grid/Telerik/types/GridConfig";
import GridDefaultHeaderTemplate
    from "../../../../../components/ui/Grid/Telerik/components/GridDefaultHeaderTemplate/GridDefaultHeaderTemplate";
import GridDefaultCellTemplate
    from "../../../../../components/ui/Grid/Telerik/components/GridDefaultCellTemplate/GridDefaultCellTemplate";
import GroupBaseTemplate from "../../../Shipment/grid/templates/GroupBaseTemplate/GroupBaseTemplate";
import {UserRole} from "../../../../../app/enums/UserRole";
import {GRID_SELECTED_FIELD} from "../../../../../components/ui/Grid/Telerik/utils/data";
import IsNewTemplate from "../templates/IsNewTemplate/IsNewTemplate";
import DocumentLinkTemplate from "../templates/DocumentLinkTemplate/DocumentLinkTemplate";
import OrderReleaseXidTemplate from "../templates/OrderReleaseXidTemplate/OrderReleaseXidTemplate";
import ProcessingDateTemplate from "../templates/ProcessingDateTemplate/ProcessingDateTemplate";
import QuoteSourceTemplate from "../templates/QuoteSourceTemplate/QuoteSourceTemplate";
import TransportTemplate from "../templates/TransportTemplate/TransportTemplate";
import PickupDateTemplate from "../templates/PickupDateTemplate/PickupDateTemplate";
import CountryTemplate from "../templates/CountryTemplate/CountryTemplate";
import WarnTemplate from "../templates/WarnTemplate/WarnTemplate";
import RefrigeratorTemplate from "../templates/RefrigeratorTemplate/RefrigeratorTemplate";
import ManagerTemplate from "../templates/ManagerTemplate/ManagerTemplate";
import StatusChangeDateTemplate from "../templates/StatusChangeDateTemplate/StatusChangeDateTemplate";
import TagsGridCellTemplate
    from "../../../../../components/shared/components/Tag/components/TagsGridCellTemplate/TagsGridCellTemplate";
import DateTemplate from "../templates/DateTemplate/DateTemplate";

const initialGridConfig: InitialGridConfig = {
    sortBy: [],
    groupBy: [],
    pageSize: 25,
    headerTemplate: GridDefaultHeaderTemplate,
    cellTemplate: GridDefaultCellTemplate,
    groupTemplate: GroupBaseTemplate,
    columns: [
        {
            visible: true,
            field: 'isNew',
            title: ' ',
            width: 10,
            locked: true,
            constantlyLocked: true,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            template: IsNewTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 0},
                {role: UserRole.Forwarder, order: 0}
            ]
        },
        {
            visible: true,
            field: GRID_SELECTED_FIELD,
            title: ' ',
            width: 25,
            locked: true,
            constantlyLocked: true,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 1},
                {role: UserRole.Forwarder, order: 1}
            ]
        },
        {
            visible: true,
            field: 'orderReleaseXid',
            title: 'quote.grid.order-release-xid',
            width: 160,
            locked: false,
            allowSorting: true,
            template: OrderReleaseXidTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 2},
                {role: UserRole.Forwarder, order: 2}
            ]
        },
        {
            visible: true,
            field: 'tags',
            title: 'quote.grid.tags',
            width: 100,
            locked: false,
            allowSorting: true,
            template: TagsGridCellTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 3},
                {role: UserRole.Forwarder, order: 3}
            ]
        },
        {
            visible: true,
            field: 'betCount',
            title: 'quote.grid.bet-count',
            width: 140,
            locked: false,
            allowSorting: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 4},
                {role: UserRole.Forwarder, order: 4}
            ]
        },
        {
            visible: true,
            field: 'transporeonNumber',
            title: 'quote.grid.transporeon-number',
            width: 100,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 5},
                {role: UserRole.Forwarder, order: 5}
            ]
        },
        {
            visible: true,
            field: 'otmStatus',
            title: 'quote.grid.otm-status',
            width: 100,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 6},
                {role: UserRole.Forwarder, order: 6}
            ]
        },
        {
            visible: true,
            field: 'pickupDate',
            title: 'quote.grid.pickup-date',
            width: 140,
            locked: false,
            allowSorting: true,
            template: PickupDateTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 7},
                {role: UserRole.Forwarder, order: 7}
            ]
        },
        {
            visible: true,
            field: 'deliveryDate',
            title: 'quote.grid.delivery-date',
            width: 140,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 8},
                {role: UserRole.Forwarder, order: 8}
            ]
        },
        {
            visible: true,
            field: 'stopsCount',
            title: 'quote.grid.stops-count',
            width: 100,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 9},
                {role: UserRole.Forwarder, order: 9}
            ]
        },

        {
            visible: true,
            field: 'sourceDistrict',
            title: 'quote.grid.source-district',
            width: 140,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 12},
                {role: UserRole.Forwarder, order: 12}
            ]
        },
        {
            visible: true,
            field: 'destinationDistrict',
            title: 'quote.grid.destination-district',
            width: 140,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 13},
                {role: UserRole.Forwarder, order: 13}
            ]
        },
        {
            visible: true,
            field: 'sourceCountryCode',
            title: 'quote.grid.source-country-code',
            width: 100,
            locked: false,
            allowSorting: true,
            template: CountryTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 10},
                {role: UserRole.Forwarder, order: 10}
            ]
        },
        {
            visible: true,
            field: 'destinationCountryCode',
            title: 'quote.grid.destination-country-code',
            width: 100,
            locked: false,
            allowSorting: true,
            template: CountryTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 11},
                {role: UserRole.Forwarder, order: 11}
            ]
        },
        {
            visible: true,
            field: 'sourceCity',
            title: 'quote.grid.source-city',
            width: 150,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 14},
                {role: UserRole.Forwarder, order: 14}
            ]
        },
        {
            visible: true,
            field: 'destinationCity',
            title: 'quote.grid.destination-city',
            width: 150,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 15},
                {role: UserRole.Forwarder, order: 15}
            ]
        },
        {
            visible: true,
            field: 'customer',
            title: 'quote.grid.customer',
            width: 150,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 16},
                {role: UserRole.Forwarder, order: 16}
            ]
        },
        {
            visible: true,
            field: 'totalWeight',
            title: 'quote.grid.total-weight',
            width: 60,
            locked: false,
            allowSorting: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 17},
                {role: UserRole.Forwarder, order: 17}
            ]
        },
        {
            visible: true,
            field: 'transportMode',
            title: 'quote.grid.transport-mode',
            width: 100,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 18},
                {role: UserRole.Forwarder, order: 18}
            ]
        },
        {
            visible: true,
            field: 'costNetto',
            title: 'quote.grid.cost-netto',
            width: 120,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 19},
                {role: UserRole.Forwarder, order: 19}
            ]
        },
        {
            visible: true,
            field: 'costBrutto',
            title: 'quote.grid.cost-brutto',
            width: 120,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 20},
                {role: UserRole.Forwarder, order: 20}
            ]
        },
        {
            visible: true,
            field: 'forwarder',
            title: 'quote.grid.forwarder',
            width: 100,
            locked: false,
            allowSorting: true,
            template: ManagerTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 21},
                {role: UserRole.Forwarder, order: 21}
            ]
        },
        {
            visible: true,
            field: 'equipment',
            title: 'quote.grid.equipment',
            width: 100,
            locked: false,
            allowSorting: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 22},
                {role: UserRole.Forwarder, order: 22}
            ]
        },
        {
            visible: true,
            field: 'curator',
            title: 'quote.grid.curator',
            width: 100,
            locked: false,
            allowSorting: true,
            template: ManagerTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 23},
                {role: UserRole.Forwarder, order: 23}
            ]
        },





        {
            visible: false,
            field: 'quoteSource',
            title: 'quote.grid.quote-source',
            width: 130,
            locked: false,
            allowSorting: true,
            template: QuoteSourceTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 24},
                {role: UserRole.Forwarder, order: 24}
            ]
        },
        {
            visible: false,
            field: 'adr',
            title: 'quote.grid.adr',
            width: 50,
            locked: false,
            allowSorting: true,
            template: WarnTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 25},
                {role: UserRole.Forwarder, order: 25}
            ]
        },
        {
            visible: false,
            field: 'customerManager',
            title: 'quote.grid.customer-manager',
            width: 100,
            locked: false,
            allowSorting: true,
            template: ManagerTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 26},
                {role: UserRole.Forwarder, order: 26}
            ]
        },
        {
            visible: false,
            field: 'insertDate',
            title: 'quote.grid.insert-date',
            width: 100,
            locked: false,
            allowSorting: true,
            template: DateTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 27},
                {role: UserRole.Forwarder, order: 27}
            ]
        },
        {
            visible: false,
            field: 'documentHref',
            title: 'quote.grid.document-href',
            width: 100,
            locked: false,
            allowSorting: false,
            template: DocumentLinkTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 28},
                {role: UserRole.Forwarder, order: 28}
            ]
        },
        {
            visible: false,
            field: 'hightValue',
            title: 'quote.grid.hight-value',
            width: 100,
            locked: false,
            allowSorting: true,
            template: WarnTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 29},
                {role: UserRole.Forwarder, order: 29}
            ]
        },
        {
            visible: false,
            field: 'transport',
            title: 'quote.grid.transport',
            width: 160,
            locked: false,
            allowSorting: true,
            template: TransportTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 30},
                {role: UserRole.Forwarder, order: 30}
            ]
        },
        {
            visible: false,
            field: 'statusChangeDate',
            title: 'quote.grid.status-change-date',
            width: 55,
            locked: false,
            allowSorting: true,
            template: StatusChangeDateTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 31},
                {role: UserRole.Forwarder, order: 31}
            ]
        },
        {
            visible: false,
            field: 'liquidity',
            title: 'quote.grid.liquidity',
            width: 100,
            locked: false,
            allowSorting: true,
            template: WarnTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 32},
                {role: UserRole.Forwarder, order: 32}
            ]
        },
        {
            visible: false,
            field: 'minBet',
            title: 'quote.grid.min-bet',
            width: 100,
            locked: false,
            allowSorting: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 33},
                {role: UserRole.Forwarder, order: 33}
            ]
        },
        {
            visible: false,
            field: 'sp',
            title: 'quote.grid.sp',
            width: 100,
            locked: false,
            allowSorting: true,
            template: ManagerTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 34},
                {role: UserRole.Forwarder, order: 34}
            ]
        },
        {
            visible: false,
            field: 'product',
            title: 'quote.grid.product',
            width: 100,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 35},
                {role: UserRole.Forwarder, order: 35}
            ]
        },
        {
            visible: false,
            field: 'refrigerator',
            title: 'quote.grid.refrigerator',
            width: 100,
            locked: false,
            allowSorting: true,
            template: RefrigeratorTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 36},
                {role: UserRole.Forwarder, order: 36}
            ]
        },
        {
            visible: false,
            field: 'processingDate',
            title: 'quote.grid.processing-date',
            width: 160,
            locked: false,
            allowSorting: true,
            template: ProcessingDateTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 37},
                {role: UserRole.Forwarder, order: 37}
            ]
        },
        {
            visible: false,
            field: 'asstraDepartment',
            title: 'quote.grid.asstra-department',
            width: 100,
            locked: false,
            allowSorting: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 38},
                {role: UserRole.Forwarder, order: 38}
            ]
        },
        {
            visible: false,
            field: 'quoteType',
            title: 'quote.grid.quote-type',
            width: 130,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 39},
                {role: UserRole.Forwarder, order: 39}
            ]
        }
    ]
};

const getQuoteInitialGridConfig = (role: UserRole) => {
    return convertToGridConfig(role, {
        groupBy: [...initialGridConfig.groupBy],
        sortBy: [...initialGridConfig.sortBy.map(item => ({...item}))],
        columns: [...initialGridConfig.columns.map(item => ({...item}))],
        pageSize: initialGridConfig.pageSize,
        headerTemplate: initialGridConfig.headerTemplate,
        cellTemplate: initialGridConfig.cellTemplate,
        groupTemplate: initialGridConfig.groupTemplate
    });
};

export default getQuoteInitialGridConfig;
