import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import ShipmentsEventList from "./ShipmentsEventList";
import {IUser} from "../../../../../app/interfaces/user/IUser";

type ShipmentsEventListDialogProps = {
    user?: IUser;
    id: string | Array<string>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShipmentsEventListDialog: React.FC<ShipmentsEventListDialogProps> = ({
                                                                               id,
                                                                               show,
                                                                               setShow,
                                                                               user
                                                                           }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'add-shipment-event-dialog'}
                show={show}
                setShow={setShow}
                width={'98%'}
                title={t("shipment.grid.add-event")}>
            <ShipmentsEventList id={id}
                                user={user}
                                close={() => setShow(false)}/>
        </Dialog>
    );
};

export default ShipmentsEventListDialog;