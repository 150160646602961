import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'thinChevronUp',
    pathData: [],
    viewBox: '0 0 9 5',
    lines: [
        {x1: 0.353553, y1: 0.353478, x2: 4.59619, y2: 4.59612},
        {x1: 8.13187, y1: 0.353553, x2: 3.88923, y2: 4.59619}
    ]
};

export default icon;