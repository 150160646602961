import React, {useEffect, useState} from 'react';
import {useShipmentApi} from "../../../../../app/api/shipment";
import {BLErrorCode} from "../../../../../app/enums/BLErrorCode";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import Alert from "../../../../../components/ui/Alert/Alert";
import {isAxiosError} from "axios";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {useTranslation} from "react-i18next";
import {ApplicationHubMessageAction} from "../../../../../app/signalR/applicationHub/IApplicationHubMessage";

type ShowTimeSlotProps = {
    id: string;
    close?: () => void;
}

const alertStyle = {
    minHeight: '40px',
    fontSize: '12px',
    fontWeight: 'bold'
} as React.CSSProperties;

const ShowTimeSlot: React.FC<ShowTimeSlotProps> = ({
                                           id,
                                           close
                                       }) => {
    const {t} = useTranslation();
    const [errors, setErrors] = useState<Array<BLErrorCode>>([]);

    const {
        getTimeSlotData: {
            isLoading,
            cancel,
            query: getTimeSlotData
        }
    } = useShipmentApi();

    useEffect(() => {
        if (!id || id === '') {
            return;
        }


        (async () => {
            try {
                const response = await getTimeSlotData(id);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    if (close) {
                        close();
                    }

                    window.open(response.data.data, '_blank');
                }
            } catch (err) {
                if (isAxiosError(err)) {
                    if (err.response && err.response.data && err.response.status === 422 &&
                        err.response.data.resultCode === ResponseResultCode.NotValidData &&
                        err.response.data.errors && Array.isArray(err.response.data.errors)) {
                        err.response.data.errors.forEach((e: IError) => {
                            setErrors(prev => {
                                if (!prev.find(pr => pr === e.errorCode)) {
                                    return [
                                        ...prev,
                                        e.errorCode
                                    ];
                                }

                                return prev;
                            });
                        });
                    } else if (close) {
                        close();
                    }
                }
            }
        })();

        return () => {
            cancel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (isLoading || errors.length <= 0) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>

        </div>
    );
};

export default ShowTimeSlot;
