import React from 'react';
import cl from './ChatListCardSkeleton.module.css';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import ChatAvatar from "../../../ChatAvatar/ChatAvatar";

const ChatListCardSkeleton: React.FC = () => {
    return (
        <SkeletonTheme baseColor={'#eaeff3'} highlightColor={'#ffffff'}>
            <div className={`${cl.card}`}>
                <div>
                    <ChatAvatar isOnline={false}
                                onVacation={false}
                                size={'Medium'}/>
                </div>
                <div>
                    <div style={{width: '95%'}}>
                        <Skeleton height={'15px'}
                                  width={'100%'}
                                  style={{borderRadius: '2px'}}/>
                    </div>
                    <div style={{width: '60%'}}>
                        <Skeleton height={'15px'}
                                  width={'100%'}
                                  style={{borderRadius: '2px'}}/>
                    </div>
                </div>
                <div>
                    <Skeleton height={'14px'}
                              width={'100%'}
                              style={{borderRadius: '2px', marginTop: '5px'}}/>
                </div>
            </div>
        </SkeletonTheme>
    );
};

export default ChatListCardSkeleton;