import {AxiosResponse} from "axios";
import {IDataResponse, IResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {ISupportGetAction} from "../../interfaces/profile/ISupportGetAction";
import {ISupportPostAction} from "../../interfaces/profile/ISupportPostAction";
import {IProfileGetAction} from "../../interfaces/profile/IProfileGetAction";
import {IProfilePostAction} from "../../interfaces/profile/IProfilePostAction";

export const useGetContactSupportData = (): {
    isLoading: boolean,
    cancel: () => void,
    getContactSupportData: () => Promise<AxiosResponse<IDataResponse<ISupportGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ISupportGetAction>>({
        method: 'get',
    });

    const getContactSupportData = useCallback(async () => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/profile/getSupportData`
    }), [execute]);

    return {getContactSupportData, isLoading, cancel};
};

export const useContactSupport = (): {
    isLoading: boolean,
    cancel: () => void,
    contactSupport: (params: ISupportPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ISupportPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/profile/supportAction`
    });

    const contactSupport = useCallback(async (params: ISupportPostAction) => await execute(params), [execute]);

    return {contactSupport, isLoading, cancel};
};

export const useGetProfileData = (): {
    isLoading: boolean,
    cancel: () => void,
    getProfileData: () => Promise<AxiosResponse<IDataResponse<IProfileGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IProfileGetAction>>({
        method: 'get',
    });

    const getProfileData = useCallback(async () => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/profile/getProfileData`
    }), [execute]);

    return {getProfileData, isLoading, cancel};
};

export const useSaveProfile = (): {
    isLoading: boolean,
    cancel: () => void,
    saveProfile: (params: IProfilePostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IProfilePostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/profile/profileAction`
    });

    const saveProfile = useCallback(async (params: IProfilePostAction) => await execute(params), [execute]);

    return {saveProfile, isLoading, cancel};
};

export const useResendConfirmationEmail = (): {
    isLoading: boolean,
    cancel: () => void,
    resendConfirmationEmail: () => Promise<AxiosResponse<IResponse> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse>({
        method: 'get',
    });

    const resendConfirmationEmail = useCallback(async () => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/profile/resendConfirmationEmail`
    }), [execute]);

    return {resendConfirmationEmail, isLoading, cancel};
};

export const useGetIsEmailNeedConfirmation = (): {
    isLoading: boolean,
    cancel: () => void,
    getIsEmailNeedConfirmation: () => Promise<AxiosResponse<IDataResponse<boolean>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<boolean>>({
        method: 'get',
    });

    const getIsEmailNeedConfirmation = useCallback(async () => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/profile/checkIfEmailNeedConfirmation`
    }), [execute]);

    return {getIsEmailNeedConfirmation, isLoading, cancel};
};
