import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'thinChevronUp',
    pathData: [],
    viewBox: '0 0 9 5',
    lines: [
        {x1: 8.42477, y1: 4.59617, x2: 4.18213, y2: 0.353527},
        {x1: 0.646447, y1: 4.59609, x2: 4.88909, y2: 0.353453}
    ]
};

export default icon;