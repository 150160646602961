import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../ui/Dialog/Dialog";
import EditTags from "./EditTags";

type EditTagsDialogProps = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditTagsDialog: React.FC<EditTagsDialogProps> = ({
                                                           show,
                                                           setShow
                                                       }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'edit-tags-dialog'}
                show={show}
                setShow={setShow}
                width={'750px'}
                title={t("tags.labels.tags-edit")}>
            <EditTags close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default EditTagsDialog;