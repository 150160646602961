import {ColumnModel, InitialColumnModel} from "./ColumnModel";
import {GridCustomCellProps, GridCustomHeaderCellProps} from "@progress/kendo-react-grid";
import {UserRole} from "../../../../../app/enums/UserRole";

export type GridConfig = {
    columns: Array<ColumnModel>;
    sortBy: Array<{
        field: string,
        dir: 'asc' | 'desc'
    }>
    groupBy: Array<string>;
    pageSize: number;

    headerTemplate?: (props: GridCustomHeaderCellProps) => JSX.Element;
    cellTemplate?: (props: GridCustomCellProps) => JSX.Element;
    groupTemplate?: (props: GridCustomCellProps) => JSX.Element;
}

export type InitialGridConfig = {
    columns: Array<InitialColumnModel>;
    sortBy: Array<{
        field: string,
        dir: 'asc' | 'desc'
    }>
    groupBy: Array<string>;
    pageSize: number;

    headerTemplate?: (props: GridCustomHeaderCellProps) => JSX.Element;
    cellTemplate?: (props: GridCustomCellProps) => JSX.Element;
    groupTemplate?: (props: GridCustomCellProps) => JSX.Element;
}

const convertToGridConfig = (role: UserRole, initialConfig: InitialGridConfig): GridConfig => {
    let columns: Array<ColumnModel> = [];

    let columnsToProcess = initialConfig.columns
        .map(item => {
            if (!item.orderByRoles) {
                return {...item};
            }

            let orderByRole = item.orderByRoles.find(orderByRole => orderByRole.role === role);

            item.order = orderByRole?.order;

            return {...item};
        })
        .filter(item => {
            return item.order !== undefined;
        })
        .sort((a, b) => {
            if (a.order !== undefined && b.order !== undefined) {
                return a.order - b.order;
            }

            return 0;
        });

    columnsToProcess.forEach(item => {
        if (item.roles !== undefined) {
            if (item.roles.includes(role)) {
                columns.push(item);
            }
        } else {
            columns.push(item);
        }

        delete item.roles;
        delete item.orderByRoles;
    });

    return {
        columns: columns,
        sortBy: initialConfig.sortBy,
        groupBy: initialConfig.groupBy,
        pageSize: initialConfig.pageSize,
        headerTemplate: initialConfig.headerTemplate,
        cellTemplate: initialConfig.cellTemplate,
        groupTemplate: initialConfig.groupTemplate
    };
}

export default convertToGridConfig;