import React, {ReactNode, useMemo} from 'react';
import {GridConfig} from "../../ui/Grid/Telerik/types/GridConfig";
import useGridLayout from "./hooks/useGridLayout";
import {LayoutConfigEntity} from "../../../app/enums/LayoutConfigEntity";
import {IGridLayoutContext} from "./models/interfaces/IGridLayoutContext";
import {GridLayoutContext} from "./context";
import GridLayoutStructure from "./components/GridLayoutStructure/GridLayoutStructure";

export type GridLayoutProps<T> = {
    grid: ReactNode;
    header: ReactNode;
    map?: ReactNode;
    filterPanel?: ReactNode;

    defaultHeaderConfig: T;
    defaultGridConfig: GridConfig;
    entity: LayoutConfigEntity;
}

const GridLayout = <T, >(props: GridLayoutProps<T>) => {
    const {
        view,
        loading,
        changeView,
        groupBy,

        saveHeaderConfig,
        headerConfig,
        defaultHeaderConfig,
        isHeaderDisabled,

        saveGridConfig,
        reloadGridConfig,
        gridConfig,
        defaultGridConfig,
        isGridDisabled,
        refresh,
        refreshVal,

        selectedRows,
        setSelectedRows,

        setIsFilterPanelShown,
        isFilterPanelShown
    } = useGridLayout<T>(props);

    const value = useMemo(() => ({
        changeView,
        groupBy,

        saveHeaderConfig,
        headerConfig,
        defaultHeaderConfig,
        isHeaderDisabled,

        saveGridConfig,
        reloadGridConfig,
        gridConfig,
        defaultGridConfig,
        isGridDisabled,
        refresh,
        refreshVal,
        selectedRows,
        setSelectedRows,
        setIsFilterPanelShown,
        isFilterPanelShown
    } as IGridLayoutContext<T>), [
        changeView,
        headerConfig,
        defaultHeaderConfig,
        isHeaderDisabled,
        gridConfig,
        defaultGridConfig,
        isGridDisabled,
        saveHeaderConfig,
        saveGridConfig,
        reloadGridConfig,
        groupBy,
        refreshVal,
        refresh,
        selectedRows,
        setSelectedRows,
        setIsFilterPanelShown,
        isFilterPanelShown
    ]);

    return (
        <GridLayoutContext.Provider value={value}>
            <GridLayoutStructure view={view}
                                 entity={props.entity}
                                 loading={loading}
                                 grid={props.grid}
                                 header={props.header}
                                 map={props.map}
                                 filterPanel={props.filterPanel}/>
        </GridLayoutContext.Provider>
    );
};

export default GridLayout;