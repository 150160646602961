import React from 'react';
import {IOffer} from "../../../../../../../../app/interfaces/offer/IOffer";
import {useAppSelector} from "../../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../../app/enums/UserRole";
import cl from './OfferOfferGid.module.css';
import {faUser, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {useOfferApi} from "../../../../../../../../app/api/offer";
import {ResponseResultCode} from "../../../../../../../../app/enums/ResponseResultCode";
import Icon from "../../../../../../../../assets/icon/Icon";

type OfferGidProps = {
    offer: IOffer;
}

const OfferOfferGid: React.FC<OfferGidProps> = ({
                                                    offer
                                                }) => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    const {
        getOfferManagementLink: {
            query: getOfferManagementLink
        }
    } = useOfferApi();

    if (!user) {
        return null;
    }

    let role = getUserRole(user);
    offer.firstOrder = true;
    if (role === UserRole.ServiceProvider) {
        return (
            <div className={'details-box-item-field-value'}>
                {offer.orderReleaseXid}
            </div>
        );
    } else {
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                {offer.hasAssigment &&
                    <FontAwesomeIcon icon={faUser}
                                     className={cl.user}
                                     title={t("shared.labels.executor-assigned-label")}/>
                }

                {offer.hasGroupAssigment &&
                    <FontAwesomeIcon icon={faUsers}
                                     className={cl.user}
                                     title={`${t("shared.labels.group-access-label")}: ${offer.forwarderUser?.name ?? ''}`}/>
                }

                {offer.firstOrder &&
                    <Icon icon={"faTriangleExclamation"}
                          title={t("shared.labels.first-order-label")}
                          className={cl.firstOrder}/>
                }

                <span className={'details-box-item-field-edit-value'}
                      onClick={async () => {
                          if (!offer.orderReleaseGid) {
                              return;
                          }

                          let gid = offer.orderReleaseGid;

                          if (offer.legGid !== null && offer.legGid !== '') {
                              gid = offer.orderReleaseGid.split('-').length > 2
                                  ? offer.orderReleaseGid.substring(0, offer.orderReleaseGid.lastIndexOf('-'))
                                  : offer.orderReleaseGid;
                          }

                          try {
                              const response = await getOfferManagementLink(gid);

                              if (response && response.data && response.data.resultCode === ResponseResultCode.Ok &&
                                  response.data.data) {
                                  window.open(response.data.data, '_blank');
                              }
                          } catch {
                              //ignore
                          }
                      }}>
                    {offer.orderReleaseXid}
                </span>
            </div>
        );
    }
};

export default OfferOfferGid;