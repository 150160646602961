import React, {useState} from 'react';
import {ICancelSpotGetAction} from "../../../../../../app/interfaces/offer/ICancelSpotGetAction";
import {ICancelSpotPostAction} from "../../../../../../app/interfaces/offer/ICancelSpotPostAction";
import {useTranslation} from "react-i18next";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import cl from './CancelOfferForm.module.css';
import SelectedOffers from "../../../form/components/SelectedOffers/SelectedOffers";
import Textarea from "../../../../../../components/ui/Textarea/Textarea";
import {SelectModel} from "../../../../../../app/types/SelectModel";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {RadioButton} from "@progress/kendo-react-inputs";

type CancelOfferFormProps = {
    model: ICancelSpotGetAction;
    disabled: boolean;
    save: (model: ICancelSpotPostAction) => Promise<void>;
};

const CancelOfferForm: React.FC<CancelOfferFormProps> = ({
                                                             model,
                                                             disabled,
                                                             save
                                                         }) => {
    const {t} = useTranslation();
    const [reasons, setReasons] = useState<Array<SelectModel>>([]);

    const form = useFormik<ICancelSpotPostAction>({
        initialValues: {
            orderReleaseGid: model.orderReleaseGid,
            notes: null,
            reason: null,
            extReason: null,
            notValid: false
        },
        validationSchema: Yup.object().shape({
            reason: Yup.string().required(t("shared.errors.required-error").replace('{0}', t("offer.form.cancel-offer-type"))),
            extReason: Yup.string().required(t("shared.errors.required-error").replace('{0}', t("offer.form.cancel-offer-reason")))
        }),
        onSubmit: async (values: ICancelSpotPostAction, actions: FormikHelpers<ICancelSpotPostAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    const buildReasonsList = (reason: string) => {
        let res: Array<SelectModel> = [];

        switch (reason) {
            case 'CANCELED_BY_ASSTRA':
                res = [
                    {
                        value: 'There is no vehicle available on the date specified',
                        text: t("offer.cancel.reason.cba-no-vehicle-available-for-date")
                    },
                    {
                        value: 'Non-profile TL/product',
                        text: t("offer.cancel.reason.cba-no-profile-tl-or-product")
                    },
                    {
                        value: 'Type of vehicle does not comply with the agreement',
                        text: t("offer.cancel.reason.cba-invalid-transport-type")
                    },
                    {
                        value: 'Out of the price range',
                        text: t("offer.cancel.reason.cba-out-of-price")
                    },
                    {
                        value: 'Duplicated OR',
                        text: t("offer.cancel.reason.cba-duplicated-or")
                    }
                ];
                break;
            case 'CANCELED_BY_CUSTOMER':
                res = [
                    {
                        value: 'Cargo is not ready',
                        text: t("offer.cancel.reason.cbc-cargo-is-not-ready")
                    },
                    {
                        value: 'Cargo unpaid',
                        text: t("offer.cancel.reason.cbc-cargo-unpaid")
                    },
                    {
                        value: 'Documents are not ready',
                        text: t("offer.cancel.reason.cbc-documents-are-not-ready")
                    },
                    {
                        value: 'The customer has changed the loading/unloading dates',
                        text: t("offer.cancel.reason.cbc-change-loading-unloading-dates")
                    },
                    {
                        value: 'The customer has already found the car',
                        text: t("offer.cancel.reason.cbc-customer-already-found-the-car")
                    },
                    {
                        value: 'The customer has chosen an alternative delivery arrangement',
                        text: t("offer.cancel.reason.cbc-customer-has-chosen-an-alternative-delivery-arrangement")
                    }
                ];
                break;
            case 'ORDER_NOT_VALID':
                res = [
                    {
                        value: 'Loading number changed, new OR received for replacement',
                        text: t("offer.cancel.reason.onv-loading-number-changed")
                    },
                    {
                        value: 'The customer resent OR',
                        text: t("offer.cancel.reason.onv-customer-resent-or")
                    }
                ];
                break;
        }

        setReasons(res);
    }

    return (
        <form id='cancel-offer-form'
              onSubmit={form.handleSubmit}>
            <SelectedOffers ids={model.items.map(item => item.id)}/>
            <div className={'pd-10'}>
                <div className={`mgb-20 ${cl.warning}`}>
                    {t("offer.warnings.cancel-offer-confirmation")}
                </div>

                <div className={`mgb-10`}>
                    <div className={cl.checkboxes}>
                        <div className={cl.label}>
                            <RadioButton name={`reason`}
                                         value={`CANCELED_BY_ASSTRA`}
                                         size={'small'}
                                         checked={form.values.reason === 'CANCELED_BY_ASSTRA'}
                                         label={t("offer.cancel.type.cancelled-by-asstra")}
                                         onChange={() => {
                                             form.setFieldValue('extReason', null);
                                             form.setFieldValue('reason', 'CANCELED_BY_ASSTRA');
                                             buildReasonsList('CANCELED_BY_ASSTRA');
                                         }}
                            />
                        </div>

                        <div className={cl.label}>
                            <RadioButton name={`reason`}
                                         value={`CANCELED_BY_CUSTOMER`}
                                         size={'small'}
                                         checked={form.values.reason === 'CANCELED_BY_CUSTOMER'}
                                         label={t("offer.cancel.type.cancelled-by-customer")}
                                         onChange={() => {
                                             form.setFieldValue('extReason', null);
                                             form.setFieldValue('reason', 'CANCELED_BY_CUSTOMER');
                                             buildReasonsList('CANCELED_BY_CUSTOMER');
                                         }}
                            />
                        </div>

                        <div className={cl.label}>
                            <RadioButton name={`reason`}
                                         value={`ORDER_NOT_VALID`}
                                         size={'small'}
                                         checked={form.values.reason === 'ORDER_NOT_VALID'}
                                         label={t("offer.cancel.type.order-not-valid")}
                                         onChange={() => {
                                             form.setFieldValue('extReason', null);
                                             form.setFieldValue('reason', 'ORDER_NOT_VALID');
                                             buildReasonsList('ORDER_NOT_VALID');
                                         }}
                            />
                        </div>
                    </div>

                    {form.errors.reason &&
                        <div className={'error-label'}>
                            {form.errors.reason}
                        </div>
                    }
                </div>

                <div className={`mgb-10 drop-down-picker`}>
                    <label className={'view-label required reason-label'}>
                        {t("offer.form.cancel-offer-reason")}
                    </label>

                    <DropDownList data={reasons}
                                  textField="text"
                                  disabled={disabled || reasons.length <= 0}
                                  id={'cancel-offer-reason-value'}
                                  dataItemKey="value"
                                  value={form.values.extReason !== null ? reasons.find(e => e.value === form.values.extReason) : null}
                                  onChange={(ev) => {
                                      form.setFieldValue('extReason', ev.target.value.value);
                                  }}/>

                    {form.errors.extReason &&
                        <div className={'error-label'}>
                            {form.errors.extReason}
                        </div>
                    }
                </div>

                <div className={'mgb-10'}>
                    <label className={'view-label comments-label'}>
                        {t("offer.form.comments")}
                    </label>
                    <Textarea disabled={disabled}
                              className={'cancel-offer-comments-value'}
                              style={{minHeight: '100px', resize: 'vertical', maxHeight: '150px'}}
                              value={form.values.notes ?? ''}
                              placeholder={t("offer.form.comments")}
                              onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                  form.setFieldValue('notes', ev.target.value);
                              }}/>
                </div>
            </div>
        </form>
    );
};

export default CancelOfferForm;