import {AxiosResponse} from "axios";
import {IDataResponse, IResponse} from "../../interfaces/response/IResponse";
import {ILoginUser} from "../../interfaces/login/ILogin";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {ISelfBillingOverview} from "../../interfaces/selfBilling/ISelfBillingOverview";
import {ISelfBillingConfirmationPostAction} from "../../interfaces/selfBilling/ISelfBillingConfirmationPostAction";
import {ISelfBilling} from "../../interfaces/selfBilling/ISelfBilling";
import {ISelfBillingInvalidVatPostAction} from "../../interfaces/selfBilling/ISelfBillingInvalidVatPostAction";
import {ISelfBillingAddCmrGetAction} from "../../interfaces/selfBilling/ISelfBillingAddCmrGetAction";
import {ISelfBillingChangeCostGetAction} from "../../interfaces/selfBilling/ISelfBillingChangeCostGetAction";
import {ISelfBillingEditGetAction} from "../../interfaces/selfBilling/ISelfBillingEditGetAction";
import {ISelfBillingEditPostAction} from "../../interfaces/selfBilling/ISelfBillingEditPostAction";
import {ISelfBillingAddExpenseGetAction} from "../../interfaces/selfBilling/ISelfBillingAddExpenseGetAction";

export const useGetServiceProvidersLoginsListAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    getServiceProvidersLoginsList: () => Promise<AxiosResponse<IDataResponse<Array<ILoginUser>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<ILoginUser>>>({
        method: 'GET'
    });

    const getServiceProvidersLoginsList = useCallback(async () => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/getServiceProvidersLoginsListAnonymous`
        });
    }, [execute]);

    return {isLoading, cancel, getServiceProvidersLoginsList};
};

export const useGetSelfBillingOverviewAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    getSelfBillingOverview: () => Promise<AxiosResponse<IDataResponse<ISelfBillingOverview>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ISelfBillingOverview>>({
        method: 'GET'
    });

    const getSelfBillingOverview = useCallback(async () => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/getSelfBillingOverviewAnonymous`
        });
    }, [execute]);

    return {isLoading, cancel, getSelfBillingOverview};
};

export const useGetSelfBillingDataAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    getSelfBillingData: () => Promise<AxiosResponse<IDataResponse<ISelfBilling>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ISelfBilling>>({
        method: 'GET'
    });

    const getSelfBillingData = useCallback(async () => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/getSelfBillingDataAnonymous`
        });
    }, [execute]);

    return {isLoading, cancel, getSelfBillingData};
};

export const useConfirmSelfBillingAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    confirmSelfBilling: (params: ISelfBillingConfirmationPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ISelfBillingConfirmationPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/confirmSelfBillingAnonymous`
    });

    const confirmSelfBilling = useCallback(async (params: ISelfBillingConfirmationPostAction) => await execute(params), [execute]);

    return {confirmSelfBilling, isLoading, cancel};
};

export const useGetAttachmentAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    getAttachment: (id: number) => Promise<AxiosResponse<IResponse | Blob> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse | Blob>({
        method: 'get',
        responseType: 'blob'
    });

    const getAttachment = useCallback(async (id: number) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/getAttachmentAnonymous?id=${id}`
    }), [execute]);

    return {getAttachment, isLoading, cancel};
};

export const useDownloadCmrDocumentAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    downloadCmrDocument: (shipmentGid: string, cmrGuid: string) => Promise<AxiosResponse<IResponse | Blob> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse | Blob>({
        method: 'get',
        responseType: 'blob'
    });

    const downloadCmrDocument = useCallback(async (shipmentGid: string, cmrGuid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/downloadCmrDocumentAnonymous?shipmentGid=${shipmentGid}&cmrGuid=${cmrGuid}`
    }), [execute]);

    return {downloadCmrDocument, isLoading, cancel};
};


// For authorized users

export const useGetSelfBillingDataAuthorize = (): {
    isLoading: boolean,
    cancel: () => void,
    getSelfBillingData: (shipmentGid: string, supplierId: string) => Promise<AxiosResponse<IDataResponse<ISelfBilling>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ISelfBilling>>({
        method: 'GET'
    });

    const getSelfBillingData = useCallback(async (shipmentGid: string, supplierId: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/getSelfBillingDataAuthorize?shipmentGid=${shipmentGid}&supplierId=${supplierId}`
        });
    }, [execute]);

    return {isLoading, cancel, getSelfBillingData};
};

export const useGetAttachmentAuthorize = (): {
    isLoading: boolean,
    cancel: () => void,
    getAttachment: (id: number) => Promise<AxiosResponse<IResponse | Blob> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse | Blob>({
        method: 'get',
        responseType: 'blob'
    });

    const getAttachment = useCallback(async (id: number) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/getAttachmentAuthorize?id=${id}`
    }), [execute]);

    return {getAttachment, isLoading, cancel};
};

export const useDownloadCmrDocumentAuthorize = (): {
    isLoading: boolean,
    cancel: () => void,
    downloadCmrDocument: (shipmentGid: string, cmrGuid: string) => Promise<AxiosResponse<IResponse | Blob> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse | Blob>({
        method: 'get',
        responseType: 'blob'
    });

    const downloadCmrDocument = useCallback(async (shipmentGid: string, cmrGuid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/downloadCmrDocumentAuthorize?shipmentGid=${shipmentGid}&cmrGuid=${cmrGuid}`
    }), [execute]);

    return {downloadCmrDocument, isLoading, cancel};
};

export const useRequestInvalidVatAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    requestInvalidVatAnonymous: (params: ISelfBillingInvalidVatPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ISelfBillingInvalidVatPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/postRequestInvalidVatAnonymous`
    });

    const requestInvalidVatAnonymous = useCallback(async (params: ISelfBillingInvalidVatPostAction) => await execute(params), [execute]);

    return {requestInvalidVatAnonymous, isLoading, cancel};
};

export const useRequestInvalidVatAuthorize = (): {
    isLoading: boolean,
    cancel: () => void,
    requestInvalidVatAuthorize: (params: ISelfBillingInvalidVatPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ISelfBillingInvalidVatPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/postRequestInvalidVatAuthorize`
    });

    const requestInvalidVatAuthorize = useCallback(async (params: ISelfBillingInvalidVatPostAction) => await execute(params), [execute]);

    return {requestInvalidVatAuthorize, isLoading, cancel};
};

export const useGetAddCmrSelfBillingDocumentDataAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    getAddCmrSelfBillingDocumentDataAnonymous: (shipmentGid: string) => Promise<AxiosResponse<IDataResponse<ISelfBillingAddCmrGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ISelfBillingAddCmrGetAction>>({
        method: 'GET'
    });

    const getAddCmrSelfBillingDocumentDataAnonymous = useCallback(async (shipmentGid: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/getSelfBillingAddCmrDocumentDataAnonymous?shipmentGid=${shipmentGid}`
        });
    }, [execute]);

    return {isLoading, cancel, getAddCmrSelfBillingDocumentDataAnonymous};
};

export const useGetAddCmrSelfBillingDocumentData = (): {
    isLoading: boolean,
    cancel: () => void,
    getAddCmrSelfBillingDocumentData: (shipmentGid: string) => Promise<AxiosResponse<IDataResponse<ISelfBillingAddCmrGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ISelfBillingAddCmrGetAction>>({
        method: 'GET'
    });

    const getAddCmrSelfBillingDocumentData = useCallback(async (shipmentGid: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/getSelfBillingAddCmrDocumentData?shipmentGid=${shipmentGid}`
        });
    }, [execute]);

    return {isLoading, cancel, getAddCmrSelfBillingDocumentData};
};

export const useAddCmrSelfBillingDocumentData = (): {
    isLoading: boolean,
    cancel: () => void,
    addCmrSelfBillingDocumentData: (params: FormData) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, FormData>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/addCmrDocumentData`,
        headers: { "Content-Type": "multipart/form-data" },
    });

    const addCmrSelfBillingDocumentData = useCallback(async (params: FormData) => await execute(params), [execute]);

    return {addCmrSelfBillingDocumentData, isLoading, cancel};
};

export const useAddCmrSelfBillingDocumentDataAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    addCmrSelfBillingDocumentDataAnonymous: (params: FormData) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, FormData>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/addCmrDocumentDataAnonymous`,
        headers: { "Content-Type": "multipart/form-data" },
    });

    const addCmrSelfBillingDocumentDataAnonymous = useCallback(async (params: FormData) => await execute(params), [execute]);

    return {addCmrSelfBillingDocumentDataAnonymous, isLoading, cancel};
};

export const useGetSelfBillingChangeCostDataAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    getSelfBillingChangeCostDataAnonymous: (shipmentGid: string) => Promise<AxiosResponse<IDataResponse<ISelfBillingChangeCostGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ISelfBillingChangeCostGetAction>>({
        method: 'GET'
    });

    const getSelfBillingChangeCostDataAnonymous = useCallback(async (shipmentGid: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/getSelfBillingChangeCostDataAnonymous?shipmentGid=${shipmentGid}`
        });
    }, [execute]);

    return {isLoading, cancel, getSelfBillingChangeCostDataAnonymous};
};

export const useGetSelfBillingChangeCostData = (): {
    isLoading: boolean,
    cancel: () => void,
    getSelfBillingChangeCostData: (shipmentGid: string) => Promise<AxiosResponse<IDataResponse<ISelfBillingChangeCostGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ISelfBillingChangeCostGetAction>>({
        method: 'GET'
    });

    const getSelfBillingChangeCostData = useCallback(async (shipmentGid: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/getSelfBillingChangeCostData?shipmentGid=${shipmentGid}`
        });
    }, [execute]);

    return {isLoading, cancel, getSelfBillingChangeCostData};
};

export const useChangeCostSelfBillingData = (): {
    isLoading: boolean,
    cancel: () => void,
    changeCostSelfBillingData: (params: FormData) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, FormData>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/changeCostData`,
        headers: { "Content-Type": "multipart/form-data" },
    });

    const changeCostSelfBillingData = useCallback(async (params: FormData) => await execute(params), [execute]);

    return {changeCostSelfBillingData, isLoading, cancel};
};

export const useChangeCostSelfBillingDataAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    changeCostSelfBillingDataAnonymous: (params: FormData) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, FormData>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/changeCostDataAnonymous`,
        headers: { "Content-Type": "multipart/form-data" },
    });

    const changeCostSelfBillingDataAnonymous = useCallback(async (params: FormData) => await execute(params), [execute]);

    return {changeCostSelfBillingDataAnonymous, isLoading, cancel};
};

export const useGetSelfBillingEditDataAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    getSelfBillingEditDataAnonymous: (shipmentGid: string) => Promise<AxiosResponse<IDataResponse<ISelfBillingEditGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ISelfBillingEditGetAction>>({
        method: 'GET'
    });

    const getSelfBillingEditDataAnonymous = useCallback(async (shipmentGid: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/getSelfBillingEditDataAnonymous?shipmentGid=${shipmentGid}`
        });
    }, [execute]);

    return {isLoading, cancel, getSelfBillingEditDataAnonymous};
};

export const useGetSelfBillingEditData = (): {
    isLoading: boolean,
    cancel: () => void,
    getSelfBillingEditData: (shipmentGid: string) => Promise<AxiosResponse<IDataResponse<ISelfBillingEditGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ISelfBillingEditGetAction>>({
        method: 'GET'
    });

    const getSelfBillingEditData = useCallback(async (shipmentGid: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/getSelfBillingEditData?shipmentGid=${shipmentGid}`
        });
    }, [execute]);

    return {isLoading, cancel, getSelfBillingEditData};
};

export const useEditSelfBilling = (): {
    isLoading: boolean,
    cancel: () => void,
    editSelfBilling: (params: ISelfBillingEditPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ISelfBillingEditPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/editSelfBilling`
    });

    const editSelfBilling = useCallback(async (params: ISelfBillingEditPostAction) => await execute(params), [execute]);

    return {editSelfBilling, isLoading, cancel};
};

export const useEditSelfBillingAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    editSelfBillingAnonymous: (params: ISelfBillingEditPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ISelfBillingEditPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/editSelfBillingAnonymous`
    });

    const editSelfBillingAnonymous = useCallback(async (params: ISelfBillingEditPostAction) => await execute(params), [execute]);

    return {editSelfBillingAnonymous, isLoading, cancel};
};

export const useGetAddExpenseSelfBillingDataAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    getAddExpenseSelfBillingDataAnonymous: (shipmentGid: string) => Promise<AxiosResponse<IDataResponse<ISelfBillingAddExpenseGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ISelfBillingAddExpenseGetAction>>({
        method: 'GET'
    });

    const getAddExpenseSelfBillingDataAnonymous = useCallback(async (shipmentGid: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/getSelfBillingAddExpenseDataAnonymous?shipmentGid=${shipmentGid}`
        });
    }, [execute]);

    return {isLoading, cancel, getAddExpenseSelfBillingDataAnonymous};
};

export const useGetAddExpenseSelfBillingData = (): {
    isLoading: boolean,
    cancel: () => void,
    getAddExpenseSelfBillingData: (shipmentGid: string) => Promise<AxiosResponse<IDataResponse<ISelfBillingAddExpenseGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ISelfBillingAddExpenseGetAction>>({
        method: 'GET'
    });

    const getAddExpenseSelfBillingData = useCallback(async (shipmentGid: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/getSelfBillingAddExpenseData?shipmentGid=${shipmentGid}`
        });
    }, [execute]);

    return {isLoading, cancel, getAddExpenseSelfBillingData};
};

export const useAddExpense = (): {
    isLoading: boolean,
    cancel: () => void,
    addExpense: (params: FormData) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, FormData>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/addExpense`,
        headers: { "Content-Type": "multipart/form-data" },
    });

    const addExpense = useCallback(async (params: FormData) => await execute(params), [execute]);

    return {addExpense, isLoading, cancel};
};

export const useAddExpenseAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    addExpenseAnonymous: (params: FormData) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, FormData>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/selfBilling/addExpenseAnonymous`,
        headers: { "Content-Type": "multipart/form-data" },
    });

    const addExpenseAnonymous = useCallback(async (params: FormData) => await execute(params), [execute]);

    return {addExpenseAnonymous, isLoading, cancel};
};