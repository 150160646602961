import React, {useState} from 'react';
import cl from './NotificationGridComponentCard.module.css';
import cl2 from '../NotificationGridComponent.module.css';

import {
    IForwarderNotificationSetupGetAction
} from "../../../../../../../app/interfaces/notification/IForwarderNotificationSetupGetAction";
import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import Checkbox from "../../../../../../../components/ui/Checkbox/Checkbox";
import Input from "../../../../../../../components/ui/Input/Input";
import {BLErrorCode} from "../../../../../../../app/enums/BLErrorCode";
import {SelectModel} from "../../../../../../../app/types/SelectModel";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {getSchedulerHours} from "../../../../../../../helpers/notification";

type NotificationGridComponentCardProps = {
    setup: IForwarderNotificationSetupGetAction;
    onChange: (items: Array<IForwarderNotificationSetupGetAction>, field: keyof IForwarderNotificationSetupGetAction, value: string | boolean) => void;
    disabled: boolean;
    isLoading: boolean;
};

const NotificationGridComponentCard: React.FC<NotificationGridComponentCardProps> = ({
                                                                                         setup,
                                                                                         disabled,
                                                                                         isLoading,
                                                                                         onChange
                                                                                     }) => {
    const {t, i18n} = useTranslation();

    const [hours] = useState<Array<SelectModel>>(setup.periods.length > 1 ? getSchedulerHours() : []);
    const periods = setup.periods.length > 1
        ? setup.periods.map(item => {
            return {
                text: t(`notification.period.${item.value}`),
                value: item.value
            } as SelectModel
        }) : [];

    let name = setup.nameLocales.find(e => e.value === i18n.language.toLowerCase())?.text ?? '';
    let description = setup.descriptionLocales.find(e => e.value === i18n.language.toLowerCase())?.text ?? '';

    return (
        <div className={cl.card}>
            <div></div>

            <div>
                <div style={{width: '20%'}} className={cl.name}>
                    {name}

                    <Tooltip id={`setup-description-${setup.id}-${setup.templateId}`}
                             style={{maxWidth: '400px', zIndex: '1004', fontSize: '10px'}}
                             place={'right'}/>
                    <FontAwesomeIcon data-tooltip-id={`setup-description-${setup.id}-${setup.templateId}`}
                                     data-tooltip-content={description}
                                     icon={faCircleInfo}
                                     className={cl.tooltip}/>
                </div>

                <div style={{width: '20%', alignItems: 'center'}} className={cl2.notifyRoleRow}>
                    <div>
                        <Checkbox disabled={disabled || isLoading}
                                  checked={isLoading
                                      ? false
                                      : setup.toCM
                                  }
                                  change={(val: boolean) => {
                                      onChange([setup], 'toCM', val);
                                  }}/>
                    </div>

                    <div>
                        <Checkbox disabled={disabled || isLoading}
                                  checked={isLoading
                                      ? false
                                      : setup.toTL
                                  }
                                  change={(val: boolean) => {
                                      onChange([setup], 'toTL', val);
                                  }}/>
                    </div>

                    <div>
                        <Checkbox disabled={disabled || isLoading}
                                  checked={isLoading
                                      ? false
                                      : setup.toFA
                                  }
                                  change={(val: boolean) => {
                                      onChange([setup], 'toFA', val);
                                  }}/>
                    </div>

                    <div>
                        <Checkbox disabled={disabled || isLoading}
                                  checked={isLoading
                                      ? false
                                      : setup.toExA
                                  }
                                  change={(val: boolean) => {
                                      onChange([setup], 'toExA', val);
                                  }}/>
                    </div>

                    <div>
                        <Checkbox disabled={disabled || isLoading}
                                  checked={isLoading
                                      ? false
                                      : setup.toOR
                                  }
                                  change={(val: boolean) => {
                                      onChange([setup], 'toOR', val);
                                  }}/>
                    </div>

                    <div>
                        <Checkbox disabled={disabled || isLoading}
                                  checked={isLoading
                                      ? false
                                      : setup.toSA
                                  }
                                  change={(val: boolean) => {
                                      onChange([setup], 'toSA', val);
                                  }}/>
                    </div>
                </div>

                <div style={{width: '15%', display: 'flex', flexDirection: 'column', gap: '2px', padding: '0 2px'}}
                     className={'drop-down-picker'}>
                    {periods.length > 1 &&
                        <>
                            <DropDownList data={periods}
                                          style={{maxHeight: '20px'}}
                                          disabled={disabled || isLoading}
                                          textField="text"
                                          dataItemKey="value"
                                          value={periods.find(e => e.value === setup.sendPeriod) ?? null}
                                          onChange={(ev) => {
                                              onChange([setup], 'sendPeriod', ev.value?.value ?? null);
                                          }}/>

                            {setup.sendPeriod === '1' || setup.sendPeriod === '2' || setup.sendPeriod === '3'
                                ? <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: '2px'
                                }}>
                                    <DropDownList data={hours}
                                                  style={{maxHeight: '20px'}}
                                                  disabled={disabled}
                                                  textField="text"
                                                  dataItemKey="value"
                                                  value={hours.find(e => e.value === setup.sendSchedule1) ?? null}
                                                  onChange={(ev) => {
                                                      onChange([setup], 'sendSchedule1', ev.value?.value ?? null);
                                                  }}/>

                                    {setup.sendPeriod === '2' || setup.sendPeriod === '3'
                                        ? <DropDownList data={hours}
                                                        style={{maxHeight: '20px'}}
                                                        disabled={disabled}
                                                        textField="text"
                                                        dataItemKey="value"
                                                        value={hours.find(e => e.value === setup.sendSchedule2) ?? null}
                                                        onChange={(ev) => {
                                                            onChange([setup], 'sendSchedule2', ev.value?.value ?? null);
                                                        }}/>
                                        : null
                                    }

                                    {setup.sendPeriod === '3' &&
                                        <DropDownList data={hours}
                                                      style={{maxHeight: '20px'}}
                                                      disabled={disabled}
                                                      textField="text"
                                                      dataItemKey="value"
                                                      value={hours.find(e => e.value === setup.sendSchedule3) ?? null}
                                                      onChange={(ev) => {
                                                          onChange([setup], 'sendSchedule3', ev.value?.value ?? null);
                                                      }}/>
                                    }
                                </div>
                                : null
                            }
                        </>
                    }
                </div>

                <div className={cl2.notifyIfSenderRow}
                     style={{
                         width: '15%',
                         alignItems: 'center',
                         justifyContent: setup.fromSystem ? 'center' : 'start'
                     }}>
                    {setup.fromSystem
                        ? <>
                            <div>
                                <div>
                                    <Checkbox disabled={disabled || isLoading || true}
                                              checked={true}
                                              change={() => {
                                                  //ignore
                                              }}/>
                                </div>

                                <div>
                                    {t("notification.labels.system-label")}
                                </div>
                            </div>
                        </>
                        : <>
                            <div>
                                <Checkbox disabled={disabled || isLoading}
                                          checked={isLoading
                                              ? false
                                              : setup.fromSelf
                                          }
                                          change={(val: boolean) => {
                                              onChange([setup], 'fromSelf', val);
                                          }}/>
                            </div>

                            <div>
                                <Checkbox disabled={disabled || isLoading}
                                          checked={isLoading
                                              ? false
                                              : setup.fromFw
                                          }
                                          change={(val: boolean) => {
                                              onChange([setup], 'fromFw', val);
                                          }}/>
                            </div>

                            <div>
                                <Checkbox disabled={disabled || isLoading || true}
                                          checked={isLoading
                                              ? false
                                              : setup.fromSp
                                          }
                                          change={(val: boolean) => {
                                              onChange([setup], 'fromSp', val);
                                          }}/>
                            </div>
                        </>
                    }
                </div>

                <div style={{width: '15%', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                    <Checkbox disabled={disabled || isLoading}
                              checked={isLoading
                                  ? false
                                  : setup.isEmail
                              }
                              change={(val: boolean) => {
                                  onChange([setup], 'isEmail', val);
                              }}/>
                </div>

                <div style={{
                    width: '15%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 5px'
                }}>
                    <Input placeholder={t("notification.labels.cc-email-short")}
                           disabled={disabled || isLoading}
                           value={setup.toCC ?? ''}
                           autoComplete={'off'}
                           icon={faEnvelope}
                           style={setup.errors.find(e => e.errorCode === BLErrorCode.InvalidEmailFormat) !== undefined
                               ? {borderColor: 'red'} : undefined}
                           onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                               onChange([setup], 'toCC', ev.target.value);
                           }}
                    />
                </div>
            </div>

            <div></div>
        </div>
    );
};

export default NotificationGridComponentCard;