import {ExpenseStatus} from "../../../../../../app/enums/ExpenseStatus";
import {ISelfBillingExpense} from "../../../../../../app/interfaces/selfBilling/ISelfBillingExpense";
import {InvoiceStatus} from "../../../../../../app/enums/InvoiceStatus";
import {EcmStatus} from "../../../../../../app/enums/EcmStatus";
import {SelectModel} from "../../../../../../app/types/SelectModel";

export const getExpenseStatus = (status: ExpenseStatus): [string, string] => {
    switch (status) {
        case ExpenseStatus.Accepted:
            return ["self-billing.expense-status.accepted", '7FB98B'];
        case ExpenseStatus.Approval:
            return ["self-billing.expense-status.approval", '3F8CE5'];
        case ExpenseStatus.New:
            return ["self-billing.expense-status.new", '3F8CE5'];
        case ExpenseStatus.Rejected:
            return ["self-billing.expense-status.rejected", 'E96024'];
    }
};

export const getVatNote = (note: string | null): string | null => {
    if (note === null || note === '') {
        return null;
    }

    let vat1 = "VAT_DE_0_UStG".toLowerCase();
    let vat2 = "S_NP_UNIA".toLowerCase();
    let vat3 = "VAT_0_RC".toLowerCase();

    if (note === vat1) {
        return 'self-billing.vat.vat-1';
    }

    if (note === vat2) {
        return 'self-billing.vat.vat-2';
    }

    if (note === vat3) {
        return 'self-billing.vat.vat-3';
    }

    return null;
};

export const getExpensePaymentStatusColor = (expense: ISelfBillingExpense): string | null => {
    if (expense.invoices.length > 0 && expense.paymentStatus === InvoiceStatus.Paid) {
        return '7fb98b';
    }

    if ((expense.isCompleted || expense.invoices.length > 0) && expense.paymentStatus === InvoiceStatus.NotPaid) {
        return 'EE2012';
    }

    if (expense.invoices.length > 0 && expense.paymentStatus === InvoiceStatus.PartiallyPaid) {
        return '7d89ba';
    }

    return null;
}

export const getCmrStatus = (status: EcmStatus | null): [string, string] | null => {
    if (status === null) {
        return null;
    }

    switch (status) {
        case EcmStatus.Handled:
            return ["self-billing.ecm-status.handled", "808080"];
        case EcmStatus.Incorrect:
            return ["self-billing.ecm-status.incorrect", "EE2012"];
        case EcmStatus.NotFound:
            return ["self-billing.ecm-status.not-found", "EE2012"];
        case EcmStatus.Deleted:
            return ["self-billing.ecm-status.deleted", "808080"];
        case EcmStatus.Sent:
            return ["self-billing.ecm-status.sent", "CACA00"];
        case EcmStatus.Check:
            return ["self-billing.ecm-status.check", "CACA00"];
        case EcmStatus.Delivered:
            return ["self-billing.ecm-status.delivered", "CACA00"];
        case EcmStatus.Completed:
            return ["self-billing.ecm-status.completed", "7fb98b"];
        case EcmStatus.Draft:
            return ["self-billing.ecm-status.draft", "3F8CE5"];
        case EcmStatus.Income:
            return ["self-billing.ecm-status.invoice", "3F8CE5"];
        case EcmStatus.New:
            return ["self-billing.ecm-status.new", "CACA00"];
    }

    return null;
};

export const calculateRate = (bet: string | null, vat: number | null, revertVat: boolean): string | null => {
    if (bet !== null && bet !== '' && vat !== null) {
        let parsedBet = Number(bet.replace(/,/g, '.').replace(/\s+/g, ""));
        return revertVat
            ? (parsedBet -
                (parsedBet / (1 + (vat / 100))) * (vat / 100)).toFixed(2)
            : (parsedBet + (parsedBet * vat / 100)).toFixed(2);
    }

    return null;
}

export const getExpenseTypes = (): Array<SelectModel> => ([
    {
        text: 'self-billing.expense-type.additional-charges',
        value: 'AAG.ADDITIONAL CHARGES',
        description: 'self-billing.expense-type.additional-charges-tooltip'
    },
    {
        text: 'self-billing.expense-type.customs-formality',
        value: 'AAG.CUSTOMS FORMALITIES',
        description: 'self-billing.expense-type.customs-formality-tooltip'
    },
    {
        text: 'self-billing.expense-type.demurrage',
        value: 'AAG.DEMURRAGE',
        description: 'self-billing.expense-type.demurrage-tooltip'
    },
    {
        text: 'self-billing.expense-type.overway',
        value: 'AAG.OVERWAY',
        description: 'self-billing.expense-type.overway-tooltip'
    }
]);
