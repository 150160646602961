import React, {useState} from 'react';
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import cl from './DateTemplate.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import ChangePlannedDateDialog from "../../../actions/ChangePlannedDate/ChangePlannedDateDialog";
import Icon from "../../../../../../assets/icon/Icon";
import { AsstraIntegrationType } from '../../../../../../app/enums/AsstraIntegrationType';


const AsstraIntegrationTemplate = (props: any) => {
    const {t} = useTranslation();
    const {handleClick} = useGridCellHandleClick(props.dataItem);

    const getAsstraIntegrationType = (status: AsstraIntegrationType): string => {
    
        if (status === AsstraIntegrationType.Constant) {
            return t("shared.labels.constant-integration-label");
        } else if (status === AsstraIntegrationType.Temporary) {
            return t("shared.labels.temporary-integration-label");
        }
    
        return '';
    }

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
            <span className={'default-left-cell cost-value'}> 
                {props.dataItem.asstraIntegration !== null && props.dataItem.asstraIntegration !== undefined && props.dataItem.asstraIntegration >= 0
                    /*? <>{(new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'EUR'}).format(props.dataItem.cost))}</>*/
                    ? <>{getAsstraIntegrationType(props.dataItem.asstraIntegration)}</>
                    : <></>
                }
            </span>
        </td>
    )
    
    
};

export default AsstraIntegrationTemplate;