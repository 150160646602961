import React from 'react';
import cl from './ShipmentInvoiceFormOverview.module.css';
import {IShipmentInvoice} from "../../../../../../../app/interfaces/invoice/IShipmentInvoice";
import {useTranslation} from "react-i18next";
import Tag from "../../../../../../../components/shared/components/Tag/Tag";
import {getInvoiceStatusColor, getInvoiceStatusResource} from "../../../utils";

type ShipmentInvoiceFormOverviewProps = {
    shipmentInvoice: IShipmentInvoice;
};

const ShipmentInvoiceFormOverview: React.FC<ShipmentInvoiceFormOverviewProps> = ({
                                                                                     shipmentInvoice
                                                                                 }) => {
    const {t} = useTranslation();

    return (
        <div className={cl.container}>
            <div className={'prevent-selection'}>
                <span>
                    {t("invoice.form.shipment-price")}
                </span>

                <span className={cl.price}>
                        {`${shipmentInvoice.initialBet !== null ? shipmentInvoice.initialBet : '0.0'} ${shipmentInvoice.initialBetCurrency ?? ''}`}
                </span>
            </div>
            <div className={cl.tag}>
                <Tag item={{
                    id: `invoice-status-${shipmentInvoice.shipmentGid}`,
                    text: t(getInvoiceStatusResource(shipmentInvoice.paymentStatus)),
                    title: t(getInvoiceStatusResource(shipmentInvoice.paymentStatus)),
                    showDeleteIcon: false,
                    showEditIcon: false,
                    showHoverDeleteIcon: false,
                    showHoverEditIcon: false,
                    hoverMagnification: false,
                    backgroundColor: getInvoiceStatusColor(shipmentInvoice.paymentStatus),
                }}
                     size={'full'}
                     containerMaxWidth={200}/>
            </div>
        </div>
    );
};

export default ShipmentInvoiceFormOverview;
