import React from 'react';
import {IQuote} from "../../../../../../../app/interfaces/quote/IQuote";
import {useTranslation} from "react-i18next";

type QCostFieldProps = {
    quote: IQuote;
}

const QCostField: React.FC<QCostFieldProps> = ({
                                                   quote
                                               }) => {
    const {t} = useTranslation();

    return (
        <>
            <label className={'thu-count-label'}>
                {t('quote.form.q-cost')}
            </label>

            <span className={'details-box-item-field-value thu-count-value'}>
                {quote.qCost !== null && quote.qCost !== undefined && quote.qCost !== ''
                    ? <>{`${quote.qCost} EUR`}</>
                    : <>{t("shared.labels.no-data")}</>
                }
            </span>
        </>
    );
};

export default QCostField;