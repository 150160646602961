import React, {ReactNode} from 'react';

type AnonymousDataAccessRouteProps = {
    children: ReactNode;
};

const AnonymousDataAccessRoute: React.FC<AnonymousDataAccessRouteProps> = ({
                                                                               children
                                                                           }) => {
    return <>{children}</>;
};

export default AnonymousDataAccessRoute;