import {
    useAssignQuote,
    useCancelQuote,
    useGetAssignQuoteData,
    useGetCancelQuoteData,
    useGetContracts,
    useGetCrmLink,
    useGetNettoBets,
    useGetProfit,
    useGetQuote,
    useGetQuoteBillContacts,
    useGetQuoteManagementLink,
    useGetQuoteOtmTransporeonLink,
    useGetSendToAllCarrierData,
    useGetServiceProviders,
    useMarkQuotesAsUnViewed,
    useMarkQuotesAsViewed,
    usePredictBruttoRate,
    useSavePriceInfo,
    useSendToAllCarrier,
    useSendTranspareonAccountRequest
} from "./requests";

export const useQuoteApi = () => {
    const {
        isLoading: getQuoteIsLoading,
        cancel: cancelGetQuote,
        getQuote
    } = useGetQuote();

    const {
        isLoading: markQuotesAsViewedLoading,
        cancel: cancelMarkQuotesAsViewed,
        markQuotesAsViewed
    } = useMarkQuotesAsViewed();

    const {
        isLoading: markQuotesAsUnViewedLoading,
        cancel: cancelMarkQuotesAsUnViewed,
        markQuotesAsUnViewed
    } = useMarkQuotesAsUnViewed();

    const {
        isLoading: getQuoteManagementLinkIsLoading,
        cancel: cancelGetQuoteManagementLink,
        getQuoteManagementLink
    } = useGetQuoteManagementLink();

    const {
        isLoading: getCrmLinkIsLoading,
        cancel: cancelGetCrmLink,
        getCrmLink
    } = useGetCrmLink();

    const {
        isLoading: getAssignQuoteDataIsLoading,
        cancel: cancelGetAssignQuoteData,
        getAssignQuoteData
    } = useGetAssignQuoteData();

    const {
        isLoading: assignQuoteIsLoading,
        cancel: cancelAssignQuote,
        assignQuote
    } = useAssignQuote();

    const {
        isLoading: getCancelQuoteDataIsLoading,
        cancel: cancelGetCancelQuoteData,
        getCancelQuoteData
    } = useGetCancelQuoteData();

    const {
        isLoading: cancelQuoteIsLoading,
        cancel: cancelCancelQuote,
        cancelQuote
    } = useCancelQuote();

    const {
        isLoading: getSendToAllCarrierDataIsLoading,
        cancel: cancelGetSendToAllCarrierData,
        getSendToAllCarrierData
    } = useGetSendToAllCarrierData();

    const {
        isLoading: sendToAllCarrierIsLoading,
        cancel: cancelSendToAllCarrier,
        sendToAllCarrier
    } = useSendToAllCarrier();

    const {
        isLoading: getQuoteOtmTransporeonLinkIsLoading,
        cancel: cancelGetQuoteOtmTransporeonLink,
        getQuoteOtmTransporeonLink
    } = useGetQuoteOtmTransporeonLink();

    const {
        isLoading: getNettoBetsIsLoading,
        cancel: cancelGetNettoBets,
        getNettoBets
    } = useGetNettoBets()

    const {
        isLoading: sendTranspareonAccountRequestIsLoading,
        cancel: cancelSendTranspareonAccountRequest,
        sendTranspareonAccountRequest
    } = useSendTranspareonAccountRequest();

    const {
        isLoading: getServiceProvidersIsLoading,
        cancel: cancelGetServiceProviders,
        getServiceProviders
    } = useGetServiceProviders();

    const {
        isLoading: getContractsIsLoading,
        cancel: cancelGetContracts,
        getContracts
    } = useGetContracts();

    const {
        isLoading: predictBruttoRateIsLoading,
        cancel: cancelPredictBruttoRate,
        predictBruttoRate
    } = usePredictBruttoRate();

    const {
        isLoading: getQuoteBillContactsIsLoading,
        cancel: cancelGetQuoteBillContacts,
        getQuoteBillContacts
    } = useGetQuoteBillContacts();

    const {
        isLoading: getProfitIsLoading,
        cancel: cancelGetProfit,
        getProfit
    } = useGetProfit();

    const {
        isLoading: savePriceInfoIsLoading,
        cancel: cancelSavePriceInfo,
        savePriceInfo
    } = useSavePriceInfo();

    return {
        getQuote: {
            query: getQuote,
            isLoading: getQuoteIsLoading,
            cancel: cancelGetQuote
        },
        markQuotesAsUnViewed: {
            mutation: markQuotesAsUnViewed,
            isLoading: markQuotesAsUnViewedLoading,
            cancel: cancelMarkQuotesAsUnViewed
        },
        markQuotesAsViewed: {
            mutation: markQuotesAsViewed,
            isLoading: markQuotesAsViewedLoading,
            cancel: cancelMarkQuotesAsViewed
        },
        getQuoteManagementLink: {
            query: getQuoteManagementLink,
            isLoading: getQuoteManagementLinkIsLoading,
            cancel: cancelGetQuoteManagementLink
        },
        getCrmLink: {
            query: getCrmLink,
            isLoading: getCrmLinkIsLoading,
            cancel: cancelGetCrmLink
        },
        getAssignQuoteData: {
            query: getAssignQuoteData,
            isLoading: getAssignQuoteDataIsLoading,
            cancel: cancelGetAssignQuoteData
        },
        assignQuote: {
            mutation: assignQuote,
            isLoading: assignQuoteIsLoading,
            cancel: cancelAssignQuote
        },
        getCancelQuoteData: {
            query: getCancelQuoteData,
            isLoading: getCancelQuoteDataIsLoading,
            cancel: cancelGetCancelQuoteData
        },
        cancelQuote: {
            mutation: cancelQuote,
            isLoading: cancelQuoteIsLoading,
            cancel: cancelCancelQuote
        },
        getSendToAllCarrierData: {
            query: getSendToAllCarrierData,
            isLoading: getSendToAllCarrierDataIsLoading,
            cancel: cancelGetSendToAllCarrierData
        },
        sendToAllCarrier: {
            mutation: sendToAllCarrier,
            isLoading: sendToAllCarrierIsLoading,
            cancel: cancelSendToAllCarrier
        },
        getQuoteOtmTransporeonLink: {
            query: getQuoteOtmTransporeonLink,
            isLoading: getQuoteOtmTransporeonLinkIsLoading,
            cancel: cancelGetQuoteOtmTransporeonLink
        },
        getNettoBets: {
            query: getNettoBets,
            isLoading: getNettoBetsIsLoading,
            cancel: cancelGetNettoBets
        },
        sendTranspareonAccountRequest: {
            mutation: sendTranspareonAccountRequest,
            isLoading: sendTranspareonAccountRequestIsLoading,
            cancel: cancelSendTranspareonAccountRequest
        },
        getServiceProviders: {
            query: getServiceProviders,
            isLoading: getServiceProvidersIsLoading,
            cancel: cancelGetServiceProviders
        },
        getContracts: {
            query: getContracts,
            isLoading: getContractsIsLoading,
            cancel: cancelGetContracts
        },
        predictBruttoRate: {
            query: predictBruttoRate,
            isLoading: predictBruttoRateIsLoading,
            cancel: cancelPredictBruttoRate
        },
        getQuoteBillContacts: {
            query: getQuoteBillContacts,
            isLoading: getQuoteBillContactsIsLoading,
            cancel: cancelGetQuoteBillContacts
        },
        getProfit: {
            query: getProfit,
            isLoading: getProfitIsLoading,
            cancel: cancelGetProfit
        },
        savePriceInfo: {
            mutation: savePriceInfo,
            isLoading: savePriceInfoIsLoading,
            cancel: cancelSavePriceInfo
        }
    };
}