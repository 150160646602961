import React, {useEffect} from 'react';
import {Outlet} from "react-router-dom";
import cl from './AnonymousLayout.module.css';
import {Language} from "../../../app/enums/Language";
import useIfFirstRender from "../../../hooks/useIsFirstRender/useIfFirstRender";
import {useAppDispatch} from "../../../app/store";
import {setLanguage} from "../../../features/application/applicationSlice";
import {clearFilterSlice} from "../../../features/filter/filterSlice";
import {LANGUAGE_LOCAL_STORAGE_KEY} from "../../ui/LanguageSwitcher/LanguageSwitcher";

const AnonymousLayout: React.FC = () => {
    const isFirstRender = useIfFirstRender();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isFirstRender) {
            return;
        }

        try {
            let lngToUpdate = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY)
                ?? (navigator.language || navigator.userLanguage);

            if (lngToUpdate && lngToUpdate.length > 2) {
                lngToUpdate = lngToUpdate.slice(0, 2);
            }

            // When user anonymous, this call sets site language to EN or saved value in LS.
            dispatch(lngToUpdate === null || lngToUpdate === undefined
                ? setLanguage(Language.EN)
                : setLanguage(Language[lngToUpdate.toUpperCase() as keyof typeof Language]));

            dispatch(clearFilterSlice());
        }catch (e) {

        }
    }, [isFirstRender, dispatch]);

    return (
        <div className={cl.container}>
            <Outlet/>
        </div>
    )
};

export default AnonymousLayout;
