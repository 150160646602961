import React, {useEffect, useState} from 'react';
import BackgroundImageContainer from "../../../../../components/ui/BackgroundImageContainer/BackgroundImageContainer";
import bg_image from "../../../../../assets/images/login-bg.jpg";
import Container from "../../../../../components/ui/Container/Container";
import Card from "../../../../../components/ui/Card/Card";
import LanguageSwitcher from "../../../../../components/ui/LanguageSwitcher/LanguageSwitcher";
import CopyRightFooter from "../../../../../components/ui/CopyRightFooter/CopyRightFooter";
import {useTranslation} from "react-i18next";
import cl from './SelfBillingLogin.module.css';
import useMediaQuery from "../../../../../hooks/useMediaQuery/useMediaQuery";
import useDefaultSiteLanguages from "../../../../../hooks/useDefaultSiteLanguages/useDefaultSiteLanguages";
import {ILoginUser} from "../../../../../app/interfaces/login/ILogin";
import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {selectLogoutMessage} from "../../../../../features/application/applicationSliceSelectors";
import useIfFirstRender from "../../../../../hooks/useIsFirstRender/useIfFirstRender";
import useDocumentTitle from "../../../../../hooks/useDocumentTitle/useDocumentTitle";
import {toast} from "react-toastify";
import {setLogoutMessage} from "../../../../../features/application/applicationSlice";
import ComponentsSwitch from "../../../../../components/ui/ComponentsSwitch/ComponentsSwitch";
import SelfBillingLoginUserListContent from "./SelfBillingLoginUserListContent/SelfBillingLoginUserListContent";
import SelfBillingLoginFormContent from "./SelfBillingLoginFormContent/SelfBillingLoginFormContent";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {Tooltip} from "react-tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {navigate} from "@progress/kendo-react-buttons/dist/es/ListButton/utils/navigation";
import {useNavigate} from "react-router-dom";

type SwitchComponents = 'LoginFormContent' | 'LoginUserListContent';

const SelfBillingLogin: React.FC = () => {
    const [activeComponent, setActiveComponent] = useState<SwitchComponents>('LoginUserListContent');
    const [selectedUser, setSelectedUser] = useState<ILoginUser | undefined | null>();
    const {t} = useTranslation();
    const languages = useDefaultSiteLanguages();
    const isMaxWidth470 = useMediaQuery('(max-width:450px)');
    const isMaxHeight490 = useMediaQuery('(max-height:490px)');
    const logOutMessage = useAppSelector(selectLogoutMessage);
    const isFirstRender = useIfFirstRender();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    useDocumentTitle({title: t("login.labels.login-page-title")});

    useEffect(() => {
        if (logOutMessage && isFirstRender) {
            toast.warn<string>(logOutMessage);

            dispatch(setLogoutMessage(undefined));
        }
    }, [logOutMessage, dispatch, isFirstRender]);

    const changeComponent = (usr?: ILoginUser | null) => {
        if (activeComponent === 'LoginFormContent') {
            setActiveComponent('LoginUserListContent');
        } else if (activeComponent === 'LoginUserListContent') {
            setActiveComponent('LoginFormContent');
            setSelectedUser(usr);
        }
    }

    return (
        <BackgroundImageContainer image={bg_image}>
            <Container centerContent={true} height={'calc(100% - 30px)'}>
                <Card style={isMaxWidth470 ? {width: 'calc(100% - 20px)'} : {width: '450px'}}>
                    <div className={cl.languageSwitcherContainer}>
                        <LanguageSwitcher languages={languages} languageIconPosition={'right'}/>
                    </div>
                    <div className={cl.logoContainer}
                         style={isMaxHeight490 ? {display: 'none'} : undefined}>
                        <img className={'prevent-selection'}
                             src={'/images/logo_main.png'}
                             alt={'logo'}/>
                    </div>
                    <div className={`${cl.loginLabel} prevent-selection`}>
                        {t("login.labels.log-in")}
                    </div>
                    <ComponentsSwitch activeComponent={activeComponent}>
                        <SelfBillingLoginFormContent selectedUser={selectedUser}
                                                     componentName={'LoginFormContent'}/>
                        <SelfBillingLoginUserListContent componentName={'LoginUserListContent'}
                                                         changeComponent={changeComponent}/>
                    </ComponentsSwitch>
                    <div style={{    textAlign: 'center', padding: '10px'}}>
                        <Button buttonType={ButtonType.Secondary}
                                style={{minWidth: '250px'}}
                                onClick={() => navigate('/selfbilling/confirmation')}>
                            {t("self-billing.btn.only-self-billing")}
                        </Button>
                    </div>
                </Card>
            </Container>
            <CopyRightFooter/>
        </BackgroundImageContainer>
    );
};

export default SelfBillingLogin;
