import React from 'react';
import {ICmrBilling} from "../../../../../../../../app/interfaces/cmr/ICmrBilling";
import {useTranslation} from "react-i18next";

type SelfBillingDocumentListItemBillingSectionProps = {
    billing: ICmrBilling;
};

const SelfBillingDocumentListItemBillingSection: React.FC<SelfBillingDocumentListItemBillingSectionProps> = ({
                                                                                                                 billing
                                                                                                             }) => {
    const {t} = useTranslation();

    return (
        <div>
            <ul style={{marginLeft: '20px', marginTop: '5px'}}>

                {billing.pickUpDate !== null && billing.pickUpDate !== '' &&
                    <li>{`${t("shipment.form.early-pickup-date")} - ${billing.pickUpDate}`}</li>
                }
                {billing.deliveryDate !== null && billing.deliveryDate !== '' &&
                    <li>{`${t("shipment.form.late-delivery-date")} - ${billing.deliveryDate}`}</li>
                }
                {billing.truck !== null && billing.truck !== '' &&
                    <li>{`${t("shipment.form.truck")} - ${billing.truck}`}</li>
                }

                {billing.trailer !== null && billing.trailer !== '' &&
                    <li>{`${t("shipment.form.trailer")} - ${billing.trailer}`}</li>
                }

                {billing.finEmail !== null && billing.finEmail !== '' &&
                    <li>{`${t("self-billing.labels.financial-email-label")} - ${billing.finEmail}`}</li>
                }

                {billing.serviceProvider !== null && billing.serviceProvider !== '' &&
                    <li>{`${t("shipment.form.supplier")} - ${billing.serviceProvider}`}</li>
                }

                {(billing.bet !== null && billing.bet !== 0)
                    ? <li>{`${t("shipment.grid.cost-brutto-label")} - ${billing.bet} ${billing.currency}`}</li>
                    : null
                }
            </ul>
        </div>
    );
};

export default SelfBillingDocumentListItemBillingSection;
