import React, {useEffect} from 'react';
import cl from './QuoteFilterPanelCheckboxSection.module.css';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useAppSelector} from "../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../features/authentication/authenticationSliceSelectors";
import {useGridLayoutContext} from "../../../../../../../components/uiLayouts/GridLayout/hooks/useGridLayoutContext";
import {IQuoteHeaderConfig} from "../../../data/interfaces/IQuoteHeaderConfig";
import Checkbox, {CheckboxSize} from "../../../../../../../components/ui/Checkbox/Checkbox";

const QuoteFilterPanelCheckboxSection = () => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);
    const {type} = useParams();

    const {
        headerConfig,
        isHeaderDisabled,
        saveHeaderConfig,
    } = useGridLayoutContext<IQuoteHeaderConfig>();

    useEffect(() => {
        if (!type && !headerConfig) {
            return;
        }

        if (type !== 'active' && headerConfig?.subTab === 'quote-required-for-quotation-tab') {
            onChange({
                ...headerConfig,
                subTab: 'quote-all-tab'
            });
        }

        if ((type === 'expired' || type === 'clientSent') && (
            headerConfig?.subTab === 'quote-deadline-today-tab' ||
            headerConfig?.subTab === 'quote-deadline-2-tab'
        )) {
            onChange({
                ...headerConfig,
                subTab: 'quote-all-tab'
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, headerConfig]);

    const onChange = (config: IQuoteHeaderConfig) => {
        (async () => {
            await saveHeaderConfig(config);
        })();
    }

    if (!headerConfig || !user || (headerConfig.subTab === 'quote-required-for-quotation-tab' && !user.isHead && !user.isOfficeHeader)) {
        return null;
    }

    return (
        <div className={cl.container}>
            <div className={cl.checkboxesContainer}>
                <Checkbox checked={headerConfig.CM}
                          size={CheckboxSize.Medium}
                          label={t("shared.grid-header.cm")}
                          change={(val) => {
                              onChange({
                                  ...headerConfig,
                                  CM: val
                              });
                          }}
                          disabled={isHeaderDisabled}/>
                <Checkbox checked={headerConfig.TLM}
                          size={CheckboxSize.Medium}
                          label={t("shared.grid-header.tlm")}
                          change={(val) => {
                              onChange({
                                  ...headerConfig,
                                  TLM: val
                              });
                          }}
                          disabled={isHeaderDisabled}/>
                <Checkbox checked={headerConfig.SMFA}
                          size={CheckboxSize.Medium}
                          label={t("shared.grid-header.sm-fa")}
                          change={(val) => {
                              onChange({
                                  ...headerConfig,
                                  SMFA: val
                              });
                          }}
                          disabled={isHeaderDisabled}/>
                <Checkbox checked={headerConfig.SMEXA}
                          size={CheckboxSize.Medium}
                          label={t("shared.grid-header.sm-exa")}
                          change={(val) => {
                              onChange({
                                  ...headerConfig,
                                  SMEXA: val
                              });
                          }}
                          disabled={isHeaderDisabled}/>
                <Checkbox checked={headerConfig.OM}
                          size={CheckboxSize.Medium}
                          label={t("shared.grid-header.om")}
                          change={(val) => {
                              onChange({
                                  ...headerConfig,
                                  OM: val
                              });
                          }}
                          disabled={isHeaderDisabled}/>

            </div>
        </div>
    );
};

export default QuoteFilterPanelCheckboxSection;
