import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {SelectModel} from "../../../../../app/types/SelectModel";
import GridFilterPanelLayoutCollapse
    from "../../../../uiLayouts/GridFilterPanelLayout/components/GridFilterPanelLayoutCollapse/GridFilterPanelLayoutCollapse";
import cl from "../../AccessSettings/AccessSettings.module.css";
import ListBox from "../../../../ui/ListBox/ListBox";
import Icon from "../../../../../assets/icon/Icon";
import FilterPanelOfferTypeFilterSelectedContainer from "./FilterPanelOfferTypeFilterSelectedContainer";

type FilterPanelOfferTypeFilterProps = {
    disabled?: boolean;
    onClear: (value?: string) => void;
    onChange: (value: Array<string>) => void,
    value: Array<string>;
}

const FilterPanelOfferTypeFilter: React.FC<FilterPanelOfferTypeFilterProps> = ({
                                                                                   onClear,
                                                                                   onChange,
                                                                                   value,
                                                                                   disabled = false
                                                                               }) => {
    const {t} = useTranslation();

    const [statuses] = useState<Array<SelectModel>>([
        {text: "EDI", value: "EDI"},
        {text: "SPOT", value: "SPOT"},
        {text: "CONTRACT", value: "CONTRACT"}
    ]);

    const [filteredStatuses, setFilteredStatuses] = useState<Array<SelectModel>>(statuses);

    const onFilter = (value: string | null) => {
        if (!value || value === '') {
            setFilteredStatuses([...statuses]);
        } else {
            setFilteredStatuses([
                ...statuses.filter(item => {
                    return item.text.toLowerCase().includes(value.toLowerCase());
                })
            ]);
        }
    }

    return (
        <GridFilterPanelLayoutCollapse titleBold={false}
                                       defaultExpanded={false}
                                       showSeparationHeaderLine={false}
                                       hideOnFilterPanelLayoutContextClear={true}
                                       style={{margin: '5px 0 10px 0'}}
                                       tagsContainer={<FilterPanelOfferTypeFilterSelectedContainer id={`offer-type-filter-tags`}
                                                                                                   value={value}
                                                                                                   onClear={onClear}/>}
                                       title={
                                           <>
                                               <div>
                                                   {t("offer.grid.offer-type")}
                                               </div>
                                               <div>
                                                   {value.length > 0
                                                       ? <Icon icon={"faTrash"}
                                                               className={cl.trash}
                                                               onClick={(ev) => {
                                                                   ev.stopPropagation();

                                                                   if (disabled) {
                                                                       return;
                                                                   }

                                                                   onClear();
                                                               }}/>
                                                       : null
                                                   }
                                               </div>
                                           </>
                                       }>
            <div>
                <ListBox id={'offer-statuses-list-box'}
                         selected={value
                             ? value.map(item => {
                                 return {
                                     text: item,
                                     value: item
                                 }
                             })
                             : []}
                         disabled={disabled}
                         data={filteredStatuses}
                         isLoading={false}
                         onFilter={onFilter}
                         height={250}
                         selection={'Multiple'}
                         onSelectionChange={(items) => {
                             onChange(items.map(item => item.value));
                         }}/>
            </div>

        </GridFilterPanelLayoutCollapse>
    )
        ;
};

export default FilterPanelOfferTypeFilter;