import React from 'react';
import {UserRole} from "../../../../../../app/enums/UserRole";
import {IChatReplyTo} from "../../../../../../app/interfaces/chat/IChat";
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../ui/Dialog/Dialog";
import SelectUserChat from "./SelectUserChat";
import {IChatUser} from "../../../../../../app/interfaces/chat/IChatUser";

type SelectUserChatDialogProps = {
    onSelect: (data: IChatUser) => void;
    role: UserRole;
    ids: string;
    replyTo?: IChatReplyTo;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const SelectUserChatDialog: React.FC<SelectUserChatDialogProps> = ({
                                                                       ids,
                                                                       role,
                                                                       replyTo,
                                                                       onSelect,
                                                                       show,
                                                                       setShow
                                                                   }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'select-user-chat-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("chat.labels.select-user-chat")}>
            <SelectUserChat ids={ids}
                            role={role}
                            onSelect={onSelect}
                            replyTo={replyTo}
                            close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default SelectUserChatDialog;