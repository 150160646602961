import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import cl from "../DateTemplate/DateTemplate.module.css";
import ChangeEquipmentDialog from "../../../actions/ChangeEquipment/ChangeEquipmentDialog";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import Icon from "../../../../../../assets/icon/Icon";

const EquipmentTemplate = (props: any) => {
    const {t} = useTranslation();
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    let val = props.dataItem[props.field] !== undefined && props.dataItem[props.field] !== null
        ? props.dataItem[props.field]
        : '';

    const user = useAppSelector(selectCurrentUser);
    if (!user) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
            </td>
        )
    }

    const role = getUserRole(user);

    if (role === UserRole.ServiceProvider) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={'default-left-cell default-overflow-cell'}>
                    {props.children}
                </span>
            </td>
        );
    }

    return (
        <td {...props.tdProps}
            colSpan={1}>
            <ChangeEquipmentDialog id={props.dataItem.id}
                                   show={isDialogShown}
                                   setShow={setIsDialogShown}/>
            <span onClick={handleClick} className={'default-left-cell default-overflow-cell grid-prevent-selection'}>
                <>

             <Icon icon={"faPen"} className={`${cl.icon} ${val && val !== '' ? cl.iconWithValue : ''} offer-grid-edit-${props.field}-btn grid-prevent-selection`}
                   onClick={(ev) => {
                       ev.stopPropagation();

                       setIsDialogShown(prev => !prev);
                   }}/>

                     <span className={`grid-prevent-selection ${cl.text}`}
                           onClick={(ev) => {
                               ev.stopPropagation();

                               setIsDialogShown(prev => !prev);
                           }}>
                            {val && val !== ''
                                ? <>{val}</>
                                : <span className={cl.addText}>{t("offer.grid.change-equipment")}</span>
                            }
                     </span>
                </>
            </span>
        </td>
    );
};

export default EquipmentTemplate;
