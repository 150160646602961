import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {matchPath, useLocation, useNavigate, useParams} from "react-router-dom";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {useGridLayoutContext} from "../../../../../../components/uiLayouts/GridLayout/hooks/useGridLayoutContext";
import useGridExport from "../../../../../../components/ui/Grid/Telerik/hooks/useGridExport";
import {
    GridActionRowButtonProps
} from "../../../../../../components/ui/Grid/Telerik/components/GridActionRow/components/GridActionRowButton/GridActionRowButton";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import moment from "moment/moment";
import HardGridActionRow, {
    GridActionRowSelectorProps
} from "../../../../../../components/ui/Grid/Telerik/components/HardGridActionRow/HardGridActionRow";
import {IQuoteHeaderConfig} from "../../data/interfaces/IQuoteHeaderConfig";
import GridColumnsConfigurationSetupDialog
    from "../../../../../../components/ui/Grid/Telerik/components/GridColumnsConfigurationSetupDialog/GridColumnsConfigurationSetupDialog";
import {LayoutConfigEntity} from "../../../../../../app/enums/LayoutConfigEntity";
import {buildQuoteGridLabel} from "../../utils";
import QuoteActionRowActionButton from "./QuoteActionRowActionButton/QuoteActionRowActionButton";
import QuoteActionRowTags from "./QuoteActionRowTags/QuoteActionRowTags";
import getQuoteInitialGridConfig from "../../data/columns";

const QuoteActionRow: React.FC = () => {
    const {t, i18n} = useTranslation();
    const {type} = useParams();

    const location = useLocation();
    const navigate = useNavigate();

    const user = useAppSelector(selectCurrentUser);

    const [isSetupDialogVisible, setIsSetupDialogVisible] = useState<boolean>(false);

    const {
        refresh,
        defaultGridConfig,
        gridConfig,
        headerConfig,
        isHeaderDisabled,
        saveHeaderConfig,
        reloadGridConfig,
    } = useGridLayoutContext<IQuoteHeaderConfig>();

    const onChange = (config: IQuoteHeaderConfig) => {
        (async () => {
            await saveHeaderConfig(config);
        })();
    }

    const {
        download
    } = useGridExport({
        gridId: 'quote-grid',
        config: gridConfig ?? defaultGridConfig,
        useSelectedRowToExport: true
    });

    const buildButtonList = useCallback((): Array<GridActionRowButtonProps> => {
        if (!headerConfig || !user) {
            return [];
        }

        let userRole = getUserRole(user);
        let res: Array<GridActionRowButtonProps> = [];

        if (userRole !== UserRole.ServiceProvider && userRole !== UserRole.Client) {
            res.push({
                id: 'quote-export',
                title: t("shared.grid-header.btn-export"),
                disabled: isHeaderDisabled,
                onClick: async () => {
                    await download(`Quote_${moment().format('DD_MM_YYYY_HH_mm')}.xlsx`);
                },
                icon: "faUpload"
            });
        }

        res.push({
            id: 'quote-table-setup',
            title: t("shared.grid-header.btn-table-setup"),
            disabled: isHeaderDisabled,
            onClick: () => setIsSetupDialogVisible(prev => !prev),
            icon: "faGear"
        });

        res.push({
            id: 'quote-refresh',
            title: t("shared.grid-header.btn-refresh"),
            disabled: isHeaderDisabled,
            onClick: () => refresh(),
            icon: "faArrowsRotate"
        });

        return res;
    }, [i18n.language, isHeaderDisabled, headerConfig, user]);

    const buildSelectorsList = useCallback((): Array<GridActionRowSelectorProps> => {
        if (!headerConfig || !user) {
            return [];
        }

        let res: Array<GridActionRowSelectorProps> = [];

        res.push({
            selector: {
                disabled: isHeaderDisabled,
                width: 150,
                onChange: (item) => {
                    onChange({
                        ...headerConfig,
                        subTab: item.id
                    });
                }
            },
            options: [
                {
                    id: 'quote-all-tab',
                    text: t("quote.header.all-tab"),
                    selected: headerConfig.subTab === 'quote-all-tab'
                },
                {
                    id: 'quote-my-quotes-tab',
                    text: t("quote.header.my-quotes"),
                    selected: headerConfig.subTab === 'quote-my-quotes-tab'
                }
            ]
        });

        return res;
    }, [i18n.language, isHeaderDisabled, user, headerConfig, type]);


    const buildMenuSelectorsList = useCallback((): GridActionRowSelectorProps | string => {
        if (!headerConfig || !user) {
            return t(buildQuoteGridLabel(type));
        }

        return {
            selector: {
                disabled: isHeaderDisabled,
                width: 200,
                onChange: (item) => {
                    if (!item.description) {
                        return;
                    }

                    navigate(item.description);
                }
            },
            options: [
                {
                    id: 'quote-category-menu-active',
                    text: t("sidebar.quote.all"),
                    selected: matchPath('/quotes/active', location.pathname) !== null,
                    tooltip: t("sidebar.quote.all-tooltip"),
                    description: '/quotes/active'
                },
                {
                    id: 'quote-category-menu-new',
                    text: t("sidebar.quote.new"),
                    selected: matchPath('/quotes/new', location.pathname) !== null,
                    tooltip: t("sidebar.quote.new-tooltip"),
                    description: '/quotes/new'
                },
                {
                    id: 'quote-category-menu-sent',
                    text: t("sidebar.quote.sent-to-carrier"),
                    selected: matchPath('/quotes/sent', location.pathname) !== null,
                    tooltip: t("sidebar.quote.sent-to-carrier-tooltip"),
                    description: '/quotes/sent'
                },
                {
                    id: 'quote-category-menu-bet',
                    text: t("sidebar.quote.bet"),
                    selected: matchPath('/quotes/bet', location.pathname) !== null,
                    tooltip: t("sidebar.quote.bet-tooltip"),
                    description: '/quotes/bet'
                },
                {
                    id: 'quote-category-menu-expired',
                    text: t("sidebar.quote.expired"),
                    selected: matchPath('/quotes/expired', location.pathname) !== null,
                    tooltip: t("sidebar.quote.expired-tooltip"),
                    description: '/quotes/expired'
                },
                {
                    id: 'quote-category-menu-customer-sent',
                    text: t("sidebar.quote.bet-sent-to-customer"),
                    selected: matchPath('/quotes/clientSent', location.pathname) !== null,
                    tooltip: t("sidebar.quote.bet-sent-to-customer-tooltip"),
                    description: '/quotes/clientSent'
                },
            ]
        };
    }, [i18n.language, isHeaderDisabled, user, location.pathname, type]);

    if (!headerConfig || !user || !type) {
        return null;
    }

    return (
        <>
            <HardGridActionRow id={'quote-action-row'}
                               title={buildMenuSelectorsList()}
                               buttons={buildButtonList()}
                               actionButton={<QuoteActionRowActionButton/>}
                               selectors={buildSelectorsList()}>
                {(props) =>
                    <QuoteActionRowTags availableWidth={props.availableWidth}/>
                }
            </HardGridActionRow>

            <GridColumnsConfigurationSetupDialog show={isSetupDialogVisible}
                                                 onSaved={async () => {
                                                     setIsSetupDialogVisible(prev => !prev);

                                                     await reloadGridConfig();
                                                 }}
                                                 setShow={setIsSetupDialogVisible}
                                                 entity={LayoutConfigEntity.Quote}
                                                 defaultGridConfig={getQuoteInitialGridConfig(getUserRole(user))}/>
        </>
    );
}

export default QuoteActionRow;