import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import ClientStatus from "./ClientStatus";

type ClientStatusDialogProps = {
    id: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ClientStatusDialog: React.FC<ClientStatusDialogProps> = ({
                                                                   id,
                                                                   show,
                                                                   setShow
                                                               }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'client-status-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.labels.customer-status")}>
            <ClientStatus id={id}/>
        </Dialog>
    );
};

export default ClientStatusDialog;