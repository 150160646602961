import React from 'react';
import {useTranslation} from "react-i18next";

type TransportHandlingUnitProps = {
    transportHandlingUnit: string | null;
}

const TransportHandlingUnit: React.FC<TransportHandlingUnitProps> = ({
                                                                         transportHandlingUnit
                                                                     }) => {
    const {t} = useTranslation();

    return (
        <>
            <label className={'transport-handling-unit-label'}>
                {t('shipment.form.transport-handling-unit')}
            </label>
            <span className={'details-box-item-field-value transport-handling-unit-value'}>
                {transportHandlingUnit !== null && transportHandlingUnit !== ''
                    ? <>{transportHandlingUnit}</>
                    : <>{t("shared.labels.no-data")}</>
                }
            </span>
        </>
    );
};

export default TransportHandlingUnit;