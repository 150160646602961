import React from 'react';
import cl from './ShipmentsEventListItemProgressBar.module.css';
import {buildShipmentEventList} from "../../utils";
import {ExtendedEvent} from "../../ShipmentsEventList";
import ShipmentsEventListItemProgressBarItem
    from "./ShipmentsEventListItemProgressBarItem/ShipmentsEventListItemProgressBarItem";
import {chunk} from "../../../../../../../helpers/array";

type ShipmentsEventListItemProgressBarProps = {
    event: ExtendedEvent;
    change: (event: ExtendedEvent) => void;
    disabled: boolean;
}
const ShipmentsEventListItemProgressBar: React.FC<ShipmentsEventListItemProgressBarProps> = ({
                                                                                                 event,
                                                                                                 change,
                                                                                                 disabled
                                                                                             }) => {
    if (!event.shipmentEvents) {
        return null;
    }

    return (
        <div className={cl.container}>
            {
                [
                    ...chunk(
                        buildShipmentEventList(event, event.showAllShipmentEvents).filter(e => !e.isHidden),
                        5
                    )
                ]
                    .map((chunk, index) => {
                        return (
                            <div key={index}>
                                {chunk.map(item => {
                                    return (
                                        <ShipmentsEventListItemProgressBarItem key={item.sortPosition}
                                                                               event={event}
                                                                               shipmentEvent={item}
                                                                               change={change}
                                                                               disabled={disabled}/>
                                    );
                                })}
                            </div>
                        );
                    })
            }
        </div>
    );
};

export default ShipmentsEventListItemProgressBar;