import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import RefuseBet from "./RefuseBet";

type RefuseBetDialogProps = {
    betId: number;
    orderReleaseGid: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const RefuseBetDialog: React.FC<RefuseBetDialogProps> = ({
                                                             betId,
                                                             orderReleaseGid,
                                                             show,
                                                             setShow
                                                         }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'refuse-bet-offer-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.btn.reject")}>
            <RefuseBet betId={betId}
                       orderReleaseGid={orderReleaseGid}
                       close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default RefuseBetDialog;