import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {isAxiosError} from "axios";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {IChangeDateGetAction} from "../../../../../app/interfaces/offer/IChangeDateGetAction";
import {useOfferApi} from "../../../../../app/api/offer";
import {IChangeDatePostAction} from "../../../../../app/interfaces/offer/IChangeDatePostAction";
import Alert from "../../../../../components/ui/Alert/Alert";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import ChangePlannedDateForm from "./ChangePlannedDateForm/ChangePlannedDateForm";

type ChangePlannedDateProps = {
    id: string;
    close?: () => void;
}

const ChangePlannedDate: React.FC<ChangePlannedDateProps> = ({
    id,
    close
                                                             }) => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [model, setModel] = useState<IChangeDateGetAction | null>(null);
    const [errors, setErrors] = useState<Array<IError>>([]);

    const {
        getChangeDateData: {
            query: getChangeDateData,
            isLoading,
            cancel
        },
        changePlannedDate: {
            mutation: changePlannedDate,
            cancel: cancelChangePlannedDate
        }
    } = useOfferApi();

    useEffect(() => {
        if (id === '') {
            if (close) {
                close()
            } else {
                return;
            }
        }

        (async () => {
            try {
                const response = await getChangeDateData(id);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch {
                if (close) {
                    close();
                } else {
                    toast.error<string>('Unable to get change date data');
                }
            }
        })();

        return () => {
            cancel();
            cancelChangePlannedDate();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const save = async (model: IChangeDatePostAction): Promise<void> => {
        setDisabled(prev => !prev);
        setErrors([]);

        try {
            const response = await changePlannedDate(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [
                            {
                                id: id,
                                isRowLoading: true
                            }
                        ]
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.offer")} ${id}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();
                    toast.error(err.message);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }


    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                {model.dtItems.length <= 0
                    ? <Alert type={'Error'} style={{fontWeight: 'bold'}}
                             id={'change-planned-date-no-dates-to-change-error'}>
                        {t("offer.warnings.no-dates-to-change")}
                    </Alert>
                    : <ChangePlannedDateForm model={model}
                                             save={save}
                                             disabled={disabled}
                                             errors={errors}/>
                }
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                {model.dtItems.filter(e => !e.isLocked).length > 0 &&
                    <Button buttonType={ButtonType.Primary}
                            disabled={disabled}
                            form='offer-change-planned-date-form'
                            type='submit'
                            style={{minWidth: '150px'}}
                            className={'confirm-button'}
                            isLoading={disabled}>
                        {t("shared.btn.submit")}
                    </Button>
                }
            </div>
        </div>
    );
};

export default ChangePlannedDate;