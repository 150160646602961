import React, {useEffect, useState} from 'react';
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {useTranslation} from "react-i18next";
import {IChangeDateGetAction} from "../../../../../app/interfaces/shipment/IChangeDateGetAction";
import {useShipmentApi} from "../../../../../app/api/shipment";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {toast} from "react-toastify";
import Alert from "../../../../../components/ui/Alert/Alert";
import ChangePlannedDateForm from "./ChangePlannedDateForm/ChangePlannedDateForm";
import {isAxiosError} from "axios";
import {IChangeDatePostAction} from "../../../../../app/interfaces/shipment/IChangeDatePostAction";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {IUser} from "../../../../../app/interfaces/user/IUser";

type ChangePlannedDateProps = {
    id: string;
    sId: string;
    close?: () => void;
    user?: IUser;
}

const ChangePlannedDate: React.FC<ChangePlannedDateProps> = ({
                                                                 close,
                                                                 id,
                                                                 sId,
                                                                 user
                                                             }) => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [model, setModel] = useState<IChangeDateGetAction | null>(null);
    const [errors, setErrors] = useState<Array<IError>>([]);

    const {
        getChangeDateData: {
            query: getChangeDateData,
            isLoading: getChangeDateDataIsLoading,
            cancel
        },
        changeDate: {
            mutation: changeDate,
            cancel: cancelChangeDate
        },

        getChangeDateDataAnonymous: {
            query: getChangeDateDataAnonymous,
            isLoading: getChangeDateDataAnonymousIsLoading,
            cancel: cancelGetChangeDateDataAnonymous
        },
        changeDateAnonymous: {
            mutation: changeDateAnonymous,
            cancel: cancelChangeDateAnonymous
        }
    } = useShipmentApi();

    useEffect(() => {
        if (id === '' || sId === '') {
            if (close) {
                close()
            } else {
                return;
            }
        }

        (async () => {
            try {
                const response = user
                    ? await getChangeDateData(id, sId)
                    : await getChangeDateDataAnonymous(id, sId);

                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch {
                if (close) {
                    close();
                } else {
                    toast.error<string>('Unable to get change date data');
                }
            }
        })();

        return () => {
            cancel();
            cancelChangeDate();
            cancelGetChangeDateDataAnonymous();
            cancelChangeDateAnonymous();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sId, id]);

    const save = async (model: IChangeDatePostAction): Promise<void> => {
        setDisabled(prev => !prev);
        setErrors([]);

        try {
            const response = user
                ? await changeDate(model)
                : await changeDateAnonymous(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [
                            {
                                id: sId,
                                isRowLoading: true
                            }
                        ]
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.shipment")} ${sId}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();
                    toast.error(err.message);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if (getChangeDateDataAnonymousIsLoading || getChangeDateDataIsLoading || model === null) {
        return (
            <FormLoadingScreen height={user ? `200px` : '100%'} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                {model.dtItems.length <= 0
                    ? <Alert type={'Error'} style={{fontWeight: 'bold'}}
                             id={'change-planned-date-no-dates-to-change-error'}>
                        {t("shipment.warnings.no-dates-to-change")}
                    </Alert>
                    : <ChangePlannedDateForm model={model}
                                             save={save}
                                             disabled={disabled}
                                             errors={errors}/>
                }
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                {/*{model.dtItems.filter(e => !e.isLocked).length > 0 &&*/
                    <Button buttonType={ButtonType.Primary}
                            disabled={disabled}
                            form='change-date-form'
                            type='submit'
                            style={{minWidth: '150px'}}
                            className={'confirm-button'}
                            isLoading={disabled}>
                        {t("shared.btn.submit")}
                    </Button>
                }
            </div>
        </div>
    );
};

export default ChangePlannedDate;
