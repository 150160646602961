import React from 'react';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {useTranslation} from "react-i18next";
import {useChatHelperContext} from "../../../../hooks/useChatHelperContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faArchive, faBuilding, faEnvelope, faMobile} from "@fortawesome/free-solid-svg-icons";
import {useAppSelector} from "../../../../../../../../app/store";
import {selectChatUserOnlineStatus} from "../../../../../../../../features/chat/chatSliceSelectors";
import ChatAvatar from "../../../ChatAvatar/ChatAvatar";

const ChatDialogueInformation = () => {
    const {t} = useTranslation();

    const {
        isMobileView,
        disabled,
        chat,
        setIsUserInformationSectionShown,
        hideChat
    } = useChatHelperContext();

    let isOnline = useAppSelector(st => selectChatUserOnlineStatus(st, {
        userId: chat?.id ?? ''
    }));

    return (
        <SkeletonTheme baseColor={'#eaeff3'} highlightColor={'#ffffff'}>
            <div className={'chat-dialogue-information-container'}>
                <div className={`chat-dialogue-information-header relative`}>
                    {isMobileView &&
                        <FontAwesomeIcon icon={faAngleLeft}
                                         className={'chat-dialogue-information-back'}
                                         onClick={() => {
                                             if (disabled) {
                                                 return;
                                             }

                                             setIsUserInformationSectionShown(false)
                                         }}/>
                    }

                    <ChatAvatar isOnline={isOnline}
                                onVacation={chat?.isAway ?? false}
                                size={'Big'}/>
                    <div>
                        {chat
                            ? <> {chat.name}</>
                            : <Skeleton height={'15px'}
                                        width={'50%'}
                                        style={{borderRadius: '2px'}}/>
                        }
                    </div>
                </div>

                <div>
                    {!chat && disabled &&
                        <>
                            <Skeleton height={'15px'}
                                      width={'96%'}
                                      style={{
                                          borderRadius: '2px',
                                          margin: '10px 5px'
                                      }}/>

                            <Skeleton height={'15px'}
                                      width={'96%'}
                                      style={{
                                          borderRadius: '2px',
                                          margin: '10px 5px'
                                      }}/>

                            <Skeleton height={'15px'}
                                      width={'96%'}
                                      style={{
                                          borderRadius: '2px',
                                          margin: '10px 5px'
                                      }}/>
                        </>
                    }
                    {chat && chat.firm !== null && chat.firm !== '' &&
                        <div className={'chat-dialogue-information-row'}>
                            <div>
                                <FontAwesomeIcon icon={faBuilding}/>
                            </div>
                            <div>
                                {chat.firm}
                            </div>
                        </div>
                    }

                    {chat && chat.phone !== null && chat.phone !== '' &&
                        <div className={'chat-dialogue-information-row'}>
                            <div>
                                <FontAwesomeIcon icon={faMobile}/>
                            </div>
                            <div>
                                {chat.phone}
                            </div>
                        </div>
                    }

                    {chat && chat.email !== null && chat.email !== '' &&
                        <div className={'chat-dialogue-information-row'}>
                            <div>
                                <FontAwesomeIcon icon={faEnvelope}/>
                            </div>
                            <div>
                                {chat.email}
                            </div>
                        </div>
                    }
                </div>

                <div className={'chat-dialogue-information-btns'}>
                    <button disabled={disabled}
                            className={`chat-dialogue-information-btn transition-all-500`}
                            title={t("chat.labels.archive-chat")}
                            onClick={() => {
                                if (disabled || !chat) {
                                    return;
                                }

                                hideChat(chat);
                            }}>
                        <FontAwesomeIcon icon={faArchive}/>
                    </button>
                </div>
            </div>
        </SkeletonTheme>
    );
};

export default ChatDialogueInformation;