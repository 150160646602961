import React from 'react';
import {IQuote} from "../../../../../../../../app/interfaces/quote/IQuote";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../../features/authentication/authenticationSliceSelectors";
import moment from "moment/moment";
import cl from './QuoteRecallDate.module.css';

type QuoteRecallDateProps = {
    quote: IQuote;
}

const QuoteRecallDate: React.FC<QuoteRecallDateProps> = ({
                                                             quote
                                                         }) => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    if (!user) {
        return null;
    }

    if (quote.recallDate === null) {
        return (
            <div className={'details-box-item-field-value'}>
                {" "}
            </div>
        );
    }

    let isWarn = moment.utc(quote.recallDate).toDate() <= moment().utc().toDate();

    return (
        <div className={`details-box-item-field-value ${isWarn ? cl.warn : cl.info}`}>
            <span >
                {moment.utc(quote.recallDate).local().format('DD.MM.YYYY HH:mm')}
            </span>
        </div>
    );
};

export default QuoteRecallDate;
