import React, {useEffect, useState} from 'react';
import {useShipmentApi} from "../../../../../app/api/shipment";
import {BLErrorCode} from "../../../../../app/enums/BLErrorCode";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import Alert from "../../../../../components/ui/Alert/Alert";
import {isAxiosError} from "axios";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {useTranslation} from "react-i18next";
import {ApplicationHubMessageAction} from "../../../../../app/signalR/applicationHub/IApplicationHubMessage";

type SPUnblockProps = {
    id: string;
    close?: () => void;
}

const alertStyle = {
    minHeight: '40px',
    fontSize: '12px',
    fontWeight: 'bold'
} as React.CSSProperties;

const SPUnblock: React.FC<SPUnblockProps> = ({
                                           id,
                                           close
                                       }) => {
    const {t} = useTranslation();

    const {
        getSPUnblockLink: {
            isLoading,
            cancel,
            query: getSPUnblockLink
        }
    } = useShipmentApi();

    useEffect(() => {
        if (!id || id === '') {
            return;
        }


        (async () => {
            try {
                const response = await getSPUnblockLink(id);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    if (close) {
                        close();
                    }
                    window.open(response.data.data, '_blank');
                }
            } catch (err) {
            }
        })();

        return () => {
            cancel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (isLoading) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return null;
};

export default SPUnblock;
