import React from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import Alert from "../../../../components/ui/Alert/Alert";
import QuoteForm from "./QuoteForm";

const QuoteFormPage: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {id} = useParams();

    if (!id) {
        return (
            <div className={'w100 h100'}>
                <Alert type={'Error'}>
                    {t("shared.errors.server-error")}
                </Alert>
            </div>
        );
    }

    return <QuoteForm id={id}
                      close={() => navigate('/quotes/active')}
                      isFullPageDetails={true}/>;
};

export default QuoteFormPage;