import React, {useState} from 'react';
import {IOffer} from "../../../../../../../../app/interfaces/offer/IOffer";
import ChangeRefNumDialog from "../../../../../actions/ChangeRefNum/ChangeRefNumDialog";
import Icon from "../../../../../../../../assets/icon/Icon";

type OfferCustomerOrderRefNumberProps = {
    offer: IOffer;
};

const OfferCustomerOrderRefNumber: React.FC<OfferCustomerOrderRefNumberProps> = ({
                                                                                     offer
                                                                                 }) => {
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);

    return (
        <div className={'details-box-item-field-edit-value'}>
            <>
                <ChangeRefNumDialog id={offer.orderReleaseGid}
                                    show={isDialogShown}
                                    setShow={setIsDialogShown}/>



                {offer.customerOrderRefNumber !== null && offer.customerOrderRefNumber !== ''
                    ? <><Icon icon={"faPen"}
                              className={'details-box-item-field-gray-icon'}
                              style={{marginRight: '5px'}}
                              onClick={() => setIsDialogShown(prev => !prev)}/><span style={{color:'#1b1b1b'}}>{offer.customerOrderRefNumber}</span></>
                    : <Icon icon={"faPen"}
                            className={'details-box-item-field-icon'}
                            style={{marginRight: '5px'}}
                            onClick={() => setIsDialogShown(prev => !prev)}/>
                }
            </>
        </div>
    );
};

export default OfferCustomerOrderRefNumber;
