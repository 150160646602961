import React, {useState} from 'react';
import {IShipment} from "../../../../../../../app/interfaces/shipment/IShipment";
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../../../components/ui/Dialog/Dialog";

type DriverProps = {
    shipment: IShipment;
}

const Driver: React.FC<DriverProps> = ({
                                           shipment
                                       }) => {
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);
    const {t} = useTranslation();

    return (
        <>
            <Dialog id={'driver-dialog'}
                    show={isDialogShown}
                    setShow={setIsDialogShown}
                    title={t(" ")}>
                <div style={{height: '350px', width: '650px'}}>
                    Driver
                </div>
            </Dialog>

            <label className={'driver-label'}>{t('shipment.form.driver')}</label>
            <span className={'details-box-item-field-value driver-value'}>
                {shipment.driver !== null && shipment.driver.name !== null && shipment.driver.name !== ''
                    ? <span /*className={'details-box-field-edit-text'}
                            onClick={() => setIsDialogShown(prev => !prev)}*/
                    >
                        {shipment.driver.name}
                </span>
                    : <>{t("shared.labels.no-data")}</>
                }
            </span>
        </>
    );
};

export default Driver;
