import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import RestorePasswordApplication from "./RestorePasswordApplication/RestorePasswordApplication";
import RestorePasswordAction from "./RestorePasswordAction/RestorePasswordAction";
import useDocumentTitle from "../../../../hooks/useDocumentTitle/useDocumentTitle";
import {useTranslation} from "react-i18next";


const RestorePassword: React.FC = () => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();

    const [code, setCode] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useDocumentTitle({title: t("login.restore.restore-label")});

    useEffect(() => {

        try {

            if (searchParams.has('code')) {
                //setCode(encodeURIComponent(searchParams.get('code')!));
                setCode(searchParams.get('code'));
            }
        } catch (ex) {

        }
        setIsLoading(false);
    }, []);

    if (isLoading) {
        return null;
    }

    return (
        <>
            {!code
                ? <RestorePasswordApplication/>
                : <RestorePasswordAction code={code}/>
            }
        </>
    );
};

export default RestorePassword;
