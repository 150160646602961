import {FilterPanelConfig} from "../../../../app/types/FilterPanelConfig";
import {useAppDispatch, useAppSelector} from "../../../../app/store";
import {selectFilterPanelConfiguration} from "../../../../features/filter/filterSliceSelectors";
import {useCallback, useEffect} from "react";
import useIfFirstRender from "../../../../hooks/useIsFirstRender/useIfFirstRender";
import {LayoutConfigEntity} from "../../../../app/enums/LayoutConfigEntity";
import {ResponseResultCode} from "../../../../app/enums/ResponseResultCode";
import {setFilterPanelConfiguration} from "../../../../features/filter/filterSlice";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {useLayoutConfigApi} from "../../../../app/api/layoutConfig";

type useGridFilterPanelLayoutConfigurationProps = {
    onInitialParametersSet?: (isShown: boolean) => void;
    entity: LayoutConfigEntity;
}

type useGridFilterPanelLayoutConfigurationReturn = {
    saveConfiguration: (config: FilterPanelConfig) => Promise<void>;
    configuration: FilterPanelConfig;
    isFilterPanelShown: boolean;
}

export const useGridFilterPanelLayoutConfiguration = ({
                                                          onInitialParametersSet
                                                      }: useGridFilterPanelLayoutConfigurationProps): useGridFilterPanelLayoutConfigurationReturn => {
    const layoutConfig = useAppSelector(selectFilterPanelConfiguration);
    const configuration = JSON.parse(layoutConfig.value) as FilterPanelConfig;
    const dispatch = useAppDispatch();
    const isFirstRender = useIfFirstRender();

    const {
        saveLayoutConfig: {
            mutation
        }
    } = useLayoutConfigApi();

    useEffect(() => {
        if (!isFirstRender) {
            return;
        }

        if (onInitialParametersSet) {
            onInitialParametersSet(configuration.isFilterPanelShown);
        }
    }, [configuration, layoutConfig, isFirstRender]);

    const saveConfiguration = useCallback(async (config: FilterPanelConfig) => {
        if (configuration.isFilterPanelShown === config.isFilterPanelShown) {
            return;
        }

        try {
            let item = {
                ...layoutConfig,
                value: JSON.stringify({
                    ...configuration,
                    isFilterPanelShown: config.isFilterPanelShown
                } as FilterPanelConfig)
            };

            const response = await mutation(item);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (response.data.field) {
                    dispatch(setFilterPanelConfiguration({
                        ...item,
                        id: Number(response.data.field)
                    }));
                }
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error('Unable to save filter panel state, ' + e.message);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutConfig, configuration]);

    return {
        configuration,
        saveConfiguration,
        isFilterPanelShown: configuration.isFilterPanelShown
    };
}