import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import CancelShipment from "./CancelShipment";

type CancelShipmentDialogProps = {
    id: string | Array<string>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const CancelShipmentDialog: React.FC<CancelShipmentDialogProps> = ({
                                                                       id,
                                                                       show,
                                                                       setShow
                                                                   }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'cancel-shipment-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.btn.cancel-order")}>
            <CancelShipment id={id}
                            close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default CancelShipmentDialog;