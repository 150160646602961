import React from 'react';
import {SelectModel} from "../../../../../app/types/SelectModel";
import cl from "../../AccessSettings/AccessSettings.module.css";
import GridFilterPanelLayoutCollapse
    from "../../../../uiLayouts/GridFilterPanelLayout/components/GridFilterPanelLayoutCollapse/GridFilterPanelLayoutCollapse";
import FilterPanelRegionFilterContent from "./FilterPanelRegionFilterContent";
import Icon from "../../../../../assets/icon/Icon";
import FilterPanelRegionFilterSelectedContainer from "./FilterPanelRegionFilterSelectedContainer";

type FilterPanelRegionFilterProps = {
    id: string;
    title: string;
    disabled?: boolean;
    onClear: (value?: string) => void;
    onChange: (value: Array<SelectModel>) => void,
    value: Array<SelectModel>;
    defaultExpanded?: boolean;
}

const FilterPanelRegionFilter: React.FC<FilterPanelRegionFilterProps> = ({
                                                                             id,
                                                                             title,
                                                                             value,
                                                                             onClear,
                                                                             onChange,
                                                                             disabled = false,
                                                                             defaultExpanded = false
                                                                         }) => {
    return (
        <GridFilterPanelLayoutCollapse titleBold={false}
                                       defaultExpanded={defaultExpanded}
                                       showSeparationHeaderLine={false}
                                       hideOnFilterPanelLayoutContextClear={true}
                                       style={{margin: '5px 0 10px 0'}}
                                       tagsContainer={<FilterPanelRegionFilterSelectedContainer id={`${id}-tags`}
                                                                                                value={value}
                                                                                                onClear={onClear}/>}
                                       title={
                                           <>
                                               <div>
                                                   {title}
                                               </div>
                                               <div>
                                                   {value.length > 0
                                                       ? <Icon icon={"faTrash"}
                                                               className={cl.trash}
                                                               onClick={(ev) => {
                                                                   ev.stopPropagation();

                                                                   if (disabled) {
                                                                       return;
                                                                   }

                                                                   onClear();
                                                               }}/>
                                                       : null
                                                   }
                                               </div>
                                           </>
                                       }>
            {({isExpanded}) =>
                <div>
                    {isExpanded
                        ? <FilterPanelRegionFilterContent id={id}
                                                          disabled={disabled}
                                                          onChange={onChange}
                                                          value={value}/>
                        : <div style={{height: '250px'}}>
                        </div>
                    }
                </div>
            }
        </GridFilterPanelLayoutCollapse>
    );
};

export default FilterPanelRegionFilter;