import React, {useState} from 'react';
import SidebarButton from "../../../components/SidebarButton/SidebarButton";
import {useTranslation} from "react-i18next";
import ProfileSupportDialog
    from "../../../../../../pages/authorized/Profile/Support/ProfileSupportDialog/ProfileSupportDialog";

const SidebarApplicationSectionSupport: React.FC = () => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState<boolean>(false);

    return (
        <>
            <ProfileSupportDialog show={isOpened}
                                  setShow={setIsOpened}/>

            <SidebarButton id={'sidebar-support-item'}
                           type={'secondary'}
                           text={t("header.support")}
                           title={t("header.support")}

                           active={isOpened}
                           onClick={() => setIsOpened(prev => !prev)}/>
        </>
    );
};

export default SidebarApplicationSectionSupport;
