import React, {useEffect, useState} from 'react';
import {IOffer} from "../../../../../../../app/interfaces/offer/IOffer";
import {BetStatus} from "../../../../../../../app/enums/BetStatus";
import cl from './ForwarderOfferBets.module.css';
import ForwarderOfferBet from "./ForwarderOfferBet/ForwarderOfferBet";
import {IBetAction} from "../../../../../../../app/interfaces/quote/IBetAction";

type ForwarderOfferBetsProps = {
    offer: IOffer;
};

const ForwarderOfferBets: React.FC<ForwarderOfferBetsProps> = ({
                                                                   offer
                                                               }) => {
    const [bets, setBets] = useState<Array<IBetAction>>([]);
    const [minBet, setMinBet] = useState<IBetAction | null>(null);

    useEffect(() => {
        if (offer.sortedBets.length <= 0) {
            return;
        }

        let item = offer.sortedBets.filter(e => e.status === BetStatus.New)
            .sort((a, b) => {
                return a.valueBase - b.valueBase;
            })[0];

        setMinBet({
            ...item
        });

        setBets([...offer.sortedBets.map(item => {
            return {
                ...item,
                isHistoryShow: false
            }
        }).sort((a, b) => {
            return a.valueBase - b.valueBase;
        })]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (offer.sortedBets.length <= 0) {
        return null;
    }

    return (
        <div className={cl.container}>
            {bets.map((item, index) => {
                return <ForwarderOfferBet key={`bet-${item.betId}-${index}`}
                                          offer={offer}
                                          bet={item}
                                          minBet={minBet}
                                          index={index}/>
            })}
        </div>
    );
};

export default ForwarderOfferBets;