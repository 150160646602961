import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useGoogleReCaptchaV3 from "../../../../hooks/useGoogleReCaptchaV3/useGoogleReCaptchaV3";
import {useAccountApi} from "../../../../app/api/account";
import useDefaultSiteLanguages from "../../../../hooks/useDefaultSiteLanguages/useDefaultSiteLanguages";
import useMediaQuery from "../../../../hooks/useMediaQuery/useMediaQuery";
import {IError} from "../../../../app/interfaces/response/IResponse";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import {BLErrorCode} from "../../../../app/enums/BLErrorCode";
import {ResponseResultCode} from "../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import {IRestoreLogin} from "../../../../app/interfaces/account/IRestoreLogin";
import bg_image from "../../../../assets/images/login-bg.jpg";
import Container from "../../../../components/ui/Container/Container";
import Card from "../../../../components/ui/Card/Card";
import cl from "../RestorePassword/RestorePassword.module.css";
import LanguageSwitcher from "../../../../components/ui/LanguageSwitcher/LanguageSwitcher";
import Alert from "../../../../components/ui/Alert/Alert";
import CopyRightFooter from "../../../../components/ui/CopyRightFooter/CopyRightFooter";
import BackgroundImageContainer from "../../../../components/ui/BackgroundImageContainer/BackgroundImageContainer";
import useDocumentTitle from "../../../../hooks/useDocumentTitle/useDocumentTitle";
import SupportForm from "../../Support/SupportForm";
import Button, {ButtonType} from "../../../../components/ui/Button/Button";

const alertStyle: React.CSSProperties = {
    margin: '0px 0px 5px 0px',
    fontSize: '11px',
    padding: '5px 10px',
    fontWeight: 'bold',
    justifyContent: 'center',
    textAlign: 'center'
};

const RestoreLogin: React.FC = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    useDocumentTitle({title: t("login.restore.restore-label")});

    const {loaded, executeReCaptcha} = useGoogleReCaptchaV3({
        siteKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '',
        language: i18n.language
    });
    const {
        restoreLogin: {
            mutation
        }
    } = useAccountApi();

    const languages = useDefaultSiteLanguages();
    const isMaxWidth470 = useMediaQuery('(max-width:450px)');
    const isMaxHeight490 = useMediaQuery('(max-height:490px)');
    const [errors, setErrors] = useState<Array<IError>>([]);
    const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            if (Object.keys(form.errors).length > 0) {
                await form.validateForm();
            }
        })();
    }, [i18n.language]);

    const form = useFormik<IRestoreLogin>({
        initialValues: {
            email: '',
            message: '',
            company: '',
            phone: '',
            token: ''
        },
        validationSchema: Yup.object({
            message: Yup.string().required(t("shared.errors.required-error")
                .replace('{0}', t("login.labels.login"))),
            company: Yup.string().required(t("shared.errors.required-error")
                .replace('{0}', t("login.restore.company")))
                .max(100),
            email: Yup.string().email(t("profile.support.invalid-email")).required(t("shared.errors.required-error")
                .replace('{0}', t("login.restore.email")))
        }),
        onSubmit: async (values: IRestoreLogin, actions: FormikHelpers<IRestoreLogin>) => {
            try {
                setErrors([]);

                actions.setSubmitting(true);

                if (!loaded || !executeReCaptcha) {
                    setErrors([{
                        errorCode: BLErrorCode.InnerError,
                        description: t('shared.errors.google-recaptcha-loaded'),
                        field: 'summary'
                    }]);

                    return;
                }

                const token: string = await executeReCaptcha('restore/submit');

                if (!token) {
                    setErrors([{
                        errorCode: BLErrorCode.InnerError,
                        description: t('shared.error.google-recaptcha-loaded'),
                        field: 'summary'
                    }]);
                }

                const response = await mutation({
                    ...values,
                    token
                });

                if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                    setIsEmailSent(true);
                }
            } catch (error) {
                if (isAxiosError(error) && error.response && error.response.data) {
                    if (error.response.data.resultCode === ResponseResultCode.ServerError) {
                        setErrors([{
                            errorCode: BLErrorCode.InnerError,
                            description: error.response.data.message,
                            field: 'summary'
                        }]);
                    }
                }
            } finally {
                actions.setSubmitting(false);
            }
        }
    });

    return (
        <BackgroundImageContainer image={bg_image}>
            <Container centerContent={true} height={'calc(100% - 30px)'}>
                <Card style={isMaxWidth470 ? {width: 'calc(100% - 20px)'} : {width: '450px'}}>
                    <div className={cl.switcher}>
                        <LanguageSwitcher languages={languages} languageIconPosition={'right'}/>
                    </div>
                    <div className={cl.logo}
                         style={isMaxHeight490 ? {display: 'none'} : undefined}>
                        <img className={'prevent-selection'}
                             src={'/images/logo_main.png'}
                             alt={'logo'}/>
                    </div>
                    <div className={`${cl.label} prevent-selection`}>
                        {t("login.restore.restore-label")}
                    </div>

                    <div className={'pd-10'}>
                        <Alert type={'BrightGrayInfo'} style={alertStyle}>
                            {isEmailSent
                                ? <>{t("login.restore.restore-login-instructions-sent")}</>
                                : <>{t("login.restore.restore-login-instructions")}</>
                            }
                        </Alert>

                        {!isEmailSent &&
                            <>
                                {errors.map(err => {
                                    return (
                                        <Alert type={'Error'} style={alertStyle} key={err.description}>
                                            {err.description}
                                        </Alert>
                                    )
                                })}

                                <SupportForm id={'restore-password-form'}
                                             onSubmit={async (values) => {
                                                 try {
                                                     setErrors([]);

                                                     if (!loaded || !executeReCaptcha) {
                                                         setErrors([{
                                                             errorCode: BLErrorCode.InnerError,
                                                             description: t('shared.errors.google-recaptcha-loaded'),
                                                             field: 'summary'
                                                         }]);

                                                         return;
                                                     }

                                                     const token: string = await executeReCaptcha('restore/submit');

                                                     if (!token) {
                                                         setErrors([{
                                                             errorCode: BLErrorCode.InnerError,
                                                             description: t('shared.error.google-recaptcha-loaded'),
                                                             field: 'summary'
                                                         }]);
                                                     }

                                                     const response = await mutation({
                                                         ...values,
                                                         token
                                                     });

                                                     if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                                                         setIsEmailSent(true);
                                                     }
                                                 } catch (error) {
                                                     if (isAxiosError(error) && error.response && error.response.data) {
                                                         if (error.response.data.resultCode === ResponseResultCode.ServerError) {
                                                             setErrors([{
                                                                 errorCode: BLErrorCode.InnerError,
                                                                 description: error.response.data.message,
                                                                 field: 'summary'
                                                             }]);
                                                         }
                                                     }
                                                 }
                                             }}/>

                                <div className={cl.center}>
                                    <Button buttonType={ButtonType.Primary}
                                            type='submit'
                                            form={'restore-password-form'}
                                            disabled={form.isSubmitting}
                                            isLoading={form.isSubmitting}
                                            style={{width: '70%'}}>
                                        {t("shared.btn.confirm")}
                                    </Button>
                                </div>
                            </>
                        }

                        <hr className={cl.separator}/>

                        <div className={cl.linkBtns}>
                            <button disabled={form.isSubmitting}
                                    onClick={() => {
                                        if (form.isSubmitting) {
                                            return;
                                        }

                                        navigate('/login');
                                    }}>
                                {t("login.btn.back-to-login-page")}
                            </button>
                        </div>
                    </div>
                </Card>
            </Container>
            <CopyRightFooter/>
        </BackgroundImageContainer>
    );
};

export default RestoreLogin;
