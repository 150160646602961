import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import AcceptTransports from "./AcceptTransports";

type AcceptTransportsDialogProps = {
    id: string | Array<string>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const AcceptTransportsDialog: React.FC<AcceptTransportsDialogProps> = ({
                                                                           id,
                                                                           show,
                                                                           setShow
                                                                       }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'accept-transports-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={Array.isArray(id) && id.length > 1
                    ? t("shared.btn.accept-transportations")
                    : t("shared.btn.accept-transportation")}>
            <AcceptTransports id={id}
                              close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default AcceptTransportsDialog;