import React from 'react';
import GridActionRowButton, {
    GridActionRowButtonProps, isGridActionRowToggleButtonProps
} from "../../../GridActionRow/components/GridActionRowButton/GridActionRowButton";
import {GridActionRowView} from "../../../GridActionRow/utils/gridActionRowContextType";
import GridActionRowContextButton
    from "../../../GridActionRow/components/GridActionRowContextButton/GridActionRowContextButton";
import {useTranslation} from "react-i18next";

type HardGridActionRowButtonsSectionProps = {
    id: string;
    buttons: Array<GridActionRowButtonProps>;
    view: GridActionRowView;
};

const HardGridActionRowButtonsSection: React.FC<HardGridActionRowButtonsSectionProps> = ({
                                                                                             buttons,
                                                                                             view
                                                                                         }) => {
    const {t} = useTranslation();

    if (view === 'Mobile') {
        return (
            <>
                {buttons
                    .filter(btn => isGridActionRowToggleButtonProps(btn))
                    .map(btn => {
                        return <GridActionRowButton key={btn.id} {...btn}/>;
                    })
                }

                <GridActionRowContextButton title={t("shared.labels.actions")}
                                            postIconText={'G-ACT'}
                                            icon={"faEllipsisHorizontal"}
                                            actions={buttons
                                                .filter(btn => !isGridActionRowToggleButtonProps(btn))
                                                .map((btn) => {
                                                    return {
                                                        id: btn.id,
                                                        title: btn.title,
                                                        disabled: btn.disabled ?? false,
                                                        onClick: () => {
                                                            btn.onClick(false);
                                                        }
                                                    }
                                                })}/>
            </>
        );
    }

    return (
        <>
            {buttons.map((btn) => {
                return <GridActionRowButton key={btn.id} {...btn}/>
            })}
        </>
    );
};

export default HardGridActionRowButtonsSection;