import React from 'react';
import {useChatHelperContext} from "../../hooks/useChatHelperContext";
import ChatContentChatListSection from "./ChatContentChatListSection/ChatContentChatListSection";
import ChatContentDialogueSection from "./ChatContentDialogueSection/ChatContentDialogueSection";

type ChatContentProps = {};

const ChatContent: React.FC<ChatContentProps> = () => {
    const {
        isMobileView
    } = useChatHelperContext();

    return (
        <div className={`relative chat-content`}
             data-view={isMobileView ? 'mobile' : 'default'}>
            <ChatContentChatListSection/>
            <ChatContentDialogueSection/>
        </div>
    );
};

export default ChatContent;