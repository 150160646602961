import React, {useState} from 'react';
import cl from './OfferNotes.module.css';
import {useTranslation} from "react-i18next";
import ChangeNoteDialog from "../../../../../actions/ChangeNote/ChangeNoteDialog";
import Icon from "../../../../../../../../assets/icon/Icon";

type OfferNotesProps = {
    notes: string | null;
    notesInfo: string | null;
    orderReleaseGid: string;
};

const OfferNotes: React.FC<OfferNotesProps> = ({
                                                   notes,
                                                   notesInfo,
                                                   orderReleaseGid
                                               }) => {
    const {t} = useTranslation();
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);

    return (
        <>
            <ChangeNoteDialog id={orderReleaseGid}
                              show={isDialogShown}
                              setShow={setIsDialogShown}/>

            <div className={`${cl.content} details-box-notes`}>
                <div>
                    {notes !== undefined && notes !== null && notes !== ''
                        ? <Icon icon={"faPen"}
                          className={'details-box-item-field-gray-icon'}
                          onClick={() => setIsDialogShown(prev => !prev)}/> :
                        <Icon icon={"faPen"}
                              className={'details-box-item-field-icon'}
                              onClick={() => setIsDialogShown(prev => !prev)}/>
                    }

                    <div className={'details-box-item-field-edit-value details-box-notes-edit-btn'}
                         onClick={() => setIsDialogShown(prev => !prev)}>
                        {notes !== undefined && notes !== null && notes !== ''
                            ? <></>
                            : <>{t("shipment.grid.add-note")}</>
                        }
                    </div>
                </div>

                <div title={notesInfo ?? undefined} className={cl.note}>
                    {notes !== undefined && notes !== null && notes !== '' &&
                        <>{notes}</>
                    }
                </div>
            </div>
        </>
    );
};

export default OfferNotes;
