import {GridActionRowTagItem} from "../GridActionRowTagsContainer";
import {isEqual} from "lodash";

export const isTagsChanged = (newTags: Array<GridActionRowTagItem>, oldTags: Array<GridActionRowTagItem>): boolean => {
    let isChanged: boolean = false;

    if (newTags.length !== oldTags.length) {
        isChanged = true;
    }

    for (const tag of newTags) {
        let existTag = oldTags.find(e => e.id === tag.id);

        if (!existTag || !isEqual(existTag, tag)) {
            isChanged = true;
        }
    }

    return isChanged;
}