import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import {useShipmentApi} from "../../../../../app/api/shipment";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {isAxiosError} from "axios";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {IChangeRefNumberPostAction} from "../../../../../app/interfaces/shipment/IChangeRefNumberPostAction";
import {IChangeRefNumberGetAction} from "../../../../../app/interfaces/shipment/IChangeRefNumberGetAction";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import ChangeRefNumForm from "./ChangeRefNumForm/ChangeRefNumForm";

type ChangeRefNumProps = {
    id: string;
    close?: () => void;
}

const ChangeRefNum: React.FC<ChangeRefNumProps> = ({
                                                       id,
                                                       close
                                                   }) => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [model, setModel] = useState<IChangeRefNumberGetAction | null>(null);
    const [, setErrors] = useState<Array<IError>>([]);

    const {
        getChangeRefNumberData: {
            query: getChangeRefNumberData,
            isLoading,
            cancel: cancelGetChangeRefNumberData
        },
        changeRefNumber: {
            mutation: changeRefNumber,
            cancel: cancelChangeRefNumber
        }
    } = useShipmentApi();

    useEffect(() => {
        if (id === '') {
            if (close) {
                close()
            } else {
                return;
            }
        }

        (async () => {
            try {
                const response = await getChangeRefNumberData(id);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch {
                if (close) {
                    close();
                } else {
                    toast.error<string>('Unable to get change ref number data');
                }
            }
        })();

        return () => {
            cancelGetChangeRefNumberData();
            cancelChangeRefNumber();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const save = async (model: IChangeRefNumberPostAction): Promise<void> => {
        setDisabled(prev => !prev);
        setErrors([]);

        try {
            const response = await changeRefNumber(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [
                            {
                                id: id,
                                isRowLoading: true
                            }
                        ]
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.shipment")} ${id}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();
                    toast.error(err.message);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                <ChangeRefNumForm model={model}
                                  save={save}
                                  disabled={disabled}/>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        form='change-ref-number-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.save")}
                </Button>
            </div>
        </div>
    );
};

export default ChangeRefNum;