import React, {useState} from 'react';
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import cl from './DateTemplate.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import ChangePlannedDateDialog from "../../../actions/ChangePlannedDate/ChangePlannedDateDialog";
import Icon from "../../../../../../assets/icon/Icon";

const CostTemplate = (props: any) => {
    const {t} = useTranslation();
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
            <span className={'default-left-cell cost-value'}>
                {props.dataItem.cost !== null && props.dataItem.cost !== undefined && props.dataItem.cost > 0
                    /*? <>{(new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'EUR'}).format(props.dataItem.cost))}</>*/
                    ? <>{(new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'EUR'}).format(props.dataItem.cost).replace('€', ''))}</>
                    : <>{t("shared.labels.no-data")}</>
                }
            </span>
        </td>
    )
    
    
};

export default CostTemplate;
