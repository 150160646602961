import React, {useEffect, useState} from 'react';
import cl from './Checkbox.module.css';
import uuid from "react-uuid";

export enum CheckboxSize {
    Small = 0,
    Medium = 1,
    Big = 2,
    SmallMedium = 3 
}

type CheckboxProps = {
    checked: boolean,
    change: (value: boolean, ev: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    title?: string;
    disabled: boolean;
    size?: CheckboxSize;
} & React.ComponentPropsWithoutRef<'input'>;

const Checkbox: React.FC<CheckboxProps> = ({
                                               checked,
                                               change,
                                               label,
                                               disabled,
                                               id,
                                               title,
                                               size = CheckboxSize.Small,
                                               ...other
                                           }) => {
    const [isChecked, setIsChecked] = useState<boolean>(checked);
    const [elementId] = useState(id ?? uuid());

    useEffect(() => {
        if (checked !== isChecked) {
            setIsChecked(checked);
        }
    }, [checked]);

    const getSizeClass = () => {
        switch (size) {
            case CheckboxSize.Small:
                return cl.small;
            case CheckboxSize.Medium:
                return cl.medium;
            case CheckboxSize.Big:
                return cl.big;
            case  CheckboxSize.SmallMedium:
                return cl.sm;

        }
    };

    return (
        <div className={`${cl.wrapper} ${getSizeClass()} ${other.className}`}>
            <input id={elementId}
                   type="checkbox"
                   checked={isChecked}
                   title={title}
                   disabled={disabled}
                   onChange={(ev) => {
                       setIsChecked(prev => {
                           change(!prev, ev);

                           return !prev;
                       });
                   }}/>
            {label &&
                <label htmlFor={elementId}
                       className={'prevent-selection'}>
                    {label}
                </label>
            }
        </div>
    );
};

export default Checkbox;