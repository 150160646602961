import {IResponse} from "../interfaces/response/IResponse";
import apiErrors from "./errors";

export type Entity = 'login' | 'device';

export interface IApiErrorItem {
    response: IResponse;
    resource: string;
}

export type ApiErrors = {
    [index in Entity]: Array<IApiErrorItem>;
}

const getServerErrorMessage = (entity: Entity, response: IResponse): string => {
    if (!(entity in apiErrors)) {
        return '';
    }

    type CheckType = 'Full' | 'Action' | 'Field';
    let checkType: CheckType | null;
    if (response.action !== undefined && response.field !== undefined) {
        checkType = 'Full';
    } else if (response.action !== undefined && response.field === undefined) {
        checkType = 'Action';
    } else if (response.action === undefined && response.field !== undefined) {
        checkType = 'Field'
    }

    let item = apiErrors[entity].find((item: IApiErrorItem) => {
        switch (checkType) {
            case 'Full':
                return item.response.resultCode === response.resultCode &&
                    item.response.field === response.field &&
                    item.response.action === response.action;
            case 'Field':
                return item.response.resultCode === response.resultCode &&
                    item.response.field === response.field;
            case 'Action':
                return item.response.resultCode === response.resultCode &&
                    item.response.action === response.action;
        }
        return false;
    });

    return item?.resource ?? 'shared.errors.server-error';
};

export default getServerErrorMessage;