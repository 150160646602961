import React from 'react';
import {IOffer} from "../../../../../../app/interfaces/offer/IOffer";
import OfferDetailsBox from "../../components/OfferDetailsBox/OfferDetailsBox";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import ServiceProviderOfferDetailsSection from "./ServiceProvider/ServiceProviderOfferDetailsSection";
import ForwarderOfferDetailsSection from "./Forwarder/ForwarderOfferDetailsSection";
import { ICustomerAttachmentDoc } from '../../../../../../app/interfaces/customerAttachment/ICustomerAttachmentDoc';

type OfferDetailsSectionProps = {
    offer: IOffer;
    isSidePanelDetails: boolean;
    customerAttachmentDocs: Array<ICustomerAttachmentDoc> | null;
};

const OfferDetailsSection: React.FC<OfferDetailsSectionProps> = ({
                                                                     offer,
                                                                     isSidePanelDetails,
                                                                     customerAttachmentDocs
                                                                 }) => {
    const user = useAppSelector(selectCurrentUser);

    if (!user) {
        return null;
    }

    let role = getUserRole(user);

    return (
        <div id={'offer-details-section'}>
            <OfferDetailsBox offer={offer}
                             isSidePanelDetails={isSidePanelDetails}
                             user={user}
                             customerAttachmentDocs={customerAttachmentDocs}/>

            {role === UserRole.ServiceProvider
                ? <ServiceProviderOfferDetailsSection offer={offer}/>
                : <ForwarderOfferDetailsSection offer={offer}/>
            }
        </div>
    );
};

export default OfferDetailsSection;
