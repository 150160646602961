import React from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import cl from './ProcessingDateTemplate.module.css';
import moment from "moment";

const ProcessingDateTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);


    let isAuctionEnd = moment.utc(props.children, 'DD.MM.YY HH:mm').toDate() <= moment().utc().add(-1, 'days').toDate();

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
                <span className={`default-left-cell default-overflow-cell ${isAuctionEnd ? cl.warn : ''}`}>
                    {moment.utc(props.children, 'DD.MM.YY HH:mm').local().format('DD.MM.YYYY HH:mm')}
                </span>
        </td>
    );
};

export default ProcessingDateTemplate;
