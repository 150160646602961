import {ExtendedEvent, ExtendedShipmentEvent} from "../ShipmentsEventList";
import {IShipmentEvent} from "../../../../../../app/interfaces/shipment/IShipmentEvent";
import {IError} from "../../../../../../app/interfaces/response/IResponse";
import {BLErrorCode} from "../../../../../../app/enums/BLErrorCode";
import moment from "moment";
import {UseTranslationResponse} from "react-i18next";
import {getEventLabel} from "../../../../../../helpers/shipment";

export const buildShipmentEventList = (shipment: ExtendedEvent, showAllEvents: boolean): Array<ExtendedShipmentEvent> => {
    let items = shipment.shipmentEvents?.filter(e => !e.isFake) ?? [];
    let latestArrivalOrDepartureEvent = getLatestEvent(items, true);
    let latestStopPoint = getLatestStopPoint(items);
    let latestEvent = getLatestEvent(items, false);
    let nextEvent = shipment.nextEvent ?? latestEvent;

    let result: Array<ExtendedShipmentEvent> = [];

    items.forEach(item => {
        let isLast: boolean = latestStopPoint !== null &&
            latestStopPoint.spPosition === item.spPosition &&
            latestStopPoint.spSubPosition === item.spSubPosition;

        let isLatest: boolean = latestArrivalOrDepartureEvent !== null &&
            latestArrivalOrDepartureEvent.spPosition === item.spPosition &&
            latestArrivalOrDepartureEvent.spSubPosition === item.spSubPosition;

        result.push({
            ...item,
            line: isLast ? 'None' : 'Solid',
            passed: item.factDate !== null && item.factDate !== undefined,
            isLast: isLast,
            isLatest: isLatest,
            isHidden: false
        });
    });

    if (result.length <= 5 || showAllEvents) {
        result = updateEditingState(result, nextEvent, showAllEvents);
        result = validate(result);

        return result.sort((a, b) => {
            return a.sortPosition - b.sortPosition
        });
    }

    result = result.sort((a, b) => {
        return a.sortPosition - b.sortPosition;
    });

    let last = result[items.length - 1];

    result.forEach(item => {
        let isHidden: boolean = false;

        if (nextEvent === null) {
            isHidden = item.sortPosition !== last.sortPosition && item.sortPosition > 3;
        } else {
            if (nextEvent.sortPosition < 3) {
                if (item.sortPosition === 0 || item.sortPosition === last.sortPosition || item.sortPosition === nextEvent.sortPosition) {
                    isHidden = false;
                } else if (item.sortPosition > 3) {
                    isHidden = true;
                }
            } else if (nextEvent.sortPosition > (last.sortPosition - 3)) {
                if (item.sortPosition === 0 || item.sortPosition === last.sortPosition || item.sortPosition === nextEvent.sortPosition) {
                    isHidden = false;
                } else if (item.sortPosition < (last.sortPosition - 3)) {
                    isHidden = true;
                }
            } else {
                if (item.sortPosition === 0 || item.sortPosition === last.sortPosition || item.sortPosition === nextEvent.sortPosition) {
                    isHidden = false;
                } else if ((item.sortPosition < (nextEvent.sortPosition - 1)) || (item.sortPosition > (nextEvent.sortPosition + 1))) {
                    isHidden = true;
                }
            }
        }

        item.isHidden = isHidden;
    });

    if (nextEvent === null) {
        let item = result
            .filter(e => !e.isHidden && e.sortPosition !== last.sortPosition)
            .sort((a, b) => {
                return a.sortPosition - b.sortPosition
            }).pop();

        if (item !== undefined && item !== null) {
            item.isHidden = false;
            item.line = 'Dashed';
        }
    } else {
        if (nextEvent.sortPosition < 3) {
            let item = result
                .filter(e => !e.isHidden && e.sortPosition !== last.sortPosition)
                .sort((a, b) => {
                    return a.sortPosition - b.sortPosition
                }).pop();

            if (item !== undefined && item !== null) {
                item.isHidden = false;
                item.line = 'Dashed';
            }
        } else if (nextEvent.sortPosition > (last.sortPosition - 3)) {
            result[0].isHidden = false;
            result[0].line = 'Dashed';
        } else {
            result[0].isHidden = false;
            result[0].line = 'Dashed';

            let item = result
                .filter(e => !e.isHidden && e.sortPosition !== last.sortPosition)
                .sort((a, b) => {
                    return a.sortPosition - b.sortPosition
                }).pop();

            if (item !== undefined && item !== null) {
                item.isHidden = false;
                item.line = 'Dashed';
            }
        }
    }

    result = updateEditingState(result, nextEvent, showAllEvents);
    result = validate(result);

    return result.sort((a, b) => {
        return a.sortPosition - b.sortPosition
    });
}

const validate = (events: Array<ExtendedShipmentEvent>): Array<ExtendedShipmentEvent> => {
    const MS_PER_MINUTE = 60000;

    events = events.sort((a, b) => {
        return a.sortPosition - b.sortPosition;
    })

    for (let i = 0; i < events.length; i++) {
        if (!events[i].isEditMode || !events[i].isEditable) {
            continue;
        }

        let date = events[i].editFactDate !== undefined ? moment(events[i].editFactDate).format("YYYY-MM-DD") : null;
        let time = events[i].editFactTime !== undefined ? moment(events[i].editFactTime).format('HH:mm') : null;

        if (date !== null && time !== null) {
            let currentDate = Date.parse(`${date} ${time}`).valueOf() - events[i].timeShift * MS_PER_MINUTE;

            let previousDate: number | null = null;
            let nextDate: number | null = null;

            for (let j = events[i].sortPosition - 1; j >= 0; j--) {
                if (j >= 0 && j <= events.length) {
                    if (events[j].isEditMode) {
                        let previousDatePart = events[j].editFactDate !== undefined ? moment(events[j].editFactDate).format("YYYY-MM-DD") : null;
                        let previousTimePart = events[j].editFactTime !== undefined ? moment(events[j].editFactTime).format("HH:mm") : null;

                        if (previousDatePart !== null && previousTimePart !== null) {
                            previousDate = Date.parse(`${previousDatePart} ${previousTimePart}`).valueOf();
                        }
                    } else if (events[j].factDate !== null && events[j].factDate !== undefined) {
                        previousDate = Date.parse(moment(events[j].factDate).format("YYYY-MM-DD HH:mm")).valueOf();
                    }

                    if (previousDate !== null) {
                        previousDate = previousDate - events[i].timeShift * MS_PER_MINUTE;
                        break;
                    }
                }
            }

            for (let k = events[i].sortPosition + 1; k < events.length; k++) {
                if (k >= 0 && k <= events.length) {
                    if (events[k].isEditMode) {
                        let nextDatePart = events[k].editFactDate !== undefined ? moment(events[k].editFactDate).format("YYYY-MM-DD") : null;
                        let nextTimePart = events[k].editFactTime !== undefined ? moment(events[k].editFactTime).format("HH:mm") : null;

                        if (nextDatePart !== null && nextTimePart !== null) {
                            nextDate = Date.parse(`${nextDatePart} ${nextTimePart}`).valueOf();
                        }
                    } else if (events[k].factDate !== null && events[k].factDate !== undefined) {
                        nextDate = Date.parse(moment(events[k].factDate).format("YYYY-MM-DD HH:mm")).valueOf();
                    }

                    if (nextDate !== null) {
                        nextDate = nextDate - events[i].timeShift * MS_PER_MINUTE;
                        break;
                    }
                }
            }

            if (previousDate !== null && previousDate >= currentDate) {
                events[i].hasDateError = true;

                continue;
            }

            if (nextDate !== null && nextDate <= currentDate) {
                events[i].hasDateError = true;

                continue;
            }

            events[i].hasDateError = false;
        }
    }

    return [
        ...events
    ];
}

const updateEditingState = (
    events: Array<ExtendedShipmentEvent>,
    nextEvent: IShipmentEvent | null,
    showAllEvents: boolean
): Array<ExtendedShipmentEvent> => {
    let isPlusChanged: boolean = false;

    events
        .filter(e => !e.isHidden)
        .sort((a, b) => {
            return a.sortPosition - b.sortPosition
        })
        .forEach(item => {
            let type = item.type?.toLowerCase();
            if (type && (type === '[c]' || type === '[o]' || type === 'c' || type === '0')) {
                item.showPlus = true;
            } else if (item.isRequired && !item.showPlus && !isPlusChanged) {
                if ((item.factDate === null || item.factDate === undefined) &&
                    item.editFactDate === undefined && item.editFactTime === undefined) {
                    if (nextEvent === null || (nextEvent.otmEvent !== item.otmEvent)) {
                        item.showPlus = true;
                        isPlusChanged = true;
                    }
                }
            }
        });

    if (!showAllEvents && events.length > 5 && nextEvent !== null && nextEvent.sortPosition < 3) {
        let item = events
            .filter(e => !e.isHidden)
            .sort((a, b) => {
                return a.sortPosition - b.sortPosition
            }).pop();

        if (item !== null && item !== undefined && item.showPlus) {
            item.showPlus = false;
        }
    }

    return [
        ...events
    ];
}

const getLatestEvent = (events: Array<ExtendedShipmentEvent>, isArrivalOrDeparture: boolean): ExtendedShipmentEvent | null => {
    if (events.length <= 0) {
        return null;
    }

    if (isArrivalOrDeparture) {
        return events
            .filter(e => e.factDate !== null && e.factDate !== undefined && (
                e.type?.toLowerCase() === '[d]' || e.type?.toLowerCase() === '[p]'
            ))
            .sort(function (a, b) {
                return a.sortPosition - b.sortPosition;
            }).pop() ?? null;
    } else {
        return events
            .filter(e => e.factDate !== null && e.factDate !== undefined)
            .sort(function (a, b) {
                return a.sortPosition - b.sortPosition;
            }).pop() ?? null;
    }
}

const getLatestStopPoint = (events: Array<ExtendedShipmentEvent>): ExtendedShipmentEvent | null => {
    if (events.length <= 0) {
        return null;
    }

    return events
        .sort(function (a, b) {
            return b.spPosition - a.spPosition || b.spSubPosition - a.spSubPosition;
        })[0];
}

export const getShipmentEventListItemError = (shipment: ExtendedEvent, errors: Array<IError>, t: UseTranslationResponse<'translation', undefined>): Array<string> => {
    let result: Array<string> = [];

    let shipmentErrors = errors.filter(e => e.field === shipment.shipmentGid);

    shipmentErrors.forEach(err => {
        // noinspection JSUnresolvedReference
        switch (err.errorCode) {
            case BLErrorCode.NotConfirmed:
                result.push(t.t('shipment.errors.add-event-shipment-unconfirmed'));
                break;
            case BLErrorCode.PickUpDateRequired:
                result.push(t.t('shared.errors.required-error').replace('{0}', t.t('shipment.grid.pickup-date-label')));
                break;
            case BLErrorCode.InvalidDate:
                if (err.description !== null && err.description !== '') {
                    let desc = JSON.parse(err.description);

                    // noinspection JSUnresolvedReference
                    if (desc && desc.ErrorStopPoint !== undefined && desc.ErrorStopPoint !== null &&
                        desc.ErrorAddressShort !== undefined && desc.ErrorAddressShort !== null && desc.ErrorAddressShort !== '' &&
                        desc.ErrorEventStatus !== undefined && desc.ErrorEventStatus !== null && desc.ErrorEventStatus !== '' &&
                        desc.ErrorEventType !== undefined && desc.ErrorEventType !== null && desc.ErrorEventType !== '') {
                        // noinspection JSUnresolvedReference
                        let event = t.t(getEventLabel(`AAG.${desc.ErrorEventStatus}`, desc.ErrorEventType, {
                            position: desc.ErrorStopPoint.Position
                        } as any));

                        if (event !== '') {
                            // noinspection JSUnresolvedReference
                            result.push(`${desc.ErrorAddressShort} ${event}: ${t.t('shipment.errors.add-event-invalid-date')}`);
                        }
                    }
                }
                break;
            case BLErrorCode.CannotAddEventInFuture:
                if (err.description !== null && err.description !== '') {
                    let desc = JSON.parse(err.description);

                    // noinspection JSUnresolvedReference
                    if (desc && desc.ErrorStopPoint !== undefined && desc.ErrorStopPoint !== null &&
                        desc.ErrorAddressShort !== undefined && desc.ErrorAddressShort !== null && desc.ErrorAddressShort !== '' &&
                        desc.ErrorEventStatus !== undefined && desc.ErrorEventStatus !== null && desc.ErrorEventStatus !== '' &&
                        desc.ErrorEventType !== undefined && desc.ErrorEventType !== null && desc.ErrorEventType !== '') {
                        // noinspection JSUnresolvedReference
                        let event = t.t(getEventLabel(`AAG.${desc.ErrorEventStatus}`, desc.ErrorEventType, {
                            position: desc.ErrorStopPoint.Position
                        } as any));

                        if (event !== '') {
                            // noinspection JSUnresolvedReference
                            result.push(`${desc.ErrorAddressShort} ${event}: ${t.t('shipment.errors.add-event-cannot-add-event-in-future')}`);
                        }
                    }
                }
                break;
            case BLErrorCode.CannotEditUnloadDate:
                result.push(t.t('shipment.errors.add-event-cannot-edit-unload-date'));
                break;
            case BLErrorCode.CannotChangeShipmentCompleteDate:
                result.push(t.t('shipment.errors.add-event-cannot-change-shipment-complete-date'));
                break;
            case BLErrorCode.EventDateIsMissing:
                result.push(t.t('shipment.errors.add-event-event-date-is-missing'));
                break;
        }
    });

    return result;
}