import React, {useEffect, useRef, useState} from 'react';
import {Query} from "../../../../../../app/types/Query";
import {useParams} from "react-router-dom";
import {useGridLayoutContext} from "../../../../../../components/uiLayouts/GridLayout/hooks/useGridLayoutContext";
import {IQuoteHeaderConfig} from "../../data/interfaces/IQuoteHeaderConfig";
import GridLayoutGrid from "../../../../../../components/ui/Grid/Telerik/GridLayoutGrid";
import {ContextMenuItem} from "../../../../../../components/ui/Grid/Telerik/types/ContextMenuItem";
import {
    ApplicationHubMessageAction,
    IApplicationHubMessage
} from "../../../../../../app/signalR/applicationHub/IApplicationHubMessage";
import {GridService} from "../../../../../../components/ui/Grid/Telerik/api/GridService";
import {LayoutConfigEntity} from "../../../../../../app/enums/LayoutConfigEntity";
import {
    useGridFilterPanelLayoutPreload
} from "../../../../../../components/uiLayouts/GridFilterPanelLayout/hooks/useGridFilterPanelLayoutPreload";
import {buildQuoteFilterQuery, buildQuoteQuery} from "../../utils";
import {useAppDispatch, useAppSelector} from "../../../../../../app/store";
import {selectAccessSettings, selectQuery} from "../../../../../../features/filter/filterSliceSelectors";
import {setFilterQuery} from "../../../../../../features/filter/filterSlice";
import {buildAccessQuery} from "../../../../../../components/uiLayouts/GridLayout/context";
import {IUserAccessSettings} from "../../../../../../app/interfaces/user/IUserAccessSettings";

const GRID_DATA_URL = `/api/${process.env.REACT_APP_API_VERSION}/quote/getQuotes`

const contextMenuItems: Array<ContextMenuItem> = [
    {
        field: 'orderReleaseXid',
        text: 'ГИПЕРССЫЛКА',
        action: 'copyHyperlinkRU',
        handler: (row: any) => {
            if (row !== null && row !== undefined && row.id !== null && row.id !== undefined &&
                row.orderReleaseXid !== null && row.orderReleaseXid !== undefined) {
                let copyText = `=ГИПЕРССЫЛКА("${process.env.REACT_APP_CLIENT_DOMAIN}/quotes/active?id=${row.id}";"${row.orderReleaseXid}")`;

                try {
                    navigator.clipboard.writeText(copyText).then(() => {
                        //ignore;
                    });
                } catch (e) {
                    console.log('Copy to clipboard error', e);
                }
            }
        }
    },
    {
        field: 'orderReleaseXid',
        text: 'HYPERLINK',
        action: 'copyHyperlinkEN',
        handler: (row: any) => {
            if (row !== null && row !== undefined && row.id !== null && row.id !== undefined &&
                row.orderReleaseXid !== null && row.orderReleaseXid !== undefined) {
                let copyText = `=HYPERLINK("${process.env.REACT_APP_CLIENT_DOMAIN}/quotes/active?id=${row.id}";"${row.orderReleaseXid}")`;

                try {
                    navigator.clipboard.writeText(copyText).then(() => {
                        //ignore;
                    });
                } catch (e) {
                    console.log('Copy to clipboard error', e);
                }
            }
        }
    },
    {
        field: 'orderReleaseXid',
        text: 'HIPERŁĄCZE',
        action: 'copyHyperlinkPL',
        handler: (row: any) => {
            if (row !== null && row !== undefined && row.id !== null && row.id !== undefined &&
                row.orderReleaseXid !== null && row.orderReleaseXid !== undefined) {
                let copyText = `=HIPERŁĄCZE("${process.env.REACT_APP_CLIENT_DOMAIN}/quotes/active?id=${row.id}";"${row.orderReleaseXid}")`;

                try {
                    navigator.clipboard.writeText(copyText).then(() => {
                        //ignore;
                    });
                } catch (e) {
                    console.log('Copy to clipboard error', e);
                }
            }
        }
    },
];

type QuoteGridContentProps = {
    onRowClick: (data: any) => void;
    onRowDoubleClick: (data: any) => void;
}

const QuoteGridContent: React.FC<QuoteGridContentProps> = ({
                                                               onRowClick,
                                                               onRowDoubleClick
                                                           }) => {
    const dispatch = useAppDispatch();

    const [query, setQuery] = useState<Query | undefined>();
    const [headerQuery, setHeaderQuery] = useState<Query | undefined>();
    const [accessSettingsQuery, setAccessSettingsQuery] = useState<Query | undefined>();
    const stateRef = useRef();
    const filterQuery = useAppSelector(state =>
        selectQuery(state, LayoutConfigEntity.Quote));
    const settings = useAppSelector(selectAccessSettings);
    const {type} = useParams();
    const {
        headerConfig,
        setSelectedRows
    } = useGridLayoutContext<IQuoteHeaderConfig>();

    useGridFilterPanelLayoutPreload({
        entity: LayoutConfigEntity.Quote,
        selectedPersonalFilter: headerConfig?.pinnedFilter ?? null,
        selectedPredefinedFilter: headerConfig?.pinnedPredefinedFilter ?? null,
        onComplete: (personal, predefined, filter) => {
            dispatch(setFilterQuery({
                entity: LayoutConfigEntity.Quote,
                data: buildQuoteFilterQuery(personal, predefined, filter).getParams()
            }));
        },
        onChange: (personal, predefined, filter) => {
            dispatch(setFilterQuery({
                entity: LayoutConfigEntity.Quote,
                data: buildQuoteFilterQuery(personal, predefined, filter).getParams()
            }));
        }
    });

    useEffect(() => {


        const onShipmentApplicationHubMessage = (ev: any) => {
            let typedEv = ev.detail as IApplicationHubMessage;

            if (typedEv.action === ApplicationHubMessageAction.Update && typedEv.payload && typedEv.payload !== '') {
                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'getItem',
                        field: 'id',
                        value: typedEv.payload,
                        callback: (item: any) => {
                            if (item !== null && item !== undefined && typedEv.payload) {
                                let service = new GridService(GRID_DATA_URL);

                                let q = new Query();
                                if (stateRef.current) {
                                    q.addParamsFromQuery(stateRef.current ?? null)
                                }


                                q.addParam('sid', typedEv.payload);

                                service.execute({
                                    skip: 0,
                                    take: 1
                                }, q).then(data => {

                                    if (data.data && data.data.length === 1) {
                                        let item = data.data[0];

                                        item.isRowLoading = false;

                                        document.dispatchEvent(new CustomEvent('onGridMessage', {
                                            detail: {
                                                action: 'updateRow',
                                                rows: [
                                                    {...item}
                                                ]
                                            }
                                        }));
                                    } else {
                                        document.dispatchEvent(new CustomEvent('onGridMessage', {
                                            detail: {
                                                action: 'refresh'
                                            }
                                        }));
                                    }
                                });
                            } else {
                                document.dispatchEvent(new CustomEvent('onGridMessage', {
                                    detail: {
                                        action: 'refresh'
                                    }
                                }));
                            }
                        }
                    }
                }));
            }
        }

        document.addEventListener('onQuoteApplicationHubMessage', onShipmentApplicationHubMessage);

        return () => {
            document.removeEventListener('onQuoteApplicationHubMessage', onShipmentApplicationHubMessage);
        }
    }, []);

    useEffect(() => {

        let onAccessSettingsUpdate = (ev: any) => {
            setAccessSettingsQuery(buildAccessQuery(new Query(), ev.detail.data as IUserAccessSettings));
        }

        document.addEventListener('onAccessSettingsUpdate', onAccessSettingsUpdate);

        setHeaderQuery(buildQuoteQuery(type ?? 'current', headerConfig));

        return () => {
            document.removeEventListener('onAccessSettingsUpdate', onAccessSettingsUpdate);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, headerConfig]);

    useEffect(() => {
        // @ts-ignore
        stateRef.current = headerQuery;

        let q = new Query();

        if (filterQuery) {
            q.addParamsFromQuery(filterQuery ? Query.ToQuery(filterQuery) : null)
        }

        if (headerQuery) {
            q.addParamsFromQuery(headerQuery ?? null)
        }

        if (accessSettingsQuery && accessSettingsQuery.getParams().length > 0) {
            q.addParamsFromQuery(accessSettingsQuery);
        }

        q = buildAccessQuery(q, settings);

        setQuery(q);
    }, [headerQuery, filterQuery, accessSettingsQuery]);

    return (
        <GridLayoutGrid id={'quote-grid'}
                        url={GRID_DATA_URL}
                        query={query}
                        selectionMode={'multiple'}
                        onRowDoubleClick={onRowDoubleClick}
                        onRowClick={onRowClick}
                        onSelect={(rows) => setSelectedRows(rows)}
                        showCellContextMenu={true}
                        contextMenuItems={contextMenuItems}/>
    );
};

export default QuoteGridContent;
