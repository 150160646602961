import React, {useContext} from 'react';
import cl from './SidebarNavigationNestedButton.module.css';
import {NavLink} from "react-router-dom";
import {SidebarContext} from "../../../Sidebar";

type SidebarNavigationNestedButtonProps = {
    id: string;
    type: 'primary' | 'secondary';
    text: string;
    title: string;
    tooltip?: string;
    path: string;
    onClick?: () => void;
    disabled?: boolean;
};

const SidebarNavigationNestedButton: React.FC<SidebarNavigationNestedButtonProps> = ({
                                                                                         id,
                                                                                         type,
                                                                                         text,
                                                                                         title,
                                                                                         path,
                                                                                         onClick,
                                                                                         disabled
                                                                                     }) => {
    const {
        hoverExpanded,
        expanded
    } = useContext(SidebarContext);

    return (
        <NavLink id={id}
                 to={path}
                 data-type={type}
                 title={title}
                 className={({isActive}) => `relative ${cl.button} ${disabled ? cl.disabled : ''} ${isActive ? cl.active : ''}`}
                 onClick={onClick}>
            <div className={`prevent-selection ${cl.text}`}
                 data-expanded={hoverExpanded || expanded ? 'expanded' : 'condensed'}>
                {text}
            </div>
        </NavLink>
    );
};

export default SidebarNavigationNestedButton;