import {IGridLayoutContext} from "../models/interfaces/IGridLayoutContext";
import React from "react";
import {Query} from "../../../../app/types/Query";
import {IUserAccessSettings} from "../../../../app/interfaces/user/IUserAccessSettings";

export const GridLayoutContext = React.createContext<IGridLayoutContext<any>>(undefined as any);


export const buildAccessQuery = (q: Query, settings: IUserAccessSettings | null): Query => {
    if(settings) {
        if (settings.forwarders.length > 0) {
            q.addParam('fw', settings.forwarders.map(item => item).join('+'));
        }

        if (settings.branches.length > 0) {
            q.addParam('br', settings.branches.map(item => item).join('+'));
        }

        if (settings.departments.length > 0) {
            q.addParam('dps', settings.departments.map(item => item).join('+'));
        }

        if (settings.transportLinesDestination.length > 0) {
            q.addParam('tld', settings.transportLinesDestination.map(item => item).join('+'));
        }

        if (settings.transportLinesSource.length > 0) {
            q.addParam('tls', settings.transportLinesSource.map(item => item).join('+'));
        }
    }
    return q;
}
