import {AxiosResponse} from "axios";
import {IDataResponse, IResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {IInvoice} from "../../interfaces/invoice/IInvoice";
import {IInvoiceDetailsRequestPostAction} from "../../interfaces/invoice/IInvoiceDetailsRequestPostAction";
import {IShipmentInvoice} from "../../interfaces/invoice/IShipmentInvoice";

export const useGetInvoice = (): {
    isLoading: boolean,
    cancel: () => void,
    getInvoice: (id: string) => Promise<AxiosResponse<IDataResponse<IInvoice>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IInvoice>>({
        method: 'get',
    });

    const getInvoice = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/invoice/getInvoice?id=${id}`
    }), [execute]);

    return {getInvoice, isLoading, cancel};
};

export const useGetInvoiceAttachment = (): {
    isLoading: boolean,
    cancel: () => void,
    getInvoiceAttachment: (id: number) => Promise<AxiosResponse<IResponse | Blob> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse | Blob>({
        method: 'get',
        responseType: 'blob'
    });

    const getInvoiceAttachment = useCallback(async (id: number) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/invoice/getAttachment?id=${id}`
    }), [execute]);

    return {getInvoiceAttachment, isLoading, cancel};
};

export const useRequestAnswerAction = (): {
    isLoading: boolean,
    cancel: () => void,
    requestAnswerAction: (params: IInvoiceDetailsRequestPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IInvoiceDetailsRequestPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/invoice/requestAnswerAction`,
        headers: { "Content-Type": "multipart/form-data" },
    });

    const requestAnswerAction = useCallback(async (params: IInvoiceDetailsRequestPostAction) => await execute(params), [execute]);

    return {requestAnswerAction, isLoading, cancel};
};

export const useRequestBlockAction = (): {
    isLoading: boolean,
    cancel: () => void,
    requestBlockAction: (params: IInvoiceDetailsRequestPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IInvoiceDetailsRequestPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/invoice/requestBlockAction`,
        headers: { "Content-Type": "multipart/form-data" },
    });

    const requestBlockAction = useCallback(async (params: IInvoiceDetailsRequestPostAction) => await execute(params), [execute]);

    return {requestBlockAction, isLoading, cancel};
};

export const useRequestPaymentAction = (): {
    isLoading: boolean,
    cancel: () => void,
    requestPaymentAction: (params: IInvoiceDetailsRequestPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IInvoiceDetailsRequestPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/invoice/requestPaymentAction`,
        headers: { "Content-Type": "multipart/form-data" },
    });

    const requestPaymentAction = useCallback(async (params: IInvoiceDetailsRequestPostAction) => await execute(params), [execute]);

    return {requestPaymentAction, isLoading, cancel};
};

export const useRequestBlockActionAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    requestBlockActionAnonymous: (params: IInvoiceDetailsRequestPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IInvoiceDetailsRequestPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/invoice/requestBlockActionAnonymous`,
        headers: { "Content-Type": "multipart/form-data" },
    });

    const requestBlockActionAnonymous = useCallback(async (params: IInvoiceDetailsRequestPostAction) => await execute(params), [execute]);

    return {requestBlockActionAnonymous, isLoading, cancel};
};

export const useRequestPaymentActionAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    requestPaymentActionAnonymous: (params: IInvoiceDetailsRequestPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IInvoiceDetailsRequestPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/invoice/requestPaymentActionAnonymous`,
        headers: { "Content-Type": "multipart/form-data" },
    });

    const requestPaymentActionAnonymous = useCallback(async (params: IInvoiceDetailsRequestPostAction) => await execute(params), [execute]);

    return {requestPaymentActionAnonymous, isLoading, cancel};
};

export const useGetShipmentInvoiceAuthorize = (): {
    isLoading: boolean,
    cancel: () => void,
    getShipmentInvoiceAuthorize: (gid: string, xid: string) => Promise<AxiosResponse<IDataResponse<IShipmentInvoice>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IShipmentInvoice>>({
        method: 'get',
    });

    const getShipmentInvoiceAuthorize = useCallback(async (gid: string, xid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/invoice/getShipmentInvoiceAuthorize?shipmentGid=${gid}&orderReleaseXid=${xid}`
    }), [execute]);

    return {getShipmentInvoiceAuthorize, isLoading, cancel};
};

export const useGetShipmentInvoiceAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    getShipmentInvoiceAnonymous: (gid: string, xid: string) => Promise<AxiosResponse<IDataResponse<IShipmentInvoice>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IShipmentInvoice>>({
        method: 'get',
    });

    const getShipmentInvoiceAnonymous = useCallback(async (gid: string, xid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/invoice/getShipmentInvoiceAnonymous?shipmentGid=${gid}&orderReleaseXid=${xid}`
    }), [execute]);

    return {getShipmentInvoiceAnonymous, isLoading, cancel};
};