import {useGetEquipmentGroupAlias, useGetEquipmentsGroups} from "./requests";

export const useEquipmentApi = () => {
    const {
        isLoading: getEquipmentGroupsIsLoading,
        cancel: cancelGetEquipmentGroups,
        getEquipmentGroups
    } = useGetEquipmentsGroups();

    const {
        isLoading: getEquipmentGroupAliasIsLoading,
        cancel: cancelGetEquipmentGroupAlias,
        getEquipmentGroupAlias
    } = useGetEquipmentGroupAlias();

    return {
        getEquipmentGroups: {
            query: getEquipmentGroups,
            isLoading: getEquipmentGroupsIsLoading,
            cancel: cancelGetEquipmentGroups
        },
        getEquipmentGroupAlias: {
            query: getEquipmentGroupAlias,
            isLoading: getEquipmentGroupAliasIsLoading,
            cancel: cancelGetEquipmentGroupAlias
        }
    };
}