import {useConfirmDevice, useGetDevices, useSendConfirmationEmail} from "./requests";

export const useDeviceApi = () => {
    const {
        isLoading: sendConfirmationEmailLoading,
        cancel: cancelSendConfirmationEmail,
        sendConfirmationEmail
    } = useSendConfirmationEmail();

    const {
        isLoading: confirmDeviceIsLoading,
        cancel: cancelConfirmDevice,
        confirmDevice
    } = useConfirmDevice();

    const {
        isLoading: getDevicesIsLoading,
        cancel: cancelGetDevices,
        getDevices
    } = useGetDevices();

    return {
        sendConfirmationEmail: {
            mutation: sendConfirmationEmail,
            isLoading: sendConfirmationEmailLoading,
            cancel: cancelSendConfirmationEmail
        },
        confirmDevice: {
            mutation: confirmDevice,
            isLoading: confirmDeviceIsLoading,
            cancel: cancelConfirmDevice
        },
        getDevices: {
            query: getDevices,
            isLoading: getDevicesIsLoading,
            cancel: cancelGetDevices
        }
    };
};
