import React from 'react';
import {GridCustomHeaderCellProps} from "@progress/kendo-react-grid";
import {HeaderThElement} from "@progress/kendo-react-data-tools";
import {createPortal} from "react-dom";
import {Tooltip} from "react-tooltip";

const GridDefaultHeaderTemplate = (props: GridCustomHeaderCellProps) => {
    const style: React.CSSProperties = {
        fontSize: '12px',
        color: '#1b1b1b',
        fontWeight: 'bold'
    };

    let headerText = '';
    if (props.children && Array.isArray(props.children)) {
        let item = props.children[0] as any;
        if (item && item.props && item.props.title) {
            headerText = item.props.title;
        }
    }

    return (
        <HeaderThElement
            columnId={props.thProps?.columnId || ""}
            {...props.thProps}
        >
            <>
                {createPortal(<Tooltip id={props.thProps?.columnId || ""}
                                       style={{maxWidth: '200px', zIndex: '1005', maxHeight: '30px', fontSize: '11px'}}
                                       place={'top'}/>, document.body)
                }
                <span style={style}
                      className={'grid-header-center'}
                      data-tooltip-id={props.thProps?.columnId || ""}
                      data-tooltip-content={headerText}>
                {props.children}
            </span>
            </>
        </HeaderThElement>
    );
};

export default GridDefaultHeaderTemplate;