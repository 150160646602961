import {FilterStateEntityItem, IAccessFilterData, IFilterState} from "./IFilterState";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ILayoutConfig} from "../../app/interfaces/layoutConfig/ILayoutConfig";
import {LayoutConfigType} from "../../app/enums/LayoutConfigType";
import {LayoutConfigEntity} from "../../app/enums/LayoutConfigEntity";
import {FilterPanelConfig} from "../../app/types/FilterPanelConfig";
import {IUserAccessSettings} from "../../app/interfaces/user/IUserAccessSettings";
import {IGridFilter} from "../../app/interfaces/gridFilter/IGridFilter";
import {SelectModel} from "../../app/types/SelectModel";
import {getDefaultGridFilter, GridFilter} from "../../app/types/GridFilter";

export type FilterSliceEntity = Exclude<LayoutConfigEntity, LayoutConfigEntity.Global>;

const filterItemInitialState: FilterStateEntityItem = {
    query: null,
    personalFilters: null,
    selectedPersonalFilter: null,
    predefinedFilters: null,
    selectedPredefinedFilter: null,
    filter: getDefaultGridFilter(),
    filterToApply: getDefaultGridFilter()
}

const initialState: IFilterState = {
    configuration: {
        id: 0,
        type: LayoutConfigType.Filter,
        entity: LayoutConfigEntity.Global,
        value: JSON.stringify({
            isFilterPanelPinned: false,
            isFilterPanelShown: false,
            tab: 'Filters'
        } as FilterPanelConfig)
    },
    accessFilter: {
        settings: null,
        data: {
            forwarders: [],
            branches: [],
            departments: [],
            transportLinesSource: [],
            transportLinesDestination: [],
            serviceProviders: []
        }
    },
    state: {
        [LayoutConfigEntity.Shipment]: filterItemInitialState,
        [LayoutConfigEntity.Quote]: filterItemInitialState,
        [LayoutConfigEntity.Offer]: filterItemInitialState,
        [LayoutConfigEntity.Disposition]: filterItemInitialState,
        [LayoutConfigEntity.Invoice]: filterItemInitialState
    }
};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        clearFilterSlice: (state) => {
            state.accessFilter = initialState.accessFilter;
            state.state = initialState.state;
            state.configuration = initialState.configuration;
        },
        setFilterPanelConfiguration: (state, action: PayloadAction<ILayoutConfig | null>) => {
            if (action.payload) {
                state.configuration = action.payload;
            }
        },
        setFilterQuery: (state, action: PayloadAction<{
            entity: FilterSliceEntity,
            data: Array<{
                key: string,
                value: string
            }>
        }>) => {
            state.state[action.payload.entity].query = action.payload.data;
        },
        setPersonalFilters: (state, action: PayloadAction<{
            entity: FilterSliceEntity,
            data: Array<IGridFilter>;
        }>) => {
            state.state[action.payload.entity].personalFilters = action.payload.data;
        },
        setPredefinedFilters: (state, action: PayloadAction<{
            entity: FilterSliceEntity,
            data: Array<SelectModel>;
        }>) => {
            state.state[action.payload.entity].predefinedFilters = action.payload.data;
        },
        setFilter: (state, action: PayloadAction<{
            entity: FilterSliceEntity,
            data: GridFilter | null
        }>) => {
            state.state[action.payload.entity].filter = action.payload.data === null
                ? initialState.state[action.payload.entity].filter
                : action.payload.data;
        },
        setFilterToApply: (state, action: PayloadAction<{
            entity: FilterSliceEntity,
            data: GridFilter | null
        }>) => {
            state.state[action.payload.entity].filterToApply = action.payload.data === null
                ? initialState.state[action.payload.entity].filterToApply
                : action.payload.data;
        },
        setAccessSettings: (state, action: PayloadAction<IUserAccessSettings | null>) => {
            state.accessFilter.settings = action.payload;
        },
        setSelectedPersonalFilter: (state, action: PayloadAction<{
            entity: FilterSliceEntity,
            data: IGridFilter | null
        }>) => {
            state.state[action.payload.entity].selectedPersonalFilter = action.payload.data;

            if (action.payload.data) {
                state.state[action.payload.entity].filter = JSON.parse(action.payload.data.value);
            }
        },
        setSelectedPredefinedFilter: (state, action: PayloadAction<{
            entity: FilterSliceEntity,
            data: SelectModel | null
        }>) => {
            state.state[action.payload.entity].selectedPredefinedFilter = action.payload.data;
        },
        setAccessSettingsData: (state, action: PayloadAction<{
            entity: 'Forwarders' | 'Departments' | 'Branches' | 'TransportLinesSource' | 'TransportLinesDestination',
            data: IAccessFilterData
        }>) => {
            if (action.payload.entity === 'Forwarders') {
                state.accessFilter.data.forwarders = action.payload.data.forwarders;
            }

            if (action.payload.entity === 'Branches') {
                state.accessFilter.data.branches = action.payload.data.branches;
            }

            if (action.payload.entity === 'Departments') {
                state.accessFilter.data.departments = action.payload.data.departments;
            }

            if (action.payload.entity === 'TransportLinesSource') {
                state.accessFilter.data.transportLinesSource = action.payload.data.transportLinesSource;
            }

            if (action.payload.entity === 'TransportLinesDestination') {
                state.accessFilter.data.transportLinesDestination = action.payload.data.transportLinesDestination;
            }
        }
    }
});

export const {
    setFilterPanelConfiguration,
    setAccessSettings,
    setAccessSettingsData,
    setPersonalFilters,
    setPredefinedFilters,
    setFilter,
    setFilterToApply,
    setSelectedPersonalFilter,
    setSelectedPredefinedFilter,
    setFilterQuery,
    clearFilterSlice
} = filterSlice.actions;

export default filterSlice.reducer;