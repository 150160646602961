import React from 'react';
import {useDataHelperApi} from "../../../../../app/api/dataHelper";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {IGridResultResponse} from "../../../../../app/interfaces/response/IGridResultResponse";
import {ISelectTreeModel} from "../../../../../app/interfaces/shared/ISelectTreeModel";
import TagContainer from "../../../../ui/Tag/TagContainer/TagContainer";
import {TagItemBackgroundColor} from "../../../../ui/Tag/Tag";
import TreeListBox from "../../../../ui/TreeListBox/TreeListBox";
import {SelectModel} from "../../../../../app/types/SelectModel";

type FilterPanelResponsibleVisibilityFilterContentProps = {
    id: string;
    onClear: (value?: string) => void;
    onChange: (value: Array<SelectModel>) => void,
    value: Array<SelectModel>;
}

const FilterPanelResponsibleVisibilityFilterContent: React.FC<FilterPanelResponsibleVisibilityFilterContentProps> = ({
                                                                                                             id,
                                                                                                             onClear,
                                                                                                             onChange,
                                                                                                             value
                                                                                                         }) => {
    const {
        getResponsibleVisibilityTree: {
            query: getResponsibleVisibilityTree
        }
    } = useDataHelperApi();

    const load = async (skip: number, take: number, filter: string | null) => {
        try {
            const response = await getResponsibleVisibilityTree(filter, skip, take);

            if (response?.status === 200 && response.data) {
                return response.data;
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(`Unable to get responsible visibility items. Error: ${e.message}`);
            }
        }

        return {
            count: 0,
            result: []
        } as IGridResultResponse<ISelectTreeModel>;
    }

    return (
        <>
            <div style={{marginBottom: '5px'}}>
                <TagContainer id={`${id}-tag`}
                              width={'100%'}
                              height={'100px'}
                              items={value.map(item => {
                                  return {
                                      id: `item-${id}-${item.value}`,
                                      text: item.text,
                                      data: item,
                                      showClearIcon: true,
                                      backgroundColor: TagItemBackgroundColor.LightGray,
                                      rounded: true
                                  };
                              })}
                              onClear={({data}) => {
                                  if (data !== undefined && data !== null) {
                                      onClear(data.value as string);
                                  }
                              }}/>
            </div>
            <TreeListBox id={`${id}-tree-list-box`}
                         selected={value}
                         take={20}
                         height={250}
                         onDataLoad={load}
                         onChange={(ev) => {
                             onChange(ev.shaken.map(item => {
                                 return {
                                     text: item.text,
                                     value: item.value
                                 } as SelectModel
                             }));
                         }}/>
        </>
    );
};

export default FilterPanelResponsibleVisibilityFilterContent;