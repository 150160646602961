import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import ChangeEquipment from "./ChangeEquipment";

type ChangeEquipmentDialogProps = {
    id: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangeEquipmentDialog: React.FC<ChangeEquipmentDialogProps> = ({
                                                                         show,
                                                                         setShow,
                                                                         id
                                                                     }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'change-equipment-event-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("offer.grid.changing-equipment")}>
            <ChangeEquipment id={id}
                             close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default ChangeEquipmentDialog;