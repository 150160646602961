import React from 'react';
import cl from './NotificationGridComponent.module.css';
import {useTranslation} from "react-i18next";
import useGetScrollbarWidth from "../../../../../../hooks/useGetScrollbarWidth/useGetScrollbarWidth";
import Loader, {LoaderType} from "../../../../../../components/ui/loaders/Loader/Loader";
import Skeleton from "react-loading-skeleton";
import Checkbox from "../../../../../../components/ui/Checkbox/Checkbox";
import _ from "lodash";
import {EmailCategory} from "../../../../../../app/enums/EmailCategory";
import {
    IForwarderNotificationSetupGetAction
} from "../../../../../../app/interfaces/notification/IForwarderNotificationSetupGetAction";
import NotificationGridComponentCard from "./NotificationGridComponentCard/NotificationGridComponentCard";

type NotificationGridComponentProps = {
    disabled: boolean;
    isLoading: boolean;
    setups: Array<IForwarderNotificationSetupGetAction>;
    onChange: (items: Array<IForwarderNotificationSetupGetAction>, field: keyof IForwarderNotificationSetupGetAction, value: string | boolean) => void;
};

const NotificationGridComponent: React.FC<NotificationGridComponentProps> = ({
                                                                                 disabled,
                                                                                 isLoading,
                                                                                 setups,
                                                                                 onChange
                                                                             }) => {
    const {t, i18n} = useTranslation();
    const scrollBarWidth = useGetScrollbarWidth();

    let groupedSetups: Array<{
        value: Array<IForwarderNotificationSetupGetAction>,
        key: string
    }> = [..._.chain(setups.filter(e => e.category === EmailCategory.OrderRelease || e.category === EmailCategory.Quote || e.category === EmailCategory.Shipment))
        .groupBy(e => e.category)
        .map((value, key) => ({value, key}))
        .value(),
        {
            key: '0',
            value: [
                ..._.chain(setups.filter(e => e.category !== EmailCategory.OrderRelease && e.category !== EmailCategory.Quote && e.category !== EmailCategory.Shipment))
                    .groupBy(e => e.category)
                    .map((value, key) => ({value, key}))
                    .value()
                    .map(item => item.value),
            ].flat(1)
        }
    ].sort((a, b) => {
        return (Number(b.key) - Number(a.key));
    });

    return (
        <div className={`k-grid ${cl.grid}`}>
            <div className={`k-grid-aria-root`}>
                <div className={cl.headerContainer}>
                    <div className={cl.header} style={{paddingInlineEnd: scrollBarWidth}}>
                        <div style={{width: '5px'}} data-type={'one-row'}></div>

                        <div style={{width: '20%'}}
                             data-type={'one-row'}>
                            <div title={t("notification.labels.setup-name")}>
                                <span>
                                    {t("notification.labels.setup-name")}
                                </span>
                            </div>
                        </div>

                        <div style={{width: '20%'}}
                             data-type={'two-rows'}>
                            <div title={t("notification.labels.notify-if-my-role")}>
                                <span>
                                    {t("notification.labels.notify-if-my-role")}
                                </span>
                            </div>
                            <div className={cl.notifyRoleRow}>
                                <div>
                                    <div>
                                        <Checkbox disabled={disabled || isLoading}
                                                  checked={isLoading || setups.length <= 0
                                                      ? false
                                                      : setups.filter(e => e.toCM).length === setups.length
                                                  }
                                                  change={(val: boolean) => {
                                                      onChange(setups, 'toCM', val);
                                                  }}/>
                                    </div>

                                    <div>
                                        {t("shared.grid-header.cm")}
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <Checkbox disabled={disabled || isLoading}
                                                  checked={isLoading || setups.length <= 0
                                                      ? false
                                                      : setups.filter(e => e.toTL).length === setups.length
                                                  }
                                                  change={(val: boolean) => {
                                                      onChange(setups, 'toTL', val);
                                                  }}/>
                                    </div>

                                    <div>
                                        {t("shared.grid-header.tlm")}
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <Checkbox disabled={disabled || isLoading}
                                                  checked={isLoading || setups.length <= 0
                                                      ? false
                                                      : setups.filter(e => e.toFA).length === setups.length
                                                  }
                                                  change={(val: boolean) => {
                                                      onChange(setups, 'toFA', val);
                                                  }}/>
                                    </div>

                                    <div>
                                        {t("shared.grid-header.sm-fa")}
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <Checkbox disabled={disabled || isLoading}
                                                  checked={isLoading || setups.length <= 0
                                                      ? false
                                                      : setups.filter(e => e.toExA).length === setups.length
                                                  }
                                                  change={(val: boolean) => {
                                                      onChange(setups, 'toExA', val);
                                                  }}/>
                                    </div>

                                    <div>
                                        {t("shared.grid-header.sm-exa")}
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <Checkbox disabled={disabled || isLoading}
                                                  checked={isLoading || setups.length <= 0
                                                      ? false
                                                      : setups.filter(e => e.toOR).length === setups.length
                                                  }
                                                  change={(val: boolean) => {
                                                      onChange(setups, 'toOR', val);
                                                  }}/>
                                    </div>

                                    <div>
                                        {t("shared.grid-header.om")}
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <Checkbox disabled={disabled || isLoading}
                                                  checked={isLoading || setups.length <= 0
                                                      ? false
                                                      : setups.filter(e => e.toSA).length === setups.length
                                                  }
                                                  change={(val: boolean) => {
                                                      onChange(setups, 'toSA', val);
                                                  }}/>
                                    </div>

                                    <div>
                                        SA
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{width: '15%'}}
                             data-type={'one-row'}>
                            <div title={t("notification.labels.notify-period")}>
                                <span>
                                    {t("notification.labels.notify-period")}
                                </span>
                            </div>
                        </div>

                        <div style={{width: '15%'}}
                             data-type={'two-rows'}>
                            <div title={t("notification.labels.notify-if-sender-is")}>
                                <span>
                                    {t("notification.labels.notify-if-sender-is")}
                                </span>
                            </div>
                            <div className={cl.notifyIfSenderRow}>
                                <div>
                                    <div>
                                        <Checkbox disabled={disabled || isLoading}
                                                  checked={isLoading || setups.length <= 0
                                                      ? false
                                                      : setups.filter(e => !e.fromSystem && e.fromSelf).length === setups.filter(e => !e.fromSystem).length
                                                  }
                                                  change={(val: boolean) => {
                                                      onChange(setups.filter(e => !e.fromSystem), 'fromSelf', val);
                                                  }}/>
                                    </div>

                                    <div>
                                        {t("notification.labels.from-self")}
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <Checkbox disabled={disabled || isLoading}
                                                  checked={isLoading || setups.length <= 0
                                                      ? false
                                                      : setups.filter(e => !e.fromSystem && e.fromFw).length === setups.filter(e => !e.fromSystem).length
                                                  }
                                                  change={(val: boolean) => {
                                                      onChange(setups.filter(e => !e.fromSystem), 'fromFw', val);
                                                  }}/>
                                    </div>

                                    <div>
                                        {t("notification.labels.from-forwarder")}
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <Checkbox disabled={disabled || isLoading || true}
                                                  checked={isLoading || setups.length <= 0
                                                      ? false
                                                      : setups.filter(e => !e.fromSystem && e.fromSp).length === setups.filter(e => !e.fromSystem).length
                                                  }
                                                  change={(val: boolean) => {
                                                      onChange(setups.filter(e => !e.fromSystem), 'fromSp', val);
                                                  }}/>
                                    </div>

                                    <div>
                                        {t("notification.labels.from-service-provider")}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{width: '15%'}}
                             data-type={'two-rows'}>

                            <div title={t("notification.labels.notify-email")}>
                                <span>
                                    {t("notification.labels.notify-email")}
                                </span>
                            </div>
                            <div style={{textAlign: 'center'}}>
                                <div>
                                    <div>
                                        <Checkbox disabled={disabled || isLoading}
                                                  checked={isLoading || setups.length <= 0
                                                      ? false
                                                      : setups.filter(e => e.isEmail).length === setups.length
                                                  }
                                                  change={(val: boolean) => {
                                                      onChange(setups, 'isEmail', val);
                                                  }}/>
                                    </div>

                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{width: '15%'}}
                             data-type={'one-row'}>
                            <div title={t("notification.labels.cc-email-short")}>
                                <span>
                                    {t("notification.labels.cc-email-short")}
                                </span>
                            </div>
                        </div>

                        <div style={{width: '5px'}} data-type={'one-row'}></div>
                    </div>
                </div>
                <div className={`k-grid-container`}>
                    {isLoading
                        ? <div className={cl.loader}>
                            <div>
                                <Loader type={LoaderType.Action} style={{scale: '0.4'}}/>
                            </div>
                        </div>
                        : null
                    }

                    <div className={'k-grid-content'}>
                        {groupedSetups.map(setup => {
                            let category: string;

                            if (setup.key === '0' || setup.key === '1' || setup.key === '2' || setup.key === '7') {
                                category = t(`notification.category.${setup.key}`);
                            } else {
                                category = t("notification.category.0");
                            }

                            return (
                                <div className={`mgt-5 mgb-20`} key={setup.key}>
                                    <div className={`default-form-collapse-header ${cl.collapse}`}>
                                        <div style={{width: '5px'}}></div>

                                        <div style={{width: '20%'}}>
                                            {category}
                                        </div>

                                        <div style={{width: '20%'}} className={cl.notifyRoleRow}>
                                            <div>
                                                <Checkbox disabled={disabled || isLoading}
                                                          checked={isLoading || setups.length <= 0
                                                              ? false
                                                              : setups.filter(e => e.category === Number(setup.key) && e.toCM).length === setups.filter(e => e.category === Number(setup.key)).length
                                                          }
                                                          change={(val: boolean) => {
                                                              onChange(setups.filter(e => e.category === Number(setup.key)),
                                                                  'toCM', val);
                                                          }}/>
                                            </div>

                                            <div>
                                                <Checkbox disabled={disabled || isLoading}
                                                          checked={isLoading || setups.length <= 0
                                                              ? false
                                                              : setups.filter(e => e.category === Number(setup.key) && e.toTL).length === setups.filter(e => e.category === Number(setup.key)).length
                                                          }
                                                          change={(val: boolean) => {
                                                              onChange(setups.filter(e => e.category === Number(setup.key)),
                                                                  'toTL', val);
                                                          }}/>
                                            </div>

                                            <div>
                                                <Checkbox disabled={disabled || isLoading}
                                                          checked={isLoading || setups.length <= 0
                                                              ? false
                                                              : setups.filter(e => e.category === Number(setup.key) && e.toFA).length === setups.filter(e => e.category === Number(setup.key)).length
                                                          }
                                                          change={(val: boolean) => {
                                                              onChange(setups.filter(e => e.category === Number(setup.key)),
                                                                  'toFA', val);
                                                          }}/>
                                            </div>

                                            <div>
                                                <Checkbox disabled={disabled || isLoading}
                                                          checked={isLoading || setups.length <= 0
                                                              ? false
                                                              : setups.filter(e => e.category === Number(setup.key) && e.toExA).length === setups.filter(e => e.category === Number(setup.key)).length
                                                          }
                                                          change={(val: boolean) => {
                                                              onChange(setups.filter(e => e.category === Number(setup.key)),
                                                                  'toExA', val);
                                                          }}/>
                                            </div>

                                            <div>
                                                <Checkbox disabled={disabled || isLoading}
                                                          checked={isLoading || setups.length <= 0
                                                              ? false
                                                              : setups.filter(e => e.category === Number(setup.key) && e.toOR).length === setups.filter(e => e.category === Number(setup.key)).length
                                                          }
                                                          change={(val: boolean) => {
                                                              onChange(setups.filter(e => e.category === Number(setup.key)),
                                                                  'toOR', val);
                                                          }}/>
                                            </div>

                                            <div>
                                                <Checkbox disabled={disabled || isLoading}
                                                          checked={isLoading || setups.length <= 0
                                                              ? false
                                                              : setups.filter(e => e.category === Number(setup.key) && e.toSA).length === setups.filter(e => e.category === Number(setup.key)).length
                                                          }
                                                          change={(val: boolean) => {
                                                              onChange(setups.filter(e => e.category === Number(setup.key)),
                                                                  'toSA', val);
                                                          }}/>
                                            </div>
                                        </div>

                                        <div style={{width: '15%'}}>

                                        </div>

                                        <div style={{width: '15%'}} className={cl.notifyIfSenderRow}>
                                            <div>
                                                <Checkbox disabled={disabled || isLoading}
                                                          checked={isLoading || setups.length <= 0
                                                              ? false
                                                              : setups.filter(e => e.category === Number(setup.key) && !e.fromSystem && e.fromSelf).length === setups.filter(e => e.category === Number(setup.key) && !e.fromSystem).length
                                                          }
                                                          change={(val: boolean) => {
                                                              onChange(setups.filter(e => !e.fromSystem && e.category === Number(setup.key)),
                                                                  'fromSelf', val);
                                                          }}/>
                                            </div>

                                            <div>
                                                <Checkbox disabled={disabled || isLoading}
                                                          checked={isLoading || setups.length <= 0
                                                              ? false
                                                              : setups.filter(e => e.category === Number(setup.key) && !e.fromSystem && e.fromFw).length === setups.filter(e => e.category === Number(setup.key) && !e.fromSystem).length
                                                          }
                                                          change={(val: boolean) => {
                                                              onChange(setups.filter(e => !e.fromSystem && e.category === Number(setup.key)),
                                                                  'fromFw', val);
                                                          }}/>
                                            </div>

                                            <div>
                                                <Checkbox disabled={disabled || isLoading || true}
                                                          checked={isLoading || setups.length <= 0
                                                              ? false
                                                              : setups.filter(e => e.category === Number(setup.key) && !e.fromSystem && e.fromSp).length === setups.filter(e => e.category === Number(setup.key) && !e.fromSystem).length
                                                          }
                                                          change={(val: boolean) => {
                                                              onChange(setups.filter(e => !e.fromSystem && e.category === Number(setup.key)),
                                                                  'fromSp', val);
                                                          }}/>
                                            </div>
                                        </div>

                                        <div style={{width: '15%', textAlign: 'center'}}>
                                            <Checkbox disabled={disabled || isLoading}
                                                      checked={isLoading || setups.length <= 0
                                                          ? false
                                                          : setups.filter(e => e.category === Number(setup.key) && e.isEmail).length === setups.filter(e => e.category === Number(setup.key)).length
                                                      }
                                                      change={(val: boolean) => {
                                                          onChange(setups.filter(e => e.category === Number(setup.key)),
                                                              'isEmail', val);
                                                      }}/>
                                        </div>

                                        <div style={{width: '15%'}}>

                                        </div>

                                        <div style={{width: '5px'}}></div>
                                    </div>

                                    <div style={{padding: '5px 0 0 0'}}>
                                        {setup.value
                                            .sort((a, b) => {
                                                let nameA = a.nameLocales.find(e => e.value === i18n.language.toLowerCase())?.text ?? '';
                                                let nameB = b.nameLocales.find(e => e.value === i18n.language.toLowerCase())?.text ?? '';

                                                return nameA.localeCompare(nameB);
                                            })
                                            .map(st => {
                                                return <NotificationGridComponentCard key={st.templateId}
                                                                                      onChange={onChange}
                                                                                      setup={st}
                                                                                      disabled={disabled || isLoading}
                                                                                      isLoading={isLoading}/>
                                            })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className={cl.footer}>
                <div>
                    {isLoading
                        ? <Skeleton height={'15px'}
                                    width={'150px'}/>
                        : <>{`${t("shared.labels.elements-count").replace('{0}', setups.length.toString())}`}</>
                    }
                </div>
            </div>
        </div>
    );
};

export default NotificationGridComponent;