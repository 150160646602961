import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {GridLayoutLoadingType} from "../../models/enums/GridLayoutLoadingType";
import {GridLayoutView} from "../../models/enums/GridLayoutView";
import SkeletonLoadingScreen from "../../../../ui/loaders/SkeletonLoadingScreen/SkeletonLoadingScreen";
import cl from './GridLayoutStructure.module.css';
import SkeletonGridLoader
    from "../../../../ui/loaders/SkeletonLoadingScreen/components/SkeletonGridLoader/SkeletonGridLoader";
import SkeletonMapLoader
    from "../../../../ui/loaders/SkeletonLoadingScreen/components/SkeletonMapLoader/SkeletonMapLoader";
import {useGridLayoutContext} from "../../hooks/useGridLayoutContext";
import GridLayoutFilterPanel from "../GridLayoutFilterPanel/GridLayoutFilterPanel";
import {LayoutConfigEntity} from "../../../../../app/enums/LayoutConfigEntity";
import {
    useGridFilterPanelLayoutConfiguration
} from "../../../GridFilterPanelLayout/hooks/useGridFilterPanelLayoutConfiguration";
import useIfFirstRender from "../../../../../hooks/useIsFirstRender/useIfFirstRender";
import Icon from "../../../../../assets/icon/Icon";
import {useTranslation} from "react-i18next";
import useMediaQuery from "../../../../../hooks/useMediaQuery/useMediaQuery";
import {isMobile} from "react-device-detect";

type GridLayoutStructureProps = {
    view: GridLayoutView | null;
    loading: GridLayoutLoadingType;
    entity: LayoutConfigEntity;

    grid: ReactNode;
    header: ReactNode;
    map?: ReactNode;
    filterPanel?: ReactNode;
}

const GridLayoutStructure = ({
                                 view,
                                 loading,
                                 grid,
                                 header,
                                 map,
                                 filterPanel,
                                 entity
                             }: GridLayoutStructureProps) => {
    const {t} = useTranslation();
    const headerRef = useRef<HTMLDivElement | null>(null);
    const isFirstRender = useIfFirstRender();
    const isMaxWidth600 = useMediaQuery('(max-width:600px)');

    const [headerContainerHeight, setHeaderContainerHeight] = useState<number>(0);

    const {
        isHeaderDisabled,
        isFilterPanelShown,
        setIsFilterPanelShown
    } = useGridLayoutContext<any>();

    const {
        isFilterPanelShown: isConfigFilterPanelShown,
        configuration,
        saveConfiguration
    } = useGridFilterPanelLayoutConfiguration({
        entity: entity,
        onInitialParametersSet: (shown) => {
            setIsFilterPanelShown(shown);
        }
    });

    useEffect(() => {
        if (!configuration || isFirstRender || !filterPanel) {
            return;
        }

        (async () => {
            await saveConfiguration({
                ...configuration,
                isFilterPanelShown: isFilterPanelShown
            })
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFilterPanelShown, isFirstRender]);

    useEffect(() => {
        if (!headerRef.current || view === null)
            return;

        const resizeObserver = new ResizeObserver(() => {
            let height = headerRef.current?.clientHeight ?? 0;
            if (height !== headerContainerHeight) {
                setHeaderContainerHeight(height);
            }
        });

        resizeObserver.observe(headerRef.current);

        return () => resizeObserver.disconnect();

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view]);

    if (loading === GridLayoutLoadingType.Unset) {
        return null;
    }

    return (
        <div className={`w100 h100 hidden relative ${cl.container}`}
             data-markup={isFilterPanelShown && filterPanel && loading === GridLayoutLoadingType.None ? 'f' : 'default'}>
            {loading === GridLayoutLoadingType.GridAndHeader && <SkeletonLoadingScreen type={'Grid'}/>}

            {loading === GridLayoutLoadingType.MapAndHeader && <SkeletonLoadingScreen type={'Map'}/>}

            {view !== null &&
                <>
                    {loading === GridLayoutLoadingType.None &&
                        <GridLayoutFilterPanel filterPanel={filterPanel}
                                               isHeaderDisabled={isHeaderDisabled}
                                               isMobileView={isMobile || isMaxWidth600}/>
                    }

                    <div className={cl.content}>
                        <div ref={headerRef}
                             className={cl.header}
                             data-visibility={loading === GridLayoutLoadingType.GridAndHeader || loading === GridLayoutLoadingType.MapAndHeader ? 'hidden' : 'default'}>
                            {filterPanel
                                ? <div className={cl.filterHeader}>
                                    <div style={{height: '55px'}}>
                                        <button className={`${cl.filterBtn} default-form-close-btn`}
                                                disabled={isHeaderDisabled}
                                                title={t("shared.labels.filters")}
                                                data-open={isConfigFilterPanelShown ? 'true' : 'false'}
                                                onClick={() => {
                                                    if (!configuration || isHeaderDisabled) {
                                                        return;
                                                    }

                                                    let val = !isFilterPanelShown;
                                                    setIsFilterPanelShown(val);
                                                }}>
                                            <Icon icon={isConfigFilterPanelShown ? "faXMark" : "faBarFilter"}/>
                                        </button>
                                    </div>

                                    <div>
                                        {header}
                                    </div>
                                </div>
                                : <>{header}</>
                            }
                        </div>

                        <div className={cl.grid}
                             style={{height: `calc(100% - ${headerContainerHeight}px)`}}
                             data-visibility={loading !== GridLayoutLoadingType.None ? 'hidden' : 'default'}>
                            {view === GridLayoutView.Grid
                                ? <>{grid}</>
                                : null
                            }

                            {map && view === GridLayoutView.Map
                                ? <>{map}</>
                                : null
                            }
                        </div>

                        {loading === GridLayoutLoadingType.Grid &&
                            <div style={{height: `calc(100% - ${headerContainerHeight}px)`}}>
                                <SkeletonGridLoader/>
                            </div>
                        }

                        {loading === GridLayoutLoadingType.Map &&
                            <div style={{height: `calc(100% - ${headerContainerHeight}px)`}}>
                                <SkeletonMapLoader/>
                            </div>
                        }
                    </div>
                </>
            }
        </div>
    );
};

export default GridLayoutStructure;