import {isDesktopSafari, isWebKit, isWebKit606OrNewer} from "../helpers/browser";

const enum SpecialFingerprint {
    KnownToSuspend = -1,
    NotSupported = -2,
    Timeout = -3,
}

const enum InnerErrorName {
    Timeout = 'timeout',
    Suspended = 'suspended',
}

export default async function getAudioFingerprint(): Promise<number> {
    const w = window
    const AudioContext = w.OfflineAudioContext || w.webkitOfflineAudioContext
    if (!AudioContext) {
        return SpecialFingerprint.NotSupported
    }

    if (doesCurrentBrowserSuspendAudioContext()) {
        return SpecialFingerprint.KnownToSuspend
    }

    const hashFromIndex = 4500
    const hashToIndex = 5000
    const context = new AudioContext(1, hashToIndex, 44100)

    const oscillator = context.createOscillator()
    oscillator.type = 'triangle'
    setAudioParam(context, oscillator.frequency, 10000)

    const compressor = context.createDynamicsCompressor()
    setAudioParam(context, compressor.threshold, -50)
    setAudioParam(context, compressor.knee, 40)
    setAudioParam(context, compressor.ratio, 12)
    setAudioParam(context, compressor.reduction, -20)
    setAudioParam(context, compressor.attack, 0)
    setAudioParam(context, compressor.release, 0.25)

    oscillator.connect(compressor)
    compressor.connect(context.destination)
    oscillator.start(0)

    let buffer: AudioBuffer
    try {
        buffer = await renderAudio(context)
    } catch (error) {
        const err = error as any;

        if (err.name === InnerErrorName.Timeout || err.name === InnerErrorName.Suspended) {
            return SpecialFingerprint.Timeout
        }
        throw error
    } finally {
        oscillator.disconnect()
        compressor.disconnect()
    }

    return getHash(buffer.getChannelData(0).subarray(hashFromIndex, hashToIndex))
}

function doesCurrentBrowserSuspendAudioContext() {
    return isWebKit() && !isDesktopSafari() && !isWebKit606OrNewer()
}

function setAudioParam(context: BaseAudioContext, param: unknown, value: number) {
    const isAudioParam = (value: unknown): value is AudioParam =>
        value as boolean && typeof (value as AudioParam).setValueAtTime === 'function'

    if (isAudioParam(param)) {
        param.setValueAtTime(value, context.currentTime)
    }
}

function renderAudio(context: OfflineAudioContext) {
    const resumeTriesMaxCount = 3
    const resumeRetryDelay = 500
    const runningTimeout = 1000

    return new Promise<AudioBuffer>((resolve, reject) => {
        context.oncomplete = (event) => resolve(event.renderedBuffer)

        let resumeTriesLeft = resumeTriesMaxCount

        const tryResume = () => {
            context.startRendering()

            switch (context.state) {
                case 'running':
                    setTimeout(() => reject(makeInnerError(InnerErrorName.Timeout)), runningTimeout)
                    break
                case 'suspended':
                    if (!document.hidden) {
                        resumeTriesLeft--
                    }
                    if (resumeTriesLeft > 0) {
                        setTimeout(tryResume, resumeRetryDelay)
                    } else {
                        reject(makeInnerError(InnerErrorName.Suspended))
                    }
                    break
            }
        }

        tryResume()
    })
}

function getHash(signal: ArrayLike<number>): number {
    let hash = 0
    for (let i = 0; i < signal.length; ++i) {
        hash += Math.abs(signal[i])
    }
    return hash
}

function makeInnerError(name: InnerErrorName) {
    const error = new Error(name)
    error.name = name
    return error
}