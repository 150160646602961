import React from 'react';
import {GridRowProps} from "@progress/kendo-react-grid";

type EditRowRenderProps = {
    originalProps: GridRowProps;
    tr: React.ReactElement<HTMLTableRowElement>;
    exitEdit: () => void;
    editField: string | undefined;
};

const EditRowRender: React.FC<EditRowRenderProps> = (props) => {
    let preventExit = false;
    const trProps = {
        ...props.tr.props,
        onMouseDown: () => {
            preventExit = true;
        },
        onBlur: () => {
            if (!preventExit) {
                props.exitEdit();
            }
            preventExit = false;
        },
    };

    return React.cloneElement(props.tr, {...trProps}, props.tr.props.children as any);
};

export default EditRowRender;