import React, {useState} from 'react';
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ttTrackingIcon from '../../../../../../assets/images/tt-tracking.png';
import viberIcon from '../../../../../../assets/images/viber.png';
import cl from './ServiceProviderTemplate.module.css';
import UserChat from "../../../../../../components/shared/components/Chat/components/UserChat/UserChat";
import SupplierDataDialog
    from "../../../../../../components/shared/form/detailsFields/SupplierData/SupplierDataDialog/SupplierDataDialog";
import Icon from "../../../../../../assets/icon/Icon";

const ServiceProviderTemplate = (props: any) => {
    const {type} = useParams();
    const user = useAppSelector(selectCurrentUser);
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const {t} = useTranslation();
    const [isDataDialogShown, setIsDataDialogShown] = useState<boolean>(false);

    let releaseNumber = props.dataItem['releaseNumber'];
    let shipmentId = props.dataItem['sId'];

    if (!releaseNumber || releaseNumber === '' || !shipmentId || shipmentId === '' || !user || !type || type === 'archive') {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                  <span className={'default-left-cell default-overflow-cell'}>
                            {props.children}
                  </span>
            </td>
        )
    }

    const role = getUserRole(user);


    if (role === UserRole.ServiceProvider || role === UserRole.Client
        || (props.dataItem["hasRegistedAccount"] === false && !props.dataItem['serviceProvider'])) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                   <span className={'default-left-cell default-overflow-cell'}>
                        {props.children}
                    </span>
            </td>
        );
    }

    let hasMessengerDistribution = props.dataItem["hasMessengerDistribution"] !== null && props.dataItem["hasMessengerDistribution"] !== undefined
        ? Boolean(props.dataItem["hasMessengerDistribution"])
        : false;

    let hasTTTracking = props.dataItem["isTTTracking"] !== null && props.dataItem["isTTTracking"] !== undefined
        ? Boolean(props.dataItem["isTTTracking"])
        : false;

    let hasShippeoTracking = props.dataItem["isShippeoTracking"] !== null && props.dataItem["isShippeoTracking"] !== undefined
        ? Boolean(props.dataItem["isShippeoTracking"])
        : false;

    if (props.dataItem["hasRegistedAccount"] === false) {
        return (
            <>
                <td {...props.tdProps}
                    colSpan={1}
                    className={`grid-prevent-selection`}>
                    {user.id &&
                        <SupplierDataDialog ids={props.dataItem['spId']}
                                            show={isDataDialogShown}
                                            setShow={setIsDataDialogShown}/>
                    }

                    <span onClick={handleClick}
                          className={`default-flex-cell default-overflow-cell grid-prevent-selection`}
                          style={{cursor: "pointer"}}>
                        <Icon icon={"faNoUser"}
                              title={t('shared.labels.service-provider-not-registered')}
                              style={{
                                  color: '#C4C4C4',
                                  fontSize: '16px',
                                  verticalAlign: 'middle',
                                  marginRight: '5px',
                              }}/>

                        {hasMessengerDistribution &&
                            <img src={viberIcon}
                                 className={cl.imgIcon}
                                 alt={t('shared.labels.messengers-distribution-label')}
                                 title={t('shared.labels.messengers-distribution-label')}/>
                        }

                        {hasTTTracking &&
                            <img src={ttTrackingIcon}
                                 className={cl.imgIcon}
                                 alt={t('shipment.grid.tt-tracking')}
                                 title={t('shipment.grid.tt-tracking')}/>
                        }

                        {hasShippeoTracking &&
                            <Icon icon={"faCircleLocationDot"}
                                  title={t('shipment.grid.shippeo-tracking')}
                                  style={{
                                      verticalAlign: 'middle',
                                      marginRight: '5px',
                                      fontSize: '16px',
                                      color: 'var(--main-orange-color)'
                                  }}/>
                        }

                        <div className={`${cl.container} transition-all-500`}
                             style={{color: 'inherit', textAlign: 'left'}}
                             onClick={(ev) => {
                                 ev.stopPropagation();

                                 setIsDataDialogShown(prev => !prev);
                             }}>
                                            {props.dataItem['serviceProvider']}
                            </div>
                        </span>
                </td>
            </>
        );
    } else {
        let serviceProviderId = props.dataItem['spId'];
        let group = props.dataItem['gr'];

        let description = '';

        if (props.dataItem['pickupDate'] && props.dataItem['sourceDistrict'] && props.dataItem['sourceCity']
            && props.dataItem['destDistrict'] && props.dataItem['destCity']) {
            description = `${props.dataItem['pickupDate']}, ${props.dataItem['sourceDistrict']} ${props.dataItem['sourceDistrict']}`;

            description += ` - ${props.dataItem['destDistrict']} ${props.dataItem['destCity']}`
        }

        return (
            <td {...props.tdProps}
                colSpan={1}>
                {user.id &&
                    <SupplierDataDialog ids={props.dataItem['spId']}
                                        show={isDataDialogShown}
                                        setShow={setIsDataDialogShown}/>
                }

                <span className={`default-flex-cell default-overflow-cell grid-prevent-selection`}
                      style={{cursor: 'pointer'}}>
                     {getUserRole(user) !== UserRole.ServiceProvider &&
                         <div style={{marginRight: '5px', marginTop: '2px'}}>
                             <UserChat user={{
                                 role: UserRole.ServiceProvider,
                                 id: serviceProviderId,
                                 serviceProvider: serviceProviderId,
                                 groupId: group
                             }} replyTo={{
                                 type: 'SHIPMENT',
                                 id: releaseNumber,
                                 idExt: shipmentId,
                                 description: description === '' ? null : description
                             }}/>
                         </div>
                     }

                    <span className={`default-flex-cell default-overflow-cell grid-prevent-selection`}
                          style={{paddingLeft: 0}} onClick={handleClick}>
                        {hasMessengerDistribution &&
                            <img src={viberIcon}
                                 className={cl.imgIcon}
                                 alt={t('shared.labels.messengers-distribution-label')}
                                 title={t('shared.labels.messengers-distribution-label')}/>
                        }

                        {hasTTTracking &&
                            <img src={ttTrackingIcon}
                                 className={cl.imgIcon}
                                 alt={t('shipment.grid.tt-tracking')}
                                 title={t('shipment.grid.tt-tracking')}/>
                        }

                        {hasShippeoTracking &&
                            <Icon icon={"faCircleLocationDot"}
                                  title={t('shipment.grid.shippeo-tracking')}
                                  style={{
                                      verticalAlign: 'middle',
                                      marginRight: '5px',
                                      fontSize: '16px',
                                      color: 'var(--main-orange-color)'
                                  }}/>
                        }

                        <div className={`${cl.container} transition-all-500`}
                             style={{color: 'inherit', textAlign: 'left'}}
                             onClick={(ev) => {
                                 ev.stopPropagation();

                                 setIsDataDialogShown(prev => !prev);
                             }}>
                                 {props.dataItem['serviceProvider']}
                             </div>
                    </span>
                </span>
            </td>
        );
    }
};

export default ServiceProviderTemplate;
