import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../app/store";
import {setDeviceId, setUtcOffset} from "../../../features/application/applicationSlice";
import {getTimezoneOffsetInSeconds} from "../../../helpers/date";
import useIfFirstRender from "../../../hooks/useIsFirstRender/useIfFirstRender";
import {Outlet} from "react-router-dom";
import LoadingScreen from "../../ui/loaders/LoadingScreen/LoadingScreen";
import {selectUserAuthenticationStatus} from "../../../features/authentication/authenticationSliceSelectors";
import {AuthenticationStatus} from "../../../features/authentication/IAuthenticationState";
import {validateUserAuthentication} from "../../../features/authentication/authenticationSlice";
import {load} from "../../../utils/fingerprint/agent";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {selectLanguage} from "../../../features/application/applicationSliceSelectors";
import {useTranslation} from "react-i18next";
import {Language} from "../../../app/enums/Language";
import {isMobileOnly} from "react-device-detect";

const BaseLayout = () => {
    const {i18n} = useTranslation();
    const dispatch = useAppDispatch();
    const isFirstRender = useIfFirstRender();
    const userAuthenticationStatus = useAppSelector(selectUserAuthenticationStatus);
    const language = useAppSelector(selectLanguage);

    useEffect(() => {
        if (isFirstRender) {
            return;
        }
        if(!Language[language])
            return;

        if (i18n.language.toLowerCase() === Language[language].toString().toLowerCase()) {
            return;
        }

        (async () => {
            await i18n.changeLanguage(Language[language].toString().toLowerCase());
        })();
    }, [i18n, language, dispatch, isFirstRender]);

    useEffect(() => {
        if (isFirstRender) {
            if (isMobileOnly)
                document.body.className += ' mobile';

            (async () => {
                // This call sets user deviceId(fingerprint)
                const fp = await load();

                const {visitorId} = await fp.get();

                dispatch(setDeviceId(visitorId));
            })();

            // This call sets global user utc offset, later used in Axios interceptor
            // to send as header param
            dispatch(setUtcOffset(getTimezoneOffsetInSeconds()));

            // This call sets global user authentication state, if successfully
            // render <AuthorizedLayout />
            dispatch(validateUserAuthentication());
        }
    }, [dispatch, isFirstRender]);

    if (userAuthenticationStatus === AuthenticationStatus.InProgress ||
        userAuthenticationStatus === AuthenticationStatus.NoAction) {
        return <LoadingScreen/>
    }

    // When authentication successfully done, render <AuthorizedLayout />,
    // where will it be produced Device authentication. To see where the redirect happens ->
    // -> app/router/ProtectedRoute
    return (
        <>
            <ToastContainer position="top-right"
                            className={'toast-container'}
                            autoClose={5000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick={false}
                            rtl={false}
                            pauseOnFocusLoss={false}
                            draggable={false}
                            pauseOnHover
                            bodyClassName='toast-body-class'
                            theme="light"/>
            <Outlet/>
        </>
    );
};

export default BaseLayout;
