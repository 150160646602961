import React from 'react';
import moment from "moment/moment";
import {RouteSectionModel} from "../../RouteSection";

type YearlyPickupDateProps = {
    model: RouteSectionModel;
}

const buildDate = (model: RouteSectionModel): string => {
    if (model.hasShipment && model.earlyPickupDate !== undefined && model.earlyPickupDate !== null) {
        return moment(model.earlyPickupDate).format('DD.MM.YY HH:mm');
    }

    if (model.earlyPickupDate === null || model.earlyPickupDate === undefined)
        return '';

    let str: string = moment(model.earlyPickupDate).format('DD.MM.YY HH:mm');

    if (model.latePickupDate !== undefined &&
        model.latePickupDate !== null &&
        model.latePickupDate !== model.earlyPickupDate) {
        str += `- ${moment(model.latePickupDate).format('DD.MM.YY HH:mm')}`
    }

    return str;
}

const YearlyPickupDate: React.FC<YearlyPickupDateProps> = ({
                                                               model
                                                           }) => {
    return (
        <>
            {model.earlyPickupDateText !== null && model.earlyPickupDateText !== ''
                ? <div style={{height: '17px'}}>
                    {buildDate(model)}
                </div>
                : <div style={{height:'17px'}}>

                </div>
            }
        </>
    );
};

export default YearlyPickupDate;