import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";
import {SignalRHubOptions} from "../../hooks/useSignalRHub/useSignalRHub";

export default class HubConnector {
    private readonly connection: HubConnection | null;
    static instance: HubConnector;

    private constructor(hubUrl: string, options?: SignalRHubOptions) {
        const hubConnectionBuild = new HubConnectionBuilder()
            .withUrl(hubUrl);

        if (options) {
            if (options.automaticReconnect) {
                //if (options.automaticReconnect === true)
                    hubConnectionBuild.withAutomaticReconnect();
               // else {

                //    hubConnectionBuild.withAutomaticReconnect(options.automaticReconnect);
               // }
            }

            if (options.logging)
                hubConnectionBuild.configureLogging(options.logging);
        }

        this.connection = hubConnectionBuild.build();
    }

    public static getInstance(hubUrl: string, options?: SignalRHubOptions): HubConnector {
        if (!HubConnector.instance) {
            HubConnector.instance = new HubConnector(hubUrl, options);
        }

        return HubConnector.instance;
    }

    public getConnection(): HubConnection | null {
        return this.connection;
    }
}
