import {useEffect, useState} from "react";

const useGetScrollbarWidth = (): number => {
    const [width, setWidth] = useState<number>(0);

    useEffect(() => {
        const scrollDiv = document.createElement("div");
        scrollDiv.className = "scrollbar-to-measure";

        document.body.appendChild(scrollDiv);

        setWidth(scrollDiv.offsetWidth - scrollDiv.clientWidth);

        document.body.removeChild(scrollDiv);
    }, []);

    return width;
};

export default useGetScrollbarWidth;