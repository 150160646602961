import React, {useEffect, useState} from 'react';
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {IClientStatus} from "../../../../../app/interfaces/offer/IClientStatus";
import {useOfferApi} from "../../../../../app/api/offer";
import {useTranslation} from "react-i18next";

type ClientStatusProps = {
    id: string;
}

const ClientStatus: React.FC<ClientStatusProps> = ({
                                                       id
                                                   }) => {
    const {t} = useTranslation();
    const [model, setModel] = useState<IClientStatus | null>(null);

    const {
        getClientStatus: {
            isLoading,
            cancel,
            query: getClientStatus
        },
    } = useOfferApi();

    useEffect(() => {
        if (!id || id === '') {
            return;
        }

        (async () => {
            try {
                const response = await getClientStatus(id);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch {
                //ignore
            }
        })();

        return () => {
            cancel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (id === '') {
        return null;
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100 pd-5'}>
            <div style={{}}
                 className={'pd-10'}>
                {model.creditLimit !== null && model.creditLimit !== '' &&
                    <div className={'view-row mgb-10'}>
                        <label style={{
                            fontWeight: 'bold'
                        }} className={'view-label'}>{t("offer.grid.client-status-credit-limit")}</label>
                        <div className={'view-value'}
                             style={{
                             }}>
                            {`${model.creditLimit} EUR`}
                        </div>
                    </div>
                }

                {model.statusOfAccounts !== null && model.statusOfAccounts !== '' &&
                    <div className={'view-row mgb-10'}>
                        <label style={{
                            fontWeight: 'bold',
                            color: '#4a4a4a'
                        }} className={'view-label'}>{t("offer.grid.client-status-status-of-accounts")}</label>
                        <div className={'view-value'}
                             style={{
                             }}>
                            {model.statusOfAccounts}
                        </div>
                    </div>
                }

                {model.clientStatus !== null && model.clientStatus !== '' &&
                    <div className={'view-row mgb-10'}>
                        <label style={{
                            fontWeight: 'bold',
                            color: '#4a4a4a'
                        }} className={'view-label'}>{t("offer.grid.client-status-client-status")}</label>
                        <div className={'view-value'}
                             style={{
                             }}>
                            {model.clientStatus}
                        </div>
                    </div>
                }

                {model.prepayment !== null && model.prepayment !== '' &&
                    <div className={'view-row mgb-10'}>
                        <label style={{
                            fontWeight: 'bold',
                            color: '#4a4a4a'
                        }} className={'view-label'}>{t("offer.grid.client-status-prepayment")}</label>
                        <div className={'view-value'}
                             style={{
                             }}>
                            {model.prepayment}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ClientStatus;
