import React from 'react';
import cl from './SkeletonActionRowLoader.module.css';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import useMediaQuery from "../../../../../../hooks/useMediaQuery/useMediaQuery";

const SkeletonActionRowLoader = () => {
    const isMaxWidth510 = useMediaQuery('(max-width:510px)');

    return (
        <SkeletonTheme baseColor={'#eaeff3'} highlightColor={'#ffffff'}>
            <div className={cl.header}>
                <div className={cl.section}>
                    <div className={cl.tabs}>
                        <Skeleton height={'35px'}
                                  width={'36px'}
                                  style={{borderRadius: '2px'}}/>
                    </div>
                </div>

                <div className={cl.buttons}>
                    <Skeleton height={'35px'}
                              width={'150px'}
                              style={{
                                  borderRadius: '2px',
                                  marginRight: '20px',
                                  display: isMaxWidth510 ? 'none' : 'inline-flex'
                              }}/>

                    <Skeleton height={'35px'}
                              width={'36px'}
                              style={{borderRadius: '2px'}}/>
                    <Skeleton height={'35px'}
                              width={'36px'}
                              style={{borderRadius: '2px'}}/>
                    <Skeleton height={'35px'}
                              width={'36px'}
                              style={{borderRadius: '2px'}}/>
                    <Skeleton height={'35px'}
                              width={'36px'}
                              style={{borderRadius: '2px'}}/>
                    <Skeleton height={'35px'}
                              width={'36px'}
                              style={{borderRadius: '2px'}}/>
                </div>
            </div>
        </SkeletonTheme>
    );
};

export default SkeletonActionRowLoader;