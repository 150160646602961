import React, {useState} from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {useTranslation} from "react-i18next";
import cl from './RefNumTemplate.module.css';
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import ChangeRefNumDialog from "../../../actions/ChangeRefNum/ChangeRefNumDialog";
import Icon from "../../../../../../assets/icon/Icon";

const RefNumTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const {t} = useTranslation();
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);
    const user = useAppSelector(selectCurrentUser);

    let sId = props.dataItem.sId !== undefined && props.dataItem.sId !== null
        ? props.dataItem.sId
        : '';
    let refNum = props.dataItem["refNum"] !== undefined && props.dataItem["refNum"] !== null
        ? props.dataItem["refNum"]
        : '';
    let isArchive = props.dataItem.isArchive !== null && props.dataItem.isArchive !== undefined
        ? Boolean(props.dataItem.isArchive)
        : false;
    if (isArchive || !sId || sId === '' || !user || getUserRole(user) === UserRole.Client) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={'default-left-cell default-overflow-cell'}>
                    {refNum}
                </span>
            </td>
        )
    }

    return (
        <td {...props.tdProps}
            colSpan={1}>
            <ChangeRefNumDialog id={sId}
                                show={isDialogShown}
                                setShow={setIsDialogShown}/>
            <span onClick={handleClick}  className={'default-left-cell default-overflow-cell grid-prevent-selection'}>
                <>

                <Icon icon={"faPen"} className={`${cl.icon} ${refNum && refNum !== '' ? cl.iconWithValue : ''} shipment-grid-edit-ref-num-btn grid-prevent-selection`}
                                 onClick={(ev) => {
                                     ev.stopPropagation();

                                     setIsDialogShown(prev => !prev);
                                 }}/>
                    {refNum && refNum !== ''
                        ? <span className={`grid-prevent-selection ${cl.text}`}
                                onClick={(ev) => {
                                    ev.stopPropagation();

                                    setIsDialogShown(prev => !prev);
                                }}>
                        {refNum}
                    </span>
                        : <span className={`grid-prevent-selection ${cl.addText}`}
                                onClick={(ev) => {
                                    ev.stopPropagation();

                                    setIsDialogShown(prev => !prev);
                                }}>
                        {t("shipment.grid.edit-ref-number")}
                    </span>
                    }
                </>
            </span>
        </td>
    );
};

export default RefNumTemplate;
