import React, {useEffect} from 'react';
import {FormikHelpers, useFormik} from "formik";
import {IRestoreLogin} from "../../../app/interfaces/account/IRestoreLogin";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import Input from "../../../components/ui/Input/Input";
import {faBuilding, faEnvelope, faMobile} from "@fortawesome/free-solid-svg-icons";
import Textarea from "../../../components/ui/Textarea/Textarea";

type SupportFormProps = {
    id: string;
    onSubmit: (values: IRestoreLogin) => Promise<void>;
};

const SupportForm: React.FC<SupportFormProps> = ({
                                                     id,
                                                     onSubmit
                                                 }) => {
    const {t, i18n} = useTranslation();

    useEffect(() => {
        (async () => {
            if (Object.keys(form.errors).length > 0) {
                await form.validateForm();
            }
        })();
    }, [i18n.language]);

    const form = useFormik<IRestoreLogin>({
        initialValues: {
            email: '',
            message: '',
            company: '',
            phone: '',
            token: ''
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: Yup.object({
            message: Yup.string().required(t("shared.errors.required-error")
                .replace('{0}', t("login.restore.message"))),
            company: Yup.string().required(t("shared.errors.required-error")
                .replace('{0}', t("login.restore.company")))
                .max(100),
            email: Yup.string().email(t("profile.support.invalid-email")).required(t("shared.errors.required-error")
                .replace('{0}', t("login.restore.email")))
        }),
        onSubmit: async (values: IRestoreLogin, actions: FormikHelpers<IRestoreLogin>) => {
            actions.setSubmitting(true);

            await onSubmit({
                ...values
            });

            actions.setSubmitting(false);
        }
    });

    return (
        <form id={id}
              onSubmit={form.handleSubmit}>
            <div className={'mgt-20'}>
                <div style={{height: '60px', marginTop: '20px'}}>
                    <label className={`view-label required`}>
                        {t("login.restore.company")}
                    </label>
                    <Input placeholder={t("login.restore.company")}
                           id={'company'}
                           name={'company'}
                           disabled={form.isSubmitting}
                           value={form.values.company ?? ''}
                           autoComplete={'off'}
                           icon={faBuilding}
                           onChange={form.handleChange}/>

                    {!form.values.company && form.errors.company &&
                        <div className={'error-label'}>
                            {form.errors.company}
                        </div>
                    }
                </div>

                <div style={{height: '60px', marginTop: '20px'}}>
                    <label className={`view-label required`}>
                        {t("login.restore.email")}
                    </label>
                    <Input placeholder={t("login.restore.email")}
                           id={'email'}
                           name={'email'}
                           disabled={form.isSubmitting}
                           value={form.values.email ?? ''}
                           autoComplete={'off'}
                           icon={faEnvelope}
                           onChange={form.handleChange}/>

                    {form.errors.email &&
                        <div className={'error-label'}>
                            {form.errors.email}
                        </div>
                    }
                </div>

                <div style={{height: '60px', marginTop: '20px'}}>
                    <label className={`view-label`}>
                        {t("login.restore.phone")}
                    </label>
                    <Input placeholder={t("login.restore.phone")}
                           id={'phone'}
                           name={'phone'}
                           disabled={form.isSubmitting}
                           value={form.values.phone ?? ''}
                           autoComplete={'off'}
                           icon={faMobile}
                           onChange={form.handleChange}/>
                </div>

                <div className={'mgb-20'} style={{ marginTop: '20px'}}>
                    <label className={`view-label required`}>
                        {t("login.restore.message")}
                    </label>
                    <Textarea disabled={form.isSubmitting}
                              style={{
                                  minHeight: '150px',
                                  resize: 'vertical',
                                  maxHeight: '350px'
                              }}
                              value={form.values.message ?? ''}
                              placeholder={t("login.restore.message")}
                              id={'message'}
                              name={'message'}
                              onChange={form.handleChange}/>

                    {!form.values.message && form.errors.message &&
                        <div className={'error-label'}>
                            {form.errors.message}
                        </div>
                    }
                </div>
            </div>
        </form>
    );
};

export default SupportForm;
