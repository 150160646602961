import React from 'react';
import {IBorderCrossingAction} from "../../../../../../app/interfaces/shipment/IBorderCrossingAction";
import {useTranslation} from "react-i18next";
import {FormikHelpers, useFormik} from "formik";
import Input from "../../../../../../components/ui/Input/Input";

type BorderCrossingFormProps = {
    model: IBorderCrossingAction;
    disabled: boolean;
    save: (model: IBorderCrossingAction) => Promise<void>;
}

const BorderCrossingForm: React.FC<BorderCrossingFormProps> = ({
                                                                   model,
                                                                   save,
                                                                   disabled
                                                               }) => {
    const {t} = useTranslation();

    const form = useFormik<IBorderCrossingAction>({
        initialValues: {
            orderReleaseGid: model.orderReleaseGid,
            shipmentGid: model.shipmentGid,
            note: model.note
        },
        onSubmit: async (values: IBorderCrossingAction, actions: FormikHelpers<IBorderCrossingAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='add-border-crossing-form'
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <Input disabled={disabled}
                       className={'border-crossing-notes-value'}
                       value={form.values.note ?? ''}
                       placeholder={t("shipment.grid.bc-notes-label")}
                       onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                           form.setFieldValue('note', ev.target.value);
                       }}/>
            </div>
        </form>
    );
};

export default BorderCrossingForm;