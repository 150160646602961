import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'triangleExclamation',
    pathData: [
        'M8.06917 2.47826C8.7051 1.37681 10.2949 1.37681 10.9308 2.47826L16.2964 11.7717C16.9323 12.8732 16.1374 14.25 14.8656 14.25H4.13441C2.86257 14.25 2.06766 12.8732 2.70358 11.7717L8.06917 2.47826Z',
    ],
    pathDataExtended: [{
        data: 'M10.2507 5.24803V8.10803C10.2507 8.41136 10.2357 8.70969 10.2057 9.00303C10.1757 9.29303 10.1374 9.60136 10.0907 9.92803H9.25074C9.20408 9.60136 9.16574 9.29303 9.13574 9.00303C9.10574 8.70969 9.09074 8.41136 9.09074 8.10803V5.24803H10.2507ZM8.89574 11.798C8.89574 11.6947 8.91408 11.5964 8.95074 11.503C8.99074 11.4097 9.04408 11.3297 9.11074 11.263C9.18074 11.1964 9.26241 11.143 9.35574 11.103C9.44908 11.063 9.54908 11.043 9.65574 11.043C9.75908 11.043 9.85741 11.063 9.95074 11.103C10.0441 11.143 10.1241 11.1964 10.1907 11.263C10.2607 11.3297 10.3157 11.4097 10.3557 11.503C10.3957 11.5964 10.4157 11.6947 10.4157 11.798C10.4157 11.9047 10.3957 12.0047 10.3557 12.098C10.3157 12.188 10.2607 12.2664 10.1907 12.333C10.1241 12.3997 10.0441 12.4514 9.95074 12.488C9.85741 12.528 9.75908 12.548 9.65574 12.548C9.54908 12.548 9.44908 12.528 9.35574 12.488C9.26241 12.4514 9.18074 12.3997 9.11074 12.333C9.04408 12.2664 8.99074 12.188 8.95074 12.098C8.91408 12.0047 8.89574 11.9047 8.89574 11.798Z',
        fill: 'white'
    }],
    viewBox: '0 0 17 17',
};

export default icon;