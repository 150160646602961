import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {useEffect} from "react";
import {clearChatSlice, getChats} from "../../../../../features/chat/chatSlice";
import {selectIsChatDialogOpened} from "../../../../../features/chat/chatSliceSelectors";

export const useChatSetup = () => {
    const dispatch = useAppDispatch();

    const isChatOpened = useAppSelector(selectIsChatDialogOpened);

    useEffect(() => {
        // Chat clear when close.
        if (!isChatOpened) {
            dispatch(clearChatSlice());

            return;
        }

        // Load chats on dialog open
        (async () => {
            dispatch(getChats(null));
        })();
    }, [isChatOpened]);
}