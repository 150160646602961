import React from 'react';
import {useTranslation} from "react-i18next";
import ConfirmShipment from "./ConfirmShipment";
import Dialog from "../../../../../components/ui/Dialog/Dialog";

type ConfirmShipmentDialogProps = {
    id: string | Array<string>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmShipmentDialog: React.FC<ConfirmShipmentDialogProps> = ({
                                                                         id,
                                                                         show,
                                                                         setShow
                                                                     }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'confirm-shipment-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.btn.confirm-shipment")}>
            <ConfirmShipment id={id}
                             close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default ConfirmShipmentDialog;