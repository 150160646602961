import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'recordVinyl',
    pathData: [
        'M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192zm0 224a128 128 0 1 0 0-256 128 128 0 1 0 0 256zm0-96a32 32 0 1 0 0-64 32 32 0 1 0 0 64z\n'
    ],
    viewBox: '0 0 512 512'
};

export default icon;