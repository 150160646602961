import React from 'react';
import {IShipment} from "../../../../../../app/interfaces/shipment/IShipment";
import cl from './ShipmentFormTabs.module.css';
import {useTranslation} from "react-i18next";

type ShipmentFormTabsProps = {
    shipment: IShipment;
    tab: string;
    onTabChange: (tab: string) => void;
    disabled: boolean;
};

const ShipmentFormTabs: React.FC<ShipmentFormTabsProps> = ({
                                                               shipment,
                                                               tab,
                                                               onTabChange
                                                           }) => {
    const {t} = useTranslation();

    return (
        <>
            <div onClick={() => onTabChange('1')}
                 className={`${cl.tab} ${tab === '1' ? cl.active : ''}`}>
                <div>
                    {t("shipment.form.details-tab")}
                </div>
            </div>


                <>

                         <div onClick={() => onTabChange('2')}
                               className={`${cl.tab} ${tab === '2' ? cl.active : ''} prevent-selection`}>
                            <div>
                                 {shipment.isCmrAllowedForSp ? t("shipment.form.self-billing-tab") : t("self-billing.labels.operations")}
                            </div>
                        </div>
                    {/*{!shipment.isCmrAllowedForSp*/}
                    {/*    && shipment.hasInvoices ? <div onClick={() => onTabChange('4')}*/}
                    {/*           className={`${cl.tab} ${tab === '4' ? cl.active : ''} prevent-selection`}>*/}
                    {/*        <div>*/}
                    {/*            {t("shipment.form.payment-status-tab")}*/}
                    {/*        </div>*/}
                    {/*    </div> : <></>*/}
                    {/*}*/}
                </>


            {/*{!shipment.isArchive &&
                <div className={`${cl.tab} ${tab === '3' ? cl.active : ''} transition-all-500`}
                 onClick={() => onTabChange('3')}>
                {t("shipment.form.operations-documents-tab")}
            </div>
            }*/}
        </>
    );
};

export default ShipmentFormTabs;
