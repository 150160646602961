import React from 'react';
import {ISelfBillingOverview} from "../../../../../../app/interfaces/selfBilling/ISelfBillingOverview";
import {useFormik} from "formik";
import {
    ISelfBillingConfirmationPostAction
} from "../../../../../../app/interfaces/selfBilling/ISelfBillingConfirmationPostAction";
import {useTranslation} from "react-i18next";
import cl from './SelfBillingConfirmationForm.module.css';
import {RadioButton} from "@progress/kendo-react-inputs";
import Button, {ButtonType} from "../../../../../../components/ui/Button/Button";
import Input from "../../../../../../components/ui/Input/Input";
import {faBuilding, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import {IUserDevice} from "../../../../../../app/interfaces/user/IUserDevice";
import {getUserDevice} from "../../../../../../utils/device";

type SelfBillingConfirmationFormProps = {
    model: ISelfBillingOverview;
    onSubmit: (values: ISelfBillingConfirmationPostAction) => Promise<void>;
    disabled: boolean;
};

const SelfBillingConfirmationForm: React.FC<SelfBillingConfirmationFormProps> = ({
                                                                                     model,
                                                                                     onSubmit,
                                                                                     disabled
                                                                                 }) => {
    const {t} = useTranslation();

    const form = useFormik<ISelfBillingConfirmationPostAction>({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            mode: null,
            email: model.email,
            firm: null,
            device: null
        },
        validationSchema: Yup.object().shape({
            mode: Yup.string().nullable()
                .required(t("shared.errors.required-error").replace('{0}', t("quote.grid.forwarder"))),
            email: Yup.array()
                .transform(function (value, originalValue) {
                    if (this.isType(value) && value !== null) {
                        return value;
                    }

                    return originalValue ? originalValue.replaceAll(';', ',').split(/[\s,]+/) : [];
                })
                .of(Yup.string().email(({value}) => `${t("profile.support.invalid-email")} (${value})`))
                .required()
        }),
        onSubmit: async (values, actions) => {
            actions.setSubmitting(true);

            const userDevice: IUserDevice = await getUserDevice();

            await onSubmit({...values, device: userDevice.browserName + " " + userDevice.browserVersion });

            actions.setSubmitting(false);
        }
    });

    return (
        <form id={'self-billing-confirmation-form'}
              onSubmit={form.handleSubmit}>
            <div className={`mgb-10 ${cl.title}`}>
                {t("self-billing.labels.self-billing-overview")
                    .replace('{0}', model.shipmentGid.replace('AAG.', ''))
                    .replace('{1}', model.route)
                    .replace('{2}', model.serviceProvider)
                }
            </div>

            <div className={cl.radioContainer}>
                <div className={cl.radioBtn}>
                    <RadioButton name={`mode`}
                                 value={`y`}
                                 size={'small'}
                                 checked={form.values.mode === 'y'}
                                 label={t("self-billing.labels.confirmation-yes-option")}
                                 onChange={async () => {
                                     await form.setFieldValue('email', model.email);
                                     await form.setFieldValue('mode', 'y');

                                     await form.validateForm();
                                 }}/>

                    {form.values.mode === 'y' &&
                        <>
                            <div className={'mgt-10 mgb-10'}>
                                <label className={`view-label required`}>
                                    {t("self-billing.labels.financial-email")}
                                </label>

                                <Input placeholder={t("self-billing.labels.financial-email")}
                                       id={'email'}
                                       name={'email'}
                                       disabled={form.isSubmitting}
                                       value={form.values.email ?? ''}
                                       autoComplete={'off'}
                                       icon={faEnvelope}
                                       onChange={form.handleChange}/>

                                {form.errors.email &&
                                    <div className={`error-label`}>
                                        {Array.isArray(form.errors.email)
                                            ? form.errors.email.map(item => {
                                                return (
                                                    <div key={item}>
                                                        {item}
                                                    </div>
                                                )
                                            })
                                            : <>{`${form.errors.email}`}</>
                                        }
                                    </div>
                                }
                            </div>
                        </>
                    }
                </div>

                <div className={cl.radioBtn}>
                    <RadioButton name={`mode`}
                                 value={`n`}
                                 size={'small'}
                                 checked={form.values.mode === 'n'}
                                 label={t("self-billing.labels.confirmation-no-option")}
                                 onChange={async () => {
                                     await form.setFieldValue('email', null);
                                     await form.setFieldValue('mode', 'n');
                                     await form.setFieldValue('firm', null);

                                     await form.validateForm();
                                 }}/>

                    {form.values.mode === 'n' &&
                        <>
                            <div className={'mgt-10 mgb-10'}>
                                <label className={`view-label required`}>
                                    {t("self-billing.labels.firm-label")}
                                </label>

                                <Input placeholder={t("self-billing.labels.firm-label")}
                                       id={'firm'}
                                       name={'firm'}
                                       disabled={form.isSubmitting}
                                       value={form.values.firm ?? ''}
                                       autoComplete={'off'}
                                       icon={faBuilding}
                                       onChange={form.handleChange}/>
                            </div>

                            <div className={'mgt-10 mgb-10'}>
                                <label className={`view-label required`}>
                                    {t("self-billing.labels.contact-email")}
                                </label>

                                <Input placeholder={t("self-billing.labels.contact-email")}
                                       id={'email'}
                                       name={'email'}
                                       disabled={form.isSubmitting}
                                       value={form.values.email ?? ''}
                                       autoComplete={'off'}
                                       icon={faEnvelope}
                                       onChange={form.handleChange}/>

                                {form.errors.email &&
                                    <div className={`error-label`}>
                                        {Array.isArray(form.errors.email)
                                            ? form.errors.email.map(item => {
                                                return (
                                                    <div key={item}>
                                                        {item}
                                                    </div>
                                                )
                                            })
                                            : <>{`${form.errors.email}`}</>
                                        }
                                    </div>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>

            <div className={`mgt-20 mgb-10 ${cl.btnContainer}`}>
                <Button buttonType={ButtonType.Secondary}
                        disabled={disabled || !form.values.mode || !form.values.email || (form.values.mode === 'n' && !form.values.firm)}
                        type={'submit'}
                        form={'self-billing-confirmation-form'}
                        style={{width: '60%'}}>
                    {t("shared.btn.confirm")}
                </Button>
            </div>
        </form>
    );
};

export default SelfBillingConfirmationForm;
