import React from "react";
import {IGridFilterPanelLayoutContext} from "../interfaces/IGridFilterPanelLayoutContext";
import {
    GridFilter,
    isGridFilterHasAtLeastOneNotNullValue,
    isGridFilterHasNoValues
} from "../../../../app/types/GridFilter";
import {IGridFilter} from "../../../../app/interfaces/gridFilter/IGridFilter";
import {isEqual} from 'lodash'

export const GridPanelLayoutContext = React.createContext<IGridFilterPanelLayoutContext>(undefined as any);

export const isApplyFilterButtonActive = (filter: GridFilter, filterToApply: GridFilter): boolean => {
    return (isGridFilterHasAtLeastOneNotNullValue(filterToApply) || isGridFilterHasNoValues(filterToApply));
}

export const isResetFilterButtonActive = (_: GridFilter, filterToApply: GridFilter): boolean => {
    return isGridFilterHasAtLeastOneNotNullValue(filterToApply);
}

export const isSaveFilterButtonActive = (selectedFilter: IGridFilter | null, _: GridFilter, filterToApply: GridFilter): boolean => {
    if (selectedFilter) {
        let selectedFilterValue = JSON.parse(selectedFilter?.value);

        return !isEqual(selectedFilterValue, filterToApply);
    }

    if (isGridFilterHasAtLeastOneNotNullValue(filterToApply)) {
        return true;
    }

    if (isGridFilterHasNoValues(filterToApply)) {
        return false;
    }

    return false;
}
