import React, {createContext, useCallback, useMemo, useState} from 'react';
import cl from './Sidebar.module.css';
import SidebarLogoSection from "./sections/SidebarLogoSection/SidebarLogoSection";
import SidebarMenuSection from "./sections/SidebarMenuSection/SidebarMenuSection";
import SidebarSettingsSection from "./sections/SidebarSettingsSection/SidebarSettingsSection";
import SidebarUserSection from "./sections/SidebarUserSection/SidebarUserSection";
import SidebarApplicationSection from "./sections/SidebarApplicationSection/SidebarApplicationSection";
import SidebarSection from "./components/SidebarSection/SidebarSection";
import {useAppDispatch} from "../../../app/store";
import {setIsSidebarExpanded, setIsSidebarHoverExpanded} from "../../../features/application/applicationSlice";
import useLocalStorage from "../../../hooks/useLocalStorage/useLocalStorage";
import {isMobile} from "react-device-detect";

const SIDEBAR_STATE_LOCAL_STORAGE_KEY = 'sidebar_state';
const SIDEBAR_STATE_DEFAULT_VALUE = false;

type SidebarContextType = {
    expanded: boolean;
    hoverExpanded: boolean;
    onExpandChange: (val: boolean) => void;
    setIsExpandedOnHover: (val: boolean) => void;
}
export const SidebarContext = createContext<SidebarContextType>({
    expanded: SIDEBAR_STATE_DEFAULT_VALUE,
    hoverExpanded: false,
    onExpandChange: undefined as any,
    setIsExpandedOnHover: undefined as any,
});

const Sidebar = () => {
    const dispatch = useAppDispatch();

    const [expanded, setExpanded] = useLocalStorage<boolean>(SIDEBAR_STATE_LOCAL_STORAGE_KEY, SIDEBAR_STATE_DEFAULT_VALUE);
    const [isExpandedOnHover, setIsExpandedOnHover] = useState<boolean>(false);

    const onExpandChange = useCallback((val: boolean) => {
        dispatch(setIsSidebarExpanded(val));
        setExpanded(val);

        if (!val) {
            dispatch(setIsSidebarHoverExpanded(false));
            setIsExpandedOnHover(false);
        }
    }, [dispatch]);

    const onExpandHoverChange = useCallback((val: boolean) => {
        if (expanded || !isExpandedOnHover) {
            return;
        }

        dispatch(setIsSidebarHoverExpanded(val));
        setIsExpandedOnHover(val);
    }, [dispatch, expanded, isExpandedOnHover]);

    const ctx = useMemo(() => ({
        expanded,
        hoverExpanded: isExpandedOnHover,
        onExpandChange,
        setIsExpandedOnHover: onExpandHoverChange
    } as SidebarContextType), [
        expanded,
        isExpandedOnHover,
        onExpandChange,
        setIsExpandedOnHover
    ]);

    return (
        <>
            {!expanded ? <div className={cl.fake}></div> : null}
            <aside className={`${cl.sidebar}`}
                   data-expanded={expanded ? 'expanded' : 'condensed'}
                   data-hover-expanded={isExpandedOnHover ? 'true' : 'false'}
                   onMouseEnter={() => {
                       if (expanded) {
                           return;
                       }

                       // setIsExpandedOnHover(true);
                   }}
                   onClick={() => {
                       if (expanded || (isExpandedOnHover && isMobile)) {
                           return;
                       }

                       dispatch(setIsSidebarHoverExpanded(true));
                       setIsExpandedOnHover(true);
                   }}
                   onMouseLeave={() => {
                       if (expanded) {
                           return;
                       }
                       dispatch(setIsSidebarHoverExpanded(false));
                       setIsExpandedOnHover(false);
                   }}>
                <SidebarContext.Provider value={ctx}>
                    <SidebarSection border={'bottom'}>
                        <SidebarLogoSection/>
                    </SidebarSection>

                    <SidebarSection paddingBottomTop={'5'}>
                        <SidebarUserSection/>
                    </SidebarSection>

                    <SidebarSection
                        /*                title={t("shared.labels.menu")}*/
                        overflow={true}>
                        <SidebarMenuSection/>

                    </SidebarSection>
                    <SidebarSection paddingBottomTop={'5'}
                        /* title={t("shared.labels.settings")}*/
                    >
                        <SidebarSettingsSection/>
                    </SidebarSection>
                    <SidebarSection border={'top'}
                                    paddingBottomTop={'5'}>
                        <SidebarApplicationSection/>
                    </SidebarSection>
                </SidebarContext.Provider>
            </aside>
        </>
    );
};

export default Sidebar;
