import React from 'react';
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import {useOfferApi} from "../../../../../../app/api/offer";

const ReleaseNumberTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);

    const {
        getOfferManagementLink: {
            query: getOfferManagementLink
        }
    } = useOfferApi();

    const user = useAppSelector(selectCurrentUser);

    if (!user) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
            </td>
        )
    }

    const role = getUserRole(user);

    if (role === UserRole.ServiceProvider) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={'default-left-cell default-overflow-cell'}>
                    {props.children}
                </span>
            </td>
        );
    }

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
            <span className={'default-left-cell default-overflow-cell grid-prevent-selection'}>
                  <button className={'grid-prevent-selection cell-button shipment-grid-open-otm-release-btn'}
                          style={{
                              fontSize: '12px'
                          }}
                          onClick={async (ev) => {
                              if (props.dataItem["releaseNumber"] === null ||
                                  props.dataItem["releaseNumber"] === undefined ||
                                  props.dataItem["mainId"] === null ||
                                  props.dataItem["mainId"] === undefined) {
                                  return;
                              }

                              ev.stopPropagation();

                              try {
                                  const response = await getOfferManagementLink(props.dataItem["mainId"]);

                                  if (response && response.data && response.data.resultCode === ResponseResultCode.Ok &&
                                      response.data.data) {
                                      window.open(response.data.data, '_blank');
                                  }
                              } catch {
                                  //ignore
                              }

                          }}>
                {props.children}
              </button>
            </span>
        </td>
    );
};

export default ReleaseNumberTemplate;
