import React from 'react';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

const ChatDialogueMessagesLoadingScreen: React.FC = () => {
    return (
        <SkeletonTheme baseColor={'#eaeff3'} highlightColor={'#ffffff'}>
            <div className={'chat-dialogue-messages-loading-screen-container'}>
                <div className={'chat-dialogue-messages-loading-screen-row'}>
                    <div className={'w100'}>
                        <Skeleton height={'30px'}
                                  width={'70%'}
                                  style={{borderRadius: '2px'}}/>
                    </div>
                    <div>
                        <Skeleton height={'15px'}
                                  width={'50px'}
                                  style={{borderRadius: '2px'}}/>
                    </div>
                </div>

                <div className={'chat-dialogue-messages-loading-screen-row'}>
                    <div className={'w100'}>
                        <Skeleton height={'70px'}
                                  width={'70%'}
                                  style={{borderRadius: '2px', float: 'right'}}/>
                    </div>
                    <div>
                        <Skeleton height={'15px'}
                                  width={'50px'}
                                  style={{borderRadius: '2px'}}/>
                    </div>
                </div>

                <div className={'chat-dialogue-messages-loading-screen-row'}>
                    <div className={'w100'}>
                        <Skeleton height={'40px'}
                                  width={'70%'}
                                  style={{borderRadius: '2px'}}/>
                    </div>
                    <div>
                        <Skeleton height={'15px'}
                                  width={'50px'}
                                  style={{borderRadius: '2px'}}/>
                    </div>
                </div>

                <div className={'chat-dialogue-messages-loading-screen-row'}>
                    <div className={'w100'}>
                        <Skeleton height={'30px'}
                                  width={'70%'}
                                  style={{borderRadius: '2px', float: 'right'}}/>
                    </div>
                    <div>
                        <Skeleton height={'15px'}
                                  width={'50px'}
                                  style={{borderRadius: '2px'}}/>
                    </div>
                </div>

                <div className={'chat-dialogue-messages-loading-screen-row'}>
                    <div className={'w100'}>
                        <Skeleton height={'50px'}
                                  width={'70%'}
                                  style={{borderRadius: '2px'}}/>
                    </div>
                    <div>
                        <Skeleton height={'15px'}
                                  width={'50px'}
                                  style={{borderRadius: '2px'}}/>
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    );
};

export default ChatDialogueMessagesLoadingScreen;