import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {IOffer} from "../../../../../../../app/interfaces/offer/IOffer";
import ChangeEquipmentDialog from "../../../../actions/ChangeEquipment/ChangeEquipmentDialog";
import Icon from "../../../../../../../assets/icon/Icon";
import {getUserRole} from "../../../../../../../helpers/user";
import {useAppSelector} from "../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../features/authentication/authenticationSliceSelectors";
import {UserRole} from "../../../../../../../app/enums/UserRole";

type EquipmentGroupProps = {
    offer: IOffer;
};

const EquipmentGroup: React.FC<EquipmentGroupProps> = ({
                                                           offer
                                                       }) => {
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);
    if (!user) {
        return null;
    }
    let role = getUserRole(user);
    return (
        <>
            <ChangeEquipmentDialog id={offer.orderReleaseGid}
                                   show={isDialogShown}
                                   setShow={setIsDialogShown}/>
            <label className={'equipment-label'}>
                {t('offer.form.equipment-group')}
            </label>
            {role !== UserRole.ServiceProvider &&
            <span className={'details-box-item-field-edit-value equipment-value'}>

                {offer.equipmentGroupName !== null && offer.equipmentGroupName !== ''
                    ? <><Icon icon={"faPen"}
                              className={'details-box-item-field-gray-icon'}
                              style={{marginRight: '5px'}}
                              onClick={() => setIsDialogShown(prev => !prev)}/><span style={{color:'#1b1b1b'}} onClick={() => setIsDialogShown(prev => !prev)}>{offer.equipmentGroupName}</span></>
                    : <Icon icon={"faPen"}
                            className={'details-box-item-field-icon'}
                            style={{marginRight: '5px'}}
                            onClick={() => setIsDialogShown(prev => !prev)}/>
                }
            </span>
            }
            {role === UserRole.ServiceProvider &&
                <span className={'equipment-value'}>

                <span style={{color:'#1b1b1b'}}>{offer.equipmentGroupName}</span>


            </span>
            }
        </>
    );
};

export default EquipmentGroup;
