import React, {ReactNode} from 'react';
import cl from './DetailBoxColumn.module.css';

type DetailsBoxColumnProps = {
    children: ReactNode;
    width?: string;
    center?: boolean;
}

const DetailsBoxColumn: React.FC<DetailsBoxColumnProps> = ({
                                                               width,
                                                               children,
                                                               center = false
                                                           }) => {
    return (
        <div className={center ? cl.center : ''}
             style={{width: width, marginTop: '10px'}}>
            {children}
        </div>
    );
};

export default DetailsBoxColumn;
