import React from 'react';
import {useTranslation} from "react-i18next";

type RegionProps = {
    code: string | null;
    provinceCode: string | null;
    provinceDescription: string | null;
}

const Region: React.FC<RegionProps> = ({
                                           code,
                                           provinceDescription,
                                           provinceCode
                                       }) => {
    const {t} = useTranslation();

    return (
        <>
            {provinceCode !== null && provinceCode !== ''
                ? <>{`${code} ${provinceCode} ${provinceDescription}`}</>
                : <>{t("shared.labels.no-data")}</>
            }
        </>
    );
};

export default Region;