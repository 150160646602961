import React from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import indicatorOk from "../../../../../../assets/images/indicator_ok.png";
import indicatorCancel from "../../../../../../assets/images/indicator_cancel.png";
import {useTranslation} from "react-i18next";

const IndicatorTemplate = (props: any) => {
    const {t} = useTranslation();
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    let icon: any | null;
    let title: string = '';

    let val = props.dataItem[props.field] !== undefined && props.dataItem[props.field] !== null
        ? props.dataItem[props.field]
        : '';

    if (val == 'G' || val == 'R') {
        if (val === 'G') {
            icon = indicatorOk;
            title = t("offer.grid.or-accepted-in-transporeon");
        } else if (val === 'R') {
            icon = indicatorCancel;
            title = t("offer.grid.or-declined-in-transporeon");
        }
    }

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
                <span className={'default-center-cell'}>
                    {icon && <img src={icon} alt={props.children} title={title}/>}
                </span>
        </td>
    );
};

export default IndicatorTemplate;
