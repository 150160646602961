import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import SelectBet from "./SelectBet";

type SelectBetDialogProps = {
    id: number;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const SelectBetDialog: React.FC<SelectBetDialogProps> = ({
                                                             id,
                                                             show,
                                                             setShow
                                                         }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'select-offer-bet-dialog'}
                show={show}
                setShow={setShow}
                width={'800px'}
                title={t("shared.btn.confirm")}>
            <SelectBet id={id} close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default SelectBetDialog;