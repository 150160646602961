import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export type UseDocumentTitleProps = {
    title: string;
    defaultTitleResourceName?: string;
    concatWithDefaultPostfixTitle?: boolean;
}

const useDocumentTitle = ({
                              title,
                              concatWithDefaultPostfixTitle = true,
                              defaultTitleResourceName = 'asstra'
                          }: UseDocumentTitleProps): void => {
    const {t} = useTranslation();
    const [defaultTitle] = useState(t(defaultTitleResourceName));

    useEffect(() => {
        if (title) {
            document.title = concatWithDefaultPostfixTitle ?
                `${title} - ${defaultTitle}`
                : title;
        }

        return () => {
            document.title = defaultTitle;
        }
    }, [title, concatWithDefaultPostfixTitle, t, defaultTitle]);
};

export default useDocumentTitle;