import React from 'react';
import {IOffer} from "../../../../../../../app/interfaces/offer/IOffer";
import {useTranslation} from "react-i18next";
import OfferCargo from "../../../components/OfferCargo/OfferCargo";
import RemarksDetails from "../../../../../../../components/shared/form/RemarksDetails/RemarksDetails";
import {RemarkName} from "../../../../../../../app/enums/RemarkName";
import ServiceProviderOfferBets from "../../../components/OffersBets/ServiceProviderOfferBets/ServiceProviderOfferBets";
import OfferMap from "../../../components/OfferMap/OfferMap";
import FormCollapse from "../../../../../../../components/ui/Collapse/FormCollapse";
import RouteSideSection from "../../../../../../../components/shared/sections/RouteSection/RouteSideSection";
import OfferDetails from "../../../components/OfferDetails/OfferDetails";

type ServiceProviderOfferDetailsSectionProps = {
    offer: IOffer;
};

const ServiceProviderOfferDetailsSection: React.FC<ServiceProviderOfferDetailsSectionProps> = ({
                                                                                                   offer
                                                                                               }) => {
    const {t} = useTranslation();

    return (
        <div className={'form-layout-container'}
             data-count={'2'}
             data-separator={'true'}>
            <div className={'form-layout-column'}>
                <FormCollapse title={t("offer.form.info-section")}>
                    <OfferDetails offer={offer}/>
                </FormCollapse>
                <FormCollapse title={t("offer.form.cargo-section")}>
                    <OfferCargo offer={offer}/>
                </FormCollapse>

                {offer.isAuction && offer.remarks.length > 0 &&
                    <FormCollapse title={t("offer.form.remarks-section")}>
                        <RemarksDetails isEditingAllowed={false}
                                        remarks={offer.remarks}/>
                    </FormCollapse>
                }

                {!offer.isAuction && offer.remarks.filter(e => e.code === RemarkName.COMMENTS_FOR_SUPPLIER_CABINET ||
                        e.code === RemarkName.EBS_AAG_COMMENTS_FROM_CUSTOMER).length > 0 &&
                    <FormCollapse title={t("offer.form.remarks-section")}>
                        <RemarksDetails isEditingAllowed={false}
                                        remarks={offer.remarks.filter(e => e.code === RemarkName.COMMENTS_FOR_SUPPLIER_CABINET ||
                                            e.code === RemarkName.EBS_AAG_COMMENTS_FROM_CUSTOMER)}/>
                    </FormCollapse>
                }
            </div>

            <div className={'form-layout-column-separator'}></div>

            <div className={'form-layout-column'}>
                <FormCollapse
                    title={offer.legGid !== null && offer.legGid !== '' && offer.tl !== null && offer.tl !== ''
                        ? `${t("offer.form.customer-full-route")} ${offer.tl}`
                        : `${t("offer.form.route-section")}`
                    }>
                    <div className={'details-box-default-container'}>
                        <RouteSideSection model={{
                            orderType: offer.orderType,
                            orderReleaseGid: offer.orderReleaseGid,
                            hasShipment: false,
                            earlyPickupDateText: offer.earlyPickupDateText,
                            earlyPickupDate: offer.earlyPickupDate ?? null,
                            earlyDeliveryDate: offer.earlyDeliveryDate ?? null,
                            lateDeliveryDateText: offer.lateDeliveryDateText,
                            lateDeliveryDate: offer.lateDeliveryDate,
                            latePickupDate: offer.latePickupDate ?? null,
                            shipmentGid: null,
                            stopPoints: offer.stopPoints ?? [],
                            sourceCountry: offer.sourceCountry,
                            sourceCity: offer.sourceCity,
                            sourceCountryCode2: offer.sourceCountryCode2,
                            sourceProvinceCode: offer.sourceProvinceCode,
                            sourceProvinceDesc: offer.sourceProvinceDesc,
                            destinationCountry: offer.destinationCountry,
                            destinationCity: offer.destinationCity,
                            destinationCountryCode2: offer.destinationCountryCode2,
                            destinationProvinceCode: offer.destinationProvinceCode,
                            destinationProvinceDesc: offer.destinationProvinceDesc
                        }}/>
                    </div>
                </FormCollapse>

                {offer.bets.length > 0 &&
                    <FormCollapse title={t("offer.form.bets-section")}>
                        <ServiceProviderOfferBets offer={offer}/>
                    </FormCollapse>
                }

                <FormCollapse title={t("offer.form.available-transport-section")}>
                    <OfferMap offer={offer}/>
                </FormCollapse>
            </div>
        </div>
    );
};

export default ServiceProviderOfferDetailsSection;
