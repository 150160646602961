import React from "react";

export type GridActionRowSelectOptionProps = {
    id: string;
    text: string;
    tooltip?: string;
    selected: boolean;
    disabled?: boolean;
    description?: string;
}

const GridActionRowSelectOption: React.FC<GridActionRowSelectOptionProps> = () => {
    return null;
};

export default GridActionRowSelectOption;