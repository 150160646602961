import {useState} from 'react';
import {useTranslation} from "react-i18next";
import {LanguageSwitcherModel} from "../../components/ui/LanguageSwitcher/LanguageSwitcher";
import {Language} from "../../app/enums/Language";

const useDefaultSiteLanguages = (): Array<LanguageSwitcherModel> => {
    const {t} = useTranslation();

    const [languages] = useState<Array<LanguageSwitcherModel>>([
        {
            language: Language.RU,
            label: t("shared.languages.ru")
        },
        {
            language: Language.PL,
            label: t("shared.languages.pl")
        },
        {
            language: Language.EN,
            label: t("shared.languages.en")
        }
    ]);

    return languages;
};

export default useDefaultSiteLanguages;