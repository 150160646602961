import React, {useEffect, useState} from 'react';
import useDocumentTitle from "../../../hooks/useDocumentTitle/useDocumentTitle";
import {useTranslation} from "react-i18next";
import bg_image from "../../../assets/images/login-bg.jpg"
import BackgroundImageContainer from "../../../components/ui/BackgroundImageContainer/BackgroundImageContainer";
import CopyRightFooter from "../../../components/ui/CopyRightFooter/CopyRightFooter";
import Container from "../../../components/ui/Container/Container";
import Card, {CardAppearAnimationType} from "../../../components/ui/Card/Card";
import LanguageSwitcher from "../../../components/ui/LanguageSwitcher/LanguageSwitcher";
import useDefaultSiteLanguages from "../../../hooks/useDefaultSiteLanguages/useDefaultSiteLanguages";
import cl from './Login.module.css';
import useMediaQuery from "../../../hooks/useMediaQuery/useMediaQuery";
import LoginFormContent from "./components/LoginFormContent/LoginFormContent";
import LoginUserListContent from "./components/LoginUserListContent/LoginUserListContent";
import ComponentsSwitch from "../../../components/ui/ComponentsSwitch/ComponentsSwitch";
import {ILoginUser} from "../../../app/interfaces/login/ILogin";
import {useAppDispatch, useAppSelector} from "../../../app/store";
import {selectLogoutMessage} from "../../../features/application/applicationSliceSelectors";
import {toast} from "react-toastify";
import {setLogoutMessage} from "../../../features/application/applicationSlice";
import useIfFirstRender from "../../../hooks/useIsFirstRender/useIfFirstRender";

type SwitchComponents = 'LoginFormContent' | 'LoginUserListContent';

const Login: React.FC = () => {
    const [activeComponent, setActiveComponent] = useState<SwitchComponents>('LoginFormContent');
    const [emailToGetUserList, setEmailToGetUserList] = useState<string | null>();
    const [selectedUser, setSelectedUser] = useState<ILoginUser | undefined | null>();
    const {t} = useTranslation();
    const languages = useDefaultSiteLanguages();
    const isMaxWidth470 = useMediaQuery('(max-width:450px)');
    const isMaxHeight490 = useMediaQuery('(max-height:490px)');
    const logOutMessage = useAppSelector(selectLogoutMessage);
    const isFirstRender = useIfFirstRender();
    const dispatch = useAppDispatch();
    useDocumentTitle({title: t("login.labels.login-page-title")});

    useEffect(() => {
        if (logOutMessage && isFirstRender) {
            toast.warn<string>(logOutMessage);

            dispatch(setLogoutMessage(undefined));
        }
    }, [logOutMessage, dispatch, isFirstRender]);

    const changeComponent = (email?: string, usr?: ILoginUser | null) => {
        if (activeComponent === 'LoginFormContent') {
            setActiveComponent('LoginUserListContent');
            setEmailToGetUserList(email);
        } else if (activeComponent === 'LoginUserListContent') {
            setActiveComponent('LoginFormContent');
            setEmailToGetUserList(email);
            setSelectedUser(usr);
        }
    }

    return (
        <BackgroundImageContainer image={bg_image}>
            <Container centerContent={true} height={'calc(100% - 30px)'}>
                <Card cardAppearAnimationType={CardAppearAnimationType.FadeIn}
                      style={isMaxWidth470 ? {width: 'calc(100% - 20px)'} : {width: '450px'}}>
                    <div className={cl.languageSwitcherContainer}>
                        <LanguageSwitcher languages={languages} languageIconPosition={'right'}/>
                    </div>
                    <div className={cl.logoContainer}
                         style={isMaxHeight490 ? {display: 'none'} : undefined}>
                        <img className={'prevent-selection'}
                             src={'/images/logo_main.png'}
                             alt={'logo'}/>
                    </div>
                    <div className={`${cl.loginLabel} prevent-selection`}>
                        {t("login.labels.log-in")}
                    </div>
                    <ComponentsSwitch activeComponent={activeComponent}>
                        <LoginFormContent selectedUser={selectedUser}
                                          componentName={'LoginFormContent'}
                                          clearSelectedUser={() => setSelectedUser(undefined)}
                                          changeComponent={changeComponent}/>
                        {emailToGetUserList &&
                            <LoginUserListContent email={emailToGetUserList}
                                                  componentName={'LoginUserListContent'}
                                                  changeComponent={changeComponent}/>
                        }
                    </ComponentsSwitch>
                </Card>
            </Container>
            <CopyRightFooter/>
        </BackgroundImageContainer>
    );
};

export default Login;
