import React from 'react';
import {IUser} from "../../../../../app/interfaces/user/IUser";
import {ISelfBilling} from "../../../../../app/interfaces/selfBilling/ISelfBilling";
import SelfBillingDetailsBox from "./components/SelfBillingDetailsBox/SelfBillingDetailsBox";
import SelfBillingExpenseSection from "./sections/SelfBillingExpenseSection/SelfBillingExpenseSection";
import ShipmentInvoiceForm
    from "../../../../authorized/Invoice/form/components/ShipmentInvoiceForm/ShipmentInvoiceForm";

type SelfBillingFormProps = {
    isAnonymousAccess: boolean;
    isSidePanelDetails?: boolean;
    user?: IUser;
    model: ISelfBilling;
};

const SelfBillingForm: React.FC<SelfBillingFormProps> = ({
                                                             model,
                                                             isAnonymousAccess,
                                                             user,
                                                             isSidePanelDetails = false
                                                         }) => {
    return (
        <div className={isAnonymousAccess ? `full details h100 hidden` : 'full details h100'}>
            <div className={isAnonymousAccess ? 'o-auto h100' : 'h100'}>
                {model.isCmrAllowedForSp && <SelfBillingDetailsBox model={model}
                                       user={user}
                                       isSidePanelDetails={isSidePanelDetails}
                                       isAnonymousAccess={isAnonymousAccess}/>}

                <SelfBillingExpenseSection isAnonymousAccess={isAnonymousAccess}
                                           model={model}
                                           user={user}
                                           isSidePanelDetails={isSidePanelDetails}/>

                {model.shipmentInvoice && model.invoices.length > 0 &&
                    <ShipmentInvoiceForm data={model.shipmentInvoice} showTotalSum={model.expenses.length == 0}
                                         showCollapse={true} isSidePanelDetails={isSidePanelDetails}
                                         user={user}/>
                }
            </div>
        </div>
    );
};

export default SelfBillingForm;
