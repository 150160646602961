import {
    useGetForwarderNotificationDetailsData,
    useGetNotificationDetailsData, useSaveForwarderNotificationDetailsData, useSaveNotificationDetailsData,
    useUnsubscribeFromTelegramNotifications,
    useUnsubscribeFromViberNotifications
} from "./requests";

const useNotificationApi = () => {
    const {
        isLoading: getNotificationDetailsDataIsLoading,
        cancel: cancelGetNotificationDetailsData,
        getNotificationDetailsData
    } = useGetNotificationDetailsData();

    const {
        isLoading: saveNotificationDetailsDataIsLoading,
        cancel: cancelSaveNotificationDetailsData,
        saveNotificationDetailsData
    } = useSaveNotificationDetailsData();

    const {
        isLoading: unsubscribeFromTelegramNotificationsIsLoading,
        cancel: cancelUnsubscribeFromTelegramNotifications,
        unsubscribeFromTelegramNotifications
    } = useUnsubscribeFromTelegramNotifications();

    const {
        isLoading: unsubscribeFromViberNotificationsIsLoading,
        cancel: cancelUnsubscribeFromViberNotifications,
        unsubscribeFromViberNotifications
    } = useUnsubscribeFromViberNotifications();

    const {
        isLoading: getForwarderNotificationDetailsDataIsLoading,
        cancel: cancelGetForwarderNotificationDetailsData,
        getForwarderNotificationDetailsData
    } = useGetForwarderNotificationDetailsData();

    const {
        isLoading: saveForwarderNotificationDetailsDataIsLoading,
        cancel: cancelSaveForwarderNotificationDetailsData,
        saveForwarderNotificationDetailsData
    } = useSaveForwarderNotificationDetailsData();

    return {
        getNotificationDetailsData: {
            query: getNotificationDetailsData,
            isLoading: getNotificationDetailsDataIsLoading,
            cancel: cancelGetNotificationDetailsData
        },
        saveNotificationDetailsData: {
            mutation: saveNotificationDetailsData,
            isLoading: saveNotificationDetailsDataIsLoading,
            cancel: cancelSaveNotificationDetailsData
        },
        unsubscribeFromTelegramNotifications: {
            mutation: unsubscribeFromTelegramNotifications,
            isLoading: unsubscribeFromTelegramNotificationsIsLoading,
            cancel: cancelUnsubscribeFromTelegramNotifications
        },
        unsubscribeFromViberNotifications: {
            mutation: unsubscribeFromViberNotifications,
            isLoading: unsubscribeFromViberNotificationsIsLoading,
            cancel: cancelUnsubscribeFromViberNotifications
        },
        getForwarderNotificationDetailsData: {
            query: getForwarderNotificationDetailsData,
            isLoading: getForwarderNotificationDetailsDataIsLoading,
            cancel: cancelGetForwarderNotificationDetailsData
        },
        saveForwarderNotificationDetailsData: {
            mutation: saveForwarderNotificationDetailsData,
            isLoading: saveForwarderNotificationDetailsDataIsLoading,
            cancel: cancelSaveForwarderNotificationDetailsData
        }
    };
};

export default useNotificationApi;