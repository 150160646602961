import { AxiosResponse } from "axios";
import useAxios from "../../../hooks/useAxios/useAxios";
import { IConfirmTruckStateGetAction } from "../../interfaces/driverCheckList/IConfirmTruckStateGetAction";
import { IDataResponse, IResponse } from "../../interfaces/response/IResponse";
import { useCallback } from "react";
import { IConfirmTruckStatePostAction } from "../../interfaces/driverCheckList/IConfirmTruckStatePostAction";



export const useGetDriverCheckListData = (): {
    isLoading: boolean,
    cancel: () => void,
    getDriverCheckListData: (id: string) => Promise<AxiosResponse<IDataResponse<IConfirmTruckStateGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IConfirmTruckStateGetAction>>({
        method: 'GET'
    });

    const getDriverCheckListData = useCallback(async (id: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/driverCheckList/getDriverCheckListData?id=${id}`
        });
    }, [execute]);

    return {isLoading, cancel, getDriverCheckListData};
};

export const useSaveDriverCheckListData = (): {
    isLoading: boolean,
    cancel: () => void,
    saveDriverCheckListData: (params: IConfirmTruckStatePostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IConfirmTruckStatePostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/driverCheckList/saveDriverCheckListDataAction`
    });

    const saveDriverCheckListData = useCallback(async (params: IConfirmTruckStatePostAction) => await execute(params), [execute]);

    return {saveDriverCheckListData, isLoading, cancel};
};

