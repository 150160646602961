import React, {ReactNode} from 'react';
import {createPortal} from "react-dom";
import {AnimatePresence, motion} from "framer-motion";
import cl from './SidePanel.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from '@fortawesome/free-solid-svg-icons';
import Icon from "../../../assets/icon/Icon";

type SidePanelProps = {
    id: string;
    isShown: boolean;
    setIsShown: (val: boolean) => void;
    children: ReactNode;
    showCloseButton?: boolean;
}

const SidePanel: React.FC<SidePanelProps> = ({
                                                 id,
                                                 isShown,
                                                 setIsShown,
                                                 children,
                                                 showCloseButton = true,
                                             }) => {
    return (
        createPortal(
            <AnimatePresence>
                {isShown &&
                    <motion.div className={cl.panel}
                                key={`overlay-${id}`}
                                initial={{width: "0"}}
                                animate={{width: "800px"}}
                                exit={{width: "0"}}
                                transition={{ease: "easeOut", duration: 0.8}}>
                        <>
                            {isShown && showCloseButton !== undefined && showCloseButton &&
                                <button onClick={() => setIsShown(false)}
                                        className={`default-form-close-btn ${cl.close}`}>
                                    <Icon icon={"faXMark"}/>
                                </button>
                            }
                                {children}
                        </>
                    </motion.div>
                }
            </AnimatePresence>,
            document.body
        )
    );
};

export default SidePanel;
/*
   <div className={cl.close} onClick={() => setIsShown(false)}>
                                    <FontAwesomeIcon icon={faXmark} />
                                </div>
 */
