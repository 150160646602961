import {IQuoteHeaderConfig} from "./interfaces/IQuoteHeaderConfig";

const defaultQuoteHeaderConfig: IQuoteHeaderConfig = {
    subTab: 'quote-my-quotes-tab',
    CM: false,
    TLM: false,
    SMFA: false,
    SMEXA: false,
    OM: false,
    pinnedFilter: null,
    pinnedPredefinedFilters: null,
    pinnedPredefinedFilter: null
};

export default defaultQuoteHeaderConfig;