import React, {useState} from 'react';
import cl from './NotesTemplate.module.css';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {useTranslation} from "react-i18next";
import ChangeNoteDialog from "../../../actions/ChangeNote/ChangeNoteDialog";
import moment from "moment";
import Icon from "../../../../../../assets/icon/Icon";

const NotesTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const {t} = useTranslation();
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);

    let sId = props.dataItem.sId !== undefined && props.dataItem.sId !== null
        ? props.dataItem.sId
        : '';
    let notes = props.dataItem.notes !== undefined && props.dataItem.notes !== null
        ? props.dataItem.notes
        : '';
    let rId = props.dataItem.rId !== undefined && props.dataItem.rId !== null
        ? props.dataItem.rId
        : '';
    let isArchive = props.dataItem.isArchive !== null && props.dataItem.isArchive !== undefined
        ? Boolean(props.dataItem.isArchive)
        : false;

    let notesUser = props.dataItem.notesUser !== undefined && props.dataItem.notesUser !== null
        ? props.dataItem.notesUser
        : '';
    let notesDate = props.dataItem.notesDate !== undefined && props.dataItem.notesDate !== null
        ? moment.utc(props.dataItem.notesDate, 'DD.MM.YY HH:mm').local().format('DD.MM.YYYY HH:mm')
        : '';

    if (isArchive || !sId || sId === '') {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={'default-left-cell default-overflow-cell'}>
                    {notes}
                </span>
            </td>
        )
    }

    return (
        <td {...props.tdProps}
            colSpan={1}>
            <ChangeNoteDialog id={rId}
                              sId={sId}
                              show={isDialogShown}
                              setShow={setIsDialogShown}/>
            <span onClick={handleClick} className={'default-left-cell default-overflow-cell grid-prevent-selection'}>
                <>

                <Icon icon={"faPen"}
                      className={`${cl.icon} ${notes && notes !== '' ? cl.iconWithValue : ''} shipment-grid-edit-notes-btn grid-prevent-selection`}
                      onClick={(ev) => {
                          ev.stopPropagation();

                          setIsDialogShown(prev => !prev);
                      }}/>
                    {notes && notes !== ''
                        ? <span className={`grid-prevent-selection ${cl.text}`} title={notesUser + " " + notesDate}
                                onClick={(ev) => {
                                    ev.stopPropagation();

                                    setIsDialogShown(prev => !prev);
                                }}>
                        {notes}
                    </span>
                        : <span className={`grid-prevent-selection ${cl.addText}`}
                                onClick={(ev) => {
                                    ev.stopPropagation();

                                    setIsDialogShown(prev => !prev);
                                }}>
                        {t("shipment.grid.add-note")}
                    </span>
                    }
                </>
            </span>
        </td>
    );
};

export default NotesTemplate;
