import React from 'react';
import cl from './FormLayoutToolbarButton.module.css';
import Icon, {IconName} from "../../../../../assets/icon/Icon";

interface IFormLayoutToolbarButtonBaseProps {
    id: string;
    title: string;
    disabled?: boolean;
    icon: IconName;
    style?: React.CSSProperties;
}

interface IFormLayoutToolbarDefaultButton extends IFormLayoutToolbarButtonBaseProps {
    onClick: () => void;
}

interface IFormLayoutToolbarToggleButtonProps extends IFormLayoutToolbarButtonBaseProps {
    toggle: boolean;
    isToggled: boolean;
    setIsToggled: React.Dispatch<React.SetStateAction<boolean>>;
    onClick: (checked: boolean) => void;
}

export const isFormLayoutToolbarToggleButtonProps = (item: IFormLayoutToolbarToggleButtonProps | IFormLayoutToolbarDefaultButton): item is IFormLayoutToolbarToggleButtonProps => {
    return (item as IFormLayoutToolbarToggleButtonProps).toggle !== undefined;
}

export type FormLayoutToolbarButtonProps = IFormLayoutToolbarToggleButtonProps | IFormLayoutToolbarDefaultButton;

const FormLayoutToolbarButton: React.FC<FormLayoutToolbarButtonProps> = (props) => {
    if (isFormLayoutToolbarToggleButtonProps(props) && props.toggle) {
        const {
            title,
            onClick,
            disabled,
            isToggled,
            setIsToggled,
            icon,
            id
        } = props;

        return (
            <label id={id}
                   title={title}
                   className={`${cl.button} ${isToggled ? cl.toggle : ''} ${disabled ? cl.disabled : ''} transition-all-500`}>
                <input type={'checkbox'} checked={isToggled}
                       onChange={() => {
                           if (disabled) {
                               return;
                           }

                           setIsToggled(prev => {
                               onClick(!prev);

                               return !prev;
                           });
                       }}/>
                <span>
                    <Icon icon={icon}/>
                </span>
            </label>
        );
    } else if (!isFormLayoutToolbarToggleButtonProps(props)) {
        const {title, onClick, disabled, icon, id, style} = props;

        return (
            <button title={title}
                    id={id}
                    onClick={onClick}
                    className={`${cl.button} transition-all-500`}
                    style={style}
                    disabled={disabled}>
                <span>
                    <Icon icon={icon}/>
                </span>
            </button>
        );
    }

    return null;
};

export default FormLayoutToolbarButton;