import React, {useState} from 'react';
import cl from './SendTranspareonAccountRequest.module.css';
import {useTranslation} from "react-i18next";
import {useQuoteApi} from "../../../../../app/api/quote";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {isAxiosError} from "axios";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";

type SendTranspareonAccountRequestProps = {
    gid: string;
    asstraCompany: string;
    close?: () => void;
}

const SendTranspareonAccountRequest: React.FC<SendTranspareonAccountRequestProps> = ({
                                                                                         gid,
                                                                                         asstraCompany,
                                                                                         close
                                                                                     }) => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        sendTranspareonAccountRequest: {
            mutation
        }
    } = useQuoteApi();

    const save = async (): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            const response = await mutation({
                orderReleaseGid: gid,
                asstraCompany: asstraCompany
            });

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [{
                            id: gid,
                            isRowLoading: true
                        }]
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.quote")} ${gid}`}/>);
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(e.message);
            }
        }

        setDisabled(prev => !prev);
    }

    return (
        <div className={'h100 w100'}>
            <div className={cl.btnContainer}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            style={{minWidth: '150px'}}
                            onClick={close}
                            className={'close-button'}>
                        {t("shared.btn.no")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        style={{minWidth: '150px'}}
                        onClick={save}
                        isLoading={disabled}
                        className={'confirm-button'}>
                    {t("shared.btn.yes")}
                </Button>
            </div>
        </div>
    );
};

export default SendTranspareonAccountRequest;