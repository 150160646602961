import React from 'react';
import {IUser} from "../../../../../app/interfaces/user/IUser";
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import ChangeCost from "./ChangeCost";

type ChangeCostDialogProps = {
    shipmentGid: string;
    user?: IUser;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChangeCostDialog: React.FC<ChangeCostDialogProps> = ({
                                                               shipmentGid,
                                                               user,
                                                               show,
                                                               setShow
                                                           }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'change-cost-dialog'}
                show={show}
                setShow={setShow}
                width={'850px'}
                title={t("self-billing.btn.change-cost")}>
            <ChangeCost shipmentGid={shipmentGid}
                        user={user}
                        close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default ChangeCostDialog;