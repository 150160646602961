import {RootState} from "../../app/store/store";

export const selectDeviceId = (state: RootState) => state.application.deviceId;

export const selectLogoutMessage = (state: RootState) => state.application.logoutMessage;

export const selectLanguage = (state: RootState) => state.application.language;

export const selectIsSidebarExpanded = (state: RootState) => state.application.isSidebarExpanded;

export const selectIsSidebarHoverExpanded = (state: RootState) => state.application.isSidebarHoverExpanded;

export const selectIsAccessSettingsPanelOpened = (state: RootState) => state.application.isAccessSettingsPanelOpened;

