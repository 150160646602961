import React from 'react';
import {IUser} from "../../../app/interfaces/user/IUser";
import cl from './UserAvatar.module.css';
import {useAppSelector} from "../../../app/store";
import {selectIsChatSocketConnected} from "../../../features/chat/chatSliceSelectors";

type UserAvatarProps = {
    user: IUser;
    size: 'big' | 'small' | 'medium';
};

const getUserName = (usr: IUser): string => {
    let parts = usr.name.split(' ').filter(e => e !== '' && e !== null && e !== undefined);
    let result = '';

    for (const part of parts) {
        if (result.length === 2)
            break;

        if (part[0].match(/[a-z]/i)) {
            result += part[0].toUpperCase();
        }
    }

    if (result.length === 1 && parts[0].length > 1 && parts[0][1].match(/[a-z]/i)) {
        result += parts[0][1].toUpperCase();
    }

    return result;
};

const UserAvatar: React.FC<UserAvatarProps> = ({
                                                   user,
                                                   size
                                               }) => {
    const isConnected = useAppSelector(selectIsChatSocketConnected);

    return (
        <div className={`${cl.avatar} prevent-selection`}
             data-size={size}>
            {getUserName(user)}
            {isConnected &&
                <span className={cl.online}></span>
            }
        </div>
    );
};

export default UserAvatar;
