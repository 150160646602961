import React, {useState} from 'react';
import {RouteSectionModel} from "../../RouteSection";
import cl from './RouteSectionStopPointList.module.css';
import {IStopPoint} from "../../../../../../app/interfaces/shared/IStopPoint";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../helpers/user";
import RefinePositionDialog
    from "../../../../../../pages/authorized/Shipment/actions/RefinePosition/RefinePositionDialog";
import {UserRole} from "../../../../../../app/enums/UserRole";
import ChangePlannedDateDialog
    from "../../../../../../pages/authorized/Shipment/actions/ChangePlannedDate/ChangePlannedDateDialog";
import Icon, {IconName} from "../../../../../../assets/icon/Icon";

type RouteSectionStopPointListProps = {
    model: RouteSectionModel;
    showEdit?: boolean
}

const getStopPointIcon = (type: string): IconName | null => {
    if (type === undefined || type === '') {
        return null;
    }

    switch (type.toLowerCase()) {
        case '[p]':
            return "faArrowRight";
        case '[o]':
            return "faRecordVinyl";
        case '[c]':
            return "faRecordVinyl";
        case '[d]':
            return "faArrowLeft";
    }

    return null;
}

const getStopPointTypeResource = (type: string): string => {
    if (type === undefined || type === '') {
        return '';
    }

    switch (type.toLowerCase()) {
        case '[p]':
            return 'stopPoint.types.p';
        case '[o]':
            return 'stopPoint.types.o';
        case '[c]':
            return 'stopPoint.types.c';
        case '[d]':
            return 'stopPoint.types.d';
    }

    return '';
}

const getStopPointDescription = (sp: IStopPoint): string => {
    let description: string = '';

    if (sp.info !== null && sp.info !== undefined && sp.info !== '') {
        description += sp.info;
    }

    if (sp.date !== null && sp.date !== undefined) {
        description += ` : ${moment(sp.date).format('DD.MM.YY HH:mm')}`;
    }

    return description;
}

const RouteSectionStopPointList: React.FC<RouteSectionStopPointListProps> = ({showEdit, model}) => {
    const [refineItem, setRefineItem] = useState<string | null>(null);

    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);
    const [isChangeDialogShown, setIsChangeDialogShown] = useState<boolean>(false);
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    let role = user ? getUserRole(user) : null;

    if (model.stopPoints === null || model.stopPoints.length <= 0) {
        return <>{t("shared.labels.no-data")}</>;
    }

    return (
        <>
            {refineItem !== null && model.shipmentGid !== null &&
                <RefinePositionDialog shipmentGid={model.shipmentGid ?? ''}
                                      locationGid={refineItem}
                                      show={isDialogShown}
                                      setShow={setIsDialogShown}/>
            }

            <ChangePlannedDateDialog id={model.orderReleaseGid ?? ''}
                                     sId={model.shipmentGid ?? ''}
                                     user={user}
                                     show={isChangeDialogShown}
                                     setShow={setIsChangeDialogShown}/>


            {model.stopPoints
                .sort((a, b) => {
                    return a.position - b.position;
                }).map(sp => {
                    if (sp.type === null || sp.type === '') {
                        return null;
                    }

                    let icon = getStopPointIcon(sp.type);

                    if (icon === null) {
                        return null;
                    }

                    return (
                        <div key={sp.position} className={`${cl.sp} stop-point-${sp.position}`}>
                            <div className={cl.spLabel}>
                                <Icon icon={icon}
                                      className={cl.icon}
                                      dataCustomValueAttribute={sp.type.toUpperCase()}/>

                                <label>
                                    {`${t(getStopPointTypeResource(sp.type))} ${getStopPointDescription(sp)}`}
                                </label>

                                {showEdit && model.shipmentGid !== '' && !model.isFinished && role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator) &&
                                    <Icon icon={"faPen"}
                                          className={`${cl.edit}`}
                                          onClick={(_ev) => {

                                              setIsChangeDialogShown(prev => !prev)
                                          }}/>
                                }
                            </div>

                            <span className={cl.address}>
                                {sp.address}
                            </span>

                            {model.hasShipment && (sp.type.toLowerCase() === "[p]" || sp.type.toLowerCase() === "[d]") && role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                ? (sp.needCheck
                                        ? <span className={cl.refineContainer}>
                                            <Icon icon={"faLocationDot"}
                                                  className={`${cl.needCheck} ${cl.locationDot}`}/>
                                            <a className={`stop-point-${sp.position}-refine-btn ${cl.needCheck}`}
                                               onClick={() => {
                                                   if (sp.locationGid === null || sp.locationGid === '')
                                                       return;

                                                   setRefineItem(sp.locationGid ?? '');
                                                   setIsDialogShown(prev => !prev)
                                               }}>
                                                {t("shared.btn.refine")}
                                            </a>
                                        </span>
                                        : <span className={cl.refineContainer}>
                                            <Icon icon={"faLocationDot"}
                                                  className={`${cl.noNeedCheck} ${cl.locationDot}`}/>
                                            <a className={`stop-point-${sp.position}-refine-btn ${cl.noNeedCheck}`}
                                               onClick={() => {
                                                   if (sp.locationGid === null || sp.locationGid === '')
                                                       return;

                                                   setRefineItem(sp.locationGid ?? '');
                                                   setIsDialogShown(prev => !prev)
                                               }}>
                                                {t("shared.btn.refine")}
                                            </a>
                                        </span>
                                )
                                : null
                            }
                        </div>
                    );
                })}
        </>
    );
};

export default RouteSectionStopPointList;
