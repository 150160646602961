import React, {useMemo} from 'react';
import cl from './OtpInput.module.css';

const IS_DIGIT = new RegExp(/^\d+$/);

type OtpInputProps = {
    value: string;
    valueLength: number;
    onChange: (value: string) => void;
    disabled: boolean;
}

const OtpInput: React.FC<OtpInputProps> = ({
                                               value,
                                               valueLength,
                                               onChange,
                                               disabled
                                           }) => {
    const valueItems = useMemo(() => {
        const valueArray = value.split('');
        const items: Array<string> = [];

        for (let i = 0; i < valueLength; i++) {
            items.push(IS_DIGIT.test(valueArray[i]) ? valueArray[i] : '')
        }

        return items;
    }, [value, valueLength]);

    const onInputChange = (ev: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        const target = ev.target;
        let targetValue = target.value.trim();
        const isTargetValueDigit = IS_DIGIT.test(targetValue);

        if (!isTargetValueDigit && targetValue !== '') {
            return;
        }

        const nextInputEl = target.nextElementSibling as HTMLInputElement | null;

        if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
            return;
        }

        targetValue = isTargetValueDigit ? targetValue : ' ';

        const targetValueLength = targetValue.length;

        if (targetValueLength === 1) {
            const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1);

            onChange(newValue);

            if (!isTargetValueDigit) {
                return;
            }

            const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;

            if (nextElementSibling) {
                nextElementSibling.focus();
            }
        } else if (targetValueLength === valueLength) {
            onChange(targetValue);

            target.blur();
        }
    }


    const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;

        target.setSelectionRange(0, target.value.length);

        if (e.key !== 'Backspace' || target.value !== '') {
            return;
        }

        const previousElementSibling = target.previousElementSibling as HTMLInputElement | null;

        if (previousElementSibling) {
            previousElementSibling.focus();
        }
    };

    const inputOnFocus = (ev: React.FocusEvent<HTMLInputElement>) => {
        const previousInputElement = ev.target.previousElementSibling as HTMLInputElement | null;

        if (previousInputElement && previousInputElement.value === '') {
            return previousInputElement.focus();
        }

        ev.target.setSelectionRange(0, ev.target.value.length);
    };

    return (
        <div className={cl.otpGroup}>
            {valueItems.map((digit, idx) => (
                <input key={idx}
                       type="text"
                       inputMode="numeric"
                       autoComplete="one-time-code"
                       pattern="\d{1}"
                       disabled={disabled}
                       onChange={(ev) => onInputChange(ev, idx)}
                       onKeyDown={inputOnKeyDown}
                       onFocus={inputOnFocus}
                       maxLength={valueLength}
                       className={cl.otpInput}
                       value={digit}
                />
            ))}
        </div>
    );
};

export default OtpInput;