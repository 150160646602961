import {IApplicationState} from "./IApplicationState";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Language} from "../../app/enums/Language";

const getIsSidebarExpanded = (): boolean => {
    const state = localStorage.getItem('sidebar_state');
    if (state) {
        return JSON.parse(state);
    }

    return false;
}

const initialState: IApplicationState = {
    language: Language.EN,
    isSidebarExpanded: getIsSidebarExpanded(),
    isSidebarHoverExpanded: false,
    isAccessSettingsPanelOpened: false
};

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        setUtcOffset: (state, action: PayloadAction<number | undefined>) => {
            state.utcOffset = action.payload;
        },
        setDeviceId: (state, action: PayloadAction<string | undefined>) => {
            state.deviceId = action.payload;
        },
        setLogoutMessage: (state, action: PayloadAction<string | undefined>) => {
            state.logoutMessage = action.payload;
        },
        setLanguage: (state, action: PayloadAction<Language>) => {
            state.language = action.payload;
        },
        setIsSidebarExpanded: (state, action: PayloadAction<boolean>) => {
            state.isSidebarExpanded = action.payload;
        },
        setIsSidebarHoverExpanded: (state, action: PayloadAction<boolean>) => {
            state.isSidebarHoverExpanded = action.payload;
        },
        setIsAccessSettingsPanelOpened: (state, action: PayloadAction<boolean>) => {
            state.isAccessSettingsPanelOpened = action.payload;
        },
        toggleIsAccessSettingsPanelOpened: (state) => {
            state.isAccessSettingsPanelOpened = !state.isAccessSettingsPanelOpened;
        }
    }
});

export const {
    setUtcOffset,
    setDeviceId,
    setLogoutMessage,
    setLanguage,
    setIsSidebarExpanded,
    setIsSidebarHoverExpanded,
    setIsAccessSettingsPanelOpened,
    toggleIsAccessSettingsPanelOpened
} = applicationSlice.actions;

export default applicationSlice.reducer;
