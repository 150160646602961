import {RootState} from "../../app/store/store";

export const selectUserAuthenticationStatus = (state: RootState) => state.authentication.userAuthenticationStatus;

export const selectAnonymousUserAuthenticationStatus = (state: RootState) => state.authentication.anonymousUserAuthenticationStatus;

export const selectAnonymousUserCode = (state: RootState) => state.authentication.anonymousUserCode;

export const selectDeviceAuthenticationStatus = (state: RootState) => state.authentication.deviceAuthenticationStatus;

export const selectDeviceAuthenticationResult = (state: RootState) => state.authentication.deviceAuthenticationResult;

export const selectCurrentUser = (state: RootState) => state.authentication.user;

export const selectAnonymousUserId = (state: RootState) => state.authentication.anonymousUserId;