import {AxiosResponse} from "axios";
import {IDataResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {IChat} from "../../interfaces/chat/IChat";
import {IGridResultResponse} from "../../interfaces/response/IGridResultResponse";
import {IChatMessage} from "../../interfaces/chat/IChatMessage";
import {UserRole} from "../../enums/UserRole";
import {IChatUser} from "../../interfaces/chat/IChatUser";

export const useGetChats = (): {
    isLoading: boolean,
    cancel: () => void,
    getChats: (filter: string) => Promise<AxiosResponse<IDataResponse<Array<IChat>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<IChat>>>({
        method: 'get'
    });

    const getChats = useCallback(async (filter: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/chat/getChats?filter=${filter}`
    }), [execute]);

    return {getChats, isLoading, cancel};
};

export const useGetChat = (): {
    isLoading: boolean,
    cancel: () => void,
    getChat: (user: string) => Promise<AxiosResponse<IDataResponse<IChat>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IChat>>({
        method: 'get'
    });

    const getChat = useCallback(async (user: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/chat/getChat?user=${user}`
    }), [execute]);

    return {getChat, isLoading, cancel};
};

export const useGetMessages = (): {
    isLoading: boolean,
    cancel: () => void,
    getMessages: (user: string, filter: string, skip?: number, take?: number, start?: string) => Promise<AxiosResponse<IGridResultResponse<IChatMessage>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IGridResultResponse<IChatMessage>>({
        method: 'get',
    });

    const getMessages = useCallback(async (user: string, filter: string, skip?: number, take?: number, start?: string) => {
        let q = `api/${process.env.REACT_APP_API_VERSION}/chat/getMessages`;

        q += `?user=${user}`

        if (start && start !== '') {
            q += `&start=${start}Z`;
        } else {

            if (filter && filter !== '') {
                q += `&filter=${filter}`;
            }

            if (skip !== undefined && skip >= 0 && take !== undefined && take >= 0) {
                q += `&skip=${skip}&take=${take}`;
            }
        }

        return await execute(undefined, {
            url: q
        });
    }, [execute]);

    return {getMessages, isLoading, cancel};
}

export const useGetUsersForChatSelection = (): {
    isLoading: boolean,
    cancel: () => void,
    getUsersForChatSelection: (role: UserRole, ids: string) => Promise<AxiosResponse<IDataResponse<Array<IChatUser>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<IChatUser>>>({
        method: 'get'
    });

    const getUsersForChatSelection = useCallback(async (role: UserRole, ids: string) => {
        let q = `api/${process.env.REACT_APP_API_VERSION}/chat/getUsersForChatSelection?role=${role}&ids=${ids}`;

        return await execute(undefined, {
            url: q
        });
    }, [execute]);

    return {getUsersForChatSelection, isLoading, cancel};
};

export const useGetUserChatData = (): {
    isLoading: boolean,
    cancel: () => void,
    getUserChatData: (id: string) => Promise<AxiosResponse<IDataResponse<IChat>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IChat>>({
        method: 'get'
    });

    const getUserChatData = useCallback(async (id: string) => {
        let q = `api/${process.env.REACT_APP_API_VERSION}/chat/getUserChatData?id=${id}`;

        return await execute(undefined, {
            url: q
        });
    }, [execute]);

    return {getUserChatData, isLoading, cancel};
};