import React, {ReactNode} from 'react';
import {useCollapse} from "react-collapsed";
import cl from './Collapse.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

type CollapseProps = {
    children: ReactNode;
    defaultExpanded?: boolean;
    hideCollapse?:boolean;
    title: string | ReactNode;
}

const Collapse: React.FC<CollapseProps> = ({
                                               children,
                                               defaultExpanded,
                                               hideCollapse,
                                               title
                                           }) => {
    const config = {
        defaultExpanded: defaultExpanded !== undefined ? defaultExpanded : true,
    };

    const {getCollapseProps, getToggleProps, isExpanded} = useCollapse(config);

    return (
        <div className={cl.collapse}>
            <div className={cl.header} {...getToggleProps()}>
                <div className={`${cl.title} prevent-selection`}>{title}</div>
                {!hideCollapse && <div className={cl.icon}>
                    {isExpanded
                        ? <FontAwesomeIcon icon={faMinus}/>
                        : <FontAwesomeIcon icon={faPlus}/>
                    }
                </div>}
            </div>
            <div {...getCollapseProps()}>
                <div className="content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Collapse;
