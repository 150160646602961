import React, {useState} from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {useQuoteApi} from "../../../../../../app/api/quote";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import {useTranslation} from "react-i18next";
import auctionImg from '../../../../../../assets/images/auction.png';
import cl from './OrderReleaseXidTemplate.module.css';
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import SendMessageToMultipleServiceProvidersDialog
    from "../../../../../../components/shared/components/Chat/actions/SendMessageToMultipleServiceProviders/SendMessageToMultipleServiceProvidersDialog";

const OrderReleaseXidTemplate = (props: any) => {
    const {t} = useTranslation();
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const [isSendMessageDialogShown, setIsSendMessageDialogShown] = useState<boolean>(false);

    const {
        getQuoteManagementLink: {
            query: getQuoteManagementLink
        }
    } = useQuoteApi();

    let id = props.dataItem.id !== null &&
    props.dataItem.id !== undefined && props.dataItem.id !== ''
        ? props.dataItem.id
        : null;

    let orderReleaseXid = props.dataItem.orderReleaseXid !== null &&
    props.dataItem.orderReleaseXid !== undefined && props.dataItem.orderReleaseXid !== ''
        ? props.dataItem.orderReleaseXid
        : null;

    let isAuction = props.dataItem.isAuction !== null && props.dataItem.isAuction !== undefined && props.dataItem.isAuction;
    let isRejected = props.dataItem["isRejected"] !== null && props.dataItem["isRejected"] !== undefined && props.dataItem["isRejected"] === true;
    let readBy = props.dataItem["readBy"] !== null && props.dataItem["readBy"] !== undefined ? props.dataItem["readBy"] : 0;

    const user = useAppSelector(selectCurrentUser);
    if (!user) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
            </td>
        )
    }

    const role = getUserRole(user);

    if (role === UserRole.ServiceProvider) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={'default-left-cell default-overflow-cell'}>
                    {props.children}
                </span>
            </td>
        );
    }

    if (!orderReleaseXid || !id) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
            </td>
        );
    }

    let releaseNumber = props.dataItem['orderReleaseXid'];
    let description = '';

    if(props.dataItem['pickupDate'] && props.dataItem['sourceDistrict'] && props.dataItem['sourceCity']
        && props.dataItem['destinationDistrict'] && props.dataItem['destinationCity']){
        description = `${props.dataItem['pickupDate']}, ${props.dataItem['sourceDistrict']} ${props.dataItem['sourceCity']}`;

        description += ` - ${props.dataItem['destinationDistrict']} ${props.dataItem['destinationCity']}`
    }

    return (
        <>
            <SendMessageToMultipleServiceProvidersDialog show={isSendMessageDialogShown}
                                                         setShow={setIsSendMessageDialogShown}
                                                         replyTo={{
                                                             type: 'QUOTE',
                                                             id: releaseNumber,
                                                             idExt: null,
                                                             description: description === '' ? null : description
                                                         }}/>

            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
            <span className={'default-flex-cell default-overflow-cell grid-prevent-selection'}>

                {isRejected &&
                    <div className={cl.rejectedMark}
                         title={t("quote.grid.rejected-mark")}>

                    </div>
                }
                <button className={'grid-prevent-selection shipment-grid-open-otm-shipment-btn cell-button'}
                        onClick={async (ev) => {
                            if (!id) {
                                return;
                            }

                            ev.stopPropagation();

                            try {
                                const response = await getQuoteManagementLink(id);

                                if (response && response.data && response.data.resultCode === ResponseResultCode.Ok &&
                                    response.data.data) {
                                    window.open(response.data.data, '_blank');
                                }
                            } catch {
                                //ignore
                            }
                        }}>
                    {props.children}
                  </button>

                {readBy > 0 &&
                    <div className={`${cl.readByMark} grid-prevent-selection`}
                         title={t("quote.grid.readBy-mark")}>
                        <FontAwesomeIcon icon={faEye}
                                         title={t("quote.grid.readBy-mark")}
                                         className={`${cl.readByMarkIcon} grid-prevent-selection`}
                                         onClick={(ev) => {
                                             ev.stopPropagation();

                                             setIsSendMessageDialogShown(prev => !prev);
                                         }}/>
                        <span className={cl.readByMarkCount}>{readBy}</span>
                    </div>
                }

                {isAuction &&
                    <img alt={'auction-release'}
                         className={cl.auction}
                         title={t("quote.grid.is-auction")}
                         src={auctionImg}/>}
            </span>
            </td>
        </>
    );
}

export default OrderReleaseXidTemplate;
