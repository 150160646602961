import React from 'react';
import {ExtendedNettoBet} from "../../QuoteNettoBetList";
import cl from './QuoteNettoBetListItemOverview.module.css';
import {useTranslation} from "react-i18next";

type QuoteNettoBetListItemOverviewProps = {
    nettoBet: ExtendedNettoBet;
}

const QuoteNettoBetListItemOverview: React.FC<QuoteNettoBetListItemOverviewProps> = ({nettoBet}) => {
    const {t} = useTranslation();

    if (nettoBet.isTransporeon) {
        return (
            <div className={cl.container}>
                <div>
                    <label className={'view-label sell-rate-label'}>{t('quote.bet.sell-rate')}</label>
                    <span className={'sell-rate-value'}>
                        {nettoBet.sellRate !== null && nettoBet.sellRate !== undefined && nettoBet.sellRate !== ''
                            ? <>{`${nettoBet.sellRate} ${nettoBet.sellRateCurrencyCode !== null && nettoBet.sellRateCurrencyCode !== '' ? nettoBet.sellRateCurrencyCode : ''}`}</>
                            : <>{t("shared.labels.no-data")}</>
                        }
                    </span>
                </div>
                <div>
                    <label className={'view-label sell-rate-target-label'}>{t('quote.bet.target-sell-rate')}</label>
                    <span className={'sell-rate-target-value'}>
                        {nettoBet.sellRateTarget !== null && nettoBet.sellRateTarget !== undefined && nettoBet.sellRateTarget !== ''
                            ? <>{`${nettoBet.sellRateTarget}`}</>
                            : <>{t("shared.labels.no-data")}</>
                        }
                    </span>
                </div>
                <div>
                    <label className={'view-label till-date-label'}>{t('quote.bet.till-date')}</label>
                    <span className={'till-date-value'}>
                        {nettoBet.tillDate !== null && nettoBet.tillDate !== undefined && nettoBet.tillDate !== ''
                            ? <>{`${nettoBet.tillDate} ${nettoBet.tillDateTime !== null && nettoBet.tillDateTime !== '' ? nettoBet.tillDateTime : ''}`}</>
                            : <>{t("shared.labels.no-data")}</>
                        }
                    </span>
                </div>
            </div>
        );
    } else if ((nettoBet.bet !== null && nettoBet.bet !== '') || nettoBet.bets.length > 0) {
        return (
            <div className={cl.container}>
                <div>
                    <label className={'view-label sell-rate-label'}>{t('quote.bet.sell-rate')}</label>
                    <span className={'sell-rate-value'}>
                        {nettoBet.sellRate !== null && nettoBet.sellRate !== undefined && nettoBet.sellRate !== ''
                            ? <>{`${nettoBet.sellRate} ${nettoBet.sellRateCurrencyCode !== null && nettoBet.sellRateCurrencyCode !== '' ? nettoBet.sellRateCurrencyCode : ''}`}</>
                            : <>{t("shared.labels.no-data")}</>
                        }
                    </span>
                </div>
                <div>
                    <label className={'view-label bet-label'}>{t('quote.bet.bet')}</label>
                    <span className={'bet-value'}>
                        {nettoBet.bet !== null && nettoBet.bet !== undefined && nettoBet.bet !== ''
                            ? <>{`${nettoBet.bet} ${nettoBet.currencyCode !== null && nettoBet.currencyCode !== '' ? nettoBet.currencyCode : ''}`}</>
                            : <>{t("shared.labels.no-data")}</>
                        }
                    </span>
                </div>
                <div>
                    <label className={'view-label profit-label'}>{t('quote.bet.profit')}</label>
                    <span className={'profit-value'}>
                        {nettoBet.profit !== null && nettoBet.profit !== undefined && nettoBet.profit !== ''
                            ? <>{`${nettoBet.profit} ${nettoBet.profitCurrencyCode !== null && nettoBet.profitCurrencyCode !== '' ? nettoBet.profitCurrencyCode : ''}`}</>
                            : <>{t("shared.labels.no-data")}</>
                        }
                    </span>
                </div>
                <div>
                    <label className={'view-label sell-rate-target-label'}>{t('quote.bet.target-sell-rate')}</label>
                    <span className={'sell-rate-target-value'}>
                        {nettoBet.sellRateTarget !== null && nettoBet.sellRateTarget !== undefined && nettoBet.sellRateTarget !== ''
                            ? <>{`${nettoBet.sellRateTarget}`}</>
                            : <>{t("shared.labels.no-data")}</>
                        }
                    </span>
                </div>
                <div>
                    <label className={'view-label till-date-label'}>{t('quote.bet.till-date')}</label>
                    <span className={'till-date-value'}>
                        {nettoBet.tillDate !== null && nettoBet.tillDate !== undefined && nettoBet.tillDate !== ''
                            ? <>{`${nettoBet.tillDate} ${nettoBet.tillDateTime !== null && nettoBet.tillDateTime !== '' ? nettoBet.tillDateTime : ''}`}</>
                            : <>{t("shared.labels.no-data")}</>
                        }
                    </span>
                </div>
            </div>
        );
    }

    return null;
};

export default QuoteNettoBetListItemOverview;