import {
    useAddCmrSelfBillingDocumentData,
    useAddCmrSelfBillingDocumentDataAnonymous, useAddExpense, useAddExpenseAnonymous,
    useChangeCostSelfBillingData,
    useChangeCostSelfBillingDataAnonymous,
    useConfirmSelfBillingAnonymous,
    useDownloadCmrDocumentAnonymous,
    useDownloadCmrDocumentAuthorize,
    useEditSelfBilling,
    useEditSelfBillingAnonymous,
    useGetAddCmrSelfBillingDocumentData,
    useGetAddCmrSelfBillingDocumentDataAnonymous,
    useGetAddExpenseSelfBillingData,
    useGetAddExpenseSelfBillingDataAnonymous,
    useGetAttachmentAnonymous,
    useGetAttachmentAuthorize,
    useGetSelfBillingChangeCostData,
    useGetSelfBillingChangeCostDataAnonymous,
    useGetSelfBillingDataAnonymous,
    useGetSelfBillingDataAuthorize,
    useGetSelfBillingEditData,
    useGetSelfBillingEditDataAnonymous,
    useGetSelfBillingOverviewAnonymous,
    useGetServiceProvidersLoginsListAnonymous,
    useRequestInvalidVatAnonymous,
    useRequestInvalidVatAuthorize
} from "./requests";

const useSelfBillingApi = () => {
    const {
        isLoading: getServiceProvidersLoginsListAnonymousIsLoading,
        cancel: cancelGetServiceProvidersLoginsListAnonymous,
        getServiceProvidersLoginsList: getServiceProvidersLoginsListAnonymous
    } = useGetServiceProvidersLoginsListAnonymous();

    const {
        isLoading: getSelfBillingOverviewAnonymousIsLoading,
        cancel: cancelGetSelfBillingOverviewAnonymous,
        getSelfBillingOverview: getSelfBillingOverviewAnonymous
    } = useGetSelfBillingOverviewAnonymous();

    const {
        isLoading: confirmSelfBillingAnonymousIsLoading,
        cancel: cancelConfirmSelfBillingAnonymous,
        confirmSelfBilling: confirmSelfBillingAnonymous
    } = useConfirmSelfBillingAnonymous();

    const {
        isLoading: getSelfBillingDataAnonymousIsLoading,
        cancel: cancelGetSelfBillingDataAnonymous,
        getSelfBillingData: getSelfBillingDataAnonymous
    } = useGetSelfBillingDataAnonymous();

    const {
        isLoading: getAttachmentAnonymousIsLoading,
        cancel: cancelGetAttachmentAnonymous,
        getAttachment: getAttachmentAnonymous
    } = useGetAttachmentAnonymous();

    const {
        isLoading: downloadCmrDocumentAnonymousIsLoading,
        cancel: cancelDownloadCmrDocumentAnonymous,
        downloadCmrDocument: downloadCmrDocumentAnonymous
    } = useDownloadCmrDocumentAnonymous();

    const {
        isLoading: requestInvalidVatAnonymousIsLoading,
        cancel: cancelRequestInvalidVatAnonymous,
        requestInvalidVatAnonymous
    } = useRequestInvalidVatAnonymous();

    // For authorized users

    const {
        isLoading: getSelfBillingDataAuthorizeIsLoading,
        cancel: cancelGetSelfBillingDataAuthorize,
        getSelfBillingData: getSelfBillingDataAuthorize
    } = useGetSelfBillingDataAuthorize();

    const {
        isLoading: getAttachmentAuthorizeIsLoading,
        cancel: cancelGetAttachmentAuthorize,
        getAttachment: getAttachmentAuthorize
    } = useGetAttachmentAuthorize();

    const {
        isLoading: downloadCmrDocumentAuthorizeIsLoading,
        cancel: cancelDownloadCmrDocumentAuthorize,
        downloadCmrDocument: downloadCmrDocumentAuthorize
    } = useDownloadCmrDocumentAuthorize();

    const {
        isLoading: requestInvalidVatAuthorizeIsLoading,
        cancel: cancelRequestInvalidVatAuthorize,
        requestInvalidVatAuthorize
    } = useRequestInvalidVatAuthorize();

    const {
        isLoading: getAddCmrSelfBillingDocumentDataAnonymousIsLoading,
        cancel: cancelGetAddCmrSelfBillingDocumentDataAnonymous,
        getAddCmrSelfBillingDocumentDataAnonymous
    } = useGetAddCmrSelfBillingDocumentDataAnonymous();

    const {
        isLoading: getAddCmrSelfBillingDocumentDataIsLoading,
        cancel: cancelGetAddCmrSelfBillingDocumentData,
        getAddCmrSelfBillingDocumentData
    } = useGetAddCmrSelfBillingDocumentData();

    const {
        isLoading: addCmrSelfBillingDocumentDataIsLoading,
        cancel: cancelAddCmrSelfBillingDocumentData,
        addCmrSelfBillingDocumentData
    } = useAddCmrSelfBillingDocumentData();

    const {
        isLoading: addCmrSelfBillingDocumentDataAnonymousIsLoading,
        cancel: cancelAddCmrSelfBillingDocumentDataAnonymous,
        addCmrSelfBillingDocumentDataAnonymous
    } = useAddCmrSelfBillingDocumentDataAnonymous();

    const {
        isLoading: getSelfBillingChangeCostDataIsLoading,
        cancel: cancelGetSelfBillingChangeCostData,
        getSelfBillingChangeCostData
    } = useGetSelfBillingChangeCostData();

    const {
        isLoading: getSelfBillingChangeCostDataAnonymousIsLoading,
        cancel: cancelGetSelfBillingChangeCostDataAnonymous,
        getSelfBillingChangeCostDataAnonymous
    } = useGetSelfBillingChangeCostDataAnonymous();

    const {
        isLoading: changeCostSelfBillingDataIsLoading,
        cancel: cancelChangeCostSelfBillingData,
        changeCostSelfBillingData
    } = useChangeCostSelfBillingData();

    const {
        isLoading: changeCostSelfBillingDataAnonymousIsLoading,
        cancel: cancelChangeCostSelfBillingDataAnonymous,
        changeCostSelfBillingDataAnonymous
    } = useChangeCostSelfBillingDataAnonymous();

    const {
        isLoading: getSelfBillingEditDataIsLoading,
        cancel: cancelGetSelfBillingEditData,
        getSelfBillingEditData
    } = useGetSelfBillingEditData();

    const {
        isLoading: getSelfBillingEditDataAnonymousIsLoading,
        cancel: cancelGetSelfBillingEditDataAnonymous,
        getSelfBillingEditDataAnonymous
    } = useGetSelfBillingEditDataAnonymous();

    const {
        isLoading: editSelfBillingIsLoading,
        cancel: cancelEditSelfBilling,
        editSelfBilling
    } = useEditSelfBilling();

    const {
        isLoading: editSelfBillingAnonymousIsLoading,
        cancel: cancelEditSelfBillingAnonymous,
        editSelfBillingAnonymous
    } = useEditSelfBillingAnonymous();

    const {
        isLoading: getAddExpenseSelfBillingDataIsLoading,
        cancel: cancelGetAddExpenseSelfBillingData,
        getAddExpenseSelfBillingData
    } = useGetAddExpenseSelfBillingData();

    const {
        isLoading: getAddExpenseSelfBillingDataAnonymousIsLoading,
        cancel: cancelGetAddExpenseSelfBillingDataAnonymous,
        getAddExpenseSelfBillingDataAnonymous
    } = useGetAddExpenseSelfBillingDataAnonymous();

    const {
        isLoading: addExpenseIsLoading,
        cancel: cancelAddExpense,
        addExpense
    } = useAddExpense();

    const {
        isLoading: addExpenseAnonymousIsLoading,
        cancel: cancelAddExpenseAnonymous,
        addExpenseAnonymous
    } = useAddExpenseAnonymous();

    return {
        getServiceProvidersLoginsListAnonymous: {
            query: getServiceProvidersLoginsListAnonymous,
            isLoading: getServiceProvidersLoginsListAnonymousIsLoading,
            cancel: cancelGetServiceProvidersLoginsListAnonymous
        },
        getSelfBillingOverviewAnonymous: {
            query: getSelfBillingOverviewAnonymous,
            isLoading: getSelfBillingOverviewAnonymousIsLoading,
            cancel: cancelGetSelfBillingOverviewAnonymous
        },
        confirmSelfBillingAnonymous: {
            mutation: confirmSelfBillingAnonymous,
            isLoading: confirmSelfBillingAnonymousIsLoading,
            cancel: cancelConfirmSelfBillingAnonymous
        },
        getSelfBillingDataAnonymous: {
            query: getSelfBillingDataAnonymous,
            isLoading: getSelfBillingDataAnonymousIsLoading,
            cancel: cancelGetSelfBillingDataAnonymous
        },
        getAttachmentAnonymous: {
            query: getAttachmentAnonymous,
            isLoading: getAttachmentAnonymousIsLoading,
            cancel: cancelGetAttachmentAnonymous
        },
        downloadCmrDocumentAnonymous: {
            query: downloadCmrDocumentAnonymous,
            isLoading: downloadCmrDocumentAnonymousIsLoading,
            cancel: cancelDownloadCmrDocumentAnonymous
        },
        getSelfBillingDataAuthorize: {
            query: getSelfBillingDataAuthorize,
            isLoading: getSelfBillingDataAuthorizeIsLoading,
            cancel: cancelGetSelfBillingDataAuthorize
        },
        getAttachmentAuthorize: {
            query: getAttachmentAuthorize,
            isLoading: getAttachmentAuthorizeIsLoading,
            cancel: cancelGetAttachmentAuthorize
        },
        downloadCmrDocumentAuthorize: {
            query: downloadCmrDocumentAuthorize,
            isLoading: downloadCmrDocumentAuthorizeIsLoading,
            cancel: cancelDownloadCmrDocumentAuthorize
        },
        requestInvalidVatAuthorize: {
            mutation: requestInvalidVatAuthorize,
            isLoading: requestInvalidVatAuthorizeIsLoading,
            cancel: cancelRequestInvalidVatAuthorize
        },
        requestInvalidVatAnonymous: {
            mutation: requestInvalidVatAnonymous,
            isLoading: requestInvalidVatAnonymousIsLoading,
            cancel: cancelRequestInvalidVatAnonymous
        },
        getAddCmrSelfBillingDocumentData: {
            query: getAddCmrSelfBillingDocumentData,
            isLoading: getAddCmrSelfBillingDocumentDataIsLoading,
            cancel: cancelGetAddCmrSelfBillingDocumentData
        },
        getAddCmrSelfBillingDocumentDataAnonymous: {
            query: getAddCmrSelfBillingDocumentDataAnonymous,
            isLoading: getAddCmrSelfBillingDocumentDataAnonymousIsLoading,
            cancel: cancelGetAddCmrSelfBillingDocumentDataAnonymous
        },
        addCmrSelfBillingDocumentDataAnonymous: {
            mutation: addCmrSelfBillingDocumentDataAnonymous,
            isLoading: addCmrSelfBillingDocumentDataAnonymousIsLoading,
            cancel: cancelAddCmrSelfBillingDocumentDataAnonymous
        },
        addCmrSelfBillingDocumentData: {
            mutation: addCmrSelfBillingDocumentData,
            isLoading: addCmrSelfBillingDocumentDataIsLoading,
            cancel: cancelAddCmrSelfBillingDocumentData,
        },
        getSelfBillingChangeCostData: {
            query: getSelfBillingChangeCostData,
            isLoading: getSelfBillingChangeCostDataIsLoading,
            cancel: cancelGetSelfBillingChangeCostData,
        },
        getSelfBillingChangeCostDataAnonymous: {
            query: getSelfBillingChangeCostDataAnonymous,
            isLoading: getSelfBillingChangeCostDataAnonymousIsLoading,
            cancel: cancelGetSelfBillingChangeCostDataAnonymous
        },
        changeCostSelfBillingData: {
            mutation: changeCostSelfBillingData,
            isLoading: changeCostSelfBillingDataIsLoading,
            cancel: cancelChangeCostSelfBillingData
        },
        changeCostSelfBillingDataAnonymous: {
            mutation: changeCostSelfBillingDataAnonymous,
            isLoading: changeCostSelfBillingDataAnonymousIsLoading,
            cancel: cancelChangeCostSelfBillingDataAnonymous
        },
        getSelfBillingEditData: {
            query: getSelfBillingEditData,
            isLoading: getSelfBillingEditDataIsLoading,
            cancel: cancelGetSelfBillingEditData
        },
        getSelfBillingEditDataAnonymous: {
            query: getSelfBillingEditDataAnonymous,
            isLoading: getSelfBillingEditDataAnonymousIsLoading,
            cancel: cancelGetSelfBillingEditDataAnonymous
        },
        editSelfBilling: {
            mutation: editSelfBilling,
            isLoading: editSelfBillingIsLoading,
            cancel: cancelEditSelfBilling
        },
        editSelfBillingAnonymous: {
            mutation: editSelfBillingAnonymous,
            isLoading: editSelfBillingAnonymousIsLoading,
            cancel: cancelEditSelfBillingAnonymous
        },
        getAddExpenseSelfBillingData: {
            query: getAddExpenseSelfBillingData,
            isLoading: getAddExpenseSelfBillingDataIsLoading,
            cancel: cancelGetAddExpenseSelfBillingData
        },
        getAddExpenseSelfBillingDataAnonymous: {
            query: getAddExpenseSelfBillingDataAnonymous,
            isLoading: getAddExpenseSelfBillingDataAnonymousIsLoading,
            cancel: cancelGetAddExpenseSelfBillingDataAnonymous
        },
        addExpense: {
            mutation: addExpense,
            isLoading: addExpenseIsLoading,
            cancel: cancelAddExpense
        },
        addExpenseAnonymous: {
            mutation: addExpenseAnonymous,
            isLoading: addExpenseAnonymousIsLoading,
            cancel: cancelAddExpenseAnonymous
        }
    };
};

export default useSelfBillingApi;