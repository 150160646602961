import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import './assets/styles/grid.css';
import './assets/styles/pickers.css';
import './assets/styles/checkbox.css';
import './assets/styles/chat.css';
import './assets/icon/icon.css';
import './assets/styles/form.css';

import {RouterProvider} from "react-router-dom";
import getRouterWithRoutes from "./app/router/routes";
import store from "./app/store/store";
import {Provider} from "react-redux";
import LoadingScreen from "./components/ui/loaders/LoadingScreen/LoadingScreen";
import setupAxiosInterceptors from "./app/axios/interceptors/axiosService";
import {registerLicense} from "@syncfusion/ej2-base";

import '@progress/kendo-theme-default/dist/all.css';

import '../node_modules/video-react/dist/video-react.css';
import './app/i18n/i18n';
import 'react-loading-skeleton/dist/skeleton.css'
import '../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
import YandexMetrics from "./components/shared/metrics/YandexMetrics";

setupAxiosInterceptors(store);
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENCE_KEY || '');

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Suspense fallback={<LoadingScreen/>}>
        <YandexMetrics/>
        <Provider store={store}>
            <RouterProvider router={getRouterWithRoutes()}/>
        </Provider>
    </Suspense>
);
