import React, {useState} from 'react';
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import cl from './DateTemplate.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import ChangePlannedDateDialog from "../../../actions/ChangePlannedDate/ChangePlannedDateDialog";
import Icon from "../../../../../../assets/icon/Icon";

const DateTemplate = (props: any) => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);

    let isReleaseEnd = props.dataItem.releaseEnd !== null && props.dataItem.releaseEnd !== undefined
        ? Boolean(props.dataItem.releaseEnd)
        : false;

    let val = props.dataItem[props.field] !== undefined && props.dataItem[props.field] !== null
        ? props.dataItem[props.field]
        : '';

    if (!user || getUserRole(user) === UserRole.ServiceProvider) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span
                    className={`default-left-cell default-overflow-cell ${props.field === 'pickupDate' && isReleaseEnd ? cl.warn : ''}`}>
                    {val}
                </span>
            </td>
        );
    }

    return (
        <td {...props.tdProps}
            colSpan={1}>
            <ChangePlannedDateDialog id={props.dataItem.id}
                                     show={isDialogShown}
                                     setShow={setIsDialogShown}/>
            <span onClick={handleClick} className={'default-left-cell default-overflow-cell grid-prevent-selection'}>
                <>

                <Icon icon={"faPen"} className={`${cl.icon} ${val && val !== '' ? cl.iconWithValue : ''} offer-grid-edit-${props.field}-btn grid-prevent-selection`}
                      onClick={(ev) => {
                          ev.stopPropagation();

                          setIsDialogShown(prev => !prev);
                      }}/>

                     <span
                         className={`grid-prevent-selection ${cl.text} ${props.field === 'pickupDate' && val && val !== '' && isReleaseEnd ? cl.warn : ''}`}
                         onClick={(ev) => {
                             ev.stopPropagation();

                             setIsDialogShown(prev => !prev);
                         }}>
                            {val && val !== ''
                                ? <>{val}</>
                                : <span className={cl.addText}>{t("offer.grid.change-planned-date")}</span>
                            }
                     </span>
                </>
            </span>
        </td>
    );
};

export default DateTemplate;
