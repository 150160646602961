import React from 'react';
import Dialog from "../../../../../ui/Dialog/Dialog";
import SupplierDataForm from "../SupplierDataForm/SupplierDataForm";

type SupplierDataDialogProps = {
    ids: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const SupplierDataDialog: React.FC<SupplierDataDialogProps> = ({
                                                                   ids,
                                                                   show,
                                                                   setShow
                                                               }) => {
    return (
        <Dialog id={'suppliers-data-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={' '}>
            <SupplierDataForm ids={ids} close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default SupplierDataDialog;