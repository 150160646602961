import {
    useAddBorderCrossing,
    useAddEvent,
    useAddGpsEvent,
    useAssignShipment,
    useCancelShipment,
    useChangeCustomsETA,
    useChangeDate,
    useChangeNote,
    useChangeRefNumber,
    useConfirmShipment,
    useDeleteShipment,
    useEditTransportData,
    useGetAddEventData,
    useGetAssignShipmentData,
    useGetBorderCrossingData,
    useGetCancelShipmentData,
    useGetChangeDateData,
    useGetChangeNoteData,
    useGetChangeRefNumberData,
    useGetConfirmShipmentData,
    useGetCopyOrData,
    useGetTimeSlotData,
    useGetSPUnblockLink,
    useGetCustomsETAData,
    useGetEditTransportData,
    useGetMapPositioningData,
    useGetRefinePositionData,
    useGetRoute,
    useGetRoutePointLocalTime,
    useGetShipment,
    useGetShipmentWithEvents,
    useGetTrackPoints,
    useGetTsHistory,
    useGetUnConfirmShipmentData,
    useMarkShipmentsAsUnViewed,
    useMarkShipmentsAsViewed,
    useRefinePosition,
    useUnConfirmShipment,
    useGetShipmentCostLink,
    useGetChangeDateDataAnonymous,
    useChangeDateAnonymous,
    useGetAddEventDataAnonymous,
    useAddEventAnonymous,
    useApproveShipment
} from "./requests";

export const useShipmentApi = () => {

    const {
        isLoading: approveShipmentLoading,
        cancel: cancelApproveShipment,
        approveShipment
    } = useApproveShipment();

    const {
        isLoading: markShipmentsAsViewedLoading,
        cancel: cancelMarkShipmentsAsViewed,
        markShipmentsAsViewed
    } = useMarkShipmentsAsViewed();

    const {
        isLoading: markShipmentsAsUnViewedLoading,
        cancel: cancelMarkShipmentsAsUnViewed,
        markShipmentsAsUnViewed
    } = useMarkShipmentsAsUnViewed();

    const {
        isLoading: getShipmentIsLoading,
        cancel: cancelGetShipment,
        getShipment
    } = useGetShipment();

    const {
        isLoading: getShipmentWithEventsIsLoading,
        cancel: cancelGetShipmentWithEvents,
        getShipmentWithEvents
    } = useGetShipmentWithEvents();

    const {
        isLoading: getTsHistoryLoading,
        cancel: cancelGetTsHistory,
        getTsHistory
    } = useGetTsHistory();

    const {
        isLoading: getAddEventDataIsLoading,
        cancel: cancelGetAddEventData,
        getAddEventData
    } = useGetAddEventData();

    const {
        isLoading: getAssignShipmentDataIsLoading,
        cancel: cancelGetAssignShipmentEventData,
        getAssignShipmentData
    } = useGetAssignShipmentData();

    const {
        isLoading: assignShipmentIsLoading,
        cancel: cancelAssignShipment,
        assignShipment
    } = useAssignShipment();

    const {
        isLoading: addEventIsLoading,
        cancel: cancelAddEvent,
        addEvent
    } = useAddEvent();

    const {
        isLoading: getCopyOrDataIsLoading,
        cancel: cancelGetCopyOrData,
        getCopyOrData
    } = useGetCopyOrData();

    const {
        isLoading: getTimeSlotDataIsLoading,
        cancel: cancelGetTimeSlotData,
        getTimeSlotData
    } = useGetTimeSlotData();

    const {
        isLoading: getSPUnblockLinkIsLoading,
        cancel: cancelGetSPUnblockLink,
        getSPUnblockLink
    } = useGetSPUnblockLink();

    const {
        isLoading: getChangeDateDataIsLoading,
        cancel: cancelGetChangeDateData,
        getChangeDateData
    } = useGetChangeDateData();

    const {
        isLoading: changeDateIsLoading,
        cancel: cancelChangeDate,
        changeDate
    } = useChangeDate();

    const {
        isLoading: getBorderCrossingDataIsLoading,
        cancel: cancelGetBorderCrossingData,
        getBorderCrossingData
    } = useGetBorderCrossingData();

    const {
        isLoading: addBorderCrossingIsLoading,
        cancel: cancelAddBorderCrossing,
        addBorderCrossing
    } = useAddBorderCrossing();

    const {
        isLoading: getCustomsETADataIsLoading,
        cancel: cancelGetCustomsETAData,
        getCustomsETAData
    } = useGetCustomsETAData();

    const {
        isLoading: changeCustomsETAIsLoading,
        cancel: cancelChangeCustomsETA,
        changeCustomsETA
    } = useChangeCustomsETA();

    const {
        isLoading: getChangeNoteDataIsLoading,
        cancel: cancelGetChangeNoteData,
        getChangeNoteData
    } = useGetChangeNoteData();

    const {
        isLoading: changeNoteIsLoading,
        cancel: cancelChangeNote,
        changeNote
    } = useChangeNote();

    const {
        isLoading: deleteShipmentIsLoading,
        cancel: cancelDeleteShipment,
        deleteShipment
    } = useDeleteShipment();

    const {
        isLoading: changeRefNumberIsLoading,
        cancel: cancelChangeRefNumber,
        changeRefNumber
    } = useChangeRefNumber();

    const {
        isLoading: getChangeRefNumberDataIsLoading,
        cancel: cancelGetChangeRefNumberData,
        getChangeRefNumberData
    } = useGetChangeRefNumberData();

    const {
        isLoading: getCancelShipmentDataIsLoading,
        cancel: cancelGetCancelShipmentData,
        getCancelShipmentData
    } = useGetCancelShipmentData();

    const {
        isLoading: cancelShipmentIsLoading,
        cancel: cancelCancelShipment,
        cancelShipment
    } = useCancelShipment();

    const {
        isLoading: confirmShipmentIsLoading,
        cancel: cancelConfirmShipment,
        confirmShipment
    } = useConfirmShipment();

    const {
        isLoading: getConfirmShipmentDataIsLoading,
        cancel: cancelGetConfirmShipmentData,
        getConfirmShipmentData
    } = useGetConfirmShipmentData();

    const {
        isLoading: unConfirmShipmentIsLoading,
        cancel: cancelUnConfirmShipment,
        unConfirmShipment
    } = useUnConfirmShipment();

    const {
        isLoading: getUnConfirmShipmentDataIsLoading,
        cancel: cancelGetUnConfirmShipmentData,
        getUnConfirmShipmentData
    } = useGetUnConfirmShipmentData();

    const {
        isLoading: editTransportDataIsLoading,
        cancel: cancelEditTransportData,
        editTransportData
    } = useEditTransportData();

    const {
        isLoading: getEditTransportDataIsLoading,
        cancel: cancelGetEditTransportData,
        getEditTransportData
    } = useGetEditTransportData();

    const {
        isLoading: refinePositionIsLoading,
        cancel: cancelRefinePosition,
        refinePosition
    } = useRefinePosition();

    const {
        isLoading: getRefinePositionDataIsLoading,
        cancel: cancelGetRefinePositionData,
        getRefinePositionData
    } = useGetRefinePositionData();

    const {
        isLoading: getMapPositioningDataIsLoading,
        cancel: cancelGetMapPositioningData,
        getMapPositioningData
    } = useGetMapPositioningData();

    const {
        isLoading: addGpsEventIsLoading,
        cancel: cancelAddGpsEvent,
        addGpsEvent
    } = useAddGpsEvent();

    const {
        isLoading: getRouteIsLoading,
        cancel: cancelGetRoute,
        getRoute
    } = useGetRoute();

    const {
        isLoading: getTrackPointsIsLoading,
        cancel: cancelGetTrackPoints,
        getTrackPoints
    } = useGetTrackPoints();

    const {
        isLoading: getRoutePointLocalTimeIsLoading,
        cancel: cancelGetRoutePointLocalTime,
        getRoutePointLocalTime
    } = useGetRoutePointLocalTime();

    const {
        isLoading: getShipmentCostLinkIsLoading,
        cancel: cancelGetShipmentCostLink,
        getShipmentCostLink
    } = useGetShipmentCostLink();

    const {
        isLoading: getChangeDateDataAnonymousIsLoading,
        cancel: cancelGetChangeDateDataAnonymous,
        getChangeDateDataAnonymous
    } = useGetChangeDateDataAnonymous();

    const {
        isLoading: changeDateAnonymousIsLoading,
        cancel: cancelChangeDateAnonymous,
        changeDateAnonymous
    } = useChangeDateAnonymous();

    const {
        isLoading: getAddEventDataAnonymousIsLoading,
        cancel: cancelGetAddEventDataAnonymous,
        getAddEventDataAnonymous
    } = useGetAddEventDataAnonymous();

    const {
        isLoading: addEventAnonymousIsLoading,
        cancel: cancelAddEventAnonymous,
        addEventAnonymous
    } = useAddEventAnonymous();

    return {
        approveShipment: {
            query: approveShipment,
            isLoading: approveShipmentLoading,
            cancel: cancelApproveShipment
        },
        getShipmentCostLink: {
            query: getShipmentCostLink,
            isLoading: getShipmentCostLinkIsLoading,
            cancel: cancelGetShipmentCostLink
        },
        getAddEventData: {
            query: getAddEventData,
            isLoading: getAddEventDataIsLoading,
            cancel: cancelGetAddEventData
        },
        maskShipmentsAsUnViewed: {
            mutation: markShipmentsAsUnViewed,
            isLoading: markShipmentsAsUnViewedLoading,
            cancel: cancelMarkShipmentsAsUnViewed
        },
        markShipmentsAsViewed: {
            mutation: markShipmentsAsViewed,
            isLoading: markShipmentsAsViewedLoading,
            cancel: cancelMarkShipmentsAsViewed
        },
        getShipment: {
            query: getShipment,
            isLoading: getShipmentIsLoading,
            cancel: cancelGetShipment
        },
        getShipmentWithEvents: {
            query: getShipmentWithEvents,
            isLoading: getShipmentWithEventsIsLoading,
            cancel: cancelGetShipmentWithEvents
        },
        getTsHistory: {
            query: getTsHistory,
            isLoading: getTsHistoryLoading,
            cancel: cancelGetTsHistory
        },
        getAssignShipmentData: {
            query: getAssignShipmentData,
            isLoading: getAssignShipmentDataIsLoading,
            cancel: cancelGetAssignShipmentEventData
        },
        assignShipment: {
            mutation: assignShipment,
            isLoading: assignShipmentIsLoading,
            cancel: cancelAssignShipment
        },
        addEvent: {
            mutation: addEvent,
            isLoading: addEventIsLoading,
            cancel: cancelAddEvent
        },
        getCopyOrData: {
            query: getCopyOrData,
            isLoading: getCopyOrDataIsLoading,
            cancel: cancelGetCopyOrData
        },
        getTimeSlotData: {
            query: getTimeSlotData,
            isLoading: getTimeSlotDataIsLoading,
            cancel: cancelGetTimeSlotData
        },
        getSPUnblockLink: {
            query: getSPUnblockLink,
            isLoading: getSPUnblockLinkIsLoading,
            cancel: cancelGetSPUnblockLink
        },
        getChangeDateData: {
            query: getChangeDateData,
            isLoading: getChangeDateDataIsLoading,
            cancel: cancelGetChangeDateData
        },
        changeDate: {
            mutation: changeDate,
            isLoading: changeDateIsLoading,
            cancel: cancelChangeDate
        },
        getBorderCrossingData: {
            query: getBorderCrossingData,
            isLoading: getBorderCrossingDataIsLoading,
            cancel: cancelGetBorderCrossingData
        },
        addBorderCrossing: {
            mutation: addBorderCrossing,
            isLoading: addBorderCrossingIsLoading,
            cancel: cancelAddBorderCrossing
        },
        getCustomsETAData: {
            query: getCustomsETAData,
            isLoading: getCustomsETADataIsLoading,
            cancel: cancelGetCustomsETAData
        },
        changeCustomsETA: {
            mutation: changeCustomsETA,
            isLoading: changeCustomsETAIsLoading,
            cancel: cancelChangeCustomsETA
        },
        getChangeNoteData: {
            query: getChangeNoteData,
            isLoading: getChangeNoteDataIsLoading,
            cancel: cancelGetChangeNoteData
        },
        changeNote: {
            mutation: changeNote,
            isLoading: changeNoteIsLoading,
            cancel: cancelChangeNote
        },
        deleteShipment: {
            mutation: deleteShipment,
            isLoading: deleteShipmentIsLoading,
            cancel: cancelDeleteShipment
        },
        changeRefNumber: {
            mutation: changeRefNumber,
            isLoading: changeRefNumberIsLoading,
            cancel: cancelChangeRefNumber
        },
        getChangeRefNumberData: {
            query: getChangeRefNumberData,
            isLoading: getChangeRefNumberDataIsLoading,
            cancel: cancelGetChangeRefNumberData
        },
        cancelShipment: {
            mutation: cancelShipment,
            isLoading: cancelShipmentIsLoading,
            cancel: cancelCancelShipment
        },
        getCancelShipmentData: {
            query: getCancelShipmentData,
            isLoading: getCancelShipmentDataIsLoading,
            cancel: cancelGetCancelShipmentData
        },
        confirmShipment: {
            mutation: confirmShipment,
            isLoading: confirmShipmentIsLoading,
            cancel: cancelConfirmShipment
        },
        getConfirmShipmentData: {
            query: getConfirmShipmentData,
            isLoading: getConfirmShipmentDataIsLoading,
            cancel: cancelGetConfirmShipmentData
        },
        unConfirmShipment: {
            mutation: unConfirmShipment,
            isLoading: unConfirmShipmentIsLoading,
            cancel: cancelUnConfirmShipment
        },
        getUnConfirmShipmentData: {
            query: getUnConfirmShipmentData,
            isLoading: getUnConfirmShipmentDataIsLoading,
            cancel: cancelGetUnConfirmShipmentData
        },
        editTransportData: {
            mutation: editTransportData,
            isLoading: editTransportDataIsLoading,
            cancel: cancelEditTransportData
        },
        getEditTransportData: {
            query: getEditTransportData,
            isLoading: getEditTransportDataIsLoading,
            cancel: cancelGetEditTransportData
        },
        getRefinePositionData: {
            query: getRefinePositionData,
            isLoading: getRefinePositionDataIsLoading,
            cancel: cancelGetRefinePositionData
        },
        refinePosition: {
            mutation: refinePosition,
            isLoading: refinePositionIsLoading,
            cancel: cancelRefinePosition
        },
        getMapPositioningData: {
            query: getMapPositioningData,
            isLoading: getMapPositioningDataIsLoading,
            cancel: cancelGetMapPositioningData
        },
        addGpsEvent: {
            mutation: addGpsEvent,
            isLoading: addGpsEventIsLoading,
            cancel: cancelAddGpsEvent
        },
        getRoute: {
            query: getRoute,
            isLoading: getRouteIsLoading,
            cancel: cancelGetRoute
        },
        getTrackPoints: {
            query: getTrackPoints,
            isLoading: getTrackPointsIsLoading,
            cancel: cancelGetTrackPoints
        },
        getRoutePointLocalTime: {
            query: getRoutePointLocalTime,
            isLoading: getRoutePointLocalTimeIsLoading,
            cancel: cancelGetRoutePointLocalTime
        },
        changeDateAnonymous: {
            mutation: changeDateAnonymous,
            isLoading: changeDateAnonymousIsLoading,
            cancel: cancelChangeDateAnonymous
        },
        getChangeDateDataAnonymous: {
            query: getChangeDateDataAnonymous,
            isLoading: getChangeDateDataAnonymousIsLoading,
            cancel: cancelGetChangeDateDataAnonymous
        },
        getAddEventDataAnonymous: {
            query: getAddEventDataAnonymous,
            isLoading: getAddEventDataAnonymousIsLoading,
            cancel: cancelGetAddEventDataAnonymous
        },
        addEventAnonymous: {
            mutation: addEventAnonymous,
            isLoading: addEventAnonymousIsLoading,
            cancel: cancelAddEventAnonymous
        }
    };
};
