import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useOfferApi} from "../../../../../app/api/offer";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {IRemarkGetAction} from "../../../../../app/interfaces/offer/IRemarkGetAction";
import SelectedOffers from "../../form/components/SelectedOffers/SelectedOffers";

type DeleteRemarkProps = {
    id: string;
    code: string;
    seq: string;
    close?: () => void;
};

const DeleteRemark: React.FC<DeleteRemarkProps> = ({
                                                       id,
                                                       code,
                                                       seq,
                                                       close
                                                   }) => {
    const {t} = useTranslation();
    const [model, setModel] = useState<IRemarkGetAction | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        getEditRemarkData: {
            isLoading,
            cancel,
            query: getEditRemarkData
        },
        deleteRemark: {
            cancel: cancelDeleteRemark,
            mutation: deleteRemark
        }
    } = useOfferApi();

    useEffect(() => {
        if (!id || !code) {
            if (close) {
                close();
            }

            return;
        }

        (async () => {
            try {
                const response = await getEditRemarkData(id, code, seq);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error<string>('Unable to get remark data');

                    if (close) {
                        close();
                    }
                }
            }
        })();

        return () => {
            cancel();
            cancelDeleteRemark();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const save = async (): Promise<void> => {
        if (!model) {
            return;
        }

        setDisabled(prev => !prev);

        try {
            const response = await deleteRemark({
                mainGid: model.mainGid,
                orderReleaseGid: model.orderReleaseGid,
                code: model.code,
                seq: model.seq
            });

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRow',
                        rows: [
                            {
                                id: id,
                                isRowLoading: true
                            }
                        ]
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.offer")} ${model.orderReleaseGid !== '' ? model.orderReleaseGid.split(';')[0] : ''}`}/>);
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(e.message);
            }
        }

        setDisabled(prev => !prev);
    }

    if (id === '' || code === '') {
        return null;
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                {model.items.length > 1 && <SelectedOffers ids={model.items.map(item => item.id)}/>}

                <div className={'pd-10'}
                     style={{
                         fontSize: '13px',
                         fontWeight: 'bold',
                         color: 'red',
                         textAlign: 'center'
                     }}>
                    {t("offer.warnings.delete-remark-confirmation").replace('{0}', model.code ?? '')}
                </div>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}
                        onClick={save}>
                    {t("shared.btn.confirm")}
                </Button>
            </div>
        </div>
    );
};

export default DeleteRemark;