import moment from "moment";

export const getCookie = (key: string) => {
    let b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");

    return b ? b.pop() : null;
};

export const setCookie = (name: string, value: string, expiredInDays: number) => {
    const expires = "expires=" + moment().add(expiredInDays, 'days').toDate().toUTCString();

    document.cookie = `${name}=${value};${expires};path=/`;
};