import React, {ReactNode} from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {useAppSelector} from "../../store";
import {selectUserAuthenticationStatus} from "../../../features/authentication/authenticationSliceSelectors";
import {AuthenticationStatus} from "../../../features/authentication/IAuthenticationState";

type UserAuthenticatedProtectedRouteProps = {
    redirect?: string;
    children: ReactNode
}

const UserAuthenticatedProtectedRoute: React.FC<UserAuthenticatedProtectedRouteProps> = ({
                                                                                             redirect = '/login',
                                                                                             children
                                                                                         }) => {
    const location = useLocation();
    const userAuthenticationStatus = useAppSelector(selectUserAuthenticationStatus);

    if (userAuthenticationStatus === AuthenticationStatus.NotAuthenticated ||
        userAuthenticationStatus === AuthenticationStatus.NoAction) {
        return <Navigate to={redirect} replace state={{from: location}}/>
    }

    return <>{children}</>;
};

export default UserAuthenticatedProtectedRoute;