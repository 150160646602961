import React from 'react';
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../features/authentication/authenticationSliceSelectors";
import {
    useGridFilterPanelLayoutContext
} from "../../../../../../../components/uiLayouts/GridFilterPanelLayout/hooks/useGridFilterPanelLayoutContext";
import {getUserRole} from "../../../../../../../helpers/user";
import GridFilterPanelLayoutInput
    from "../../../../../../../components/uiLayouts/GridFilterPanelLayout/components/GridFilterPanelLayoutInput/GridFilterPanelLayoutInput";
import FilterPanelPeriodFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelPeriodFilter/FilterPanelPeriodFilter";
import FilterPanelRegionFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelRegionFilter/FilterPanelRegionFilter";
import FilterPanelSupplierFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelSupplierFilter/FilterPanelSupplierFilter";
import FilterPanelServiceProviderFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelServiceProviderFilter/FilterPanelServiceProviderFilter";
import FilterPanelEquipmentFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelEquipmentFilter/FilterPanelEquipmentFilter";
import FilterPanelOfferTypeFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelOfferTypeFilter/FilterPanelOfferTypeFilter";
import {UserRole} from "../../../../../../../app/enums/UserRole";
import FilterPanelTagsFilter
    from "../../../../../../../components/shared/components/FilterPanel/FilterPanelTagsFilter/FilterPanelTagsFilter";

const OfferFilterPanelFiltersSection = () => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    const {
        disabled,
        filterToApply,
        applyFilterToApply,
        onKeyDown
    } = useGridFilterPanelLayoutContext();

    if (!user) {
        return null;
    }

    let role = getUserRole(user);

    return (
        <div>
            {role === UserRole.ServiceProvider &&
                <div className={'view-row mgb-10'}>
                    <GridFilterPanelLayoutInput disabled={disabled}
                                                showClearIcon={true}
                                                value={filterToApply.orderReleaseNumber ?? ''}
                                                placeholder={t("offer.grid.number")}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                    applyFilterToApply(
                                                        'orderReleaseNumber',
                                                        ev.target.value === ''
                                                            ? null
                                                            : ev.target.value
                                                    );
                                                }}
                                                onClear={() => {
                                                    applyFilterToApply(
                                                        'orderReleaseNumber',
                                                        null
                                                    );
                                                }}
                                                onKeyDown={(ev) => onKeyDown(ev, 'orderReleaseNumber')}
                    />
                </div>
            }

            {role !== UserRole.ServiceProvider &&
                <div className={'flex-two-columns flex-gap-10 view-row mgb-10'}>
                    <div>
                        <GridFilterPanelLayoutInput disabled={disabled}
                                                    showClearIcon={true}
                                                    value={filterToApply.orderReleaseNumber ?? ''}
                                                    placeholder={t("offer.grid.number")}
                                                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                        applyFilterToApply(
                                                            'orderReleaseNumber',
                                                            ev.target.value === ''
                                                                ? null
                                                                : ev.target.value
                                                        );
                                                    }}
                                                    onClear={() => {
                                                        applyFilterToApply(
                                                            'orderReleaseNumber',
                                                            null
                                                        );
                                                    }}
                                                    onKeyDown={(ev) => onKeyDown(ev, 'orderReleaseNumber')}
                        />
                    </div>
                    <div>
                        <GridFilterPanelLayoutInput disabled={disabled}
                                                    showClearIcon={true}
                                                    value={filterToApply.customerRefNumber ?? ''}
                                                    placeholder={t("shipment.grid.load-customer-ref-num")}
                                                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                        applyFilterToApply(
                                                            'customerRefNumber',
                                                            ev.target.value === ''
                                                                ? null
                                                                : ev.target.value
                                                        );
                                                    }}
                                                    onClear={() => {
                                                        applyFilterToApply(
                                                            'customerRefNumber',
                                                            null
                                                        );
                                                    }}
                                                    onKeyDown={(ev) => onKeyDown(ev, 'customerRefNumber')}
                        />
                    </div>
                </div>


            }

            <div className={'view-row mgb-10'}>

            </div>

            {role !== UserRole.ServiceProvider &&
                <div className={'view-row mgb-10'}>

                </div>
            }

            <div className={'view-row mgb-10'}>
                <FilterPanelPeriodFilter id={'offer-pickup-date-filter'}
                                         disabled={disabled}
                                         title={t("offer.grid.pickup-date")}
                                         value={{
                                             from: filterToApply.pickupDateFrom,
                                             to: filterToApply.pickupDateTo
                                         }}
                                         onChange={(type, value) => {
                                             applyFilterToApply(type === 'from'
                                                 ? 'pickupDateFrom'
                                                 : 'pickupDateTo', value);
                                         }}
                                         onClear={() => {
                                             applyFilterToApply(
                                                 ['pickupDateFrom', 'pickupDateTo'],
                                                 null
                                             );
                                         }}/>
            </div>

            

            <div className={'view-row mgb-10'}>
                <FilterPanelPeriodFilter id={'offer-delivery-date-filter'}
                                         title={t("offer.grid.delivery-date")}
                                         value={{
                                             from: filterToApply.deliveryDateFrom,
                                             to: filterToApply.deliveryDateTo
                                         }}
                                         onChange={(type, value) => {
                                             applyFilterToApply(type === 'from'
                                                 ? 'deliveryDateFrom'
                                                 : 'deliveryDateTo', value);
                                         }}
                                         onClear={() => {
                                             applyFilterToApply(
                                                 ['deliveryDateFrom', 'deliveryDateTo'],
                                                 null
                                             );
                                         }}/>
            </div>

            {role !== UserRole.ServiceProvider &&
                <div className={'flex-two-columns flex-gap-10 view-row mgb-10'}>
                    <div>
                    <GridFilterPanelLayoutInput disabled={disabled}
                                                            showClearIcon={true}
                                                            value={filterToApply.jobOrderRelease ?? ''}
                                                            placeholder={t("offer.filter.job-order-release")}
                                                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                                applyFilterToApply(
                                                                    'jobOrderRelease',
                                                                    ev.target.value === ''
                                                                        ? null
                                                                        : ev.target.value
                                                                );
                                                            }}
                                                            onClear={() => {
                                                                applyFilterToApply(
                                                                    'jobOrderRelease',
                                                                    null
                                                                );
                                                            }}
                                                            //onKeyDown={(ev) => onKeyDown(ev, 'jobOrderRelease')}
                                />
                    </div>
                    <div></div>
                </div>
            }   

            <div className={'view-row mgb-10'}>
                <FilterPanelRegionFilter id={'offer-source-region-filter'}
                                         title={t("shared.labels.source-region-point")}
                                         onClear={(val) => {
                                             applyFilterToApply('source', !val
                                                 ? null
                                                 : filterToApply.destination.filter(e => e.value !== val));
                                         }}
                                         onChange={val => {
                                             applyFilterToApply('source', val);
                                         }}
                                         value={filterToApply.source}/>
            </div>

            <div className={'view-row mgb-10'}>
                <FilterPanelRegionFilter id={'offer-destination-region-filter'}
                                         title={t("shared.labels.destination-region-point")}
                                         onClear={(val) => {
                                             applyFilterToApply('destination', !val
                                                 ? null
                                                 : filterToApply.destination.filter(e => e.value !== val));
                                         }}
                                         onChange={val => {
                                             applyFilterToApply('destination', val);
                                         }}
                                         value={filterToApply.destination}/>
            </div>

            <div className={'view-row mgb-10'}>
                <FilterPanelEquipmentFilter id={'offer-equipment-filter'}
                                            onClear={(val) => {
                                                applyFilterToApply('equipment', !val
                                                    ? null
                                                    : filterToApply.equipment.filter(e => e.value !== val));
                                            }}
                                            onChange={val => {
                                                applyFilterToApply('equipment', val);
                                            }}
                                            value={filterToApply.equipment}/>
            </div>

            {role !== UserRole.ServiceProvider &&
                <div className={'view-row mgb-10'}>
                    <FilterPanelSupplierFilter id={'offer-supplier-filter'}
                                               onClear={(val) => {
                                                   applyFilterToApply('supplier', !val
                                                       ? null
                                                       : filterToApply.supplier.filter(e => e.value !== val));
                                               }}
                                               onChange={val => {
                                                   applyFilterToApply('supplier', val);
                                               }}
                                               value={filterToApply.supplier}/>
                </div>
            }

            {role !== UserRole.ServiceProvider &&
                <div className={'view-row mgb-10'}>
                    <FilterPanelServiceProviderFilter id={'offer-service-provider-filter'}
                                                      onClear={(val) => {
                                                          applyFilterToApply('serviceProvider', !val
                                                              ? null
                                                              : filterToApply.serviceProvider.filter(e => e.value !== val));
                                                      }}
                                                      onChange={val => {
                                                          applyFilterToApply('serviceProvider', val);
                                                      }}
                                                      value={filterToApply.serviceProvider}
                                                      isNotInBlackList={true}
                                                      isNotInHideStatus={true}/>
                </div>
            }

            {role !== UserRole.ServiceProvider &&
                <div className={'view-row mgb-10'}>
                    <FilterPanelOfferTypeFilter value={filterToApply.status}
                                                onClear={(value) => {
                                                    applyFilterToApply('status', !value
                                                        ? null
                                                        : filterToApply.status.filter(item => item !== value));
                                                }}
                                                onChange={(values) => {
                                                    applyFilterToApply('status', values);
                                                }}/>
                </div>
            }

            {role !== UserRole.ServiceProvider &&
                <div className={`view-row mgb-10`}>
                    <FilterPanelTagsFilter id={'offer-tags-filter'}
                                           type={'release'}
                                           onClear={(val) => {
                                               applyFilterToApply('tags', !val
                                                   ? null
                                                   : filterToApply.tags.filter(e => e.value !== val));
                                           }}
                                           onChange={(val) => {
                                               applyFilterToApply('tags', val);
                                           }}
                                           value={filterToApply.tags} />
                </div>
            }

            
        </div>

        
    );
};

export default OfferFilterPanelFiltersSection;