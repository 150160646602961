import {
    useAssociateTag,
    useDeleteTag, useGetAssociateTagData, useGetAvailableTags,
    useGetCurrentUserTags,
    useGetFilterTags,
    useGetRemoveTagFromEntityData, useGetTag, useRemoveTagFromEntity,
    useSaveTag
} from "./requests";

export const useTagApi = () => {
    const {
        isLoading: getFilterTagsIsLoading,
        cancel: cancelGetFilterTags,
        getFilterTags
    } = useGetFilterTags();

    const {
        isLoading: getCurrentUsersTagsIsLoading,
        cancel: cancelGetCurrentUsersTags,
        getCurrentUsersTags
    } = useGetCurrentUserTags();

    const {
        isLoading: saveTagIsLoading,
        cancel: cancelSaveTag,
        saveTag
    } = useSaveTag();

    const {
        isLoading: deleteTagIsLoading,
        cancel: cancelDeleteTag,
        deleteTag
    } = useDeleteTag();

    const {
        isLoading: getRemoveTagFromEntityDataIsLoading,
        cancel: cancelGetRemoveTagFromEntityData,
        getRemoveTagFromEntityData
    } = useGetRemoveTagFromEntityData();

    const {
        isLoading: removeTagFromEntityIsLoading,
        cancel: cancelRemoveTagFromEntity,
        removeTagFromEntity
    } = useRemoveTagFromEntity();

    const {
        isLoading: getAvailableTagsIsLoading,
        cancel: cancelGetAvailableTags,
        getAvailableTags
    } = useGetAvailableTags();

    const {
        isLoading: getAssociateTagDataIsLoading,
        cancel: cancelGetAssociateTagData,
        getAssociateTagData
    } = useGetAssociateTagData();

    const {
        isLoading: associateTagIsLoading,
        cancel: cancelAssociateTag,
        associateTag
    } = useAssociateTag();

    const {
        isLoading: getTagIsLoading,
        cancel: cancelGetTag,
        getTag
    } = useGetTag();

    return {
        getFilterTags: {
            query: getFilterTags,
            isLoading: getFilterTagsIsLoading,
            cancel: cancelGetFilterTags
        },
        getCurrentUserTags: {
            query: getCurrentUsersTags,
            isLoading: getCurrentUsersTagsIsLoading,
            cancel: cancelGetCurrentUsersTags
        },
        saveTag: {
            mutation: saveTag,
            isLoading: saveTagIsLoading,
            cancel: cancelSaveTag
        },
        deleteTag: {
            mutation: deleteTag,
            isLoading: deleteTagIsLoading,
            cancel: cancelDeleteTag
        },
        getRemoveTagFromEntityData: {
            query: getRemoveTagFromEntityData,
            isLoading: getRemoveTagFromEntityDataIsLoading,
            cancel: cancelGetRemoveTagFromEntityData
        },
        removeTagFromEntity: {
            mutation: removeTagFromEntity,
            isLoading: removeTagFromEntityIsLoading,
            cancel: cancelRemoveTagFromEntity
        },
        getAvailableTags: {
            query: getAvailableTags,
            isLoading: getAvailableTagsIsLoading,
            cancel: cancelGetAvailableTags
        },
        getAssociateTagData: {
            query: getAssociateTagData,
            isLoading: getAssociateTagDataIsLoading,
            cancel: cancelGetAssociateTagData
        },
        associateTag: {
            mutation: associateTag,
            isLoading: associateTagIsLoading,
            cancel: cancelAssociateTag
        },
        getTag: {
            query: getTag,
            isLoading: getTagIsLoading,
            cancel: cancelGetTag
        }
    };
}