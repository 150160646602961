import React, {useState} from 'react';
import cl from './DateTemplate.module.css';
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import ChangePlannedDateDialog from "../../../actions/ChangePlannedDate/ChangePlannedDateDialog";
import Icon from "../../../../../../assets/icon/Icon";

const DateTemplate = (props: any) => {
    const user = useAppSelector(selectCurrentUser);
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);

    let sId = props.dataItem.sId !== undefined && props.dataItem.sId !== null
        ? props.dataItem.sId
        : '';
    let rId = props.dataItem.rId !== undefined && props.dataItem.rId !== null
        ? props.dataItem.rId
        : '';
    let val = props.dataItem[props.field] !== undefined && props.dataItem[props.field] !== null
        ? props.dataItem[props.field]
        : '';
    let isFinished = props.dataItem.isFinished !== null && props.dataItem.isFinished !== undefined
        ? Boolean(props.dataItem.isFinished)
        : false;
    let isArchive = props.dataItem.isArchive !== null && props.dataItem.isArchive !== undefined
        ? Boolean(props.dataItem.isArchive)
        : false;
    if (!sId || sId === '' || isFinished || isArchive || !user ||
        (getUserRole(user) === UserRole.Client) ||
        (getUserRole(user) === UserRole.ServiceProvider)) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={'default-left-cell default-overflow-cell'}>
                    {val}
                </span>
            </td>
        )
    }

    return (
        <td {...props.tdProps}
            colSpan={1}>
            <ChangePlannedDateDialog id={rId}
                                     user={user}
                                     sId={sId}
                                     show={isDialogShown}
                                     setShow={setIsDialogShown}/>
            <span onClick={handleClick} className={'default-left-cell default-overflow-cell grid-prevent-selection'}>
                <>

                <Icon icon={"faPen"}
                      className={`${cl.icon} ${val && val !== '' ? cl.iconWithValue : ''} grid-prevent-selection shipment-grid-edit-date-btn`}
                      onClick={(ev) => {
                          ev.stopPropagation();

                          setIsDialogShown(prev => !prev);
                      }}/>
                    {val && val !== ''
                        ? <span className={`grid-prevent-selection ${cl.text}`}
                                onClick={(ev) => {
                                    ev.stopPropagation();

                                    setIsDialogShown(prev => !prev);
                                }}>
                            {val}
                        </span>
                        : null
                    }
                </>
            </span>
        </td>
    );
};

export default DateTemplate;
