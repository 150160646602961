import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'locationDot',
    pathData: [
        'M4.8 6.6C5.79411 6.6 6.6 5.79411 6.6 4.8C6.6 3.80589 5.79411 3 4.8 3C3.80589 3 3 3.80589 3 4.8C3 5.79411 3.80589 6.6 4.8 6.6Z',
        'M4.8 12C7.2 9.6 9.6 7.45097 9.6 4.8C9.6 2.14903 7.45097 0 4.8 0C2.14903 0 0 2.14903 0 4.8C0 7.45097 2.4 9.6 4.8 12Z'
    ],
    circle: [{
        cx: 4.7999,
        cy: 4.80002,
        r: 2.4,
        fill: 'white'
    }],
    viewBox: '0 0 10 12'
};

export default icon;