import {RootState} from "../../app/store/store";
import {createSelector} from "@reduxjs/toolkit";
import {FilterSliceEntity} from "./filterSlice";
import {isGridFilterHasAtLeastOneNotNullValue} from "../../app/types/GridFilter";

export const selectFilterPanelConfiguration = (state: RootState) => state.filter.configuration;


export const selectAccessSettings = (state: RootState) => state.filter.accessFilter.settings;

export const selectAccessSettingsForwarders = createSelector(
    (state: RootState) => state.filter.accessFilter.data,
    (filtersData) => {
        return filtersData.forwarders;
    }
);

export const selectAccessSettingsBranches = createSelector(
    (state: RootState) => state.filter.accessFilter.data,
    (filtersData) => {
        return filtersData.branches;
    }
);

export const selectAccessSettingsDepartments = createSelector(
    (state: RootState) => state.filter.accessFilter.data,
    (filtersData) => {
        return filtersData.departments;
    }
);

export const selectAccessSettingsTransportLinesSource = createSelector(
    (state: RootState) => state.filter.accessFilter.data,
    (filtersData) => {
        return filtersData.transportLinesSource;
    }
);

export const selectAccessSettingsTransportLinesDestination = createSelector(
    (state: RootState) => state.filter.accessFilter.data,
    (filtersData) => {
        return filtersData.transportLinesDestination;
    }
);

export const selectIsAccessSettingsExists = (state: RootState) => {
    if (state.filter.accessFilter.settings === null) {
        return false;
    }

    return (state.filter.accessFilter.settings.branches.length > 0 ||
        state.filter.accessFilter.settings.departments.length > 0 ||
        state.filter.accessFilter.settings.forwarders.length > 0 ||
        state.filter.accessFilter.settings.transportLinesDestination.length > 0 ||
        state.filter.accessFilter.settings.transportLinesSource.length > 0 ||
        state.filter.accessFilter.settings.serviceProviderId !== null);
}

export const selectPredefinedFilters = createSelector([
        (state: RootState) => state.filter.state,
        (_: RootState, entity: FilterSliceEntity) => entity
    ],
    (data, entity) => {
        return data[entity].predefinedFilters;
    }
);

export const selectPersonalFilters = createSelector([
        (state: RootState) => state.filter.state,
        (_: RootState, entity: FilterSliceEntity) => entity
    ],
    (data, entity) => {
        return data[entity].personalFilters;
    }
);

export const selectSelectedPredefinedFilter = createSelector([
        (state: RootState) => state.filter.state,
        (_: RootState, entity: FilterSliceEntity) => entity
    ],
    (data, entity) => {
        return data[entity].selectedPredefinedFilter;
    }
);

export const selectSelectedPersonalFilter = createSelector([
        (state: RootState) => state.filter.state,
        (_: RootState, entity: FilterSliceEntity) => entity
    ],
    (data, entity) => {
        return data[entity].selectedPersonalFilter;
    }
);

export const selectFilter = createSelector([
        (state: RootState) => state.filter.state,
        (_: RootState, entity: FilterSliceEntity) => entity
    ],
    (data, entity) => {


        return data[entity].filter;
    }
);

export const selectFilterToApply = createSelector([
        (state: RootState) => state.filter.state,
        (_: RootState, entity: FilterSliceEntity) => entity
    ],
    (data, entity) => {
        return data[entity].filterToApply;
    }
);

export const selectIsFilterExist = createSelector([
        (state: RootState) => state.filter.state,
        (_: RootState, entity: FilterSliceEntity) => entity
    ],
    (data, entity) => {
        return isGridFilterHasAtLeastOneNotNullValue(data[entity].filter);
    }
);


export const selectQuery = createSelector([
        (state: RootState) => state.filter.state,
        (_: RootState, entity: FilterSliceEntity) => entity
    ],
    (data, entity) => {
        return data[entity].query;
    }
);
