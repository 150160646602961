import React from 'react';
import {IOffer} from "../../../../../../../../app/interfaces/offer/IOffer";
import ForwarderField from "../../../../../../../../components/shared/form/detailsFields/ForwarderField/ForwarderField";

type OrderManagerProps = {
    offer: IOffer;
};

const OfferOrderManager: React.FC<OrderManagerProps> = ({
                                                       offer
                                                   }) => {
    if (offer.orderManager === null) {
        return null;
    }

    return (
        <div className={'details-box-item-field-value'}>
            <ForwarderField user={offer.orderManager}
                            chatReplyTo={{
                                type: 'RELEASE',
                                description: offer.chatDescription,
                                id: offer.orderReleaseXid,
                                idExt: null
                            }}/>
        </div>
    );
};

export default OfferOrderManager;