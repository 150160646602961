import React, {useCallback, useState} from 'react';
import {useTranslation} from "react-i18next";
import {matchPath, useLocation, useNavigate, useParams} from "react-router-dom";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {useGridLayoutContext} from "../../../../../../components/uiLayouts/GridLayout/hooks/useGridLayoutContext";
import useGridExport from "../../../../../../components/ui/Grid/Telerik/hooks/useGridExport";
import {
    GridActionRowButtonProps
} from "../../../../../../components/ui/Grid/Telerik/components/GridActionRow/components/GridActionRowButton/GridActionRowButton";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import moment from "moment/moment";
import HardGridActionRow, {
    GridActionRowSelectorProps
} from "../../../../../../components/ui/Grid/Telerik/components/HardGridActionRow/HardGridActionRow";
import GridColumnsConfigurationSetupDialog
    from "../../../../../../components/ui/Grid/Telerik/components/GridColumnsConfigurationSetupDialog/GridColumnsConfigurationSetupDialog";
import {LayoutConfigEntity} from "../../../../../../app/enums/LayoutConfigEntity";
import {IOfferHeaderConfig} from "../../data/interfaces/IOfferHeaderConfig";
import {buildOfferGridLabel} from "../../utils";
import OfferActionRowTags from "./OfferActionRowTags/OfferActionRowTags";
import getOfferInitialGridConfig from "../../data/columns";
import OfferActionRowEdiActionButton from "./OfferActionRowEdiActionButton/OfferActionRowEdiActionButton";
import OfferActionRowActionButton from "./OfferActionRowActionButton/OfferActionRowActionButton";

const OfferActionRow: React.FC = () => {
    const {t, i18n} = useTranslation();
    const {type} = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    const user = useAppSelector(selectCurrentUser);
    const [isSetupDialogVisible, setIsSetupDialogVisible] = useState<boolean>(false);

    const {
        groupBy,
        refresh,
        defaultGridConfig,
        gridConfig,
        headerConfig,
        isHeaderDisabled,
        saveHeaderConfig,
        reloadGridConfig,
    } = useGridLayoutContext<IOfferHeaderConfig>();

    const onChange = (config: IOfferHeaderConfig) => {
        (async () => {
            await saveHeaderConfig(config);
        })();
    }

    const {
        download
    } = useGridExport({
        gridId: 'offer-grid',
        config: gridConfig ?? defaultGridConfig,
        useSelectedRowToExport: true
    });

    const buildButtonList = useCallback((): Array<GridActionRowButtonProps> => {
        if (!headerConfig || !user) {
            return [];
        }

        let userRole = getUserRole(user);
        let res: Array<GridActionRowButtonProps> = [];

        if (userRole !== UserRole.ServiceProvider && userRole !== UserRole.Support) {
            res.push({
                id: 'offer-group-by-release-number',
                title: headerConfig.isGroupByReleaseNumber
                    ? t("shared.grid-header.btn-disable-group-by-release-number")
                    : t("shared.grid-header.btn-enable-group-by-release-number"),
                toggle: true,
                defaultValue: headerConfig.isGroupByReleaseNumber,
                disabled: isHeaderDisabled,
                icon: "faList",
                onClick: (val) => {
                    onChange({
                        ...headerConfig,
                        isGroupByReleaseNumber: val
                    });

                    groupBy(val ? 'mainOrderReleaseXid' : undefined);
                }
            });
        }
            res.push({
                id: 'offer-export',
                title: t("shared.grid-header.btn-export"),
                disabled: isHeaderDisabled,
                onClick: async () => {
                    await download(`Offer${moment().format('DD_MM_YYYY_HH_mm')}.xlsx`);
                },
                icon: "faUpload"
            });


        res.push({
            id: 'offer-table-setup',
            title: t("shared.grid-header.btn-table-setup"),
            disabled: isHeaderDisabled,
            onClick: () => setIsSetupDialogVisible(prev => !prev),
            icon: "faGear"
        });

        res.push({
            id: 'offer-refresh',
            title: t("shared.grid-header.btn-refresh"),
            disabled: isHeaderDisabled,
            onClick: () => refresh(),
            icon: "faArrowsRotate"
        });

        return res;
    }, [i18n.language, isHeaderDisabled, headerConfig, user]);

    const buildSelectorsList = useCallback((): Array<GridActionRowSelectorProps> => {
        if (!headerConfig || !user) {
            return [];
        }

        let userRole = getUserRole(user);
        let res: Array<GridActionRowSelectorProps> = [];

        if (userRole !== UserRole.Support && userRole !== UserRole.ServiceProvider) {
            res.push({
                selector: {
                    disabled: isHeaderDisabled,
                    width: 150,
                    onChange: (item) => {
                        onChange({
                            ...headerConfig,
                            subTab: item.id
                        });
                    }
                },
                options: [
                    {
                        id: 'offer-my-offers-tab',
                        text: t("offer.header.my-offers-tab"),
                        selected: headerConfig.subTab === 'offer-my-offers-tab',
                        tooltip: t("offer.header.my-offers-tab-tooltip")
                    },
                    {
                        id: 'offer-common-tab',
                        text: t("offer.header.common-tab"),
                        selected: headerConfig.subTab === 'offer-common-tab',
                        tooltip: t("offer.header.common-tab-tooltip")
                    },
                    {
                        id: 'offer-all-tab',
                        text: t("offer.header.all-tab"),
                        selected: headerConfig.subTab === 'offer-all-tab',
                        tooltip: t("offer.header.all-tab-tooltip")
                    }
                ]
            });
        }

        return res;
    }, [i18n.language, isHeaderDisabled, user, headerConfig]);


    const buildMenuSelectorsList = useCallback((): GridActionRowSelectorProps | string => {
        if (!headerConfig || !user) {
            return t(buildOfferGridLabel(type, user));
        }

        let userRole = getUserRole(user);
        const menu: GridActionRowSelectorProps = {
            selector: {
                disabled: isHeaderDisabled,
                width: 200,
                onChange: (item) => {
                    if (!item.description) {
                        return;
                    }

                    navigate(item.description);
                }
            },
            options: []
        };

        if (userRole === UserRole.ServiceProvider) {
            menu.options.push({
                id: 'offer-category-menu-offer',
                text: t("sidebar.offer.offer"),
                selected: matchPath('/offers/offer', location.pathname) !== null,
                description: '/offers/offer'
            });

            if (user.serviceProvider && !user.serviceProvider.hideOffers) {
                menu.options.push({
                    id: 'offer-category-menu-my-bet',
                    text: t("sidebar.offer.my-bet"),
                    selected: matchPath('/offers/bet', location.pathname) !== null,
                    description: '/offers/bet'
                });
            }
        }

        if (userRole === UserRole.Forwarder || userRole === UserRole.Administrator) {
            menu.options.push(...[
                {
                    id: 'offer-category-menu-all',
                    text: t("sidebar.offer.all"),
                    selected: matchPath('/offers/all', location.pathname) !== null,
                    description: '/offers/all'
                },
                {
                    id: 'offer-category-menu-exchange',
                    text: t("sidebar.offer.exchange"),
                    selected: matchPath('/offers/exchange', location.pathname) !== null,
                    description: '/offers/exchange',
                    tooltip: t("sidebar.offer.exchange-tooltip")
                },
                {
                    id: 'offer-category-menu-new',
                    text: t("sidebar.offer.new"),
                    selected: matchPath('/offers/new', location.pathname) !== null,
                    description: '/offers/new',
                    tooltip: t("sidebar.offer.new-tooltip")
                },
                {
                    id: 'offer-category-menu-sent',
                    text: t("sidebar.offer.sent"),
                    selected: matchPath('/offers/sent', location.pathname) !== null,
                    description: '/offers/sent',
                    tooltip: t("sidebar.offer.sent-tooltip")
                },
                {
                    id: 'offer-category-menu-bet',
                    text: t("sidebar.offer.bet"),
                    selected: matchPath('/offers/bet', location.pathname) !== null,
                    description: '/offers/bet',
                    tooltip: t("sidebar.offer.bet-tooltip")
                }
            ]);
        }

        return menu;
    }, [i18n.language, isHeaderDisabled, user, location.pathname, type]);

    if (!headerConfig || !user || !type) {
        return null;
    }

    return (
        <>
            <HardGridActionRow id={'offer-action-row'}
                               title={buildMenuSelectorsList()}
                               buttons={buildButtonList()}
                               actionButton={
                                   <>
                                       {getUserRole(user) === UserRole.Administrator || getUserRole(user) === UserRole.Forwarder
                                           ? <OfferActionRowEdiActionButton/>
                                           : null
                                       }

                                       <OfferActionRowActionButton/>
                                   </>
                               }
                               selectors={buildSelectorsList()}>
                {(props) =>
                    <OfferActionRowTags availableWidth={props.availableWidth}/>
                }
            </HardGridActionRow>

            <GridColumnsConfigurationSetupDialog show={isSetupDialogVisible}
                                                 onSaved={async () => {
                                                     setIsSetupDialogVisible(prev => !prev);

                                                     await reloadGridConfig();
                                                 }}
                                                 setShow={setIsSetupDialogVisible}
                                                 entity={LayoutConfigEntity.Offer}
                                                 defaultGridConfig={getOfferInitialGridConfig(getUserRole(user))}/>
        </>
    );
};

export default OfferActionRow;


/*
  const [isSetupDialogVisible, setIsSetupDialogVisible] = useState<boolean>(false);
    const {t} = useTranslation();
    const {type} = useParams();
    const user = useAppSelector(selectCurrentUser);
    const [userRole, setUserRole] = useState<UserRole | null>(null);
    const settings = useAppSelector(selectAccessSettings);
    const [query, setQuery] = useState<Query | null>(null);

    const filterQueryParams = useAppSelector(state =>
        selectQuery(state, LayoutConfigEntity.Offer));

    const {
        groupBy,
        refresh,

        defaultGridConfig,
        gridConfig,
        headerConfig,
        isHeaderDisabled,
        saveHeaderConfig,
        reloadGridConfig
    } = useGridLayoutContext<IOfferHeaderConfig>();

    const {
        download
    } = useGridExport({
        gridId: 'offer-grid',
        config: gridConfig ?? defaultGridConfig,
        useSelectedRowToExport: true
    });

    useEffect(() => {
        if (!user) {
            return;
        }

        setUserRole(getUserRole(user));
    }, [user]);

    useEffect(() => {
        if (!headerConfig || !filterQueryParams) {
            return;
        }


        setQuery(buildOfferQuery(type ?? '', headerConfig).addParamsFromQuery(Query.ToQuery(filterQueryParams)));
    }, [headerConfig, filterQueryParams]);

    if (!headerConfig || !type || userRole === null || !user) {
        return null;
    }

    const onChange = (config: IOfferHeaderConfig) => {
        (async () => {
            await saveHeaderConfig(config);
        })();
    }

    return (
        <GridActionRow id={'offer-grid-action-row'}>
            <GridActionRowSection id={'offer-grid-header-tabs-section'}>
                {(props) =>
                    <>
                        {props.view === 'PC' &&
                            <div className={'grid-entity-label prevent-selection'}>
                                {t(buildOfferGridLabel(type, user))}
                            </div>
                        }
                    </>
                }
            </GridActionRowSection>
            <GridActionRowSection id={'offer-grid-header-buttons-section'}>
                <div className={'flex flex-gap-20 flex-center-vertically'}>
                    {userRole !== UserRole.Support && userRole !== UserRole.ServiceProvider
                        ? <div>
                            <GridActionRowSelect disabled={isHeaderDisabled}
                                                 width={150}
                                                 onChange={(item) => {
                                                     onChange({
                                                         ...headerConfig,
                                                         subTab: item.id
                                                     });
                                                 }}>
                                <GridActionRowSelectOption id={'offer-my-offers-tab'}
                                                           text={t("offer.header.my-offers-tab")}
                                                           selected={headerConfig.subTab === 'offer-my-offers-tab'}
                                                           tooltip={t("offer.header.my-offers-tab-tooltip")}/>
                                <GridActionRowSelectOption id={'offer-common-tab'}
                                                           text={t("offer.header.common-tab")}
                                                           selected={headerConfig.subTab === 'offer-common-tab'}
                                                           tooltip={t("offer.header.common-tab-tooltip")}/>
                                <GridActionRowSelectOption id={'offer-all-tab'}
                                                           text={t("offer.header.all-tab")}
                                                           selected={headerConfig.subTab === 'offer-all-tab'}
                                                           tooltip={t("offer.header.all-tab-tooltip")}/>
                            </GridActionRowSelect>
                        </div>
                        : null
                    }

                    <div className={'flex flex-gap-10 flex-center-vertically'}>
                        {userRole === UserRole.Administrator || userRole === UserRole.Forwarder
                            ? <OfferActionRowEdiActionButton/>
                            : null
                        }

                        <OfferActionRowActionButton/>

                        {userRole !== UserRole.ServiceProvider && userRole !== UserRole.Support
                            ? <>
                                <GridActionRowButton id={'offer-group-by-release-number'}
                                                     title={headerConfig.isGroupByReleaseNumber ? t("shared.grid-header.btn-disable-group-by-release-number") : t("shared.grid-header.btn-enable-group-by-release-number")}
                                                     toggle={true}
                                                     defaultValue={headerConfig.isGroupByReleaseNumber}
                                                     disabled={isHeaderDisabled}
                                                     onClick={(val) => {
                                                         onChange({
                                                             ...headerConfig,
                                                             isGroupByReleaseNumber: val
                                                         });

                                                         groupBy(val ? 'mainOrderReleaseXid' : undefined);
                                                     }}
                                                     icon={"faList"}/>
                                <GridActionRowButton id={'offer-export'}
                                                     title={t("shared.grid-header.btn-export")}
                                                     disabled={isHeaderDisabled}
                                                     onClick={async () => {
                                                         await download(`Offer_${moment().format('DD_MM_YYYY_HH_mm')}.xlsx`);
                                                     }}
                                                     icon={"faUpload"}/>
                            </>
                            : null
                        }

                        <GridActionRowButton id={'offer-table-setup'}
                                             title={t("shared.grid-header.btn-table-setup")}
                                             disabled={isHeaderDisabled}
                                             onClick={() => setIsSetupDialogVisible(prev => !prev)}
                                             icon={"faGear"}/>
                        <GridColumnsConfigurationSetupDialog show={isSetupDialogVisible}
                                                             onSaved={async () => {
                                                                 setIsSetupDialogVisible(prev => !prev);

                                                                 await reloadGridConfig();
                                                             }}
                                                             setShow={setIsSetupDialogVisible}
                                                             entity={LayoutConfigEntity.Offer}
                                                             defaultGridConfig={getOfferInitialGridConfig(getUserRole(user))}/>

                        <GridActionRowButton id={'offer-refresh'}
                                             title={t("shared.grid-header.btn-refresh")}
                                             disabled={isHeaderDisabled}
                                             onClick={() => refresh()}
                                             icon={"faArrowsRotate"}/>
                    </div>
                </div>
            </GridActionRowSection>
        </GridActionRow>
    );

       <GridActionRowPredefinedFilterTabs disabled={isHeaderDisabled}
                                                           entity={LayoutConfigEntity.Offer}
                                                           selectedPredefinedFilter={headerConfig?.pinnedPredefinedFilter}
                                                           onPredefinedFilterSelect={(f) => {
                                                               onChange({
                                                                   ...headerConfig,
                                                                   pinnedPredefinedFilter: f
                                                               });
                                                           }}
                                                           predefinedFilters={!query || !headerConfig || !headerConfig.pinnedPredefinedFilters
                                                               ? []
                                                               : headerConfig.pinnedPredefinedFilters.map((f) => {
                                                                   let q = new Query().addParamsFromQuery(query);

                                                                   q.removeParam('f');
                                                                   q.addParam('f', f);

                                                                   q = buildAccessQuery(q, settings);

                                                                   return {
                                                                       value: f,
                                                                       countApiUrl: `api/${process.env.REACT_APP_API_VERSION}/offer/getOffersCount`,
                                                                       query: q
                                                                   };
                                                               })}/>
                        {props.availableWidth > 200 && <OfferFilterTags width={props.availableWidth}/>}

 */
