import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'upload',
    pathData: [
        'M5.83333 9.16642C5.05836 9.16642 4.67087 9.16642 4.35295 9.25161C3.49022 9.48278 2.81635 10.1566 2.58519 11.0194C2.5 11.3373 2.5 11.7248 2.5 12.4998V13.4998C2.5 14.8999 2.5 15.6 2.77248 16.1347C3.01217 16.6051 3.39462 16.9876 3.86502 17.2273C4.3998 17.4998 5.09987 17.4998 6.5 17.4998H13.5C14.9001 17.4998 15.6002 17.4998 16.135 17.2273C16.6054 16.9876 16.9878 16.6051 17.2275 16.1347C17.5 15.6 17.5 14.8999 17.5 13.4998V12.4998C17.5 11.7248 17.5 11.3373 17.4148 11.0194C17.1836 10.1566 16.5098 9.48278 15.647 9.25161C15.3291 9.16642 14.9416 9.16642 14.1667 9.16642M13.3333 5.83309L10 2.49976M10 2.49976L6.66667 5.83309M10 2.49976V12.4998'
    ],
    viewBox: '0 0 20 20',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;