import {IUser} from "../app/interfaces/user/IUser";
import {UserRole} from "../app/enums/UserRole";

export const checkUserInRole = (role: UserRole, user?: IUser): boolean => {
    if (!user) {
        return false;
    }

    return user.fakeRole !== undefined && user.fakeRole !== null
        ? user.fakeRole === role
        : user.role === role;
}

export const getUserRole = (user: IUser): UserRole => {
    return user.fakeRole !== undefined && user.fakeRole !== null
        ? user.fakeRole
        : user.role;
}

export const checkUserIsAdmin = (user?: IUser): boolean => {
    if (!user) {
        return false;
    }

    return user.role !== undefined && user.role !== null && user.role === UserRole.Administrator;
}
