import React, {useMemo, useRef, useState} from 'react';
import {IRefinePositionGetAction} from "../../../../../../app/interfaces/shipment/IRefinePositionGetAction";
import {Circle, CircleProps, GoogleMap, InfoWindow, Marker, MarkerProps, useLoadScript} from "@react-google-maps/api";
import FormLoadingScreen from "../../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {useTranslation} from "react-i18next";
import GoogleMapCustomControl
    from "../../../../../../components/ui/GoogleMap/GoogleMapCustomControl/GoogleMapCustomControl";
import {defaultMapOptions, useLoadScriptOptions} from "../../../../../../components/ui/GoogleMap/utils/map";
import MapSearchBox from "../../../../../../components/ui/GoogleMap/MapSearchBox/MapSearchBox";
import {debounce} from "debounce";
import Button, {ButtonType} from "../../../../../../components/ui/Button/Button";
import {
    buildRoutePointCirclesArray,
    buildRoutePointMarkersArray,
    LocationMapExtendedMarkerProps
} from "../../LocationMap/utils";
import {toast} from "react-toastify";
import {useShipmentApi} from "../../../../../../app/api/shipment";
import LocationMapRouteMarkerInfoWindow
    from "../../LocationMap/components/LocationMapRouteMarkerInfoWindow/LocationMapRouteMarkerInfoWindow";

type RefinePositionMapProps = {
    model: IRefinePositionGetAction;
    onChange: (model: IRefinePositionGetAction) => void;
    save: (lat: number, lng: number) => Promise<void>;
    disabled: boolean;
}

const RefinePositionMap: React.FC<RefinePositionMapProps> = ({
                                                                 model,
                                                                 onChange,
                                                                 disabled,
                                                                 save
                                                             }) => {
    const {i18n, t} = useTranslation();
    const [isReferenceSet, setIsReferenceSet] = useState<boolean>(false);
    const [isSaveButtonShown, setIsSaveButtonShown] = useState<boolean>(false);

    const [marker, setMarker] = useState<MarkerProps | null>();
    const [circle, setCircle] = useState<CircleProps | null>(null);
    const [bounds, setBounds] = useState<google.maps.LatLngBounds | null>(null);
    const [routePointMarkers, setRoutePointMarkers] = useState<Array<LocationMapExtendedMarkerProps>>([]);
    const [routePointCircles, setRoutePointCircles] = useState<Array<CircleProps>>([]);
    const [activeInfoWindow, setActiveInfoWindow] = useState<string | null>(null);
    const map = useRef<google.maps.Map | null>(null);
    const options = useMemo(() => (defaultMapOptions), []);
    const {isLoaded} = useLoadScript({
        ...useLoadScriptOptions,
        language: i18n.language,
    });

    const {
        getRoute: {
            query: getRoute
        },
        getTrackPoints: {
            query: getTrackPoints
        }
    } = useShipmentApi();

    const handleInfoWindow = (id: string, ev: 'close' | 'open') => {
        if (ev === 'close') {
            setActiveInfoWindow(null);
        } else {
            setActiveInfoWindow(id);
        }
    }

    const showRoutePoints = async () => {
        try {
            const response = await getTrackPoints(model.shipmentGid);
            if (response && response.status === 200 && response.data && response.data.data &&
                response.data.data.length > 0) {
                const markers = buildRoutePointMarkersArray(response.data.data, handleInfoWindow);
                const circles = buildRoutePointCirclesArray(response.data.data);

                setRoutePointMarkers(markers);
                setRoutePointCircles(circles);
            }
        } catch (e) {
            toast.error('Unable to get track points');
        }
    }
    const onMapLoad = (mapRef: google.maps.Map) => {
        map.current = mapRef;

        if (!model.lt || model.lt === 0 || !model.lg || model.lg === 0) {
            setIsReferenceSet(true);

            return;
        }

        initMarker({
            lat: model.lt ?? 0,
            lng: model.lg ?? 0,
            address: model.address
        });

        map.current?.addListener('rightclick', function (ev: google.maps.MapMouseEvent) {
            if (!ev.latLng) {
                return;
            }

            const pos = {
                lat: ev.latLng.lat(),
                lng: ev.latLng.lng()
            }

            initMarker({
                lat: pos.lat,
                lng: pos.lng,
                address: ''
            });

            initAddress(pos.lat, pos.lng);

            if (!isSaveButtonShown) {
                setIsSaveButtonShown(true);
            }
        });

        setIsReferenceSet(true);

        showRoutePoints().then(() => {
            //ignore
        });
    }

    const initMarker = (props: {
        lat: number;
        lng: number;
        address: string | null;
    }) => {
        if (circle) {
            setCircle(null);
        }

        if (marker) {
            setMarker(null);
        }

        let markerLocal: MarkerProps = {
            icon: '/images/marker.png',
            title: '',
            draggable: true,
            zIndex: 10,
            position: {
                lat: props.lat,
                lng: props.lng
            }
        };

        let circleLocal: CircleProps = {
            center: {
                lat: props.lat,
                lng: props.lng
            },
            radius: 1000,
            options: {
                strokeColor: '#29d64e',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#29d64e',
                fillOpacity: 0.35,
            }
        }

        markerLocal.onDragEnd = function (ev) {
            if (!ev.latLng) {
                return;
            }

            const pos = {
                lat: ev.latLng.lat(),
                lng: ev.latLng.lng()
            }

            let c: CircleProps = {
                center: {
                    lat: pos.lat,
                    lng: pos.lng
                },
                radius: 1000,
                options: {
                    strokeColor: '#29d64e',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: '#29d64e',
                    fillOpacity: 0.35,
                }
            }

            setCircle(c);
            setMarker({
                ...markerLocal,
                title: '',
                position: {
                    lat: pos.lat,
                    lng: pos.lng
                }
            });

            if (map.current) {
                map.current?.panTo({
                    lat: pos.lat,
                    lng: pos.lng
                });
            }

            initAddress(pos.lat, pos.lng);

            if (!isSaveButtonShown) {
                setIsSaveButtonShown(true);
            }
        }

        setMarker(markerLocal);
        setCircle(circleLocal);

        if (map.current) {
            map.current?.panTo({
                lat: props.lat,
                lng: props.lng
            });

            map.current?.setZoom(12);

            let boundsLocal = new google.maps.LatLngBounds();

            boundsLocal.extend({
                lat: props.lat,
                lng: props.lng
            });

            setBounds(boundsLocal);
        }
    }

    const onPlaceChange = (items: Array<google.maps.places.PlaceResult>) => {
        if (items.length <= 0) {
            return;
        }

        let item = items[0];

        if (!item.geometry || !item.geometry.location) {
            return;
        }

        initMarker({
            lat: item.geometry.location.lat(),
            lng: item.geometry.location.lng(),
            address: item.formatted_address ?? null
        });

        initAddress(item.geometry.location.lat(), item.geometry.location.lng());

        if (!isSaveButtonShown) {
            setIsSaveButtonShown(true);
        }
    }

    const initAddress = debounce((lat: number, lng: number) => {
        if (model.fromTranspareon) {
            return;
        }

        try {
            const geocoder = new google.maps.Geocoder();

            geocoder.geocode({location: {lat: lat, lng: lng}})
                .then((data) => {
                    if (data.results.length > 0) {
                        onChange({
                            ...model,
                            address: data.results[0].formatted_address
                        });
                    }
                });
        } catch {
            // ignore
        }
    }, 500);

    if (!isLoaded) {
        return (
            <FormLoadingScreen height={`100%`} style={{padding: '5px'}}/>
        );
    }

    return (
        <div className={'relative h100 pd-5'}>
            <GoogleMap mapContainerClassName="h100"
                       options={options}
                       onLoad={onMapLoad}>
                {isSaveButtonShown &&
                    <GoogleMapCustomControl position={google.maps.ControlPosition.TOP_RIGHT}
                                            map={map.current}
                                            isMapLoaded={isReferenceSet}
                                            className={'pac-button-outer-container'}>
                        <Button buttonType={ButtonType.Primary}
                                disabled={disabled}
                                onClick={async () => {
                                    if (marker) {
                                        const pos = {
                                            lat: typeof (marker.position.lat) === 'function'
                                                ? marker.position.lat()
                                                : marker.position.lat,
                                            lng: typeof (marker.position.lng) === 'function'
                                                ? marker.position.lng()
                                                : marker.position.lng,
                                        }

                                        await save(pos.lat, pos.lng);
                                    }
                                }}>
                            {t("shared.btn.save-new-location")}
                        </Button>
                    </GoogleMapCustomControl>
                }
                <GoogleMapCustomControl position={google.maps.ControlPosition.TOP_LEFT}
                                        map={map.current}
                                        isMapLoaded={isReferenceSet}
                                        className={'pac-outer-container'}>
                    <MapSearchBox map={map.current}
                                  onPlacesChanged={onPlaceChange}
                                  isMapLoaded={isReferenceSet}
                                  bounds={bounds ?? undefined}
                                  placeholder={t("shared.labels.search-address")}/>
                </GoogleMapCustomControl>
                {routePointMarkers.map((marker, index) => {
                    return (
                        <Marker key={index} {...marker}>
                            {activeInfoWindow !== null && activeInfoWindow === marker.id &&
                                <InfoWindow>
                                    <LocationMapRouteMarkerInfoWindow position={marker.pos}/>
                                </InfoWindow>
                            }
                        </Marker>
                    );
                })}
                {routePointCircles.map((circle, index) => {
                    return (
                        <Circle key={index} {...circle} />
                    );
                })}
                {marker && <Marker {...marker} />}
                {circle && <Circle {...circle} />}
            </GoogleMap>
        </div>
    );
};

export default RefinePositionMap;
