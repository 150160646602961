import {AxiosResponse} from "axios";
import {IDataResponse, IResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {ILayoutConfig} from "../../interfaces/layoutConfig/ILayoutConfig";
import {LayoutConfigEntity} from "../../enums/LayoutConfigEntity";
import {LayoutConfigType} from "../../enums/LayoutConfigType";

export const useGetLayoutConfig = (): {
    isLoading: boolean,
    cancel: () => void,
    getLayoutConfig: (entity: LayoutConfigEntity, type: LayoutConfigType, deviceId?: number) => Promise<AxiosResponse<IDataResponse<ILayoutConfig>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ILayoutConfig>>({
        method: 'get',
    });

    const getLayoutConfig = useCallback(async (entity: LayoutConfigEntity, type: LayoutConfigType, deviceId?: number) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/layoutConfig/getLayoutConfig?entity=${entity}&type=${type}&deviceId=${deviceId}`
    }), [execute]);

    return {getLayoutConfig, isLoading, cancel};
};

export const useSaveLayoutConfig = (): {
    isLoading: boolean,
    cancel: () => void,
    saveLayoutConfig: (params: ILayoutConfig) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ILayoutConfig>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/layoutConfig/saveLayoutConfig`
    });

    const saveLayoutConfig = useCallback(async (params: ILayoutConfig) => await execute(params), [execute]);

    return {saveLayoutConfig, isLoading, cancel};
};