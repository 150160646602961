import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import cl from './StatusChangeDateTemplate.module.css';
import {useTranslation} from "react-i18next";

type CircleTimerParams = {
    radius: number;
    text: string;
    textColor: string;
    borderColor: string;
    strokeDashoffset: number;
    strokeDasharray: number;
}

const StatusChangeDateTemplate = (props: any) => {
    const {t} = useTranslation();
    const [circleTimerParams, setCircleTimerParams] = useState<CircleTimerParams | null>(null);

    const {handleClick} = useGridCellHandleClick(props.dataItem);

    useEffect(() => {
        if (props.dataItem.statusChangeDate === null || props.dataItem.statusChangeDate === undefined) {
            return;
        }

        setupTimerCircle()

        let intervalId = setInterval(() => {
            setupTimerCircle();
        }, 60000);

        return () => {
            clearInterval(intervalId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setupTimerCircle = () => {
        const radius = 14;
        const currentUtcDate = moment().utc().toDate();

        let val = date.getTime() - currentUtcDate.getTime();
        let value_c = 1 - (((7200000 - val) / (7200000)));

        let circumference = radius * 2 * Math.PI;
        let offset = circumference - value_c * circumference;
        let color = "green";
        let tColor = "black";
        let minutes_remaining = val / 60000;

        if (minutes_remaining <= 10) {
            tColor = "red";
        }

        minutes_remaining = Math.ceil(minutes_remaining);
        let text = '';

        if (val < 1000 || minutes_remaining >= 120) {
            offset = 0;
            circumference = 0;
            if (minutes_remaining >= 120) {
                text = `> ${Math.abs(Math.floor(minutes_remaining / 60))} ${t("shared.labels.hour-short")}`;
            }
            if (val < 1000) {
                color = "red";
                text = 'exp.';
            }
        } else {
            if (minutes_remaining >= 60) {
                let minutes = minutes_remaining - 60;
                text = "01:" + (minutes > 9 ? minutes + "" : "0" + minutes)
            } else {

                text = "00:" + (minutes_remaining > 9 ? minutes_remaining + "" : "0" + minutes_remaining)
            }
        }

        setCircleTimerParams({
            radius: radius,
            strokeDashoffset: offset,
            strokeDasharray: circumference,
            text: text,
            textColor: tColor,
            borderColor: color
        });
    }

    let date = props.dataItem.statusChangeDate !== null && props.dataItem.statusChangeDate !== undefined
        ? props.dataItem.statusChangeDate
        : null;

    if (date === null || !circleTimerParams) {
        return (
            <td {...props.tdProps}
                colSpan={1}
                onClick={handleClick}>
                <span className={'default-center-cell'}>

                </span>
            </td>
        );
    }

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
            <span className={'default-center-cell'}
                  title={t("quote.grid.time-for-quoting")}>
                <svg className={cl.ring} width="34" height="34">
                    <circle strokeDasharray={circleTimerParams.strokeDasharray}
                            strokeDashoffset={circleTimerParams.strokeDashoffset}
                            className={cl.circle}
                            stroke={circleTimerParams.borderColor}
                            strokeWidth={2}
                            fill={'white'}
                            r={circleTimerParams.radius}
                            cx={17}
                            cy={17}/>
                    <text fill={circleTimerParams.textColor}
                          className={`prevent-selection ${cl.text}`}
                          textAnchor="middle"
                          dominantBaseline="central"
                          x="50%"
                          y="50%">
                        {circleTimerParams.text}
                    </text>
                </svg>
            </span>
        </td>
    );
};

export default StatusChangeDateTemplate;