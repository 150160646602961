import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import UnConfirmShipment from "./UnConfirmShipment";

type UnConfirmShipmentDialogProps = {
    id: string | Array<string>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const UnConfirmShipmentDialog: React.FC<UnConfirmShipmentDialogProps> = ({
                                                                             id,
                                                                             show,
                                                                             setShow
                                                                         }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'un-confirm-shipment-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("shared.btn.un-confirm-shipment")}>
            <UnConfirmShipment id={id}
                               close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default UnConfirmShipmentDialog;