import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import InvoiceRequestDetails from "./InvoiceRequestDetails";
import {IUser} from "../../../../../app/interfaces/user/IUser";

type InvoiceRequestDetailsDialogProps = {
    user?: IUser;
    invoiceGid: string;
    mode: 'Answer' | 'Block' | 'Payment';
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const InvoiceRequestDetailsDialog: React.FC<InvoiceRequestDetailsDialogProps> = ({
                                                                                     user,
                                                                                     invoiceGid,
                                                                                     mode,
                                                                                     show,
                                                                                     setShow
                                                                                 }) => {
    const {t} = useTranslation();

    let title: string = '';

    switch (mode) {
        case "Answer":
            title = t("chat.labels.message");
            break;
        case "Block":
            title = t("invoice.form.refine-blocking-details");
            break;
        case "Payment":
            title = t("invoice.form.refine-payment-details");
            break;
    }

    return (
        <Dialog id={'invoice-request-details-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={title}>
            <InvoiceRequestDetails invoiceGid={invoiceGid}
                                   user={user}
                                   mode={mode}
                                   close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default InvoiceRequestDetailsDialog;