import React from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";
import {useTranslation} from "react-i18next";
import Icon from "../../../../../../assets/icon/Icon";

const PaidStatusTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const {t} = useTranslation();

    let color: string = '';
    let text: string = '';

    if (props.dataItem.paidStatus !== null &&
        props.dataItem.paidStatus !== undefined &&
        props.dataItem.paidStatus !== "") {
        if (props.dataItem.paidStatus === 'paid') {
            color = '#7FB98B';
            text = t('invoice.status.paid');
        } else if (props.dataItem.paidStatus === 'notpaid') {
            color = '#4e4e4e';
            text = t('invoice.status.not-paid');
        } else {
            color = '#7D89BA';
            text = t('invoice.status.partially-paid');
        }
    }

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
            <span className={'default-center-cell'}>
                {props.dataItem.paidStatus !== null &&
                    props.dataItem.paidStatus !== undefined &&
                    props.dataItem.paidStatus !== "" &&
                    color && text &&
                    <Icon icon={"faCircleDollar"}
                          title={text}
                          style={{
                              color: color, fontSize: '16px'
                          }}/>
                }
            </span>
        </td>
    );
};

export default PaidStatusTemplate;
