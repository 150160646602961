import React, {useEffect, useRef, useState} from 'react';
import cl from './TagsGridCellTemplate.module.css';
import {ITag} from "../../../../../../app/interfaces/tag/ITag";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../helpers/user";
import {UserRole} from "../../../../../../app/enums/UserRole";
import Tag from "../../Tag";
import DisassociateTagDialog from "../../actions/DisassociateTag/DisassociateTagDialog";
import {OrderType} from "../../../../../../app/enums/OrderType";
import AssociateTagDialog from "../../actions/AssociateTag/AssociateTagDialog";
import {useGridCellHandleClick} from "../../../../../ui/Grid/Telerik/hooks/useGridCellHandleClick";

const TagsGridCellTemplate = (props: any) => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);
    const {handleClick} = useGridCellHandleClick(props.dataItem);
    const tagsContainer = useRef<HTMLDivElement | null>(null);
    const [tagsContainerWidth, setTagsContainerWidth] = useState<number>(0);

    const [isAssociateDialogShown, setIsAssociateDialogShown] = useState<boolean>(false);
    const [isDisassociateDialogShown, setIsDisassociateDialogShown] = useState<boolean>(false);
    const [tagToDelete, setTagToDelete] = useState<ITag | null>(null);

    useEffect(() => {
        if (!tagsContainer.current)
            return;

        const resizeObserver = new ResizeObserver(() => {
            let width = tagsContainer.current?.clientWidth ?? 0;
            if (width !== tagsContainerWidth) {
                setTagsContainerWidth(width);
            }
        });

        resizeObserver.observe(tagsContainer.current);

        return () => resizeObserver.disconnect();

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!user) {
        return (
            <td {...props.tdProps}
                colSpan={1}>
            </td>
        );
    }

    let role = getUserRole(user);
    let tags = props.dataItem["tagsCollection"] !== undefined && props.dataItem["tagsCollection"] !== null && props.dataItem["tagsCollection"].length > 0
        ? props.dataItem["tagsCollection"] as Array<ITag>
        : null;

    let gid = props.dataItem.id !== undefined && props.dataItem.id !== null && props.dataItem.id !== ''
        ? props.dataItem.id as string
        : null;
    let orderType = props.dataItem.orderType !== undefined && props.dataItem.orderType !== null
        ? props.dataItem.orderType as OrderType
        : null;

    if (role === UserRole.Forwarder) {
        return (
            <td {...props.tdProps}
                colSpan={1}>
                {tagToDelete && gid !== null && orderType !== null &&
                    <DisassociateTagDialog tagId={tagToDelete.id}
                                           orderReleaseGid={gid}
                                           orderType={orderType}
                                           show={isDisassociateDialogShown}
                                           setShow={setIsDisassociateDialogShown}/>
                }

                {gid !== null && orderType !== null &&
                    <AssociateTagDialog orderReleaseGid={gid}
                                        orderType={orderType}
                                        show={isAssociateDialogShown}
                                        setShow={setIsAssociateDialogShown}/>
                }
                <div onClick={handleClick} className={`grid-prevent-selection ${cl.container} ${cl.cfw}`}>
                    <div className={cl.iconContainer}>
                        <FontAwesomeIcon icon={faPen}
                                         className={`${cl.icon} add-tag-btn grid-prevent-selection`}
                                         title={t("shared.btn.add-tag")}
                                         onClick={(ev) => {

                                             setIsAssociateDialogShown(prev => !prev);


                                             ev.stopPropagation();
                                         }}/>
                    </div>
                    <div ref={tagsContainer} className={`grid-prevent-selection ${cl.tags}`}>
                        {tags === null &&
                            <div className={`grid-prevent-selection ${cl.addText}`}
                                 onClick={(ev) => {

                                     setIsAssociateDialogShown(prev => !prev);

                                     ev.stopPropagation();
                                 }}>
                                {t("shared.btn.add-tag")}
                            </div>
                        }

                        {tags !== null &&
                            <>
                                {tags.map(item => {
                                    return (
                                        <Tag key={item.id}
                                             size={tags!.length < 3 ? 'full' : 'rectangle'}
                                             item={{
                                                 id: `item-${item.id}`,
                                                 text: item.name,
                                                 title: `${item.name} / ${item.userName !== '' ? item.userName : ''}`,
                                                 showDeleteIcon: false,
                                                 showEditIcon: false,
                                                 showHoverDeleteIcon: item.canDelete,
                                                 showHoverEditIcon: false,
                                                 hoverMagnification: true,
                                                 backgroundColor: item.color
                                             }}
                                             containerMaxWidth={tagsContainerWidth}
                                             onClear={() => {
                                                 setTagToDelete(item);

                                                 setIsDisassociateDialogShown(prev => !prev);
                                             }}/>
                                    );
                                })}
                            </>
                        }
                    </div>
                </div>
            </td>
        );
    } else if (role === UserRole.Administrator) {
        return (
            <td {...props.tdProps} onClick={handleClick}
                colSpan={1}>
                <div className={`grid-prevent-selection ${cl.container}`}>
                    <div ref={tagsContainer} className={`h100 grid-prevent-selection ${cl.tags}`}>
                        {tags !== null &&
                            <>
                                {tags.map(item => {
                                    return (
                                        <Tag key={item.id}
                                             size={tags!.length < 3 ? 'full' : 'rectangle'}
                                             item={{
                                                 id: `item-${item.id}`,
                                                 text: item.name,
                                                 title: `${item.name} / ${item.userName !== '' ? item.userName : ''}`,
                                                 showDeleteIcon: false,
                                                 showEditIcon: false,
                                                 showHoverDeleteIcon: false,
                                                 showHoverEditIcon: false,
                                                 hoverMagnification: true,
                                                 backgroundColor: item.color
                                             }}
                                             containerMaxWidth={tagsContainerWidth}/>
                                    );
                                })}
                            </>
                        }
                    </div>
                </div>
            </td>
        );
    }

    return (
        <td {...props.tdProps}
            colSpan={1}>
        </td>
    );
};

export default TagsGridCellTemplate;
