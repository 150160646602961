import React from 'react';
import {ISelfBilling} from "../../../../../../../app/interfaces/selfBilling/ISelfBilling";
import {IUser} from "../../../../../../../app/interfaces/user/IUser";
import {ISelfBillingExpense} from "../../../../../../../app/interfaces/selfBilling/ISelfBillingExpense";
import SelfBillingDocumentListItem from "../SelfBillingDocumentListItem/SelfBillingDocumentListItem";

type SelfBillingDocumentListProps = {
    model: ISelfBilling;
    expense: ISelfBillingExpense;
    isAnonymousAccess: boolean;
    user?: IUser;
};

const SelfBillingDocumentList: React.FC<SelfBillingDocumentListProps> = ({
                                                                             expense,
                                                                             user,
                                                                             model,
                                                                             isAnonymousAccess
                                                                         }) => {
    return (
        <>
            {[...expense.cmrDocuments]
                .sort((a, b) => {
                    return (b.documentType - a.documentType) || (new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());
                })
                .map(document => {
                    return <SelfBillingDocumentListItem key={document.id}
                                                        document={document}
                                                        model={model}
                                                        expense={expense}
                                                        isAnonymousAccess={isAnonymousAccess}
                                                        user={user}/>
                })}
        </>
    );
};

export default SelfBillingDocumentList;
