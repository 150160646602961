import {
    useContactSupport,
    useGetContactSupportData, useGetIsEmailNeedConfirmation,
    useGetProfileData,
    useResendConfirmationEmail,
    useSaveProfile
} from "./requests";

export const useProfileApi = () => {
    const {
        isLoading: getContactSupportDataIsLoading,
        cancel: cancelGetContactSupportData,
        getContactSupportData
    } = useGetContactSupportData();

    const {
        isLoading: contactSupportIsLoading,
        cancel: cancelContactSupport,
        contactSupport
    } = useContactSupport();

    const {
        isLoading: getProfileDataIsLoading,
        cancel: cancelGetProfileData,
        getProfileData
    } = useGetProfileData();

    const {
        isLoading: saveProfileIsLoading,
        cancel: cancelSaveProfile,
        saveProfile
    } = useSaveProfile();

    const {
        isLoading: resendConfirmationEmailIsLoading,
        cancel: cancelResendConfirmationEmail,
        resendConfirmationEmail
    } = useResendConfirmationEmail();

    const {
        isLoading: getIsEmailNeedConfirmationIsLoading,
        cancel: cancelGetIsEmailNeedConfirmation,
        getIsEmailNeedConfirmation
    } = useGetIsEmailNeedConfirmation();

    return {
        contactSupport: {
            mutation: contactSupport,
            isLoading: contactSupportIsLoading,
            cancel: cancelContactSupport
        },
        getContactSupportData: {
            query: getContactSupportData,
            isLoading: getContactSupportDataIsLoading,
            cancel: cancelGetContactSupportData
        },
        saveProfile: {
            mutation: saveProfile,
            isLoading: saveProfileIsLoading,
            cancel: cancelSaveProfile
        },
        getProfileData: {
            query: getProfileData,
            isLoading: getProfileDataIsLoading,
            cancel: cancelGetProfileData
        },
        resendConfirmationEmail: {
            query: resendConfirmationEmail,
            isLoading: resendConfirmationEmailIsLoading,
            cancel: cancelResendConfirmationEmail
        },
        getIsEmailNeedConfirmation: {
            query: getIsEmailNeedConfirmation,
            isLoading: getIsEmailNeedConfirmationIsLoading,
            cancel: cancelGetIsEmailNeedConfirmation
        }
    };
};