import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import ChangePlannedDate from "./ChangePlannedDate";

type ChangePlannedDateDialogProps = {
    id: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangePlannedDateDialog: React.FC<ChangePlannedDateDialogProps> = ({
                                                                             id,
                                                                             show,
                                                                             setShow
                                                                         }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'change-date-event-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={t("offer.grid.changing-planned-date")}>
            <ChangePlannedDate id={id}
                               close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default ChangePlannedDateDialog;