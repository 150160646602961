import React, {useEffect, useState} from 'react';
import {IUser} from "../../../../../app/interfaces/user/IUser";
import {useTranslation} from "react-i18next";
import {IError} from "../../../../../app/interfaces/response/IResponse";
import useSelfBillingApi from "../../../../../app/api/selfBilling";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {ISelfBillingEditGetAction} from "../../../../../app/interfaces/selfBilling/ISelfBillingEditGetAction";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {ISelfBillingEditPostAction} from "../../../../../app/interfaces/selfBilling/ISelfBillingEditPostAction";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {BLErrorCode} from "../../../../../app/enums/BLErrorCode";
import Alert from "../../../../../components/ui/Alert/Alert";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import EditBillingDataForm from "./EditBillingDataForm/EditBillingDataForm";

type EditBillingDataProps = {
    shipmentGid: string;
    user?: IUser;
    close?: () => void;
};

const alertStyle: React.CSSProperties = {
    margin: '5px 10px',
    fontSize: '11px',
    fontWeight: 'bold',
}

const EditBillingData: React.FC<EditBillingDataProps> = ({
                                                             shipmentGid,
                                                             user,
                                                             close
                                                         }) => {
    const {t} = useTranslation();
    const [model, setModel] = useState<ISelfBillingEditGetAction | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError>>([]);

    const {
        getSelfBillingEditData: {
            isLoading: getSelfBillingEditDataIsLoading,
            cancel: cancelGetSelfBillingEditData,
            query: getSelfBillingEditData
        },
        getSelfBillingEditDataAnonymous: {
            isLoading: getSelfBillingEditDataAnonymousIsLoading,
            cancel: cancelGetSelfBillingEditDataAnonymous,
            query: getSelfBillingEditDataAnonymous
        },
        editSelfBilling: {
            mutation: editSelfBilling,
            cancel: cancelEditSelfBilling
        },
        editSelfBillingAnonymous: {
            mutation: editSelfBillingAnonymous,
            cancel: cancelEditSelfBillingAnonymous
        }
    } = useSelfBillingApi();

    useEffect(() => {
        if (!shipmentGid || shipmentGid === '') {
            if (close) {
                close();
            }

            return;
        }

        (async () => {
            try {
                const response = user
                    ? await getSelfBillingEditData(shipmentGid)
                    : await getSelfBillingEditDataAnonymous(shipmentGid);

                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error<string>(`Unable to get billing data. Error: ${e.message}`);

                    if (close) {
                        close();
                    }
                }
            }
        })();

        return () => {
            cancelGetSelfBillingEditData();
            cancelGetSelfBillingEditDataAnonymous();
            cancelEditSelfBillingAnonymous();
            cancelEditSelfBilling();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipmentGid]);

    const save = async (values: ISelfBillingEditPostAction): Promise<void> => {
        if (!model) {
            return;
        }

        setDisabled(prev => !prev);

        try {
            const response = user
                ? await editSelfBilling(values)
                : await editSelfBillingAnonymous(values);

            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("self-billing.labels.billing-data-label")}`}/>);

                if (close) {
                    close();
                }
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotValidData &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();

                    toast.error(`Unable to save billing data. Error: ${err.message}`);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if (!shipmentGid || shipmentGid === '') {
        return null;
    }

    if (getSelfBillingEditDataAnonymousIsLoading || getSelfBillingEditDataIsLoading || model === null) {
        return (
            <FormLoadingScreen height={`350px`} style={{padding: '5px'}}/>
        );
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                {errors.map(item => {
                    switch (item.errorCode) {
                        case BLErrorCode.InnerError:
                            return (
                                <Alert key={item.errorCode} type={'Error'} style={alertStyle}>
                                    {item.description}
                                </Alert>
                            );
                    }

                    return null;
                })}

                <EditBillingDataForm model={model}
                                     disabled={disabled}
                                     onSubmit={save}/>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        form='edit-billing-data-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.save")}
                </Button>
            </div>
        </div>
    );
};

export default EditBillingData;