import React from 'react';
import {IOffer} from "../../../../../../../../app/interfaces/offer/IOffer";

type OfferCostBruttoProps = {
    offer: IOffer;
};

const OfferCostBrutto: React.FC<OfferCostBruttoProps> = ({
                                                             offer
                                                         }) => {
    if (offer.costBrutto === null || offer.costBrutto === '') {
        return (
            <div className={'details-box-item-field-value'}>

            </div>
        );
    }

    return (
        <div className={'details-box-item-field-value'}>
            {offer.costBrutto}
        </div>
    );
};

export default OfferCostBrutto;
