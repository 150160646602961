import React, {useEffect, useState} from 'react';
import cl from './LoginFormContent.module.css';
import {UserDeviceType} from "../../../../../app/enums/UserDeviceType";
import {ILogin, ILoginUser} from "../../../../../app/interfaces/login/ILogin";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";
import LoginInput from "../../../../../components/ui/LoginInput/LoginInput";
import {faLock, faTimes, faUser} from "@fortawesome/free-solid-svg-icons";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {motion} from 'framer-motion';
import {IUserDevice} from "../../../../../app/interfaces/user/IUserDevice";
import {getUserDevice} from "../../../../../utils/device";
import {AxiosError} from "axios";
import useGoogleReCaptchaV3 from "../../../../../hooks/useGoogleReCaptchaV3/useGoogleReCaptchaV3";
import {useLoginApi} from "../../../../../app/api/login";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import useIfFirstRender from "../../../../../hooks/useIsFirstRender/useIfFirstRender";
import {ServerError} from "../../../../../hooks/useServerError/useServerError";
import {IResponse, isResponse} from "../../../../../app/interfaces/response/IResponse";
import ServerErrorLabel from "../../../../../components/ui/ServerErrorLabel/ServerErrorLabel";
import {validateUserAuthentication} from "../../../../../features/authentication/authenticationSlice";
import {useAppDispatch} from "../../../../../app/store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import Alert from "../../../../../components/ui/Alert/Alert";

const initialValues: ILogin = {
    login: '',
    password: '',
    token: '',
    userDevice: {
        deviceId: '',
        browserVersion: '',
        browserName: '',
        deviceType: UserDeviceType.PersonalComputer
    }
};

type LoginFormContentProps = {
    changeComponent: (email?: string) => void;
    clearSelectedUser: () => void;
    selectedUser?: ILoginUser | null;
    componentName: string;
}

const LoginFormContent: React.FC<LoginFormContentProps> = ({changeComponent, selectedUser, clearSelectedUser}) => {
    const {t, i18n} = useTranslation();
    const {loaded, executeReCaptcha} = useGoogleReCaptchaV3({
        siteKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '',
        language: i18n.language
    });
    const navigate = useNavigate();
    const {login: {mutation}} = useLoginApi();
    const [error, setError] = useState<ServerError | IResponse>();
    const isFirstRender = useIfFirstRender();
    const dispatch = useAppDispatch();

    useEffect(() => {
        (async () => {
            if (Object.keys(form.errors).length > 0) {
                await form.validateForm();
            }
        })();
    }, [i18n.language]);

    const form = useFormik<ILogin>({
        initialValues,
        validationSchema: Yup.object({
            login: Yup.string()
                .required(t("shared.errors.required-error").replace('{0}', t("login.labels.login")))
                .max(100),
            password: Yup.string()
                .required(t("shared.errors.required-error").replace('{0}', t("login.labels.password")))
                .max(100),
        }),
        onSubmit: async (values: ILogin, actions: FormikHelpers<ILogin>) => {
            try {
                actions.setSubmitting(true);

                if (!loaded || !executeReCaptcha) {
                    setError(t('shared.errors.google-recaptcha-loaded'));

                    return;
                }

                const userDevice: IUserDevice = await getUserDevice();
                const token: string = await executeReCaptcha('login/submit');

                if (!token) {
                    setError(t('shared.error.google-recaptcha-loaded'));
                }

                const response = await mutation({
                    userDevice,
                    token,
                    login: values.login,
                    password: values.password
                });

                if (response?.data !== undefined &&
                    response.data.resultCode === ResponseResultCode.Ok) {
                    dispatch(validateUserAuthentication());
                }

            } catch (error) {
                if (error instanceof AxiosError) {
                    if (error.response !== undefined &&
                        error.response.data !== undefined &&
                        isResponse(error.response.data)) {
                        if (error.response.data.resultCode === ResponseResultCode.MultipleChoices &&
                            error.response.data.action === 'authentication/login') {
                            changeComponent(values.login);
                        } else {
                            setError(error.response.data as IResponse);
                        }
                    } else {
                        setError({
                            resultCode: ResponseResultCode.ServerError,
                            message: error.message
                        });
                    }
                }
            } finally {
                actions.setSubmitting(false);
            }
        }
    });

    useEffect(() => {
        if (form) {
            if (!isFirstRender) {
                form.validateForm().then(() => {
                    //ignore
                });
            } else {
                if (selectedUser !== null && selectedUser !== undefined) {
                    form.setFieldValue('login', selectedUser.login);
                    form.submitForm().then(() => {
                        //ignore
                    });
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFirstRender, i18n.language]);

    return (
        <motion.div key={'login-form-content'}
                    className={'pd-10'}
                    initial={{opacity: 0.5}}
                    animate={{opacity: 1}}
                    transition={{duration: 1}}
                    exit={{opacity: 0.5}}>
            <div style={{marginBottom: '10px'}}>

                <ServerErrorLabel entity={'login'}
                                  error={error}
                                  onErrorCleared={() => setError(undefined)}/>
            </div>
            <form onSubmit={form.handleSubmit}>
                <div className={cl.relative}>
                    <LoginInput id="login"
                                icon={faUser}
                                name="login"
                                autoComplete="login"
                                type='text'
                                disabled={form.isSubmitting || selectedUser !== undefined}
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                    if (ev && ev.target && ev.target.value) {
                                        form.setFieldValue('login', ev.target.value.toUpperCase());
                                    } else {
                                        form.setFieldValue('login', '');
                                    }
                                }}
                                value={form.values.login}
                                error={form.touched.login && form.errors.login ? form.errors.login : undefined}
                                placeholder={t("login.labels.login") ?? ''}/>
                    {!form.isSubmitting && form.values.login && selectedUser !== undefined
                        ? <FontAwesomeIcon icon={faTimes}
                                           className={cl.clearButton}
                                           onClick={() => {
                                               form.setFieldValue('login', '');
                                               form.setFieldValue('password', '');
                                               clearSelectedUser();
                                           }}/>
                        : null
                    }
                </div>
                <div>
                    <LoginInput icon={faLock}
                                type='password'
                                placeholder={t("login.labels.password") ?? ''}
                                id="password"
                                name="password"
                                autoComplete="current-password"
                                disabled={form.isSubmitting}
                                onChange={form.handleChange}
                                value={form.values.password}
                                error={form.touched.password && form.errors.password ? form.errors.password : undefined}/>
                </div>
                <div className={cl.buttonContainer}>
                    <Button buttonType={ButtonType.Primary}
                            type='submit'
                            disabled={form.isSubmitting}
                            isLoading={form.isSubmitting}
                            style={{width: '70%'}}>
                        {t("shared.btn.login")}
                    </Button>
                </div>
            </form>

            <hr className={cl.separator}/>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '0 10px'
                }}>
                <button className={cl.linkButton}
                        disabled={form.isSubmitting}
                        onClick={() => {
                            if (form.isSubmitting) {
                                return;
                            }

                            navigate('/registration');
                        }}>
                    {t("login.btn.register")}
                </button>
                <button className={cl.linkButton}
                        disabled={form.isSubmitting}
                        onClick={() => {
                            if (form.isSubmitting) {
                                return;
                            }

                            navigate('/restore/password');
                        }}>
                    {t("login.btn.forgot")}
                </button>
            </div>


        </motion.div>
    )
};

export default LoginFormContent;
