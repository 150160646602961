import React from 'react';
import cl from './ProgressBarItemDataSection.module.css';
import {getEventLabel, getStopPointIcon} from "../../../../../../../../helpers/shipment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import {ExtendedEvent, ExtendedShipmentEvent} from "../../../ShipmentsEventList";
import Icon from "../../../../../../../../assets/icon/Icon";

type ProgressBarItemDataSectionProps = {
    shipmentEvent: ExtendedShipmentEvent;
    event: ExtendedEvent;
    change: (event: ExtendedEvent) => void;
    disabled: boolean;
}

const ProgressBarItemDataSection: React.FC<ProgressBarItemDataSectionProps> = ({
                                                                                   shipmentEvent,
                                                                                   event,
                                                                                   change,
                                                                                   disabled
                                                                               }) => {
    const {t} = useTranslation();
    let icon = getStopPointIcon(shipmentEvent.type ?? '');
    let passed = shipmentEvent.factDate !== null && shipmentEvent.factDate !== undefined;

    if (icon === null) {
        return null;
    }

    const changeEditMode = (val: boolean) => {
        if (!event.shipmentEvents || disabled) {
            return;
        }

        change({
            ...event,
            shipmentEvents: [
                ...event.shipmentEvents.filter(e => e.uuid !== shipmentEvent.uuid),
                {
                    ...shipmentEvent,
                    isEditMode: val
                }
            ]
        });
    }

    const changeShowReasonCode = (val: boolean) => {
        if (!event.shipmentEvents || disabled) {
            return;
        }

        change({
            ...event,
            shipmentEvents: [
                ...event.shipmentEvents.filter(e => e.uuid !== shipmentEvent.uuid),
                {
                    ...shipmentEvent,
                    showReasonCode: val
                }
            ]
        });
    }

    return (
        <div className={cl.container}>
            <div className={cl.label}>
                <FontAwesomeIcon icon={icon}
                                 className={cl.typeIcon}
                                 data-sp-type={shipmentEvent.type}/>
                <label className={`view-label ${passed ? cl.passed : ''}`}>
                    {`${t(getEventLabel(shipmentEvent.status ?? '', shipmentEvent.type ?? '', null))} ${shipmentEvent.info ?? ''}`}
                </label>
            </div>
            <div className={cl.address}>
                {shipmentEvent.addressShort}
            </div>
            <div className={cl.dates}>
                {shipmentEvent.factDate !== null && shipmentEvent.factDate !== undefined
                    ? <>
                        {shipmentEvent.date !== null && shipmentEvent.date !== undefined &&
                            <>
                                    <span className={cl.date}>
                                        {moment(shipmentEvent.date).format('DD.MM.YY HH:mm')}
                                    </span>
                                {!disabled && !event.isLocked && shipmentEvent.isEditable && shipmentEvent.isEditMode
                                    ? <FontAwesomeIcon icon={faMinus}
                                                       onClick={() => changeEditMode(!shipmentEvent.isEditMode)}
                                                       className={cl.ban}/>
                                    : null
                                }
                                {!disabled && shipmentEvent.isEditMode &&
                                    <Icon icon={"faMessage"}
                                          title={t("shared.btn.show-reason-code")}
                                          onClick={() => changeShowReasonCode(!shipmentEvent.showReasonCode)}
                                          className={cl.comment}/>
                                }
                                <br/>
                            </>
                        }
                        {!shipmentEvent.isEditMode &&
                            <span className={cl.factDate}>
                                    {moment(shipmentEvent.factDate).format('DD.MM.YY HH:mm')}
                                </span>
                        }
                        {!disabled && !event.isLocked && shipmentEvent.isEditable && !shipmentEvent.isEditMode
                            ? <Icon icon={"faPen"}
                                    title={t("shared.btn.edit-event")}
                                    onClick={() => changeEditMode(!shipmentEvent.isEditMode)}
                                    className={cl.pen}/>
                            : null
                        }
                    </>
                    : <>
                        {shipmentEvent.date !== null && shipmentEvent.date !== undefined &&
                            <>
                                    <span className={cl.date}>
                                        {moment(shipmentEvent.date).format('DD.MM.YY HH:mm')}
                                    </span>
                                {!disabled && !event.isLocked && shipmentEvent.isEditable && shipmentEvent.isEditMode
                                    ? <FontAwesomeIcon icon={faMinus}
                                                       onClick={() => changeEditMode(!shipmentEvent.isEditMode)}
                                                       className={cl.ban}/>
                                    : null
                                }
                                {!disabled && shipmentEvent.isEditMode &&
                                    <Icon icon={"faMessage"}
                                          title={t("shared.btn.show-reason-code")}
                                          onClick={() => changeShowReasonCode(!shipmentEvent.showReasonCode)}
                                          className={cl.comment}/>
                                }
                                <br/>
                            </>
                        }
                        {!disabled && !event.isLocked && shipmentEvent.isEditable && !shipmentEvent.isEditMode && shipmentEvent.showPlus
                            ? <Icon icon={"faPlus"}
                                    onClick={() => changeEditMode(!shipmentEvent.isEditMode)}
                                    title={t("shared.btn.add-event")}
                                    className={cl.plus}/>
                            : null
                        }
                    </>
                }
            </div>
        </div>
    );
};

export default ProgressBarItemDataSection;
