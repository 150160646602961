import React from 'react';
import {createPortal} from "react-dom";
import cl from './LoadingScreen.module.css';

const LoadingScreen: React.FC = () => {
    return (createPortal(
            <div className={cl.container}>
                <div className="loading-line"></div>
                <div className="loading-container">
                    <div>
                        <div className="loader"></div>
                    </div>
                </div>
            </div>, document.body)
    );
};

export default LoadingScreen;