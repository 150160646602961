import React from 'react';
import {IChangeDateGetAction} from "../../../../../../app/interfaces/offer/IChangeDateGetAction";
import {IChangeDatePostAction} from "../../../../../../app/interfaces/offer/IChangeDatePostAction";
import {IError} from "../../../../../../app/interfaces/response/IResponse";
import SelectedOffers from "../../../form/components/SelectedOffers/SelectedOffers";
import {useTranslation} from "react-i18next";
import {FormikHelpers, useFormik} from "formik";
import Alert from "../../../../../../components/ui/Alert/Alert";
import {BLErrorCode} from "../../../../../../app/enums/BLErrorCode";
import {RadioButton} from "@progress/kendo-react-inputs";
import {Tooltip} from "react-tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import cl from './ChangePlannedDateForm.module.css';
import _ from 'lodash'
import {getStopPointIcon, getStopPointTypeResource} from "../../../../../../helpers/shipment";
import moment from "moment/moment";
import Textarea from "../../../../../../components/ui/Textarea/Textarea";
import ReactDatePicker from "../../../../../../components/ui/ReactDatePicker/ReactDatePicker/ReactDatePicker";

type ChangePlannedDateFormProps = {
    model: IChangeDateGetAction;
    disabled: boolean;
    save: (model: IChangeDatePostAction) => Promise<void>;
    errors: Array<IError>;
}

const ChangePlannedDateForm: React.FC<ChangePlannedDateFormProps> = ({
                                                                         model,
                                                                         save,
                                                                         errors,
                                                                         disabled
                                                                     }) => {
    const {t} = useTranslation();

    const form = useFormik<IChangeDatePostAction>({
        initialValues: {
            changeDateOrderReleaseGid: model.changeDateOrderReleaseGid,
            changeDateTarget: model.changeDateTarget,
            approvedByCustomer: false,
            notApprovedOrTechnical: false,
            dtItems: [],
            items: model.dtItems.map(item => {
                return {
                    ...item
                };
            })
        },
        onSubmit: async (values: IChangeDatePostAction, actions: FormikHelpers<IChangeDatePostAction>) => {
            actions.setSubmitting(true);

            values.dtItems = [];
            values.items.forEach(item => {
                values.dtItems.push({
                    position: item.position,
                    changeDateStart: item.changeDateStart,
                    changeDateFinish: item.changeDateFinish,
                    changeDateItemOrderReleaseGid: item.changeDateItemOrderReleaseGid,
                    reason: item.reason,
                    changeDateItemOrderLegGid: item.changeDateItemOrderLegGid
                });
            });

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='offer-change-planned-date-form'
              onSubmit={form.handleSubmit}>
            {model.items.length > 1 && <SelectedOffers ids={model.items.map(item => item.id)}/>}
            <div className={'pd-10'}>
                <div className={'mgb-10'}>
                    {errors.find(e => e.errorCode === BLErrorCode.PlannedModeNotChoose) &&
                        <Alert type={'Error'} style={{fontSize: '12px', fontWeight: 'bold', margin: '0 0 10px 0'}}>
                            {t("offer.errors.change-planned-date-mode-is-required")}
                        </Alert>
                    }

                    <div className={'radio-buttons-container'}>
                        <div style={{display: 'inline'}}>
                            <RadioButton name="mode"
                                         value="1"
                                         size={'small'}
                                         checked={form.values.approvedByCustomer}
                                         label={t("offer.form.change-date-approved-by-customer-mode")}
                                         onChange={() => {
                                             if (!form.values.approvedByCustomer) {
                                                 form.setFieldValue('approvedByCustomer', true);
                                             }
                                             form.setFieldValue('notApprovedOrTechnical', false);
                                         }}
                            />
                            <Tooltip id={'approved-by-customer-mode'}
                                     style={{maxWidth: '250px', zIndex: '1002', fontSize: '11px'}}
                                     place={'right'}/>
                            <FontAwesomeIcon data-tooltip-id={'approved-by-customer-mode'}
                                             data-tooltip-content={t("offer.form.change-date-approved-by-customer-mode-tooltip")}
                                             icon={faCircleInfo}
                                             className={cl.tooltip}/>
                        </div>
                        <div style={{display: 'inline', marginLeft: '25px'}}>
                            <RadioButton name="mode"
                                         value="0"
                                         size={'small'}
                                         checked={form.values.notApprovedOrTechnical}
                                         label={t("offer.form.change-date-not-approved-or-technical-mode")}
                                         onChange={() => {
                                             if (!form.values.notApprovedOrTechnical) {
                                                 form.setFieldValue('notApprovedOrTechnical', true);
                                             }
                                             form.setFieldValue('approvedByCustomer', false);
                                         }}
                            />
                            <Tooltip id={'technical-mode'}
                                     style={{maxWidth: '250px', zIndex: '1002', fontSize: '11px'}}
                                     place={'right'}/>
                            <FontAwesomeIcon data-tooltip-id={'technical-mode'}
                                             data-tooltip-content={t("offer.form.change-date-not-approved-or-technical-mode-tooltip")}
                                             icon={faCircleInfo}
                                             className={cl.tooltip}/>
                        </div>
                    </div>
                </div>
                <div className={cl.spList}>
                    {_.chain(form.values.items)
                        .groupBy(e => e.changeDateItemOrderLegGid)
                        .map((items, leg) => {
                            return {items: items, leg: leg};
                        })
                        .value()
                        .map(groupedItem => {
                            return (
                                <div key={groupedItem.leg} className={`${cl.container}`}>
                                    <label className={cl.leg}>{groupedItem.leg.replace('AAG.', '')}</label>
                                    {groupedItem.items.map((sp, index) => {
                                        let icon = getStopPointIcon(sp.spType ?? '');

                                        if (icon === null) {
                                            return null;
                                        }

                                        let errs: Array<string> = [];

                                        errors.filter(e => e.description !== null && Number(e.description.split('/')[0]) === sp.position &&
                                            e.description.split('/')[1] === sp.changeDateItemOrderLegGid)
                                            .forEach(err => {
                                                switch (err.errorCode) {
                                                    case BLErrorCode.LateDeliveryDateMustBeLaterLastDate:
                                                        errs.push(t("shared.errors.date-field-must-be-later-than-date-for-previous-track-section")
                                                            .replace('{0}', t("offer.form.late-delivery-date")));
                                                        break;
                                                    case BLErrorCode.LatePickupDateMustBeLaterLastDate:
                                                        errs.push(t("shared.errors.date-field-must-be-later-than-date-for-previous-track-section")
                                                            .replace('{0}', t("offer.form.late-pickup-date")));
                                                        break;
                                                    case BLErrorCode.EarlyDeliveryDateMustBeLaterLastDate:
                                                        errs.push(t("shared.errors.date-field-must-be-later-than-date-for-previous-track-section")
                                                            .replace('{0}', t("offer.form.early-delivery-date")));
                                                        break;
                                                    case BLErrorCode.EarlyPickupDateMustBeLaterLastDate:
                                                        errs.push(t("shared.errors.date-field-must-be-later-than-date-for-previous-track-section")
                                                            .replace('{0}', t("offer.form.early-pickup-date")));
                                                        break;
                                                    case BLErrorCode.EarlyPickupDateRequiredBecauseLateDeliveryDateFilled:
                                                        errs.push(t("shared.errors.field-one-required-because-field-two-is-filled")
                                                            .replace('{0}', t("offer.form.early-pickup-date"))
                                                            .replace('{1}', t("offer.form.late-delivery-date")));
                                                        break;
                                                    case BLErrorCode.LateDeliveryDateEearlierThanEarlyPickupDate:
                                                        errs.push(t("shared.errors.date-field-one-cannot-be-earlier-than-date-two")
                                                            .replace('{0}', t("offer.form.late-delivery-date"))
                                                            .replace('{1}', t("offer.form.early-pickup-date")));
                                                        break;
                                                    case BLErrorCode.LateDeliveryDateEearlierThanEarlyDeliveryDate:
                                                        errs.push(t("shared.errors.date-field-one-cannot-be-earlier-than-date-two")
                                                            .replace('{0}', t("offer.form.late-delivery-date"))
                                                            .replace('{1}', t("offer.form.early-delivery-date")));
                                                        break;
                                                    case BLErrorCode.LatePickupDateEearlierThanEarlyPickupDate:
                                                        errs.push(t("shared.errors.date-field-one-cannot-be-earlier-than-date-two")
                                                            .replace('{0}', t("offer.form.late-pickup-date"))
                                                            .replace('{1}', t("offer.form.early-pickup-date")));
                                                        break;
                                                    case BLErrorCode.LateDeliveryDateRequired:
                                                        errs.push(t("shared.errors.required-error").replace('{0}', t("offer.form.late-delivery-date")));
                                                        break;
                                                    case BLErrorCode.EarlyPickupDateRequired:
                                                        errs.push(t("shared.errors.required-error").replace('{0}', t("offer.form.early-pickup-date")));
                                                        break;
                                                }
                                            });

                                        return (
                                            <div key={sp.position} className={'mgt-5'}>
                                                <div className={cl.spLabel}>
                                                    <FontAwesomeIcon icon={icon}
                                                                     className={cl.icon}
                                                                     data-sp-type={sp.spType?.toUpperCase()}/>
                                                    <label className={'view-label'}>
                                                        {`${t(getStopPointTypeResource(sp.spType ?? ''))} ${sp.spInfo ?? ''}`}
                                                    </label>
                                                    <div className={`${cl.address}`}>
                                                        {sp.address}
                                                    </div>
                                                    {sp.isLocked && sp.lockNote && sp.lockNote !== ''
                                                        ? <>
                                                            <Tooltip id={`date-start-${sp.position}-${index}`}
                                                                     style={{
                                                                         maxWidth: '250px',
                                                                         zIndex: '1002',
                                                                         fontSize: '11px'
                                                                     }}
                                                                     place={'right'}/>
                                                            <FontAwesomeIcon icon={faCircleInfo}
                                                                             data-tooltip-id={`date-start-${sp.position}-${index}`}
                                                                             data-tooltip-content={t(`${sp.lockNote}`)}
                                                                             style={{margin: '0 0 2px 5px'}}
                                                                             className={cl.tooltip}/>
                                                        </>
                                                        : null
                                                    }
                                                </div>

                                                {errs.length > 0 && errs.map(e => {
                                                    return (
                                                        <div className={'error-label'} key={e}>
                                                            {e}
                                                        </div>
                                                    );
                                                })}

                                                <div className={`mgb-10 picker ${cl.dateTimePickerContainer}`}>
                                                    <div style={{width: '50%'}}>
                                                        <ReactDatePicker disabled={disabled || sp.isLocked}
                                                                         format={'dd.MM.yyyy HH:mm'}
                                                                         size={'big'}
                                                                         value={sp.changeDateStart
                                                                             ? moment(sp.changeDateStart, 'DD.MM.YYYY HH:mm').toDate()
                                                                             : null}
                                                                         onChange={(val) => {
                                                                             let elem = form.values.items.find(e => e.position === sp.position && e.changeDateItemOrderLegGid === sp.changeDateItemOrderLegGid);
                                                                             if (elem) {
                                                                                 let index = form.values.items.indexOf(elem);

                                                                                 if (index > -1) {
                                                                                     form.setFieldValue(`items[${index}].changeDateStart`, val ? moment(val).format('DD.MM.YYYY HH:mm') : null);
                                                                                 }
                                                                             }
                                                                         }}
                                                                         placeholder={t("shared.labels.date")}
                                                        />
                                                    </div>

                                                    <div style={{marginTop: '11px'}}>
                                                        &ndash;
                                                    </div>

                                                    <div style={{width: '50%'}}>
                                                        <ReactDatePicker disabled={disabled || sp.isLocked}
                                                                         format={'dd.MM.yyyy HH:mm'}
                                                                         size={'big'}
                                                                         value={sp.changeDateFinish ? moment(sp.changeDateFinish, 'DD.MM.YYYY HH:mm').toDate() : null}
                                                                         onChange={(val) => {
                                                                             let elem = form.values.items.find(e => e.position === sp.position && e.changeDateItemOrderLegGid === sp.changeDateItemOrderLegGid);
                                                                             if (elem) {
                                                                                 let index = form.values.items.indexOf(elem);

                                                                                 if (index > -1) {
                                                                                     form.setFieldValue(`items[${index}].changeDateFinish`, val ? moment(val).format('DD.MM.YYYY HH:mm') : null);
                                                                                 }
                                                                             }
                                                                         }}
                                                                         placeholder={t("shared.labels.date")}
                                                        />
                                                    </div>
                                                </div>
                                                {/*<div className={`mgb-10 picker ${cl.dateTimePickerContainer}`}>
                                                    <div style={{width: '50%'}}>
                                                        <DateTimePicker format={'dd.MM.yyyy HH:mm'}
                                                                        dateInput={DateInput}
                                                                        disabled={disabled || sp.isLocked}
                                                                        formatPlaceholder={{
                                                                            year: "yyyy",
                                                                            month: "MM",
                                                                            day: "dd",
                                                                            hour: "HH",
                                                                            minute: "mm"
                                                                        }}
                                                                        value={sp.changeDateStart ? moment(sp.changeDateStart, 'DD.MM.YYYY HH:mm').toDate() : null}
                                                                        onChange={(ev) => {
                                                                            let elem = form.values.items.find(e => e.position === sp.position && e.changeDateItemOrderLegGid === sp.changeDateItemOrderLegGid);
                                                                            if (elem) {
                                                                                let index = form.values.items.indexOf(elem);

                                                                                if (index > -1) {
                                                                                    form.setFieldValue(`items[${index}].changeDateStart`, ev.target.value ? moment(ev.target.value).format('DD.MM.YYYY HH:mm') : null);
                                                                                }
                                                                            }
                                                                        }}
                                                                        rounded={'small'}
                                                                        fillMode={'outline'}
                                                                        placeholder={t("shared.labels.date")}
                                                                        adaptive={true}/>
                                                    </div>
                                                    <div style={{marginTop: '5px'}}>
                                                        &ndash;
                                                    </div>
                                                    <div style={{width: '50%'}}>
                                                        <DateTimePicker format={'dd.MM.yyyy HH:mm'}
                                                                        dateInput={DateInput}
                                                                        disabled={disabled || sp.isLocked}
                                                                        formatPlaceholder={{
                                                                            year: "yyyy",
                                                                            month: "MM",
                                                                            day: "dd",
                                                                            hour: "HH",
                                                                            minute: "mm"
                                                                        }}
                                                                        value={sp.changeDateFinish ? moment(sp.changeDateFinish, 'DD.MM.YYYY HH:mm').toDate() : null}
                                                                        onChange={(ev) => {
                                                                            let elem = form.values.items.find(e => e.position === sp.position && e.changeDateItemOrderLegGid === sp.changeDateItemOrderLegGid);
                                                                            if (elem) {
                                                                                let index = form.values.items.indexOf(elem);

                                                                                if (index > -1) {
                                                                                    form.setFieldValue(`items[${index}].changeDateFinish`, ev.target.value ? moment(ev.target.value).format('DD.MM.YYYY HH:mm') : null);
                                                                                }
                                                                            }
                                                                        }}
                                                                        rounded={'small'}
                                                                        fillMode={'outline'}
                                                                        placeholder={t("shared.labels.date")}
                                                                        adaptive={true}/>
                                                    </div>
                                                </div>*/}
                                                {model.showReasonFields &&
                                                    <div>
                                                        <Textarea disabled={disabled || sp.isLocked}
                                                                  style={{
                                                                      minHeight: '70px',
                                                                      resize: 'vertical',
                                                                      maxHeight: '150px'
                                                                  }}
                                                                  value={sp.reason ?? ''}
                                                                  placeholder={t("shipment.form.change-date-reason-code")}
                                                                  onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                                      form.setFieldValue(`items[${index}].reason`, ev.target.value);
                                                                  }}/>
                                                    </div>
                                                }
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </form>
    );
};

export default ChangePlannedDateForm;
