import React, {useEffect, useRef, useState} from 'react';
import {ISendContractGetAction} from "../../../../../../../../app/interfaces/offer/ISendContractGetAction";
import {GridConfig} from "../../../../../../../../components/ui/Grid/Telerik/types/GridConfig";
import GridDefaultHeaderTemplate
    from "../../../../../../../../components/ui/Grid/Telerik/components/GridDefaultHeaderTemplate/GridDefaultHeaderTemplate";
import {Query} from "../../../../../../../../app/types/Query";
import Grid from "../../../../../../../../components/ui/Grid/Telerik/Grid";
import {join} from "lodash";
import {GRID_SELECTED_FIELD} from "../../../../../../../../components/ui/Grid/Telerik/utils/data";
import cl
    from "../../../../../../../../components/ui/Grid/Telerik/components/GridDefaultCellTemplate/GridDefaultCellTemplate.module.css";
import {IUser} from "../../../../../../../../app/interfaces/user/IUser";

type SendContractServiceProviderGridSelectorProps = {
    model: ISendContractGetAction;
    onChange: (serviceProviderId: string | null) => void;
    user?: IUser;
};

const CellTemplate = (props: any) => {
    let noLimit = props.dataItem === null || props.dataItem.available === null || props.dataItem.available <= 0;

    return (
        <td {...props.tdProps}
            colSpan={1}
            style={noLimit ? {
                backgroundColor: 'lightgrey',
                color: 'white'
            } : undefined}>
                <span className={cl.cell}>
                    {props.children}
                </span>
        </td>
    );
};

const SupplierTemplate = (props: any) => {
    let noLimit = props.dataItem === null || props.dataItem.available === null || props.dataItem.available <= 0;
    let isDisabled = props.dataItem !== null && props.dataItem.isDisabled !== null && props.dataItem.isDisabled === true;

    let style: React.CSSProperties | undefined = undefined;

    if (noLimit) {
        style = {
            backgroundColor: 'lightgrey',
            color: 'white'
        };
    }

    if (isDisabled) {
        style = {
            ...style,
            borderLeft: '3px solid red'
        };
    }

    return (
        <td {...props.tdProps}
            colSpan={1}
            style={style}>
                <span className={cl.cell}>
                    {props.children}
                </span>
        </td>
    );
}

const config: GridConfig = {
    sortBy: [],
    groupBy: [],
    pageSize: 100,
    headerTemplate: GridDefaultHeaderTemplate,
    cellTemplate: CellTemplate,
    columns: [
        {
            visible: true,
            field: GRID_SELECTED_FIELD,
            title: ' ',
            width: 50,
            locked: true,
            constantlyLocked: true,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            order: 0
        },
        {
            visible: true,
            field: 'spName',
            title: 'offer.form.supplier',
            width: 210,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            template: SupplierTemplate,
            order: 1
        },
        {
            visible: true,
            field: 'status',
            title: ' ',
            width: 120,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            order: 2
        },
        {
            visible: true,
            field: 'contractType',
            title: 'offer.grid.service-provider-contract-type',
            width: 120,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            order: 3
        },
        {
            visible: true,
            field: 'weeklyLimit',
            title: 'offer.grid.service-provider-weekly-limit',
            width: 120,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            order: 4
        },
        {
            visible: true,
            field: 'notConfirmed',
            title: 'offer.grid.service-provider-not-confirmed',
            width: 171,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            order: 5
        },
        {
            visible: true,
            field: 'rate',
            title: 'offer.grid.service-provider-rate',
            width: 150,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            order: 6
        },
        {
            visible: true,
            field: 'currency',
            title: 'offer.grid.service-provider-currency',
            width: 150,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            order: 7
        },
        {
            visible: true,
            field: 'eq',
            title: 'offer.grid.service-provider-equipment',
            width: 150,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            order: 8
        },
        {
            visible: true,
            field: 'confirmed',
            title: 'offer.grid.service-provider-confirmed',
            width: 150,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            order: 9
        },
        {
            visible: true,
            field: 'available',
            title: 'offer.grid.service-provider-available',
            width: 150,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            order: 10
        },
        {
            visible: true,
            field: 'tillDate',
            title: 'offer.grid.service-provider-till-date',
            width: 150,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            order: 11
        }
    ]
};

const SendContractServiceProviderGridSelector: React.FC<SendContractServiceProviderGridSelectorProps> = ({
                                                                                                             model,
                                                                                                             onChange,
                                                                                                             user
                                                                                                         }) => {
    const [query, setQuery] = useState<Query | null>(null);
    const isFirstSelectionDone = useRef<boolean>(false);

    useEffect(() => {
        let q = new Query().addParam('orderReleaseGid', model.orderReleaseGid);

        setQuery(q);
    }, []);

    if (!query) {
        return null;
    }

    return (
        <Grid id={'send-contract-service-provider-grid'}
              config={config}
              url={user
                  ? `/api/${process.env.REACT_APP_API_VERSION}/offer/getContractedServiceProviders`
                  : `/api/${process.env.REACT_APP_API_VERSION}/offer/getContractedServiceProvidersAnonymous`}
              query={query}

              onSelect={(rows) => {
                  if (!isFirstSelectionDone.current) {
                      isFirstSelectionDone.current = true;

                      return;
                  }

                  let disabledItems: Array<any> = [];

                  rows.forEach(item => {
                      if (item.isDisabled !== undefined && item.isDisabled !== null && item.isDisabled === true) {
                          disabledItems.push(item);
                      }
                  });

                  disabledItems.forEach(item => {
                      document.dispatchEvent(new CustomEvent('onGridMessage', {
                          detail: {
                              action: 'unSelectItemByKey',
                              gridId: 'send-contract-service-provider-grid',
                              value: item.id
                          }
                      }));
                  });

                  if ((rows.length === 0 && disabledItems.length === 0) || rows.length !== disabledItems.length) {
                      onChange(rows.length > 0
                          ? join(rows.map(item => item.id), ';')
                          : null);
                  }
              }}

              width={'100%'}
              height={'calc(100% - 13px)'}
              allowGrouping={false}
              allowReordering={false}
              allowPaging={false}
              allowResizing={false}
              allowSelection={true}
              allowSorting={false}
              selectionMode={'multiple'}
        />
    );
};

export default SendContractServiceProviderGridSelector;