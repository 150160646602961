import React from 'react';
import {createPortal} from "react-dom";
import cl from './AccessSettingsPanel.module.css';
import {useTranslation} from "react-i18next";
import {IUserAccessSettings} from "../../../../../app/interfaces/user/IUserAccessSettings";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {setAccessSettings} from "../../../../../features/filter/filterSlice";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {selectCurrentUser} from "../../../../../features/authentication/authenticationSliceSelectors";
import {useUserApi} from "../../../../../app/api/user";
import {useNavigate} from "react-router-dom";
import {selectAccessSettings, selectIsAccessSettingsExists} from "../../../../../features/filter/filterSliceSelectors";
import {CustomScroll} from "react-custom-scroll";
import Icon from "../../../../../assets/icon/Icon";
import Button, {ButtonType} from "../../../../ui/Button/Button";
import AccessSettings from "../AccessSettings";

type AccessSettingsPanelProps = {
    isOpened: boolean;
    setIsOpened: (val: boolean) => void;
};

const AccessSettingsPanel: React.FC<AccessSettingsPanelProps> = ({
                                                                     isOpened,
                                                                     setIsOpened
                                                                 }) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isAccessSettingsExists = useAppSelector(selectIsAccessSettingsExists);
    const accessSettings = useAppSelector(selectAccessSettings);
    const user = useAppSelector(selectCurrentUser);

    const {
        saveAccessSettings: {
            mutation,
            isLoading
        }
    } = useUserApi()

    const save = async (settings: IUserAccessSettings) => {
        if (!user) {
            return;
        }

        try {
            const response = await mutation(settings);

            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                let needReload = user.userAccessSettings.serviceProviderId !== settings.serviceProviderId;

                if (needReload) {
                    navigate(0);
                } else {
                    dispatch(setAccessSettings(settings));

                    document.dispatchEvent(new CustomEvent('onGridMessage', {
                        detail: {
                            action: 'refresh'
                        }
                    }));

                    document.dispatchEvent(new CustomEvent('onAccessSettingsUpdate', {
                        detail: {
                            data: settings
                        }
                    }));
                }
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error('Unable to clear access settings');
            }
        }
    };

    return (
        <>
            {createPortal(
                <div className={`${cl.container} ${isOpened ? cl.opened : ''}`}>
                    <header className={cl.header}>
                        <div>
                            {t("shared.labels.access-settings")}
                        </div>
                        <div>
                            <button className={'default-form-close-btn'}
                                    title={t("shared.btn.close")}
                                    onClick={() => setIsOpened(false)}>
                                <Icon icon={"faXMark"}/>
                            </button>
                        </div>
                    </header>

                    <div className={cl.content}>
                        <CustomScroll heightRelativeToParent={'100%'}>
                            <AccessSettings disabled={isLoading}/>

                            <div style={{height: '40px'}}></div>

                            <div className={cl.btns}>
                                <Button buttonType={ButtonType.Transparent}
                                        style={{width: '30%'}}
                                        disabled={!isAccessSettingsExists || isLoading}
                                        onClick={async () => {
                                            if (!accessSettings) {
                                                return;
                                            }

                                            await save({
                                                ...accessSettings,
                                                serviceProviderName: null,
                                                serviceProviderId: null,
                                                forwarders: [],
                                                transportLinesSource: [],
                                                branches: [],
                                                departments: [],
                                                transportLinesDestination: []
                                            } as IUserAccessSettings);
                                        }}>
                                    {t("shared.btn.reset")}
                                </Button>
                                <Button buttonType={ButtonType.Secondary}
                                        style={{width: '70%'}}
                                        disabled={isLoading}
                                        onClick={async () => {
                                            if (!accessSettings) {
                                                return;
                                            }

                                            await save(accessSettings);
                                        }}>
                                    {t("shared.btn.apply")}
                                </Button>
                            </div>
                        </CustomScroll>
                    </div>
                </div>,
                document.body)}
        </>
    );
};

export default AccessSettingsPanel;