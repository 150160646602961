import React, {useEffect, useRef} from 'react';
import {createRoot, Root} from "react-dom/client";

type LatLngLiteral = google.maps.LatLngLiteral;
type Map = google.maps.Map;
type AdvancedMarkerElement = google.maps.marker.AdvancedMarkerElement;

export type AdvancedMarkerProps = {
    map: Map;
    position: LatLngLiteral;
    children: React.ReactNode;
    options?: google.maps.marker.AdvancedMarkerElementOptions;
}

const AdvancedMarker: React.FC<AdvancedMarkerProps> = ({
                                                           map,
                                                           position,
                                                           children,
                                                           options
                                                       }) => {
    const markerRef = useRef<AdvancedMarkerElement>();
    const rootRef = useRef<Root>();
    useEffect(() => {
        if (!rootRef.current) {
            const container = document.createElement("div");
            rootRef.current = createRoot(container);
            markerRef.current = new google.maps.marker.AdvancedMarkerElement({
                ...options,
                position,
                content: container,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!markerRef.current || !rootRef.current) return;
        rootRef.current.render(children);
        markerRef.current.position = position;
        markerRef.current.map = map;
    }, [map, position, children]);

    return <></>;
};

export default AdvancedMarker;