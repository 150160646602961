import React, {useEffect, useState} from 'react';
import ListBox from "../../../../ui/ListBox/ListBox";
import {IUserAccessSettings} from "../../../../../app/interfaces/user/IUserAccessSettings";
import {SelectModel} from "../../../../../app/types/SelectModel";
import {useAppDispatch} from "../../../../../app/store";
import {useDataHelperApi} from "../../../../../app/api/dataHelper";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import TagContainer from "../../../../ui/Tag/TagContainer/TagContainer";
import {TagItem, TagItemBackgroundColor} from "../../../../ui/Tag/Tag";
import {setAccessSettings} from "../../../../../features/filter/filterSlice";

type AccessSettingsServiceProviderFilterProps = {
    disabled: boolean;
    settings: IUserAccessSettings;
    isExpanded: boolean;
}

const AccessSettingsServiceProviderFilter: React.FC<AccessSettingsServiceProviderFilterProps> = ({
                                                                                                     disabled,
                                                                                                     settings,
                                                                                                     isExpanded
                                                                                                 }) => {
    const {t} = useTranslation();
    const [data, setData] = useState<Array<SelectModel>>([]);
    const [filter, setFilter] = useState<string>('');
    const dispatch = useAppDispatch();

    const {
        getAccessFilterServiceProviders: {
            query,
            isLoading
        }
    } = useDataHelperApi();

    useEffect(() => {
        if (filter === '') {
            setData(
                settings.serviceProviderId !== null && settings.serviceProviderName !== null
                    ? [{
                        text: settings.serviceProviderName,
                        value: settings.serviceProviderId
                    }]
                    : []
            );

            return;
        }

        if (filter.length < 3) {
            return;
        }

        (async () => {
            try {
                const response = await query(filter);

                if (response?.data?.resultCode === ResponseResultCode.Ok && response?.data.data) {
                    setData(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error('Unable to load forwarders list: ' + e.message);
                }
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filter]);

    return (
        <div style={{margin: '10px 0'}}>
            {isExpanded &&
                <div style={{marginBottom: '5px'}}>
                    <TagContainer id={'access-settings-service-provider-filter-tags'}
                                  width={'100%'}
                                  height={'100px'}
                                  items={settings.serviceProviderId !== null && settings.serviceProviderId !== '' &&
                                  settings.serviceProviderName !== null && settings.serviceProviderName !== ''
                                      ? [{
                                          id: `service-provider-tag-${settings.serviceProviderId}`,
                                          text: settings.serviceProviderName,
                                          data: {
                                              id: settings.serviceProviderId,
                                              name: settings.serviceProviderName
                                          },
                                          showClearIcon: true,
                                          backgroundColor: TagItemBackgroundColor.LightGray,
                                          rounded: true
                                      } as TagItem]
                                      : []}
                                  onClear={() => {
                                      dispatch(setAccessSettings({
                                          ...settings,
                                          serviceProviderId: null,
                                          serviceProviderName: null
                                      }));
                                  }}/>
                </div>
            }
            <ListBox id={'access-settings-service-provider-filter'}
                     selected={settings.serviceProviderId !== null && settings.serviceProviderName !== null
                         ? [{
                             text: settings.serviceProviderName,
                             value: settings.serviceProviderId
                         }]
                         : []
                     }
                     disabled={disabled}
                     data={data}
                     isLoading={isLoading}
                     onFilter={(value) => setFilter(value ?? '')}
                     height={250}
                     selection={'Single'}
                     noDataLabel={t("accessSettings.service-provider-no-data-available")}
                     onSelectionChange={(items) => {
                         dispatch(setAccessSettings({
                             ...settings,
                             serviceProviderId: items.length > 0 ? items[0].value : null,
                             serviceProviderName: items.length > 0 ? items[0].text : null
                         }));
                     }}/>
        </div>
    );
};

export default AccessSettingsServiceProviderFilter;