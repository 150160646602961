import React, {useEffect, useRef, useState} from 'react';
import {GridActionRowTagItem} from "../../GridActionRowTagsContainer";
import cl from './GridActionRowTagsContextMenu.module.css';
import Icon from "../../../../../../../../assets/icon/Icon";
import {Query} from "../../../../../../../../app/types/Query";
import useIfFirstRender from "../../../../../../../../hooks/useIsFirstRender/useIfFirstRender";
import apiAxios from "../../../../../../../../app/axios/apiAxios";
import Skeleton from "react-loading-skeleton";

type GridActionRowTagsContextMenuTagProps = {
    tag: GridActionRowTagItem;
};

const GridActionRowTagsContextMenuTag: React.FC<GridActionRowTagsContextMenuTagProps> = ({
                                                                                             tag
                                                                                         }) => {
    const [count, setCount] = useState<number>(0);
    const [countLoading, setCountLoading] = useState<boolean>(false);

    const localQuery = useRef<Query | null>(null);
    const isFirstRender = useIfFirstRender();

    useEffect(() => {
        if (!tag.countUrl || !tag.query || !tag.isTab) {
            return;
        }

        localQuery.current = tag.query;

        (async () => {
            await loadCount();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isFirstRender || !localQuery.current || Query.compare(localQuery.current, tag.query) || !tag.isTab) {
            return;
        }

        localQuery.current = tag.query ?? null;

        (async () => {
            await loadCount();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tag, isFirstRender]);

    const loadCount = async () => {
        try {
            setCountLoading(prev => !prev);

            const response = await apiAxios.get(`${tag.countUrl}?${tag.query}`);

            if (response?.status === 200 && response?.data && response.data.data !== null && response.data.data !== undefined) {
                setCount(response.data.data);
            }
        } catch (e) {
            setCount(0);
            setCountLoading(prev => !prev);
        }

        setCountLoading(prev => !prev);
    }

    return (
        <div className={`${cl.tag} ${tag.isTab && tag.selected ? cl.selected : ''}`}
             data-type={tag.isTab ? 'tab' : 'tag'}
             onClick={tag.onClick}
             id={`context-menu-tag-option-${tag.id}`}>
            <div title={tag.text}>
                {tag.text}
            </div>

            <div onClick={tag.onClear}>
                {tag.isTab && tag.countUrl
                    ? <>
                        {countLoading
                            ? <Skeleton height={'15px'}
                                        width={'20px'}
                                        style={{borderRadius: '2px'}}/>
                            : null
                        }

                        {!countLoading &&
                            <>{count}</>
                        }
                    </>
                    : <>
                        {tag.showClearIcon &&
                            <Icon icon={"faXMark"}/>
                        }
                    </>
                }
            </div>
        </div>
    );
};

export default GridActionRowTagsContextMenuTag;