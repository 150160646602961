import {ResponseResultCode} from "../../enums/ResponseResultCode";
import {BLErrorCode} from "../../enums/BLErrorCode";

export interface IResponse {
    resultCode: ResponseResultCode;
    message?: string;
    field?: string;
    action?: string;
    errors?: Array<IError>;
}

export interface IDataResponse<T> extends IResponse {
    data?: T;
}

export const isResponse = (item: any): item is IResponse => {
    return (item as IResponse).resultCode !== undefined;
}

export interface IError {
    field: string | null;
    errorCode: BLErrorCode;
    description: string | null;
}

export interface ISuccessResponse {
    id: string | null;
}
