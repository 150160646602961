import {IDataResponse, IResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {IUser} from "../../interfaces/user/IUser";
import {AxiosResponse} from "axios";
import {IUserAccessSettings} from "../../interfaces/user/IUserAccessSettings";
import {IUserData} from "../../interfaces/user/IUserData";

export const useChangeLanguage = (): {
    isLoading: boolean,
    cancel: () => void,
    changeLanguage: (params: Partial<IUser>) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, Partial<IUser>>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/user/changeLanguage`
    });

    const changeLanguage = useCallback(async (params: Partial<IUser>) => await execute(params), [execute]);

    return {changeLanguage, isLoading, cancel};
};

export const useSaveAccessSettings = (): {
    isLoading: boolean,
    cancel: () => void,
    saveAccessSettings: (params: Partial<IUserAccessSettings>) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, Partial<IUserAccessSettings>>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/user/saveAccessSettings`
    });

    const saveAccessSettings = useCallback(async (params: Partial<IUserAccessSettings>) => await execute(params), [execute]);

    return {saveAccessSettings, isLoading, cancel};
};

export const useGetForwardersData = (): {
    isLoading: boolean,
    cancel: () => void,
    getForwardersData: (ids: string) => Promise<AxiosResponse<IDataResponse<Array<IUserData>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<IUserData>>>({
        method: 'get'
    });

    const getForwardersData = useCallback(async (ids: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/user/getForwardersData?ids=${ids}`
        });
    }, [execute]);

    return {getForwardersData, isLoading, cancel};
};

export const useGetServiceProvidersData = (): {
    isLoading: boolean,
    cancel: () => void,
    getServiceProvidersData: (ids: string) => Promise<AxiosResponse<IDataResponse<Array<IUserData>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<IUserData>>>({
        method: 'get'
    });

    const getServiceProvidersData = useCallback(async (ids: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/user/getServiceProvidersData?ids=${ids}`
        });
    }, [execute]);

    return {getServiceProvidersData, isLoading, cancel};
};