import {getter, groupBy, GroupDescriptor} from "@progress/kendo-data-query";
import {setExpandedState, setGroupIds} from "@progress/kendo-react-data-tools";

export const GRID_DATA_ITEM_KEY: string = "id";
export const GRID_SELECTED_FIELD: string = "selected";
export const GRID_EMPTY_FIELD: string = "empty";
export const GRID_EDIT_FIELD: string = "inEdit";

export const processData = (data: any[],
                            groupDescription: GroupDescriptor[],
                            selectedState: {
                                [id: string]: boolean | number[];
                            },
                            collapsedGroups: Array<any>
): any[] => {
    if (groupDescription.length > 0) {
        let d = data.map(item => {
            return {
                ...item,
                items: item.items !== undefined && item.items !== null ? item.items.map((c: any) => {
                    return {
                        ...c,
                        [GRID_SELECTED_FIELD]: selectedState[idGetter(c)]
                    }
                }) : []
            };
        });

        if (collapsedGroups.length <= 0) {
            return d;
        } else {
            return setExpandedState({
                data: d,
                collapsedIds: collapsedGroups
            });
        }
    } else {
        return data.map(item => {
            return {
                ...item,
                [GRID_SELECTED_FIELD]: selectedState[idGetter(item)]
            }
        });
    }
}

export const processWithGroups = (data: any[], group: GroupDescriptor[]) => {
    const newDataState = groupBy(data, group);

    setGroupIds({data: newDataState, group: group});

    return newDataState;
};

export const idGetter = getter(GRID_DATA_ITEM_KEY);

export const getSelectedRowsData = (data: any[],
                                    groupDescription: GroupDescriptor[],
                                    selectedState: {
                                        [id: string]: boolean | number[];
                                    }): Array<any> => {
    let result: Array<any> = [];

    let keys = Object.keys(selectedState);

    if (keys.length <= 0 || data.length <= 0)
        return result;

    let isGroupedData = groupDescription.length > 0;

    keys.forEach(key => {
        if (!selectedState[key]) {
            return;
        }

        if (isGroupedData) {
            data.forEach(group => {
                if (group.items !== null && group.items !== undefined) {
                    let item = group.items.find((e: any) => e[GRID_DATA_ITEM_KEY] === key);
                    if (item !== null && item !== undefined && !result.find(exist => exist[GRID_DATA_ITEM_KEY] === key)) {
                        result.push(item);
                    }
                }
            });
        } else {
            let item = data.find(e => e[GRID_DATA_ITEM_KEY] === key);
            if (item !== null && item !== undefined && !result.find(exist => exist[GRID_DATA_ITEM_KEY] === key)) {
                result.push(item);
            }
        }
    });

    return result;
}
