import {AxiosResponse} from "axios";
import {IDataResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {SelectModel} from "../../types/SelectModel";

export const useGetEquipmentsGroups = (): {
    isLoading: boolean,
    cancel: () => void,
    getEquipmentGroups: (forServiceProvider: boolean) => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
    });

    const getEquipmentGroups = useCallback(async (forServiceProvider: boolean) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/equipment/getEquipmentGroups?forSp=${forServiceProvider}`
    }), [execute]);

    return {getEquipmentGroups, isLoading, cancel};
};

export const useGetEquipmentGroupAlias = (): {
    isLoading: boolean,
    cancel: () => void,
    getEquipmentGroupAlias: (group: string) => Promise<AxiosResponse<IDataResponse<string | null>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<string | null>>({
        method: 'get',
    });

    const getEquipmentGroupAlias = useCallback(async (group: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/equipment/getEquipmentGroupAlias?group=${group}`
    }), [execute]);

    return {getEquipmentGroupAlias, isLoading, cancel};
};
