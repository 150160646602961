import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ICancelQuoteGetAction} from "../../../../../app/interfaces/quote/ICancelQuoteGetAction";
import {useQuoteApi} from "../../../../../app/api/quote";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import {isAxiosError} from "axios";
import FormLoadingScreen from "../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import cl from './SendQuoteToAll.module.css';
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import SelectedQuotes from "../../form/components/SelectedQuotes/SelectedQuotes";

type SendQuoteToAllProps = {
    id: string | Array<string>;
    close?: () => void;
}

const SendQuoteToAll: React.FC<SendQuoteToAllProps> = ({
                                                           id,
                                                           close
                                                       }) => {
    const {t} = useTranslation();
    const [model, setModel] = useState<ICancelQuoteGetAction | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        getSendToAllCarrierData: {
            isLoading,
            cancel,
            query: getSendToAllCarrierData
        },
        sendToAllCarrier: {
            cancel: cancelSendToAllCarrier,
            mutation: sendToAllCarrier
        }
    } = useQuoteApi();

    useEffect(() => {
        if (!id || id === '') {
            return;
        }

        (async () => {
            try {
                const response = await getSendToAllCarrierData(id);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch {
                //ignore
            }
        })();

        return () => {
            cancel();
            cancelSendToAllCarrier();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const save = async (): Promise<void> => {
        if (!model) {
            return;
        }

        setDisabled(prev => !prev);

        try {
            const response = await sendToAllCarrier({
                orderReleaseGid: model.orderReleaseGid
            });

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [...id].map((item) => {
                            return {
                                id: item,
                                isRowLoading: true
                            };
                        })
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.quote")} ${model.orderReleaseGid !== '' ? model.orderReleaseGid.split(';')[0] : ''}`}/>);
            }
        } catch (e) {
            if (isAxiosError(e)) {
                toast.error(e.message);
            }
        }

        setDisabled(prev => !prev);
    }

    if ((Array.isArray(id) && id.length <= 0) || id === '') {
        return null;
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>
            {Array.isArray(id) && id.length > 1 && <SelectedQuotes ids={id}/>}
            <div className={cl.btnContainer}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            style={{minWidth: '150px'}}
                            onClick={close}
                            className={'close-button'}>
                        {t("shared.btn.no")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        style={{minWidth: '150px'}}
                        onClick={save}
                        isLoading={disabled}
                        className={'confirm-button'}>
                    {t("shared.btn.yes")}
                </Button>
            </div>
        </div>
    );
};

export default SendQuoteToAll;