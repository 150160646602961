import React, {useEffect, useState} from 'react';
import {OrderType} from "../../../../../../app/enums/OrderType";
import {useTranslation} from "react-i18next";
import {IError} from "../../../../../../app/interfaces/response/IResponse";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../uiLayouts/ToastLayout/ToastLayout";
import {isAxiosError} from "axios";
import FormLoadingScreen from "../../../../../ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {IDisassociateTagGetAction} from "../../../../../../app/interfaces/tag/IDisassociateTagGetAction";
import {useTagApi} from "../../../../../../app/api/tag";
import Button, {ButtonType} from "../../../../../ui/Button/Button";
import Alert from "../../../../../ui/Alert/Alert";
import {BLErrorCode} from "../../../../../../app/enums/BLErrorCode";

type DisassociateTagProps = {
    tagId: number;
    orderReleaseGid: string;
    orderType: OrderType;
    close?: () => void;
};

const DisassociateTag: React.FC<DisassociateTagProps> = ({
                                                             close,
                                                             tagId,
                                                             orderType,
                                                             orderReleaseGid
                                                         }) => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError>>([]);
    const [model, setModel] = useState<IDisassociateTagGetAction | null>(null);

    const {
        removeTagFromEntity: {
            mutation: removeTagFromEntity,
            cancel: cancelRemoveTagFromEntity
        },
        getRemoveTagFromEntityData: {
            query: getRemoveTagFromEntityData,
            cancel: cancelGetRemoveTagFromEntityData,
            isLoading
        }
    } = useTagApi();

    useEffect(() => {
        if (tagId <= 0 || orderReleaseGid === '') {
            if (close) {
                close()
            } else {
                return;
            }
        }

        (async () => {
            try {
                const response = await getRemoveTagFromEntityData(tagId, orderReleaseGid, orderType);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch {
                if (close) {
                    close();
                } else {
                    toast.error<string>('Unable to get remove tag data');
                }
            }
        })();

        return () => {
            cancelRemoveTagFromEntity();
            cancelGetRemoveTagFromEntityData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = async (): Promise<void> => {
        setErrors([]);

        if (!model) {
            return;
        }

        setDisabled(prev => !prev);

        try {
            const response = await removeTagFromEntity({
                orderReleaseGid: model.orderReleaseGid,
                orderType: model.orderType,
                tagId: model.tagId
            });

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [
                            {
                                id: model.orderReleaseGid,
                                isRowLoading: true
                            }
                        ]
                    }
                }));

                let updateTitle = '';

                if (orderType === OrderType.OrderRelease) {
                    updateTitle = `${t("shared.labels.offer")} ${model.orderReleaseGid.replace('AAG.', '')}`;
                } else if (orderType === OrderType.Quote) {
                    updateTitle = `${t("shared.labels.quote")} ${model.orderReleaseGid.replace('AAG.', '')}`;
                } else if (orderType === OrderType.Shipment) {
                    updateTitle = `${t("shared.labels.shipment")} ${model.orderReleaseGid.replace('AAG.', '')}`;
                }

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${updateTitle}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotFound &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();

                    toast.error(err.message);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if (tagId <= 0 || orderReleaseGid === '') {
        return null;
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                {errors.map(item => {
                    switch (item.errorCode) {
                        case BLErrorCode.InnerError:
                            return (
                                <Alert type={'Error'}>
                                    {item.description}
                                </Alert>
                            );
                    }

                    return null;
                })}
                <div className={'pd-10'}
                     style={{
                         fontSize: '16px',
                         fontWeight: 'bold',
                         textAlign: 'center'
                     }}>
                    <>
                        {t("tags.labels.remove-from-entity-confirmation")}
                        <span style={{
                            color: 'red',
                            margin: '0px 5px'
                        }}>
                            {`${model.tagName}`}
                        </span>
                        ?
                    </>
                </div>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            style={{minWidth: '100px'}}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.no")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        style={{minWidth: '150px'}}
                        onClick={submit}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.yes")}
                </Button>
            </div>
        </div>
    );
};

export default DisassociateTag;