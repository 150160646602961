import React, {useState} from 'react';
import {IUser} from "../../../../../app/interfaces/user/IUser";
import {useTranslation} from "react-i18next";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import RequestInvalidVatForm from "./RequestInvalidVatForm/RequestInvalidVatForm";
import {
    ISelfBillingInvalidVatPostAction
} from "../../../../../app/interfaces/selfBilling/ISelfBillingInvalidVatPostAction";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import useSelfBillingApi from "../../../../../app/api/selfBilling";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../components/uiLayouts/ToastLayout/ToastLayout";

type RequestInvalidVatProps = {
    orderReleaseGid: string;
    shipmentGid: string;
    user?: IUser;
    close?: () => void;
};

const RequestInvalidVat: React.FC<RequestInvalidVatProps> = ({
                                                                 user,
                                                                 orderReleaseGid,
                                                                 shipmentGid,
                                                                 close
                                                             }) => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        requestInvalidVatAnonymous: {
            mutation: requestInvalidVatAnonymous
        },
        requestInvalidVatAuthorize: {
            mutation: requestInvalidVatAuthorize
        }
    } = useSelfBillingApi();

    const onSubmit = async (values: ISelfBillingInvalidVatPostAction) => {
        setDisabled(prev => !prev);

        try {
            const response = user
                ? await requestInvalidVatAuthorize(values)
                : await requestInvalidVatAnonymous(values);

            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok) {
                toast.success(<ToastLayout title={t("self-billing.labels.start-page-title")}
                                           text={t("self-billing.labels.invalid-vat-request-sent")}/>);

                if (close) {
                    close();
                }
            }
        } catch (err) {
            if (isAxiosError(err)) {
                toast.error(`Unable to send vat request. Error: ${err.message}`);
            }
        }

        setDisabled(prev => !prev);
    }

    if (!orderReleaseGid || !shipmentGid || orderReleaseGid === '' || shipmentGid === '') {
        return null;
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                <RequestInvalidVatForm orderReleaseGid={orderReleaseGid}
                                       shipmentGid={shipmentGid}
                                       onSubmit={onSubmit}/>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        form='invalid-vat-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.send")}
                </Button>
            </div>
        </div>
    );
};

export default RequestInvalidVat;