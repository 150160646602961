import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {UserRole} from "../../../../../../app/enums/UserRole";
import {useTranslation} from "react-i18next";
import {ComboBox, ComboBoxWithoutContext, ListItemProps} from "@progress/kendo-react-dropdowns";
import {useNavigate} from "react-router-dom";
import {ISearchGlobalItem} from "../../../../../../app/interfaces/shared/ISearchGlobalItem";
import useSearchApi from "../../../../../../app/api/search";
import {debounce} from "debounce";
import shipmentCompletedImg from "../../../../../../assets/images/shipment_completed.png";
import shipmentStartedImg from "../../../../../../assets/images/shipment_started.png";
import cl from './SidebarUserSectionGlobalSearch.module.css';
import {SidebarContext} from "../../../Sidebar";
import Icon from "../../../../../../assets/icon/Icon";
import Loader, {LoaderType} from "../../../../loaders/Loader/Loader";

const HeaderGlobalSearchItemTemplate = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
) => {
    let child: React.ReactElement = (
        <div className={cl.comboboxItem}>
            <span>
                {itemProps.dataItem.header.replace(/\s/g, '')}
            </span>
            <span>
                {itemProps.dataItem.label}
            </span>
            {itemProps.dataItem.indicator !== undefined &&
                itemProps.dataItem.indicator !== null &&
                itemProps.dataItem.indicator === 'G' &&
                <img src={shipmentCompletedImg} alt={'G'}/>
            }
            {itemProps.dataItem.indicator !== undefined &&
                itemProps.dataItem.indicator !== null &&
                itemProps.dataItem.indicator === 'Y' &&
                <img src={shipmentStartedImg} alt={'Y'}/>
            }
        </div>
    );

    return React.cloneElement(li, li.props, child);
};

const NoDataFoundGlobalSearchItemTemplate = (
    {
        element,
        filter,
        filterValue,
        items
    }: {
        element: React.ReactElement<HTMLDivElement>;
        filter?: string;
        filterValue?:string;
        items: Array<ISearchGlobalItem>
    }) => {

    const {t} = useTranslation();
    const elem = filter && filterValue && filter !== '' && items.length <= 0
        ? <div>
            <Loader type={LoaderType.Action}
                    style={{scale: '0.4', borderColor: 'black', borderBottomColor: 'white'}}/>
        </div>
        : <div style={{
            fontSize: '10px',
            fontWeight: 'bold',
            color: 'black'
        }}>
            {filterValue && filterValue !== '' && filterValue.length > 2 ? t("shared.errors.search-not-found") : t("shared.errors.search-too-short")}
        </div>

    return React.cloneElement(element, {...element.props}, elem);
};

const SidebarUserSectionGlobalSearch: React.FC = () => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    const combobox = useRef<ComboBoxWithoutContext | null>(null);
    const navigate = useNavigate();

    const [items, setItems] = useState<Array<ISearchGlobalItem>>([]);
    const [filter, setFilter] = useState<string | undefined>();
    const [filterValue, setFilterValue] = useState<string | undefined>();
    const {
        searchGlobal: {
            query,
            isLoading,
            cancel
        }
    } = useSearchApi();

    const {
        expanded,
        hoverExpanded
    } = useContext(SidebarContext);

    useEffect(() => {
        return () => {
            cancel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            if(filter === undefined)
                return;

            await load(filter);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const load = useCallback(debounce(async (lFilter?: string) => {
        try {
            const response = await query(lFilter ?? '');
            if (response?.status === 200) {
                if (response.data?.data) {
                    setItems(response.data.data);
                } else {
                    setItems([]);
                }

                setFilter(undefined);
            }
        } catch {
            //ignore
        }
    }, 500), []);

    if (!user || (user.role !== UserRole.Administrator && user.role !== UserRole.Forwarder)) {
        return null;
    }

    return (
        <div className={`relative global-search-combobox-picker ${cl.container}`}
             data-expanded={hoverExpanded || expanded ? 'expanded' : 'condensed'}>
            <div className={`relative`}>
                <ComboBox id="global-search-combobox"
                          ref={rf => combobox.current = rf}
                          data={items}
                          value={null}
                          defaultValue={null}
                          placeholder={hoverExpanded || expanded ? t("shared.btn.search") : undefined}
                          clearButton={false}
                          textField="gid"
                          dataItemKey="unique"
                          filterable={true}
                          loading={isLoading}
                          popupSettings={{
                              width: '240px',
                              height: '265px'
                          }}
                          itemRender={HeaderGlobalSearchItemTemplate}
                          listNoDataRender={(elem) =>
                              <NoDataFoundGlobalSearchItemTemplate element={elem}
                                                                   filter={filter}
                                                                   filterValue={filterValue}
                                                                   items={items}/>}
                          onBlur={() => {
                              setItems([]);
                          }}
                          onFilterChange={(ev) => {
                              if ((ev.nativeEvent === undefined || ev.nativeEvent === null) && (
                                  ev.syntheticEvent === undefined || ev.syntheticEvent === null
                              )) {
                                  setFilter(undefined);
                                  setFilterValue(undefined);
                                  return;
                              }

                              if (!ev.filter?.value) {
                                  setItems([]);

                                  return;
                              }

                              setFilter(ev.filter.value);
                              setFilterValue(ev.filter.value);
                          }}
                          onChange={(ev) => {
                              setTimeout(() => {
                                  setItems([]);
                              }, 500);

                              if (ev.value && ev.value.type && ev.value.gid) {
                                  switch (ev.value.type) {
                                      case 'Quote':
                                          navigate(`/quote/${ev.value.gid}`);
                                          break;
                                      case 'OrderRelease':
                                          navigate(`/offer/${ev.value.gid}`);
                                          break;
                                      case 'Shipment':
                                      case 'Expense':
                                          if (ev.value.sid) {
                                              navigate(`/shipment/${ev.value.sid}/${ev.value.gid}`);
                                          }
                                          break;
                                  }
                              }
                          }}
                />

                <Icon icon={"faMagnifyingGlass"}
                      className={`${cl.glass} ${hoverExpanded || expanded ? '' : cl.glassCondensed}`}/>
            </div>
        </div>
    );
};

export default SidebarUserSectionGlobalSearch;
