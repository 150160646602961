import React from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import EditRemark from "./EditRemark";

type EditRemarkDialogProps = {
    id: string;
    code: string | null;
    seq: string | null;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditRemarkDialog: React.FC<EditRemarkDialogProps> = ({
                                                               id,
                                                               code,
                                                               seq,
                                                               show,
                                                               setShow
                                                           }) => {
    const {t} = useTranslation();

    return (
        <Dialog id={'edit-remark-dialog'}
                show={show}
                setShow={setShow}
                width={'650px'}
                title={code === null && seq === null
                    ? t("shared.btn.add-remark")
                    : t("shared.btn.edit-remark")}>
            <EditRemark id={id}
                        code={code}
                        seq={seq}
                        close={() => setShow(prev => !prev)}/>
        </Dialog>
    );
};

export default EditRemarkDialog;