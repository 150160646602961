import {IChatState} from "./IChatState";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IChatSocketSession} from "../../app/types/ChatSocketSession";
import {IDataResponse, isResponse} from "../../app/interfaces/response/IResponse";
import {IChat} from "../../app/interfaces/chat/IChat";
import apiAxios from "../../app/axios/apiAxios";
import {AxiosError} from "axios";
import {ResponseResultCode} from "../../app/enums/ResponseResultCode";
import {IChatMessage} from "../../app/interfaces/chat/IChatMessage";
import {IGridResultResponse} from "../../app/interfaces/response/IGridResultResponse";
import {RootState} from "../../app/store/store";
import moment from "moment";

const initialState: IChatState = {
    isConnected: false,
    isOpened: false,
    isFullScreen: false,
    isSendMessageOpen: false,
    totalUnreadMessages: 0,
    sessions: [],
    chats: {
        filter: null,
        isLoading: false,
        chats: []
    },
    chat: {
        chat: null,
        message: '',
        isUserInformationSectionShown: false,
        search: {
            search: '',
            isMessagesSearchSectionShown: false,
            currentActiveMessage: null,
            messages: {
                messages: [],
                isLoading: false,
                count: 0
            },
        },
        messages: {
            messages: [],
            isLoading: false,
            count: 0
        }
    }
};

export const searchChatMessages = createAsyncThunk<IGridResultResponse<IChatMessage>, {
    user: string;
    search: string | null;
}>(
    "chat/searchChatMessages",
    async (params) => {
        if (!params.search || params.search === '') {
            return {
                count: 0,
                result: []
            } as IGridResultResponse<IChatMessage>;
        }

        try {
            let q = `api/${process.env.REACT_APP_API_VERSION}/chat/searchMessages`;

            q += `?user=${params.user}&search=${params.search}`

            const response = await apiAxios.get<IGridResultResponse<IChatMessage>>(q);

            if (response?.status === 200 && response.data) {
                return {
                    count: response.data.count,
                    result: response.data.result
                } as IGridResultResponse<IChatMessage>;
            }
        } catch (err) {
            if (err instanceof AxiosError && err.response && err.response.data) {
                return {
                    count: 0,
                    result: []
                } as IGridResultResponse<IChatMessage>;
            }
        }

        return {
            count: 0,
            result: []
        } as IGridResultResponse<IChatMessage>;
    }
);

export const getChatMessages = createAsyncThunk<IGridResultResponse<IChatMessage>, {
    user: string;
    search: string | null;
    skip: number;
    take: number;
    start?: string;
}>(
    "chat/getChatMessages",
    async (params) => {
        try {
            let q = `api/${process.env.REACT_APP_API_VERSION}/chat/getMessages`;

            q += `?user=${params.user}`

            if (params.start !== undefined && params.start !== null && params.start !== '') {
                q += `&start=${params.start}`;
            } else {
                if (params.search && params.search !== '') {
                    q += `&filter=${params.search}`;
                }

                if (params.skip !== undefined && params.skip >= 0 && params.take !== undefined && params.take >= 0) {
                    q += `&skip=${params.skip}&take=${params.take}`;
                }
            }

            const response = await apiAxios.get<IGridResultResponse<IChatMessage>>(q);

            if (response?.status === 200 && response.data) {
                return {
                    count: response.data.count,
                    result: response.data.result
                } as IGridResultResponse<IChatMessage>;
            }
        } catch (err) {
            if (err instanceof AxiosError && err.response && err.response.data) {
                return {
                    count: 0,
                    result: []
                } as IGridResultResponse<IChatMessage>;
            }
        }

        return {
            count: 0,
            result: []
        } as IGridResultResponse<IChatMessage>;
    }
);

export const getChats = createAsyncThunk<IDataResponse<Array<IChat>>, string | null, {
    state: RootState
}>(
    "chat/getChats",
    async (filter) => {
        try {
            let q = `api/${process.env.REACT_APP_API_VERSION}/chat/getChats`;

            if (filter && filter !== '') {
                q += `?filter=${filter}`;
            }

            const response = await apiAxios.get<IDataResponse<Array<IChat>>>(q);

            return {
                resultCode: response.data.resultCode,
                data: response.data.data
            };
        } catch (err) {
            if (err instanceof AxiosError && err.response && err.response.data) {
                if (isResponse(err.response.data)) {
                    return {
                        resultCode: err.response.data.resultCode,
                        data: undefined
                    };
                }
            }
        }

        return {
            resultCode: ResponseResultCode.NotFound,
            data: undefined
        };
    }
);

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        resetChatSlice: () => initialState,
        setIsChatDialogOpened: (state, action: PayloadAction<boolean>) => {
            if (state.isOpened !== action.payload) {
                state.isOpened = action.payload;
            }
        },
        setIsChatSocketConnected: (state, action: PayloadAction<boolean>) => {
            if (state.isConnected !== action.payload) {
                state.isConnected = action.payload;
            }
        },
        setIsChatFullScreenMode: (state, action: PayloadAction<boolean>) => {
            if (state.isFullScreen !== action.payload) {
                state.isFullScreen = action.payload;
            }
        },
        setChatSessions: (state, action: PayloadAction<Array<IChatSocketSession>>) => {
            state.sessions = action.payload;
        },
        setChatTotalUnreadMessaged: (state, action: PayloadAction<number>) => {
            if (state.totalUnreadMessages !== action.payload) {
                state.totalUnreadMessages = action.payload;
            }
        },
        clearChatSlice: (state) => {
            state.chats = initialState.chats;
            state.chat = initialState.chat;
        },
        setChat: (state, action: PayloadAction<IChat | null>) => {
            state.chat.chat = action.payload;

            state.chat.messages = initialState.chat.messages;
            state.chat.search = initialState.chat.search;
            state.chat.message = initialState.chat.message;
            //state.chat.isUserInformationSectionShown = initialState.chat.isUserInformationSectionShown;
        },
        setChats: (state, action: PayloadAction<Array<IChat>>) => {
            state.chats.chats = action.payload
        },
        setIsUserInformationSectionShown: (state, action: PayloadAction<boolean>) => {
            if (state.chat.isUserInformationSectionShown !== action.payload) {
                state.chat.isUserInformationSectionShown = action.payload;
            }
        },
        setIsMessagesSearchSectionShown: (state, action: PayloadAction<boolean>) => {
            if (state.chat.search.isMessagesSearchSectionShown !== action.payload) {
                if (!action.payload) {
                    state.chat.search = initialState.chat.search;

                    return;
                }

                state.chat.search.isMessagesSearchSectionShown = action.payload;
            }
        },
        setActiveChatSearchMessage: (state, action: PayloadAction<IChatMessage | null>) => {
            state.chat.search.currentActiveMessage = action.payload;
        },
        setActiveChatSearchString: (state, action: PayloadAction<string>) => {
            state.chat.search.search = action.payload;
        },
        setMessage: (state, action: PayloadAction<string>) => {
            state.chat.message = action.payload;
        },
        UNSAFE_setMessages: (state, action: PayloadAction<Array<IChatMessage>>) => {
            state.chat.messages.messages = action.payload;
        },
        UNSAFE_setIsSendMessageOpen: (state, action: PayloadAction<boolean>) => {
            state.isSendMessageOpen = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(getChats.pending, (state) => {
            state.chats.isLoading = true;
        });
        builder.addCase(getChats.fulfilled, (state, {payload}) => {
            if (state.isSendMessageOpen && state.chat.chat) {
                state.isSendMessageOpen = false;

                let result: Array<IChat> = [...payload.data ?? []];
                let newPosition = Math.max(...result.map(e => e.position)) + 1;
                let isChatExist = result.find(e => e.id === state.chat.chat?.id);

                if (!isChatExist) {
                    result.push({
                        ...state.chat.chat,
                        date: moment().utc().toDate(),
                        position: newPosition
                    });
                }

                state.chats.isLoading = false;
                state.chats.chats = result;
            } else {
                state.chats.isLoading = false;
                state.chats.chats = payload.data ?? [];
            }
        });
        builder.addCase(getChats.rejected, (state) => {
            state.chats.isLoading = false;
        });

        builder.addCase(getChatMessages.pending, (state) => {
            state.chat.messages.isLoading = true;
        });
        builder.addCase(getChatMessages.fulfilled, (state, {payload}) => {
            let messages = payload.result.length > 0
                ? [...payload.result, ...state.chat.messages.messages]
                : [...state.chat.messages.messages];

            const key = 'id';

            messages = [...new Map(messages.map(item => [item[key], item])).values()]
                .sort((a, b) => {
                    return ((new Date(a.date)).getTime() - (new Date(b.date)).getTime());
                });

            state.chat.messages.messages = messages;

            state.chat.messages.count = payload.count;
            state.chat.messages.isLoading = false;
        });
        builder.addCase(getChatMessages.rejected, (state) => {
            state.chat.messages.isLoading = false;
        });

        builder.addCase(searchChatMessages.pending, (state) => {
            state.chat.search.messages.isLoading = true;
        });
        builder.addCase(searchChatMessages.fulfilled, (state, {payload}) => {
            state.chat.search.currentActiveMessage = null;

            state.chat.search.messages.messages = payload.result;

            state.chat.search.messages.count = payload.count;
            state.chat.search.messages.isLoading = false;
        });
        builder.addCase(searchChatMessages.rejected, (state) => {
            state.chat.search.messages.isLoading = false;
        });
    }
});

export const {
    setIsChatDialogOpened,
    setIsChatSocketConnected,
    setIsChatFullScreenMode,
    setChatSessions,
    setChatTotalUnreadMessaged,
    clearChatSlice,
    resetChatSlice,
    setChat,
    setChats,
    setIsUserInformationSectionShown,
    setMessage,
    setIsMessagesSearchSectionShown,
    setActiveChatSearchMessage,
    setActiveChatSearchString,
    UNSAFE_setMessages,
    UNSAFE_setIsSendMessageOpen
} = chatSlice.actions;

export default chatSlice.reducer;