import React, {useEffect, useState} from 'react';
import {IOffer} from "../../../../app/interfaces/offer/IOffer";
import {
    FormLayoutToolbarActionItem
} from "../../../../components/uiLayouts/FormLayout/components/FormLayoutToolbar/FormLayoutToolbar";
import FormLayout from "../../../../components/uiLayouts/FormLayout/FormLayout";
import Alert from "../../../../components/ui/Alert/Alert";
import {CustomScroll} from "react-custom-scroll";
import {useOfferApi} from "../../../../app/api/offer";
import {useTranslation} from "react-i18next";
import {ResponseResultCode} from "../../../../app/enums/ResponseResultCode";
import {AxiosError} from "axios";
import {toast} from "react-toastify";
import {OrderReleaseStatus} from "../../../../app/enums/OrderReleaseStatus";
import {ButtonType} from "../../../../components/ui/Button/Button";
import RecallBetDialog from "../../../authorized/Offer/actions/RecallBet/RecallBetDialog";
import ProposeRateDialog from "../../../authorized/Offer/actions/ProposeRate/ProposeRateDialog";
import ConfirmAuctionDialog from "../../../authorized/Offer/actions/ConfirmAuction/ConfirmAuctionDialog";
import OfferDetailsBox from "../../../authorized/Offer/form/components/OfferDetailsBox/OfferDetailsBox";
import ServiceProviderOfferDetailsSection
    from "../../../authorized/Offer/form/sections/OfferDetailsSection/ServiceProvider/ServiceProviderOfferDetailsSection";

type OfferDetailsProps = {
    id: string;
};

const OfferDetails: React.FC<OfferDetailsProps> = ({
                                                       id
                                                   }) => {
    const {t} = useTranslation();

    const [data, setData] = useState<IOffer | null>(null);
    const [actions, setActions] = useState<Array<FormLayoutToolbarActionItem | 'separator'>>([]);

    const [isRecallBetDialogShown, setIsRecallBetDialogShown] = useState<boolean>(false);
    const [isProposeRateDialogShown, setIsProposeRateDialogShown] = useState<boolean>(false);
    const [isConfirmAuctionDialogShown, setIsConfirmAuctionDialogShown] = useState<boolean>(false);

    const {
        getOfferAnonymous: {
            query,
            isLoading
        }
    } = useOfferApi();

    useEffect(() => {
        if (!data) {
            return;
        }

        setActions(buildActionsList());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, t]);

    useEffect(() => {
        if (!id) {
            return;
        }

        (async () => {
            await getOrderData(id)
        })();

        return () => {
            setData(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);


    const getOrderData = async (id: string) => {
        try {
            const data = await query(id);

            if (data !== undefined && data.status === 200 && data.data !== undefined &&
                data.data.resultCode === ResponseResultCode.Ok) {
                setData(data.data.data ?? null);
            }
        } catch (e) {
            if (e instanceof AxiosError && e.response !== undefined && e.response.data !== undefined &&
                e.response.data.resultCode !== ResponseResultCode.NotFound) {
                toast.error(e.message);
            }
        }
    };

    const buildActionsList = (): Array<FormLayoutToolbarActionItem | 'separator'> => {
        if (!data) {
            return [];
        }

        let arr: Array<FormLayoutToolbarActionItem | 'separator'> = [];

        if (data.status === OrderReleaseStatus.WithBet) {
            arr.push({
                disabled: false,
                id: 'recall-rate-btn',
                title: t("shared.btn.recall-rate"),
                onClick: () => setIsRecallBetDialogShown(prev => !prev),
                color: 'red',
                pinnedButtonType: ButtonType.Warning
            });
        }

        if (data.status === OrderReleaseStatus.Free) {
            if (!data.isAuction || data.initialBet === null) {
                arr.push({
                    disabled: false,
                    id: 'propose-rate-btn',
                    title: t("shared.btn.propose-rate"),
                    onClick: () => setIsProposeRateDialogShown(prev => !prev),
                    pinnedButtonType: ButtonType.Secondary
                });
            }
        }

        if (data.isAuction && data.initialBet !== null) {
            arr.push({
                disabled: false,
                id: 'confirm-auction-btn',
                title: t("shared.btn.accept-transportation"),
                onClick: () => setIsConfirmAuctionDialogShown(prev => !prev),
                pinnedButtonType: ButtonType.Secondary
            });
        }

        return arr;
    }

    return (
        <>
            {id && id !== '' && data &&
                <>
                    {data.myBet !== null &&
                        <RecallBetDialog betId={data.myBet.betId}
                                         orderReleaseGid={id}
                                         show={isRecallBetDialogShown}
                                         setShow={setIsRecallBetDialogShown}/>
                    }

                    <ProposeRateDialog id={id}
                                       show={isProposeRateDialogShown}
                                       setShow={setIsProposeRateDialogShown}/>

                    <ConfirmAuctionDialog id={[id]}
                                          show={isConfirmAuctionDialogShown}
                                          setShow={setIsConfirmAuctionDialogShown}/>
                </>
            }

            <FormLayout id={'offer-details'}
                        isLoading={!id || isLoading}
                        showCloseIcon={false}
                        actions={actions}
                        close={() => {

                        }}>
                {!data && <Alert type={'Error'}>{t("offer.errors.not-found")}</Alert>}
                {data &&
                    <div className={`full details h100`}>
                        <CustomScroll heightRelativeToParent={'100%'}>
                            <div id={'offer-details-section'}>
                                <OfferDetailsBox offer={data}
                                                 isSidePanelDetails={false}
                                                 customerAttachmentDocs={null}/>

                                <ServiceProviderOfferDetailsSection offer={data}/>
                            </div>
                        </CustomScroll>
                    </div>
                }
            </FormLayout>
        </>
    );
};

export default OfferDetails;