import React, {SetStateAction} from 'react';
import {IAssignShipmentGetAction} from "../../../../../../app/interfaces/shipment/IAssignShipmentGetAction";
import {FormikHelpers, useFormik} from "formik";
import {IAssignShipmentPostAction} from "../../../../../../app/interfaces/shipment/IAssignShipmentPostAction";
import Checkbox from "../../../../../../components/ui/Checkbox/Checkbox";
import {useTranslation} from "react-i18next";
import cl from './AssignShipmentForm.module.css';
import AssignShipmentOrderManagerSelector
    from "./AssignShipmentOrderManagerSelector/AssignShipmentOrderManagerSelector";
import AssignShipmentSecurityManagerSelector
    from "./AssignShipmentSecurityManagerSelector/AssignShipmentSecurityManagerSelector";

type AssignShipmentFormProps = {
    model: IAssignShipmentGetAction;
    disabled: boolean;
    setIsSubmitDisabled: React.Dispatch<SetStateAction<boolean>>;
    save: (model: IAssignShipmentPostAction) => Promise<void>;
}

const AssignShipmentForm: React.FC<AssignShipmentFormProps> = (props) => {
    const {t} = useTranslation();

    const form = useFormik<IAssignShipmentPostAction>({
        initialValues: {
            hasOrderManager: false,
            hasSecurityAgent: false,
            orderManager: props.model.orderManager,
            securityAgent: props.model.securityAgent,
            orderReleaseGid: props.model.orderReleaseGid
        },
        onSubmit: async (values: IAssignShipmentPostAction, actions: FormikHelpers<IAssignShipmentPostAction>) => {
            actions.setSubmitting(true);

            await props.save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='assign-shipment-form'
              onSubmit={form.handleSubmit}>
            <div className={'pd-10'}>
                <div className={'mgb-10'}>
                    <div className={cl.label}>
                        <Checkbox checked={form.values.hasOrderManager}
                                  id={'assign-shipment-has-order-manager'}
                                  change={(val) => {
                                      props.setIsSubmitDisabled(!(form.values.hasSecurityAgent || val));

                                      form.setFieldValue('hasOrderManager', val)
                                  }}
                                  disabled={props.disabled || form.isSubmitting}/>
                        <label className={`view-label ${form.values.hasOrderManager ? 'required' : ''}`}>
                            {t("shipment.grid.order-manager-label")}
                        </label>
                    </div>
                    <AssignShipmentOrderManagerSelector
                        onChange={(val) => form.setFieldValue('orderManager', val)}
                        disabled={props.disabled || form.isSubmitting || !form.values.hasOrderManager}
                        value={form.values.orderManager}/>
                </div>
                <div className={'mgb-10'}>
                    <div className={cl.label}>
                        <Checkbox checked={form.values.hasSecurityAgent}
                                  id={'assign-shipment-has-security-agent'}
                                  change={(val) => {
                                      props.setIsSubmitDisabled(!(val || form.values.hasOrderManager));

                                      form.setFieldValue('hasSecurityAgent', val)
                                  }}
                                  disabled={props.disabled || form.isSubmitting}/>
                        <label className={`view-label ${form.values.hasSecurityAgent ? 'required' : ''}`}>
                            {t("shipment.grid.security-manager-label")}
                        </label>
                    </div>
                    <AssignShipmentSecurityManagerSelector
                        onChange={(val) => form.setFieldValue('securityAgent', val)}
                        disabled={props.disabled || form.isSubmitting || !form.values.hasSecurityAgent}
                        value={form.values.securityAgent}/>
                </div>
            </div>
        </form>
    );
};

export default AssignShipmentForm;
