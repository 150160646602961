import {AxiosResponse} from "axios";
import {IDataResponse, IResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {INotificationGetAction} from "../../interfaces/notification/INotificationGetAction";
import {INotificationPostAction} from "../../interfaces/notification/INotificationPostAction";
import {IForwarderNotificationGetAction} from "../../interfaces/notification/IForwarderNotificationGetAction";
import {IForwarderNotificationPostAction} from "../../interfaces/notification/IForwarderNotificationPostAction";

export const useGetNotificationDetailsData = (): {
    isLoading: boolean,
    cancel: () => void,
    getNotificationDetailsData: () => Promise<AxiosResponse<IDataResponse<INotificationGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<INotificationGetAction>>({
        method: 'get',
    });

    const getNotificationDetailsData = useCallback(async () => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/notification/getNotificationData`
    }), [execute]);

    return {getNotificationDetailsData, isLoading, cancel};
};


export const useSaveNotificationDetailsData = (): {
    isLoading: boolean,
    cancel: () => void,
    saveNotificationDetailsData: (params: INotificationPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, INotificationPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/notification/notificationAction`
    });

    const saveNotificationDetailsData = useCallback(async (params: INotificationPostAction) => await execute(params), [execute]);

    return {saveNotificationDetailsData, isLoading, cancel};
};

export const useGetForwarderNotificationDetailsData = (): {
    isLoading: boolean,
    cancel: () => void,
    getForwarderNotificationDetailsData: () => Promise<AxiosResponse<IDataResponse<IForwarderNotificationGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IForwarderNotificationGetAction>>({
        method: 'get',
    });

    const getForwarderNotificationDetailsData = useCallback(async () => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/notification/getForwarderNotificationData`
    }), [execute]);

    return {getForwarderNotificationDetailsData, isLoading, cancel};
};


export const useSaveForwarderNotificationDetailsData = (): {
    isLoading: boolean,
    cancel: () => void,
    saveForwarderNotificationDetailsData: (params: IForwarderNotificationPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IForwarderNotificationPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/notification/forwarderNotificationAction`
    });

    const saveForwarderNotificationDetailsData = useCallback(async (params: IForwarderNotificationPostAction) => await execute(params), [execute]);

    return {saveForwarderNotificationDetailsData, isLoading, cancel};
};

export const useUnsubscribeFromViberNotifications = (): {
    isLoading: boolean,
    cancel: () => void,
    unsubscribeFromViberNotifications: () => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, {
        orderReleaseId: number
    }>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/notification/unsubscribeViberAction`
    });

    const unsubscribeFromViberNotifications = useCallback(async () => await execute(), [execute]);

    return {unsubscribeFromViberNotifications, isLoading, cancel};
};

export const useUnsubscribeFromTelegramNotifications = (): {
    isLoading: boolean,
    cancel: () => void,
    unsubscribeFromTelegramNotifications: () => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, {
        orderReleaseId: number
    }>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/notification/unsubscribeTelegramAction`
    });

    const unsubscribeFromTelegramNotifications = useCallback(async () => await execute(), [execute]);

    return {unsubscribeFromTelegramNotifications, isLoading, cancel};
};