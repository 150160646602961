import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'globe',
    pathData: [
        'M7.57533 7.50033C7.77125 6.94338 8.15795 6.47375 8.66695 6.1746C9.17596 5.87546 9.7744 5.76611 10.3563 5.86592C10.9382 5.96573 11.466 6.26826 11.8462 6.71993C12.2264 7.1716 12.4345 7.74326 12.4337 8.33366C12.4337 10.0003 9.93366 10.8337 9.93366 10.8337M10.0003 14.167H10.0087M18.3337 10.0003C18.3337 14.6027 14.6027 18.3337 10.0003 18.3337C5.39795 18.3337 1.66699 14.6027 1.66699 10.0003C1.66699 5.39795 5.39795 1.66699 10.0003 1.66699C14.6027 1.66699 18.3337 5.39795 18.3337 10.0003Z'
    ],
    viewBox: '0 0 20 20',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;