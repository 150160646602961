import React, {useCallback, useEffect, useState} from 'react';
import {SelectModel} from "../../../../../../../app/types/SelectModel";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {useTranslation} from "react-i18next";
import {debounce} from "debounce";
import {useDataHelperApi} from "../../../../../../../app/api/dataHelper";

type AssignShipmentOrderManagerSelectorProps = {
    disabled: boolean;
    value?: string;
    onChange: (val?: string) => void;
}

const AssignShipmentOrderManagerSelector: React.FC<AssignShipmentOrderManagerSelectorProps> = ({
                                                                                                   disabled,
                                                                                                   value,
                                                                                                   onChange
                                                                                               }) => {
    const {t} = useTranslation();
    const [items, setItems] = useState<Array<SelectModel>>([]);
    const [filter, setFilter] = useState<string | undefined>();

    const {
        getUsersForAssignment: {
            query,
            isLoading,
            cancel
        }
    } = useDataHelperApi();

    useEffect(() => {
        return () => {
            cancel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            await load(value, filter);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);


    const load = useCallback(debounce(async (lValue?: string, lFilter?: string) => {
        try {
            const response = await query(false, lValue, lFilter);
            if (response?.status === 200 && response.data?.data) {
                setItems(response.data.data.filter(e => e.text !== '' && e.value !== ''));
            }
        } catch {
            //ignore
        }
    }, 500), []);

    return (
        <div className={`w100 combobox-picker`} style={{paddingTop: '5px'}}>
            <ComboBox data={items}
                      id={'assign-shipment-order-manager'}
                      value={value && items.length > 0 ? items.find(e => e.value === value) : undefined}
                      onChange={ev => onChange(ev.value?.value)}
                      disabled={disabled}
                      placeholder={t("shipment.grid.order-manager-label")}
                      textField="text"
                      dataItemKey="value"
                      clearButton={true}
                      filterable={true}
                      loading={isLoading}
                      onFilterChange={(ev) => {
                          onChange(undefined);

                          if (!ev.filter?.value || ev.filter.value.length < 3) {
                              return;
                          }

                          setFilter(ev.filter.value);
                      }}
            />
        </div>
    );
};

export default AssignShipmentOrderManagerSelector;