import React, {useState} from 'react';
import {IShipment} from "../../../../../../../../../app/interfaces/shipment/IShipment";
import {useTranslation} from "react-i18next";
import {UserRole} from "../../../../../../../../../app/enums/UserRole";
import {useAppSelector} from "../../../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../../../../helpers/user";
import ChangeRefNumDialog from "../../../../../../actions/ChangeRefNum/ChangeRefNumDialog";
import Icon from "../../../../../../../../../assets/icon/Icon";

type CustomerOrderRefNumberProps = {
    shipment: IShipment
}

const CustomerOrderRefNumber: React.FC<CustomerOrderRefNumberProps> = ({shipment}) => {
    const {t} = useTranslation();
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);

    const user = useAppSelector(selectCurrentUser);

    if (!user) {
        return null;
    }

    const role = getUserRole(user);

    return (
        <div className={'details-box-item-field-value'}>
            <>

                {(role === UserRole.Forwarder || role === UserRole.Administrator) &&
                     <>
                         <ChangeRefNumDialog id={shipment.shipmentGid ?? ''}
                                             show={isDialogShown}
                                             setShow={setIsDialogShown}/>
                         {shipment.customerOrderRefNumber !== null && shipment.customerOrderRefNumber !== undefined && shipment.customerOrderRefNumber !== '' ?
                         <><Icon icon={"faPen"}
                            className={'details-box-item-field-gray-icon'}
                            style={{marginRight: '5px'}}
                            onClick={() => setIsDialogShown(prev => !prev)}/>{shipment.customerOrderRefNumber}
                         </> : <Icon icon={"faPen"}
                                     className={'details-box-item-field-icon'}
                                     style={{marginRight: '5px'}}
                                     onClick={() => setIsDialogShown(prev => !prev)}/>
                         }
                    </>

                }
                {(role === UserRole.ServiceProvider || role === UserRole.Client) ?
                    (shipment.customerOrderRefNumber !== null && shipment.customerOrderRefNumber !== undefined && shipment.customerOrderRefNumber !== '' ? shipment.customerOrderRefNumber : t("shared.labels.no-data")) : <></>
                }
            </>
        </div>
    );
};

export default CustomerOrderRefNumber;
