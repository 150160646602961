import React from 'react';
import {useChatHelperContext} from "../../../hooks/useChatHelperContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {AnimatePresence, motion} from "framer-motion";
import ChatDialogueInformation from "./ChatDialogueInformation/ChatDialogueInformation";
import ChatDialogueHeader from "./ChatDialogueHeader/ChatDialogueHeader";
import ChatDialogue from "./ChatDialogue/ChatDialogue";
import ChatDialogueSearch from "./ChatDialogueSearch/ChatDialogueSearch";

const ChatContentDialogueSection: React.FC = () => {
    const {
        isMobileView,
        chat,
        isUserInformationSectionShown
    } = useChatHelperContext();

    if (!isMobileView && !chat) {
        return (
            <div className={'chat-content-dialogue-section-empty'}>
                <FontAwesomeIcon icon={faEnvelope}/>
            </div>
        );
    }

    if (isMobileView) {
        return (
            <>
                <AnimatePresence>
                    {isUserInformationSectionShown &&
                        <motion.div key={'chat-dialogue-information-section'}
                                    initial={{x: '100%', opacity: 0, scale: 0.5}}
                                    exit={{x: '100%', opacity: 0}}
                                    animate={{x: 0, opacity: 1, scale: 1}}
                                    transition={{duration: 0.2, ease: 'easeOut'}}
                                    className={'chat-content-dialogue-section-info'}
                                    data-type={'mobile'}>
                            <ChatDialogueInformation/>
                        </motion.div>
                    }
                </AnimatePresence>

                <AnimatePresence>
                    {chat &&
                        <motion.div key={'chat-dialogue'}
                                    initial={{x: '100%', opacity: 0, scale: 0.5}}
                                    exit={{x: '100%', opacity: 0}}
                                    animate={{x: 0, opacity: 1, scale: 1}}
                                    transition={{duration: 0.2, ease: 'easeOut'}}
                                    className={'chat-content-dialogue relative'}
                                    data-type={'mobile'}>
                            <ChatDialogueSearch/>

                            <ChatDialogueHeader/>

                            <div className={`chat-dialogue-content ${chat.replyTo ? 'chat-dialogue-content-reply-to' : ''}`}
                                 data-type={'mobile'}>
                                <ChatDialogue/>
                            </div>
                        </motion.div>
                    }
                </AnimatePresence>
            </>
        );
    }

    if (!chat) {
        return null;
    }

    return (
        <div className={'chat-content-dialogue'}
             data-type={isUserInformationSectionShown ? 'wi' : ''}>
            <div className={`chat-dialogue-content ${chat.replyTo ? 'chat-dialogue-content-reply-to' : ''} relative`}>
                <ChatDialogueSearch/>

                <ChatDialogueHeader/>

                <ChatDialogue/>
            </div>

            {isUserInformationSectionShown && <ChatDialogueInformation/>}
        </div>
    );
};

export default ChatContentDialogueSection;