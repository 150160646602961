import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'book',
    pathData: [
        'M11.7998 9.16699H6.3998M8.1998 12.5003H6.3998M13.5998 5.83366H6.3998M17.1998 5.66699V14.3337C17.1998 15.7338 17.1998 16.4339 16.9055 16.9686C16.6467 17.439 16.2336 17.8215 15.7256 18.0612C15.148 18.3337 14.3919 18.3337 12.8798 18.3337H7.1198C5.60766 18.3337 4.85159 18.3337 4.27403 18.0612C3.76599 17.8215 3.35295 17.439 3.09409 16.9686C2.7998 16.4339 2.7998 15.7338 2.7998 14.3337V5.66699C2.7998 4.26686 2.7998 3.5668 3.09409 3.03202C3.35295 2.56161 3.76599 2.17916 4.27403 1.93948C4.85159 1.66699 5.60766 1.66699 7.1198 1.66699H12.8798C14.3919 1.66699 15.148 1.66699 15.7256 1.93948C16.2336 2.17916 16.6467 2.56161 16.9055 3.03202C17.1998 3.5668 17.1998 4.26686 17.1998 5.66699Z'
    ],
    viewBox: '0 0 20 20',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
};

export default icon;