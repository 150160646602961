import {IUploadFile} from "../types/IUploadFile";
import {FileUploadRestrictions, UploadFileStatus} from "../types";

export const getFileUploadExtensions = (fileName: string): string => {
    const regex = /\.([^\.]+)$/;
    const match = fileName.match(regex);

    return match ? `.${match[1].toLowerCase()}` : '';
};

export const processRestrictions = (file: IUploadFile, restrictions?: FileUploadRestrictions): IUploadFile => {
    if (!restrictions) {
        return file;
    }

    if (restrictions.allowedExtensions && restrictions.allowedExtensions.length > 0) {
        if ([...restrictions.allowedExtensions.map(item => item.toString())].indexOf(file.extension) === -1) {
            file.status = UploadFileStatus.RestrictionFailed;

            file.errors.push("file-uploader.invalid-file-extension");
        }
    }

    if (restrictions.maxFileSize !== undefined && file.size > restrictions.maxFileSize) {
        file.status = UploadFileStatus.RestrictionFailed;

        file.errors.push("file-uploader.invalid-max-file-size");
    }

    if (restrictions.minFileSize !== undefined && file.size < restrictions.minFileSize) {
        file.status = UploadFileStatus.RestrictionFailed;

        file.errors.push("file-uploader.invalid-min-file-size");
    }

    return file;
};

export const getFileUploaderSizeLabel = (sizeInBytes: number): string => {
    if (sizeInBytes < (1024 * 1024)) {
        return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    }

    return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
};

export const readFileChunk = (file: File, offset: number, chunkSize: number, reader: FileReader): number => {
    if (offset < file.size) {
        const slice = file.slice(offset, offset + chunkSize);
        reader.readAsText(slice);
        offset += chunkSize;
    }

    return offset;
}