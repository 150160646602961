export enum BLErrorCode {
    Success = 0,
    InnerError,
    ExternalError,
    IncorrectLoginOrPassword,
    InvalidDate,
    NotValidFormatOfTruckNumber,
    NotValidLengthOfTruckNumber,
    DriverPhoneRequired,
    DriverDataFormatNotValid,
    DriverDataRequired,
    NeedDataRefresh,
    OrderHasRelatedInvoices,
    OrderHasRelatedInsuranceOperations,
    ProfitTransferCreated,
    LockErrorPickUp,
    LockErrorDelivery,
    LockErrorOther,
    PickUpDateRequired,
    IncorrectPeriod,
    PlannedModeNotChoose,
    CmrNotSupportedFormat,
    CmrFileIsBig,
    CmrFileIsEmpty,
    FinFileIsEmpty,
    ReasonNotSelected,
    OrderStatusChanged,
    RefNumNotValid,
    LoadingNumNotValid,
    EdiLoadIdNotValid,
    EdiNumberNotValid,
    NotConfirmed,
    CannotAddEventInFuture,
    CannotEditUnloadDate,
    CannotChangeShipmentCompleteDate,
    MultiEventsError,
    EventDateIsMissing,
    OrderNotFound,
    NotValidData,
    ClientIsTRANSPOREON,
    ClientIsIOR,
    SupplierNotFound,
    ContractNotFound,
    EquipmentGroupRequired,
    BetRequired,
    BetNotValid,
    SellRateRequired,
    SellRateNotValid,
    AsstraCompanyRequired,
    TillDateNotValid,
    RateCanNotBeAccepted,
    RateCanNotBeRecall,
    EarlyPickupDateRequired,
    LateDeliveryDateRequired,
    LatePickupDateEearlierThanEarlyPickupDate,
    LateDeliveryDateEearlierThanEarlyDeliveryDate,
    LateDeliveryDateEearlierThanEarlyPickupDate,
    EarlyPickupDateRequiredBecauseLateDeliveryDateFilled,
    EarlyPickupDateMustBeLaterLastDate,
    EarlyDeliveryDateMustBeLaterLastDate,
    LatePickupDateMustBeLaterLastDate,
    LateDeliveryDateMustBeLaterLastDate,
    EarlyPickupDateMustBeEarlierCurrentDate,
    ContractExpirationDateError,
    MustBeGreaterZero,
    DateMustBeEarlierCurrentDate,
    SpNotAllowed,
    ServiceProviderNotAllowed,
    LateDeliveryDateIsMissing,
    FieldIsRequired,
    SpecificCustomer,
    ClientHasDelayedPayments,
    ClientNotReal,
    ClientHasLowCredit,
    ClientWorksInAdvance,
    NoStandartOperation,
    ValidateExecutionAgentError,
    NoRecordsFound,
    ConfirmationNotAccepted,
    OrderNotAllowedForSupplier,
    NoAccess,
    CompanyNameRequired,
    VATNumberRequired,
    ContactPersonFullNameRequired,
    ContactPersonPhoneRequired,
    ContactPersonEmailRequired,
    SourceRequired,
    SourceFromNotesRequired,
    CompanyTypeRequired,
    CountryRequired,
    CityRequired,
    AddressRequired,
    CompanyPhoneRequired,
    CompanyEmailRequired,
    DirectorRequired,
    PositionRequired,
    RecaptchaValidationError,
    InvalidEmailFormat,
    MustBeOnlyOneEmail,
    EmailIsAlreadyRegistered,
    PhoneIsAlreadyRegistered,
    RequiredField,
    AlreadyExists,
    CmrHasInvoice,
    NotFoundCustomerAttachmentDoc,
    CustomerAttachmentNotSupportedFormat,  
    CustomerAttachmentFileIsBig,  
    CustomerAttachmentFileIsEmpty,
    TemperatureFieldRequired,
    FuelFieldRequired,
    LoadFieldRequired,
    VehicleForGreenRouterFieldRequired,
    ShipmentGidIsNull,
    MaxLimitExceeded
}