import React from 'react';
import cl from './SelfBillingExpenseListItemOverview.module.css';
import {ISelfBillingExpense} from "../../../../../../../app/interfaces/selfBilling/ISelfBillingExpense";
import {useTranslation} from "react-i18next";
import Tag from "../../../../../../../components/shared/components/Tag/Tag";
import uuid from "react-uuid";
import {getCmrStatus, getExpenseStatus, getVatNote} from "../../utils";
import useMediaQuery from "../../../../../../../hooks/useMediaQuery/useMediaQuery";
import {CmrDocumentType} from "../../../../../../../app/enums/CmrDocumentType";
import {useDownloadFile} from "../../../../../../../hooks/useDownloadFile/useDownloadFile";
import {EcmStatus} from "../../../../../../../app/enums/EcmStatus";
import {IResponse, isResponse} from "../../../../../../../app/interfaces/response/IResponse";
import {isAxiosError} from "axios";
import {ResponseResultCode} from "../../../../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import {ICmrDocument} from "../../../../../../../app/interfaces/cmr/ICmrDocument";
import useSelfBillingApi from "../../../../../../../app/api/selfBilling";
import {ISelfBilling} from "../../../../../../../app/interfaces/selfBilling/ISelfBilling";
import {IUser} from "../../../../../../../app/interfaces/user/IUser";

type SelfBillingExpenseListItemOverviewProps = {
    expense: ISelfBillingExpense;
    isSidePanelDetails: boolean;
    isAnonymousAccess: boolean;
    user?: IUser;
};

const SelfBillingExpenseListItemOverview: React.FC<SelfBillingExpenseListItemOverviewProps> = ({
                                                                                                   expense,
                                                                                                   isSidePanelDetails,
                                                                                                   isAnonymousAccess,
                                                                                                   user
                                                                                               }) => {
    const isMobileView = useMediaQuery('(max-width:1024px)');
    const {t} = useTranslation();
    const {
        getAttachmentAnonymous: {
            query: getAttachmentAnonymous
        },
        downloadCmrDocumentAnonymous: {
            query: downloadCmrDocumentAnonymous
        },
        getAttachmentAuthorize: {
            query: getAttachmentAuthorize
        },
        downloadCmrDocumentAuthorize: {
            query: downloadCmrDocumentAuthorize
        }
    } = useSelfBillingApi();

    const {
        download
    } = useDownloadFile({
        target: '_blank',
        getBlob: async (param?: string, param2?: string) => {
            if (!param || !param2 || !invoice) {
                return;
            }

            let isAuthorize = user !== undefined && !isAnonymousAccess;

            try {
                    const response = isAuthorize
                        ? await downloadCmrDocumentAuthorize(expense.shipmentGid, invoice.cmrGuid)
                        : await downloadCmrDocumentAnonymous(expense.shipmentGid, invoice.cmrGuid);

                    if (response?.status === 200 && !isResponse(response.data)) {
                        return response.data;
                    }

            } catch (err) {
                if (isAxiosError(err) && err.response && err.response.data && err.response.data.type === "application/json") {
                    const textResponse = await err.response?.data?.text();

                    if (textResponse && typeof textResponse === 'string') {
                        let response = JSON.parse(textResponse) as IResponse;

                        if (response.resultCode === ResponseResultCode.NotValidData) {
                            toast.error(`Unable to download file. Error: Input parameter is invalid.`)
                        }

                        if (response.resultCode === ResponseResultCode.NotFound) {
                            toast.error(`Unable to download file. Error: File is not found.`)
                        }
                    }
                }
            }
        }
    });

    let ecmStatus = getCmrStatus(expense.ecmStatus);
    let ecmInStatus = getCmrStatus(expense.ecmInStatus);
    let invoice: ICmrDocument | null = null;
    if(expense.cmrDocuments.filter(e => e.documentType == CmrDocumentType.Invoice).length > 0) {
        invoice = expense.cmrDocuments.filter(e => e.documentType == CmrDocumentType.Invoice)[0];
    }

    let expenseName: string;

    if (expense.isFreight) {
        expenseName = t("self-billing.labels.freight").toUpperCase();
    } else if (expense.expenseGid !== null && expense.expenseGid !== '') {
        expenseName = expense.expenseGid.replace('AAG.', '').replace('_', ' ');
    } else {
        expenseName = expense.expenseName ?? '';
    }

    if (isSidePanelDetails || isMobileView) {
        return (
            <div className={cl.container}
                 data-view={'mobile'}>
                <div className={cl.col}>
                    <div>
                        <label>
                            {t("shipment.form.rate")}
                        </label>
                        <div>
                            {expense.rate}
                        </div>
                    </div>

                    {expense.vat !== null && expense.vatNote !== null && expense.vatNote !== '' &&
                        <>
                            <div>
                                <label>
                                    {t("self-billing.labels.sum-with-vat")}
                                </label>
                                <div>
                                    {expense.rateWithVAT}
                                </div>
                            </div>

                            <div>
                                <label>
                                    {t("self-billing.labels.vat")}
                                </label>
                                <div>
                                    {`${expense.vat} % ${getVatNote(expense.vatNote) ? `(${t(getVatNote(expense.vatNote) ?? '')})` : ''}`}
                                </div>
                            </div>
                        </>
                    }



                    <div>
                        <label>
                            {t("self-billing.labels.bank-account")}
                        </label>
                        <div>
                            {expense.bankAccount ?? ''}
                        </div>
                    </div>
                    <div>
                        <label>
                            {t("self-billing.labels.payment-receiver")}
                        </label>
                        <div>
                            {expense.anotherPaymentRecipient ?? ''}
                        </div>
                    </div>
                </div>
                <div className={cl.col}>


                    <div>
                        <label>
                            {t("self-billing.labels.expense-status")}
                        </label>
                        <div className={cl.tag}>
                            {expense.expenseStatus !== null &&
                                <Tag item={{
                                    id: `expense-status-${uuid()}`,
                                    text: t(getExpenseStatus(expense.expenseStatus)[0]),
                                    title: t(getExpenseStatus(expense.expenseStatus)[0]),
                                    showDeleteIcon: false,
                                    showEditIcon: false,
                                    showHoverDeleteIcon: false,
                                    showHoverEditIcon: false,
                                    hoverMagnification: false,
                                    backgroundColor: getExpenseStatus(expense.expenseStatus)[1]
                                }}
                                     size={'full'}
                                     containerMaxWidth={200}/>
                            }
                        </div>
                    </div>

                    <div>
                        <label>
                            {t("self-billing.labels.attachments")}
                        </label>
                        <div className={cl.tag}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 alignItems: 'start',
                                 gap: '5px'
                             }}>
                            {ecmStatus !== null &&
                                <Tag size={'full'}
                                     containerMaxWidth={200}
                                     item={{
                                         id: `expense-ecm-status-${uuid()}`,
                                         text: t(ecmStatus[0]),
                                         title: t(ecmStatus[0]),
                                         showDeleteIcon: false,
                                         showEditIcon: false,
                                         showHoverDeleteIcon: false,
                                         showHoverEditIcon: false,
                                         hoverMagnification: false,
                                         backgroundColor: ecmStatus[1]
                                     }}/>
                            }

                            {ecmInStatus !== null &&
                                <a style={{cursor:"pointer"}} onClick={async () => await download(invoice?.fileName ?? '', invoice?.id.toString())}>

                                <Tag item={{
                                    id: `expense-ecm-in-status-${uuid()}`,
                                    text: t(ecmInStatus[0]),
                                    title: t(ecmInStatus[0]),
                                    showDeleteIcon: false,
                                    showEditIcon: false,
                                    showHoverDeleteIcon: false,
                                    showHoverEditIcon: false,
                                    hoverMagnification: false,
                                    backgroundColor: ecmInStatus[1]
                                }}
                                     size={'full'}
                                     containerMaxWidth={200}/></a>
                            }
                        </div>
                    </div>

                    <div>
                        <label>
                            {t("self-billing.labels.supplier-invoice-number")}
                        </label>
                        <div>
                            {expense.invoiceNumber ?? ''}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={cl.container}>
            <div className={cl.col}>
                <div>
                    <label>
                        {t("shipment.form.rate")}
                    </label>
                    <div>
                        {expense.rate}
                    </div>
                </div>

                {expense.vat !== null && expense.vatNote !== null && expense.vatNote !== '' &&
                    <>
                        <div>
                            <label>
                                {t("self-billing.labels.sum-with-vat")}
                            </label>
                            <div>
                                {expense.rateWithVAT}
                            </div>
                        </div>

                        <div>
                            <label>
                                {t("self-billing.labels.vat")}
                            </label>
                            <div>
                                {`${expense.vat} % ${getVatNote(expense.vatNote) ? `(${t(getVatNote(expense.vatNote) ?? '')})` : ''}`}
                            </div>
                        </div>
                    </>
                }

                <div>
                    <label>
                        {t("self-billing.labels.supplier-invoice-number")}
                    </label>
                    <div>
                        {expense.invoiceNumber ?? ''}
                    </div>
                </div>
            </div>

            <div className={cl.col}>




                <div>
                    <label>
                        {t("self-billing.labels.bank-account")}
                    </label>
                    <div>
                        {expense.bankAccount ?? ''}
                    </div>
                </div>
                <div>
                    <label>
                        {t("self-billing.labels.payment-receiver")}
                    </label>
                    <div>
                        {expense.anotherPaymentRecipient ?? ''}
                    </div>
                </div>
            </div>

            <div className={cl.col}>
                <div>
                    <label>
                        {t("self-billing.labels.attachments")}
                    </label>
                    <div className={cl.tag}
                         style={{
                             display: 'flex',
                             flexDirection: 'row',
                             alignItems: 'start',
                             gap: '5px'
                         }}>
                        {ecmStatus !== null &&
                            <Tag item={{
                                id: `expense-ecm-status-${uuid()}`,
                                text: t(ecmStatus[0]),
                                title: t(ecmStatus[0]),
                                showDeleteIcon: false,
                                showEditIcon: false,
                                showHoverDeleteIcon: false,
                                showHoverEditIcon: false,
                                hoverMagnification: false,
                                backgroundColor: ecmStatus[1]
                            }}
                                 size={'full'}
                                 containerMaxWidth={200}/>
                        }

                        {ecmInStatus !== null &&
                            <a style={{cursor:"pointer"}} onClick={async (ev) => { ev.stopPropagation(); await download(invoice?.fileName ?? '', invoice?.id.toString())}}>
                            <Tag item={{
                                id: `expense-ecm-in-status-${uuid()}`,
                                text: t(ecmInStatus[0]),
                                title: t(ecmInStatus[0]),
                                showDeleteIcon: false,
                                showEditIcon: false,
                                showHoverDeleteIcon: false,
                                showHoverEditIcon: false,
                                hoverMagnification: false,
                                backgroundColor: ecmInStatus[1]
                            }}
                                 size={'full'}
                                 containerMaxWidth={200}/></a>
                        }
                    </div>
                </div>
                <div>
                    <label>
                        {t("self-billing.labels.expense-status")}
                    </label>
                    <div className={cl.tag}>
                        {expense.expenseStatus !== null &&
                            <Tag size={'full'}
                                 containerMaxWidth={200}
                                 item={{
                                     id: `expense-status-${uuid()}`,
                                     text: t(getExpenseStatus(expense.expenseStatus)[0]),
                                     title: t(getExpenseStatus(expense.expenseStatus)[0]),
                                     showDeleteIcon: false,
                                     showEditIcon: false,
                                     showHoverDeleteIcon: false,
                                     showHoverEditIcon: false,
                                     hoverMagnification: false,
                                     backgroundColor: getExpenseStatus(expense.expenseStatus)[1]
                                 }}/>
                        }
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SelfBillingExpenseListItemOverview;
