import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useOfferApi} from "../../../../../../app/api/offer";
import {isAxiosError} from "axios";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../../components/uiLayouts/ToastLayout/ToastLayout";
import FormLoadingScreen from "../../../../../../components/ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {IConfirmAuctionGetAction} from "../../../../../../app/interfaces/offer/IConfirmAuctionGetAction";
import {IConfirmAuctionPostAction} from "../../../../../../app/interfaces/offer/IConfirmAuctionPostAction";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import Button, {ButtonType} from "../../../../../../components/ui/Button/Button";
import ConfirmAuctionFormServiceProvider from "./ConfirmAuctionFormServiceProvider/ConfirmAuctionFormServiceProvider";
import {IError} from "../../../../../../app/interfaces/response/IResponse";
import {BLErrorCode} from "../../../../../../app/enums/BLErrorCode";
import Alert from "../../../../../../components/ui/Alert/Alert";

type ConfirmAuctionServiceProviderProps = {
    id: string | Array<string>;
    close?: () => void;
};

const ConfirmAuctionServiceProvider: React.FC<ConfirmAuctionServiceProviderProps> = ({
                                                                                         id,
                                                                                         close
                                                                                     }) => {
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);
    const [model, setModel] = useState<IConfirmAuctionGetAction | null>(null);
    const [_isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError>>([]);

    const {
        getConfirmAuctionData: {
            isLoading: getConfirmAuctionDataIsLoading,
            cancel: cancelGetConfirmAuctionData,
            query: getConfirmAuctionData
        },
        confirmAuction: {
            cancel: cancelConfirmAuction,
            mutation: confirmAuction
        },
        getConfirmAuctionDataAnonymous: {
            isLoading: getConfirmAuctionDataAnonymousIsLoading,
            cancel: cancelGetConfirmAuctionDataAnonymous,
            query: getConfirmAuctionDataAnonymous
        },
        confirmAuctionAnonymous: {
            cancel: cancelConfirmAuctionAnonymous,
            mutation: confirmAuctionAnonymous
        },
    } = useOfferApi();

    useEffect(() => {
        if (!id || id === '' || Array.isArray(id) && id.length <= 0) {
            if (close) {
                close();
            }

            return;
        }

        (async () => {
            try {
                const response = user
                    ? await getConfirmAuctionData(id, user.serviceProvider?.id ?? null)
                    : await getConfirmAuctionDataAnonymous(id, null);

                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch (e) {
                if (isAxiosError(e)) {
                    toast.error<string>('Unable to get confirm auction data');

                    if (close) {
                        close();
                    }
                }
            }
        })();

        return () => {
            cancelGetConfirmAuctionData();
            cancelConfirmAuction();

            cancelGetConfirmAuctionDataAnonymous();
            cancelConfirmAuctionAnonymous();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const save = async (model: IConfirmAuctionPostAction): Promise<void> => {
        setDisabled(prev => !prev);

        try {
            const response = user
                ? await confirmAuction(model)
                : await confirmAuctionAnonymous(model);

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                document.dispatchEvent(new CustomEvent('onGridMessage', {
                    detail: {
                        action: 'updateRows',
                        rows: [...id].map((item) => {
                            return {
                                id: item,
                                isRowLoading: true
                            };
                        })
                    }
                }));

                toast.success(<ToastLayout text={t("shared.labels.delayed-update-warning")}
                                           title={`${t("shared.labels.offer")} ${model.orderReleaseGid}`}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotFound &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();
                    toast.error(err.message);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if (!id || id === '' || Array.isArray(id) && id.length <= 0) {
        return null;
    }

    if (getConfirmAuctionDataIsLoading || getConfirmAuctionDataAnonymousIsLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                {errors.length > 0 && errors.map((err, index) => {
                    switch (err.errorCode) {
                        case BLErrorCode.InnerError:
                            return (
                                <Alert type={'Error'}
                                       id={`confirm-auction-error-${index}`}>
                                    {err.description}
                                </Alert>
                            )
                    }

                    return null;
                })}

                <ConfirmAuctionFormServiceProvider model={model}
                                                   save={save}
                                                   disabled={disabled}
                                                   setIsSubmitDisabled={setIsSubmitDisabled}/>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        form='confirm-auction-form'
                        type='submit'
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.confirm")}
                </Button>
            </div>
        </div>
    );
};

export default ConfirmAuctionServiceProvider;
