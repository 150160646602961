import React from 'react';
import {SelectModel} from "../../../../../app/types/SelectModel";
import cl from "../../AccessSettings/AccessSettings.module.css";
import GridFilterPanelLayoutCollapse
    from "../../../../uiLayouts/GridFilterPanelLayout/components/GridFilterPanelLayoutCollapse/GridFilterPanelLayoutCollapse";
import {useTranslation} from "react-i18next";
import FilterPanelSupplierFilterContent from "./FilterPanelSupplierFilterContent";
import Icon from "../../../../../assets/icon/Icon";
import FilterPanelSupplierFilterSelectedContainer from "./FilterPanelSupplierFilterSelectedContainer";

type FilterPanelSupplierFilterProps = {
    id: string;
    disabled?: boolean;
    onClear: (value?: string) => void;
    onChange: (value: Array<SelectModel>) => void,
    value: Array<SelectModel>;
}

const FilterPanelSupplierFilter: React.FC<FilterPanelSupplierFilterProps> = ({
                                                                                 id,
                                                                                 value,
                                                                                 disabled,
                                                                                 onClear,
                                                                                 onChange
                                                                             }) => {
    const {t} = useTranslation();

    return (
        <GridFilterPanelLayoutCollapse titleBold={false}
                                       defaultExpanded={false}
                                       showSeparationHeaderLine={false}
                                       hideOnFilterPanelLayoutContextClear={true}
                                       style={{margin: '5px 0 10px 0'}}
                                       tagsContainer={<FilterPanelSupplierFilterSelectedContainer id={`${id}-tags`}
                                                                                                  value={value}
                                                                                                  onClear={onClear}/>}
                                       title={
                                           <>
                                               <div>
                                                   {t("shared.labels.supplier")}
                                               </div>
                                               <div>
                                                   {value.length > 0
                                                       ? <Icon icon={"faTrash"}
                                                               className={cl.trash}
                                                               onClick={(ev) => {
                                                                   ev.stopPropagation();

                                                                   if (disabled) {
                                                                       return;
                                                                   }

                                                                   onClear();
                                                               }}/>
                                                       : null
                                                   }
                                               </div>
                                           </>
                                       }>
            {({isExpanded}) =>
                <div>
                    {isExpanded
                        ? <FilterPanelSupplierFilterContent id={id}
                                                            onChange={onChange}
                                                            value={value}/>
                        : <div style={{height: '250px'}}>
                        </div>
                    }
                </div>
            }
        </GridFilterPanelLayoutCollapse>
    );
};

export default FilterPanelSupplierFilter;