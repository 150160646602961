import {IChatManagerContext} from "../interfaces/IChatManagerContext";
import {useCallback} from "react";
import {IChatReplyTo} from "../../../../../app/interfaces/chat/IChat";
import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {selectIsChatDialogOpened} from "../../../../../features/chat/chatSliceSelectors";
import useChatApi from "../../../../../app/api/chat";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {setChat, setIsChatDialogOpened, UNSAFE_setIsSendMessageOpen} from "../../../../../features/chat/chatSlice";

const useChatManager = (): IChatManagerContext => {
    const isChatDialogOpened = useAppSelector(selectIsChatDialogOpened);
    const dispatch = useAppDispatch();

    const {
        getUserChatData: {
            query
        }
    } = useChatApi();

    const sendMessage = useCallback((login: string, replyTo?: IChatReplyTo) => {
        (async () => {
            try {
                const response = await query(login);
                if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok && response.data.data) {
                    dispatch(setChat({
                        ...response.data.data,
                        replyTo
                    }));

                    if (!isChatDialogOpened) {
                        dispatch(UNSAFE_setIsSendMessageOpen(true));
                        dispatch(setIsChatDialogOpened(true));
                    }
                }
            } catch {
                // ignore
            }
        })();
    }, [isChatDialogOpened]);

    return {
        sendMessage
    };
}

export default useChatManager;