import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'triangle',
    pathData: [
        'M6.76795 1C7.53775 -0.333332 9.46225 -0.333334 10.2321 1L16.7272 12.25C17.497 13.5833 16.5348 15.25 14.9952 15.25H2.00481C0.46521 15.25 -0.497042 13.5833 0.272758 12.25L6.76795 1Z'
    ],
    viewBox: '0 0 17 16'
};

export default icon;