import {IIcon} from "../interfaces/IIcon";

const icon: IIcon = {
    name: 'broadcast',
    pathData: [
        'M12.828 7.0711L12.8306 7.07372M18.7109 6.84501C18.7036 5.36202 18.1609 3.91874 17.0706 2.82843C15.9803 1.73811 14.537 1.1954 13.054 1.18815M12.9783 3.1162C13.9685 3.14058 14.9273 3.5135 15.6564 4.24266C16.3856 4.97183 16.7585 5.93061 16.7829 6.92078M14.8495 6.98551C14.8087 6.49283 14.6074 6.02205 14.2422 5.65685C13.8715 5.28614 13.3919 5.08424 12.8912 5.04776',
    ],
    pathDataExtended: [
        {
            data: 'M0.5 9C0.5 8.17157 1.17157 7.5 2 7.5H9C9.82843 7.5 10.5 8.17157 10.5 9V16.5H2C1.17157 16.5 0.5 15.8284 0.5 15V9Z',
            fill: 'currentColor'
        },
        {
            data: 'M14.0401 9.5H12.5V16.5H16.5C17.0523 16.5 17.5 16.0523 17.5 15.5V13.3699C17.5 13.1312 17.4146 12.9004 17.2593 12.7191L14.7993 9.84921C14.6093 9.62756 14.332 9.5 14.0401 9.5Z',
            fill: 'currentColor'
        }
    ],
    circle: [
        {cx: 4.5, cy: 16.5, r: 2.5}, {cx: 13.5, cy: 16.5, r: 2.5}
    ],
    viewBox: '0 0 20 20',
    strokeWidth: 1,
    strokeLinejoin: 'round',
    strokeLinecap: 'round'
};

export default icon;