import React from 'react';
import cl from './SkeletonFormLoader.module.css';
import Skeleton from "react-loading-skeleton";
import Loader, {LoaderType} from "../../../Loader/Loader";

const SkeletonFormLoader = () => {
    return (
        <div className={`w100 h100 ${cl.container}`}>
            <div className={cl.buttons}>
                <div>
                    <Skeleton height={'35px'}
                              width={'100px'}
                              style={{borderRadius: '2px'}}/>
                </div>
                <div>
                    <Skeleton height={'35px'}
                              width={'100px'}
                              style={{borderRadius: '2px'}}/>
                    <Skeleton height={'35px'}
                              width={'100px'}
                              style={{borderRadius: '2px'}}/>
                </div>
            </div>
            <div className={cl.info}>
                <div>
                    <div>
                        <Skeleton height={'15px'}
                                  width={'100%'}
                                  style={{borderRadius: '2px'}}/>
                        <Skeleton height={'15px'}
                                  width={'100%'}
                                  style={{borderRadius: '2px'}}/>
                        <Skeleton height={'15px'}
                                  width={'100%'}
                                  style={{borderRadius: '2px'}}/>
                    </div>
                </div>
                <div>
                    <div>
                        <Skeleton height={'15px'}
                                  width={'100%'}
                                  style={{borderRadius: '2px'}}/>
                        <Skeleton height={'15px'}
                                  width={'100%'}
                                  style={{borderRadius: '2px'}}/>
                        <Skeleton height={'15px'}
                                  width={'100%'}
                                  style={{borderRadius: '2px'}}/>
                    </div>
                </div>
                <div>
                    <div>
                        <Skeleton height={'15px'}
                                  width={'100%'}
                                  style={{borderRadius: '2px'}}/>
                        <Skeleton height={'15px'}
                                  width={'100%'}
                                  style={{borderRadius: '2px'}}/>
                        <Skeleton height={'15px'}
                                  width={'100%'}
                                  style={{borderRadius: '2px'}}/>
                    </div>
                </div>
            </div>
            <div>
                <div className={'action-loader-container'}>
                    <div>
                        <Loader type={LoaderType.Action} style={{scale: '0.4'}}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonFormLoader;