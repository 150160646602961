import React from 'react';
import {useGridCellHandleClick} from "../../../../../../components/ui/Grid/Telerik/hooks/useGridCellHandleClick";

const CountryTemplate = (props: any) => {
    const {handleClick} = useGridCellHandleClick(props.dataItem);

    let val = props.dataItem[props.field];

    return (
        <td {...props.tdProps}
            colSpan={1}
            onClick={handleClick}>
           <span className={'default-center-cell'}>
                {val !== null && val !== undefined && val !== '' &&
                    <img src={`/images/flags/${val.toString().toUpperCase()}.png`} alt={val} title={val}/>
                }
            </span>
        </td>
    );
};

export default CountryTemplate;