import React, {useEffect, useRef, useState} from 'react';
import {GridActionRowTagItem} from "../../GridActionRowTagsContainer";
import cl from './GridActionRowTag.module.css';
import Icon from "../../../../../../../../assets/icon/Icon";
import apiAxios from "../../../../../../../../app/axios/apiAxios";
import {Query} from "../../../../../../../../app/types/Query";
import useIfFirstRender from "../../../../../../../../hooks/useIsFirstRender/useIfFirstRender";
import Skeleton from "react-loading-skeleton";

type GridActionRowTagProps = {
    onRender?: (width: number, height: number) => void;
    render?: boolean;
} & GridActionRowTagItem;

const GridActionRowTag: React.FC<GridActionRowTagProps> = ({
                                                               id,
                                                               text,
                                                               tooltip,
                                                               icon,
                                                               iconColor,
                                                               showClearIcon,
                                                               onRender,
                                                               render,
                                                               onClear,
                                                               isTab,
                                                               selected,
                                                               onClick,
                                                               countUrl,
                                                               query
                                                           }) => {
    const ref = useRef<HTMLDivElement | null>(null);

    const [count, setCount] = useState<number>(0);
    const [countLoading, setCountLoading] = useState<boolean>(false);

    const localQuery = useRef<Query | null>(null);
    const isFirstRender = useIfFirstRender();

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        if (onRender) {
            let computedStyle = ref.current?.getBoundingClientRect();

            onRender(computedStyle.width, computedStyle.height);
        }
    }, []);

    useEffect(() => {
        if (!countUrl || !query || render || !isTab) {
            return;
        }

        localQuery.current = query;

        (async () => {
            await loadCount();
        })();
    }, []);

    useEffect(() => {
        if (isFirstRender || !localQuery.current || Query.compare(localQuery.current, query) || !isTab || render) {
            return;
        }

        localQuery.current = query ?? null;

        (async () => {
            await loadCount();
        })();
    }, [query, isFirstRender]);

    const loadCount = async () => {
        try {
            setCountLoading(prev => !prev);

            const response = await apiAxios.get(`${countUrl}?${query}`);

            if (response?.status === 200 && response?.data && response.data.data !== null && response.data.data !== undefined) {
                setCount(response.data.data);
            }
        } catch (e) {
            setCount(0);
            setCountLoading(prev => !prev);
        }

        setCountLoading(prev => !prev);
    }

    return (
        <div id={id}
             ref={ref}
             data-icon={showClearIcon ? 'true' : 'false'}
             data-count={isTab && countUrl ? 'true' : 'false'}
             onClick={onClick}
             title={tooltip}
             data-type={isTab ? 'tab' : 'tag'}
             className={`${cl.tag} ${isTab && selected ? cl.selected : ''} ${isTab ? 'prevent-selection' : ''}`}
             style={render ? {visibility: "hidden"} : undefined}>
            {isTab
                ? <>
                    {isTab && countUrl
                        ? <>
                            <div>
                                {icon && <Icon style={{color: iconColor}} icon={icon}/>}
                                {text}
                            </div>
                            <div style={{
                                width: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                {countLoading || (render && countUrl)
                                    ? <Skeleton height={'15px'}
                                                width={'20px'}
                                                style={{borderRadius: '2px'}}/>
                                    : null
                                }

                                {!render && !countLoading &&
                                    <>{count}</>
                                }
                            </div>
                        </>
                        : <>{icon && <Icon style={{color: iconColor}} icon={icon}/>}{text}</>
                    }
                </>
                : <>
                    {showClearIcon
                        ? <div>
                            <div>
                                {icon && <Icon style={{color: iconColor}} icon={icon}/>}
                                {text}
                            </div>

                            <div onClick={(ev) => {
                                ev.stopPropagation();

                                if (onClear) {
                                    onClear();
                                }
                            }}>
                                <Icon icon={"faXMark"}/>
                            </div>
                        </div>
                        : <>
                            {icon && <Icon style={{color: iconColor}} icon={icon}/>}
                            {text}
                        </>
                    }
                </>
            }
        </div>
    );
};

export default GridActionRowTag;
