import React from 'react';
import {IUser} from "../../../../../../../app/interfaces/user/IUser";
import SelfBillingExpenseListItem from "../SelfBillingExpenseListItem/SelfBillingExpenseListItem";
import {ISelfBilling} from "../../../../../../../app/interfaces/selfBilling/ISelfBilling";
import uuid from "react-uuid";
import cl from './SelfBillingExpenseList.module.css';

type SelfBillingExpenseListProps = {
    model: ISelfBilling;
    isAnonymousAccess: boolean;
    isSidePanelDetails: boolean;
    user?: IUser;
};

const SelfBillingExpenseList: React.FC<SelfBillingExpenseListProps> = ({
                                                                           model,
                                                                           isAnonymousAccess,
                                                                           user,
                                                                           isSidePanelDetails
                                                                       }) => {
    return (
        <div className={cl.container}>
            {
                [...model.expenses]
                    .sort((a, b) => {
                        return (Number(b.isFreight) - Number(a.isFreight));
                    })
                    .map((expense, index) => {
                        return <SelfBillingExpenseListItem key={`${index}-${uuid()}`}
                                                           expense={expense}
                                                           model={model}
                                                           isAnonymousAccess={isAnonymousAccess}
                                                           user={user}
                                                           isSidePanelDetails={isSidePanelDetails}/>
                    })
            }
        </div>
    );
};

export default SelfBillingExpenseList;