import React from 'react';
import {IServiceProvider} from "../../../../../app/interfaces/shared/IServiceProvider";
import {IChatReplyTo} from "../../../../../app/interfaces/chat/IChat";
import viberIcon from "../../../../../assets/images/viber.png";
import cl from './SupplierField.module.css';
import ttTrackingIcon from "../../../../../assets/images/tt-tracking.png";
import shippeoTrackingIcon from "../../../../../assets/images/shippeo-tracking.png";
import {useTranslation} from "react-i18next";
import notRegisteredIcon from "../../../../../assets/images/not-registed.png";
import UserChat from "../../../components/Chat/components/UserChat/UserChat";
import {UserRole} from "../../../../../app/enums/UserRole";
import {useAppSelector} from "../../../../../app/store";
import {selectCurrentUser} from "../../../../../features/authentication/authenticationSliceSelectors";
import SupplierData from "../SupplierData/SupplierData";
import Icon from "../../../../../assets/icon/Icon";

type SupplierFieldProps = {
    user: IServiceProvider;
    chatReplyTo?: IChatReplyTo;
};

const SupplierField: React.FC<SupplierFieldProps> = ({
                                                         user,
                                                         chatReplyTo
                                                     }) => {
    const currentUser = useAppSelector(selectCurrentUser);
    const {t} = useTranslation();

    if (!currentUser) {
        return null;
    }

    let name: string;

    if (user.name) {
        name = user.name;

        if (user.extName) {
            name += ` ${user.extName}`;
        }
    } else {
        name = user.extName ?? '';
    }


    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '3px'
        }}>
            {user.hasKpAccount
                ? <UserChat user={{
                    role: UserRole.ServiceProvider,
                    groupId: user.group ?? undefined,
                    id: user.id,
                    serviceProvider: user.id
                }}
                            replyTo={chatReplyTo}/>
                : <img src={notRegisteredIcon}
                       className={cl.img}
                       alt={t('shared.labels.service-provider-not-registered')}
                       title={t('shared.labels.service-provider-not-registered')}/>
            }

            {user.hasMessengersSubscription &&
                <img src={viberIcon}
                     className={cl.img}
                     alt={t('shared.labels.messengers-distribution-label')}
                     title={t('shared.labels.messengers-distribution-label')}/>
            }

            {user.isTTTracking &&
                <img src={ttTrackingIcon}
                     className={cl.img}
                     alt={t('shipment.grid.tt-tracking')}
                     title={t('shipment.grid.tt-tracking')}/>
            }

            {user.isShippeoTracking &&

                        <Icon icon={"faCircleLocationDot"}
                    title={t('shipment.grid.shippeo-tracking')}
                    style={{
                        verticalAlign: 'middle',
                        marginRight: '5px',
                        fontSize: '16px',
                        color: 'var(--main-orange-color)'
                    }}/>
            }

            <SupplierData user={{
                id: user.id,
                name
            }}/>
        </div>
    );
};

export default SupplierField;
