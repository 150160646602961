import React from 'react';
import Checkbox from "../../../ui/Checkbox/Checkbox";
import {RemarkName} from "../../../../app/enums/RemarkName";
import {getRemarkNameResource} from "../../../../helpers/remark";
import {useTranslation} from "react-i18next";
import uuid from "react-uuid";
import {IRemark} from "../../../../app/interfaces/shared/IRemark";
import cl from './RemarksDetails.module.css';
import Icon from "../../../../assets/icon/Icon";

type RemarksDetailsProps = {
    isEditingAllowed: boolean;
    remarks: Array<IRemark>;
    onRemoveButtonClick?: (remark: IRemark) => void;
    onEditButtonClick?: (remark: IRemark) => void;
}
const RemarksDetails: React.FC<RemarksDetailsProps> = ({
                                                           remarks,
                                                           isEditingAllowed,
                                                           onEditButtonClick,
                                                           onRemoveButtonClick
                                                       }) => {
    const {t} = useTranslation();

    if (remarks === null || remarks.length <= 0) {
        return null;
    }

    return (
        <div className={'details-box-default-container'}>
            {remarks.map((item, index) => {
                if (item === null || item.code === null) {
                    return null;
                }

                return (
                    <div className={`remark-${index} ${cl.remark}`}
                         data-display={item.code === RemarkName.FROM_SITE ? 'flex' : 'default'}
                         key={`${item.code}-${uuid()}`}>
                        <label>
                            {isEditingAllowed && item.code !== RemarkName.FROM_SITE
                                ? <>
                                    <Icon icon={"faPen"}
                                          className={`${cl.pen} transition-all-500`}
                                          title={t("shared.btn.edit")}
                                          onClick={() => {
                                              if (onEditButtonClick) {
                                                  onEditButtonClick(item);
                                              }
                                          }}/>

                                    <Icon icon={"faTrash"}
                                          className={`${cl.trash} transition-all-500`}
                                          title={t("shared.btn.delete")}
                                          onClick={() => {
                                              if (onRemoveButtonClick) {
                                                  onRemoveButtonClick(item);
                                              }
                                          }}/>
                                </>
                                : null
                            }

                            {t(getRemarkNameResource(item.code))}
                        </label>

                        <div style={{wordBreak: 'break-word'}}>
                            {item.code === RemarkName.FROM_SITE && <Checkbox checked={true} change={() => {
                                //ignore
                            }} disabled={true}/>}
                            {item.code === RemarkName.BUY_SHIPMENT_ERROR && <div style={{color: 'var(--red-color)'}}>{item.text}</div>}

                            {item.code != RemarkName.FROM_SITE && item.code != RemarkName.BUY_SHIPMENT_ERROR && <>{item.text}</>}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default RemarksDetails;

/*

     <div className={`view-row remark-${index}`} key={`${item.code}-${uuid()}`}
                         style={{marginBottom: '15px'}}>
                        <div className={'two-columns'}>
                            {isEditingAllowed && item.code !== RemarkName.FROM_SITE
                                ? <>
                                    <FontAwesomeIcon icon={faPen}
                                                     className={`${cl.pen} transition-all-500`}
                                                     title={t("shared.btn.edit")}
                                                     onClick={() => {
                                                         if (onEditButtonClick) {
                                                             onEditButtonClick(item);
                                                         }
                                                     }}/>
                                    <FontAwesomeIcon icon={faTrash}
                                                     className={`${cl.trash} transition-all-500`}
                                                     title={t("shared.btn.delete")}
                                                     onClick={() => {
                                                         if (onRemoveButtonClick) {
                                                             onRemoveButtonClick(item);
                                                         }
                                                     }}/>
                                </>
                                : null
                            }

                            <b style={{fontSize: '12px'}}>
                                {t(getRemarkNameResource(item.code))}
                            </b>
                        </div>
                        <div className={'two-columns'} style={{fontSize: '12px'}}>
                            {item.code === RemarkName.FROM_SITE
                                ? <Checkbox checked={true} change={() => {
                                    //ignore
                                }} disabled={true}/>
                                : <>{item.text}</>
                            }
                        </div>
                    </div>
 */
