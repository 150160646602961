import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {IError} from "../../../../../../app/interfaces/response/IResponse";
import {ITag} from "../../../../../../app/interfaces/tag/ITag";
import {useTagApi} from "../../../../../../app/api/tag";
import {toast} from "react-toastify";
import {ResponseResultCode} from "../../../../../../app/enums/ResponseResultCode";
import ToastLayout from "../../../../../uiLayouts/ToastLayout/ToastLayout";
import {isAxiosError} from "axios";
import FormLoadingScreen from "../../../../../ui/loaders/FormLoadingScreen/FormLoadingScreen";
import {BLErrorCode} from "../../../../../../app/enums/BLErrorCode";
import Alert from "../../../../../ui/Alert/Alert";
import Button, {ButtonType} from "../../../../../ui/Button/Button";
import TagForm from "../../components/TagForm/TagForm";
import {ISaveTagPostAction} from "../../../../../../app/interfaces/tag/ISaveTagPostAction";

type EditTagProps = {
    id: number;
    orderReleaseGid: string;
    close?: () => void;
};

const EditTag: React.FC<EditTagProps> = ({
                                             id,
                                             close
                                         }) => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError>>([]);
    const [model, setModel] = useState<ITag | null>(null);

    const {
        getTag: {
            query: getTag,
            cancel: cancelGetTag,
            isLoading
        },
        saveTag: {
            mutation: saveTag,
            cancel: cancelSaveTag
        }
    } = useTagApi();

    useEffect(() => {
        if (!id || id <= 0) {
            if (close) {
                close()
            } else {
                return;
            }
        }

        (async () => {
            try {
                const response = await getTag(id);
                if (response?.status === 200 && response.data && response.data.data && response.data.data) {
                    setModel(response.data.data);
                }
            } catch {
                if (close) {
                    close();
                } else {
                    toast.error<string>('Unable to get tag data');
                }
            }
        })();

        return () => {
            cancelGetTag();
            cancelSaveTag();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = async (tag: ISaveTagPostAction): Promise<void> => {
        setErrors([]);

        if (!model) {
            return;
        }

        setDisabled(prev => !prev);

        try {
            const response = await saveTag({
                id: tag.id,
                name: tag.name,
                isPublic: tag.isPublic,
                color: tag.color.indexOf('#') > -1
                    ? tag.color.substring(1, 7)
                    : tag.color
            });

            if (response?.status === 200 && response.data.resultCode === ResponseResultCode.Ok) {
                if (close) {
                    close();
                }

                toast.success(<ToastLayout text={t("tags.labels.tag-saved-text")}
                                           title={t("tags.labels.tag")}/>);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response && err.response.data && err.response.status === 422 &&
                    err.response.data.resultCode === ResponseResultCode.NotFound &&
                    err.response.data.errors && Array.isArray(err.response.data.errors)) {
                    setErrors(err.response.data.errors);
                } else if (close) {
                    close();

                    toast.error(err.message);
                }
            }
        }

        setDisabled(prev => !prev);
    }

    if (!id || id <= 0) {
        return null;
    }

    if (isLoading || model === null) {
        return (
            <FormLoadingScreen height={`200px`} style={{padding: '5px'}}/>
        )
    }

    return (
        <div className={'h100 w100'}>
            <div className={'dialog-default-content-container'}>
                {errors.map(item => {
                    switch (item.errorCode) {
                        case BLErrorCode.InnerError:
                            return (
                                <Alert type={'Error'}>
                                    {item.description}
                                </Alert>
                            );
                    }

                    return null;
                })}

                <div className={'pd-10'}>
                    <div className={'pd-10'}
                         style={{backgroundColor: 'var(--main-bright-gray-color)'}}>
                        <TagForm disabled={disabled}
                                 onTagUpdate={submit}
                                 onTagSave={submit}
                                 showButtons={false}
                                 tag={model}/>
                    </div>
                </div>
            </div>
            <div className={'dialog-default-buttons-container'}>
                {close &&
                    <Button buttonType={ButtonType.Transparent}
                            disabled={disabled}
                            style={{minWidth: '100px'}}
                            className={'close-button'}
                            onClick={close}>
                        {t("shared.btn.cancel")}
                    </Button>
                }
                <Button buttonType={ButtonType.Primary}
                        disabled={disabled}
                        type={'submit'}
                        form={'edit-tag-form'}
                        style={{minWidth: '150px'}}
                        className={'confirm-button'}
                        isLoading={disabled}>
                    {t("shared.btn.save")}
                </Button>
            </div>
        </div>
    );
};

export default EditTag;