import React from 'react';
import circularBlack from "../../../../../../../../assets/images/circular-black.png";
import circularGray from "../../../../../../../../assets/images/circular-gray.png";
import cl from './ShipmentsEventListItemProgressBarItem.module.css';
import {ExtendedEvent, ExtendedShipmentEvent} from "../../../ShipmentsEventList";
import ProgressBarItemDataSection from "../ProgressBarItemDataSection/ProgressBarItemDataSection";
import ProgressBarItemFormSection from "../ProgressBarItemFormSection/ProgressBarItemFormSection";
import Icon from "../../../../../../../../assets/icon/Icon";

type ShipmentsEventListItemProgressBarItemProps = {
    event: ExtendedEvent;
    shipmentEvent: ExtendedShipmentEvent;
    change: (event: ExtendedEvent) => void;
    disabled: boolean;
}

const ShipmentsEventListItemProgressBarItem: React.FC<ShipmentsEventListItemProgressBarItemProps> = ({
                                                                                                         event,
                                                                                                         shipmentEvent,
                                                                                                         change,
                                                                                                         disabled
                                                                                                     }) => {
    let circle = !event.isCompleted && shipmentEvent.isLatest
        ? circularBlack
        : (shipmentEvent.passed ? circularBlack : circularGray);

    return (
        <div className={`${cl.item} ${shipmentEvent.passed ? cl.passed : ''}`}>
            <div className={cl.line}
                 data-type={shipmentEvent.line}
                 data-passed={shipmentEvent.passed ? 'p' : 'np'}>

            </div>
            <div className={cl.circle}
                 data-passed={shipmentEvent.passed ? 'p' : 'np'}>
                {shipmentEvent.passed && <Icon icon={"faCheck"}/>}
            </div>
            {/*      <img className={cl.indicator}
                 src={circle}
                 alt={shipmentEvent.passed ? 'p' : 'np'}/>*/}
            <div className={cl.data}>
                <ProgressBarItemDataSection shipmentEvent={shipmentEvent}
                                            event={event}
                                            change={change}
                                            disabled={disabled}/>
                {shipmentEvent.isEditMode &&
                    <ProgressBarItemFormSection shipmentEvent={shipmentEvent}
                                                event={event}
                                                change={change}
                                                disabled={disabled}/>
                }
            </div>
        </div>
    );
};

export default ShipmentsEventListItemProgressBarItem;
