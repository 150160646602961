export enum EmailCategory {
    Unknown = 0,
    OrderRelease = 1,
    Shipment = 2,
    Transport = 3,
    User = 4,
    EmailHeader = 5,
    EmailFooter = 6,
    Quote = 7,
    EmbeddedItem = 8,
}