import {countTruthy} from "./data";

/**
 * Checks whether the browser is based on Trident (the Internet Explorer engine) without using user-agent.
 */
export function isTrident(): boolean {
    const w = window
    const n = navigator

    return (
        countTruthy([
            'MSCSSMatrix' in w,
            'msSetImmediate' in w,
            'msIndexedDB' in w,
            'msMaxTouchPoints' in n,
            'msPointerEnabled' in n,
        ]) >= 4
    )
}

/**
 * Checks whether the browser is based on EdgeHTML (the pre-Chromium Edge engine) without using user-agent.
 */
export function isEdgeHTML(): boolean {
    const w = window
    const n = navigator

    return (
        countTruthy(['msWriteProfilerMark' in w, 'MSStream' in w, 'msLaunchUri' in n, 'msSaveBlob' in n]) >= 3 &&
        !isTrident()
    )
}

/**
 * Checks whether the browser is based on Chromium without using user-agent.
 */
export function isChromium(): boolean {
    // Based on research in October 2020. Tested to detect Chromium 42-86.
    const w = window
    const n = navigator

    return (
        countTruthy([
            'webkitPersistentStorage' in n,
            'webkitTemporaryStorage' in n,
            n.vendor.indexOf('Google') === 0,
            'webkitResolveLocalFileSystemURL' in w,
            'BatteryManager' in w,
            'webkitMediaStream' in w,
            'webkitSpeechGrammar' in w,
        ]) >= 5
    )
}

/**
 * Checks whether the browser is based on mobile or desktop Safari without using user-agent.
 * All iOS browsers use WebKit (the Safari engine).
 */
export function isWebKit(): boolean {
    // Based on research in September 2020
    const w = window
    const n = navigator

    return (
        countTruthy([
            'ApplePayError' in w,
            'CSSPrimitiveValue' in w,
            'Counter' in w,
            n.vendor.indexOf('Apple') === 0,
            'getStorageUpdates' in n,
            'WebKitMediaKeys' in w,
        ]) >= 4
    )
}

/**
 * Checks whether the WebKit browser is a desktop Safari.
 */
export function isDesktopSafari(): boolean {
    const w = window

    return (
        countTruthy([
            'safari' in w, // Always false in Karma and BrowserStack Automate
            !('DeviceMotionEvent' in w),
            !('ongestureend' in w),
            !('standalone' in navigator),
        ]) >= 3
    )
}

/**
 * Checks whether the browser is based on Gecko (Firefox engine) without using user-agent.
 */
export function isGecko(): boolean {
    const w = window

    return (
        countTruthy([
            'buildID' in navigator,
            'MozAppearance' in (document.documentElement?.style ?? {}),
            'MediaRecorderErrorEvent' in w,
            'mozInnerScreenX' in w,
            'CSSMozDocumentRule' in w,
            'CanvasCaptureMediaStream' in w,
        ]) >= 4
    )
}

/**
 * Checks whether the browser is based on Chromium version ≥86 without using user-agent.
 * It doesn't check that the browser is based on Chromium, there is a separate function for this.
 */
export function isChromium86OrNewer(): boolean {
    const w = window

    return (
        countTruthy([
            !('MediaSettingsRange' in w),
            'RTCEncodedAudioFrame' in w,
            '' + w.Intl === '[object Intl]',
            '' + w.Reflect === '[object Reflect]',
        ]) >= 3
    )
}

/**
 * Checks whether the browser is based on WebKit version ≥606 (Safari ≥12) without using user-agent.
 * It doesn't check that the browser is based on WebKit, there is a separate function for this.
 */
export function isWebKit606OrNewer(): boolean {
    const w = window

    return (
        countTruthy([
            'DOMRectList' in w,
            'RTCPeerConnectionIceEvent' in w,
            'SVGGeometryElement' in w,
            'ontransitioncancel' in w,
        ]) >= 3
    )
}